import { DropDownTree } from "@progress/kendo-react-dropdowns";
import * as React from "react";
import "../pages/auditlog-master/style.scss";

const selectField = "selected";
const expandField = "expanded";
const dataItemKey = "id";
const textField = "text";

export const CustomTreeDropdown = (props) => {
  const {
    dropDownAppendTo,
    className,
    onExpandChange,
    onChange,
    value,
    treeData,
    onFilterChange,
    filter,
    placeholder,
    style,
    loading,
  } = props;
  return (
    <>
      <DropDownTree
        id={"myDropDownTree"}
        style={style}
        data={treeData}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        textField={textField}
        dataItemKey={dataItemKey}
        selectField={selectField}
        expandField={expandField}
        onExpandChange={onExpandChange}
        filterable={true}
        onFilterChange={onFilterChange}
        filter={filter.value}
        popupSettings={{
          popupClass: "tree-popup",
          height: "400px",
          appendTo: dropDownAppendTo,
        }}
        className={className}
        loading={loading}
      />
    </>
  );
};
