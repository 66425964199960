//export const LOGIN = "/user/login";
export const ACCESS_USERNAME = "/user/access-username";
export const AUTHENTICATION = "/user/authentication";
export const TENANT_SYSTEM_CONFIG = "/user/get-mfa-value";
export const OTP_GENERATION = "/user/generate-otp";
export const VERIFY_OTP = "/user/verify-otp";
export const EXPIRE_LOGIN_USER_OTP = "/user/expire-login-user-otp";
export const GET_SSO_LOGIN = "/user/ssoLogin";
export const GET_USER_ADMIN_DATA = "/user/select-user-admin";
export const UPDATE_USER_DATA = "/user/update";
export const ADD_USER_DATA = "/user/insert";
export const GET_DB_ROLES = "/user/select-db-roles";
export const GET_USER_STATUS = "/user/select-user-status-type";
export const DELETE_USER = "/user/delete";
export const GET_AUDIT_LOGS = "/user/select-user-admin-audit";
export const GET_DTU_LOGS = "/user/select-ETL-TABLE";
export const GET_BATCh_DTU_LOGS = "/user/select-user-ETL-batch-master";
export const GET_DTU_ERROR_LOGS = "/user/select-user-error-audit-log";
export const SEND_USER_POPUP_EMAIL = "/user/send-user-popup-email";
export const RESEND_EMAIL = "/user/resend-email";
export const GET_GROUP_NAME = "/org/select-title";
export const FORGOT_PASSWORD = "/user/forgot-password";
export const GET_SYSTEM_CONFIG = "/system-setting/select-system-pref-config";
export const UPDATE_SYSTEM_CONFIG = "/system-setting/update-system-pref-config";
export const CHANGE_PASSWORD = "/user/update-user-profile";
export const SELECT_USER = "/user/select-profile";
export const IMPERSONATE_USER = "/user/impersonate-user";
export const GET_BUSINESS_GROUP_LIST = "/business-group/select-business-group";
export const DELETE_BUSINESS_GROUP_DATA =
    "/business-group/delete-business-group";
export const ADD_BUSINESS_GROUP_DATA = "/business-group/insert-business-group";
export const UPDATE_BUSINESS_GROUP_DATA =
    "/business-group/update-business-group";
export const GET_CALENDAR_DATA = "/calendar/select-calendar";
export const UPDATE_CALENDAR_DATA = "/calendar/update-calendar";
export const ADD_CALENDAR_DATA = "/calendar/insert-calendar";
export const DELETE_CALENDAR_DATA = "/calendar/delete-calendar";
export const GET_SELECT_SPECIALTY_DATA = "/org/select-speicalty";
export const GET_COMP_PLAN_DATA = "/comp-config/select-comp-plan";
export const GET_POSITION_DATA = "/org/select-positions";
export const GET_POSITION_NO_CFD_DATA = "/org/select-no-cfd-positions";
export const GET_PERIOD_TYPE = "/calendar/select-period-type";
export const GET_RESULT_KPI = "/results/select-KPI";
export const GET_RESULT_NAME = "/results/select-result-name";
export const GET_RESULT_CREDITS = "/results/select-result-credits";
export const GET_RESULT_MEASUREMENTS = "/results/select-result-measurements";
export const GET_RESULT_INCENTIVES = "/results/select-result-incentives";
export const GET_RESULT_PAYMENTS = "/results/select-result-payments";
export const GET_SELECT_GRID_COLUMN_XML = "/results/select-grid-column-xml";
export const UPLOAD_CSV_FILE = "/common/upload-csv-file";
export const GET_DB_ROLE_TYPE_KEY_LIST = "/db-role-user/select-type-of-db-role";
export const ADD_ROLE = "/db-role-user/insert";
export const UPDATE_ROLE = "/db-role-user/update";
export const DELETE_ROLE = "/db-role-user/delete";
export const GET_ROLE_OBJ_ASSIGNMENT = "/db-role-user/select-db-object-by-role";
export const GET_PARENT_CHILD_ROLE_OBJ_ASSIGNMENT =
    "/db-role-user/select-parent-child-db-object-by-role-assignment";
export const GET_ROLE_OBJ_TABLE_ASSIGNMENT_ROLE =
    "/db-role-user/select-db-object-by-role-access";
export const UPDATE_ROLE_OBJ_TABLE_ASSIGNMENT =
    "/db-role-user/update-db-role-obj-assign";
export const GET_DB_ROLE_TABLE_ASSIGNMENT =
    "/db-role-user/select-db-role-obj-table-assignment";
export const UPDATE_DB_ROLE_TABLE_ASSIGNMENT =
    "/db-role-user/update-db-role-obj-table-assignment";
export const INSERT_DB_ROLE_TABLE_ASSIGNMENT =
    "/db-role-user/insert-db-role-obj-table-assignment";
export const GET_SPECIALITY_DATA = "/org/select-speicalty";
export const UPDATE_SPECIALITY_DATA = "/org/update-speicalty";
export const ADD_SPECIALITY_DATA = "/org/insert-speicalty";
export const DELETE_SPECIALITY_DATA = "/org/delete-speicalty";
export const GET_TITLE_DATA = "/org/select-title";
export const UPDATE_TITLE_DATA = "/org/update-title";
export const ADD_TITLE_DATA = "/org/insert-title";
export const DELETE_TITLE_DATA = "/org/delete-title";
export const GET_EVENT_TYPE = "/tables/select-event-type";
export const GET_EVENT_SERVICE_LOCATION =
    "/tables/select-event-service-location";
export const GET_EVENT_DATA_SOURCE = "/tables/select-event-data-source";
export const GET_EVENT_POSITION = "/tables/select-event-position";
export const GET_EVENT_COMP_PLAIN = "/tables/select-comp-plan-filter";
export const GET_EVENT_DATA = "/tables/select-events";
export const DELETE_EVENTS = "/tables/select-delete-events";
export const DELETE_EVENTS_BATCHES = "/tables/select-delete-events";
export const GET_CUSTOM_TABLE_EFF_DATES =
    "/tables/select-custom-table-eff-dates";
export const GET_CUSTOM_TABLE = "/tables/select-custom-table";
export const GET_CUSTOM_TABLE_DATA = "/tables/select-custom-table-data";
export const EXPORT_CUSTOM_TABLES = "/tables/export-to-excel";
export const DELETE_CUSTOM_TABLE_DATA = "/tables/delete-custom-table-data";
export const DELETE_CUSTOM_DATA = "/tables/delete-custom-table";
export const INSERT_UPDATE_CUSTOM_TABLE_DATA =
    "/tables/insert-custom-table-data";
export const SELECT_CUSTOM_DATA_COLUMNS = "/tables/select-custom-table-columns";
export const EXPORT_RESULT_MODULE = "/results/export-to-excel";
//export const SELECT_RATE_DATA_COLUMNS = "/tables/select-rate-table";
//export const GET_RATE_TABLE_DATA = "/tables/select-rate-table-data";
//export const INSERT_UPDATE_RATE_TABLE_DATA = "/tables/insert-rate-table-data";
//export const UPDATE_RATE_TABLE_TIER = "/tables/update-rate-table-tier";
export const GET_RATE_TABLE = "/tables/select-rate-table";
export const INSERT_RATE_TABLE = "/tables/insert-rate-table";
export const UPDATE_RATE_TABLE = "/tables/update-rate-table";
export const DELETE_RATE_TABLE = "/tables/delete-rate-table";
export const GET_RATE_TABLE_EFF_DATES = "/tables/select-rate-table-eff-dates";
export const GET_RATE_TABLE_TIER = "/tables/select-rate-table-tier";
export const INSERT_UPDATE_RATE_TABLE_TIER = "/tables/insert-rate-table-tier";
export const DELETE_RATE_TABLE_TIER = "/tables/delete-rate-table-tier";
export const GET_ETL_GROUP_LIST = "/dtu-data/select-etl-file-data";
export const ADD_ETL_DATA = "/dtu/insert-etl-file";
export const DELETE_ETL_DATA = "/dtu/delete-etl-file";
export const DELETE_ETL_ATTRIBUTE_DATA =
    "/dtu/delete-etl-attribute-file-config";
export const UPDATE_ETL_DATA = "/dtu/update-etl-file";
export const GET_ETL_EVENT_TYPE = "/dtu-data/select-etl-file-event-type";
export const GET_ETL_CONFIG_DATA = "/dtu-data/select-etl-file-config";
export const GET_ETL_ATTRIBUTE_DATA = "/dtu-data/select-etl-attribute";
export const UPDATE_ETL_ATTRIBUTE_DATA = "/dtu-data/update-etl-config";
export const GET_ETL_AUDIT_LOGS = "/dtu-data/select-etl-admin-audit";
export const SELECT_UNIT_TYPE = "/user/select-unittype";
export const SELECT_RULE_RESULT_NAME = "/comp-config/select-rule-result-name";
export const INSERT_RULE_RESULT_NAME = "/results/insert-rule-result-name";
export const UPDATE_RULE_RESULT_NAME = "/results/update-rule-result-name";
export const DELETE_RULE_RESULT_NAME = "/results/delete-rule-result-name";
export const SELECT_RESULT_TYPE = "/results/select-rule-result-type";
export const SELECT_RESULT_GROUP = "/results/select-result-group";
export const SELECT_RESULT_TYPE_LIST = "/results/select-result-type";
export const INSERT_EVENT_TYPE = "/tables/insert-event-type";
export const UPDATE_EVENT_TYPE = "/tables/update-event-type-master";
export const DELETE_EVENT_TYPE = "/tables/delete-event-type-master";
export const INSERT_KPI_TYPE = "/comp-config/insert-kpi-type-master";
export const UPDATE_KPI_TYPE = "/comp-config/update-kpi-type-master";
export const DELETE_KPI_TYPE = "/comp-config/delete-kpi-type-master";
export const SELECT_KPI_TYPE = "/comp-config/select-kpi-type";
export const SELECT_RULE_TYPE_MASTER = "/results/select-rule-result-type";
export const INSERT_RULE_TYPE_MASTER = "/results/insert-rule-type-master";
export const UPDATE_RULE_TYPE_MASTER = "/results/update-rule-type-master";
export const DELETE_RULE_TYPE_MASTER = "/results/delete-rule-type-master";
export const GET_QUERY_FILTER_LIST = "/filter/select-filter-data";
export const INSERT_QUERY_FILTER = "/filter/insert-filter-data";
export const UPDATE_QUERY_FILTER = "/filter/update-filter-data";
export const GET_PLAN_KPI_ASSIGN = "/comp-config/select-plan-kpi-assign";
export const GET_EVENT_TYPE_AUDIT_LOGS = "/tables/audit-event-type-master";
export const GET_EVENT_GROUP = "/tables/select-event-type-group";
export const GET_PLAN_PROVIDER_ASSIGN =
    "/comp-config/select-plan-provider-assign";
export const GET_PLAN_POSTION_ASSIGN = "/comp-config/select-plan-postion";
export const GET_PLAN_EFF_VERSION = "/comp-config/select-plan-Eff-Versions";
export const INSERT_COMP_PLAN = "/comp-config/insert-comp-plan";
export const DELETE_COMP_PLAN = "/comp-config/delete-Comp-Plan";
export const UPDATE_COMP_PLAN = "/comp-config/update-comp-plan";
export const UPDATE_KPI_ASSIGN = "/comp-config/update-plan-kpi-assign";
export const INSERT_PLAN_PROVIDER_ASSIGN =
    "/comp-config/insert-plan-provider-assign";
export const DELETE_PLAN_PROVIDER_ASSIGN =
    "/comp-config/delete-Plan-provide-assign";
export const COMP_PLAN_AUDIT = "/comp-config/select-comp-plan-audit";
export const INSERT_CUSTOM_TABLE = "/tables/insert-custom-table";
export const INSERT_CUSTOM_TABLE_COLUMNS =
    "/tables/insert-update-custom-table-data-columns";
export const GET_DATA_TYPES = "/user/select-data-type";
export const UPDATE_CUSTOM_TABLE = "/tables/update-custom-table";
export const SELECT_GROUP_AUDIT = "/org/select-group-audit";
export const SELECT_SPECIALTY_AUDIT = "/org/select-specialty-audit";
export const SELECT_ROLE_AUDIT = "/db-role-user/select-db-role-audit";
export const GET_CUSTOM_TABLE_AUDIT = "/tables/select-custom-table-audit";
export const GET_CUSTOM_TABLE_DATA_ROWS_AUDIT =
    "/tables/select-custom-table-data-audit";
export const GET_RATE_TABLE_AUDIT = "/tables/select-rate-table-audit";
export const GET_RATE_TABLE_TIER_AUDIT = "/tables/select-rate-table-tier-audit";
export const GET_CALENDAR_TABLE_AUDIT = "/calendar/select-period-type-audit";
export const GET_BUSINESS_TABLE_AUDIT = "/user/select-business-group-audit";
export const GET_KPI_TYPE_AUDIT_LOG = "/comp-config/select-kpi-type-audit-log";
export const GET_RESULT_TYPE_MASTER_AUDIT =
    "/results/select-result-type-master-audit";
export const SELECT_KPI = "/comp-config/select-kpi";
export const INSERT_KPI = "/comp-config/insert-kpi";
export const UPDATE_KPI = "/comp-config/update-kpi";
export const DELETE_KPI = "/comp-config/delete-kpi";
export const GET_KPI_AUDIT = "/comp-config/select-kpi-audit";
export const GET_COMP_ELEMENT = "/comp-config/select-kpi-comp-element-assign";
export const GET_KPI_RULES_ASSIGN = "/comp-config/select-kpi-rule-assign";
export const INSERT_COMP_ELEMENT =
    "/comp-config/insert-kpi-comp-element-assign";
export const DELETE_COMP_ELEMENT =
    "/comp-config/delete-kpi-comp-element-assign";
export const INSERT_KPI_RULE_ASSIGN =
    "/comp-config/insert-kpi-rule-config-assign";
export const DELETE_KPI_RULE_ASSIGN = "/comp-config//delete-kpi-rule-assign";
export const SELECT_COMP_ELEMENT_TYPE = "/comp-config/select-comp-element-type";
export const SELECT_JOB_CONFGURATION = "/jobs/select-job";
export const SELECT_JOB_TYPE = "/jobs/select-job-type";
export const GET_JOB_HISTORY = "/jobs/select-job-history";
export const INSERT_JOB = "/jobs/insert-job";
export const UPDATE_JOB = "/jobs/update-Job";
export const DELETE_JOB = "/jobs/delete-Job";
export const SELECT_JOB_ENGINE_PHASE_ASSIGNMENT =
    "/jobs/select-job-engine-phase-assignment";
export const UPDATE_JOB_ENGINE_PHASE_ASSIGNMENT =
    "/jobs/update-Job-engine-phase-assignment";
export const GET_JOB_AUDIT_LOG = "/jobs/select-job-audit";
export const SELECT_JOB_EXEUCUTAION = "/jobs/select-job-queue";
export const DELETE_JOB_EXEUCUTAION = "/jobs/delete-job-queue";
export const SELECT_PIPELINE_LOG = "/jobs/select-pipeline-run-log";
export const SELECT_ERROR_DETAIL_LOG = "/jobs/select-error-detail-log";
export const GET_UNIT_TYPE = "/user/select-unittype";
export const SELECT_JOB_PROCESS_MASTER = "/jobs/select-job-process-master";
export const DELETE_UNIT_TYPE = "/user/delete-unit-type-master";
export const INSERT_UNIT_TYPE = "/user/insert-unit-type";
export const GET_COMPENSATION_RULES_DATA = "/comp-config/select-comp-rule";
export const DELETE_COMP_RULE = "/comp-config/delete-comp-rule";
export const SELECT_RULE_TYPE = "/comp-config/select-rule-type";
export const INSERT_COMP_RULE = "/comp-config/insert-comp-rule";
export const SELECT_COMP_RULE_EFF_VERSION =
    "/comp-config/select-comp-rule-eff-versions";
export const UPDATE_CREDIT_ALLOC_RULE_CONFIG =
    "/comp-config/update-credit-alloc-rule-config";
export const UPDATE_CREDIT_RULE_CONFIG =
    "/comp-config/update-credit-rule-config";
export const UPDATE_INCENTIVE_CONFIG =
    "/comp-config/update-incentive-rule-config";
export const UPDATE_MEASUREMENTS_RULE_CONFIG =
    "/comp-config/update-measurements-rule-config";
export const UPDATE_PAYMENT_RULE_CONFIG =
    "/comp-config/update-payment-rule-config";
export const SELECT_GENERAL_LEDGER = "/comp-config/select-general-ledger";
export const SELECT_UDF_DATA_OBJECT = "/comp-config/select-udf-data-object";
export const SELECT_OPERATOR = "/comp-config/select-operator";
export const SELECT_COMP_RULE = "/comp-config/select-comp-rule-config";
export const UPDATE_UNIT_TYPE = "/user/update-unit-type-master";
export const SELECT_FORMULA_MASTER = "/comp-config/select-formula";
export const DELETE_FORMULA_MASTER = "/comp-config/delete-formula";
export const INSERT_FORMULA_MASTER = "/comp-config/insert-formula";
export const UPDATE_FORMULA_MASTER = "/comp-config/update-formula";
export const SP_INSERT_UDF_DATA_OBJECT = "/comp-config/insert-udf-data-object";
export const UPDATE_PIPELINE = "/jobs/update-pipeline-run-status";
export const RESTART_PIPELINE = "/jobs/restart-pipeline-run-status";
export const SELECT_UNIT_TYPE_MASTER_AUDIT =
    "/user/select-unit-type-master-audit";
export const SELECT_COMP_RULE_AUDIT = "/comp-config/select-comp-rule-audit";
export const DELETE_CUSTOM_TABLE_DATA_ROWS =
    "/tables/delete-custom-table-data-rows";
export const INSERT_JOB_QUEUE = "/jobs/insert-job-queue";
export const SELECT_JOB_POSTION_PARAM = "/jobs/select-job-position-param";
export const SELECT_JOB_POSTION_STAGEHOOK_PARAM =
    "/jobs/select-job-position-stagehook-param";
export const PUBLISH_JOB = "/jobs/publish-job-rule";
export const SELECT_FORMULA_MASTER_AUDIT_LOG =
    "/comp-config/select-formula-master-audit";
export const SELECT_PROVIDER_AUDIT_LOG = "/org/select-provider-master-audit";
export const SELECT_POSITION_AUDIT_LOG = "/org/select-position-audit";
export const SELECT_ROLE_LIST_BY_AUDIT_LOG =
    "/db-role-user/select-parent-child-db-object-by-role-by-audit-log";
export const SELECT_FLUSH_CUSTOM_DATA =
    "/tables/select-flush-custom-table-data";
export const SELECT_RESULT_NAME_MASTER_AUDIT =
    "/results/select-result-name-master-audit";
// export const SELECT_REPORT_STATUS = "/jobs/select-report-status";
export const SELECT_ETL_LOAD_STATUS = "/jobs/select-etl-load-mstr";
export const SELECT_JOB_PROGRESS_STATUS = "/jobs/select-job-queue-socket";
export const SELECT_AUTO_REPORT_STATUS = "/jobs/select-auto-report-status";
export const SELECT_JOB_END_TIME_STATUS =
    "/jobs/select-job-process-master-end-time";
export const SELECT_EVENTS_DATA = "/tables/select-event-batches";
export const SELECT_DELETE_BATCH_EVENTS = "/tables/select-delete-events";
export const SELECT_GOAL_HIERARCHY_VALUE =
    "/comp-config/select-goal-hierarchy-values";
export const SELECT_TEAM_NAME = "/comp-config/select-team-name";
export const SELECT_PROVIDER_POSTION_FILTER =
    "/org/select-provider-position-filter";
export const SELECT_PROVIDER_POSTION_LIST = "/org/select-provider-position";
export const SELECT_RATE_TYPE = "/tables/select-rate-type";
export const SELECT_SYSTEM_FX = "/comp-config/select-system-fx";
export const SELECT_SYSTEM_FX_PARM_ASSIGNMENT =
    "/comp-config/select-system-fx-parm-assignment";
export const SELECT_CUSTOM_FIELDS = "/tables/select-custom-fields";
export const INSERT_CUSTOM_FIELDS = "/tables/insert-custom-fields";
export const UPDATE_CUSTOM_FIELDS = "/tables/update-custom-fields";
export const DELETE_CUSTOM_FIELDS = "/tables/delete-custom-fields";
export const SELECT_CUSTOM_FIELDS_MASTER =
    "/tables/select-custom-fields-master";
export const UPDATE_COLUMN_ORDER = "/reports/comp-detail-update-column-order";
export const DELETE_EVENTS_DATA = "/tables/select-multi-delete-events";
export const GET_CATEGORY = "/org/select-category";
export const GET_CATEGORY_FIELD = "/org/select-category-field";
export const GET_POSITION_TYPE_LIST = "/org/select-positiontype";
export const INSERT_PROVIDER = "/org/insert-provider";
export const INSERT_POSITION = "/org/insert-position";
export const UPDATE_PROVIDER = "/org/update-provider";
export const UPDATE_POSITION = "/org/update-position";
export const INSERT_CUSTOM_FIELD_DATA = "/org/insert-custom-field-data";
export const DELETE_PROVIDER = "/org/delete-provider";
export const UPDATE_CATEGORY_ORDER = "/org/update-category-order";
export const UPDATE_CATEGORY_FDM_ORDER = "/org/update-category-fdm-provider";
export const UPDATE_EVENT = "/tables/update-event";
export const GET_RECURRING_TYPE_LIST = "/jobs/select-recurring-event-data";
export const SELECT_CUSTOM_FIELD_AUDIT_LOG =
    "/tables/select-custom-fields-audit";
export const DOWNLOAD_DTU_FILE = "/common/download-csv-file";
export const SELECT_APPROVAL_STATUS = "/tables/select-approval-status-master";
export const SELECT_SYSTEM_PREF_CONFIG_BY_CODE =
    "/system-setting/select-system-config-by-code";
export const ADD_JOB_SCHEDULER = "/jobs/insert-recurring-job-data";
export const DELETE_POSITION = "/org/delete-position";
export const GET_PROVIDER_EFF_DATES = "/org/select-provider-versions";
export const GET_POSITION_EFF_DATES = "/org/select-position-version";
export const INSERT_PROVIDER_EFF_VERSION = "/org/insert-provider-eff-version";
export const INSERT_POSITION_EFF_VERSION = "/org/insert-position-eff-version";
export const GET_PROVIDER_POSITION_VERSION_DATA =
    "/org/select-provider-position-version-data";
export const SELECT_DOCUMENT = "/org/select-document";
export const INSERT_DOCUMENT = "/org/insert-document";
export const DELETE_DOCUMENT = "/org//delete-document";
export const SELECT_PROVIDER_PROFILE = "/org/select-provider-user";
export const SELECT_REPORT_PUBLISH_DATA = "/jobs/select-reportpublish-key-data";
export const SELECT_BUILD_DATA = "/user/get-build-version";
export const GET_MAINTENACE_DATA = "/user/system-maintenance-message";
export const SELECT_PROVIDER_VERSION_DATA = "/org/select-provider-version-data";
export const PLAN_PROVIDER_AUDIT = "/comp-config/select-plan-provider-audit";
export const SELECT_CUSTOM_TABLE_COLUMNS_DATA =
    "/tables/select-custom-table-columns-data";
