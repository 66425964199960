
import React from 'react';
import { getUserCredential } from "../../lib/cookies";
import { errStatusHandler, POSTAPI } from "../../api-setup/api-helper";
import { downloadURI, GridColumnDataMapper, ResultGridDataMapper } from '../../utils/utils';
import { USER_GRID_DEFAULT_PAGE_SIZE } from '../../constants/grid';
import { useDispatch } from "react-redux";
import { setNewMessage } from '../../redux/actions/setting-actions';
import { GET_SELECT_GRID_COLUMN_XML ,EXPORT_RESULT_MODULE } from '../../api-setup/api-endpoints';
import {
    setExpandedState,
    setGroupIds,
} from "@progress/kendo-react-data-tools";
import { groupBy } from "@progress/kendo-data-query";
import { useHistory } from 'react-router-dom';

const initialDataState = {
    skip: 0,
    take: USER_GRID_DEFAULT_PAGE_SIZE,
};
let defaultItem = {
    text: USER_GRID_DEFAULT_PAGE_SIZE,
    id: 2,
};
const processWithGroups = (data, group) => {
    const newDataState = groupBy(data, group);
    setGroupIds({
        data: newDataState,
        group: group,
    });
    return newDataState;
};

export default function Results() {
    const history = useHistory()
    const cookiesData = getUserCredential();
    const header = {
        "x-access-token": cookiesData.secretToken,
    };
    const dispatch = useDispatch();
    const [data, setData] = React.useState([]);
    const [columns, setColumns] = React.useState([]);
    const [dataCount, setDataCount] = React.useState(0);
    const [isColumnMenuSearch, setIsColumnMenuSearch] = React.useState(false);
    const [paginationData, setPaginationData] = React.useState({
        pageData: 0,
        rows: 10
    });
    const [noDataFound, setNoDataFound] = React.useState(false);

    const [initialLoading, setLoadingState] = React.useState(false);
    const [pageData, setPage] = React.useState(initialDataState);
    const [page, setPageData] = React.useState(0);
    const [editData, setEditData] = React.useState('');
    const [pageSize, setPageSize] = React.useState(defaultItem);
    const [isAPICalling, setAPICallingState] = React.useState(false);
    const [errorMsg, SetErrorMsg] = React.useState('');
    const [group, setGroup] = React.useState([]);
    const [resultState, setResultState] = React.useState([])
    const [collapsedState, setCollapsedState] = React.useState([]);
    const [originalData, setOriginalData] = React.useState([])
    const [aliasData, setaliasData] = React.useState([])
    const [loading, setLoadingStateSearch] = React.useState({
        isSearchLoader: false,
        isUpdateColumn: false
      });
    let newData = []
    let processedGroupData = []
    const getListDataAsync = (listData, apiName, groupData) => {
        setIsColumnMenuSearch(true);
        POSTAPI(false, apiName, null, header, listData, dispatch).then((res) => {
            if(res){
            if (res.data.out_parameters.out_ret_code > 0) {
                setLoadingState(true);
                setAPICallingState(false);
                setIsColumnMenuSearch(false);
                SetErrorMsg(res.data.out_parameters.out_error_msg);
                errStatusHandler(res, history, dispatch);
                setLoadingStateSearch({ ...loading, isSearchLoader: false });
                setNoDataFound(false)
                setData([])
                setDataCount(res.data.out_parameters.out_total_rows || 0)
                setOriginalData([])
                setaliasData([])
            } else {
                if (res.data) {
                    setAPICallingState(false);
                    setLoadingState(true);
                    const finalData = ResultGridDataMapper(res.data.out_parameters.out_results_xml);
                    const finalDatawithAlias = ResultGridDataMapper(res.data.out_parameters.out_results_xml,"alias");
                    if (finalData && finalData.length > 0) {
                        let filterDateArray = [];
                        for (const resultData of finalData) {
                            if (resultData && resultData.payment_date) {
                                resultData.payment_date =  new Date(resultData.payment_date)
                            }
                            if (resultData && resultData.incentive_date) {
                                resultData.incentive_date = new Date(resultData.incentive_date)
                            }
                            if (resultData && resultData.action_date) {
                                resultData.action_date =  new Date(resultData.action_date);
                            }
                            resultData.sortOrder = 'asc'
                            filterDateArray.push(resultData);
                        }
                        newData = setExpandedState({
                            data: group.length === 0 ? filterDateArray : processWithGroups(filterDateArray, group),
                            collapsedIds: collapsedState,
                        });
                        if (groupData && groupData.length > 0) {
                            const newDataState = processWithGroups(filterDateArray, groupData);
                            setResultState(newDataState);
                            processedGroupData = setExpandedState({
                                data: newDataState,
                                collapsedIds: collapsedState,
                            });
                            setData(processedGroupData);
                        } else {
                            setData(filterDateArray);
                        }
                        setNoDataFound(false)

                        setDataCount(res.data.out_parameters.out_total_rows);
                        setIsColumnMenuSearch(false);
                        setaliasData(finalDatawithAlias[0]);
                        setOriginalData(filterDateArray)
                        setLoadingStateSearch({ ...loading, isSearchLoader: false });

                    }
                }
            }
        }
        });
    };

    const getColumListXML = (reqData,checked) => {
        POSTAPI(false, GET_SELECT_GRID_COLUMN_XML, null, header, reqData, dispatch).then((res) => {
            if (res.data.out_parameters.out_ret_code > 0) {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                setLoadingState(true);
                setAPICallingState(true);
                errStatusHandler(res, history, dispatch);
            } else {
                const columData = GridColumnDataMapper(res.data.out_parameters.out_columns_xml, 'field_proceed');
                let columnFilterData = [...columData]
                if (checked) {
                  columnFilterData.map((cItem) => {
                    if (cItem.field === "is_published") {
                      cItem.show = false
                    }
                    if (cItem.field === "is_active") {
                      cItem.show = true
                    }
                    return 0
                  })
                } else {
                  columnFilterData.map((cItem) => {
                    if (cItem.field === "is_published") {
                      cItem.show = true
                    }
                    if (cItem.field === "is_active") {
                      cItem.show = false
                    }
                    return 0
                  })
                }
                setColumns([...columnFilterData]);
                setLoadingStateSearch({...loading ,isUpdateColumn : false})
            }
        });
    };

    const exportResultData = (reqData)=>{
        POSTAPI(false, EXPORT_RESULT_MODULE, null, header, reqData, dispatch).then((res) => {
            if (res.data.out_parameters.out_ret_code > 0) {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                setLoadingState(true);
                setAPICallingState(true);
                setLoadingStateSearch({ ...loading, updateColumn: false });
                errStatusHandler(res, history, dispatch);
            } else {
              setLoadingStateSearch({ ...loading, updateColumn: false });
              downloadURI(res.data.fileName, res.data.fileLink)
            }
        });
    }

    return [
        {
            data,
            columns,
            dataCount,
            setData,
            setColumns,
            isColumnMenuSearch,
            pageData,
            editData,
            page,
            pageSize,
            isAPICalling,
            setEditData,
            initialLoading,
            setPage,
            setPageData,
            setPageSize,
            setPaginationData,
            setAPICallingState,
            paginationData,
            errorMsg,
            collapsedState,
            setCollapsedState,
            group,
            setResultState,
            setGroup,
            newData,
            originalData,
            resultState,
            processedGroupData,
            setLoadingStateSearch,
            loading,
            setLoadingState,
            noDataFound,
            aliasData,
            setDataCount
        },
        {
            getListDataAsync,
            getColumListXML,
            exportResultData,
        }
    ];
}
