// eslint-disable-next-line no-useless-concat
import { getter } from "@progress/kendo-react-common";
import {
    getSelectedState,
    GridColumn as Column,
} from "@progress/kendo-react-grid";
import { Input } from "@progress/kendo-react-inputs";
import * as React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { columnMenuProps } from "../../../components/customColumnMenu";
import DataTable from "../../../components/dataTable";
import { DialogComponent } from "../../../components/dialog";
import { CellRender, RowRender } from "../../../components/renderers";
import {
    CANCEL_BTN_ACTIONS,
    DELETE_BTN_ACTIONS,
} from "../../../constants/constants";
import { DEFAULT_SORT } from "../../../constants/grid";
import { getUserCredential, getTenantCookieData } from "../../../lib/cookies";
import {
    removeMessage,
    setLinkedRules,
} from "../../../redux/actions/setting-actions";
import {
    changeCancelBtnState,
    changeUpdateBtnState,
    debounceForFunc,
    executeGetColumnOrderInfo,
    getfilterDataString,
    isNullOrEmptyUndefined,
    isNullOrEmptyUndefinedOrZero,
    microTime,
} from "../../../utils/utils";
import useApi from "./service";
import { LoadingPanel } from "../../../components/loadingPanel";
import MessageSchema from "../../../utils/messageSchema";
import {
    executionDataSelector,
    linkedRulesSelector,
    prorateDataSelector,
    resultSettingSelector,
} from "../../../redux/selectors/custom-selector";
import {
    setResetState,
    setRuleFilterItemDataAction,
    setUpdateRule,
} from "../../../redux/actions/rule-library-actions";
import { Button } from "@progress/kendo-react-buttons";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import {
    prepareFormulaFilterText,
    prepareRuleFilterText,
    validateAndOrOperator,
    validateRuleItem,
} from "../rule-filters/ruleFilterHelper";
import { updateColumnReorderAsync } from "src/lib/common-service";

const editField = "inEdit";
const SELECTED_FIELD = "selected";
const DATA_ITEM_KEY = "primary_key";
const idGetter = getter(DATA_ITEM_KEY);

export const CompenSationData = (restProps) => {
    const {
        headerId,
        isShow,
        setShow,
        setColumnsData,
        columnsData,
        setSelectedRowId,
        selectedRowId,
        isCheckColumn,
        setColumnCheck,
        pageProps,
        setAPICall,
        isAPICall,
        setSelectedState,
        selectedState,
        setRuleEdit,
        isRuleEdit,
        formFields,
        isRuleFilterEnabled,
        setUpdateCompRule,
        noDataFound,
        setIsCopyLoading,
    } = restProps;
    const dispatch = useDispatch();
    const cookiesData = getUserCredential();
    const history = useHistory();
    const defaultPageSize = getTenantCookieData("record_per_page");
    const [list, Action] = useApi();
    const prorateReduxData = useSelector(prorateDataSelector, shallowEqual);
    const executionReduxData = useSelector(executionDataSelector, shallowEqual);
    const resultSettingReduxData = useSelector(
        resultSettingSelector,
        shallowEqual
    );
    const { linkedRules } = useSelector(linkedRulesSelector, shallowEqual);
    const [loadingState, setLoadingState] = React.useState({
        isUpdateCompRule: false,
        updateColumn: false,
    });
    const [filterField, setFilterField] = React.useState({
        logic: "and",
        filters: [],
    });
    const [sortField, setSortField] = React.useState([]);
    const {
        data,
        columns,
        dataCount,
        setData,
        originalData,
        isAPICalling,
        setConfirmationPopup,
        confirmationPopup,
        setAPICallingState,
        initialLoading,
    } = list;

    //state to set and get filter, sort number, sort order
    const [filterData, setFilterData] = React.useState({
        isSort: false,
        isFilter: false,
        data: "",
        in_sort_number: DEFAULT_SORT.number,
        sort_order: DEFAULT_SORT.order,
    });

    //state to set and get limit, offset
    const [pageData, setPage] = React.useState({
        skip: 0,
        take: defaultPageSize.recordTake,
    });

    const [pageSize, setPageSize] = React.useState(defaultPageSize);

    const [errorObj, setErrorObj] = React.useState({});
    const [gridColumns, setGridColumns] = React.useState([]);
    const [loadState, setLoadState] = React.useState({
        isCopyRowsLoading: false,
    });
    const [resetSort, setResetSort] = React.useState(false);

    const apiCallData = {
        in_tenant_id: cookiesData.out_tenant_id,
        in_filter: null,
        in_sort_number: DEFAULT_SORT.number,
        in_sort_order: DEFAULT_SORT.order,
        in_page_row_offset: 0,
        in_page_rows: defaultPageSize.recordTake,
    };

    React.useEffect(() => {
        if (isAPICall) {
            setSelectedState({});
            setSelectedRowId("");
            setAPICall(false);
            setAPICallingState(true);
            const dataVal = {
                ...apiCallData,
                in_filter: getInFilter(),
                in_sort_number: getInSortNumber(),
                in_sort_order: getInSortOrder(),
                in_page_row_offset: pageData.skip,
                in_page_rows: pageData.take,
                in_load_type: "grid-reload",
            };
            getGridData(dataVal);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAPICall]);

    React.useEffect(() => {
        let filter = {};
        let kpi_mstr_key = null;
        if (linkedRules && linkedRules?.[headerId]) {
            const linkedData = linkedRules[headerId];
            if (linkedData.type === "formula") {
                filter.formula_mstr_key = linkedData.formula_mstr_key;
            } else if (linkedData.type === "kpi_rule") {
                if (linkedData.kpi_mstr_key) {
                    kpi_mstr_key = linkedData.kpi_mstr_key;
                }
                if (linkedData.rule_type_mstr_key) {
                    filter.rule_type_mstr_key = linkedData.rule_type_mstr_key;
                }
                if (linkedData.rule_config_key) {
                    filter.rule_config_key = linkedData.rule_config_key;
                }
            }
        }
        const dataVal = {
            ...apiCallData,
            in_filter: getInFilter(),
            in_sort_number: getInSortNumber(),
            in_sort_order: getInSortOrder(),
            in_page_row_offset: pageData.skip,
            in_page_rows: pageData.take,
            in_kpi_mstr_key: kpi_mstr_key,
            in_load_type: "grid-reload",
            filter: filter,
        };
        getGridData(dataVal);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [linkedRules, filterData]);

    React.useEffect(() => {
        if (originalData && originalData.length > 0) {
            let prevSelected = originalData.find(
                (obj) => obj.primary_key == restProps.selectedRowId
            );
            setNewSelectedRow(
                prevSelected !== undefined
                    ? { [prevSelected.primary_key]: true }
                    : { [originalData[0].primary_key]: true }
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [originalData]);

    React.useEffect(() => {
        let prevSelected = originalData?.find(
            (obj) => obj[DATA_ITEM_KEY] === selectedRowId
        );
        onRowClick(prevSelected !== undefined ? prevSelected : originalData[0]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [originalData]);

    React.useEffect(() => {
        if (isShow.duplicateRow) {
            handleCopySelectedRow();
            setShow({ ...isShow, duplicateRow: false });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShow]);

    React.useEffect(() => {
        if (isShow.deleteRows) {
            remove();
            setShow({ ...isShow, deleteRows: false });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShow]);

    React.useEffect(() => {
        if (data.length > 0) {
            restProps.setDataList(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    React.useEffect(() => {
        if (columns.length > 0) {
            setData(data);
            setColumnsData(columns);
            setGridColumns(columns);
            changeCancelBtnState("disable", "grid-incell-cancel-comp-rule");
            changeUpdateBtnState("disable", "grid-incell-update-comp-rule");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columns]);

    React.useEffect(() => {
        if (isCheckColumn) {
            setGridColumns(columnsData);
            setColumnCheck(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCheckColumn]);

    const getGridData = async (inParams, isFilter, isSort) => {
        setErrorObj({});
        setResetSort(false);
        let res = await Action.getListDataAsync(inParams);
        if (res.length > 0 && (isFilter || isSort)) {
            restProps.setCompRuleGridRefresh(true);
        }
        if (
            res.length > 0 &&
            Object.keys(selectedState).length === 1 &&
            inParams.isAdd
        ) {
            setSelectedRowId(res[0].primary_key);
            let newState = {};
            newState[res[0][DATA_ITEM_KEY]] = true;
            setSelectedState(newState);
            let newData = res.map((item) => ({
                ...item,
                selected:
                    item[DATA_ITEM_KEY] === res[0].primary_key ? true : false,
            }));
            setData(newData);
            restProps.setCompRuleGridRefresh(true);
        } else if (res.length > 0 && Object.keys(selectedState).length === 0) {
            setSelectedRowId(res[0].primary_key);
            let newState = {};
            newState[res[0][DATA_ITEM_KEY]] = true;
            setSelectedState(newState);
            let newData = res.map((item) => ({
                ...item,
                selected:
                    item[DATA_ITEM_KEY] === res[0].primary_key ? true : false,
            }));
            setData(newData);
            restProps.setCompRuleGridRefresh(true);
        } else if (res.length > 0 && inParams.isDeleteRow) {
            setSelectedRowId(res[0].primary_key);
            let newState = {};
            newState[res[0][DATA_ITEM_KEY]] = true;
            setSelectedState(newState);
            let newData = res.map((item) => ({
                ...item,
                selected:
                    item[DATA_ITEM_KEY] === res[0].rule_config_key
                        ? true
                        : false,
            }));
            setData(newData);
            restProps.setCompRuleGridRefresh(true);
        } else if (res.length === 0) {
            setSelectedRowId("");
            setSelectedState({});
            restProps.setCompRuleGridRefresh(true);
        }
        changeCancelBtnState("disable", "grid-incell-cancel-comp-rule");
        changeUpdateBtnState("disable", "grid-incell-update-comp-rule");
    };
    const getInFilter = () => {
        return filterData.isFilter ? filterData.data : null;
    };
    const getInSortNumber = () => {
        return filterData.isSort
            ? filterData.in_sort_number
            : DEFAULT_SORT.number;
    };

    // const getPageTake = () => {
    //   return pageData.take;
    // };

    // const getPageSkip = () => {
    //   return pageData.skip;
    // };

    const getInSortOrder = () => {
        return filterData.isSort ? filterData.sort_order : DEFAULT_SORT.order;
    };

    const remove = (ids) => {
        setConfirmationPopup({
            ...confirmationPopup,
            isVisible: true,
            desc: "Are you sure you want to delete this rule ?",
            type: "DELETE_CONFIRM",
            actionBtn: DELETE_BTN_ACTIONS(onCancelConfirm, onDeleteConfirm),
            data: [],
            action: "Delete",
        });
    };

    const getKeys = () => {
        let keys = Object.keys(selectedState);
        let tempArray = [];
        keys.map((item) => {
            let rule_key = item.split("_");
            tempArray.push(rule_key[0]);
            return 0;
        });
        return tempArray;
    };

    const onDeleteConfirm = async (event) => {
        let newArray = [];
        data.map((item) => {
            if (getKeys().includes(item.rule_config_key.toString())) {
                let obj = {
                    mstr_key: item.rule_config_key,
                    // date_version: item.date_version,
                    date_version: null,
                    rule_type_mstr_key: item.rule_type_mstr_key,
                };
                newArray.push(obj);
            }
            return 0;
        });
        let deleteData = {
            in_user_id: cookiesData.out_user_id,
            in_tenant_id: cookiesData.out_tenant_id,
            in_version_data: newArray,
        };
        let response = await Action.deleteDataAsync(deleteData);
        if (response) {
            setConfirmationPopup({
                ...confirmationPopup,
                isVisible: false,
                type: "",
                actionBtn: [],
                data: {},
                action: "",
            });
            setSelectedRowId("");
            setErrorObj({});
            setAPICallingState(true);
            apiCallData.isDeleteRow = true;

            apiCallData.in_filter = getInFilter();
            const dataVal = {
                ...apiCallData,
                in_filter: getInFilter(),
                in_sort_number: getInSortNumber(),
                in_sort_order: getInSortOrder(),
                in_page_row_offset: pageData.skip,
                in_page_rows: pageData.take,
                in_load_type: "grid-reload",
            };
            if (data.length === 1) {
                setResetSort(true);
                onClearFilter();
            }
            await getGridData(dataVal);
        }
    };

    const onCancelConfirm = (action) => {
        setConfirmationPopup({
            ...confirmationPopup,
            isVisible: false,
            type: "",
            actionBtn: [],
            data: {},
            action: "",
        });
        data.map((item) => {
            item.selected = false;
            return 0;
        });
        setData([...data]);
        if (action === "outSideClick") {
            // TODO : UNSAVED CHANGES
            // dispatch(
            //   setUnSavedChangesPopup({ ...unsavedPopup, isUnSavedChange: true })
            // );
        }
    };

    const isRowSelected = () => {
        const selectedStateVal = Object.values(selectedState);

        /**
         * consider no row selected if no selected state is true
         * the flag is set to false when user deselect any row
         */
        if (selectedStateVal.length && !selectedStateVal.includes(true)) {
            return false;
        }
        const selectedKeys = Object.keys(selectedState).map((key) =>
            parseInt(key)
        );

        let ids = data.map(
            (el) => el.rule_config_key + "_" + el.rule_type_mstr_key
        );
        return !selectedKeys.length
            ? false
            : selectedKeys.every((value) => {
                return ids.includes(value);
            });
    };

    const onRowClick = (dataItem) => {
        restProps.setDataList(data);
        if (isAPICall === true) {
            setAPICall(false);
        }

        if ((originalData && originalData.length <= 0) || data.length <= 0) {
            setSelectedRowId("");
            return false;
        }
        //default select clicked row
        setSelectedRowId(dataItem.primary_key);
        if (!isRowSelected()) {
            let newSelectedState = {};
            let newData = data.map((item) => ({
                ...item,
                selected:
                    item[DATA_ITEM_KEY] === dataItem[DATA_ITEM_KEY]
                        ? true
                        : false,
            }));
            newSelectedState[dataItem[[DATA_ITEM_KEY]]] = true;
            setData(newData);
        } else {
            const newSelectedStateKeys = Object.keys(selectedState);

            const selectedKeyArr = newSelectedStateKeys.filter(function (x) {
                return selectedState[x] === true;
            });

            //default selected latest row from all selection
            !selectedKeyArr.includes(dataItem[DATA_ITEM_KEY].toString()) &&
                setSelectedRowId(
                    selectedKeyArr[selectedKeyArr.length - 1]?.primary_key
                );
        }
        if(selectedRowId !==dataItem.primary_key ){
            dispatch(setResetState());
        }
        
    };

    const setNewSelectedRow = (newSelectedState, isReset) => {
        const keys = Object.keys(newSelectedState).filter(
            (key) => newSelectedState[key] === true
        );
        let tempArray = [];

        Object.keys(newSelectedState).map((item) => {
            if (newSelectedState[item] === false) {
                return 0;
            }

            let obj = {};
            obj.mstr_key = Number(item);

            tempArray.push(obj);
            return 0;
        });

        const newSelectedStateKeys = Object.keys(newSelectedState);

        const selectedKeyArr = newSelectedStateKeys.filter(function (x) {
            return newSelectedState[x] !== false;
        });

        let newData = data.map((item) => ({
            ...item,
            selected:
                item.primary_key === selectedKeyArr[selectedKeyArr.length - 1],
            /*
            selected: selectedKeyArr.includes(
                item.rule_config_key + "_" + item.rule_type_mstr_key
            ),
                */
        }));
        setData(newData);
        setSelectedState(newSelectedState);
        onRowClick(newData.find((it) => it.selected === true));
    };

    // const setNewSelectedRow = (newState, isReset) => {
    //   const keys = Object.keys(newState).filter((key) => newState[key] === true);

    //   setSelectedState(newState);
    //   let tempArray = [];
    //   keys.map((item) => {
    //     let obj = {};
    //     obj.mstr_key = Number(item);
    //     tempArray.push(obj);
    //     return 0;
    //   });
    //   // setDeleteSelectedIds(tempArray);

    //   const selectedKeyArr = keys.filter(function (x) {
    //     return newState[x] !== false;
    //   });

    //   if (isReset) {
    //     originalData.map((item) => {
    //       item.inEdit = false;
    //       item.selected = selectedKeyArr.includes(item.primary_key.toString());
    //       return 0;
    //     });
    //     const item = originalData.find((it) => it.selected === true);
    //     setData(originalData);
    //     restProps.setDataList(originalData);
    //     debounceForFunc(() => onRowClick(item), 100);
    //   } else {
    //     let newData = data.map((item) => ({
    //       ...item,
    //       selected: selectedKeyArr.includes(item.rule_config_key + "_" + item.rule_type_mstr_key),
    //     }));
    //     const item = newData.find((it) => it.selected === true);
    //     setData(newData);
    //     restProps.setDataList(newData);
    //     setSelectedState(newState);
    //     debounceForFunc(() => onRowClick(item), 100);
    //   }
    // };

    // const onSelectionChange = (event) => {
    //   // TODO : UNSAVED CHANGES

    //   // if (unsavedPopup.isUnSavedChange) {
    //   //   dispatch(
    //   //     setUnSavedChangesPopup({
    //   //       ...unsavedPopup,
    //   //       isVisible: true,
    //   //       callbackFn: () => {
    //   //         setTimeout(() => setNewSelectedRow(event), 1000);
    //   //       },
    //   //     })
    //   //   );
    //   // } else {
    //   setNewSelectedRow(event);
    //   //}
    // };

    const onSelectionChange = (event) => {
        const newSelectedState = getSelectedState({
            event,
            selectedState: selectedState,
            dataItemKey: DATA_ITEM_KEY,
        });
        // let newKeys = Object.keys(newSelectedState);
        // let oldKeys = Object.keys(selectedState);
        // const isSame = isSameRow(oldKeys, newKeys);

        // if (unsavedPopup.isUnSavedChange && !isSame) {
        //   dispatch(
        //     setUnSavedChangesPopup({
        //       ...unsavedPopup,
        //       isVisible: true,
        //       callbackFn: () => {
        //         setNewSelectedRow(newSelectedState, true);
        //         setErrorObj({});
        //         changeUpdateBtnState("disable");
        //         changeCancelBtnState("disable");
        //       },
        //     })
        //   );
        //   return;
        // }

        setNewSelectedRow(newSelectedState, false);
    };

    const enterEdit = (dataItem, field) => {
        if (pageProps.writeAccess === 0) {
            return false;
        }

        let newData = data.map((item) => ({
            ...item,
            inEdit:
                item.primary_key === dataItem.primary_key ? field : undefined,
        }));

        setData(newData);
        setRuleEdit(true);
    };

    const exitEdit = (dataItem, index, dataIndex, e) => {
        if (dataItem.rule_name === "") {
            handleValidation();
        }
        // let newData = data.map((item) => ({
        //   ...item,
        //   inEdit: undefined
        // }));
        // setData(newData);
    };

    const validateRuleFilters = (ruleItems) => {
        const items = [...ruleItems];

        items.map((element, index) => {
            if (
                element.operatorItem.value !== "IS NULL" &&
                element.operatorItem.value !== "IS NOT NULL"
            ) {
                const error = validateRuleItem(element);
                element.uId = uuidv4();
                element.valueItem = { ...element.valueItem, ...error };
            }
            return 0;
        });
        const found = items
            .map((it) => it.valueItem.error)
            .find((it) => it === true);
        return { found: !found ? true : false, items: items };
    };

    const handleValidation = () => {
        let item = data.find((x) => x.primary_key === selectedRowId);
        let isValid = true;
        data.filter((x) => x.inEdit !== undefined && x.inEdit !== false).map(
            (item) => {
                const { rule_name, rowId } = item;

                let errorObj = {
                    rule_name: "",
                };

                if (rule_name.trim() === "") {
                    errorObj.rule_name = MessageSchema.ruleNameRequired;
                } else {
                    errorObj.rule_name = "";
                }

                let obj = {};

                for (const [key, value] of Object.entries(errorObj)) {
                    obj[`${key + rowId}`] = `${value}`;
                }

                setErrorObj(obj);

                for (const [, value] of Object.entries(obj)) {
                    if (value !== "") {
                        isValid = false;
                    }
                }

                return 0;
            }
        );

        if (
            isNullOrEmptyUndefined(
                resultSettingReduxData.resultSettingData.result_name
            )
        ) {
            isValid = false;
            dispatch(setUpdateRule({ isUpdateRuleClick: true }));
        }

        if (
            isNullOrEmptyUndefined(
                resultSettingReduxData?.resultSettingData?.rate_table_name
            ) &&
            item.rule_type_name == "incentive" &&
            resultSettingReduxData?.resultSettingData?.in_rate_type_mstr_key !==
            1
        ) {
            isValid = false;
            dispatch(setUpdateRule({ isUpdateRuleClick: true }));
        }
        let valueFx = isNullOrEmptyUndefined(
            resultSettingReduxData.resultSettingData.value_fx
        );
        let value = isNullOrEmptyUndefinedOrZero(
            resultSettingReduxData.resultSettingData.value
        );

        let valueData = valueFx ? (value ? true : false) : false;
        if (valueData && item.rule_type_name !== "incentive") {
            isValid = false;
            dispatch(setUpdateRule({ isUpdateRuleClick: true }));
        }
        return isValid;
    };

    // const onRefreshClick = async () => {
    //   setErrorObj({});
    //   setAPICallingState(true);
    //   setFilterData({
    //     ...filterData,
    //     in_filter: getInFilter(),
    //     in_sort_number: getInSortNumber(),
    //     in_sort_order: getInSortOrder(),
    //     in_page_row_offset: pageData.skip,
    //     in_page_rows: pageData.take,
    //     isAdd: false,
    //   });
    //   setAPICallingState(false);
    // };

    const onRefreshClick = (isReset = false, defaultKey = null) => {
        setAPICallingState(true);
        const dataVal = {
            ...apiCallData,
            in_filter: getInFilter(),
            in_sort_number: getInSortNumber(),
            in_sort_order: getInSortOrder(),
            in_page_row_offset: pageData.skip,
            in_page_rows: pageData.take,
        };
        getGridData(dataVal, defaultKey, isReset);
    };

    const updateCompRuleData = async (ruleFilterText, formulaFilterText) => {
        let item = data.find((x) => x.primary_key === selectedRowId);
        setLoadingState({
            ...loadingState,
            isUpdateCompRule: true,
        });

        let obj = {};
        obj.in_user_id = cookiesData.out_user_id;
        obj.in_tenant_id = cookiesData.out_tenant_id;
        obj.in_rule_config_key = item.rule_config_key;
        obj.in_date_version = restProps.effVersionDataItem.dateVersion
            ? restProps.effVersionDataItem.dateVersion
            : item.date_version;
        obj.in_rule_name = item.rule_name;
        obj.in_rule_desc = item.rule_desc;
        obj.in_rule_type_mstr_key = item.rule_type_mstr_key;
        obj.in_period_mstr_key = restProps.effVersionDataItem.periodMstrKey
            ? restProps.effVersionDataItem.periodMstrKey
            : item.period_mstr_key;
        obj.in_period_type_mstr_key =
            executionReduxData?.executionData?.period_type_mstr_key || 0;
        obj.in_rule_filter_text = ruleFilterText;
        obj.in_formula_filter_text = formulaFilterText;

        obj.in_rule_filter_desc =
            formFields.description ||
            prorateReduxData?.prorateData.rule_filter_desc;
        obj.in_result_name_mstr_key =
            resultSettingReduxData?.resultSettingData?.result_name_key || 0;

        if (document?.getElementById("value_fx_cb")?.checked) {
            obj.in_value_formula_mstr_key = 0;
            obj.in_value = resultSettingReduxData?.resultSettingData?.value === 0 ? null : resultSettingReduxData?.resultSettingData?.value;
        } else {
            obj.in_value_formula_mstr_key =
                resultSettingReduxData.resultSettingData.formula_mstr_key || 0;
            obj.in_value = null;
        }
        obj.in_value_unittype =
            resultSettingReduxData?.resultSettingData?.value_unit_type || "";
        obj.in_run_order = item.run_order;
        if (item.rule_type_name !== "creditallocation") {
            obj.in_generate_zero_result =
                executionReduxData?.executionData?.generate_zero_result || 0;
            if (
                item.rule_type_name !== "directrule" ||
                item.rule_type_name !== "indirectrule"
            ) {
                obj.in_generate_quarter_total =
                    executionReduxData?.executionData?.generate_quarter_total ||
                    0;
                obj.in_generate_year_total =
                    executionReduxData?.executionData?.generate_year_total || 0;
                obj.in_generate_qtd_value =
                    executionReduxData?.executionData?.generate_qtd_value || 0;
                obj.in_generate_ytd_value =
                    executionReduxData?.executionData?.generate_ytd_value || 0;
            }
            obj.in_prorate_period_type_mstr_key = prorateReduxData?.prorateData
                ?.in_prorate_flag
                ? prorateReduxData?.prorateData?.in_prorate_period_type_mstr_key
                : 0;
            obj.in_prorate_udf_data_obj_key = prorateReduxData?.prorateData
                ?.in_prorate_flag
                ? prorateReduxData?.prorateData?.in_prorate_udf_data_obj_key
                : 0;
            obj.in_prorate_flag =
                prorateReduxData?.prorateData?.in_prorate_flag || 0;
        }
        const module = item.rule_type_name.replace(/\s/g, "").toLowerCase();
        switch (module) {
            case "incentive":
                obj.in_rate_table_mstr_key =
                    resultSettingReduxData?.resultSettingData?.rate_table_key;
                obj.in_rate_type_mstr_key =
                    resultSettingReduxData?.resultSettingData
                        ?.in_rate_type_mstr_key || 0;

                obj.in_goal_mstr_key = null;
                obj.in_is_kpi_wt_applied =
                    resultSettingReduxData?.resultSettingData?.is_kpi_wt_applied;
                break;
            case "payment":
                obj.in_is_pass_thru =
                    executionReduxData?.executionData?.is_pass_thru || 0;

                obj.in_gl_mstr_key =
                    executionReduxData?.executionData?.gl_mstr_key || 0;
                break;
            case "measurement":
                obj.in_is_rollable =
                    executionReduxData?.executionData?.is_rollable || 0;

                break;
            case "directcredit":
                obj.in_is_rollable =
                    executionReduxData?.executionData?.is_rollable || 0;
                obj.in_is_indirect = 0;

                obj.in_clear_event_flag =
                    prorateReduxData?.prorateData.is_clear_event_flag || 0;
                obj.in_hierarchy_mstr_key = item.in_hierarchy_mstr_key || 0;
                obj.in_udf_data_object_key =
                    prorateReduxData?.prorateData.in_udf_data_obj_key || 0;
                break;
            case "indirectcredit":
                obj.in_is_rollable =
                    executionReduxData?.executionData?.is_rollable || 0;
                obj.in_is_indirect = 0;

                obj.in_clear_event_flag =
                    prorateReduxData?.prorateData.is_clear_event_flag || 0;
                obj.in_hierarchy_mstr_key = item.in_hierarchy_mstr_key || 0;
                obj.in_udf_data_object_key =
                    prorateReduxData?.prorateData.in_udf_data_obj_key || 0;
                break;
            default:
                break;
        }

        const updateRuleBodyData = { ...obj };
        if (updateRuleBodyData) {
            let response = await Action.UpdateDataAsync(
                updateRuleBodyData,
                module
            );

            if (response) {
                dispatch(setUpdateRule({ isUpdateRuleClick: false }));
                //TODO : UNSAVED CHANGES
                // dispatch(
                //   setUnSavedChangesPopup({ ...unsavedPopup, isUnSavedChange: false })
                // );
                dispatch(setResetState());
                data.map((item) => {
                    item.inEdit = false;
                    return 0;
                });
                setRuleEdit(false);
                if (isRuleEdit) {
                    onRefreshClick();
                }
                setLoadingState({
                    ...loadingState,
                    isUpdateCompRule: false,
                });
                changeCancelBtnState("disable", "grid-incell-cancel-comp-rule");
                changeUpdateBtnState("disable", "grid-incell-update-comp-rule");
                setUpdateCompRule(true);
            } else {
                setLoadingState({
                    ...loadingState,
                    isUpdateCompRule: false,
                });
            }
        } else {
            cancelRuleGridChanges();
        }
    };

    const cancelRuleGridChanges = () => {
        dispatch(
            removeMessage({
                message: "",
                type: "Error",
                show: false,
            })
        );
        originalData.map((item) => {
            item.inEdit = false;
            item.selected = false;
            return 0;
        });
        restProps.setDataList(originalData);
        setRuleEdit(false);
        setData(originalData);
        setErrorObj({});
        setUpdateCompRule(true);
    };

    const updateCompRuleGridChanges = async () => {
        let isValid = await handleValidation();
        let isValidResult = isValid;
        let ruleFilter = {
            error: false,
            errorMsg: "",
            value: null,
        };
        let formulaFilter = {
            error: false,
            errorMsg: "",
            value: null,
        };
        if (isRuleFilterEnabled) {
            const ruleFilterItems =
                prorateReduxData.prorateData.ruleFilterItems;
            const logicalValidation = validateAndOrOperator(
                prorateReduxData.prorateData.ruleFilterItems
            );
            isValid = !logicalValidation.error;
            if (isValid && isRuleFilterEnabled) {
                // if(!resultSettingReduxData?.resultSettingData?.value_fx && !resultSettingReduxData?.resultSettingData?.value){
                //   return
                // }
                // validate rule filter items.
                setLoadingState({
                    ...loadingState,
                    isUpdateCompRule: true,
                });

                if (isValid) {
                    if (isValid) {
                        const res = validateRuleFilters(ruleFilterItems);
                        isValid = res.found;

                        if (isValid) {
                            ruleFilter = prepareRuleFilterText(ruleFilterItems);
                            formulaFilter =
                                prepareFormulaFilterText(ruleFilterItems);

                            if (ruleFilter.error) {
                                isValid = false;
                                setConfirmationPopup({
                                    ...confirmationPopup,
                                    isVisible: true,
                                    // type: "DELETE_CONFIRM",
                                    title: "ERROR",
                                    desc: ruleFilter.errorMsg,
                                    actionBtn:
                                        CANCEL_BTN_ACTIONS(onCancelConfirm),
                                    width: "550",
                                    action: "Delete",
                                });
                            } else if (formulaFilter.error) {
                                isValid = false;
                                setConfirmationPopup({
                                    ...confirmationPopup,
                                    isVisible: true,
                                    // type: "DELETE_CONFIRM",
                                    title: "ERROR",
                                    desc: formulaFilter.errorMsg,
                                    actionBtn:
                                        CANCEL_BTN_ACTIONS(onCancelConfirm),
                                    width: "550",
                                    action: "Delete",
                                });
                            }
                        } else {
                            dispatch(setRuleFilterItemDataAction(res.items));
                        }
                    }
                } else {
                    setConfirmationPopup({
                        ...confirmationPopup,
                        isVisible: true,
                        title: "ERROR",
                        desc: logicalValidation.errorMsg,
                        actionBtn: CANCEL_BTN_ACTIONS(onCancelConfirm),
                        width: "550",
                        action: "Delete",
                    });
                }
            }
        }

        if (isValid && isValidResult) {
            // dispatch(setUpdateRule({ isUpdateRuleClick: true }));
            updateCompRuleData(ruleFilter?.value, formulaFilter?.value);
        } else {
            setLoadingState({
                ...loadingState,
                isUpdateCompRule: false,
            });
            changeCancelBtnState("enable", "grid-incell-cancel-comp-rule");
            changeUpdateBtnState("enable", "grid-incell-update-comp-rule");
        }
    };

    if (document.getElementById("grid-incell-update-comp-rule")) {
        document.getElementById("grid-incell-update-comp-rule").onclick =
            function (e) {
                updateCompRuleGridChanges();
            };
    }

    const itemChange = React.useCallback(
        (event) => {
            const field = event.field || "";
            const newData = data.map((item) =>
                item[DATA_ITEM_KEY] === event.dataItem[DATA_ITEM_KEY]
                    ? { ...item, [field]: event.value, inIteamEdit: true }
                    : item
            );
            let editItem = event.dataItem;
            editItem[field] = event.value;
            setData(newData);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [data]
    );

    const handleColumnMenu = async (
        columnMenuData,
        isFilter,
        isSort,
        filterObj
    ) => {
        if (isSort) {
            setSortField(columnMenuData);
        } else {
            setFilterField(filterObj);
        }
        let index = 1;
        let filterSkip = pageData.skip;
        let filterTake = pageData.take;

        if (isFilter) {
            filterSkip = 0;
            filterTake = pageSize.recordTake;

            //reset pager when filter applies
            setPage({ skip: filterSkip, take: filterTake });
        }
        if (data.length > 0 && columnMenuData[0] !== undefined) {
            let obj = Object.keys(data[0]);
            index = obj.findIndex((x) => x === columnMenuData[0].field) + 1;
        }

        const filterString = isFilter
            ? getfilterDataString(columnMenuData, gridColumns)
            : filterData.data;

        isFilter = false;
        if (filterString !== "") {
            isFilter = true;
        }

        let sortDir =
            filterData.isSort && columnMenuData.length
                ? filterData.sort_order
                : DEFAULT_SORT.order;
        let sortNumber =
            filterData.isSort && columnMenuData.length
                ? filterData.in_sort_number
                : DEFAULT_SORT.number;

        isSort = !columnMenuData.length ? false : filterData.isSort;

        if (
            columnMenuData[0] !== undefined &&
            columnMenuData[0].dir !== undefined
        ) {
            isSort = true;
            sortDir = columnMenuData[0].dir.toUpperCase();
            sortNumber = index;
        }
        setFilterData({
            ...filterData,
            isFilter: isFilter,
            isSort: isSort,
            data: filterString,
            in_sort_number: sortNumber,
            sort_order: sortDir,
            isAdd: false,
        });

        setAPICallingState(true);
    };

    const useCol = (columns) => {
        return columns.map((column, idx) => {
            return column.show && column.title !== "" ? (
                <Column
                    {...columnMenuProps(column)}
                    key={idx}
                    cell={column.field === "action" ? ActionCell : NameCell}
                    sortable={column.field === "action" ? false : true}
                    width={column.field === "action" ? "90px" : "180px"}
                    reorderable={column.field === "action" ? false : true}
                    filterable={column.field === "action" ? false : true}
                />
            ) : (
                false
            );
        });
    };

    const ActionCell = React.useCallback(
        (props) => {
            const { ariaColumnIndex, columnIndex, dataItem, render } = props;
            const defaultRendering = (
                <td
                    aria-colindex={ariaColumnIndex}
                    data-grid-col-index={columnIndex}
                    className={
                        pageProps.writeAccess === 0
                            ? "text-center disable-class"
                            : "text-center"
                    }
                >
                    <span
                        title="Linked KPI"
                        style={{ marginLeft: 5, marginRight: 5 }}
                        className={
                            pageProps.writeAccess === 0
                                ? "k-icon k-font-icon k-i-hyperlink-open pointer-class disable-class"
                                : "k-icon k-font-icon k-i-hyperlink-open pointer-class"
                        }
                        onPointerDown={() => {
                            if (pageProps.writeAccess === 0) {
                                return false;
                            } else {
                                dispatch(
                                    setLinkedRules({
                                        key: "kpi",
                                        data: {
                                            rule_type_mstr_key:
                                                dataItem.rule_type_mstr_key,
                                            rule_config_key:
                                                dataItem.rule_config_key,
                                            rule_config_table_name:
                                                dataItem.rule_config_table_name,
                                            title: `Assigned to Rule Name = ${dataItem.rule_name}`,
                                            type: "kpi",
                                        },
                                    })
                                );
                                history.push("/kpi");
                            }
                        }}
                    ></span>
                </td>
            );

            return render?.call(undefined, defaultRendering, props);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [pageProps]
    );

    const NameCell = React.useCallback(
        (props) => {
            const { ariaColumnIndex, columnIndex, dataItem, field, render } =
                props;
            let isInEdit =
                field === "rule_name" ||
                    field === "run_order" ||
                    field === "rule_desc"
                    ? field === dataItem.inEdit
                    : false;

            let value = props.field + dataItem.rowId;
            const handleChange = (e, props, value) => {
                if (props.onChange) {
                    //TODO : UNSAVED CHANGES
                    // dispatch(
                    //   setUnSavedChangesPopup({ ...unsavedPopup, isUnSavedChange: true })
                    // );
                    props.onChange({
                        // dataIndex: 0,
                        dataItem: props.dataItem,
                        field: props.field,
                        syntheticEvent: e.syntheticEvent,
                        value: e.target.value,
                    });
                }
            };

            const defaultRendering = (
                <td
                    aria-colindex={ariaColumnIndex}
                    data-grid-col-index={columnIndex}
                    className="kpi-field"
                >
                    {isInEdit ? (
                        <Input
                            type={
                                props.field === "run_order" ? "number" : "text"
                            }
                            value={dataItem[props.field]}
                            onChange={(e) => {
                                handleChange(e, props, value);
                            }}
                            name={`${props.field}`}
                            autoComplete="off"
                        />
                    ) : props.field === "rule_type_mstr_key" ? (
                        props.dataItem["rule_type_name"]
                    ) : (
                        <span title={props.dataItem[props.field || ""]}>
                            {props.dataItem[props.field || ""]}
                        </span>
                    )}
                    {Object.keys(errorObj).length > 0 ? (
                        <div role="alert" className="k-form-error k-text-start">
                            {Object.keys(errorObj).length > 0
                                ? errorObj[value]
                                : ""}
                        </div>
                    ) : (
                        false
                    )}
                </td>
            );
            return render?.call(undefined, defaultRendering, props);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [errorObj]
    );

    let GroupColumn = useCol(gridColumns);

    const onClearFilter = () => {
        setFilterField({ logic: "and", filters: [] });
        setAPICallingState(true);

        //set filter to false
        setFilterData({
            ...filterData,
            data: "",
            isFilter: false,
            in_filter: null,
            in_sort_number: getInSortNumber(),
            in_sort_order: getInSortOrder(),
            in_page_row_offset: pageData.skip,
            in_page_rows: pageData.take,
            isAdd: false,
        });
    };

    const customRowRender = (tr, props) => (
        <RowRender
            originalProps={props}
            tr={tr}
            editField={editField}
            exitEdit={exitEdit}
        />
    );

    const customCellRender = (td, props) => {
        return (
            <CellRender
                navigatable={true}
                originalProps={props}
                td={td}
                enterEdit={enterEdit}
                editField={editField}
                updateActBtn="grid-incell-update-comp-rule"
                cancelActBtn="grid-incell-cancel-comp-rule"
            />
        );
    };

    const cancelCompRuleGridChanges = () => {
        dispatch(
            removeMessage({
                message: "",
                type: "Error",
                show: false,
            })
        );
        originalData.map((item) => {
            item.inEdit = false;
            item.selected = false;
            return 0;
        });
        setRuleEdit(false);
        setData(originalData);
        setErrorObj({});
        restProps.setDataList(originalData);
    };

    if (document.getElementById("grid-incell-cancel-comp-rule")) {
        document.getElementById("grid-incell-cancel-comp-rule").onclick =
            function (e) {
                // TODO: UNSAVED CHANGES
                // dispatch(setUnSavedChangesPopup({ isUnSavedChange: false }));
                if (e.isTrusted) {
                    changeCancelBtnState(
                        "disable",
                        "grid-incell-cancel-comp-rule"
                    );
                    changeUpdateBtnState(
                        "disable",
                        "grid-incell-update-comp-rule"
                    );
                    isRuleEdit === true && cancelCompRuleGridChanges();
                    setUpdateCompRule(true);
                } else {
                    const inputCell = document.activeElement.closest("td");
                    if (!inputCell) {
                        return false;
                    }
                    const cellClass = inputCell.classList[0];

                    switch (cellClass) {
                        case "kpi-field":
                            cancelCompRuleGridChanges();
                            break;
                        default:
                            return false;
                    }
                }
            };
    }

    const getEffectiveVersionsList = async (reqData) => {
        let res = await Action.getEffectiveVersions(reqData);
        return res;
    };

    const handleCopySelectedRow = async () => {
        setLoadState({
            ...loadState,
            isCopyRowsLoading: true,
        });
        setIsCopyLoading(true);
        let successCount = 0;

        let newSelectedStateKeys = Object.keys(selectedState);

        let selectedKeyArr = newSelectedStateKeys.filter(function (x) {
            return selectedState[x] === true;
        });
        for (const cItem of Object.values(selectedKeyArr)) {
            let last_insert_id = 0;
            const newData = data.find((item) => item.primary_key === cItem);

            if (newData) {
                const effReqData = {
                    in_tenant_id: cookiesData.out_tenant_id,
                    in_mstr_key: newData.rule_config_key,
                    in_rule_type_mstr_key: newData.rule_type_mstr_key,
                    in_sort_number: 5,
                    in_sort_order: "ASC",
                };

                const versionsList = await getEffectiveVersionsList(effReqData);
                const rule_name =
                    "Copy of " + newData.rule_name + "-" + microTime();
                for (const [i, item] of versionsList.entries()) {
                    if (versionsList.length > 0) {
                        const reqData = {
                            in_user_id: cookiesData.out_user_id,
                            in_tenant_id: cookiesData.out_tenant_id,
                            in_rule_config_key: item['a.rule_config_key'],
                            in_date_version: item["a.date_version"],
                            in_rule_name: rule_name,
                            in_rule_desc: newData.rule_desc,
                            in_rule_type_mstr_key: newData.rule_type_mstr_key,
                            in_period_mstr_key: item["a.period_mstr_key"],
                            in_period_type_mstr_key:
                                newData.period_type_mstr_key,
                            in_flag: "copyMultipleRule",
                            in_current_version: item.is_current,
                            in_insert_id: last_insert_id,
                            in_version: i + 1,
                        };

                        const response = await Action.copyMultipleDataAsync(
                            reqData
                        );
                        if (response) {
                            if (last_insert_id === 0) {
                                last_insert_id =
                                    response.out_parameters.out_last_insert_id;
                            }

                        } else {
                            setAPICallingState(false);
                        }
                    }
                }
            }
            successCount += 1;
            // When all selected items have been successfully processed
            if (successCount === Object.values(selectedKeyArr).length) {
                setLoadState({
                    ...loadState,
                    isCopyRowsLoading: false,
                });
                setIsCopyLoading(false);
                setSelectedState({});
                setFilterData({
                    ...filterData,
                    in_filter: getInFilter(),
                    in_sort_number: getInSortNumber(),
                    in_sort_order: getInSortOrder(),
                    in_page_row_offset: pageData.skip,
                    in_page_rows: pageData.take,
                    in_load_type: "grid-reload",
                    isAdd: true,
                });
                // const dataVal = {
                //     ...apiCallData,
                //     in_filter: getInFilter(),
                //     in_sort_number: getInSortNumber(),
                //     in_sort_order: getInSortOrder(),
                //     in_page_row_offset: pageData.skip,
                //     in_page_rows: pageData.take,
                //     in_load_type: "grid-reload",
                // };
                // getGridData(dataVal);
                onRefreshClick()
                changeCancelBtnState(
                    "disable",
                    "grid-incell-cancel-comp-rule"
                );
                changeUpdateBtnState(
                    "disable",
                    "grid-incell-update-comp-rule"
                );
            }
        }
    };

    const handlePageChange = async (event) => {
        const pageData = event.page;

        setAPICallingState(true);

        setPage({ skip: pageData.skip, take: pageData.take });

        setFilterData({
            ...filterData,
            in_filter: getInFilter(),
            in_sort_number: getInSortNumber(),
            in_sort_order: getInSortOrder(),
            in_page_row_offset: pageData.skip,
            in_page_rows: pageData.take,
            isAdd: false,
        });
    };

    const prepareColumnString = (colData) => {
        return executeGetColumnOrderInfo(colData, "rule");
    };

    const columnReorderData = async (columnData) => {
        const str = prepareColumnString(
            columnData.filter((x) => x.field !== "action")
        );
        setLoadingState({ ...loadingState, updateColumn: true });
        let reqData = {
            in_db_name: "procare_system",
            in_tenant_id: cookiesData.out_tenant_id,
            in_filter_logical_cond: "N",
            in_filter_label_cond: "N",
            in_column_info: str,
        };
        let res = await updateColumnReorderAsync(reqData, dispatch, history);
        if (res) {
            setColumnsData([]);
            let colDataList = JSON.parse(JSON.stringify(columnData));
            colDataList.forEach((item) => {
                item.hidden = false;
            });
            let colData = colDataList.sort(function (a, b) {
                return a.ariaColumnIndex - b.ariaColumnIndex;
            });
            setColumnsData(colData);
            setLoadingState({ ...loadingState, updateColumn: false });
        } else {
            setLoadingState({ ...loadingState, updateColumn: false });
        }
    };

    return (
        <>
            {isAPICall && <LoadingPanel />}
            {loadState.isCopyRowsLoading ? <LoadingPanel /> : false}
            {loadingState.isUpdateCompRule ? <LoadingPanel /> : false}
            {columns.length > 0 ? (
                <DataTable
                    isAPICalling={isAPICalling}
                    initialLoading={initialLoading}
                    data={data.map((item) => ({
                        ...item,
                        [SELECTED_FIELD]: selectedState[idGetter(item)],
                    }))}
                    resetSort={resetSort}
                    pageData={pageData}
                    id="compensation_rule_grid"
                    editField={editField}
                    dataCount={dataCount}
                    customColumn={GroupColumn}
                    columns={gridColumns}
                    dataItemKey={DATA_ITEM_KEY}
                    width={"auto"}
                    module={"compensation_rule"}
                    defaultPageSize={defaultPageSize}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    handlePageChange={handlePageChange}
                    rowRender={customRowRender}
                    cellRender={customCellRender}
                    itemChange={itemChange}
                    handleColumnMenu={handleColumnMenu}
                    onClearFilter={onClearFilter}
                    onSelectionChange={onSelectionChange}
                    refreshClick={onRefreshClick}
                    reorderable={pageProps.writeAccess === 0 ? false : true}
                    columnReorderData={columnReorderData}
                    initialFilter={filterField}
                    initialSort={sortField}
                    noDataFound={noDataFound}
                    gridHeight={"73vh"}
                />
            ) : (
                <>
                    <LoadingPanel />
                </>
            )}
            <DialogComponent
                title={confirmationPopup?.title}
                onCancelConfirm={onCancelConfirm}
                width={"450"}
                height={"auto"}
                className={"Dialog-Delete"}
                desc={confirmationPopup?.desc}
                visible={confirmationPopup?.isVisible}
                actionBtn={confirmationPopup?.actionBtn}
                titleClassName={
                    confirmationPopup?.action === "Delete"
                        ? "delete-confirm"
                        : "unSave-confirm"
                }
            />
        </>
    );
};

export const GridButton = (restProps) => {
    return (
        <>
            <Button
                type="submit"
                className="cancelbtn"
                id="grid-incell-cancel-comp-rule"
            >
                Cancel
            </Button>
            <Button
                type="submit"
                className="primarybtn"
                id="grid-incell-update-comp-rule"
            >
                Save
            </Button>
        </>
    );
};
