/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Button } from "@progress/kendo-react-buttons";
import { FieldWrapper, Form, FormElement } from "@progress/kendo-react-form";
import { Input, Checkbox, TextArea } from "@progress/kendo-react-inputs";
import { Error, Label } from "@progress/kendo-react-labels";
import { Splitter } from "@progress/kendo-react-layout";
import { HeaderPage } from "../base/headerPage";
import CustomDropdown from "../../components/customDropdown";
import Popup from "../../components/customPopup";
import { getUserCredential } from "../../lib/cookies";
import MessageSchema from "../../utils/messageSchema";
import { changeCancelBtnState, changeUpdateBtnState } from "../../utils/utils";
import { GridButton, JobData } from "./jobConfigurationGrid";
import useServiceApi from "./service";
import DataRows from "./dataRows/dataRows";
import { useDocumentClickEventListener } from "../../lib/hooks/custom-common-hooks";
import { Loader } from "@progress/kendo-react-indicators";

const initialFormFields = {
  job_name: "",
  description: "",
  job_parameters: "",
  attach_documents: false,
  position: "",
  stagehook: false,
  publish_postion: false,
  comp_plan: false,
  email_body: "",
  email_subject: "",
  cc_email: "",
};

const Job = (pageProps) => {
  const [list, Action] = useServiceApi();
  const cookiesData = getUserCredential();
  const addRef = React.useRef(null);

  const [isAddNewConfig, setNewConfig] = React.useState(false);
  const [isShow, setShow] = React.useState({
    deleteRows: false,
  });
  const [exportDataClick, setExportDataClick] = React.useState(false);
  const [isAPICall, setAPICall] = React.useState(false);
  const [formFields, setFormFields] = React.useState({
    ...initialFormFields,
  });
  const [errorObj, setErrorObj] = React.useState({});
  const [isSaveClick, setSaveClick] = React.useState(false);
  const [selectedRowId, setSelectedRowId] = React.useState("");
  const [dataList, setDataList] = React.useState([]);
  const [dataItem, setDataItem] = React.useState({});
  const [columnsData, setColumnsData] = React.useState([]);
  const [isCheckColumn, setColumnCheck] = React.useState(false);

  const [panes, setPanes] = React.useState([
    {
      size: "35%",
      collapsible: true,
      scrollable: false,
    },
    {
      scrollable: false,
      min: "300px",
    },
  ]);
  let selectedTab = 0;
  let screenLock = false;
  const [isUpdateClick, setisUpdateClick] = React.useState(false);
  const [isCancelClick, setisCancelClick] = React.useState(false);
  const [isJobEdit, setisJobEdit] = React.useState(false);
  const [isEngineEdit, setisEngineEdit] = React.useState(false);
  const [isDisplay, setIsDisplay] = React.useState(false);
  const [isDisplayPostion, setIsDisplayPostion] = React.useState(false);
  const [isStageHookDisplay, setIsStageHookDisplay] = React.useState(false);
  const [isPostionDisplay, setIsPostionDisplay] = React.useState(true);
  const [isCollapsed, setIsCollapsed] = React.useState(false);
  const [isCompPlan, setIsCompPlan] = React.useState(true);
  const [isAddConfigLoading, setisAddConfigLoading] = React.useState(true);
  const [isEmailFieldsDisplay, setisEmailFieldsDisplay] = React.useState(false);

  let popupRef = React.useRef();
  useDocumentClickEventListener(isAddNewConfig, setNewConfig, popupRef);

  const {
    loadingState,
    originJobType,
    jobTypeFields,
    setJobTypeFields,
    originCompPlan,
    compPlanFields,
    setcompPlanFields,
    stagehookFields,
    setStagehookFields,
    originStageHook,
  } = list;
  React.useEffect(() => {
    if (loadingState.isSaveData) {
      setFormFields({ ...initialFormFields });
      setAPICall(true);
      setNewConfig(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingState.isSaveData]);

  React.useEffect(() => {
    const selectedData = dataList.filter(
      (data) => data.job_mstr_key == selectedRowId
    );
    let newSelectedData = {};

    if (selectedData[0]) {
      newSelectedData["mstr_key"] = selectedData[0].job_mstr_key;
      newSelectedData["comp_plan_keys"] = selectedData[0].comp_plan_keys;
      newSelectedData["job_type_name"] = selectedData[0].job_type_name;
    }
    setDataItem(newSelectedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowId]);

  //disable in-cell update and cancel button
  React.useEffect(() => {
    if (isEngineEdit === false && isJobEdit === false) {
      changeCancelBtnState();
      changeUpdateBtnState();
      isCancelClick === true && setisCancelClick(false);
      isUpdateClick === true && setisUpdateClick(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEngineEdit, isJobEdit, dataItem]);

  const onPaneChange = (event) => {
    const eventTarget = event.nativeEvent.target;
    if (eventTarget === undefined) {
      setPanes(event.newState);
      return;
    }

    if (eventTarget.classList.contains("k-collapse-prev")) {
      if (isCollapsed === false) {
        setIsCollapsed(true);
        event.newState[0].collapsed = false;
        event.newState[0].size = "0%";
        eventTarget.classList.remove("k-i-arrow-60-left");
        eventTarget.classList.add("k-i-arrow-60-right");
      } else {
        setIsCollapsed(false);
        event.newState[0].collapsed = false;
        event.newState[0].size = "35%";
        eventTarget.classList.remove("k-i-arrow-60-right");
        eventTarget.classList.add("k-i-arrow-60-left");
      }
      setPanes(event.newState);
    }
  };
  const addNewConfig = async (e) => {
    setNewConfig(!isAddNewConfig);
    setIsCompPlan(true);
    setIsPostionDisplay(true);
    setIsDisplay(false);
    setIsDisplayPostion(false);
    setIsStageHookDisplay(false);
    setisEmailFieldsDisplay(false);
    setFormFields(initialFormFields);
    await Action.getJobTypesData();
    await Action.getCompPlanData();
    await Action.getStagehookTypesData();
    e.preventDefault();
  };

  const ShowError = React.useCallback(
    (props) => {
      return <Error>{errorObj[props.name]}</Error>;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errorObj, isSaveClick]
  );

  const handleValidation = () => {
    let isValid = true;

    if (jobTypeFields.jobTypeValue.text === "Email Process") {
      if (formFields.email_body.trim() === "") {
        isValid = false;
        errorObj.email_body = MessageSchema.jobBodyRequired;
      }
      if (formFields.email_subject.trim() === "") {
        isValid = false;
        errorObj.email_subject = MessageSchema.jobSubjectRequired;
      }
      if (!formFields.stagehook) {
        isValid = false;
        errorObj.publish_postion = MessageSchema.jobParmsRequired;
      }
      // if (stagehookFields.in_stagehook_mstr_key === "") {
      //   isValid = false;
      //   errorObj.stagehookValue = MessageSchema.jobStageHookRequired;
      // }
      if (stagehookFields.stagehookData.length === 0) {
        isValid = false;
        errorObj.stagehookValue = MessageSchema.jobStageHookRequired;
      }
    }
    if (formFields.job_name.trim() === "") {
      isValid = false;
      errorObj.job_name = MessageSchema.jobNameRequired;
    }

    setErrorObj((preVState) => ({ ...preVState, errorObj }));

    return isValid;
  };

  function findSpecificObjects(array, jobTypeKey, compPlanKey) {
    return array.filter(
      (obj) =>
        obj.job_type_mstr_key == jobTypeKey && obj.comp_plan_keys == compPlanKey
    );
  }
  const handleSubmit = () => {
    let valid = handleValidation();
    if (valid) {
      setErrorObj({});
      const bodyData = {
        in_user_id: cookiesData.out_user_id,
        in_tenant_id: cookiesData.out_tenant_id,
        in_job_name: formFields.job_name,
        in_job_desc: formFields.description,
        in_job_type_mstr_key: jobTypeFields.jobTypeValue.id,
        in_comp_plan_mstr_key:
          jobTypeFields.jobTypeValue.code === "stage_hook" ||
          jobTypeFields.jobTypeValue.code === "email_process"
            ? null
            : compPlanFields.compPlanValue.id,
        in_stagehook_job_mstr_key:
          jobTypeFields.jobTypeValue.code === "email_process"
            ? stagehookFields.stagehookValue.id
            : null,
        in_is_attach_provider_doc: formFields.attach_documents === true ? 1 : 0,
        in_notification_email: formFields.cc_email,
        in_email_subject: formFields.email_subject,
        in_email_body: formFields.email_body,
        in_plan_param:
          jobTypeFields.jobTypeValue.code === "stage_hook" &&
          formFields.comp_plan === true
            ? 1
            : 0,
        in_position_param:
          (jobTypeFields.jobTypeValue.code !== "email_process" &&
            formFields.position === true) ||
          jobTypeFields.jobTypeValue.code === "comp_plan"
            ? 1
            : 0,
        in_stagehook_param:
          jobTypeFields.jobTypeValue.code === "email_process" &&
          formFields.stagehook === true
            ? 1
            : 0,
        in_published_position:
          jobTypeFields.jobTypeValue.code === "email_process" &&
          formFields.publish_postion === true
            ? 1
            : formFields.position === true &&
              formFields.publish_postion === true
            ? 1
            : 0,
      };

      // const foundObjects = findSpecificObjects(
      //   dataList,
      //   2,
      //   bodyData.in_comp_plan_mstr_key
      // );
      // let updatedData = { ...bodyData };

      // if (jobTypeFields.jobTypeValue.code === "comp_plan") {
      //   updatedData.report_publish_extis = foundObjects.length === 0;
      // }

      Action.addDataAsync(bodyData);
    } else {
      setSaveClick(true);
    }
  };

  const onCancelClick = () => {
    setFormFields(initialFormFields);
    setErrorObj({});
    setNewConfig(false);
    setSaveClick(false);
  };

  const handleFieldChange = (e) => {
    const name = e.name ? e.name : e.target.name;
    if (e.value !== null) {
      if (e.value.text === "Regular") {
        setFormFields({
          ...formFields,
          [name]: e.value,
        });
        setErrorObj({ ...errorObj, [name]: "" });
      } else {
        if (
          name === "position" &&
          jobTypeFields.jobTypeValue.text === "Stage Hook"
        ) {
          if (e.value === true) {
            setIsDisplayPostion(true);
          } else {
            setIsDisplayPostion(false);
          }
        }
        setFormFields({ ...formFields, [name]: e.value });
        setErrorObj({ ...errorObj, [name]: "" });
      }
    }
  };
  const AddNewForm = () => {
    return (
      <Form
        onSubmit={handleSubmit}
        render={() => (
          <FormElement className="emailForm jobconfigform" horizontal={true}>
            <FieldWrapper className="full-width">
              <div className={"k-form-field-wrap-popup full-width"}>
                <Label>{"Job Name*:"}</Label>
                <Input
                  type={"text"}
                  name="job_name"
                  placeholder="Job Name"
                  value={formFields.job_name}
                  onChange={(e) => {
                    handleFieldChange(e);
                  }}
                  autoFocus={true}
                />
                <ShowError name={"job_name"}></ShowError>
              </div>
            </FieldWrapper>

            <FieldWrapper className="full-width">
              <div className={"k-form-field-wrap-popup full-width"}>
                <Label>{"Description:"}</Label>
                <TextArea
                  type={"text"}
                  name="description"
                  placeholder="Description"
                  rows={5}
                  value={formFields.description}
                  onChange={(e) => {
                    handleFieldChange(e);
                  }}
                />
                <ShowError name={"description"}></ShowError>
              </div>
            </FieldWrapper>

            <FieldWrapper>
              <div className={"k-form-field-wrap-popup"}>
                <Label>{"Job Type*: "}</Label>
                <CustomDropdown
                  name={"jobTypeValue"}
                  data={jobTypeFields.jobTypeData}
                  textField="text"
                  dataItemKey="id"
                  valueData={originJobType.find(
                    (c) => c.text === jobTypeFields.jobTypeValue.text
                  )}
                  onOpen={(dataV) => {
                    setJobTypeFields({
                      ...jobTypeFields,
                      jobTypeData: dataV,
                    });
                  }}
                  onChange={(e, name) => {
                    setJobTypeFields({
                      ...jobTypeFields,
                      [name]: e,
                    });
                    if (e.text === "Email Process") {
                      setisEmailFieldsDisplay(true);
                      setIsDisplay(true);
                      // setIsDisplayPostion(true);
                      setIsPostionDisplay(false);
                      setIsStageHookDisplay(false);
                      setIsCompPlan(false);
                      setFormFields({
                        ...formFields,
                        position: false,
                        publish_postion: false,
                      });
                    } else if (e.text === "Stage Hook") {
                      setIsDisplayPostion(false);
                      setIsDisplay(false);
                      setisEmailFieldsDisplay(false);
                      setIsStageHookDisplay(true);
                      setIsPostionDisplay(true);
                      setIsCompPlan(false);
                      setFormFields({
                        ...formFields,
                        email_body: "",
                        email_subject: "",
                        cc_email: "",
                        stagehook: false,
                        publish_postion: false,
                        position: false,
                        comp_plan: false,
                      });
                    } else {
                      setisEmailFieldsDisplay(false);
                      setIsCompPlan(true);
                      setIsDisplay(false);
                      setIsDisplayPostion(false);
                      setIsStageHookDisplay(false);
                      setIsPostionDisplay(true);
                      setFormFields({
                        ...formFields,
                        email_body: "",
                        email_subject: "",
                        cc_email: "",
                        stagehook: false,
                        publish_postion: false,
                        position: false,
                      });
                    }
                  }}
                  onFilterChange={(dataValue) => {
                    setJobTypeFields({
                      ...jobTypeFields,
                      jobTypeData: dataValue,
                    });
                  }}
                  filterable={true}
                  autoClose={false}
                  autoBind={true}
                  dataList={originJobType}
                  loading={jobTypeFields.isJobTypeDataLoading}
                />
              </div>
            </FieldWrapper>
            {isEmailFieldsDisplay ? (
              <FieldWrapper>
                <div className={"k-form-field-wrap-popup"}>
                  <Label>{"CC Email:"}</Label>
                  <Input
                    type={"text"}
                    name="cc_email"
                    placeholder="CC Email"
                    value={formFields.cc_email}
                    onChange={(e) => {
                      handleFieldChange(e);
                    }}
                  />
                </div>
              </FieldWrapper>
            ) : (
              ""
            )}
            <FieldWrapper>
              {isCompPlan ? (
                <div className={"k-form-field-wrap-popup"}>
                  <Label>{"Comp Plan*: "}</Label>
                  <CustomDropdown
                    name={"compPlanValue"}
                    data={compPlanFields.compPlanData}
                    textField="text"
                    dataItemKey="id"
                    valueData={originCompPlan.find(
                      (c) => c.text === compPlanFields.compPlanValue.text
                    )}
                    onOpen={(dataV) => {
                      setcompPlanFields({
                        ...compPlanFields,
                        compPlanData: dataV,
                      });
                    }}
                    onChange={(e, name) => {
                      setcompPlanFields({
                        ...compPlanFields,
                        [name]: e,
                      });
                      // Action.getPeriodsData(e.id, e);
                    }}
                    onFilterChange={(dataValue) => {
                      setcompPlanFields({
                        ...compPlanFields,
                        compPlanData: dataValue,
                      });
                    }}
                    filterable={true}
                    autoClose={false}
                    autoBind={true}
                    dataList={originCompPlan}
                    loading={compPlanFields.iscompPlanDataLoading}
                  />
                </div>
              ) : (
                ""
              )}
              {isEmailFieldsDisplay ? (
                <div className={"k-form-field-wrap-popup"}>
                  <Label>{"Email Subject*:"}</Label>
                  <Input
                    type={"text"}
                    name="email_subject"
                    placeholder="<provider_name> and <period_name> tags will be replaced with job execution values"
                    value={formFields.email_subject}
                    onChange={(e) => {
                      handleFieldChange(e);
                    }}
                  />
                  <ShowError name={"email_subject"}></ShowError>
                </div>
              ) : (
                ""
              )}
            </FieldWrapper>

            {isDisplay ? (
              <FieldWrapper>
                <div className={"k-form-field-wrap-popup"}>
                  <Label>{"Stage Hook*:"}</Label>
                  <CustomDropdown
                    name={"stagehookValue"}
                    data={stagehookFields.stagehookData}
                    textField="text"
                    dataItemKey="id"
                    valueData={originStageHook.find(
                      (c) => c.id === stagehookFields.stagehookValue.id
                    )}
                    onChange={(e, name) => {
                      setStagehookFields({
                        ...stagehookFields,
                        [name]: e,
                      });
                    }}
                    onFilterChange={(dataValue) => {
                      setStagehookFields({
                        ...stagehookFields,
                        stagehookData: dataValue,
                      });
                    }}
                    filterable={true}
                    autoClose={false}
                    autoBind={true}
                    dataList={originStageHook}
                    loading={stagehookFields.isstagehookDataLoading}
                  />
                  <ShowError name={"stagehookValue"}></ShowError>
                </div>
              </FieldWrapper>
            ) : (
              ""
            )}
            {isEmailFieldsDisplay ? (
              <FieldWrapper className="full-width">
                <div className={"k-form-field-wrap-popup full-width"}>
                  <Label>{"Email Body*:"}</Label>
                  <TextArea
                    rows={5}
                    name="email_body"
                    placeholder="<provider_name> and <period_name> tags will be replaced with job execution values"
                    value={formFields.email_body}
                    onChange={(e) => {
                      handleFieldChange(e);
                    }}
                  />
                  <ShowError name={"email_body"}></ShowError>
                </div>
              </FieldWrapper>
            ) : (
              ""
            )}

            <FieldWrapper className="full-width">
              {isEmailFieldsDisplay ? (
                <div
                  className={
                    "k-form-field-wrap-popup jobparapositionchekbox full-width"
                  }
                >
                  <Checkbox
                    value={formFields.attach_documents}
                    label={"Attach Provider Documents:"}
                    name={"attach_documents"}
                    onChange={(e) => {
                      handleFieldChange(e);
                    }}
                  />
                </div>
              ) : (
                ""
              )}
              <div
                className={
                  "k-form-field-wrap-popup jobparapositionchekbox full-width"
                }
              >
                <Label>{"Job Parameters:"}</Label>
                {isPostionDisplay ? (
                  <>
                    <Checkbox
                      label={"Position"}
                      value={
                        jobTypeFields.jobTypeValue.code === "comp_plan"
                          ? true
                          : formFields.position
                      }
                      name={"position"}
                      onChange={(e) => {
                        handleFieldChange(e);
                      }}
                      disabled={
                        jobTypeFields.jobTypeValue.code === "comp_plan"
                          ? true
                          : false
                      }
                      //  style={{
                      //   display: "inline-block",
                      // }}
                    />
                  </>
                ) : (
                  ""
                )}

                {isEmailFieldsDisplay ? (
                  <>
                    <Checkbox
                      label={"Stagehook"}
                      // style={{
                      //   display: "inline-block",
                      // }}
                      value={formFields.stagehook}
                      name={"stagehook"}
                      onChange={(e) => {
                        handleFieldChange(e);
                      }}
                    />
                  </>
                ) : (
                  ""
                )}

                {isEmailFieldsDisplay || isDisplayPostion ? (
                  <>
                    <Checkbox
                      label={"Published Position Only"}
                      // style={{
                      //   display: "inline-block",
                      // }}
                      value={formFields.publish_postion}
                      name={"publish_postion"}
                      onChange={(e) => {
                        handleFieldChange(e);
                      }}
                    />

                    <ShowError name={"publish_postion"}></ShowError>
                  </>
                ) : (
                  ""
                )}
                {isStageHookDisplay ? (
                  <>
                    <Checkbox
                      label={"Comp Plan"}
                      // style={{
                      //   display: "inline-block",
                      // }}
                      value={formFields.comp_plan}
                      name={"comp_plan"}
                      onChange={(e) => {
                        handleFieldChange(e);
                      }}
                    />
                  </>
                ) : (
                  ""
                )}
              </div>
            </FieldWrapper>
          </FormElement>
        )}
      />
    );
  };

  const AddNewPopup = () => {
    return (
      <Popup anchor={addRef.current} show={isAddNewConfig} className="right0">
        <div className="dot-dropdown-open dot-dropdown-open-Form">
          <div className="caption">
            <span>Add New Job</span>
          </div>

          {AddNewForm()}

          <div className="dropdownFooter">
            <div className="k-form-buttons">
              <Button
                primary={true}
                type="submit"
                onClick={() => {
                  onCancelClick();
                }}
              >
                Cancel
              </Button>
              <Button
                primary={true}
                type="submit"
                onClick={() => {
                  handleSubmit(formFields, errorObj);
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </Popup>
    );
  };

  const AddNewLink = () => {
    return (
      <div ref={popupRef}>
        <a
          className="cta-small"
          ref={addRef}
          onClick={(e) => {
            addNewConfig(e);
          }}
        >
          <i className="fas fa-plus"></i> Add New Job
        </a>

        {AddNewPopup()}
      </div>
    );
  };

  const handleMenuItemClick = (action) => {
    switch (action) {
      case "export":
        setExportDataClick(true);
        break;
      case "delete":
        setShow({
          ...isShow,
          deleteRows: !isShow.deleteRows,
        });
        break;
      default:
        break;
    }
  };

  const headerConfig = {
    title: "Job Configuration",
    id: "job",
    add_new_fx: AddNewLink,
    action_menu: {
      export: { fx: handleMenuItemClick },
      delete: { fx: handleMenuItemClick },
    },
    manage_col: {
      columnsData: columnsData,
      setColumnsData: setColumnsData,
      setColumnCheck: setColumnCheck,
    },
  };

  const headerAssignmentConfig = {
    title: "Engine Phase Assignment",
    id: "plan-assignment",
    buttons: { template_fx: GridButton },
  };

  const parent = document.querySelector("#content-" + headerConfig.id);

  if (parent) {
    const exportToExcel = parent.querySelector(".export-to-excel");
    const auditLog = parent.querySelector(".audit-log");
    const deleteRows = parent.querySelector(".delete-rows");

    exportToExcel && exportToExcel.disableKendoMenu();
    auditLog && auditLog.disableKendoMenu();
    deleteRows && deleteRows.disableKendoMenu();

    dataList.length && exportToExcel && exportToExcel.enableKendoMenu();

    if (selectedRowId !== "" && dataList.length > 0) {
      auditLog && auditLog.enableKendoMenu();
      deleteRows && deleteRows.enableKendoMenu();
    }
  }
  const assign = document.querySelector(
    "#content-" + headerAssignmentConfig.id
  );

  if (assign) {
    const dtuUpload = assign.querySelector(".upload");
    const actionMenu = assign.querySelector(".hoverMenu");

    if (dtuUpload) {
      dtuUpload.style.display = selectedTab === 0 ? "none" : "block";
    }

    if (actionMenu) {
      actionMenu.style.display = selectedTab === 0 ? "none" : "block";
    }
  }

  const leftPane = () => {
    return (
      <>
        <div className="content-head">
          <HeaderPage pageProps={pageProps} headerConfig={headerConfig} />
        </div>

        <div className="content-body job-config-left-grid-cont">
          <div className="dtu-file-container">
            <JobData
              isAPICall={isAPICall}
              setAPICall={setAPICall}
              setExportDataClick={setExportDataClick}
              exportDataClick={exportDataClick}
              selectedRowId={selectedRowId}
              setSelectedRowId={setSelectedRowId}
              setShow={setShow}
              isShow={isShow}
              setDataList={setDataList}
              setisUpdateClick={setisUpdateClick}
              setisCancelClick={setisCancelClick}
              isEngineEdit={isEngineEdit}
              setisEngineEdit={setisEngineEdit}
              setisJobEdit={setisJobEdit}
              isJobEdit={isJobEdit}
              pageProps={pageProps}
              columnsData={columnsData}
              setColumnsData={setColumnsData}
              setColumnCheck={setColumnCheck}
              isCheckColumn={isCheckColumn}
              isAddConfigLoading={isAddConfigLoading}
            />
          </div>
        </div>
      </>
    );
  };

  const rightPane = () => {
    // if (!dataItem.mstr_key) {
    //   return <Loader size="large" className="k-loading-procare" />;
    // }
    return (
      <>
        <div className="content-head">
          <HeaderPage
            pageProps={pageProps}
            headerConfig={headerAssignmentConfig}
          />
        </div>
        <DataRows
          jobTableMasterKey={dataItem.mstr_key}
          isEngineEdit={isEngineEdit}
          setisEngineEdit={setisEngineEdit}
          isUpdateClick={isUpdateClick}
          compPlanKey={dataItem.comp_plan_keys}
          jobTypeName={dataItem.job_type_name}
          isCancelClick={isCancelClick}
          setisCancelClick={setisCancelClick}
        />
      </>
    );
  };
  return (
    <div className="Main jobconfigurationcont mobileblock">
      <div className="overlaymobile">
        <div className="overlaycont">
          <div className="procarelogomob"></div>
          <div className="mobnotificationlogo">
            <h3>Please use tablet or desktop to view this page</h3>
            <button className="primarybtn" href="/dashboard">
              Back to Home
            </button>
          </div>
        </div>
      </div>
      <div className="content">
        {screenLock ? <div className="k-loading-mask"></div> : false}

        <Splitter
          panes={panes}
          onChange={onPaneChange}
          className="splitviewcustom"
        >
          <div className="pane-content">{leftPane()}</div>
          <div className="pane-content">
            {dataList.length == 0 && dataItem.mstr_key ? (
              <div className="assign-nodata">
                <div className="overlaycont">
                  <div className="procarelogomob"></div>
                  <div className="mobnotificationlogo">
                    <h3>
                      {" "}
                      There is no record on the left side of the screen. Please
                      clear the filter or refresh the screen.
                    </h3>
                  </div>
                </div>
              </div>
            ) : (
              false
            )}
            {rightPane()}
          </div>
        </Splitter>
      </div>
    </div>
  );
};

export default React.memo(Job);
