import React from "react";
import {
    GET_CUSTOM_TABLE,
    UPDATE_CUSTOM_TABLE,
    DELETE_CUSTOM_DATA,
    SELECT_CUSTOM_DATA_COLUMNS,
    DELETE_CUSTOM_TABLE_DATA_ROWS,
    SELECT_FLUSH_CUSTOM_DATA,
    INSERT_CUSTOM_TABLE,
} from "../../api-setup/api-endpoints";
import { getUserCredential } from "../../lib/cookies";
import { POSTAPI, errStatusHandler } from "../../api-setup/api-helper";
import { GridColumnDataMapper } from "../../utils/utils";
import { useHistory } from "react-router";
import { USER_GRID_DEFAULT_PAGE_SIZE } from "../../constants/grid";
import { useDispatch } from "react-redux";
import { setNewMessage } from "../../redux/actions/setting-actions";
import { EXPAND_ACTION_COLUM_OBJECT } from "../../constants/constants";


const initialDataState = {
    skip: 0,
    take: USER_GRID_DEFAULT_PAGE_SIZE,
};
let defaultItem = {
    text: USER_GRID_DEFAULT_PAGE_SIZE,
    id: 2,
};

export default function CustomTables() {
    const cookiesData = getUserCredential();
    const header = {
        "x-access-token": cookiesData.secretToken,
    };
    const history = useHistory();
    const dispatch = useDispatch();
    const [data, setData] = React.useState([]);
    const [columns, setColumns] = React.useState([]);
    const [dataCount, setDataCount] = React.useState(0);
    const [isColumnMenuSearch, setIsColumnMenuSearch] = React.useState(false);
    const [paginationData, setPaginationData] = React.useState({
        pageData: 0,
        rows: 10,
    });
    const [loadingState, setLoadState] = React.useState({
        isSaveData: undefined,
        isDeleteData: undefined,
    });
    const [initialLoading, setLoadingState] = React.useState(false);
    const [pageData, setPage] = React.useState(initialDataState);
    const [page, setPageData] = React.useState(0);
    const [editData, setEditData] = React.useState("");
    const [pageSize, setPageSize] = React.useState(defaultItem);
    const [confirmationPopup, setConfirmationPopup] = React.useState({
        title: "Delete",
        desc: "Are you sure you want to delete this record?",
        isVisible: false,
        type: "",
        actionBtn: [],
        data: {},
        action: "Delete",
    });
    const [originalData, setOriginalData] = React.useState([]);
    const [isAPICalling, setAPICallingState] = React.useState(false);
    const [isRefreshLoading, setRefreshLoading] = React.useState(false);

    const getListDataAsync = (listData, extraColumn) => {
        return new Promise(function (resolve, reject) {
            setIsColumnMenuSearch(true);
            if (extraColumn && (extraColumn.isFilter || extraColumn.isSort)) {
                setIsColumnMenuSearch(true);
            } else {
                setIsColumnMenuSearch(false);
            }
            POSTAPI(
                false,
                GET_CUSTOM_TABLE,
                null,
                header,
                listData,
                dispatch
            ).then((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    setLoadingState(true);
                    errStatusHandler(res, history, dispatch);
                    setIsColumnMenuSearch(false);
                }
                if (extraColumn && extraColumn.event) {
                    setPageData(extraColumn.pageData);
                    setPage(extraColumn.event);
                    setPaginationData({
                        ...paginationData,
                        pageData: extraColumn.pageValue * extraColumn.rows,
                        rows: extraColumn.rows,
                    });
                    if (extraColumn.pageSizeValue) {
                        setPageSize(extraColumn.pageSizeValue);
                    }
                }
                setAPICallingState(false);
                setLoadingState(true);
                setEditData("");
                setConfirmationPopup({
                    ...confirmationPopup,
                    isVisible: false,
                    type: "",
                    actionBtn: [],
                    data: {},
                    action: "",
                });

                if (res.data) {
                    let userData = JSON.parse(
                        JSON.stringify(res.data.row_result)
                    );
                    const columData = GridColumnDataMapper(
                        res.data.out_parameters.out_column_xml
                    );
                    userData.map((item, idx) => {
                        item.rowId = idx;
                        item.selected = false;
                        // item.isValidateRow = false;
                        item.error = {};
                        return 0;
                    });
                    columData.map((cItem) => {
                        cItem.minWidth = 210;
                        cItem.show =
                            cItem.field === "custom_table_mstr_key" ||
                            cItem.field === "version"
                                ? false
                                : true;
                        return 0;
                    });
                    columData.unshift(EXPAND_ACTION_COLUM_OBJECT);
                    setData(userData);
                    setOriginalData(userData);
                    setColumns(columData);
                    setDataCount(res.data.out_parameters.out_total_rows);
                    setIsColumnMenuSearch(false);
                    setRefreshLoading(false);
                    resolve(userData);
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    const addDataAsync = (bodyData) => {
        setLoadState({ ...loadingState, isSaveData: false });
        POSTAPI(
            false,
            INSERT_CUSTOM_TABLE,
            null,
            header,
            bodyData,
            dispatch
        ).then((res) => {
            if (res.status) {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                } else {
                    setLoadState({ ...loadingState, isSaveData: true });
                }
            }
        });
    };

    const updateDataAsync = (bodyData) => {
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                UPDATE_CUSTOM_TABLE,
                null,
                header,
                bodyData,
                dispatch
            ).then((res) => {
                if (
                    res &&
                    res.data.out_parameters.out_ret_code === 0) {
                    resolve(true);
                } else {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    const deleteDataAsync = (bodyData) => {
        setLoadState({ ...loadingState, isDeleteData: false });
        POSTAPI(
            false,
            DELETE_CUSTOM_DATA,
            null,
            header,
            bodyData,
            dispatch
        ).then((res) => {
            if (res.data.out_parameters.out_ret_code > 0) {
                setConfirmationPopup({
                    ...confirmationPopup,
                    isVisible: false,
                    type: "",
                    actionBtn: [],
                    data: {},
                    action: "",
                });
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                setLoadState({ ...loadingState, isDeleteData: true });
            } else {
                setConfirmationPopup({
                    ...confirmationPopup,
                    isVisible: false,
                    type: "",
                    actionBtn: [],
                    data: {},
                    action: "",
                });
                // setAPICallingState(true);
                // getListDataAsync(apiCallData);
                setLoadState({ ...loadingState, isDeleteData: true });
            }
        });
    };
    const exportDataAsync = (bodyData) => {
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                GET_CUSTOM_TABLE,
                null,
                header,
                bodyData,
                dispatch
            ).then((res) => {
                if (
                    res &&
                    (res.data.out_parameters.out_ret_code === 0 ||
                        res.data.out_parameters.out_ret_code === 2)
                ) {
                    resolve(res.data.row_result);
                } else {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    resolve([]);
                    errStatusHandler(res, history, dispatch);
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    const getCustomTableColumns = (bodyData) => {
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                SELECT_CUSTOM_DATA_COLUMNS,
                null,
                header,
                bodyData,
                dispatch
            ).then((res) => {
                if (
                    res.data.out_parameters.out_ret_code === 0 ||
                    res.data.out_parameters.out_ret_code === 2
                ) {
                    resolve(res.data.row_result);
                } else {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
        });
    };

    const deleteCustomTableDataRowsAsync = async (bodyData) => {
        setLoadState({ ...loadingState, isDeleteData: false });
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                DELETE_CUSTOM_TABLE_DATA_ROWS,
                null,
                header,
                bodyData,
                dispatch
            ).then((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    let msg = res.data.out_parameters.out_error_msg;

                  //  setLoadState({ ...loadingState, isDeleteData: false });
                    setConfirmationPopup({
                        ...confirmationPopup,
                        isVisible: false,
                        type: "",
                        actionBtn: [],
                        data: {},
                        action: "",
                    });
                    dispatch(
                        setNewMessage({
                            message: msg,
                            type: "Error",
                            show: true,
                        })
                    );
                } else {
                    setConfirmationPopup({
                        ...confirmationPopup,
                        isVisible: false,
                        type: "",
                        actionBtn: [],
                        data: {},
                        action: "",
                    });
                   // setLoadState({ ...loadingState, isDeleteData: true });
                    setTimeout(function () {
                        resolve("success");
                    });
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    const FlushCustomTableDataRowsAsync = async (bodyData) => {
        setLoadState({ ...loadingState, isDeleteData: false });
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                SELECT_FLUSH_CUSTOM_DATA,
                null,
                header,
                bodyData,
                dispatch
            ).then((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    let msg = res.data.out_parameters.out_error_msg;

                    setLoadState({ ...loadingState, isDeleteData: false });
                    setConfirmationPopup({
                        ...confirmationPopup,
                        isVisible: false,
                        type: "",
                        actionBtn: [],
                        data: {},
                        action: "",
                    });
                    dispatch(
                        setNewMessage({
                            message: msg,
                            type: "Error",
                            show: true,
                        })
                    );
                } else {
                    setConfirmationPopup({
                        ...confirmationPopup,
                        isVisible: false,
                        type: "",
                        actionBtn: [],
                        data: {},
                        action: "",
                    });
                    setLoadState({ ...loadingState, isDeleteData: true });
                    setTimeout(function () {
                        resolve("success");
                    });
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };
    return [
        {
            data,
            columns,
            dataCount,
            setData,
            setColumns,
            isColumnMenuSearch,
            pageData,
            editData,
            page,
            originalData,
            pageSize,
            isAPICalling,
            setEditData,
            confirmationPopup,
            setConfirmationPopup,
            initialLoading,
            setPage,
            setPageData,
            setPageSize,
            setPaginationData,
            setAPICallingState,
            paginationData,
            loadingState,
            setOriginalData,
            setLoadState,
            setRefreshLoading,
            isRefreshLoading,
        },
        {
            getListDataAsync,
            addDataAsync,
            updateDataAsync,
            deleteDataAsync,
            exportDataAsync,
            getCustomTableColumns,
            deleteCustomTableDataRowsAsync,
            FlushCustomTableDataRowsAsync,
        },
    ];
}
