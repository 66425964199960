import React, { useEffect } from "react";
import { Label } from "@progress/kendo-react-labels";
import { Button } from "@progress/kendo-react-buttons";
import CustomDropdown from "../../components/customDropdown";
import useReportsService from "./service";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { reportsSelector } from "../../redux/selectors/custom-selector";
import {
    clearReportsCompDetailResults,
    setCompPlanFilterValue,
    setCompPlanPeriodFilterValue,
    setEventTypeFilterValue,
    setIsFromProvider,
    setPeriodFilterValue,
    setPrefetchDropdownAction,
    setProviderFilterValue,
} from "../../redux/actions/reports-actions";
import { getTenantCookieData } from "../../lib/cookies";

const Filters = () => {
    const dispatch = useDispatch();
    const {
        compPlanFilterData,
        compPlanFilterValue,
        isCompPlanFilterDataLoading,

        compPlanPeriodFilterData,
        compPlanPeriodFilterValue,
        isCompPlanPeriodFilterDataLoading,

        providerFilterData,
        providerFilterValue,
        isProviderFilterDataLoading,

        periodFilterData,
        periodFilterValue,
        isPeriodFilterDataLoading,

        eventTypeFilterData,
        eventTypeFilterValue,
        isEventTypeFilterDataLoading,

        isFromProvider,
        prefetchDropdowns,
    } = useSelector(reportsSelector, shallowEqual);
    const reportsServiceActions = useReportsService();

    const [activeCompPlanList, setActiveCompPlanList] = React.useState(
        compPlanFilterData || []
    );
    const [activeCompPlanPeriodList, setActiveCompPlanPeriodList] =
        React.useState(compPlanPeriodFilterData || []);
    const [activeProviderList, setActiveProviderList] = React.useState(
        providerFilterData || []
    );
    const [activePeriodList, setActivePeriodList] = React.useState(
        periodFilterData || []
    );
    const [activeEventTypeList, setActiveEventTypeList] = React.useState(
        eventTypeFilterData || []
    );

    const defaultPageSize = getTenantCookieData("record_per_page");

    React.useEffect(() => {
        if (compPlanFilterData?.length > 0) {
            setActiveCompPlanList(compPlanFilterData);
        } else {
            reportsServiceActions.getCompPlan();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [compPlanFilterData]);

    useEffect(() => {
        setActiveCompPlanPeriodList(compPlanPeriodFilterData);
    }, [compPlanPeriodFilterData]);

    useEffect(() => {
        setActiveProviderList(providerFilterData);
    }, [providerFilterData]);

    useEffect(() => {
        setActivePeriodList(periodFilterData);
    }, [periodFilterData]);

    useEffect(() => {
        setActiveEventTypeList(eventTypeFilterData);
    }, [eventTypeFilterData]);

    useEffect(() => {
       
            reportsServiceActions.getCompPlanPeriods(compPlanFilterValue);
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [compPlanFilterValue]);

    useEffect(() => {
     
            reportsServiceActions.getCompPlanProviders(
                compPlanPeriodFilterValue
            );
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [compPlanPeriodFilterValue]);

    useEffect(() => {
        
            reportsServiceActions.getPeriod(
                compPlanFilterValue,
                compPlanPeriodFilterValue
            );
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [compPlanPeriodFilterValue, compPlanFilterValue]);

    useEffect(() => {
        if (prefetchDropdowns && !eventTypeFilterData.length) {
            reportsServiceActions.getEventTypes();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // useEffect(() => {
    //   if (eventTypeFilterData.length > 0) {
    //     const found = eventTypeFilterData.find(
    //       (it) =>
    //         it.event_type_mstr_key === eventTypeFilterValue?.event_type_mstr_key
    //     );

    //     dispatch(setEventTypeFilterValue(found || eventTypeFilterData[0]));
    //   } else {
    //     reportsServiceActions.getEventTypes();
    //   }
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [eventTypeFilterData]);

    const handleChangeCompPlan = (value) => {
        dispatch(setPrefetchDropdownAction(true));
        dispatch(setCompPlanFilterValue(value));
    };

    const handleChangeCompPlanPeriod = (value) => {
        dispatch(setCompPlanPeriodFilterValue(value));
    };

    const handleChangeProvider = (value) => {
        dispatch(setProviderFilterValue(value));
    };

    const handleChangePeriod = (value) => {
        dispatch(setPeriodFilterValue(value));
    };

    const handleChangeEventType = (value) => {
        dispatch(setEventTypeFilterValue(value));
    };

    useEffect(() => {
        if (
            compPlanFilterValue?.comp_plan_key &&
            compPlanPeriodFilterValue?.period_key &&
            providerFilterValue?.provider_key &&
            periodFilterValue?.period_key &&
            eventTypeFilterValue?.event_type_mstr_key &&
            isFromProvider &&
            !isCompPlanFilterDataLoading &&
            !isCompPlanPeriodFilterDataLoading &&
            !isProviderFilterDataLoading &&
            !isPeriodFilterDataLoading &&
            !isEventTypeFilterDataLoading
        ) {
            dispatch(setIsFromProvider(false));
            runReports();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        compPlanFilterValue,
        compPlanPeriodFilterValue,
        providerFilterValue,
        periodFilterValue,
        isFromProvider,
        eventTypeFilterValue,
        isCompPlanFilterDataLoading,
        isCompPlanPeriodFilterDataLoading,
        isProviderFilterDataLoading,
        isPeriodFilterDataLoading,
        isEventTypeFilterDataLoading,
    ]);

 
    const runReports = () => {
        dispatch(setPrefetchDropdownAction(false));
        reportsServiceActions.getAutoHideSection1();
        getSection1();
        getSection2();
    };

    const getSection1 = () => {
        reportsServiceActions.getSection1(
            providerFilterValue["provider_key"],
            periodFilterValue["period_key"]
        );
    };

    const getSection2 = () => {
        let sortField = "1 ASC";
        dispatch(clearReportsCompDetailResults(defaultPageSize));
        reportsServiceActions.getSection2(
            providerFilterValue["provider_key"],
            eventTypeFilterValue["event_type_mstr_key"],
            compPlanFilterValue["comp_plan_key"],
            periodFilterValue["period_key"],
            periodFilterValue["period_start_date"],
            periodFilterValue["period_end_date"],
            0,
            defaultPageSize.recordTake,
            sortField,
            null,
            true
        );
    };

    return (
        <ul className="CompReportFilter-links reports-filters">
            <div className={"k-form-field-wrap-popup valueDropdown width250"}>
                <Label>{"Comp Plan:"}</Label>
                <CustomDropdown
                    name="compPlanDropdown"
                    data={activeCompPlanList}
                    textField="comp_plan_name"
                    dataItemKey="comp_plan_key"
                    loading={isCompPlanFilterDataLoading}
                    valueData={compPlanFilterValue}
                    onChange={(value) => handleChangeCompPlan(value)}
                    filterable={true}
                    dataList={compPlanFilterData}
                    onClose={() => {
                        setActiveCompPlanList(compPlanFilterData);
                    }}
                    onFilterChange={(dataValue) =>
                        setActiveCompPlanList(dataValue)
                    }
                />
            </div>
            <div className={"k-form-field-wrap-popup valueDropdown width250"}>
                <Label>{"Comp Plan Period:"}</Label>
                <CustomDropdown
                    name="compPlanPeriodDropdown"
                    data={activeCompPlanPeriodList}
                    textField="period_name"
                    onChange={(value) => handleChangeCompPlanPeriod(value)}
                    dataItemKey="period_key"
                    loading={isCompPlanPeriodFilterDataLoading}
                    valueData={compPlanPeriodFilterValue}
                    filterable={true}
                    dataList={compPlanPeriodFilterData}
                    onFilterChange={(dataValue) => {
                        setActiveCompPlanPeriodList(dataValue);
                    }}
                    onClose={() => {
                        setActiveCompPlanPeriodList(compPlanPeriodFilterData);
                    }}
                />
            </div>
            <div className={"k-form-field-wrap-popup valueDropdown width250"}>
                <Label>{"Provider:"}</Label>
                <CustomDropdown
                    name="providerDropdown"
                    data={activeProviderList}
                    textField="provider_name"
                    onChange={(value) => handleChangeProvider(value)}
                    dataItemKey="provider_key"
                    loading={isProviderFilterDataLoading}
                    valueData={providerFilterValue}
                    filterable={true}
                    dataList={providerFilterData}
                    onClose={() => {
                        setActiveProviderList(providerFilterData);
                    }}
                    onFilterChange={(dataValue) =>
                        setActiveProviderList(dataValue)
                    }
                />
            </div>
            <div className={"k-form-field-wrap-popup valueDropdown width250"}>
                <Label>{"Period:"}</Label>
                <CustomDropdown
                    name="periodDropdown"
                    data={activePeriodList}
                    textField="period_name"
                    onChange={(value) => handleChangePeriod(value)}
                    dataItemKey="period_key"
                    loading={isPeriodFilterDataLoading}
                    valueData={periodFilterValue}
                    filterable={true}
                    dataList={periodFilterData}
                    onClose={() => {
                        setActivePeriodList(periodFilterData);
                    }}
                    onFilterChange={(dataValue) =>
                        setActivePeriodList(dataValue)
                    }
                />
            </div>
            <div className={"k-form-field-wrap-popup valueDropdown"}>
                <Label>{"Event Type:"}</Label>
                <CustomDropdown
                    name="eventTypeDropdown"
                    data={activeEventTypeList}
                    textField="event_type_name"
                    onChange={(value) => handleChangeEventType(value)}
                    dataItemKey="event_type_mstr_key"
                    loading={isEventTypeFilterDataLoading}
                    valueData={eventTypeFilterValue}
                    filterable={true}
                    dataList={eventTypeFilterData}
                    onClose={() => {
                        setActiveEventTypeList(eventTypeFilterData);
                    }}
                    onFilterChange={(dataValue) =>
                        setActiveEventTypeList(dataValue)
                    }
                />
            </div>
            <div className="k-form-buttons btn-comp-report-filter">
                <Button
                    primary={true}
                    disabled={
                        !compPlanFilterValue ||
                        !compPlanPeriodFilterValue ||
                        !providerFilterValue ||
                        !periodFilterValue ||
                        !eventTypeFilterValue ||
                        isCompPlanFilterDataLoading ||
                        isCompPlanPeriodFilterDataLoading ||
                        isPeriodFilterDataLoading ||
                        isProviderFilterDataLoading ||
                        isEventTypeFilterDataLoading
                    }
                    type={"submit"}
                    onClick={() => runReports()}
                    className="primarybtn"
                >
                    Run
                </Button>
            </div>
        </ul>
    );
};

export default React.memo(Filters);
