import React from 'react';
import { Popup, PopupPropsContext } from "@progress/kendo-react-popup";



const anchorAlign = {
    horizontal: "center",
    vertical: "bottom",
};
const popupAlign = {
    horizontal: "right",
    vertical: "top",
};

const offset = {
    left: 130,
    top: 32,
  };

export default function CustomPopup(props) {

    const {show, className, appendTo } = props;
    const wrapper = React.useRef(null);
    const anchor = React.useRef(null);

    return (
        <>
            <div ref={wrapper}>
                <PopupPropsContext.Provider
                    value={(props) => ({
                        ...props,
                        appendTo: wrapper.current,
                    })}
                >
                    <Popup show={show} offset={offset} className={className} appendTo={appendTo} anchor={anchor.current} anchorAlign={anchorAlign} popupAlign={popupAlign}>
                        {props.children}
                    </Popup>
                </PopupPropsContext.Provider>
            </div>
        </>
    );
}