import { useEffect, useState } from "react";
import CustomDropdown from "../../../../components/customDropdown";
import { GetUDFDataObj } from "../../../../lib/common-service";
import { Label } from "@progress/kendo-react-labels";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Checkbox } from "@progress/kendo-react-inputs";
import {
    changeCancelBtnState,
    changeUpdateBtnState,
} from "../../../../utils/utils";
import { prorateDataSelector } from "../../../../redux/selectors/custom-selector";
import { setProrationData } from "../../../../redux/actions/rule-library-actions";

const WritebackEvent = (restProps) => {
    const dispatch = useDispatch();
    const prorateReduxData = useSelector(prorateDataSelector, shallowEqual);
    const [eventFields, setEventTypeFields] = useState({
        eventDataValue: {},
        eventData: [],
        isEventDataLoading: true,
    });
    const [eventTypeList, setEventTypeList] = useState([]);
    const [formFields, setFormFields] = useState({
        is_clear_event_flag: 0,
    });

    useEffect(() => {
        getUdfData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (prorateReduxData?.prorateData && eventTypeList.length > 0) {
            setFormFields({
                ...formFields,
                is_clear_event_flag:
                    prorateReduxData?.prorateData.is_clear_event_flag === null ? 0 : prorateReduxData?.prorateData.is_clear_event_flag,
            });
            setEventTypeFields({
                ...eventFields,
                isEventDataLoading: false,
                eventDataValue:
                    prorateReduxData?.prorateData.in_udf_data_obj_key > 0
                        ? eventTypeList.find(
                              (x) =>
                                  x.udf_data_object_key ===
                                  prorateReduxData?.prorateData
                                      .in_udf_data_obj_key
                          )
                        : eventTypeList[0],
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventTypeList,prorateReduxData?.prorateData]);

    /**
     * Get Periods Data Type
     */
    const getUdfData = async () => {
        let eventData = {
            in_filter:
                "AND u.rfct_field_metadata like 'DECIMAL(20,8)%' AND u.udf_data_obj_field_name like '%event%'",
            in_sort_number: 3,
            in_sort_order: "ASC",
            in_is_group: 0,
        };
        let res = await GetUDFDataObj(eventData, dispatch);
        if (res.length > 0) {
            res.unshift({
                udf_data_obj_field_label: "",
                udf_data_object_key: "null",
            });
            setEventTypeList(res);
            setEventTypeFields({
                ...eventFields,
                eventData: res,
                eventDataValue: res[0],
                isEventDataLoading: false,
            });
        } else {
            setEventTypeFields({
                ...eventFields,
                isEventDataLoading: false,
            });
        }
    };

    const handleCheckBoxChange = (e) => {
        setFormFields({
            ...formFields,
            is_clear_event_flag: e.target.value ? 1 : 0,
        });
        dispatch(
            setProrationData({
                ...prorateReduxData.prorateData,
                is_clear_event_flag: e.target.value ? 1 : 0,
            })
        );
        restProps?.onDataChange?.();
        changeCancelBtnState("enable", "grid-incell-cancel-comp-rule");
        changeUpdateBtnState("enable", "grid-incell-update-comp-rule");
    };

    const handleDropdownChange = (e, name) => {
        setEventTypeFields({
            ...eventFields,
            [name]: e,
        });
        if (e.udf_data_object_key === "null") {
            setFormFields({
                ...formFields,
                is_clear_event_flag: 0,
            });
        }
        dispatch(
            setProrationData({
                ...prorateReduxData.prorateData,
                in_udf_data_obj_key: e.udf_data_object_key,
                is_clear_event_flag:
                    e.udf_data_object_key === "null"
                        ? 0
                        : formFields.is_clear_event_flag,
            })
        );
        changeCancelBtnState("enable", "grid-incell-cancel-comp-rule");
        changeUpdateBtnState("enable", "grid-incell-update-comp-rule");
    };

    return (
        <>
            <div className="inner-title-head">
                <h1>Writeback Events</h1>
            </div>
            <div className="inner-title-cont">
                <div className="k-form-field-wrap-popup " style={{ width: "auto" }}>
                    <Label className="innerlabletext" >
                        {"Event Field: "}
                    </Label>
                    <CustomDropdown
                        name={"eventDataValue"}
                        data={eventFields.eventData}
                        textField="udf_data_obj_field_label"
                        onOpen={(dataValue) => {
                            setEventTypeFields({
                                ...eventFields,
                                eventData: dataValue,
                            });
                        }}
                        dataItemKey="udf_data_object_key"
                        valueData={eventTypeList.find(
                            (c) =>
                                c.udf_data_obj_field_label ===
                                eventFields.eventDataValue
                                    .udf_data_obj_field_label
                        )}
                        onChange={(e, name) => {
                            handleDropdownChange(e, name);
                        }}
                        filterable={true}
                        onFilterChange={(dataValue) => {
                            setEventTypeFields({
                                ...eventFields,
                                eventData: dataValue,
                            });
                        }}
                        autoClose={false}
                        autoBind={true}
                        isSelectALl={true}
                        dataList={eventTypeList}
                        loading={eventFields.isEventDataLoading}
                        style={{
                            width: "200px",
                        }}
                        disabled={
                            restProps.pageProps.writeAccess === 0 ? true : false
                        }
                    />
                   
                </div>
                <div className="k-form-field-wrap-popup execution-checkbox">
                        <Checkbox
                            label={"Clear Event"}
                            checked={
                                formFields?.is_clear_event_flag ? true : false
                            }
                            onChange={handleCheckBoxChange}
                            className={"k-checkbox-label innerlabletext"}
                            style={{
                                display: "inline-block",
                            }}
                            disabled={
                                restProps.pageProps.writeAccess === 0
                                    ? true
                                    : eventFields.eventDataValue
                                          ?.udf_data_obj_label
                                    ? false
                                    : true
                            }
                        />
                    </div>
            </div>
        </>
    );
};

export default WritebackEvent;
