import React from "react";
import { Window, WindowActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import SendEmailForm from "./form/sendEmailForm";

const SendEmails = (restProps) => {
    const [visible, setVisible] = React.useState(restProps.isShow.sendEmails);
    const [stageChange, setStateChange] = React.useState("DEFAULT");
    const [modal, setModal] = React.useState(true);

    const onClosePopup = () => {
        setVisible(!visible);
        restProps.setShow({ ...restProps.isShow, sendEmails: false });
    };

    const ModalTitle = () => {
        return <span>Send Emails</span>;
    };

    const ModalDesc = () => {
        return (
            <div>
                <SendEmailForm
                    onClosePopup={onClosePopup}
                    setAPICall={restProps.setAPICall}
                />
            </div>
        );
    };

    const ModalActionBar = () => {
        return (
            <>
                <Button
                    type={"button"}
                    onClick={onClosePopup}
                    className="cancelbtn"
                >
                    Cancel
                </Button>
                <Button
                    type={"submit"}
                    className="primarybtn"
                    form="sendEmailForm"
                >
                    Send
                </Button>{" "}
            </>
        );
    };

    const handleStageChange = (e) => {
        setStateChange(e.state);
        if (e.state === "MINIMIZED") {
            setModal(false);
        } else if (e.state === "DEFAULT") {
            setModal(true);
        }
    };

    return (
        <>
            <Window
                title={<ModalTitle />}
                onClose={onClosePopup}
                width={850}
                height={600}
                stage={stageChange}
                onStageChange={handleStageChange}
                modal={modal}
                //appendTo={restProps.dropDownAppendTo}
                //className={"window-styles"}
                resizable={false}
            >
                <ModalDesc />
                <WindowActionsBar layout={"end"}>
                    <ModalActionBar />{" "}
                </WindowActionsBar>
            </Window>
        </>
    );
};

export default React.memo(SendEmails);
