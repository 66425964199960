import { generateHeader } from "src/api-setup/api-helper";
export default async function AxiosRequest(
  config
) {
  const accessToken = generateHeader();
  return {
    ...config,
    headers: {
      "Access-Control-Allow-Origin": "*",
      ...accessToken,
      ...config.headers,
    },
  };
}
