import * as React from "react";
import { Popup } from "@progress/kendo-react-popup";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { showUnSavedChangesFn } from "src/utils/utils";
import { useDispatch } from "react-redux";

const anchorAlign = {
  horizontal: "right",
  vertical: "bottom",
};
const popupAlign = {
  horizontal: "right",
  vertical: "top",
};

const ManageColumns = (props) => {
  const dispatch = useDispatch();
  const { setColumnsData, setColumnCheck } = props;
  let columnsData = [];
  props.columnsData.forEach((column, idx) => {
    if (column.title !== "") {
      let obj = {};
      Object.assign(obj, column);
      columnsData.push(obj);
    }
  });

  let anchor = null;
  let divRef = React.createRef();
  const [show, setShow] = React.useState(false);
  let contentRef = React.createRef();
  let blurTimeoutRef = React.createRef();
  const [popupAppendTo, setPopupAppendTo] = React.useState(null);
  const [isSelectAllColumn, setSelectAllColumn] = React.useState(true);
  const [searchVal, setSearch] = React.useState("");

  React.useEffect(() => {
    setPopupAppendTo(divRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (show) {
      document.addEventListener("click", handleDocumentClick);
    } else {
      document.removeEventListener("click", handleDocumentClick);
    }

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  React.useEffect(() => {
    columnsData.filter((c) => !c.show).length
      ? setSelectAllColumn(false)
      : setSelectAllColumn(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.columnsData]);

  const onOpen = (e) => {
    contentRef.current.focus();
  };

  const onFocus = () => {
    // the user is still inside the content
    clearTimeout(blurTimeoutRef.current);
  };

  const onBlurTimeout = () => {
    // the user is now outside the popup
    setShow(false);
  };

  const onBlur = () => {
    if (blurTimeoutRef.current !== null) {
      clearTimeout(blurTimeoutRef.current);

      blurTimeoutRef.current = setTimeout(onBlurTimeout, 200);
    }
  };

  const onClick = () => {
    // showUnSavedChangesFn(dispatch, () => {
    //   setShow(!show);
    // }, props.unsavedHandle?.cancelId, props.unsavedHandle?.saveId);
    setShow(!show);
  };

  const onClosePopup = () => {
    setShow(false);
  };
  const handleDocumentClick = (e) => {
    // Check if the click event happened outside the popup content
    if (divRef.current && !divRef.current.contains(e.target)) {
      onClosePopup();
    }
  };

  const onToggleColumn = React.useCallback(
    (field, alias = null) => {
      const newColumns = columnsData.map((column, idx) => {
        if (alias) {
          return column.field === field && column.alias === alias
            ? { ...column, show: !column.show }
            : column;
        } else {
          return column.field === field
            ? { ...column, show: !column.show }
            : column;
        }
      });
      setColumnsData(newColumns);
      setColumnCheck(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columnsData]
  );

  const onToggleAllColumn = React.useCallback(
    (e) => {
      const serachColLength = columnsData.filter((item) =>
        item.title.toLowerCase().includes(searchVal.toLowerCase())
      ).length;
      const falsColLength =
        serachColLength === columnsData.length
          ? columnsData.length - 1
          : columnsData.length;

      const newColumns = columnsData.map((column, idx) => {
        return idx === falsColLength ||
          !column.title.toLowerCase().includes(searchVal.toLowerCase())
          ? { ...column, show: true }
          : column.title === "ACTION"
          ? { ...column, show: true }
          : { ...column, show: e.target.checked };
      });
      setSelectAllColumn(e.target.checked);
      setColumnsData(newColumns);
      setColumnCheck(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columnsData]
  );

  const oneVisibleColumn =
    columnsData &&
    columnsData.filter((c) => c.show && c.title !== "ACTION").length === 1;

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  return (
    <div ref={divRef} className="col-config">
      <Tooltip openDelay={100} position="left" anchorElement="target">
        <li
          className="fa fa-list-ul pointer-class"
          onClick={onClick}
          ref={(button) => {
            anchor = button;
          }}
          title="Manage Columns"
        ></li>
      </Tooltip>
      <Popup
        anchor={anchor !== null ? anchor.current : anchor}
        show={show}
        onOpen={onOpen}
        appendTo={popupAppendTo}
        anchorAlign={anchorAlign}
        popupAlign={popupAlign}
      >
        <div ref={contentRef} onFocus={onFocus} onBlur={onBlur}>
          <div className="dot-dropdown-open managecolumnpopup">
            <div className="caption">
              <span>Manage Columns</span>
            </div>
            <div className="ColSearch">
              <span>
                <i className="fas fa-search"></i>
                <input
                  type="text"
                  className="input_box"
                  value={searchVal}
                  name=""
                  placeholder="Search"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                ></input>
                <span
                  className="k-icon k-i-close tooltip"
                  onClick={() => {
                    setSearch("");
                  }}
                >
                  <div className="tooltiptext plaintooltip">Clear</div>
                </span>
              </span>
            </div>
            <>
              {columnsData.filter(
                (item) =>
                  item.title.toLowerCase() !== "action" &&
                  item.title.toLowerCase().includes(searchVal.toLowerCase())
              ).length > 1 && (
                <ul className="acc_toggle_columns showall">
                  <li>
                    <input
                      className="k-checkbox"
                      type="checkbox"
                      readOnly={true}
                      id={"manage-col-show-all-" + props.pageId}
                      checked={isSelectAllColumn}
                      onChange={(e) => {
                        onToggleAllColumn(e);
                      }}
                    />
                    <label htmlFor={"manage-col-show-all-" + props.pageId}>
                      {"SHOW ALL"}
                    </label>
                  </li>
                </ul>
              )}
              <ul
                className="acc_toggle_columns"
                id={"group-columns-" + props.pageId}
              >
                {columnsData.filter((item) =>
                  item.title.toLowerCase().includes(searchVal.toLowerCase())
                ).length > 0 ? (
                  columnsData
                    .filter((item) =>
                      item.title.toLowerCase().includes(searchVal.toLowerCase())
                    )
                    .map((item, idx) => {
                      return item.title.toLowerCase() !== "action" ? (
                        <li key={idx}>
                          <input
                            className="k-checkbox"
                            type="checkbox"
                            id={
                              props.pageId +
                              "_" +
                              `${item.alias ? item.alias + "_" : ""}` +
                              item.field
                            }
                            value={item.show}
                            disabled={item.show && oneVisibleColumn}
                            checked={item.show}
                            onChange={() => {
                              onToggleColumn(
                                item.field,
                                props.pageId === "compensation_detail"
                                  ? item.alias
                                  : null
                              );
                            }}
                          />
                          <label
                            htmlFor={
                              props.pageId +
                              "_" +
                              `${item.alias ? item.alias + "_" : ""}` +
                              item.field
                            }
                          >
                            {item.title}
                          </label>
                        </li>
                      ) : (
                        false
                      );
                    })
                ) : (
                  <div className="nocolumn-error">No Columns Found</div>
                )}
              </ul>
            </>
          </div>
        </div>
      </Popup>
    </div>
  );
};
export default React.memo(ManageColumns);
