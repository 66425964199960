import { GET_CATEGORY_FIELD, SELECT_DOCUMENT, SELECT_PROVIDER_PROFILE } from "src/api-setup/api-endpoints";
import { POSTAPI, errStatusHandler, generateHeader } from "src/api-setup/api-helper";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setNewMessage } from "src/redux/actions/setting-actions";

export default function ProviderProfile() {
  const history = useHistory()
  const dispatch = useDispatch();

  const getProviderPosition = async (reqData) => {
    return new Promise(function (resolve, reject) {
      POSTAPI(false, SELECT_PROVIDER_PROFILE, null, generateHeader(), reqData).then(
        (res) => {
          if (res?.data?.out_parameters.out_ret_code > 0) {
            errStatusHandler(res, history, dispatch);
            resolve([]);
          
          }else{
            if (res.data) {
              let userData = JSON.parse(
                JSON.stringify(res.data.row_result)
              );
              resolve(userData);
            }
          }
        }
      );
    })
      .then((res) => {
        return res;
      })
      .catch((res) => {
        if (res.data.out_parameters.out_ret_code > 0) {
          dispatch(
            setNewMessage({
              message: res.data.out_parameters.out_error_msg,
              type: "Error",
              show: true,
            })
          );
          errStatusHandler(res, history, dispatch);
        }
      });
  };

  const getCategoryField = (reqData, dispatch, history) => {
    return new Promise(function (resolve, reject) {
      POSTAPI(false, GET_CATEGORY_FIELD, null, generateHeader(), reqData).then(
        (res) => {
          if (res?.data?.out_parameters.out_ret_code > 0) {
            errStatusHandler(res, history, dispatch);
          }
          if (res.data) {
            let userData = JSON.parse(
              JSON.stringify(res.data.row_result)
            );
            resolve(userData);
          }
        }
      );
    })
      .then((res) => {
        return res;
      })
      .catch((res) => {
        if (res.data.out_parameters.out_ret_code > 0) {
          dispatch(
            setNewMessage({
              message: res.data.out_parameters.out_error_msg,
              type: "Error",
              show: true,
            })
          );
          errStatusHandler(res, history, dispatch);
        }
      });
  };


  const selectDocument = (data, dispatch, history) => {
    return new Promise(function (resolve, reject) {
      POSTAPI(
        false,
        SELECT_DOCUMENT,
        null,
        generateHeader(),
        data,
        dispatch
      ).then((res) => {
        if (
          res &&
          (res.data.out_parameters.out_ret_code === 0)
        ) {
          resolve(res)
        } else {
          dispatch(
            setNewMessage({
              message: res.data.out_parameters.out_error_msg,
              type: "Error",
              show: true,
            })
          );
          errStatusHandler(res, history, dispatch);
        }
      });
    }).then((res) => {
      return res
    })
  };


  return [
    {

    },
    {
      getProviderPosition,
      getCategoryField,
      selectDocument
    },
  ];
}
