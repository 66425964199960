import { useCallback } from "react";
import { chartTypeConfig } from "./chart.const";
import {
    ColumnParserHook,
    LineParserHook,
    AreaParserHook,
    PieParserHook,
    BubbleParserHook,
    BarParserHook,
} from "./parser";

export const ChartHook = ({ data }) => {
    const dataSetData = data;

    const { renderColumn } = ColumnParserHook();
    const { renderLine } = LineParserHook();
    const { renderArea } = AreaParserHook();
    const { renderPie } = PieParserHook();
    const { renderBubble } = BubbleParserHook();
    const { renderBar } = BarParserHook();

    const renderChartBody = useCallback(
        ({ chartValue, chartType, isStacked }) => {
            switch (chartType) {
                case chartTypeConfig.line.key: {
                    return renderLine({ chartValue, isStacked });
                }
                case chartTypeConfig.column.key: {
                    return renderColumn({ chartValue, isStacked });
                }
                case chartTypeConfig.area.key: {
                    return renderArea({ chartValue, isStacked });
                }
                case chartTypeConfig.bar.key: {
                    return renderBar({ chartValue, isStacked });
                }
                case chartTypeConfig.pie.key: {
                    return renderPie({ chartValue });
                }
                case chartTypeConfig.bubble.key: {
                    return renderBubble({ chartValue });
                }
                default: {
                    return [];
                }
            }
        },
        [
            renderLine,
            renderColumn,
            renderArea,
            renderBar,
            renderPie,
            renderBubble,
        ]
    );

    const renderChart = useCallback(() => {
        return dataSetData?.map((chartData) =>
            renderChartBody({
                chartValue: chartData?.data,
                chartType: chartData?.chartType,
                isStacked: chartData?.isStacked,
            })
        );
    }, [dataSetData, renderChartBody]);

    return {
        renderChart,
    };
};
