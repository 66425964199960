import {
  ErrorMessage,
  ResponseStatus,
  ResponseError,
} from "src/constants/api/error-messages.const";

export default function AxiosResponseError(error) {
  const Error = error;
  // TODO: Will also implement the authGaurd check for 401 error responses here later
  // TODO: to add different error messages based on the error when given by the business team
  switch (error.code) {
    case ResponseError.ERR_NETWORK: {
      Error.message = ErrorMessage[ResponseError.ERR_NETWORK];
      break;
    }
    case ResponseError.ERR_BAD_REQUEST: {
      Error.message = ErrorMessage[ResponseError.ERR_NETWORK];
      if (error?.response?.status === ResponseStatus.notAuthorized) {
        Error.message = ErrorMessage[ResponseStatus.notAuthorized];
      }
      break;
    }
    default:
  }
  return Promise.reject(Error);
}
