import React from "react";
import { getUserCredential } from "../../lib/cookies";
import { errStatusHandler, POSTAPI } from "../../api-setup/api-helper";
import { GridColumnDataMapper } from "../../utils/utils";
import { USER_GRID_DEFAULT_PAGE_SIZE } from "../../constants/grid";
import { useDispatch } from "react-redux";
import { setNewMessage } from "../../redux/actions/setting-actions";
import {
    GET_EVENT_TYPE,
    INSERT_EVENT_TYPE,
    DELETE_EVENT_TYPE,
    UPDATE_EVENT_TYPE,
    GET_EVENT_GROUP,
} from "../../api-setup/api-endpoints";
import { useHistory } from "react-router-dom";

const initialDataState = {
    skip: 0,
    take: USER_GRID_DEFAULT_PAGE_SIZE,
};
let defaultItem = {
    text: USER_GRID_DEFAULT_PAGE_SIZE,
    id: 2,
};

export default function Events() {
    const history = useHistory();
    const cookiesData = getUserCredential();
    const header = {
        "x-access-token": cookiesData.secretToken,
    };
    const dispatch = useDispatch();
    const [data, setData] = React.useState([]);
    const [columns, setColumns] = React.useState([]);
    const [dataCount, setDataCount] = React.useState(0);
    const [isColumnMenuSearch, setIsColumnMenuSearch] = React.useState(false);
    const [paginationData, setPaginationData] = React.useState({
        pageData: 0,
        rows: 10,
    });
    const [initialLoading, setLoadingState] = React.useState(false);
    const [pageData, setPage] = React.useState(initialDataState);
    const [page, setPageData] = React.useState(0);
    const [editData, setEditData] = React.useState("");
    const [pageSize, setPageSize] = React.useState(defaultItem);
    const [isAPICalling, setAPICallingState] = React.useState(false);
    const [group, setGroup] = React.useState([]);
    const [resultState, setResultState] = React.useState([]);
    const [collapsedState, setCollapsedState] = React.useState([]);
    const [originalData, setOriginalData] = React.useState([]);
    const [visible, setVisible] = React.useState(false);
    const [responsecode, setresponsecode] = React.useState(null);
    const [responsemsg, setresponsemsg] = React.useState(null);
    const [originEventGroupData, setoriginEventGroupData] = React.useState([]);
    const [eventGroupData, setEventGroupData] = React.useState({
        eventGroupValue: {
            in_event_group: "",
        },
        eventGroupDataValue: [],
        iseventGroupDataLoading: true,
    });
    const [noDataFound, setNoDataFound] = React.useState(false);
    const [isRefreshLoading, setRefreshLoading] = React.useState(false);
    const [loadingState, setLoadState] = React.useState({
        isSaveData: undefined,
        isDeleteData: undefined,
    });

    let newData = [];
    let processedGroupData = [];
    const [fileData, setFileData] = React.useState({});
    const [confirmationPopup, setConfirmationPopup] = React.useState({
        title: "Delete",
        desc: "Are you sure you want to delete this record?",
        isVisible: false,
        type: "",
        actionBtn: [],
        data: {},
        action: "Delete",
    });

    const getResultList = (listData) => {
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                GET_EVENT_TYPE,
                null,
                header,
                listData,
                dispatch
            ).then((res) => {
                if (res) {
                    if (res.data.out_parameters.out_ret_code > 0) {
                        setLoadingState(true);
                        errStatusHandler(res, history, dispatch);
                    }
                    setLoadingState(true);
                    setVisible(true);
                    setAPICallingState(false);
                    if (listData.in_load_type === "grid-reload") {
                        const columData = GridColumnDataMapper(
                            res.data.out_parameters.out_column_xml
                        );
                        setColumns(columData);
                    }
                    res.data.row_result.map((item, idx) => {
                        item.rowId = idx;
                        return 0;
                    });

                    setData(res.data.row_result);
                    setDataCount(res.data.out_parameters.out_total_rows);
                    setOriginalData(res.data.row_result);

                    setIsColumnMenuSearch(false);
                    setNoDataFound(false);
                    setRefreshLoading(false);
                    resolve(res.data.row_result);
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    const addDataAsync = (bodyData, data) => {
        setRefreshLoading(true);
        setLoadState({ ...loadingState, isSaveData: false });
        setAPICallingState(true);
        POSTAPI(
            false,
            INSERT_EVENT_TYPE,
            null,
            header,
            bodyData,
            dispatch
        ).then((res) => {
            setRefreshLoading(false);
            if (res.status) {
                if (res.data.out_parameters.out_ret_code > 0) {
                    setAPICallingState(false);
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                    setresponsecode(res.data.out_parameters.out_ret_code);
                    setresponsemsg(res.data.out_parameters.out_error_msg);
                } else {
                    setLoadState({ ...loadingState, isSaveData: true });
                    setAPICallingState(false);

                    // setAPICallingState(true);
                }
            }
        });
    };

    const deleteDataAsync = async (bodyData) => {
        setRefreshLoading(true);
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                DELETE_EVENT_TYPE,
                null,
                header,
                bodyData,
                dispatch
            ).then((res) => {
                setRefreshLoading(false);
                if (res.data.out_parameters.out_ret_code > 0) {
                    const totalResultTypes = bodyData.in_event_type_mstr_key.length;
                    const unSucessFullCount = res.data.successCount;
                    setRefreshLoading(false);
                    if (res.data.out_parameters.out_ret_code > 0) {
                        let msg = res.data.out_parameters.out_error_msg;
                        if (totalResultTypes !== unSucessFullCount) {
                            msg =
                                unSucessFullCount +
                                " Event Type (" +
                                res.data.successName +
                                ") out of " +
                                totalResultTypes + ' '+
                                "selected linked to active Event type - cannot delete.";
                            setLoadState({ ...loadingState, isDeleteData: true });
                            setTimeout(function () {
                                resolve("success");
                            });
                        } else {
                                setLoadState({ ...loadingState, isDeleteData: false });
                                setTimeout(function () {
                                    resolve("error");
                                });
                        }
                        setConfirmationPopup({
                            ...confirmationPopup,
                            isVisible: false,
                            type: "",
                            actionBtn: [],
                            data: {},
                            action: "",
                        });
                        dispatch(
                            setNewMessage({
                                message: msg,
                                type: "Error",
                                show: true,
                            })
                        );
                    } else {
                        setConfirmationPopup({
                            ...confirmationPopup,
                            isVisible: false,
                            type: "",
                            actionBtn: [],
                            data: {},
                            action: "",
                        });
                        setLoadState({ ...loadingState, isDeleteData: true });
                        setTimeout(function () {
                            resolve("success");
                        });
                    }
                }else{
                    setConfirmationPopup({
                        ...confirmationPopup,
                        isVisible: false,
                        type: "",
                        actionBtn: [],
                        data: {},
                        action: "",
                    });
                    setLoadState({ ...loadingState, isDeleteData: true });
                    setTimeout(function () {
                        resolve("success");
                    });
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    const updateDataAsync = (bodyData) => {
        setRefreshLoading(true);
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                UPDATE_EVENT_TYPE,
                null,
                header,
                bodyData,
                dispatch
            ).then((res) => {
                setRefreshLoading(false);
                if (res && res.data.out_parameters.out_ret_code === 0) {
                    resolve(true);
                } else {
                    dispatch(
                        setNewMessage({
                            message: res?.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    resolve([]);
                    errStatusHandler(res, history, dispatch);
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    const getEventGroupData = () => {
        let bodyData = {
            in_filter: null,
            in_sort_number: 1,
            in_sort_order: "DESC",
            in_page_row_offset: 0,
            in_page_rows: 100,
        };
        POSTAPI(false, GET_EVENT_GROUP, null, header, bodyData, dispatch).then(
            (res) => {
                if (res.status) {
                    if (res.data.out_parameters.out_ret_code > 0) {
                        setEventGroupData({
                            ...eventGroupData,
                            iseventGroupDataLoading: false,
                        });
                    } else {
                        let tempArray = [{ text: "", value: "blank" }];
                        res.data.row_result.map((item) => {
                            let obj = {};
                            obj.value = item.event_group_mstr_key;
                            obj.text = item.event_group_name;
                            tempArray.push(obj);
                            return 0;
                        });
                        setoriginEventGroupData(tempArray);
                        setEventGroupData({
                            ...eventGroupData,
                            eventGroupDataValue: tempArray,
                            eventGroupValue: tempArray[0],
                            iseventGroupDataLoading: false,
                        });
                    }
                }
            }
        );
    };

    return [
        {
            data,
            columns,
            dataCount,
            setData,
            setColumns,
            isColumnMenuSearch,
            pageData,
            editData,
            page,
            pageSize,
            isAPICalling,
            setEditData,
            initialLoading,
            setPage,
            setPageData,
            setPageSize,
            setPaginationData,
            setAPICallingState,
            paginationData,
            collapsedState,
            setCollapsedState,
            group,
            setResultState,
            setGroup,
            newData,
            originalData,
            resultState,
            processedGroupData,
            fileData,
            setFileData,
            visible,
            setVisible,
            responsecode,
            responsemsg,
            eventGroupData,
            originEventGroupData,
            setEventGroupData,
            setNoDataFound,
            noDataFound,
            setRefreshLoading,
            isRefreshLoading,
            loadingState,
            setLoadState,
            setConfirmationPopup,
            confirmationPopup
        },
        {
            getResultList,
            addDataAsync,
            deleteDataAsync,
            updateDataAsync,
            getEventGroupData,
        },
    ];
}
