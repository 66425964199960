import React from "react";
import {
  SELECT_PIPELINE_LOG,
  GET_SYSTEM_CONFIG,
} from "../../../api-setup/api-endpoints";
import { getUserCredential } from "../../../lib/cookies";
import { POSTAPI, errStatusHandler } from "../../../api-setup/api-helper";
import { GridColumnDataMapper } from "../../../utils/utils";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { setNewMessage } from "../../../redux/actions/setting-actions";

export default function DataRows() {
  const cookiesData = getUserCredential();
  const header = {
    "x-access-token": cookiesData.secretToken,
  };
  const history = useHistory();
  const dispatch = useDispatch();
  const [data, setData] = React.useState([]);
  const [columns, setColumns] = React.useState([]);
  const [dataCount, setDataCount] = React.useState(0);
  const [processDataCount, setprocessDataCount] = React.useState(0);
  const [processData, setprocessData] = React.useState([]);
  const [isAPICalling, setAPICallingState] = React.useState(false);
  const [originalData, setOriginalData] = React.useState([]);
  const [isRefreshLoading, setRefreshLoading] = React.useState(false);
  const [isProcessAPICalling, setProcessAPICallingState] =
    React.useState(false);
  const [processcolumns, setProcessColumns] = React.useState([]);
  const [ruleData, setRuleData] = React.useState([]);
  const [isRuleAPICalling, setRuleAPICallingState] = React.useState(false);
  const [rulecolumns, setRuleColumns] = React.useState([]);
  const [ruleDataCount, setRuleDataCount] = React.useState(0);
  const [initialLoading, setLoadingState] = React.useState(false);
  const [phaseinitialLoading, setPhaseLoadingState] = React.useState(false);
  const [isSaveData, setSaveData] = React.useState(false);
  const [prefValue, setprefValue] = React.useState('');

  const getPhasePromiseData = (defaultData, columnData) => {
    return new Promise(function (resolve, reject) {
        setAPICallingState(false);
        setLoadingState(true);
        
        setColumns(columnData);
        setData(defaultData);
        setOriginalData(defaultData);
        setDataCount(defaultData.length);
        resolve([]);
    })
        .then((res) => {
            return res;
        })
        .catch((res) => {
            dispatch(
                setNewMessage({
                    message: "Error",
                    type: "Error",
                    show: true,
                })
            );
            // errStatusHandler(res, history, dispatch);
        });
};
  const getSysteamPrefConfig = (apiCallData) => {
    const data = {
      in_tenant_id: cookiesData.out_tenant_id,
      in_filter: "AND system_pref_code = 'sys_job_exec_log'",
      in_sort_number: null,
      in_sort_order: null,
      in_page_row_offset: null,
      in_page_rows: null,
    };
    return new Promise(function (resolve, reject) {
      POSTAPI(false, GET_SYSTEM_CONFIG, null, header, data, dispatch).then(
        (res) => {
          if (res) {
            let userData = JSON.parse(
              JSON.stringify(res.data.row_result[0]),
            );

            // getListDataAsync(apiCallData, userData.pref_value);
            localStorage.setItem('intervalDays',parseInt(parseFloat(userData.pref_value)))

            setprefValue(userData.pref_value);
          }
        },
      );
    })
      .then((res) => {
        return res;
      })
      .catch((res) => {
        if (res.data.out_parameters.out_ret_code > 0) {
          dispatch(
            setNewMessage({
              message: res.data.out_parameters.out_error_msg,
              type: "Error",
              show: true,
            }),
          );
          errStatusHandler(res, history, dispatch);
        }
      });
  };


  const getProcessListDataAsync = (listData) => {
    let logIntervalString = `AND a.start_time > DATE_SUB(NOW(), INTERVAL ${localStorage.getItem("intervalDays")} DAY)`;
  
    listData.in_filter = listData.in_filter!==null?logIntervalString+listData.in_filter:logIntervalString;
      return new Promise(function (resolve, reject) {
        POSTAPI(
          false,
          SELECT_PIPELINE_LOG,
          null,
          header,
          listData,
          dispatch,
        ).then((res) => {
          if (res) {
            if (res.data.out_parameters.out_ret_code > 0) {
              setLoadingState(true);
              errStatusHandler(res, history, dispatch);
            }
            setAPICallingState(false);
            setLoadingState(true);
            let userData = JSON.parse(JSON.stringify(res.data.row_result));
            const phaseTableObj = {};

            // if (listData.in_load_type === "grid-reload") {
            const columData = GridColumnDataMapper(
              res.data.out_parameters.out_columns_xml,
            );
            phaseTableObj["phaseColumn"] = columData;

            setColumns(columData);
            userData.map((item, idx) => {
              item.rowId = idx;
              item.selected = false;
              item.proc_ctrl_mstr_key = listData.in_proc_ctrl_mstr_key;
              if (item.job_type_code === "report_publish") {
                item.proc_ctrl_mstr_key = null;
              }
              return 0;
            });

            phaseTableObj["phaseData"] = userData;

            setData(userData);
            setDataCount(res.data.out_parameters.out_total_rows);
            setRefreshLoading(false);
            if (listData.in_init === 1) {
              resolve(phaseTableObj);
          } else {
              resolve(userData);
          }
          }
        });
      })
        .then((res) => {
          return res;
        })
        .catch((res) => {
          if (res.data.out_parameters.out_ret_code > 0) {
            dispatch(
              setNewMessage({
                message: res.data.out_parameters.out_error_msg,
                type: "Error",
                show: true,
              }),
            );
            errStatusHandler(res, history, dispatch);
          }
        });
    };
    
  return [
    {
      data,
      columns,
      dataCount,
      setData,
      setColumns,
      isAPICalling,
      setAPICallingState,
      setSaveData,
      isSaveData,
      isProcessAPICalling,
      setProcessAPICallingState,
      processData,
      setprocessData,
      processDataCount,
      setprocessDataCount,
      isRefreshLoading,
      initialLoading,
      processcolumns,
      setProcessColumns,
      setRuleColumns,
      rulecolumns,
      setRuleData,
      ruleData,
      ruleDataCount,
      isRuleAPICalling,
      setRuleAPICallingState,
      prefValue,
      setPhaseLoadingState,
      phaseinitialLoading,
      setRefreshLoading,
      originalData,
      setRuleDataCount
    },
    {
      getProcessListDataAsync,
      getSysteamPrefConfig,
      getPhasePromiseData
    },
  ];
}
