import React from "react";
import DataTable from "../components/dataTable";
import { GridColumn as Column } from "@progress/kendo-react-grid";
import { DialogComponent } from "../components/dialog";
import { POSTAPI } from "../api-setup/api-helper";
import { getUserCredential, getTenantCookieData } from "../lib/cookies";
import {
  SELECT_ROLE_AUDIT,
  GET_AUDIT_LOGS,
  GET_ETL_AUDIT_LOGS,
  GET_EVENT_TYPE_AUDIT_LOGS,
  COMP_PLAN_AUDIT,
  GET_SELECT_GRID_COLUMN_XML,
  SELECT_SPECIALTY_AUDIT,
  SELECT_GROUP_AUDIT,
  GET_CUSTOM_TABLE_AUDIT,
  GET_RATE_TABLE_AUDIT,
  GET_RATE_TABLE_TIER_AUDIT,
  GET_CALENDAR_TABLE_AUDIT,
  GET_BUSINESS_TABLE_AUDIT,
  GET_KPI_TYPE_AUDIT_LOG,
  GET_RESULT_TYPE_MASTER_AUDIT,
  GET_KPI_AUDIT,
  GET_JOB_AUDIT_LOG,
  GET_CUSTOM_TABLE_DATA,
} from "../api-setup/api-endpoints";
import {
  GridColumnDataMapper,
  dateFormateCell,
  getfilterDataString,
} from "../utils/utils";
import { DEFAULT_SORT } from "../constants/grid";
import { columnMenuProps } from "../components/customColumnMenu";
import { LoadingPanel } from "../components/loadingPanel";
import { useDispatch } from "react-redux";

const AuditLogs = (restProps) => {
  const dispatch = useDispatch();
  const defaultPageSize = getTenantCookieData("record_per_page");

  const [visible, setVisible] = React.useState(false);
  const [data, setData] = React.useState([]);
  const cookiesData = getUserCredential();
  const [columns, setColumns] = React.useState([]);
  const [dataCount, setDataCount] = React.useState(0);
  const [isColumnMenuSearch, setIsColumnMenuSearch] = React.useState(false);
  const [initialLoading, setLoadingState] = React.useState(false);
  const [isAPICalling, setAPICallingState] = React.useState(false);

  const initialSort = [
    {
      field: "user_first_name",
      dir: "asc",
    },
  ];
  const [sort, setSort] = React.useState(initialSort);
  const [noDataFound, setNoDataFound] = React.useState(false);
  const [filterData, setFilterData] = React.useState({
    isSort: false,
    isFilter: false,
    data: "",
    in_sort_number: DEFAULT_SORT.number,
    sort_order: DEFAULT_SORT.order,
  });
  //state to set and get limit, offset
  const [pageData, setPage] = React.useState({
    skip: 0,
    take: defaultPageSize.recordTake,
  });

  const [pageSize, setPageSize] = React.useState(defaultPageSize);

  const DATA_TABLE_ID = "audit-log-grid";

  const header = {
    "x-access-token": cookiesData.secretToken,
  };

  const getPageTake = () => {
    return pageData.take;
  };

  const getPageSkip = () => {
    return pageData.skip;
  };

  const getAPIData = () => {
    let apiData = {};
    apiData.in_tenant_id = cookiesData.out_tenant_id;
    apiData.in_filter = null;
    apiData.in_sort_number = 2;
    apiData.in_sort_order = "ASC";
    apiData.in_page_row_offset = 0;
    apiData.in_page_rows = defaultPageSize.recordTake;
    switch (restProps.module) {
      case "USER":
        apiData.in_user_key = restProps.selectedRowId;
        break;
      case "DTUDATA":
        apiData.in_etl_file_mstr_key = restProps.selectedRowId;
        break;
      case "EVENT-TYPE":
        apiData.in_event_type_mstr_key = restProps.selectedRowId;
        break;
      case "COMPPLAN":
        apiData.in_comp_plan_mstr_key = restProps.selectedRowId;
        break;
      case "Group":
        apiData.in_org_title_key = restProps.selectedRowId;
        break;
      case "Specialty":
        apiData.in_specialty_mstr_key = restProps.selectedRowId;
        break;
      case "DbRole":
        apiData.in_db_role_key = restProps.selectedRowId;
        break;
      case "CUSTOM-TABLE":
      case "CUSTOM-TABLE-ROWS":
      case "RATE-TABLE":
      case "RATE-TABLE-TIER_ROWS":
      case "CALENDAR":
      case "BUSINESS_GROUP":
      case "KPI_TYPE":
      case "RESULT-TYPE":
      case "KPI":
      case "JOB":
        apiData[restProps.dataItemKey] = restProps.selectedRowId;
        break;
      default:
        apiData.in_etl_file_mstr_key = restProps.selectedRowId;
        break;
    }
    return apiData;
  };

  React.useEffect(() => {
    let apiData = getAPIData();
    getAuditLogsAsync(apiData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAuditLogsAsync = (data, extraColumn) => {
    if (extraColumn && (extraColumn.isFilter || extraColumn.isSort)) {
      setIsColumnMenuSearch(true);
    } else {
      setIsColumnMenuSearch(false);
    }
    const moduleName = restProps.module;
    let api = "";
    switch (restProps.module) {
      case "USER":
        api = GET_AUDIT_LOGS;
        break;
      case "DTUDATA":
        api = GET_ETL_AUDIT_LOGS;
        break;
      case "EVENT-TYPE":
        api = GET_EVENT_TYPE_AUDIT_LOGS;
        break;
      case "COMPPLAN":
        api = COMP_PLAN_AUDIT;
        break;
      case "Group":
        api = SELECT_GROUP_AUDIT;
        break;
      case "Specialty":
        api = SELECT_SPECIALTY_AUDIT;
        break;
      case "DbRole":
        api = SELECT_ROLE_AUDIT;
        break;
      case "CUSTOM-TABLE":
        api = GET_CUSTOM_TABLE_AUDIT;
        break;
      case "CUSTOM-TABLE-ROWS":
        api = GET_CUSTOM_TABLE_DATA;
        break;
      case "RATE-TABLE":
        api = GET_RATE_TABLE_AUDIT;
        break;
      case "RATE-TABLE-TIER_ROWS":
        api = GET_RATE_TABLE_TIER_AUDIT;
        break;
      case "CALENDAR":
        api = GET_CALENDAR_TABLE_AUDIT;
        break;
      case "BUSINESS_GROUP":
        api = GET_BUSINESS_TABLE_AUDIT;
        break;
      case "KPI_TYPE":
        api = GET_KPI_TYPE_AUDIT_LOG;
        break;
      case "RESULT-TYPE":
        api = GET_RESULT_TYPE_MASTER_AUDIT;
        break;
      case "KPI":
        api = GET_KPI_AUDIT;
        break;
      case "JOB":
        api = GET_JOB_AUDIT_LOG;
        break;
      default:
        api = GET_ETL_AUDIT_LOGS;
        break;
    }

    if (moduleName === "COMPPLAN") {
      const reqData = {
        in_tenant_id: cookiesData.out_tenant_id,
        in_filter: null,
        in_filter_db_name: "procare_system",
        in_filter_table_name: "vw_comp_plan_master_audit",
        in_result_name_mstr_key: 0,
        in_event_type_mstr_key: 0,
        in_hide_column: 0,
      };
      getColumListXML(reqData);
    }
    if (moduleName === "JOB") {
      const reqData = {
        in_tenant_id: cookiesData.out_tenant_id,
        in_filter: null,
        in_filter_db_name: "procare_system",
        in_filter_table_name: "vw_job_master_audit",
        in_result_name_mstr_key: 0,
        in_event_type_mstr_key: 0,
        in_hide_column: 0,
      };
      getColumListXML(reqData);
    }
    POSTAPI(false, api, null, header, data, dispatch).then((res) => {
      if (res.status) {
        if (res.data.out_parameters.out_ret_code === 0) {
          setLoadingState(true);
          setAPICallingState(true);
          setVisible(restProps.visible);
          if (moduleName !== "COMPPLAN" && moduleName !== "JOB") {
            const columnData = GridColumnDataMapper(
              res.data.out_parameters.out_columns_xml
            );
            setColumns(columnData);
          }
          setNoDataFound(false);
          setAPICallingState(false);
          setData(JSON.parse(JSON.stringify(res.data.row_result)));
          setDataCount(res.data.out_parameters.out_total_rows);
          setIsColumnMenuSearch(false);
        } else {
          setNoDataFound(true);
          setAPICallingState(false);
        }
      }
    });
  };

  const getColumListXML = (reqData) => {
    POSTAPI(
      false,
      GET_SELECT_GRID_COLUMN_XML,
      null,
      header,
      reqData,
      dispatch
    ).then((res) => {
      if (res.data.out_parameters.out_ret_code > 0) {
        setNoDataFound(true);
      } else {
        const columData = GridColumnDataMapper(
          res.data.out_parameters.out_columns_xml
        );
        setIsColumnMenuSearch(false);
        setColumns(columData);
      }
    });
  };

  const useCol = (columns) => {
    return columns.map((column, idx) => {
      return column.show && column.title !== "" ? (
        <Column
          {...columnMenuProps(column)}
          key={idx}
          cell={
            column.field === "action_date" ||
            column.field === "period_start_date" ||
            column.field === "period_end_date"
              ? dateFormateCell
              : StringReandOnlyCell
          }
          sortable={true}
        />
      ) : (
        false
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const StringReandOnlyCell = React.useCallback((props) => {
    const { ariaColumnIndex, columnIndex, dataItem, field } = props;

    let val = dataItem[field || ""];

    if (field.indexOf("_mstr_key") !== -1) {
      val = dataItem[field.replace("_mstr_key", "_name") || ""];
    }

    const defaultRendering = (
      <td aria-colindex={ariaColumnIndex} data-grid-col-index={columnIndex}>
        <span title={val}>{val}</span>
      </td>
    );

    return defaultRendering;
  }, []);

  let AuditColumn = useCol(columns);

  const onClearFilter = () => {
    let apiData = getAPIData();
    //set in_params
    const dataVal = {
      ...apiData,
      in_filter: null,
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
      in_page_row_offset: getPageSkip(),
      in_page_rows: getPageTake(),
    };

    //set filter to false
    setFilterData({
      ...filterData,
      data: "",
      isFilter: false,
    });

    getAuditLogsAsync(dataVal);
  };

  const handleColumnMenu = async (columnMenuData, isFilter, isSort) => {
    let index = 1;
    let filterSkip = getPageSkip();
    let filterTake = getPageTake();

    if (isFilter) {
      filterSkip = 0;
      filterTake = pageSize.recordTake;

      //reset pager when filter applies
      setPage({ skip: filterSkip, take: filterTake });
    }

    if (data.length > 0 && columnMenuData[0] !== undefined) {
      let obj = Object.keys(data[0]);
      index = obj.findIndex((x) => x === columnMenuData[0].field) + 1;
    }

    const filterString = isFilter
      ? getfilterDataString(columnMenuData, columns)
      : filterData.data;

    isFilter = false;
    if (filterString !== "") {
      isFilter = true;
    }

    let sortDir =
      filterData.isSort && columnMenuData.length
        ? filterData.sort_order
        : DEFAULT_SORT.order;
    let sortNumber =
      filterData.isSort && columnMenuData.length
        ? filterData.in_sort_number
        : DEFAULT_SORT.number;

    isSort = !columnMenuData.length ? false : filterData.isSort;

    if (
      columnMenuData[0] !== undefined &&
      columnMenuData[0].dir !== undefined
    ) {
      isSort = true;
      sortDir = columnMenuData[0].dir.toUpperCase();
      sortNumber = index;
    }
    let moduleKey = restProps.dataItemKey
      ? restProps.dataItemKey
      : "in_etl_file_mstr_key";
    const dataVal = {
      in_tenant_id: cookiesData.out_tenant_id,
      [moduleKey]: restProps.selectedRowId,
      in_filter: isFilter ? filterString : null,
      in_sort_number: sortNumber,
      in_sort_order: sortDir,
      in_page_row_offset: filterSkip,
      in_page_rows: filterTake,
      in_user_key: cookiesData.out_user_id.toString(),
      in_user_role_filter: "> 0",
    };

    setFilterData({
      ...filterData,
      isFilter: isFilter,
      isSort: isSort,
      data: filterString,
      in_sort_number: sortNumber,
      sort_order: sortDir,
    });

    setAPICallingState(true);

    getAuditLogsAsync(dataVal);
    // if(deleteSelectedIds.length > 0){
    //   let ids = response.map( el => el.org_title_key );
    //   let selectedKeys = []
    //   let tempArray = []
    //   let tempArrayData =[]
    //   Object.keys(selectedState).map((item) => {
    //     let obj = {};
    //     obj.id = Number(item);
    //     selectedKeys.push(item);
    //     tempArray.push(obj)
    //     return 0;
    //   });
    //    ids.map((item)=>{
    //     if(selectedKeys.includes(item.toString())){
    //       tempArrayData.push(item)
    //     }
    //    })
    //   if(tempArrayData.length > 0){
    //     restProps.setSelectedRowId(parseInt(selectedKeys[0]));
    //   }else{
    //     restProps.setSelectedRowId('');
    //   }
    // }
  };

  const itemChange = (event) => {
    const field = event.field || "";
    const newData = data.map((item) =>
      item.user_key === event.dataItem.user_key
        ? { ...item, [field]: event.value }
        : item
    );
    setData(newData);
  };
  const getInFilter = () => {
    return filterData.isFilter ? filterData.data : null;
  };

  const getInSortNumber = () => {
    return filterData.isSort ? filterData.in_sort_number : DEFAULT_SORT.number;
  };

  const getInSortOrder = () => {
    return filterData.isSort ? filterData.sort_order : DEFAULT_SORT.order;
  };

  const handlePageChange = async (event) => {
    const pageData = event.page;

    setAPICallingState(true);
    setPage({ skip: pageData.skip, take: pageData.take });

    let apiCallData = getAPIData();

    apiCallData.in_filter = getInFilter();
    apiCallData.in_sort_number = getInSortNumber();
    apiCallData.in_sort_order = getInSortOrder();
    apiCallData.in_page_rows = pageData.take;
    apiCallData.in_page_row_offset = pageData.skip;

    getAuditLogsAsync(apiCallData);

    // restProps.setDataList(data);
    // restProps.setSelectedRowId('');
  };

  const onRefreshClick = async () => {
    setAPICallingState(true);

    let apiData = getAPIData();

    const dataVal = {
      ...apiData,
      in_filter: getInFilter(),
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
      in_page_row_offset: getPageSkip(),
      in_page_rows: getPageTake(),
    };

    await getAuditLogsAsync(dataVal);
  };

  const onClosePopup = () => {
    setVisible(!visible);
    restProps.setShow({ ...restProps.visible, auditLogs: false });
  };

  const ModalTitle = () => {
    return (
      <span>
        <i className="fas fa-layer-group"></i> Audit Logs
      </span>
    );
  };

  return (
    <>
      {initialLoading ? (
        <DialogComponent
          title={<ModalTitle />}
          className={"Dialog-dtulogs Dialog-lModal"}
          desc={
            <div className="dtu-file-container-logs">
              <DataTable
                isAPICalling={isAPICalling}
                initialLoading={initialLoading}
                data={data}
                id={DATA_TABLE_ID}
                itemChange={itemChange}
                pageData={pageData}
                dataCount={dataCount}
                sort={sort}
                customColumn={AuditColumn}
                isColumnMenuSearch={isColumnMenuSearch}
                columns={columns}
                className={"DataTable-dtuLog"}
                width={"auto"}
                gridHeight={"365px"}
                setSort={setSort}
                defaultPageSize={defaultPageSize}
                pageSize={pageSize}
                setPageSize={setPageSize}
                setPage={setPage}
                handlePageChange={handlePageChange}
                handleColumnMenu={handleColumnMenu}
                refreshClick={onRefreshClick}
                onClearFilter={onClearFilter}
                noDataFound={noDataFound}
              />
            </div>
          }
          visible={visible}
          primBtnText={"Cancel"}
          isShowOneButton={true}
          width={"70%"}
          height={520}
          onPrimButton={onClosePopup}
          onCancelConfirm={onClosePopup}
        />
      ) : (
        <LoadingPanel />
      )}
    </>
  );
};
export default React.memo(AuditLogs);
