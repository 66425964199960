import React from "react";
import { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { mainSelector } from "../redux/selectors/custom-selector";
import { useLocation, useHistory } from "react-router-dom";

const ErrorPage = () => {
    const { dbObjData, readAccessDeniend } = useSelector(
        mainSelector,
        shallowEqual
    );

    const history = useHistory();
    const location = useLocation();

    const subMenuCode = location.pathname.replace(/^\/+/, "");

    useEffect(() => {
        if (
            typeof readAccessDeniend.find((obj) => {
                return obj === subMenuCode;
            }) !== undefined &&
            dbObjData.length
        ) {
            const newRedirectCode = dbObjData?.[0]?.children?.[0]?.db_obj_code;

            history.push("/" + newRedirectCode);
        }
    }, []);

    return (
        <div className="Main error-page-container">
            <div className="content">
                <div className="content-body error-body">
                    <div className="error-message-common">
                        <h1>Oops !</h1>
                        <p>
                            The page you requested could not be found. <br />{" "}
                            Try refining your search, or use the navigation to
                            locate the page.
                        </p>
                        <button className="primarybtn">
                            <span className="k-button-text">Back to Home</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(ErrorPage);
