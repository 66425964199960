import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getUserCredential } from "../../lib/cookies";
import { POSTAPI, errStatusHandler } from "../../api-setup/api-helper";
import {
  setCompPlanFilterData,
  setCompPlanFilterDataLoading,
  setCompPlanPeriodFilterData,
  setCompPlanPeriodFilterDataLoading,
  setEventTypeFilterData,
  setEventTypeFilterDataLoading,
  setPeriodFilterData,
  setPeriodFilterDataLoading,
  setProviderFilterData,
  setProviderFilterDataLoading,
  setReportsCompDetailSection1Data,
  setReportsCompDetailSection1DataLoading,
  setReportsCompDetailSection2Data,
  setReportsCompDetailSection2DataLoading,
  setShowReportsCompDetailSection,
} from "../../redux/actions/reports-actions";
import {
  GridColumnDataMapper,
  ResultGridDataMapper_V2,
  downloadURI,
} from "../../utils/utils";
import { useHistory } from "react-router-dom";
import { setNewMessage } from "../../redux/actions/setting-actions";
import { reportsSelector } from "src/redux/selectors/custom-selector";
import { GetSystemConfigDataByCode } from "src/lib/common-service";
import { transformDateTime } from "src/lib/date-time.util";

const useReportsService = () => {
  const cookiesData = getUserCredential();
  const header = {
    "x-access-token": cookiesData.secretToken,
  };
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    compPlanFilterValue,
    compPlanPeriodFilterValue,
    providerFilterValue,
    periodFilterValue,
    eventTypeFilterValue,
    isFromProvider,
  } = useSelector(reportsSelector, shallowEqual);

  const getCompPlan = async () => {
    let reqData = {
      in_tenant_id: cookiesData.out_tenant_id,
      in_user_role_filter: ">0",
      in_user_key: cookiesData.out_user_id,
    };

    dispatch(setCompPlanFilterDataLoading(true));

    try {
      const res = await POSTAPI(
        false,
        "/reports/select-comp-detail-report-filter-comp-plan",
        null,
        header,
        reqData,
        dispatch
      );

      let value = null;
      let data = [];

      if (res.data.out_parameters.out_ret_code > 0) {
        errStatusHandler(res, history, dispatch);
      } else {
        value = compPlanFilterValue || res.data.row_result[0];
        data = res.data.row_result;
    
          dispatch(
            setCompPlanFilterData({
              compPlanFilterValue: value,
              compPlanFilterData: data,
            })
          );
      }
    } catch (error) {
      dispatch(
        setCompPlanFilterData({
          compPlanFilterValue: null,
          compPlanFilterData: [],
        })
      );
    } finally {
      dispatch(setCompPlanFilterDataLoading(false));
    }
  };

  const getCompPlanPeriods = async (compPlan) => {
    if (!compPlan && !isFromProvider) {
      dispatch(
        setCompPlanPeriodFilterData({
          compPlanPeriodFilterValue: null,
          compPlanPeriodFilterData: [],
        })
      );
      return;
    }
    let reqData = {
      in_tenant_id: cookiesData.out_tenant_id,
      in_period_year_key: 0,
      in_user_key: cookiesData.out_user_id,
      in_comp_plan_key: compPlan.comp_plan_key,
      in_filter: null,
    };
    dispatch(setCompPlanPeriodFilterDataLoading(true));

    try {
      const res = await POSTAPI(
        false,
        "/reports/select-comp-detail-report-filter-comp-plan-period",
        null,
        header,
        reqData,
        dispatch
      );

      let value = null;
      let data = [];

      if (res.data.out_parameters.out_ret_code > 0) {
        errStatusHandler(res, history, dispatch);
      } else {
        const filterItem = res.data.row_result.find(
          (it) => it.period_key === compPlanPeriodFilterValue?.period_key
        );
        value = filterItem || res.data.row_result[0];
        data = res.data.row_result;
      }

      
        dispatch(
          setCompPlanPeriodFilterData({
            compPlanPeriodFilterValue: value,
            compPlanPeriodFilterData: data,
          })
        );
    
    } catch (error) {
      dispatch(
        setCompPlanPeriodFilterData({
          compPlanPeriodFilterValue: null,
          compPlanPeriodFilterData: [],
        })
      );
    } finally {
      dispatch(setCompPlanPeriodFilterDataLoading(false));
    }
  };

  const getCompPlanProviders = async (compPlanPeriod) => {
    if (!compPlanPeriod && !isFromProvider) {
      dispatch(
        setProviderFilterData({
          providerFilterValue: null,
          providerFilterData: [],
        })
      );
      return;
    }

    let reqData = {
      in_user_key: cookiesData.out_user_id,
      in_tenant_id: cookiesData.out_tenant_id,
      in_filter: null,
      in_user_role_type_abbr: cookiesData.out_db_role_type_abbr,
      in_period_start_date: transformDateTime({
        date: compPlanPeriod.period_start_date,
      }),
      in_period_end_date: transformDateTime({
        date: compPlanPeriod.period_end_date,
      }),
      in_encrypt_key: cookiesData.out_acct_encrypt_key,
    };

    dispatch(setProviderFilterDataLoading(true));

    try {
      const res = await POSTAPI(
        false,
        "/reports/select-comp-detail-report-filter-provider",
        null,
        header,
        reqData,
        dispatch
      );

      let value = null;
      let data = [];

      if (res.data.out_parameters.out_ret_code > 0) {
        errStatusHandler(res, history, dispatch);
      } else {
        const found = res.data.row_result.find(
          (it) => it.provider_key === providerFilterValue?.provider_key
        );
        value = found || res.data.row_result[0];
        data = res.data.row_result;
      }

      
        dispatch(
          setProviderFilterData({
            providerFilterValue: value,
            providerFilterData: data,
          })
        );
      
    } catch (error) {
      dispatch(
        setProviderFilterData({
          providerFilterValue: null,
          providerFilterData: [],
        })
      );
    } finally {
      dispatch(setProviderFilterDataLoading(false));
    }
  };

  const getPeriod = async (compPlan, compPlanPeriod) => {
    if (!isFromProvider && (!compPlan || !compPlanPeriod)) {
      dispatch(
        setPeriodFilterData({
          periodFilterValue: null,
          periodFilterData: [],
        })
      );
      return;
    }
    let reqData = {
      in_tenant_id: cookiesData.out_tenant_id,
      in_period_year_key: compPlanPeriod.period_key,
      in_user_key: cookiesData.out_user_id,
      in_comp_plan_key: compPlan.comp_plan_key,
      in_filter: null,
    };

    dispatch(setPeriodFilterDataLoading(true));

    try {
      const res = await POSTAPI(
        false,
        "/reports/select-comp-detail-report-filter-comp-plan-period",
        null,
        header,
        reqData,
        dispatch
      );

      let value = null;
      let data = [];

      if (res.data.out_parameters.out_ret_code > 0) {
        errStatusHandler(res, history, dispatch);
      } else {
        const found = res.data.row_result.find(
          (it) =>
            it.period_key === periodFilterValue?.period_key ||
            it.period_name === periodFilterValue?.period_name
        );
        value = found || res.data.row_result[0];
        data = res.data.row_result;
      }

     
        dispatch(
          setPeriodFilterData({
            periodFilterValue: value,
            periodFilterData: data,
          })
        );
      
    } catch (error) {
      dispatch(
        setPeriodFilterData({
          periodFilterValue: null,
          periodFilterData: [],
        })
      );
    } finally {
      dispatch(setPeriodFilterDataLoading(false));
    }
  };

  const getEventTypes = async () => {
    let reqData = {
      in_tenant_id: cookiesData.out_tenant_id,
      in_filter: null,
      in_sort_number: "3",
      in_sort_order: "ASC",
      in_page_row_offset: 0,
      in_page_rows: 9999,
    };

    dispatch(setEventTypeFilterDataLoading(true));

    try {
      const res = await POSTAPI(
        false,
        "/tables/select-event-type",
        null,
        header,
        reqData,
        dispatch
      );

      let value = null;
      let data = [];

      if (res.data.out_parameters.out_ret_code > 0) {
        errStatusHandler(res, history, dispatch);
      } else {
        const found = res.data.row_result.find(
          (it) =>
            it.event_type_mstr_key === eventTypeFilterValue?.event_type_mstr_key
        );
        value = found || res.data.row_result[0];
        data = res.data.row_result;
      }
      dispatch(
        setEventTypeFilterData({
          eventTypeFilterValue: value,
          eventTypeFilterData: data,
        })
      );
    } catch (error) {
      dispatch(
        setEventTypeFilterData({
          eventTypeFilterValue: null,
          eventTypeFilterData: [],
        })
      );
    } finally {
      dispatch(setEventTypeFilterDataLoading(false));
    }
  };

  const getAutoHideSection1 = async () => {
    let reqData = {
      in_tenant_id: cookiesData.out_tenant_id,
      pref_code: ["auto_hide_report_sec1"],
    };
    let res = await GetSystemConfigDataByCode(reqData, history, dispatch);
    if (res) {
      let flag = res.auto_hide_report_sec1 === "0" ? true : false;
      dispatch(setShowReportsCompDetailSection(flag, 1));
    }
  };

  const getSection1 = async (providerKey, periodKey) => {
    let reqData = {
      in_tenant_id: cookiesData.out_tenant_id,
      in_provider_key: providerKey,
      in_period_key: periodKey,
      in_encrypt_key: cookiesData.out_acct_encrypt_key,
    };

    dispatch(setReportsCompDetailSection1DataLoading(true));

    try {
      const res = await POSTAPI(
        false,
        "/reports/select-comp-detail-report-header-provider",
        null,
        header,
        reqData,
        dispatch
      );

      let data = null;
      if (res.data.out_parameters.out_ret_code > 0) {
        if (res.data.out_parameters.out_ret_code !== 2) {
          dispatch(
            setNewMessage({
              message: res.data.out_parameters.out_error_msg,
              type: "Error",
              show: true,
            })
          );
        }

        errStatusHandler(res, history, dispatch);
      } else {
        data = res.data.row_result[0];
      }
      dispatch(setReportsCompDetailSection1Data(data));
    } catch (error) {
      dispatch(setReportsCompDetailSection1Data(null));
    }
  };

  const getSection2 = async (
    providerKey,
    eventTypeKey,
    compPlanKey,
    periodKey,
    periodStartDate,
    periodEndDate,
    pageOffset,
    pageSize,
    sortField,
    filterString = null,
    refreshColumns = false
  ) => {
    let reqData = {
      in_filter: filterString,
      in_groupby: "h.title_name",
      in_groupby_sort_order: "ASC",
      in_sort_number: sortField,
      in_sort_order: "ABC",
      in_provider_key: providerKey,
      in_event_type_key: eventTypeKey,
      in_comp_plan_key: compPlanKey,
      in_period_key: periodKey,
      in_period_start_date: transformDateTime({ date: periodStartDate }),
      in_period_end_date: transformDateTime({ date: periodEndDate }),
      in_page_row_offset: pageOffset,
      in_page_rows: pageSize,
      in_tenant_id: cookiesData.out_tenant_id,
      in_acct_encrypt_key: cookiesData.out_acct_encrypt_key,
    };

    dispatch(setReportsCompDetailSection2DataLoading(true));

    try {
      const res = await POSTAPI(
        false,
        "/reports/select-comp-detail-report-event-details",
        null,
        header,
        reqData,
        dispatch
      );

      let data = [];
      let columns = [];
      let totalRows = 0;

      if (res.data.out_parameters.out_ret_code > 0) {
        // dispatch(
        //     setNewMessage({
        //         message: res.data.out_parameters.out_error_msg,
        //         type: "Error",
        //         show: true,
        //     })
        // );
        errStatusHandler(res, history, dispatch);
      }

      let dataValue = ResultGridDataMapper_V2(
        res.data.out_parameters.out_results_xml
      );
      let columnValue = GridColumnDataMapper(
        res.data.out_parameters.out_columns_xml,
        null,
        true
      );

      data = dataValue?.length ? dataValue : [];
      columns = refreshColumns
        ? columnValue?.length
          ? columnValue
          : []
        : null;
      totalRows = res.data.out_parameters.out_total_rows;

      dispatch(setReportsCompDetailSection2Data(data, columns, totalRows));
    } catch (error) {
      dispatch(setReportsCompDetailSection2Data([], [], 0));
    }
  };

  const exportToPdf = async (
    providerKey,
    eventTypeKey,
    compPlanKey,
    periodKey,
    periodStartDate,
    periodEndDate,
    pageOffset,
    pageSize,
    sortField,
    filterString = null,
    refreshColumns = true
  ) => {
    let reqData = {
      in_filter: filterString,
      in_groupby: "h.title_name",
      in_groupby_sort_order: "ASC",
      in_sort_number: sortField,
      in_sort_order: "ABC",
      in_provider_key: providerKey,
      in_event_type_key: eventTypeKey,
      in_comp_plan_key: compPlanKey,
      in_period_key: periodKey,
      in_period_start_date: transformDateTime({ date: periodStartDate }),
      in_period_end_date: transformDateTime({ date: periodEndDate }),
      in_page_row_offset: pageOffset,
      in_page_rows: pageSize,
      in_tenant_id: cookiesData.out_tenant_id,
      in_acct_encrypt_key: cookiesData.out_acct_encrypt_key,
    };

    dispatch(setReportsCompDetailSection2DataLoading(true));

    try {
      const res = await POSTAPI(
        false,
        "/reports/select-comp-detail-report-event-details",
        null,
        header,
        reqData,
        dispatch
      );

      let data = [];
      let columns = [];
      let totalRows = 0;

      if (res.data.out_parameters.out_ret_code > 0) {
        errStatusHandler(res, history, dispatch);
      }

      let dataValue = ResultGridDataMapper_V2(
        res.data.out_parameters.out_results_xml
      );
      let columnValue = GridColumnDataMapper(
        res.data.out_parameters.out_columns_xml,
        null,
        true
      );

      data = dataValue?.length ? dataValue : [];
      columns = refreshColumns
        ? columnValue?.length
          ? columnValue
          : []
        : null;
      totalRows = res.data.out_parameters.out_total_rows;

      return { data, columns, totalRows };
    } catch (error) {
      //dispatch(setReportsCompDetailSection2Data([], [], 0));
    } finally {
      dispatch(setReportsCompDetailSection2DataLoading(false));
    }
  };

  const compDetailExportToExcell = async (
    providerKey,
    eventTypeKey,
    compPlanKey,
    periodKey,
    periodStartDate,
    periodEndDate,
    sortField,
    filterString = null
  ) => {
    let reqData = {
      in_user_id: cookiesData.out_user_id,
      in_filter: filterString ? filterString.replace(/[a-z]\.?\./g, "") : "",
      in_sort_number: sortField,
      in_sort_order: "ABC",
      in_provider_key: providerKey,
      in_event_type_key: eventTypeKey,
      in_comp_plan_key: compPlanKey,
      in_period_key: periodKey,
      in_period_start_date: transformDateTime({ date: periodStartDate }),
      in_period_end_date: transformDateTime({ date: periodEndDate }),
      in_tenant_id: cookiesData.out_tenant_id,
      in_acct_encrypt_key: cookiesData.out_acct_encrypt_key,
    };

    try {
      const res = await POSTAPI(
        false,
        "/reports/comp-detail-export-to-excel",
        null,
        header,
        reqData,
        dispatch
      );
      if (res) {
        if (res.status) {
          let fileData = {
            fileName: res.data.fileName,
            fileLink: res.data.fileLink,
          };
          setTimeout(async () => {
            await downloadURI(fileData.fileName, fileData.fileLink);
          }, 1000);
        } else {
          dispatch(
            setNewMessage({
              message: res.data.msg,
              type: "Error",
              show: true,
            })
          );
          errStatusHandler(res, history, dispatch);
        }
      }
    } catch (error) {}
  };

  const compDetailUpdateColumnOrder = async (columnString) => {
    let reqData = {
      in_db_name: "procare_system",
      in_tenant_id: cookiesData.out_tenant_id,
      in_filter_logical_cond: "Y",
      in_filter_label_cond: "N",
      in_column_info: columnString,
    };
    try {
      const res = await POSTAPI(
        false,
        "/reports/comp-detail-update-column-order",
        null,
        header,
        reqData,
        dispatch
      );
      if (res.data.out_parameters.out_ret_code > 0) {
        dispatch(
          setNewMessage({
            message: res.data.out_parameters.out_error_msg,
            type: "Error",
            show: true,
          })
        );
        errStatusHandler(res, history, dispatch);
      }
    } catch (error) {
      // Do nothing
    }
  };

  return {
    getCompPlan,
    getCompPlanPeriods,
    getPeriod,
    getCompPlanProviders,
    getEventTypes,
    getAutoHideSection1,
    getSection1,
    getSection2,
    exportToPdf,
    compDetailExportToExcell,
    compDetailUpdateColumnOrder,
  };
};

export default useReportsService;
