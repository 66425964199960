import { bind } from "@react-rxjs/core";
import { createSignal } from "@react-rxjs/utils";

export const [dashboardPod$, setDashboardPod] = createSignal();

export const [getDashboardPod, getDashboardPod$] = bind(dashboardPod$, {});

export const [dashboardAddPodDialog$, setDashboardAddPodDialog] =
    createSignal();

export const [getDashboardAddPodDialog, getDashboardAddPodDialog$] = bind(
    dashboardAddPodDialog$,
    {}
);

export const [dashboardEditPodDialog$, setDashboardEditPodDialog] =
    createSignal();

export const [getDashboardEditPodDialog, getDashboardEditPodDialog$] = bind(
    dashboardEditPodDialog$,
    {}
);

export const MapAttributes = {
    mapListData: (dataObj = {}, responseKey = "") => {
        return dataObj?.[String(responseKey)] || null;
    },
};

export const mapDashboardPodData = {
    name: {
        key: "name",
        label: "Name",
        getData: (data) => MapAttributes.mapListData(data, "label_name"),
    },
    tabId: {
        key: "dashboardTabId",
        label: "Dashboard Tab Master Key",
        getData: (data) =>
            MapAttributes.mapListData(data, "dashboard_tab_mstr_key"),
    },
    dashboardTypeName: {
        key: "dashboardTypeName",
        label: "Dashboard Type Name",
        getData: (data) =>
            MapAttributes.mapListData(data, "dashboard_type_name"),
    },
    dashboardThemeName: {
        key: "dashboardTheme",
        label: "Dashboard Theme Name",
        getData: (data) => MapAttributes.mapListData(data, "theme"),
    },
    dashboardTypeMstrKey: {
        key: "dashboardTypeMstrKey",
        label: "Dashboard Type Master Key",
        getData: (data) =>
            MapAttributes.mapListData(data, "dashboard_type_mstr_key"),
    },
    periodTypeMstrKey: {
        key: "periodTypeMstrKey",
        label: "Period Type Master Key",
        getData: (data) =>
            MapAttributes.mapListData(data, "period_type_mstr_key"),
    },
    reportPublishedFlag: {
        key: "reportPublishedFlag",
        label: "Report Published",
        getData: (data) =>
            MapAttributes.mapListData(data, "is_report_published"),
    },
    dashboardPodKey: {
        key: "dashboardPodKey",
        label: "Dashboard Pod Key",
        getData: (data) => MapAttributes.mapListData(data, "dashboard_pod_key"),
    },
    periodEndDate: {
        key: "periodEndDate",
        label: "Period End Date",
        getData: (data) => MapAttributes.mapListData(data, "period_end_date"),
    },
    periodStartDate: {
        key: "periodStartDate",
        label: "Period Start Date",
        getData: (data) => MapAttributes.mapListData(data, "period_start_date"),
    },
    providerFilter: {
        key: "providerFilter",
        label: "Provider Filter",
        getData: (data) => MapAttributes.mapListData(data, "provider_filter"),
    },
    specialtyFilter: {
        key: "specialtyFilter",
        label: "Specialty Filter",
        getData: (data) => MapAttributes.mapListData(data, "specialty_filter"),
    },
    groupFilter: {
        key: "groupFilter",
        label: "Group Filter",
        getData: (data) => MapAttributes.mapListData(data, "group_filter"),
    },
    providerFilterLabel: {
        key: "providerFilterLabel",
        label: "> Provider: ",
        getData: (data) =>
            MapAttributes.mapListData(data, "provider_filter_label"),
    },
    specialtyFilterLabel: {
        key: "specialtyFilterLabel",
        label: "> Specialty: ",
        getData: (data) =>
            MapAttributes.mapListData(data, "specialty_filter_label"),
    },
    groupFilterLabel: {
        key: "groupFilterLabel",
        label: "> Group: ",
        getData: (data) =>
            MapAttributes.mapListData(data, "group_filter_label"),
    },
};

export const mapDashboardComplanData = {
    compPlanMstrKeys: {
        key: "compPlanMstrKeys",
        label: "compPlanMstrKeys",
        getData: (data) =>
            MapAttributes.mapListData(data, "comp_plan_mstr_keys"),
    },
};

export const mapDashboardDataSet = {
    dataName: {
        key: "dataName",
        label: "Data Name",
        getData: (data) => MapAttributes.mapListData(data, "dataset_name"),
    },
    aggregationType: {
        key: "aggregationType",
        label: "Aggregation Type",
        getData: (data) => MapAttributes.mapListData(data, "aggregation"),
    },
    chartType: {
        key: "Chart Type",
        label: "chartType",
        getData: (data) => MapAttributes.mapListData(data, "chart_type"),
    },
    groupDimension: {
        key: "groupDimension",
        label: "Group Dimension",
        getData: (data) => MapAttributes.mapListData(data, "group_dimension"),
    },
    isStacked: {
        key: "isStacked",
        label: "Is Stacked",
        getData: (data) => MapAttributes.mapListData(data, "is_stacked"),
    },
    groupByResult: {
        key: "groupByResult",
        label: "Group By Result",
        getData: (data) =>
            MapAttributes.mapListData(data, "is_group_by_result"),
    },
    compPlanFilter: {
        key: "compPlanFilter",
        label: "Comp Plan Filter",
        getData: (data) =>
            MapAttributes.mapListData(data, "comp_plan_mstr_key"),
    },
    kpiFilter: {
        key: "kpiFilter",
        label: "KPI Filter",
        getData: (data) => MapAttributes.mapListData(data, "kpi_mstr_key"),
    },
    resultFilterX: {
        key: "resultFilterX",
        label: "Result Filter X",
        getData: (data) =>
            MapAttributes.mapListData(data, "result_name_mstr_key_x"),
    },
    resultFilterY: {
        key: "resultFilterY",
        label: "Result Filter Y",
        getData: (data) =>
            MapAttributes.mapListData(data, "result_name_mstr_key_y"),
    },
    resultFilterZ: {
        key: "resultFilterZ",
        label: "Result Filter Z",
        getData: (data) =>
            MapAttributes.mapListData(data, "result_name_mstr_key_z"),
    },
};

export const mapDashboardData = {
    categoryAxis: {
        key: "Category Axis",
        label: "categoryAxis",
        getData: (data) => MapAttributes.mapListData(data, "category_axis"),
    },
    datasetIndex: {
        key: "Dataset Index",
        label: "datasetIndex",
        getData: (data) => MapAttributes.mapListData(data, "dataset_index"),
    },
    periodStartDate: {
        key: "Period Start Date",
        label: "periodStartDate",
        getData: (data) => MapAttributes.mapListData(data, "period_start_date"),
    },
    seriesData: {
        key: "Series Data",
        label: "seriesData",
        getData: (data) => MapAttributes.mapListData(data, "series_data"),
    },
    seriesName: {
        key: "Series Name",
        label: "seriesName",
        getData: (data) => MapAttributes.mapListData(data, "series_name"),
    },
};

export const [dashboardPublishEnable$, setDashboardPublishEnable] = createSignal();

export const [getDashboardPublishEnable, getDashboardPublish$] = bind(dashboardPublishEnable$, {});
