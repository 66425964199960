/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable-next-line no-useless-concat
import * as React from "react";

import { GridColumn as Column } from "@progress/kendo-react-grid";
import { columnMenuProps } from "../../components/customColumnMenu";
import DataTable from "../../components/dataTable";
import { DialogComponent } from "../../components/dialog";
import { CellRender, RowRender } from "../../components/renderers";
import { DELETE_BTN_ACTIONS } from "../../constants/constants";
import { DEFAULT_SORT } from "../../constants/grid";
import { getUserCredential, getTenantCookieData } from "../../lib/cookies";
import { getfilterDataString } from "../../utils/utils";
import useApi from "./service";
import EffDataRowsApi from "../../components/effectiveVesionService";
import {
    GET_RATE_TABLE_EFF_DATES,
    INSERT_RATE_TABLE,
    DELETE_RATE_TABLE,
} from "../../api-setup/api-endpoints";
import { EffectiveVersions } from "../../components/effectiveVersions";
import TableData from "./dataRows/dataRows";
import useDataRowApi from "./dataRows/service";
import { FieldWrapper, Form, FormElement } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import { Error, Label } from "@progress/kendo-react-labels";
import CustomDropdown from "../../components/customDropdown";
import MessageSchema from "src/utils/messageSchema";
// import { useDispatch, useStore } from "react-redux";
// import { setUnSavedChangesPopup } from "src/redux/actions/setting-actions";
import { Window, WindowActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";

const DATA_ITEM_KEY = "rate_table_mstr_key";

const initialFormFields = {
    in_rate_table_name: "",
    in_rate_table_desc: "",
    in_rate_table_unittype: "",
};

const loadingPanel = (
    <div className="k-loading-mask">
        <span className="k-loading-text">Loading</span>
        <div className="k-loading-image"></div>
        <div className="k-loading-color"></div>
    </div>
);

const ModalTitle = () => {
    return <span>Edit Rate Table</span>;
};

export const RateTablesData = (restProps) => {
    const cookiesData = getUserCredential();
   // const dispatch = useDispatch();
   // const store = useStore();
    const defaultPageSize = getTenantCookieData("record_per_page");
    const apiCallData = {
        in_tenant_id: cookiesData.out_tenant_id,
        in_filter: null,
        in_sort_number: DEFAULT_SORT.number,
        in_sort_order: DEFAULT_SORT.order,
        in_page_row_offset: 0,
        in_page_rows: defaultPageSize.recordTake,
        in_user_role_filter: "> 0",
        in_load_type: "data-reload",
    };

    const tierApiCallData = {
        in_tenant_id: cookiesData.out_tenant_id,
        in_rate_table_mstr_key: 0,
        in_date_version: 0,
        in_filter: null,
        in_sort_number: DEFAULT_SORT.number,
        in_sort_order: DEFAULT_SORT.order,
        in_page_row_offset: 0,
        in_page_rows: defaultPageSize.recordTake,
        in_user_role_filter: "> 0",
        in_load_type: "grid-reload",
        in_init: 1,
    };

    const [list, Action] = useApi();
    const [listVersion, ActionVersion] = EffDataRowsApi();
    const [, ActionDatarow] = useDataRowApi();

    const [deleteSelectedIds, setDeleteSelectedIds] = React.useState([]);
    const {
        data,
        columns,
        dataCount,
        setData,
        isAPICalling,
        setConfirmationPopup,
        confirmationPopup,
        setAPICallingState,
        initialLoading,
        isRefreshLoading,
        setRefreshLoading,
        unitTypeDataSource,
    } = list;
    
    //state to set and get limit, offset
    const [pageData, setPage] = React.useState({
        skip: 0,
        take: defaultPageSize.recordTake,
    });

    const [pageSize, setPageSize] = React.useState(defaultPageSize);

    //state to set and get filter, sort number, sort order
    const [filterData, setFilterData] = React.useState({
        isSort: false,
        isFilter: false,
        data: "",
        in_sort_number: DEFAULT_SORT.number,
        sort_order: DEFAULT_SORT.order,
    });

    const [exportData, setExportData] = React.useState([]);
    const [errorObj, setErrorObj] = React.useState({});
    const [gridColumns, setGridColumns] = React.useState([]);

    const [effVersionState, setEffVersionState] = React.useState({
        changeVersion: { rowId: -1, versionIdx: -1 },
        insertVersion: { rowId: -1, periodKey: 0 },
        deleteVersion: { rowId: -1 },
    });
    const [tierApiCallDataState, setTierApiCallDataState] =
        React.useState(tierApiCallData);
    const [refreshTableKey, setRefreshTableKey] = React.useState({
        refreshData: 0,
    });
    const [openEditPopup, setOpenEditPopup] = React.useState({
        isOpenPopup: false,
        editData: {},
    });
    const [isSaveClick, setSaveClick] = React.useState(false);
    const [formFields, setFormFields] = React.useState({
        ...initialFormFields,
    });
    const [addUnitTypeFields, setAddUnitTypeFields] = React.useState({
        unitTypeValue: {
            id: "",
            text: "",
        },
        unitTypeData: [],
    });
    const [isEdit, setIsEdit] = React.useState(false);
    const [stageChange, setStateChange] = React.useState('DEFAULT')
    const [modal, setModal] = React.useState(true)

    React.useEffect(() => {
        restProps.setDataList(data);
        Action.getUnitTypeData();
    }, [data]);

    React.useEffect(() => {
        if (restProps.isAPICall) {
            restProps.setAPICall(false);
            onRefreshClick();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [restProps.isAPICall]);

    React.useEffect(() => {
        if (restProps.isAddNewConfig) {
            restProps.setNewConfig(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [restProps.isAddNewConfig]);

    React.useEffect(() => {
        if (list.loadingState.isDeleteData) {
            list.setLoadState({ ...list.loadingState, isDeleteData: false });
            onRefreshClick();
        }
    }, [list.loadingState.isDeleteData]);

    React.useEffect(() => {
        if (restProps.isShow.deleteRows) {
            remove();
            restProps.setShow({ ...restProps.isShow, deleteRows: false });
        }
    }, [restProps.isShow.deleteRows]);

    React.useEffect(() => {
        if (columns.length > 0) {
            restProps.setDataList(data);
            restProps.setColumnsData(columns);
            setGridColumns(columns);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columns]);

    React.useEffect(() => {
        const dataVal = {
            ...apiCallData,
            in_load_type: "grid-reload",
        };

        Action.getListDataAsync(dataVal);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (restProps.isCheckColumn) {
            setGridColumns(restProps.columnsData);
            restProps.setColumnCheck(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [restProps.isCheckColumn]);

    //change eff version
    React.useEffect(async () => {
        let masterTableData = data.slice();
        if (masterTableData.length > 0) {
            const versionState = effVersionState.changeVersion;
            const mstrRow = masterTableData[versionState.rowId];
            let thisEffVersionDeta = mstrRow.effVersionDetails.data;

            thisEffVersionDeta
                .filter((x) => x.isSelected === 1)
                .map((item) => {
                    item.isSelected = 0;
                    return 0;
                });

            const newSelectedVersion =
                thisEffVersionDeta[versionState.versionIdx];

            newSelectedVersion.isSelected = 1;

            const tierDataVal = {
                ...tierApiCallData,
                in_rate_table_mstr_key: mstrRow.rate_table_mstr_key,
                in_date_version: newSelectedVersion.dateVersion,
            };
            const tierData = await ActionDatarow.getListDataAsync(tierDataVal);

            mstrRow.effVersionDetails.tierData = tierData;

            setData(masterTableData);
        }
    }, [effVersionState.changeVersion]);

    /**
     * call delete eff version API
     */
    React.useEffect(() => {
        let masterTableData = data.slice();
        const deleteVersion = effVersionState.deleteVersion;

        if (masterTableData.length > 0 && deleteVersion.rowId !== -1) {
            const mstrDeta = masterTableData[deleteVersion.rowId];
            const thisEffVersionDeta = mstrDeta.effVersionDetails.data;

            const selectedVersion = thisEffVersionDeta.filter(
                (x) => x.isSelected === 1
            )[0];

            const bodyData = {
                in_user_id: cookiesData.out_user_id,
                in_version_data: [
                    {
                        mstr_key: mstrDeta.rate_table_mstr_key,
                        date_version: selectedVersion.dateVersion,
                    },
                ],
                in_tenant_id: cookiesData.out_tenant_id,
            };

            ActionVersion.deleteDataAsync({
                api: DELETE_RATE_TABLE,
                bodyData: bodyData,
            });
        }
    }, [effVersionState.deleteVersion]);

    /**
     * call add eff version API
     */
    React.useEffect(() => {
        let masterTableData = data.slice();
        const insertVersion = effVersionState.insertVersion;

        if (masterTableData.length > 0 && insertVersion.periodKey) {
            const mstrDeta = masterTableData[insertVersion.rowId];

            const bodyData = {
                in_user_id: cookiesData.out_user_id,
                in_tenant_id: cookiesData.out_tenant_id,
                in_rate_table_mstr_key: mstrDeta.rate_table_mstr_key,
                in_rate_table_name: mstrDeta.rate_table_name,
                in_rate_table_desc: mstrDeta.rate_table_desc,
                in_rate_table_unittype: mstrDeta.system_unit_mstr_key,
                in_period_mstr_key: insertVersion.periodKey,
            };

            ActionVersion.addDataAsync({
                api: INSERT_RATE_TABLE,
                bodyData: bodyData,
            });
        }
    }, [effVersionState.insertVersion]);

    /**
     * update dataset after insert a new version
     */
    React.useEffect(async () => {
        if (listVersion.loadingState.isSaveData === true) {
            let masterTableData = data.slice();

            const mstrIdx = effVersionState.insertVersion.rowId;
            const mstrDeta = masterTableData[mstrIdx];

            let effVersionResponse = await getEffVersionsApi(
                mstrDeta.rate_table_mstr_key
            );

            masterTableData[mstrIdx].effVersionDetails = effVersionResponse;

            setEffVersionState({
                ...effVersionState,
                insertVersion: { rowId: -1, periodKey: 0 },
            });
            setData(masterTableData);
        }
    }, [listVersion.loadingState.isSaveData]);

    /**
     * update dataseta fter delete an existing version
     */
    React.useEffect(async () => {
        if (listVersion.loadingState.isDeleteData === true) {
            let masterTableData = data.slice();

            const mstrIdx = effVersionState.deleteVersion.rowId;
            const mstrDeta = masterTableData[mstrIdx];

            let effVersionResponse = await getEffVersionsApi(
                mstrDeta.rate_table_mstr_key
            );

            if (!effVersionResponse.data.length) {
                onRefreshClick();
            } else {
                masterTableData[mstrIdx].effVersionDetails = effVersionResponse;
            }

            setEffVersionState({
                ...effVersionState,
                deleteVersion: { rowId: -1 },
            });
            setData(masterTableData);
        }
    }, [listVersion.loadingState.isDeleteData]);

    React.useEffect(async () => {
        if (tierApiCallDataState.in_rate_table_mstr_key > 0) {
            setRefreshLoading(true);
            const tierDataVal = {
                ...tierApiCallData,
                in_rate_table_mstr_key:
                    tierApiCallDataState.in_rate_table_mstr_key,
                in_date_version: tierApiCallDataState.in_date_version,
            };

            let response = await ActionDatarow.getListDataAsync(tierDataVal);

            let masterTableData = data.slice();

            let mstrIdx = masterTableData.findIndex(
                (d) =>
                    d.rate_table_mstr_key ===
                    tierApiCallDataState.in_rate_table_mstr_key
            );

            const mstrDeta = masterTableData[mstrIdx];

            mstrDeta.effVersionDetails.tierData.rateTierData =
                response.rateTierData;

            setTierApiCallDataState(tierApiCallData);
            setData(masterTableData);
            setRefreshLoading(false);
        }
    }, [tierApiCallDataState]);

    const getInFilter = () => {
        return filterData.isFilter ? filterData.data : null;
    };

    const getInSortNumber = () => {
        return filterData.isSort
            ? filterData.in_sort_number
            : DEFAULT_SORT.number;
    };

    const getInSortOrder = () => {
        return filterData.isSort ? filterData.sort_order : DEFAULT_SORT.order;
    };

    const getPageTake = () => {
        return pageData.take;
    };

    const getPageSkip = () => {
        return pageData.skip;
    };

    const remove = (ids) => {
        setConfirmationPopup({
            ...confirmationPopup,
            isVisible: true,
            type: "DELETE_CONFIRM",
            actionBtn: DELETE_BTN_ACTIONS(onCancelConfirm, onDeleteConfirm),
            data: [],
            action: "Delete",
        });
    };

    const onDeleteConfirm = (event) => {
        const deleteArray = deleteSelectedIds.map((obj2) => {
            const obj1 = data.find(
                (elem) => elem.rate_table_mstr_key === obj2.mstr_key
            );
            return {
                mstr_key: obj2.mstr_key,
                rateTableName: obj1.rate_table_name,
                date_version: obj1.date_version,
            };
        });
        let deleteData = {
            in_user_id: cookiesData.out_user_id,
            in_tenant_id: cookiesData.out_tenant_id,
            in_version_data: deleteArray,
        };

        Action.deleteDataAsync(deleteData);

        setDeleteSelectedIds([]);
        setErrorObj({});
        restProps.setSelectedRowId("");
    };

    const onCancelConfirm = (action) => {
        setConfirmationPopup({
            ...confirmationPopup,
            isVisible: false,
            type: "",
            actionBtn: [],
            data: {},
            action: "",
        });
    };

    const onItemSelected = (props ,e) => {
        // const { dataItem: dataObj, nativeEvent: e } = dataItem;
        let dataObj = props.dataItem
        let obj1 = data.find(
            (elem) => elem.rate_table_mstr_key === dataObj.rate_table_mstr_key
        );

        if (obj1.expanded === true && obj1.selected === true) {
            return false;
        }

        const isCtrlKey = e?.ctrlKey;

        if (!isCtrlKey) {
            data.map((item) => {
                item.selected = false;
                return 0;
            });
        }

        obj1.selected = isCtrlKey ? !obj1.selected : true;

        let tempArray = [];
        const trueStatus = data.filter((elem) => elem.selected === true);

        trueStatus.map((item) => {
            let obj = {};
            obj.mstr_key = item.rate_table_mstr_key;

            tempArray.push(obj);
            return 0;
        });

        setDeleteSelectedIds(tempArray);
        setData(data);
        if (tempArray.length) {
            restProps.setSelectedRowId(dataObj.rate_table_mstr_key);
        } else {
            restProps.setSelectedRowId("");
        }
        restProps.setDataList(data);
    };

    const onRowClick = (dataItem,e) => {
     //   const { dataItem: dataObj } = dataItem;
        // const isSame = isSameRow(
        //     [dataObj.rate_table_mstr_key],
        //     [restProps.selectedRowId]
        // );
// TODO : UNSAVED CHANGES
    
        // if (store.getState()?.OTHER?.unsavedPopup?.isUnSavedChange && !isSame) {
        //     dispatch(
        //         setUnSavedChangesPopup({
        //             ...store.getState()?.OTHER?.unsavedPopup,
        //             isVisible: true,
        //             callbackFn: () => {
        //                 onItemSelected(dataItem);
        //             },
        //         })
        //     );
        // } else {
        //     onItemSelected(dataItem);
        // }
        onItemSelected(dataItem.originalProps,e);
    };

    const setPageMenuState = (response) => {
        if (!deleteSelectedIds.length) {
            return false;
        }

        /*
        let tempArray = [];

        deleteSelectedIds.map((item) => {
            const selectedObj = response.find(
                (elem) => elem.rate_table_mstr_key === item.mstr_key
            );

            if (selectedObj) {
                selectedObj.selected = true;

                let obj = {};
                obj.mstr_key = item.mstr_key;

                tempArray.push(obj);
            }

            return 0;
        });

        setDeleteSelectedIds(tempArray);
        if (tempArray.length) {
            const lastSelectedId = tempArray[tempArray.length - 1];
            restProps.setSelectedRowId(lastSelectedId.mstr_key);
        } else {
            restProps.setSelectedRowId("");
        }
        */
        setDeleteSelectedIds([]);
        setData(response);
        restProps.setSelectedRowId("");
        restProps.setDataList(response);
    };

    const handlePageChange = async (event) => {
        const pageData = event.page;

        setAPICallingState(true);

        setPage({ skip: pageData.skip, take: pageData.take });

        const dataVal = {
            ...apiCallData,
            in_filter: getInFilter(),
            in_sort_number: getInSortNumber(),
            in_sort_order: getInSortOrder(),
            in_page_row_offset: pageData.skip,
            in_page_rows: pageData.take,
        };

        getGridData(dataVal);
    };

    const handleColumnMenu = (columnMenuData, isFilter, isSort) => {
        let index = 1;
        let filterSkip = getPageSkip();
        let filterTake = getPageTake();

        if (isFilter) {
            filterSkip = 0;
            filterTake = pageSize.recordTake;

            //reset pager when filter applies
            setPage({ skip: filterSkip, take: filterTake });
        }

        if (data.length > 0 && columnMenuData[0] !== undefined) {
            let obj = Object.keys(data[0]);
            let sortNameField = "";

            //replace mstr key with name field only when sorting is applied
            if (
                columnMenuData[0].field &&
                columnMenuData[0].field.indexOf("_mstr_key")
            ) {
                let sortKeyField = columnMenuData[0].field;
                sortNameField = sortKeyField.replace("_mstr_key", "_name");
            }

            const sortOriginField =
                sortNameField !== "" ? sortNameField : columnMenuData[0].field;

            index = obj.findIndex((x) => x === sortOriginField) + 1;
        }

        const filterString = isFilter
            ? getfilterDataString(columnMenuData, gridColumns)
            : filterData.data;

        isFilter = false;
        if (filterString !== "") {
            isFilter = true;
        }

        let sortDir =
            filterData.isSort && columnMenuData.length
                ? filterData.sort_order
                : DEFAULT_SORT.order;
        let sortNumber =
            filterData.isSort && columnMenuData.length
                ? filterData.in_sort_number
                : DEFAULT_SORT.number;

        isSort = !columnMenuData.length ? false : filterData.isSort;

        if (
            columnMenuData[0] !== undefined &&
            columnMenuData[0].dir !== undefined
        ) {
            isSort = true;
            sortDir = columnMenuData[0].dir.toUpperCase();
            sortNumber = index;
        }

        const dataVal = {
            ...apiCallData,
            in_filter: isFilter ? filterString : null,
            in_sort_number: sortNumber,
            in_sort_order: sortDir,
            in_page_row_offset: filterSkip,
            in_page_rows: filterTake,
        };

        setFilterData({
            ...filterData,
            isFilter: isFilter,
            isSort: isSort,
            data: filterString,
            in_sort_number: sortNumber,
            sort_order: sortDir,
        });

        setAPICallingState(true);

        getGridData(dataVal);
    };

    const UnitTypeCell = React.useCallback(
        (props) => {
            const { ariaColumnIndex, columnIndex, dataItem, field, render } =
                props;

            let val = dataItem[field || ""];

            const nameField = field.replace("_mstr_key", "_name");
            if (field.indexOf("_mstr_key") !== -1) {
                val = dataItem[nameField || ""];
            }

            const defaultRendering = (
                <td
                    // onDoubleClick={}
                    aria-colindex={ariaColumnIndex}
                    data-grid-col-index={columnIndex}
                >
                    <span title={val}>{val}</span>
                </td>
            );

            return render?.call(undefined, defaultRendering, props);
        },
        [errorObj, restProps.unitTypeDataSource]
    );

    const StringReadAndOnlyCell = React.useCallback((props) => {
        const { ariaColumnIndex, columnIndex, dataItem, field } = props;

        let val = dataItem[field || ""];

        const defaultRendering = (
            <td aria-colindex={ariaColumnIndex} data-grid-col-index={columnIndex}>
                <span title={val}>{val}</span>
            </td>
        );

        return defaultRendering;
    }, []);


    const useCol = (columns) => {
        return columns.map((column, idx) => {
            return column.show && column.title !== "" ? (
                <Column
                    {...columnMenuProps(column)}
                    key={idx}
                    cell={
                        column.field === "system_unit_mstr_key"
                            ? UnitTypeCell
                            : StringReadAndOnlyCell
                    }
                    sortable={true}
                />
            ) : (
                false
            );
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    let GroupColumn = useCol(gridColumns);

    const onClearFilter = async () => {
        setAPICallingState(true);

        //set in_params
        const dataVal = {
            ...apiCallData,
            in_filter: null,
            in_sort_number: getInSortNumber(),
            in_sort_order: getInSortOrder(),
            in_page_row_offset: getPageSkip(),
            in_page_rows: getPageTake(),
        };

        //set filter to false
        setFilterData({
            ...filterData,
            data: "",
            isFilter: false,
        });

        getGridData(dataVal);
    };
    const getGridData = async (dataVal) => {
        let response = await Action.getListDataAsync(dataVal);
        setData([...response]);
        setPageMenuState(response);
        setAPICallingState(false);
    };

    const onRefreshClick = () => {
        setAPICallingState(true);

        const dataVal = {
            ...apiCallData,
            in_filter: getInFilter(),
            in_sort_number: getInSortNumber(),
            in_sort_order: getInSortOrder(),
            in_page_row_offset: getPageSkip(),
            in_page_rows: getPageTake(),
        };
        getGridData(dataVal);
    };

    const customRowRender = (tr, props) => (
        <RowRender originalProps={props} tr={tr}
        onRowDoubleClick={onRowDoubleClick}
        onRowClick={(props,e)=>onRowClick(props,e)}
        title={"Double Click to Rate Table"}
        />
    );

    const getExportData = async () => {
        const dataVal = {
            ...apiCallData,
            in_page_row_offset: 0,
            in_page_rows: dataCount,
            in_filter: getInFilter(),
            in_sort_number: getInSortNumber(),
            in_sort_order: getInSortOrder(),
        };

        let res = await Action.exportDataAsync(dataVal);

        if (res) {
            setExportData(res);
        }
        setDeleteSelectedIds([]);
        setErrorObj({});
        restProps.setSelectedRowId("");
    };

    const customCellRender = (td, props) => {
        return <CellRender navigatable={true} originalProps={props} td={td} />;
    };

    /**
     * call list eff version API
     */
    const getEffVersionsApi = async (mstrKey) => {
        setRefreshLoading(true);

        const versionProps = {
            apiEndPoints: { listApi: GET_RATE_TABLE_EFF_DATES },
            dataItem: { mstr_key: mstrKey },
            pageProps: { writeAccess: restProps.pageProps.writeAccess },
        };

        const versionApiCallData = {
            in_tenant_id: cookiesData.out_tenant_id,
            in_mstr_key: mstrKey,
            in_sort_number: 2,
            in_sort_order: "ASC",
        };

        //get response for eff version tab
        let effVersionResponse = await ActionVersion.getListDataAsync(
            versionApiCallData,
            versionProps
        );

        const currentEndDate = effVersionResponse.currentVersionEndDate;

        //get response for eff version period menu
        const periodDataResponse = await ActionVersion.getPeriodsDataTypeAsync(
            currentEndDate
        );

        effVersionResponse["periodsData"] = periodDataResponse;

        const selectedVersion = effVersionResponse.data.filter(
            (x) => x.isSelected === 1
        )[0];

        let tierData = {};
        if (selectedVersion) {
            const tierDataVal = {
                ...tierApiCallData,
                in_rate_table_mstr_key: mstrKey,
                in_date_version: selectedVersion.dateVersion,
            };

            tierData = await ActionDatarow.getListDataAsync(tierDataVal);
        }

        effVersionResponse["tierData"] = tierData;

        setRefreshLoading(false);

        return effVersionResponse;
    };

    const DetailComponent = React.memo((props) => {
        return props.dataItem ? (
            <>
                <EffectiveVersions
                    pageProps={restProps.pageProps}
                    versionData={props.dataItem}
                    effVersionState={effVersionState}
                    setEffVersionState={setEffVersionState}
                />
                {props.dataItem.effVersionDetails.tierData ? (
                    <TableData
                        pageProps={restProps.pageProps}
                        versionData={props.dataItem}
                        tierApiCallData={tierApiCallData}
                        tierApiCallDataState={tierApiCallDataState}
                        setTierApiCallDataState={setTierApiCallDataState}
                        setRefreshTableKey={setRefreshTableKey}
                    />
                ) : null}
            </>
        ) : null;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const collapseRows = () => {
        data.filter((d) => d.selected === true).map((item) => {
            item.selected = false;
            return 0;
        });

        const newData = data.filter((d) => d.expanded === true);

        if (newData.length) {
            newData.map((item) => {
                item.expanded = false;
                return 0;
            });
            setData([...data]);
        }
    };

    const expandChange = async (event) => {
        data.filter((d) => d.selected === true).map((item) => {
            item.selected = false;
            return 0;
        });

        let mstrKey = event.dataItem.rate_table_mstr_key;
        let masterTableData = data.slice();

        let index = masterTableData.findIndex(
            (d) => d.rate_table_mstr_key === mstrKey
        );

        masterTableData
            .filter((x) => x.expanded === true)
            .map((item) => {
                item.expanded = false;
                return 0;
            });

        /**
         * do not set dataItem when collapse or re-expand
         */
        if (!event.value || event.dataItem.effVersionDetails) {
            if (event.value === true && refreshTableKey === mstrKey) {
                setRefreshTableKey(0);
            } else {
                masterTableData[index].expanded = event.value;
                masterTableData[index].selected = true;
                setData(masterTableData);

                restProps.setSelectedRowId(mstrKey);
                restProps.setDataList(data);
                return;
            }
        }

        onItemSelected(event);
        let effVersionDataItem = await getEffVersionsApi(mstrKey);

        masterTableData[index].effVersionDetails = effVersionDataItem;
        masterTableData[index].expanded = event.value;
        masterTableData[index].selected = true;

        setData(masterTableData);

        restProps.setSelectedRowId(mstrKey);
        restProps.setDataList(data);
    };

    const onRowDoubleClick = (props) => {
        const { dataItem } = props.originalProps;
        // TODO: UNSAVED CHANGES
        // if (store.getState().OTHER.unsavedPopup.isUnSavedChange) {
        //     dispatch(
        //         setUnSavedChangesPopup({
        //             ...store.getState().OTHER.unsavedPopup,
        //             isVisible: true,
        //             callbackFn: () => {
        //                 setIsEdit(false);
        //                 setOpenEditPopup({
        //                     ...openEditPopup,
        //                     isOpenPopup: true,
        //                     editData: dataItem,
        //                 });
        //                 setFormFields({
        //                     ...formFields,
        //                     in_rate_table_name: dataItem.rate_table_name,
        //                     in_rate_table_desc: dataItem.rate_table_desc,
        //                     in_rate_table_unittype:
        //                         dataItem.system_unit_mstr_key,
        //                 });
        //                 setAddUnitTypeFields({
        //                     ...addUnitTypeFields,
        //                     unitTypeData: unitTypeDataSource,
        //                     unitTypeValue: unitTypeDataSource.find(
        //                         (x) => x.id === dataItem.system_unit_mstr_key
        //                     ),
        //                 });
        //             },
        //         })
        //     );
        // } else {
            setIsEdit(false);
            setOpenEditPopup({
                ...openEditPopup,
                isOpenPopup: true,
                editData: dataItem,
            });
            setFormFields({
                ...formFields,
                in_rate_table_name: dataItem.rate_table_name,
                in_rate_table_desc: dataItem.rate_table_desc,
                in_rate_table_unittype: dataItem.system_unit_mstr_key,
            });
            setAddUnitTypeFields({
                ...addUnitTypeFields,
                unitTypeData: unitTypeDataSource,
                unitTypeValue: unitTypeDataSource.find(
                    (x) => x.id === dataItem.system_unit_mstr_key
                ),
            });
       // }
    };

    const onClosePopup = () => {
        setOpenEditPopup({
            ...openEditPopup,
            isOpenPopup: false,
            editData: {},
        });
        setFormFields({ ...initialFormFields });
        setErrorObj({});
    };

    const ShowError = React.useCallback(
        (props) => {
            return <Error>{errorObj[props.name]}</Error>;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [errorObj, isSaveClick]
    );

    const handleSubmit = async () => {
        let valid = handleValidation();

        if (valid) {
            setErrorObj({});
            const bodyData = {
                in_user_id: cookiesData.out_user_id.toString(),
                in_tenant_id: cookiesData.out_tenant_id,
                in_rate_table_name: formFields.in_rate_table_name,
                in_rate_table_desc: formFields.in_rate_table_desc,
                in_rate_table_unittype: addUnitTypeFields.unitTypeValue?.id,
                in_rate_table_mstr_key:
                    openEditPopup.editData.rate_table_mstr_key,
            };
            let res = await Action.updateDataAsync(bodyData);
            if (res) {
                onClosePopup();
                setAPICallingState(true);
                const dataVal = {
                    ...apiCallData,
                    in_filter: getInFilter(),
                    in_sort_number: getInSortNumber(),
                    in_sort_order: getInSortOrder(),
                    in_page_row_offset: getPageSkip(),
                    in_page_rows: getPageTake(),
                    in_load_type: "grid-reload",
                };
                await getGridData(dataVal);
            }
        } else {
            setSaveClick(true);
        }
    };

    const handleValidation = () => {
        let isValid = true;

        if (addUnitTypeFields.unitTypeValue.id === "") {
            if (formFields.in_rate_table_unittype === "") {
                isValid = false;
                errorObj.in_rate_table_unittype = "Please select unit type";
            }
        }
        if (formFields.in_rate_table_name.trim() === "") {
            isValid = false;
            errorObj.in_rate_table_name = MessageSchema.rateTableRequired;
        }

        setErrorObj((preVState) => ({ ...preVState, errorObj }));

        return isValid;
    };

    const handleFieldChange = (e) => {
        setIsEdit(true);
        const name = e.name ? e.name : e.target.name;
        if (e.value !== null) {
            setFormFields({ ...formFields, [name]: e.value });
            setErrorObj({ ...errorObj, [name]: "" });
        }
    };

        
    const handleStageChange = (e) => {
        setStateChange(e.state)
        if(e.state === 'MINIMIZED'){
            setModal(false)
        }else if(e.state === 'DEFAULT'){
            setModal(true)
        }
    };

    return (
        <>
            {isRefreshLoading && <>{loadingPanel}</>}

            <DataTable
                isAPICalling={isAPICalling}
                initialLoading={initialLoading}
                data={data}
                id="rate-table-grid"
                pageData={pageData}
                dataCount={dataCount}
                customColumn={GroupColumn}
                columns={gridColumns}
                dataItemKey={DATA_ITEM_KEY}
                width={"auto"}
                module={"rate-table"}
                getExportData={getExportData}
                setExportData={restProps.setExportDataClick}
                isExportDataClick={restProps.exportDataClick}
                fileName={"Export_rate_table.xlsx"}
                exportData={exportData}
                rowRender={customRowRender}
                cellRender={customCellRender}
                // onRowClick={onRowClick}
                defaultPageSize={defaultPageSize}
                pageSize={pageSize}
                setPageSize={setPageSize}
                handleColumnMenu={handleColumnMenu}
                handlePageChange={handlePageChange}
                refreshClick={onRefreshClick}
                onClearFilter={onClearFilter}
                expandChange={expandChange}
                DetailComponent={DetailComponent}
                collapseRows={collapseRows}
                // onRowDoubleClick={onRowDoubleClick}
                reorderable={false}
            />
            <DialogComponent
                title={confirmationPopup.title}
                onCancelConfirm={onCancelConfirm}
                width={"450"}
                height={"auto"}
                className={"Dialog-Delete"}
                desc={confirmationPopup.desc}
                visible={confirmationPopup.isVisible}
                actionBtn={confirmationPopup.actionBtn}
                titleClassName={
                    confirmationPopup.action === "Delete"
                        ? "delete-confirm"
                        : "unSave-confirm"
                }
            />

            {openEditPopup.isOpenPopup && (
                <Window
                    title={<ModalTitle />}
                    onClose={onClosePopup}
                    width={370}
                    height={250}
                    resizable={false}
                    modal={modal}
                    stage={stageChange}
                    onStageChange={handleStageChange}
                    className="form-row specialitywin"
                >

                    <Form onSubmit={handleSubmit} render={() => (
                        <FormElement
                            horizontal={false}
                            className="horizontal-form"
                        >
                            <FieldWrapper>
                                <div
                                    className={
                                        "k-form-field-wrap-popup"
                                    }
                                >
                                    <Label>
                                        {"Rate Table Name*:"}
                                    </Label>
                                    <Input
                                        type={"text"}
                                        name="in_rate_table_name"
                                        placeholder="Rate Table Name"
                                        value={
                                            formFields.in_rate_table_name
                                        }
                                        onChange={(e) => {
                                            handleFieldChange(e);
                                        }}
                                        autoFocus={true}
                                    />
                                    <ShowError
                                        name={"in_rate_table_name"}
                                    ></ShowError>
                                </div>
                            </FieldWrapper>

                            

                            <FieldWrapper>
                                <div
                                    className={
                                        "k-form-field-wrap-popup"
                                    }
                                >
                                    <Label>{"Unit Type* : "}</Label>
                                    <CustomDropdown
                                        name={"unitTypeValue"}
                                        data={
                                            addUnitTypeFields.unitTypeData
                                        }
                                        textField="text"
                                        dataItemKey="id"
                                        valueData={unitTypeDataSource.find(
                                            (c) =>
                                                c.id ===
                                                addUnitTypeFields
                                                    .unitTypeValue.id
                                        )}
                                        onChange={(e, name) => {
                                            setIsEdit(true);
                                            setAddUnitTypeFields({
                                                ...addUnitTypeFields,
                                                [name]: e,
                                            });
                                        }}
                                        onOpen={(dataValue) => {
                                            setAddUnitTypeFields({
                                                ...addUnitTypeFields,
                                                unitTypeData: dataValue,
                                            });
                                        }}
                                        onFilterChange={(dataValue) => {
                                            setAddUnitTypeFields({
                                                ...addUnitTypeFields,
                                                unitTypeData: dataValue,
                                            });
                                        }}
                                        filterable={true}
                                        autoClose={false}
                                        autoBind={true}
                                        dataList={unitTypeDataSource}
                                    />
                                    <ShowError
                                        name={"in_rate_table_unittype"}
                                    ></ShowError>
                                </div>
                            </FieldWrapper>
                            <FieldWrapper>
                                <div
                                    className={
                                        "k-form-field-wrap-popup"
                                    }
                                >
                                    <Label>{"Description:"}</Label>
                                    <Input
                                        type={"text"}
                                        name="in_rate_table_desc"
                                        placeholder="Description"
                                        value={
                                            formFields.in_rate_table_desc
                                        }
                                        onChange={(e) => {
                                            handleFieldChange(e);
                                        }}
                                    />
                                    <ShowError
                                        name={"in_rate_table_desc"}
                                    ></ShowError>
                                </div>
                            </FieldWrapper>
                        </FormElement>

                    )} />

                    {/* <>
                        <Form
                            render={(formRenderProps) => (
                                <FormElement
                                    className="addcustomtableform"
                                    horizontal={false}
                                >
                                    <FieldWrapper>
                                        <fieldset className={"k-form-fieldset"}>
                                            <div
                                                className={
                                                    "k-form-field-wrap-popup"
                                                }
                                            >
                                                <Label>
                                                    {"Rate Table Name*:"}
                                                </Label>
                                                <Input
                                                    type={"text"}
                                                    name="in_rate_table_name"
                                                    placeholder="Rate Table Name"
                                                    value={
                                                        formFields.in_rate_table_name
                                                    }
                                                    onChange={(e) => {
                                                        handleFieldChange(e);
                                                    }}
                                                    autoFocus={true}
                                                />
                                                <ShowError
                                                    name={"in_rate_table_name"}
                                                ></ShowError>
                                            </div>
                                        </fieldset>
                                    </FieldWrapper>
                                    <FieldWrapper>
                                        <div
                                            className={
                                                "k-form-field-wrap-popup"
                                            }
                                        >
                                            <Label>{"Description:"}</Label>
                                            <Input
                                                type={"text"}
                                                name="in_rate_table_desc"
                                                placeholder="Description"
                                                value={
                                                    formFields.in_rate_table_desc
                                                }
                                                onChange={(e) => {
                                                    handleFieldChange(e);
                                                }}
                                            />
                                            <ShowError
                                                name={"in_rate_table_desc"}
                                            ></ShowError>
                                        </div>
                                    </FieldWrapper>
                                    <FieldWrapper>
                                        <div
                                            className={
                                                "k-form-field-wrap-popup"
                                            }
                                        >
                                            <Label>{"Unit Type* : "}</Label>
                                            <CustomDropdown
                                                name={"unitTypeValue"}
                                                data={
                                                    addUnitTypeFields.unitTypeData
                                                }
                                                textField="text"
                                                dataItemKey="id"
                                                valueData={unitTypeDataSource.find(
                                                    (c) =>
                                                        c.id ===
                                                        addUnitTypeFields
                                                            .unitTypeValue.id
                                                )}
                                                onChange={(e, name) => {
                                                    setIsEdit(true);
                                                    setAddUnitTypeFields({
                                                        ...addUnitTypeFields,
                                                        [name]: e,
                                                    });
                                                }}
                                                onOpen={(dataValue) => {
                                                    setAddUnitTypeFields({
                                                        ...addUnitTypeFields,
                                                        unitTypeData: dataValue,
                                                    });
                                                }}
                                                onFilterChange={(dataValue) => {
                                                    setAddUnitTypeFields({
                                                        ...addUnitTypeFields,
                                                        unitTypeData: dataValue,
                                                    });
                                                }}
                                                filterable={true}
                                                autoClose={false}
                                                autoBind={true}
                                                dataList={unitTypeDataSource}
                                            />
                                            <ShowError
                                                name={"in_rate_table_unittype"}
                                            ></ShowError>
                                        </div>
                                    </FieldWrapper>
                                </FormElement>
                            )}
                        />
                    </> */}
                    <WindowActionsBar layout={"end"}>
                        <Button
                            type={"submit"}
                            className="cancelbtn"
                            onClick={onClosePopup}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="primarybtn"
                            onClick={(e) => {
                                handleSubmit();
                            }}
                            disabled={!isEdit}
                            autoFocus={true}
                        >
                            {"Save"}
                        </Button>
                    </WindowActionsBar>
                </Window>
            )}
        </>
    );
};
