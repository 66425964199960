import { combineReducers } from "redux";
import OTHER from "../reducer/setting-reducer";
import DATAPER from "../reducer/jobexecution-reducer";
import prorateData from "../reducer/rules-library-reducer";
import reportsReducer from "../reducer/reports-reducer";
import compPlanReducer from "../reducer/compplan-reducer";
import providerPlanReducer from "../reducer/providerplan-reducer";
import mainReducer from "./main-reducer";
import userGridReducer from "./user-reducer";
import providerReducer from "./provider-reducer";
import formulaReducer from "./formula-reducer";

const RootReducer = combineReducers({
  OTHER,
  mainReducer,
  reportsReducer,
  compPlanReducer,
  providerPlanReducer,
  DATAPER,
  prorateData,
  userGridReducer,
  providerReducer,
  formulaReducer,
});

export default RootReducer;
