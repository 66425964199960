import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Login from "./pages/login/login";
import Authentication from "./pages/login/authentication";
import SsoAuthentication from "./pages/login/ssoAuthentication";
import ForgotPassword from "./pages/login/forgotPassword";
import AdminUser from "./pages/adminUser/index";
import Otp from "./pages/login/otp";
import SystemSetting from "./pages/system-setting/index";
import DtuDataList from "./pages/dtuData/index";
import ErrorPage from "./pages/errorPage";
import ChangePassword from "./pages/manage-profile/changePassword";
import Dashboard from "./pages/dashboard/index";
import BusinessGroup from "./pages/businessGroup/index";
import Calendar from "./pages/calendar";
import Results from "./pages/results";
import Role from "./pages/role";
import Specialty from "./pages/speciality";
import Group from "./pages/group";
import Events from "./pages/events";
import ProviderPosition from "./pages/providerPosition";
import CustomTables from "./pages/custom-tables";
import RateTables from "./pages/rateTables";
import Plan from "./pages/plan";
import Result from "./pages/resultType/index";
import kpi from "./pages/kpi/index";
import DataTypes from "./pages/dataTypes";
import AuditLogs from "./pages/auditlog-master";
import FormulaMaster from "./pages/formula-master";

import {
    checkIsLoggedIn,
    getUserCredential,
    getLocalStorage,
} from "./lib/cookies";
import jobHistory from "./pages/jobHistory/jobHistory";
import Job from "./pages/JobConfiguration/index";
import JobExeucution from "./pages/JobExeucution/index";
import compensationRules from "./pages/rulesLibrary/compensation-rules";
import Reports from "./pages/reports";
import { shallowEqual, useSelector } from "react-redux";
import { mainSelector } from "./redux/selectors/custom-selector";
import BaseLayout from "./components/baseLayout";
import PlanCompStatementReport from "./pages/planCompStatementReport";
import ProviderPlanStatementReport from "./pages/providerCompReport";
import ProviderProfile from "./pages/provider-profile/providerProfile";
import { useLocation } from "react-router-dom";

const getLocalStorageAttr = (keyType) => {
    if (!keyType) {
        return {};
    }

    const processEnv = process.env;

    switch (keyType) {
        case "user_type":
            return getLocalStorage(processEnv.REACT_APP_CREDENTIAL_KEY);
        case "auth_type":
            return getUserCredential();
        default:
            return {};
    }
};

const redirectToAuthentication = (redirectPath = "") => {
    if (redirectPath) {
        return <Redirect to={`/authentication?redirect=${redirectPath}`} />;
    } else {
        return <Redirect to={"/authentication"} />;
    }
};

const redirectToLogin = (redirectPath) => {
    if (redirectPath) {
        return <Redirect to={`/login?redirect=${redirectPath}`} />;
    } else {
        return <Redirect to={"/login"} />;
    }
};

const redirectToDbObj = (authJson, redirectPath) => {
    if (redirectPath) {
        return <Redirect to={`/${redirectPath}`} />;
    } else {
        return <Redirect to={`/${authJson?.out_db_obj_code}`} />;
    }
};

const redirectToChangePwd = (redirectPath) => {
    if (redirectPath) {
        return <Redirect to={`/change-password?redirect=${redirectPath}`} />;
    } else {
        return <Redirect to={"/change-password"} />;
    }
};

const redirectToOtp = (redirectPath) => {
    if (redirectPath) {
        return <Redirect to={`/otp?redirect=${redirectPath}`} />;
    } else {
        return <Redirect to={"/otp"} />;
    }
};

const AuthRoute = ({ component: Component, ...rest }) => {
    const credentailJson = getLocalStorageAttr("user_type");
    const authJson = getLocalStorageAttr("auth_type");

    const accessType = authJson?.out_credential
        ? authJson.out_credential
        : credentailJson?.out_credential || "";

    switch (accessType) {
        case "USER_ACCESS":
            return redirectToAuthentication();
        case "CHANGE_PWD_ACCESS":
            if (Component !== ChangePassword) {
                Component = ErrorPage;
            }
            return (
                <Route
                    {...rest}
                    render={(props) => (
                        <BaseLayout>
                            <Component
                                {...props}
                                readAccess={rest.readAccess}
                                writeAccess={rest.writeAccess}
                                controlAccess={rest?.controlAccess}
                                exportControlAccess={rest?.exportControlAccess}
                                salaryInfoControlAccess={
                                    rest?.salaryInfoControlAccess
                                }
                                eventTypeWriteAccess={
                                    rest?.eventTypeWriteAccess
                                }
                                eventTypeReadAccess={rest?.eventTypeReadAccess}
                                kpiTypeReadAccess={rest?.kpiTypeReadAccess}
                                kpiTypeWriteAccess={rest?.kpiTypeWriteAccess}
                                resultTypeReadAccess={
                                    rest?.resultTypeReadAccess
                                }
                                resultTypeWriteAccess={
                                    rest?.resultTypeWriteAccess
                                }
                            />
                        </BaseLayout>
                    )}
                ></Route>
            );
        case "MFA_ACCESS":
            if (Component !== Otp) {
                Component = ErrorPage;
            }
            return (
                <Route
                    {...rest}
                    render={(props) => (
                        <BaseLayout>
                            <Component
                                {...props}
                                readAccess={rest.readAccess}
                                writeAccess={rest.writeAccess}
                                controlAccess={rest?.controlAccess}
                                exportControlAccess={rest?.exportControlAccess}
                                salaryInfoControlAccess={
                                    rest?.salaryInfoControlAccess
                                }
                                eventTypeWriteAccess={
                                    rest?.eventTypeWriteAccess
                                }
                                eventTypeReadAccess={rest?.eventTypeReadAccess}
                                kpiTypeReadAccess={rest?.kpiTypeReadAccess}
                                kpiTypeWriteAccess={rest?.kpiTypeWriteAccess}
                                resultTypeReadAccess={
                                    rest?.resultTypeReadAccess
                                }
                                resultTypeWriteAccess={
                                    rest?.resultTypeWriteAccess
                                }
                            />
                        </BaseLayout>
                    )}
                ></Route>
            );
        case "AUTH_ACCESS":
            if (Component === Otp) {
                Component = ErrorPage;
            }
            return (
                <Route
                    {...rest}
                    render={(props) => (
                        <BaseLayout>
                            <Component
                                {...props}
                                readAccess={rest.readAccess}
                                writeAccess={rest.writeAccess}
                                controlAccess={rest?.controlAccess}
                                exportControlAccess={rest?.exportControlAccess}
                                salaryInfoControlAccess={
                                    rest?.salaryInfoControlAccess
                                }
                                eventTypeWriteAccess={
                                    rest?.eventTypeWriteAccess
                                }
                                eventTypeReadAccess={rest?.eventTypeReadAccess}
                                kpiTypeReadAccess={rest?.kpiTypeReadAccess}
                                kpiTypeWriteAccess={rest?.kpiTypeWriteAccess}
                                resultTypeReadAccess={
                                    rest?.resultTypeReadAccess
                                }
                                resultTypeWriteAccess={
                                    rest?.resultTypeWriteAccess
                                }
                                resultScreenReadAccess={
                                    rest?.resultScreenReadAccess
                                }
                            />
                        </BaseLayout>
                    )}
                ></Route>
            );
        default:
            return redirectToLogin();
    }
};

const RootRender = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    let redirectPath = searchParams.get("redirect");

    const credentailJson = getLocalStorageAttr("user_type");
    const authJson = getLocalStorageAttr("auth_type");

    const accessType = authJson?.out_credential
        ? authJson.out_credential
        : credentailJson?.out_credential || "";

    switch (accessType) {
        case "USER_ACCESS":
            return redirectToAuthentication(redirectPath);
        case "MFA_ACCESS":
            return redirectToOtp(redirectPath);
        case "CHANGE_PWD_ACCESS":
            return redirectToChangePwd(redirectPath);
        case "AUTH_ACCESS":
            return redirectToDbObj(authJson, redirectPath);
        default:
            return redirectToLogin(redirectPath);
    }
};

const PublicRoute = ({ component: Component, ...rest }) => {
    const authJson = getLocalStorageAttr("auth_type");

    const accessType = authJson?.out_credential || "";

    if (accessType === "AUTH_ACCESS") {
        return redirectToDbObj(authJson);
    }

    if (accessType === "CHANGE_PWD_ACCESS") {
        return redirectToChangePwd();
    }

    if (accessType === "MFA_ACCESS") {
        return redirectToOtp();
    }

    return <Route {...rest} render={(props) => <Component {...props} />} />;
};

const UserRoute = ({ component: Component, ...rest }) => {
    const credentailJson = getLocalStorageAttr("user_type");
    const authJson = getLocalStorageAttr("auth_type");

    const accessType = authJson?.out_credential
        ? authJson.out_credential
        : credentailJson?.out_credential || "";

    if (accessType === "AUTH_ACCESS") {
        return redirectToDbObj(authJson);
    }

    if (accessType === "CHANGE_PWD_ACCESS") {
        return redirectToChangePwd();
    }

    if (accessType === "MFA_ACCESS") {
        return redirectToOtp();
    }

    if (accessType !== "USER_ACCESS") {
        return redirectToLogin();
    }

    return <Route {...rest} render={(props) => <Component {...props} />} />;
};

const Routes = () => {
    const isLoggedIn = checkIsLoggedIn();
    const { readAccessDeniend, writeAccessDeniend } = useSelector(
        mainSelector,
        shallowEqual
    );

    const creditsReadAccess =
        typeof readAccessDeniend.find((obj) => {
            return obj === "credits";
        }) === "undefined"
            ? 1
            : 0;

    const incentivesReadAccess =
        typeof readAccessDeniend.find((obj) => {
            return obj === "incentives";
        }) === "undefined"
            ? 1
            : 0;

    const measurementsReadAccess =
        typeof readAccessDeniend.find((obj) => {
            return obj === "measurements";
        }) === "undefined"
            ? 1
            : 0;

    const paymentsReadAccess =
        typeof readAccessDeniend.find((obj) => {
            return obj === "payments";
        }) === "undefined"
            ? 1
            : 0;

    return (
        <Switch>
            <Route exact path="/" component={RootRender} />
            <PublicRoute exact path="/login" component={Login} />
            <UserRoute
                exact
                path="/authentication"
                component={Authentication}
            />
            <UserRoute path="/sso-auth" component={SsoAuthentication} />
            <UserRoute path="/forgotpassword" component={ForgotPassword} />
            <AuthRoute exact path="/otp" component={Otp} />
            {typeof readAccessDeniend.find((obj) => {
                return obj === "dashboard";
            }) === "undefined" ? (
                <AuthRoute
                    path="/dashboard"
                    component={Dashboard}
                    resultScreenReadAccess={{
                        incentives: incentivesReadAccess,
                        measurements: measurementsReadAccess,
                        payments: paymentsReadAccess,
                    }}
                    readAccess={1}
                    writeAccess={
                        typeof writeAccessDeniend.find((obj) => {
                            return obj === "dashboard";
                        }) === "undefined"
                            ? 1
                            : 0
                    }
                />
            ) : null}
            {typeof readAccessDeniend.find((obj) => {
                return obj === "prv_profile";
            }) === "undefined" ? (
                <AuthRoute
                    path="/prv_profile"
                    component={ProviderProfile}
                    readAccess={1}
                    controlAccess={
                        typeof readAccessDeniend.find((obj) => {
                            return obj === "prv_profile_doc";
                        }) === "undefined"
                            ? 1
                            : 0
                    }
                    writeAccess={
                        typeof writeAccessDeniend.find((obj) => {
                            return obj === "prv_profile";
                        }) === "undefined"
                            ? 1
                            : 0
                    }
                />
            ) : null}
            {typeof readAccessDeniend.find((obj) => {
                return obj === "specialty";
            }) === "undefined" ? (
                <AuthRoute
                    path="/specialty"
                    component={Specialty}
                    readAccess={1}
                    writeAccess={
                        typeof writeAccessDeniend.find((obj) => {
                            return obj === "specialty";
                        }) === "undefined"
                            ? 1
                            : 0
                    }
                />
            ) : null}
            {typeof readAccessDeniend.find((obj) => {
                return obj === "group";
            }) === "undefined" ? (
                <AuthRoute
                    path="/group"
                    component={Group}
                    readAccess={1}
                    writeAccess={
                        typeof writeAccessDeniend.find((obj) => {
                            return obj === "group";
                        }) === "undefined"
                            ? 1
                            : 0
                    }
                />
            ) : null}
            {typeof readAccessDeniend.find((obj) => {
                return obj === "prv_pos";
            }) === "undefined" ? (
                <AuthRoute
                    path="/prv_pos"
                    component={ProviderPosition}
                    readAccess={1}
                    controlAccess={
                        typeof readAccessDeniend.find((obj) => {
                            return obj === "pos_doc";
                        }) === "undefined"
                            ? 1
                            : 0
                    }
                    writeAccess={
                        typeof writeAccessDeniend.find((obj) => {
                            return obj === "prv_pos";
                        }) === "undefined"
                            ? 1
                            : 0
                    }
                />
            ) : null}
            {typeof readAccessDeniend.find((obj) => {
                return obj === "events";
            }) === "undefined" ? (
                <AuthRoute
                    path="/events"
                    component={Events}
                    readAccess={1}
                    eventTypeWriteAccess={
                        typeof writeAccessDeniend.find((obj) => {
                            return obj === "event_type_master";
                        }) === "undefined"
                            ? 1
                            : 0
                    }
                    eventTypeReadAccess={
                        typeof readAccessDeniend.find((obj) => {
                            return obj === "event_type_master";
                        }) === "undefined"
                            ? 1
                            : 0
                    }
                    writeAccess={
                        typeof writeAccessDeniend.find((obj) => {
                            return obj === "events";
                        }) === "undefined"
                            ? 1
                            : 0
                    }
                />
            ) : null}
            {typeof readAccessDeniend.find((obj) => {
                return obj === "custom_tables";
            }) === "undefined" ? (
                <AuthRoute
                    path="/custom_tables"
                    component={CustomTables}
                    readAccess={1}
                    writeAccess={
                        typeof writeAccessDeniend.find((obj) => {
                            return obj === "custom_tables";
                        }) === "undefined"
                            ? 1
                            : 0
                    }
                />
            ) : null}
            {typeof readAccessDeniend.find((obj) => {
                return obj === "rate_tables";
            }) === "undefined" ? (
                <AuthRoute
                    path="/rate_tables"
                    component={RateTables}
                    readAccess={1}
                    writeAccess={
                        typeof writeAccessDeniend.find((obj) => {
                            return obj === "rate_tables";
                        }) === "undefined"
                            ? 1
                            : 0
                    }
                />
            ) : null}
            {typeof readAccessDeniend.find((obj) => {
                return obj === "plan";
            }) === "undefined" ? (
                <AuthRoute
                    path="/plan"
                    component={Plan}
                    readAccess={1}
                    writeAccess={
                        typeof writeAccessDeniend.find((obj) => {
                            return obj === "plan";
                        }) === "undefined"
                            ? 1
                            : 0
                    }
                />
            ) : null}
            {typeof readAccessDeniend.find((obj) => {
                return obj === "kpi";
            }) === "undefined" ? (
                <AuthRoute
                    path="/kpi"
                    component={kpi}
                    readAccess={1}
                    kpiTypeReadAccess={
                        typeof readAccessDeniend.find((obj) => {
                            return obj === "kpi_type_master";
                        }) === "undefined"
                            ? 1
                            : 0
                    }
                    kpiTypeWriteAccess={
                        typeof writeAccessDeniend.find((obj) => {
                            return obj === "kpi_type_master";
                        }) === "undefined"
                            ? 1
                            : 0
                    }
                    writeAccess={
                        typeof writeAccessDeniend.find((obj) => {
                            return obj === "kpi";
                        }) === "undefined"
                            ? 1
                            : 0
                    }
                />
            ) : null}
            {typeof readAccessDeniend.find((obj) => {
                return obj === "result_name";
            }) === "undefined" ? (
                <AuthRoute
                    path="/result_name"
                    component={Result}
                    readAccess={1}
                    resultTypeReadAccess={
                        typeof readAccessDeniend.find((obj) => {
                            return obj === "result_type_master";
                        }) === "undefined"
                            ? 1
                            : 0
                    }
                    resultTypeWriteAccess={
                        typeof writeAccessDeniend.find((obj) => {
                            return obj === "result_type_master";
                        }) === "undefined"
                            ? 1
                            : 0
                    }
                    writeAccess={
                        typeof writeAccessDeniend.find((obj) => {
                            return obj === "result_name";
                        }) === "undefined"
                            ? 1
                            : 0
                    }
                />
            ) : null}
            {typeof readAccessDeniend.find((obj) => {
                return obj === "rules_library";
            }) === "undefined" ? (
                <AuthRoute
                    path="/rules_library"
                    component={compensationRules}
                    readAccess={1}
                    writeAccess={
                        typeof writeAccessDeniend.find((obj) => {
                            return obj === "rules_library";
                        }) === "undefined"
                            ? 1
                            : 0
                    }
                />
            ) : null}
            {creditsReadAccess > 0 ? (
                <AuthRoute
                    path="/credits"
                    component={Results}
                    readAccess={1}
                    writeAccess={
                        typeof writeAccessDeniend.find((obj) => {
                            return obj === "credits";
                        }) === "undefined"
                            ? 1
                            : 0
                    }
                />
            ) : null}
            {measurementsReadAccess > 0 ? (
                <AuthRoute
                    path="/measurements"
                    component={Results}
                    readAccess={1}
                    writeAccess={
                        typeof writeAccessDeniend.find((obj) => {
                            return obj === "measurements";
                        }) === "undefined"
                            ? 1
                            : 0
                    }
                />
            ) : null}
            {incentivesReadAccess > 0 ? (
                <AuthRoute
                    path="/incentives"
                    component={Results}
                    readAccess={1}
                    writeAccess={
                        typeof writeAccessDeniend.find((obj) => {
                            return obj === "incentives";
                        }) === "undefined"
                            ? 1
                            : 0
                    }
                />
            ) : null}
            {paymentsReadAccess > 0 ? (
                <AuthRoute
                    path="/payments"
                    component={Results}
                    readAccess={1}
                    writeAccess={
                        typeof writeAccessDeniend.find((obj) => {
                            return obj === "payments";
                        }) === "undefined"
                            ? 1
                            : 0
                    }
                />
            ) : null}
            {typeof readAccessDeniend.find((obj) => {
                return obj === "job_history";
            }) === "undefined" ? (
                <AuthRoute
                    path="/job_history"
                    component={jobHistory}
                    readAccess={1}
                    writeAccess={
                        typeof writeAccessDeniend.find((obj) => {
                            return obj === "job_history";
                        }) === "undefined"
                            ? 1
                            : 0
                    }
                />
            ) : null}
            {typeof readAccessDeniend.find((obj) => {
                return obj === "job_config";
            }) === "undefined" ? (
                <AuthRoute
                    path="/job_config"
                    component={Job}
                    readAccess={1}
                    writeAccess={
                        typeof writeAccessDeniend.find((obj) => {
                            return obj === "job_config";
                        }) === "undefined"
                            ? 1
                            : 0
                    }
                />
            ) : null}
            {typeof readAccessDeniend.find((obj) => {
                return obj === "pipeline";
            }) === "undefined" ? (
                <AuthRoute
                    path="/pipeline"
                    component={JobExeucution}
                    readAccess={1}
                    writeAccess={
                        typeof writeAccessDeniend.find((obj) => {
                            return obj === "pipeline";
                        }) === "undefined"
                            ? 1
                            : 0
                    }
                />
            ) : null}
            {typeof readAccessDeniend.find((obj) => {
                return obj === "roles";
            }) === "undefined" ? (
                <AuthRoute
                    path="/roles"
                    component={Role}
                    readAccess={1}
                    writeAccess={
                        typeof writeAccessDeniend.find((obj) => {
                            return obj === "roles";
                        }) === "undefined"
                            ? 1
                            : 0
                    }
                />
            ) : null}
            {typeof readAccessDeniend.find((obj) => {
                return obj === "users";
            }) === "undefined" ? (
                <AuthRoute
                    path="/users"
                    component={AdminUser}
                    readAccess={1}
                    writeAccess={
                        typeof writeAccessDeniend.find((obj) => {
                            return obj === "users";
                        }) === "undefined"
                            ? 1
                            : 0
                    }
                />
            ) : null}
            {typeof readAccessDeniend.find((obj) => {
                return obj === "calendar";
            }) === "undefined" ? (
                <AuthRoute
                    path="/calendar"
                    component={Calendar}
                    readAccess={1}
                    writeAccess={
                        typeof writeAccessDeniend.find((obj) => {
                            return obj === "users";
                        }) === "undefined"
                            ? 1
                            : 0
                    }
                />
            ) : null}
            {typeof readAccessDeniend.find((obj) => {
                return obj === "system_pref_config";
            }) === "undefined" ? (
                <AuthRoute
                    path="/system_pref_config"
                    component={SystemSetting}
                    readAccess={1}
                    writeAccess={
                        typeof writeAccessDeniend.find((obj) => {
                            return obj === "system_pref_config";
                        }) === "undefined"
                            ? 1
                            : 0
                    }
                />
            ) : null}
            {typeof readAccessDeniend.find((obj) => {
                return obj === "business_group";
            }) === "undefined" ? (
                <AuthRoute
                    path="/business_group"
                    component={BusinessGroup}
                    readAccess={1}
                    writeAccess={
                        typeof writeAccessDeniend.find((obj) => {
                            return obj === "business_group";
                        }) === "undefined"
                            ? 1
                            : 0
                    }
                />
            ) : null}
            {typeof readAccessDeniend.find((obj) => {
                return obj === "system_types";
            }) === "undefined" ? (
                <AuthRoute
                    path="/system_types"
                    component={DataTypes}
                    readAccess={1}
                    writeAccess={
                        typeof writeAccessDeniend.find((obj) => {
                            return obj === "system_types";
                        }) === "undefined"
                            ? 1
                            : 0
                    }
                />
            ) : null}
            {typeof readAccessDeniend.find((obj) => {
                return obj === "dtu_config";
            }) === "undefined" ? (
                <AuthRoute
                    path="/dtu_config"
                    component={DtuDataList}
                    readAccess={1}
                    writeAccess={
                        typeof writeAccessDeniend.find((obj) => {
                            return obj === "dtu_config";
                        }) === "undefined"
                            ? 1
                            : 0
                    }
                />
            ) : null}
            {typeof readAccessDeniend.find((obj) => {
                return obj === "audit_logs";
            }) === "undefined" ? (
                <AuthRoute
                    path="/audit_logs"
                    component={AuditLogs}
                    readAccess={1}
                    writeAccess={
                        typeof writeAccessDeniend.find((obj) => {
                            return obj === "audit_logs";
                        }) === "undefined"
                            ? 1
                            : 0
                    }
                />
            ) : null}
            {typeof readAccessDeniend.find((obj) => {
                return obj === "formula_master";
            }) === "undefined" ? (
                <AuthRoute
                    path="/formula_master"
                    component={FormulaMaster}
                    readAccess={1}
                    writeAccess={
                        typeof writeAccessDeniend.find((obj) => {
                            return obj === "formula_master";
                        }) === "undefined"
                            ? 1
                            : 0
                    }
                />
            ) : null}
            {typeof readAccessDeniend.find((obj) => {
                return obj === "plan_comp_statement";
            }) === "undefined" ? (
                <AuthRoute
                    path="/plan_comp_statement"
                    component={PlanCompStatementReport}
                    readAccess={1}
                    writeAccess={
                        typeof writeAccessDeniend.find((obj) => {
                            return obj === "plan_comp_statement";
                        }) === "undefined"
                            ? 1
                            : 0
                    }
                />
            ) : null}
            {typeof readAccessDeniend.find((obj) => {
                return obj === "provider_comp_statement";
            }) === "undefined" ? (
                <AuthRoute
                    path="/provider_comp_statement"
                    component={ProviderPlanStatementReport}
                    readAccess={1}
                    writeAccess={
                        typeof writeAccessDeniend.find((obj) => {
                            return obj === "provider_comp_statement";
                        }) === "undefined"
                            ? 1
                            : 0
                    }
                />
            ) : null}
            {
                /** * REPORTS route */
                typeof readAccessDeniend.find((obj) => {
                    return obj === "compensation_detail";
                }) === "undefined" ? (
                    <AuthRoute
                        path="/compensation_detail"
                        component={Reports}
                        readAccess={1}
                        exportControlAccess={
                            typeof readAccessDeniend.find((obj) => {
                                return obj === "export";
                            }) === "undefined"
                                ? 1
                                : 0
                        }
                        salaryInfoControlAccess={
                            typeof readAccessDeniend.find((obj) => {
                                return obj === "salary_info";
                            }) === "undefined"
                                ? 1
                                : 0
                        }
                        writeAccess={
                            typeof writeAccessDeniend.find((obj) => {
                                return obj === "compensation_detail";
                            }) === "undefined"
                                ? 1
                                : 0
                        }
                    />
                ) : null
            }

            {
                /** * Change Password route */
                <AuthRoute path="/change-password" component={ChangePassword} />
            }
            {<AuthRoute path="/manage-profile" component={ChangePassword} />}
            {isLoggedIn ? (
                <AuthRoute exact path="*" component={ErrorPage} />
            ) : (
                <Route exact path="*" component={ErrorPage} />
            )}
        </Switch>
    );
};

export default Routes;
