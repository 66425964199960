import React from "react";
import {
    ACCESS_USERNAME,
    AUTHENTICATION,
    FORGOT_PASSWORD,
    CHANGE_PASSWORD,
    VERIFY_OTP,
    OTP_GENERATION,
    EXPIRE_LOGIN_USER_OTP,
    GET_SSO_LOGIN,
} from "../../api-setup/api-endpoints";
import {
    GETAPI,
    POSTAPI,
    errStatusHandler,
    generateHeader,
    getUserToken,
} from "../../api-setup/api-helper";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { setNewMessage } from "../../redux/actions/setting-actions";

export default function Login() {
    const history = useHistory();
    const dispatch = useDispatch();
    const [isRefreshLoading, setRefreshLoading] = React.useState(false);

    const authenticateAsync = async (bodyData) => {
        setRefreshLoading(true);

        const userToken = getUserToken();

        if (bodyData?.in_sso_token) {
            userToken["x-sso-token"] = bodyData?.in_sso_token;
            delete bodyData.in_sso_token;
        }

        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                AUTHENTICATION,
                null,
                userToken,
                bodyData,
                dispatch
            ).then((res) => {
                setRefreshLoading(false);
                // const outParams = res?.data.out_parameters
                //     ? res?.data.out_parameters
                //     : res?.data?.data?.out_parameters;

                // if (
                //     outParams?.out_ret_code > 0 &&
                //     !userToken?.["x-sso-token"]
                // ) {
                //     dispatch(
                //         setNewMessage({
                //             message: outParams?.out_error_msg,
                //             type: "Error",
                //             show: true,
                //         })
                //     );
                //     errStatusHandler(res, history, dispatch);
                // }
                resolve(res);
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res?.data?.out_parameters?.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res?.data?.out_parameters?.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    const accessUserNameAsync = async (bodyData) => {
        setRefreshLoading(true);
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                ACCESS_USERNAME,
                null,
                generateHeader(),
                bodyData,
                dispatch
            ).then((res) => {
                setRefreshLoading(false);
                const outParams = res?.data.out_parameters
                    ? res?.data.out_parameters
                    : res?.data?.data?.out_parameters;

                if (outParams?.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: outParams?.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
                resolve(outParams);
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res?.data?.out_parameters?.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res?.data?.out_parameters?.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    const forgotPasswordAsync = async (bodyData) => {
        setRefreshLoading(true);
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                FORGOT_PASSWORD,
                null,
                getUserToken(),
                bodyData,
                dispatch
            ).then((res) => {
                setRefreshLoading(false);
                if (res?.data?.out_parameters?.out_ret_code === 0) {
                    if (res.data.msg) {
                        dispatch(
                            setNewMessage({
                                message:
                                    "Your Password Reset Successfully and Sent to Your Registered Email Address",
                                type: "Success",
                                show: true,
                            })
                        );
                    }
                } else {
                    dispatch(
                        setNewMessage({
                            message: res?.data?.out_parameters?.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                }
                resolve(res);
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res?.data?.out_parameters?.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res?.data?.out_parameters?.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    const changePasswordAsync = async (bodyData) => {
        setRefreshLoading(true);
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                CHANGE_PASSWORD,
                null,
                generateHeader(),
                bodyData,
                dispatch
            ).then((res) => {
                setRefreshLoading(false);
                if (res?.data?.out_parameters?.out_ret_code === 0) {
                    if (res?.data?.msg) {
                        dispatch(
                            setNewMessage({
                                message:
                                    "Password changed successfully - login with new password",
                                type: "Success",
                                show: true,
                            })
                        );
                        resolve(res);
                    }
                } else {
                    dispatch(
                        setNewMessage({
                            message: res?.data?.out_parameters?.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                }
                resolve(res);
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res?.data?.out_parameters?.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res?.data?.out_parameters?.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    const otpAsync = async (bodyData) => {
        setRefreshLoading(true);
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                VERIFY_OTP,
                null,
                generateHeader(),
                bodyData,
                dispatch
            ).then((res) => {
                setRefreshLoading(false);
                resolve(res);
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res?.data?.out_parameters?.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res?.data?.out_parameters?.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    const otpGenerationAsync = async (bodyData) => {
        setRefreshLoading(true);
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                OTP_GENERATION,
                null,
                generateHeader(),
                bodyData,
                dispatch
            ).then((res) => {
                setRefreshLoading(false);
                resolve(res);
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res?.data?.out_parameters?.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res?.data?.out_parameters?.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };
    const expireOtpAsync = async (bodyData) => {
        setRefreshLoading(true);
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                EXPIRE_LOGIN_USER_OTP,
                null,
                generateHeader(),
                bodyData,
                dispatch
            ).then((res) => {
                setRefreshLoading(false);
                resolve(res);
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res?.data?.out_parameters?.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res?.data?.out_parameters?.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    const ssoLoginAsync = async (bodyData) => {
        setRefreshLoading(true);
        return new Promise(function (resolve, reject) {
            GETAPI(
                false,
                GET_SSO_LOGIN,
                null,
                generateHeader(),
                bodyData,
                dispatch
            ).then((res) => {
                setRefreshLoading(false);
                resolve(res);
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res?.data?.out_parameters?.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res?.data?.out_parameters?.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };
    return [
        {
            setRefreshLoading,
            isRefreshLoading,
        },
        {
            accessUserNameAsync,
            authenticateAsync,
            forgotPasswordAsync,
            changePasswordAsync,
            otpGenerationAsync,
            otpAsync,
            expireOtpAsync,
            ssoLoginAsync,
        },
    ];
}
