import { getLocalStorage } from "src/lib/cookies";
import { ChangeUserComponents } from "src/pages/common/changeUserComponents";

export const AuthContent = ({
  label = "",
  showChangeUserBtn = true,
  showUserNameLabel = true,
}) => {
  const processEnv = process.env;
  const localStorageData = getLocalStorage(processEnv.REACT_APP_CREDENTIAL_KEY);

  if (!localStorageData) {
    return "";
  }

  return (
    <>
      <div className="loginwelcometext">
        <h3>{label}</h3>
      </div>

      {showUserNameLabel && (
        <>
          <div>
            <h3 className="loginusername">
              {localStorageData?.out_user_first_name +
                " " +
                localStorageData?.out_user_last_name}
            </h3>
          </div>
          <div>
            <ChangeUserComponents
              out_user_name={localStorageData?.out_user_name}
              showChangeUserBtn={showChangeUserBtn}
            />
          </div>
        </>
      )}
    </>
  );
};
