export const SET_COMPPLAN_DATA = "SET_COMPPLAN_DATA";
export const SET_COMPPLAN_LOADING = "SET_COMPPLAN_LOADING";
export const SET_SELECTED_COMPPLANS = "SET_SELECTED_COMPPLANS";

export const SET_PLANPERIOD_DATA = "SET_PLANPERIOD_DATA";
export const SET_PLANPERIOD_LOADING = "SET_PLANPERIOD_LOADING";
export const SET_SELECTED_PLAN_PERIOD = "SET_SELECTED_PLAN_PERIOD";

export const SET_SPECIALITIES_DATA = "SET_SPECIALITIES_DATA";
export const SET_SPCIALITY_LOADING = "SET_SPECIALITY_LOADING";
export const SET_SELECTED_SPECIALITIES = "SET_SELECTED_SPECIALITIES";

export const SET_GROUPS_DATA = "SET_GROUPS_DATA";
export const SET_GROUP_LOADING = "SET_GROUP_LOADING";
export const SET_SELECTED_GROUPS = "SET_SELECTED_GROUPS";

export const SET_PROVIDER_DATA = "SET_PROVIDER_DATA";
export const SET_PROVIDER_LOADING = "SET_PROVIDER_LOADING";
export const SET_SELECTED_PROVIDERS = "SET_SELECTED_PROVIDERS";

export const SET_PROVIDER_DETAILS_FLAG = "SET_PROVIDER_DETAILS_FLAG";

export const SET_SHOW_SECTION_1 = "SET_SHOW_SECTION_1";
export const SET_SHOW_SECTION_2 = "SET_SHOW_SECTION_2";

export const SET_COMPPLAN_SUMMARY_HEADER = "SET_COMPPLAN_SUMMARY_HEADER";
export const SET_COMPPLAN_KPI_PAYMENT_SUMMARY = "SET_COMPPLAN_KPI_PAYMENT_SUMMARY";

export const SET_COMPPLAN_SECTION2_DATA = "SET_COMPPLAN_SECTION2_DATA";
export const SET_SHOW_COMP_PLAN_REPORT_RESULT = "SET_SHOW_COMP_PLAN_REPORT_RESULT";

export const PREFETCH_COMPPLAN_DROPDOWNS = "PREFETCH_COMPPLAN_DROPDOWNS";

export const CLEAR_COMP_PLAN_STATE = "CLEAR_COMP_PLAN_STATE";

export const setCompPlanDataAction = (data) => {
    return { type: SET_COMPPLAN_DATA, data: data };
}
export const setCompPlanLoadingAction = (value) => {
    return { type: SET_COMPPLAN_LOADING, data: value};
}
export const setSelectedCompPlanAction = (data) => {
    return { type: SET_SELECTED_COMPPLANS, data: data};
}
export const setCompPlanPeriodDataAction = (data) => {
    return { type: SET_PLANPERIOD_DATA, data: data};
}
export const setCompPlanPeriodLoadingAction = (value) => {
    return {type: SET_PLANPERIOD_LOADING, data: value};
}
export const setSelectedPeriodAction = (data) => {
    return { type: SET_SELECTED_PLAN_PERIOD, data: data};
}
export const setSpecialitiesDataAction = (data) => {
    return { type: SET_SPECIALITIES_DATA, data: data};
}
export const setSpecialitiesLoadingAction = (value) => {
    return { type: SET_SPCIALITY_LOADING, data: value}
}
export const setSelectedSpecialitiesAction = (data) => {
    return { type: SET_SELECTED_SPECIALITIES, data: data}
}
export const setGroupsDataAction = (data) => {
    return { type: SET_GROUPS_DATA, data: data};
}
export const setGroupLoadingAction = (value) => {
    return {type: SET_GROUP_LOADING, data: value};
}
export const setSelectedGroupsAction = (data) => {
    return {type: SET_SELECTED_GROUPS, data: data};
}
export const setProviderDataAction = (data) => {
    return { type: SET_PROVIDER_DATA, data: data};
}
export const setProviderLoadingAction = (value) => {
    return  { type: SET_PROVIDER_LOADING, data: value };
}
export const setSelectedProviderAction = (data) => {
    return { type: SET_SELECTED_PROVIDERS, data: data};
}
export const setIsProviderDetailFlagAction = (value) => {
    return { type: SET_PROVIDER_DETAILS_FLAG, data: value };
}
export const setShowSection1 = (data) => {
    return { type: SET_SHOW_SECTION_1, data: data};
}
export const setShowSection2 = (data) => {
    return { type: SET_SHOW_SECTION_2, data: data};
}
export const setCompPlanSummaryHeaderAction = (data) => {
    return { type: SET_COMPPLAN_SUMMARY_HEADER, data: data};
}
export const setKPIPaymentSummaryAction = (data) => {
    return { type: SET_COMPPLAN_KPI_PAYMENT_SUMMARY, data: data};
}

export const setCompPlanSection2DataAction = (data) => {
    return { type: SET_COMPPLAN_SECTION2_DATA, data: data};
}

export const setShowReportResult = (data) => {
    return {type: SET_SHOW_COMP_PLAN_REPORT_RESULT, data: data};
}

export const setPrefetchDropdownAction = (data) => {
    return { type: PREFETCH_COMPPLAN_DROPDOWNS, data: data};
}

export const clearCompPlanReportsState = () => {
    return {
      type: CLEAR_COMP_PLAN_STATE,
    };
  };