export function messageSelector(state) {
  const { OTHER } = state;
  return {
    messageList: OTHER.messageList,
  };
}

export function unSaveChangesPopupSelector(state) {
  const { OTHER } = state;
  return {
    unsavedPopup: OTHER.unsavedPopup,
  };
}


export function fetchUnitTypeDataSelector(state) {
  const { OTHER } = state;
  return {
    unitTypeList: OTHER.unitTypeList,
  };
}

export function prorateDataSelector(state) {
  const { prorateData } = state;
  return {
    prorateData,
  };
}

export function executionDataSelector(state) {
  const { executionData } = state.prorateData;
  return {
    executionData,
  };
}

export function resultSettingSelector(state) {
  const { resultSettingData } = state.prorateData;
  return {
    resultSettingData,
  };
}

export function fetchSystemFxDataSelector(state) {
  const { systemFxDropdownList } = state.prorateData;
  return { systemFxDropdownList };
}


export function fetchCustomTableInputFieldDataSelector(state) {
  const { customTableInputFieldList } = state.formulaReducer;
  return { customTableInputFieldList };
}

export function jobexecutionSelector(state) {
  const { DATAPER } = state;
  return {
    DATAPER,
  };
}

export function reportsSelector(state) {
  const { reportsReducer } = state;
  return reportsReducer;
}

export function compPlanStatementSelector(state) {
  const { compPlanReducer } = state;
  return compPlanReducer;
}

export function providerPlanStatementSelector(state) {
  const { providerPlanReducer } = state;
  return providerPlanReducer;
}

export function mainSelector(state) {
  const { mainReducer } = state;
  return mainReducer;
}

export function updateRuleSelector(state) {
  const { isUpdateRuleClick } = state.prorateData;
  return isUpdateRuleClick;
}
export function userGridSelector(state) {
  const { userGridReducer } = state;
  return userGridReducer;
}

export function linkedRulesSelector(state) {
  const { linkedRules } = state.OTHER;
  return {
    linkedRules,
  };
}

export function providerSelector(state) {
  const { providerReducer } = state;
  return providerReducer
}