/**
 * Form schema for email send popup
 */
import {
    FormCheckbox,
    FormInput,
    FormMultiSelect,
    FormTextArea,
} from "src/components/form/form-elements";

const schema = {
    on_change_state: {
        in_is_onboarding: false,
        in_is_attach: true,
        in_subject: true,
        in_message: true,
    },
    in_role_value: {
        label: "Select Role:",
        name: "in_role_value",
        id: "in_role_value",
        textField: "text",
        dataItemKey: "id",
        wrapperClass: "formelement",
        component: FormMultiSelect,
        autoClose: false,
        autoBind: true,
        isSelectALl: true,
        loading: true,
    },
    in_group_value: {
        label: "Select Group:",
        name: "in_group_value",
        id: "in_group_value",
        textField: "text",
        dataItemKey: "id",
        wrapperClass: "formelement",
        component: FormMultiSelect,
        autoClose: false,
        autoBind: true,
        isSelectALl: true,
        loading: true,
    },
    in_comp_plan_value: {
        label: "Select Comp Plan:",
        name: "in_comp_plan_value",
        id: "in_comp_plan_value",
        textField: "text",
        dataItemKey: "id",
        wrapperClass: "formelement",
        component: FormMultiSelect,
        autoClose: false,
        autoBind: true,
        isSelectALl: true,
        loading: true,
    },
    in_user_status_value: {
        label: "Select User Status:",
        name: "in_user_status_value",
        id: "in_user_status_value",
        textField: "text",
        dataItemKey: "id",
        wrapperClass: "formelement",
        component: FormMultiSelect,
        autoClose: false,
        autoBind: true,
        isSelectALl: true,
        loading: true,
    },
    in_is_onboarding: {
        id: "in_is_onboarding",
        name: "in_is_onboarding",
        label: "Send Onboarding Auto-email",
        wrapperClass: "formelement checkboxalign",
        component: FormCheckbox,
        value: 0,
    },
    in_is_attach: {
        id: "in_is_attach",
        name: "in_is_attach",
        label: "Attach Provider Documents",
        wrapperClass: "formelement checkboxalign",
        component: FormCheckbox,
    },
    in_subject: {
        id: "in_subject",
        name: "in_subject",
        label: "Subject*:",
        wrapperClass: "formelement fullwidthelm",
        component: FormInput,
        style: {
            width: "780px",
        },
        rules: {
            required: "Subject is required",
            minLength: {
                value: 3,
                message: "Subject should have at least 3 characters",
            },
        },
    },
    in_message: {
        id: "in_message",
        name: "in_message",
        label: "Message*:",
        wrapperClass: "formelement fullwidthelm",
        rows: 5,
        cols: 98,
        component: FormTextArea,
        rules: {
            required: "Message is required",
            minLength: {
                value: 10,
                message: "Message should have at least 10 characters",
            },
        },
    },
};

export default schema;
