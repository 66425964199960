import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { compPlanStatementSelector } from "src/redux/selectors/custom-selector";
import useCompPlanStatementService from "./service";
import { Label } from "@progress/kendo-react-labels";
import { Button } from "@progress/kendo-react-buttons";
import CustomDropdown from "../../components/customDropdown";
import CustomMultiSelect from "../../components/multiSelect";
import {
    setIsProviderDetailFlagAction,
    setPrefetchDropdownAction,
    setSelectedCompPlanAction,
    setSelectedGroupsAction,
    setSelectedPeriodAction,
    setSelectedProviderAction,
    setSelectedSpecialitiesAction,
} from "src/redux/actions/compplan-actions";

import { Switch } from "@progress/kendo-react-inputs";
import "./plan-report.scss";

const PlanCompFilter = ({ runReports }) => {
    const {
        compPlanData,
        isCompPlanLoading,
        selectedCompPlan,
        compPlanPeriodData,
        isCompPlanPeriodLoading,
        selectedCompPlanPeriod,

        specialityData,
        isSpecialityLoading,
        selectedSpecialities,

        groupData,
        isGroupLoading,
        selectedGroups,

        providerData,
        isProviderLoading,
        selectedProviders,
        prefetchDropdowns,
        isProviderDetail,
    } = useSelector(compPlanStatementSelector, shallowEqual);
    const dispatch = useDispatch();

    const [activeCompPlanList, setActiveCompPlanList] = React.useState(
        compPlanData || []
    );
    const [activeCompPlanPeriodList, setActiveCompPlanPeriodList] =
        React.useState(compPlanPeriodData || []);
    const [activeSpecialityList, setActiveSpecialityList] = React.useState(
        specialityData || []
    );
    const [activeGroupList, setActiveGroupList] = React.useState(
        groupData || []
    );
    const [activeProviderList, setActiveProviderList] = React.useState(
        providerData || []
    );

    const reportService = useCompPlanStatementService();

    React.useEffect(() => {
        if (compPlanData?.length > 0) {
            setActiveCompPlanList(compPlanData);
        } else {
            reportService.getCompPlan();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [compPlanData]);

    React.useEffect(() => {
        setActiveCompPlanPeriodList(compPlanPeriodData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [compPlanPeriodData]);

    React.useEffect(() => {
        setActiveSpecialityList(specialityData.filter((it) => it.specialty_key !== 0));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [specialityData]);

    React.useEffect(() => {
        setActiveGroupList(groupData.filter((it) => it.org_title_key !== 0));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupData]);

    React.useEffect(() => {
        setActiveProviderList(providerData.filter((it) => it.provider_key !== 0 ));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [providerData]);

    useEffect(() => {
        if (selectedCompPlan && prefetchDropdowns) {
            reportService.fetchCompPlanPeriods(selectedCompPlan);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCompPlan, prefetchDropdowns]);

    useEffect(() => {
        if (prefetchDropdowns) {
            reportService.fetchSpecialities(selectedCompPlanPeriod);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCompPlanPeriod, prefetchDropdowns]);

    useEffect(() => {
        if (selectedSpecialities && prefetchDropdowns) {
            reportService.fetchGroups(selectedSpecialities);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSpecialities, prefetchDropdowns]);

    useEffect(() => {
        if (selectedGroups && prefetchDropdowns) {
            reportService.fetchProviders(selectedGroups);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedGroups, prefetchDropdowns]);

    const handleChangeCompPlan = (value) => {
        dispatch(setPrefetchDropdownAction(true));
        dispatch(setSelectedCompPlanAction(value));
    };

    const handleChangeCompPlanPeriod = (value) => {
        dispatch(setPrefetchDropdownAction(true));

        dispatch(setSelectedPeriodAction(value));
    };

    const handleChangeSpecialities = (value) => {
        dispatch(setPrefetchDropdownAction(true));

        const items =
            value.length === 0 ||
                value.findIndex((it) => it.specialty_key === 0) > 0
                ? [specialityData[0]]
                : value.filter((it) => it.specialty_key !== 0);
        dispatch(setSelectedSpecialitiesAction(items));
    };

    const handleChangeGroups = (value) => {
        dispatch(setPrefetchDropdownAction(true));
        const items =
            value.length === 0 ||
                value.findIndex((it) => it.org_title_key === 0) > 0
                ? [groupData[0]]
                : value.filter((it) => it.org_title_key !== 0);
        dispatch(setSelectedGroupsAction(items));
    };

    const handleChangeProvider = (value) => {
        const items =
            value.length === 0 ||
                value.findIndex((it) => it.provider_key === 0) > 0
                ? [providerData[0]]
                : value.filter((it) => it.provider_key !== 0);
        dispatch(setSelectedProviderAction(items));
    };

    return (
        <ul className="ReportFilters-links reports-filters">
            <div
                className={"k-form-field-wrap-popup valueDropdown width280"}
                style={{ paddingRight: "5px !important" }}
            >
                <Label>{"Comp Plan:"}</Label>
                <CustomDropdown
                    name="compPlanDropdown"
                    data={activeCompPlanList}
                    textField="comp_plan_name"
                    dataItemKey="comp_plan_mstr_key"
                    loading={isCompPlanLoading}
                    valueData={selectedCompPlan}
                    onChange={(value) => handleChangeCompPlan(value)}
                    filterable={true}
                    dataList={compPlanData}
                    onClose={() => {
                        setActiveCompPlanList(compPlanData);
                    }}
                    onFilterChange={(dataValue) =>
                        setActiveCompPlanList(dataValue)
                    }
                />
            </div>
            <div
                className={"k-form-field-wrap-popup valueDropdown width125"}
                style={{ paddingRight: "5px !important" }}
            >
                <Label>{"Comp Plan Period:"}</Label>
                <CustomDropdown
                    name="compPlanPeriodDropdown"
                    data={activeCompPlanPeriodList}
                    textField="period_name"
                    dataItemKey="period_mstr_key"
                    loading={isCompPlanPeriodLoading}
                    valueData={selectedCompPlanPeriod}
                    onChange={(value) => handleChangeCompPlanPeriod(value)}
                    filterable={true}
                    dataList={compPlanPeriodData}
                    onClose={() => {
                        setActiveCompPlanPeriodList(compPlanPeriodData);
                    }}
                    onFilterChange={(dataValue) =>
                        setActiveCompPlanPeriodList(dataValue)
                    }
                />
            </div>
            <div
                className={"k-form-field-wrap-popup valueDropdown"}
                style={{ paddingRight: "5px !important" }}
            >
                <Label>{"Specialty:"}</Label>
                <CustomMultiSelect
                    name="specialityDropdown"
                    data={activeSpecialityList}
                    textField="specialty_name"
                    dataItemKey="specialty_key"
                    loading={isSpecialityLoading}
                    valueData={selectedSpecialities}
                    onChange={(value) => handleChangeSpecialities(value)}
                    filterable={true}
                    isSelectALl={false}
                    dataList={specialityData.filter((it) => it.specialty_key !== 0)}
                    onClose={() => {
                        setActiveSpecialityList(specialityData.filter((it) => it.specialty_key !== 0));
                    }}
                    onFilterChange={(dataValue) =>
                        setActiveSpecialityList(dataValue)
                    }
                    style={{ maxHeight: 200}}
                    autoClose={false}
                />
            </div>
            <div
                className={"k-form-field-wrap-popup valueDropdown"}
                style={{ paddingRight: "5px !important" }}
            >
                <Label>{"Group:"}</Label>
                <CustomMultiSelect
                    name="groupDropdown"
                    data={activeGroupList}
                    textField="title_name"
                    dataItemKey="org_title_key"
                    loading={isGroupLoading}
                    valueData={selectedGroups}
                    onChange={(value) => handleChangeGroups(value)}
                    filterable={true}
                    isSelectALl={false}
                    dataList={groupData.filter((it) => it.org_title_key !== 0)}
                    onClose={() => {
                        setActiveGroupList(groupData.filter((it) => it.org_title_key !== 0));
                    }}
                    onFilterChange={(dataValue) =>
                        setActiveGroupList(dataValue)
                    }
                    style={{ maxHeight: 200 }}
                    autoClose={false}
                />
            </div>
            <div
                className={"k-form-field-wrap-popup valueDropdown"}
                style={{ paddingRight: "5px !important" }}
            >
                <Label>{"Provider:"}</Label>
                <CustomMultiSelect
                    name="providerDropdown"
                    data={activeProviderList}
                    textField="provider_name"
                    dataItemKey="provider_key"
                    loading={isProviderLoading}
                    valueData={selectedProviders}
                    onChange={(value) => handleChangeProvider(value)}
                    filterable={true}
                    isSelectALl={false}
                    dataList={providerData.filter((it) => it.provider_key !== 0)}
                    onClose={() => {
                        setActiveProviderList(providerData.filter((it) => it.provider_key !== 0));
                    }}
                    onFilterChange={(dataValue) =>
                        setActiveProviderList(dataValue)
                    }
                    style={{ maxHeight: 200 }}
                    autoClose={false}
                />
            </div>
            <div
                className={"k-form-field-wrap-popup valueDropdown"}
                style={{ width: "125px" }}
            >
                <Label>{"Provider Detail:"}</Label>
                <Switch
                    className="result-switch"
                    onChange={(e) => {
                        dispatch(setIsProviderDetailFlagAction(e.value));
                    }}
                    thumbRounded={"medium"}
                    trackRounded={"medium"}
                    size={"medium"}
                    checked={isProviderDetail}
                    onLabel={"ON"}
                    offLabel={"OFF"}
                />
            </div>
            <div className="k-form-buttons btn-comp-plan-filter">
                <Button
                    primary={true}
                    disabled={
                        !selectedCompPlan ||
                        !selectedCompPlanPeriod ||
                        !selectedSpecialities.length ||
                        !selectedGroups.length ||
                        !selectedProviders.length ||
                        isCompPlanLoading ||
                        isCompPlanPeriodLoading ||
                        isSpecialityLoading ||
                        isGroupLoading ||
                        isProviderLoading
                    }
                    type={"submit"}
                    onClick={runReports}
                    className="primarybtn"
                >
                    Run
                </Button>
            </div>
        </ul>
    );
};
export default PlanCompFilter;
