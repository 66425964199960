import { Button } from "@progress/kendo-react-buttons";
import { FieldWrapper, Form, FormElement } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import { Error, Label } from "@progress/kendo-react-labels";
import { changeCancelBtnState, changeUpdateBtnState } from "../../utils/utils";
import React from "react";
import Popup from "../../components/customPopup";
import { getUserCredential } from "../../lib/cookies";
import MessageSchema from "../../utils/messageSchema";
import { HeaderPage } from "../base/headerPage";
import DtuBatchLogs from "../common/dtuBatchLogs";
import { GridButton, GroupData } from "./groupGrid";
import UploadDTU from "../user/uploadDtu";
import useServiceApi from "./service";
import { useDocumentClickEventListener } from "../../lib/hooks/custom-common-hooks";

const initialFormFields = {
  in_group_code: "",
  in_title_name: "",
  in_title_desc: "",
  in_region: "",
  in_facility: "",
  in_cost_center: "",
};

const Group = (pageProps) => {
  const [list, Action] = useServiceApi();
  const cookiesData = getUserCredential();
  const addRef = React.useRef(null);
  const [isAddNewConfig, setNewConfig] = React.useState(false);
  const [isShow, setShow] = React.useState({
    dtuLogs: false,
    sendEmails: false,
    dtuUpload: false,
    deleteRows: false,
  });
  const [exportDataClick, setExportDataClick] = React.useState(false);
  const [isAPICall, setAPICall] = React.useState(false);
  const [formFields, setFormFields] = React.useState({
    ...initialFormFields,
  });
  const [errorObj, setErrorObj] = React.useState({});
  const [isSaveClick, setSaveClick] = React.useState(false);
  const [selectedRowId, setSelectedRowId] = React.useState("");
  const [dataList, setDataList] = React.useState([]);
  const [groupEdit, setGroupEdit] = React.useState(false);

  const [columnsData, setColumnsData] = React.useState([]);
  const [isCheckColumn, setColumnCheck] = React.useState(false);
  let popupRef = React.useRef();
  useDocumentClickEventListener(isAddNewConfig, setNewConfig, popupRef);

  React.useEffect(() => {
    if (list.loadingState.isSaveData) {
      setFormFields({ ...initialFormFields });
      setAPICall(true);
      setNewConfig(false);
    }
  }, [list.loadingState.isSaveData]);

  React.useEffect(() => {
    if (groupEdit === false) {
      changeCancelBtnState();
      changeUpdateBtnState();
    }
  }, [groupEdit]);

  const addNewConfig = (e) => {
    setNewConfig(!isAddNewConfig);
    e.preventDefault();
  };

  const ShowError = React.useCallback(
    (props) => {
      return <Error>{errorObj[props.name]}</Error>;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errorObj, isSaveClick]
  );

  const handleValidation = () => {
    let isValid = true;

    if (formFields.in_title_name.trim() === "") {
      isValid = false;
      errorObj.in_title_name = MessageSchema.groupNameRequired;
    }

    setErrorObj((preVState) => ({ ...preVState, errorObj }));

    return isValid;
  };

  const handleSubmit = () => {
    let valid = handleValidation();

    if (valid) {
      setErrorObj({});
      const bodyData = {
        in_user_id: cookiesData.out_user_id.toString(),
        in_tenant_id: cookiesData.out_tenant_id,
        in_group_code: formFields.in_group_code,
        in_title_name: formFields.in_title_name,
        in_title_desc: formFields.in_title_desc,
        in_region: formFields.in_region,
        in_facility: formFields.in_facility,
        in_cost_center: formFields.in_cost_center,
      };
      Action.addDataAsync(bodyData);
    } else {
      setSaveClick(true);
    }
  };

  const onCancelClick = () => {
    setFormFields(initialFormFields);
    setErrorObj({});
    setNewConfig(false);
    setSaveClick(false);
  };

  const handleFieldChange = (e) => {
    const name = e.name ? e.name : e.target.name;
    if (e.value !== null) {
      if (e.value.text === "Regular") {
        setFormFields({
          ...formFields,
          [name]: e.value,
        });
        setErrorObj({ ...errorObj, [name]: "" });
      } else {
        setFormFields({ ...formFields, [name]: e.value });
        setErrorObj({ ...errorObj, [name]: "" });
      }
    }
  };

  const AddNewForm = () => {
    return (
      <Form
        onSubmit={handleSubmit}
        render={() => (
          <FormElement className="emailForm" horizontal={true}>
            <FieldWrapper>
              <div className="k-form-field-wrap-popup">
                <Label>Group Code : </Label>
                <Input
                  type="text"
                  name="in_group_code"
                  value={formFields.in_group_code}
                  onChange={(e) => {
                    handleFieldChange(e);
                  }}
                  autoFocus={true}
                />
                <ShowError name="in_group_code" />
              </div>
            </FieldWrapper>

            <FieldWrapper>
              <div className="k-form-field-wrap-popup">
                <Label>Group Name* : </Label>
                <Input
                  type="text"
                  name="in_title_name"
                  value={formFields.in_title_name}
                  onChange={(e) => {
                    handleFieldChange(e);
                  }}
                />
                <ShowError name="in_title_name" />
              </div>
            </FieldWrapper>

            <FieldWrapper>
              <div className="k-form-field-wrap-popup">
                <Label>Description : </Label>
                <Input
                  type="text"
                  name="in_title_desc"
                  value={formFields.in_title_desc}
                  onChange={(e) => {
                    handleFieldChange(e);
                  }}
                />
                <ShowError name="in_title_desc" />
              </div>
            </FieldWrapper>

            <FieldWrapper>
              <div className="k-form-field-wrap-popup">
                <Label>Region : </Label>
                <Input
                  type="text"
                  name="in_region"
                  value={formFields.in_region}
                  onChange={(e) => {
                    handleFieldChange(e);
                  }}
                />
                <ShowError name="in_region" />
              </div>
            </FieldWrapper>

            <FieldWrapper>
              <div className="k-form-field-wrap-popup">
                <Label>Facility : </Label>
                <Input
                  type="text"
                  name="in_facility"
                  value={formFields.in_facility}
                  onChange={(e) => {
                    handleFieldChange(e);
                  }}
                />
                <ShowError name="in_facility" />
              </div>
            </FieldWrapper>

            <FieldWrapper>
              <div className="k-form-field-wrap-popup">
                <Label>Cost Center : </Label>
                <Input
                  type="text"
                  name="in_cost_center"
                  value={formFields.in_cost_center}
                  onChange={(e) => {
                    handleFieldChange(e);
                  }}
                />
                <ShowError name="in_cost_center" />
              </div>
            </FieldWrapper>
          </FormElement>
        )}
      />
    );
  };

  const AddNewPopup = () => {
    return (
      <Popup
        anchor={addRef.current}
        show={isAddNewConfig}
        setShow={setNewConfig}
        className="right0"
      >
        <div className="dot-dropdown-open dot-dropdown-open-Form">
          <div className="caption">
            <span>Add New Group</span>
          </div>

          {AddNewForm()}

          <div className="dropdownFooter">
            <div className="k-form-buttons">
              <Button
                primary={true}
                type="submit"
                onClick={() => {
                  onCancelClick();
                }}
              >
                Cancel
              </Button>
              <Button
                primary={true}
                type="submit"
                onClick={() => {
                  handleSubmit(formFields, errorObj);
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </Popup>
    );
  };

  const AddNewLink = () => {
    return (
      <div ref={popupRef}>
        <a
          href="/#"
          className="cta-small"
          ref={addRef}
          onClick={(e) => {
            addNewConfig(e);
          }}
        >
          <i className="fas fa-plus"></i> Add New Group
        </a>

        {AddNewPopup()}
      </div>
    );
  };

  const handleMenuItemClick = (action) => {
    switch (action) {
      case "dtuLogs":
        setShow({
          ...isShow,
          dtuLogs: !isShow.dtuLogs,
        });
        break;
      case "export":
        setExportDataClick(true);
        break;
      case "delete":
        setShow({
          ...isShow,
          deleteRows: !isShow.deleteRows,
        });
        break;
      default:
        break;
    }
  };

  const handleUpload = (e) => {
    setShow({
      ...isShow,
      dtuUpload: !isShow.dtuUpload,
    });
    e.preventDefault();
  };

  const headerConfig = {
    title: "Group",
    id: "group",
    buttons: { template_fx: GridButton },
    add_new_fx: AddNewLink,
    upload_fx: handleUpload,
    action_menu: {
      export: { fx: handleMenuItemClick },
      dtuLog: { fx: handleMenuItemClick },
      delete: { fx: handleMenuItemClick },
    },
    manage_col: {
      columnsData: columnsData,
      setColumnsData: setColumnsData,
      setColumnCheck: setColumnCheck,
    },
  };

  const parent = document.querySelector("#content-" + headerConfig.id);

  if (parent) {
    const exportToExcel = parent.querySelector(".export-to-excel");
    const auditLog = parent.querySelector(".audit-log");
    const deleteRows = parent.querySelector(".delete-rows");

    exportToExcel && exportToExcel.disableKendoMenu();
    auditLog && auditLog.disableKendoMenu();
    deleteRows && deleteRows.disableKendoMenu();

    dataList.length && exportToExcel && exportToExcel.enableKendoMenu();

    if (selectedRowId !== "") {
      auditLog && auditLog.enableKendoMenu();
      deleteRows && deleteRows.enableKendoMenu();
    }
  }

  return (
    <div className="Main">
      <div className="content">
        <div className="content-head">
          <HeaderPage pageProps={pageProps} headerConfig={headerConfig} />
        </div>

        <div className="content-body">
          <div className="dtu-file-container">
            <GroupData
              isAPICall={isAPICall}
              setAPICall={setAPICall}
              setExportDataClick={setExportDataClick}
              exportDataClick={exportDataClick}
              selectedRowId={selectedRowId}
              setSelectedRowId={setSelectedRowId}
              setShow={setShow}
              isShow={isShow}
              setDataList={setDataList}
              pageProps={pageProps}
              groupEdit={groupEdit}
              setGroupEdit={setGroupEdit}
              columnsData={columnsData}
              setColumnsData={setColumnsData}
              setColumnCheck={setColumnCheck}
              isCheckColumn={isCheckColumn}
            />
          </div>
        </div>
      </div>
      {isShow.dtuLogs && (
        <DtuBatchLogs
          visible={isShow}
          setShow={setShow}
          module={"org_title"}
          pageProps={pageProps}
        />
      )}
      {isShow.dtuUpload && (
        <UploadDTU
          visible={isShow}
          setShow={setShow}
          title={"Upload Groups"}
          pageId={"group"}
          setAPICall={setAPICall}
        />
      )}
    </div>
  );
};

export default React.memo(Group);
