import React from "react";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";

const MultiSelectComponent = (props) => {
    const {
        style,
        placeholder,
        loading = false,
        dataList,
        name,
        module,
        textField,
        dataItemKey,
        valueData,
        data,
        appendTo,
        autoClose,
        autoBind,
        isSelectALl,
        onOpen,
        onChange,
        onFilterChange,
        disabled,
        selectAllId = "Select All",
        onClose,
    } = props;
    const [search, setSearch] = React.useState("");
    const [filterValue, SetFilterValue] = React.useState("");

    const filterDropdownChangeValue = (event) => {
        handleFocus();
        setSearch(event.filter);
        SetFilterValue(event.filter.value);
        let dataValue = filterBy(dataList.slice(), event.filter);
        onFilterChange && onFilterChange(dataValue);
    };

    const onOpenValue = () => {
        let dataValue =
            filterValue && filterValue !== ""
                ? filterBy(data.slice(), search)
                : dataList;
        onOpen && onOpen(dataValue);
    };

    const handleChangeDropdownValue = (e) => {
        const name = e.name ? e.name : e.target.name;
        if (isSelectALl) {
            if (
                valueData &&
                valueData.filter((x) => x.text === "Select All").length > 0
            ) {
                let index = e.value.findIndex((x) => x.text === "Select All");
                e.value.splice(index, 1);
            } else if (
                e.value &&
                e.value.filter((x) => x.text === "Select All").length > 0 &&
                e.value.length > 1
            ) {
                e.value = [];
                e.value.push({ text: "Select All", id: selectAllId });
            }
            if (e.value.length === 0) {
                e.value.push({ text: "Select All", id: selectAllId });
            }
            onChange(e.value, name, e);
        } else {
            onChange(e.value, name, e);
        }
        SetFilterValue(filterValue);
    };

    const listLoadingRender = (element) => {
        const noData = (
            <h4
                style={{
                    fontSize: "1em",
                }}
            >
                <br />
                Loading
            </h4>
        );
        return React.cloneElement(element, { ...element.props }, noData);
    };

    const listNoDataRender = (element) => {
        const noData = (
            <h4
                style={{
                    fontSize: "1em",
                }}
            >
                <br />
                No data found
            </h4>
        );
        return React.cloneElement(element, { ...element.props }, noData);
    };

    const handleBlur = (e) => {
         SetFilterValue("");
    };

    const handleFocus = () => {};

    const handleOnClose = () => {
        onClose?.();
    }

    return (
        <MultiSelect
            name={name}
            data={data}
            filter={filterValue}
            textField={textField}
            onOpen={() => {
                onOpenValue(module);
            }}
            dataItemKey={dataItemKey}
            value={valueData}
            onChange={(e) => {
                handleChangeDropdownValue(e);
            }}
            filterable={true}
            onFilterChange={(e) => {
                filterDropdownChangeValue(e, module);
            }}
            onClose={handleOnClose}
            popupSettings={{ appendTo: appendTo }}
            autoClose={autoClose || false}
            autoBind={autoBind}
            loading={loading}
            placeholder={placeholder}
            style={style}
            onBlur={handleBlur}
            onFocus={handleFocus}
            listNoDataRender={
                loading
                    ? listLoadingRender
                    : loading === false && data && data.length === 0
                    ? listNoDataRender
                    : listNoDataRender
            }
            disabled={disabled}
        />
    );
};

export default React.memo(MultiSelectComponent);
