export const NEW_MESSAGE = "NEW_MESSAGE";
export const REMOVE_MESSAGE = "REMOVE_MESSAGE";
export const HARD_REMOVE_MESSAGE = "HARD_REMOVE_MESSAGE";
export const SET_UNSAVED_CHANGES_POPUP = "SET_UNSAVED_CHANGES_POPUP";
export const SET_LINKED_RULES = "SET_LINKED_RULES";
export const CLEAR_LINKED_RULES = "CLEAR_LINKED_RULES";
export const SET_UNIT_TYPE_DATA = "SET_UNIT_TYPE_DATA";

export function setNewMessage(data) {
  return {
    type: NEW_MESSAGE,
    message: data,
  };
}

export function removeMessage(index) {
  return {
    type: REMOVE_MESSAGE,
    index,
  };
}

export function hardRemoveMessage(index) {
  return {
    type: HARD_REMOVE_MESSAGE,
    index,
  };
}

export function setLinkedRules(data) {
  return {
    type: SET_LINKED_RULES,
    data: data,
  };
}

export function clearLinkedRules(data) {
  return {
    type: CLEAR_LINKED_RULES,
    data: data,
  };
}

export function setUnSavedChangesPopup(data) {
  return {
    type: SET_UNSAVED_CHANGES_POPUP,
    data: data,
  };
}

export function setUnitTypeData(data) {
  return {
    type: SET_UNIT_TYPE_DATA,
    data: data,
  };
}
