import * as React from "react";
import DataTable from "../../components/dataTable";
import { GridCell, GridColumn } from "@progress/kendo-react-grid";
import {
  setExpandedState,
  setGroupIds,
} from "@progress/kendo-react-data-tools";
import { groupBy } from "@progress/kendo-data-query";
import { IntlService } from "@progress/kendo-react-intl";
import { columnMenuProps } from "src/components/customColumnMenu";

const HeaderCell = (props) => {
  return (
    <span className="k-cell-inner">
      <span className="k-link">
        <span data-field={props.field}>{props.title}</span>
      </span>
    </span>
  );
};

const initialGroup = [
  {
    field: "h$title_name",
  },
];

const intl = new IntlService("en");

const processWithGroups = (data, group) => {
  const newDataState = groupBy(data, group);
  setGroupIds({
    data: newDataState,
    group: group,
  });
  return newDataState;
};

const PDFCompDetailSec2Template = ({
  reportsCompDetailSection2DataColumns,
  reportsCompDetailSection2Data,
  reportsCompDetailSection2DataTotalRows,
  reportsCompDetailSection2PageSize,
}) => {
  const DateCell = React.useCallback((props) => {
    const { ariaColumnIndex, columnIndex, dataItem, field, render } = props;
    const defaultRendering = (
      <td aria-colindex={ariaColumnIndex} data-grid-col-index={columnIndex}>
        {dataItem[field]
          ? intl.format(props.format, new Date(dataItem[field]), "en")
          : ""}
      </td>
    );
    return render?.call(undefined, defaultRendering, props);
  }, []);

  const NumberCell = React.useCallback((props) => {
    const { ariaColumnIndex, columnIndex, dataItem, field, render } = props;
    const defaultRendering = (
      <td aria-colindex={ariaColumnIndex} data-grid-col-index={columnIndex}>
        {dataItem[field]
          ? intl.format(props.format || `{0:0}`, Number(dataItem[field]), "en")
          : ""}
      </td>
    );
    return render?.call(undefined, defaultRendering, props);
  }, []);

  const BooleanCell = React.useCallback((props) => {
    const { ariaColumnIndex, columnIndex, dataItem, field, render } = props;
    const defaultRendering = (
      <td aria-colindex={ariaColumnIndex} data-grid-col-index={columnIndex}>
        {dataItem[field] !== ""
          ? dataItem[field] == 1
            ? "true"
            : "false"
          : ""}
      </td>
    );
    return render?.call(undefined, defaultRendering, props);
  }, []);

  const getWidth = (field, totalLength) => {
    let outcome = {};
    if (totalLength > 14) {
      outcome = { width: `${2800 / totalLength}px`, field: field };
    } else {
      if (
        field.indexOf("name") > -1 ||
        field.indexOf("desc") > -1 ||
        field.indexOf("id") > -1
      ) {
        outcome = { width: "220px", field: field };
      }
      if (
        field.indexOf("type") > -1 ||
        field.indexOf("unit") > -1 ||
        field.indexOf("amount") > -1
      ) {
        outcome = { width: "200px", field: field };
      } else {
        outcome = { width: "150px", field: field };
      }
    }
    return outcome;
  };

  const customColumns = () => {
    let counter = -1;
    const cols = reportsCompDetailSection2DataColumns
      .filter((it) => it.title !== "")
      .filter((it) => it.show);
    return cols.map((column, idx) => {
      return (
        <GridColumn
          {...columnMenuProps(column, "compensation_detail")}
          key={column.field}
          id={`${column.field}@${(counter += 1)}`}
          sortable={true}
          reorderable={true}
          headerCell={HeaderCell}
          encrypted={column.encrypted}
          filterable={false}
          width={getWidth(column.field, cols.length).width}
          resizable={false}
          locked={false}
          cell={
            column.type === "boolean"
              ? BooleanCell
              : column.type === "date"
              ? DateCell
              : column.type === "number"
              ? NumberCell
              : GridCell
          }
          format={column.format}
        />
      );
    });
  };

  // const customColumns = () => {
  //   return reportsCompDetailSection2DataColumns.map((column, idx) => {
  //     return column.show && column.title !== "" ? (
  //       <GridColumn

  //         field={column.alias ? `${column.alias}$${column.field}` : column.field}
  //         title={column.title}
  //         headerCell={HeaderCell}
  //         encrypted={column.encrypted}
  //         filterable={false}
  //         key={idx}
  //         width={calculateWidth(
  //           column.alias ? `${column.alias}$${column.field}` : column.field,
  //           column.title
  //         )}
  //         cell={column.type === "boolean" && BooleanCell}
  //       />
  //     ) : (
  //       false
  //     );
  //   });
  // };

  const customRowRender = (tr, props) => {
    let newProps = { ...tr.props };
    newProps.bgcolor = "#d5d5d5";
    return React.cloneElement(tr, { ...newProps }, tr.props.children);
  };

  const newData = setExpandedState({
    data: processWithGroups(reportsCompDetailSection2Data, initialGroup),
    collapsedIds: [],
  });

  const cellRender = (td, props) => {
    if (td && td.props.children && props.rowType === "groupHeader") {
      let children = (
        <>
          <p className="k-reset">
            {td.props?.children?.props?.children[0]}
            TITLE NAME: {props.dataItem[props.field]}
          </p>
        </>
      );
      return React.cloneElement(td, td.props, children);
    }
    return td;
  };

  const removeElementsByClass = (className) => {
    const elements = document.getElementsByClassName(className);
    while (elements.length > 0) {
      elements[0].parentNode.removeChild(elements[0]);
    }
  };

  React.useEffect(() => {
    if (document.getElementsByClassName("k-grouping-header").length > 0) {
      const element = document
        .getElementsByClassName("k-grouping-header")
        .item(0);
      element.classList.add("k-hidden");
    }
    removeElementsByClass("k-grouping-header");
  }, [reportsCompDetailSection2Data]);

  return (
    <div className="compensationDetailReportTable">
      <DataTable
        gridWidth={"auto"}
        gridHeight=""
        isAPICalling={false}
        initialLoading={true}
        data={newData}
        pageData={{
          skip: 0,
          take: reportsCompDetailSection2PageSize,
        }}
        scrollable="none"
        dataCount={reportsCompDetailSection2DataTotalRows}
        customColumn={customColumns()}
        columns={reportsCompDetailSection2DataColumns}
        defaultPageSize={reportsCompDetailSection2PageSize}
        pageSize={reportsCompDetailSection2PageSize}
        module={"reports"}
        cellRender={cellRender}
        noDataFound={reportsCompDetailSection2Data?.length > 0 ? false : true}
        rowRender={customRowRender}
        groupable={true}
        group={[
          {
            field: "h$title_name",
          },
        ]}
        onGroupChange={() => null}
      />
    </div>
  );
};
export default PDFCompDetailSec2Template;
