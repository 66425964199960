import { filterBy } from "@progress/kendo-data-query";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import React from "react";
import { NODE_NAME_LIST } from "src/constants/constants";
import { changeCancelBtnState, changeUpdateBtnState } from "src/utils/utils";

export const DropDownCell =
    (props) => {
        const [filterValue, setFilterValue] = React.useState("");
        const [nodeList, setNodeList] = React.useState(NODE_NAME_LIST)
        const { ariaColumnIndex, columnIndex, dataItem, field, render } = props;
        let isInEdit = field === dataItem.inEdit;
        let fieldValue = props.dataItem[props.field];
        let requiredMsg = props.field + '_msg'
        let custom_table_mstr_key = dataItem?.custom_table_mstr_key


        const onChange = (e) => {
            if (props.onChange) {
                changeCancelBtnState(
                    "enable",
                    "grid-incell-cancel-" + custom_table_mstr_key
                  );
                  changeUpdateBtnState(
                    "enable",
                    "grid-incell-update-" + custom_table_mstr_key
                  );
                props.onChange({
                    dataIndex: 0,
                    dataItem: props.dataItem,
                    field: props.field,
                    syntheticEvent: e.syntheticEvent,
                    value: e.target.value.text,
                });
            }
        };

        const onFilterChange = (event) => {
            setFilterValue(event.filter.value);
            let dataValue = filterBy(NODE_NAME_LIST.slice(), event.filter);
            setNodeList(dataValue)
        }


        const onOpenValue = () => {
            setFilterValue("");
            setNodeList(NODE_NAME_LIST)
        };

        const onClosePopup = () => {
            setFilterValue("");
        };

        const handleBlur = (e) => {
            setFilterValue("");
        };

        const defaultRendering = (
            <td aria-colindex={ariaColumnIndex} data-grid-col-index={columnIndex}>
                {isInEdit ? (
                    <DropDownList
                        style={{
                            width: "150px",
                        }}
                        onChange={onChange}
                        value={NODE_NAME_LIST && NODE_NAME_LIST.find((c) => c.text === fieldValue)}
                        data={nodeList}
                        onOpen={onOpenValue}
                        textField="text"
                        placeholder={"Select"}
                        filterable={true}
                        onFilterChange={onFilterChange}
                        filter={filterValue}
                        onClose={onClosePopup}
                        onBlur={handleBlur}
                        disabled={nodeList.length === 0 ? true : false}
                        className="table-input"
                        name={props.field}
                    />
                ) : (
                    props.dataItem[props.field || ""]
                )}

                <div role="alert" className="k-form-error k-text-start text-wrap custom-table-data-err-msg">
                    {dataItem[requiredMsg] ? dataItem[requiredMsg] : ""}
                </div>

            </td>
        );
        return render?.call(undefined, defaultRendering, props);
    }

export const NodeNameDropdownCell =
    (props) => {
        const [filterValue, setFilterValue] = React.useState("");
        const { ariaColumnIndex, columnIndex, dataItem, field, render } = props;
        const [nodeList, setNodeList] = React.useState(dataItem.nodeNameList.data)
        let isInEdit = field === dataItem.inEdit;
        let fieldValue = props.dataItem['node_key'];
        let requiredMsg = props.field + '_msg'
        let custom_table_mstr_key = dataItem?.custom_table_mstr_key
        let uniqueValueValid = props.field + dataItem.rowId;
        const onChange = (e) => {
            if (props.onChange) {
                changeCancelBtnState(
                    "enable",
                    "grid-incell-cancel-" + custom_table_mstr_key
                  );
                  changeUpdateBtnState(
                    "enable",
                    "grid-incell-update-" + custom_table_mstr_key
                  );
                props.onChange({
                    dataIndex: 0,
                    dataItem: props.dataItem,
                    field: 'node_key',
                    syntheticEvent: e.syntheticEvent,
                    value: e.target.value,
                });
            }
        };

        const onFilterChange = (event) => {
            setFilterValue(event.filter.value);
            let dataValue = filterBy(dataItem.nodeNameList.data.slice(), event.filter);
            setNodeList(dataValue)
        }

        const onOpenValue = () => {
            setFilterValue("");
            setNodeList(dataItem.nodeNameList.data)
        };

        const onClosePopup = () => {
            setFilterValue("");
        };

        const handleBlur = (e) => {
            setFilterValue("");
        };

        const defaultRendering = (
            <td aria-colindex={ariaColumnIndex} data-grid-col-index={columnIndex}>
                {isInEdit ? (
                    <DropDownList
                        style={{
                            width: "150px",
                        }}
                        onChange={onChange}
                        value={dataItem.nodeNameList.data && dataItem.nodeNameList.data.find((c) => {
                            const fieldValueToCompare = fieldValue === 0 ? dataItem?.Node_Name : fieldValue?.value?.toString() || fieldValue?.toString();
                            return c.value.toString() === fieldValueToCompare;
                        })}
                        data={nodeList}
                        disabled={dataItem?.Node?.toLowerCase() === 'plan' ? true : !dataItem.nodeNameList.loadingState ? true : false}
                        onOpen={onOpenValue}
                        textField="text"
                        placeholder={"Select"}
                        filterable={true}
                        onFilterChange={onFilterChange}
                        filter={filterValue}
                        onClose={onClosePopup}
                        onBlur={handleBlur}
                        loading={dataItem?.Node?.toLowerCase() === 'plan' ? false  : !dataItem.nodeNameList.loadingState ? true : false}
                         className="table-input"
                         name={props.field}
                    />
                ) : (
                    <span>
                   {dataItem.nodeNameList.data && dataItem.nodeNameList.data.find((c) => c.value.toString() === props.dataItem[props.field])?.text ||  props.dataItem[props.field || ""]}
                    </span>
                )}

                <div role="alert" className="k-form-error k-text-start text-wrap custom-table-data-err-msg">
                    {dataItem[requiredMsg] ? dataItem[requiredMsg] : dataItem[uniqueValueValid] ? dataItem[uniqueValueValid] : '' }
                </div>

            </td>
        );
        return render?.call(undefined, defaultRendering, props);
    }