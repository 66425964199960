import React from "react";
import {
    GET_PLAN_KPI_ASSIGN,
    UPDATE_KPI_ASSIGN,
    GET_SELECT_GRID_COLUMN_XML,
} from "../../../api-setup/api-endpoints";
import { getUserCredential } from "../../../lib/cookies";
import { POSTAPI, errStatusHandler } from "../../../api-setup/api-helper";
import { GridColumnDataMapper } from "../../../utils/utils";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { setNewMessage } from "../../../redux/actions/setting-actions";

export default function DataRows() {
    const cookiesData = getUserCredential();
    const header = {
        "x-access-token": cookiesData.secretToken,
    };
    const history = useHistory();
    const dispatch = useDispatch();
    const [data, setData] = React.useState([]);
    const [columns, setColumns] = React.useState([]);
    const [dataCount, setDataCount] = React.useState(0);
    const [isAPICalling, setAPICallingState] = React.useState(false);
    const [isSaveData, setSaveData] = React.useState(false);
    const [isRefreshLoading, setRefreshLoading] = React.useState(false);

    const getListDataAsync = (listData) => {
        setRefreshLoading(true);
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                GET_PLAN_KPI_ASSIGN,
                null,
                header,
                listData,
                dispatch
            ).then((res) => {
                const reqData = {
                    in_tenant_id: cookiesData.out_tenant_id,
                    in_filter: null,
                    in_filter_db_name: "procare_system",
                    in_filter_table_name: "vw_plan_kpi_assignment",
                    in_result_name_mstr_key: 0,
                    in_event_type_mstr_key: 0,
                    in_hide_column: 0,
                    in_load_type: listData.in_load_type,
                };

                if (listData.in_load_type == "grid-reload") {
                    setTimeout(function () {
                        getColumListXML(reqData);
                    }, 100);
                }

                if (res) {
                    let userData = [];
                    if (res.data.out_parameters.out_ret_code === 0) {
                        userData = JSON.parse(
                            JSON.stringify(res.data.row_result)
                        );

                        userData.map((item, idx) => {
                            item.rowId = idx;
                            item.selected = false;
                            return 0;
                        });
                    }
                    setData(userData);
                    setDataCount(res.data.out_parameters.out_total_rows);
                    setAPICallingState(false);
                    setRefreshLoading(false);
                    resolve(userData);
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    const getColumListXML = (reqData) => {
        POSTAPI(
            false,
            GET_SELECT_GRID_COLUMN_XML,
            null,
            header,
            reqData,
            dispatch
        ).then((res) => {
            if (res.data.out_parameters.out_ret_code > 0) {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                setAPICallingState(true);
                errStatusHandler(res, history, dispatch);
            } else {
                const columData = GridColumnDataMapper(
                    res.data.out_parameters.out_columns_xml
                );
                setColumns(columData);
            }
        });
    };

    const assignPlanKpiAsync = (bodyData) => {
        setRefreshLoading(true);
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                UPDATE_KPI_ASSIGN,
                null,
                header,
                bodyData,
                dispatch
            ).then((res) => {
                setRefreshLoading(false);
                if (
                    res &&
                    (res.data.out_parameters.out_ret_code === 0 ||
                        res.data.out_parameters.out_ret_code === 2)
                ) {
                    resolve(true);
                } else {
                    dispatch(
                        setNewMessage({
                            message: res?.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    resolve([]);
                    errStatusHandler(res, history, dispatch);
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );

                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    return [
        {
            data,
            columns,
            dataCount,
            setData,
            setColumns,
            isAPICalling,
            setAPICallingState,
            setSaveData,
            isSaveData,
            isRefreshLoading,
        },
        {
            getListDataAsync,
            assignPlanKpiAsync,
        },
    ];
}
