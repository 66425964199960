import React from "react";
import { IncentivesData } from "./dataRowsGrid";

const IncentivesDataRows = (restProps) => {
  //disable in-cell update and cancel button
  return (
    <>
      <div className="dtu-file-container">
        <IncentivesData
          pageProps={restProps.pageProps}
          compTableMasterKey={restProps.compTableMasterKey}
          selectedTab={restProps.selected}
          setIsIncentivesUpdateClick={restProps.setIsIncentivesUpdateClick}
          setisIncentivesCancelClick={restProps.setisIncentivesCancelClick}
          setScreenLock={restProps.setScreenLock}
          screenLock={restProps.screenLock}
          view={restProps.view}
          incentivesColumnsData={restProps.incentivesColumnsData}
          setIncentivesColumnsData={restProps.setIncentivesColumnsData}
          isIncentivesCheckColumn={restProps.isIncentivesCheckColumn}
          setIncentivesColumnCheck={restProps.setIncentivesColumnCheck}
          cancelIncentivesAwaitStatus={restProps.cancelIncentivesAwaitStatus}
          setCancelIncentivesAwaitStatus={
            restProps.setCancelIncentivesAwaitStatus
          }
          isIncentivesEdit={restProps.isIncentivesEdit}
          setisIncentivesEdit={restProps.setisIncentivesEdit}
          isIncentivesUpdateClick={restProps.isIncentivesUpdateClick}
          isIncentivesCancelClick={restProps.isIncentivesCancelClick}
          isRefreshBtnClick={restProps.isRefreshBtnClick}
          setRefreshBtnClick={restProps.setRefreshBtnClick}
          selectedRowId={restProps.selectedRowId}
        />
      </div>
    </>
  );
};

export default React.memo(IncentivesDataRows);
