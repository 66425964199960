import { useQuery } from "react-query";
import {
    DashboardTypeListApi,
    DashboardPeriodTypeListApi,
    DashboardCompPlanListApi,
    DashboardKPIFilterListApi,
    DashboardResultFilterListApi,
    DashboardPODUpdateListApi,
    DashboardPODDatasetUpdateListApi,
    DashboardBusinessGroupApi,
    DashboardSpecialtyGroupApi,
    DashboardProviderApi,
    DashboardGroupApi,
} from "src/service/service.config";
import {
    DashboardTypeListApi as DashboardTypeDataSetListApi,
    DashboardPeriodTypeListApi as DashboardPeriodTypeDataSetListApi,
    DashboardCompPlanListApi as DashboardCompPlanDataSetListApi,
    DashboardKPIFilterListApi as DashboardKPIFilterDataSetListApi,
    DashboardResultFilterListApi as DashboardResultFilterDataSetList,
    DashboardPODDataSetUpdateListApi as DashboardPODDataSetUpdateList,
    DashboardPODUpdateListApi as DashboardPODUpdateList,
    DashboardRoleAccessListApi as DashboardRoleAccessList,
    DashboardBusinessGroupListApi as DashboardBusinessGroupList,
    DashboardGroupListApi as DashboardGroupList,
    DashboardSpecialityListApi as DashboardSpecialityList,
    DashboardProviderListApi as DashboardProviderList,
} from "src/service/dashboard.service";

export const DashboardTypeList = (request) => {
    const enabled =
        (request?.tenantId &&
            request?.userDBRoleKey &&
            request?.userDBRoleKey !== 0) ||
        false;
    return useQuery({
        queryKey: [DashboardTypeListApi.uniqueKey, JSON.stringify(request)],
        queryFn: () => {
            return DashboardTypeDataSetListApi(request);
        },
        enabled: enabled,
        staleTime: 10 * (60 * 1000),
        cacheTime: 15 * (60 * 1000),
        retry: 2,
        refetchOnMount: false,
        retryOnMount: false,
        refetchOnWindowFocus: false,
        refetchInterval: false
    });
};

export const DashboardPeriodTypeList = () => {
    return useQuery({
        queryKey: [DashboardPeriodTypeListApi.uniqueKey],
        queryFn: () => {
            return DashboardPeriodTypeDataSetListApi();
        },
        staleTime: 10 * (60 * 1000),
        cacheTime: 15 * (60 * 1000),
        retry: 2,
        refetchOnMount: false,
        retryOnMount: false,
        refetchOnWindowFocus: false,
        refetchInterval: false
    });
};

export const DashboardCompPlanList = (request) => {
    const enabled =
        (request?.tenantId &&
            request?.dashboardTabMstrKey &&
            request?.dashboardTabMstrKey !== 0 &&
            request?.dashboardPodKey !== 0 &&
            request?.userKey &&
            request?.userKey !== 0 &&
            request?.dbRoleTypeAbbr &&
            request?.dbRoleTypeAbbr !== "") ||
        false;
    return useQuery({
        queryKey: [DashboardCompPlanListApi.uniqueKey, JSON.stringify(request)],
        queryFn: () => {
            return DashboardCompPlanDataSetListApi(request);
        },
        enabled: enabled,
        staleTime: 10 * (60 * 1000),
        cacheTime: 15 * (60 * 1000),
        retry: 2,
        refetchOnMount: false,
        retryOnMount: false,
        refetchOnWindowFocus: false,
        refetchInterval: false
    });
};

export const DashboardKPIFilterList = (request) => {
    const enabled =
        (request?.tenantId &&
            request?.userKey &&
            request?.userKey !== 0 &&
            request?.dbRoleTypeAbbr &&
            request?.dbRoleTypeAbbr !== "" &&
            request?.dashboardTabMstrKey &&
            request?.dashboardTabMstrKey !== 0 &&
            request?.compPlanMstrKey &&
            request?.compPlanMstrKey !== null &&
            request?.compPlanMstrKey !== "") ||
        false;
    return useQuery({
        queryKey: [
            DashboardKPIFilterListApi.uniqueKey,
            JSON.stringify(request),
        ],
        queryFn: () => {
            return DashboardKPIFilterDataSetListApi(request);
        },
        enabled: enabled,
        staleTime: 10 * (60 * 1000),
        cacheTime: 15 * (60 * 1000),
        retry: 2,
        refetchOnMount: false,
        retryOnMount: false,
        refetchOnWindowFocus: false,
        refetchInterval: false
    });
};

export const DashboardResultFilterList = (request) => {
    const enabled =
        (request?.tenantId &&
            request?.kpiMstrKey &&
            request?.kpiMstrKey !== 0 &&
            request?.compPlanMstrKey &&
            request?.compPlanMstrKey !== 0 &&
            request?.userKey &&
            request?.userKey !== 0) ||
        false;
    return useQuery({
        queryKey: [
            DashboardResultFilterListApi.uniqueKey,
            JSON.stringify(request),
        ],
        queryFn: () => {
            return DashboardResultFilterDataSetList(request);
        },
        enabled: enabled,
        staleTime: 10 * (60 * 1000),
        cacheTime: 0,
        retry: 2,
        refetchOnMount: false,
        retryOnMount: false,
        refetchOnWindowFocus: false,
        refetchInterval: false
    });
};

export const DashboardPODUpdateBasicFormApi = (request) => {
    const enabled =
        (request?.tenantId && request?.userId && request?.userId !== 0) ||
        false;
    return useQuery({
        queryKey: [
            DashboardPODUpdateListApi.uniqueKey,
            JSON.stringify(request),
        ],
        queryFn: () => {
            return DashboardPODUpdateList(request);
        },
        enabled: enabled,
        staleTime: 10 * (60 * 1000),
        cacheTime: 15 * (60 * 1000),
        retry: 2,
        refetchOnMount: false,
        retryOnMount: false,
        refetchOnWindowFocus: false,
        refetchInterval: false
    });
};

export const DashboardPODUpdateDataSetApi = (request) => {
    const enabled =
        (request?.tenantId && request?.userId && request?.userId !== 0) ||
        false;
    return useQuery({
        queryKey: [
            DashboardPODDatasetUpdateListApi.uniqueKey,
            JSON.stringify(request),
        ],
        queryFn: () => {
            return DashboardPODDataSetUpdateList(request);
        },
        enabled: enabled,
        staleTime: 10 * (60 * 1000),
        cacheTime: 15 * (60 * 1000),
        retry: 2,
        refetchOnMount: false,
        retryOnMount: false,
        refetchOnWindowFocus: false,
        refetchInterval: false
    });
};

export const DashboardRoleAccessListApi = () => {
    return useQuery({
        queryFn: () => {
            return DashboardRoleAccessList();
        },
        staleTime: 10 * (60 * 1000),
        cacheTime: 15 * (60 * 1000),
        retry: 2,
        refetchOnMount: false,
        retryOnMount: false,
        refetchOnWindowFocus: false,
        refetchInterval: false
    });
};

export const DashboardBusinessGroupListApi = (request) => {
    return useQuery({
        queryKey: [
            DashboardBusinessGroupApi.uniqueKey,
            JSON.stringify(request),
        ],
        queryFn: () => {
            return DashboardBusinessGroupList(request);
        },
        staleTime: 10 * (60 * 1000),
        cacheTime: 15 * (60 * 1000),
        retry: 2,
        refetchOnMount: false,
        retryOnMount: false,
        refetchOnWindowFocus: false,
        refetchInterval: false
    });
};

export const DashboardGroupListApi = (request) => {
    const enabled = Boolean(
        request?.roleTypeAccess &&
            request?.businessGroupAccess &&
            request?.compPlanAccess
    );
    return useQuery({
        queryKey: [DashboardGroupApi.uniqueKey, JSON.stringify(request)],
        queryFn: () => {
            return DashboardGroupList(request);
        },
        enabled: enabled,
        staleTime: 10 * (60 * 1000),
        cacheTime: 15 * (60 * 1000),
        retry: 2,
        refetchOnMount: false,
        retryOnMount: false,
        refetchOnWindowFocus: false,
        refetchInterval: false
    });
};

export const DashboardSpecialtyListApi = (request) => {
    const enabled = Boolean(
        request?.roleTypeAccess &&
            request?.businessGroupAccess &&
            request?.compPlanAccess &&
            request?.groupFilter
    );
    return useQuery({
        enabled: enabled,
        queryKey: [
            DashboardSpecialtyGroupApi.uniqueKey,
            JSON.stringify(request),
        ],
        queryFn: () => {
            return DashboardSpecialityList(request);
        },
        staleTime: 10 * (60 * 1000),
        cacheTime: 15 * (60 * 1000),
        retry: 2,
        refetchOnMount: false,
        retryOnMount: false,
        refetchOnWindowFocus: false,
        refetchInterval: false
    });
};

export const DashboardProviderListApi = (request) => {
    const enabled = Boolean(
        request?.roleTypeAccess &&
            request?.businessGroupAccess &&
            request?.compPlanAccess &&
            request?.groupFilter &&
            request?.specialtyFilter
    );
    return useQuery({
        enabled: enabled,
        queryKey: [DashboardProviderApi.uniqueKey, JSON.stringify(request)],
        queryFn: () => {
            return DashboardProviderList(request);
        },
        staleTime: 10 * (60 * 1000),
        cacheTime: 15 * (60 * 1000),
        retry: 2,
        refetchOnMount: false,
        retryOnMount: false,
        refetchOnWindowFocus: false,
        refetchInterval: false
    });
};
