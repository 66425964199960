// eslint-disable-next-line no-useless-concat
/* eslint-disable jsx-a11y/anchor-is-valid */
import { getter } from "@progress/kendo-react-common";
import {
  getSelectedState,
  GridColumn as Column,
} from "@progress/kendo-react-grid";
import { Input, Checkbox } from "@progress/kendo-react-inputs";
import * as React from "react";
import { columnMenuProps } from "../../components/customColumnMenu";
import DataTable from "../../components/dataTable";
import { CellRender, RowRender } from "../../components/renderers";
import { DEFAULT_SORT } from "../../constants/grid";
import { getUserCredential, getTenantCookieData } from "../../lib/cookies";
import { getfilterDataString } from "../../utils/utils";
import useApi from "./jobDetailService";
import { HeaderPage } from "../base/headerPage";
import { Button } from "@progress/kendo-react-buttons";
import { Window, WindowActionsBar } from "@progress/kendo-react-dialogs";
import JobDetails from "../common/jobDetails";

const editField = "inEdit";
const SELECTED_FIELD = "selected";
const DATA_ITEM_KEY = "job_mstr_key";
const idGetter = getter(DATA_ITEM_KEY);

const loadingPanel = (
  <div className="k-loading-mask">
    <span className="k-loading-text">Loading</span>
    <div className="k-loading-image"></div>
    <div className="k-loading-color"></div>
  </div>
);

export const JobDetail = (restProps) => {
  const cookiesData = getUserCredential();
  const defaultPageSize = getTenantCookieData("record_per_page");

  const [list, Action] = useApi();
  const {
    data,
    columns,
    dataCount,
    setData,
    isAPICalling,
    setAPICallingState,
    initialLoading,
    isRefreshLoading,
    visible,
    setVisible,
    periodsFields,
    postionData,
    // postioncolumns,
    setPostionData,
    // setIsCompPlanDisable,
    // setPostionAPICallingState,
  } = list;

  //state to set and get filter, sort number, sort order
  const [filterData, setFilterData] = React.useState({
    isSort: false,
    isFilter: false,
    data: "",
    in_sort_number: DEFAULT_SORT.number,
    sort_order: DEFAULT_SORT.order,
  });
  const [pageData, setPage] = React.useState({
    skip: 0,
    take: defaultPageSize.recordTake,
  });
  const [pageSize, setPageSize] = React.useState(defaultPageSize);
  const [exportData, setExportData] = React.useState([]);
  const [selectedState, setSelectedState] = React.useState({});
  const [isAddJobtoQueue, setIsAddJobtoQueue] = React.useState(false);
  const [jobMstrKey, setJobMstrKey] = React.useState();
  const [compPlanKeys, setcompPlanKeys] = React.useState();
  const [errorObj, setErrorObj] = React.useState({});
  const [dataItemList, setDataItemList] = React.useState({});
  const [modal, setModal] = React.useState(true);
  const [stageChange, setStateChange] = React.useState("DEFAULT");
  // const [selectedDate, setSelectedDate] = React.useState(new Date()); // Set the initial date

  const apiCallData = {
    in_tenant_id: cookiesData.out_tenant_id,
    in_db_role_key: cookiesData?.out_db_role_key,
    in_filter: null,
    in_sort_number: DEFAULT_SORT.number,
    in_sort_order: DEFAULT_SORT.order,
    in_page_row_offset: 0,
    in_page_rows: defaultPageSize.recordTake,
    in_load_type: "data-reload",
  };

  React.useEffect(() => {
    const dataaVal = {
      ...apiCallData,
      in_load_type: "grid-reload",
    };
    getGridData(dataaVal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const shouldCallCompPlanData =
      dataItemList.job_type_code === "stage_hook" &&
      dataItemList.plan_param === 1 &&
      periodsFields.periodsData.length > 0;

    const shouldCallPositionData =
      (dataItemList.position_param === 1 ||
        dataItemList.published_position === 1 ||
        dataItemList.job_type_code === "email_process") &&
      periodsFields.periodsData.length > 0;

    const postionReqData = {
      in_tenant_id: cookiesData.out_tenant_id,
      in_job_mstr_key: jobMstrKey,
      [dataItemList.job_type_code === "email_process"
        ? "in_stagehook_job_mstr_key"
        : "in_comp_plan_mstr_key"]:
        dataItemList.job_type_code === "email_process"
          ? dataItemList.stagehook_mstr_key
          : dataItemList.comp_plan_keys,
      in_period_mstr_key: periodsFields.periodsValue.id,
      in_filter: null,
      in_sort_number: DEFAULT_SORT.number,
      in_sort_order: DEFAULT_SORT.order,
      in_is_published: 0,
      in_acct_encrypt_key: cookiesData.out_acct_encrypt_key,
      job_type_code: dataItemList.job_type_code,
    };

    if (shouldCallCompPlanData) {
      Action.getCompPlanData()
        .then((compPlanData) => {
          if (shouldCallPositionData) {
            Action.getPostionListDataAsync(postionReqData);
          }
        })
        .catch((error) => {
          // Handle errors from the getCompPlanData API call
        });
    } else if (!shouldCallCompPlanData && shouldCallPositionData) {
      Action.getPostionListDataAsync(postionReqData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataItemList, periodsFields]);

  // const getPostiongridData = async (inParams) => {
  //   Action.getPostionListDataAsync(inParams);
  // };

  const getGridData = async (inParams) => {
    let res = await Action.getListDataAsync(inParams);
    if (res) {
      restProps.setjobDetailLoading(false);
    }
  };

  const getInFilter = () => {
    return filterData.isFilter ? filterData.data : null;
  };

  const getInSortNumber = () => {
    return filterData.isSort ? filterData.in_sort_number : DEFAULT_SORT.number;
  };

  const getInSortOrder = () => {
    return filterData.isSort ? filterData.sort_order : DEFAULT_SORT.order;
  };

  // const isRowSelected = () => {
  //   const selectedStateVal = Object.values(selectedState);

  //   /**
  //    * consider no row selected if no selected state is true
  //    * the flag is set to false when user deselect any row
  //    */
  //   if (selectedStateVal.length && !selectedStateVal.includes(true)) {
  //     return false;
  //   }

  //   const selectedKeys = Object.keys(selectedState).map((key) => parseInt(key));

  //   let ids = data.map((el) => el.job_mstr_key);

  //   return !selectedKeys.length
  //     ? false
  //     : selectedKeys.every((value) => {
  //         return ids.includes(value);
  //       });
  // };

  const onRowClick = (dataItem) => {};

  const onSelectionChange = (event) => {
    const newSelectedState = getSelectedState({
      event,
      selectedState: selectedState,
      dataItemKey: DATA_ITEM_KEY,
    });

    let tempArray = [];

    Object.keys(newSelectedState).map((item) => {
      if (newSelectedState[item] === false) {
        return 0;
      }

      let obj = {};
      obj.id = Number(item);

      tempArray.push(obj);
      return 0;
    });

    const newSelectedStateKeys = Object.keys(newSelectedState);

    const selectedKeyArr = newSelectedStateKeys.filter(function (x) {
      return newSelectedState[x] !== false;
    });

    let newData = data.map((item) => ({
      ...item,
      selected: selectedKeyArr.includes(item.job_mstr_key),
    }));

    setData(newData);
    setSelectedState(newSelectedState);
  };

  const enterEdit = (dataItem, field) => {
    // if (restProps.pageProps.writeAccess === 0) {
    //   return false;
    // }

    let newData = postionData.map((item) => ({
      ...item,
      inEdit:
        item.org_position_key === dataItem.org_position_key ? field : undefined,
    }));

    setPostionData(newData);
  };

  const exitEdit = () => {
    let newData = data.map((item) => ({
      ...item,
      inEdit: undefined,
    }));
    setData(newData);
  };

  const handlePageChange = async (event) => {
    const pageData = event.page;

    setAPICallingState(true);

    setPage({ skip: pageData.skip, take: pageData.take });

    const dataVal = {
      ...apiCallData,
      in_filter: getInFilter(),
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
      in_page_row_offset: pageData.skip,
      in_page_rows: pageData.take,
    };

    getGridData(dataVal);
  };

  const handleColumnMenu = (columnMenuData, isFilter, isSort) => {
    let index = 1;

    if (data.length > 0 && columnMenuData[0] !== undefined) {
      let obj = Object.keys(data[0]);
      let val = columnMenuData[0].field;
      if (columnMenuData[0].field === "job_type_mstr_key") {
        val = columnMenuData[0].field.replace("_mstr_key", "_name");
      }
      index = obj.findIndex((x) => x === val) + 1;
    }

    let filterString = isFilter
      ? getfilterDataString(columnMenuData, columns)
      : filterData.data;

    // Use a regular expression to find and capture the value inside the single quotes
    const regex = /.job_param = '([^']+)'/;
    const match = filterString.match(regex);
    if (match) {
      // Extract the captured value
      const capturedValue = match[1];
      filterString = filterString.replace(
        `job_param = '${capturedValue}'`,
        `${capturedValue} = 1`
      );
    }
    isFilter = false;
    if (filterString !== "") {
      isFilter = true;
    }

    let sortDir =
      filterData.isSort && columnMenuData.length
        ? filterData.sort_order
        : DEFAULT_SORT.order;
    let sortNumber =
      filterData.isSort && columnMenuData.length
        ? filterData.in_sort_number
        : DEFAULT_SORT.number;

    isSort = !columnMenuData.length ? false : filterData.isSort;

    if (
      columnMenuData[0] !== undefined &&
      columnMenuData[0].dir !== undefined
    ) {
      isSort = true;
      sortDir = columnMenuData[0].dir.toUpperCase();
      sortNumber = index;
    }

    const dataVal = {
      ...apiCallData,
      in_filter: isFilter ? filterString : null,
      in_sort_number: sortNumber,
      in_sort_order: sortDir,
    };

    setFilterData({
      ...filterData,
      isFilter: isFilter,
      isSort: isSort,
      data: filterString,
      in_sort_number: sortNumber,
      sort_order: sortDir,
    });

    setAPICallingState(true);
    getGridData(dataVal);
  };
  // const handlePostionColumnMenu = (columnMenuData, isFilter, isSort) => {
  //   let index = 1;

  //   if (postionData.length > 0 && columnMenuData[0] !== undefined) {
  //     let obj = Object.keys(postionData[0]);
  //     index = obj.findIndex((x) => x === columnMenuData[0].field) + 1;
  //   }

  //   let filterString = isFilter
  //     ? getfilterDataString(columnMenuData, postioncolumns)
  //     : filterData.data;

  //   // Use a regular expression to find and capture the value inside the single quotes
  //   const regex = /.job_param = '([^']+)'/;
  //   const match = filterString.match(regex);
  //   if (match) {
  //     // Extract the captured value
  //     const capturedValue = match[1];
  //     filterString = filterString.replace(
  //       `job_param = '${capturedValue}'`,
  //       `${capturedValue} = 1`
  //     );
  //   }
  //   isFilter = false;
  //   if (filterString !== "") {
  //     isFilter = true;
  //   }

  //   let sortDir =
  //     filterData.isSort && columnMenuData.length
  //       ? filterData.sort_order
  //       : DEFAULT_SORT.order;
  //   let sortNumber =
  //     filterData.isSort && columnMenuData.length
  //       ? filterData.in_sort_number
  //       : DEFAULT_SORT.number;

  //   isSort = !columnMenuData.length ? false : filterData.isSort;

  //   if (
  //     columnMenuData[0] !== undefined &&
  //     columnMenuData[0].dir !== undefined
  //   ) {
  //     isSort = true;
  //     sortDir = columnMenuData[0].dir.toUpperCase();
  //     sortNumber = index;
  //   }

  //   const dataVal = {
  //     in_tenant_id: cookiesData.out_tenant_id,
  //     in_job_mstr_key: jobMstrKey,
  //     [dataItemList.job_type_code === "email_process"
  //       ? "in_stagehook_job_mstr_key"
  //       : "in_comp_plan_mstr_key"]:
  //       dataItemList.job_type_code === "email_process"
  //         ? dataItemList.stagehook_mstr_key
  //         : dataItemList.comp_plan_keys,
  //     in_period_mstr_key: periodsFields.periodsValue.id,
  //     in_filter: isFilter ? filterString : null,
  //     in_sort_number: sortNumber,
  //     in_sort_order: sortDir,
  //     in_is_published: 0,
  //     in_acct_encrypt_key: cookiesData.out_acct_encrypt_key,
  //     job_type_code: dataItemList.job_type_code,
  //   };

  //   setFilterData({
  //     ...filterData,
  //     isFilter: isFilter,
  //     isSort: isSort,
  //     data: filterString,
  //     in_sort_number: sortNumber,
  //     sort_order: sortDir,
  //   });

  //   setPostionAPICallingState(true);
  //   getPostiongridData(dataVal);
  // };

  const useCol = (columns) => {
    return columns.map((column, idx) => {
      return column.show && column.title !== "" ? (
        <Column
          {...columnMenuProps(column)}
          key={idx}
          sortable={
            column.field === "job_param" || column.field === "job_mstr_key"
              ? false
              : true
          }
          resizable={column.field === "job_mstr_key" ? false : true}
          width={column.field === "job_mstr_key" ? "80px" : column.minWidth}
          cell={
            column.field === "published_position"
              ? publicAssinCell
              : column.field === "job_name" ||
                column.field === "job_desc" ||
                column.field === "email_body" ||
                column.field === "job_email_notif" ||
                column.field === "email_subject" ||
                column.field === "job_typ_name"
              ? NameCell
              : column.field === "job_mstr_key"
              ? customCell
              : StringReandOnlyCell
          }
          editable={false}
        />
      ) : (
        false
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const customCell = React.useCallback((props) => {
    const { dataItem } = props;
    // const jobTypeCode = dataItem.job_type_code;
    // const jobPostion = dataItem.position_param;
    const jobMstrKey = dataItem.job_mstr_key;

    const handleIconClick = async () => {
      // await Action.getPeriodTypesData();
      setJobMstrKey(jobMstrKey);
      setIsAddJobtoQueue(true);
      setcompPlanKeys(dataItem.comp_plan_keys);
      setDataItemList(dataItem);
    };

    return (
      <td
        className="k-command-cell"
        style={{ textAlign: "center" }}
        title="Add Job to queue"
      >
        {dataItem.data_write_access === 1 ? (
          <a onClick={handleIconClick}>
            <img
              src={require("../../images/add_to_queue.png").default}
              alt="Add Job to Queue"
              className="pointer-class"
            />{" "}
          </a>
        ) : (
          false
        )}
      </td>
    );
  }, []);

  const publicAssinCell = React.useCallback(
    (props) => {
      const { ariaColumnIndex, columnIndex, dataItem, field, render } = props;
      let isInEdit = field === dataItem.inEdit;
      if (
        dataItem["job_type_name"] === "Email Process" &&
        dataItem["published_position"] === 1
      ) {
        isInEdit = field === dataItem.inEdit;
      } else {
        isInEdit = false;
      }
      let valueData =
        props.dataItem[props.field] !== ""
          ? props.dataItem[props.field] === 1
            ? "true"
            : "false"
          : "";

      let value = props.field + dataItem.rowId;

      const handleChange = (e, props, value) => {
        if (props.onChange) {
          // TODO : UNSAVED CHANGES
          // dispatch(
          //   setUnSavedChangesPopup({ ...unsavedPopup, isUnSavedChange: true })
          // );
          props.onChange({
            // dataIndex: 0,
            dataItem: props.dataItem,
            field: props.field,
            syntheticEvent: e.syntheticEvent,
            value: e.target.value === true ? 1 : 0,
          });
        }
      };
      const defaultRendering = (
        <td
          aria-colindex={ariaColumnIndex}
          data-grid-col-index={columnIndex}
          className="kpi-field"
        >
          {isInEdit ? (
            <Checkbox
              value={dataItem[props.field]}
              checked={dataItem[props.field]}
              onChange={(e) => {
                handleChange(e, props, value);
              }}
              name={`${props.field}`}
              autoComplete="off"
            />
          ) : (
            <span title={valueData}>{valueData}</span>
          )}
          {Object.keys(errorObj).length > 0 ? (
            <div role="alert" className="k-form-error k-text-start">
              {Object.keys(errorObj).length > 0 ? errorObj[value] : ""}
            </div>
          ) : (
            false
          )}
        </td>
      );
      return render?.call(undefined, defaultRendering, props);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errorObj]
  );

  const StringReandOnlyCell = React.useCallback((props) => {
    const { ariaColumnIndex, columnIndex, dataItem, field, render } = props;

    let val = dataItem[field || ""];

    if (field.indexOf("_mstr_key") !== -1) {
      val = dataItem[field.replace("_mstr_key", "_name") || ""];
    }

    const defaultRendering = (
      <td aria-colindex={ariaColumnIndex} data-grid-col-index={columnIndex}>
        <span title={val}>{val}</span>
      </td>
    );
    return render?.call(undefined, defaultRendering, props);
  }, []);

  const NameCell = React.useCallback(
    (props) => {
      const { ariaColumnIndex, columnIndex, dataItem, field, render } = props;
      let isInEdit = field === dataItem.inEdit;
      if (dataItem["job_type_name"] === "Email Process") {
        if (
          field === "email_body" ||
          field === "job_email_notif" ||
          field === "email_subject"
        ) {
          isInEdit = field === dataItem.inEdit;
        }
      } else {
        if (
          field === "email_body" ||
          field === "job_email_notif" ||
          field === "email_subject"
        ) {
          isInEdit = false;
        }
      }
      let valueData = dataItem[field || ""];
      if (field.indexOf("_mstr_key") !== -1) {
        valueData = dataItem[field.replace("_mstr_key", "_name") || ""];
      }
      let value = props.field + dataItem.rowId;
      const handleChange = (e, props, value) => {
        if (props.onChange) {
          // TODO : UNSAVED CHANGES
          // dispatch(
          //   setUnSavedChangesPopup({ ...unsavedPopup, isUnSavedChange: true })
          // );
          props.onChange({
            // dataIndex: 0,
            dataItem: props.dataItem,
            field: props.field,
            syntheticEvent: e.syntheticEvent,
            value: e.target.value,
          });
        }
      };

      const defaultRendering = (
        <td
          aria-colindex={ariaColumnIndex}
          data-grid-col-index={columnIndex}
          className="kpi-field"
        >
          {isInEdit ? (
            <Input
              // required
              value={dataItem[props.field]}
              onChange={(e) => {
                handleChange(e, props, value);
              }}
              name={`${props.field}`}
              autoComplete="off"
            />
          ) : (
            <span title={valueData}>{valueData}</span>
          )}
          {Object.keys(errorObj).length > 0 ? (
            <div role="alert" className="k-form-error k-text-start">
              {Object.keys(errorObj).length > 0 ? errorObj[value] : ""}
            </div>
          ) : (
            false
          )}
        </td>
      );
      return render?.call(undefined, defaultRendering, props);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errorObj]
  );

  let GroupColumn = useCol(columns);

  const onClearFilter = () => {
    setAPICallingState(true);

    //set in_params
    const dataVal = {
      ...apiCallData,
      in_filter: null,
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
    };

    //set filter to false
    setFilterData({
      ...filterData,
      data: "",
      isFilter: false,
    });

    getGridData(dataVal);
  };

  const onRefreshClick = () => {
    setAPICallingState(true);

    const dataVal = {
      ...apiCallData,
      in_filter: getInFilter(),
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
    };

    getGridData(dataVal);
  };

  const customRowRender = (tr, props) => (
    <RowRender
      originalProps={props}
      tr={tr}
      editField={editField}
      exitEdit={exitEdit}
    />
  );

  const getExportData = async () => {
    const dataVal = {
      ...apiCallData,
      in_filter: getInFilter(),
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
    };

    let res = await Action.exportDataAsync(dataVal);
    if (res) {
      const exportRes = res.map((item) => ({
        ...item,
        published_position: item.published_position === 1,
        job_type_mstr_key: item.job_type_name,
      }));
      setExportData(exportRes);
    }
  };

  const customCellRender = (td, props) => {
    return (
      <CellRender
        navigatable={true}
        originalProps={props}
        td={td}
        enterEdit={enterEdit}
        editField={editField}
        onRowClick={onRowClick}
        updateActBtn="grid-incell-update"
        cancelActBtn="grid-incell-cancel"
      />
    );
  };
  const onClosePopup = () => {
    setVisible(!visible);
    restProps.setisJobDetail(false);
  };

  const headerConfig = {
    // title: "Job Detail",
    id: "job-detail",
  };

  const ModalTitle = () => {
    return <span>Job Details</span>;
  };
  const handleStageChange = (e) => {
    setStateChange(e.state);
    if (e.state === "MINIMIZED") {
      setModal(false);
    } else if (e.state === "DEFAULT") {
      setModal(true);
    }
  };
  return (
    <>
      <>
        <Window
          title={<ModalTitle />}
          onClose={onClosePopup}
          width={window.innerWidth * 0.8}
          height={window.innerHeight * 0.8}
          // top={40}
          resizable={false}
          stage={stageChange}
          onStageChange={handleStageChange}
          modal={modal}
        >
          <div className="dtu-file-container-logs jobdetailspopup">
            <div className="content">
              <div className="Quick-links">
                <HeaderPage
                  pageProps={restProps.pageProps}
                  headerConfig={headerConfig}
                />
              </div>
            </div>
            {isRefreshLoading && loadingPanel}
            {columns.length > 0 ? (
              <DataTable
                isAPICalling={isAPICalling}
                initialLoading={initialLoading}
                data={data.map((item) => ({
                  ...item,
                  [SELECTED_FIELD]: selectedState[idGetter(item)],
                }))}
                id="job-grid"
                editField={editField}
                pageData={pageData}
                dataCount={dataCount}
                customColumn={GroupColumn}
                columns={columns}
                dataItemKey={DATA_ITEM_KEY}
                module={"job_configuration"}
                getExportData={getExportData}
                fileName={"Export_job_configuration.xlsx"}
                defaultPageSize={defaultPageSize}
                pageSize={pageSize}
                setPageSize={setPageSize}
                exportData={exportData}
                rowRender={customRowRender}
                cellRender={customCellRender}
                handlePageChange={handlePageChange}
                handleColumnMenu={handleColumnMenu}
                onClearFilter={onClearFilter}
                onSelectionChange={onSelectionChange}
                refreshClick={onRefreshClick}
                reorderable={false}
              />
            ) : (
              <>{loadingPanel}</>
            )}
          </div>
          <WindowActionsBar layout={"end"}>
            <Button
              className="cancelbtn"
              onClick={(e) => {
                onClosePopup();
              }}
              autoFocus={true}
            >
              {"Cancel"}
            </Button>
          </WindowActionsBar>
        </Window>
        {isAddJobtoQueue ? (
          <>
            <JobDetails
              visible={isAddJobtoQueue}
              setIsAddJobtoQueue={setIsAddJobtoQueue}
              pageProps={restProps}
              dataItemList={dataItemList}
              jobMstrKey={jobMstrKey}
              compPlanKeys={compPlanKeys}
              setIsJobqueueAdd={restProps.setIsJobqueueAdd}
              setisJobDetail={restProps.setisJobDetail}
              module={"job_execution"}
            />
          </>
        ) : (
          false
        )}
      </>
    </>
  );
};
