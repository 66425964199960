import calculateSize from "calculate-size";

export const userGridWidth = (data, field) => {
  let maxWidth = 0;

  data.forEach((item) => {
    const size = calculateSize(field, {
      font: 'Poppins',
      fontSize: "12px",
      fontWeight: 500,
    }); // pass the font properties based on the application
    if (size.width > maxWidth) {
      maxWidth = size.width + 102;
    }
  });
  return maxWidth;
};

export const calenderGridWidth = (data, field) => {
  let maxWidth = 0;

  data.forEach((item) => {
    const size = calculateSize(field.title, {
      font: 'Poppins',
      fontSize: "12px",
      fontWeight: 500,
    }); // pass the font properties based on the application
    if (size.width > maxWidth) {
      maxWidth = size.width + 200;
    }
  });
  return maxWidth;
};

export const businessGridWidth = (data, field) => {
  let maxWidth = 0;

  data.forEach((item) => {
    const size = calculateSize(field, {
      font: 'Poppins',
      fontSize: "12px",
      fontWeight: 500,
    }); // pass the font properties based on the application
    if (size.width > maxWidth) {
      maxWidth = size.width + 247;
    }
  });
  return maxWidth;
};

export const resultsGridWidth = (data, field) => {
  let maxWidth = 0;

  data.forEach((item) => {
    const size = calculateSize(field, {
      font: 'Poppins',
      fontSize: "12px",
      fontWeight: 500,
    }); // pass the font properties based on the application
    if (size.width > maxWidth) {
      maxWidth = size.width + 160;
    }
  });
  return maxWidth;
};


export const jobHistoryGridWidth = (data, field) => {
  let maxWidth = 0;

  data.forEach((item) => {
    const size = calculateSize(field.title, {
      font: 'Poppins',
      fontSize: "12px",
      fontWeight: 500,
    }); // pass the font properties based on the application
    if (size.width > maxWidth) {
      maxWidth = size.width + 80;
    }
  });
  return maxWidth;
};