// eslint-disable-next-line no-useless-concat
import React from "react";
import { HeaderPage } from "../base/headerPage";
import { getUserCredential } from "../../lib/cookies";
import useApi from "./service";
import { changeCancelBtnState, changeUpdateBtnState } from "../../utils/utils";
import { Input } from "@progress/kendo-react-inputs";
import { UNIT_TYPE_FX_DROPDOWN_CELL } from "../../constants/constants";
import MessageSchema from "../../utils/messageSchema";
import Popup from "../../components/customPopup";
import { FieldWrapper, Form, FormElement } from "@progress/kendo-react-form";
import { Error, Label } from "@progress/kendo-react-labels";
import { DataType, GridButton } from "./dataTypeGrid";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { useDocumentClickEventListener } from "../../lib/hooks/custom-common-hooks";

const UNIT_TYPE_PLACEHOLDER = "Select Group Fx";

const initialFormFields = {
  in_system_unit_name: "",
  in_system_unit_desc: "",
  in_unit_type_group_fx: {
    text: UNIT_TYPE_PLACEHOLDER,
  },
  in_unit_type_report_format: "",
};

const DataTypes = (pageProps) => {
  const [list, Action] = useApi();
  const { setData, originalData } = list;
  const cookiesData = getUserCredential();
  const [formFields, setFormFields] = React.useState({
    ...initialFormFields,
  });
  const [columnsData, setColumnsData] = React.useState([]);
  const [isCheckColumn, setColumnCheck] = React.useState(false);
  const [isShow, setShow] = React.useState({});
  const [selectedRowId, setSelectedRowId] = React.useState("");
  // const [dataTypeEdit, setDataTypeEdit] = React.useState(false);
  const [errorObj, setErrorObj] = React.useState({});
  const [isAddNewConfig, setNewConfig] = React.useState(false);
  const [isSaveClick, setSaveClick] = React.useState(false);
  const [unitEdit, setUnitEdit] = React.useState(false);
  const [exportDataClick, setExportDataClick] = React.useState(false);
  const [isAPICall, setAPICall] = React.useState(false);

  let popupRef = React.useRef();
  const addRef = React.useRef(null);

  useDocumentClickEventListener(isAddNewConfig, setNewConfig, popupRef);

  React.useEffect(() => {
    if (list.loadingState.isSaveData) {
      setFormFields({ ...initialFormFields });
      setAPICall(true);
      setNewConfig(false);
      list.setLoadState({ ...list.loadingState, isSaveData: false });
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list.loadingState.isSaveData]);

  React.useEffect(() => {
      changeCancelBtnState();
      changeUpdateBtnState();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addNewConfig = (e) => {
    setNewConfig(!isAddNewConfig);
    e.preventDefault();
    setFormFields(initialFormFields);
  };
  const handleMenuItemClick = (action) => {
    switch (action) {
      case "delete":
        setShow({
          ...isShow,
          deleteRows: !isShow.deleteRows,
        });
        break;
      default:
        break;
    }
  };

  const onCancelClick = () => {
    setFormFields(initialFormFields);
    setErrorObj({});
    setNewConfig(false);
    setSaveClick(false);
  };

  const ShowError = React.useCallback(
    (props) => {
      return <Error>{errorObj[props.name]}</Error>;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errorObj, isSaveClick]
  );

  const handleFieldChange = (e) => {
    const name = e.name ? e.name : e.target.name;
    if (e.value !== null) {
      setFormFields({ ...formFields, [name]: e.value });
      setErrorObj({ ...errorObj, [name]: "" });
    }
  };

  const handleSubmit = () => {
    let valid = handleValidation();
    if (valid) {
      setErrorObj({});
      const bodyData = {
        in_user_id: cookiesData.out_user_id,
        in_tenant_id: cookiesData.out_tenant_id,
        in_system_unit_name: formFields.in_system_unit_name,
        in_system_unit_desc: formFields.in_system_unit_desc,
        in_unit_type_group_fx:
          formFields.in_unit_type_group_fx.text !== UNIT_TYPE_PLACEHOLDER
            ? formFields.in_unit_type_group_fx.text
            : "",
        in_unit_type_report_format: formFields.in_unit_type_report_format,
      };
      Action.addDataAsync(bodyData);
    } else {
      setSaveClick(true);
    }
  };

  const onChange = (event) => {
    setFormFields({
      ...formFields,
      in_unit_type_group_fx: event.value,
    });
  };

  const AddNewForm = () => {
    return (
      <Form
        onSubmit={handleSubmit}
        render={() => (
          <FormElement className="emailForm" horizontal={true}>
            <FieldWrapper>
              <div className="k-form-field-wrap-popup">
                <Label>Name* : </Label>
                <Input
                  type="text"
                  name="in_system_unit_name"
                  value={formFields.in_system_unit_name}
                  onChange={(e) => {
                    handleFieldChange(e);
                  }}
                  placeholder={"Name"}
                  autoFocus={true}
                />
                <ShowError name="in_system_unit_name" />
              </div>
            </FieldWrapper>

            <FieldWrapper>
              <div className="k-form-field-wrap-popup">
                <Label>Description*: </Label>
                <Input
                  type="text"
                  name="in_system_unit_desc"
                  value={formFields.in_system_unit_desc}
                  placeholder={"Description"}
                  onChange={(e) => {
                    handleFieldChange(e);
                  }}
                />
                <ShowError name="in_system_unit_desc" />
              </div>
            </FieldWrapper>

            <FieldWrapper>
              <div className="k-form-field-wrap-popup">
                <Label>Group Fx : </Label>
                <div>
                  <DropDownList
                    style={{
                      width: "150px",
                    }}
                    onChange={onChange}
                    value={formFields.in_unit_type_group_fx}
                    data={UNIT_TYPE_FX_DROPDOWN_CELL}
                    dataItemKey="value"
                    textField="text"
                    defaultItem={{
                      text: UNIT_TYPE_PLACEHOLDER,
                    }}
                  />
                </div>
                <ShowError name="in_unit_type_group_fx" />
              </div>
            </FieldWrapper>

            <FieldWrapper>
              <div className="k-form-field-wrap-popup">
                <Label>Report Display : </Label>
                <Input
                  type="text"
                  name="in_unit_type_report_format"
                  value={formFields.in_unit_type_report_format}
                  onChange={(e) => {
                    handleFieldChange(e);
                  }}
                  placeholder={"Report Display"}
                />
                <ShowError name="in_unit_type_report_format" />
              </div>
            </FieldWrapper>
          </FormElement>
        )}
      />
    );
  };

  const AddNewPopup = () => {
    return (
      <Popup
        anchor={addRef.current}
        show={isAddNewConfig}
        setShow={setNewConfig}
        className="right0"
      >
        <div className="dot-dropdown-open dot-dropdown-open-Form">
          <div className="caption">
            <span>Add New System Unit Type</span>
          </div>

          {AddNewForm()}

          <div className="dropdownFooter">
            <div className="k-form-buttons">
              <Button
                primary={true}
                type="submit"
                onClick={() => {
                  onCancelClick();
                }}
              >
                Cancel
              </Button>
              <Button
                primary={true}
                type="submit"
                onClick={() => {
                  handleSubmit(formFields, errorObj);
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </Popup>
    );
  };

  const AddNewLink = () => {
    return (
      <div ref={popupRef}>
        <a
          href="/#"
          className="cta-small"
          ref={addRef}
          onClick={(e) => {
            addNewConfig(e);
          }}
        >
          <i className="fas fa-plus"></i> Add New System Unit Type
        </a>

        {AddNewPopup()}
      </div>
    );
  };

  const headerConfig = {
    title: "System Unit Types",
    id: "DataTypes",
    buttons: { template_fx: GridButton },
    add_new_fx: AddNewLink,
    action_menu: {
      delete: { fx: handleMenuItemClick },
    },
    manage_col: {
      columnsData: columnsData,
      setColumnsData: setColumnsData,
      setColumnCheck: setColumnCheck,
    },
  };

  const parent = document.querySelector("#content-" + headerConfig.id);
  if (parent) {
    const auditLog = parent.querySelector(".audit-log");
    const deleteRows = parent.querySelector(".delete-rows");

    auditLog && auditLog.disableKendoMenu();
    deleteRows && deleteRows.disableKendoMenu();

    if (selectedRowId !== "") {
      auditLog && auditLog.enableKendoMenu();
      deleteRows && deleteRows.enableKendoMenu();
    }
  }

  if (document.getElementById("grid-incell-cancel")) {
    document.getElementById("grid-incell-cancel").onclick = function (e) {
      originalData.map((item) => {
        item.inEdit = undefined;
        item.selected = false;
        return 0;
      });
      setData(originalData);
      setErrorObj({});
      //disable in-cell update and cancel button
      e.target.disableKendoBtn();
      changeUpdateBtnState();
    };
  }

  const handleValidation = () => {
    let isValid = true;

    if (formFields.in_system_unit_name.trim() === "") {
      isValid = false;
      errorObj.in_system_unit_name = MessageSchema.systemUnitRequired;
    }

    if (formFields.in_system_unit_desc.trim() === "") {
      isValid = false;
      errorObj.in_system_unit_desc = MessageSchema.systemUnitDescRequired;
    }

    setErrorObj((preVState) => ({ ...preVState, errorObj }));

    return isValid;
  };

  return (
    <>
      <div className="Main eventspage">
        <div className="content">
          <div className="content-head">
            <HeaderPage pageProps={pageProps} headerConfig={headerConfig} />
          </div>
          <div className="content-body">
            <div className="dtu-file-container">
              <DataType
                isAPICall={isAPICall}
                setAPICall={setAPICall}
                setExportDataClick={setExportDataClick}
                exportDataClick={exportDataClick}
                selectedRowId={selectedRowId}
                setSelectedRowId={setSelectedRowId}
                setShow={setShow}
                isShow={isShow}
                pageProps={pageProps}
                unitEdit={unitEdit}
                setUnitEdit={setUnitEdit}
                columnsData={columnsData}
                setColumnsData={setColumnsData}
                setColumnCheck={setColumnCheck}
                isCheckColumn={isCheckColumn}
                reorderable={false}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(DataTypes);
