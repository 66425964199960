import { useCallback, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { DELETE_BTN_ACTIONS } from "src/constants/constants";
import { setNewMessage } from "src/redux/actions/setting-actions";
import {
    DashboardTabCopyApi,
    DashboardTabDeleteApi,
    PublishDashboardApi,
} from "src/service/dashboard.service";
import { DashboardAccountSummary } from "src/service/query/dashboard-tab-list.query";
import {
    getDashboardPublishEnable,
    mapDashboardPodData,
    setDashboardPod,
    setDashboardPublishEnable,
} from "src/store/dashboard.store";
import { getUserData, mapUserData } from "src/store/user.store";
import { DashboardConstant } from "./index.const";

const { tenantId, userKey, acctEncryptKey, dbRoleKey } = mapUserData;
export const DashboardHook = () => {
    const [activeTabStrip, setActiveTabStrip] = useState(
        DashboardConstant.defaultTabKey
    );
    const [isPublishDashboardApiCalled, setIsPublishDashboardApiCalled] =
        useState(false);

    const [isPublishLoading, setIsPublishLoading] = useState(false);

    const userData = getUserData();
    const dashboardPublishEnable = getDashboardPublishEnable();

    const syncDashboardApi = async () => {
        setIsPublishLoading(true);
        const payload = {
            tenantId: tenantId.getData(userData) || null,
            dashboardTabKey: 0,
            adminUserKey: 0,
            userId: userKey.getData(userData),
            dbRoleKey: dbRoleKey.getData(userData),
        };
        await PublishDashboardApi(payload);
        setIsPublishDashboardApiCalled(true);
    };

    const {
        data: dashboardAccountSummaryData,
        isLoading: dashboardAccountSummaryisLoading,
        refetch,
    } = DashboardAccountSummary({
        tenantId: tenantId.getData(userData),
        userKey: userKey.getData(userData),
        dbRoleKey: dbRoleKey.getData(userData),
        acctEncryptKey: acctEncryptKey.getData(userData),
        isPublishDashboardApiCalled: isPublishDashboardApiCalled,
    });

    useEffect(() => {
        syncDashboardApi();
    }, []);

    const reFetchDashTabData = async (tabSelection, lastInsertTabKey) => {
        try {
            const response = await refetch();
            let position = tabSelection;
            if (!response.isLoading && response.data && position < 0) {
                position = response.data.findIndex(
                    (item) => item.dashboard_tab_mstr_key === lastInsertTabKey
                );
            }
            position =
                position > 0 ? position : DashboardConstant.defaultTabKey;
            setActiveTabStrip(position);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        setIsPublishLoading(false);
        setDashboardPod(dashboardAccountSummaryData?.[activeTabStrip] || {});
    }, [activeTabStrip, dashboardAccountSummaryData]);

    const tabStripOnChange = (e) => {
        if (e?.selected !== activeTabStrip) {
            setActiveTabStrip(e.selected);
        }
    };

    const { tabId } = mapDashboardPodData;

    const [confirmationPopup, setConfirmationPopup] = useState({
        title: "Delete",
        desc: `<p>Are you sure you want to delete this record?</p>`,
        isVisible: false,
        type: "",
        actionBtn: [],
        data: {},
        action: "Delete",
    });
    const dispatch = useDispatch();

    const deleteDashboardTab = useMutation({
        mutationFn: async () => {
            const dashboardTabKey = tabId.getData(
                dashboardAccountSummaryData?.[activeTabStrip]
            );
            if (dashboardTabKey) {
                const payload = {
                    userId: userKey.getData(userData) || null,
                    dbRoleKey: dbRoleKey.getData(userData),
                    tenantId: tenantId.getData(userData) || null,
                    dashboardTabKey: dashboardTabKey || null,
                };
                await DashboardTabDeleteApi(payload);
            }
        },
        onSuccess() {
            reFetchDashTabData(DashboardConstant.defaultTabKey);
        },
        onError() {
            dispatch(
                setNewMessage({
                    message: DashboardConstant.notification.error.text,
                    type: DashboardConstant.notification.error.type,
                    show: true,
                })
            );
        },
    });

    const copyDashboardTab = useMutation({
        mutationFn: async () => {
            const dashboardTabKey = tabId.getData(
                dashboardAccountSummaryData?.[activeTabStrip]
            );
            if (dashboardTabKey) {
                const payload = {
                    userId: userKey.getData(userData) || null,
                    dbRoleKey: dbRoleKey.getData(userData),
                    dashboardTabKey: dashboardTabKey || null,
                    tenantId: tenantId.getData(userData) || null,
                };
                return await DashboardTabCopyApi(payload);
            }
        },
        onSuccess(lastInsertTabKey) {
            dispatch(
                setNewMessage({
                    message: DashboardConstant.notification.copySuccess.text,
                    type: DashboardConstant.notification.copySuccess.type,
                    show: true,
                })
            );
            reFetchDashTabData(-1, lastInsertTabKey);
        },
        onError(error) {
            dispatch(
                setNewMessage({
                    message: error
                        ? error
                        : DashboardConstant.notification.error.text,
                    type: DashboardConstant.notification.error.type,
                    show: true,
                })
            );
        },
    });

    const publishDashboard = useMutation({
        mutationFn: async ({ isPublish, onPageLoadSync }) => {
            setIsPublishLoading(true);
            const dashboardTabKey = tabId.getData(
                dashboardAccountSummaryData?.[activeTabStrip]
            );
            let result = {};
            if (dashboardTabKey) {
                const payload = {
                    tenantId: tenantId.getData(userData) || null,
                    dashboardTabKey: isPublish ? dashboardTabKey : 0,
                    adminUserKey: isPublish ? userKey.getData(userData) : 0,
                    userId: isPublish ? 0 : userKey.getData(userData),
                    dbRoleKey: dbRoleKey.getData(userData),
                };
                result = await PublishDashboardApi(payload);
            }
            return { ...result, onPageLoadSync };
        },
        onSuccess({ lastInsertTabKey, onPageLoadSync }) {
            Object.keys(dashboardPublishEnable).forEach((key) => {
                dashboardPublishEnable[key] = false;
            });
            setDashboardPublishEnable({ ...dashboardPublishEnable });
            /*
            if (!onPageLoadSync) {
                reFetchDashTabData(-1, lastInsertTabKey);
            }
			*/
            setIsPublishLoading(false);
        },
        onError(error) {
            setIsPublishLoading(false);
            dispatch(
                setNewMessage({
                    message: error
                        ? error
                        : DashboardConstant.notification.error.text,
                    type: DashboardConstant.notification.error.type,
                    show: true,
                })
            );
        },
    });

    const onDeleteConfirm = useCallback(() => {
        deleteDashboardTab.mutate();
        setConfirmationPopup({
            ...confirmationPopup,
            isVisible: false,
            type: "",
            actionBtn: [],
            data: {},
            action: "Delete",
        });
    }, [confirmationPopup, deleteDashboardTab]);

    const onCancelConfirm = useCallback(() => {
        setConfirmationPopup({
            ...confirmationPopup,
            isVisible: false,
            type: "",
            actionBtn: [],
            data: {},
            action: "Delete",
        });
    }, [confirmationPopup]);

    const onDeleteDashTab = useCallback(() => {
        setConfirmationPopup({
            ...confirmationPopup,
            isVisible: true,
            type: "DELETE_CONFIRM",
            actionBtn: DELETE_BTN_ACTIONS(onCancelConfirm, () =>
                onDeleteConfirm()
            ),
            data: {},
            action: "Delete",
        });
    }, [confirmationPopup, onCancelConfirm, onDeleteConfirm]);

    const onCopyConfirm = useCallback(() => {
        copyDashboardTab.mutate();
        setConfirmationPopup({
            ...confirmationPopup,
            isVisible: false,
            type: "Copy",
            actionBtn: [],
            data: {},
            action: "Copy",
        });
    }, [confirmationPopup, copyDashboardTab]);

    const handleCopyDashTab = useCallback(() => {
        setConfirmationPopup({
            ...confirmationPopup,
            title: "Copy Dashboard",
            desc: "Do you want to copy selected Dashboard?",
            isVisible: true,
            type: "DELETE_CONFIRM",
            actionBtn: DELETE_BTN_ACTIONS(onCancelConfirm, () =>
                onCopyConfirm()
            ),
            data: {},
            action: "Copy",
        });
    }, [confirmationPopup, onCancelConfirm, onCopyConfirm]);

    const onPublishConfirm = useCallback(() => {
        publishDashboard.mutate({ isPublish: true });
        setConfirmationPopup({
            ...confirmationPopup,
            isVisible: false,
            type: "Publish",
            actionBtn: [],
            data: {},
            action: "Publish",
        });
    }, [confirmationPopup, publishDashboard]);

    const handlePublishDashboard = useCallback(() => {
        setConfirmationPopup({
            ...confirmationPopup,
            title: "Publish Dashboard",
            desc: "Do you want to publish selected Dashboard?",
            isVisible: true,
            type: "DELETE_CONFIRM",
            actionBtn: DELETE_BTN_ACTIONS(onCancelConfirm, () =>
                onPublishConfirm()
            ),
            data: {},
            action: "Publish",
        });
    }, [confirmationPopup, onCancelConfirm, onPublishConfirm]);

    return {
        tabStripOnChange,
        activeTabStrip,
        dashboardAccountSummaryData,
        dashboardAccountSummaryisLoading,
        onDeleteDashTab,
        confirmationPopup,
        onCancelConfirm,
        handleCopyDashTab,
        reFetchDashTabData,
        handlePublishDashboard,
        publishDashboard,
        isPublishLoading,
    };
};
