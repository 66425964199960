import moment from "moment";
import * as React from "react";

const PDFPageTemplate = (props) => {
  return (
    <>
      <div style={{
        position: "absolute",
        top: "20px",
        height: "80px",
        width: "calc(100% - 60px)",
        borderBottom: "1px solid black",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
      }}>
        <div className="Header-left">
          <img
            src={require("../../images/logo-desktop.png").default}
            alt="ProCARE"
            className="procare-icon"
          />{" "}
          <span>
            <img
              src={require("../../images/Pro-care.png").default}
              height="39"
              alt=""
            />
          </span>
        </div>
        <div style={{
          display: "flex",
          flexDirection: "column",
          width: "50%",
          textAlign: "center",
          alignSelf: "center"
        }}>
          <p>Plan Comp Statement</p>
          <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
            <span><span style={{fontWeight: "bold"}}>Comp Plan:</span> {props.compPlan}</span>
            <span><span style={{fontWeight: "bold"}}>Period:</span> {props.period}</span>
            <span><span style={{fontWeight: "bold"}}>Run Date:</span> {moment(new Date()).format("YYYY-MM-DD")}</span>
          </div>
        </div>
        <span></span>
      </div>
      <div style={{
        position: "absolute",
        bottom: "0px",
        height: "40px",
        width: "calc(100% - 60px)",
        borderTop: "1px solid black",
        textAlign: "center"
      }}>
        Page {props.pageNum} of {props.totalPages}
      </div>
    </>
  );
};

export default PDFPageTemplate;