import React from "react";
import {
    GET_ETL_GROUP_LIST,
    ADD_ETL_DATA,
    UPDATE_ETL_DATA,
    UPDATE_ETL_ATTRIBUTE_DATA,
    GET_DTU_LOGS,
    GET_ETL_EVENT_TYPE,
    GET_ETL_CONFIG_DATA,
    DELETE_ETL_DATA,
    DELETE_ETL_ATTRIBUTE_DATA,
    GET_ETL_ATTRIBUTE_DATA,
} from "../../api-setup/api-endpoints";
import { getUserCredential } from "../../lib/cookies";
import { POSTAPI, errStatusHandler } from "../../api-setup/api-helper";
import { GridColumnDataMapper } from "../../utils/utils";
import { useHistory } from "react-router";
import { USER_GRID_DEFAULT_PAGE_SIZE } from "../../constants/grid";
import { useDispatch } from "react-redux";
import { setNewMessage } from "../../redux/actions/setting-actions";

const initialDataState = {
    skip: 0,
    take: USER_GRID_DEFAULT_PAGE_SIZE,
};
let defaultItem = {
    text: USER_GRID_DEFAULT_PAGE_SIZE,
    id: 2,
};

export default function DtuData() {
    const cookiesData = getUserCredential();


    const header = {
        "x-access-token": cookiesData.secretToken,
    };
    const history = useHistory();
    const dispatch = useDispatch();
    const [data, setData] = React.useState([]);
    const [columns, setColumns] = React.useState([]);
    const [dataCount, setDataCount] = React.useState(0);
    const [isColumnMenuSearch, setIsColumnMenuSearch] = React.useState(false);
    const [paginationData, setPaginationData] = React.useState({
        pageData: 0,
        rows: 10,
    });

    const [loadingState, setLoadState] = React.useState({
        isSaveData: undefined,
        isDeleteData: undefined,
        isupdateData: undefined,
    });
    const [updateloadingState, setUpdateloadingState] = React.useState(false);
    const [initialLoading, setLoadingState] = React.useState(false);
    const [pageData, setPage] = React.useState(initialDataState);
    const [page, setPageData] = React.useState(0);
    const [editData, setEditData] = React.useState("");
    const [pageSize, setPageSize] = React.useState(defaultItem);
    const [confirmationPopup, setConfirmationPopup] = React.useState({
        title: "Delete",
        desc: "Are you sure you want to delete this record?",
        isVisible: false,
        type: "",
        actionBtn: [],
        data: {},
        action: "Delete",
    });
    const [originalData, setOriginalData] = React.useState([]);
    const [isAPICalling, setAPICallingState] = React.useState(false);
    const [isRefreshLoading, setRefreshLoading] = React.useState(false);
    const [dtuTableData, setdtuTableData] = React.useState([]);
    const [eventTypeData, seteventTypeData] = React.useState([]);
    const [updatEtlAttrData, seupdatEtlAttrData] = React.useState([]);
    const [configEtlAttrData, setconfigEtlAttrData] = React.useState([]);

    const getListDataAsync = (listData) => {
        setIsColumnMenuSearch(true);
        listData.in_tenant_id = cookiesData.out_tenant_id;
        listData.in_encrypt_key = cookiesData.out_acct_encrypt_key;
        listData.in_export_flag = 0;
        listData.in_org_title_keys = null;
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                GET_ETL_GROUP_LIST,
                null,
                header,
                listData,
                dispatch
            ).then((res) => {
                if (res) {
                    if (res.data.out_parameters.out_ret_code > 0) {
                        setLoadingState(true);
                        errStatusHandler(res, history, dispatch);
                    }
                    setAPICallingState(false);
                    setLoadingState(true);
                    setEditData("");
                    setConfirmationPopup({
                        ...confirmationPopup,
                        isVisible: false,
                        type: "",
                        actionBtn: [],
                        data: {},
                        action: "",
                    });
                    let userData = JSON.parse(
                        JSON.stringify(res.data.row_result)
                    );
                    if (listData.in_load_type === "grid-reload") {
                        const columData = GridColumnDataMapper(
                            res.data.out_parameters.out_column_xml
                        );
                        columData.map((cItem) => {
                            cItem.minWidth = 250;
                            return 0;
                        });
                        setColumns(columData);
                    }

                    userData.map((item, idx) => {
                        item.rowId = idx;
                        item.selected = false;
                        item.isValidateRow = false;
                        item.expanded = false;
                        return 0;
                    });

                    setData(userData);
                    setOriginalData(userData);

                    setDataCount(res.data.out_parameters.out_total_rows);
                    setRefreshLoading(false);
                    resolve(userData);
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };
    const exportDataAsync = (bodyData) => {
        setRefreshLoading(true);
        bodyData.in_tenant_id = cookiesData.out_tenant_id;
        bodyData.in_encrypt_key = cookiesData.out_acct_encrypt_key;
        bodyData.in_export_flag = 0;
        bodyData.in_org_title_keys = null;
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                GET_ETL_GROUP_LIST,
                null,
                header,
                bodyData,
                dispatch
            ).then((res) => {
                setRefreshLoading(false);
                if (
                    res &&
                    (res.data.out_parameters.out_ret_code === 0 ||
                        res.data.out_parameters.out_ret_code === 2)
                ) {
                    resolve(res.data.row_result);
                } else {
                    dispatch(
                        setNewMessage({
                            message: res?.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    resolve([]);
                    errStatusHandler(res, history, dispatch);
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    const addDataAsync = (bodyData) => {
        setRefreshLoading(true);
        setLoadState({ ...loadingState, isSaveData: false });

        POSTAPI(false, ADD_ETL_DATA, null, header, bodyData, dispatch).then(
            (res) => {
                if (res.status) {
                    setRefreshLoading(false);
                    if (res.data.out_parameters.out_ret_code > 0) {
                        dispatch(
                            setNewMessage({
                                message: res.data.out_parameters.out_error_msg,
                                type: "Error",
                                show: true,
                            })
                        );
                        errStatusHandler(res, history, dispatch);
                    } else {
                        setLoadState({ ...loadingState, isSaveData: true });
                    }
                }
            }
        );
    };

    const UpdateDataAsync = (bodyData, attrBodyData) => {
        setRefreshLoading(true);
        setLoadState({ ...loadingState, isupdateData: false });
        POSTAPI(false, UPDATE_ETL_DATA, null, header, bodyData, dispatch).then(
            (res) => {
                setRefreshLoading(false);
                if (res.status) {
                    if (res.data.out_parameters.out_ret_code > 0) {
                        dispatch(
                            setNewMessage({
                                message: res.data.out_parameters.out_error_msg,
                                type: "Error",
                                show: true,
                            })
                        );
                        errStatusHandler(res, history, dispatch);
                        setLoadState({ ...loadingState, isupdateData: true });
                    } else {
                        setUpdateloadingState(true);
                        attrUpdateDataAsync(attrBodyData);
                        setLoadState({ ...loadingState, isupdateData: true });
                    }
                }
            }
        );
    };

    const attrUpdateDataAsync = (bodyData) => {
        POSTAPI(
            false,
            UPDATE_ETL_ATTRIBUTE_DATA,
            null,
            header,
            bodyData,
            dispatch
        ).then((res) => {
            if (res.status) {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                } else {
                    setUpdateloadingState(true);
                }
            }
        });
    };

    const getDtuLogs = () => {
        const data = {
            in_filter: "",
            in_sort_number: null,
            in_sort_order: null,
            in_page_row_offset: 0,
            in_page_rows: 100,
        };

        POSTAPI(false, GET_DTU_LOGS, null, header, data, dispatch).then(
            (res) => {
                if (res.data.out_parameters.out_ret_code === 0) {
                    if (res.data.row_result.length > 0) {
                        if (res.status) {
                            let tempArray = [];
                            res.data.row_result.map((item) => {
                                let obj = {};
                                obj.etl_table_label_name = item.etl_table_label;
                                obj.etl_table_mstr_key =
                                    item.etl_table_mstr_key;
                                tempArray.push(obj);
                                return 0;
                            });
                            setdtuTableData(tempArray);
                        } else {
                            errStatusHandler(res, history, dispatch);
                        }
                    }
                } else {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                }
            }
        );
    };

    const GetEventTypeAPI = (etlFileMstrKey) => {
        const data = {
            in_tenant_id: cookiesData.out_tenant_id,
            in_filter: `AND a.etl_table_mstr_key = '${etlFileMstrKey}' `,
            in_sort_number: 1,
            in_sort_order: "DESC",
        };
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                GET_ETL_EVENT_TYPE,
                null,
                header,
                data,
                dispatch
            ).then((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    setLoadingState(true);
                    // errStatusHandler(res, history, dispatch);
                }
                let tempArray = [];
                res.data.row_result.map((item) => {
                    let obj = {};
                    obj.in_event_type_mstr_label = item.event_type_name;
                    obj.in_event_type_mstr_key = item.event_type_mstr_key;
                    tempArray.push(obj);
                    return 0;
                });
                seteventTypeData(tempArray);
                resolve(res.data.row_result);
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                // if (res.data.out_parameters.out_ret_code > 0) {
                //   dispatch(
                //     setNewMessage({
                //       message: res.data.out_parameters.out_error_msg,
                //       type: "Error",
                //       show: true,
                //     })
                //   );
                //   errStatusHandler(res, history, dispatch);
                // }
            });
    };

    const GetEventConfigAPI = (etlFileMstrKey) => {
        const data = {
            in_filter: `AND a.etl_file_mstr_key = ${etlFileMstrKey}`,
            in_page_row_offset: 0,
            in_page_rows: 9999,
            in_sort_number: 1,
            in_sort_order: "DESC",
        };
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                GET_ETL_CONFIG_DATA,
                null,
                header,
                data,
                dispatch
            ).then((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    setLoadingState(true);
                    errStatusHandler(res, history, dispatch);
                }
                resolve(res.data.row_result);
                seupdatEtlAttrData(res.data.row_result);
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    // dispatch(
                    //   setNewMessage({
                    //     message: res.data.out_parameters.out_error_msg,
                    //     type: "Error",
                    //     show: true,
                    //   })
                    // );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    const GetEventAttributeAPI = (etlFilData) => {
        const data = {
            in_filter: null,
            in_page_row_offset: 0,
            in_page_rows: 9999,
            in_sort_number: 1,
            in_sort_order: "DESC",
            row_result: etlFilData,
        };
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                GET_ETL_ATTRIBUTE_DATA,
                null,
                header,
                data,
                dispatch
            ).then((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    setLoadingState(true);
                    errStatusHandler(res, history, dispatch);
                }
                resolve(res.data.row_result);
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    const deleteDataAsync = async (bodyData, resultArray) => {
        setRefreshLoading(true);
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                DELETE_ETL_DATA,
                null,
                header,
                bodyData,
                dispatch
            ).then((res) => {
                setRefreshLoading(false);
                if (res.data.out_parameters.out_ret_code > 0) {
                    let msg = res.data.out_parameters.out_error_msg;

                    setLoadState({ ...loadingState, isDeleteData: false });
                    setConfirmationPopup({
                        ...confirmationPopup,
                        isVisible: false,
                        type: "",
                        actionBtn: [],
                        data: {},
                        action: "",
                    });
                    dispatch(
                        setNewMessage({
                            message: msg,
                            type: "Error",
                            show: true,
                        })
                    );
                } else {
                    onDeleteAttributeConfirm(resultArray);
                    setConfirmationPopup({
                        ...confirmationPopup,
                        isVisible: false,
                        type: "",
                        actionBtn: [],
                        data: {},
                        action: "",
                    });
                    setLoadState({ ...loadingState, isDeleteData: true });
                    setTimeout(function () {
                        resolve("success");
                    });
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    const onDeleteAttributeConfirm = (resultArray) => {
        let data = {
            in_user_id: cookiesData.out_user_id.toString(),
            deleteETLFileAttributeArray: resultArray,
            in_tenant_id: cookiesData.out_tenant_id,
        };
        POSTAPI(
            false,
            DELETE_ETL_ATTRIBUTE_DATA,
            null,
            header,
            data,
            dispatch
        ).then((res) => {
            if (res.data.out_parameters.out_ret_code > 0) {
            } else {
                setConfirmationPopup({
                    ...confirmationPopup,
                    isVisible: false,
                    type: "",
                    actionBtn: [],
                    data: {},
                    action: "",
                });

                // getListDataAsync(apiCallData);
                // setDeleteSelectedIds([]);
                // setSelectedState({});
            }
        });
    };

    return [
        {
            data,
            columns,
            dataCount,
            setData,
            setColumns,
            isColumnMenuSearch,
            pageData,
            editData,
            page,
            originalData,
            pageSize,
            isAPICalling,
            setEditData,
            confirmationPopup,
            setConfirmationPopup,
            initialLoading,
            setPage,
            setPageData,
            setPageSize,
            setPaginationData,
            setAPICallingState,
            paginationData,
            loadingState,
            setLoadState,
            updateloadingState,
            setUpdateloadingState,
            setRefreshLoading,
            isRefreshLoading,
            setdtuTableData,
            dtuTableData,
            eventTypeData,
            seteventTypeData,
            updatEtlAttrData,
            seupdatEtlAttrData,
            configEtlAttrData,
            setconfigEtlAttrData
        },
        {
            getListDataAsync,
            addDataAsync,
            UpdateDataAsync,
            getDtuLogs,
            GetEventTypeAPI,
            GetEventConfigAPI,
            deleteDataAsync,
            GetEventAttributeAPI,
            exportDataAsync,
        },
    ];
}
