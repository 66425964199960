import React from "react";
import DataTable from "../../components/dataTable";
import { GridColumn as Column } from "@progress/kendo-react-grid";
import { POSTAPI } from "../../api-setup/api-helper";
import { getTenantCookieData, getUserCredential } from "../../lib/cookies";
import { GET_DTU_ERROR_LOGS } from "../../api-setup/api-endpoints";
import {
    GridColumnDataMapper,
    formatDate,
} from "../../utils/utils";
import {
    DEFAULT_SORT,
    USER_GRID_DEFAULT_PAGE_SIZE,
    USER_GRID_PAGE_SIZES,
} from "../../constants/grid";
import {
    columnMenuProps,
} from "../../components/customColumnMenu";
import ErrorDetailLogs from "./errorDetailLogs";
import { LoadingPanel } from "../../components/loadingPanel";
import { useDispatch } from "react-redux";
import { getfilterDataString } from "../../utils/utils";
import { Button } from "@progress/kendo-react-buttons";
import { Window, WindowActionsBar } from "@progress/kendo-react-dialogs";
import { HeaderPage } from "../base/headerPage";

const ModalTitle = () => {
    return (
        <span>
            DTU Error Logs
        </span>
    );
};


const DtuErrorLogs = (restProps) => {
    const dispatch = useDispatch();
    const defaultPageSize = getTenantCookieData('record_per_page')

    const [visible, setVisible] = React.useState(restProps.visible);
    const [data, setData] = React.useState([]);
    const cookiesData = getUserCredential();
    const [columns, setColumns] = React.useState([]);
    const [dataCount, setDataCount] = React.useState(0);
    const [initialLoading, setLoadingState] = React.useState(false);
    const [isAPICalling, setAPICallingState] = React.useState(false);
    const [exportDataClick, setExportDataClick] = React.useState(false);
    const [exportData, setExportData] = React.useState([]);
    const [paginationData, setPaginationData] = React.useState({
        pageData: 0,
        rows: 10,
    });
    const [filterData, setFilterData] = React.useState({
        isSort: false,
        isFilter: false,
        data: '',
        in_sort_number: DEFAULT_SORT.number,
        sort_order: DEFAULT_SORT.order
    });
    const [pageData, setPage] = React.useState({
        skip: 0,
        take: defaultPageSize.recordTake
    });
    const [pageSize, setPageSize] = React.useState(defaultPageSize)

    const apiCallData = {
        in_filter: null,
        in_sort_number: DEFAULT_SORT.number,
        in_sort_order: DEFAULT_SORT.order,
        in_page_row_offset: 0,
        in_page_rows: defaultPageSize.recordTake,
        in_batch_group_flag: 0,
    };
    const header = {
        "x-access-token": cookiesData.secretToken,
    };

    const [isErrorDetail, setIsErrorDetail] = React.useState(false);
    const [errorObj, setErrorObj] = React.useState("");


    const getInFilter = () => {

        return filterData.isFilter ? `${filterData.data}` :

            `AND (u.etl_batch_mstr_key = ${restProps.selectedBatchKey})`;
    };

    const getInSortNumber = () => {

        return filterData.isSort ? filterData.in_sort_number : DEFAULT_SORT.number;
    };

    const getInSortOrder = () => {

        return filterData.isSort ? filterData.sort_order : DEFAULT_SORT.order;
    };
    const getPageTake = () => {
        return pageData.take;
    };
    const getPageSkip = () => {
        return pageData.skip;
    };

    React.useEffect(() => {
        apiCallData.in_filter = getInFilter()
        getDTUErrorLogsAsync(apiCallData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getDTUErrorLogsAsync = (data) => {
        setAPICallingState(true);
        setLoadingState(true);
        POSTAPI(false, GET_DTU_ERROR_LOGS, null, header, data, dispatch).then(
            (res) => {
                if (res.status) {
                    setLoadingState(true);
                    setAPICallingState(false);
                    const columnData = GridColumnDataMapper(
                        res.data.out_parameters.out_columns_xml
                    );
                    columnData.map((item) => {
                        item.width = 150;
                        return 0;
                    })
                    setColumns(columnData);
                    res.data.row_result.forEach((item) => {
                        ["action_date"].forEach((field) => {
                            if (item[field]) {
                                item[field] = new Date(item[field]);
                            }
                        });
                    });
                    setData(res.data.row_result);
                    setDataCount(res.data.out_parameters.out_total_rows);
                }
            }
        );
    };

    const LinkCell = React.useCallback((props) => {
        const { dataItem } = props;

        const onLinkClick = () => {
            setIsErrorDetail(true)
            setErrorObj(dataItem)
        };

        return (
            <td>
                {dataItem.error_code === 0 ? (
                    dataItem.error_code
                ) : (
                    <span
                        className={"link-class"}
                        onClick={() => {
                            onLinkClick();
                        }}
                    >
                        {dataItem.error_code}
                    </span>
                )}
            </td>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const StringReadAndOnlyCell = React.useCallback((props) => {
        const { ariaColumnIndex, columnIndex, dataItem, field } = props;

        let val = dataItem[field || ""];

        const defaultRendering = (
            <td aria-colindex={ariaColumnIndex} data-grid-col-index={columnIndex}>
                <span title={val}>{val}</span>
            </td>
        );

        return defaultRendering;
    }, []);

    const cellType = (module) => {
        switch (module) {
            case "error_code":
                return LinkCell;
            default:
                return StringReadAndOnlyCell;
        }
    };

    const DtuErrorLogsColumn = columns.map((column, idx) => {
        return (
            <Column
                {...columnMenuProps(column)}
                key={idx}
                field={column.field}
                title={<span title={column.title}>{column.title}</span>}
                format={column.format}
                cell={column.type === 'date' ? '' : cellType(column.field)}
                resizable={true}
                width={'170px'}
            />
        );
    });

    const handleColumnMenu = (
        columnMenuData, isFilter, isSort
    ) => {
        let index = 1
        let filterSkip = getPageSkip()
        let filterTake = getPageTake()

        if (isFilter) {

            filterSkip = 0
            filterTake = pageSize.recordTake

            //reset pager when filter applies
            setPage({ skip: filterSkip, take: filterTake })
        }

        if (data.length > 0 && columnMenuData[0] !== undefined) {
            let obj = Object.keys(data[0])
            index = obj.findIndex((x) => x === columnMenuData[0].field) + 1
        }

        const filterString = isFilter ? `AND (u.etl_batch_mstr_key = ${restProps.selectedBatchKey})` + getfilterDataString(columnMenuData, columns) : `AND (u.etl_batch_mstr_key = ${restProps.selectedBatchKey})` + filterData.data

        isFilter = false
        if (filterString !== '') {
            isFilter = true
        }

        let sortDir = (filterData.isSort && columnMenuData.length) ? filterData.sort_order : DEFAULT_SORT.order
        let sortNumber = (filterData.isSort && columnMenuData.length) ? filterData.in_sort_number : DEFAULT_SORT.number

        isSort = !columnMenuData.length ? false : filterData.isSort

        if (columnMenuData[0] !== undefined && columnMenuData[0].dir !== undefined) {
            isSort = true
            sortDir = columnMenuData[0].dir.toUpperCase()
            sortNumber = index
        }

        const dataVal = {
            ...apiCallData,
            in_tenant_id: cookiesData.out_tenant_id,
            in_filter: isFilter ? filterString : null,
            in_sort_number: sortNumber,
            in_sort_order: sortDir,
            in_page_row_offset: filterSkip,
            in_page_rows: filterTake,
        };

        setFilterData({
            ...filterData,
            isFilter: isFilter,
            isSort: isSort,
            data: filterString,
            in_sort_number: sortNumber,
            sort_order: sortDir,
        });
        setAPICallingState(true);
        getDTUErrorLogsAsync(dataVal);
    }


    const handlePageChange = (event) => {
        const pageData = event.page

        setAPICallingState(true)

        setPage({ skip: pageData.skip, take: pageData.take })

        const dataVal = {
            ...apiCallData,
            in_filter: getInFilter(),
            in_sort_number: getInSortNumber(),
            in_sort_order: getInSortOrder(),
            in_page_row_offset: pageData.skip,
            in_page_rows: pageData.take,
        }
        getDTUErrorLogsAsync(dataVal);
    };

    const onRefreshClick = () => {
        setAPICallingState(true);
        const dataVal = {
            ...apiCallData,
            in_filter: getInFilter(),
            in_sort_number: getInSortNumber(),
            in_sort_order: getInSortOrder(),
            in_page_row_offset: getPageSkip(),
            in_page_rows: getPageTake(),
        };
        getDTUErrorLogsAsync(dataVal);
    };
    const onClearFilter = () => {
        //set in_params
        const dataVal = {
            ...apiCallData,
            in_filter: `AND (u.etl_batch_mstr_key = ${restProps.selectedBatchKey})`,
            in_sort_number: getInSortNumber(), in_sort_order: getInSortOrder(), in_page_row_offset: getPageSkip(), in_page_rows: getPageTake(),
        };

        //set filter to false
        setFilterData({
            ...filterData,
            data: '',
            isFilter: false
        });
        getDTUErrorLogsAsync(dataVal);
    };

    const getExportData = async () => {
        const dataVal = {
            ...apiCallData,
            in_page_row_offset: 0,
            in_page_rows: dataCount,
            in_filter: getInFilter(),
            in_sort_number: getInSortNumber(),
            in_sort_order: getInSortOrder(),
            in_export_flag: 1,
        };
        await POSTAPI(
            false,
            GET_DTU_ERROR_LOGS,
            null,
            header,
            dataVal,
            dispatch
        ).then((res) => {
            if (res.data.row_result.length > 0) {
                res.data.row_result.forEach((item) => {
                    [
                        "action_date",
                    ].forEach((field) => {
                        if (item[field]) {
                            item[field] = formatDate(item[field]);
                        }
                    });
                });
                setExportData(res.data.row_result);
            }
        });
    };

    const handleMenuItemClick = (module) => {
        switch (module) {
            case "export":
                setExportDataClick(true);
                break;
            default:
                break;
        }
    };

    const onClosePopup = () => {
        setVisible(!visible);
        restProps.setShow({ ...restProps.visible, dtuErrorLogsModal: false });
    };


    const headerConfig = {
        id: "dtu_error_logs",
        action_menu: {
            export: { fx: handleMenuItemClick },
        },
    };

    const parent = document.querySelector("#content-" + headerConfig.id);
    if (parent) {
        const exportToExcel = parent.querySelector(".export-to-excel");
        exportToExcel && exportToExcel.disableKendoMenu();
        data.length && exportToExcel && exportToExcel.enableKendoMenu();
    }


    return (
        <>
            {initialLoading ? (
                <>
                    <Window
                        title={<ModalTitle />}
                        onClose={onClosePopup}
                        width={window.innerWidth * 0.8}
                        height={window.innerHeight * 0.9}
                        resizable={false}
                    >
                        <div className="dtu-file-container-logs">
                            <div className="content dtutitlebar">
                                <div className="Quick-links eventtypequicklinks-dtu-Logs">
                                    <HeaderPage
                                        pageProps={restProps.pageProps}
                                        headerConfig={headerConfig}
                                    />
                                </div>
                            </div>
                            <DataTable
                                isAPICalling={isAPICalling}
                                initialLoading={initialLoading}
                                data={data}
                                handleColumnMenu={handleColumnMenu}
                                pageData={pageData}
                                dataCount={dataCount}
                                customColumn={DtuErrorLogsColumn}
                                columns={columns}
                                width={"auto"}
                                paginationData={paginationData}
                                customPageSizes={USER_GRID_PAGE_SIZES}
                                defaultPageSize={
                                    USER_GRID_DEFAULT_PAGE_SIZE
                                }
                                pageSize={pageSize}
                                setPageSize={setPageSize}
                                setPage={setPage}
                                setPaginationData={setPaginationData}
                                handlePageChange={handlePageChange}
                                getExportData={getExportData}
                                setExportData={setExportDataClick}
                                isExportDataClick={exportDataClick}
                                className={"DataTable-dtuLogs"}
                                fileName={
                                    "Export_" +
                                    restProps.etlTableName +
                                    "_DTU_Error_Log"
                                }
                                exportData={exportData}
                                refreshClick={onRefreshClick}
                                onClearFilter={onClearFilter}
                                reorderable={false}
                            />
                        </div>
                        <WindowActionsBar layout={'end'}>
                            <Button
                                className="cancelbtn"
                                onClick={(e) => onClosePopup()}
                                autoFocus={true}
                            >
                                {'Cancel'}
                            </Button>
                        </WindowActionsBar>
                    </Window>
                </>
            ) : (
                <LoadingPanel />
            )}
            {isErrorDetail ? (
                <ErrorDetailLogs
                    visible={isErrorDetail}
                    setIsErrorDetail={setIsErrorDetail}
                    errorObj={errorObj}
                    gridHeight={150}
                    modalHeight={314}
                ></ErrorDetailLogs>
            ) : (
                false
            )}
        </>
    );
};
export default React.memo(DtuErrorLogs);
