import { dashboardRuleResultTableConfig } from "src/constants/constants";
import { transformDateTime } from "src/lib/date-time.util";

export class DashboardTabListApiRequest {
    in_tenant_id;
    in_user_key;
    in_db_role_key;
    in_filter;
    in_sort_number;
    in_sort_order;
    in_acct_encrypt_key;
    constructor(request) {
        this.in_tenant_id = request?.tenantId || null;
        this.in_user_key = request?.userKey || null;
        this.in_db_role_key = request?.dbRoleKey || null;
        this.in_filter = request?.filter || null;
        this.in_sort_number = request?.sortNumber || 5;
        this.in_sort_order = request?.sortOrder || "ASC";
        this.in_acct_encrypt_key = request?.acctEncryptKey || null;
    }
}

export class CommonListApiResponse {
    response;
    constructor(request) {
        this.response = request?.data?.row_result || [];
    }
}

export class DashboardPODApiResponse {
    response;
    constructor(request) {
        this.response = request?.data || {};
    }
}

export class DashboardPodListApiRequest {
    in_tenant_id;
    in_user_key;
    in_filter;
    in_sort_number;
    in_sort_order;
    in_dashboard_tab_mstr_key;
    constructor(request) {
        this.in_tenant_id = request?.tenantId || null;
        this.in_user_key = request?.userKey || null;
        this.in_db_role_key = request?.dbRoleKey || null;
        this.in_filter = request?.filter || null;
        this.in_sort_number = request?.sortNumber || 5;
        this.in_sort_order = request?.sortOrder || "ASC";
        this.in_dashboard_tab_mstr_key = request?.dashboardTabMstrKey || null;
    }
}

export class DashboardCompPlanConfigApiRequest {
    in_tenant_id;
    in_dashboard_tab_mstr_key;
    in_user_key;
    in_db_role_type_abbr;
    in_filter;
    in_sort_number;
    in_sort_order;
    in_multi_sort_order;
    constructor(request) {
        this.in_tenant_id = request?.tenantId || null;
        this.in_dashboard_tab_mstr_key = request?.dashboardTabMstrKey || null;
        this.in_user_key = request?.userKey || null;
        this.in_db_role_type_abbr = request?.dbRoleTypeAbbr || null;
        this.in_filter = request?.filter || null;
        this.in_sort_number = request?.sortNumber || 2;
        this.in_sort_order = request?.sortOrder || "ASC";
        this.in_multi_sort_order = request?.multiSortOrder || "NULL";
    }
}

export class DashboardChartRuleApiRequest {
    in_tenant_id;
    in_user_key;
    in_rule_result_table;
    in_period_start_date;
    in_period_end_date;
    in_period_type_mstr_key;
    in_acct_encrypt_key;
    in_group_filter;
    in_specialty_filter;
    in_provider_filter;
    in_dashboard_tab_mstr_key;
    in_dashboard_pod_key;
    in_report_published_flag;
    constructor(request) {
        const ruleResultTable = (request?.ruleResultTable || "").toLowerCase();
        switch (ruleResultTable) {
            case dashboardRuleResultTableConfig.credit.key:
                this.in_rule_result_table =
                    dashboardRuleResultTableConfig.credit.value;
                break;
            case dashboardRuleResultTableConfig.measurement.key:
                this.in_rule_result_table =
                    dashboardRuleResultTableConfig.measurement.value;
                break;
            case dashboardRuleResultTableConfig.incentive.key:
                this.in_rule_result_table =
                    dashboardRuleResultTableConfig.incentive.value;
                break;
            case dashboardRuleResultTableConfig.payment.key:
                this.in_rule_result_table =
                    dashboardRuleResultTableConfig.payment.value;
                break;
            default:
                this.in_rule_result_table = null;
                break;
        }
        const periodStartDate = transformDateTime({
            date: request?.periodStartDate,
            format: null,
            delimiter: "-",
            includeTime: true,
            defaultTime: true,
        });
        const periodEndDate = transformDateTime({
            date: request?.periodEndDate,
            format: null,
            delimiter: "-",
            includeTime: true,
            defaultTime: true,
        });
        this.in_tenant_id = request?.tenantId || null;
        this.in_user_key = request?.userKey || null;
        this.in_period_start_date = periodStartDate || null;
        this.in_period_end_date = periodEndDate || null;
        this.in_period_type_mstr_key = request?.periodTypeMstrKey || null;
        this.in_acct_encrypt_key = request?.encryptKey || null;
        this.in_group_filter = request?.groupFilter || null;
        this.in_specialty_filter = request?.specialtyFilter || null;
        this.in_provider_filter = request?.providerFilter || null;
        this.in_dashboard_tab_mstr_key = request?.dashboardTabMstrKey || null;
        this.in_dashboard_pod_key = request?.dashboardPodKey || null;
        this.in_report_published_flag = request?.reportPublishedFlag || 0;
    }
}

export class DashboardChartDataSetApiRequest {
    in_tenant_id;
    in_filter;
    in_sort_number;
    in_sort_order;
    in_pod_key;
    in_user_key;
    constructor(request) {
        /*
        let filter = "NULL";
        if (
            request?.dashboardPodKey &&
            request?.dashboardPodKey !== 0 &&
            request?.userKey &&
            request?.userKey !== 0
        ) {
            filter = `AND a.dashboard_pod_key = ${
                request?.dashboardPodKey || "NULL"
            } AND a.user_key = ${request?.userKey || "NULL"}`;
        }
        */
        this.in_pod_key = request?.dashboardPodKey;
        this.in_user_key = request?.userKey;
        this.in_tenant_id = request?.tenantId || null;
        this.in_filter = null;
        this.in_sort_number = request?.sortNumber || 1;
        this.in_sort_order = request?.sortOrder || "ASC";
    }
}

export class DashboardTypeApiRequest {
    in_tenant_id;
    in_user_db_role_key;
    constructor(request) {
        this.in_tenant_id = request?.tenantId || null;
        this.in_user_db_role_key = request?.userDBRoleKey || null;
    }
}

export class DashboardPeriodTypeApiRequest {
    in_filter;
    in_sort_number;
    in_sort_order;
    constructor(request) {
        this.in_filter =
            request?.filter || "AND a.period_type_hierarchy_level IS NOT NULL";
        this.in_sort_number = request?.sortNumber || 1;
        this.in_sort_order = request?.sortOrder || "ASC";
    }
}

export class DashboardCompPlanApiRequest {
    in_tenant_id;
    in_dashboard_tab_mstr_key;
    in_user_key;
    in_db_role_type_abbr;
    in_filter;
    in_sort_number;
    in_sort_order;
    in_multi_sort_order;
    constructor(request) {
        this.in_tenant_id = request?.tenantId || null;
        this.in_dashboard_tab_mstr_key = request?.dashboardTabMstrKey || null;
        this.in_user_key = request?.userKey || null;
        this.in_db_role_type_abbr = request?.dbRoleTypeAbbr || null;
        this.in_filter = request?.filter || null;
        this.in_sort_number = request?.sortNumber || 1;
        this.in_sort_order = request?.sortOrder || "ASC";
        this.in_multi_sort_order =
            request?.multiSortOrder || "p.comp_plan_names ASC";
    }
}

export class DashboardKPIFilterApiRequest {
    in_tenant_id;
    in_filter;
    in_user_key;
    in_comp_plan_mstr_key;
    in_date_version;
    in_sort_number;
    in_sort_order;
    in_page_row_offset;
    in_page_rows;
    constructor(request) {
        this.in_tenant_id = request?.tenantId || null;
        this.in_filter = request?.filter || null;
        this.in_user_key = request?.userKey || null;
        this.in_comp_plan_mstr_key = request?.compPlanMstrKey
            ? request?.compPlanMstrKey.toString()
            : null;
        this.in_date_version = request?.dateVersion || null;
        this.in_sort_number = request?.sortNumber || 2;
        this.in_sort_order = request?.sortOrder || "ASC";
        this.in_page_row_offset = request?.pageRowOffset || 0;
        this.in_page_rows = request?.pageRows || 9999;
    }
}

export class DashboardResultFilterApiRequest {
    in_tenant_id;
    in_user_key;
    in_kpi_mstr_key;
    in_comp_plan_mstr_key;
    in_rule_result_table;
    in_dash_tab_mstr_key;

    constructor(request) {
        this.in_tenant_id = request?.tenantId || null;
        this.in_user_key = request?.userKey || null;
        this.in_kpi_mstr_key = request?.kpiMstrKey || null;
        this.in_comp_plan_mstr_key = request?.compPlanMstrKey || null;
        this.in_rule_result_table = request?.ruleResultTable || "measurement";
        this.in_dash_tab_mstr_key = request?.dashboardTabMstrKey || null;
    }
}

export class DashboardPODUpdateApiRequest {
    in_user_id;
    in_db_role_key;
    in_tenant_id;
    in_dashboard_pod_key;
    in_version;
    in_dashboard_tab_mstr_key;
    in_dashboard_type_mstr_key;
    in_label_name;
    in_period_type_mstr_key;
    constructor(request) {
        this.in_user_id = request?.userId;
        this.in_db_role_key = request?.dbRoleKey;
        this.in_tenant_id = request?.tenantId;
        this.in_dashboard_pod_key = request?.dashboardPodKey;
        this.in_version = request?.version;
        this.in_dashboard_tab_mstr_key = request?.dashboardTabMstrKey;
        this.in_dashboard_type_mstr_key = request?.dashboardTypeMstrKey;
        this.in_label_name = request?.labelName;
        this.in_period_type_mstr_key = request?.periodTypeMstrKey;
    }
}

export class DashboardPODDeleteDatasetApiRequest {
    in_user_id;
    in_tenant_id;
    in_dashboard_dataset_key;
    constructor(request) {
        this.in_user_id = request?.userId;
        this.in_tenant_id = request?.tenantId;
        this.in_dashboard_dataset_key = request?.dashboardDataSetKey;
    }
}

export class DashboardPODUpdateDatasetApiRequest {
    in_user_id;
    in_tenant_id;
    in_dashboard_dataset_key;
    in_version;
    in_dashboard_dataset_num;
    in_dataset_name;
    in_comp_plan_mstr_key;
    in_kpi_mstr_key;
    in_result_name_mstr_key_x;
    in_result_name_mstr_key_y;
    in_result_name_mstr_key_z;
    in_aggregation;
    in_chart_type;
    in_group_dimension;
    in_is_group_by_result;
    in_is_stacked;
    in_dashboard_pod_key;
    constructor(request) {
        this.in_user_id = request?.userId;
        this.in_tenant_id = request?.tenantId;
        this.in_dashboard_dataset_key = request?.dashboardDataSetKey;
        this.in_version = request?.version;
        this.in_dashboard_dataset_num = request?.dashboardDataSetNum;
        this.in_dataset_name = request?.datasetName;
        this.in_comp_plan_mstr_key = request?.compPlanMstrKey || "0";
        this.in_kpi_mstr_key = request?.kpiMstrKey || "0";
        this.in_result_name_mstr_key_x = request?.resultNameMstrKeyX;
        this.in_result_name_mstr_key_y = request?.resultNameMstrKeyY || "0";
        this.in_result_name_mstr_key_z = request?.resultNameMstrKeyZ;
        this.in_aggregation = request?.aggregation;
        this.in_chart_type = request?.chartType;
        this.in_group_dimension = request?.groupDimension;
        this.in_is_group_by_result = request?.isGroupByResult;
        this.in_is_stacked = request?.isStacked;
        this.in_dashboard_pod_key = request?.dashboardPodKey;
    }
}

export class DashboardPODInsertApiRequest {
    in_user_id;
    in_db_role_key;
    in_tenant_id;
    in_dashboard_tab_mstr_key;
    in_dashboard_type_mstr_key;
    in_label_name;
    in_period_type_mstr_key;
    constructor(request) {
        this.in_user_id = request?.userId;
        this.in_db_role_key = request?.dbRoleKey;
        this.in_tenant_id = request?.tenantId;
        this.in_version = request?.version;
        this.in_dashboard_tab_mstr_key = request?.dashboardTabMstrKey;
        this.in_dashboard_type_mstr_key = request?.dashboardTypeMstrKey;
        this.in_label_name = request?.labelName;
        this.in_period_type_mstr_key = request?.periodTypeMstrKey;
    }
}

export class DashboardPODInsertDatasetApiRequest {
    in_user_id;
    in_tenant_id;
    in_version;
    in_dashboard_dataset_num;
    in_dataset_name;
    in_comp_plan_mstr_key;
    in_kpi_mstr_key;
    in_result_name_mstr_key_x;
    in_result_name_mstr_key_y;
    in_result_name_mstr_key_z;
    in_aggregation;
    in_chart_type;
    in_group_dimension;
    in_is_group_by_result;
    in_is_stacked;
    in_dashboard_pod_key;
    constructor(request) {
        this.in_user_id = request?.userId;
        this.in_tenant_id = request?.tenantId;
        this.in_version = request?.version;
        this.in_dashboard_dataset_num = request?.dashboardDataSetNum;
        this.in_dataset_name = request?.datasetName;
        this.in_comp_plan_mstr_key = request?.compPlanMstrKey;
        this.in_kpi_mstr_key = request?.kpiMstrKey;
        this.in_result_name_mstr_key_x = request?.resultNameMstrKeyX;
        this.in_result_name_mstr_key_y = request?.resultNameMstrKeyY;
        this.in_result_name_mstr_key_z = request?.resultNameMstrKeyZ;
        this.in_aggregation = request?.aggregation;
        this.in_chart_type = request?.chartType;
        this.in_group_dimension = request?.groupDimension;
        this.in_is_group_by_result = request?.isGroupByResult;
        this.in_is_stacked = request?.isStacked;
        this.in_dashboard_pod_key = request?.dashboardPodKey;
    }
}

export class DashboardPODDeleteApiRequest {
    in_user_id;
    in_tenant_id;
    in_db_role_key;
    in_dashboard_pod_key;
    constructor(request) {
        this.in_user_id = request?.userId;
        this.in_db_role_key = request?.dbRoleKey;
        this.in_tenant_id = request?.tenantId;
        this.in_dashboard_pod_key = [{ id: request?.dashboardPodKey }];
    }
}

export class DashboardTabDeleteApiRequest {
    in_user_id;
    in_tenant_id;
    in_dashboard_tab_mstr_key;
    in_db_role_key;
    constructor(request) {
        this.in_user_id = request?.userId;
        this.in_tenant_id = request?.tenantId;
        this.in_dashboard_tab_mstr_key = [{ id: request?.dashboardTabKey }];
        this.in_db_role_key = request?.dbRoleKey;
    }
}

export class DashboardBusinessGroupApiRequest {
    in_tenant_id;
    in_filter;
    sort_number;
    sort_order;
    offset;
    limit;
    is_export;
    constructor(request) {
        this.in_tenant_id = request?.tenantId || null;
        this.in_filter = request?.filter || null;
        this.sort_number = request?.sortNumber || 3;
        this.sort_order = request?.sortOrder || "ASC";
        this.offset = request?.offset || 9999;
        this.limit = request?.limit || 0;
        this.is_export = request?.is_export || 0;
    }
}

export class DashboardGroupApiRequest {
    in_user_key;
    in_tenant_id;
    in_role_type_access;
    in_business_group_access;
    in_comp_plan_access;
    in_filter;
    in_sort_number;
    in_sort_order;
    in_multi_sort_order;
    constructor(request) {
        this.in_user_key = request?.userKey || null;
        this.in_tenant_id = request?.tenantId || null;
        this.in_role_type_access = request?.roleTypeAccess || "0";
        this.in_business_group_access = request?.businessGroupAccess || "0";
        this.in_comp_plan_access = request?.compPlanAccess || "0";
        this.in_filter = request?.filter || null;
        this.in_sort_number = request?.sortNumber || 1;
        this.in_sort_order = request?.sortOrder || "ASC";
        this.in_multi_sort_order = request?.multiSortOrder || "group_names ASC";
    }
}

export class DashboardSpecialtyApiRequest {
    in_user_key;
    in_tenant_id;
    in_role_type_access;
    in_business_group_access;
    in_comp_plan_access;
    in_group_filter;
    in_filter;
    in_sort_number;
    in_sort_order;
    in_multi_sort_order;
    constructor(request) {
        this.in_user_key = request?.userKey || null;
        this.in_tenant_id = request?.tenantId || null;
        this.in_role_type_access = request?.roleTypeAccess || "0";
        this.in_business_group_access = request?.businessGroupAccess || "0";
        this.in_comp_plan_access = request?.compPlanAccess || "0";
        this.in_group_filter = request?.groupFilter || "0";
        this.in_filter = request?.filter || null;
        this.in_sort_number = request?.sortNumber || 1;
        this.in_sort_order = request?.sortOrder || "ASC";
        this.in_multi_sort_order = request?.multiSortOrder || "NULL";
    }
}

export class DashboardProviderApiRequest {
    in_user_key;
    in_tenant_id;
    in_role_type_access;
    in_business_group_access;
    in_comp_plan_access;
    in_group_filter;
    in_specialty_filter;
    in_filter;
    in_sort_number;
    in_sort_order;
    in_multi_sort_order;
    in_acct_encrypt_key;
    constructor(request) {
        this.in_user_key = request?.userKey || null;
        this.in_tenant_id = request?.tenantId || null;
        this.in_role_type_access = request?.roleTypeAccess || "0";
        this.in_business_group_access = request?.businessGroupAccess || "0";
        this.in_comp_plan_access = request?.compPlanAccess || "0";
        this.in_group_filter = request?.groupFilter || "0";
        this.in_specialty_filter = request?.specialtyFilter || "0";
        this.in_filter = request?.filter || null;
        this.in_sort_number = request?.sortNumber || 1;
        this.in_sort_order = request?.sortOrder || "ASC";
        this.in_multi_sort_order = request?.multiSortOrder || "NULL";
        this.in_acct_encrypt_key = request?.acctEncryptKey || null;
    }
}

export class DashboardInsertTabApiRequest {
    in_user_key;
    in_db_role_key;
    in_tenant_id;
    in_label_name;
    in_group_filter;
    in_specialty_filter;
    in_provider_filter;
    in_period_start_date;
    in_period_end_date;
    in_theme;
    in_tab_order;
    in_role_type_access;
    in_business_group_access;
    in_comp_plan_access;
    in_report_published_flag;

    constructor(request) {
        this.in_user_key = request?.userKey;
        this.in_db_role_key = request?.dbRoleKey;
        this.in_tenant_id = request?.tenantId;
        this.in_label_name = request?.labelName;
        this.in_group_filter = request?.groupFilter || "0";
        this.in_specialty_filter = request?.specialtyFilter || "0";
        this.in_provider_filter = request?.providerFilter || "0";
        this.in_period_start_date = request?.periodStartDate;
        this.in_period_end_date = request?.periodEndDate;
        this.in_theme = request?.theme;
        this.in_tab_order = request?.tabOrder > 0 ? request?.tabOrder : 0;
        this.in_role_type_access = request?.roleTypeAccess;
        this.in_business_group_access = request?.businessGroupAccess || "0";
        this.in_comp_plan_access = request?.compPlanAccess || "0";
        this.in_report_published_flag = request?.reportPublishedFlag ? 1 : 0;
    }
}

export class DashboardUpdateTabApiRequest {
    in_user_key;
    in_db_role_key;
    in_tenant_id;
    in_dashboard_tab_mstr_key;
    in_label_name;
    in_group_filter;
    in_specialty_filter;
    in_provider_filter;
    in_period_start_date;
    in_period_end_date;
    in_theme;
    in_tab_order;
    in_role_type_access;
    in_business_group_access;
    in_comp_plan_access;
    in_report_published_flag;

    constructor(request) {
        this.in_user_key = request?.userKey;
        this.in_db_role_key = request?.dbRoleKey;
        this.in_tenant_id = request?.tenantId;
        this.in_dashboard_tab_mstr_key = request?.dashboardTabMstrKey;
        this.in_label_name = request?.labelName;
        this.in_group_filter = request?.groupFilter;
        this.in_specialty_filter = request?.specialtyFilter;
        this.in_provider_filter = request?.providerFilter;
        this.in_period_start_date = request?.periodStartDate;
        this.in_period_end_date = request?.periodEndDate;
        this.in_theme = request?.theme;
        this.in_tab_order = request?.tabOrder > 0 ? request?.tabOrder : 0;
        this.in_role_type_access = request?.roleTypeAccess;
        this.in_business_group_access = request?.businessGroupAccess;
        this.in_comp_plan_access = request?.compPlanAccess;
        this.in_report_published_flag = request?.reportPublishedFlag ? 1 : 0;
    }
}

export class DashboardUpdatePodOrderApiRequest {
    in_user_id;
    in_db_role_key;
    in_tenant_id;
    in_dashboard_pod_key_list;
    in_dashboard_tab_mstr_key;

    constructor(request) {
        this.in_user_id = request?.userId;
        this.in_db_role_key = request?.dbRoleKey;
        this.in_tenant_id = request?.tenantId;
        this.in_dashboard_pod_key_list = request?.dashboardPodKeyList;
        this.in_dashboard_tab_mstr_key = request?.dashboardTabMstrKey;
    }
}

export class DashboardTabCopyApiRequest {
    in_login_user_id;
    in_login_db_role_key;
    in_dashboard_tab_mstr_key;
    in_tenant_id;
    constructor(request) {
        this.in_login_user_id = request?.userId;
        this.in_login_db_role_key = request?.dbRoleKey;
        this.in_dashboard_tab_mstr_key = request?.dashboardTabKey;
        this.in_tenant_id = request?.tenantId;
    }
}

export class PublishDashboardApiRequest {
    in_tenant_id;
    in_tab_mstr_key;
    in_admin_user_key;
    in_user_key;
    in_db_role_key;
    constructor(request) {
        this.in_tenant_id = request?.tenantId;
        this.in_tab_mstr_key = request?.dashboardTabKey;
        this.in_admin_user_key = request?.adminUserKey;
        this.in_user_key = request?.userId;
        this.in_db_role_key = request?.dbRoleKey;
    }
}
