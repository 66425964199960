import React from "react";
import {
    getDashboardEditPodDialog,
    getDashboardPod,
    getDashboardPublishEnable,
    mapDashboardPodData,
    setDashboardPublishEnable,
} from "src/store/dashboard.store";
import { getUserData, mapUserData } from "src/store/user.store";
import {
    DashboardCompPlanListApi,
    DashboardKPIFilterListApi,
    DashboardPODDataSetInsertListApi,
    DashboardPODDataSetUpdateListApi,
    DashboardPODDatasetDeleteApi,
    DashboardPODInsertListApi,
    DashboardResultFilterListApi,
    DashboardPODUpdateListApi,
} from "src/service/dashboard.service";
import { TransformtDropdownUnique } from "src/components/form-wrapper";
import { useMutation } from "react-query";
import { setNewMessage } from "src/redux/actions/setting-actions";
import { useDispatch } from "react-redux";
import { isEmpty } from "src/utils/utils";

const { dbRoleKey, dbRoleTypeAbbr, tenantId, userKey } = mapUserData;
const { tabId } = mapDashboardPodData;

export default function Plan(
    formFields,
    closeDialog,
    refreshDashboardPodDetail,
    selectedTab,
    podDatasetDropdownOptions,
    dashboardDataSet
) {
    const dispatch = useDispatch();

    const [originalData, setOriginalData] = React.useState([]);
    const [isRefreshLoading, setRefreshLoading] = React.useState(false);

    const defaultSelectAllOption = { text: "Select All", id: "0" };
    const [compPlanDataList, setCompPlanDataList] = React.useState([]);
    const [compPlanDataFields, setCompPlanDataFields] = React.useState({
        compPlanData: [],
        isCompPlanDataLoading: false,
    });

    const [compPlanData2List, setCompPlanData2List] = React.useState([]);
    const [compPlanData2Fields, setCompPlanData2Fields] = React.useState({
        compPlanData: [],
        isCompPlanDataLoading: false,
    });

    const [kpiDataList, setKpiDataList] = React.useState([]);
    const [kpiDataFields, setKpiDataFields] = React.useState({
        kpiData: [],
        isKpiDataLoading: false,
    });

    const [kpiData2List, setKpiData2List] = React.useState([]);
    const [kpiData2Fields, setKpiData2Fields] = React.useState({
        kpiData: [],
        isKpiDataLoading: false,
    });

    const [resultFilterDataList, setResultFilterDataList] = React.useState([]);
    const [resultFilterDataFields, setResultFilterDataFields] = React.useState({
        resultDataX: [],
        resultDataY: [],
        resultDataZ: [],
        isResultDataLoading: false,
    });

    const [resultFilterData2List, setResultFilterData2List] = React.useState(
        []
    );
    const [resultFilterData2Fields, setResultFilterData2Fields] =
        React.useState({
            resultData: [],
            isResultDataLoading: false,
        });

    const dashboardEditPodData = getDashboardEditPodDialog();
    const isEditForm =
        Object.keys(dashboardEditPodData?.podDetail || {})?.length > 0;
    const activeDashboardPod = getDashboardPod();
    const dashboardPublishEnable = getDashboardPublishEnable();
    const activeTabId = tabId.getData(activeDashboardPod);
    const userData = getUserData();
    const getTenantId = tenantId.getData(userData) || "";
    const getUserKey = userKey.getData(userData) || "";

    const generateTempArray = (resName, type) => {
        let tempArray = [];
        let keyObj = {};

        switch (type) {
            case "compPlan":
                keyObj = {
                    keyForLabel: "comp_plan_names",
                    keyForValue: "comp_plan_mstr_keys",
                };
                break;
            case "kpi":
                keyObj = {
                    keyForLabel: "kpi_name",
                    keyForValue: "kpi_mstr_key",
                };
                break;
            case "result":
                keyObj = {
                    keyForLabel: "result_name",
                    keyForValue: "result_name_mstr_key",
                };
                break;
            default:
                keyObj = {};
        }

        if (Object.keys(keyObj).length) {
            tempArray = TransformtDropdownUnique({
                data: resName,
                ...keyObj,
            });
        }

        if (tempArray === null) {
            tempArray = [];
        }

        /*
        if (tempArray?.length) {
            tempArray.unshift(defaultSelectAllOption);
        }
            */

        return tempArray;
    };

    const getPodDatasetData = (datasetNum = 0) => {
        return dashboardDataSet?.data?.[datasetNum];
    };

    const getCompPlanKeyData = (selectedDs = 1) => {
        const dropdownName = "dataset[" + (selectedDs - 1) + "]compPlanFilter";

        let keyData =
            selectedDs === 1
                ? compPlanDataFields?.[dropdownName]
                : compPlanData2Fields?.[dropdownName];

        if (!keyData?.length) {
            return [];
        }

        //select all possible comp_plans if comp_plan contains "Select All"
        if (keyData.find((x) => x.id === "0")) {
            keyData =
                selectedDs === 1
                    ? compPlanDataList.filter((x) => x.id !== "0")
                    : compPlanData2List.filter((x) => x.id !== "0");
        }

        return keyData;
    };

    const getKpiKeyData = (selectedDs = 1) => {
        const dropdownName = "dataset[" + (selectedDs - 1) + "]kpiFilter";

        return selectedDs === 1
            ? kpiDataFields?.[dropdownName]
            : kpiData2Fields?.[dropdownName];
    };

    const getDefaultVal = (findKey, tempArray = [], selectedDs = 0) => {
        const datasetData = getPodDatasetData(selectedDs);

        if (!tempArray.length) {
            return [];
        }

        if (isEmpty(datasetData)) {
            return [defaultSelectAllOption];
        }

        const keys = datasetData?.[findKey]?.split(",");

        if (isEmpty(keys)) {
            return [defaultSelectAllOption];
        }

        const selected = tempArray.filter(
            (o) => keys.indexOf(o?.id?.toString()) !== -1
        );

        return selected.length ? selected : [defaultSelectAllOption];
    };

    const getCompPlanList = async (selectedDataset = 1) => {
        const dropdownName =
            "dataset[" + (selectedDataset - 1) + "]compPlanFilter";

        if (selectedDataset === 1 && compPlanDataFields?.[dropdownName]) {
            return false;
        }

        if (selectedDataset === 2 && compPlanData2Fields?.[dropdownName]) {
            return false;
        }

        if (selectedDataset === 1) {
            setCompPlanDataFields({
                ...compPlanDataFields,
                isCompPlanDataLoading: true,
            });
        } else {
            setCompPlanData2Fields({
                ...compPlanData2Fields,
                isCompPlanDataLoading: true,
            });
        }

        const dataObjValue = {
            tenantId: getTenantId || null,
            userKey: getUserKey || null,
            dashboardTabMstrKey: tabId.getData(
                dashboardEditPodData?.podDetail || activeDashboardPod
            ),
            dbRoleTypeAbbr: dbRoleTypeAbbr.getData(userData),
        };

        const compPlanData = await DashboardCompPlanListApi(dataObjValue);
        let tempArray = generateTempArray(compPlanData, "compPlan");

        const defaultVal = getDefaultVal(
            "comp_plan_mstr_key",
            tempArray,
            selectedDataset - 1
        );

        const dataField = {
            compPlanData: tempArray,
            isCompPlanDataLoading: false,
            [dropdownName]: defaultVal,
        };

        if (selectedDataset === 1) {
            setCompPlanDataList(tempArray);
            setCompPlanDataFields({
                ...compPlanDataFields,
                ...dataField,
            });
        } else {
            setCompPlanData2List(tempArray);
            setCompPlanData2Fields({
                ...compPlanData2Fields,
                ...dataField,
            });
        }
    };

    const getKpiDataList = async (selectedDataset = 1) => {
        //get selected comp_plan_keys
        const compPlanKeyData = getCompPlanKeyData(selectedDataset);

        if (!compPlanKeyData?.length) {
            return false;
        }

        if (selectedDataset === 1) {
            setKpiDataFields({
                ...kpiDataFields,
                isKpiDataLoading: true,
            });
        } else {
            setKpiData2Fields({
                ...kpiData2Fields,
                isKpiDataLoading: true,
            });
        }

        const compPlanKey = compPlanKeyData.map((o) => o.id);
        const dataObjValue = {
            tenantId: getTenantId || null,
            userKey: getUserKey || null,
            dashboardTabMstrKey: tabId.getData(
                dashboardEditPodData?.podDetail || activeDashboardPod
            ),
            dbRoleTypeAbbr: dbRoleTypeAbbr.getData(userData),
            compPlanMstrKey: isEmpty(compPlanKey) ? null : compPlanKey.join(),
            filter: " AND a.is_kpi_assign = 1",
        };

        const dropdownName = "dataset[" + (selectedDataset - 1) + "]kpiFilter";
        const kpiData = await DashboardKPIFilterListApi(dataObjValue);

        let tempArray = generateTempArray(kpiData, "kpi");

        const defaultVal = getDefaultVal(
            "kpi_mstr_key",
            tempArray,
            selectedDataset - 1
        );

        const dataField = {
            kpiData: tempArray,
            isKpiDataLoading: false,
            [dropdownName]: defaultVal,
        };

        if (selectedDataset === 1) {
            setKpiDataList(tempArray);
            setKpiDataFields({
                ...kpiDataFields,
                ...dataField,
            });
        } else {
            setKpiData2List(tempArray);
            setKpiData2Fields({
                ...kpiData2Fields,
                ...dataField,
            });
        }
    };

    const getResultDataList = async (selectedDataset = 1, type = undefined) => {
        //get selected comp plans
        const compPlanKeyData = getCompPlanKeyData(selectedDataset);

        if (!compPlanKeyData?.length) {
            return false;
        }

        //get selected KPIs
        const kpiKeyData = getKpiKeyData(selectedDataset);
        /*
        if (!kpiKeyData?.length) {
            return false;
        }
            */

        if (!isEmpty(type)) {
            if (
                selectedDataset === 1 &&
                resultFilterData2Fields?.["dataset[1]resultFilterY"]
            ) {
                setResultFilterData2List([]);
                setResultFilterData2Fields({
                    ...resultFilterData2Fields,
                    resultDataY: [],
                    ["dataset[1]resultFilterY"]: [],
                    isResultDataLoading: true,
                });
            }

            if (
                selectedDataset === 2 &&
                resultFilterDataFields?.["dataset[0]resultFilterY"]
            ) {
                setResultFilterDataList([]);
                setResultFilterDataFields({
                    ...resultFilterDataFields,
                    resultDataX: [],
                    resultDataY: [],
                    resultDataZ: [],
                    ["dataset[0]resultFilterX"]: [],
                    ["dataset[0]resultFilterY"]: [],
                    ["dataset[0]resultFilterZ"]: [],
                    isResultDataLoading: true,
                });
            }
        }

        if (selectedDataset === 1) {
            setResultFilterDataFields({
                ...resultFilterDataFields,
                isResultDataLoading: true,
            });
        } else {
            setResultFilterData2Fields({
                ...resultFilterData2Fields,
                isResultDataLoading: true,
            });
        }

        const compPlanKey = compPlanKeyData?.map((o) => o.id).join(",");
        const kpiKey = kpiKeyData?.map((o) => o.id).join(",");
        const ruleResultType = !isEmpty(type)
            ? type
            : formFields?.dashboardType?.text;
        const tabMstrKey = tabId.getData(
            dashboardEditPodData?.podDetail || activeDashboardPod
        );

        const dataObjValue = {
            tenantId: getTenantId || null,
            userKey: getUserKey || null,
            dashboardTabMstrKey: tabMstrKey,
            dbRoleTypeAbbr: dbRoleTypeAbbr.getData(userData),
            compPlanMstrKey: isEmpty(compPlanKey) ? null : compPlanKey,
            kpiMstrKey: isEmpty(kpiKey) ? null : kpiKey,
            ruleResultTable: ruleResultType?.toLowerCase(),
        };

        const resultNameData = await DashboardResultFilterListApi(dataObjValue);

        let tempArray = generateTempArray(resultNameData, "result");

        const defaultValX = getDefaultVal(
            "result_name_mstr_key_x",
            tempArray,
            selectedDataset - 1
        );
        const defaultValY = getDefaultVal(
            "result_name_mstr_key_y",
            tempArray,
            selectedDataset - 1
        );
        const defaultValZ = getDefaultVal(
            "result_name_mstr_key_z",
            tempArray,
            selectedDataset - 1
        );

        if (!isEmpty(type)) {
            if (
                selectedDataset === 1 &&
                resultFilterData2Fields?.["dataset[1]resultFilterY"]
            ) {
                setResultFilterData2List(tempArray);
                setResultFilterData2Fields({
                    ...resultFilterData2Fields,
                    resultDataY: tempArray,
                    isResultDataLoading: false,
                    ["dataset[1]resultFilterY"]: defaultValY,
                });
            }

            if (
                selectedDataset === 2 &&
                resultFilterDataFields?.["dataset[0]resultFilterY"]
            ) {
                setResultFilterDataList(tempArray);
                setResultFilterDataFields({
                    ...resultFilterDataFields,
                    resultDataX: tempArray,
                    resultDataY: tempArray,
                    resultDataZ: tempArray,
                    isResultDataLoading: false,
                    ["dataset[0]resultFilterX"]: defaultValX,
                    ["dataset[0]resultFilterY"]: defaultValY,
                    ["dataset[0]resultFilterZ"]: defaultValZ,
                });
            }
        }

        const dropdownNameY =
            "dataset[" + (selectedDataset - 1) + "]resultFilterY";

        const dataField = {
            resultDataY: tempArray,
            isResultDataLoading: false,
            [dropdownNameY]: defaultValY,
        };

        if (selectedDataset === 1) {
            dataField.resultDataX = tempArray;
            dataField.resultDataZ = tempArray;

            dataField["dataset[" + (selectedDataset - 1) + "]resultFilterX"] =
                defaultValX;
            dataField["dataset[" + (selectedDataset - 1) + "]resultFilterZ"] =
                defaultValZ;

            setResultFilterDataList(tempArray);
            setResultFilterDataFields({
                ...resultFilterDataFields,
                ...dataField,
            });
        } else {
            setResultFilterData2List(tempArray);
            setResultFilterData2Fields({
                ...resultFilterData2Fields,
                ...dataField,
            });
        }
    };

    const datasetPayload = (formFields, datasetNum) => {
        const datasetId = "dataset[" + datasetNum + "]";

        const datasetName = formFields?.[datasetId + "dataName"] || "";
        const isGroupBy = formFields?.[datasetId + "groupByResult"]?.value
            ? 1
            : 0;

        if (isEmpty(isGroupBy) && datasetName === "") {
            return {};
        }

        const filters = {};
        if (datasetNum === 0) {
            filters.compPlanFilter =
                compPlanDataFields[datasetId + "compPlanFilter"]
                    ?.map((o) => o.id)
                    .join(",") || null;

            filters.kpiFilter =
                kpiDataFields[datasetId + "kpiFilter"]
                    ?.map((o) => o.id)
                    .join(",") || null;

            filters.resultFilterY =
                resultFilterDataFields[datasetId + "resultFilterY"]
                    ?.map((o) => o.id)
                    .join(",") || null;
        } else {
            filters.compPlanFilter =
                compPlanData2Fields[datasetId + "compPlanFilter"]
                    ?.map((o) => o.id)
                    .join(",") || null;

            filters.kpiFilter =
                kpiData2Fields[datasetId + "kpiFilter"]
                    ?.map((o) => o.id)
                    .join(",") || null;

            filters.resultFilterY =
                resultFilterData2Fields[datasetId + "resultFilterY"]
                    ?.map((o) => o.id)
                    .join(",") || null;
        }

        const payload = {
            dashboardDataSetKey:
                getPodDatasetData(datasetNum)?.dashboard_dataset_key || null,
            version: 0,
            dashboardDataSetNum: datasetNum + 1,
            datasetName: isGroupBy ? "" : datasetName,
            aggregation:
                formFields?.[datasetId + "aggregationType"]?.value || null,
            chartType: formFields?.[datasetId + "chartType"]?.value || null,
            groupDimension:
                formFields?.[datasetId + "groupDimension"]?.value || null,
            isStacked: formFields?.[datasetId + "isStacked"]?.value ? 1 : 0,
            isGroupByResult: isGroupBy,
            compPlanMstrKey: filters?.compPlanFilter || null,
            kpiMstrKey: filters?.kpiFilter || null,
            resultNameMstrKeyY: filters?.resultFilterY || null,
            resultNameMstrKeyX: null,
            resultNameMstrKeyZ: null,
        };

        return payload;
    };

    const onSubmit = useMutation({
        mutationFn: async ({ formDatasetFields, formDataset2Fields }) => {
            if (!formDatasetFields) {
                throw new Error("Dataset Not Found");
            }

            // create commonPayload object
            const commonPaylod = {
                userId: getUserKey || null,
                tenantId: getTenantId || null,
                dashboardPodKey: dashboardEditPodData?.dashboardPodKey || null,
            };

            // create pod payload object
            const dashboardPODPayload = {
                ...commonPaylod,
                dbRoleKey: dbRoleKey.getData(userData) || null,
                dashboardTabMstrKey: tabId.getData(
                    dashboardEditPodData?.podDetail || activeDashboardPod
                ),
                dashboardTypeMstrKey: formFields?.dashboardType
                    ? formFields?.dashboardType?.value
                    : null,
                labelName: formFields?.labelName || null,
                periodTypeMstrKey: formFields?.dashboardPeriodType
                    ? formFields?.dashboardPeriodType?.value
                    : null,
            };

            // insert dashboard pod
            if (!isEditForm) {
                const response = await DashboardPODInsertListApi(
                    dashboardPODPayload
                );
                if (response?.out_parameters?.out_ret_code > 0) {
                    throw new Error(response?.out_parameters?.out_error_msg);
                } else {
                    commonPaylod.dashboardPodKey =
                        response?.out_parameters?.out_dashboard_pod_key;
                }
            }
            // update dashboard pod
            if (isEditForm) {
                const response = await DashboardPODUpdateListApi(
                    dashboardPODPayload
                );
                if (response?.out_parameters?.out_ret_code > 0) {
                    throw new Error(response?.out_parameters?.out_error_msg);
                }
            }

            // create dataset-1 payload object
            const payload1 = datasetPayload(formDatasetFields, 0);

            if (isEditForm && isEmpty(payload1.dashboardDataSetKey)) {
                throw new Error("Dataset Key Not Found for dataset-1");
            }

            // set result filter x,z if chart type is set to bubble
            if (payload1.chartType === "bubble") {
                if (formDataset2Fields?.["dataset[1]dataName"]) {
                    formDataset2Fields["dataset[1]dataName"] = "";
                }
                if (formDataset2Fields?.["dataset[1]groupByResult"]?.value) {
                    formDataset2Fields["dataset[1]groupByResult"].value = 0;
                }

                payload1.resultNameMstrKeyX =
                    resultFilterDataFields["dataset[0]resultFilterX"]
                        ?.map((o) => o.id)
                        .join(",") || null;

                payload1.resultNameMstrKeyZ =
                    resultFilterDataFields["dataset[0]resultFilterZ"]
                        ?.map((o) => o.id)
                        .join(",") || null;
            }

            if (payload1.chartType === "pie") {
                if (formDataset2Fields?.["dataset[1]dataName"]) {
                    formDataset2Fields["dataset[1]dataName"] = "";
                }
                if (formDataset2Fields?.["dataset[1]groupByResult"]?.value) {
                    formDataset2Fields["dataset[1]groupByResult"].value = 0;
                }
            }

            const dataset1Payload = {
                ...commonPaylod,
                ...payload1,
            };

            // insert / update dataset-1
            if (!isEditForm) {
                await DashboardPODDataSetInsertListApi(dataset1Payload);
            } else {
                await DashboardPODDataSetUpdateListApi(dataset1Payload);
            }

            // create dataset-2 payload object
            const payload2 = formDataset2Fields
                ? datasetPayload(formDataset2Fields, 1)
                : {};

            if (Object.keys(payload2).length) {
                const dataset2Payload = {
                    ...commonPaylod,
                    ...payload2,
                };

                // insert / update dataset-2
                if (isEmpty(payload2.dashboardDataSetKey)) {
                    await DashboardPODDataSetInsertListApi(dataset2Payload);
                } else {
                    await DashboardPODDataSetUpdateListApi(dataset2Payload);
                }
            }

            const existingDataset2Data = getPodDatasetData(1);

            //delete existing dataset-2 if it is unset when edit
            if (
                existingDataset2Data?.dataset_name ||
                existingDataset2Data?.is_group_by_result
            ) {
                const deleteDatasetPaylod = {
                    userId: getUserKey || null,
                    tenantId: getTenantId || null,
                    dashboardDataSetKey:
                        existingDataset2Data?.dashboard_dataset_key || 0,
                };

                if (!Object.keys(payload2).length) {
                    await DashboardPODDatasetDeleteApi(deleteDatasetPaylod);
                }
            }
        },
        onSuccess() {
            closeDialog();
            dashboardPublishEnable[activeTabId] = true;
            setDashboardPublishEnable({ ...dashboardPublishEnable });
            dashboardDataSet.remove();
            refreshDashboardPodDetail && refreshDashboardPodDetail();
        },
        onError(error) {
            dispatch(
                setNewMessage({
                    message:
                        error?.message ||
                        error ||
                        "Oops! Something went wrong ...",
                    type: "error",
                    show: true,
                })
            );
        },
    });

    return [
        {
            compPlanDataList,
            compPlanDataFields,
            setCompPlanDataFields,
            compPlanData2List,
            compPlanData2Fields,
            setCompPlanData2Fields,
            kpiDataFields,
            kpiDataList,
            setKpiDataFields,
            kpiData2Fields,
            kpiData2List,
            setKpiData2Fields,
            setResultFilterDataFields,
            resultFilterDataFields,
            resultFilterDataList,
            setResultFilterData2Fields,
            resultFilterData2Fields,
            resultFilterData2List,
            originalData,
            setOriginalData,
            setRefreshLoading,
            isRefreshLoading,
            defaultSelectAllOption,
            isEditForm,
        },
        {
            getCompPlanList,
            getKpiDataList,
            getResultDataList,
            getPodDatasetData,
            onSubmit,
        },
    ];
}
