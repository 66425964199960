import React from "react";
import { FormulaMaster } from "./formula-master";

const FormulaPage = (pageProps) => {
  return (
    <div className="Main">
      <div className="content">
        <FormulaMaster isModal={false} pageProps={pageProps}/>
      </div>
    </div>
  );
};

export default FormulaPage;
