import {
  CLEAR_PROVIDER_PLAN_STATE,
  PREFETCH_DROPDOWNS,
  SET_PROVIDER_COMPPLAN_DATA,
  SET_PROVIDER_COMPPLAN_LOADING,
  SET_PROVIDER_GROUP_LOADING,
  SET_PROVIDER_GROUPS_DATA,
  SET_PROVIDER_KPI_PAYMENT_SUMMARY,
  SET_PROVIDER_PLANPERIOD_DATA,
  SET_PROVIDER_PLANPERIOD_LOADING,
  SET_PROVIDER_PROVIDER_DATA,
  SET_PROVIDER_PROVIDER_LOADING,
  SET_PROVIDER_SECTION2_DATA,
  SET_PROVIDER_SELECTED_COMPPLANS,
  SET_PROVIDER_SELECTED_GROUPS,
  SET_PROVIDER_SELECTED_PLAN_PERIOD,
  SET_PROVIDER_SELECTED_PROVIDERS,
  SET_PROVIDER_SHOW_SECTION_1,
  SET_PROVIDER_SHOW_SECTION_2,
  SET_PROVIDER_SUMMARY_HEADER,
  SET_SHOW_PROVIDER_REPORT_RESULT,
} from "../actions/providerplan-actions";

const initialData = {
  compPlanData: [],
  isCompPlanLoading: false,
  selectedCompPlan: null,

  compPlanPeriodData: [],
  isCompPlanPeriodLoading: false,
  selectedCompPlanPeriod: null,

  groupData: [],
  isGroupLoading: false,
  selectedGroups: [],

  providerData: [],
  isProviderLoading: false,
  selectedProviders: [],

  showSection1: false,
  showSection2: true,

  providerPlanSummaryHeader: {
    columns: [],
    data: [],
  },

  providerKpiPaymentSummary: {
    columns: [],
    data: [],
  },

  providerSection2Data: {
    columns: [],
    data: [],
  },

  showReportResults: false,
  prefetchDropdowns: true,
};

const providerPlanReducer = (state = initialData, action) => {
  switch (action.type) {
    case SET_PROVIDER_COMPPLAN_DATA:
      return { ...state, compPlanData: action.data };
    case SET_PROVIDER_COMPPLAN_LOADING:
      return { ...state, isCompPlanLoading: action.data };
    case SET_PROVIDER_SELECTED_COMPPLANS:
      return { ...state, selectedCompPlan: action.data };

    case SET_PROVIDER_PLANPERIOD_DATA:
      return { ...state, compPlanPeriodData: action.data };
    case SET_PROVIDER_PLANPERIOD_LOADING:
      return { ...state, isCompPlanPeriodLoading: action.data };
    case SET_PROVIDER_SELECTED_PLAN_PERIOD:
      return { ...state, selectedCompPlanPeriod: action.data };

    case SET_PROVIDER_GROUPS_DATA:
      return { ...state, groupData: action.data };
    case SET_PROVIDER_GROUP_LOADING:
      return { ...state, isGroupLoading: action.data };
    case SET_PROVIDER_SELECTED_GROUPS:
      return { ...state, selectedGroups: action.data };

    case SET_PROVIDER_PROVIDER_DATA:
      return { ...state, providerData: action.data };
    case SET_PROVIDER_PROVIDER_LOADING:
      return { ...state, isProviderLoading: action.data };
    case SET_PROVIDER_SELECTED_PROVIDERS:
      return { ...state, selectedProviders: action.data };

    case SET_PROVIDER_SHOW_SECTION_1:
      return { ...state, showSection1: action.data };
    case SET_PROVIDER_SHOW_SECTION_2:
      return { ...state, showSection2: action.data };
    case SET_PROVIDER_SUMMARY_HEADER:
      return {
        ...state,
        providerPlanSummaryHeader: action.data,
        showReportResults: action.data ? true : state.showReportResults,
      };
    case SET_PROVIDER_KPI_PAYMENT_SUMMARY:
      return {
        ...state,
        providerKpiPaymentSummary: action.data,
        showReportResults: action.data ? true : state.showReportResults,
      };
    case SET_PROVIDER_SECTION2_DATA:
      return {
        ...state,
        providerSection2Data: { ...state.providerSection2Data, ...action.data },
        showReportResults: action.data ? true : state.showReportResults,
      };

    case SET_SHOW_PROVIDER_REPORT_RESULT:
      return {
        ...state,
        showReportResults: action.data,
        providerPlanSummaryHeader: {
          columns: [],
          data: [],
        },
        providerKpiPaymentSummary: {
          columns: [],
          data: [],
        },
        providerSection2Data: {
          columns: [],
          data: [],
        },
      };

    case PREFETCH_DROPDOWNS: 
      return {...state, prefetchDropdowns: action.data};

    case CLEAR_PROVIDER_PLAN_STATE:
      return initialData;

    default:
      return state;
  }
};

export default providerPlanReducer;
