import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { GetMaintanceData } from "../../lib/common-service";
import "react-toastify/dist/ReactToastify.css";
import { transformDateTime } from "src/lib/date-time.util";
import { useLocation } from "react-router-dom";

const MaintenanceMsg = () => {
  const [msg, setMsg] = useState([]);
  const [visible, setVisible] = useState(true);
  const [closedIndex, setClosedIndex] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        let res = await GetMaintanceData();
        if (res.length !== 0) {
          let responseData = res;
          // let startDate = responseData.maintenance_start_date;
          // let endDate = responseData.maintenance_end_date;
          // let startTime = responseData.maintenance_start_time;
          // let endTime = responseData.maintenance_end_time;
          // let sysMessage = responseData.sys_message;

          // const formattedStartDate = transformDateTime({
          //   date: startDate,
          //   format: "dddd MMMM DD",
          // });
          // const formattedEndDate = transformDateTime({
          //   date: endDate,
          //   format: "dddd MMMM DD",
          // });

          // let maintenanceDate = `${formattedStartDate} ${startTime} to ${formattedEndDate} ${endTime}`;

          // if (startDate === endDate) {
          //   maintenanceDate = `${formattedStartDate} between ${startTime} and ${endTime}`;
          // }

          // let maintenanceMsg = sysMessage.replace(
          //   "@@MAINTENANCE_DATE@@",
          //   maintenanceDate
          // );

          // setMsg(maintenanceMsg);
          let messagesArray = [];

          responseData.forEach((item) => {
            let startDate = item.maintenance_start_date;
            let endDate = item.maintenance_end_date;
            let startTime = item.maintenance_start_time;
            let endTime = item.maintenance_end_time;
            let sysMessage = item.sys_message;
            // Format the start and end dates
            const formattedStartDate = transformDateTime({
              date: startDate,
              format: "dddd MMMM DD",
            });
            const formattedEndDate = transformDateTime({
              date: endDate,
              format: "dddd MMMM DD",
            });

            // Construct the maintenance date string
            let maintenanceDate = `${formattedStartDate} ${startTime} to ${formattedEndDate} ${endTime}`;

            if (startDate === endDate) {
              maintenanceDate = `${formattedStartDate} between ${startTime} and ${endTime}`;
            }

            // Replace placeholder in the system message
            let maintenanceMsg = sysMessage.replace(
              "@@MAINTENANCE_DATE@@",
              maintenanceDate
            );

            // Add the formatted message to the array
            messagesArray.push({
              sys_msg_key: item.sys_msg_key,
              sys_message: maintenanceMsg,
              schedule_key: item.sys_msg_schedule_key,
              sys_msg_theme: item.sys_msg_theme,
            });
          });

          // Now messagesArray contains the dynamic objects with formatted messages
          setMsg(messagesArray);
        }
      } catch (error) {
        console.error("Error fetching maintenance data:", error);
        // Handle error fetching data if needed
      }
    };

    if (location.pathname !== "/sso-auth") {
      fetchData();
    }
  }, []);

  // const closemsg = () => {
  //   setVisible(false); // Hide the div on close button click
  // };
  const closemsg = (index) => {
    if (closedIndex === null) {
      setVisible(false);
      setClosedIndex(index); // Set the index of the first closed message
    }
  };

  //   useEffect(() => {
  //     if (msg) {
  //       const toastId = toast.warn(
  //         <div className="maintainancemessage-wrapper">
  //           {msg}
  //           {/* <button
  //             onClick={() => toast.dismiss(toastId)}
  //             style={{ marginTop: "10px" }}
  //           >
  //             I understand
  //           </button> */}
  //         </div>,
  //         {
  //           position: "top-center",
  //           autoClose: false,
  //           hideProgressBar: false,
  //           closeOnClick: false,
  //           pauseOnHover: false,
  //           draggable: true,
  //           progress: undefined,
  //           theme: "light",
  //           className: "maintainance-message",

  //           // transition: Bounce, // Uncomment if Bounce is properly imported
  //         }
  //       );
  //     }
  //   }, [msg]);

  return (
    visible && (
      <>
        {msg.map((messageObj, index) => (
          <div key={index} className="maintainance-message">
            <div className="message-structure warning-message">
              <div className="message-structure-inner">
                <div className={`message-icon ${messageObj.sys_msg_icon}-icon`}>
                  <svg
                    viewBox="0 0 24 24"
                    width="100%"
                    height="100%"
                    fill="#2d2d2d"
                  >
                    <path d="M23.32 17.191L15.438 2.184C14.728.833 13.416 0 11.996 0c-1.42 0-2.733.833-3.443 2.184L.533 17.448a4.744 4.744 0 000 4.368C1.243 23.167 2.555 24 3.975 24h16.05C22.22 24 24 22.044 24 19.632c0-.904-.251-1.746-.68-2.44zm-9.622 1.46c0 1.033-.724 1.823-1.698 1.823s-1.698-.79-1.698-1.822v-.043c0-1.028.724-1.822 1.698-1.822s1.698.79 1.698 1.822v.043zm.039-12.285l-.84 8.06c-.057.581-.408.943-.897.943-.49 0-.84-.367-.896-.942l-.84-8.065c-.057-.624.25-1.095.779-1.095h1.91c.528.005.84.476.784 1.1z"></path>
                  </svg>
                </div>
                {/* Render the sys_message from the object */}
                <div className="maintainancemessage-wrapper">
                  {messageObj.sys_message}
                </div>
              </div>
              <button
                className="message-close-icon"
                type="button"
                aria-label="close"
                onClick={closemsg}
              >
                <svg aria-hidden="true" viewBox="0 0 14 16">
                  <path
                    fill-rule="evenodd"
                    d="M7.71 8.23l3.75 3.75-1.48 1.48-3.75-3.75-3.75 3.75L1 11.98l3.75-3.75L1 4.48 2.48 3l3.75 3.75L9.98 3l1.48 1.48-3.75 3.75z"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
        ))}
      </>
    )
  );
};

export default React.memo(MaintenanceMsg);
