import * as React from "react";
import * as ReactDOM from "react-dom";

export const LoadingPanel = (props) => {
    const loadingPanel = (
        <div className="k-loading-mask">
            <span className="k-loading-text">Loading</span>
            <div className="k-loading-image" />
            <div className="k-loading-color" />
        </div>
    );

    const gridId = document && document.querySelector("#" + props.gridId);
    const gridContent = gridId && gridId.querySelector(".k-grid-content");

    return gridContent
        ? ReactDOM.createPortal(loadingPanel, gridContent)
        : loadingPanel;
};
