import React from "react";
import {
    GET_JOB_HISTORY,
    GET_SELECT_GRID_COLUMN_XML,
} from "../../api-setup/api-endpoints";
import { getTenantCookieData, getUserCredential } from "../../lib/cookies";
import {
    POSTAPI,
    errStatusHandler,
    generateHeader,
} from "../../api-setup/api-helper";
import { GridColumnDataMapper } from "../../utils/utils";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { setNewMessage } from "../../redux/actions/setting-actions";
import { GetPeriodTypeAPI, GetPeriodsDataList } from "../../lib/common-service";

export default function Title() {
    const defaultPageSize = getTenantCookieData("record_per_page");
    const cookiesData = getUserCredential();
    const header = {
        "x-access-token": cookiesData.secretToken,
    };
    const history = useHistory();
    const dispatch = useDispatch();
    const [data, setData] = React.useState([]);
    const [columns, setColumns] = React.useState([]);
    const [dataCount, setDataCount] = React.useState(0);
    const [loadingState, setLoadState] = React.useState({
        isSaveData: undefined,
        isDeleteData: undefined,
    });
    const [initialLoading, setLoadingState] = React.useState(false);
    const [isAPICalling, setAPICallingState] = React.useState(false);
    const [isRefreshLoading, setRefreshLoading] = React.useState(false);
    const [loading, setLoadingStateSearch] = React.useState({
        isSearchLoader: false,
        updateColumn: false
    });
    const [periodsTypeFields, setPeriodTypeFields] = React.useState({
        periodTypeValue: {
            in_period_mstr_key: "",
        },
        periodTypeData: [],
        isPeriodTypeDataLoading: true,
    });
    const [jobNameFields, setJobNameFields] = React.useState({
        jobNameValue: [

        ],
        jobNameData: [],
        isJobNameDataLoading: true,
    });
    const [jobNameList, setJobNameList] = React.useState([]);
    const [groupNameList, setGroupNameList] = React.useState([]);
    const [groupNameFields, setGroupNameFields] = React.useState({
        groupNameValue: [

        ],
        groupValueData: [],
        isGroupNameDataLoading: true,
    });
    const [periodTypeList, setPeriodTypeList] = React.useState([]);
    const [periodsFields, setPeriodFields] = React.useState({
        periodsValue: {
            id: "",
            text: "",
        },
        periodsData: [],
        isPeriodDataLoading: true,
    });
    const [jobTypeFields, setJobTypeFields] = React.useState({
        jobType: {
            id: "",
            text: "",
        },
        jobTypeData: [],
        isJobTypeDataLoading: true,
    });
    const [jobTypeList, setJobTypeList] = React.useState([]);
    const [periodsList, setPeriodsList] = React.useState([]);

    const getListDataAsync = (listData, module) => {
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                GET_JOB_HISTORY,
                null,
                header,
                listData,
                dispatch
            ).then((res) => {
                if (res && res.data.out_parameters.out_ret_code > 0) {
                    setLoadingState(true);
                    errStatusHandler(res, history, dispatch);
                }
    
                let userData = res ? JSON.parse(JSON.stringify(res.data.row_result)) : [];
    
                userData = userData.map((item) => {
                    ["start_time", "end_time"].forEach((field) => {
                        if (item[field]) {
                            item[field] = new Date(item[field]);
                        }
                    });
                    
                    ["is_processed"].forEach((field) => {
                        item[field] = item[field] === 1 ? 'true' : 'false';
                    });
    
                    return item;
                });
    
                setData(userData);
                setDataCount(res ? res.data.out_parameters.out_total_rows : 0);
                setRefreshLoading(false);
                setLoadingStateSearch({
                    ...loading,
                    isSearchLoader: false,
                });
                setAPICallingState(false);
                setLoadingState(true);
                resolve(userData);
            }).catch((error) => {
                if (error.response && error.response.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: error.response.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(error.response, history, dispatch);
                } else {
                    reject(error);
                }
            });
        });
    };


    const getColumListXMLData = (reqData) => {
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                GET_SELECT_GRID_COLUMN_XML,
                null,
                header,
                reqData,
                dispatch
            ).then((res) => {
                if (res.data && res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    setAPICallingState(true);
                    errStatusHandler(res, history, dispatch);
                } else {
                    const columData = GridColumnDataMapper(
                        res.data.out_parameters.out_columns_xml
                    );
                    setColumns(columData)
                    resolve(columData)

                }
            });
        }).then((res) => {
            return res
        })
    };


    const getGroupName = async (jobResultData) => {
        setGroupNameFields({
            ...groupNameFields,
            isGroupNameDataLoading: true,
        });
        let resData = await getJobList(jobResultData);
        let tempArrayList = [];
        if (resData.length > 0) {
            resData.map((item) => {
                let obj = {};
                obj.text = item.title_name;
                obj.id = item.org_title_key;
                tempArrayList.push(obj);
                return 0;
            });
            // tempArrayList.unshift({
            //     text: "Select All",
            //     id: "Select All",
            // })
            setGroupNameFields({
                ...groupNameFields,
                groupValueData: tempArrayList,
                isGroupNameDataLoading: false,
                groupNameValue: [
                    {
                        text: "Select All",
                        id: "Select All",
                    },
                ],
            });
            setGroupNameList(tempArrayList);
        } else {
            setGroupNameList([]);
            setGroupNameFields({
                ...groupNameFields,
                // groupNameValue: [
                //     {
                //         text: "Select All",
                //         id: "Select All",
                //     },
                // ],
                groupValueData: [],
                isGroupNameDataLoading: false,
            });
        }
    };

    const getJobNameList = async (jobResultData) => {
        setJobNameFields({
            ...jobNameFields,
            isJobNameDataLoading: true,
        });
        let resData = await getJobList(jobResultData);
        let tempArrayList = [];
        if (resData.length > 0) {
            resData.map((item) => {
                let obj = {};
                obj.text = item.job_name;
                obj.id = item.job_mstr_key;
                tempArrayList.push(obj);
                return 0;
            });
            // tempArrayList.unshift({
            //     text: "Select All",
            //     id: "Select All",
            // })
            setJobNameList(tempArrayList);
            setJobNameFields({
                ...jobNameFields,
                jobNameData: tempArrayList,
                isJobNameDataLoading: false,
                jobNameValue: [
                    {
                        text: "Select All",
                        id: "Select All",
                    },
                ],
            });
            jobResultData.in_group_by = "a.org_title_key";
            jobResultData.in_job_mstr_keys = "0";
            getGroupName(jobResultData);
        } else {
            setJobNameList([]);
            setJobNameFields({
                ...jobNameFields,
                jobNameValue: [],
                jobNameData: [],
                isJobNameDataLoading: false,
            });
        }
    };

    const getJobList = (listData) => {
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                GET_JOB_HISTORY,
                null,
                header,
                listData,
                dispatch
            ).then((res) => {
                if (res) {
                    if (res.data.out_parameters.out_ret_code > 0) {
                        setLoadingState(true);
                        errStatusHandler(res, history, dispatch);
                    }
                    let userData = JSON.parse(
                        JSON.stringify(res.data.row_result)
                    );
                    resolve(userData);
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    }

    const getJobTypeList = async (id) => {
        let jobResultData = {
            in_acct_encrypt_key: cookiesData.out_acct_encrypt_key,
            in_period_mstr_key: id,
            in_job_type_mstr_key: "0",
            in_job_mstr_keys: "0",
            in_org_title_keys: "0",
            in_filter: null,
            in_group_by: "a.job_type_mstr_key",
            in_sort_number: 11,
            in_sort_order: "DESC",
            in_page_row_offset: 0,
            in_page_rows: 9999,
        };
        let resData = await getJobList(jobResultData);
        let tempArrayList = [];
        if (resData.length > 0) {
            resData.map((item) => {
                let obj = {};
                obj.text = item.job_type_name;
                obj.id = item.job_type_mstr_key;
                obj.job_mstr_key = item.job_mstr_key;
                tempArrayList.push(obj);
                return 0;
            });
            setJobTypeList(tempArrayList);
            setJobTypeFields({
                ...jobTypeFields,
                jobTypeData: tempArrayList,
                jobType: tempArrayList[0],
                isJobTypeDataLoading: false,
            });
            jobResultData.in_group_by = "a.job_mstr_key";
            jobResultData.in_job_type_mstr_key = tempArrayList[0].id;
            getJobNameList(jobResultData);
        } else {
            resetAllStates();
        }
    };

    const getPeriodsDataList = async (id) => {
        setPeriodFields({
            ...periodsFields,
            isPeriodDataLoading: true,
        });
        let periodsDataReq = {
            in_tenant_id: cookiesData.out_tenant_id,
            in_filter: `AND a.period_type_mstr_key = ${id}`,
            in_sort_number: null,
            in_sort_order: null,
            in_page_row_offset: 0,
            in_page_rows: defaultPageSize.recordTake,
            in_sort_number_concat:
                "a.period_end_date DESC, a.period_level_order DESC",
        };
        let resPeriods = await GetPeriodsDataList(
            periodsDataReq,
            dispatch,
            history
        );
        if (resPeriods.length > 0) {
            let tempArray = [];
            resPeriods.map((item) => {
                let obj = {};
                obj.text = item.period_name;
                obj.id = item.period_mstr_key;
                obj.in_period_start_date = item.period_start_date;
                obj.in_period_end_date = item.period_end_date;
                tempArray.push(obj);
                return 0;
            });
            setPeriodsList(tempArray);
            setPeriodFields({
                ...periodsFields,
                periodsData: tempArray,
                periodsValue: tempArray[0],
                isPeriodDataLoading: false,
            });
            setJobTypeList([]);
            setJobTypeFields({
                ...jobTypeFields,
                isJobTypeDataLoading: true,
            });
            getJobTypeList(tempArray[0].id);
        } else {
            setPeriodsList([]);
            setPeriodFields({
                periodsValue: {
                    id: "",
                    text: "",
                },
                periodsData: [],
                isPeriodDataLoading: false,
            });
            resetAllStates();
        }
    };

    const getPeriodTypeList = async () => {
        let periodTypeData = {
            in_filter: "AND a.period_type_hierarchy_level IS NOT NULL",
            in_sort_number: null,
            in_sort_order: null,
        };
        let res = await GetPeriodTypeAPI(periodTypeData, dispatch, history);
        if (res.length > 0) {
            let tempArray = [];
            res.map((item) => {
                let obj = {};
                obj.text = item.period_type_name;
                obj.id = item.period_type_mstr_key;
                tempArray.push(obj);
                return 0;
            });
            getPeriodsDataList(tempArray[0].id);
            setPeriodTypeList(tempArray);
            setPeriodTypeFields({
                ...periodsTypeFields,
                periodTypeData: tempArray,
                periodTypeValue: tempArray[0],
                isPeriodTypeDataLoading: false,
            });
        } else {
            setPeriodTypeFields({
                ...periodsTypeFields,
                isPeriodTypeDataLoading: false,
            });
        }
    };

    const resetAllStates = () => {
        setJobTypeList([]);
        setJobTypeFields({
            ...jobTypeFields,
            jobTypeData: [],
            jobType: "",
            isJobTypeDataLoading: false,
        });
        setJobNameList([]);
        setGroupNameList([]);
        setJobNameFields({
            ...jobNameFields,
            isJobNameDataLoading: false,
            jobNameValue: [],
            jobNameData: [],
        });
        setGroupNameFields({
            ...groupNameFields,
            groupNameValue: [],
            groupValueData: [],
            isGroupNameDataLoading: false,
        });
    };

    const exportDataAsync = (bodyData) => {
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                GET_JOB_HISTORY,
                null,
                generateHeader(),
                bodyData,
                dispatch
            ).then((res) => {
                if (
                    res &&
                    (res.data.out_parameters.out_ret_code === 0 ||
                        res.data.out_parameters.out_ret_code === 2)
                ) {
                    resolve(res.data.row_result);
                } else {
                    dispatch(
                        setNewMessage({
                            message: res?.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    resolve([]);
                    errStatusHandler(res, history, dispatch);
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    return [
        {
            data,
            columns,
            dataCount,
            setData,
            setColumns,
            isAPICalling,
            initialLoading,
            setAPICallingState,
            loadingState,
            setLoadState,
            setRefreshLoading,
            isRefreshLoading,
            loading,
            setLoadingStateSearch,
            setJobTypeFields,
            jobTypeFields,
            groupNameFields,
            jobNameFields,
            setGroupNameFields,
            setJobNameFields,
            jobTypeList,
            setPeriodFields,
            periodsFields,
            jobNameList,
            periodTypeList,
            setPeriodTypeList,
            groupNameList,
            periodsTypeFields,
            setPeriodTypeFields,
            setPeriodsList,
            periodsList,
        },
        {
            getListDataAsync,
            getJobTypeList,
            getJobNameList,
            getGroupName,
            getPeriodTypeList,
            getPeriodsDataList,
            exportDataAsync,
            getColumListXMLData
        },
    ];
}
