import { DashboardDataset } from "src/service/query/dashboard-tab-list.query";
import { getUserData, mapUserData } from "src/store/user.store";
import {
    DashboardPeriodTypeList,
    DashboardTypeList,
} from "src/service/query/dashboard-operation.query";
import { TransformDropdown } from "src/components/form-wrapper";
import { getDashboardEditPodDialog } from "src/store/dashboard.store";

const { dbRoleKey, tenantId, userKey } = mapUserData;

export const dashboardPodDialogHook = () => {
    const userData = getUserData();
    const getTenantId = tenantId.getData(userData) || "";
    const getUserKey = userKey.getData(userData) || "";

    const dashboardEditPodData = getDashboardEditPodDialog();

    let dashboardTypeDataSet = [];
    let dashboardPeriodTypeDataSet = [];

    const dialogPodConfig = {
        datasetFields: {
            dashboardType: {
                label: "dashboard_type_name",
                value: "dashboard_type_mstr_key",
            },
            dashboardPeriodType: {
                label: "period_type_name",
                value: "period_type_mstr_key",
            },
            compPlanFilter: {
                label: "comp_plan_names",
                value: "comp_plan_mstr_keys",
            },
            kpiFilter: {
                label: "kpi_name",
                value: "kpi_mstr_key",
            },
            resultFilter: {
                label: "result_name",
                value: "result_name_mstr_key",
            },
        },
        initialValue: {
            labelName: "",
            dashboardType: dashboardTypeDataSet[0] || null,
            dashboardPeriodType: dashboardPeriodTypeDataSet[0] || null,
        },
    };

    const datasetFields = dialogPodConfig?.datasetFields;
    let initialValues = dialogPodConfig?.initialValue || {};

    const dashboardDataSet = DashboardDataset({
        tenantId: getTenantId || null,
        userKey: getUserKey || null,
        dashboardPodKey: dashboardEditPodData?.dashboardPodKey || null,
    });

    const dashboardTypeData = DashboardTypeList({
        tenantId: getTenantId || null,
        userDBRoleKey: dbRoleKey.getData(userData),
    });

    const dashboardPeriodType = DashboardPeriodTypeList();

    if (!dashboardTypeData?.isLoading && dashboardTypeData?.data?.length > 0) {
        dashboardTypeDataSet = TransformDropdown({
            data: dashboardTypeData?.data,
            keyForLabel: datasetFields?.dashboardType?.label,
            keyForValue: datasetFields?.dashboardType?.value,
        });
    }

    if (
        !dashboardPeriodType?.isLoading &&
        dashboardPeriodType?.data?.length > 0
    ) {
        dashboardPeriodTypeDataSet = TransformDropdown({
            data: dashboardPeriodType?.data.sort(
                (a, b) =>
                    a.period_type_hierarchy_level -
                    b.period_type_hierarchy_level
            ),
            keyForLabel: datasetFields?.dashboardPeriodType?.label,
            keyForValue: datasetFields?.dashboardPeriodType?.value,
        });
    }

    return {
        dashboardDataSet,
        dashboardTypeDataSet,
        dashboardPeriodTypeDataSet,
        initialValues,
    };
};
