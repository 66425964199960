import Cookies from "universal-cookie";
const AUTH_COOKIES_KEY = process.env.REACT_APP_AUTH_KEY;

export function setUserData(obj, objName) {
    const cookies = new Cookies();
    let secure = false;
    cookies.set(objName, obj, { secure: secure, path: "/" });
}

export function setUserCredential(obj) {
    return setUserData(obj, AUTH_COOKIES_KEY);
}

export function getUserData(objName) {
    const cookies = new Cookies();
    const userObj = cookies.get(objName);
    return userObj || null;
}

export function getUserCredential() {
    return getUserData(AUTH_COOKIES_KEY);
}

export function checkIsLoggedIn() {
    let getUserCredentialData = getUserCredential();

    if (!getUserCredentialData) {
        return { is_login: false, is_auth: false };
    }

    if (getUserCredentialData.secretToken && !getUserCredentialData.isAuth) {
        return { is_login: true, is_auth: false };
    }

    if (getUserCredentialData.secretToken && getUserCredentialData.isAuth) {
        return { is_login: true, is_auth: true };
    }
}

export function checkIsMFA() {
    return getTenantCookieData("is_mfa") === "0" ? true : false;
}

export function deleteUserData(objName) {
    const cookies = new Cookies();
    cookies.remove(objName);
}

export function checkIsVerifiedOtp() {
    let getUserCredentialData = getUserCredential();
    return getUserCredentialData && getUserCredentialData.is_verified_otp === 1
        ? true
        : false;
}

export function removeUserCredential() {
    return deleteUserData(AUTH_COOKIES_KEY);
}

export function setOtpTimer(obj) {
    const cookies = new Cookies();
    let secure = false;
    cookies.set("otpData", obj, { secure: secure, path: "/" });
}

export function getOtpTimer() {
    const cookies = new Cookies();
    const userObj = cookies.get("otpData");
    return userObj;
}

export function removeOtpData() {
    const cookies = new Cookies();
    cookies.remove("otpData");
}

export function isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }

    return true;
}

export function setLocalStorage(itemName, items) {
    if (typeof items === "object") {
        items = JSON.stringify(items);
    }
    localStorage.setItem(itemName, items);
}

export function getLocalStorage(itemName) {
    const localVar = localStorage.getItem(itemName);

    if (!localVar) {
        return localVar;
    }

    if (isJsonString(localVar)) {
        const jsonStr = JSON.parse(localVar);
        if (jsonStr.type !== "flash") {
            return jsonStr;
        }

        //remove from local storage if type = flash
        let message = isJsonString(jsonStr.items)
        ? JSON.parse(jsonStr.items)
        : jsonStr.items;
        setTimeout(()=>{
            removeLocalStorage(itemName);
        }, 1000)
        return message
    }

    return localVar;
}

export function removeLocalStorage(itemName) {
    localStorage.removeItem(itemName);
}

export function clearLocalStorage() {
    localStorage.clear();
}

export function setFlash(itemName, items) {
    setLocalStorage(itemName, { type: "flash", items: items });
}

export function getFlash(itemName) {
    return getLocalStorage(itemName);
}

export function getTenantCookieData(key) {
    const getUserCredentialData = getUserCredential();

    if (!getUserCredentialData || !getUserCredentialData.tenant_data) {
        return {};
    }

    if (key === undefined) {
        return getUserCredentialData.tenant_data;
    }

    return typeof getUserCredentialData.tenant_data[key] != "undefined"
        ? getUserCredentialData.tenant_data[key]
        : "";
}
