import React, { useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { FieldWrapper, Form, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { Label, Error } from "@progress/kendo-react-labels";
import { Input } from "@progress/kendo-react-inputs";
import MessageSchema from "../../utils/messageSchema";
import { userGridSelector } from "../../redux/selectors/custom-selector";
import CustomDropdown from "../../components/customDropdown";
import useApi from "./service";

const elem1 = ["active", "inactive", "new"];

const AddNew = (restProps) => {
    const userGridReduxData = useSelector(userGridSelector, shallowEqual);
    let statusRef = React.useRef(null)
    let roleRef = React.useRef(null)

    const { closePopup } = restProps;
    const { userStatusData } = userGridReduxData.userStatusData;
    const { roleData } = userGridReduxData.roleData;


    useEffect(() => {
        sortArray([])
    }, [userStatusData])
    const sortArray = (arr) => {
        return arr.sort((a, b) => (a.text === "new" ? -1 : b.text === "new" ? 1 : 0));
    };

    const filteredStatus = userStatusData.filter((e) =>
        elem1.includes(e.text.toLowerCase())
    );

    const filteredRole = roleData.filter(
        (e) => e.text.toLowerCase() !== "select all"
    );

    const defaultRole = filteredRole[0] ? filteredRole[0] : {};
    const defaultStatus = filteredStatus[2] ? filteredStatus[2] : {};

    const initialFormFields = {
        in_user_first_name: "",
        in_user_last_name: "",
        in_user_username: "",
        in_user_email: "",
        in_db_role_key: defaultRole,
        in_status_type_key: defaultStatus,
        in_user_comments: "",
        in_employee_id: "",
    };

    const [errorObj, setErrorObj] = React.useState({});
    const [formFields, setFormFields] = React.useState(initialFormFields);
    const [statusFields, setStatusFields] = React.useState(filteredStatus);
    const [roleFields, setRoleFields] = React.useState(filteredRole);
    const [roleDesc, setRoleDesc] = React.useState(defaultRole.role_desc);
    const [statusFieldsList] = React.useState(filteredStatus);
    const [List, Action] = useApi();

    React.useEffect(() => {
        if (List.loadingState.isSaveData) {
            List.setLoadState({ ...List.loadingState, isSaveData: false });
            onClosePopup(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [List.loadingState.isSaveData]);

    /**
     * Handle on close Popup
     */
    const onClosePopup = (isGridRefresh) => {
        closePopup(isGridRefresh);
    };

    const handleCancel = () => {
        onClosePopup(false);
    };

    /**
     *
     * handle Field Change
     */
    const handleFieldChange = React.useCallback(
        (e) => {
            const name = e.name ? e.name : e.target.name;
            if (e.value !== null) {
                setFormFields({ ...formFields, [name]: e.value });
                setErrorObj({ ...errorObj, [name]: "" });
            }
        },
        [formFields, errorObj]
    );

    const handleDropdownChange = React.useCallback(
        (e, name) => {
            if (e) {
                setFormFields({ ...formFields, [name]: e });
            }
        },
        [formFields]
    );

    const handleDescChange = (e) => {
        setRoleDesc(e.role_desc);
    };

    /**
     * Show Error message
     */
    const ShowError = React.useCallback(
        (props) => {
            return <Error>{errorObj[props.name]}</Error>;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [errorObj]
    );

    /**
     * Handle Validation
     */
    const handleValidation = () => {
        let isValid = true;
        let errObj = {};
        if (formFields.in_user_username.trim() === "") {
            isValid = false;
            errObj.in_user_username = MessageSchema.usernameRequired;
        }
        if (formFields.in_user_first_name.trim() === "") {
            isValid = false;
            errObj.in_user_first_name = MessageSchema.firstnameRequired;
        }
        if (formFields.in_user_last_name.trim() === "") {
            isValid = false;
            errObj.in_user_last_name = MessageSchema.lastnameRequired;
        }
        if (formFields.in_user_email.trim() === "") {
            isValid = false;
            errObj.in_user_email = MessageSchema.emailRequired;
        }

        if (errObj.in_user_email === undefined) {
            if (formFields.in_user_email.validateEmail() === null) {
                isValid = false;
                errObj.in_user_email = MessageSchema.emailValid;
            }
        }
        setErrorObj({ ...errObj });
        return isValid;
    };

    /**
     * Handle Save method
     */
    const handleSave = async () => {
        let isValid = handleValidation();
        if (isValid) {
            let reqData = {
                in_user_first_name: formFields.in_user_first_name,
                in_user_last_name: formFields.in_user_last_name,
                in_user_username: formFields.in_user_username,
                in_user_email: formFields.in_user_email,
                in_db_role_key: formFields.in_db_role_key.value,
                in_status_type_key: formFields.in_status_type_key.value,
                in_user_comments: formFields.in_user_comments,
                in_employee_id: formFields.in_employee_id,
            };

            Action.addDataAsync(reqData);
        }
    };

    return (
        <Form
            render={() => (
                <FormElement className="emailForm" horizontal={true}>
                    <FieldWrapper>
                        <div className={"k-form-field-wrap-popup"}>
                            <Label>{"First Name* : "}</Label>
                            <Input
                                type="text"
                                name="in_user_first_name"
                                value={formFields.in_user_first_name}
                                onChange={(e) => {
                                    handleFieldChange(e);
                                }}
                                autoFocus={true}
                            />
                            <ShowError name="in_user_first_name" />
                        </div>
                    </FieldWrapper>
                    <FieldWrapper>
                        <div className={"k-form-field-wrap-popup"}>
                            <Label>{"Last Name* : "}</Label>
                            <Input
                                type="text"
                                name="in_user_last_name"
                                value={formFields.in_user_last_name}
                                onChange={(e) => {
                                    handleFieldChange(e);
                                }}
                            />
                            <ShowError name="in_user_last_name" />
                        </div>
                    </FieldWrapper>
                    <FieldWrapper>
                        <div className={"k-form-field-wrap-popup"} ref={roleRef}>
                            <Label>{"Role* : "}</Label>
                            <CustomDropdown
                                name={"in_db_role_key"}
                                data={roleFields}
                                textField="text"
                                dataItemKey="value"
                                valueData={filteredRole.find(
                                    (c) =>
                                        c.text ===
                                        formFields.in_db_role_key.text
                                )}
                                onChange={(e, name) => {
                                    handleDropdownChange(e, name);
                                    handleDescChange(e);
                                }}
                                filterable={true}
                                onFilterChange={(dataValue) => {
                                    setRoleFields(dataValue);
                                }}
                                onOpen={(dataV) => {
                                    setRoleFields(roleData);
                                }}
                                autoClose={false}
                                autoBind={true}
                                isSelectALl={false}
                                dataList={roleData}
                                appendTo={roleRef.current}
                            />
                        </div>
                    </FieldWrapper>
                    <FieldWrapper>
                        <div className={"k-form-field-wrap-popup"}>
                            <Label>{"Role Description* : "}</Label>
                            <span>{roleDesc}</span>
                        </div>
                    </FieldWrapper>

                    <FieldWrapper>
                        <div className={"k-form-field-wrap-popup"}>
                            <Label>{"Username* : "}</Label>
                            <Input
                                type="text"
                                name="in_user_username"
                                value={formFields.in_user_username}
                                onChange={(e) => {
                                    handleFieldChange(e);
                                }}
                            />
                            <ShowError name="in_user_username" />
                        </div>
                    </FieldWrapper>
                    <FieldWrapper>
                        <div className={"k-form-field-wrap-popup"}>
                            <Label>{"Email* : "}</Label>
                            <Input
                                type="text"
                                name="in_user_email"
                                value={formFields.in_user_email}
                                onChange={(e) => {
                                    handleFieldChange(e);
                                }}
                            />
                            <ShowError name="in_user_email" />
                        </div>
                    </FieldWrapper>
                    <FieldWrapper>
                        <div className={"k-form-field-wrap-popup"} ref={statusRef}>
                            <Label>{"Status* : "}</Label>
                            <CustomDropdown
                                name={"in_status_type_key"}
                                data={statusFields}
                                textField="text"
                                dataItemKey="value"
                                valueData={filteredStatus.find(
                                    (c) =>
                                        c.text ===
                                        formFields.in_status_type_key.text
                                )}
                                onChange={(e, name) => {
                                    handleDropdownChange(e, name);
                                }}
                                filterable={true}
                                onFilterChange={(dataValue) => {
                                    setStatusFields(dataValue);
                                }}
                                onOpen={(dataV) => {
                                    setStatusFields(statusFieldsList);
                                }}
                                autoClose={false}
                                autoBind={true}
                                isSelectALl={false}
                                dataList={statusFieldsList}
                                appendTo={statusRef.current}
                            />
                        </div>
                    </FieldWrapper>
                    <FieldWrapper>
                        <div className={"k-form-field-wrap-popup"}>
                            <Label>{"Comments : "}</Label>
                            <Input
                                type="text"
                                name="in_user_comments"
                                value={formFields.in_user_comments}
                                onChange={(e, name) => {
                                    handleFieldChange(e, name);
                                }}
                            />
                            <ShowError name="in_user_comments" />
                        </div>
                    </FieldWrapper>

                    <div className="dropdownFooter martop15 userdropdownbtn">
                        <div className="k-form-buttons">
                            <Button
                                primary={true}
                                type={"submit"}
                                onClick={handleCancel}
                            >
                                Cancel
                            </Button>
                            <Button
                                primary={true}
                                type={"submit"}
                                onClick={handleSave}
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                </FormElement>
            )}
        />
    );
};

export default React.memo(AddNew);
