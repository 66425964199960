import * as React from "react";
import { GridColumnMenuWrapper } from "@progress/kendo-react-grid";


export const CommonHeaderCellTemplate = (props) => {
  let filteredMembers = {}
  const setFilteredMembers = (filter, members) => {
    if (filter.filters) {
      for (let i = 0; i < filter.filters.length; i++) {
        setFilteredMembers(filter.filters[i], members);
      }
    } else {
      members[filter.field] = true;
    }
  };
  if (props.columnMenuWrapperProps?.filter) {
    setFilteredMembers(props.columnMenuWrapperProps.filter, filteredMembers)
  }

  const getFilteredKey = (filterKey) => {
    let showIcon = false
    for (let filteredKey in filteredMembers) {
      if (filterKey === filteredKey) {
        showIcon = true
      }
    }
    return showIcon
  }

  return (
    <span className="k-cell-inner">
      <span className="k-link" onClick={props.onClick}>
        <span className="k-column-title">
          <span data-field={props.field}>
            {getFilteredKey(props.field) && <i className="fas fa-filter">&nbsp;</i>}
            {props.title}</span>
        </span>
        {props.children}
      </span>
      <GridColumnMenuWrapper {...props.columnMenuWrapperProps}></GridColumnMenuWrapper>
    </span>
  );
};

export const ActionHeaderCellTemplate = (props) => {
  return (
    <span className="k-cell-inner default-cursor">
      <span className="k-link default-cursor" onClick={props.onClick}>
        <span className="k-column-title default-cursor">
          <span data-field={props.field}>{props.title}</span>
        </span>
        {props.children}
      </span>
    </span>
  );
};

export const AssignCheckboxHeaderCellTemplate = (props) => {
  let filteredMembers = {}
  const setFilteredMembers = (filter, members) => {
    if (filter.filters) {
      for (let i = 0; i < filter.filters.length; i++) {
        setFilteredMembers(filter.filters[i], members);
      }
    } else {
      members[filter.field] = true;
    }
  };
  if (props.columnMenuWrapperProps?.filter) {
    setFilteredMembers(props.columnMenuWrapperProps.filter, filteredMembers)
  }

  const getFilteredKey = (filterKey) => {
    let showIcon = false
    for (let filteredKey in filteredMembers) {
      if (filterKey === filteredKey) {
        showIcon = true
      }
    }
    return showIcon
  }
  return (
    <React.Fragment>
      <span className="k-cell-inner">
        <input
          className="k-checkbox"
          type="checkbox"
          value={0}
          checked={props.aditionalProps.checked}
          onChange={(e) => props.aditionalProps.onChange(e)}
          disabled={props.aditionalProps.disabled}
        />
        <span className="k-link" onClick={props.onClick}>
          <span className="k-column-title">
            <span data-field={props.field}>
              {getFilteredKey(props.field) && <i className="fas fa-filter">&nbsp;</i>}
              {props.title}
            </span>
          </span>
          {props.children}
        </span>
        <GridColumnMenuWrapper {...props.columnMenuWrapperProps}></GridColumnMenuWrapper>
      </span>
    </React.Fragment >
  );
};

export const CustomDataRowsHeaderCellTemplate = (props) => {
  let filteredMembers = {}
  const setFilteredMembers = (filter, members) => {
    if (filter.filters) {
      for (let i = 0; i < filter.filters.length; i++) {
        setFilteredMembers(filter.filters[i], members);
      }
    } else {
      members[filter.field] = true;
    }
  };
  if (props.columnMenuWrapperProps?.filter) {
    setFilteredMembers(props.columnMenuWrapperProps.filter, filteredMembers)
  }

  const getFilteredKey = (filterKey) => {
    let showIcon = false
    for (let filteredKey in filteredMembers) {
      if (filterKey === filteredKey) {
        showIcon = true
      }
    }
    return showIcon
  }
  return (
    <React.Fragment>
      <span className="k-cell-inner">
        <span className="k-link" onClick={props.onClick}>
          <span className="k-column-title">
            <span data-field={props.field}>
              {" "}
              {getFilteredKey(props.field) && <i className="fas fa-filter">&nbsp;</i>}
              {' '} 
              {props.isRequired ? (
                <>
                  <i title="Business Key" className="fas fa-key">&nbsp;</i>{" "}{props.title}
                </>
              ) : (
                props.title
              )}
            </span>
          </span>
          {props.children}
        </span>
        <GridColumnMenuWrapper {...props.columnMenuWrapperProps}></GridColumnMenuWrapper>
      </span>
    </React.Fragment>
  );
};


export const EventEditableHeaderCellTemplate = (props) => {
  let filteredMembers = {}
  const setFilteredMembers = (filter, members) => {
    if (filter.filters) {
      for (let i = 0; i < filter.filters.length; i++) {
        setFilteredMembers(filter.filters[i], members);
      }
    } else {
      members[filter.field] = true;
    }
  };
  if (props.columnMenuWrapperProps?.filter) {
    setFilteredMembers(props.columnMenuWrapperProps.filter, filteredMembers)
  }

  const getFilteredKey = (filterKey) => {
    let showIcon = false
    for (let filteredKey in filteredMembers) {
      if (filterKey === filteredKey) {
        showIcon = true
      }
    }
    return showIcon
  }
  return (
    <React.Fragment>
      <span className="k-cell-inner">
        <span className="k-link" onClick={props.onClick}>
          <span className="k-column-title">
            <span data-field={props.field}>
              {" "}
              {props.isEditable && <i className="fas fa-pencil-alt"></i>}{" "}
              {getFilteredKey(props.field) ? <i className="fas fa-filter"></i> : false}
              {' '} 
              {props.isRequired ? (
                <>
                  <i title="Business Key" className="fas fa-key"></i> {props.title}
                </>
              ) : (
                props.title
              )}
            </span>
          </span>
          {props.children}
        </span>
        <GridColumnMenuWrapper {...props.columnMenuWrapperProps}></GridColumnMenuWrapper>
      </span>
    </React.Fragment>
  );
};

