import React from "react";
import Header from "./header";
import Sidebar from "./sidebar";
import Footer from "./footer";
import {
  getUserCredential,
  removeOtpData,
  removeUserCredential,
} from "../lib/cookies";
import SysTimeOut from "./sysTimeOut";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { mainSelector } from "../redux/selectors/custom-selector";
import { useLocation, useHistory } from "react-router-dom";
import { setDbObjData } from "../redux/actions/main-actions";
import { useCallback } from "react";

const BaseLayout = ({ children }) => {
  const cookiesData = getUserCredential();
  const { dbObjData } = useSelector(mainSelector, shallowEqual);
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const getParentMenuCode = useCallback((code) => {
    if (!dbObjData || dbObjData.length === 0) return null;
    for (let i = 0; i < dbObjData.length; i++) {
      let index = dbObjData[i].children.findIndex(
        (data) => data.db_obj_code === code
      );
      if (index > -1) {
        return dbObjData[i].db_obj_code;
      }
    }
    return null;
   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateMenuSelection = useCallback(() => {
    let objData = [...dbObjData];
    const subMenuCode = location.pathname.replace(/^\/+/, "");
    const menuCode = getParentMenuCode(subMenuCode);
    let prevMenuIndex = -1;
    let prevSubMenuIndex = -1;

    prevMenuIndex = objData.findIndex((data) => data.is_active === 1);

    if (prevMenuIndex > -1) {
      prevSubMenuIndex = objData[prevMenuIndex].children.findIndex(
        (data) => data.is_active === 1
      );
    }

    if (!menuCode) {
      if (prevMenuIndex > -1) {
        objData[prevMenuIndex].is_active = 0;
        if (prevSubMenuIndex > -1) {
          objData[prevMenuIndex].children[prevSubMenuIndex].is_active = 0;
        }
      }
      dispatch(setDbObjData(objData));
      return;
    }

    let menuIndex = objData.findIndex((data) => data.db_obj_code === menuCode);
    let subMenuIndex = objData[menuIndex].children.findIndex(
      (data) => data.db_obj_code === subMenuCode
    );

    if (prevMenuIndex === menuIndex && prevSubMenuIndex === subMenuIndex) {
      return;
    }

    if (prevMenuIndex > -1) {
      objData[prevMenuIndex].is_active = 0;
      if (prevSubMenuIndex > -1) {
        objData[prevMenuIndex].children[prevSubMenuIndex].is_active = 0;
      }
    }

    objData[menuIndex].is_active = 1;
    objData[menuIndex].children[subMenuIndex].is_active = 1;

    dispatch(setDbObjData(objData));
    return;
       // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dbObjData]);

  React.useEffect(() => {
    updateMenuSelection();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  React.useEffect(() => {
    if (
      cookiesData &&
      cookiesData?.tenant_data?.system_time_out === undefined
    ) {
      removeUserCredential();
      removeOtpData();
      history.push("/login");
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookiesData]);

  const returnHeader = () => {
    if (cookiesData?.out_credential !== "AUTH_ACCESS") {
      return "";
    }

    return <Header />;
  };

  const returnSideBar = () => {
    if (cookiesData?.out_credential !== "AUTH_ACCESS") {
      return "";
    }

    return <Sidebar />;
  };

  return (
    <>
      {returnHeader()}
      {returnSideBar()}
      <div id="page-content">
        <SysTimeOut tenantTimeOut={cookiesData?.tenant_data?.system_time_out} />
        {/*dbObjData && dbObjData.length > 0 ? children : null */}
        {children}
      </div>
      <footer>
        <>
          <Footer />
        </>
      </footer>
    </>
  );
};

export default BaseLayout;
