/* eslint-disable jsx-a11y/anchor-is-valid */
import { useHistory } from "react-router-dom";
import {
  removeLocalStorage,
  removeOtpData,
  removeUserCredential,
} from "src/lib/cookies";

export const BackToLoginComponent = (props) => {
  const history = useHistory()
  const BackToLoginHandler = () => {
      removeLocalStorage("resultData");
      removeUserCredential();
      removeOtpData();
      history.push("/authentication");
  };

  return (
    <div onClick={props.clickHandler ? props.clickHandler : BackToLoginHandler} className="Logout-custom-link">
      <a className="cursor_blue">
        <i className="fas fa-chevron-left"></i>  Back to Login
      </a>
      {props.children}
    </div>
  );
};
