export const SET_PROVIDER_COMPPLAN_DATA = "SET_PROVIDER_COMPPLAN_DATA";
export const SET_PROVIDER_COMPPLAN_LOADING = "SET_PROVIDER_COMPPLAN_LOADING";
export const SET_PROVIDER_SELECTED_COMPPLANS = "SET_PROVIDER_SELECTED_COMPPLANS";

export const SET_PROVIDER_PLANPERIOD_DATA = "SET_PROVIDER_PLANPERIOD_DATA";
export const SET_PROVIDER_PLANPERIOD_LOADING = "SET_PROVIDER_PLANPERIOD_LOADING";
export const SET_PROVIDER_SELECTED_PLAN_PERIOD = "SET_PROVIDER_SELECTED_PLAN_PERIOD";

export const SET_PROVIDER_GROUPS_DATA = "SET_PROVIDER_GROUPS_DATA";
export const SET_PROVIDER_GROUP_LOADING = "SET_PROVIDER_GROUP_LOADING";
export const SET_PROVIDER_SELECTED_GROUPS = "SET_PROVIDER_SELECTED_GROUPS";

export const SET_PROVIDER_PROVIDER_DATA = "SET_PROVIDER_PROVIDER_DATA";
export const SET_PROVIDER_PROVIDER_LOADING = "SET_PROVIDER_PROVIDER_LOADING";
export const SET_PROVIDER_SELECTED_PROVIDERS = "SET_PROVIDER_SELECTED_PROVIDERS";

export const SET_PROVIDER_SHOW_SECTION_1 = "SET_PROVIDER_SHOW_SECTION_1";
export const SET_PROVIDER_SHOW_SECTION_2 = "SET_PROVIDER_SHOW_SECTION_2";

export const SET_PROVIDER_SUMMARY_HEADER = "SET_PROVIDER_SUMMARY_HEADER";
export const SET_PROVIDER_KPI_PAYMENT_SUMMARY = "SET_PROVIDER_KPI_PAYMENT_SUMMARY";

export const SET_SHOW_PROVIDER_REPORT_RESULT = "SET_SHOW_PROVIDER_REPORT_RESULT";

export const SET_PROVIDER_SECTION2_DATA = "SET_PROVIDER_SECTION2_DATA";
export const CLEAR_PROVIDER_PLAN_STATE = "CLEAR_PROVIDER_PLAN_STATE";

export const PREFETCH_DROPDOWNS = "PREFETCH_DROPDOWNS";

export const setProviderPlanDataAction = (data) => {
    return { type: SET_PROVIDER_COMPPLAN_DATA, data: data };
}
export const setProviderPlanLoadingAction = (value) => {
    return { type: SET_PROVIDER_COMPPLAN_LOADING, data: value};
}
export const setSelectedProviderCompPlanAction = (data) => {
    return { type: SET_PROVIDER_SELECTED_COMPPLANS, data: data};
}


export const setProviderPlanPeriodDataAction = (data) => {
    return { type: SET_PROVIDER_PLANPERIOD_DATA, data: data};
}
export const setProviderPlanPeriodLoadingAction = (value) => {
    return {type: SET_PROVIDER_PLANPERIOD_LOADING, data: value};
}
export const setSelectedProviderPeriodAction = (data) => {
    return { type: SET_PROVIDER_SELECTED_PLAN_PERIOD, data: data};
}


export const setProviderGroupsDataAction = (data) => {
    return { type: SET_PROVIDER_GROUPS_DATA, data: data};
}
export const setProviderGroupLoadingAction = (value) => {
    return {type: SET_PROVIDER_GROUP_LOADING, data: value};
}
export const setSelectedProviderGroupsAction = (data) => {
    return {type: SET_PROVIDER_SELECTED_GROUPS, data: data};
}


export const setProviderProvidersDataAction = (data) => {
    return { type: SET_PROVIDER_PROVIDER_DATA, data: data};
}
export const setProviderLoadingAction = (value) => {
    return  { type: SET_PROVIDER_PROVIDER_LOADING, data: value };
}
export const setSelectedProviderProviderAction = (data) => {
    return { type: SET_PROVIDER_SELECTED_PROVIDERS, data: data};
}


export const setProviderShowSection1 = (data) => {
    return { type: SET_PROVIDER_SHOW_SECTION_1, data: data};
}
export const setProviderShowSection2 = (data) => {
    return { type: SET_PROVIDER_SHOW_SECTION_2, data: data};
}

export const setProviderSummaryHeaderAction = (data) => {
    return { type: SET_PROVIDER_SUMMARY_HEADER, data: data};
}

export const setProviderKPIPaymentSummaryAction = (data) => {
    return { type: SET_PROVIDER_KPI_PAYMENT_SUMMARY, data: data};
}

export const setShowReportResult = (data) => {
    return {type: SET_SHOW_PROVIDER_REPORT_RESULT, data: data};
}

export const setProviderSection2DataAction = (data) => {
    return { type: SET_PROVIDER_SECTION2_DATA, data: data};
}

export const setPrefetchDropdownAction = (data) => {
    return { type: PREFETCH_DROPDOWNS, data: data};
}

export const clearProviderPlanReportsState = () => {
    return {
      type: CLEAR_PROVIDER_PLAN_STATE,
    };
  };