import React from "react";
import "./scss/tmplAuth.scss";
import { ProCareLogo, ProCareFooter } from "src/pages/base/headerPage";
import MaintenanceMsg from "src/pages/common/maintenanceMsg";

const TmplAuth = (props) => {
    return (
        <>
            <div className="login-main">
                <div className="login-box">
                    <div className="login-box-cont">
                        <div className="login-box-left">
                            <div className="logo-container">
                                <ProCareLogo />
                                {props.pageContent ? props.pageContent : ""}
                            </div>
                        </div>
                        <div className="login-box-right">
                            {props.form ? props.form : ""}
                        </div>
                    </div>
                    <div className="loginfooter">
                        <ProCareFooter />
                    </div>
                </div>
            </div>
        </>
    );
};

export default React.memo(TmplAuth);
