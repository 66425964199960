import { useCallback, useEffect, useRef, useState } from "react";
import { LoadingPanel } from "src/components/loadingPanel";
import { Window, WindowActionsBar } from "@progress/kendo-react-dialogs";
import PropTypes from "prop-types";
import { Button } from "@progress/kendo-react-buttons";
import { StackLayout } from "@progress/kendo-react-layout";
import { FieldWrapper, Form } from "@progress/kendo-react-form";
import DashboardTabApi from "./service";
import { Error, Label } from "@progress/kendo-react-labels";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Input, NumericTextBox, Switch } from "@progress/kendo-react-inputs";
import CustomDropdown from "src/components/customDropdown";
import { Tooltip } from "@progress/kendo-react-tooltip";
import MultiSelectDropdown from "src/components/multiSelect";
import MessageSchema from "src/utils/messageSchema";
import {
    endDateValidator,
    requiredValidator,
    startDateValidator,
} from "src/components/form-validators";
import { getUserCredential } from "src/lib/cookies";
import { chartThemeConfig } from "src/components/chart/chart.const";
import { transformDateTime } from "src/lib/date-time.util";
import { filterBy } from "@progress/kendo-data-query";

export const AddDashboardTabDialog = ({ closeDialog, isEditForm }) => {
    const cookiesData = getUserCredential();
    const themeData = chartThemeConfig;

    const [initialWidth, setInitialWidth] = useState();
    const [initialHeight, setInitialHeight] = useState();
    const [errorObj, setErrorObj] = useState({});
    const [modal, setModal] = useState(true);
    const [stageChange, setStateChange] = useState("DEFAULT");
    const [isRoleAccessDisabled, setIsRoleAccessDisabled] = useState(true);

    const [list, Action] = DashboardTabApi(closeDialog, isEditForm);

    const {
        defaultSelectAllOption,
        defaultSelfOption,
        roleAccessDataFields,
        setRoleAccessDataFields,
        bgAccessDataFields,
        setBgAccessDataFields,
        compPlanDataFields,
        setCompPlanDataFields,
        groupFilterFields,
        setGroupFilterFields,
        specialtyFilterFields,
        setSpecialtyFilterFields,
        providerFilterFields,
        setProviderFilterFields,
        activeDashboardPod,
        originRoleAccessData,
        originBgAccessData,
        originCompPlanAccessData,
        originGroupFilterData,
        originSpecialtyFilterData,
        originProviderFilterData,
        mapAndJoinId,
    } = list;

    const initialValue = {
        periodStart: null,
        periodEnd: null,
        labelName: activeDashboardPod?.label_name || "",
        theme: activeDashboardPod?.theme
            ? themeData.find((o) => o.value === activeDashboardPod.theme)
            : themeData[0],
        tabOrder:
            activeDashboardPod?.tab_order >= 0
                ? activeDashboardPod.tab_order
                : null,
        isPublished: activeDashboardPod?.is_report_published || false,
    };

    const [formFields, setFormFields] = useState({ ...initialValue });

    useEffect(async () => {
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;

        const newInitialWidth = Math.min(screenWidth * 0.85, 1000);
        let newInitialHeight = Math.min(screenHeight * 0.85, 1000);

        if (cookiesData?.out_db_role_type_abbr !== "adm1") {
            newInitialHeight -= 110;
        }

        setInitialWidth(newInitialWidth);
        setInitialHeight(newInitialHeight);

        if (isEditForm) {
            setFormFields({
                ...formFields,
                periodStart: parseDateString(
                    activeDashboardPod?.period_start_date
                ),
                periodEnd: parseDateString(activeDashboardPod?.period_end_date),
            });
        }

        await Action.getRoleAccessList();
        await Action.getBusinessGroupList();

        if (cookiesData?.out_db_role_type_abbr === "adm1") {
            await Action.getCompPlanList();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!roleAccessDataFields.isDataLoading) {
            let isDisable = true;
            if (isEditForm) {
                if (activeDashboardPod?.pod_count === 0) {
                    isDisable = false;
                }
            } else {
                isDisable = false;
            }

            !isDisable && setIsRoleAccessDisabled(isDisable);
        }
    }, [roleAccessDataFields.isDataLoading]);

    //refresh group dropdown after role, bg, comp plan refresh
    useEffect(() => {
        const isRoleRefresh = roleAccessDataFields.isDataRefresh;
        const isBgRefresh = bgAccessDataFields.isDataRefresh;
        const isCompPlanRefresh = compPlanDataFields.isDataRefresh;

        if (isRoleRefresh && isBgRefresh && isCompPlanRefresh) {
            setRoleAccessDataFields({
                ...roleAccessDataFields,
                isDataRefresh: false,
            });
            setBgAccessDataFields({
                ...bgAccessDataFields,
                isDataRefresh: false,
                isDisabled: toggleBgAccess(roleAccessDataFields.roleAccess),
            });
            setCompPlanDataFields({
                ...compPlanDataFields,
                isDataRefresh: false,
                isDisabled: togglePlangAccess(roleAccessDataFields.roleAccess),
            });

            Action.getGroupFilterList();
        }
    }, [
        roleAccessDataFields.isDataRefresh,
        bgAccessDataFields.isDataRefresh,
        compPlanDataFields.isDataRefresh,
    ]);

    //refresh specialty dropdown after group refresh
    useEffect(() => {
        if (groupFilterFields.isDataRefresh) {
            Action.getSpecialtyFilterList();

            setGroupFilterFields({
                ...groupFilterFields,
                isDataRefresh: false,
            });
        }
    }, [groupFilterFields.isDataRefresh]);

    //refresh provider dropdown after specialty refresh
    useEffect(() => {
        if (specialtyFilterFields.isDataRefresh) {
            Action.getProviderFilterList();

            setSpecialtyFilterFields({
                ...specialtyFilterFields,
                isDataRefresh: false,
            });
        }
    }, [specialtyFilterFields.isDataRefresh]);

    //refresh group dropdown after role access change event
    useEffect(() => {
        if (roleAccessDataFields.isDataChanged) {
            Action.getGroupFilterList();

            setRoleAccessDataFields({
                ...roleAccessDataFields,
                isDataChanged: false,
            });
        }
    }, [roleAccessDataFields.isDataChanged]);

    //refresh group dropdown after BG access change event
    useEffect(() => {
        if (bgAccessDataFields.isDataChanged) {
            Action.getGroupFilterList();

            setBgAccessDataFields({
                ...bgAccessDataFields,
                isDataChanged: false,
            });
        }
    }, [bgAccessDataFields.isDataChanged]);

    //refresh group dropdown after BG access change event
    useEffect(() => {
        if (compPlanDataFields.isDataChanged) {
            Action.getGroupFilterList();

            setCompPlanDataFields({
                ...compPlanDataFields,
                isDataChanged: false,
            });
        }
    }, [compPlanDataFields.isDataChanged]);

    //refresh specialty dropdown after group change event
    useEffect(() => {
        if (groupFilterFields.isDataChanged) {
            Action.getSpecialtyFilterList();

            setGroupFilterFields({
                ...groupFilterFields,
                isDataChanged: false,
            });
        }
    }, [groupFilterFields.isDataChanged]);

    //refresh provider dropdown after specialty change event
    useEffect(() => {
        if (specialtyFilterFields.isDataChanged) {
            Action.getProviderFilterList();

            setSpecialtyFilterFields({
                ...specialtyFilterFields,
                isDataChanged: false,
            });
        }
    }, [specialtyFilterFields.isDataChanged]);

    const focusRef = useRef(null);

    const parseDateString = (thisDate) => {
        return transformDateTime({
            date: thisDate,
            includeTime: true,
            defaultTime: true,
            returnDate: true,
        });
    };

    const handleValidation = () => {
        let isValid = true;
        let errObj = {};

        if (formFields?.periodStart === null) {
            isValid = false;
            errObj.periodStart = requiredValidator(formFields?.periodStart);
        } else {
            const validationMessage = startDateValidator(
                formFields?.periodStart,
                formFields?.periodEnd
            );
            if (validationMessage) {
                isValid = false;
                errObj.periodStart = validationMessage;
            }
        }

        if (formFields?.periodEnd === null) {
            isValid = false;
            errObj.periodEnd = requiredValidator(formFields?.periodEnd);
        } else {
            const validationMessage = endDateValidator(
                formFields?.periodStart,
                formFields?.periodEnd
            );
            if (validationMessage) {
                isValid = false;
                errObj.periodEnd = validationMessage;
            }
        }

        if (formFields?.labelName?.trim() === "") {
            isValid = false;
            errObj.labelName = MessageSchema.labelnameRequired;
        }

        if (cookiesData?.out_db_role_type_abbr !== "bgm1") {
            const businessGroupAccess = mapAndJoinId(
                bgAccessDataFields?.businessGroup || []
            );
            if (businessGroupAccess === null) {
                isValid = false;
                errObj.businessGroup = MessageSchema.businessGroupRequired;
            }

            const compPlanAccess = mapAndJoinId(
                compPlanDataFields?.compPlan || []
            );
            if (compPlanAccess === null) {
                isValid = false;
                errObj.compPlan = MessageSchema.compPlanRequired;
            }
        }

        const groupFilter = mapAndJoinId(groupFilterFields?.groupFilter || []);
        if (groupFilter === null) {
            isValid = false;
            errObj.groupFilter = MessageSchema.groupFilterRequired;
        }

        const specialtyFilter = mapAndJoinId(
            specialtyFilterFields?.specialtyFilter || []
        );
        if (specialtyFilter === null) {
            isValid = false;
            errObj.specialtyFilter = MessageSchema.specialtyFilterRequired;
        }

        const providerFilter = mapAndJoinId(
            providerFilterFields?.providerFilter || []
        );
        if (providerFilter === null) {
            isValid = false;
            errObj.providerFilter = MessageSchema.providerFilterRequired;
        }

        setErrorObj({ ...errObj });
        return isValid;
    };

    const handleDashboardApiCall = async () => {
        const isValid = handleValidation();
        if (isValid) {
            Action.onSubmit.mutate({ formFields, isEditForm });
        }
    };

    const ShowError = useCallback(
        (props) => {
            return <Error>{errorObj[props.name]}</Error>;
        },
        [errorObj]
    );

    const handleFieldChange = (e) => {
        const name = e?.name ? e?.name : e?.target?.name;
        const value = e?.value;
        if (value !== null) {
            const updatedFormFields = {
                ...formFields,
                [name]: value,
            };

            setFormFields(updatedFormFields);

            const errObj = { ...errorObj, [name]: "" };

            // Validate the start date
            if (name === "periodStart" && updatedFormFields?.periodEnd) {
                const validationMessage = startDateValidator(
                    value,
                    updatedFormFields.periodEnd
                );
                errObj.periodStart = validationMessage;
                if (!validationMessage) {
                    errObj.periodEnd = ""; // Clear any related errors if validation passes
                }
            }

            // Validate the end date
            if (name === "periodEnd" && updatedFormFields?.periodStart) {
                const validationMessage = endDateValidator(
                    updatedFormFields.periodStart,
                    value
                );
                errObj.periodEnd = validationMessage;
                if (!validationMessage) {
                    errObj.periodStart = ""; // Clear any related errors if validation passes
                }
            }

            setErrorObj(errObj);
        }
    };

    const handleRoleFieldChange = (value, name) => {
        let updatedArray = value;
        let isAdminFind = undefined;
        let isSlefFind = undefined;

        if (value.length > 0) {
            const lastSelectedVal = value[value.length - 1];

            //remove self if last updated value is adm1
            if (lastSelectedVal.key === "adm1") {
                updatedArray = value.filter((item) => item.key !== "0");
            }

            //remove adm1 if last updated value is self
            if (lastSelectedVal.key === "0") {
                updatedArray = value.filter((item) => item.key !== "adm1");
            }

            isAdminFind = updatedArray.find((o) => o.key === "adm1");
            isSlefFind = updatedArray.find((o) => o.key === "0");

            enableFilterLoading();

            setBgAccessDataFields({
                ...bgAccessDataFields,
                isDisabled: toggleBgAccess(updatedArray),
            });

            setCompPlanDataFields({
                ...compPlanDataFields,
                isDisabled: togglePlangAccess(updatedArray),
            });
        }

        //set self option when admin or self not found from updated value list
        if (!isAdminFind && !isSlefFind) {
            updatedArray = [defaultSelfOption, ...value];
        }

        setRoleAccessDataFields({
            ...roleAccessDataFields,
            [name]: updatedArray,
            isDataChanged: true,
        });
    };

    const toggleBgAccess = (roleAccess) => {
        if (isEditForm && activeDashboardPod?.pod_count > 0) {
            return true;
        }

        return roleAccess.find((o) => o.key === "bgm1") ? false : true;
    };

    const togglePlangAccess = (roleAccess) => {
        if (isEditForm && activeDashboardPod?.pod_count > 0) {
            return true;
        }

        return roleAccess.find((o) => o.key === "prv1") ? false : true;
    };

    const enableFilterLoading = (group = true, specialty = true) => {
        group &&
            setGroupFilterFields({ ...groupFilterFields, isDataLoading: true });
        specialty &&
            setSpecialtyFilterFields({
                ...specialtyFilterFields,
                isDataLoading: true,
            });
        setProviderFilterFields({
            ...providerFilterFields,
            isDataLoading: true,
        });
    };

    const renderDropdownItem = (li, itemProps) => {
        const { text, value } = itemProps.dataItem;

        const handleClick = (e) => {
            const event = {};
            event.name = "theme";
            event.value = itemProps.dataItem;
            handleFieldChange(event);
            focusRef.current.focus();
        };

        return (
            <li
                onClick={handleClick}
                className={itemProps.selected ? "k-state-selected" : ""}
            >
                <span className="k-input">
                    <span
                        className={`mr4 tc-color theme-${value}-color-1`}
                    ></span>
                    <span
                        className={`mr4 tc-color theme-${value}-color-2`}
                    ></span>
                    <span
                        className={`mr4 tc-color theme-${value}-color-3`}
                    ></span>
                    <span className="tc-name">{text}</span>
                </span>
            </li>
        );
    };

    const renderForm = () => (
        <Form
            initialValues={initialValue || {}}
            render={() => (
                <StackLayout
                    orientation="vertical"
                    gap={15}
                    className="form-container"
                >
                    <div className="dashboard-tab-scroll">
                        <StackLayout
                            orientation="vertical"
                            gap={15}
                            className="form-body dashboardTabForm"
                        >
                            <h3 className="adddashboardh3">
                                <span>Tab Attributes</span>
                            </h3>
                            <StackLayout orientation="vertical" gap={15}>
                                <FieldWrapper>
                                    <StackLayout gap={15}>
                                        <div
                                            className={
                                                "k-form-field-wrap-popup"
                                            }
                                        >
                                            <Label>{"Period Start"}</Label>
                                            <div
                                                className={"k-form-field-wrap"}
                                            >
                                                <DatePicker
                                                    value={
                                                        formFields.periodStart
                                                    }
                                                    name="periodStart"
                                                    format="yyyy-MM-dd"
                                                    onChange={handleFieldChange}
                                                    formatPlaceholder={{
                                                        year: "yyyy",
                                                        month: "mm",
                                                        day: "dd",
                                                    }}
                                                    placeholder="yyyy-mm-dd"
                                                />
                                            </div>
                                            <ShowError name={"periodStart"} />
                                        </div>
                                        <div
                                            className={
                                                "k-form-field-wrap-popup"
                                            }
                                        >
                                            <Label>{"Period End"}</Label>
                                            <div
                                                className={"k-form-field-wrap"}
                                            >
                                                <DatePicker
                                                    value={formFields.periodEnd}
                                                    name="periodEnd"
                                                    format="yyyy-MM-dd"
                                                    onChange={handleFieldChange}
                                                    formatPlaceholder={{
                                                        year: "yyyy",
                                                        month: "mm",
                                                        day: "dd",
                                                    }}
                                                    placeholder="yyyy-mm-dd"
                                                />
                                            </div>
                                            <ShowError name={"periodEnd"} />
                                        </div>
                                    </StackLayout>
                                </FieldWrapper>

                                <FieldWrapper>
                                    <StackLayout gap={15}>
                                        <div
                                            className={
                                                "k-form-field-wrap-popup"
                                            }
                                        >
                                            <Label>{"Label Name"}</Label>
                                            <Input
                                                type={"text"}
                                                name="labelName"
                                                value={formFields.labelName}
                                                onChange={handleFieldChange}
                                            />
                                            <ShowError name={"labelName"} />
                                        </div>
                                        <div
                                            className={
                                                "k-form-field-wrap-popup"
                                            }
                                        >
                                            <Label>{"Theme"}</Label>
                                            <CustomDropdown
                                                name={"theme"}
                                                data={themeData}
                                                textField="text"
                                                dataItemKey="value"
                                                itemRender={renderDropdownItem}
                                                valueData={themeData.find(
                                                    (c) =>
                                                        c.text ===
                                                        formFields?.theme?.text
                                                )}
                                                filterable={false}
                                                autoClose={true}
                                                autoBind={true}
                                                dataList={themeData}
                                            />
                                        </div>
                                    </StackLayout>
                                </FieldWrapper>

                                <FieldWrapper>
                                    <StackLayout gap={15}>
                                        <div
                                            className={
                                                "k-form-field-wrap-popup"
                                            }
                                        >
                                            <Label>{"Tab Order"}</Label>
                                            <NumericTextBox
                                                ref={focusRef}
                                                name={"tabOrder"}
                                                value={formFields?.tabOrder}
                                                onChange={handleFieldChange}
                                            />
                                        </div>
                                        {cookiesData?.out_db_role_type_abbr !==
                                            "bgm1" && (
                                            <div
                                                className={
                                                    "k-form-field-wrap-popup"
                                                }
                                            >
                                                <div>
                                                    <Label>
                                                        {"Current/Published"}
                                                    </Label>
                                                </div>
                                                <div>
                                                    <Switch
                                                        id={"isPublished"}
                                                        name={"isPublished"}
                                                        onLabel={"Published"}
                                                        offLabel={"Current"}
                                                        onChange={
                                                            handleFieldChange
                                                        }
                                                        checked={
                                                            formFields?.isPublished
                                                                ? true
                                                                : false
                                                        }
                                                        className={"k-switch"}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </StackLayout>
                                </FieldWrapper>
                            </StackLayout>

                            {cookiesData?.out_db_role_type_abbr !== "bgm1" && (
                                <>
                                    <h3 className="adddashboardh3">
                                        <span>Tab Access Settings</span>
                                        {isEditForm &&
                                        activeDashboardPod?.pod_count > 0 ? (
                                            <div className="tab-access-disable">
                                                <Tooltip
                                                    anchorElement="target"
                                                    position="bottom"
                                                    openDelay={100}
                                                >
                                                    <span
                                                        style={{
                                                            cursor: "help",
                                                            color: "#00A1E4",
                                                            borderBottom: 0,
                                                        }}
                                                        title="Role access cannot be changed once dashpods have been created and saved."
                                                    >
                                                        Why is this disabled?
                                                    </span>
                                                </Tooltip>
                                            </div>
                                        ) : null}
                                    </h3>
                                    <StackLayout
                                        orientation="vertical"
                                        gap={15}
                                    >
                                        <div
                                            className={
                                                "k-form-field-wrap-popup valueDropdown"
                                            }
                                        >
                                            <Label>{"Role Access"}</Label>
                                            <MultiSelectDropdown
                                                name={"roleAccess"}
                                                module="roleAccess"
                                                data={
                                                    roleAccessDataFields.dropdownData
                                                }
                                                textField="text"
                                                onOpen={(data) => {
                                                    setRoleAccessDataFields({
                                                        ...roleAccessDataFields,
                                                        dropdownData: data,
                                                    });
                                                }}
                                                dataItemKey="id"
                                                valueData={
                                                    roleAccessDataFields?.roleAccess
                                                }
                                                onChange={(data, name) => {
                                                    handleRoleFieldChange(
                                                        data,
                                                        name
                                                    );
                                                }}
                                                filterable={true}
                                                onFilterChange={(dataValue) => {
                                                    setRoleAccessDataFields({
                                                        ...roleAccessDataFields,
                                                        dropdownData: dataValue,
                                                    });
                                                }}
                                                autoClose={false}
                                                autoBind={false}
                                                isSelectALl={false}
                                                dataList={originRoleAccessData}
                                                loading={
                                                    roleAccessDataFields.isDataLoading
                                                }
                                                disabled={isRoleAccessDisabled}
                                            />
                                        </div>

                                        <div
                                            className={
                                                "k-form-field-wrap-popup valueDropdown"
                                            }
                                        >
                                            <Label>{"Business Group"}</Label>
                                            <MultiSelectDropdown
                                                name={"businessGroup"}
                                                module="businessGroup"
                                                data={
                                                    bgAccessDataFields.dropdownData
                                                }
                                                textField="text"
                                                onOpen={(data) => {
                                                    setBgAccessDataFields({
                                                        ...bgAccessDataFields,
                                                        dropdownData: data,
                                                    });
                                                }}
                                                dataItemKey="id"
                                                valueData={
                                                    bgAccessDataFields?.businessGroup
                                                }
                                                onChange={(data, name) => {
                                                    setBgAccessDataFields({
                                                        ...bgAccessDataFields,
                                                        isDataChanged: true,
                                                        [name]: data,
                                                    });
                                                    enableFilterLoading();
                                                }}
                                                filterable={true}
                                                onFilterChange={(dataValue) => {
                                                    setBgAccessDataFields({
                                                        ...bgAccessDataFields,
                                                        dropdownData: dataValue,
                                                    });
                                                }}
                                                autoClose={false}
                                                autoBind={false}
                                                isSelectALl={true}
                                                selectAllId={"0"}
                                                dataList={originBgAccessData}
                                                loading={
                                                    bgAccessDataFields.isDataLoading
                                                }
                                                disabled={
                                                    bgAccessDataFields.isDisabled
                                                }
                                            />
                                            <ShowError name={"businessGroup"} />
                                        </div>

                                        <div
                                            className={
                                                "k-form-field-wrap-popup valueDropdown"
                                            }
                                        >
                                            <Label>{"Comp Plan"}</Label>
                                            <MultiSelectDropdown
                                                name={"compPlan"}
                                                module="compPlan"
                                                data={
                                                    compPlanDataFields.dropdownData
                                                }
                                                textField="text"
                                                onOpen={(data) => {
                                                    setCompPlanDataFields({
                                                        ...compPlanDataFields,
                                                        dropdownData: data,
                                                    });
                                                }}
                                                dataItemKey="id"
                                                valueData={
                                                    compPlanDataFields?.compPlan
                                                }
                                                onChange={(data, name) => {
                                                    setCompPlanDataFields({
                                                        ...compPlanDataFields,
                                                        isDataChanged: true,
                                                        [name]: data,
                                                    });
                                                    enableFilterLoading();
                                                }}
                                                filterable={true}
                                                onFilterChange={(dataValue) => {
                                                    setCompPlanDataFields({
                                                        ...compPlanDataFields,
                                                        dropdownData: dataValue,
                                                    });
                                                }}
                                                autoClose={false}
                                                autoBind={false}
                                                isSelectALl={true}
                                                selectAllId={"0"}
                                                dataList={
                                                    originCompPlanAccessData
                                                }
                                                loading={
                                                    compPlanDataFields.isDataLoading
                                                }
                                                disabled={
                                                    compPlanDataFields.isDisabled
                                                }
                                            />
                                            <ShowError name={"compPlan"} />
                                        </div>
                                    </StackLayout>
                                </>
                            )}

                            <h3 className="adddashboardh3">
                                <span>Tab Filters</span>
                            </h3>
                            <StackLayout orientation="vertical" gap={15}>
                                <div
                                    className={
                                        "k-form-field-wrap-popup valueDropdown"
                                    }
                                >
                                    <Label>{"Group Filter"}</Label>
                                    <MultiSelectDropdown
                                        name={"groupFilter"}
                                        module="groupFilter"
                                        data={groupFilterFields.dropdownData}
                                        textField="text"
                                        onOpen={(data) => {
                                            setGroupFilterFields({
                                                ...groupFilterFields,
                                                dropdownData: data,
                                            });
                                        }}
                                        dataItemKey="id"
                                        valueData={
                                            groupFilterFields?.groupFilter
                                        }
                                        onChange={(data, name) => {
                                            setGroupFilterFields({
                                                ...groupFilterFields,
                                                isDataChanged: true,
                                                [name]: data,
                                            });
                                            enableFilterLoading(false);
                                        }}
                                        filterable={true}
                                        onFilterChange={(dataValue) => {
                                            setGroupFilterFields({
                                                ...groupFilterFields,
                                                dropdownData: dataValue,
                                            });
                                        }}
                                        autoClose={false}
                                        autoBind={false}
                                        isSelectALl={true}
                                        selectAllId={"0"}
                                        dataList={originGroupFilterData}
                                        loading={
                                            groupFilterFields.isDataLoading
                                        }
                                        disabled={
                                            groupFilterFields.isDataLoading
                                        }
                                    />
                                    <ShowError name={"groupFilter"} />
                                </div>

                                <div
                                    className={
                                        "k-form-field-wrap-popup valueDropdown"
                                    }
                                >
                                    <Label>{"Specialty Filter"}</Label>
                                    <MultiSelectDropdown
                                        name={"specialtyFilter"}
                                        module="specialtyFilter"
                                        data={
                                            specialtyFilterFields.dropdownData
                                        }
                                        textField="text"
                                        onOpen={(data) => {
                                            setSpecialtyFilterFields({
                                                ...specialtyFilterFields,
                                                dropdownData: data,
                                            });
                                        }}
                                        dataItemKey="id"
                                        valueData={
                                            specialtyFilterFields?.specialtyFilter
                                        }
                                        onChange={(data, name) => {
                                            setSpecialtyFilterFields({
                                                ...specialtyFilterFields,
                                                isDataChanged: true,
                                                [name]: data,
                                            });
                                            enableFilterLoading(false, false);
                                        }}
                                        filterable={true}
                                        onFilterChange={(dataValue) => {
                                            setSpecialtyFilterFields({
                                                ...specialtyFilterFields,
                                                dropdownData: dataValue,
                                            });
                                        }}
                                        autoClose={false}
                                        autoBind={false}
                                        isSelectALl={true}
                                        selectAllId={"0"}
                                        dataList={originSpecialtyFilterData}
                                        loading={
                                            specialtyFilterFields.isDataLoading
                                        }
                                        disabled={
                                            specialtyFilterFields.isDataLoading
                                        }
                                    />
                                    <ShowError name={"specialtyFilter"} />
                                </div>

                                <div
                                    className={
                                        "k-form-field-wrap-popup valueDropdown"
                                    }
                                >
                                    <Label>{"Provider Filter"}</Label>
                                    <MultiSelectDropdown
                                        name={"providerFilter"}
                                        module="prooviderFilter"
                                        data={providerFilterFields.dropdownData}
                                        textField="text"
                                        onOpen={(data) => {
                                            setProviderFilterFields({
                                                ...providerFilterFields,
                                                dropdownData: data,
                                            });
                                        }}
                                        dataItemKey="id"
                                        valueData={
                                            providerFilterFields?.providerFilter
                                        }
                                        onChange={(data, name) => {
                                            setProviderFilterFields({
                                                ...providerFilterFields,
                                                isDataChanged: true,
                                                [name]: data,
                                            });
                                        }}
                                        filterable={true}
                                        onFilterChange={(dataValue) => {
                                            setProviderFilterFields({
                                                ...providerFilterFields,
                                                dropdownData: dataValue,
                                            });
                                        }}
                                        autoClose={false}
                                        autoBind={false}
                                        isSelectALl={true}
                                        selectAllId={"0"}
                                        dataList={originProviderFilterData}
                                        loading={
                                            providerFilterFields.isDataLoading
                                        }
                                        disabled={
                                            providerFilterFields.isDataLoading
                                        }
                                    />
                                    <ShowError name={"providerFilter"} />
                                </div>
                            </StackLayout>
                        </StackLayout>
                    </div>

                    <div className="form-action">
                        <WindowActionsBar layout="end">
                            <Button
                                type="submit"
                                className="cancelbtn"
                                onClick={closeDialog}
                                disabled={Action?.onSubmit?.isLoading}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                onClick={() => handleDashboardApiCall()}
                                className="primarybtn"
                                disabled={Action?.onSubmit?.isLoading}
                            >
                                Submit
                            </Button>
                        </WindowActionsBar>
                    </div>
                </StackLayout>
            )}
        />
    );

    const handleStageChange = (e) => {
        setStateChange(e.state);
        if (e.state === "MINIMIZED") {
            setModal(false);
        } else if (e.state === "DEFAULT") {
            setModal(true);
        }
    };

    return (
        <>
            {false ? (
                <LoadingPanel />
            ) : (
                <>
                    {initialHeight !== undefined &&
                        initialWidth !== undefined && (
                            <Window
                                title={`${
                                    isEditForm ? "Edit" : "Add"
                                } Dashboard Tab`}
                                initialHeight={initialHeight}
                                initialWidth={initialWidth}
                                onClose={closeDialog}
                                resizable={false}
                                className="dashboard-tab-wrapper"
                                stage={stageChange}
                                onStageChange={handleStageChange}
                                modal={modal}
                            >
                                {Action.onSubmit?.isLoading ? (
                                    <LoadingPanel />
                                ) : (
                                    <div className="dashboard-tab-container">
                                        {renderForm()}
                                    </div>
                                )}
                            </Window>
                        )}
                </>
            )}
        </>
    );
};

AddDashboardTabDialog.propTypes = {
    closeDialog: PropTypes.func.isRequired,
};
