import React from "react";
import { getUserCredential } from "../../lib/cookies";
import {
    errStatusHandler,
    generateHeader,
    POSTAPI,
} from "../../api-setup/api-helper";
import { GridColumnDataMapper } from "../../utils/utils";
import { USER_GRID_DEFAULT_PAGE_SIZE } from "../../constants/grid";
import { useDispatch } from "react-redux";
import { setNewMessage } from "../../redux/actions/setting-actions";
import {
    SELECT_CUSTOM_FIELDS,
    INSERT_CUSTOM_FIELDS,
    DELETE_CUSTOM_FIELDS,
    UPDATE_CUSTOM_FIELDS,
    SELECT_CUSTOM_FIELDS_MASTER,
} from "../../api-setup/api-endpoints";
import { useHistory } from "react-router-dom";
import { GetDataTypes, GetUnitType } from "../../lib/common-service";

const initialDataState = {
    skip: 0,
    take: USER_GRID_DEFAULT_PAGE_SIZE,
};
let defaultItem = {
    text: USER_GRID_DEFAULT_PAGE_SIZE,
    id: 2,
};

export default function Events() {
    const history = useHistory();
    const cookiesData = getUserCredential();
    const header = {
        "x-access-token": cookiesData.secretToken,
    };
    const dispatch = useDispatch();
    const [data, setData] = React.useState([]);
    const [columns, setColumns] = React.useState([]);
    const [dataCount, setDataCount] = React.useState(0);
    const [isColumnMenuSearch, setIsColumnMenuSearch] = React.useState(false);
    const [paginationData, setPaginationData] = React.useState({
        pageData: 0,
        rows: 10,
    });
    const [initialLoading, setLoadingState] = React.useState(false);
    const [pageData, setPage] = React.useState(initialDataState);
    const [page, setPageData] = React.useState(0);
    const [editData, setEditData] = React.useState("");
    const [pageSize, setPageSize] = React.useState(defaultItem);
    const [isAPICalling, setAPICallingState] = React.useState(false);
    const [group, setGroup] = React.useState([]);
    const [resultState, setResultState] = React.useState([]);
    const [collapsedState, setCollapsedState] = React.useState([]);
    const [originalData, setOriginalData] = React.useState([]);
    const [visible, setVisible] = React.useState(false);


    const [addUnitTypeFields, setAddUnitTypeFields] = React.useState({
        unitTypeValue: {
            id: "",
            text: "",
        },
        unitTypeData: [],
    });
    const [addDataTypeFields, setAddDataTypeFields] = React.useState({
        dataTypeValue: {
            id: "",
            text: "",
        },
        dataTypeList: [],
    });

    const [addBaseTableFields, setAddBaseTableFields] = React.useState({
        baseTableValue: {
            id: "",
            text: "",
        },
        baseTableList: [],
    });
    const [noDataFound, setNoDataFound] = React.useState(false);
    const [isRefreshLoading, setRefreshLoading] = React.useState(false);
    const [loadingState, setLoadState] = React.useState({
        isSaveData: undefined,
        isDeleteData: undefined,
        initLoading : true
    });
    const [unitTypeDataSource, setUnitTypeDataSource] = React.useState([]);
    let newData = [];
    let processedGroupData = [];
    const [fileData, setFileData] = React.useState({});
    const [confirmationPopup, setConfirmationPopup] = React.useState({
        title: "Delete",
        desc: "Are you sure you want to delete this record?",
        isVisible: false,
        type: "",
        actionBtn: [],
        data: {},
        action: "Delete",
    });
    const [dataTypeSource, setDataTypeSource] = React.useState([]);
    const [customFieldMasterSource, setCustomFieldsMasterSource] =
        React.useState([]);

    const getDataList = (listData) => {
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                SELECT_CUSTOM_FIELDS,
                null,
                header,
                listData,
                dispatch
            ).then((res) => {
                if (res) {
                    if (res.data.out_parameters.out_ret_code > 0) {
                        setLoadingState(true);
                        errStatusHandler(res, history, dispatch);
                    }
                    setLoadingState(true);
                    setAPICallingState(false);
                    if (listData?.in_load_type === "grid-reload") {
                        const columData = GridColumnDataMapper(
                            res.data.out_parameters.out_columns_xml
                        );
                        setColumns(columData);
                    }
                    res.data.row_result.map((item, idx) => {
                        item.rowId = idx;
                        return 0;
                    });

                    setData(res.data.row_result);
                    setDataCount(res.data.out_parameters.out_total_rows);
                    setOriginalData(res.data.row_result);
                    setIsColumnMenuSearch(false);
                    setNoDataFound(false);
                    setRefreshLoading(false);
                    resolve(res.data.row_result);
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    const addDataAsync = (bodyData, data) => {
        setRefreshLoading(true);
        setLoadState({ ...loadingState, isSaveData: false });
        setAPICallingState(true);
        POSTAPI(
            false,
            INSERT_CUSTOM_FIELDS,
            null,
            header,
            bodyData,
            dispatch
        ).then((res) => {
            setRefreshLoading(false);
            if (res.status) {
                if (res.data.out_parameters.out_ret_code > 0) {
                    setAPICallingState(false);
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                    //setresponsecode(res.data.out_parameters.out_ret_code);
                    //setresponsemsg(res.data.out_parameters.out_error_msg);
                } else {
                    setLoadState({ ...loadingState, isSaveData: true });
                    setAPICallingState(false);
                }
            }
        });
    };

    const deleteDataAsync = async (bodyData) => {
        setRefreshLoading(true);
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                DELETE_CUSTOM_FIELDS,
                null,
                header,
                bodyData,
                dispatch
            ).then((res) => {
                const totalCustomFields = bodyData.in_custom_fld_col_mstr_key.length;
                const unSuccessFullCount = res.data.successCount;

                setRefreshLoading(false);
                if (res.data.out_parameters.out_ret_code > 0) {
                    let msg = res.data.out_parameters.out_error_msg;
                    if (totalCustomFields !== unSuccessFullCount) {
                        msg =
                            unSuccessFullCount +
                            " Custom Fields (" +
                            res.data.successName +
                            ") out of " +
                            totalCustomFields +
                            " as " +
                            msg;

                        setLoadState({ ...loadingState, isDeleteData: true });
                        setTimeout(function () {
                            resolve("success");
                        });
                    } else {
                        setLoadState({ ...loadingState, isDeleteData: false });
                        setTimeout(function () {
                            resolve("error");
                        });
                    }

                    setConfirmationPopup({
                        ...confirmationPopup,
                        isVisible: false,
                        type: "",
                        actionBtn: [],
                        data: {},
                        action: "",
                    });
                    dispatch(
                        setNewMessage({
                            message: msg,
                            type: "Error",
                            show: true,
                        })
                    );
                } else {
                    setConfirmationPopup({
                        ...confirmationPopup,
                        isVisible: false,
                        type: "",
                        actionBtn: [],
                        data: {},
                        action: "",
                    });
                    setLoadState({ ...loadingState, isDeleteData: true });
                    setTimeout(function () {
                        resolve("success");
                    });
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    const updateDataAsync = (bodyData) => {
        setRefreshLoading(true);
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                UPDATE_CUSTOM_FIELDS,
                null,
                header,
                bodyData,
                dispatch
            ).then((res) => {
                setRefreshLoading(false);
                if (res && res.data.out_parameters.out_ret_code === 0) {
                    resolve(true);
                } else {
                    dispatch(
                        setNewMessage({
                            message: res?.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    resolve([]);
                    errStatusHandler(res, history, dispatch);
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    const getUnitTypeData = async () => {
        let unitTypeDataReq = {
            in_tenant_id: cookiesData.out_tenant_id,
            in_filter: " AND (a.system_unit_name NOT LIKE 'fdm_%')",
            in_sort_number: null,
            in_sort_order: null,
            in_page_row_offset: 0,
            in_page_rows: 9999,
        };

        let resUnitType = await GetUnitType(unitTypeDataReq, dispatch, history);
        if (resUnitType.length > 0) {
            let tempArray = [];

            resUnitType.map((item) => {
                let obj = {};

                obj.text = item.system_unit_name;
                obj.id = item.system_unit_mstr_key;

                tempArray.push(obj);

                return 0;
            });

            setUnitTypeDataSource(tempArray);
            setAddUnitTypeFields({
                ...addUnitTypeFields,
                unitTypeData: tempArray,
            });
        }
    };

    const getDataTypeList = async () => {
        let reqData = {
            in_filter: null,
            in_sort_number: 2,
            in_sort_order: "ASC",
        };

        let res = await GetDataTypes(reqData, dispatch, history);
        if (res.length > 0) {
            let tempArray = [];

            res.map((item) => {
                let obj = {};

                obj.text = item.data_type_name;
                obj.id = item.data_type_mstr_key;

                tempArray.push(obj);

                return 0;
            });
            setDataTypeSource(tempArray);
            setAddDataTypeFields({
                dataTypeList: tempArray,
                dataTypeValue: tempArray?.find(
                    (x) => x.text.toLowerCase() === "boolean"
                ),
            });
        }
    };

    const getCustomFieldsMaster = (data, dispatch, history) => {
        POSTAPI(
            false,
            SELECT_CUSTOM_FIELDS_MASTER,
            null,
            generateHeader(),
            data,
            dispatch
        ).then((res) => {
            if (
                res &&
                (res.data.out_parameters.out_ret_code === 0 ||
                    res.data.out_parameters.out_ret_code === 2)
            ) {
                if (res.data.row_result.length > 0) {
                    let baseTableData = res.data.row_result;
                    let tempArray = [];

                    if (history.location.pathname === "/events") {
                        baseTableData = baseTableData.filter(
                            (x) =>
                                x.custom_fld_table_name !==
                                "vw_provider_master" &&
                                x.custom_fld_table_name !== "vw_org_position"
                        );
                    } else {
                        baseTableData = baseTableData.filter(
                            (x) =>
                                x.custom_fld_table_name ===
                                "vw_provider_master" ||
                                x.custom_fld_table_name === "vw_org_position"
                        );
                    }

                    tempArray = baseTableData.map((item) => ({
                        text: item.custom_fld_table_label,
                        id: item.custom_fld_mstr_key,
                        baseTable:item.custom_fld_table_name
                    }));
                    setCustomFieldsMasterSource(tempArray);
                    setAddBaseTableFields({
                        ...addBaseTableFields,
                        baseTableList: tempArray,
                        baseTableValue: tempArray[0],
                    });
                }
                setVisible(true);
            } else {
                setLoadState({
                    ...loadingState,
                    initLoading : false
                })
                setVisible(true);
                dispatch(
                    setNewMessage({
                        message: res?.data.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                errStatusHandler(res, history, dispatch);
            }
        });
    };

    return [
        {
            data,
            columns,
            dataCount,
            setData,
            setColumns,
            isColumnMenuSearch,
            pageData,
            editData,
            page,
            pageSize,
            isAPICalling,
            setEditData,
            initialLoading,
            setPage,
            setPageData,
            setPageSize,
            setPaginationData,
            setAPICallingState,
            paginationData,
            collapsedState,
            setCollapsedState,
            group,
            setResultState,
            setGroup,
            newData,
            originalData,
            resultState,
            processedGroupData,
            fileData,
            setFileData,
            visible,
            setVisible,
            setNoDataFound,
            noDataFound,
            setRefreshLoading,
            isRefreshLoading,
            loadingState,
            setLoadState,
            unitTypeDataSource,
            dataTypeSource,
            customFieldMasterSource,
            setAddUnitTypeFields,
            setAddDataTypeFields,
            setAddBaseTableFields,
            addBaseTableFields,
            addUnitTypeFields,
            addDataTypeFields,
        },
        {
            getDataList,
            addDataAsync,
            deleteDataAsync,
            updateDataAsync,
            getUnitTypeData,
            getDataTypeList,
            getCustomFieldsMaster,
        },
    ];
}
