import moment from "moment";
import {
  MULTIPLE_NUMBER_REGEX,
  MULTIPLE_STRING_REGEX,
  REGEX_FOR_DATE_IN,
  REGEX_FOR_LIKE_NUMBER,
  REGEX_FOR_LIKE_STRING,
} from "src/constants/constants";
import { transformDateTime } from "src/lib/date-time.util";

export const tinyIntValues = [
  { text: 0, value: "0" },
  { text: 1, value: "1" },
];

export const validateRuleItem = (rule) => {

  const inOperators = ["IN", "NOT IN", "IS NULL", "IS NOT NULL", "LIKE", "NOT LIKE"];

  const valueItem = rule.valueItem;
  const operator = rule.operatorItem && rule.operatorItem.value;

  if (!valueItem && (operator !== "IS NULL" || operator !== "IS NOT NULL"))
    return { error: true, errorMsg: "Values are not defined." };

  const inFilters = inOperators.includes(operator);

  if (valueItem) {
    if(valueItem.key == "system_fx" || valueItem.key === "result_name"){
      if (!valueItem.value.text) {
        return { error: true, errorMsg: "Value is required." };
      } 
    }
    if (valueItem.key === "number") {
      if (!valueItem.value) return { error: true, errorMsg: "Value is required." };

      if (inFilters) {
        if (
          (operator === "IN" || operator === "NOT IN") &&
          !MULTIPLE_NUMBER_REGEX.test(valueItem.value)
        ) {
          return { error: true, errorMsg: "Invalid multiple numbers" };
        } else if (
          (operator === "LIKE" || operator === "NOT LIKE") &&
          !REGEX_FOR_LIKE_NUMBER.test(valueItem.value)
        ) {
          return { error: true, errorMsg: "Value must be number with % symbol only." };
        }
      } else if (isNaN(valueItem.value) || valueItem.value === "") {
        return { error: true, errorMsg: "Please enter valid number." };
      }
    } else if (valueItem.key === "string") {
      if (!valueItem.value) return { error: true, errorMsg: "Value is required." };
      if (inFilters) {
        if (
          (operator === "IN" || operator === "NOT IN") &&
          !MULTIPLE_STRING_REGEX.test(valueItem.value)
        ) {
          return { error: true, errorMsg: "Invalid multiple string." };
        } else if (
          (operator === "LIKE" || operator === "NOT LIKE") &&
          !REGEX_FOR_LIKE_STRING.test(valueItem.value)
        ) {
          return { error: true, errorMsg: "Value must be string with % symbol only." };
        }
      } else if (valueItem.value.trim() === "") {
        return { error: true, errorMsg: "Please enter valid string." };
      }
    } else if (valueItem.key === "date") {
      if (!valueItem?.value) return { error: true, errorMsg: "Value is required." };
      if (inFilters) {
        if (operator === "IN" || operator === "NOT IN") {
          if (!REGEX_FOR_DATE_IN.test(valueItem.value)) {
            return {
              error: true,
              errorMsg: "Please enter proper valid dates enclosed between parentheses.",
            };
          } else {
            const datesList = valueItem.value.replace("(", "").replace(")", "").split(",");
            for (const dateItem of datesList) {
              const dateVal = moment(dateItem.trim(), "YYYY-MM-DD")?.isValid();
              if (!dateVal || dateVal === null) {
                return { error: true, errorMsg: "Some of date might not valid." };
              }
            }
          }
        }
      }
    }
  }

  return { error: false, errorMsg: "" };
};

function countParentheses(str) {
  let countOpen = 0;
  let countClose = 0;

  for (let i = 0; i < str.length; i++) {
    if (str[i] === "(") {
      countOpen++;
    } else if (str[i] === ")") {
      countClose++;
    }
  }

  return countOpen === countClose ? 0 : 1;
}

export const prepareRuleFilterText = (ruleItems) => {
  let str = "<condition>";
  let parenCounter = 0;
  const list = ruleItems.filter((it) => it.objectItem.value !== "System Fx");

  const getAttributeItem = (item) => {
    if (item.operatorItem.value === "IS NULL" || item.operatorItem.value === "IS NOT NULL") {
      return `NULLIF(${item.attributeItem.value}, '')`;
    }
    return item.attributeItem?.value?.trim();
  };

  list.forEach((it, index) => {
    if (it.valueItem.value === null) return;
    let val =
      typeof it.valueItem.value === "object" ? it.valueItem.value.value : it.valueItem.value;
    if (
      it.valueItem.key === "date" &&
      ["=", "!=", ">", "<", ">=", "<="].includes(it.operatorItem.value)
    ) {
      val = `${transformDateTime({
        date: it.valueItem.value,
        format: "YYYY-MM-DD",
      })}`
    }

    if (
      (it.operatorItem.value === "LIKE" || it.operatorItem.value === "NOT LIKE") &&
      !val.includes("%")
    ) {
      val = `%${val}%`;
    }

    if (it.openParen.value === "(") parenCounter += 1;
    if (it.closeParen.value === ")") parenCounter -= 1;

    if(val) {
      if((it.valueItem.key === "string" || it.valueItem.key === "date" ) && (it.operatorItem.value.trim() !== "IN" && it.operatorItem.value.trim() !== "NOT IN")) { 
        val = `"${val.toString().trim()}"`;
      } else {
        val = val.toString().trim();
      }
    }

    str += ` ${it.openParen.value.trim()} ${getAttributeItem(it)} ${it.operatorItem.value.trim()} ${val || ""} ${it.closeParen.value.trim()} ${index === list.length - 1 ? "" : it.andOr.value.trim()} `;
  });

  str = str.trim();
  if (parenCounter === 0) {
    parenCounter = countParentheses(str);
  }
  str += "</condition>";

  if (parenCounter !== 0) {
    return { error: true, errorMsg: "Filter condition parens are not open or closed properly." };
  }
  return { error: false, value: str };
};

export const prepareFormulaFilterText = (ruleItems) => {
  let str = "";
  let parenCounter = 0;
  const list = ruleItems.filter((it) => it.objectItem.value === "System Fx");
  list.forEach((it, index) => {
    if (it.openParen.value === "(") parenCounter += 1;
    if (it.closeParen.value === ")") parenCounter -= 1;

    str += ` ${it.openParen.value} ${it.valueItem.value.value} ${it.closeParen.value} ${index === list.length - 1 ? "" : it.andOr.value
      }`;
  });
  str = str.trim();
  if (parenCounter === 0) {
    parenCounter = countParentheses(str);
  }
  if (parenCounter !== 0) {
    return { error: true, errorMsg: "Filter condition parens are not open or closed properly." };
  }
  return { error: false, value: str };
};

export const fetchDefaultValueBasedOnOperator = (valueOptions, valueItem, operator) => {
  if (operator.value === "IS NULL" || operator.value === "IS NOT NULL") {
    return "";
  } else if (operator.value === "IN" || operator.value === "NOT IN") {
    return valueItem.key === "number"
      ? "(123, 123)"
      : valueItem.key === "date"
        ? `("${transformDateTime({date: new Date(),format: "YYYY-MM-DD"})}","${transformDateTime({date: new Date(),format: "YYYY-MM-DD"})}")`
        : `("abc", "abc")`;
  } else if (operator.value === "LIKE" || operator.value === "NOT LIKE") {
    return valueItem.key === "number"
      ? "%123%"
      : valueItem.key === "date"
        ? `%${transformDateTime({date: new Date(),format: "YYYY-MM-DD"})}%`
        : "%abc%";
  } else if (["event_type", "kpi", "period_type", "group", "period_name"].includes(valueItem.key)) {
    return valueOptions[0];
  } else if (valueItem.key === "date") {
    return null;
  } else if (valueItem.key === "boolean") {
    return valueOptions[0];
  } else {
    return "";
  }
};

export const fetchExistingValues = (valueOptions, valueItem, operator) => {
  if (
    [
      "event_type",
      "kpi",
      "period_type",
      "group",
      "period_name",
      "result_name",
      "system_fx",
      "boolean",
    ].includes(valueItem.key)
  ) {
    const val = valueItem.value;
    if (val && typeof val === "string" && valueOptions && valueOptions.length>0) {
      const kpiItem = valueOptions?.find((it) => it.value == val);
      return kpiItem || valueOptions[0];
    }
    return val;
  }
  return valueItem.value || "";
};

export const getAttributeFromObject = (attributeList, objectItem) => {

  const attribs = attributeList.filter((it) => it.udf_data_obj_label.toLowerCase() === objectItem.value.toLowerCase()).map((it) => {
    if(it.input_control && 
      it.input_control.rule_filter === '0') {
        return null;
      }
      return it;
  }).filter(Boolean);

  return attribs;
};

export const getAttributeOperatorAndKeyFromAttribute = (attribute, allOperators) => {
  if (!attribute.input_control) {
    if (attribute.rfct_field_metadata?.includes("DECIMAL")) {
      return { operators: allOperators.map((it) => ({ ...it, disabled: false })), key: "number" };
    } else if (attribute.rfct_field_metadata?.includes("DATETIME")) {
      return { operators: allOperators.map((it) => ({ ...it, disabled: false })), key: "date" };
    } else if (attribute.rfct_field_metadata === "INT(10) NULL") {
      return { operators: allOperators.map((it) => ({ ...it, disabled: false })), key: "number" };
    } else if (
      attribute.rfct_field_metadata?.includes("TINYINT") ||
      attribute.rfct_field_metadata?.includes("INT")
    ) {
      return {
        operators: allOperators.map((it) => ({
          ...it,
          disabled: ["=", "!=", "IS NULL", "IS NOT NULL"].indexOf(it.value) === -1,
        })),
        key: "boolean",
      };
    } else {
      return {
        operators: allOperators.map((it) => ({
          ...it,
          disabled: [">", ">=", "<", "<="].indexOf(it.value) !== -1,
        })),
        key: "string",
      };
    }
  } else if (attribute.input_control.type === "select") {
    return {
      operators: allOperators.map((it) => ({
        ...it,
        disabled: ["=", "!=", "IS NULL", "IS NOT NULL"].indexOf(it.value) === -1,
      })),
      key: attribute?.input_control?.object,
    };
  }
};


export const validateAndOrOperator = (ruleItems) => {
  if (ruleItems && ruleItems.length > 1) {
    const idx = ruleItems.findIndex((it) => it.andOr.value === "");
    if (idx >= 0 && idx !== ruleItems.length - 1) {
      return { error: true, errorMsg: "And/Or condition not set properly." };
    }
  }
  return { error: false, errorMsg: "" }
}