const MessageSchema = {
    /** User Login Schema */
    usernameRequired: "Please enter username",
    passwordRequired: "Please enter password",
    otpRequired: "Please enter otp",
    newPasswordRequired: "Please enter new password",
    confirmNewPasswordRequired: "Please enter confirm new password",
    passwordMatchError: "Confirm password is not same as new password",
    //    enforcePatternMessage: "Password has one alpha one numeric one special char required",
    firstnameRequired: "Please enter first name",
    lastnameRequired: "Please enter last name",
    emailRequired: "Please enter email",
    emailValid: "Please enter valid email",

    /** Business Group Schema */
    groupNameRequired: "Please enter group Name",
    ownerUserRequired: "Please select owner user",
    assignmentRequired: "Please select assignment",
    startDateRequired: "Please select start date",

    /** DTU Data Group Schema */
    etlFileNameRequired: "Please enter etl file name",
    etlFileDescRequired: "Please enter etl file description",
    etlFileTableRequired: "Please enter etl table name",
    etlFilPdiLableRequired: "Please enter etl pdi lable",
    etlFileNameValidation:
        "ETL File Name must contain only alphanumeric, asterisk or underscore \n with or without (.csv, .txt) extension",

    etlFilPdiLableRequiredValid:
        "Etl Pdi Label must contain only alphanumeric or underscore",

    etlEventTypeNameRequired: "Please enter event type name",

    /**Calendar Grid Schema */
    calendarYearRequired: "Please enter year",
    calendarYearValid: "Please enter valid year",
    periodNameRequired: "Please enter period name",
    periodNameValid: "Please enter valid period name",
    descRequired: "Please enter description",
    descValid: "Please enter valid description",

    includeSemiFinalRequired: "Please select include semi final",
    periodStartDateRequired: "Please enter period start date",
    periodDescription: "Please enter period description",

    /**Role Screen Schema */
    roleName: "Please enter role name",
    roleDesc: "Please enter role description",
    roleQuery: "Please enter query name",

    /**Speciality Grid Schema */
    specialityNameRequired: "Please enter specialty name",

    /** Group Screen Schema */
    titleNameRequired: "Please enter title name",

    /** Rate Table Schema */
    rateTableRequired: "Please enter rate table name",
    planTableRequired: "Please enter plan name",
    planDescRequired: "Please enter plan description",

    /**Custom Table Schema*/
    customTableRequired: "Please enter table name",
    customTableRowsRequired: "You must have to assign a key to atleast one row",
    customTableRowsValidate: "Please create at least one custom table row",
    customTableNameRequired: "Please enter custom table name",

    /**Event Table Schema*/
    StartDateRequired: "Please select start date",
    EndDateRequired: "Please select end date",
    maxEndDate: "End Date should be greater then start date",

    /**Job Table Schema*/
    jobNameRequired: " Please enter job name",
    jobSubjectRequired: "Please enter email subject",
    jobBodyRequired: "Please enter email body",
    jobParmsRequired: "Please select job parameter",
    jobStageHookRequired: "Please select stage Hook",
    jobMonthNameRequired: "Please select day of month",
    jobCompPlanRequired: "Please select comp plan",

    /**Data Types Schema */
    systemUnitRequired: "Please enter name",
    systemUnitDescRequired: "Please enter description",

    /**Audit Log Master */
    actionStartDateRequired: "Please select start date",
    actionStartTimeRequired: "Please select start time",

    actionEndDateRequired: "Please select end date",
    screenNameRequired: "Please select data element",
    validDate: "End date must be after start date",
    customTableDataRequired: "Please select custom table name",

    /** Comp Rule */
    ruleNameRequired: "Please enter rule name",

    /**Manage Custom Field */
    fieldNameRequired: "Please enter field name",
    fieldDescriptionRequired: "Please enter field description",
    eventFiledNonEditable:
        "This field is non-editable as it's contains data for events",
    providerFieldNonEditable:
        "This field is non-editable as it's contains data for provider-position",
    customFieldNameRequired: "Please select custom field name",
    customFieldValid:
        "Field Name must contain only alphanumeric, space or underscore.",

    /**Dtu Upload */
    validFile: "File too Big, please select a file less than 100mb",
    validFileType: "File Type is not allowed",

    /**Event */
    procedureCodeRequired: "Please enter procedure code.",
    EventTypeValid:
        "Event Type Name must contain only alphanumeric, space or underscore.",

    /**Dashboard POD Schema */
    labelnameRequired: "Please enter label name",
    datanameRequired: "Please enter data name",

    /**Dashboard Tab Schema */
    businessGroupRequired: "Please select Business Group",
    compPlanRequired: "Please select Comp Plan",
    groupFilterRequired: "Please select Group Filter",
    specialtyFilterRequired: "Please select Specialty Filter",
    providerFilterRequired: "Please select Provider Filter",
};

export default MessageSchema;
