import { orderBy } from "@progress/kendo-data-query";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { errStatusHandler, POSTAPI } from "src/api-setup/api-helper";
import { GetSystemConfigDataByCode } from "src/lib/common-service";
import { getUserCredential } from "src/lib/cookies";
import { transformDateTime } from "src/lib/date-time.util";
import {
    setCompPlanDataAction,
    setCompPlanLoadingAction,
    setCompPlanPeriodDataAction,
    setCompPlanPeriodLoadingAction,
    setCompPlanSection2DataAction,
    setCompPlanSummaryHeaderAction,
    setGroupLoadingAction,
    setGroupsDataAction,
    setKPIPaymentSummaryAction,
    setProviderDataAction,
    setProviderLoadingAction,
    setSelectedCompPlanAction,
    setSelectedGroupsAction,
    setSelectedPeriodAction,
    setSelectedProviderAction,
    setSelectedSpecialitiesAction,
    setShowSection1,
    setSpecialitiesDataAction,
    setSpecialitiesLoadingAction,
} from "src/redux/actions/compplan-actions";
import { compPlanStatementSelector } from "src/redux/selectors/custom-selector";
import { GridColumnDataMapper } from "src/utils/utils";
import { v4 as uuidV4 } from "uuid";

const useCompPlanStatementService = () => {
    const cookiesData = getUserCredential();
    const header = {
        "x-access-token": cookiesData.secretToken,
    };
    const dispatch = useDispatch();
    const history = useHistory();

    const {
        selectedCompPlan,
        selectedCompPlanPeriod,
        selectedSpecialities,
        selectedGroups,
        selectedProviders,
        isProviderDetail,
        showReportResults,
    } = useSelector(compPlanStatementSelector, shallowEqual);

    const getCompPlan = async () => {
        let reqData = {
            in_tenant_id: cookiesData.out_tenant_id,
            in_user_role_filter: ">0",
            in_user_key: cookiesData.out_user_id,
        };

        dispatch(setCompPlanLoadingAction(true));

        try {
            const res = await POSTAPI(
                false,
                "/reports/select-report-unpub-filter-comp-plan",
                null,
                header,
                reqData,
                dispatch
            );

            let value = null;
            let data = [];

            if (res.data.out_parameters.out_ret_code > 0) {
                errStatusHandler(res, history, dispatch);
            } else {
                value = selectedCompPlan || res.data.row_result[0];
                data = res.data.row_result;
                dispatch(setCompPlanDataAction(data));
                dispatch(setSelectedCompPlanAction(value));
            }
        } catch (error) {
            dispatch(setSelectedCompPlanAction(null));
            dispatch(setCompPlanDataAction([]));
        } finally {
            dispatch(setCompPlanLoadingAction(false));
        }
    };

    const fetchCompPlanPeriods = async (compPlan) => {
        if (!compPlan) {
            dispatch(setSelectedPeriodAction(null));
            dispatch(setCompPlanPeriodDataAction([]));
            return;
        }
        let reqData = {
            in_tenant_id: cookiesData.out_tenant_id,
            in_filter: null,
            in_comp_plan_key: compPlan.comp_plan_mstr_key,
        };
        dispatch(setCompPlanPeriodLoadingAction(true));

        try {
            const res = await POSTAPI(
                false,
                "/reports/select-report-unpub-filter-comp-plan-period",
                null,
                header,
                reqData,
                dispatch
            );

            let value = null;
            let data = [];

            if (res.data.out_parameters.out_ret_code > 0) {
                errStatusHandler(res, history, dispatch);
            } else {
                if (!showReportResults) {
                    value = res.data.row_result.length
                        ? res.data.row_result[0]
                        : null;
                } else {
                    const filterItem = res.data.row_result.find(
                        (it) =>
                            it.period_mstr_key ===
                            selectedCompPlanPeriod?.period_mstr_key
                    );
                    value = filterItem || res.data.row_result[0];
                }
                data = res.data.row_result;
            }

            dispatch(setCompPlanPeriodDataAction(data));
            dispatch(setSelectedPeriodAction(value));
        } catch (error) {
            dispatch(setSelectedPeriodAction(null));
            dispatch(setCompPlanPeriodDataAction([]));
        } finally {
            dispatch(setCompPlanPeriodLoadingAction(false));
        }
    };

    const fetchSpecialities = async (period) => {
        if (!period) {
            dispatch(setSelectedSpecialitiesAction([]));
            dispatch(setSpecialitiesDataAction([]));
            return;
        }
        let reqData = {
            in_tenant_id: cookiesData.out_tenant_id,
            in_filter: null,
            in_comp_plan_key: selectedCompPlan?.comp_plan_mstr_key,
            in_period_key: period?.period_mstr_key,
            in_user_key: cookiesData.out_user_id,
            in_user_role_type_abbr: cookiesData.out_db_role_type_abbr,
        };
        dispatch(setSpecialitiesLoadingAction(true));

        try {
            const res = await POSTAPI(
                false,
                "/reports/select-report-unpub-filter-specialty",
                null,
                header,
                reqData,
                dispatch
            );

            let data = [];
            let value = [];

            if (res.data.out_parameters.out_ret_code > 0) {
                errStatusHandler(res, history, dispatch);
            } else {
                data.unshift({
                    specialty_key: 0,
                    specialty_name: "Select All",
                });
                // value = [...data];
                if (!showReportResults) {
                    value = [{
                        specialty_key: 0,
                        specialty_name: "Select All",
                    }];
                } else {
                    const selectedKeys = selectedSpecialities.map(
                        (it) => it.specialty_key
                    );
                    const filteredArray = res.data.row_result.filter((value) =>
                        selectedKeys.includes(value.specialty_key)
                    );
                    value =
                        filteredArray.length > 0
                            ? [...selectedSpecialities]
                            : [data[0]];
                }

                data = [...data, ...res.data.row_result];
            }

            dispatch(setSpecialitiesDataAction(data));
            dispatch(setSelectedSpecialitiesAction(value));
        } catch (error) {
            dispatch(setSelectedSpecialitiesAction([]));
            dispatch(setSpecialitiesDataAction([]));
        } finally {
            dispatch(setSpecialitiesLoadingAction(false));
        }
    };

    const fetchGroups = async (specialities) => {
        if (!specialities?.length) {
            dispatch(setSelectedGroupsAction([]));
            dispatch(setGroupsDataAction([]));
            return;
        }
        let reqData = {
            in_tenant_id: cookiesData.out_tenant_id,
            in_filter: null,
            in_comp_plan_key: selectedCompPlan?.comp_plan_mstr_key,
            in_period_key: selectedCompPlanPeriod?.period_mstr_key,
            in_user_key: cookiesData.out_user_id,
            in_user_role_type_abbr: cookiesData.out_db_role_type_abbr,
            in_specialty_key: specialities
                .map((it) => it.specialty_key)
                .join(","),
        };
        dispatch(setGroupLoadingAction(true));

        try {
            const res = await POSTAPI(
                false,
                "/reports/select-report-unpub-filter-group",
                null,
                header,
                reqData,
                dispatch
            );

            let data = [];
            let value = [];

            if (res.data.out_parameters.out_ret_code > 0) {
                errStatusHandler(res, history, dispatch);
            } else {
                data.unshift({
                    org_title_key: 0,
                    title_name: "Select All",
                });
                if (!showReportResults) {
                    value = [{ org_title_key: 0, title_name: "Select All" }];
                } else {
                 

                    const selectedKeys = selectedGroups.map(
                        (it) => it.org_title_key
                    );
                    const filteredArray = res.data.row_result.filter((value) =>
                        selectedKeys.includes(value.org_title_key)
                    );

                    value =
                        filteredArray.length > 0
                            ? [...selectedGroups]
                            : [data[0]];
                }

                data = [...data, ...res.data.row_result];
            }

            dispatch(setGroupsDataAction(data));
            dispatch(setSelectedGroupsAction(value));
        } catch (error) {
            dispatch(setSelectedGroupsAction([]));
            dispatch(setGroupsDataAction([]));
        } finally {
            dispatch(setGroupLoadingAction(false));
        }
    };

    const fetchProviders = async (groups) => {
        if (!groups?.length) {
            dispatch(setSelectedProviderAction([]));
            dispatch(setProviderDataAction([]));
            return;
        }
        let reqData = {
            in_tenant_id: cookiesData.out_tenant_id,
            in_filter: null,
            in_comp_plan_key: selectedCompPlan?.comp_plan_mstr_key,
            in_period_key: selectedCompPlanPeriod?.period_mstr_key,
            in_user_key: cookiesData.out_user_id,
            in_user_role_type_abbr: cookiesData.out_db_role_type_abbr,
            in_specialty_key: selectedSpecialities
                .map((it) => it.specialty_key)
                .join(","),
            in_group_key: selectedGroups
                .map((it) => it.org_title_key)
                .join(","),
            in_encrypt_key: cookiesData.out_acct_encrypt_key,
        };
        dispatch(setProviderLoadingAction(true));

        try {
            const res = await POSTAPI(
                false,
                "/reports/select-report-unpub-filter-provider",
                null,
                header,
                reqData,
                dispatch
            );

            let data = [];
            let value = [];

            if (res.data.out_parameters.out_ret_code > 0) {
                errStatusHandler(res, history, dispatch);
            } else {
                data = res.data.row_result;
                data = [{
                    provider_key: 0,
                    provider_name: "Select All",
                }, ...res.data.row_result]

                if (!showReportResults) {
                    value = [{
                        provider_key: 0,
                        provider_name: "Select All",
                    }];
                } else {
                    const selectedKeys = selectedProviders.map(
                        (it) => it.provider_key
                    );
                    const filteredArray = res.data.row_result.filter((value) =>
                        selectedKeys.includes(value.provider_key)
                    );
                    value =
                        filteredArray.length > 0
                            ? [...selectedProviders]
                            : [data[0]];
                }
            }

            if (cookiesData.out_db_role_type_abbr === "prv1") {
                value = [res.data.row_result[0]];
                data = data.filter((it) => it.provider_key !== 0);
            }

            dispatch(setProviderDataAction(data));
            dispatch(setSelectedProviderAction(value));
        } catch (error) {
            dispatch(setSelectedProviderAction([]));
            dispatch(setProviderDataAction([]));
        } finally {
            dispatch(setProviderLoadingAction(false));
        }
    };

    const fetchSummaryHeaderProvider = async () => {
        let reqData = {
            in_tenant_id: cookiesData.out_tenant_id,
            in_filter: null,
            in_comp_plan_key: selectedCompPlan.comp_plan_mstr_key,
            in_period_key: selectedCompPlanPeriod.period_mstr_key,
            in_user_key: cookiesData.out_user_id,
            in_user_role_type_abbr: cookiesData.out_db_role_type_abbr,
            in_specialty_keys: selectedSpecialities
                .map((it) => it.specialty_key)
                .join(","),
            in_group_keys: selectedGroups
                .map((it) => it.org_title_key)
                .join(","),
            in_provider_keys: selectedProviders
                .map((it) => it.provider_key)
                .join(","),
            in_encrypt_key: cookiesData.out_acct_encrypt_key,
        };

        try {
            const res = await POSTAPI(
                false,
                "/reports/select-report-unpub-summary-header-provider",
                null,
                header,
                reqData,
                dispatch
            );
            let data = [];
            let columns = [];

            if (res.data.out_parameters.out_columns_xml) {
                columns = GridColumnDataMapper(
                    res.data.out_parameters.out_columns_xml,
                    null
                );
                columns = columns.map((it) => ({ ...it, filterable: false }));
            }
            if (res.data.out_parameters.out_ret_code > 0) {
                errStatusHandler(res, history, dispatch);
            }

            columns.push({
                id: uuidV4(),
                title: "CONTRACT TYPE",
                type: "string",
                field: "contract_type",
                filterable: false,
                encrypted: false,
                show: true,
                childCols: [],
            });

            columns.push({
                id: uuidV4(),
                title: "UTILIZATION",
                type: "string",
                field: null,
                filterable: false,
                encrypted: false,
                show: true,
                childCols: [
                    {
                        id: uuidV4(),
                        title: "ACADEMIC",
                        type: "number",
                        field: "utilization_academic",
                        filterable: false,
                        reorderable: false,
                        encrypted: false,
                        show: true,
                    },
                    {
                        id: uuidV4(),
                        title: "ADMIN",
                        type: "number",
                        field: "utilization_admin",
                        filterable: false,
                        reorderable: false,
                        encrypted: false,
                        show: true,
                    },
                    {
                        id: uuidV4(),
                        title: "CLINICAL",
                        type: "number",
                        field: "utilization_clinical",
                        filterable: false,
                        reorderable: false,
                        encrypted: false,
                        show: true,
                    },
                    {
                        id: uuidV4(),
                        title: "RESEARCH",
                        type: "number",
                        field: "utilization_research",
                        filterable: false,
                        reorderable: false,
                        encrypted: false,
                        show: true,
                    },
                ],
            });

            data = res.data.row_result.map((it) => {
                if (it.hire_date) {
                    return {
                        ...it,
                        hire_date: transformDateTime({
                            date: it.hire_date,
                            format: "MM/DD/YYYY",
                        }),
                    };
                }
                return it;
            });
            dispatch(setCompPlanSummaryHeaderAction({ columns, data: data }));
        } catch (error) {}
    };

    const fetchPaymentSummary = async (payload) => {
        let reqData = {
            in_tenant_id: cookiesData.out_tenant_id,
            in_filter: payload.in_filter,
            in_sort_number: payload.in_sort_number,
            in_sort_order: payload.in_sort_order,
            in_comp_plan_key: selectedCompPlan.comp_plan_mstr_key,
            in_period_key: selectedCompPlanPeriod.period_mstr_key,
            in_user_key: cookiesData.out_user_id,
            in_user_role_type_abbr: cookiesData.out_db_role_type_abbr,
            in_specialty_keys: selectedSpecialities
                .map((it) => it.specialty_key)
                .join(","),
            in_group_keys: selectedGroups
                .map((it) => it.org_title_key)
                .join(","),
            in_provider_keys: selectedProviders
                .map((it) => it.provider_key)
                .join(","),
            in_encrypt_key: cookiesData.out_acct_encrypt_key,
        };

        try {
            const res = await POSTAPI(
                false,
                "/reports/select-report-unpub-summary-column-header-payment",
                null,
                header,
                reqData,
                dispatch
            );
            let data = [];
            let columns = [];

            if (res.data.out_parameters.out_columns_xml) {
                columns = GridColumnDataMapper(
                    res.data.out_parameters.out_columns_xml,
                    null
                );
            }

            if (res.data.out_parameters.out_ret_code > 0) {
                errStatusHandler(res, history, dispatch);
            }
            data = res.data.row_result;
            dispatch(setKPIPaymentSummaryAction({ columns, data: data }));
        } catch (error) {}
    };

    const getAutoHideSection1 = async () => {
        let reqData = {
            in_tenant_id: cookiesData.out_tenant_id,
            pref_code: ["auto_hide_report_sec1"],
        };
        let res = await GetSystemConfigDataByCode(reqData, history, dispatch);
        if (res) {
            let flag = res.auto_hide_report_sec1 === "0" ? true : false;
            dispatch(setShowSection1(flag));
        }
    };

    const fetchReportSummaryColumnHeaders = async () => {
        let reqData = {
            in_period_key: selectedCompPlanPeriod.period_mstr_key,
        };
        try {
            const res = await POSTAPI(
                false,
                "/reports/select-report-unpub-summary-column-headers",
                null,
                header,
                reqData,
                dispatch
            );
            let data = [];

            if (res.data.out_parameters.out_ret_code > 0) {
                errStatusHandler(res, history, dispatch);
            }
            data = res.data.row_result.map((it) => ({ ...it, show: true }));
            dispatch(setCompPlanSection2DataAction({ columns: data }));
        } catch (error) {}
    };

    const fetchReportPlanGridDetails = async () => {
        let reqData = {
            in_tenant_id: cookiesData.out_tenant_id,
            in_comp_plan_key: selectedCompPlan.comp_plan_mstr_key,
            in_period_key: selectedCompPlanPeriod.period_mstr_key,
            in_user_key: cookiesData.out_user_id,
            in_user_role_type_abbr: cookiesData.out_db_role_type_abbr,
            in_specialty_keys: selectedSpecialities
                .map((it) => it.specialty_key)
                .join(","),
            in_group_keys: selectedGroups
                .map((it) => it.org_title_key)
                .join(","),
            in_provider_keys: selectedProviders
                .map((it) => it.provider_key)
                .join(","),
            in_encrypt_key: cookiesData.out_acct_encrypt_key,
            in_aggregate_flag: isProviderDetail ? 0 : 1,
        };

        try {
            const res = await POSTAPI(
                false,
                "/reports/select-report-unpub-summary-plan-grid-details",
                null,
                header,
                reqData,
                dispatch
            );
            let data = [];
            if (res.data.out_parameters.out_ret_code > 0) {
                errStatusHandler(res, history, dispatch);
            }
            data = res.data.row_result.map((it) => {
                const newObj = { ...it };
                newObj.period1_value_total =
                   it.result_metadata_tags === "<grand_total>"
                        ? Number(it.period1_value)
                        : 0;
                newObj.period2_value_total =
                      it.result_metadata_tags === "<grand_total>"
                        ? Number(it.period2_value)
                        : 0;
                newObj.period3_value_total =
                       it.result_metadata_tags === "<grand_total>"
                        ? Number(it.period3_value)
                        : 0;
                newObj.period4_value_total =
                     it.result_metadata_tags === "<grand_total>"
                        ? Number(it.period4_value)
                        : 0;
                newObj.period5_value_total =
                      it.result_metadata_tags === "<grand_total>"
                        ? Number(it.period5_value)
                        : 0;
                newObj.period6_value_total =
                     it.result_metadata_tags === "<grand_total>"
                        ? Number(it.period6_value)
                        : 0;
                newObj.period7_value_total =
                       it.result_metadata_tags === "<grand_total>"
                        ? Number(it.period7_value)
                        : 0;
                newObj.period8_value_total =
                      it.result_metadata_tags === "<grand_total>"
                        ? Number(it.period8_value)
                        : 0;
                newObj.period9_value_total =
                       it.result_metadata_tags === "<grand_total>"
                        ? Number(it.period9_value)
                        : 0;
                newObj.period10_value_total =
                      it.result_metadata_tags === "<grand_total>"
                        ? Number(it.period10_value)
                        : 0;
                newObj.period11_value_total =
                      it.result_metadata_tags === "<grand_total>"
                        ? Number(it.period11_value)
                        : 0;
                newObj.period12_value_total =
                       it.result_metadata_tags === "<grand_total>"
                        ? Number(it.period12_value)
                        : 0;
                newObj.period13_value_total =
                      it.result_metadata_tags === "<grand_total>"
                        ? Number(it.period13_value)
                        : 0;
                newObj.period14_value_total =
                      it.result_metadata_tags === "<grand_total>"
                        ? Number(it.period14_value)
                        : 0;
                newObj.period15_value_total =
                      it.result_metadata_tags === "<grand_total>"
                        ? Number(it.period15_value)
                        : 0;
                newObj.period16_value_total =
                      it.result_metadata_tags === "<grand_total>"
                        ? Number(it.period16_value)
                        : 0;
                newObj.period17_value_total =
                      it.result_metadata_tags === "<grand_total>"
                        ? Number(it.period17_value)
                        : 0;

                return newObj;
            });
            const resultdata = orderBy(data, [{field: "kpi_report_order", dir: "asc"}, 
                {field: "kpi_name", dir: "asc"}, 
                {field: "result_name_report_order", dir: "asc"}, {field: "result_name", dir: "asc"}]);
            dispatch(setCompPlanSection2DataAction({ data: resultdata }));
        } catch (error) {}
    };

    return {
        getCompPlan,
        fetchCompPlanPeriods,
        fetchSpecialities,
        fetchGroups,
        fetchProviders,
        fetchSummaryHeaderProvider,
        fetchPaymentSummary,
        fetchReportSummaryColumnHeaders,
        fetchReportPlanGridDetails,
        getAutoHideSection1,
    };
};

export default useCompPlanStatementService;
