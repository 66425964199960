/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable-next-line no-useless-concat
import * as React from "react";
import {
  GridColumn as Column,
  getSelectedState,
} from "@progress/kendo-react-grid";
import { useDispatch } from "react-redux";
import { columnMenuProps } from "../../components/customColumnMenu";
import DataTable from "../../components/dataTable";
import { DialogComponent } from "../../components/dialog";
import { DELETE_BTN_ACTIONS } from "../../constants/constants";
import { DEFAULT_SORT } from "../../constants/grid";
import { getUserCredential, getTenantCookieData } from "../../lib/cookies";
import {
  changeCancelBtnState,
  changeUpdateBtnState,
  executeGetColumnOrderInfo,
  getfilterDataString,
} from "../../utils/utils";
import useApi from "./service";
import EffDataRowsApi from "../../components/effectiveVesionService";
import {
  GET_CUSTOM_TABLE_EFF_DATES,
  DELETE_CUSTOM_DATA,
  INSERT_CUSTOM_TABLE,
} from "../../api-setup/api-endpoints";
import { EffectiveVersions } from "../../components/effectiveVersions";
import TableData from "./dataRows/dataRows";
import useDataRowApi from "./dataRows/service";
import EditCustomTable from "./editCustomTable";
import { Window } from "@progress/kendo-react-dialogs";
import { getter } from "@progress/kendo-data-query";
import { LoadingPanel } from "../../components/loadingPanel";
// import { setUnSavedChangesPopup } from "src/redux/actions/setting-actions";
import { RowRender } from "src/components/renderers";
import { updateColumnReorderAsync } from "src/lib/common-service";
import { useHistory } from "react-router";

const DATA_ITEM_KEY = "custom_table_mstr_key";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

const loadingPanel = (
  <div className="k-loading-mask">
    <span className="k-loading-text">Loading</span>
    <div className="k-loading-image"></div>
    <div className="k-loading-color"></div>
  </div>
);

export const CustomTableData = (restProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  // const { unsavedPopup } = useSelector(unSaveChangesPopupSelector, shallowEqual);
  const cookiesData = getUserCredential();
  const defaultPageSize = getTenantCookieData("record_per_page");
  const apiCallData = {
    in_filter: null,
    in_sort_number: 1,
    in_sort_order: "DESC",
    in_db_role_key: cookiesData?.out_db_role_key,
  };

  const tierApiCallData = {
    in_tenant_id: cookiesData.out_tenant_id,
    in_custom_table_mstr_key: 0,
    in_date_version: 0,
    in_filter: null,
    in_sort_number: DEFAULT_SORT.number,
    in_sort_order: DEFAULT_SORT.order,
    in_page_row_offset: 0,
    in_page_rows: defaultPageSize.recordTake,
    in_user_role_filter: "> 0",
    in_load_type: "grid-reload",
    in_init: 1,
  };

  const [list, Action] = useApi();
  const [listVersion, ActionVersion] = EffDataRowsApi();
  const [listDatarow, ActionDatarow] = useDataRowApi();

  const [deleteSelectedIds, setDeleteSelectedIds] = React.useState([]);
  const [filterField, setFilterField] = React.useState({
    logic: "and",
    filters: [],
  });
  const [sortField, setSortField] = React.useState([]);
  const { dataRowsCount, setDataRowsCount } = listDatarow;

  const {
    data,
    columns,
    dataCount,
    setData,
    isAPICalling,
    setConfirmationPopup,
    confirmationPopup,
    setAPICallingState,
    initialLoading,
    isRefreshLoading,
    setRefreshLoading,
    setColumns,
  } = list;

  //state to set and get limit, offset
  const [pageData, setPage] = React.useState({
    skip: 0,
    take: defaultPageSize.recordTake,
  });

  const [pageSize, setPageSize] = React.useState(defaultPageSize);

  //state to set and get filter, sort number, sort order
  const [filterData, setFilterData] = React.useState({
    isSort: false,
    isFilter: false,
    data: "",
    in_sort_number: DEFAULT_SORT.number,
    sort_order: DEFAULT_SORT.order,
  });

  const [exportData, setExportData] = React.useState([]);
  const [selectedState, setSelectedState] = React.useState({});
  const [gridColumns, setGridColumns] = React.useState([]);
  const [effVersionLoadingState, setEffVersionLoadingState] =
    React.useState(false);

  const [effVersionState, setEffVersionState] = React.useState({
    changeVersion: { rowId: -1, versionIdx: -1 },
    insertVersion: { rowId: -1, periodKey: 0 },
    deleteVersion: { rowId: -1 },
  });
  const [changeVersion, setChangeVersion] = React.useState(false);
  const [tierApiCallDataState, setTierApiCallDataState] =
    React.useState(tierApiCallData);
  const [isShow, setShow] = React.useState({
    editCustomTable: false,
    editDataItem: {},
  });
  const [isCustomTableUpdate, setCustomTableUpdate] = React.useState(false);
  const [isExpandClick, setExpandClick] = React.useState(false);
  const [openPopUp, setOpenPopup] = React.useState(false);
  const [parentDataItem, setParentDataItem] = React.useState("");
  const [isUpdateCustomTableData, setUpdateCustomTableData] =
    React.useState(false);
  const [modal, setModal] = React.useState(true);
  const [stageChange, setStateChange] = React.useState("DEFAULT");

  React.useEffect(async () => {
    if (isUpdateCustomTableData) {
      let mstrKey = parentDataItem.custom_table_mstr_key;
      let masterTableData = data.slice();

      let index = masterTableData.findIndex(
        (d) => d.custom_table_mstr_key === mstrKey
      );
      const tierDataVal = {
        in_custom_table_mstr_key: mstrKey,
        in_date_version: parentDataItem.date_version,
        in_filter: null,
        in_init: 1,
        in_load_type: "grid-reload",
        in_page_row_offset: 0,
        in_page_rows: getPageTake(),
        in_sort_number: 1,
        in_sort_order: "DESC",
        in_tenant_id: cookiesData.out_tenant_id,
        in_user_role_filter: "> 0",
        selectedVersion: parentDataItem,
      };

      const tierData = await ActionDatarow.getListDataAsync(tierDataVal);
      masterTableData[index].effVersionDetails.tierData = tierData;
      masterTableData[index].selected = true;

      masterTableData
        .filter((x) => x.selected === true)
        .map((item) => {
          item.selected = false;
          return 0;
        });
      setParentDataItem(masterTableData[index]);
      setUpdateCustomTableData(false);
    }
  }, [isUpdateCustomTableData]);

  React.useEffect(() => {
    if (isExpandClick) {
      setShow({
        ...isShow,
        editCustomTable: false,
      });
      setExpandClick(false);
    }
  }, [isExpandClick]);

  React.useEffect(() => {
    restProps.setDataList(data);
  }, [data]);

  React.useEffect(async () => {
    if (restProps.isAPICall) {
      restProps.setSelectedRowId("");
      setDeleteSelectedIds([]);
      setSelectedState({});
      restProps.setAPICall(false);
      setAPICallingState(true);
      const dataVal = {
        ...apiCallData,
        in_filter: getInFilter(),
        in_sort_number: getInSortNumber(),
        in_sort_order: getInSortOrder(),
        in_page_row_offset: getPageSkip(),
        in_page_rows: getPageTake(),
      };
      let res = await Action.getListDataAsync(dataVal);
      if (res) {
        setDeleteSelectedIds([{ id: res[0].custom_table_mstr_key }]);
        restProps.setSelectedRowId(res[0].custom_table_mstr_key);
        setSelectedState({ [res[0].custom_table_mstr_key]: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restProps.isAPICall]);

  React.useEffect(() => {
    if (restProps.isAddNewConfig) {
      restProps.setNewConfig(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restProps.isAddNewConfig]);

  React.useEffect(() => {
    if (list.loadingState.isDeleteData) {
      list.setLoadState({ ...list.loadingState, isDeleteData: false });
      setAPICallingState(true);
      const dataVal = {
        ...apiCallData,
        in_filter: getInFilter(),
        in_sort_number: getInSortNumber(),
        in_sort_order: getInSortOrder(),
        in_page_row_offset: getPageSkip(),
        in_page_rows: getPageTake(),
      };
      getGridData(dataVal, "delete");
    }
  }, [list.loadingState.isDeleteData]);

  React.useEffect(() => {
    if (restProps.isShow.deleteRows) {
      remove();
      restProps.setShow({ ...restProps.isShow, deleteRows: false });
    }
  }, [restProps.isShow.deleteRows]);

  React.useEffect(() => {
    if (columns.length > 0) {
      restProps.setColumnsData(columns);
      setGridColumns(columns);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);

  React.useEffect(() => {
    if (isCustomTableUpdate) {
      setCustomTableUpdate(false);
      setAPICallingState(true);

      const dataVal = {
        ...apiCallData,
        in_filter: getInFilter(),
        in_sort_number: getInSortNumber(),
        in_sort_order: getInSortOrder(),
        in_page_row_offset: getPageSkip(),
        in_page_rows: getPageTake(),
      };
      getGridData(dataVal, "update");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCustomTableUpdate]);

  React.useEffect(() => {
    const dataVal = {
      ...apiCallData,
      in_load_type: "grid-reload",
      in_page_row_offset: getPageSkip(),
      in_page_rows: getPageTake(),
    };

    Action.getListDataAsync(dataVal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (restProps.isCheckColumn) {
      setColumns(restProps.columnsData);
      restProps.setColumnCheck(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restProps.isCheckColumn]);

  //change eff version
  React.useEffect(async () => {
    let masterTableData = data.slice();
    if (masterTableData.length > 0) {
      setEffVersionLoadingState(true);
      const versionState = effVersionState.changeVersion;
      const mstrRow = masterTableData[versionState.rowId];
      let thisEffVersionDeta = mstrRow.effVersionDetails.data;

      thisEffVersionDeta
        .filter((x) => x.isSelected === 1)
        .map((item) => {
          item.isSelected = 0;
          return 0;
        });

      const newSelectedVersion = thisEffVersionDeta[versionState.versionIdx];

      newSelectedVersion.isSelected = 1;
      newSelectedVersion.endDate =
        mstrRow.effVersionDetails.currentVersionEndDate;
      const tierDataVal = {
        ...tierApiCallData,
        in_custom_table_mstr_key: mstrRow.custom_table_mstr_key,
        in_date_version: newSelectedVersion.dateVersion,
        in_filter: null,
        in_sort_number: DEFAULT_SORT.number,
        selectedVersion: newSelectedVersion,
      };
      setChangeVersion(true);
      changeCancelBtnState(
        "disable",
        "grid-incell-cancel-" + mstrRow.custom_table_mstr_key
      );
      changeUpdateBtnState(
        "disable",
        "grid-incell-update-" + mstrRow.custom_table_mstr_key
      );
      const tierData = await ActionDatarow.getListDataAsync(tierDataVal);
      mstrRow.effVersionDetails.tierData = tierData;
      setEffVersionLoadingState(false);
      setData([...masterTableData]);
    }
  }, [effVersionState.changeVersion]);

  /**
   * call delete eff version API
   */
  React.useEffect(() => {
    let masterTableData = data.slice();
    const deleteVersion = effVersionState.deleteVersion;

    if (masterTableData.length > 0 && deleteVersion.rowId !== -1) {
      const mstrDeta = masterTableData[deleteVersion.rowId];
      const thisEffVersionDeta = mstrDeta.effVersionDetails.data;

      const selectedVersion = thisEffVersionDeta.filter(
        (x) => x.isSelected === 1
      )[0];
      const bodyData = {
        in_user_id: cookiesData.out_user_id,
        in_custom_table_mstr_key: [
          {
            id: mstrDeta.custom_table_mstr_key,
            in_date_version: selectedVersion.dateVersion,
          },
        ],
        in_tenant_id: cookiesData.out_tenant_id,
      };
      setChangeVersion(true);
      setEffVersionLoadingState(true);
      ActionVersion.deleteDataAsync({
        api: DELETE_CUSTOM_DATA,
        bodyData: bodyData,
      });
    }
  }, [effVersionState.deleteVersion]);

  /**
   * call add eff version API
   */
  React.useEffect(() => {
    let masterTableData = data.slice();
    const insertVersion = effVersionState.insertVersion;

    if (masterTableData.length > 0 && insertVersion.periodKey) {
      setEffVersionLoadingState(true);
      const mstrDeta = masterTableData[insertVersion.rowId];

      const bodyData = {
        in_user_id: cookiesData.out_user_id,
        in_tenant_id: cookiesData.out_tenant_id,
        in_custom_table_mstr_key: mstrDeta.custom_table_mstr_key,
        in_custom_table_name: mstrDeta.custom_table_name,
        in_custom_table_desc: mstrDeta.custom_table_desc,
        in_custom_table_type: mstrDeta.custom_table_type,
        in_period_mstr_key: insertVersion.periodKey,
      };

      ActionVersion.addDataAsync({
        api: INSERT_CUSTOM_TABLE,
        bodyData: bodyData,
      });
    }
  }, [effVersionState.insertVersion]);

  /**
   * update dataset after insert a new version
   */
  React.useEffect(async () => {
    if (listVersion.loadingState.isSaveData === true) {
      let masterTableData = data.slice();

      const mstrIdx = effVersionState.insertVersion.rowId;
      const mstrDeta = masterTableData[mstrIdx];

      let effVersionResponse = await getEffVersionsApi(
        mstrDeta.custom_table_mstr_key
      );

      masterTableData[mstrIdx].effVersionDetails = effVersionResponse;
      setChangeVersion(true);
      setEffVersionState({
        ...effVersionState,
        insertVersion: { rowId: -1, periodKey: 0 },
      });
      setData(masterTableData);
      setEffVersionLoadingState(false);
      changeCancelBtnState(
        "disable",
        "grid-incell-cancel-" + mstrDeta.custom_table_mstr_key
      );
      changeUpdateBtnState(
        "disable",
        "grid-incell-update-" + mstrDeta.custom_table_mstr_key
      );
    }
  }, [listVersion.loadingState.isSaveData]);

  /**
   * update dataseta fter delete an existing version
   */
  React.useEffect(async () => {
    if (listVersion.loadingState.isDeleteData === true) {
      let masterTableData = data.slice();

      const mstrIdx = effVersionState.deleteVersion.rowId;
      const mstrDeta = masterTableData[mstrIdx];

      let effVersionResponse = await getEffVersionsApi(
        mstrDeta.custom_table_mstr_key
      );
      if (!effVersionResponse.data.length) {
        restProps.setSelectedRowId("");
        setDeleteSelectedIds([]);
        setAPICallingState(true);
        const dataVal = {
          ...apiCallData,
          in_filter: getInFilter(),
          in_sort_number: getInSortNumber(),
          in_sort_order: getInSortOrder(),
          in_page_row_offset: getPageSkip(),
          in_page_rows: getPageTake(),
        };
        getGridData(dataVal, "delete");
      } else {
        masterTableData[mstrIdx].effVersionDetails = effVersionResponse;
      }
      setEffVersionState({
        ...effVersionState,
        deleteVersion: { rowId: -1 },
      });
      setData(masterTableData);
      setEffVersionLoadingState(false);
      changeCancelBtnState(
        "disable",
        "grid-incell-cancel-" + mstrDeta.custom_table_mstr_key
      );
      changeUpdateBtnState(
        "disable",
        "grid-incell-update-" + mstrDeta.custom_table_mstr_key
      );
    }
  }, [listVersion.loadingState.isDeleteData]);

  React.useEffect(async () => {
    if (tierApiCallDataState.in_custom_table_mstr_key > 0) {
      setRefreshLoading(true);
      const tierDataVal = {
        ...tierApiCallData,
        in_filter: tierApiCallDataState.in_filter,
        in_sort_number: tierApiCallDataState.in_sort_number,
        in_sort_order: tierApiCallDataState.in_sort_order,
        in_page_row_offset: tierApiCallDataState.in_page_row_offset,
        in_page_rows: tierApiCallDataState.in_page_rows,
        in_custom_table_mstr_key: tierApiCallDataState.in_custom_table_mstr_key,
        in_date_version: tierApiCallDataState.in_date_version,
      };
      let response = await ActionDatarow.getListDataAsync(tierDataVal);
      let masterTableData = data.slice();

      let mstrIdx = masterTableData.findIndex(
        (d) =>
          d.custom_table_mstr_key ===
          tierApiCallDataState.in_custom_table_mstr_key
      );

      const mstrDeta = masterTableData[mstrIdx];
      mstrDeta.effVersionDetails.tierData.rateTierData = response.rateTierData;
      setDataRowsCount(response.dataCount || 0);
      setTierApiCallDataState(tierApiCallData);
      setData(masterTableData);
      setRefreshLoading(false);
    }
  }, [tierApiCallDataState]);

  const getInFilter = () => {
    return filterData.isFilter ? filterData.data : null;
  };

  const getInSortNumber = () => {
    return filterData.isSort ? filterData.in_sort_number : DEFAULT_SORT.number;
  };

  const getInSortOrder = () => {
    return filterData.isSort ? filterData.sort_order : DEFAULT_SORT.order;
  };

  const getPageTake = () => {
    return pageData.take;
  };

  const getPageSkip = () => {
    return pageData.skip;
  };

  const remove = (ids) => {
    setConfirmationPopup({
      ...confirmationPopup,
      isVisible: true,
      type: "DELETE_CONFIRM",
      actionBtn: DELETE_BTN_ACTIONS(onCancelConfirm, onDeleteConfirm),
      data: [],
      action: "Delete",
    });
  };

  const onDeleteConfirm = (event) => {
    const deleteArray = Object.keys(selectedState).map((obj2) => {
      const obj1 = data.find(
        (elem) => elem.custom_table_mstr_key === Number(obj2)
      );
      return {
        id: obj2,
        in_date_version: null,
      };
    });
    let deleteData = {
      in_user_id: cookiesData.out_user_id,
      in_tenant_id: cookiesData.out_tenant_id,
      in_custom_table_mstr_key: deleteArray,
    };

    Action.deleteDataAsync(deleteData);

    setDeleteSelectedIds([]);
    restProps.setSelectedRowId("");
  };

  const onCancelConfirm = (action) => {
    setConfirmationPopup({
      ...confirmationPopup,
      isVisible: false,
      type: "",
      actionBtn: [],
      data: {},
      action: "",
    });
    data.map((item) => {
      item.selected = false;
      return 0;
    });
    setData([...data]);
    if (action === "outSideClick") {
      // TODO : UNSAVED CHANGES
      // dispatch(setUnSavedChangesPopup({ ...unsavedPopup, isUnSavedChange: true }));
    }
  };

  const enterEdit = (props) => {
    let { dataItem } = props;
    let obj1 = data.find(
      (elem) => elem.custom_table_mstr_key === dataItem.custom_table_mstr_key
    );

    // obj1.selected = isCtrlKey ? !obj1.selected : true;
    obj1.selected = true;
    let tempArray = [];
    const trueStatus = data.filter((elem) => elem.selected === true);

    trueStatus.map((item) => {
      let obj = {};
      obj.id = item.custom_table_mstr_key;

      tempArray.push(obj);
      return 0;
    });

    setDeleteSelectedIds(tempArray);
    setData(data);
    restProps.setSelectedRowId(dataItem.custom_table_mstr_key);
    restProps.setDataList(data);
    setShow({
      ...isShow,
      editCustomTable: true,
      editDataItem: dataItem,
    });
  };

  const onRowDoubleClick = (props) => {
    // if (props.originalProps.dataItem.data_write_access === 0) {
    //   return false;
    // }
    onOpenPopup(props.originalProps.dataItem);
  };

  const setPageMenuState = (response) => {
    if (!deleteSelectedIds.length) {
      return false;
    }

    let ids = response.map((el) => el.custom_table_mstr_key);

    let selectedKeys = [];
    let tempArray = [];
    let tempArrayData = [];

    Object.keys(selectedState).map((item) => {
      let obj = {};

      obj.id = Number(item);
      selectedKeys.push(item);
      tempArray.push(obj);

      return 0;
    });

    ids.map((item) => {
      if (selectedKeys && selectedKeys.includes(item.toString())) {
        tempArrayData.push(item);
      }
      return 0;
    });

    let strSelectedKeys = "";

    if (tempArrayData.length > 0) {
      strSelectedKeys = parseInt(selectedKeys[0]);
    }
    setData(response);
    restProps.setDataList(response);
    restProps.setSelectedRowId(strSelectedKeys);
  };

  const handlePageChange = async (event) => {
    const pageData = event.page;

    setAPICallingState(true);

    setPage({ skip: pageData.skip, take: pageData.take });

    const dataVal = {
      ...apiCallData,
      in_filter: getInFilter(),
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
      in_page_row_offset: getPageSkip(),
      in_page_rows: pageData.take,
    };

    getGridData(dataVal);
  };

  const handleColumnMenu = (columnMenuData, isFilter, isSort, filterObj) => {
    if (isSort) {
      setSortField(columnMenuData);
    } else {
      setFilterField(filterObj);
    }

    let index = 1;
    let filterSkip = getPageSkip();
    let filterTake = getPageTake();

    if (isFilter) {
      filterSkip = 0;
      filterTake = pageSize.recordTake;

      //reset pager when filter applies
      setPage({ skip: filterSkip, take: filterTake });
    }

    if (data.length > 0 && columnMenuData[0] !== undefined) {
      let obj = Object.keys(data[0]);
      let sortNameField = "";

      //replace mstr key with name field only when sorting is applied
      if (
        columnMenuData[0].field &&
        columnMenuData[0].field.indexOf("_mstr_key")
      ) {
        let sortKeyField = columnMenuData[0].field;
        sortNameField = sortKeyField.replace("_mstr_key", "_name");
      }

      const sortOriginField =
        sortNameField !== "" ? sortNameField : columnMenuData[0].field;

      index = obj.findIndex((x) => x === sortOriginField) + 1;
    }

    const filterString = isFilter
      ? getfilterDataString(columnMenuData, gridColumns)
      : filterData.data;

    isFilter = false;
    if (filterString !== "") {
      isFilter = true;
    }

    let sortDir =
      filterData.isSort && columnMenuData.length
        ? filterData.sort_order
        : DEFAULT_SORT.order;
    let sortNumber =
      filterData.isSort && columnMenuData.length
        ? filterData.in_sort_number
        : DEFAULT_SORT.number;

    isSort = !columnMenuData.length ? false : filterData.isSort;

    if (
      columnMenuData[0] !== undefined &&
      columnMenuData[0].dir !== undefined
    ) {
      isSort = true;
      sortDir = columnMenuData[0].dir.toUpperCase();
      sortNumber = index;
    }

    const dataVal = {
      ...apiCallData,
      in_filter: isFilter ? filterString : null,
      in_sort_number: sortNumber,
      in_sort_order: sortDir,
      in_page_row_offset: filterSkip,
      in_page_rows: filterTake,
    };

    setFilterData({
      ...filterData,
      isFilter: isFilter,
      isSort: isSort,
      data: filterString,
      in_sort_number: sortNumber,
      sort_order: sortDir,
    });

    setAPICallingState(true);
    getGridData(dataVal, "filter");
  };

  const StringReandOnlyCell = React.useCallback((props) => {
    const { ariaColumnIndex, columnIndex, dataItem, field } = props;

    let val = dataItem[field || ""];

    if (field.indexOf("_mstr_key") !== -1) {
      val = dataItem[field.replace("_mstr_key", "_name") || ""];
    }

    const defaultRendering = (
      <td aria-colindex={ariaColumnIndex} data-grid-col-index={columnIndex}>
        <span title={val}>{val}</span>
      </td>
    );

    return defaultRendering;
  }, []);

  const CustomTableTypeCell = React.useCallback(
    (props) => {
      const { ariaColumnIndex, columnIndex } = props;
      let type =
        props.dataItem[props.field] === "S" ||
        props.dataItem[props.field] === null
          ? "Standard"
          : "Hierarchical";
      const defaultRendering = (
        <td aria-colindex={ariaColumnIndex} data-grid-col-index={columnIndex}>
          <span title={type}>{type}</span>
        </td>
      );
      return defaultRendering;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onOpenPopup = async (dataItem) => {
    restProps.setSelectedRowId(dataItem.custom_table_mstr_key);
    setSelectedState({ [dataItem.custom_table_mstr_key]: true });
    let mstrKey = dataItem.custom_table_mstr_key;
    let masterTableData = data.slice();
    let index = masterTableData.findIndex(
      (d) => d.custom_table_mstr_key === mstrKey
    );
    setRefreshLoading(true);
    const versionProps = {
      apiEndPoints: { listApi: GET_CUSTOM_TABLE_EFF_DATES },
      dataItem: { mstr_key: mstrKey },
      pageProps: { writeAccess: restProps.pageProps.writeAccess },
    };

    const versionApiCallData = {
      in_tenant_id: cookiesData.out_tenant_id,
      in_mstr_key: mstrKey,
      in_sort_number: 1,
      in_sort_order: "ASC",
    };
    //get response for eff version tab
    let effVersionResponse = await ActionVersion.getListDataAsync(
      versionApiCallData,
      versionProps
    );
    const currentEndDate = effVersionResponse.currentVersionEndDate;
    //get response for eff version period menu
    const periodDataResponse = await ActionVersion.getPeriodsDataTypeAsync(
      currentEndDate
    );
    if (effVersionResponse.data.length > 0) {
      setRefreshLoading(false);
      effVersionResponse["periodsData"] = periodDataResponse;
      const selectedVersion = effVersionResponse.data.filter(
        (x) => x.isSelected === 1
      )[0];
      let effVersionDataItem = effVersionResponse;
      masterTableData[index].effVersionDetails = effVersionDataItem;
      masterTableData[index].selected = true;
      masterTableData
        .filter((x) => x.selected === true)
        .map((item) => {
          item.selected = false;
          return 0;
        });
      restProps.setSelectedRowId(mstrKey);
      restProps.setDataList(data);
      setParentDataItem(masterTableData[index]);
      setOpenPopup(true);

      const tierDataVal = {
        ...tierApiCallData,
        in_custom_table_mstr_key: mstrKey,
        in_date_version: selectedVersion?.dateVersion,
        selectedVersion: selectedVersion,
      };

      const tierData = await ActionDatarow.getListDataAsync(tierDataVal);
      effVersionResponse["tierData"] = tierData;
      masterTableData[index].effVersionDetails = effVersionResponse;
      setDataRowsCount(
        masterTableData[index]?.effVersionDetails?.tierData?.dataCount || 0
      );
      setData(masterTableData);
    } else {
      onClosePopup();
    }
  };

  const ActionCell = (props) => {
    return (
      <td
        title={
          props.dataItem.data_write_access === 1 ? "Configure Custom Table" : ""
        }
        className="text-center"
      >
        {props.dataItem.data_write_access === 1 ? (
          <span
            className="k-icon k-font-icon k-i-hyperlink-open"
            onClick={() => enterEdit(props)}
          ></span>
        ) : (
          <span></span> // This can be any message or just leave it blank
        )}
      </td>
    );
  };

  const useCol = (useColumns) => {
    return useColumns.map((column, idx) => {
      return column.show && column.title !== "" ? (
        <Column
          {...columnMenuProps(column)}
          key={idx}
          cell={
            column.field === "custom_table_type"
              ? CustomTableTypeCell
              : column.field === "expand"
              ? ActionCell
              : StringReandOnlyCell
          }
          width={column.field === "expand" ? 40 : 210}
          filterable={column.field === "expand" ? false : true}
          sortable={column.field === "expand" ? false : true}
          resizable={column.field === "expand" ? false : true}
          reorderable={column.field === "expand" ? false : true}
        />
      ) : (
        false
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  let CustomColumn = useCol(gridColumns);

  const onClearFilter = async () => {
    setAPICallingState(true);
    setFilterField({ logic: "and", filters: [] });
    //set in_params
    const dataVal = {
      ...apiCallData,
      in_filter: null,
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
      in_page_row_offset: getPageSkip(),
      in_page_rows: getPageTake(),
    };

    //set filter to false
    setFilterData({
      ...filterData,
      data: "",
      isFilter: false,
    });

    getGridData(dataVal);
  };
  const getGridData = async (dataVal, module) => {
    let response = await Action.getListDataAsync(dataVal);
    if (response.length) {
      switch (module) {
        case "update":
          setPageMenuState(response);
          setAPICallingState(false);
          break;
        case "delete":
          setPageMenuState(response);
          setAPICallingState(false);
          break;
        // case 'add':
        //   setDeleteSelectedIds([{'id' : response[0].custom_table_mstr_key }])
        //   restProps.setSelectedRowId(response[0].custom_table_mstr_key)
        //   setSelectedState({ [response[0].custom_table_mstr_key]: true });
        //   setPageMenuState(response);
        //   break;
        default:
          setPageMenuState(response);
          setAPICallingState(false);
          break;
      }
    } else {
      if (module === "filter") {
        setPageMenuState(response);
        setAPICallingState(false);
      }
      if (response.length === 0 && module === "update") {
        setPageMenuState(response);
        setAPICallingState(false);
      }
    }
  };

  const onRefreshClick = () => {
    setAPICallingState(true);

    const dataVal = {
      ...apiCallData,
      in_filter: getInFilter(),
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
      in_page_row_offset: getPageSkip(),
      in_page_rows: getPageTake(),
    };
    getGridData(dataVal);
  };

  const getExportData = async () => {
    const dataVal = {
      ...apiCallData,
      in_page_row_offset: 0,
      in_page_rows: dataCount,
      in_filter: getInFilter(),
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
    };
    let res = await Action.exportDataAsync(dataVal);
    if (res) {
      const exportRes = res.map((item) => ({
        ...item,
        custom_table_type:
          item.custom_table_type === "S" ? "Standard" : "Hierarchical",
      }));
      setExportData(exportRes);
    }
  };

  const onClosePopup = () => {
    setOpenPopup(false);
    // TODO : UNSAVED CHANGES
    // dispatch(setUnSavedChangesPopup({ isUnSavedChange: false }));
  };

  /**
   * call list eff version API
   */
  const getEffVersionsApi = async (mstrKey) => {
    setRefreshLoading(true);
    const versionProps = {
      apiEndPoints: { listApi: GET_CUSTOM_TABLE_EFF_DATES },
      dataItem: { mstr_key: mstrKey },
      pageProps: { writeAccess: restProps.pageProps.writeAccess },
    };

    const versionApiCallData = {
      in_tenant_id: cookiesData.out_tenant_id,
      in_mstr_key: mstrKey,
      in_sort_number: 1,
      in_sort_order: "ASC",
    };

    //get response for eff version tab
    let effVersionResponse = await ActionVersion.getListDataAsync(
      versionApiCallData,
      versionProps
    );
    const currentEndDate = effVersionResponse.currentVersionEndDate;
    //get response for eff version period menu
    const periodDataResponse = await ActionVersion.getPeriodsDataTypeAsync(
      currentEndDate
    );
    if (effVersionResponse.data.length > 0) {
      effVersionResponse["periodsData"] = periodDataResponse;
      const selectedVersion = effVersionResponse.data.filter(
        (x) => x.isSelected === 1
      )[0];

      const tierDataVal = {
        ...tierApiCallData,
        in_custom_table_mstr_key: mstrKey,
        in_date_version: selectedVersion?.dateVersion,
        selectedVersion: selectedVersion,
      };
      let masterTableData = data.slice();
      let parentIndex = masterTableData.findIndex(
        (x) => x.custom_table_mstr_key === tierDataVal.in_custom_table_mstr_key
      );
      if (parentIndex > -1) {
        if (masterTableData[parentIndex].data_filter_access === 0) {
          const tierData = await ActionDatarow.getListDataAsync(tierDataVal);
          effVersionResponse["tierData"] = tierData;
          setRefreshLoading(false);
        } else {
          const tierData = await ActionDatarow.getCustomTableListDataAsync(
            tierDataVal
          );
          effVersionResponse["tierData"] = tierData;
          setRefreshLoading(false);
        }
      }
    } else {
      onClosePopup();
    }

    return effVersionResponse;
  };

  const onSelectionChange = (event) => {
    const newSelectedState = getSelectedState({
      event,
      selectedState: selectedState,
      dataItemKey: DATA_ITEM_KEY,
    });
    setSelectedState(newSelectedState);
    let tempArray = [];

    Object.keys(newSelectedState).map((item) => {
      if (newSelectedState[item] === false) {
        return 0;
      }
      let obj = {};
      obj.id = Number(item);
      // obj.text = item.event_group_name;
      tempArray.push(obj);
      return 0;
    });
    restProps.setSelectedRowId(tempArray[0]);
    setDeleteSelectedIds(tempArray);
  };

  const handleStageChange = (e) => {
    setStateChange(e.state);
    if (e.state === "MINIMIZED") {
      setModal(false);
    } else if (e.state === "DEFAULT") {
      setModal(true);
    }
  };

  const customRowRender = (tr, props) => (
    <RowRender
      originalProps={props}
      tr={tr}
      onRowDoubleClick={onRowDoubleClick}
    />
  );

  const prepareColumnString = (colData) => {
    return executeGetColumnOrderInfo(colData);
  };

  const columnReorderData = async (columnData) => {
    const str = prepareColumnString(
      columnData.filter((x) => x.field !== "expand")
    );
    let reqData = {
      in_db_name: "procare_system",
      in_tenant_id: cookiesData.out_tenant_id,
      in_filter_logical_cond: "N",
      in_filter_label_cond: "N",
      in_column_info: str,
    };
    let res = await updateColumnReorderAsync(reqData, dispatch, history);
    if (res) {
      setColumns([]);
      let colDataList = JSON.parse(JSON.stringify(columnData));
      colDataList.forEach((item) => {
        item.hidden = false;
      });
      let colData = colDataList.sort(function (a, b) {
        return a.ariaColumnIndex - b.ariaColumnIndex;
      });
      setColumns(colData);
    }
  };

  const ModalTitle = () => {
    return (
      <>
        <span className="custtableflex">
          <div className="custtableitem">
            {parentDataItem.custom_table_name}
          </div>{" "}
          <span className="custtableseprator">&gt;</span>{" "}
          {`Custom Table Data Rows`}
        </span>
      </>
    );
  };

  return (
    <>
      {isRefreshLoading && <>{loadingPanel}</>}
      {columns.length > 0 ? (
        <DataTable
          isAPICalling={isAPICalling}
          initialLoading={initialLoading}
          data={data.map((item) => ({
            ...item,
            [SELECTED_FIELD]: selectedState[idGetter(item)],
          }))}
          id="custom-table-grid"
          pageData={pageData}
          dataCount={dataCount}
          customColumn={CustomColumn}
          columns={gridColumns}
          dataItemKey={DATA_ITEM_KEY}
          width={"auto"}
          module={"custom-table"}
          getExportData={getExportData}
          setExportData={restProps.setExportDataClick}
          isExportDataClick={restProps.exportDataClick}
          fileName={"Export_custom_table.xlsx"}
          exportData={exportData}
          defaultPageSize={defaultPageSize}
          pageSize={pageSize}
          setPageSize={setPageSize}
          handleColumnMenu={handleColumnMenu}
          handlePageChange={handlePageChange}
          refreshClick={onRefreshClick}
          onClearFilter={onClearFilter}
          onSelectionChange={onSelectionChange}
          reorderable={restProps.pageProps.writeAccess === 0 ? false : true}
          columnReorderData={columnReorderData}
          rowRender={customRowRender}
          initialFilter={filterField}
          initialSort={sortField}
        />
      ) : (
        <>{loadingPanel}</>
      )}

      <DialogComponent
        title={confirmationPopup.title}
        onCancelConfirm={onCancelConfirm}
        width={"450"}
        height={"auto"}
        className={"Dialog-Delete"}
        desc={confirmationPopup.desc}
        visible={confirmationPopup.isVisible}
        actionBtn={confirmationPopup.actionBtn}
        titleClassName={
          confirmationPopup.action === "Delete"
            ? "delete-confirm"
            : "unSave-confirm"
        }
      />
      {isShow.editCustomTable ? (
        <EditCustomTable
          visible={isShow.editCustomTable}
          editDataItem={isShow.editDataItem}
          setShow={setShow}
          setCustomTableUpdate={setCustomTableUpdate}
          setSelectedRowId={restProps.setSelectedRowId}
          selectedRowId={restProps.selectedRowId}
          pageProps={restProps.pageProps}
        />
      ) : (
        false
      )}
      {openPopUp && (
        <Window
          className={"Dialog-dtulogs Dialog-lModal customtabledatacont"}
          title={<ModalTitle />}
          onClose={() => {
            onClosePopup();
          }}
          width={window.innerWidth * 0.8}
          height={window.innerHeight * 0.8}
          resizable={false}
          stage={stageChange}
          onStageChange={handleStageChange}
          modal={modal}
        >
          <div
            className="content-head-left"
            style={{
              paddingBottom: 10,
            }}
          >
            {/* <div className="Page-title">
              <h1>{parentDataItem.custom_table_name
                ? `Custom Table Name : ${parentDataItem.custom_table_name}`
                : "Custom Table Name"}</h1>
            </div> */}
          </div>
          <div></div>
          {parentDataItem ? (
            <>
              {effVersionLoadingState ? <LoadingPanel /> : false}
              <EffectiveVersions
                pageProps={restProps.pageProps}
                versionData={parentDataItem}
                effVersionState={effVersionState}
                setEffVersionState={setEffVersionState}
                effVersionLoadingState={effVersionLoadingState}
              />
              {parentDataItem.effVersionDetails?.tierData ? (
                <TableData
                  effVersionLoadingState={effVersionLoadingState}
                  pageProps={restProps.pageProps}
                  versionData={parentDataItem}
                  tierApiCallData={tierApiCallData}
                  tierApiCallDataState={tierApiCallDataState}
                  setTierApiCallDataState={setTierApiCallDataState}
                  setUpdateCustomTableData={setUpdateCustomTableData}
                  setChangeVersion={setChangeVersion}
                  changeVersion={changeVersion}
                  dataRowsCount={dataRowsCount}
                  setDataRowsCount={setDataRowsCount}
                />
              ) : (
                <LoadingPanel />
              )}
            </>
          ) : (
            false
          )}
        </Window>
      )}
    </>
  );
};
