import {
    GridColumn as Column,
} from "@progress/kendo-react-grid";
import * as React from "react";
import { columnMenuProps } from "../../components/customColumnMenu";
import DataTable from "../../components/dataTable";
import { RowRender } from "../../components/renderers";
import { DEFAULT_SORT } from "../../constants/grid";
import { getUserCredential, getTenantCookieData } from "../../lib/cookies";
import {
    getfilterDataString,
    changeCancelBtnState,
    changeUpdateBtnState,
} from "../../utils/utils";
import useApi from "./searchModuleService";
import { Window, WindowActionsBar } from "@progress/kendo-react-dialogs";
import { GET_TITLE_DATA, GET_USER_ADMIN_DATA } from "src/api-setup/api-endpoints";
import { Button } from "@progress/kendo-react-buttons";


const editField = "inEdit";

const loadingPanel = (
    <div className="k-loading-mask">
        <span className="k-loading-text">Loading</span>
        <div className="k-loading-image"></div>
        <div className="k-loading-color"></div>
    </div>
);

export const SearchModule = (restProps) => {
    const cookiesData = getUserCredential();
    const defaultPageSize = getTenantCookieData("record_per_page");
    let DATA_ITEM_KEY = ''
    let apiName = ''
    let modalTitle = ''
    let currentModule = ''
    let apiCallData = {
        in_tenant_id: cookiesData.out_tenant_id,
        in_filter: null,
        in_sort_number: DEFAULT_SORT.number,
        in_sort_order: DEFAULT_SORT.order,
        in_page_row_offset: 0,
        in_page_rows: defaultPageSize.recordTake,
        in_user_role_filter: "> 0",
        in_load_type: "data-reload",
        in_export_flag : 0
    };
    if (restProps.showGroupFilterPopup) {
        DATA_ITEM_KEY = "org_title_key";
        apiName = GET_TITLE_DATA
        modalTitle = 'Title Search'
        currentModule = 'group'
    } else if (restProps.showUserFilterPopup) {
        DATA_ITEM_KEY = "user_key";
        apiName = GET_USER_ADMIN_DATA;
        modalTitle = 'User Search'
        apiCallData.in_encrypt_key = cookiesData.out_acct_encrypt_key;
        currentModule = 'user'
    }

    const [list, Action] = useApi();
    const {
        data,
        columns,
        dataCount,
        setData,
        isAPICalling,
        setAPICallingState,
        initialLoading,
        isRefreshLoading,
    } = list;

    //state to set and get limit, offset
    const [pageData, setPage] = React.useState({
        skip: 0,
        take: defaultPageSize.recordTake,
    });

    const [pageSize, setPageSize] = React.useState(defaultPageSize);
    const [selectedRowId, setSelectedRowId] = React.useState('')

    //state to set and get filter, sort number, sort order
    const [filterData, setFilterData] = React.useState({
        isSort: false,
        isFilter: false,
        data: "",
        in_sort_number: DEFAULT_SORT.number,
        sort_order: DEFAULT_SORT.order,
    });
    const [selectedState, setSelectedState] = React.useState({});
    const [gridColumns, setGridColumns] = React.useState([]);

    React.useEffect(() => {
        if (columns.length > 0) {
            setGridColumns(columns);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columns]);

    React.useEffect(() => {
        const dataVal = {
            ...apiCallData,
            in_load_type: "grid-reload",
        };
        Action.getListDataAsync(dataVal, apiName);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const getInFilter = () => {
        return filterData.isFilter ? filterData.data : null;
    };

    const getInSortNumber = () => {
        return filterData.isSort
            ? filterData.in_sort_number
            : DEFAULT_SORT.number;
    };

    const getInSortOrder = () => {
        return filterData.isSort ? filterData.sort_order : DEFAULT_SORT.order;
    };

    const getPageTake = () => {
        return pageData.take;
    };

    const getPageSkip = () => {
        return pageData.skip;
    };


    const onRowClick = (props) => {
        const {dataItem} = props
        let newData = data.map((item) => ({
            ...item,
            selected:
                item[DATA_ITEM_KEY] === dataItem[DATA_ITEM_KEY] ? true : false,
        }));
        setData(newData);
        setSelectedRowId(dataItem[DATA_ITEM_KEY]);
    };

    const setPageMenuState = (response) => {
        let ids = response.map((el) => el[DATA_ITEM_KEY]);

        let selectedKeys = [];
        let tempArray = [];
        let tempArrayData = [];

        Object.keys(selectedState).map((item) => {
            let obj = {};

            obj.id = Number(item);
            selectedKeys.push(item);
            tempArray.push(obj);

            return 0;
        });

        ids.map((item) => {
            if (selectedKeys.includes(item.toString())) {
                tempArrayData.push(item);
            }
            return 0;
        });

        let strSelectedKeys = "";

        if (tempArrayData.length > 0) {
            strSelectedKeys = parseInt(selectedKeys[0]);
        }

        setSelectedRowId(strSelectedKeys);
    };

    const handlePageChange = async (event) => {
        const pageData = event.page;

        setAPICallingState(true);

        setPage({ skip: pageData.skip, take: pageData.take });

        const dataVal = {
            ...apiCallData,
            in_filter: getInFilter(),
            in_sort_number: getInSortNumber(),
            in_sort_order: getInSortOrder(),
            in_page_row_offset: pageData.skip,
            in_page_rows: pageData.take,
        };

        getGridData(dataVal);
    };

    const handleColumnMenu = (columnMenuData, isFilter, isSort) => {
        let index = 1;
        let filterSkip = getPageSkip();
        let filterTake = getPageTake();

        if (isFilter) {
            filterSkip = 0;
            filterTake = pageSize.recordTake;

            //reset pager when filter applies
            setPage({ skip: filterSkip, take: filterTake });
        }

        if (data.length > 0 && columnMenuData[0] !== undefined) {
            let obj = Object.keys(data[0]);
            let sortNameField = "";

            //replace mstr key with name field only when sorting is applied
            if (
                columnMenuData[0].field &&
                columnMenuData[0].field.indexOf("_mstr_key")
            ) {
                let sortKeyField = columnMenuData[0].field;
                sortNameField = sortKeyField.replace("_mstr_key", "_name");
            }

            const sortOriginField =
                sortNameField !== "" ? sortNameField : columnMenuData[0].field;

            index = obj.findIndex((x) => x === sortOriginField) + 1;
        }

        

        const filterString = isFilter
            ? getfilterDataString(columnMenuData, gridColumns)
            : filterData.data;

        isFilter = false;
        if (filterString !== "") {
            isFilter = true;
        }

        let sortDir =
            filterData.isSort && columnMenuData.length
                ? filterData.sort_order
                : DEFAULT_SORT.order;
        let sortNumber =
            filterData.isSort && columnMenuData.length
                ? filterData.in_sort_number
                : DEFAULT_SORT.number;

        isSort = !columnMenuData.length ? false : filterData.isSort;

        if (
            columnMenuData[0] !== undefined &&
            columnMenuData[0].dir !== undefined
        ) {
            isSort = true;
            sortDir = columnMenuData[0].dir.toUpperCase();
            sortNumber = index;
        }

        const dataVal = {
            ...apiCallData,
            in_filter: isFilter ? filterString : null,
            in_sort_number: sortNumber,
            in_sort_order: sortDir,
            in_page_row_offset: filterSkip,
            in_page_rows: filterTake,
        };

        setFilterData({
            ...filterData,
            isFilter: isFilter,
            isSort: isSort,
            data: filterString,
            in_sort_number: sortNumber,
            sort_order: sortDir,
        });

        setAPICallingState(true);

        getGridData(dataVal);
    };

    const StringReandOnlyCell = React.useCallback((props) => {
        const { ariaColumnIndex, columnIndex, dataItem, field } = props;
    
        let val = dataItem[field || ""];
    
        if (field.indexOf("_mstr_key") !== -1) {
          val = dataItem[field.replace("_mstr_key", "_name") || ""];
        }
    
        const defaultRendering = (
          <td aria-colindex={ariaColumnIndex} data-grid-col-index={columnIndex}>
            <span title={val}>{val}</span>
          </td>
        );
    
        return defaultRendering;
      }, []);


    const useCol = (columns) => {
        return columns.map((column, idx) => {
            return column.show && column.title !== "" ? (
                <Column
                    {...columnMenuProps(column)}
                    key={idx}
                    sortable={true}
                    format={column.format}
                    width={150}
                    cell={StringReandOnlyCell}
                />
            ) : (
                false
            );
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    let ModuleColumn = useCol(gridColumns);

    const onClearFilter = async () => {
        setAPICallingState(true);

        //set in_params
        const dataVal = {
            ...apiCallData,
            in_filter: null,
            in_sort_number: getInSortNumber(),
            in_sort_order: getInSortOrder(),
            in_page_row_offset: getPageSkip(),
            in_page_rows: getPageTake(),
        };

        //set filter to false
        setFilterData({
            ...filterData,
            data: "",
            isFilter: false,
        });

        getGridData(dataVal);
    };

    const getGridData = async (dataVal) => {
        let response = await Action.getListDataAsync(dataVal ,apiName);
        setPageMenuState(response);
    };

    const onRefreshClick = () => {
        setAPICallingState(true);

        const dataVal = {
            ...apiCallData,
            in_filter: getInFilter(),
            in_sort_number: getInSortNumber(),
            in_sort_order: getInSortOrder(),
            in_page_row_offset: getPageSkip(),
            in_page_rows: getPageTake(),
        };
        getGridData(dataVal);
    };



    const customRowRender = (tr, props) => (
        <RowRender
            originalProps={props}
            tr={tr}
            editField={editField}
            onRowClick={(props) =>{
                onRowClick(props.originalProps)
            }}
        />
    );

    const ModalTitle = () => {
        return (
            <span>
                {`${modalTitle}`}
            </span>
        );
    };

    const onClosePopup = () => {
        restProps.setShowPopup({
            ...restProps.showPopup,
            showGroupFilterPopup: false,
            showUserFilterPopup: false
        })
    }



    const onOkBtnClick = () => {
        let selectedValue = data.find((x) => x[DATA_ITEM_KEY] === selectedRowId)
        switch (currentModule) {
            case 'group':
                setSelectedState({})
                changeUpdateBtnState("enable", "grid-incell-update");
                changeCancelBtnState("enable")
                restProps.setSelectedState({ ...restProps.selectedData, groupData: selectedValue })
                restProps.setFormFields({ ...restProps.formFields, groupData: selectedValue ,title_name : selectedValue.title_name })
                break;
            case 'user':
                setSelectedState({})
                changeUpdateBtnState("enable", "grid-incell-update");
                changeCancelBtnState("enable")
                restProps.setSelectedState({ ...restProps.selectedData, userData: selectedValue })
                restProps.setFormFields({ ...restProps.formFields, userData: selectedValue ,user_details :selectedValue.user_username})
                break;
            default:
                break;
        }
        onClosePopup()
    }

    return (
        <>
            <Window
                title={<ModalTitle />}
                onClose={onClosePopup}
                width={800}
                height={550}
                resizable={false}
                modal={true}
            >
                {isRefreshLoading && loadingPanel}
                <>
                    <DataTable
                        isAPICalling={isAPICalling}
                        initialLoading={initialLoading}
                        data={data}
                        id="module-grid"
                        editField={editField}
                        pageData={pageData}
                        dataCount={dataCount}
                        customColumn={ModuleColumn}
                        columns={gridColumns}
                        dataItemKey={DATA_ITEM_KEY}
                        module={"search"}
                        defaultPageSize={defaultPageSize}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        handleColumnMenu={handleColumnMenu}
                        handlePageChange={handlePageChange}
                        refreshClick={onRefreshClick}
                        onClearFilter={onClearFilter}
                        onRowClick={onRowClick}
                        rowRender={customRowRender}
                        reorderable={false}
                        gridHeight={405}
                    />
                </>
                <WindowActionsBar layout={"end"}>
                    <Button
                        type={"submit"}
                        className="primarybtn"
                        onClick={() => {
                            onOkBtnClick()
                        }}
                        disabled={selectedRowId ? false : true}
                    >
                        Ok
                    </Button>
                    <Button
                        className="cancelbtn"
                        onClick={(e) => {
                            onClosePopup();
                        }}
                        autoFocus={true}
                    >
                        {"Cancel"}
                    </Button>
                </WindowActionsBar>
            </Window>
        </>
    );
};

