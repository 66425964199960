import React from "react";
import { Button } from "@progress/kendo-react-buttons";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { providerPlanStatementSelector } from "src/redux/selectors/custom-selector";
import {
  setProviderShowSection1,
  setProviderShowSection2,
} from "src/redux/actions/providerplan-actions";

const SectionHeader = ({ handleExport }) => {
  const dispatch = useDispatch();
  const { showSection1, showSection2 } = useSelector(
    providerPlanStatementSelector,
    shallowEqual
  );

  return (
    <div className={"reports-comp-detail-section-header"}>
      <div className={"reports-comp-detail-section-selectors-container"}>
        <div
          onClick={() => dispatch(setProviderShowSection1(!showSection1))}
          className={`reports-comp-detail-section-button${
            showSection1 ? " reports-comp-detail-section-selected" : ""
          }`}
        >
          Section 1
        </div>
        <div
          onClick={() => dispatch(setProviderShowSection2(!showSection2))}
          className={`reports-comp-detail-section-button${
            showSection2 ? " reports-comp-detail-section-selected" : ""
          }`}
        >
          Section 2
        </div>
      </div>

      <div className="provider-reports-comp-detail-section-export-container">
        <Button
          className="reports-comp-detail-section-export-button primarybtn"
          onClick={handleExport}
          primary={true}
          disabled={!showSection1 && !showSection2}
        >
          Export to PDF
        </Button>
      </div>
    </div>
  );
};

export default React.memo(SectionHeader);
