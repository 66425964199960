import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { unSaveChangesPopupSelector } from "src/redux/selectors/custom-selector";
import { DialogComponent } from "./dialog";
import { setUnSavedChangesPopup } from "src/redux/actions/setting-actions";
import { UNSAVE_BTN_ACTIONS } from "src/constants/constants";

const UnSavedChangePopup = () => {
  const dispatch = useDispatch();
  const { unsavedPopup } = useSelector(
    unSaveChangesPopupSelector,
    shallowEqual
  );
  const [actionBtn, setActionBtn] = useState();

  const hideCommonUnsavedChanges = () => {
    dispatch(
      setUnSavedChangesPopup({
        ...unsavedPopup,
        isVisible: false,
        isUnSavedChange: false,
        onSaveChange: null,
        onDiscardChange: null,
      })
    );
  };

  useEffect(() => {
    if (unsavedPopup && unsavedPopup.isVisible) {
      setActionBtn(
        UNSAVE_BTN_ACTIONS(
          () => {
            unsavedPopup.onDiscardChange?.();
            hideCommonUnsavedChanges();
          },
          () => {
            unsavedPopup.onSaveChange?.();
            hideCommonUnsavedChanges();
          }
        )
      );
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unsavedPopup]);

  window.$_UNSAVED_CHANGES_FLAG = unsavedPopup?.isUnSavedChange || false;

  if (!unsavedPopup) return null;

  return (
    <DialogComponent
      title={unsavedPopup.title}
      onCancelConfirm={hideCommonUnsavedChanges}
      width={"450"}
      height={"auto"}
      className={"Dialog-Delete"}
      desc={unsavedPopup.desc}
      visible={unsavedPopup.isVisible && unsavedPopup.isUnSavedChange}
      actionBtn={actionBtn}
      titleClassName={"unSave-confirm"}
    />
  );
};

export default UnSavedChangePopup;
