import React, { useRef, useState } from "react";
import SystemFxElements from "./system-fx-elements";
import { fetchCustomTableColumnsData } from "./formula-helpers";
import { useDispatch } from "react-redux";
import { changeCancelBtnState, changeUpdateBtnState } from "src/utils/utils";

const SystemFxComponent = (props) => {
  const { sysFx, onFxDataReceived, pageProps } = props;
  const { value: sysFxValues } = sysFx;
  const dispatch = useDispatch();

  const [systemFxValues, setSystemFxValues] = useState(sysFxValues);

  const allSysFxItems = useRef(sysFxValues);

  const onDataPopulate = async (dataList, item, selectedItem) => {
    const sysFxNewItems = [...allSysFxItems.current];

    const valIdx = sysFxNewItems.findIndex(
      (it) => it.system_fx_parm_name === item.system_fx_parm_name
    );
    if (valIdx >= 0) {
      sysFxNewItems[valIdx] = {
        ...sysFxNewItems[valIdx],
        data: dataList,
        value: selectedItem,
      };
    }

    if (item.system_fx_parm_name === "custom_table_mstr_key") {
      const res = await fetchCustomTableColumnsData(
        dispatch,
        selectedItem.value
      );

      for (const fieldVal of [
        "table_field1",
        "table_field2",
        "table_field3",
        "table_field4",
        "table_field5",
        "return_field",
      ]) {
        const tf1Idx = sysFxNewItems.findIndex(
          (it) => it.system_fx_parm_name === fieldVal
        );
        if (tf1Idx >= 0) {
          let dataVals = res;
          let vals =
            res?.find((itx) => itx.value === sysFxNewItems[tf1Idx].value) ||
            null;

          /// if custom table type is H then we have to do table_field1 = Node, input_field1 = NA, table_field2 = Node Name and input_field2 = NA

          if (selectedItem.custom_table_type === "H") {
            if (fieldVal === "table_field1" || fieldVal === "table_field2") {
              vals = res?.find(
                (itx) =>
                  itx.value ===
                  (fieldVal === "table_field1" ? "Node" : "Node Name")
              );
            }
          }
          sysFxNewItems[tf1Idx] = {
            ...sysFxNewItems[tf1Idx],
            data: dataVals,
            value: vals,
            disabled:
              selectedItem.custom_table_type === "H" &&
              (fieldVal === "table_field1" || fieldVal === "table_field2"),
          };
        }
      }

      /// if custom table type is H then we have to do table_field1 = Node, input_field1 = NA, table_field2 = Node Name and input_field2 = NA

      if (selectedItem.custom_table_type === "H") {
        for (const fieldVal of ["input_field1", "input_field2"]) {
          const tf1Idx = sysFxNewItems.findIndex(
            (it) => it.system_fx_parm_name === fieldVal
          );
          if (tf1Idx >= 0) {
            let dataVals = sysFxNewItems[tf1Idx].data;
            let vals =
              sysFxNewItems[tf1Idx].data.find((itx) => itx.value === "NA") ||
              sysFxNewItems[tf1Idx].data[0];

            sysFxNewItems[tf1Idx] = {
              ...sysFxNewItems[tf1Idx],
              data: dataVals,
              value: vals,
              disabled: true,
            };
          }
        }
      }
    }
    allSysFxItems.current = sysFxNewItems;
    if (
      !sysFxNewItems
        .filter((x) => x.input_control_type === "select")
        .find((y) => y.data.length === 0) &&
      sysFx.isProcessing
    ) {
      onFxDataReceived?.(sysFxNewItems);
    } else {
      setSystemFxValues(sysFxNewItems);
    }
  };

  const onSelectChange = async (selectionVal, item) => {
    changeCancelBtnState("enable", "grid-formula-cancel");
    changeUpdateBtnState("enable", "grid-formula-update");
    const sysFxNewItems = [...allSysFxItems.current];
    const valIdx = sysFxNewItems.findIndex(
      (it) => it.system_fx_parm_name === item.system_fx_parm_name
    );
    sysFxNewItems[valIdx] = { ...sysFxNewItems[valIdx], ...selectionVal };
    if (item.system_fx_parm_name === "custom_table_mstr_key") {
      const res = await fetchCustomTableColumnsData(
        dispatch,
        selectionVal.value.value
      );
      for (const fieldVal of [
        "table_field1",
        "table_field2",
        "table_field3",
        "table_field4",
        "table_field5",
        "return_field",
      ]) {
        const tf1Idx = sysFxNewItems.findIndex(
          (it) => it.system_fx_parm_name === fieldVal
        );
        if (tf1Idx >= 0) {
          let dataVals = res;
          let vals =
            res?.find((itx) => itx.value === sysFxNewItems[tf1Idx].value) ||
            null;

          if (selectionVal.value.custom_table_type === "H") {
            if (fieldVal === "table_field1" || fieldVal === "table_field2") {
              vals = res?.find(
                (itx) =>
                  itx.value ===
                  (fieldVal === "table_field1" ? "Node" : "Node Name")
              );
            }
          }
          sysFxNewItems[tf1Idx] = {
            ...sysFxNewItems[tf1Idx],
            data: dataVals,
            value: vals,
            disabled:
              selectionVal.value.custom_table_type === "H" &&
              (fieldVal === "table_field1" || fieldVal === "table_field2"),
          };
        }
      }

      for (const fieldVal of [
        "input_field1",
        "input_field2",
        "input_field3",
        "input_field4",
        "input_field5",
      ]) {
        const tf1Idx = sysFxNewItems.findIndex(
          (it) => it.system_fx_parm_name === fieldVal
        );
        if (tf1Idx >= 0) {
          let dataVals = sysFxNewItems[tf1Idx].data;
          let vals =
            fieldVal === "input_field1" || fieldVal === "input_field2"
              ? selectionVal.value.custom_table_type === "H"
                ? sysFxNewItems[tf1Idx].data.find((itx) => itx.value === "NA")
                : sysFxNewItems[tf1Idx].data[0]
              : sysFxNewItems[tf1Idx].data[0];

          sysFxNewItems[tf1Idx] = {
            ...sysFxNewItems[tf1Idx],
            data: dataVals,
            value: vals,
            disabled:
              fieldVal === "input_field1" || fieldVal === "input_field2"
                ? selectionVal.value.custom_table_type === "H"
                : false,
          };
        }
      }
    }
    allSysFxItems.current = sysFxNewItems;
    onFxDataReceived?.(sysFxNewItems);
  };

  return (
    <>
      {systemFxValues.map((item, idex) => (
        <SystemFxElements
          key={item.elementId}
          item={item}
          pageProps={pageProps}
          onDataPopulate={(dataList, selectedItem) =>
            onDataPopulate(dataList, item, selectedItem)
          }
          onSelectChange={(selectedVal) => onSelectChange(selectedVal, item)}
        />
      ))}
    </>
  );
};

export default React.memo(SystemFxComponent);
