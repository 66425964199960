/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable-next-line no-useless-concat
import {
  getSelectedState,
  GridColumn as Column,
} from "@progress/kendo-react-grid";
import { Input } from "@progress/kendo-react-inputs";
import { Splitter } from "@progress/kendo-react-layout";
import { Sortable } from "@progress/kendo-react-sortable";
import * as React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { columnMenuProps } from "../../components/customColumnMenu";
import DataTable from "../../components/dataTable";
import { DialogComponent } from "../../components/dialog";
import "./formula.scss";
import {
  DELETE_BTN_ACTIONS,
  RULE_COND_FLAG,
  FORMULA_TYPE,
  MULTIPLE_NUMBER_REGEX,
  MULTIPLE_STRING_REGEX,
} from "../../constants/constants";
import { Button } from "@progress/kendo-react-buttons";
import { DEFAULT_SORT } from "../../constants/grid";
import { getTenantCookieData, getUserCredential } from "../../lib/cookies";
import {
  setLinkedRules,
  //setUnSavedChangesPopup,
  setUnitTypeData,
} from "../../redux/actions/setting-actions";
import {
  changeCancelBtnState,
  changeUpdateBtnState,
  debounceForFunc,
  executeGetColumnOrderInfo,
  getfilterDataString,
  //isSameRow,
} from "../../utils/utils";
import useApi from "./service";
import { XMLParser, XMLValidator } from "fast-xml-parser";
import {
  resultSettingSelector,
  fetchUnitTypeDataSelector,
  // unSaveChangesPopupSelector,
} from "../../redux/selectors/custom-selector";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { v4 as uuidv4 } from "uuid";

import { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import {
  GetSeletedSystemFxData,
  GetUnitType,
  updateColumnReorderAsync,
} from "../../lib/common-service";
import { Tooltip } from "@progress/kendo-react-tooltip";
import {
  fetchCustomTableInputFieldData,
  fetchSystemFxData,
  setupXmlValue,
} from "./formula-helpers";
import FormulaItemElements from "./item-elements";
import FormulaToolbar from "./formula-toolbar";
import { HeaderPage } from "../base/headerPage";
import { CellRender } from "src/components/renderers";
import { setResultSettingData } from "src/redux/actions/rule-library-actions";
import { useHistory } from "react-router-dom";
import UploadDtu from "../user/uploadDtu";
import SystemFx from "./system-fx";
import { LoadingPanel } from "src/components/loadingPanel";
import DtuBatchLogs from "../common/dtuBatchLogs";

const editField = "inEdit";


const parser = new XMLParser({
  allowBooleanAttributes: true,
  preserveOrder: true,
  processEntities: false,
});

let timer;

export const FormulaMaster = forwardRef(
  ({ isModal = true, ...pageProps }, ref) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const cookiesData = getUserCredential();
    const defaultPageSize = getTenantCookieData("record_per_page");
    const [list, Action] = useApi();

    const [formulaData, setFormulaData] = useState([]);
    const [tempFormulaData, setTempFormulaData] = useState([]);
    const sortableRef = React.useRef();

    const [systemFxData, setSystemFxData] = useState([]);
    const [showProcessing, setShowProcessing] = useState(false);

    const [errorPopup, setErrorPopup] = useState(false);
    const [savePressed, setSavePressed] = useState(false);
    //state to set and get limit, offset
    const [pageData, setPage] = React.useState({
      skip: 0,
      take: defaultPageSize.recordTake,
    });
    // const { unsavedPopup } = useSelector(
    //   unSaveChangesPopupSelector,
    //   shallowEqual
    // );
    const [pageSize, setPageSize] = React.useState(defaultPageSize);
    const selectedEl = React.useRef("");
    const [isShow, setShow] = React.useState({
      uplodDtu: false,
      dtuLogs: false,
    });
    const {
      data,
      columns,
      setColumns,
      dataCount,
      setData,
      isAPICalling,
      setConfirmationPopup,
      confirmationPopup,
      setAPICallingState,
      initialLoading,
      isRefreshLoading,
      editData,
      setEditData,
      originalData,
      setOriginalData,
      selectedState,
      setSelectedState,
      selectedFormula,
      setSelectedFormula,
      autoScrollIndex,
    } = list;

    const [filterField, setFilterField] = React.useState({
      logic: "and",
      filters: [],
    });
    const [sortField, setSortField] = React.useState([]);

    const [panes, setPanes] = useState([
      { size: "200px", collapsible: true },
      { resizable: true },
    ]);

    const [errorObj, setErrorObj] = React.useState({});
    const [gridColumns, setGridColumns] = useState([]);
    //state to set and get limit, offset

    const { resultSettingData } = useSelector(
      resultSettingSelector,
      shallowEqual
    );

    const { unitTypeList } = useSelector(
      fetchUnitTypeDataSelector,
      shallowEqual
    );

    //state to set and get filter, sort number, sort order
    const [filterData, setFilterData] = useState({
      isSort: false,
      isFilter: false,
      data: "",
      in_sort_number: DEFAULT_SORT.number,
      sort_order: DEFAULT_SORT.order,
    });

    const [isReady, setIsReady] = useState(false);

    const getDataTypes = async () => {
      let data = {
        in_filter: "AND (a.system_unit_name NOT LIKE 'fdm_%')",
        in_sort_number: null,
        in_sort_order: null,
        in_page_row_offset: 0,
        in_page_rows: 9999,
      };
      let res = await GetUnitType(data, dispatch);
      if (res.length > 0) {
        dispatch(setUnitTypeData(res));
      }
    };

    useEffect(() => {
      if (!isModal) {
        setPanes([
          {
            size: "35%",
            min: "30%",
            collapsible: true,
            scrollable: false,
          },
          {
            scrollable: false,
            min: "50%",
          },
        ]);
      }
      return () => {
        timer && clearTimeout(timer);
      }
    }, [isModal]);

    useEffect(() => {
      if (!unitTypeList) {
        getDataTypes();
      }
    }, [unitTypeList]);

    useEffect(() => {
      if (columns.length > 0) {
        setGridColumns(columns);
      }
    }, [columns]);

    const getPageTake = () => {
      return isModal ? pageData.take : pageData.take;
    };

    const getPageSkip = () => {
      return pageData.skip;
    };

    const getInSortNumber = () => {
      return filterData.isSort
        ? filterData.in_sort_number
        : DEFAULT_SORT.number;
    };

    const getInSortOrder = () => {
      return filterData.isSort ? filterData.sort_order : DEFAULT_SORT.order;
    };
    const getInFilter = () => {
      let formulaFilter;
      if (isModal) {
        switch (pageProps.filterType) {
          case "SystemFx":
            formulaFilter = " AND ( (a.formula_type = 'boolean') )";
            break;
          case "RateTierFx":
            formulaFilter = " AND ( (a.formula_type = 'rate table') )";
            break;

          default:
            formulaFilter = " AND ( (a.formula_type <> 'boolean') )";
            break;
        }
        return filterData.isFilter
          ? formulaFilter + filterData.data
          : formulaFilter;
      }

      return filterData.data;
    };
    const apiCallData = {
      in_filter: getInFilter(),
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
      in_db_role_key: cookiesData?.out_db_role_key,
      in_page_row_offset: 0,
      in_page_rows: defaultPageSize.recordTake,
    };

    const fetchSelectedSystemFxData = async (sysFx, sysFxExtValues) => {
      const response = await GetSeletedSystemFxData(
        {
          in_filter: `AND a.system_fx_mstr_key = '${sysFx?.system_fx_mstr_key}'`,
          in_sort_number: 6,
          in_sort_order: "ASC",
          in_page_row_offset: 0,
          in_page_rows: 9999,
        },
        dispatch
      );

      const newObj = {
        key: "system_fx",
        value: response,
        name: sysFx.system_fx_label,
        desc: sysFx.system_fx_desc,
        system_fx_name: sysFx.system_fx_name,
        uId: uuidv4(),
        isProcessing: true,
      };

      const sysFxValues = response.map((it, index) => {
        return {
          ...it,
          system_fx_name: sysFx.system_fx_name,
          data: [],
          elementId: uuidv4(),
          value: sysFxExtValues
            ? sysFxExtValues[index]
            : it.input_control_type === "number"
              ? 0
              : "",
          error: false,
          errorMsg: "",
          disabled: false,
        };
      });

      return { ...newObj, value: sysFxValues };
    };

    const fetchSelectedFx = async (selectedSystemFx) => {
      if (selectedSystemFx?.system_fx_mstr_key) {
        const val = await fetchSelectedSystemFxData(selectedSystemFx);
        setTempFormulaData((prevItems) => [...prevItems, val]);
        scrollToLastElement(val.uId);
      }
    };

    useEffect(() => {
      const dataVal = {
        ...apiCallData,
        in_filter: getInFilter(),
        in_sort_number: getInSortNumber(),
        in_sort_order: getInSortOrder(),
        in_page_row_offset: pageData.skip,
        in_page_rows: pageData.take,
        in_load_type: !isReady ? "grid-reload" : "",
      };
      getGridData(
        dataVal,
        !isReady
          ? (pageProps.defaultKey ? pageProps.defaultKey : null) ||
          (isModal ? resultSettingData.formula_mstr_key : null)
          : null
      );
    }, [filterData, isModal]);

    useEffect(() => {
      const fetchPredefinedData = async () => {
        const systemFxRes = await fetchSystemFxData(dispatch);
        await setSystemFxData(systemFxRes);
        await fetchCustomTableInputFieldData(dispatch);
        setIsReady(true);
      };
      fetchPredefinedData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const saveOrUpdateFormula = async () => {
      const updateData = data.filter((it) => it.inIteamEdit);
      const item =
        updateData && updateData.length > 0 ? updateData[0] : selectedFormula;
      const isValid = await handleValidation();
      if (!isValid) return;

      const newFormulas = changeRuleConditionFlags(item.formula_type);
      const { error, xml } = prepareFormulaXml(newFormulas);
      if (error) {
        setErrorPopup(true);
        return;
      }
      setErrorPopup(false);

      const result = XMLValidator.validate(xml, {
        allowBooleanAttributes: true,
      });
      if (!result?.err) {
        // call update here...

        const bodyData = {
          in_user_id: cookiesData.out_user_id,
          in_tenant_id: cookiesData.out_tenant_id,
          in_formula_mstr_key: item.formula_mstr_key,
          in_formula_name: item.formula_name,
          in_formula_desc: item.formula_desc,
          in_formula_text: xml || item.formula_text,
          in_formula_type: item.formula_type,
          in_formula_unittype: item.system_unit_mstr_key,
        };

        if (item.formula_mstr_key === 0) {
          Action.addFormulaDataAsync(bodyData).then((res) => {
            if (res === "success") {
              onRefreshClick();
            }
          });
        } else {
          Action.updateFormulaDataAsync(bodyData).then((res) => {
            if (res === "success") {
              setErrorObj({});
              changeCancelBtnState("disable", "grid-formula-cancel");
              changeUpdateBtnState("disable", "grid-formula-update");

              changeUpdateBtnState("enable", "dialog-btn_1");
              const newData = data.map((it) => {
                if (it.formula_mstr_key === item.formula_mstr_key) {
                  return {
                    ...it,
                    ...item,
                    formula_text: xml || item.formula_text,
                    inEdit: false,
                    inIteamEdit: false,
                    selected: true,
                  };
                } else {
                  return {
                    ...it,
                    selected: false,
                    inEdit: false,
                    inIteamEdit: false,
                  };
                }
              });
              setOriginalData(newData);
              setSelectedFormula({
                ...item,
                formula_text: xml || item.formula_text,
                isEdit: true
              });
              setData(newData);
              setEditData("");
              //onRefreshClick(false, null, false);
            }
          });
        }
      } else {
        setErrorPopup(true);
      }
    };

    const onCloseErrorPopup = () => {
      setErrorPopup(false);
    };

    const ERROR_POPUP_ACTIONS = [
      {
        name: "OK",
        onClick: onCloseErrorPopup,
      },
    ];

    useImperativeHandle(
      ref,
      () => {
        return {
          chooseFormula: () => {
            if (!selectedFormula) return;

            if (
              pageProps.filterType === "RateTierFx" ||
              pageProps.filterType === "SystemFx"
            ) {
              return selectedFormula;
            } else {
              dispatch(
                setResultSettingData({
                  resultSettingData: {
                    ...resultSettingData,
                    value_fx: selectedFormula.formula_name,
                    value: null,
                    formula_mstr_key: selectedFormula.formula_mstr_key,
                    formula_name: selectedFormula.formula_name,
                  },
                })
              );
              if (pageProps.filterType === "valueFx") {
                return selectedFormula;
              }
            }
          },
        };
      },
      [data, selectedFormula]
    );

    const prepareFormulaXml = (finalFormulas) => {
      const resultData = validateFormulaObjects(finalFormulas);
      let error = resultData.find((item) => item.error) !== undefined;

      // we need to have atleast one key other than paren so we will
      const keysData = [
        "system_fx",
        "logical_op",
        "math_op",
        "rfct_field",
        "number",
        "number_in",
        "string",
        "string_in",
        "date",
      ];
      const formulaKeys = resultData.map((it) => it.key);
      const idx = formulaKeys.findIndex((i) => keysData.includes(i)) === -1;
      const parenIdx = formulaKeys.findIndex((it) => it === "(");
      if (parenIdx >= 0) {
        const nextEl =
          formulaKeys.length > parenIdx ? formulaKeys[parenIdx + 1] : "";
        error = error || (nextEl && nextEl === ")");
      }
      error = error || idx;
      if (error) {
        return { xml: "", error: true };
      }

      let subTag = "";
      resultData.forEach((item) => {
        if (item.key === "system_fx") {
          let str = `<system_fx>${item.system_fx_name}(`;
          item.value.forEach((val) => {
            if (val.input_control_type === "select") {
              let valueStr = val.value
                ? typeof val.value.value === "number"
                  ? `${val.value.value},`
                  : `'${val.value.value?.replace(/['"]+/g, "")}',`
                : `'',`;

              str += valueStr;
            } else if (val.input_control_type === "number") {
              str += `${Number(val.value || 0)},`;
            } else if (val.input_control_type === "string") {
              str += `'${val.value?.replace(/['"]+/g, "")}',`;
            } else {
              str += val.value
                ? `'${val.value?.replace(/['"]+/g, "")}',`
                : "'',";
            }
          });
          str += "@out_ret_code,@out_error_code,@out_error_msg)</system_fx>";
          subTag += str;
        } else {
          subTag = setupXmlValue(subTag, item.key, item.value);
        }
      });

      return { xml: `<udf>${subTag}</udf>`, error: false };
    };

    useEffect(() => {
      if (selectedFormula && isReady && !selectedFormula?.isEdit) {
        setupInitialData(selectedFormula);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isReady, selectedFormula]);

    useEffect(() => {
      //if (unsavedPopup.isUnSavedChange && savePressed) {
      if (savePressed) {
        saveOrUpdateFormula();
        setSavePressed(false);
      }
    }, [savePressed]); // [savePressed, unsavedPopup.isUnSavedChange]);

    const remove = (ids) => {
      setConfirmationPopup({
        ...confirmationPopup,
        title: "Delete",
        desc: "Are you sure you want to delete this record?",
        isVisible: true,
        type: "DELETE_CONFIRM",
        actionBtn: DELETE_BTN_ACTIONS(onCancelConfirm, () => {
          setErrorObj({});
          onDeleteConfirm(ids);
        }),
        data: [],
        action: "Delete",
      });
    };

    const onDeleteConfirm = (ids) => {
      if (Number(ids[0].id) === 0) {
        const newData = data.filter((it) => it.formula_mstr_key !== 0);
        setData(newData);
        setConfirmationPopup({
          ...confirmationPopup,
          isVisible: false,
          type: "",
          actionBtn: [],
          data: {},
          action: "",
        });
        return;
      }

      let deleteData = {
        in_user_id: cookiesData.out_user_id,
        in_formula_mstr_key: ids,
        in_tenant_id: cookiesData.out_tenant_id,
      };
      Action.deleteDataAsync(deleteData).then(function (result) {
        if (result === "success") {
          onRefreshClick(true);
        }
      });
    };

    const onSelectionChange = (event) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: "formula_mstr_key",
      });

      let newData = [...data];
      let newKeys = Object.keys(newSelectedState);
      let oldKeys = Object.keys(selectedState);
      //const isSame = isSameRow(oldKeys, newKeys);

      // if (unsavedPopup.isUnSavedChange && !isSame) {
      //   dispatch(
      //     setUnSavedChangesPopup({
      //       ...unsavedPopup,
      //       isVisible: true,
      //       callbackFn: () => {
      //         newData = originalData;
      //         newData = newData.map((it) => ({
      //           ...it,
      //           selected: newKeys.includes(it.formula_mstr_key.toString()),
      //         }));
      //         const idx = newData.findIndex(
      //           (it) =>
      //             it.formula_mstr_key === Number(newKeys[newKeys.length - 1])
      //         );

      //         if (idx >= 0 && newData[idx]) {
      //           setSelectedFormula(newData[idx]);
      //         }
      //         setSelectedState({ [Number(newKeys[newKeys.length - 1])]: true });
      //         setData(newData);
      //       },
      //     })
      //   );
      //   return;
      // }

      const newKey = newKeys.filter((val) => !oldKeys.includes(val));

      const foundItem = newData.find(
        (it) => Number(it.formula_mstr_key) === Number(newKey[0])
      );
      newData = newData.map((it) => ({
        ...it,
        selected: newKeys.includes(it.formula_mstr_key.toString()),
      }));

      if (foundItem) {
        setSelectedFormula(foundItem);
      }

      setSelectedState(newSelectedState);
      setData(newData);
    };

    const onCancelConfirm = (action) => {
      setConfirmationPopup({
        ...confirmationPopup,
        isVisible: false,
        type: "",
        actionBtn: [],
        data: {},
        action: "",
      });
      if (action === "outSideClick") {
        // dispatch(
        //   setUnSavedChangesPopup({ ...unsavedPopup, isUnSavedChange: true })
        // );
      }
    };

    const handleColumnMenu = (columnMenuData, isFilter, isSort, filterObj) => {

      if (isSort) {
        setSortField(columnMenuData);
      } else {
        setFilterField(filterObj);
      }

      setPage({ skip: 0, take: isModal ? 9999 : defaultPageSize.recordTake });
      let index = 1;

      if (data.length > 0 && columnMenuData[0] !== undefined) {
        let obj = Object.keys(data[0]);
        let sortNameField = "";

        //replace mstr key with name field only when sorting is applied
        if (
          columnMenuData[0].field &&
          columnMenuData[0].field.indexOf("_mstr_key")
        ) {
          let sortKeyField = columnMenuData[0].field;
          sortNameField = sortKeyField.replace("_mstr_key", "_name");
        }
        const sortOriginField =
          sortNameField !== "" ? sortNameField : columnMenuData[0].field;

        index = obj.findIndex((x) => x === sortOriginField) + 1;
      }

      const filterString = isFilter
        ? getfilterDataString(columnMenuData, gridColumns)
        : filterData.data;

      isFilter = filterString !== "";

      let sortDir =
        filterData.isSort && columnMenuData.length
          ? filterData.sort_order
          : DEFAULT_SORT.order;
      let sortNumber =
        filterData.isSort && columnMenuData.length
          ? filterData.in_sort_number
          : DEFAULT_SORT.number;

      isSort = !columnMenuData.length ? false : filterData.isSort;

      if (
        columnMenuData[0] !== undefined &&
        columnMenuData[0].dir !== undefined
      ) {
        isSort = true;
        sortDir = columnMenuData[0].dir.toUpperCase();
        sortNumber = index;
      }
      setFilterData({
        ...filterData,
        isFilter: isFilter,
        isSort: isSort,
        data: filterString,
        in_sort_number: sortNumber,
        sort_order: sortDir,
      });
    };

    const handleValidation = () => {
      let isValid = true;

      data
        .filter((x) => x.inEdit)
        .map((item) => {
          const {
            formula_name,
            formula_desc,
            formula_type,
            system_unit_mstr_key,
            rowId,
          } = item;
          let errorObj = {
            formula_name: "",
            formula_desc: "",
            formula_type: "",
            system_unit_mstr_key: "",
          };

          if (formula_name.trim() === "") {
            errorObj.formula_name = "Please enter formula name";
          } else {
            errorObj.formula_name = "";
          }
          // if (formula_desc.trim() === "") {
          //   errorObj.formula_desc = "Please enter formula description.";
          // } else {
          //   errorObj.formula_desc = "";
          // }
          if (formula_type.trim() === "") {
            errorObj.formula_type = "Please select formula type.";
          } else {
            errorObj.formula_type = "";
          }
          if (system_unit_mstr_key === 0) {
            errorObj.system_unit_mstr_key = "Please select data type.";
          } else {
            errorObj.system_unit_mstr_key = "";
          }

          let obj = {};

          for (const [key, value] of Object.entries(errorObj)) {
            obj[`${key + rowId}`] = `${value}`;
          }

          setErrorObj(obj);

          for (const [, value] of Object.entries(obj)) {
            if (value !== "") {
              isValid = false;
            }
          }

          return 0;
        });

      return isValid;
    };

    const ActionCell = React.useCallback(
      (props) => {
        const { ariaColumnIndex, columnIndex, dataItem, render } = props;

        const defaultRendering = (
          <td
            aria-colindex={ariaColumnIndex}
            data-grid-col-index={columnIndex}
            className="text-center"
          >
            <span
              title="Linked Rules"
              style={{ marginLeft: 5, marginRight: 5 }}
              className="k-icon k-font-icon k-i-hyperlink-open pointer-class"
              onPointerDown={() => {
                if (pageProps.pageProps.writeAccess === 0) {
                  return false;
                } else {
                  // if (unsavedPopup.isUnSavedChange) {
                  //   dispatch(
                  //     setUnSavedChangesPopup({
                  //       ...unsavedPopup,
                  //       isVisible: true,
                  //       callbackFn: () => {
                  //         dispatch(
                  //           setLinkedRules({
                  //             key: "Compensation_Rules",
                  //             data: {
                  //               formula_mstr_key: dataItem.formula_mstr_key,
                  //               title: `Formula Name = ${dataItem.formula_name}`,
                  //               type: "formula",
                  //             },
                  //           })
                  //         );
                  //         if (isModal) {
                  //           pageProps?.closePopup();
                  //         } else {
                  //           // if not then we have to navigate to page..
                  //           history.push("/rules_library");
                  //         }
                  //       },
                  //     })
                  //   );
                  // } else {
                  dispatch(
                    setLinkedRules({
                      key: "Compensation_Rules",
                      data: {
                        formula_mstr_key: dataItem.formula_mstr_key,
                        title: `Formula Name = ${dataItem.formula_name}`,
                        type: "formula",
                      },
                    })
                  );
                  if (isModal) {
                    pageProps?.closePopup();
                    history.push("/rules_library");
                  } else {
                    // if not then we have to navigate to page..
                    history.push("/rules_library");
                  }
                  //}
                }
              }}
            ></span>
          </td>
        );

        return render?.call(undefined, defaultRendering, props);
      },
      [data, isModal, pageProps] // unsavedPopup]
    );

    const changeRuleConditionFlags = (ruleType) => {
      const newFormulas = formulaData.map((item) => {
        if (item.key === "system_fx") {
          const fxItems = [...item.value];
          const idx = fxItems.findIndex(
            (it) => it.system_fx_parm_name === "rule_cond_flag"
          );
          if (idx >= 0) {
            fxItems[idx] = {
              ...fxItems[idx],
              value:
                ruleType === "rate table"
                  ? RULE_COND_FLAG[2]
                  : ruleType === "boolean"
                    ? RULE_COND_FLAG[1]
                    : RULE_COND_FLAG[0],
            };
          }

          return { ...item, value: fxItems };
        }
        return item;
      });
      return newFormulas;
      //setFormulaData(newFormulas);
    };

    const NameCell = React.useCallback(
      (props) => {
        const { ariaColumnIndex, columnIndex, dataItem, render } = props;
        let isInEdit = dataItem.inEdit;
        let value = props.field + dataItem.rowId;

        const handleChange = (e, props, value) => {
          if (props.onChange) {
            // dispatch(
            //   setUnSavedChangesPopup({ ...unsavedPopup, isUnSavedChange: true })
            // );
            changeCancelBtnState("enable", "grid-formula-cancel");
            changeUpdateBtnState("enable", "grid-formula-update");

            changeUpdateBtnState("disable", "dialog-btn_1");
            props.onChange({
              dataIndex: 0,
              dataItem: props.dataItem,
              field: props.field,
              syntheticEvent: e.syntheticEvent,
              value: e.target.value,
            });
          }
        };
        const defaultRendering = (
          <td
            aria-colindex={ariaColumnIndex}
            data-grid-col-index={columnIndex}
            title={dataItem[props.field]}
          >
            {isInEdit ? (
              <Input
                autoFocus={dataItem.formula_mstr_key === 0 && columnIndex === 1}
                value={dataItem[props.field]}
                onChange={(e) => {
                  handleChange(e, props, value);
                }}
                name={`${props.field}`}
              />
            ) : (
              <span title={props.dataItem[props.field || ""]}>
                {props.dataItem[props.field || ""]}
              </span>
            )}
            {Object.keys(errorObj).length > 0 ? (
              <div role="alert" className="k-form-error k-text-start">
                {Object.keys(errorObj).length > 0 ? errorObj[value] : ""}
              </div>
            ) : (
              false
            )}
          </td>
        );
        return render?.call(undefined, defaultRendering, props);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [errorObj]
    );
    const DropDownCell = React.useCallback(
      (props) => {
        const { ariaColumnIndex, columnIndex, dataItem, field, render } = props;

        let value = dataItem[field || ""];
        let fieldRow = field + dataItem.rowId;
        let valueData = "";
        switch (field) {
          case "formula_type":
            valueData =
              pageProps.filterType === "RateTierFx"
                ? FORMULA_TYPE.filter((it) => it.text === "rate table")
                : pageProps.filterType === "SystemFx"
                  ? FORMULA_TYPE.filter((it) => it.text === "boolean")
                  : isModal
                    ? FORMULA_TYPE.filter((it) => it.text !== "boolean")
                    : FORMULA_TYPE;
            break;
          case "system_unit_mstr_key":
            valueData =
              unitTypeList?.map((it) => ({
                text: it.system_unit_name,
                value: it.system_unit_mstr_key,
              })) || [];
            break;
          default:
            break;
        }
        if (field.indexOf("_mstr_key") !== -1) {
          value = dataItem[field.replace("_mstr_key", "_name") || ""];
        }
        const onChange = (e) => {
          changeCancelBtnState("enable", "grid-formula-cancel");
          changeUpdateBtnState("enable", "grid-formula-update");
          changeUpdateBtnState("disable", "dialog-btn_1");
          if (props.onChange) {
            props.onChange({
              dataIndex: 0,
              dataItem: props.dataItem,
              field: props.field.replace("_mstr_key", "_name"),
              syntheticEvent: e.syntheticEvent,
              value: e.target.value.text,
            });
            if (props.field === "system_unit_mstr_key") {
              props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: e.syntheticEvent,
                value: e.target.value.value,
              });
            } else if (props.field === "formula_type") {
              if (e.target.value.value === "boolean") {
                props.onChange({
                  dataIndex: 0,
                  dataItem: props.dataItem,
                  field: "system_unit_mstr_key",
                  syntheticEvent: e.syntheticEvent,
                  value: unitTypeList.find(
                    (it) => it.system_unit_name === "Boolean"
                  ).system_unit_mstr_key,
                });
                props.onChange({
                  dataIndex: 0,
                  dataItem: props.dataItem,
                  field: "system_unit_name",
                  syntheticEvent: e.syntheticEvent,
                  value: unitTypeList.find(
                    (it) => it.system_unit_name === "Boolean"
                  ).system_unit_name,
                });
              } else if (e.target.value.value === "string") {
                props.onChange({
                  dataIndex: 0,
                  dataItem: props.dataItem,
                  field: "system_unit_mstr_key",
                  syntheticEvent: e.syntheticEvent,
                  value: unitTypeList.find(
                    (it) => it.system_unit_name.toLowerCase() === "rate tier"
                  ).system_unit_mstr_key,
                });
                props.onChange({
                  dataIndex: 0,
                  dataItem: props.dataItem,
                  field: "system_unit_name",
                  syntheticEvent: e.syntheticEvent,
                  value: unitTypeList.find(
                    (it) => it.system_unit_name.toLowerCase() === "rate tier"
                  ).system_unit_name,
                });
              } else if (e.target.value.value === "number") {
                props.onChange({
                  dataIndex: 0,
                  dataItem: props.dataItem,
                  field: "system_unit_mstr_key",
                  syntheticEvent: e.syntheticEvent,
                  value: unitTypeList.find(
                    (it) => it.system_unit_name.toLowerCase() === "usd"
                  ).system_unit_mstr_key,
                });
                props.onChange({
                  dataIndex: 0,
                  dataItem: props.dataItem,
                  field: "system_unit_name",
                  syntheticEvent: e.syntheticEvent,
                  value: unitTypeList.find(
                    (it) => it.system_unit_name.toLowerCase() === "usd"
                  ).system_unit_name,
                });
              }
              // const newFormulas = changeRuleConditionFlags(e.target.value.value);
              // setFormulaData(newFormulas);
            }
          }
        };

        const defaultRendering = (
          <td aria-colindex={ariaColumnIndex} data-grid-col-index={columnIndex}>
            {dataItem.inEdit ? (
              <DropDownList
                className="dropdown-item-formula"
                //opened={field === isOpen.field && isOpen.open}
                onChange={onChange}
                value={valueData && valueData.find((c) => c.text === value)}
                data={valueData}
                textField="text"
                dataItemKey="value"
                placeholder={"Select"}
              />
            ) : (
              <span title={value}>{value}</span>
            )}

            {Object.keys(errorObj).length > 0 ? (
              <div role="alert" className="k-form-error k-text-start">
                {Object.keys(errorObj).length > 0 ? errorObj[fieldRow] : ""}
              </div>
            ) : (
              false
            )}
          </td>
        );
        return render?.call(undefined, defaultRendering, props);
      },
      [unitTypeList, errorObj, pageProps.filterType, isModal]
    );

    const colRenders = (columnList) => {
      return columnList.map((column, idx) => {
        return column.show && column.title !== "" ? (
          <Column
            {...columnMenuProps(column)}
            key={idx}
            cell={
              column.field === "expand"
                ? ActionCell
                : column.field === "system_unit_mstr_key" ||
                  column.field === "formula_type"
                  ? DropDownCell
                  : NameCell
            }
            sortable={column.field === "expand" ? false : true}
            filterable={
              column.field === "expand" ? false : column.filterable == 1
            }
            reorderable={column.field !== "expand"}
            width={
              column.field === "expand"
                ? 80
                : column.width
                  ? column.width
                  : undefined
            }
          />
        ) : (
          false
        );
      });
    };

    let GroupColumn = React.useMemo(
      () => colRenders(gridColumns),
      [gridColumns, errorObj]
    );


    const onClearFilter = async () => {
      setAPICallingState(true);

      setPage({ skip: 0, take: isModal ? 9999 : defaultPageSize.recordTake });
      //set filter to false
      setFilterData({
        ...filterData,
        data: "",
        isFilter: false,
        sort_order: getInSortOrder(),
        in_sort_number: getInSortNumber(),
      });
    };

    const getGridData = async (dataVal, defaultKey = null, isReset = false) => {
      setErrorObj({});
      await Action.getListDataAsync(dataVal, defaultKey, isReset);
      changeCancelBtnState("disable", "grid-formula-cancel");
      changeUpdateBtnState("disable", "grid-formula-update");
      changeUpdateBtnState("enable", "dialog-btn_1");
    };

    const handlePageChange = async (event) => {
      const pageData = event.page;

      setAPICallingState(true);

      setPage({ skip: pageData.skip, take: isModal ? 9999 : pageData.take });

      const dataVal = {
        ...apiCallData,
        in_filter: getInFilter(),
        in_sort_number: getInSortNumber(),
        in_sort_order: getInSortOrder(),
        in_page_row_offset: pageData.skip,
        in_page_rows: isModal ? pageData.take : pageData.take,
      };

      getGridData(dataVal);
    };

    const onRefreshClick = (isReset = false, defaultKey = null) => {
      setAPICallingState(true);

      const dataVal = {
        ...apiCallData,
        in_filter: getInFilter(),
        in_sort_number: getInSortNumber(),
        in_sort_order: getInSortOrder(),
        in_page_row_offset: getPageSkip(),
        in_page_rows: getPageTake(),
      };
      getGridData(dataVal, defaultKey, isReset);
    };

    const validateFormulaObjects = (finalFormulas) => {
      const updatedFormulaData = finalFormulas.map((item) => {
        if (item.key === "system_fx") {
          const fxItems = [...item.value];
          let sysFxErr = false;
          const updatedValues = fxItems.map((it) => {
            if (it.is_required) {
              if (
                it.input_control_type === "number" &&
                (it.value === "" || isNaN(it.value))
              ) {
                it.error = true;
                it.errorMsg = "Please enter valid number.";
                sysFxErr = true;
              } else if (
                it.input_control_type === "select" &&
                it.system_fx_parm_name !== "rule_cond_flag" &&
                // it.system_fx_parm_name !== "input_field1" &&
                (!it.value || !it.value?.value)
              ) {
                it.error = true;
                it.errorMsg = `${it.system_fx_parm_label} is required.`;
                sysFxErr = true;
              } else if (
                it.input_control_type !== "number" &&
                it.input_control_type !== "select" &&
                (!it.value || !it.value.trim())
              ) {
                it.error = true;
                it.errorMsg = `${it.system_fx_parm_label} is required.`;
                sysFxErr = true;
              }
            }
            return it;
          });
          return { ...item, value: updatedValues, error: sysFxErr };
        } else if (
          item.key === "number" &&
          (item.value === "" || isNaN(item.value))
        ) {
          return {
            ...item,
            error: true,
            errorMsg: "Please enter valid number.",
          };
        } else if (item.key === "number_in") {
          if (!item.value) {
            return {
              ...item,
              error: true,
              errorMsg: "Insert multiple numbers required.",
            };
          } else if (!MULTIPLE_NUMBER_REGEX.test(item.value)) {
            return {
              ...item,
              error: true,
              errorMsg: "Invalid multiple numbers",
            };
          }
        } else if (item.key === "string" && !item.value?.toString()?.trim()) {
          return { ...item, error: true, errorMsg: "Insert string required." };
        } else if (item.key === "string_in") {
          if (!item.value?.trim()) {
            return {
              ...item,
              error: true,
              errorMsg: "Insert multiple string required.",
            };
          } else if (!MULTIPLE_STRING_REGEX.test(item.value)) {
            return {
              ...item,
              error: true,
              errorMsg: "Invalid multiple string.",
            };
          }
        } else if (item.key === "date" && !item.value) {
          return { ...item, error: true, errorMsg: "Please enter valid date." };
        }
        return item;
      });
      setFormulaData(updatedFormulaData);
      return updatedFormulaData;
    };

    const setupInitialData = (formula) => {
      setTempFormulaData([]);
      setFormulaData([]);
      debounceForFunc(() => {
        const jsonData = parser.parse(formula.formula_text, {
          allowBooleanAttributes: true,
        });
        // now we will parse through the object and check what field we have and based on that data we will save.
        setupInitObjects(jsonData[0].udf);
      }, 200);
    };

    const setupInitObjects = async (json) => {
      let arr = [];
      const parseJS = (jsData) => {
        jsData.forEach((it) => {
          let key = Object.keys(it)[0];
          let values = Object.values(it)[0];
          arr.push({
            key: key,
            value: values.length > 0 ? values[0]["#text"] : "",
          });
          if (key === "paren") {
            const parenItems = Object.values(it)[0];
            if (parenItems.length > 0) {
              parseJS(parenItems);
            }
            arr.push({ key: "paren_close", value: "paren_close" });
          }
        });
      };

      parseJS(json);

      // now we will loop through whole arr and setup initial object.
      const finalArr = await Promise.all(
        arr.map(async (item) => {
          if (item.key === "paren")
            return {
              key: "(",
              value: "(",
              uId: uuidv4(),
              error: false,
              errorMsg: "",
              isProcessing: false,
            };
          else if (item.key === "paren_close")
            return {
              key: ")",
              value: ")",
              uId: uuidv4(),
              error: false,
              errorMsg: "",
              isProcessing: false,
            };
          else if (item.key === "number")
            return {
              key: "number",
              value: item.value,
              uId: uuidv4(),
              error: false,
              errorMsg: "",
              isProcessing: false,
            };
          else if (item.key === "number_in")
            return {
              key: "number_in",
              value: item.value,
              uId: uuidv4(),
              error: false,
              errorMsg: "",
              isProcessing: false,
            };
          else if (item.key === "string")
            return {
              key: "string",
              value: item.value,
              uId: uuidv4(),
              error: false,
              errorMsg: "",
              isProcessing: false,
            };
          else if (item.key === "string_in")
            return {
              key: "string_in",
              value: item.value,
              uId: uuidv4(),
              error: false,
              errorMsg: "",
              isProcessing: false,
            };
          else if (item.key === "date")
            return {
              key: "date",
              value: new Date(item.value),
              uId: uuidv4(),
              error: false,
              errorMsg: "",
              isProcessing: false,
            };
          else if (item.key === "logical_op")
            return {
              key: "logical_op",
              value: item.value,
              uId: uuidv4(),
              error: false,
              errorMsg: "",
              isProcessing: true,
            };
          else if (item.key === "math_op")
            return {
              key: "math_op",
              value: item.value,
              uId: uuidv4(),
              error: false,
              errorMsg: "",
              isProcessing: true,
            };
          else if (item.key === "rfct_field") {
            return {
              key: "rfct_field",
              value: item.value,
              uId: uuidv4(),
              error: false,
              errorMsg: "",
              isProcessing: true,
            };
          } else if (item.key === "system_fx") {
            const name = item.value.substring(0, item.value.indexOf("("));
            const sysFxValues = item.value
              .substring(item.value.indexOf("(") + 1, item.value.indexOf(")"))
              .split(",")
              .map((it) =>
                it?.charAt(0) === "'" ? it.substring(1, it.length - 1) : it
              );

            const sysFx = systemFxData.find((it) => it.system_fx_name === name);
            if (sysFx) {
              const formulaFx = await fetchSelectedSystemFxData(
                sysFx,
                sysFxValues
              );
              return formulaFx;
            }
          }
          return null;
        })
      );

      setTempFormulaData(finalArr);
    };

    useEffect(() => {
      if (tempFormulaData.length) {
        for (let i = 0; i < tempFormulaData.length; i++) {
          let tempFormula = tempFormulaData[i];
          const foundIn = formulaData.find((it) => it.uId === tempFormula.uId);
          if (foundIn && foundIn.isProcessing) {
            break;
          } else if (foundIn && !foundIn.isProcessing) {
            continue;
          } else {
            debounceForFunc(() => {
              setFormulaData((prevItems) => [...prevItems, tempFormula]);
              scrollToLastElement(tempFormula.uId);
            }, 500);

            break;
          }
        }
      }

      if (tempFormulaData.length === formulaData.length) {
        const idx = formulaData.findIndex((it) => it.isProcessing);

        setShowProcessing(idx >= 0);
      } else {
        setShowProcessing(true);
      }

    }, [tempFormulaData, formulaData]);

    const SortableItemUI = (props) => {
      const { attributes, dataItem, forwardRef, isActive, style, pageProps } =
        props;
      useEffect(() => {
        if (isActive) {
          selectedEl.current = dataItem.uId;
        }
      }, [isActive]);
      return (
        <div
          ref={forwardRef}
          {...attributes}
          className="dragcomponent"
          style={{
            ...style,
            width:
              dataItem.key === "(" ||
                dataItem.key === ")" ||
                dataItem.key === "system_fx"
                ? "100%"
                : "49%",
            display: "inline-block",
            height:
              dataItem.key === "(" ||
                dataItem.key === ")" ||
                dataItem.key === "system_fx"
                ? "auto"
                : "80px",
            verticalAlign: "bottom",
            border: isActive ? "2px dashed #00a1e4" : "2px dashed #ccc",
            padding: 5,
            marginBottom: 5,
            backgroundColor: isActive ? "#f6fbff" : "#fff",
          }}
          id={dataItem.uId}
        >
          {dataItem.key === "system_fx" ? (
            dataItem.value?.length ? (
              <div className="formulamastercont">
                <div className="sortable-itemui-sysfx-title-div">
                  <Tooltip
                    anchorElement="target"
                    position="top"
                    openDelay={100}
                  >
                    <span
                      style={{ textAlign: "center", alignSelf: "center" }}
                      title={dataItem.desc}
                    >
                      {dataItem.name}
                    </span>
                  </Tooltip>
                </div>
                <div className="sortable-itemui-sysfx-items-div">
                  <SystemFx
                    key={dataItem.uId}
                    sysFx={dataItem}
                    pageProps={pageProps}
                    onFxDataReceived={(fxItems) => {
                      setFormulaData((prevItems) =>
                        prevItems.map((it) =>
                          it.uId === dataItem.uId
                            ? { ...it, value: fxItems, isProcessing: false }
                            : it
                        )
                      );
                    }}
                  />
                </div>
              </div>
            ) : null
          ) : (
            <FormulaItemElements
              item={dataItem}
              key={dataItem.uId}
              dropDownItem={
                dataItem.key === "logical_op"
                  ? {
                    value: dataItem.value,
                    dataText: "operator_label",
                    dataKey: "operator_value",
                  }
                  : dataItem.key === "math_op"
                    ? {
                      value: dataItem.value,
                      dataText: "operator_label",
                      dataKey: "operator_value",
                    }
                    : dataItem.key === "rfct_field"
                      ? {
                        value: dataItem.value,
                        dataText: "udf_data_obj_field_label",
                        dataKey: "udf_data_obj_field_name",
                      }
                      : null
              }
              onDataPopulate={(data, uId, defaultVal) => {
                const idx = formulaData.findIndex((it) => it.uId === uId);
                const formulaNewData = [...formulaData];
                formulaNewData[idx] = {
                  ...formulaNewData[idx],
                  data: data,
                  value: defaultVal,
                  isProcessing: false,
                };
                setFormulaData(formulaNewData);
              }}
              onObjectChange={(updatedItem) => {
                const idx = formulaData.findIndex(
                  (it) => it.uId === updatedItem.uId
                );

                // dispatch(
                //   setUnSavedChangesPopup({
                //     ...unsavedPopup,
                //     isUnSavedChange: true,
                //   })
                // );
                changeCancelBtnState("enable", "grid-formula-cancel");
                changeUpdateBtnState("enable", "grid-formula-update");
                changeUpdateBtnState("disable", "dialog-btn_1");
                const formulaNewData = [...formulaData];
                formulaNewData[idx] = { ...updatedItem };

                setFormulaData(formulaNewData);
                // scrollToLastElement(updatedItem.uId);
              }}
              pageProps={pageProps}
            />
          )}
        </div>
      );
    };

    const scrollToLastElement = (id) => {
      timer = setTimeout(() => {
        const scrollableDiv =
          document.getElementsByClassName("formulaContainer")[0];
        if (scrollableDiv) {
          scrollableDiv.scrollTop = scrollableDiv?.scrollHeight || 0;
        }
      }, 0);
    };

    const AddNewLink = () => {
      const addNewFn = () => {
        setSelectedState({ 0: true });
        setErrorObj({});
        setTimeout(() => {
          const addNewFormulaRecord = {
            formula_mstr_key: 0,
            formula_name: "",
            formula_desc: "",
            formula_type: "",
            formula_text: "<udf></udf>",
            version: 1,
            system_unit_name: "",
            system_unit_mstr_key: 0,
            selected: true,
            inEdit: true,
            inIteamEdit: true,
          };
          // dispatch(
          //   setUnSavedChangesPopup({
          //     ...unsavedPopup,
          //     isUnSavedChange: true,
          //   })
          // );
          changeCancelBtnState("enable", "grid-formula-cancel");
          changeUpdateBtnState("enable", "grid-formula-update");
          changeUpdateBtnState("disable", "dialog-btn_1");
          const tempData = [...originalData];
          tempData.unshift(addNewFormulaRecord);

          setData(tempData.map((it, idx) => ({ ...it, rowId: idx })));
          //setData(tempData);
          setSelectedFormula(addNewFormulaRecord);
        });
      };

      return (
        <div>
          <a
            className="cta-small"
            onClick={() => {
              // if (unsavedPopup.isUnSavedChange) {
              //   dispatch(
              //     setUnSavedChangesPopup({
              //       ...unsavedPopup,
              //       isVisible: true,
              //       callbackFn: () => {
              //         addNewFn();
              //       },
              //     })
              //   );
              // } else {
              addNewFn();
              // }
            }}
          >
            <i className="fas fa-plus"></i> Add New Formula
          </a>
        </div>
      );
    };

    const copyFormulaItem = async () => {
      let result = "";

      result = await Action.addFormulaDataAsync({
        in_user_id: cookiesData.out_user_id,
        in_tenant_id: cookiesData.out_tenant_id,
        in_formula_mstr_key: selectedFormula.formula_mstr_key,
        in_formula_name: selectedFormula.formula_name,
        in_formula_desc: selectedFormula.formula_desc,
        in_formula_text: selectedFormula.formula_text,
        in_formula_type: selectedFormula.formula_type,
        in_formula_unittype: selectedFormula.system_unit_mstr_key,
      });

      if (result === "success") {
        onRefreshClick(true);
      }
    };

    const handleMenuItemClick = (module) => {
      switch (module) {
        case "dtuLogs":
          setShow({
            ...isShow,
            dtuLogs: !isShow.dtuLogs,
          });
          break;
        case "delete":
          const deleteArray = Object.keys(selectedState).map((item) => {
            return { id: item };
          });
          remove(deleteArray);
          break;
        case "duplicateRow":
          copyFormulaItem();
          break;
        default:
          break;
      }
    };

    const onRowDoubleClick = (props) => {
      if (pageProps.pageProps.writeAccess === 0) {
        return false;
      }
      const { dataItem } = props;
      enterEdit(dataItem);
      changeCancelBtnState("enable", "grid-formula-cancel");
      changeUpdateBtnState("enable", "grid-formula-update");
      changeUpdateBtnState("disable", "dialog-btn_1");
    };

    const enterEdit = (dataItem) => {
      if (pageProps.pageProps.writeAccess === 0) {
        return false;
      }
      if (editData) {
        editData.selected = editData.formula_mstr_key
          ? false
          : editData.selected;
        let newData = data.map((item) =>
          item.formula_mstr_key === dataItem.formula_mstr_key
            ? { ...item, inEdit: true }
            : { ...item, inEdit: false }
        );
        setData(newData);
      } else {
        let newData = data.map((item) =>
          item.formula_mstr_key === dataItem.formula_mstr_key
            ? {
              ...item,
              inEdit: true,
            }
            : item
        );
        setData(newData);
        delete dataItem.inEdit;
        setEditData(dataItem);
      }
    };

    const customCellRender = (td, props) => {
      return (
        <CellRender
          navigatable={true}
          originalProps={props}
          td={td}
          enterEdit={enterEdit}
          editField={editField}
          updateActBtn="grid-formula-update"
          cancelActBtn="grid-formula-cancel"
        />
      );
    };

    const itemChange = React.useCallback(
      (event) => {
        const field = event.field || "";
        const newData = data.map((item) =>
          item.formula_mstr_key === event.dataItem.formula_mstr_key
            ? { ...item, [field]: event.value, inIteamEdit: true }
            : item
        );
        let editItem = event.dataItem;
        editItem[field] = event.value;
        setData(newData);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [data]
    );

    const onCancelEditData = () => {
      changeCancelBtnState("disable", "grid-formula-cancel");
      changeUpdateBtnState("disable", "grid-formula-update");
      changeUpdateBtnState("enable", "dialog-btn_1");
      setErrorObj({});
      const foundItem = originalData.find(
        (it) => selectedFormula.formula_mstr_key === it.formula_mstr_key
      );
      if (!foundItem && originalData.length) {
        setSelectedState({ [originalData[0].formula_mstr_key]: true });
        setSelectedFormula(originalData[0]);
        setupInitialData(originalData[0]);
        setData(
          originalData.map((it, idx) => ({ ...it, selected: idx === 0 }))
        );
      } else if (foundItem) {
        setSelectedState({ [selectedFormula.formula_mstr_key]: true });
        setSelectedFormula(foundItem);
        setupInitialData(foundItem);
        setData(
          originalData.map((it, idx) => ({
            ...it,
            selected: it.formula_mstr_key === selectedFormula.formula_mstr_key,
          }))
        );
      } else {
        setData(originalData);
      }
    };

    const prepareColumnString = (colData) => {
      return executeGetColumnOrderInfo(colData);
    };

    const columnReorderData = async (columnData) => {
      const str = prepareColumnString(
        columnData.filter((x) => x.field !== "expand")
      );

      let reqData = {
        in_db_name: "procare_system",
        in_tenant_id: cookiesData.out_tenant_id,
        in_filter_logical_cond: "N",
        in_filter_label_cond: "N",
        in_column_info: str,
      };

      await updateColumnReorderAsync(reqData, dispatch, history);
    };

    const handleUpload = (e) => {
      setShow({
        ...isShow,
        dtuUpload: !isShow.dtuUpload,
      });
      e.preventDefault();
    };

    if (document.getElementById("grid-formula-cancel")) {
      document.getElementById("grid-formula-cancel").onclick = () => {
        onCancelEditData();
      };
    }
    if (document.getElementById("grid-formula-update")) {
      document.getElementById("grid-formula-update").onpointerdown = () => {
        document.activeElement?.blur();
        setSavePressed(true);
      };
    }

    const headerConfig = {
      title: isModal ? "Formula Configuration" : "Formula Master",
      id: "formula_mstr_key",
      add_new_fx: AddNewLink,
      buttons: { template_fx: GridButtonFormula },
      upload_fx: handleUpload,
      action_menu: {
        dtuLog: { fx: handleMenuItemClick },
        delete: { fx: handleMenuItemClick },
        duplicateRow: {
          fx: handleMenuItemClick,
          caption: "Duplicate Selected Row",
        },
      },
    };
    const parent = document.querySelector("#content-" + headerConfig.id);
    if (parent) {
      const deleteRows = parent.querySelector(".delete-rows");
      const copyRow = parent.querySelector(".copy-data");

      deleteRows && deleteRows.disableKendoMenu();
      copyRow && copyRow.disableKendoMenu();

      if (Object.keys(selectedState).length > 0) {
        if (selectedFormula && selectedFormula.formula_mstr_key !== 0) {
          deleteRows && deleteRows.enableKendoMenu();
        }
        if (
          selectedFormula &&
          selectedFormula.formula_mstr_key !== 0 &&
          Object.keys(selectedState).length === 1
        ) {
          copyRow && copyRow.enableKendoMenu();
        }
      }
    }

    const leftPane = () => {
      return (

        <div className="content-body">
          <div className="dtu-file-container">
            {gridColumns.length > 0 ? (
              <DataTable
                isAPICalling={isAPICalling}
                initialLoading={initialLoading}
                data={data}
                autoScrollIndex={autoScrollIndex}
                id="formula-grid"
                isPagination={!isModal}
                pageData={pageData}
                defaultPageSize={defaultPageSize}
                pageSize={pageSize}
                setPageSize={setPageSize}
                handlePageChange={handlePageChange}
                editField={editField}
                dataCount={dataCount}
                customColumn={GroupColumn}
                columns={gridColumns}
                cellRender={customCellRender}
                itemChange={itemChange}
                dataItemKey={"formula_mstr_key"}
                gridHeight={isModal ? panes[0].size : "auto"}
                module={"group"}
                reorderable={pageProps?.writeAccess === 0 ? false : true}
                handleColumnMenu={handleColumnMenu}
                refreshClick={onRefreshClick}
                onClearFilter={onClearFilter}
                onRowDoubleClick={onRowDoubleClick}
                onSelectionChange={onSelectionChange}
                columnReorderData={columnReorderData}
                initialFilter={filterField}
                initialSort={sortField}
              />
            ) : (
              <div style={{ height: "50vh" }}><LoadingPanel /></div>
            )}
          </div>
        </div>

      );
    };

    const rightPane = () => {
      return (
        <div className="content-body">
          {data.length > 0 && isReady ? (
            <>
              {showProcessing ? <LoadingPanel /> : null}
              <FormulaToolbar
                sysFxData={systemFxData}
                onSelectedSystemFx={(sysFx) => {
                  // dispatch(
                  //   setUnSavedChangesPopup({
                  //     ...unsavedPopup,
                  //     isUnSavedChange: true,
                  //   })
                  // );

                  changeCancelBtnState("enable", "grid-formula-cancel");
                  changeUpdateBtnState("enable", "grid-formula-update");
                  changeUpdateBtnState("disable", "dialog-btn_1");
                  fetchSelectedFx(sysFx);
                }}
                onButtonPress={(item) => {
                  changeCancelBtnState("enable", "grid-formula-cancel");
                  changeUpdateBtnState("enable", "grid-formula-update");
                  changeUpdateBtnState("disable", "dialog-btn_1");
                  setTempFormulaData((prevItem) => [...prevItem, item]);
                  scrollToLastElement(item.uId);
                }}
                onClearPress={() => {
                  if (!selectedEl.current) {
                    alert("Please select a row.");
                    return;
                  }
                  changeCancelBtnState("enable", "grid-formula-cancel");
                  changeUpdateBtnState("enable", "grid-formula-update");
                  changeUpdateBtnState("disable", "dialog-btn_1");
                  const newFormulaData = formulaData.filter(
                    (it) => it.uId !== selectedEl.current
                  );
                  setTempFormulaData(newFormulaData);
                  setFormulaData(newFormulaData);
                  selectedEl.current = "";
                }}
                pageProps={pageProps.pageProps}
              />

              <div
                className={`formulaContainer ${isModal ? "sortable-ui-div-modal" : "sortable-ui-div"
                  } `}
              >
                <Sortable
                  ref={sortableRef}
                  idField={"uId"}
                  // className={`formulaContainer ${
                  //   isModal ? "sortable-ui-div-modal" : "sortable-ui-div"
                  // } `}
                  data={formulaData}
                  itemUI={(props) => (
                    <SortableItemUI
                      {...props}
                      pageProps={pageProps.pageProps}
                    />
                  )}
                  emptyItemUI={() => <></>}
                  onDragOver={(event) => {
                    setFormulaData(event.newState);
                    changeCancelBtnState("enable", "grid-formula-cancel");
                    changeUpdateBtnState("enable", "grid-formula-update");
                    changeUpdateBtnState("disable", "dialog-btn_1");
                  }}
                  onNavigate={(event) => setFormulaData(event.newState)}
                />
                {showProcessing ? <span>Processing...</span> : null}
              </div>

            </>
          ) : (
            <div style={isModal ? {} : { height: "60vh" }}></div>
          )}
        </div>
      );
    };

    return (
      <>
        <div>
          {isRefreshLoading ? <LoadingPanel /> : null}
          <div
            className={isModal ? "Main" : " mobileblock"}
            style={{ minHeight: 0 }}
          >
            <div className="overlaymobile">
              <div className="overlaycont">
                <div className="procarelogomob"></div>
                <div className="mobnotificationlogo">
                  <h3>Please use tablet or desktop to view this page</h3>
                  <button className="primarybtn" href="/dashboard">
                    Back to Home
                  </button>
                </div>
              </div>
            </div>
            <div className="content-head">
              <HeaderPage
                pageProps={pageProps.pageProps}
                headerConfig={headerConfig}
              />
            </div>
          </div>
          <Splitter
            className="splitviewcustom"
            style={isModal ? {} : { minHeight: 500 }}
            panes={panes}
            onChange={(event) => {
              setPanes(event.newState);
            }}
            orientation={isModal ? "vertical" : "horizontal"}
          >
            <div className="pane-content">{leftPane()}</div>
            <div className="pane-content">
              {data.length === 0 && filterData.data && isReady && !isModal ? (
                <div className="assign-nodata">
                  <div className="overlaycont">
                    <div className="procarelogomob"></div>
                    <div className="mobnotificationlogo">
                      <h3>
                        {" "}
                        There is no record on the left side of the screen.
                        Please clear the filter or refresh the screen.
                      </h3>
                    </div>
                  </div>
                </div>
              ) : (
                false
              )}
              {rightPane()}
            </div>
          </Splitter>
        </div>

        <DialogComponent
          title={confirmationPopup.title}
          onCancelConfirm={onCancelConfirm}
          width={"450"}
          height={"auto"}
          className={"Dialog-Delete"}
          desc={confirmationPopup.desc}
          visible={confirmationPopup.isVisible}
          actionBtn={confirmationPopup.actionBtn}
          titleClassName={"delete-confirm"}
        // titleClassName={confirmationPopup.action === "Delete" ? "delete-confirm" : "unSave-confirm"}
        />
        <DialogComponent
          title="Error"
          width={"450"}
          height={"auto"}
          className={"Dialog-Delete"}
          desc="Invalid formula format. Please create valid formula and then try again!"
          visible={errorPopup}
          actionBtn={ERROR_POPUP_ACTIONS}
          onPrimButton={onCloseErrorPopup}
          onCancelConfirm={onCloseErrorPopup}
          titleClassName="unSave-confirm"
        />
        {isShow.dtuUpload && (
          <UploadDtu
            visible={isShow.dtuUpload}
            setShow={setShow}
            title={"Upload Formulas"}
            pageId={"formula"}
            setAPICall={onRefreshClick}
          />
        )}

        {isShow.dtuLogs && (
          <DtuBatchLogs
            visible={isShow}
            setShow={setShow}
            module={"formula_master"}
            pageProps={pageProps}
          />
        )}
      </>
    );
  }
);

const GridButtonFormula = (restProps) => {
  return (
    <>
      <Button
        type="submit"
        className="cancelbtn"
        id="grid-formula-cancel"
        onClick={(e) => { }}
      >
        Cancel
      </Button>
      <Button
        type="submit"
        className="primarybtn"
        id="grid-formula-update"
        onPointerDown={() => { }}
      >
        Save
      </Button>
    </>
  );
};
