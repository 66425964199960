/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Splitter } from "@progress/kendo-react-layout";
import { HeaderPage } from "../base/headerPage";
import { changeCancelBtnState, changeUpdateBtnState } from "../../utils/utils";
import { JobData } from "./jobExeucutionGrid";
import useServiceApi from "./service";
import DataRows from "./dataRows/dataRows";
import { useDispatch } from "react-redux";
import { socket } from "../../App";
import { setProgressPercentage } from "../../redux/actions/jobexecution-actions";
import { getUserCredential } from "../../lib/cookies";
const Job = (pageProps) => {
  const dispatch = useDispatch();
  const cookiesData = getUserCredential();
  const [list] = useServiceApi();
  const addRef = React.useRef(null);
  const [isJobDetail, setisJobDetail] = React.useState(false);

  const [isShow, setShow] = React.useState({
    auditLogs: false,
    deleteRows: false,
  });

  const [exportDataClick, setExportDataClick] = React.useState(false);
  const [isAPICall, setAPICall] = React.useState(false);
  const [selectedRowId, setSelectedRowId] = React.useState("");
  const [dataList, setDataList] = React.useState([]);
  const [logdataList, setLogDataList] = React.useState([]);
  const [pipeLogColumnsData, setpipeLogColumnsData] = React.useState([]);
  const [isAPICallForReport, setisAPICallForReport] = React.useState(false);

  const [panes, setPanes] = React.useState([
    {
      size: "35%",
      collapsible: true,
      scrollable: false,
    },
    {
      scrollable: false,
      min: "300px",
    },
  ]);
  const [isUpdateClick, setisUpdateClick] = React.useState(false);
  const [isCancelClick, setisCancelClick] = React.useState(false);
  const [isJobEdit, setisJobEdit] = React.useState(false);
  const [isEngineEdit, setisEngineEdit] = React.useState(false);
  const [isCollapsed, setIsCollapsed] = React.useState(false);
  const [logdaysInterVal, setLogdaysInterVal] = React.useState(false);
  const [isPublishDisable, setIsPublishDisable] = React.useState(true);
  const [publishJobData, setPublishJobData] = React.useState({});
  const [progressBarPer, setprogressBarPer] = React.useState(null);
  const [progressBarEndtime, setprogressBarEndtime] = React.useState(null);

  const { loadingState, isJobAPI, setIsJobAPI } = list;

  React.useEffect(() => {
    if (loadingState.isSaveData) {
      // setFormFields({ ...initialFormFields });
      setAPICall(true);
    }
  }, [loadingState.isSaveData]);

  //disable in-cell update and cancel button
  React.useEffect(() => {
    if (isEngineEdit === false && isJobEdit === false) {
      changeCancelBtnState();
      changeUpdateBtnState();
      isCancelClick === true && setisCancelClick(false);
      isUpdateClick === true && setisUpdateClick(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEngineEdit, isJobEdit]);

  React.useEffect(() => {
    socket.on("SUCCESS_PROGRESS_JOB_PER", (dataPer) => {
      const roundedPercentage =
        cookiesData.out_tenant_id == dataPer.tenantId
          ? Math.floor(dataPer.percentage)
          : null;
      if (
        roundedPercentage !== progressBarPer &&
        cookiesData.out_tenant_id == dataPer.tenantId
      ) {
        setprogressBarPer(roundedPercentage);
        setprogressBarEndtime(dataPer.endtime);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    socket.on("SUCCESS_PROGRESS_REPORT", (dataPer) => {
      const roundedPercentage =
        dataPer.processCtrlPer !== null
          ? Math.floor(dataPer.processCtrlPer)
          : null;
      if (
        roundedPercentage !== progressBarPer &&
        cookiesData.out_tenant_id == dataPer.tenantId
      ) {
        setprogressBarPer(roundedPercentage);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPaneChange = (event) => {
    const eventTarget = event.nativeEvent.target;

    if (eventTarget === undefined) {
      setPanes(event.newState);
      return;
    }

    if (eventTarget.classList.contains("k-collapse-prev")) {
      if (isCollapsed === false) {
        setIsCollapsed(true);
        event.newState[0].collapsed = false;
        event.newState[0].size = 0;
        eventTarget.classList.remove("k-i-arrow-60-left");
        eventTarget.classList.add("k-i-arrow-60-right");
      } else {
        setIsCollapsed(false);
        event.newState[0].collapsed = false;
        event.newState[0].size = "35%";
        eventTarget.classList.remove("k-i-arrow-60-right");
        eventTarget.classList.add("k-i-arrow-60-left");
      }
      setPanes(event.newState);
    }
  };
  const addNewConfig = async (e) => {
    setisJobDetail(true);
  };
  const AddNewLink = () => {
    return (
      <>
        <a
          className="cta-small"
          ref={addRef}
          onClick={(e) => {
            addNewConfig(e);
          }}
        >
          <i className="fas fa-plus"></i> Add Job to Queue
        </a>
      </>
    );
  };

  const handleMenuItemClick = (action) => {
    switch (action) {
      case "auditLogs":
        setShow({
          ...isShow,
          auditLogs: !isShow.auditLogs,
        });
        break;
      case "export":
        setExportDataClick(true);
        break;
      case "delete":
        setShow({
          ...isShow,
          deleteRows: !isShow.deleteRows,
        });
        break;
      default:
        break;
    }
  };

  const headerConfig = {
    title: "Job Execution Queue",
    id: "job_grid",
    add_new_fx: AddNewLink,
    action_menu: {
      delete: { fx: handleMenuItemClick },
    },
  };
  const headerAssignmentConfig = {
    title: `Job Run Log - Last ${
      localStorage.getItem("intervalDays") !== null
        ? localStorage.getItem("intervalDays")
        : ""
    } Days`,
    id: "pipe-log",
    action_menu: {
      export: { fx: handleMenuItemClick },
    },
    progress_fx: {
      visibale: progressBarPer === null ? false : true,
      progressValue: progressBarPer,
    },
  };
  const parent = document.querySelector("#content-" + headerConfig.id);
  const rightParent = document.querySelector(
    "#content-" + headerAssignmentConfig.id
  );

  if (parent) {
    const auditLog = parent.querySelector(".audit-log");
    const deleteRows = parent.querySelector(".delete-rows");

    auditLog && auditLog.disableKendoMenu();
    deleteRows && deleteRows.disableKendoMenu();

    if (selectedRowId !== "") {
      auditLog && auditLog.enableKendoMenu();
      deleteRows && deleteRows.enableKendoMenu();
    }
  }
  if (rightParent) {
    const exportToExcel = rightParent.querySelector(".export-to-excel");
    exportToExcel && exportToExcel.disableKendoMenu();

    logdataList.length && exportToExcel && exportToExcel.enableKendoMenu();
  }

  const leftPane = () => {
    return (
      <>
        <div className="content-head jobexecutionpadding">
          <HeaderPage pageProps={pageProps} headerConfig={headerConfig} />
        </div>

        <div className="content-body">
          <div className="dtu-file-container">
            <JobData
              isAPICall={isAPICall}
              setAPICall={setAPICall}
              selectedRowId={selectedRowId}
              setSelectedRowId={setSelectedRowId}
              setShow={setShow}
              isShow={isShow}
              setDataList={setDataList}
              setisUpdateClick={setisUpdateClick}
              setisCancelClick={setisCancelClick}
              isEngineEdit={isEngineEdit}
              setisEngineEdit={setisEngineEdit}
              setisJobEdit={setisJobEdit}
              isJobEdit={isJobEdit}
              pageProps={pageProps}
              isJobAPI={isJobAPI}
              setIsJobAPI={setIsJobAPI}
              isJobDetail={isJobDetail}
              setisJobDetail={setisJobDetail}
              setIsPublishDisable={setIsPublishDisable}
              setPublishJobData={setPublishJobData}
              progressBarPer={progressBarPer}
              progressBarEndtime={progressBarEndtime}
              setprogressBarPer={setprogressBarPer}
              isAPICallForReport={isAPICallForReport}
              setisAPICallForReport={setisAPICallForReport}
              dataList={dataList}
            />
          </div>
        </div>
      </>
    );
  };

  const rightPane = () => {
    return (
      <>
        <div className="content-head">
          <HeaderPage
            pageProps={pageProps}
            headerConfig={headerAssignmentConfig}
          />
        </div>
        <div className="content-body">
          <div className="dtu-file-container">
            <DataRows
              isCancelClick={isCancelClick}
              setisCancelClick={setisCancelClick}
              pipeLogColumnsData={pipeLogColumnsData}
              setpipeLogColumnsData={setpipeLogColumnsData}
              isJobAPI={isJobAPI}
              setIsJobAPI={setIsJobAPI}
              setLogdaysInterVal={setLogdaysInterVal}
              setExportDataClick={setExportDataClick}
              exportDataClick={exportDataClick}
              isPublishDisable={isPublishDisable}
              setIsPublishDisable={setIsPublishDisable}
              publishJobData={publishJobData}
              progressBarPer={progressBarPer}
              setLogDataList={setLogDataList}
              isAPICallForReport={isAPICallForReport}
              setisAPICallForReport={setisAPICallForReport}
              logdaysInterVal={logdaysInterVal}
            />
          </div>
        </div>
      </>
    );
  };
  return (
    <div className="Main mobileblock">
      <div className="overlaymobile">
        <div className="overlaycont">
          <div className="procarelogomob"></div>
          <div className="mobnotificationlogo">
            <h3>Please use tablet or desktop to view this page</h3>
            <button className="primarybtn" href="/dashboard">
              Back to Home
            </button>
          </div>
        </div>
      </div>
      <div className="content">
        <Splitter
          panes={panes}
          onChange={onPaneChange}
          className="splitviewcustom"
        >
          <div className="pane-content ">{leftPane()}</div>
          {/* <div className="pane-content jobrunlogprogressbar">test</div> */}

          <div className="pane-content jobrunlogprogressbar">{rightPane()}</div>
        </Splitter>
      </div>
    </div>
  );
};

export default React.memo(Job);
