import React from "react";
import { getUserCredential } from "../../lib/cookies";
import {
    POSTAPI,
    errStatusHandler,
} from "../../api-setup/api-helper";
import { GridColumnDataMapper } from "../../utils/utils";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { setNewMessage } from "../../redux/actions/setting-actions";

export default function Title() {
    const cookiesData = getUserCredential();
    const header = {
        "x-access-token": cookiesData.secretToken,
    };
    const history = useHistory();
    const dispatch = useDispatch();
    const [data, setData] = React.useState([]);
    const [columns, setColumns] = React.useState([]);
    const [dataCount, setDataCount] = React.useState(0);
    const [loadingState, setLoadState] = React.useState({
        isSaveData: undefined,
        isDeleteData: undefined,
    });
    const [initialLoading, setLoadingState] = React.useState(false);
    const [editData, setEditData] = React.useState("");
    const [originalData, setOriginalData] = React.useState([]);
    const [isAPICalling, setAPICallingState] = React.useState(false);
    const [isRefreshLoading, setRefreshLoading] = React.useState(false);

    const getListDataAsync = (listData,api) => {
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                api,
                null,
                header,
                listData,
                dispatch
            ).then((res) => {
                if (res) {
                    if (res?.data?.out_parameters?.out_ret_code > 0) {
                        setLoadingState(true);
                        errStatusHandler(res, history, dispatch);
                    }
                    setAPICallingState(false);
                    setLoadingState(true);
                    setEditData("");
                    let userData = JSON.parse(
                        JSON.stringify(res.data.row_result)
                    );
                    const columData = GridColumnDataMapper(
                        res.data.out_parameters.out_column_xml || res.data.out_parameters.out_columns_xml 
                    );
                    if (listData.in_load_type === "grid-reload") {
                        columData.map((cItem) => {
                            cItem.minWidth = 210;
                            cItem.show =
                                cItem.field === "specialty_mstr_key" ||
                                cItem.field === "version"
                                    ? false
                                    : true;
                            return 0;
                        });
                        setColumns(columData);
                    }
                    columData?.map((item) => {
                        if (item.type === 'boolean') {
                            userData.forEach((rItem) => {
                                rItem[item.field] = rItem[item.field] === 1 ? 'True' : 'False'

                            })
                        }
                        return 0;
                    })
                    userData.map((item, idx) => {
                        item.rowId = idx;
                        item.selected = false;
                        return 0;
                    });
                    setData(userData);
                    setOriginalData(userData);
                    setDataCount(res.data.out_parameters.out_total_rows);
                    setRefreshLoading(false);
                    resolve(userData);
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };



    return [
        {
            data,
            columns,
            dataCount,
            setData,
            setColumns,
            editData,
            originalData,
            isAPICalling,
            setEditData,
            initialLoading,
            setAPICallingState,
            loadingState,
            setLoadState,
            setOriginalData,
            setRefreshLoading,
            isRefreshLoading,
        },
        {
            getListDataAsync,
        },
    ];
}
