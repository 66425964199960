// eslint-disable-next-line no-useless-concat
import * as React from "react";
import { useDispatch } from "react-redux";
import { removeMessage } from "../../../redux/actions/setting-actions";
import {
  changeCancelBtnState,
  changeUpdateBtnState,
} from "../../../utils/utils";
import useApi from "./service";
import { DEFAULT_SORT } from "../../../constants/grid";
import { getTenantCookieData, getUserCredential } from "../../../lib/cookies";
import { Typography } from "@progress/kendo-react-common";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

import "./job-configuration.scss";

import {
  ListBox,
  processListBoxDragAndDrop,
} from "@progress/kendo-react-listbox";
// import "./list.css";

const SELECTED_FIELD = "selected";

const loadingPanel = (
  <div className="k-loading-mask">
    <span className="k-loading-text">Loading</span>
    <div className="k-loading-image"></div>
    <div className="k-loading-color"></div>
  </div>
);

export const EnginePhaseData = (restProps) => {
  const dispatch = useDispatch();
  const [list, Action] = useApi();
  const defaultPageSize = getTenantCookieData("record_per_page");
  const {
    data,
    isAPICalling,
    setAPICallingState,
    isAssignAPICalling,
    setAssignAPICallingState,
  } = list;

  const [pageData, setPage] = React.useState({
    skip: 0,
    take: defaultPageSize.recordTake,
  });

  const [state, setState] = React.useState({
    iteamName: [],
    dragbleIteamName: [],
    draggedItem: {},
    class: "",
  });
  const [isSelectedData, setIsSelectedData] = React.useState(false);
  const [isVisible, setIsVisible] = React.useState(false);

  const [originalAssignData, setOriginalAssignData] = React.useState([]);
  const [originalUnassignData, setOriginalUnassignData] = React.useState([]);
  const [dataName, setDataName] = React.useState("");

  const cookiesData = getUserCredential();

  //state to set and get filter, sort number, sort order
  const [filterData, setFilterData] = React.useState({
    isSort: false,
    isFilter: false,
    data: "",
    in_sort_number: DEFAULT_SORT.number,
    sort_order: DEFAULT_SORT.order,
  });

  const apiCallData = {
    in_tenant_id: cookiesData.out_tenant_id,
    in_filter: null,
    in_job_mstr_key: restProps.jobTableMasterKey
      ? restProps.jobTableMasterKey.toString()
      : "",
    in_assign_type: "unassign",
    in_sort_number: DEFAULT_SORT.number,
    in_sort_order: DEFAULT_SORT.order,
    in_page_row_offset: 0,
    in_page_rows: defaultPageSize.recordTake,
  };
  const assignApiCallData = {
    in_tenant_id: cookiesData.out_tenant_id,
    in_filter: null,
    in_job_mstr_key: restProps.jobTableMasterKey
      ? restProps.jobTableMasterKey.toString()
      : "",
    in_assign_type: "assign",
    in_sort_number: DEFAULT_SORT.number,
    in_sort_order: DEFAULT_SORT.order,
    in_page_row_offset: 0,
    in_page_rows: defaultPageSize.recordTake,
  };

  React.useEffect(() => {
    setAPICallingState(true);
    setAssignAPICallingState(true);

    setPage({ skip: 0, take: defaultPageSize.recordTake });

    setTimeout(function () {
      getGridData(apiCallData, assignApiCallData);
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restProps.jobTableMasterKey]);

  React.useEffect(() => {
    if (restProps.isUpdateClick === true) {
      updateAssignment();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restProps.isUpdateClick]);

  const getGridData = async (inParams, assignInparms) => {
    if (restProps.jobTypeName === "Stage Hook") {
      inParams.in_filter = "AND a.is_stagehook = 1";
      assignInparms.in_filter = "AND a.is_stagehook = 1";
    }
    let response = await Action.getListDataAsync(inParams);
    let assignResponse = await Action.getAssignListDataAsync(assignInparms);

    let itemData = response.map((item) => ({
      ...item,
      engine_phase_label:
        item.is_stagehook === 1 ? (
          <>
            {item.engine_phase_label} <b>(Stagehook)</b>
          </>
        ) : (
          item.engine_phase_label
        ),
    }));
    // let assignitemData = assignResponse.filter((assignitem) => assignitem);
    let assignitemData = assignResponse.map((assignitem) => ({
      ...assignitem,

      engine_phase_label:
        assignitem.is_stagehook === 1 ? (
          <>
            {assignitem.engine_phase_label} <b>(Stagehook)</b>
          </>
        ) : (
          assignitem.engine_phase_label
        ),
    }));

    setOriginalAssignData(itemData);
    setOriginalUnassignData(assignResponse);
    setState({
      ...state,
      iteamName: itemData.sort(
        (a, b) => a.parent_run_order - b.parent_run_order
      ),

      dragbleIteamName: assignitemData.sort(
        (a, b) => a.run_order - b.run_order
      ),
      draggedItem: {},
    });
    restProps.setisEngineEdit(false);
  };

  React.useEffect(() => {
    if (restProps.isCancelClick === true) {
      cancelAssignment();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restProps.isCancelClick]);

  const getInFilter = () => {
    return filterData.isFilter ? filterData.data : null;
  };

  const getInSortNumber = () => {
    return filterData.isSort ? filterData.in_sort_number : DEFAULT_SORT.number;
  };

  const getInSortOrder = () => {
    return filterData.isSort ? filterData.sort_order : DEFAULT_SORT.order;
  };

  const getPageTake = () => {
    return pageData.take;
  };

  const getPageSkip = () => {
    return pageData.skip;
  };

  const onRefreshClick = () => {
    setAPICallingState(true);

    const dataVal = {
      ...apiCallData,
      in_filter: getInFilter(),
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
      in_page_row_offset: getPageSkip(),
      in_page_rows: getPageTake(),
    };
    const assignDataVal = {
      ...assignApiCallData,
      in_filter: getInFilter(),
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
      in_page_row_offset: getPageSkip(),
      in_page_rows: getPageTake(),
    };

    getGridData(dataVal, assignDataVal);
  };

  const inCommaValue = (data) => {
    let string = 0;
    if (data.length > 0) {
      let enginephaseData = data
        .map((item) => {
          return item.engine_phase_mstr_key;
        })
        .toString();
      string = `${enginephaseData}`;
    }

    return string;
  };
  const updateAssignment = async () => {
    const updatedArray =
      state.dragbleIteamName.length !== 0 ? state.dragbleIteamName : [];
    let assignEnginePhaseString = inCommaValue(updatedArray);
    if (updatedArray.length > 0) {
      const bodydata = {
        in_user_id: cookiesData.out_user_id,
        in_tenant_id: cookiesData.out_tenant_id,
        in_job_mstr_key: restProps.jobTableMasterKey,
        in_job_plan_assign_mstr_key:
          restProps.compPlanKey !== null ? restProps.compPlanKey : "",
        in_engine_phase_mstr_keys: assignEnginePhaseString,
      };
      let response = await Action.assignEnginePhaseAsync(bodydata);

      if (response) {
        // TODO : UNSAVED CHANGES
        // dispatch(
        //   setUnSavedChangesPopup({ ...unsavedPopup, isUnSavedChange: false })
        // );
        data.map((item) => {
          item.inEdit = false;
          return 0;
        });

        restProps.setisEngineEdit(false);
        onRefreshClick();
      }
    } else {
      setIsVisible(true);
      let itemData = originalAssignData.map((item) => ({
        ...item,
        engine_phase_label: item.engine_phase_label,
      }));

      // let assignitemData = assignResponse.filter((assignitem) => assignitem);
      let assignitemData = originalUnassignData.map((assignitem) => ({
        ...assignitem,

        engine_phase_label:
          assignitem.is_stagehook === 1 ? (
            <>
              {assignitem.engine_phase_label} <b>(Stagehook)</b>
            </>
          ) : (
            assignitem.engine_phase_label
          ),
      }));
      setState({
        ...state,
        iteamName: itemData.sort(
          (a, b) => a.parent_run_order - b.parent_run_order
        ),
        dragbleIteamName: assignitemData.sort(
          (a, b) => a.run_order - b.run_order
        ),
        draggedItem: {},
      });
    }
  };

  const cancelAssignment = () => {
    dispatch(
      removeMessage({
        message: "",
        type: "Error",
        show: false,
      })
    );
    let itemData = originalAssignData.map((item) => ({
      ...item,
      engine_phase_label: item.engine_phase_label,
    }));
    // let assignitemData = assignResponse.filter((assignitem) => assignitem);
    let assignitemData = originalUnassignData.map((assignitem) => ({
      ...assignitem,

      engine_phase_label:
        assignitem.is_stagehook === 1 ? (
          <>
            {assignitem.engine_phase_label} <b>(Stagehook)</b>
          </>
        ) : (
          assignitem.engine_phase_label
        ),
    }));
    setState({
      ...state,
      iteamName: itemData.sort(
        (a, b) => a.parent_run_order - b.parent_run_order
      ),
      dragbleIteamName: assignitemData.sort(
        (a, b) => a.run_order - b.run_order
      ),
      draggedItem: {},
    });
    restProps.setisCancelClick(false);
    restProps.setisEngineEdit(false);
  };

  const handleItemClick = (event, data) => {
    setIsSelectedData(true);
    setDataName(data);

    setState((prevState) => ({
      ...prevState,
      [data]: prevState[data].map((item) => {
        const isCurrentItem =
          item.engine_phase_label === event.dataItem.engine_phase_label;

        if (isCurrentItem) {
          // Toggle selected and unassigned for the clicked item
          item[SELECTED_FIELD] = !item[SELECTED_FIELD];
          item.unassigned = !item.unassigned;
        } else if (!event.nativeEvent.ctrlKey) {
          // Deselect other items if Ctrl is not held
          item[SELECTED_FIELD] = false;
          item.unassigned = true;
        }
        return item;
      }),
      class: "selected",
    }));
  };

  const handleDragStart = (e) => {
    let target = e.target;
    e.dataItem.dataCollection = target.props.engine_phase_label || "";
    setState({
      ...state,
      draggedItem: e.dataItem,
    });
  };

  const handleDrop = (e) => {
    changeCancelBtnState("enable");
    changeUpdateBtnState("enable");
    // TODO : UNSAVED CHANGES
    // dispatch(setUnSavedChangesPopup({ isUnSavedChange: true }));
    const listBoxOneData = state.iteamName;
    const listBoxTwoData = state.dragbleIteamName;
    const draggedItem = state.draggedItem;
    const dataItem = e.dataItem;

    const result = processListBoxDragAndDrop(
      listBoxOneData,
      listBoxTwoData,
      draggedItem,
      dataItem,
      "engine_phase_mstr_key"
    );

    if (!isSelectedData) {
      const sortedListBoxOneData = result.listBoxOneData.sort(
        (a, b) => a.parent_run_order - b.parent_run_order
      );

      const resultlistBoxTwoData = result.listBoxTwoData.map((item) => ({
        ...item,
        unassigned: false,
      }));

      const sortedResultListBoxOneData = sortedListBoxOneData.map((item) => ({
        ...item,
        unassigned: true,
      }));
      setState({
        ...state,
        dragbleIteamName: resultlistBoxTwoData,
        iteamName: sortedResultListBoxOneData,
      });
    } else {
      const unassignedItems = state.iteamName.filter(
        (item) => item.unassigned === true
      );
      const assignedItems = state.iteamName.filter(
        (item) => item.unassigned === false
      );
      const draggableUnassignedItems = state.dragbleIteamName.filter(
        (item) => item.unassigned === true
      );
      const draggableAssignedItems = state.dragbleIteamName.filter(
        (item) => item.unassigned === false
      );

      const selectedDraggableItems =
        draggableUnassignedItems.length > 0
          ? draggableUnassignedItems.concat(unassignedItems)
          : unassignedItems;

      const selectedDroppableItems =
        draggableAssignedItems.length > 0
          ? draggableAssignedItems.concat(assignedItems)
          : assignedItems;
      const updatedSelectedDroppableItems = selectedDroppableItems.map(
        (item) => ({
          ...item,
          selected: true,
        })
      );

      const updatedSelectedDraggableItems = selectedDraggableItems.map(
        (item) => ({
          ...item,
          selected: false,
        })
      );

      setState({
        ...state,
        dragbleIteamName: updatedSelectedDroppableItems,
        iteamName: updatedSelectedDraggableItems.sort(
          (a, b) => a.parent_run_order - b.parent_run_order
        ),
        class: "",
      });
      setIsSelectedData(false);
    }
    restProps.setisEngineEdit(true);
  };
  const handleValdtionDilouge = () => {
    setIsVisible(!isVisible);

    restProps.setisEngineEdit(false);
  };

  const getJobConfigGridHeight = () => {
    let height = "";
    let ele = document.getElementsByClassName("job-config-left-grid-cont");

    if (ele !== null && ele[0] !== undefined) {
      height = ele[0].offsetHeight - 38;
    }
    return height;
  };
  return (
    <>
      <Typography.p className="drag-notification">
        Drag engine phase from left box and drop into right box. Select multiple
        engine phases with CTRL key + Click and select range using Shift key +
        Click.
      </Typography.p>
      {!isAPICalling && !isAssignAPICalling ? (
        <div
          style={{
            height: getJobConfigGridHeight(),
          }}
          className={
            restProps.jobTypeName === "Comp Plan" ||
            restProps.jobTypeName === "Stage Hook"
              ? "jobconfig-drag-cont"
              : "overlay-filter jobconfig-drag-cont"
          }
        >
          <div
            className={`drag-drop-cont unassigned-select ${
              restProps.jobTypeName === "Comp Plan" ||
              restProps.jobTypeName === "Stage Hook"
                ? "disabled-filter"
                : "disabled-filter"
            }`}
          >
            <Typography.p>Unassigned Engine Phases</Typography.p>
            <ListBox
              data={state.iteamName}
              textField="engine_phase_label"
              selectedField={dataName === "iteamName" ? state.class : ""}
              onItemClick={(e) =>
                handleItemClick(e, "iteamName", "dragbleIteamName")
              }
              onDragStart={handleDragStart}
              onDrop={handleDrop}
            />
          </div>
          <div className="drag-drop-cont select-drop-list disabled-filter unassigned-select">
            <Typography.p className="padleft15">
              Assigned Engine Phases
            </Typography.p>
            <ListBox
              data={state.dragbleIteamName}
              textField="engine_phase_label"
              selectedField={dataName === "dragbleIteamName" ? state.class : ""}
              onItemClick={(e) =>
                handleItemClick(e, "dragbleIteamName", "iteamName")
              }
              onDragStart={handleDragStart}
              onDrop={handleDrop}
            />
          </div>
        </div>
      ) : (
        <>{loadingPanel}</>
      )}
      <>
        {isVisible ? (
          <Dialog
            title={"Job Engine Phase Assignment"}
            width={500}
            onClose={handleValdtionDilouge}
          >
            <p style={{ margin: "25px" }}>
              You should have at least one Engine Phase in Assigned Engine
              Phases for a job
            </p>

            <DialogActionsBar>
              <button
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                onClick={handleValdtionDilouge}
              >
                Ok
              </button>
            </DialogActionsBar>
          </Dialog>
        ) : (
          false
        )}
      </>
    </>
  );
};
