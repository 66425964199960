/**
 * Form schema for email send popup
 */
import { FormInput } from "src/components/form/form-elements";

const schema = {
    in_password: {
        id: "in_password",
        name: "in_password",
        // label: "ProCARE Password:",
        wrapperClass: "formelement fullwidthelm logininputs eye-icon",
        component: FormInput,
        type: "password",
        placeHolder:'Password',
        autoFocus: true,
        rules: {
            required: "ProCARE Password is required",
        },
    },
};

export default schema;
