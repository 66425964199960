export const SET_USER_STATUS_DATA = "SET_USER_STATUS_DATA";
export const SET_ROLE_DATA = "SET_ROLE_DATA";

export function setUserStatusData(userStatusData) {
    return {
        type: SET_USER_STATUS_DATA,
        data: { userStatusData },
    };
}

export function setRoleData(roleData) {
    return {
        type: SET_ROLE_DATA,
        data: { roleData },
    };
}
