import PropTypes from "prop-types";
import { memo } from "react";
import { DashboardWidgetWrapperHook } from "./index.hook";

export const DashboardWidgetWrapper = memo(({ podDetail }) => {
  const { renderBody } = DashboardWidgetWrapperHook(podDetail);

  return renderBody;
});

DashboardWidgetWrapper.propTypes = {
  podDetail: PropTypes.any.isRequired,
};
