import React, { useEffect, useState } from "react";
import CustomDropdown from "../../../../components/customDropdown";
import { GetPeriodTypeAPI } from "../../../../lib/common-service";
import { Label } from "@progress/kendo-react-labels";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Checkbox } from "@progress/kendo-react-inputs";
import { setExecutionData } from "../../../../redux/actions/rule-library-actions";
import { executionDataSelector } from "../../../../redux/selectors/custom-selector";
import {
    changeCancelBtnState,
    changeUpdateBtnState,
} from "../../../../utils/utils";

const CHECKBOX_ARRAY = [
    {
        label: "Rollable Result",
        key: "is_rollable",
    },
    {
        label: "Generate Zero Value",
        key: "generate_zero_result",
    },
    {
        label: "Generate Quarter Totals",
        key: "generate_quarter_total",
    },
    {
        label: "Generate Year Total",
        key: "generate_year_total",
    },
    {
        label: "Generate QTD Totals",
        key: "generate_qtd_value",
    },
    {
        label: "Generate YTD Total",
        key: "generate_ytd_value",
    },
];

const ExecutionSettings = (restProps) => {
    const dispatch = useDispatch();
    const executionReduxData = useSelector(executionDataSelector, shallowEqual);
    const [periodsTypeFields, setPeriodTypeFields] = useState({
        periodTypeValue: {
            period_type_mstr_key: 0,
            period_type_name: "All Periods",
            in_period_start_date: "",
            in_period_end_date: "",
        },
        periodTypeData: [],
        isPeriodTypeDataLoading: true,
    });
    const [periodTypeList, setPeriodTypeList] = useState([]);
    const [disabledCheckboxState, setDisableCheckBoxState] = useState({
        generate_zero_result: false,
        generate_quarter_total: false,
        generate_year_total: false,
        generate_qtd_value: false,
        generate_ytd_value: false,
    });

    const [CheckboxState, setCheckBoxState] = useState({
        ...executionReduxData?.executionData,
    });

    const [changePeriodType, setChangePeriodType] = useState(0);

    /*
    useEffect(() => {
        if (executionReduxData?.executionData) {
            setCheckBoxState({
                ...executionReduxData?.executionData,
            });
        }
    }, [executionReduxData?.executionData]);
    */

    useEffect(() => {
        getPeriodsDataType();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /*
    useEffect(() => {
        if (Object.keys(periodsTypeFields.periodTypeValue).length > 0) {
            if (periodsTypeFields.periodTypeValue.period_type_name) {
                handleAllCheckbox(
                    periodsTypeFields.periodTypeValue.period_type_name
                );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [periodsTypeFields]);
    */

    useEffect(() => {
        if (periodTypeList.length > 0) {
            setPeriodTypeFields({
                ...periodsTypeFields,
                periodTypeValue: periodTypeList.find(
                    (x) =>
                        x.period_type_mstr_key ===
                            executionReduxData?.executionData
                                ?.period_type_mstr_key || 0
                ),
                isPeriodTypeDataLoading: false,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        periodTypeList,
        executionReduxData?.executionData.period_type_mstr_key,
    ]);

    /**
     * Get Periods Data Type
     */
    const getPeriodsDataType = async () => {
        let periodTypeData = {
            in_filter: "AND a.period_type_hierarchy_level IS NOT NULL",
            in_sort_number: null,
            in_sort_order: null,
        };
        let res = await GetPeriodTypeAPI(periodTypeData, dispatch);
        if (res.length > 0) {
            res.unshift({
                period_type_name: "All Periods",
                period_type_mstr_key: 0,
            });
            setPeriodTypeList(res);
            setPeriodTypeFields({
                ...periodsTypeFields,
                periodTypeData: res,
                periodTypeValue: res.find(
                    (x) =>
                        x.period_type_mstr_key ===
                            executionReduxData?.executionData
                                ?.period_type_mstr_key || 0
                ),
                isPeriodTypeDataLoading: false,
            });
        } else {
            setPeriodTypeFields({
                ...periodsTypeFields,
                isPeriodTypeDataLoading: false,
            });
        }
    };

    /*
    const handleAllCheckbox = (module, key, e) => {
        switch (module.trim()) {
            case "All Periods":
                setDisableCheckBoxState({
                    ...disabledCheckboxState,
                    generate_zero_result: true,
                    generate_quarter_total: false,
                    generate_year_total: false,
                    generate_qtd_value: false,
                    generate_ytd_value: false,
                    is_rollable: true,
                });
                dispatch(
                    setExecutionData({
                        executionData: {
                            ...executionReduxData.executionData,
                            [key]: e?.period_type_mstr_key,
                            generate_quarter_total: 0,
                            generate_year_total: 0,
                            generate_qtd_value: 0,
                            generate_ytd_value: 0,
                        },
                    })
                );
                break;
            case "year":
                setDisableCheckBoxState({
                    ...disabledCheckboxState,
                    generate_zero_result: true,
                    generate_quarter_total: false,
                    generate_year_total: false,
                    generate_qtd_value: false,
                    generate_ytd_value: false,
                    is_rollable: true,
                });
                dispatch(
                    setExecutionData({
                        executionData: {
                            ...executionReduxData.executionData,
                            [key]: e?.period_type_mstr_key,
                            generate_quarter_total: 0,
                            generate_year_total: 0,
                            generate_qtd_value: 0,
                            generate_ytd_value: 0,
                        },
                    })
                );
                break;
            case "month":
                setDisableCheckBoxState({
                    ...disabledCheckboxState,
                    generate_zero_result: true,
                    generate_quarter_total: true,
                    generate_year_total: true,
                    generate_qtd_value: true,
                    generate_ytd_value: true,
                    is_rollable: true,
                });
                dispatch(
                    setExecutionData({
                        executionData: {
                            ...executionReduxData.executionData,
                            [key]: e?.period_type_mstr_key,
                        },
                    })
                );
                break;
            case "quarter":
                setDisableCheckBoxState({
                    ...disabledCheckboxState,
                    generate_zero_result: true,
                    generate_quarter_total: false,
                    generate_year_total: true,
                    generate_qtd_value: false,
                    generate_ytd_value: false,
                    is_rollable: true,
                });
                dispatch(
                    setExecutionData({
                        executionData: {
                            ...executionReduxData.executionData,
                            [key]: e?.period_type_mstr_key,
                            generate_quarter_total: 0,
                            generate_qtd_value: 0,
                            generate_ytd_value: 0,
                        },
                    })
                );
                break;
            case "semi-annual":
                setDisableCheckBoxState({
                    ...disabledCheckboxState,
                    generate_zero_result: true,
                    generate_quarter_total: false,
                    generate_year_total: false,
                    generate_qtd_value: false,
                    generate_ytd_value: true,
                    is_rollable: true,
                });
                dispatch(
                    setExecutionData({
                        executionData: {
                            ...executionReduxData.executionData,
                            [key]: e?.period_type_mstr_key,
                            generate_quarter_total: 0,
                            generate_year_total: 0,
                            generate_qtd_value: 0,
                        },
                    })
                );
                break;
            default:
                break;
        }
    };
    */
    const handleChangeDropdown = (e, name, key) => {
        setPeriodTypeFields({
            ...periodsTypeFields,
            [name]: e,
        });
        dispatch(
            setExecutionData({
                executionData: {
                    ...executionReduxData.executionData,
                    [key]: e.period_type_mstr_key,
                },
            })
        );
        setChangePeriodType(1);
        //handleAllCheckbox(e.period_type_name, key, e);
        restProps?.onDataChange?.();
        changeCancelBtnState("enable", "grid-incell-cancel-comp-rule");
        changeUpdateBtnState("enable", "grid-incell-update-comp-rule");
    };

    const handleCheckboxChange = (e, key) => {
        setCheckBoxState({
            ...CheckboxState,
            [key]: e.target.value,
        });
        dispatch(
            setExecutionData({
                executionData: {
                    ...executionReduxData.executionData,
                    [key]: e.target.value ? 1 : 0,
                },
            })
        );
        restProps?.onDataChange?.();
        changeCancelBtnState("enable", "grid-incell-cancel-comp-rule");
        changeUpdateBtnState("enable", "grid-incell-update-comp-rule");
    };

    useEffect(() => {
        conditionalDisablingHandler();
    }, [
        executionReduxData.executionData,
        periodsTypeFields.periodTypeValue.period_type_mstr_key,
    ]);

    const conditionalDisablingHandler = async () => {
        const periods =
            periodsTypeFields.periodTypeValue.period_type_name.toLowerCase();

        const execData = executionReduxData.executionData;

        if (periods == "month") {
            setDisableCheckBoxState({
                ...disabledCheckboxState,
                generate_qtd_value: !execData.generate_quarter_total, // Disable QTD if quarter total is checked
                generate_quarter_total: !execData.generate_qtd_value,
                generate_year_total: !execData.generate_ytd_value,
                generate_ytd_value: !execData.generate_year_total,
                generate_zero_result: true,
                is_rollable: true,
            });

            setCheckBoxState({
                ...CheckboxState,
                generate_qtd_value: execData.generate_quarter_total
                    ? false
                    : execData.generate_qtd_value, // Disable QTD if quarter total is checked
                generate_quarter_total: execData.generate_qtd_value
                    ? false
                    : execData.generate_quarter_total,
                generate_year_total: execData.generate_ytd_value
                    ? false
                    : execData.generate_year_total,
                generate_ytd_value: execData.generate_year_total
                    ? false
                    : execData.generate_ytd_value,
                generate_zero_result: execData.generate_zero_result,
                is_rollable: execData.is_rollable,
            });

            if (changePeriodType) {
                setChangePeriodType(0);
                dispatch(
                    setExecutionData({
                        executionData: {
                            ...executionReduxData.executionData,
                            generate_qtd_value: execData.generate_quarter_total
                                ? 0
                                : execData.generate_qtd_value
                                ? 1
                                : 0, // Disable QTD if quarter total is checked
                            generate_quarter_total: execData.generate_qtd_value
                                ? 0
                                : execData.generate_quarter_total
                                ? 1
                                : 0,
                            generate_year_total: execData.generate_ytd_value
                                ? 0
                                : execData.generate_year_total
                                ? 1
                                : 0,
                            generate_ytd_value: execData.generate_year_total
                                ? 0
                                : execData.generate_ytd_value
                                ? 1
                                : 0,
                        },
                    })
                );
            }
        }
        if (periods == "quarter") {
            setDisableCheckBoxState({
                ...disabledCheckboxState,
                generate_quarter_total: false,
                generate_qtd_value: false,
                generate_year_total: !execData.generate_ytd_value,
                generate_ytd_value: !execData.generate_year_total,
                generate_zero_result: true,
                is_rollable: true,
            });
            setCheckBoxState({
                ...CheckboxState,
                generate_quarter_total: false,
                generate_qtd_value: false,
                generate_year_total: execData.generate_ytd_value
                    ? false
                    : execData.generate_year_total,
                generate_ytd_value: execData.generate_year_total
                    ? false
                    : execData.generate_ytd_value,
                generate_zero_result: execData.generate_zero_result,
                is_rollable: execData.is_rollable,
            });
            if (changePeriodType) {
                setChangePeriodType(0);

                dispatch(
                    setExecutionData({
                        executionData: {
                            ...executionReduxData.executionData,
                            generate_qtd_value: 0, // Disable QTD if quarter total is checked
                            generate_quarter_total: 0,
                            generate_year_total: execData.generate_ytd_value
                                ? 0
                                : execData.generate_year_total
                                ? 1
                                : 0,
                            generate_ytd_value: execData.generate_year_total
                                ? 0
                                : execData.generate_ytd_value
                                ? 1
                                : 0,
                        },
                    })
                );
            }
        }
        if (periods == "year" || periods === "all periods") {
            setDisableCheckBoxState({
                ...disabledCheckboxState,
                generate_quarter_total: false,
                generate_qtd_value: false,
                generate_year_total: false,
                generate_ytd_value: false,
                generate_zero_result: true,
                is_rollable: true,
            });
            setCheckBoxState({
                ...CheckboxState,
                generate_quarter_total: false,
                generate_qtd_value: false,
                generate_year_total: false,
                generate_ytd_value: false,
                generate_zero_result: execData.generate_zero_result,
                is_rollable: execData.is_rollable,
            });
            if (changePeriodType) {
                setChangePeriodType(0);
                dispatch(
                    setExecutionData({
                        executionData: {
                            ...executionReduxData.executionData,
                            generate_qtd_value: 0, // Disable QTD if quarter total is checked
                            generate_quarter_total: 0,
                            generate_year_total: 0,
                            generate_ytd_value: 0,
                        },
                    })
                );
            }
        }
    };

    return (
        <div>
            <div className="inner-title-head">
                <h1 key={restProps.key}>Execution Settings</h1>
            </div>
            <div className="inner-title-cont wrapfrequency">
                <div className={"k-form-field-wrap-popup"}>
                    <Label className="innerlabletext">{"Frequency*: "}</Label>
                    <CustomDropdown
                        name={"periodTypeValue"}
                        data={periodsTypeFields.periodTypeData}
                        textField="period_type_name"
                        onOpen={(dataValue) => {
                            setPeriodTypeFields({
                                ...periodsTypeFields,
                                periodTypeData: dataValue,
                            });
                        }}
                        dataItemKey="period_type_mstr_key"
                        valueData={periodTypeList.find(
                            (c) =>
                                c.period_type_mstr_key ===
                                    periodsTypeFields?.periodTypeValue
                                        ?.period_type_mstr_key || 0
                        )}
                        onChange={(e, name) => {
                            handleChangeDropdown(
                                e,
                                name,
                                "period_type_mstr_key"
                            );
                        }}
                        filterable={false}
                        autoClose={false}
                        autoBind={true}
                        isSelectALl={true}
                        dataList={periodTypeList}
                        loading={periodsTypeFields.isPeriodTypeDataLoading}
                        disabled={
                            restProps.pageProps.writeAccess === 0 ? true : false
                        }
                    />
                </div>
                {CHECKBOX_ARRAY.map((item, id) => {
                    return (
                        <div className={"k-form-field-wrap-popup"}>
                            <div className="execution-checkbox" key={id}>
                                <Checkbox
                                    id={item.key}
                                    label={item.label}
                                    disabled={
                                        restProps.pageProps.writeAccess === 0
                                            ? true
                                            : !disabledCheckboxState[item.key]
                                    }
                                    onChange={(e) =>
                                        handleCheckboxChange(e, item.key)
                                    }
                                    checked={
                                        CheckboxState[item.key] ? true : false
                                    }
                                    className={
                                        "k-checkbox-label innerlabletext"
                                    }
                                    style={{
                                        display: "inline-block",
                                    }}
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default React.memo(ExecutionSettings);
