import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { providerPlanStatementSelector } from "src/redux/selectors/custom-selector";

import { Label } from "@progress/kendo-react-labels";
import { Button } from "@progress/kendo-react-buttons";
import CustomDropdown from "../../components/customDropdown";

import CustomMultiSelect from "../../components/multiSelect";

import "./provider-report.scss";
import useProviderPlanStatementService from "./service";
import {
  setPrefetchDropdownAction,
  setSelectedProviderCompPlanAction,
  setSelectedProviderGroupsAction,
  setSelectedProviderPeriodAction,
  setSelectedProviderProviderAction,
} from "src/redux/actions/providerplan-actions";

const ProviderPlanFilter = ({ runReports }) => {
  const {
    compPlanData,
    isCompPlanLoading,
    selectedCompPlan,

    compPlanPeriodData,
    isCompPlanPeriodLoading,
    selectedCompPlanPeriod,

    groupData,
    isGroupLoading,
    selectedGroups,

    providerData,
    isProviderLoading,
    selectedProviders,
    
    prefetchDropdowns,
  } = useSelector(providerPlanStatementSelector, shallowEqual);
  const dispatch = useDispatch();

  const [activeCompPlanList, setActiveCompPlanList] = React.useState(
    compPlanData || []
  );
  const [activeCompPlanPeriodList, setActiveCompPlanPeriodList] =
    React.useState(compPlanPeriodData || []);
  const [activeGroupList, setActiveGroupList] = React.useState(groupData || []);
  const [activeProviderList, setActiveProviderList] = React.useState(
    providerData || []
  );

  const reportService = useProviderPlanStatementService();
  React.useEffect(() => {
    if (compPlanData?.length > 0) {
      setActiveCompPlanList(compPlanData);
    } else {
      reportService.getCompPlan();
    }
       // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compPlanData]);

  React.useEffect(() => {
    //if (compPlanPeriodData?.length > 0) {
    setActiveCompPlanPeriodList(compPlanPeriodData);
    //}
       // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compPlanPeriodData]);

  React.useEffect(() => {
    // if (groupData?.length > 0) {
    setActiveGroupList(groupData.filter((it) => it.title_key !== 0));
    //}
       // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupData]);

  React.useEffect(() => {
    // if (providerData?.length > 0) {
    setActiveProviderList(providerData.filter((it) => it.provider_key !== 0));
    //}
       // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerData]);

  useEffect(() => {
    if (prefetchDropdowns) {
      reportService.fetchCompPlanPeriods(selectedCompPlan);
    }
       // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompPlan, prefetchDropdowns]);

  useEffect(() => {
    if (prefetchDropdowns) {
      reportService.fetchGroups(selectedCompPlanPeriod);
    }
       // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompPlanPeriod, prefetchDropdowns]);

  useEffect(() => {
    if (prefetchDropdowns) {
      reportService.fetchProviders();
    }
       // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroups, prefetchDropdowns]);

  const handleChangeCompPlan = (value) => {
    dispatch(setPrefetchDropdownAction(true));
    dispatch(setSelectedProviderCompPlanAction(value));
  };

  const handleChangeCompPlanPeriod = (value) => {
    dispatch(setPrefetchDropdownAction(true));

    dispatch(setSelectedProviderPeriodAction(value));
  };

  const handleChangeGroups = (value) => {
    dispatch(setPrefetchDropdownAction(true));

    // const { value } = event;
    const items =
      value.length === 0 || value.findIndex((it) => it.title_key === 0) > 0
        ? [groupData[0]]
        : value.filter((it) => it.title_key !== 0);
    dispatch(setSelectedProviderGroupsAction(items));
  };

  const handleChangeProvider = (value) => {
    //dispatch(setPrefetchDropdownAction(true));

    // const { value } = event;
    const items =
      value.length === 0 || value.findIndex((it) => it.provider_key === 0) > 0
        ? [providerData[0]]
        : value.filter((it) => it.provider_key !== 0);

    dispatch(setSelectedProviderProviderAction(items));
  };

  return (
    <ul className="ProviderReportFilters-links reports-filters">
      <div
        className={"k-form-field-wrap-popup valueDropdown width280"}
        style={{ paddingRight: "5px !important" }}
      >
        <Label>{"Comp Plan:"}</Label>
        <CustomDropdown
          name="compPlanDropdown"
          data={activeCompPlanList}
          textField="comp_plan_name"
          dataItemKey="comp_plan_key"
          loading={isCompPlanLoading}
          valueData={selectedCompPlan}
          onChange={(value) => handleChangeCompPlan(value)}
          filterable={true}
          dataList={compPlanData}
          onClose={() => {
            setActiveCompPlanList(compPlanData);
          }}
          onFilterChange={(dataValue) => setActiveCompPlanList(dataValue)}
        />
      </div>
      <div
        className={"k-form-field-wrap-popup valueDropdown width125"}
        style={{ paddingRight: "5px !important"}}
      >
        <Label>{"Comp Plan Period:"}</Label>
        <CustomDropdown
          name="compPlanPeriodDropdown"
          data={activeCompPlanPeriodList}
          textField="period_name"
          dataItemKey="period_key"
          loading={isCompPlanPeriodLoading}
          valueData={selectedCompPlanPeriod}
          onChange={(value) => handleChangeCompPlanPeriod(value)}
          filterable={true}
          dataList={compPlanPeriodData}
          onClose={() => {
            setActiveCompPlanPeriodList(compPlanPeriodData);
          }}
          onFilterChange={(dataValue) => setActiveCompPlanPeriodList(dataValue)}
        />
      </div>

      <div
        className={"k-form-field-wrap-popup valueDropdown"}
        style={{ paddingRight: "5px !important" }}
      >
        <Label>{"Group:"}</Label>
        <CustomMultiSelect
          name="groupDropdown"
          data={activeGroupList}
          textField="title_name"
          dataItemKey="title_key"
          loading={isGroupLoading}
          valueData={selectedGroups}
          onChange={(value) => handleChangeGroups(value)}
          filterable={true}
          isSelectALl={false}
          dataList={groupData.filter((it) => it.title_key !== 0)}
          onClose={() => {
            setActiveGroupList(groupData.filter((it) => it.title_key !== 0));
          }}
          onFilterChange={(dataValue) => setActiveGroupList(dataValue)}
          style={{ maxHeight: 200 }}
          autoClose={false}
        />
      </div>
      <div
        className={"k-form-field-wrap-popup valueDropdown"}
        style={{ paddingRight: "5px !important" }}
      >
        <Label>{"Provider:"}</Label>
        <CustomMultiSelect
          name="providerDropdown"
          data={activeProviderList}
          textField="provider_name"
          dataItemKey="provider_key"
          loading={isProviderLoading}
          valueData={selectedProviders}
          onChange={(value) => handleChangeProvider(value)}
          filterable={true}
          isSelectALl={false}
          dataList={providerData.filter((it) => it.provider_key !== 0)}
          onClose={() => {
            setActiveProviderList(providerData.filter((it) => it.provider_key !== 0));
          }}
          onFilterChange={(dataValue) => setActiveProviderList(dataValue)}
          style={{ maxHeight: 200 }}
          autoClose={false}
        />
      </div>

      <div className="k-form-buttons btn-provider-filter">
        <Button
          primary={true}
          disabled={
            !selectedCompPlan ||
            !selectedCompPlanPeriod ||
            !selectedGroups.length ||
            !selectedProviders.length ||
            isCompPlanLoading ||
            isCompPlanPeriodLoading ||
            isGroupLoading ||
            isProviderLoading
          }
          type={"submit"}
          onClick={runReports}
          className="primarybtn"
        >
          Run
        </Button>
      </div>
    </ul>
  );
};
export default ProviderPlanFilter;
