/**
 * Form schema for email send popup
 */
import { FormInput } from "src/components/form/form-elements";

const schema = {
    in_username: {
        id: "in_username",
        name: "in_username",
        // label: "ProCARE User Name:",
        wrapperClass: "formelement fullwidthelm logininputs",
        component: FormInput,
        placeHolder:'Enter Username',
        autoFocus: true,
        autoComplete: "on",
        rules: {
            required: "ProCARE Username is required",
            // minLength: {
            //     value: 3,
            //     message: "ProCARE Username should have at least 3 characters",
            // },
        },
    },
};

export default schema;
