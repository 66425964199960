/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { DtuDataListGroup } from "../dtuData/DtuDataList";
import Popup from "../../components/customPopup";
import { Form, FormElement, FieldWrapper } from "@progress/kendo-react-form";
import { Label, Error } from "@progress/kendo-react-labels";
import { Input, TextArea } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Button } from "@progress/kendo-react-buttons";
import MessageSchema from "../../utils/messageSchema";
import { getUserCredential } from "../../lib/cookies";
import DtuData from "./service";
// import { setNewMessage } from "../../redux/actions/setting-actions";
import {
  GET_DTU_LOGS,
  GET_ETL_EVENT_TYPE,
} from "../../api-setup/api-endpoints";
import { POSTAPI } from "../../api-setup/api-helper";
import { useDispatch } from "react-redux";

import { HeaderPage } from "../base/headerPage";

const initialFormFields = {
  etl_file_name: "",
  etl_file_desc: "",
  etl_file_table: "",
  etl_pdi_label: "",
  in_event_type_mstr_key: "",
  etl_table_mstr_key: "",
  etl_event_type_mstr_key: "",
  in_event_type_mstr_label: "",
};

const DtuDataList = (pageProps) => {
  const [list, Action] = DtuData();
  const dispatch = useDispatch();
  const cookiesData = getUserCredential();
  const header = {
    "x-access-token": cookiesData.secretToken,
  };

  const addRef = React.useRef(null);
  let divRef = React.createRef();
  let objDrpRef = React.useRef(null);

  const [columnsData, setColumnsData] = React.useState([]);
  const [isAddNewConfig, setNewConfig] = React.useState(false);
  const [isShow, setShow] = React.useState({
    dtuLogs: false,
    deleteSelectedRow: false,
    sendEmails: false,
  });
  const [exportDataClick, setExportDataClick] = React.useState(false);
  const [isAPICall, setAPICall] = React.useState(false);
  const [isCheckColumn, setColumnCheck] = React.useState(false);
  const [formFields, setFormFields] = React.useState({
    ...initialFormFields,
  });
  const [errorObj, setErrorObj] = React.useState({});
  const [isSaveClick, setSaveClick] = React.useState(false);
  const [loadingState, setLoadingState] = React.useState({
    isOwnerUserListLoading: false,
    isAssignCompListLoading: false,
    isCancelClick: false,
    isUpdateClick: false,
  });
  const [dataList, setDataList] = React.useState([]);
  const [dtuTableData, setdtuTableData] = React.useState([]);
  const [eventTypeData, seteventTypeData] = React.useState([]);
  const [eventLoading, setEventLoading] = React.useState(false);
  const [customeventLoading, setcustomEventLoading] = React.useState(false);
  const [selectedRowId, setSelectedRowId] = React.useState("");
  const [isUpdatenewConfig, setUpdatenewConfig] = React.useState(false);
  let popupRef = React.useRef();
  // useDocumentClickEventListener(isAddNewConfig, setNewConfig, popupRef);

  React.useEffect(() => {
    if (list.loadingState.isSaveData) {
      setFormFields({ ...initialFormFields });
      setAPICall(true);
      setNewConfig(false);
      list.setLoadState({ ...loadingState, isSaveData: undefined });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list.loadingState.isSaveData]);

  const addNewConfig = async () => {
    setFormFields(initialFormFields);

    setEventLoading(false);
    setcustomEventLoading(false);
    setErrorObj({});
    setNewConfig(!isAddNewConfig);
    // close update popup while add
    setUpdatenewConfig(false);
    getDtuLogsAsync();
  };

  const getDtuLogsAsync = () => {
    const data = {
      in_filter: "",
      in_sort_number: null,
      in_sort_order: null,
      in_page_row_offset: 0,
      in_page_rows: 100,
    };

    POSTAPI(false, GET_DTU_LOGS, null, header, data, dispatch).then((res) => {
      if (res.data.out_parameters.out_ret_code === 0) {
        if (res.data.row_result.length > 0) {
          if (res.status) {
            let tempArray = [];
            setFormFields({
              ...formFields,
              etl_file_table: {
                etl_table_label: res.data.row_result[0].etl_table_label,
                etl_table_mstr_key: res.data.row_result[0].etl_table_mstr_key,
              },
              etl_table_mstr_key: res.data.row_result[0].etl_table_mstr_key,
            });
            res.data.row_result.map((item) => {
              let obj = {};
              obj.etl_table_label = item.etl_table_label;
              obj.etl_table_mstr_key = item.etl_table_mstr_key;
              tempArray.push(obj);
              return 0;
            });
            setdtuTableData(tempArray);
          } else {
            // errStatusHandler(res, history, dispatch);
          }
        }
      } else {
        dispatch();
        // setNewMessage({
        //   message: res.data.out_parameters.out_error_msg,
        //   type: "Error",
        //   show: true,
        // })
      }
    });
  };

  const GetEventTypeAPI = (module, etl_table_mstr_key) => {
    const data = {
      in_tenant_id: cookiesData.out_tenant_id,
      in_filter: `AND a.etl_table_mstr_key = '${etl_table_mstr_key}' `,
      in_sort_number: 1,
      in_sort_order: "ASC",
    };

    POSTAPI(false, GET_ETL_EVENT_TYPE, null, header, data, dispatch).then(
      (res) => {
        if (res.data.out_parameters.out_ret_code === 0) {
          if (res.data.row_result.length > 0) {
            if (res.status) {
              let tempArray = [];

              res.data.row_result.map((item) => {
                let obj = {};
                obj.in_event_type_mstr_label = item.event_type_name;
                obj.in_event_type_mstr_key = item.event_type_mstr_key;
                tempArray.push(obj);
                return 0;
              });
              seteventTypeData(tempArray);
              setFormFields({
                ...formFields,
                etl_pdi_label: "",
                in_event_type_mstr_label: {
                  in_event_type_mstr_label:
                    res.data.row_result[0].event_type_name,
                  in_event_type_mstr_key:
                    res.data.row_result[0].event_type_mstr_key,
                },
                etl_event_type_mstr_key:
                  res.data.row_result[0].event_type_mstr_key,
                etl_file_table: {
                  etl_table_label: module,
                  etl_table_mstr_key: etl_table_mstr_key,
                },
                etl_table_mstr_key: etl_table_mstr_key,
              });
            } else {
              // errStatusHandler(res, history, dispatch);
            }
          }
        }
        // else {
        //   dispatch(
        //     setNewMessage({
        //       message: res.data.out_parameters.out_error_msg,
        //       type: "Error",
        //       show: false,
        //     })
        //   );
        // }
      }
    );
  };

  const handleMenuItemClick = (module) => {
    switch (module) {
      case "dtuLogs":
        setShow({
          ...isShow,
          dtuLogs: !isShow.dtuLogs,
        });
        break;
      case "delete":
        setShow({
          ...isShow,
          deleteRows: !isShow.deleteRows,
        });
        break;
      default:
        break;
    }
  };

  const ShowError = React.useCallback(
    (props) => {
      return <Error>{errorObj[props.name]}</Error>;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errorObj, isSaveClick]
  );

  const handleValidation = () => {
    let isValid = true;
    // const pattern = /^[^.]+(\.(txt|csv))?$/;
    const pattern = /^[^. ]+(\.(txt|csv))?$/;
    const etlpattern = /^[a-zA-Z0-9_]+$/;

    const updatedErrorObj = { ...errorObj }; // Create a copy of the errorObj

    if (formFields.etl_file_name.trim() === "") {
      isValid = false;
      updatedErrorObj.etl_file_name = MessageSchema.etlFileNameRequired;
    }
    if (
      !pattern.test(formFields.etl_file_name) &&
      formFields.etl_file_name.trim() !== ""
    ) {
      isValid = false;
      updatedErrorObj.etl_file_name = MessageSchema.etlFileNameValidation;
    }
    if (eventLoading && formFields.etl_event_type_mstr_key === "") {
      isValid = false;
      updatedErrorObj.etl_event_type_mstr_key =
        MessageSchema.etlEventTypeNameRequired;
    }
    if (
      (eventLoading || customeventLoading) &&
      formFields.etl_pdi_label.trim() === ""
    ) {
      isValid = false;
      updatedErrorObj.etl_pdi_label = MessageSchema.etlFilPdiLableRequired;
    }
    if (
      (eventLoading || customeventLoading) &&
      !etlpattern.test(formFields.etl_pdi_label) &&
      formFields.etl_pdi_label.trim() !== ""
    ) {
      isValid = false;
      updatedErrorObj.etl_pdi_label = MessageSchema.etlFilPdiLableRequiredValid;
    }
    setErrorObj(updatedErrorObj); // Update the state with the new error object
    return isValid;
  };

  const handleSubmit = () => {
    let valid = handleValidation();
    if (valid) {
      setErrorObj("");
      const bodyData = {
        in_user_id: cookiesData.out_user_id.toString(),
        in_tenant_id: cookiesData.out_tenant_id,
        in_etl_file_name: formFields.etl_file_name,
        in_etl_file_desc: formFields.etl_file_desc,
        in_etl_table_mstr_key: formFields.etl_table_mstr_key,
        in_event_type_mstr_key: formFields.etl_event_type_mstr_key,
        in_etl_pdi_label:
          formFields.etl_pdi_label === "" ? null : formFields.etl_pdi_label,
      };
      Action.addDataAsync(bodyData);
      if (
        list.loadingState.isSaveData !== undefined &&
        list.loadingState.isSaveData !== false
      ) {
        setEventLoading(false);
        setcustomEventLoading(false);
      }
    } else {
      setSaveClick(true);
    }
  };

  const onCancelClick = () => {
    setFormFields(initialFormFields);
    setErrorObj({});
    setNewConfig(false);
    setSaveClick(false);
  };

  const handleFieldChange = (e) => {
    const name = e.name ? e.name : e.target.name;
    if (name === "etl_file_table") {
      setFormFields({
        ...formFields,
        etl_file_table: {
          etl_table_label: e.value.etl_table_label,
          etl_table_mstr_key: e.value.etl_table_mstr_key,
        },
        etl_table_mstr_key: e.value.etl_table_mstr_key,
      });
      handleOpenDropdown(e.value.etl_table_label, e.value.etl_table_mstr_key);
    } else if (name === "in_event_type_mstr_label") {
      setFormFields({
        ...formFields,
        in_event_type_mstr_label: {
          in_event_type_mstr_label: e.value.in_event_type_mstr_label,
          in_event_type_mstr_key: e.value.in_event_type_mstr_key,
        },
        etl_event_type_mstr_key: e.value.in_event_type_mstr_key,
      });
    } else {
      setFormFields({ ...formFields, [name]: e.value });
    }
    setErrorObj({ ...errorObj, [name]: "" });
  };

  const handleOpenDropdown = async (module, etl_key) => {
    if (
      module === "Event Patient Sat" ||
      module === "Event Quality" ||
      module === "Event Encounter" ||
      module === "Event"
    ) {
      seteventTypeData([]);
      setFormFields({
        ...formFields,
        etl_pdi_label: "",
        in_event_type_mstr_label: {
          in_event_type_mstr_label: "",
          in_event_type_mstr_key: "",
        },
        etl_event_type_mstr_key: "",

        etl_file_table: {
          etl_table_label: module,
          etl_table_mstr_key: etl_key,
        },
        etl_table_mstr_key: etl_key,
      });
      setEventLoading(true);
      setcustomEventLoading(false);
      GetEventTypeAPI(module, etl_key);
    } else if (module === "Custom" || module === "Custom Table") {
      setcustomEventLoading(true);
      setEventLoading(false);
      setFormFields({
        ...formFields,
        etl_pdi_label: "",
        in_event_type_mstr_label: {
          in_event_type_mstr_label: "",
          in_event_type_mstr_key: "",
        },
        etl_event_type_mstr_key: "",
        etl_file_table: {
          etl_table_label: module,
          etl_table_mstr_key: etl_key,
        },
        etl_table_mstr_key: etl_key,
      });
    } else {
      setEventLoading(false);
      setcustomEventLoading(false);
      setFormFields({
        ...formFields,
        etl_pdi_label: "",
        in_event_type_mstr_label: {
          in_event_type_mstr_label: "",
          in_event_type_mstr_key: "",
        },
        etl_event_type_mstr_key: "",
        etl_file_table: {
          etl_table_label: module,
          etl_table_mstr_key: etl_key,
        },
        etl_table_mstr_key: etl_key,
      });
    }
  };

  const handleCancel = () => {
    setLoadingState({ ...loadingState, isCancelClick: true });
  };

  const handleUpdate = () => {
    setLoadingState({ ...loadingState, isUpdateClick: true });
  };

  const AddNewForm = () => {
    return (
      <Form
        onSubmit={handleSubmit}
        render={() => (
          <FormElement className="emailForm textboxeswrapper" horizontal={true}>
            <FieldWrapper>
              <div className={"k-form-field-wrap-popup"}>
                <Label>{"ETL File Name*:"}</Label>
                <Input
                  type={"text"}
                  name="etl_file_name"
                  placeholder="ETL File Name"
                  value={formFields.etl_file_name}
                  onChange={(e) => {
                    handleFieldChange(e);
                  }}
                  autoFocus={true}
                />
                <ShowError name={"etl_file_name"} />
              </div>
            </FieldWrapper>

            <FieldWrapper>
              <div className={"k-form-field-wrap-popup"}>
                <Label>{"ETL File Description:"}</Label>
                <Input
                  // rows={4}
                  value={formFields.etl_file_desc}
                  onChange={(e) => {
                    handleFieldChange(e);
                  }}
                  name="etl_file_desc"
                  placeholder="ETL File Description"
                />
                {/* <ShowError name={"etl_file_desc"} /> */}
              </div>
            </FieldWrapper>
            <FieldWrapper>
              <div
                className={"k-form-field-wrap-popup valueDropdown"}
                ref={objDrpRef}
              >
                <Label>{"ETL File Table*:"}</Label>

                <DropDownList
                  onChange={(e) => {
                    handleFieldChange(e);
                  }}
                  value={formFields.etl_file_table}
                  data={dtuTableData}
                  textField="etl_table_label"
                  dataItemKey="etl_table_mstr_key"
                  name="etl_file_table"
                  popupSettings={{ appendTo: objDrpRef.current }}
                />
              </div>
            </FieldWrapper>
            {eventLoading ? (
              <FieldWrapper>
                <div className={"k-form-field-wrap-popup valueDropdown hide"}>
                  <Label>{"Event Type*:"}</Label>

                  <DropDownList
                    onChange={(e) => {
                      handleFieldChange(e);
                    }}
                    value={formFields.in_event_type_mstr_label}
                    data={eventTypeData}
                    textField="in_event_type_mstr_label"
                    dataItemKey="in_event_type_mstr_key"
                    name="in_event_type_mstr_label"
                  />
                  <ShowError name={"etl_event_type_mstr_key"} />
                </div>
              </FieldWrapper>
            ) : (
              ""
            )}
            {eventLoading || customeventLoading ? (
              <FieldWrapper>
                <div className={"k-form-field-wrap-popup"}>
                  <Label>{"ETL Pdi Label*:"}</Label>
                  <TextArea
                    // rows={4}
                    value={formFields.etl_pdi_label}
                    onChange={(e) => {
                      handleFieldChange(e);
                    }}
                    name="etl_pdi_label"
                    placeholder="ETL Pdi Label"
                  />
                  <ShowError name={"etl_pdi_label"} />
                </div>
              </FieldWrapper>
            ) : (
              ""
            )}
          </FormElement>
        )}
      />
    );
  };

  const AddNewPopup = () => {
    return (
      <Popup anchor={addRef.current} show={isAddNewConfig} className="right0">
        <div className="dot-dropdown-open dot-dropdown-open-Form">
          <div className="caption">
            <span>Add New DTU Settings</span>
          </div>
          {AddNewForm()}

          <div className="dropdownFooter">
            <div className="k-form-buttons">
              <Button
                primary={true}
                type="submit"
                onClick={() => {
                  onCancelClick();
                }}
              >
                Cancel
              </Button>
              <Button
                primary={true}
                type="submit"
                onClick={() => {
                  handleSubmit(formFields, errorObj);
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </Popup>
    );
  };

  const AddNewLink = () => {
    return (
      <div ref={popupRef}>
        <a
          // href="/#"
          className="cta-small"
          ref={addRef}
          onClick={(e) => {
            addNewConfig(e);
          }}
        >
          <i className="fas fa-plus"></i> Add New DTU Settings
        </a>

        {AddNewPopup()}
      </div>
    );
  };

  const headerConfig = {
    title: "DTU Data File Settings",
    id: "dtuData",
    add_new_fx: AddNewLink,
    action_menu: {
      delete: { fx: handleMenuItemClick },
    },
    manage_col: {
      columnsData: columnsData,
      setColumnsData: setColumnsData,
      setColumnCheck: setColumnCheck,
    },
  };

  const parent = document.querySelector("#content-" + headerConfig.id);

  if (parent) {
    const exportToExcel = parent.querySelector(".export-to-excel");
    const auditLog = parent.querySelector(".audit-log");
    const deleteRows = parent.querySelector(".delete-rows");

    exportToExcel && exportToExcel.disableKendoMenu();
    auditLog && auditLog.disableKendoMenu();
    deleteRows && deleteRows.disableKendoMenu();

    dataList.length && exportToExcel && exportToExcel.enableKendoMenu();

    if (selectedRowId !== "") {
      auditLog && auditLog.enableKendoMenu();
      deleteRows && deleteRows.enableKendoMenu();
    }
  }
  return (
    <>
      <div className="Main dtudatafile">
        <div className="content">
          <div className="content-head">
            <HeaderPage pageProps={pageProps} headerConfig={headerConfig} />
          </div>
          <div className="content-body">
            <div
              className="dtu-file-container"
              ref={divRef}
              id="edit-raw-container"
            >
              <DtuDataListGroup
                loadingState={loadingState}
                setLoadingState={setLoadingState}
                setColumnCheck={setColumnCheck}
                isCheckColumn={isCheckColumn}
                isAPICall={isAPICall}
                setAPICall={setAPICall}
                isDeleteSelectedRow={isShow.deleteSelectedRow}
                setShow={setShow}
                isShow={isShow}
                setExportDataClick={setExportDataClick}
                exportDataClick={exportDataClick}
                columnsData={columnsData}
                setColumnsData={setColumnsData}
                setDataList={setDataList}
                dataList={dataList}
                setSelectedRowId={setSelectedRowId}
                setdivRef={divRef}
                addNewConfig={addNewConfig}
                setNewConfig={setNewConfig}
                isUpdatenewConfig={isUpdatenewConfig}
                setUpdatenewConfig={setUpdatenewConfig}
                pageProps={pageProps}
              />
            </div>

            {dataList.filter((x) => x.expanded === true).length > 0 ? (
              <div className="btm-btn-cont">
                <li className="k-form-buttons">
                  <Button
                    type={"submit"}
                    onClick={() => {
                      handleCancel();
                    }}
                    className="k-primary"
                    disabled={
                      loadingState.isCancelClick || loadingState.isUpdateClick
                    }
                  >
                    Cancel
                  </Button>{" "}
                  <Button
                    type={"submit"}
                    onClick={() => {
                      handleUpdate();
                    }}
                    className="k-primary"
                    disabled={
                      loadingState.isCancelClick || loadingState.isUpdateClick
                    }
                  >
                    Save
                  </Button>
                </li>
              </div>
            ) : (
              false
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(DtuDataList);
