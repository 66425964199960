export const SET_PRORATION_DATA = "SET_PRORATION_DATA";
export const SET_EXECUTION_DATA = "SET_EXECUTION_DATA";
export const SET_RESULT_SETTING_DATA = "SET_RESULT_SETTING_DATA";
export const SET_RESET_STATE = "SET_RESET_STATE";
export const SET_ALL_DATA = "SET_ALL_DATA";
export const SET_COMP_RULE_UPDATE = "SET_COMP_RULE_UPDATE";


export const SET_RULE_FILTER_ITEMS_DATA = "SET_RULE_FILTER_ITEMS_DATA";


export function setProrationData(data) {
  return {
    type: SET_PRORATION_DATA,
    data: { ...data },
  };
}

export function setExecutionData(data) {
  return {
    type: SET_EXECUTION_DATA,
    data: { ...data },
  };
}

export function setResultSettingData(data) {
  return {
    type: SET_RESULT_SETTING_DATA,
    data: { ...data },
  };
}

export function setResetState(data) {
  return {
    type: SET_RESET_STATE,
    data: { ...data },
  };
}

export function setAllData(data) {
  return {
    type: SET_ALL_DATA,
    data: { ...data },
  };
}

export function setUpdateRule(data) {
  return {
    type: SET_COMP_RULE_UPDATE,
    data: data,
  };
}



export function setRuleFilterItemDataAction(data) {
  return {
    type: SET_RULE_FILTER_ITEMS_DATA,
    data: data,
  };
}
