import { useCallback, useRef, useState } from "react";
import {
  DashboardUpdatePodOrderApi
} from "src/service/dashboard.service";
import {
  DashboardPodList
} from "src/service/query/dashboard-tab-list.query";
import {
  getDashboardPod,
  mapDashboardPodData
} from "src/store/dashboard.store";
import { getUserData, mapUserData } from "src/store/user.store";

export const DashboardChartsHook = () => {
  const { tenantId, userKey, dbRoleKey } = mapUserData;
  const { tabId } = mapDashboardPodData;
  const activeDashboardPod = getDashboardPod();
  const userData = getUserData();
  const [tilesLayout, setTilesLayout] = useState([]);

  const dashboardPodList = DashboardPodList({
    tenantId: tenantId.getData(userData),
    userKey: userKey.getData(userData),
    dashboardTabMstrKey: tabId.getData(activeDashboardPod),
    dbRoleKey: dbRoleKey.getData(userData),
  });

  const refreshDashboardPodDetail = useCallback(() => {
    dashboardPodList.remove();
    dashboardPodList.refetch();
  }, [dashboardPodList]);
  
  const isDragging = useRef(false);
  const getUserKey = userKey.getData(userData) || "";
  const getTenantId = tenantId.getData(userData) || "";

  const handleReposition = (e) => {
    const newState = e.newState;
    setTilesLayout(newState);
    isDragging.current = true;
  };

  const updatePodOrder = () => {
    if (isDragging.current) {
      const dashboardPodKeyIds = tilesLayout.map(item => item.id).join(',');
      const request = {
        userId: getUserKey,
        dbRoleKey: dbRoleKey.getData(userData),
        tenantId: getTenantId,
        dashboardPodKeyList: dashboardPodKeyIds,
        dashboardTabMstrKey: tabId.getData(activeDashboardPod),
      };
      DashboardUpdatePodOrderApi(request);
    }
    isDragging.current = false;
  };

  return {
    dashboardPodList,
    refreshDashboardPodDetail,
    handleReposition,
    updatePodOrder,
    tilesLayout: {
      tilesLayout,
      setTilesLayout,
    }
  };
};
