import React from "react";
import { EnginePhaseData } from "../dataRows/dataRowsGrid";

const DataRows = (restProps) => {
    //disable in-cell update and cancel button
    return (
        <>
            <div className="dtu-file-container">
                <EnginePhaseData
                 jobTableMasterKey={restProps.jobTableMasterKey}
                 isEngineEdit={restProps.isEngineEdit}
                 setisEngineEdit={restProps.setisEngineEdit}
                 isUpdateClick={restProps.isUpdateClick}
                 compPlanKey={restProps.compPlanKey}
                 jobTypeName={restProps.jobTypeName}
                 isCancelClick={restProps.isCancelClick}
                 setisCancelClick={restProps.setisCancelClick}
                   
                />
            </div>
        </>
    );
};

export default React.memo(DataRows);
