import { applyMiddleware, createStore } from "redux";
import thunkMiddleware from "redux-thunk";
import rootReducer from "./reducer/root-reducer";
import { composeWithDevTools } from "redux-devtools-extension";

const middleWares = [thunkMiddleware];
const middlewareEnhancer = applyMiddleware(...middleWares);
const enhancers = middlewareEnhancer;

const composedEnhancers = composeWithDevTools(enhancers);

export const store = createStore(rootReducer, composedEnhancers);
