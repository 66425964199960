import React from "react";
import {
    errStatusHandler,
    generateHeader,
    POSTAPI,
} from "../../api-setup/api-helper";
import {
    GET_SYSTEM_CONFIG,
    UPDATE_SYSTEM_CONFIG,
} from "../../api-setup/api-endpoints";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { getUserCredential, setUserCredential } from "../../lib/cookies";
import { useDispatch } from "react-redux";
import { setNewMessage } from "../../redux/actions/setting-actions";
import { AutoComplete } from "@progress/kendo-react-dropdowns";
import { useHistory } from "react-router";
import { LoadingPanel } from "../../components/loadingPanel";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import moment from "moment";
import { transformDateTime } from "src/lib/date-time.util";
import("./system-settings.scss");

let objectsAreSame = [];
function checkSameObject(x, y) {
    if (x.length > 0 && y.length > 0) {
        for (let val in y) {
            if (x[val] && y[val] && x[val].pref_value !== y[val].pref_value) {
                if (objectsAreSame.length > 0) {
                    let findIndex = objectsAreSame.findIndex(
                        (item) =>
                            item.system_pref_config_key ===
                            x[val].system_pref_config_key
                    );
                    if (findIndex === -1) {
                        objectsAreSame.push(x[val]);
                    } else {
                        objectsAreSame[findIndex] = x[val];
                    }
                } else {
                    objectsAreSame.push(x[val]);
                }
            }
        }
    }
    return objectsAreSame;
}

const dropdownOptionList = (item) => {
    let values = JSON.parse(item.input_control_values);
    return values.map((vItem, i) => {
        return (
            <option key={vItem.key} value={vItem.key}>
                {vItem.value}
            </option>
        );
    });
};

const SERVICE_CONFIG = "etl";
const SERVER_CONFIG = "sys";
const EMAIL_CONFIG = "doc";

const handleSystemConfigData = (configData, config) => {
    return configData
        .filter((x) => x.category_code === config)
        .sort(function (a, b) {
            return a.system_pref_mstr_key - b.system_pref_mstr_key;
        });
};

const toggleValue = (cItem) => {
    return (
        <Tooltip openDelay={100} position="auto" anchorElement="target">
            <span title={cItem.system_pref_desc}>{cItem.system_pref_name}</span>
        </Tooltip>
    );
};

const SystemSetting = (props) => {
    let emailConfigRef = React.useRef();
    let serviceConfigRef = React.useRef();
    let serverConfigRef = React.useRef();
    let cookiesData = getUserCredential();
    const dispatch = useDispatch();
    const history = useHistory();

    const [loadingState, setLoadingState] = React.useState({
        initLoading: true,
        isSaveLoading: false,
    });
    const [systemConfigData, setSystemConfigData] = React.useState([]);
    const [originalData, setOriginalData] = React.useState([]);
    const [isExpandClick, setExpandClick] = React.useState(true);
    const [searchData, setSearchData] = React.useState([]);
    const [searchValue, setSearchValue] = React.useState("");
    const [expanded, setExpanded] = React.useState([".0"]);
    const [lastUpdatedRecord, setLastUpdatedRecord] = React.useState({});

    const disableFields = (item) => {
        let isDisable = false;
        if (item.input_control_type === "string | disable") {
            isDisable = true;
        }
        return props.writeAccess === 0 ? true : isDisable;
    };

    React.useEffect(() => {
        getSystemPrefConfig();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getSystemPrefConfig = () => {
        const data = {
            in_tenant_id: cookiesData.out_tenant_id,
            in_filter: null,
            in_sort_number: null,
            in_sort_order: null,
            in_page_row_offset: null,
            in_page_rows: null,
        };
        POSTAPI(
            false,
            GET_SYSTEM_CONFIG,
            null,
            generateHeader(),
            data,
            dispatch
        ).then((res) => {
            if (res.status) {
                if (res.data.out_parameters.out_ret_code === 0) {
                    const response = res.data.row_result;
                    response.map((item) => {
                        item.errorMsg = "";
                        return 0;
                    });
                    setLoadingState({ ...loadingState, initLoading: false });
                    let tempData = [];
                    const maxDateRecord = response.reduce((max, record) => {
                        // Convert date strings to Date objects
                        const recordDate = new Date(record.action_date);
                        const maxDate = new Date(max.action_date);

                        // Compare dates
                        return recordDate > maxDate ? record : max;
                    });
                    setLastUpdatedRecord(maxDateRecord);
                    response.map((item) => {
                        if (item.pref_value !== null) {
                            if (item.system_pref_code === "etl_dtu_email") {
                                let indexValue = response.findIndex(
                                    (x) =>
                                        x.system_pref_code === "etl_dtu_notif"
                                );
                                item.input_control_type =
                                    response[indexValue].pref_value === "0"
                                        ? "string | disable"
                                        : "string";
                            }
                            if (
                                item.system_pref_code === "sys_pipeline_email"
                            ) {
                                let tempIndex = response.findIndex(
                                    (x) =>
                                        x.system_pref_code ===
                                        "sys_pipeline_notif"
                                );
                                item.input_control_type =
                                    response[tempIndex].pref_value === "0"
                                        ? "string | disable"
                                        : "string";
                            }
                            tempData.push(item);
                        }
                        return 0;
                    });
                    setSearchData(
                        tempData.filter(
                            (x) =>
                                x.data_type_mstr_key === 1 ||
                                x.data_type_mstr_key === 3
                        )
                    );
                    setOriginalData(tempData);
                    setSystemConfigData(tempData);
                } else {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                }
            } else {
                errStatusHandler(res, history, dispatch);
            }
        });
    };

    const handleChange = (e, item) => {
        let updatedData = JSON.parse(JSON.stringify(systemConfigData));
        let index = updatedData.findIndex(
            (x) => x.system_pref_config_key === item.system_pref_config_key
        );
        updatedData[index].pref_value = e.target.value;
        let jobExecIndex = updatedData.findIndex(
            (x) => x.system_pref_code === "sys_job_exec_log"
        );
        let dbCleanIndex = updatedData.findIndex(
            (x) => x.system_pref_code === "db_cleanup_older_days"
        );
        if (index === dbCleanIndex) {
            if (
                parseInt(updatedData[jobExecIndex].pref_value) >
                parseInt(updatedData[index].pref_value)
            ) {
                updatedData[
                    jobExecIndex
                ].errorMsg = `Job execution log should be less than or equal to DB Cleanup Older Days`;
            } else {
                updatedData[jobExecIndex].errorMsg = ``;
            }
        }
        updatedData[index].errorMsg = "";
        setSystemConfigData(updatedData);
    };

    const handleCheckBoxChange = (e) => {
        let updatedData = JSON.parse(JSON.stringify(systemConfigData));
        let index = updatedData.findIndex(
            (x) => x.system_pref_code === e.target.name
        );
        updatedData[index].pref_value = e.target.checked ? "1" : "0";
        if (
            e.target.name === "etl_dtu_notif" ||
            e.target.name === "sys_pipeline_notif"
        ) {
            let index = updatedData.findIndex(
                (x) => x.system_pref_code === e.target.name
            );
            if (updatedData[index].system_pref_code === "etl_dtu_notif") {
                let indexValue = updatedData.findIndex(
                    (x) => x.system_pref_code === "etl_dtu_email"
                );
                updatedData[indexValue].input_control_type =
                    updatedData[index].pref_value === "0"
                        ? "string | disable"
                        : "string";
            }
            if (updatedData[index].system_pref_code === "sys_pipeline_notif") {
                let tempIndex = updatedData.findIndex(
                    (x) => x.system_pref_code === "sys_pipeline_email"
                );
                updatedData[tempIndex].input_control_type =
                    updatedData[index].pref_value === "0"
                        ? "string | disable"
                        : "string";
            }
        }
        setSystemConfigData(updatedData);
    };

    const handleValidation = () => {
        let valid = true;
        let validateData = JSON.parse(JSON.stringify(systemConfigData));
        let jobExecIndex = validateData.findIndex(
            (x) => x.system_pref_code === "sys_job_exec_log"
        );
        let dbCleanIndex = validateData.findIndex(
            (x) => x.system_pref_code === "db_cleanup_older_days"
        );
        validateData
            .filter((x) => x.system_pref_code !== "sys_export_email")
            .forEach((item) => {
                if (item.pref_value === "") {
                    valid = false;
                    item.errorMsg = `Please enter a valid ${item.system_pref_name}`;
                    search(
                        systemConfigData.filter(
                            (x) =>
                                x.data_type_mstr_key === 1 ||
                                x.data_type_mstr_key === 3 ||
                                x.data_type_mstr_key === 4
                        ),
                        item.system_pref_name
                    );
                }
            });
        if (
            parseInt(validateData[jobExecIndex].pref_value) >
            parseInt(validateData[dbCleanIndex].pref_value)
        ) {
            valid = false;
            validateData[
                jobExecIndex
            ].errorMsg = `Job execution log should be less than or equal to DB Cleanup Older Days`;
        }
        if (valid === false) {
            dispatch(
                setNewMessage({
                    message:
                        "There are errors in the form. kindly resolve to proceed",
                    type: "Error",
                    show: true,
                })
            );
        }
        setSystemConfigData(validateData);
        return valid;
    };

    const onSaveClick = () => {
        let valid = handleValidation();
        if (valid) {
            let updatedArray = checkSameObject(systemConfigData, originalData);
            if (updatedArray.length > 0) {
                updatedArray.map((item, i) => {
                    const data = {
                        in_user_id: cookiesData.out_user_id.toString(),
                        in_tenant_id: cookiesData.out_tenant_id,
                        in_system_pref_code: item.system_pref_code,
                        in_value: item.pref_value,
                    };
                    setLoadingState({ ...loadingState, initLoading: true });
                    POSTAPI(
                        false,
                        UPDATE_SYSTEM_CONFIG,
                        null,
                        generateHeader(),
                        data,
                        dispatch
                    ).then((res) => {
                        if (res.status) {
                            if (res.data.out_parameters.out_ret_code === 0) {
                                setLoadingState({
                                    ...loadingState,
                                    initLoading: false,
                                });
                                if (
                                    item.system_pref_code ===
                                    "grid_records_per_page"
                                ) {
                                    const existingData = cookiesData;
                                    const updatedData = {
                                        ...existingData,
                                        tenant_data: {
                                            ...existingData.tenant_data,
                                            record_per_page: {
                                                ...existingData.tenant_data
                                                    .record_per_page,
                                                recordTake: Number(
                                                    item.pref_value
                                                ),
                                                id: Number(item.pref_value),
                                            },
                                        },
                                    };
                                    setUserCredential(updatedData);
                                    getSystemPrefConfig();
                                }

                                if (updatedArray.length - 1 === i) {
                                    setOriginalData([...systemConfigData]);
                                    setLoadingState({
                                        ...loadingState,
                                        initLoading: false,
                                    });
                                    if (res.data.msg) {
                                        // dispatch(
                                        //     setNewMessage({ message: res.data.msg, type: 'Success', show: true })
                                        // )
                                    } else {
                                        dispatch(
                                            setNewMessage({
                                                message:
                                                    res.data.out_parameters
                                                        .out_error_msg,
                                                type: "Error",
                                                show: true,
                                            })
                                        );
                                    }
                                }
                            }
                        } else {
                            errStatusHandler(res, history, dispatch);
                        }
                    });
                    return 0;
                });
            }
        }
    };

    const onExpandClick = () => {
        setExpandClick(!isExpandClick);
        setExpanded([".0", ".1", ".2"]);
    };

    const onCollapseClick = () => {
        setExpandClick(!isExpandClick);
        setExpanded([]);
    };

    const handleSelect = (event) => {
        if (event.expandedItems) {
            setExpanded(event.expandedItems);
        }
    };
    const lookForElement = (ref, block) => {
        if (ref) {
            ref.current.scrollIntoView({
                behavior: "smooth",
                block: block || "start",
            });
            return;
        }
        setTimeout(lookForElement, 100);
    };

    const filterData = (value) => {
        let data = JSON.parse(
            JSON.stringify(
                systemConfigData.filter(
                    (x) =>
                        x.data_type_mstr_key === 1 ||
                        x.data_type_mstr_key === 3 ||
                        x.data_type_mstr_key === 4
                )
            )
        );
        let tempData = [];
        data.map((item) => {
            tempData.push(item.system_pref_name);
            return 0;
        });
        const filteredArray = tempData.filter((item) =>
            item.toLowerCase().includes(value.toLowerCase())
        );
        return filteredArray;
    };

    const onOpen = () => {
        let data = JSON.parse(
            JSON.stringify(
                systemConfigData.filter(
                    (x) =>
                        x.data_type_mstr_key === 1 ||
                        x.data_type_mstr_key === 3 ||
                        x.data_type_mstr_key === 4
                )
            )
        );
        let tempData = [];
        data.map((item) => {
            tempData.push(item.system_pref_name);
            return 0;
        });
        setSearchData(tempData);
    };

    const handleSearch = (e) => {
        let value = e.target.value;
        setSearchValue(e.target.value);
        let dataList = filterData(e.target.value);
        setSearchData(dataList);
        let newData = search(
            systemConfigData.filter(
                (x) =>
                    x.data_type_mstr_key === 1 ||
                    x.data_type_mstr_key === 3 ||
                    x.data_type_mstr_key === 4
            ),
            value
        );
        setSystemConfigData(newData);
    };

    const search = (items, term) => {
        return items.reduce((acc, item) => {
            if (contains(item.system_pref_name, term) && term !== "") {
                item.hasSearch = true;
                acc.push(item);
                if (
                    handleSystemConfigData(
                        systemConfigData,
                        SERVICE_CONFIG
                    ).filter(
                        (x) =>
                            (x.data_type_mstr_key === 1 ||
                                x.data_type_mstr_key === 3 ||
                                x.data_type_mstr_key === 4) &&
                            x.system_pref_name === item.system_pref_name
                    ).length > 0
                ) {
                    let expandValue = [".0"];
                    let data = [...expanded, ...expandValue];
                    setExpanded([...data]);
                    setTimeout(() => {
                        lookForElement(serviceConfigRef);
                    }, 500);
                } else if (
                    handleSystemConfigData(
                        systemConfigData,
                        SERVER_CONFIG
                    ).filter(
                        (x) =>
                            (x.data_type_mstr_key === 1 ||
                                x.data_type_mstr_key === 3 ||
                                x.data_type_mstr_key === 4) &&
                            x.system_pref_name === item.system_pref_name
                    ).length > 0
                ) {
                    let index = handleSystemConfigData(
                        systemConfigData,
                        SERVER_CONFIG
                    ).findIndex(
                        (x) =>
                            (x.data_type_mstr_key === 1 ||
                                x.data_type_mstr_key === 3 ||
                                x.data_type_mstr_key === 4) &&
                            x.system_pref_name === item.system_pref_name
                    );
                    let expandValue = [".1"];
                    let data = [...expanded, ...expandValue];
                    setExpanded([...data]);
                    setTimeout(() => {
                        lookForElement(
                            serverConfigRef,
                            index > 9 ? "end" : "start"
                        );
                    }, 500);
                } else if (
                    handleSystemConfigData(
                        systemConfigData,
                        EMAIL_CONFIG
                    ).filter(
                        (x) =>
                            (x.data_type_mstr_key === 1 ||
                                x.data_type_mstr_key === 3 ||
                                x.data_type_mstr_key === 4) &&
                            x.system_pref_name === item.system_pref_name
                    ).length > 0
                ) {
                    let expandValue = [".2"];
                    let data = [...expanded, ...expandValue];
                    setExpanded([...data]);
                    setTimeout(() => {
                        lookForElement(emailConfigRef);
                    }, 500);
                }
            } else {
                item.hasSearch = false;
                acc.push(item);
            }
            return acc;
        }, []);
    };

    const contains = (text, term) => {
        return text.toLowerCase() === term.toLowerCase();
    };

    const disableSaveButton = () => {
        let isDisable = false;
        if (systemConfigData.length > 0 && originalData.length > 0) {
            for (let val in originalData) {
                if (
                    systemConfigData[val] &&
                    originalData[val] &&
                    systemConfigData[val].pref_value !==
                        originalData[val].pref_value
                ) {
                    isDisable = true;
                    break;
                }
            }
        }
        // TODO: UNSAVED CHANGES
        // dispatch(setUnSavedChangesPopup({ isUnSavedChange: isDisable }));
        return isDisable;
    };

    const items = [
        {
            expanded: expanded.includes(0),
            ref: serviceConfigRef,
            id: "1",
            title: (
                <>
                    <h2 className="accordian-row-head" id="accordian">
                        <span>
                            <i className="fas fa-wrench"></i>
                        </span>{" "}
                        Service Configuration{" "}
                    </h2>
                </>
            ),
            content: (
                <div
                    className="acc_content sytemsettscroll"
                    ref={serviceConfigRef}
                >
                    <div className="acc_content-intro"></div>
                    <div className="acc_content-form">
                        {handleSystemConfigData(
                            systemConfigData,
                            SERVICE_CONFIG
                        )
                            .filter(
                                (x) =>
                                    (x.data_type_mstr_key === 1 ||
                                        x.data_type_mstr_key === 3) &&
                                    (x.input_control_type === "string" ||
                                        x.input_control_type ===
                                            "string | disable")
                            )
                            .map((item, i) => {
                                return (
                                    <div
                                        className="acc_content form-block"
                                        key={i}
                                    >
                                        <label
                                            className={
                                                item.hasSearch
                                                    ? "search-background-color"
                                                    : ""
                                            }
                                        >
                                            {item.system_pref_name}
                                            <span
                                                className="tooltip space-between"
                                                data-tooltip-location="right"
                                                data-tooltip={
                                                    item.system_pref_desc
                                                }
                                            >
                                                <i className="fas fa-info-circle"></i>
                                            </span>
                                        </label>
                                        <div
                                            className={`${
                                                item.errorMsg.length
                                                    ? "input error"
                                                    : "input"
                                            }`}
                                        >
                                            <i className="fas fa-wrench"></i>
                                            <input
                                                disabled={disableFields(item)}
                                                type="text"
                                                className="input_box"
                                                value={item.pref_value}
                                                name=""
                                                placeholder=""
                                                onChange={(e) => {
                                                    handleChange(
                                                        e,
                                                        item,
                                                        SERVICE_CONFIG
                                                    );
                                                }}
                                            />
                                            <span className="error-msg">
                                                {item.errorMsg}
                                            </span>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                    <div className="acc_content-toggle">
                        <ul className="acc_toggle">
                            {handleSystemConfigData(
                                systemConfigData,
                                SERVICE_CONFIG
                            )
                                .filter((x) => x.data_type_mstr_key === 4)
                                .map((cItem, i) => {
                                    return (
                                        <li key={i}>
                                            <input
                                                value={
                                                    cItem.pref_value === "1"
                                                        ? true
                                                        : false
                                                }
                                                checked={
                                                    cItem.pref_value === "1"
                                                        ? true
                                                        : false
                                                }
                                                type="checkbox"
                                                name={cItem.system_pref_code}
                                                id={cItem.system_pref_code}
                                                onChange={(e) => {
                                                    handleCheckBoxChange(
                                                        e,
                                                        cItem
                                                    );
                                                }}
                                                disabled={
                                                    props.writeAccess === 0
                                                        ? true
                                                        : false
                                                }
                                            />{" "}
                                            <label
                                                htmlFor={cItem.system_pref_code}
                                                className={
                                                    cItem.hasSearch
                                                        ? "search-background-color"
                                                        : ""
                                                }
                                            >
                                                {toggleValue(cItem)}
                                            </label>
                                        </li>
                                    );
                                })}
                        </ul>
                    </div>
                    {/* <div className="acc_content form-block">
            {handleSystemConfigData(systemConfigData, SERVICE_CONFIG)
              .filter(
                (x) =>
                  (x.data_type_mstr_key === 1 || x.data_type_mstr_key === 3) &&
                  x.input_control_type === "select"
              )
              .map((item, i) => {
                return (
                  <>
                    <label
                      className={
                        item.hasSearch ? "search-background-color" : ""
                      }
                    >
                      {item.system_pref_name}
                      <span
                        className="tooltip space-between"
                        data-tooltip-location="left"
                        data-tooltip={item.system_pref_desc}
                      >
                        <i className="fas fa-info-circle"></i>
                      </span>
                    </label>
                    <div
                      className={`${
                        item.errorMsg.length ? "input error" : "input"
                      }`}
                    >
                      <i className="fas fa-wrench"></i>
                      <select
                        name=""
                        className="input_box"
                        onChange={(e) => {
                          handleChange(e, item, SERVICE_CONFIG);
                        }}
                        value={item.pref_value}
                      >
                        {dropdownOptionList(item)}
                      </select>
                      <span className="error-msg">{item.errorMsg}</span>
                    </div>
                  </>
                );
              })}
          </div> */}
                </div>
            ),
        },
        {
            expanded: expanded.includes(1),
            ref: serverConfigRef,
            id: "2",
            title: (
                <>
                    <h2 className="accordian-row-head" id="accordian">
                        <span>
                            <i className="fas fa-server"></i>
                        </span>{" "}
                        Server Configuration{" "}
                    </h2>
                </>
            ),
            content: (
                <div className="acc_content sytemsettscroll">
                    <div className="acc_content-intro"></div>
                    <div className="acc_content-form" ref={serverConfigRef}>
                        {handleSystemConfigData(systemConfigData, SERVER_CONFIG)
                            .filter(
                                (x) =>
                                    (x.data_type_mstr_key === 1 ||
                                        x.data_type_mstr_key === 3) &&
                                    (x.input_control_type === "string" ||
                                        x.input_control_type ===
                                            "string | disable")
                            )
                            .map((item, i) => {
                                return (
                                    <div
                                        className="acc_content form-block"
                                        key={i}
                                    >
                                        <label
                                            className={
                                                item.hasSearch
                                                    ? "search-background-color"
                                                    : ""
                                            }
                                        >
                                            {item.system_pref_name}
                                            <span
                                                className="tooltip space-between"
                                                data-tooltip-location="right"
                                                data-tooltip={
                                                    item.system_pref_desc
                                                }
                                            >
                                                <i className="fas fa-info-circle"></i>
                                            </span>
                                        </label>
                                        <div
                                            className={`${
                                                item.errorMsg.length
                                                    ? "input error"
                                                    : "input"
                                            }`}
                                        >
                                            <i className="fas fa-wrench"></i>
                                            <input
                                                disabled={disableFields(item)}
                                                type="text"
                                                className="input_box"
                                                value={item.pref_value}
                                                name=""
                                                placeholder=""
                                                onChange={(e) => {
                                                    handleChange(
                                                        e,
                                                        item,
                                                        SERVER_CONFIG
                                                    );
                                                }}
                                            />
                                            <span className="error-msg">
                                                {item.errorMsg}
                                            </span>
                                        </div>
                                    </div>
                                );
                            })}

                        
                            {handleSystemConfigData(
                                systemConfigData,
                                SERVER_CONFIG
                            )
                                .filter(
                                    (x) =>
                                        (x.data_type_mstr_key === 1 ||
                                            x.data_type_mstr_key === 3) &&
                                        x.input_control_type === "select"
                                )
                                .map((item, i) => {
                                    return (
                                        <div className="acc_content form-block" key={i}>
                                            <label
                                                className={
                                                    item.hasSearch
                                                        ? "search-background-color"
                                                        : ""
                                                }
                                            >
                                                {item.system_pref_name}
                                                <span
                                                    className="tooltip space-between"
                                                    data-tooltip-location="right"
                                                    data-tooltip={
                                                        item.system_pref_desc
                                                    }
                                                >
                                                    <i className="fas fa-info-circle"></i>
                                                </span>
                                            </label>
                                            <div
                                                className={`${
                                                    item.errorMsg.length
                                                        ? "input error"
                                                        : "input"
                                                }`}
                                            >
                                                <i className="fas fa-wrench"></i>
                                                <select
                                                    name=""
                                                    className="input_box"
                                                    onChange={(e) => {
                                                        handleChange(
                                                            e,
                                                            item,
                                                            SERVER_CONFIG
                                                        );
                                                    }}
                                                    value={item.pref_value}
                                                    disabled={
                                                        props.writeAccess === 0
                                                            ? true
                                                            : false
                                                    }
                                                >
                                                    {dropdownOptionList(item)}
                                                </select>
                                                <span className="error-msg">
                                                    {item.errorMsg}
                                                </span>
                                            </div>
                                        </div>
                                    );
                                })}
                        
                    </div>
                    <div className="acc_content-toggle">
                        <ul className="acc_toggle">
                            {handleSystemConfigData(
                                systemConfigData,
                                SERVER_CONFIG
                            )
                                .filter((x) => x.data_type_mstr_key === 4)
                                .map((item, i) => {
                                    return (
                                        <li key={i}>
                                            <input
                                                value={
                                                    item.pref_value === "1"
                                                        ? true
                                                        : false
                                                }
                                                checked={
                                                    item.pref_value === "1"
                                                        ? true
                                                        : false
                                                }
                                                type="checkbox"
                                                name={item.system_pref_code}
                                                id={item.system_pref_code}
                                                onChange={(e) => {
                                                    handleCheckBoxChange(
                                                        e,
                                                        item
                                                    );
                                                }}
                                                disabled={
                                                    props.writeAccess === 0
                                                        ? true
                                                        : false
                                                }
                                            />{" "}
                                            <label
                                                htmlFor={item.system_pref_code}
                                                className={
                                                    item.hasSearch
                                                        ? "search-background-color"
                                                        : ""
                                                }
                                            >
                                                {toggleValue(item)}
                                            </label>
                                        </li>
                                    );
                                })}
                        </ul>
                    </div>
                </div>
            ),
        },
        {
            expanded: expanded.includes(2),
            ref: emailConfigRef,
            id: "3",
            title: (
                <>
                    <h2 className="accordian-row-head" id="accordian">
                        <span>
                            <i className="fas fa-envelope"></i>
                        </span>{" "}
                        Document Configuration
                    </h2>
                </>
            ),
            content: (
                <div
                    className="acc_content sytemsettscroll"
                    ref={emailConfigRef}
                >
                    <div className="acc_content-intro"></div>
                    <div className="acc_content-form">
                        {handleSystemConfigData(systemConfigData, EMAIL_CONFIG)
                            .filter(
                                (x) =>
                                    (x.data_type_mstr_key === 1 ||
                                        x.data_type_mstr_key === 3) &&
                                    (x.input_control_type === "string" ||
                                        x.input_control_type ===
                                            "string | disable")
                            )
                            .map((item, i) => {
                                return (
                                    <div
                                        className="acc_content form-block"
                                        key={i}
                                    >
                                        <label
                                            className={
                                                item.hasSearch
                                                    ? "search-background-color"
                                                    : ""
                                            }
                                        >
                                            {item.system_pref_name}
                                            <span
                                                className="tooltip space-between"
                                                data-tooltip-location="right"
                                                data-tooltip={
                                                    item.system_pref_desc
                                                }
                                            >
                                                <i className="fas fa-info-circle"></i>
                                            </span>
                                        </label>
                                        <div
                                            className={`${
                                                item.errorMsg.length
                                                    ? "input error"
                                                    : "input"
                                            }`}
                                        >
                                            <i className="fas fa-wrench"></i>
                                            <input
                                                disabled={disableFields(item)}
                                                type="text"
                                                className="input_box"
                                                value={item.pref_value}
                                                name=""
                                                placeholder=""
                                                onChange={(e) => {
                                                    handleChange(
                                                        e,
                                                        item,
                                                        SERVICE_CONFIG
                                                    );
                                                }}
                                            />
                                            <span className="error-msg">
                                                {item.errorMsg}
                                            </span>
                                        </div>
                                    </div>
                                );
                            })}

                            {handleSystemConfigData(systemConfigData, EMAIL_CONFIG)
              .filter(
                (x) =>
                  (x.data_type_mstr_key === 1 || x.data_type_mstr_key === 3) &&
                  x.input_control_type === "select"
              )
              .map((item, i) => {
                return (
                  <>
                    <div className="acc_content form-block">
                      <label
                        className={
                          item.hasSearch ? "search-background-color" : ""
                        }
                      >
                        {item.system_pref_name}
                        <span
                          className="tooltip space-between"
                          data-tooltip-location="right"
                          data-tooltip={item.system_pref_desc}
                        >
                          <i className="fas fa-info-circle"></i>
                        </span>
                      </label>
                      <div
                        className={`${
                          item.errorMsg.length ? "input error" : "input"
                        }`}
                      >
                        <i className="fas fa-wrench"></i>
                        <select
                          name=""
                          className="input_box"
                          onChange={(e) => {
                            handleChange(e, item, EMAIL_CONFIG);
                          }}
                          value={item.pref_value}
                          disabled={props.writeAccess === 0 ? true : false}
                        >
                          {dropdownOptionList(item)}
                        </select>
                        <span className="error-msg">{item.errorMsg}</span>
                      </div>
                    </div>
                  </>
                );
              })}
                    </div>
                    <div className="acc_content-toggle">
                        <ul className="acc_toggle">
                            {handleSystemConfigData(
                                systemConfigData,
                                EMAIL_CONFIG
                            )
                                .filter((x) => x.data_type_mstr_key === 4)
                                .map((sItem, i) => {
                                    return (
                                        <li key={i}>
                                            <input
                                                value={
                                                    sItem.pref_value === "1"
                                                        ? true
                                                        : false
                                                }
                                                checked={
                                                    sItem.pref_value === "1"
                                                        ? true
                                                        : false
                                                }
                                                type="checkbox"
                                                name={sItem.system_pref_code}
                                                id={sItem.system_pref_code}
                                                onChange={(e) => {
                                                    handleCheckBoxChange(
                                                        e,
                                                        sItem
                                                    );
                                                }}
                                                disabled={
                                                    props.writeAccess === 0
                                                        ? true
                                                        : false
                                                }
                                            />{" "}
                                            <label
                                                htmlFor={sItem.system_pref_code}
                                                className={
                                                    sItem.hasSearch
                                                        ? "search-background-color"
                                                        : ""
                                                }
                                            >
                                                {toggleValue(sItem)}
                                            </label>
                                        </li>
                                    );
                                })}
                        </ul>
                    </div>
                </div>
            ),
        },
    ];

    const PanelBarComponent = React.useCallback(
        (props) => {
            return (
                <>
                    <PanelBar
                        expandMode="multiple"
                        onSelect={handleSelect}
                        keepItemsMounted
                        isControlled={true}
                        expanded={expanded}
                    >
                        {props.itemData.map((item, i) => {
                            return (
                                <PanelBarItem key={item.id} title={item.title}>
                                    <div ref={item.ref} key={i}>
                                        {item.content}
                                    </div>
                                </PanelBarItem>
                            );
                        })}
                    </PanelBar>
                </>
            );
        },
        [expanded]
    );

    return (
        <>
            <div className="Main systemsettings">
                {loadingState.initLoading ? <LoadingPanel /> : false}
                <div className="content">
                    <div className="content-head sticky-header">
                        <div className="content-head-left">
                            <div className="Page-title">
                                <h1>System Settings</h1>
                            </div>
                            <div className="last-edited">
                                {/* <img
                                    src={
                                        require("../../images/Chris.png")
                                            .default
                                    }
                                    alt="icon"
                                /> */}
                                {/* <span>
                                    {Object.keys(lastUpdatedRecord).length > 0 ?
                                        <>
                                        User Name
                                            <b>{lastUpdatedRecord?.user_first_name + " " + lastUpdatedRecord?.user_last_name}</b>{" "}

                                        </>
                                        : false}
                                </span> */}
                            </div>
                        </div>
                        <div className="content-head-right">
                            <ul className="system-actions">
                                <li className="system-search">
                                    <i className="fas fa-search"></i>{" "}
                                    <span>
                                        <AutoComplete
                                            style={{
                                                width: "300px",
                                                height: "37px",
                                            }}
                                            className="system-search"
                                            data={searchData}
                                            onChange={(e) => {
                                                handleSearch(e);
                                            }}
                                            value={searchValue}
                                            placeholder="Type service attribute"
                                            onOpen={onOpen}
                                        />
                                    </span>
                                </li>
                                {isExpandClick ? (
                                    <li
                                        className={
                                            isExpandClick
                                                ? "expand-click active"
                                                : "expand-click"
                                        }
                                        id="expand"
                                        onClick={() => {
                                            onExpandClick();
                                        }}
                                    >
                                        <i className="fas fa-plus-square"></i>{" "}
                                        <span className="mobi-hide">
                                            Expand All
                                        </span>
                                    </li>
                                ) : (
                                    <li
                                        className={
                                            isExpandClick
                                                ? "expand-click"
                                                : "expand-click"
                                        }
                                        id="collapse"
                                        onClick={() => {
                                            onCollapseClick();
                                        }}
                                    >
                                        <i className="fas fa-minus-square"></i>
                                        <span className="mobi-hide">
                                            {" "}
                                            Collapse All
                                        </span>
                                    </li>
                                )}
                                {props.writeAccess === 1 && (
                                    <li className="top-button-actions systemconfigbuttons">
                                        <button
                                            className="cancelbtn k-button"
                                            disabled={
                                                disableSaveButton()
                                                    ? false
                                                    : true
                                            }
                                            type="submit"
                                            onClick={() => {
                                                setSystemConfigData([
                                                    ...originalData,
                                                ]);
                                            }}
                                        >
                                            CANCEL
                                        </button>
                                        <button
                                            className={
                                                disableSaveButton()
                                                    ? "primarybtn k-button"
                                                    : "primarybtn k-button"
                                            }
                                            type="submit"
                                            disabled={
                                                disableSaveButton()
                                                    ? false
                                                    : true
                                            }
                                            onClick={() => {
                                                onSaveClick();
                                            }}
                                        >
                                            SAVE
                                        </button>
                                        {/* </div> */}
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>

                    <div className="content-body">
                        <div className="lastedited">
                            <i className="fas fa-calendar-alt"></i> Last edited
                            by{" "}
                            <b>
                                {Object.keys(lastUpdatedRecord).length > 0 ? (
                                    <>
                                        {lastUpdatedRecord?.user_first_name +
                                            " " +
                                            lastUpdatedRecord?.user_last_name}{" "}
                                    </>
                                ) : (
                                    false
                                )}
                            </b>{" "}
                            on{" "}
                            {transformDateTime({
                                date: lastUpdatedRecord?.action_date,
                                format: "MM/DD/YYYY",
                                includeTime: true,
                            })}{" "}
                        </div>
                        <div className="systemA-content">
                            <div className="accrodian">
                                <div
                                    className="accrodian-row"
                                    id="service-config"
                                >
                                    <PanelBarComponent itemData={items} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default React.memo(SystemSetting);
