/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
    mainSelector,
    unSaveChangesPopupSelector,
} from "../redux/selectors/custom-selector";
import {
    clearLinkedRules,
    setUnSavedChangesPopup,
} from "../redux/actions/setting-actions";
import { useHistory, useLocation } from "react-router-dom";
import { clearReportsState } from "src/redux/actions/reports-actions";
import { clearCompPlanReportsState } from "src/redux/actions/compplan-actions";
import { clearProviderPlanReportsState } from "src/redux/actions/providerplan-actions";

const Sidebar = () => {
    const dispatch = useDispatch();
    const { dbObjData } = useSelector(mainSelector, shallowEqual);
    const { unsavedPopup } = useSelector(
        unSaveChangesPopupSelector,
        shallowEqual
    );
    const history = useHistory();

    const ToggleClass = (e) => {
        const targetNode = e.currentTarget;
        const parentNode = targetNode.parentNode;

        if (!parentNode.querySelector("ul li.active")) {
            parentNode.classList.toggle("active");
            targetNode.querySelector("span i").classList.toggle("fa-plus");
            targetNode.querySelector("span i").classList.toggle("fa-minus");
        }

        e.preventDefault();
    };

    const handleClick = (url, e) => {
        const thisParent = e.currentTarget.closest("ul").closest("li");
        const thisParentDataCode = thisParent.getAttribute("data-code");
        const parentList = e.currentTarget
            .closest("ul.navigation")
            .querySelectorAll(
                "li[data-code]:not([data-code='" + thisParentDataCode + "'])"
            );

        parentList.forEach((el) => {
            el.classList.remove("active");
            el.querySelector("span i").classList.remove("fa-minus");
            el.querySelector("span i").classList.add("fa-plus");
        });

        e.preventDefault();

        dispatch(clearLinkedRules());
        if (unsavedPopup.isUnSavedChange) {
            dispatch(
                setUnSavedChangesPopup({
                    ...unsavedPopup,
                    isVisible: true,
                    callbackFn: () => {
                        history.push(url);
                    },
                })
            );
        } else {
            history.push(url);
        }
    };

    const location = useLocation();
    const pathName = location.pathname.substring(1);

    return (
        <div className="container">
            <nav className="NavContainer">
                <div className="desktop-logo">
                    <a>
                        <img
                            src={require("../images/menu-icon.svg").default}
                            alt="ProCARE"
                        />{" "}
                    </a>
                    <a className="close" href="javascript.void(0);">
                        <i className="fas fa-times"></i>
                    </a>
                </div>
                <ul className="navigation">
                    {dbObjData.map(
                        ({
                            db_obj_name,
                            db_obj_key,
                            db_obj_code,
                            is_active,
                            children: subMenu,
                            ...rest
                        }) => (
                            <li
                                key={db_obj_key}
                                data-code={db_obj_code}
                                className={
                                    subMenu.find(
                                        (elem) => elem.db_obj_code === pathName
                                    )
                                        ? "active"
                                        : ""
                                }
                            >
                                <a
                                    className="cursor-pointer"
                                    onClick={(e) => {
                                        ToggleClass(e);
                                    }}
                                >
                                    <i
                                        className={
                                            "fas nav_icon_" + db_obj_code
                                        }
                                    ></i>{" "}
                                    <span>
                                        {db_obj_name}{" "}
                                        <i
                                            className={
                                                subMenu.find(
                                                    (elem) =>
                                                        elem.db_obj_code ===
                                                        pathName
                                                )
                                                    ? "fas fa-minus"
                                                    : "fas fa-plus"
                                            }
                                        ></i>
                                    </span>
                                </a>
                                {Array.isArray(subMenu) ? (
                                    <ul className="child-nav">
                                        {subMenu.map((childDbObj) => (
                                            <li
                                                key={childDbObj.db_obj_key}
                                                id={
                                                    "nav_" +
                                                    childDbObj.db_obj_code
                                                }
                                                className={
                                                    childDbObj.db_obj_code ===
                                                    pathName
                                                        ? "pointer-class active"
                                                        : "pointer-class"
                                                }
                                            >
                                                <a
                                                    className="cursor-pointer"
                                                    onClick={(e) => {
                                                        if (
                                                            db_obj_code !==
                                                            "reports"
                                                        ) {
                                                            dispatch(
                                                                clearReportsState()
                                                            );
                                                            dispatch(clearCompPlanReportsState());
                                                            dispatch(clearProviderPlanReportsState());
                                                        }
                                                        handleClick(
                                                            childDbObj.db_obj_code,
                                                            e
                                                        );
                                                    }}
                                                >
                                                    <i
                                                        className={
                                                            "fas nav_icon_" +
                                                            childDbObj.db_obj_code
                                                        }
                                                    ></i>{" "}
                                                    <span>
                                                        {childDbObj.db_obj_name}
                                                    </span>{" "}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                ) : null}
                            </li>
                        )
                    )}
                </ul>
            </nav>
        </div>
    );
};

export default React.memo(Sidebar);