import React from "react";
import { DELETE_BTN_ACTIONS } from "../../constants/constants";

import {
  TabStrip,
  TabStripTab,
  Menu,
  MenuItem,
} from "@progress/kendo-react-layout";
import { Window, WindowActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { DatePicker, ToggleButton } from "@progress/kendo-react-dateinputs";
import { Label, Error } from "@progress/kendo-react-labels";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { DialogComponent } from "src/components/dialog";


export const CustomToggleButton = (props) => {
  return (
    <Tooltip
      position="bottom"
      anchorElement="target"
      style={{
        paddingTop: 10,
      }}
    >
      <ToggleButton {...props} title="Calendar" className="calendariconprovider">
        <span className="k-icon k-font-icon k-i-calendar" title="Calendar" />
      </ToggleButton>
    </Tooltip>
  );
};

 const EffectiveVersions = (restProps) => {
  const effVersionDetails = restProps?.versionData
  const effTabData = effVersionDetails?.data;
  const { isShowRightMenu = true } = restProps;
  const [selectedIdx, setSelectedIdx] = React.useState(0);
  const [confirmationPopup, setConfirmationPopup] = React.useState({
    title: "Delete",
    desc: "Are you sure you want to delete this version?",
    isVisible: false,
    type: "",
    actionBtn: [],
    data: {},
    action: "Delete",
  });
  const [isAddNew, setAddNew] = React.useState(false)
  const [dateFields, setDateFields] = React.useState({
    start_date: ''
  })
  const [errorObj, setErrorObj] = React.useState({
    start_date: ''
  })

  React.useEffect(() => {
    if (effTabData && effTabData.length) {
      const selected = effTabData.findIndex((elem) => elem.isSelected === 1);
      setSelectedIdx(selected);
    }
  }, [restProps.versionData, effTabData]);

  const remove = () => {
    setConfirmationPopup({
      ...confirmationPopup,
      isVisible: true,
      type: "DELETE_CONFIRM",
      actionBtn: DELETE_BTN_ACTIONS(onCancelConfirm, onDeleteConfirm),
      action: "Delete",
    });
    onClosePopup()
  };

  const onCancelConfirm = () => {
    setConfirmationPopup({
      ...confirmationPopup,
      isVisible: false,
      type: "",
      actionBtn: [],
      data: {},
      action: "",
    });
    setDateFields({
      start_date: ''
    })
  };

  const onDeleteConfirm = () => {
    restProps.setEffVersionState({
      ...restProps.effVersionState,
      deleteVersion: { dateVersion: effTabData[selectedIdx].date_version },
    });
    onCancelConfirm();
  };

  const inertPeriod = (e) => {
    let module = e.item.data;

    if (module === undefined) {
      return false;
    }

    let indexMOdule = module.indexOf("add-new-version");
    // let periodMstrKey = 0;

    if (indexMOdule !== -1) {
      // let periodData = module.split("add-new-version");
      module = "add-new-version";
      // periodMstrKey = periodData[1];
    }
    switch (module) {
      case "delete-version":
        remove();
        break;
      default:
        return false;
      case "add-new-version":
        setAddNew(true)
        break;
    }
  };

  const saveAssignment = (reqPeriodMstrKey) => {
    restProps.setEffVersionState({
      ...restProps.effVersionState,
      insertVersion: {
        rowId: restProps.versionData.rowId,
        eff_start_date: dateFields.start_date,
      },
    });
    onClosePopup()
  };

  const handleSelect = (e) => {
    if (restProps.isUnSavedChangePopup) {
      restProps.setCommonUnsavedChanges({
        isUnSavedChangePopup: true,
        isVisible: true,
        callbackFunc: () => {
          restProps.setEffVersionState({
            ...restProps.effVersionState,
            changeVersion: {
              rowId: restProps.versionData.rowId,
              versionIdx: e.selected,
            },
          });
          setSelectedIdx(e.selected);
        },
      });
    } else {
      restProps.setEffVersionState({
        ...restProps.effVersionState,
        changeVersion: {
          rowId: restProps.versionData.rowId,
          versionIdx: e.selected,
        },
      });
      setSelectedIdx(e.selected);
    }
  };

  const effVersionMenu = () => {
    if (restProps.pageProps.writeAccess === 0) {
      return false;
    }

    return (
      <Menu
        vertical={true}
        dir={"rtl"}
        style={{
          display: "inline-block",
        }}
        onSelect={inertPeriod}
        openOnClick={true}
        hoverOpenDelay={400}
        hoverCloseDelay={400}
      >
        <MenuItem
          icon="more-vertical"
          id="effmenu"
          className="effectivemenuitem"
        >
          <MenuItem text="Add New Version" id="add" data="add-new-version" >

          </MenuItem>
          <MenuItem
            text="Delete Selected Version"
            data="delete-version"
          ></MenuItem>
        </MenuItem>
      </Menu>
    );
  };

  const effVersoinTab = () => {
    return (
      <TabStrip
        selected={selectedIdx}
        onSelect={handleSelect}
        className="tab-strip-width cusomtab"
        scrollable={true}
      >
        {effTabData.map((item, index) => {
          return (
            <TabStripTab
              title={item.periodName}
              key={index}
              id={"eff-tabs-" + index}
            ></TabStripTab>
          );
        })}
      </TabStrip>
    );
  };

  const effVersionLeft = () => {
    return (
      <>
        <div className="innerhead">
          <div className="Page-title">
            <h1>Effective Versions</h1>
          </div>
        </div>
        <div className="innertabarea">{effVersoinTab()}</div>
      </>
    );
  };

  const ModalTitle = () => {
    return <span>Add New Version</span>;
  };

  const onClosePopup = () => {
    setAddNew(false)
    setDateFields({
      start_date: ''
    })
    setErrorObj({
      start_date: ''
    })
  }

  const handleSubmit = () => {
    let isValid = handleValidation()
    if (isValid) {
      saveAssignment()
    }
  }

  const handleDateChange = (e) => {
    setDateFields({
      ...dateFields,
      [e.target.name]: e.target.value,
    });
    setErrorObj({
      ...errorObj,
      start_date: "",
    });
  };

  const handleValidation = () => {
    let isValid = true
    let obj = {}
    if (dateFields.start_date === '') {
      isValid = false
      obj.start_date = 'Please select date'
    }
    setErrorObj(obj)
    return isValid
  }

  return (
    <>
      {effTabData && effTabData.length > 0 ? (
        <div className="Page-title updateproviderposition">
          <div className="content-head effectiversionbg effectivepad15">
            <div className="content-head-left">{effVersionLeft()}</div>
            {isShowRightMenu ? (
              <div className="content-head-right">{effVersionMenu()}</div>
            ) : (
              false
            )}
          </div>
        </div>
      ) : (
        false
      )}

      {isAddNew &&
        <Window
          title={<ModalTitle />}
          onClose={onClosePopup}
          resizable={false}
          width={300}
          height={180}
          modal={true}
        >
          <div>
            <Label>{"Effective Date* : "}</Label>
            <DatePicker
              value={dateFields.start_date}
              name="start_date"
              format="yyyy-MM-dd"
              onChange={(e) => {
                handleDateChange(e);
              }}
              formatPlaceholder={{
                year: "yyyy",
                month: "mm",
                day: "dd",
              }}
              placeholder="yyyy-mm-dd"
              toggleButton={CustomToggleButton}
            />
            <Error className="start_date_align">{errorObj.start_date}</Error>
          </div>
          <WindowActionsBar layout={'end'}>
            <>
              <Button
                className="cancelbtn"
                onClick={(e) => onClosePopup()}
                autoFocus={true}
              >
                {'Cancel'}
              </Button>

              <Button
                className="primarybtn"
                onClick={(e) => handleSubmit()}
                autoFocus={true}
              >
                {'Add'}
              </Button>
            </>
          </WindowActionsBar>
        </Window>}
      <DialogComponent
        title={confirmationPopup.title}
        onCancelConfirm={onCancelConfirm}
        width={"450"}
        height={"auto"}
        disabled={false}
        className={"Dialog-Delete"}
        desc={confirmationPopup.desc}
        visible={confirmationPopup.isVisible}
        actionBtn={confirmationPopup.actionBtn}
        titleClassName={
          confirmationPopup.action === "Delete"
            ? "delete-confirm"
            : "unSave-confirm"
        }
      />
    </>
  );
};

export default React.memo(EffectiveVersions);