import { useQuery } from "react-query";
import {
  DashboardTabListApi,
  DashboardPodListApi,
  DashboardCompPlanConfigApi,
  DashboardChartRuleListApi,
  DashboardChartDataSetListApi,
} from "src/service/dashboard.service";
import {
  DashboardTabApi,
  DashboardPodApi,
  DashboardCompPlanApi,
  DashboardChartRuleApi,
  DashboardChartDataSetApi,
} from "src/service/service.config";

export const DashboardAccountSummary = (request) => {
  const enabled = (request?.tenantId && request?.userKey !== 0 && request.isPublishDashboardApiCalled) || false;
  return useQuery({
    queryKey: [DashboardTabApi.uniqueKey, JSON.stringify(request)],
    queryFn: () => {
      return DashboardTabListApi(request);
    },
    enabled: enabled,
    staleTime: 10 * (60 * 1000),
    cacheTime: 0,
    retry: 2,
    refetchOnMount: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
    refetchInterval: false
  });
};

export const DashboardPodList = (request) => {
  const enabled =
    (request?.tenantId &&
      request?.userKey &&
      request?.userKey !== 0 &&
      request?.dashboardTabMstrKey &&
      request?.dashboardTabMstrKey !== 0) ||
    false;
  return useQuery({
    queryKey: [DashboardPodApi.uniqueKey, JSON.stringify(request)],
    queryFn: () => {
      return DashboardPodListApi(request);
    },
    enabled: enabled,
    staleTime: 10 * (60 * 1000),
    cacheTime: 0,
    retry: 2,
    refetchOnMount: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
    refetchInterval: false
  });
};

export const DashboardCompPlanConfig = (request) => {
  const enabled =
    (request?.tenantId &&
      request?.userKey &&
      request?.userKey !== 0 &&
      request?.dashboardTabMstrKey &&
      request?.dashboardTabMstrKey !== 0) ||
    false;
  return useQuery({
    queryKey: [DashboardCompPlanApi.uniqueKey, JSON.stringify(request)],
    queryFn: () => {
      return DashboardCompPlanConfigApi(request);
    },
    enabled: enabled,
    staleTime: 10 * (60 * 1000),
    cacheTime: 15 * (60 * 1000),
    retry: 2,
    refetchOnMount: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
    refetchInterval: false
  });
};

export const DashboardChartRuleList = (request) => {
  const enabled =
    (request?.tenantId &&
      request?.userKey &&
      request?.userKey !== 0 &&
      request?.dashboardPodKey &&
      request?.dashboardPodKey !== 0 &&
      request?.dashboardTabMstrKey &&
      request?.dashboardTabMstrKey !== "") ||
    false;
  return useQuery({
    queryKey: [DashboardChartRuleApi.uniqueKey, JSON.stringify(request)],
    queryFn: () => {
      return DashboardChartRuleListApi(request);
    },
    enabled: enabled,
    staleTime: 10 * (60 * 1000),
    cacheTime: 0,
    retry: 2,
    refetchOnMount: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
    refetchInterval: false
  });
};

export const DashboardDataset = (request) => {
  const enabled =
    (request?.tenantId &&
      request?.dashboardPodKey &&
      request?.dashboardPodKey !== 0 &&
      request?.userKey &&
      request?.userKey !== 0) ||
    false;
  return useQuery({
    queryKey: [DashboardChartDataSetApi.uniqueKey, JSON.stringify(request)],
    queryFn: () => {
      return DashboardChartDataSetListApi(request);
    },
    enabled: enabled,
    staleTime: 10 * (60 * 1000),
    cacheTime: 0,
    retry: 2,
    refetchOnMount: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
    refetchInterval: false
  });
};
