import React from "react";
import MultiSelectDropdown from "../multiSelect";
import { Input, TextArea, Checkbox } from "@progress/kendo-react-inputs";
import { Label, Error, Hint } from "@progress/kendo-react-labels";
import { FieldWrapper } from "@progress/kendo-react-form";

export const FormMultiSelect = (fieldRenderProps) => {
    const {
        validationMessage,
        touched,
        label,
        name,
        valid,
        disabled,
        wrapperClass,
        formSchema,
        setFormSchema,
        onChange,
        onFieldChange,
        ...others
    } = fieldRenderProps;

    const editorRef = React.useRef(null);
    const showValidationMessage = touched && validationMessage;
    const errorId = showValidationMessage ? `${name}_error` : "";
    const labelId = label ? `${name}_label` : "";
    const fieldSchema = formSchema[name];

    const handleDropdownChange = (e) => {
        setFormSchema({
            ...formSchema,
            [e.target.props.name]: {
                ...fieldSchema,
                value: e.value,
                // data: fieldSchema.originData,
            },
        });
    };

    const handleDropdownFilterChange = (newData) => {
        setFormSchema({
            ...formSchema,
            [name]: {
                ...fieldSchema,
                data: newData,
            },
        });
    };

    const handleOpenDropdown = (newData) => {
        setFormSchema({
            ...formSchema,
            [name]: {
                ...fieldSchema,
                data: fieldSchema.originData,
            },
        });
    };

    return (
        <FieldWrapper className={wrapperClass}>
            <Label
                id={labelId}
                editorRef={editorRef}
                editorId={name}
                editorValid={valid}
                editorDisabled={disabled}
            >
                {label}
            </Label>
            <div className={"k-form-field-wrap valueDropdown"}>
                <MultiSelectDropdown
                    name={name}
                    data={fieldSchema.data}
                    dataList={fieldSchema.originData}
                    valueData={fieldSchema.value}
                    disabled={false}
                    onChange={(data, name, e) => {
                        onChange({
                            syntheticEvent: e.syntheticEvent,
                            value: e.value,
                        });

                        typeof onFieldChange === "function"
                            ? onFieldChange(e)
                            : handleDropdownChange(e);
                    }}
                    onOpen={(data) => {
                        handleOpenDropdown(data);
                    }}
                    onFilterChange={(data) => {
                        handleDropdownFilterChange(data);
                    }}
                    {...others}
                />
                {showValidationMessage && (
                    <Error id={errorId}>{validationMessage}</Error>
                )}
            </div>
        </FieldWrapper>
    );
};

export const FormCheckbox = (fieldRenderProps) => {
    const {
        validationMessage,
        touched,
        id,
        valid,
        disabled,
        wrapperClass,
        hint,
        optional,
        label,
        visited,
        modified,
        onChange,
        onFieldChange,
        ...others
    } = fieldRenderProps;
    const editorRef = React.useRef(null);
    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : "";
    const errorId = showValidationMessage ? `${id}_error` : "";
    const labelId = label ? `${id}_label` : "";

    return (
        <FieldWrapper className={wrapperClass}>
            <div>
                <Checkbox
                    ariaDescribedBy={`${hintId} ${errorId}`}
                    labelOptional={optional}
                    valid={valid}
                    id={id}
                    disabled={disabled}
                    onChange={(e) => {
                        onChange({
                            syntheticEvent: e.syntheticEvent,
                            value: e.target.value,
                        });
                        typeof onFieldChange === "function" && onFieldChange(e);
                    }}
                    {...others}
                />
                {showHint && <Hint id={hintId}>{hint}</Hint>}
                {showValidationMessage && (
                    <Error id={errorId}>{validationMessage}</Error>
                )}
            </div>
            &nbsp;
            <Label
                id={labelId}
                editorRef={editorRef}
                editorId={id}
                editorValid={valid}
                editorDisabled={disabled}
            >
                {label}
            </Label>
        </FieldWrapper>
    );
};

export const FormInput = (fieldRenderProps) => {
    const {
        validationMessage,
        touched,
        label,
        id,
        valid,
        disabled,
        wrapperClass,
        hint,
        type,
        optional,
        ...others
    } = fieldRenderProps;

    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : "";
    const errorId = showValidationMessage ? `${id}_error` : "";
    return (
        <FieldWrapper className={wrapperClass}>
            <Label
                editorId={id}
                editorValid={valid}
                editorDisabled={disabled}
                optional={optional}
            >
                {label}
            </Label>
            <div className={"k-form-field-wrap"}>
                <Input
                    valid={valid}
                    type={type}
                    id={id}
                    disabled={disabled}
                    ariaDescribedBy={`${hintId} ${errorId}`}
                    {...others}
                />
                {showHint && <Hint id={hintId}>{hint}</Hint>}
                {showValidationMessage && (
                    <Error id={errorId}>{validationMessage}</Error>
                )}
            </div>
        </FieldWrapper>
    );
};

export const FormInput2 = (fieldRenderProps) => {
    const {
        validationMessage,
        touched,
        label,
        id,
        valid,
        disabled,
        wrapperClass,
        hint,
        type,
        optional,
        ...others
    } = fieldRenderProps;

    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : "";
    const errorId = showValidationMessage ? `${id}_error` : "";
    return (
        <FieldWrapper className={wrapperClass}>
            <Label
                editorId={id}
                editorValid={valid}
                editorDisabled={disabled}
                optional={optional}
            >
                {label}
            </Label>
            <div className={"k-form-field-wrap"}>
                <Input
                    valid={valid}
                    type={type}
                    id={id}
                    disabled={disabled}
                    ariaDescribedBy={`${hintId} ${errorId}`}
                    {...others}
                />
                {showHint && <Hint id={hintId}>{hint}</Hint>}
                {showValidationMessage && (
                    <Error id={errorId}>{validationMessage}</Error>
                )}
            </div>
        </FieldWrapper>
    );
};

export const FormTextArea = (fieldRenderProps) => {
    const {
        validationMessage,
        touched,
        label,
        id,
        valid,
        hint,
        disabled,
        wrapperClass,
        optional,
        ...others
    } = fieldRenderProps;
    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : "";
    const errorId = showValidationMessage ? `${id}_error` : "";
    return (
        <FieldWrapper className={wrapperClass}>
            <Label editorId={id} editorValid={valid} optional={optional}>
                {label}
            </Label>
            <div className={"k-form-field-wrap"}>
                <TextArea
                    valid={valid}
                    id={id}
                    disabled={disabled}
                    ariaDescribedBy={`${hintId} ${errorId}`}
                    {...others}
                />
                {showHint && <Hint id={hintId}>{hint}</Hint>}
                {showValidationMessage && (
                    <Error id={errorId}>{validationMessage}</Error>
                )}
            </div>
        </FieldWrapper>
    );
};
