/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { FieldWrapper, Form, FormElement } from "@progress/kendo-react-form";
import { Input, Checkbox } from "@progress/kendo-react-inputs";
import { Error, Label } from "@progress/kendo-react-labels";
import { Splitter, TabStrip, TabStripTab } from "@progress/kendo-react-layout";

import { HeaderPage } from "../base/headerPage";
import CustomDropdown from "../../components/customDropdown";
import Popup from "../../components/customPopup";
import { getUserCredential } from "../../lib/cookies";
import DtuBatchLogs from "../common/dtuBatchLogs";
import { changeCancelBtnState, changeUpdateBtnState } from "../../utils/utils";
import { GridButton, KpiData } from "./kpiGrid";
import useServiceApi from "./service";
import CompAllocationDataRows from "./compAllocation/dataRows";
import MensurmentsDataRows from "./measurments/dataRows";
import IncentivesDataRows from "./incentives/dataRows";
import PaymentsDataRows from "./payments/dataRows";
import { ManageKpiType } from "./kpiType.jsx";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { DEFAULT_SORT } from "src/constants/grid";
import { GetRuleTypeAPI } from "src/lib/common-service";
import {
  setLinkedRules,
  // setUnSavedChangesPopup,
} from "src/redux/actions/setting-actions";
import {
  linkedRulesSelector,
  // unSaveChangesPopupSelector,
} from "src/redux/selectors/custom-selector";
import { Loader } from "@progress/kendo-react-indicators";

const initialFormFields = {
  kpi_name: "",
  description: "",
  kpi_type_mstr_key: "",
  report_order: "",
  period_type_mstr_key: "",
  is_model: "",
};

const Kpi = (pageProps) => {
  const [list, Action] = useServiceApi();
  const cookiesData = getUserCredential();
  const addRef = React.useRef(null);

  const [isAddNewConfig, setNewConfig] = React.useState(false);
  const [isShow, setShow] = React.useState({
    deleteRows: false,
    isKpiType: false,
  });
  const [isVersionShow, setVersionShow] = React.useState({
    dtuLogs: false,
    dtuUpload: false,
  });
  const [exportDataClick, setExportDataClick] = React.useState(false);
  const [isAPICall, setAPICall] = React.useState(false);
  const [formFields, setFormFields] = React.useState({
    ...initialFormFields,
  });
  const [errorObj, setErrorObj] = React.useState({});
  const [isSaveClick, setSaveClick] = React.useState(false);
  const [selectedRowId, setSelectedRowId] = React.useState("");
  const [dataList, setDataList] = React.useState([]);
  const [dataItem, setDataItem] = React.useState({});
  const [ruleTypeData, setRuleTypeData] = useState([]);
  const [isCollapsed, setIsCollapsed] = React.useState(false);

  const [panes, setPanes] = React.useState([
    {
      size: "35%",
      collapsible: true,
      scrollable: false,
    },
    {
      scrollable: false,
      min: "300px",
    },
  ]);
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [isUpdateClick, setisUpdateClick] = React.useState(false);
  const [isCancelClick, setisCancelClick] = React.useState(false);
  const [isMeasurmentsUpdateClick, setIsMeasurmentsUpdateClick] =
    React.useState(false);
  const [isMeasurmentsCancelClick, setisMeasurmentsCancelClick] =
    React.useState(false);
  const [isIncentivesUpdateClick, setIsIncentivesUpdateClick] =
    React.useState(false);
  const [isIncentivesCancelClick, setisIncentivesCancelClick] =
    React.useState(false);
  const [isPaymentsUpdateClick, setIsPaymentsUpdateClick] =
    React.useState(false);
  const [isPaymentsCancelClick, setisPaymentsCancelClick] =
    React.useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const [isKpiEdit, setisKpiEdit] = React.useState(false);
  const [isCreditAllocationEdit, setisCreditAllocationEdit] =
    React.useState(false);
  const [isCreditMensurmentsEdit, setisMensurmentsEdit] = React.useState(false);
  const [isIncentivesEdit, setisIncentivesEdit] = React.useState(false);
  const [isPaymentsEdit, setisPaymentsEdit] = React.useState(false);
  const [screenLock, setScreenLock] = React.useState(false);
  const [columnsData, setColumnsData] = React.useState([]);
  const [isCheckColumn, setColumnCheck] = React.useState(false);
  const { linkedRules } = useSelector(linkedRulesSelector, shallowEqual);
  // const { unsavedPopup } = useSelector(
  //   unSaveChangesPopupSelector,
  //   shallowEqual
  // );

  const [CreditrulesColumnsData, setCreditRulesColumnsData] = React.useState(
    []
  );
  const [isCreditRuleCheckColumn, setCreditRuleColumnCheck] =
    React.useState(false);
  const [isMensurmentsCheckColumn, setMensurmentsColumnCheck] =
    React.useState(false);
  const [mensurmentsColumnsData, setMensurmentsColumnsData] = React.useState(
    []
  );
  const [isIncentivesCheckColumn, setIncentivesColumnCheck] =
    React.useState(false);
  const [incentivesColumnsData, setIncentivesColumnsData] = React.useState([]);
  const [isPaymentsCheckColumn, setPaymentsColumnCheck] = React.useState(false);
  const [paymentsColumnsData, setPaymentsColumnsData] = React.useState([]);

  const [cancelKpiElementAwaitStatus, setCancelKpiElementAwaitStatus] =
    React.useState(undefined);
  const [
    cancelKpiMensurmentsElementAwaitStatus,
    setCancelKpiMensurmentsElementAwaitStatus,
  ] = React.useState(undefined);
  const [cancelIncentivesAwaitStatus, setCancelIncentivesAwaitStatus] =
    React.useState(undefined);
  const [cancelKpiPaymentsAwaitStatus, setCancelKpiPaymentsAwaitStatus] =
    React.useState(undefined);
  const [isRefreshBtnClick, setRefreshBtnClick] = React.useState(false);
  const [iskpiGridRefresh, setKpiGridRefresh] = React.useState(false);

  const {
    loadingState,
    originPeriodType,
    periodsTypeFields,
    setPeriodTypeFields,
    originKPIType,
    KPITypeFields,
    setKPIdTypeFields,
    // isAPICalling,
  } = list;

  React.useEffect(() => {
    window.$_UNSAVED_CHANGES_FLAG = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (iskpiGridRefresh) {
      setKpiGridRefresh(false);
      changeCancelBtnState();
      changeUpdateBtnState();
      getSelectedData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iskpiGridRefresh]);

  React.useEffect(() => {
    if (
      !isKpiEdit &&
      !isCreditAllocationEdit &&
      !isCreditMensurmentsEdit &&
      !isIncentivesEdit &&
      !isPaymentsEdit
    ) {
      changeCancelBtnState();

      changeUpdateBtnState();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isKpiEdit,
    isCreditAllocationEdit,
    isCreditMensurmentsEdit,
    isIncentivesEdit,
    isPaymentsEdit,
    dataItem,
  ]);

  React.useEffect(() => {
    if (loadingState.isSaveData) {
      setFormFields({ ...initialFormFields });
      setAPICall(true);
      setNewConfig(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingState.isSaveData]);

  const getSelectedData = () => {
    const selectedData = dataList.filter(
      (data) => data.kpi_mstr_key == selectedRowId
    );
    let newSelectedData = {};
    if (selectedData[0]) {
      newSelectedData["mstr_key"] = selectedData[0].kpi_mstr_key;
      newSelectedData["mstr_name"] = selectedData[0].kpi_name;
    }
    if (selectedData.length > 0) {
      setDataItem(newSelectedData);
    } else {
      setDataItem({});
    }
  };

  React.useEffect(() => {
    getSelectedData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowId]);

  const fetchRuleTypes = async () => {
    let procsParam = {
      in_filter: null,
      in_sort_number: DEFAULT_SORT.number,
      in_sort_order: "ASC",
    };

    let resRuleType = await GetRuleTypeAPI(procsParam, dispatch, history);
    setRuleTypeData(resRuleType);
  };

  useEffect(() => {
    fetchRuleTypes();
    if (linkedRules && linkedRules["kpi"]) {
      const linkedData = linkedRules["kpi"];
      const tableName = linkedData?.rule_config_table_name ?? "";
      switch (tableName) {
        case "credit_rule_config":
          setSelectedTab(1);
          break;
        case "measurements_rule_config":
          setSelectedTab(2);
          break;
        case "incentive_rule_config":
          setSelectedTab(3);
          break;
        case "payment_rule_config":
          setSelectedTab(4);
          break;
        default:
          setSelectedTab(0);
          break;
      }
    } else {
      setSelectedTab(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkedRules]);

  const onPaneChange = (event) => {
    const eventTarget = event.nativeEvent.target;

    if (eventTarget === undefined) {
      setPanes(event.newState);
      return;
    }

    if (eventTarget.classList.contains("k-collapse-prev")) {
      if (isCollapsed === false) {
        setIsCollapsed(true);
        event.newState[0].collapsed = false;
        event.newState[0].size = 0;
        eventTarget.classList.remove("k-i-arrow-60-left");
        eventTarget.classList.add("k-i-arrow-60-right");
      } else {
        setIsCollapsed(false);
        event.newState[0].collapsed = false;
        event.newState[0].size = "35%";
        eventTarget.classList.remove("k-i-arrow-60-right");
        eventTarget.classList.add("k-i-arrow-60-left");
      }
      setPanes(event.newState);
    }
  };

  const handleSelect = (e) => {
    setSelectedTab(e.selected);
    setNewConfig(false);
  };

  const addNewConfig = async (e) => {
    setFormFields(initialFormFields);

    setNewConfig(!isAddNewConfig);
    list.setLoadState({ ...list.loadingState, isSaveData: false });

    await Action.getPeriodTypesData();
    await Action.getKpiTypesData();
    // e.preventDefault();
  };

  const ShowError = React.useCallback(
    (props) => {
      return <Error>{errorObj[props.name]}</Error>;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errorObj, isSaveClick]
  );

  const handleValidation = () => {
    let isValid = true;

    if (formFields.kpi_name.trim() === "") {
      isValid = false;
      errorObj.kpi_name = "Please enter kpi name";
    }

    setErrorObj((preVState) => ({ ...preVState, errorObj }));

    return isValid;
  };

  const handleSubmit = async () => {
    let valid = handleValidation();

    if (valid) {
      setErrorObj({});
      const bodyData = {
        in_user_id: cookiesData.out_user_id,
        in_tenant_id: cookiesData.out_tenant_id,
        in_kpi_name: formFields.kpi_name,
        in_kpi_desc: formFields.description,
        in_kpi_type_mstr_key: KPITypeFields.KPITypeValue.id,
        in_is_model: formFields.is_model === true ? 1 : 0,
        in_period_type_mstr_key: periodsTypeFields.periodTypeValue.id,
        in_report_order: formFields.report_order,
      };

      let res = await Action.addDataAsync(bodyData);
      if (res === "success") {
        setSelectedTab(0);
      }
    } else {
      setSaveClick(true);
    }
  };

  const onCancelClick = () => {
    setFormFields(initialFormFields);
    setErrorObj({});
    setNewConfig(false);
    setSaveClick(false);
  };

  const handleFieldChange = (e) => {
    const name = e.name ? e.name : e.target.name;
    if (e.value !== null) {
      if (name === "is_model") {
        setFormFields({
          ...formFields,
          [name]: e.target.value,
        });
        setErrorObj({ ...errorObj, [name]: "" });
      } else {
        setFormFields({ ...formFields, [name]: e.value });
        setErrorObj({ ...errorObj, [name]: "" });
      }
    }
  };

  const AddNewForm = () => {
    return (
      <Form
        onSubmit={handleSubmit}
        render={() => (
          <FormElement className="emailForm" horizontal={true}>
            <FieldWrapper>
              <div className={"k-form-field-wrap-popup"}>
                <Label>{"KPI Name*:"}</Label>
                <Input
                  type={"text"}
                  name="kpi_name"
                  placeholder="KPI Name"
                  value={formFields.kpi_name}
                  onChange={(e) => {
                    handleFieldChange(e);
                  }}
                  autoFocus={true}
                />
                <ShowError name={"kpi_name"}></ShowError>
              </div>
            </FieldWrapper>

            <FieldWrapper>
              <div className={"k-form-field-wrap-popup"}>
                <Label>{"Description:"}</Label>
                <Input
                  type={"text"}
                  name="description"
                  placeholder="Description"
                  value={formFields.description}
                  onChange={(e) => {
                    handleFieldChange(e);
                  }}
                />
                <ShowError name={"description"}></ShowError>
              </div>
            </FieldWrapper>
            <FieldWrapper>
              <div className={"k-form-field-wrap-popup"}>
                <Label>{"Period Type* : "}</Label>
                <CustomDropdown
                  name={"periodTypeValue"}
                  data={periodsTypeFields.periodTypeData}
                  textField="text"
                  dataItemKey="id"
                  valueData={originPeriodType.find(
                    (c) => c.text === periodsTypeFields.periodTypeValue.text
                  )}
                  onChange={(e, name) => {
                    setPeriodTypeFields({
                      ...periodsTypeFields,
                      [name]: e,
                    });
                  }}
                  onOpen={(dataV) => {
                    setPeriodTypeFields({
                      ...periodsTypeFields,
                      periodTypeData: originPeriodType,
                    });
                  }}
                  onFilterChange={(dataValue) => {
                    setPeriodTypeFields({
                      ...periodsTypeFields,
                      periodTypeData: dataValue,
                    });
                  }}
                  filterable={true}
                  autoClose={false}
                  autoBind={true}
                  dataList={originPeriodType}
                  loading={periodsTypeFields.isPeriodTypeDataLoading}
                />
              </div>
            </FieldWrapper>
            <FieldWrapper>
              <div className={"k-form-field-wrap-popup"}>
                <Label>{"KPI Type* : "}</Label>
                <CustomDropdown
                  name={"KPITypeValue"}
                  data={KPITypeFields.KPITypeData}
                  textField="text"
                  dataItemKey="id"
                  valueData={originKPIType.find(
                    (c) => c.text === KPITypeFields.KPITypeValue.text
                  )}
                  onOpen={(dataV) => {
                    setKPIdTypeFields({
                      ...KPITypeFields,
                      KPITypeData: originKPIType,
                    });
                  }}
                  onChange={(e, name) => {
                    setKPIdTypeFields({
                      ...KPITypeFields,
                      [name]: e,
                    });
                  }}
                  onFilterChange={(dataValue) => {
                    setKPIdTypeFields({
                      ...KPITypeFields,
                      KPITypeData: dataValue,
                    });
                  }}
                  filterable={true}
                  autoClose={false}
                  autoBind={true}
                  dataList={originKPIType}
                  loading={KPITypeFields.isKPITypeDataLoading}
                />
              </div>
            </FieldWrapper>
            <FieldWrapper>
              <div className={"k-form-field-wrap-popup"}>
                <Label>{"Report Order:"}</Label>
                <Input
                  type={"number"}
                  name="report_order"
                  placeholder="Report Order                                    "
                  value={formFields.report_order}
                  onChange={(e) => {
                    handleFieldChange(e);
                  }}
                />
                <ShowError name={"report_order"}></ShowError>
              </div>
            </FieldWrapper>
            <FieldWrapper>
              <div className={"k-form-field-wrap-popup"}>
                <Label>{"Model Flag:"}</Label>
                <Checkbox
                  value={formFields.is_model}
                  name={"is_model"}
                  onChange={(e) => {
                    handleFieldChange(e);
                  }}
                />
              </div>
            </FieldWrapper>
          </FormElement>
        )}
      />
    );
  };

  const AddNewPopup = () => {
    return (
      <Popup anchor={addRef.current} show={isAddNewConfig} className="right0">
        <div className="dot-dropdown-open dot-dropdown-open-Form">
          <div className="caption">
            <span>Add New KPI</span>
          </div>

          {AddNewForm()}

          <div className="dropdownFooter martop9">
            <div className="k-form-buttons">
              <Button
                primary={true}
                type="submit"
                onClick={() => {
                  onCancelClick();
                }}
              >
                Cancel
              </Button>
              <Button
                primary={true}
                type="submit"
                onClick={() => {
                  handleSubmit(formFields, errorObj);
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </Popup>
    );
  };

  const AddNewLink = () => {
    return (
      <div>
        <a
          className="cta-small"
          ref={addRef}
          onClick={(e) => {
            addNewConfig(e);
          }}
        >
          <i className="fas fa-plus"></i> Add New KPI
        </a>

        {AddNewPopup()}
      </div>
    );
  };

  const handleMenuItemClick = (action) => {
    switch (action) {
      case "export":
        setExportDataClick(true);
        break;
      case "delete":
        setShow({
          ...isShow,
          deleteRows: !isShow.deleteRows,
        });
        break;
      case "manageType":
        setShow({
          ...isShow,
          isKpiType: !isShow.isKpiType,
        });
        break;
      default:
        break;
    }
  };
  const headerConfig = {
    title: "KPIs",
    id: "kpi",
    add_new_fx: AddNewLink,
    action_menu: {
      export: { fx: handleMenuItemClick },
      delete: { fx: handleMenuItemClick },
      ...(pageProps.kpiTypeReadAccess === 1 ? {
        manageType: {
          fx: handleMenuItemClick,
          items: [{ caption: "Manage KPI Types", module: "manageType" }],
        }
      } : {}),
    },
    manage_col: {
      columnsData: columnsData,
      setColumnsData: setColumnsData,
      setColumnCheck: setColumnCheck,
    },
  };
  const headerAssignmentConfig = {
    title: dataItem.mstr_name
      ? "Rule Assignments | " + dataItem.mstr_name
      : "Rule Assignments",
    id: "kpi-assignment",
    buttons: { template_fx: GridButton },
    manage_col: {
      columnsData:
        selectedTab === 0
          ? CreditrulesColumnsData
          : selectedTab === 1
            ? mensurmentsColumnsData
            : selectedTab === 2
              ? incentivesColumnsData
              : paymentsColumnsData,
      setColumnsData:
        selectedTab === 0
          ? setCreditRulesColumnsData
          : selectedTab === 1
            ? setMensurmentsColumnsData
            : selectedTab === 2
              ? setIncentivesColumnsData
              : setPaymentsColumnsData,

      setColumnCheck:
        selectedTab === 0
          ? setCreditRuleColumnCheck
          : selectedTab === 1
            ? setMensurmentsColumnCheck
            : selectedTab === 2
              ? setIncentivesColumnCheck
              : setPaymentsColumnCheck,
    },
  };
  const parent = document.querySelector("#content-" + headerConfig.id);

  if (parent) {
    const exportToExcel = parent.querySelector(".export-to-excel");
    const auditLog = parent.querySelector(".audit-log");
    const deleteRows = parent.querySelector(".delete-rows");

    exportToExcel && exportToExcel.disableKendoMenu();
    auditLog && auditLog.disableKendoMenu();
    deleteRows && deleteRows.disableKendoMenu();

    dataList.length && exportToExcel && exportToExcel.enableKendoMenu();

    if (selectedRowId !== "" && dataList.length > 0) {
      auditLog && auditLog.enableKendoMenu();
      deleteRows && deleteRows.enableKendoMenu();
    }
  }
  const assign = document.querySelector(
    "#content-" + headerAssignmentConfig.id
  );

  if (assign) {
    const dtuUpload = assign.querySelector(".upload");
    const actionMenu = assign.querySelector(".hoverMenu");

    if (dtuUpload) {
      dtuUpload.style.display = selectedTab === 0 ? "none" : "block";
    }

    if (actionMenu) {
      actionMenu.style.display = selectedTab === 0 ? "none" : "block";
    }
  }

  const leftPane = () => {
    return (
      <>
        <div className="content-head">
          <HeaderPage pageProps={pageProps} headerConfig={headerConfig} />
        </div>

        <div className="content-body">
          <div className="dtu-file-container kpigridcont">
            <KpiData
              isAPICall={isAPICall}
              setAPICall={setAPICall}
              setExportDataClick={setExportDataClick}
              exportDataClick={exportDataClick}
              selectedRowId={selectedRowId}
              setSelectedRowId={setSelectedRowId}
              setShow={setShow}
              isShow={isShow}
              setDataList={setDataList}
              setisUpdateClick={setisUpdateClick}
              isCancelClick={isCancelClick}
              setisCancelClick={setisCancelClick}
              setIsMeasurmentsUpdateClick={setIsMeasurmentsUpdateClick}
              setisMeasurmentsCancelClick={setisMeasurmentsCancelClick}
              setIsIncentivesUpdateClick={setIsIncentivesUpdateClick}
              setisIncentivesCancelClick={setisIncentivesCancelClick}
              setIsPaymentsUpdateClick={setIsPaymentsUpdateClick}
              setisPaymentsCancelClick={setisPaymentsCancelClick}
              isKpiEdit={isKpiEdit}
              setisKpiEdit={setisKpiEdit}
              isCreditAllocationEdit={isCreditAllocationEdit}
              setisCreditAllocationEdit={setisCreditAllocationEdit}
              isCreditMensurmentsEdit={isCreditMensurmentsEdit}
              setisMensurmentsEdit={setisMensurmentsEdit}
              isIncentivesEdit={isIncentivesEdit}
              setisIncentivesEdit={setisIncentivesEdit}
              isPaymentsEdit={isPaymentsEdit}
              setisPaymentsEdit={setisPaymentsEdit}
              pageProps={pageProps}
              columnsData={columnsData}
              setColumnsData={setColumnsData}
              setColumnCheck={setColumnCheck}
              isCheckColumn={isCheckColumn}
              setSelectedTab={setSelectedTab}
              setCancelKpiElementAwaitStatus={setCancelKpiElementAwaitStatus}
              setCancelKpiMensurmentsElementAwaitStatus={
                setCancelKpiMensurmentsElementAwaitStatus
              }
              setCancelIncentivesAwaitStatus={setCancelIncentivesAwaitStatus}
              setCancelKpiPaymentsAwaitStatus={setCancelKpiPaymentsAwaitStatus}
              setRefreshBtnClick={setRefreshBtnClick}
              isRefreshBtnClick={isRefreshBtnClick}
              setKpiGridRefresh={setKpiGridRefresh}
            />
          </div>
        </div>
      </>
    );
  };

  const rightPane = () => {
    if (columnsData.length==0 && !dataItem?.mstr_key) {
      return <Loader size="large" className="k-loading-procare" />;
    }

    if( !dataItem.mstr_key && columnsData.length>0){
      return <div className="assign-nodata">
      <div className="overlaycont">
        <div className="procarelogomob"></div>
        <div className="mobnotificationlogo">
          <h3 style={{color: 'black'}}>
            {" "}
            There is no record on the left side of the screen. Please
            clear the filter or refresh the screen.
          </h3>
        </div>
      </div>
    </div>
    }

    const Title = (props) => {
      return (
        <div
          onClick={() => {
            // TODO : UNSAVED CHANGES
            // if (!unsavedPopup.isUnSavedChange) {
            //   setSelectedTab(props.tabPos);
            // }
            setSelectedTab(props.tabPos);
          }}
        >
          {props.content}
          <span className="k-spacer" />
          <span
            className="tab-icon k-icon k-font-icon k-i-link linkiconbtn pointer-class"
            title="View Assigned Rules"
            onClick={() => {
              if(pageProps.writeAccess === 0){
                return false;
              }else{
              // TODO : UNSAVED CHANGES
              // if (unsavedPopup.isUnSavedChange) {
              //   dispatch(
              //     setUnSavedChangesPopup({
              //       ...unsavedPopup,
              //       isVisible: true,
              //       callbackFn: () => {
              //         dispatch(
              //           setLinkedRules({
              //             key: "Compensation_Rules",
              //             data: {
              //               kpi_mstr_key: dataItem.mstr_key,
              //               rule_type_mstr_key: props.rule?.map(
              //                 (it) => it.rule_type_mstr_key
              //               ),
              //               title: `Assigned with KPI Name = ${dataItem.mstr_name}`,
              //               type: "kpi_rule",
              //             },
              //           })
              //         );
              //         history.push("/rules_library");
              //       },
              //     })
              //   );
              //   return;
              // }

              dispatch(
                setLinkedRules({
                  key: "Compensation_Rules",
                  data: {
                    kpi_mstr_key: dataItem.mstr_key,
                    rule_type_mstr_key: props.rule?.map(
                      (it) => it.rule_type_mstr_key
                    ),
                    title: `Assigned with KPI Name = ${dataItem.mstr_name}`,
                    type: "kpi_rule",
                  },
                })
              );
              // TODO : UNSAVED CHANGES
              // dispatch(setUnSavedChangesPopup({ isUnSavedChange: false }));
              history.push("/rules_library");
            }
            }}
          ></span>
        </div>
      );
    };

    return (
      <>
        <div className="content-head">
          <HeaderPage
            pageProps={pageProps}
            headerConfig={headerAssignmentConfig}
          />
        </div>
        <TabStrip
          selected={selectedTab}
          onSelect={handleSelect}
          className="tab-strip-width kpitabsinnertabs kpitabs"
          keepTabsMounted={true}
        >
          <TabStripTab
            title={
              <Title
                content="Credits"
                rule={ruleTypeData.filter(
                  (it) => it.rule_config_table_name === "credit_rule_config"
                )}
                tabPos={0}
              />
            }
            keepTabsMounted={true}
            key={"credit_data"}
          >
            <CompAllocationDataRows
              pageProps={pageProps}
              compTableMasterKey={dataItem.mstr_key}
              selected={selectedTab}
              isUpdateClick={isUpdateClick}
              isCancelClick={isCancelClick}
              setScreenLock={setScreenLock}
              screenLock={screenLock}
              view={"vw_credit_rule_config"}
              isCreditAllocationEdit={isCreditAllocationEdit}
              setisCreditAllocationEdit={setisCreditAllocationEdit}
              CreditrulesColumnsData={CreditrulesColumnsData}
              setCreditRulesColumnsData={setCreditRulesColumnsData}
              isCreditRuleCheckColumn={isCreditRuleCheckColumn}
              setCreditRuleColumnCheck={setCreditRuleColumnCheck}
              cancelKpiElementAwaitStatus={cancelKpiElementAwaitStatus}
              setCancelKpiElementAwaitStatus={setCancelKpiElementAwaitStatus}
              setCancelKpiMensurmentsElementAwaitStatus={
                setCancelKpiMensurmentsElementAwaitStatus
              }
              setCancelIncentivesAwaitStatus={setCancelIncentivesAwaitStatus}
              setCancelKpiPaymentsAwaitStatus={setCancelKpiPaymentsAwaitStatus}
              setisUpdateClick={setisUpdateClick}
              isRefreshBtnClick={isRefreshBtnClick}
              setRefreshBtnClick={setRefreshBtnClick}
              selectedRowId={selectedRowId}
            />
          </TabStripTab>

          <TabStripTab
            title={
              <Title
                content="Measurements"
                rule={ruleTypeData.filter(
                  (it) =>
                    it.rule_config_table_name === "measurements_rule_config"
                )}
                tabPos={1}
              />
            }
            key={"measurements"}
            keepTabsMounted={true}
          >
            <MensurmentsDataRows
              pageProps={pageProps}
              compTableMasterKey={dataItem.mstr_key}
              selected={selectedTab}
              isMeasurmentsUpdateClick={isMeasurmentsUpdateClick}
              isMeasurmentsCancelClick={isMeasurmentsCancelClick}
              setScreenLock={setScreenLock}
              screenLock={screenLock}
              view={"vw_measurements_rule_config"}
              mensurmentsColumnsData={mensurmentsColumnsData}
              setMensurmentsColumnsData={setMensurmentsColumnsData}
              isMensurmentsCheckColumn={isMensurmentsCheckColumn}
              setMensurmentsColumnCheck={setMensurmentsColumnCheck}
              cancelKpiMensurmentsElementAwaitStatus={
                cancelKpiMensurmentsElementAwaitStatus
              }
              setCancelKpiMensurmentsElementAwaitStatus={
                setCancelKpiMensurmentsElementAwaitStatus
              }
              isCreditMensurmentsEdit={isCreditMensurmentsEdit}
              setisMensurmentsEdit={setisMensurmentsEdit}
              setIsMeasurmentsUpdateClick={setIsMeasurmentsUpdateClick}
              setisMeasurmentsCancelClick={setisMeasurmentsCancelClick}
              isRefreshBtnClick={isRefreshBtnClick}
              setRefreshBtnClick={setRefreshBtnClick}
              selectedRowId={selectedRowId}
            />
          </TabStripTab>

          <TabStripTab
            title={
              <Title
                content="Incentives"
                rule={ruleTypeData.filter(
                  (it) => it.rule_config_table_name === "incentive_rule_config"
                )}
                tabPos={2}
              />
            }
            key={"incentives"}
            keepTabsMounted={true}
          >
            <IncentivesDataRows
              pageProps={pageProps}
              compTableMasterKey={dataItem.mstr_key}
              selected={selectedTab}
              isIncentivesUpdateClick={isIncentivesUpdateClick}
              isIncentivesCancelClick={isIncentivesCancelClick}
              setScreenLock={setScreenLock}
              screenLock={screenLock}
              view={"vw_incentive_rule_config"}
              incentivesColumnsData={incentivesColumnsData}
              setIncentivesColumnsData={setIncentivesColumnsData}
              isIncentivesCheckColumn={isIncentivesCheckColumn}
              setIncentivesColumnCheck={setIncentivesColumnCheck}
              cancelIncentivesAwaitStatus={cancelIncentivesAwaitStatus}
              setCancelIncentivesAwaitStatus={setCancelIncentivesAwaitStatus}
              isIncentivesEdit={isIncentivesEdit}
              setisIncentivesEdit={setisIncentivesEdit}
              setIsIncentivesUpdateClick={setIsIncentivesUpdateClick}
              setisIncentivesCancelClick={setisIncentivesCancelClick}
              isRefreshBtnClick={isRefreshBtnClick}
              setRefreshBtnClick={setRefreshBtnClick}
              selectedRowId={selectedRowId}
            />
          </TabStripTab>
          <TabStripTab
            title={
              <Title
                content="Payments"
                rule={ruleTypeData.filter(
                  (it) => it.rule_config_table_name === "payment_rule_config"
                )}
                tabPos={3}
              />
            }
            key={"payments"}
            keepTabsMounted={true}
          >
            <PaymentsDataRows
              pageProps={pageProps}
              compTableMasterKey={dataItem.mstr_key}
              selected={selectedTab}
              isPaymentsUpdateClick={isPaymentsUpdateClick}
              isPaymentsCancelClick={isPaymentsCancelClick}
              setScreenLock={setScreenLock}
              screenLock={screenLock}
              view={"vw_payment_rule_config"}
              paymentsColumnsData={paymentsColumnsData}
              setPaymentsColumnsData={setPaymentsColumnsData}
              isPaymentsCheckColumn={isPaymentsCheckColumn}
              setPaymentsColumnCheck={setPaymentsColumnCheck}
              cancelKpiPaymentsAwaitStatus={cancelKpiPaymentsAwaitStatus}
              setCancelKpiPaymentsAwaitStatus={setCancelKpiPaymentsAwaitStatus}
              isPaymentsEdit={isPaymentsEdit}
              setisPaymentsEdit={setisPaymentsEdit}
              setisPaymentsCancelClick={setisPaymentsCancelClick}
              setIsPaymentsUpdateClick={setIsPaymentsUpdateClick}
              isRefreshBtnClick={isRefreshBtnClick}
              setRefreshBtnClick={setRefreshBtnClick}
              selectedRowId={selectedRowId}
            />
          </TabStripTab>
        </TabStrip>
      </>
    );
  };

  return (
    <div className="Main mobileblock">
      <div className="overlaymobile">
        <div className="overlaycont">
          <div className="procarelogomob"></div>
          <div className="mobnotificationlogo">
            <h3>Please use tablet or desktop to view this page</h3>
            <button className="primarybtn" href="/dashboard">
              Back to Home
            </button>
          </div>
        </div>
      </div>
      <div className="content">
        {screenLock ? <div className="k-loading-mask"></div> : false}

        <Splitter
          panes={panes}
          onChange={onPaneChange}
          className="splitviewcustom"
        >
          <div className="pane-content">{leftPane()}</div>
          <div className="pane-content kpiassignment">
            {" "}
            {dataList.length == 0 && dataItem.mstr_key ? (
              <div className="assign-nodata">
                <div className="overlaycont">
                  <div className="procarelogomob"></div>
                  <div className="mobnotificationlogo">
                    <h3>
                      {" "}
                      There is no record on the left side of the screen. Please
                      clear the filter or refresh the screen.
                    </h3>
                  </div>
                </div>
              </div>
            ) : (
              false
            )}
            {rightPane()}
          </div>
        </Splitter>
      </div>

      {isVersionShow.dtuLogs && (
        <DtuBatchLogs
          visible={isVersionShow}
          setShow={setVersionShow}
          module={"org_position"}
          pageProps={pageProps}
        />
      )}

      {isShow.isKpiType ? (
        <ManageKpiType
          visible={isShow.isKpiType}
          setShow={setShow}
          pageProps={pageProps}
        />
      ) : (
        false
      )}
    </div>
  );
};

export default React.memo(Kpi);
