import { bind } from "@react-rxjs/core";
import { createSignal } from "@react-rxjs/utils";
import { getUserCredential } from "src/lib/cookies";

export const [userData$, setUserData] = createSignal();

export const [getUserData, getUserData$] = bind(
  userData$,
  getUserCredential() || {}
);
export const MapAttributes = {
  mapListData: (dataObj = {}, responseKey = "") => {
    return dataObj?.[String(responseKey)] || "";
  },
};
export const mapUserData = {
  tenantId: {
    key: "tenantId",
    label: "Tenant ID",
    getData: (data) => MapAttributes.mapListData(data, "out_tenant_id"),
  },
  userKey: {
    key: "userKey",
    label: "User Key",
    getData: (data) => MapAttributes.mapListData(data, "out_user_id"),
  },
  acctEncryptKey: {
    key: "acctEncryptKey",
    label: "Encrpty Key",
    getData: (data) => MapAttributes.mapListData(data, "out_acct_encrypt_key"),
  },
  dbRoleKey: {
    key: "dbRoleKey",
    label: "DB Role Key",
    getData: (data) => MapAttributes.mapListData(data, "out_db_role_key"),
  },
  dbRoleTypeAbbr: {
    key: "dbRoleTypeAbbr",
    label: "DB Role Type Abbreviation",
    getData: (data) => MapAttributes.mapListData(data, "out_db_role_type_abbr"),
  },
};
