import React, { useEffect, useState } from "react";
import TmplAuth from "src/template/tmplAuth";
import OtpForm from "./form/otpForm";
import { getLocalStorage } from "../../lib/cookies";




const OtpContent = () => {
    const processEnv = process.env;
    const localStorageData = getLocalStorage(processEnv.REACT_APP_CREDENTIAL_KEY);
    
    const [localStorageDetails, setLocalStorageDetails] = useState({})
    useEffect(()=>{
        setLocalStorageDetails(localStorageData)
    },[localStorageData])

    return (
        <>
        
            <div>
            <div className="loginwelcometext"><h3>Authentication Required</h3></div>
                <h3 className="loginusername">
                    {localStorageDetails?.out_user_first_name +
                        " " +
                        localStorageDetails?.out_user_last_name}
                </h3>
            </div>
            <div>
                <span
                    title="Change User?"
                    className="changeuserlink changeUserlinkOTP"
                >
                    {localStorageDetails?.out_user_name}{" "}
                    {/* <span className="loginuserbtn"></span> */}
                </span>
            </div>
        </>
    );
};

const Otp = () => {
    return <div>
        <TmplAuth form={<OtpForm/>} pageContent={<OtpContent/>} />;
        </div>
};

export default React.memo(Otp);
