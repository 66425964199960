import {
  setCustomTableInputFieldDataAction,
} from "src/redux/actions/formula-actions";
import {
  GetCustomTableColumns,
  GetPeriodTypeAPI,
  GetRateTableData,
  GetRateTypeData,
  GetResultNameData,
  GetResultTypeData,
  GetRuleTypeAPI,
  GetSystemFxData,
  GetUDFDataObj,
  getCustomTableListAsync,
} from "../../lib/common-service";

import { transformDateTime } from "src/lib/date-time.util";

export const fetchRfctFieldData = async (dispatch) => {
  const REJEX_EXP = `(.*)?"(.[^",]*)?formula":"1"`;
  const FLT1 = `(u.input_control IS NULL OR u.input_control REGEXP '${REJEX_EXP}')`;
  const FLT2 = `u.rfct_field_name <> 'custom_string_value'`;
  const response = await GetUDFDataObj(
    {
      in_filter: `AND ${FLT1} AND ${FLT2}`,
      in_sort_number: 3,
      in_sort_order: "ASC",
      in_is_group: 0,
    },
    dispatch
  );
  const result = response.map((item) => ({
    udf_data_obj_field_label: item.udf_data_obj_field_label,
    udf_data_obj_field_name: item.rfct_field_name,
  }));
  return result;
};

export const fetchSystemFxData = async (dispatch) => {
  const response = await GetSystemFxData(
    {
      in_filter: `AND a.system_fx_label <> ''`,
      in_sort_number: 1,
      in_sort_order: "ASC",
      in_page_row_offset: 0,
      in_page_rows: 9999,
    },
    dispatch
  );
  response.unshift({ system_fx_label: "Select System Fx", system_fx_name: "" });
  return response;
};

export const fetchPeriodTypeData = async (dispatch) => {
  const response = await GetPeriodTypeAPI(
    {
      in_filter: null,
      in_sort_number: 1,
      in_sort_order: "ASC",
      in_page_row_offset: 0,
      in_page_rows: 9999,
    },
    dispatch
  );
  const result = response.map((item) => ({
    ...item,
    text: item.period_type_name,
    value: item.period_type_name,
  }));
  return result;
};

export const fetchRateTableData = async (dispatch) => {
  const response = await GetRateTableData(
    {
      in_filter: null,
      in_sort_number: 1,
      in_sort_order: "ASC",
    },
    dispatch
  );
  const results = response.map((item) => ({
    ...item,
    text: item.rate_table_name,
    value: item.rate_table_mstr_key,
  }));
  return results;
};

export const fetchRateTypeData = async (dispatch) => {
  const response = await GetRateTypeData(
    {
      in_filter: null,
      in_sort_number: 1,
      in_sort_order: "ASC",
    },
    dispatch
  );
  const result = response.map((item) => ({
    ...item,
    text: item.rate_type_name,
    value: item.rate_type_name,
  }));
  return result;
};

export const fetchRuleResultNameData = async (dispatch) => {
  const response = await GetResultNameData(
    {
      in_filter: null,
      in_sort_number: 2,
      in_sort_order: "ASC",
      in_page_row_offset: 0,
      in_page_rows: 9999,
    },
    dispatch
  );
  const result = response.map((item) => ({
    ...item,
    text: item.result_name_type_group,
    value: item.result_name_mstr_key,
  }));
  result.unshift({ text: "", value: 0 });
  return result;
};

export const fetchCreditResultNameData = async (dispatch) => {
  const response = await GetResultNameData(
    {
      in_filter: "AND g.result_group_name LIKE '%credit%'",
      in_sort_number: 2,
      in_sort_order: "ASC",
      in_page_row_offset: 0,
      in_page_rows: 9999,
    },
    dispatch
  );
  const result = response.map((item) => ({
    ...item,
    text: item.result_name_type,
    value: item.result_name_mstr_key,
  }));
  result.unshift({ text: "", value: 0 });
  return result;
};

export const fetchMeasurementResultNameData = async (dispatch) => {
  const response = await GetResultNameData(
    {
      in_filter: "AND g.result_group_name LIKE '%measure%'",
      in_sort_number: 2,
      in_sort_order: "ASC",
      in_page_row_offset: 0,
      in_page_rows: 9999,
    },
    dispatch
  );
  const result = response.map((item) => ({
    ...item,
    text: item.result_name_type,
    value: item.result_name_mstr_key,
  }));
  result.unshift({ text: "", value: 0 });
  return result;
};

export const fetchIncentiveResultNameData = async (dispatch) => {
  const response = await GetResultNameData(
    {
      in_filter: "AND g.result_group_name LIKE '%incentive%'",
      in_sort_number: 2,
      in_sort_order: "ASC",
      in_page_row_offset: 0,
      in_page_rows: 9999,
    },
    dispatch
  );
  const result = response.map((item) => ({
    ...item,
    text: item.result_name_type,
    value: item.result_name_mstr_key,
  }));
  result.unshift({ text: "", value: 0 });
  return result;
};

export const fetchPaymentResultNameData = async (dispatch) => {
  const response = await GetResultNameData(
    {
      in_filter: "AND g.result_group_name LIKE '%payment%'",
      in_sort_number: 2,
      in_sort_order: "ASC",
      in_page_row_offset: 0,
      in_page_rows: 9999,
    },
    dispatch
  );
  const result = response.map((item) => ({
    ...item,
    text: item.result_name_type,
    value: item.result_name_mstr_key,
  }));
  result.unshift({ text: "", value: 0 });
  return result;
};

export const fetchRuleTypeData = async (dispatch) => {
  const response = await GetRuleTypeAPI(
    {
      in_rule_type_name: null,
    },
    dispatch
  );
  const result = response.map((item) => ({
    ...item,
    text: item.rule_type_name,
    value: item.rule_type_mstr_key,
  }));
  return result;
};

export const fetchResultTypeData = async (dispatch) => {
  const response = await GetResultTypeData(
    {
      in_filter: null,
      in_sort_number: 1,
      in_sort_order: "ASC",
      in_page_row_offset: 0,
      in_page_rows: 9999,
    },
    dispatch
  );
  const result = response.map((item) => ({
    ...item,
    text: item.result_type_name,
    value: item.result_type_mstr_key,
  }));
  result.unshift({ text: "", value: 0 });
  return result;
};

export const fetchReturnFieldData = async (dispatch) => {
  const response = await GetUDFDataObj(
    {
      in_filter: `AND u.udf_data_obj_field_name LIKE '%vw_rule_result_rates%'`,
      in_sort_number: 3,
      in_sort_order: "ASC",
      in_is_group: 0,
    },
    dispatch
  );
  const result = response.map((item) => ({
    ...item,
    text: item.udf_data_obj_field_label,
    value: item.udf_data_obj_field_name,
  }));
  result.unshift({ text: "", value: "" });
  return result;
};

export const fetchReturnFieldProrationData = async (dispatch) => {
  const response = await GetUDFDataObj(
    {
      in_filter: `AND u.udf_data_obj_field_name LIKE '%vw_rule_result_proration%'`,
      in_sort_number: 3,
      in_sort_order: "ASC",
      in_is_group: 0,
    },
    dispatch
  );
  const result = response.map((item) => ({
    ...item,
    text: item.udf_data_obj_field_label,
    value: item.udf_data_obj_field_name,
  }));
  result.unshift({ text: "", value: "" });
  return result;
};

export const fetchCustomTableData = async (dispatch, dbRoleKey, paramName) => {
  const response = await getCustomTableListAsync(
    {
      in_filter: null,
      in_sort_number: 1,
      in_sort_order: "ASC",
      in_db_role_key: dbRoleKey,
      in_page_row_offset: 0,
      in_page_rows: 9999,
      //in_is_group: 0,
    },
    dispatch
  );
  const result = response.map((item) => ({
    ...item,
    text: item.custom_table_name_type,
    value: item.custom_table_mstr_key,
  })).filter((it) => paramName !== "sp_sysfx_lookup_table_value" ? it.custom_table_type === "S" : it.custom_table_type);
  return result;
};

export const fetchCustomTableColumnsData = async (dispatch, key) => {
  const response = await GetCustomTableColumns(
    {
      in_filter: null,
      in_sort_order: null,
      in_multi_sort_order: null,
      in_sort_number: null,
      in_page_row_offset: 0,
      in_page_rows: 9999,
      in_custom_table_mstr_key: key,
    },
    dispatch
  );
  const result = response.map((item) => ({
    text: item.origin_custom_col_name,
    value: item.origin_custom_col_name,
  }));
  result.unshift({ text: "", value: "" });
  return result;
};

export const fetchCustomTableInputFieldData = async (dispatch) => {
  const response = await GetUDFDataObj(
    {
      in_filter: null,
      in_sort_number: 4,
      in_sort_order: "ASC",
      in_is_group: 0,
    },
    dispatch
  );
  const result = response.map((item) => ({
    text: item.udf_data_obj_field_name,
    value: item.udf_data_obj_field_name,
  }));
  result.unshift({ text: "", value: " " });
  dispatch(setCustomTableInputFieldDataAction(result));

  return result;
};

export const setupXmlValue = (str, inputTag, inputValue) => {
  let value = inputValue;
  if (inputTag === "(") return `${str}<paren>`;
  if (inputTag === ")") return `${str}</paren>`;
  if (inputTag === "logical_op" || inputTag === "math_op") {
    value = inputValue.operator_value || inputValue;
  } else if (inputTag === "rfct_field") {
    value = inputValue.udf_data_obj_field_name || inputValue;
  } else if (inputTag === "date") {
    value = transformDateTime({ date: value, format: "YYYY-MM-DD" });
  }

  return `${str}<${inputTag}>${value}</${inputTag}>`;
};
