export const SET_DB_OBJ_DATA = "SET_DB_OBJ_DATA";
export const SET_READ_WRITE_ACCESS = "SET_READ_WRITE_ACCESS";

export const setDbObjData = (dbObjData) => {
  return {
    type: SET_DB_OBJ_DATA,
    data: dbObjData
  };
};

export const setReadWriteAccess = (readAccessDenied, writeAccessDenied) => {
  return {
    type: SET_READ_WRITE_ACCESS,
    data: {
      readAccessDenied,
      writeAccessDenied
    },
  };
}