import React from "react";
import { removeUserCredential, removeOtpData, setFlash, removeLocalStorage } from "../../lib/cookies";
import { TIMEOUT_DIALOG_TIMER_COUNTDOWN } from "../../constants/constants";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

let dialogTimeout = null;
let countdownInterval = null;

class BaseSysTimeOut extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      events: ["load", "mousemove", "mousedown", "click", "scroll", "keypress"],
      leftSecond: TIMEOUT_DIALOG_TIMER_COUNTDOWN,
      isVisible: false,
    }
  }

  /**
   * if none of the event is triggered within defined secs, inactivity dialog will be setup
   */
  setupDialog = (props) => {

    this.resetTimer()

    dialogTimeout = setTimeout(() => {

      this.resetTimer()
      this.removeEventListner()
      this.setState({ isVisible: true })

      //set interval to decrease countdown timer at every sec (1000 = 1 sec)
      countdownInterval = setInterval(this.startCountdown.bind(this), 1000)

      document.getElementById("keep-alive-timeout").addEventListener("click", this.unsetDialog)

    }, props.dialogOpenTimeMs)
  };

  /**
   * unset dialog if dialog is already setup
   */
  unsetDialog = () => {

    // clear interval and reset counter
    if (countdownInterval) {

      clearInterval(countdownInterval)
      countdownInterval = null

      this.setState({ leftSecond: TIMEOUT_DIALOG_TIMER_COUNTDOWN })
    }

    this.removeEventListner()
    this.setState({ isVisible: false })
  };

  /**
   * resets the timer if it exists
   */
  resetTimer = () => {

    if (dialogTimeout) {

      clearTimeout(dialogTimeout)
    }
  };

  /**
   * Listener clean up. Removes the existing event listener from the window
   */
  removeEventListner = () => {
    this.state.events.forEach((item) => {
      window.removeEventListener(item, this.resetTimer)
    })
  };

  /**
   * start the countdown and update the latest count to element
   */
  startCountdown() {

    this.setState((state, props) => ({

      leftSecond: state.leftSecond - 1
    }))

    if (this.state.leftSecond <= 0) {

      this.setState({ leftSecond: 1 })

      this.unsetDialog()
      this.forceLogout()
    }

    document.getElementById('left-sec-timeout').innerHTML = this.state.leftSecond
  }

  forceLogout = (isAutoLogout = true) => {

    removeLocalStorage("resultData");
    removeUserCredential()
    removeOtpData()
    // clearLocalStorage()

    if (isAutoLogout) {

      setFlash('timeout', true)
    }

    window.location.pathname = '/authentication'
  };

  dialogHTML = () => {

    return(

      <>
        {this.state.isVisible && (
          <Dialog title={"Inactivity warning"} id="dialog-timeout" width={500}>
            <p style={{ margin: "25px" }}>
              You will be logged out due to inactivity in<span id="left-sec-timeout" style={{width: "22px", display: "inline-block", textAlign: "center"}}>{this.state.leftSecond}</span>seconds.<br />
              Do you need more time?
            </p>

            <DialogActionsBar>
              <button
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                id="keep-alive-timeout"
              >
                Yes
              </button>

              <button
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                onClick={() => { this.forceLogout(false); }}
              >
                Logout
              </button>
            </DialogActionsBar>
          </Dialog>
        )}
        </>
      );
  }
}

export default BaseSysTimeOut;