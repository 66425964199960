import React from "react";
import { useDispatch } from "react-redux";

import {
    generateHeader,
    errStatusHandler,
    POSTAPI,
} from "../api-setup/api-helper";
import { getUserCredential } from "../lib/cookies";
import { formatSystemDate } from "../utils/utils";
import { useHistory } from "react-router";

import { GetPeriodTypeAPI, GetPeriodsDataList } from "../lib/common-service";
import { setNewMessage } from "../redux/actions/setting-actions";

export default function DataRows() {
    const dispatch = useDispatch();
    const history = useHistory();
    const cookiesData = getUserCredential();
    const [loadingState, setLoadState] = React.useState({
        isSaveData: undefined,
        isDeleteData: undefined,
    });

    const getListDataAsync = async (listData, apiData) => {
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                apiData.apiEndPoints.listApi,
                null,
                generateHeader(),
                listData,
                dispatch
            ).then((res) => {
                if (res) {
                    if (res.data.out_parameters.out_ret_code > 0) {
                        errStatusHandler(res, history, dispatch);
                    }
                    resolve(res);
                }
            });
        })
            .then((res) => {
                let userData = JSON.parse(JSON.stringify(res.data.row_result));

                let versionData = [];
                let endDate = null;

                userData.map((resData) => {
                    let data = {};
                    data["isSelected"] = 0;
                    data["isCurrent"] = resData.is_current;
                    data["periodName"] = resData["b.period_name"];
                    data["dateVersion"] = resData["a.date_version"];
                    data["periodMstrKey"] = resData["a.period_mstr_key"];
                    data["uniqueKey"] =
                        apiData.dataItem.mstr_key +
                        "_" +
                        resData["a.period_mstr_key"];

                    if (resData.is_current === 1) {
                        endDate = formatSystemDate(resData["b.eff_end_date"]);

                        data["endDate"] = endDate;
                        data["isSelected"] = 1;
                    }

                    versionData.push(data);
                    return 0;
                });

                const lastIndex = versionData.length - 1;

                return {
                    data: versionData,
                    lastVersionIdx: lastIndex,
                    currentVersionEndDate: endDate,
                };
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    const getPeriodsDataTypeAsync = async (PeriodEndData) => {
        let resPeriods = "";

        let in_filter_periods = "";
        if (PeriodEndData !== null) {
            in_filter_periods = `AND a.period_start_date >= '${PeriodEndData}'`;
        }

        let periodsDataReq = {
            in_tenant_id: cookiesData.out_tenant_id,
            in_filter: in_filter_periods,
            in_sort_number: null,
            in_sort_order: null,
            in_page_row_offset: 0,
            in_page_rows: 9999,
            in_sort_number_concat:
                "a.period_end_date DESC, a.period_level_order DESC",
        };

        resPeriods = await GetPeriodsDataList(
            periodsDataReq,
            dispatch,
            history
        );

        if (!resPeriods.length) {
            return [];
        }

        let res = await GetPeriodTypeAPI(
            {
                in_filter:
                    " AND a.period_type_hierarchy_level IS NOT NULL AND a.period_type_name != 'semi-annual'",
                in_sort_number: 1,
                in_sort_order: "DESC",
            },
            dispatch,
            history
        );

        if (res.length === 0) {
            return [];
        }

        let tempArray = {};

        res.forEach((item) => {
            tempArray[item.period_type_name] = {
                period_type_mstr_key: item.period_type_mstr_key,
                period_type_name: item.period_type_name,
                period_type_hierarchy_level: item.period_type_hierarchy_level,
                items: [],
            };
        });

        resPeriods.forEach((item) => {
            if (tempArray[item.period_type_name]) {
                tempArray[item.period_type_name].items.push({
                    period_mstr_key: item.period_mstr_key,
                    period_name: item.period_name,
                    period_desc: item.period_desc,
                    period_start_date: item.period_start_date,
                    period_end_date: item.period_end_date,
                    period_type_mstr_key: item.period_type_mstr_key,
                    period_type_name: item.period_type_name,
                    period_level_order: item.period_level_order,
                });
            }
        });

        return Object.values(tempArray);
    };

    const addDataAsync = (apiData) => {
        setLoadState({ ...loadingState, isSaveData: false });

        POSTAPI(
            false,
            apiData.api,
            null,
            generateHeader(),
            apiData.bodyData,
            dispatch
        ).then((res) => {
            if (res.status) {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                } else {
                    setLoadState({ ...loadingState, isSaveData: true });
                }
            }
        });
    };

    const deleteDataAsync = async(apiData) => {
        setLoadState({ ...loadingState, isDeleteData: false });

        const response = await POSTAPI(
            false,
            apiData.api,
            null,
            generateHeader(),
            apiData.bodyData,
            dispatch
        ).then((res) => {
            if (res.status) {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                } else {
                    setLoadState({ ...loadingState, isDeleteData: true });
                    return res
                }
            }
        });
        return response
    };

    return [
        {
            loadingState,
            setLoadState,
        },
        {
            getListDataAsync,
            getPeriodsDataTypeAsync,
            addDataAsync,
            deleteDataAsync,
        },
    ];
}
