import React, { useEffect, useState, cloneElement } from "react";
import {
  Toolbar,
  ToolbarSeparator,
  Button,
  ButtonGroup,
  SplitButton,
  SplitButtonItem,
} from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Label } from "@progress/kendo-react-labels";
import { filterBy } from "@progress/kendo-data-query";
import { v4 as uuidv4 } from "uuid";
import { Tooltip } from "@progress/kendo-react-tooltip";

const FormulaToolbar = (props) => {
  const { sysFxData, onSelectedSystemFx, onButtonPress, onClearPress, pageProps } = props;

  const [selectedSystemFx, setSelectedSystemFx] = useState();
  const [systemFxData, setSystemFxData] = useState();
  const [sysFxFilter, setSysFxFilter] = useState("");

  useEffect(() => {
    if (!selectedSystemFx) {
      setSelectedSystemFx(sysFxData[0]);
    }
    if (!systemFxData) {
      setSystemFxData(sysFxData);
    }
  }, [selectedSystemFx, systemFxData, sysFxData]);

  const itemRenderSystemFx = (li, itemProps) => {
    const itemChildren = (
      <Tooltip anchorElement="target" position="right" openDelay={100}>
        <span title={itemProps.dataItem.system_fx_desc}>{li.props.children}</span>
      </Tooltip>
    );
    return cloneElement(li, li.props, itemChildren);
  };

  return (
    <Toolbar>
      <div>
        <Label style={{ fontSize: 12 }} className="sysfx">
          System Fx:
        </Label>
        <DropDownList
          name="system_fx"
          className="system_fx_width"
          value={
            systemFxData?.find(
              (e) => e.system_fx_mstr_key === selectedSystemFx?.system_fx_mstr_key
            ) || sysFxData[0]
          }
          onChange={(e) => {
            setSelectedSystemFx(e.target.value);
            onSelectedSystemFx(e.target.value);
            setSelectedSystemFx(sysFxData[0]);
            setSystemFxData(sysFxData.slice());
            setSysFxFilter("");
          }}
          data={systemFxData}
          dataItemKey="system_fx_name"
          textField="system_fx_label"
          filterable={true}
          filter={sysFxFilter}
          onFilterChange={(event) => {
            setSystemFxData(filterBy(sysFxData.slice(), event.filter));
            setSysFxFilter(event.filter.value);
          }}
          itemRender={itemRenderSystemFx}
          disabled={pageProps.writeAccess === 0 ? true : false}
        />
      </div>
      <ToolbarSeparator />
      <ButtonGroup>
        <Button
          onClick={() => {
            onButtonPress({ key: "(", value: "(", uId: uuidv4(), isProcessing: false });
          }}
          disabled={pageProps.writeAccess === 0 ? true : false}
        >
          Open
        </Button>
        <Button
          onClick={() => {
            onButtonPress({ key: ")", value: ")", uId: uuidv4(),  isProcessing: false });
          }}
          disabled={pageProps.writeAccess === 0 ? true : false}
        >
          Close
        </Button>
      </ButtonGroup>
      <ToolbarSeparator />
      <SplitButton
       disabled={pageProps.writeAccess === 0 ? true : false}
        text="123"
        onButtonClick={() => {
          onButtonPress({ key: "number", value: "0", uId: uuidv4(),  isProcessing: false });
        }}
        onItemClick={(e) => {
          if (e.item.text === "Insert Single 123") {
            onButtonPress({ key: "number", value: "0", uId: uuidv4(),  isProcessing: false });
          } else {
            onButtonPress({ key: "number_in", value: "(123,456)", error: false, uId: uuidv4(),  isProcessing: false });
          }
        }}
      >
        <SplitButtonItem text="Insert Single 123"  disabled={pageProps.writeAccess === 0 ? true : false}/>
        <SplitButtonItem text="Insert Multiple (123,456)"  disabled={pageProps.writeAccess === 0 ? true : false}/>
      </SplitButton>
      <SplitButton
       disabled={pageProps.writeAccess === 0 ? true : false}
        text="abc"
        onButtonClick={() => {
          onButtonPress({ key: "string", value: "", error: false, uId: uuidv4(), isProcessing: false });
        }}
        onItemClick={(e) => {
          if (e.item.text === "Insert Single abc") {
            onButtonPress({ key: "string", value: "", error: false, uId: uuidv4(),  isProcessing: false });
          } else {
            onButtonPress({
              key: "string_in",
              value: "('abc','def')",
              error: false,
              uId: uuidv4(),
            });
          }
        }}
      >
        <SplitButtonItem text="Insert Single abc"  disabled={pageProps.writeAccess === 0 ? true : false} />
        <SplitButtonItem text="Insert Multiple ('abc','def')"  disabled={pageProps.writeAccess === 0 ? true : false} />
      </SplitButton>
      <Button
        onClick={() => {
          onButtonPress({ key: "date", value: "", error: false, uId: uuidv4(),  isProcessing: false });
        }}
        disabled={pageProps.writeAccess === 0 ? true : false}
      >
        YYY-MM-DD
      </Button>
      <ToolbarSeparator />
      <ButtonGroup  disabled={pageProps.writeAccess === 0 ? true : false}>
        <Button
         disabled={pageProps.writeAccess === 0 ? true : false}
          onClick={() => {
            onButtonPress({
              key: "logical_op",
              value: null,
              error: false,
              uId: uuidv4(),
              isProcessing: true
            });
          }}
        >
          AND / OR
        </Button>
        <Button
         disabled={pageProps.writeAccess === 0 ? true : false}
          onClick={() => {
            onButtonPress({
              key: "math_op",
              value: null,
              error: false,
              uId: uuidv4(),
              isProcessing: true
            });
          }}
        >
          Math / SQL
        </Button>
      </ButtonGroup>
      <ToolbarSeparator />
      <Button
       disabled={pageProps.writeAccess === 0 ? true : false}
        onClick={() => {
          onButtonPress({
            key: "rfct_field",
            value: null,
            error: false,
            uId: uuidv4(),
            isProcessing: true
          });
        }}
      >
        RFCT Field
      </Button>
      <ToolbarSeparator />
      <Button onClick={onClearPress}  disabled={pageProps.writeAccess === 0 ? true : false} >Clear</Button>
    </Toolbar>
  );
};

export default FormulaToolbar;
