import React from "react";
import {
  clearLocalStorage,
  removeOtpData,
  removeUserCredential,
} from "src/lib/cookies";
import { CreateUserLocalStorage } from "src/utils/loginUtils";
import {useHistory } from 'react-router-dom';

export const ChangeUserComponents = (props) => {
  const history = useHistory();

  const changeUserHandler = () => {
    removeOtpData();
    clearLocalStorage();
    removeUserCredential();
    CreateUserLocalStorage({out_user_name: props?.out_user_name, out_credential: "NA"});
    history.push('/login');

  };

  return (
    <div>
      {/* {props.children} */}
      <span
        onClick={props.showChangeUserBtn == false ? ()=> false : changeUserHandler}
        {...(props.showChangeUserBtn ? {title: "Change User?"} : {})}
        className={props.showChangeUserBtn ? `changeuserlink` : 'changeuserlink changeUserlinkOTP'}
      >
        {props?.out_user_name} {props.showChangeUserBtn && <span className="loginuserbtn">Change User</span>}
      </span>
    </div>
  );
};
