import { savePDF } from "@progress/kendo-react-pdf";
import { useCallback, useState } from "react";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { DELETE_BTN_ACTIONS } from "src/constants/constants";
import {
    setNewMessage,
    setLinkedRules,
} from "src/redux/actions/setting-actions";
import { DashboardPODDeleteApi } from "src/service/dashboard.service";
import {
    getDashboardEditPodDialog,
    getDashboardPod,
    getDashboardPublishEnable,
    mapDashboardPodData,
    setDashboardEditPodDialog,
    setDashboardPublishEnable,
} from "src/store/dashboard.store";
import { getUserData, mapUserData } from "src/store/user.store";
import { dialogConfig } from "./index.const";
import { useHistory } from "react-router-dom";

export const DashboardPodHeaderHook = (refreshDashboardPodDetail) => {
    const { tenantId, userKey, dbRoleKey } = mapUserData;
    const { name, dashboardPodKey } = mapDashboardPodData;
    const userData = getUserData();
    const [editDataSetDialog, setEditDataSetDialog] = useState(false);
    const dashboardData = getDashboardEditPodDialog();
    const activeDashboardPod = getDashboardPod();
    const { tabId } = mapDashboardPodData;
    const activeTabId = tabId.getData(activeDashboardPod);
    const dashboardPublishEnable = getDashboardPublishEnable();

    const dispatch = useDispatch();
    const history = useHistory();

    const editDashboardDataSet = useCallback(
        (podDetail) => {
            if (!editDataSetDialog) {
                setDashboardEditPodDialog({
                    podDetail,
                    tenantId: tenantId.getData(userData) || null,
                    userKey: userKey.getData(userData),
                    dashboardPodKey: dashboardPodKey.getData(podDetail) || null,
                });
                setEditDataSetDialog(true);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [editDataSetDialog, dashboardData, userData]
    );

    const linkToResult = useCallback(
        (podDetail, dashboardData) => {
            const resutlX = podDetail?.result_name_master_keys_x
                ? podDetail?.result_name_master_keys_x?.split(",")
                : [];
            const resutlY = podDetail?.result_name_master_keys_y?.split(",");
            const resutlZ = podDetail?.result_name_master_keys_z
                ? podDetail?.result_name_master_keys_z?.split(",")
                : [];

            const defaultObj = [{ id: "0" }];

            let resultObj = defaultObj;
            let resultArray = defaultObj;
            let compPlanObj = defaultObj;
            let compPlanArray = defaultObj
            let kpiObj = defaultObj;

            //create a set of unique key
            if (
                resutlX[0] !== "0" &&
                resutlY[0] !== "0" &&
                resutlZ[0] !== "0"
            ) {
                const resultName = [
                    ...new Set([...resutlX, ...resutlY, ...resutlZ]),
                ];

                resultObj = Object.fromEntries(
                    resultName.map((o, i) => [
                        i,
                        {
                            id: parseInt(o),
                        },
                    ])
                );
                resultArray = resultName.map((o, i) => ({
                    id: parseInt(o),
                }));
                
            }

            const compPlanKeys = podDetail?.comp_plan_master_keys?.split(",");

            if (compPlanKeys[0] !== "0") {
                compPlanObj = Object.fromEntries(
                    compPlanKeys.map((o, i) => [
                        i,
                        {
                            id: parseInt(o),
                        },
                    ])
                );
                compPlanArray = compPlanKeys.map((o, i) => ({
                    id: parseInt(o),
                }));
            }

            const kpiKeys = podDetail?.kpi_master_keys?.split(",");

            if (kpiKeys[0] !== "0") {
                kpiObj = Object.fromEntries(
                    kpiKeys.map((o, i) => [
                        i,
                        {
                            id: parseInt(o),
                        },
                    ])
                );
            }
            let obj = {
                periodTypeValue: dashboardData?.tab_period_type_mstr_key || 0,
                periodsValue: dashboardData?.tab_period_mstr_key || 0,
                resultCompPlanValue: compPlanArray,
                resultNameValue: resultArray,
            };
            localStorage.setItem("resultData", JSON.stringify(obj));

            dispatch(
                setLinkedRules({
                    key: "dashboard",
                    data: {
                        //pod_period_type_mstr_key apply in result grid, tab_period_type_mstr_key apply in result filter
                        pod_period_type_mstr_key:
                            podDetail?.period_type_mstr_key || 0,
                        tab_period_type_mstr_key:
                            dashboardData?.tab_period_type_mstr_key || 0,
                        tab_period_mstr_key:
                            dashboardData?.tab_period_mstr_key || 0,
                        is_report_published:
                            dashboardData?.is_report_published || 0,
                        comp_plan_mstr_key: compPlanObj,
                        kpi_mstr_key: kpiObj,
                        result_name_mstr_key: resultObj,
                        title: "",
                        type: "dashboard",
                    },
                })
            );

            podDetail?.dashboard_type_name &&
                history.push(
                    "/" + podDetail?.dashboard_type_name?.toLowerCase() + "s"
                );

            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [editDataSetDialog, dashboardData, userData]
    );

    const getUserKey = userKey.getData(userData) || "";
    const getTenantId = tenantId.getData(userData) || "";

    const deleteDashboardPod = useMutation({
        mutationFn: async (dashboardPodKey) => {
            const payload = {
                userId: getUserKey || null,
                dbRoleKey: dbRoleKey.getData(userData),
                tenantId: getTenantId || null,
                dashboardPodKey: dashboardPodKey || null,
            };
            await DashboardPODDeleteApi(payload);
        },
        onSuccess() {
            dashboardPublishEnable[activeTabId] = true;
            setDashboardPublishEnable({ ...dashboardPublishEnable });
            refreshDashboardPodDetail();
        },
        onError() {
            dispatch(
                setNewMessage({
                    message: dialogConfig.notification.error.text,
                    type: dialogConfig.notification.error.type,
                    show: true,
                })
            );
            refreshDashboardPodDetail();
        },
    });

    const [detailPopup, setDetailPopup] = useState({
        title: "Detail",
        isVisible: false,
        type: "",
        actionBtn: [],
        data: {},
        action: "Close",
    });

    const onDetailClose = useCallback(() => {
        setDetailPopup({
            ...detailPopup,
            isVisible: false,
            type: "",
            actionBtn: [],
            data: {},
            action: "Detail",
        });
    }, [detailPopup]);

    const onDetailClick = useCallback(
        (podDetail) => {
            setDetailPopup({
                ...detailPopup,
                title: name.getData(podDetail),
                isVisible: true,
                type: "DETAIL",
                actionBtn: [
                    {
                        name: "Close",
                        onClick: onDetailClose,
                    },
                ],
                data: {
                    ...podDetail,
                },
                action: "Detail",
            });
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [detailPopup, onDetailClose]
    );

    const [confirmationPopup, setConfirmationPopup] = useState({
        title: "Delete",
        desc: `<p>Are you sure you want to delete this record?</p>`,
        isVisible: false,
        type: "",
        actionBtn: [],
        data: {},
        action: "Delete",
    });

    const onDeleteConfirm = useCallback(
        (dashboardPodKeyValue) => {
            deleteDashboardPod.mutate(dashboardPodKeyValue);
            setConfirmationPopup({
                ...confirmationPopup,
                isVisible: false,
                type: "",
                actionBtn: [],
                data: {},
                action: "Delete",
            });
        },
        [confirmationPopup, deleteDashboardPod]
    );

    const onCancelConfirm = useCallback(() => {
        setConfirmationPopup({
            ...confirmationPopup,
            isVisible: false,
            type: "",
            actionBtn: [],
            data: {},
            action: "Delete",
        });
    }, [confirmationPopup]);

    const onDeleteClick = useCallback(
        (podDetail) => {
            const dashboardPodKeyValue = dashboardPodKey.getData(podDetail);
            setConfirmationPopup({
                ...confirmationPopup,
                isVisible: true,
                type: "DELETE_CONFIRM",
                actionBtn: DELETE_BTN_ACTIONS(onCancelConfirm, () =>
                    onDeleteConfirm(dashboardPodKeyValue)
                ),
                data: {},
                action: "Delete",
            });
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [confirmationPopup, onCancelConfirm, onDeleteConfirm]
    );

    const onDownloadPOD = async (podDetail) => {
        const element = document.querySelector(
            `.pod-key_${podDetail.dashboard_pod_key}`
        );
        const svgElement = document.querySelector(
            `.pod-key_${podDetail.dashboard_pod_key} .k-chart.k-widget`
        );

        if (!svgElement) {
            dispatch(
                setNewMessage({
                    message: "Oops! No data to export",
                    type: "error",
                    show: true,
                })
            );
            return false;
        }

        // Save the original styles to revert after PDF export
        const originalMaxWidth = element.style.maxWidth;
        const originalWidth = element.style.width;
        const svgOriginalWidth = svgElement.style.width;
        const originalHeight = element.style.height;
        const originalTransform = element.style.transform;
        const originalTransformOrigin = element.style.transformOrigin;
        const originalPosition = element.style.position;

        // Fix the size of the element to match A4 landscape dimensions
        element.style.maxWidth = "none"; // Remove max-width constraints
        element.style.width = "207mm"; // Fixed width for A4 landscape
        svgElement.style.width = "205mm"; // Fixed width for A4 landscape
        element.style.height = "auto"; // Let height adjust proportionally
        element.style.transform = "none"; // Remove any transformations
        element.style.transformOrigin = "top left"; // Keep the element origin consistent
        element.style.position = "absolute"; // Fix the position to avoid layout shifts

        // Force reflow to apply styles before exporting
        window.dispatchEvent(new Event("resize"));

        // Export the element to PDF
        await savePDF(element, {
            paperSize: "A4",
            fileName: `${name.getData(activeDashboardPod)}_${name.getData(
                podDetail
            )}.pdf`,
            landscape: true,
            margin: "1cm",
            scale: 1, // Ensure no scaling within the PDF export
        });

        // Revert styles to original after PDF export
        element.style.maxWidth = originalMaxWidth;
        element.style.width = originalWidth;
        svgElement.style.width = svgOriginalWidth;
        element.style.height = originalHeight;
        element.style.transform = originalTransform;
        element.style.transformOrigin = originalTransformOrigin;
        element.style.position = originalPosition;

        // Allow the browser to re-render with the original styles
        window.dispatchEvent(new Event("resize"));
    };

    const closeDashboardDataSetDialog = useCallback(() => {
        if (editDataSetDialog) {
            setEditDataSetDialog(false);
            setDashboardEditPodDialog({});
        }
    }, [editDataSetDialog]);

    return {
        editDashboardDataSet,
        linkToResult,
        editDataSetDialog,
        onDeleteClick,
        onDownloadPOD,
        onDetailClick,
        closeDashboardDataSetDialog,
        confirmationPopup,
        onCancelConfirm,
        detailPopup,
        onDetailClose,
    };
};
