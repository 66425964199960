/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button } from "@progress/kendo-react-buttons";
import { FieldWrapper, Form, FormElement } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import { Error, Label } from "@progress/kendo-react-labels";
import {
  changeCancelBtnState,
  changeUpdateBtnState,
  showUnSavedChangesFn,
} from "../../utils/utils";
import React from "react";
import Popup from "../../components/customPopup";
import { getUserCredential } from "../../lib/cookies";
import MessageSchema from "../../utils/messageSchema";
import { HeaderPage } from "../base/headerPage";
import DtuBatchLogs from "../common/dtuBatchLogs";
import { GridButton, SpecialityData } from "../speciality/specialityGrid";
import UploadDTU from "../user/uploadDtu";
import useBusinessGroupApi from "./service";
import { useDocumentClickEventListener } from "../../lib/hooks/custom-common-hooks";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { unSaveChangesPopupSelector } from "src/redux/selectors/custom-selector";
import { setUnSavedChangesPopup } from "src/redux/actions/setting-actions";

const initialFormFields = {
  in_specialty_name: "",
  in_specialty_desc: "",
  in_region_name: "",
};

const Specialty = (pageProps) => {
  const [list, Action] = useBusinessGroupApi();
  const cookiesData = getUserCredential();
  const addRef = React.useRef(null);
  const [isAddNewConfig, setNewConfig] = React.useState(false);
  const [isShow, setShow] = React.useState({
    dtuLogs: false,
    sendEmails: false,
    dtuUpload: false,
    deleteRows: false,
  });
  const dispatch = useDispatch();
  const [exportDataClick, setExportDataClick] = React.useState(false);
  const [isAPICall, setAPICall] = React.useState(false);
  const [formFields, setFormFields] = React.useState({ ...initialFormFields });
  const [errorObj, setErrorObj] = React.useState({});
  const [isSaveClick, setSaveClick] = React.useState(false);
  const [selectedRowId, setSelectedRowId] = React.useState("");
  const [dataList, setDataList] = React.useState([]);
  const [specialityEdit, setspecialityEdit] = React.useState(false);
  const [columnsData, setColumnsData] = React.useState([]);
  const [isCheckColumn, setColumnCheck] = React.useState(false);
  let popupRef = React.useRef();
  useDocumentClickEventListener(isAddNewConfig, setNewConfig, popupRef);

  const { unsavedPopup } = useSelector(
    unSaveChangesPopupSelector,
    shallowEqual
  );

  React.useEffect(() => {
    if (list.loadingState.isSaveData) {
      setFormFields({ ...initialFormFields });
      setAPICall(true);
      setNewConfig(false);
    }
  }, [list.loadingState.isSaveData]);

  React.useEffect(() => {
    if (specialityEdit === false) {
      changeCancelBtnState();
      changeUpdateBtnState();
    }
  }, [specialityEdit]);


  const addNewConfig = (e) => {
    e.preventDefault();
    showUnSavedChangesFn(dispatch, () => {
      setNewConfig(!isAddNewConfig);
    });
  };

  const ShowError = React.useCallback(
    (props) => {
      return <Error>{errorObj[props.name]}</Error>;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errorObj, isSaveClick]
  );

  const handleValidation = () => {
    let isValid = true;
    if (formFields.in_specialty_name.trim() === "") {
      isValid = false;
      errorObj.in_specialty_name = MessageSchema.specialityNameRequired;
    }
    setErrorObj((preVState) => ({ ...preVState, errorObj }));
    return isValid;
  };

  const handleSubmit = () => {
    let valid = handleValidation();
    if (valid) {
      setErrorObj({});
      const bodyData = {
        in_user_id: cookiesData.out_user_id,
        in_tenant_id: cookiesData.out_tenant_id,
        in_specialty_name: formFields.in_specialty_name,
        in_specialty_desc: formFields.in_specialty_desc,
        in_region_name: formFields.in_region_name,
      };
      Action.addDataAsync(bodyData);
    } else {
      setSaveClick(true);
    }
  };

  const onCancelClick = () => {
    setFormFields(initialFormFields);
    setErrorObj({});
    setNewConfig(false);
    setSaveClick(false);
  };

  const handleFieldChange = (e) => {
    const name = e.name ? e.name : e.target.name;
    if (e.value !== null) {
      if (e.value.text === "Regular") {
        setFormFields({
          ...formFields,
          [name]: e.value,
        });
        setErrorObj({ ...errorObj, [name]: "" });
      } else {
        setFormFields({ ...formFields, [name]: e.value });
        setErrorObj({ ...errorObj, [name]: "" });
      }
    }
  };

  const AddNewForm = () => {
    return (
      <Form
        onSubmit={handleSubmit}
        render={() => (
          <FormElement className="emailForm" horizontal={true}>
            <FieldWrapper>
              <div className={"k-form-field-wrap-popup"}>
                <Label>{"Specialty Name* : "}</Label>
                <Input
                  type={"text"}
                  name="in_specialty_name"
                  value={formFields.in_specialty_name}
                  onChange={(e) => {
                    handleFieldChange(e);
                  }}
                  autoFocus={true}
                />
                <ShowError name={"in_specialty_name"} />
              </div>
            </FieldWrapper>
            {/* <FieldWrapper> */}
            <FieldWrapper>
              <div className={"k-form-field-wrap-popup"}>
                <Label>{"Description : "}</Label>
                <Input
                  type={"text"}
                  name="in_specialty_desc"
                  value={formFields.in_specialty_desc}
                  onChange={(e) => {
                    handleFieldChange(e);
                  }}
                />
                <ShowError name={"in_specialty_desc"} />
              </div>
            </FieldWrapper>
            {/* </FieldWrapper> */}
            <FieldWrapper>
              <div className={"k-form-field-wrap-popup"}>
                <Label>{"Region Name : "}</Label>
                <Input
                  type={"text"}
                  name="in_region_name"
                  value={formFields.in_region_name}
                  onChange={(e) => {
                    handleFieldChange(e);
                  }}
                />
                <ShowError name={"in_region_name"} />
              </div>
            </FieldWrapper>
          </FormElement>
        )}
      />
    );
  };

  const AddNewPopup = () => {
    return (
      <Popup anchor={addRef.current} show={isAddNewConfig} className="right0">
        <div className="dot-dropdown-open dot-dropdown-open-Form">
          <div className="caption">
            <span>Add New Specialty</span>
          </div>

          {AddNewForm()}

          <div className="dropdownFooter">
            <div className="k-form-buttons">
              <Button
                primary={true}
                type="submit"
                onClick={() => {
                  onCancelClick();
                }}
              >
                Cancel
              </Button>
              <Button
                primary={true}
                type="submit"
                onClick={() => {
                  handleSubmit(formFields, errorObj);
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </Popup>
    );
  };

  const AddNewLink = () => {
    return (
      <div ref={popupRef}>
        <a
          className="cta-small"
          ref={addRef}
          onClick={(e) => {
            addNewConfig(e);
          }}
        >
          <i className="fas fa-plus"></i> Add New Specialty
        </a>

        {AddNewPopup()}
      </div>
    );
  };

  const handleMenuItemClick = (action) => {
    switch (action) {
      case "dtuLogs":
        showUnSavedChangesFn(dispatch, () => {
          setShow({
            ...isShow,
            dtuLogs: !isShow.dtuLogs,
          });
        });
        break;
      case "export":
        showUnSavedChangesFn(dispatch, () => {
          setExportDataClick(true);
        });
        break;
      case "delete":
        showUnSavedChangesFn(dispatch, () => {
          setShow({
            ...isShow,
            deleteRows: !isShow.deleteRows,
          });
        });
        break;
      default:
        break;
    }
  };

  const handleUpload = (e) => {
    e.preventDefault();
    showUnSavedChangesFn(dispatch, () => {
      setShow({
        ...isShow,
        dtuUpload: !isShow.dtuUpload,
      });
    });
  };

  const headerConfig = {
    title: "Specialty",
    id: "speciality-grid",
    buttons: { template_fx: GridButton },
    add_new_fx: AddNewLink,
    upload_fx: handleUpload,
    action_menu: {
      export: { fx: handleMenuItemClick },
      dtuLog: { fx: handleMenuItemClick },
      delete: { fx: handleMenuItemClick },
    },
    manage_col: {
      columnsData: columnsData,
      setColumnsData: setColumnsData,
      setColumnCheck: setColumnCheck,
      unsavedHandle: {cancelId: "grid-incell-cancel", saveId: "grid-incell-update"}
    },
  };

  const parent = document.querySelector("#content-" + headerConfig.id);

  if (parent) {

    const exportToExcel = parent.querySelector('.export-to-excel')
    const deleteRows = parent.querySelector('.delete-rows')

    exportToExcel && exportToExcel.disableKendoMenu();
    deleteRows && deleteRows.disableKendoMenu();

    dataList.length && exportToExcel && exportToExcel.enableKendoMenu();

    if (selectedRowId !== "") {
      deleteRows && deleteRows.enableKendoMenu();
    }
  }

  return (
    <div className="Main specialitypage">
      <div className="content">
        <div className="content-head">
          <HeaderPage pageProps={pageProps} headerConfig={headerConfig} />
        </div>

        <div className="content-body">
          <div className="dtu-file-container">
            <SpecialityData
              isAPICall={isAPICall}
              setAPICall={setAPICall}
              setExportDataClick={setExportDataClick}
              exportDataClick={exportDataClick}
              selectedRowId={selectedRowId}
              setSelectedRowId={setSelectedRowId}
              setShow={setShow}
              isShow={isShow}
              setDataList={setDataList}
              pageProps={pageProps}
              specialityEdit={specialityEdit}
              setspecialityEdit={setspecialityEdit}
              columnsData={columnsData}
              setColumnsData={setColumnsData}
              setColumnCheck={setColumnCheck}
              isCheckColumn={isCheckColumn}
            />
          </div>
        </div>
      </div>
      {isShow.dtuLogs && (
        <DtuBatchLogs
          visible={isShow}
          setShow={setShow}
          module={"specialty_master"}
          pageProps={pageProps}
        />
      )}
      {isShow.dtuUpload && (
        <UploadDTU
          visible={isShow}
          setShow={setShow}
          title={"Upload Specialties"}
          pageId={"specialty"}
          setAPICall={setAPICall}
        />
      )}
    </div>
  );
};

export default React.memo(Specialty);
