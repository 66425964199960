import React from "react";
import {
    GET_COMP_PLAN_DATA,
    INSERT_COMP_PLAN,
    UPDATE_COMP_PLAN,
    DELETE_COMP_PLAN,
} from "../../api-setup/api-endpoints";
import { getUserCredential } from "../../lib/cookies";
import {
    POSTAPI,
    errStatusHandler,
    generateHeader,
} from "../../api-setup/api-helper";
import { GridColumnDataMapper } from "../../utils/utils";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { setNewMessage } from "../../redux/actions/setting-actions";
import { GetPeriodTypeAPI, GetPeriodsDataList } from "../../lib/common-service";

export default function Plan() {
    /**
     * start to define default states for common grid functions (list/insert/update/delete)
     */
    const cookiesData = getUserCredential();
    const header = {
        "x-access-token": cookiesData.secretToken,
    };
    const history = useHistory();
    const dispatch = useDispatch();
    const [data, setData] = React.useState([]);
    const [columns, setColumns] = React.useState([]);
    const [dataCount, setDataCount] = React.useState(0);
    const [loadingState, setLoadState] = React.useState({
        isSaveData: undefined,
        isDeleteData: undefined,
    });
    const [initialLoading, setLoadingState] = React.useState(false);
    const [editData, setEditData] = React.useState("");
    const [confirmationPopup, setConfirmationPopup] = React.useState({
        title: "Delete",
        desc: "Are you sure you want to delete this record?",
        isVisible: false,
        type: "",
        actionBtn: [],
        data: {},
        action: "Delete",
    });
    const [originalData, setOriginalData] = React.useState([]);
    const [isAPICalling, setAPICallingState] = React.useState(false);
    const [isRefreshLoading, setRefreshLoading] = React.useState(false);
    const [loading, setLoadingStateSearch] = React.useState({
        isSearchLoader: false,
        updateColumn: false
    });
    /**
     * end to define default states for common grid functions (list/insert/update/delete)
     */

    /**
     * start to define default states for periods and period types
     */
    const [periodsTypeFields, setPeriodTypeFields] = React.useState({
        periodTypeValue: {
            in_period_mstr_key: "",
            in_period_start_date: "",
            in_period_end_date: "",
        },
        periodTypeData: [],
        isPeriodTypeDataLoading: true,
    });
    const [originPeriodType, setOriginPeriodType] = React.useState([]);

    const [periodsFields, setPeriodFields] = React.useState({
        periodsValue: {
            id: "",
            text: "",
        },
        periodsData: [],
        isPeriodDataLoading: true,
    });
    const [originPeriod, setOriginPeriod] = React.useState([]);
    /**
     * end to define default states for periods and period types
     */

    const getPeriodTypesData = async () => {
        let periodTypeData = {
            in_filter: "AND a.period_type_hierarchy_level IS NOT NULL",
            in_sort_number: null,
            in_sort_order: null,
        };

        let res = await GetPeriodTypeAPI(periodTypeData, dispatch, history);

        if (res.length > 0) {
            let tempArray = [];

            res.map((item) => {
                let obj = {};
                obj.text = item.period_type_name;
                obj.id = item.period_type_mstr_key;

                tempArray.push(obj);
                return 0;
            });

            setOriginPeriodType(tempArray);

            //get period data from period type
            getPeriodsData(tempArray[0].id);

            setPeriodTypeFields({
                ...periodsTypeFields,
                periodTypeData: tempArray,
                periodTypeValue: tempArray[0],
                isPeriodTypeDataLoading: false,
            });
        } else {
            setPeriodTypeFields({
                ...periodsTypeFields,
                isPeriodTypeDataLoading: false,
            });
        }
    };

    const getPeriodsData = async (id) => {
        setPeriodFields({
            ...periodsFields,
            isPeriodDataLoading: true,
        });

        let periodsDataReq = {
            in_tenant_id: cookiesData.out_tenant_id,
            in_filter: `AND a.period_type_mstr_key = ${id}`,
            in_sort_number: null,
            in_sort_order: null,
            in_page_row_offset: 0,
            in_page_rows: 100,
            in_sort_number_concat:
                "a.period_end_date DESC, a.period_level_order DESC",
        };

        let resPeriods = await GetPeriodsDataList(
            periodsDataReq,
            dispatch,
            history
        );

        if (resPeriods.length > 0) {
            let tempArray = [];

            resPeriods.map((item) => {
                let obj = {};

                obj.text = item.period_name;
                obj.id = item.period_mstr_key;
                obj.in_period_start_date = item.period_start_date;
                obj.in_period_end_date = item.period_end_date;

                tempArray.push(obj);

                return 0;
            });

            setOriginPeriod(tempArray);

            setPeriodFields({
                ...periodsFields,
                periodsData: tempArray,
                periodsValue: tempArray[0],
                isPeriodDataLoading: false,
            });
        } else {
            setPeriodFields({
                periodsValue: { id: "", text: "" },
                periodsData: [],
                isPeriodDataLoading: false,
            });
        }
    };

    const getListDataAsync = (listData) => {
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                GET_COMP_PLAN_DATA,
                null,
                header,
                listData,
                dispatch
            ).then((res) => {
                if (res) {
                    if (res.data.out_parameters.out_ret_code > 0) {
                        setLoadingState(true);
                        errStatusHandler(res, history, dispatch);
                    }
                    setAPICallingState(false);
                    setLoadingState(true);
                    setEditData("");
                    setConfirmationPopup({
                        ...confirmationPopup,
                        isVisible: false,
                        type: "",
                        actionBtn: [],
                        data: {},
                        action: "",
                    });
                    let userData = JSON.parse(
                        JSON.stringify(res.data.row_result)
                    );
                    if (listData.in_load_type === "grid-reload") {
                        const columData = GridColumnDataMapper(
                            res.data.out_parameters.out_column_xml
                        );
                        columData.map((cItem) => {
                            cItem.minWidth = 210;
                            return 0;
                        });
                        setColumns(columData);
                    }
                    setData(userData);
                    userData.map((item, idx) => {
                        item.rowId = idx;
                        item.selected = false;
                        item.defaultSelected = idx === 0;
                        item.rowCount = userData.length;
                        return 0;
                    });

                    setOriginalData(userData);
                    setDataCount(res.data.out_parameters.out_total_rows);
                    setRefreshLoading(false);
                    setLoadingStateSearch({
                        ...loading,
                        isSearchLoader: false,
                    });
                    resolve(userData);
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    
    const addDataAsync = async (bodyData) => {
        setRefreshLoading(true);
    
            return new Promise(function (resolve, reject) {
                POSTAPI(false, INSERT_COMP_PLAN, null, header, bodyData, dispatch).then(
                    (res) => {
                        setRefreshLoading(false);
                        if (res.data.out_parameters.out_ret_code > 0) {
                            setLoadState({ ...loadingState, isSaveData: false });
                            dispatch(
                                setNewMessage({
                                    message: res.data.out_parameters.out_error_msg,
                                    type: "Error",
                                    show: true,
                                })
                            );
                        } else {
                            setLoadState({ ...loadingState, isSaveData: true });
                            setTimeout(function () {
                                resolve("success");
                            });
                        }
                    }
                );
            })
                .then((res) => {
                    return res;
                })
                .catch((res) => {
                    if (res.data.out_parameters.out_ret_code > 0) {
                        dispatch(
                            setNewMessage({
                                message: res.data.out_parameters.out_error_msg,
                                type: "Error",
                                show: true,
                            })
                        );
                        errStatusHandler(res, history, dispatch);
                    }
                });
        };

    const deleteDataAsync = async (bodyData) => {
        setRefreshLoading(true);
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                DELETE_COMP_PLAN,
                null,
                header,
                bodyData,
                dispatch
            ).then((res) => {
                const totalCompPlan = bodyData.in_version_data.length;
                const unSucessFullCount = res.data.successCount;
                setRefreshLoading(false);
                if (res.data.out_parameters.out_ret_code > 0) {
                    let msg = res.data.out_parameters.out_error_msg;
                    if (totalCompPlan !== unSucessFullCount) {
                        msg =
                            unSucessFullCount +
                            " Comp Plan (" +
                            res.data.successName +
                            ") out of " +
                            totalCompPlan +
                            " as " +
                            msg;

                        setLoadState({ ...loadingState, isDeleteData: true });
                        setTimeout(function () {
                            resolve("success");
                        });
                    } else {
                        setLoadState({ ...loadingState, isDeleteData: false });
                        setTimeout(function () {
                            resolve("error");
                        });
                    }

                    setConfirmationPopup({
                        ...confirmationPopup,
                        isVisible: false,
                        type: "",
                        actionBtn: [],
                        data: {},
                        action: "",
                    });
                    dispatch(
                        setNewMessage({
                            message: msg,
                            type: "Error",
                            show: true,
                        })
                    );
                } else {
                    setConfirmationPopup({
                        ...confirmationPopup,
                        isVisible: false,
                        type: "",
                        actionBtn: [],
                        data: {},
                        action: "",
                    });
                    setLoadState({ ...loadingState, isDeleteData: true });
                    setTimeout(function () {
                        resolve("success");
                    });
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    const exportDataAsync = (bodyData) => {
        setRefreshLoading(true);
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                GET_COMP_PLAN_DATA,
                null,
                generateHeader(),
                bodyData,
                dispatch
            ).then((res) => {
                setRefreshLoading(false);
                if (
                    res &&
                    (res.data.out_parameters.out_ret_code === 0 ||
                        res.data.out_parameters.out_ret_code === 2)
                ) {
                    resolve(res.data.row_result);
                } else {
                    dispatch(
                        setNewMessage({
                            message: res?.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    resolve([]);
                    errStatusHandler(res, history, dispatch);
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    const updateDataAsync = (bodyData) => {
        setRefreshLoading(true);
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                UPDATE_COMP_PLAN,
                null,
                generateHeader(),
                bodyData,
                dispatch
            ).then((res) => {
                setRefreshLoading(false);
                if (res && res.data.out_parameters.out_ret_code === 0) {
                    resolve(true);
                } else {
                    dispatch(
                        setNewMessage({
                            message: res?.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    resolve([]);
                    errStatusHandler(res, history, dispatch);
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    const getListDataLengthAsync = (listData) => {
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                GET_COMP_PLAN_DATA,
                null,
                header,
                listData,
                dispatch
            ).then((res) => {
                if (res) {
                    let userData = [];
                    userData = JSON.parse(JSON.stringify(res.data.row_result));
                    resolve(userData);
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    return [
        {
            data,
            columns,
            dataCount,
            setData,
            editData,
            originalData,
            isAPICalling,
            setEditData,
            confirmationPopup,
            setConfirmationPopup,
            initialLoading,
            setAPICallingState,
            loadingState,
            setLoadState,
            setOriginalData,
            setRefreshLoading,
            isRefreshLoading,
            originPeriodType,
            periodsTypeFields,
            setPeriodTypeFields,
            originPeriod,
            periodsFields,
            setPeriodFields,
            setLoadingStateSearch,
            loading,
            setColumns
        },
        {
            getListDataAsync,
            addDataAsync,
            deleteDataAsync,
            updateDataAsync,
            getPeriodTypesData,
            getPeriodsData,
            exportDataAsync,
            getListDataLengthAsync
        },
    ];
}
