import React from "react";
import TmplAuth from "src/template/tmplAuth";
import AuthenticationForm from "./form/authenticationForm";
import { AuthContent } from "./common/AuthContent";

const Authentication = () => {
    return (
        <TmplAuth
            form={<AuthenticationForm />}
            pageContent={<AuthContent label="First verify it's you" />}
        />
    );
};

export default React.memo(Authentication);
