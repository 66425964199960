import React from "react";
import {
    GET_USER_ADMIN_DATA,
    ADD_USER_DATA,
    UPDATE_USER_DATA,
    DELETE_USER,
    SEND_USER_POPUP_EMAIL,
    RESEND_EMAIL,
    IMPERSONATE_USER,
} from "../../api-setup/api-endpoints";
import { getUserCredential } from "../../lib/cookies";
import {
    POSTAPI,
    errStatusHandler,
    generateHeader,
} from "../../api-setup/api-helper";
import { GridColumnDataMapper } from "../../utils/utils";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { setNewMessage } from "../../redux/actions/setting-actions";
import { DEFAULT_SORT } from "../../constants/grid";
import { GeUserStatusTypeAPI, GeDbRoleAPI } from "../../lib/common-service";

export default function User() {
    const cookiesData = getUserCredential();
    const header = {
        "x-access-token": cookiesData.secretToken,
    };
    const history = useHistory();
    const dispatch = useDispatch();
    const [data, setData] = React.useState([]);
    const [columns, setColumns] = React.useState([]);
    const [dataCount, setDataCount] = React.useState(0);
    const [loadingState, setLoadState] = React.useState({
        isSaveData: undefined,
        isDeleteData: undefined,
    });
    const [initialLoading, setLoadingState] = React.useState(false);
    const [editData, setEditData] = React.useState("");
    const [confirmationPopup, setConfirmationPopup] = React.useState({
        title: "Delete",
        desc: "Are you sure you want to delete this record?",
        isVisible: false,
        type: "",
        actionBtn: [],
        data: {},
        action: "Delete",
    });
    const [originalData, setOriginalData] = React.useState([]);
    const [isAPICalling, setAPICallingState] = React.useState(false);
    const [isRefreshLoading, setRefreshLoading] = React.useState(false);

    const [originUserStatusType, setOriginUserStatusType] = React.useState([]);
    const [originDbRole, setOriginDbRole] = React.useState([]);

    const getListDataAsync = (listData) => {
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                GET_USER_ADMIN_DATA,
                null,
                header,
                listData,
                dispatch
            ).then((res) => {
                if (res) {
                    if (res.data.out_parameters.out_ret_code > 0) {
                        setLoadingState(true);
                        errStatusHandler(res, history, dispatch);
                    }
                    setAPICallingState(false);
                    setLoadingState(true);
                    setEditData("");
                    setConfirmationPopup({
                        ...confirmationPopup,
                        isVisible: false,
                        type: "",
                        actionBtn: [],
                        data: {},
                        action: "",
                    });
                    let userData = JSON.parse(
                        JSON.stringify(res.data.row_result)
                    );

                    if (listData.in_load_type === "grid-reload") {
                        const columData = GridColumnDataMapper(
                            res.data.out_parameters.out_columns_xml,
                            "",
                            true
                        );

                        columData.map((cItem) => {
                            cItem.minWidth = 210;
                            cItem.show =
                                cItem.field === "user_key" ||
                                cItem.field === "version"
                                    ? false
                                    : cItem.show;
                            return 0;
                        });

                        setColumns(columData);
                    }
                    userData.map((item, idx) => {
                        item.rowId = idx;
                        item.selected = false;
                        return 0;
                    });
                    setData(userData);
                    setOriginalData(userData);
                    setDataCount(res.data.out_parameters.out_total_rows);
                    setRefreshLoading(false);
                    resolve(userData);
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    const getUserStatusTypeDataAsync = async () => {
        let returnArray = [];

        let procsParam = {
            in_filter: null,
            in_sort_number: DEFAULT_SORT.number,
            in_sort_order: "ASC",
        };

        let res = await GeUserStatusTypeAPI(procsParam, dispatch, history);

        res.map((item) => {
            returnArray.push({
                text: item.status_type_name,
                value: item.status_type_key,
            });
            return 0;
        });

        setOriginUserStatusType(returnArray);
    };

    const getDbRoleDataAsync = async () => {
        let returnArray = [];

        let procsParam = {
            in_filter: null,
            in_sort_number: 3,
            in_sort_order: "ASC",
            in_page_row_offset: 0,
            in_page_rows: 9999,
        };

        let res = await GeDbRoleAPI(procsParam, dispatch, history);

        res.map((item) => {
            returnArray.push({
                text: item.db_role_name,
                value: item.db_role_key,
                role_desc: item.db_role_desc,
            });
            return 0;
        });

        setOriginDbRole(returnArray);
    };

    const addDataAsync = (bodyData) => {
        setRefreshLoading(true);
        POSTAPI(false, ADD_USER_DATA, null, header, bodyData, dispatch).then(
            (res) => {
                setRefreshLoading(false);
                if (res.status) {
                    if (res.data.out_parameters.out_ret_code > 0) {
                        dispatch(
                            setNewMessage({
                                message: res.data.out_parameters.out_error_msg,
                                type: "Error",
                                show: true,
                            })
                        );
                        errStatusHandler(res, history, dispatch);
                    } else {
                        setLoadState({ ...loadingState, isSaveData: true });
                    }
                }
            }
        );
    };

    const deleteDataAsync = async (bodyData) => {
        setRefreshLoading(true);
        return new Promise(function (resolve, reject) {
            POSTAPI(false, DELETE_USER, null, header, bodyData, dispatch).then(
                (res) => {
                    const totalUser = bodyData.in_user_id.length;
                    const unSucessFullCount = res.data.successCount;
                    setRefreshLoading(false);

                    if (res.data.out_parameters.out_ret_code > 0) {
                        let msg = res.data.out_parameters.out_error_msg;
                        if (totalUser !== unSucessFullCount) {
                            msg =
                                unSucessFullCount +
                                " User (" +
                                res.data.successName +
                                ") out of " +
                                totalUser +
                                " as " +
                                msg;

                            setLoadState({
                                ...loadingState,
                                isDeleteData: true,
                            });
                            setTimeout(function () {
                                resolve("success");
                            });
                        } else {
                            setLoadState({
                                ...loadingState,
                                isDeleteData: false,
                            });
                            setTimeout(function () {
                                resolve("error");
                            });
                        }

                        setConfirmationPopup({
                            ...confirmationPopup,
                            isVisible: false,
                            type: "",
                            actionBtn: [],
                            data: {},
                            action: "",
                        });
                        dispatch(
                            setNewMessage({
                                message: msg,
                                type: "Error",
                                show: true,
                            })
                        );
                    } else {
                        setConfirmationPopup({
                            ...confirmationPopup,
                            isVisible: false,
                            type: "",
                            actionBtn: [],
                            data: {},
                            action: "",
                        });
                        setLoadState({ ...loadingState, isDeleteData: true });
                        setTimeout(function () {
                            resolve("success");
                        });
                    }
                }
            );
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    const updateUserDataAsync = (bodyData) => {
        setRefreshLoading(true);
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                UPDATE_USER_DATA,
                null,
                generateHeader(),
                bodyData,
                dispatch
            ).then((res) => {
                setRefreshLoading(false);
                if (res && res.data.out_parameters.out_ret_code === 0) {
                    resolve(true);
                } else {
                    dispatch(
                        setNewMessage({
                            message: res?.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    resolve([]);
                    errStatusHandler(res, history, dispatch);
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    const exportDataAsync = (bodyData) => {
        setRefreshLoading(true);
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                GET_USER_ADMIN_DATA,
                null,
                generateHeader(),
                bodyData,
                dispatch
            ).then((res) => {
                setRefreshLoading(false);
                if (
                    res &&
                    (res.data.out_parameters.out_ret_code === 0 ||
                        res.data.out_parameters.out_ret_code === 2)
                ) {
                    resolve(res.data.row_result);
                } else {
                    dispatch(
                        setNewMessage({
                            message: res?.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    resolve([]);
                    errStatusHandler(res, history, dispatch);
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    const sendEmailAsync = (bodyData) => {
        setRefreshLoading(true);
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                SEND_USER_POPUP_EMAIL,
                null,
                generateHeader(),
                bodyData,
                dispatch
            ).then((res) => {
                setRefreshLoading(false);
                const outParams = res?.data.out_parameters
                    ? res?.data.out_parameters
                    : res?.data?.data?.out_parameters;

                let resolveStatus = {
                    is_success: true,
                    is_update: outParams.out_update_code
                        ? outParams.out_update_code
                        : 0,
                };
                if (outParams.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: "User not found for selected criteria.",
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);

                    resolveStatus.is_success =
                        outParams.out_error_msg.includes("success");
                } else 
                if (outParams.out_ret_code == 0) {
                    dispatch(
                        setNewMessage({
                            message: res?.msg,
                            type: "Success",
                            show: true,
                        })
                    );
                }
                resolve(resolveStatus);
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    const resendEmailAsync = (bodyData) => {
        setRefreshLoading(true);
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                RESEND_EMAIL,
                null,
                generateHeader(),
                bodyData,
                dispatch
            ).then((res) => {
                setRefreshLoading(false);
                const outParams = res?.data.out_parameters
                    ? res?.data.out_parameters
                    : res?.data?.data?.out_parameters;

                let resolveStatus = {
                    is_success: true,
                    is_update: outParams.out_update_code
                        ? outParams.out_update_code
                        : 0,
                };
                if (outParams.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: outParams.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);

                    resolveStatus.is_success =
                        outParams.out_error_msg.includes("success");
                } else {
                    if(outParams.out_ret_code == 0){
                        dispatch(
                            setNewMessage({
                                message: res?.msg,
                                type: "Success",
                                show: true,
                            })
                        );
                    }
                }
                resolve(resolveStatus);
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    const impersonateAsync = async (bodyData) => {
        setRefreshLoading(true);

        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                IMPERSONATE_USER,
                null,
                generateHeader(),
                bodyData,
                dispatch
            ).then((res) => {
                setRefreshLoading(false);
                const outParams = res?.data.out_parameters
                    ? res?.data.out_parameters
                    : res?.data?.data?.out_parameters;

                if (outParams?.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: outParams?.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
                resolve(outParams);
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res?.data?.out_parameters?.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res?.data?.out_parameters?.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });

        /*
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                IMPERSONATE_USER,
                null,
                generateHeader(),
                bodyData,
                dispatch
            ).then((res) => {
                setRefreshLoading(false);
                const outParams = res?.data.out_parameters
                    ? res?.data.out_parameters
                    : res?.data?.data?.out_parameters;

                if (outParams?.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: outParams?.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
                resolve(outParams);
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
            */
    };

    return [
        {
            data,
            columns,
            dataCount,
            setData,
            setColumns,
            editData,
            originalData,
            isAPICalling,
            setEditData,
            confirmationPopup,
            setConfirmationPopup,
            initialLoading,
            setAPICallingState,
            loadingState,
            setLoadState,
            setOriginalData,
            setRefreshLoading,
            isRefreshLoading,
            originUserStatusType,
            originDbRole,
        },
        {
            getListDataAsync,
            addDataAsync,
            deleteDataAsync,
            exportDataAsync,
            updateUserDataAsync,
            getUserStatusTypeDataAsync,
            getDbRoleDataAsync,
            sendEmailAsync,
            resendEmailAsync,
            impersonateAsync,
        },
    ];
}
