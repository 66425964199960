import React, { useCallback } from "react";
import { ChartSeriesItem } from "@progress/kendo-react-charts";
import { shortLabel } from "src/lib/widget.util";

const columnChartConfig = {
    type: "column",
    visibleInLegend: true,
    tooltip: {
        visible: true,
        format: "N2",
        render: (e) => {
            const formattedValue = new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }).format(e?.point?.value);

            return `${e?.point?.category} - ${e?.point?.series?.name} : ${formattedValue}`;
        },
    },
    labels: {
        font: "08px Poppins, sans-serif",
        content: shortLabel,
    },
};

export const ColumnParserHook = () => {
    const renderColumn = useCallback(({ chartValue, isStacked }) => {
        return chartValue.map((item, idx) => (
            <ChartSeriesItem
                key={idx}
                data={item?.data || 0}
                name={item?.name || ""}
                stack={isStacked}
                visible={item.visible}
                color={item?.color || ""}
                {...columnChartConfig}
            />
        ));
    }, []);
    return {
        renderColumn,
    };
};
