import { orderBy } from "@progress/kendo-data-query";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getUserCredential } from "src/lib/cookies";
import { useHistory } from "react-router-dom";
import { providerPlanStatementSelector } from "src/redux/selectors/custom-selector";
import {
    setProviderGroupLoadingAction,
    setProviderGroupsDataAction,
    setProviderKPIPaymentSummaryAction,
    setProviderLoadingAction,
    setProviderPlanDataAction,
    setProviderPlanLoadingAction,
    setProviderPlanPeriodDataAction,
    setProviderPlanPeriodLoadingAction,
    setProviderProvidersDataAction,
    setProviderSection2DataAction,
    setProviderShowSection1,
    setProviderSummaryHeaderAction,
    setSelectedProviderCompPlanAction,
    setSelectedProviderGroupsAction,
    setSelectedProviderPeriodAction,
    setSelectedProviderProviderAction,
} from "src/redux/actions/providerplan-actions";
import { errStatusHandler, POSTAPI } from "src/api-setup/api-helper";
import { setNewMessage } from "src/redux/actions/setting-actions";
import { GridColumnDataMapper } from "src/utils/utils";
import { v4 as uuidV4 } from "uuid";
import { GetSystemConfigDataByCode } from "src/lib/common-service";
import { transformDateTime } from "src/lib/date-time.util";

const useProviderPlanStatementService = () => {
    const cookiesData = getUserCredential();
    const header = {
        "x-access-token": cookiesData.secretToken,
    };
    const dispatch = useDispatch();
    const history = useHistory();

    const {
        selectedCompPlan,
        selectedCompPlanPeriod,
        selectedGroups,
        selectedProviders,
        showReportResults,
    } = useSelector(providerPlanStatementSelector, shallowEqual);

    const getCompPlan = async () => {
        let reqData = {
            in_tenant_id: cookiesData.out_tenant_id,
            in_user_role_filter: ">0",
            in_user_key: cookiesData.out_user_id,
        };

        dispatch(setProviderPlanLoadingAction(true));

        try {
            const res = await POSTAPI(
                false,
                "/reports/select-provider-comp-report-filter-comp-plan",
                null,
                header,
                reqData,
                dispatch
            );

            let value = null;
            let data = [];

            if (res.data.out_parameters.out_ret_code > 0) {
                errStatusHandler(res, history, dispatch);
            } else {
                value = selectedCompPlan || res.data.row_result[0];
                data = res.data.row_result;
                dispatch(setProviderPlanDataAction(data));
                dispatch(setSelectedProviderCompPlanAction(value));
            }
        } catch (error) {
            dispatch(setSelectedProviderCompPlanAction(null));
            dispatch(setProviderPlanDataAction([]));
        } finally {
            dispatch(setProviderPlanLoadingAction(false));
        }
    };

    const fetchCompPlanPeriods = async (compPlan) => {
        if (!compPlan) {
            dispatch(setSelectedProviderPeriodAction(null));
            dispatch(setProviderPlanPeriodDataAction([]));
            return;
        }
        let reqData = {
            in_tenant_id: cookiesData.out_tenant_id,
            in_filter: null,
            in_user_role_filter: "abc",
            in_user_key: cookiesData.out_user_id,
            in_comp_plan_key: compPlan?.comp_plan_key,
        };
        dispatch(setProviderPlanPeriodLoadingAction(true));

        try {
            const res = await POSTAPI(
                false,
                "/reports/select-provider-comp-report-filter-comp-plan-period",
                null,
                header,
                reqData,
                dispatch
            );

            let value = null;
            let data = [];

            if (res.data.out_parameters.out_ret_code > 0) {
                errStatusHandler(res, history, dispatch);
            } else {
                if (!showReportResults) {
                    value = res.data.row_result.length
                        ? res.data.row_result[0]
                        : null;
                } else {
                    const filterItem = res.data.row_result.find(
                        (it) =>
                            it.period_key === selectedCompPlanPeriod?.period_key
                    );
                    value = filterItem || res.data.row_result[0];
                }

                data = res.data.row_result;
            }

            dispatch(setProviderPlanPeriodDataAction(data));
            dispatch(setSelectedProviderPeriodAction(value));
        } catch (error) {
            dispatch(setSelectedProviderPeriodAction(null));
            dispatch(setProviderPlanPeriodDataAction([]));
        } finally {
            dispatch(setProviderPlanPeriodLoadingAction(false));
        }
    };

    const fetchGroups = async () => {
        if (!selectedCompPlanPeriod) {
            dispatch(setSelectedProviderGroupsAction([]));
            dispatch(setProviderGroupsDataAction([]));
            return;
        }
        let reqData = {
            in_tenant_id: cookiesData.out_tenant_id,
            in_filter: null,
            in_comp_plan_key: selectedCompPlan?.comp_plan_key,
            in_period_key: selectedCompPlanPeriod?.period_key,
            in_user_key: cookiesData.out_user_id,
            in_user_role_type_abbr: cookiesData.out_db_role_type_abbr,
        };
        dispatch(setProviderGroupLoadingAction(true));

        try {
            const res = await POSTAPI(
                false,
                "/reports/select-provider-comp-report-filter-group",
                null,
                header,
                reqData,
                dispatch
            );

            let value = [];
            let data = [];

            if (res.data.out_parameters.out_ret_code > 0) {
                errStatusHandler(res, history, dispatch);
            } else {
                data.unshift({ title_key: 0, title_name: "Select All" });
                // value = [...data];
                if (!showReportResults) {
                    value = [data[0]];
                } else {
                    const selectedKeys = selectedGroups.map(
                        (it) => it.title_key
                    );
                    const filteredArray = res.data.row_result.filter((value) =>
                        selectedKeys.includes(value.title_key)
                    );
                    value =
                        filteredArray.length > 0 ? filteredArray : [data[0]];
                }

                data = [...data, ...res.data.row_result];
            }

            dispatch(setProviderGroupsDataAction(data));
            dispatch(setSelectedProviderGroupsAction(value));
        } catch (error) {
            dispatch(setSelectedProviderGroupsAction([]));
            dispatch(setProviderGroupsDataAction([]));
        } finally {
            dispatch(setProviderGroupLoadingAction(false));
        }
    };

    const getAutoHideSection1 = async () => {
        let reqData = {
            in_tenant_id: cookiesData.out_tenant_id,
            pref_code: ["auto_hide_report_sec1"],
        };
        let res = await GetSystemConfigDataByCode(reqData, history, dispatch);
        if (res) {
            let flag = res.auto_hide_report_sec1 === "0" ? true : false;
            dispatch(setProviderShowSection1(flag));
        }
    };

    const fetchProviders = async () => {
        if (selectedGroups.length === 0) {
            dispatch(setSelectedProviderProviderAction([]));
            dispatch(setProviderProvidersDataAction([]));
            return;
        }
        let reqData = {
            in_tenant_id: cookiesData.out_tenant_id,
            in_filter: null,
            in_comp_plan_key: selectedCompPlan?.comp_plan_key,
            in_period_key: selectedCompPlanPeriod?.period_key,
            in_user_key: cookiesData.out_user_id,
            in_user_role_type_abbr: cookiesData.out_db_role_type_abbr,
            in_user_role_filter: "> 0",
            in_group_key: selectedGroups.map((it) => it.title_key).join(","),
            in_encrypt_key: cookiesData.out_acct_encrypt_key,
        };
        dispatch(setProviderLoadingAction(true));

        try {
            const res = await POSTAPI(
                false,
                "/reports/select-provider-comp-report-filter-provider",
                null,
                header,
                reqData,
                dispatch
            );

            let data = [];
            let value = [];

            if (res.data.out_parameters.out_ret_code > 0) {
                errStatusHandler(res, history, dispatch);
            } else {
                data = res.data.row_result;
                data = [{ provider_key: 0, provider_name: "Select All" }, ...res.data.row_result];
              
                if (!showReportResults) {
                    value = [data[0]];
                } else {
                    const selectedKeys = selectedProviders.map(
                        (it) => it.provider_key
                    );
                    const filteredArray = res.data.row_result.filter((value) =>
                        selectedKeys.includes(value.provider_key)
                    );
                    value =
                        filteredArray.length > 0 ? filteredArray : [data[0]];
                }
            }
            if (cookiesData.out_db_role_type_abbr === "prv1") {
                value = [res.data.row_result[0]];
                data = data.filter((it) => it.provider_key !== 0);
            }

          
            dispatch(setProviderProvidersDataAction(data));
            dispatch(setSelectedProviderProviderAction(value));
        } catch (error) {
            dispatch(setSelectedProviderProviderAction([]));
            dispatch(setProviderProvidersDataAction([]));
        } finally {
            dispatch(setProviderLoadingAction(false));
        }
    };

    const fetchSummaryHeaderProvider = async () => {
        let reqData = {
            in_tenant_id: cookiesData.out_tenant_id,
            in_comp_plan_key: selectedCompPlan.comp_plan_key,
            in_period_key: selectedCompPlanPeriod.period_key,
            in_user_key: cookiesData.out_user_id,
            in_user_role_type_abbr: cookiesData.out_db_role_type_abbr,
            in_group_keys: selectedGroups.map((it) => it.title_key).join(","),
            in_provider_keys: selectedProviders
                .map((it) => it.provider_key)
                .join(","),
            in_encrypt_key: cookiesData.out_acct_encrypt_key,
        };

        try {
            const res = await POSTAPI(
                false,
                "/reports/select-provider-comp-report-provider-details",
                null,
                header,
                reqData,
                dispatch
            );
            let data = [];
            let columns = [];

            if (res.data.out_parameters.out_columns_xml) {
                columns = GridColumnDataMapper(
                    res.data.out_parameters.out_columns_xml,
                    null
                );
                columns = columns.map((it) => ({ ...it, filterable: false }));
            }

            if (res.data.out_parameters.out_ret_code > 0) {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                errStatusHandler(res, history, dispatch);
            }

            columns.push({
                id: uuidV4(),
                title: "CONTRACT TYPE",
                type: "string",
                field: "contract_type",
                filterable: false,
                encrypted: false,
                show: true,
                childCols: [],
            });

            columns.push({
                id: uuidV4(),
                title: "UTILIZATION",
                type: "string",
                field: null,
                filterable: false,
                encrypted: false,
                show: true,
                childCols: [
                    {
                        id: uuidV4(),
                        title: "ACADEMIC",
                        type: "number",
                        field: "utilization_academic",
                        filterable: false,
                        reorderable: false,
                        encrypted: false,
                        show: true,
                    },
                    {
                        id: uuidV4(),
                        title: "ADMIN",
                        type: "number",
                        field: "utilization_admin",
                        filterable: false,
                        reorderable: false,
                        encrypted: false,
                        show: true,
                    },
                    {
                        id: uuidV4(),
                        title: "CLINICAL",
                        type: "number",
                        field: "utilization_clinical",
                        filterable: false,
                        reorderable: false,
                        encrypted: false,
                        show: true,
                    },
                    {
                        id: uuidV4(),
                        title: "RESEARCH",
                        type: "number",
                        field: "utilization_research",
                        filterable: false,
                        reorderable: false,
                        encrypted: false,
                        show: true,
                    },
                ],
            });

            data = res.data.row_result.map((it) => {
                if (it.hire_date) {
                    return {
                        ...it,
                        hire_date: transformDateTime({
                            date: it.hire_date,
                            format: "MM/DD/YYYY",
                        }),
                    };
                }
                return it;
            });
            dispatch(setProviderSummaryHeaderAction({ columns, data: data }));
        } catch (error) {}
    };

    const fetchProviderKpiPaymentSummary = async (payload) => {
        let reqData = {
            in_filter: payload.in_filter,
            in_sort_number: payload.in_sort_number,
            in_sort_order: payload.in_sort_order,
            in_tenant_id: cookiesData.out_tenant_id,
            in_comp_plan_key: selectedCompPlan.comp_plan_key,
            in_period_key: selectedCompPlanPeriod.period_key,
            in_user_key: cookiesData.out_user_id,
            in_user_role_type_abbr: cookiesData.out_db_role_type_abbr,
            in_group_keys: selectedGroups.map((it) => it.title_key).join(","),
            in_provider_keys: selectedProviders
                .map((it) => it.provider_key)
                .join(","),
            in_encrypt_key: cookiesData.out_acct_encrypt_key,
        };

        try {
            const res = await POSTAPI(
                false,
                "/reports/select-provider-comp-report-kpi-payment-summary",
                null,
                header,
                reqData,
                dispatch
            );
            let data = [];
            let columns = [];

            if (res.data.out_parameters.out_columns_xml) {
                columns = GridColumnDataMapper(
                    res.data.out_parameters.out_columns_xml,
                    null
                );
            }
            if (res.data.out_parameters.out_ret_code > 0) {
                errStatusHandler(res, history, dispatch);
            }
            data = res.data.row_result;
            dispatch(
                setProviderKPIPaymentSummaryAction({ columns, data: data })
            );
        } catch (error) {}
    };

    const fetchReportSummaryColumnHeaders = async () => {
        let reqData = {
            in_period_key: selectedCompPlanPeriod.period_key,
        };
        try {
            const res = await POSTAPI(
                false,
                "/reports/select-report-unpub-summary-column-headers",
                null,
                header,
                reqData,
                dispatch
            );
            let data = [];

            if (res.data.out_parameters.out_ret_code > 0) {
                errStatusHandler(res, history, dispatch);
            }
            data = res.data.row_result.map((it) => ({ ...it, show: true }));
            dispatch(setProviderSection2DataAction({ columns: data }));
        } catch (error) {}
    };

    const fetchProviderKpiPaymentDetails = async () => {
        let reqData = {
            in_tenant_id: cookiesData.out_tenant_id,
            in_comp_plan_key: selectedCompPlan.comp_plan_key,
            in_period_key: selectedCompPlanPeriod.period_key,
            in_user_key: cookiesData.out_user_id,
            in_user_role_type_abbr: cookiesData.out_db_role_type_abbr,
            in_group_keys: selectedGroups.map((it) => it.title_key).join(","),
            in_provider_keys: selectedProviders
                .map((it) => it.provider_key)
                .join(","),
            in_encrypt_key: cookiesData.out_acct_encrypt_key,
        };

        try {
            const res = await POSTAPI(
                false,
                "/reports/select-provider-comp-report-kpi-payment-details",
                null,
                header,
                reqData,
                dispatch
            );
            let data = [];

            if (res.data.out_parameters.out_ret_code > 0) {
                errStatusHandler(res, history, dispatch);
            }
            // data = res.data.row_result;
            data = res.data.row_result.map((it) => {
                const newObj = { ...it };
                newObj.period1_value_total =
                    it.result_metadata_tags === "<grand_total>"
                        ? Number(it.period1_value)
                        : 0;
                newObj.period2_value_total =
                    it.result_metadata_tags === "<grand_total>"
                        ? Number(it.period2_value)
                        : 0;
                newObj.period3_value_total =
                      it.result_metadata_tags === "<grand_total>"
                        ? Number(it.period3_value)
                        : 0;
                newObj.period4_value_total =
                    it.result_metadata_tags === "<grand_total>"
                        ? Number(it.period4_value)
                        : 0;
                newObj.period5_value_total =
                   it.result_metadata_tags === "<grand_total>"
                        ? Number(it.period5_value)
                        : 0;
                newObj.period6_value_total =
                     it.result_metadata_tags === "<grand_total>"
                        ? Number(it.period6_value)
                        : 0;
                newObj.period7_value_total =
                      it.result_metadata_tags === "<grand_total>"
                        ? Number(it.period7_value)
                        : 0;
                newObj.period8_value_total =
                      it.result_metadata_tags === "<grand_total>"
                        ? Number(it.period8_value)
                        : 0;
                newObj.period9_value_total =
                     it.result_metadata_tags === "<grand_total>"
                        ? Number(it.period9_value)
                        : 0;
                newObj.period10_value_total =
                     it.result_metadata_tags === "<grand_total>"
                        ? Number(it.period10_value)
                        : 0;
                newObj.period11_value_total =
                      it.result_metadata_tags === "<grand_total>"
                        ? Number(it.period11_value)
                        : 0;
                newObj.period12_value_total =
                     it.result_metadata_tags === "<grand_total>"
                        ? Number(it.period12_value)
                        : 0;
                newObj.period13_value_total =
                      it.result_metadata_tags === "<grand_total>"
                        ? Number(it.period13_value)
                        : 0;
                newObj.period14_value_total =
                      it.result_metadata_tags === "<grand_total>"
                        ? Number(it.period14_value)
                        : 0;
                newObj.period15_value_total =
                      it.result_metadata_tags === "<grand_total>"
                        ? Number(it.period15_value)
                        : 0;
                newObj.period16_value_total =
                    it.result_metadata_tags === "<grand_total>"
                        ? Number(it.period16_value)
                        : 0;
                newObj.period17_value_total =
                      it.result_metadata_tags === "<grand_total>"
                        ? Number(it.period17_value)
                        : 0;

                return newObj;
            });
            const resultdata = orderBy(data, [{field: "kpi_report_order", dir: "asc"}, 
                {field: "kpi_name", dir: "asc"}, 
                {field: "result_name_report_order", dir: "asc"}, {field: "result_name", dir: "asc"}]);
           
            dispatch(setProviderSection2DataAction({ data: resultdata }));
        } catch (error) {}
    };

    return {
        getCompPlan,
        fetchCompPlanPeriods,
        fetchGroups,
        fetchProviders,
        getAutoHideSection1,
        fetchSummaryHeaderProvider,
        fetchProviderKpiPaymentSummary,
        fetchReportSummaryColumnHeaders,
        fetchProviderKpiPaymentDetails,
    };
};

export default useProviderPlanStatementService;
