import React from "react";
import DataTable from "../../components/dataTable";
import { GridColumn as Column } from "@progress/kendo-react-grid";
import { getUserCredential, getTenantCookieData } from "../../lib/cookies";
import { DEFAULT_SORT } from "../../constants/grid";
import { columnMenuProps } from "../../components/customColumnMenu";
import { useDispatch } from "react-redux";
import { getfilterDataString } from "../../utils/utils";
import { Window, WindowActionsBar } from "@progress/kendo-react-dialogs";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { DAYS_OF_WEEK, MONTH_OF_YEAR } from "src/constants/constants";
import { Error, Label, Info } from "@progress/kendo-react-labels";
import useAPI from "./jobDetailService";
import CustomDropdown from "src/components/customDropdown";
import { FieldWrapper, FormElement } from "@progress/kendo-react-form";
import { NumericTextBox, Checkbox } from "@progress/kendo-react-inputs";
import { DatePicker, TimePicker } from "@progress/kendo-react-dateinputs";
import MultiSelectDropdown from "../../components/multiSelect";
import { GetSystemConfigData } from "../../lib/common-service";
import { CellRender, RowRender } from "../../components/renderers";
import MessageSchema from "../../utils/messageSchema";
import { useHistory } from "react-router-dom";
import { transformDateTime } from "src/lib/date-time.util";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

const loadingPanel = (
  <div className="k-loading-mask">
    <span className="k-loading-text">Loading</span>
    <div className="k-loading-image"></div>
    <div className="k-loading-color"></div>
  </div>
);
const initialFormFields = {
  in_start_date: "",
  in_start_time: "",
  in_period_mstr_key: "",
  in_position_keys: [],
  is_recurring_type_job: false,
  in_separation_count: 1,
  is_repet_job: "",
  in_recurring_end_date: "",
  in_day_of_month: 1,
  in_month_of_year: MONTH_OF_YEAR[0],
  compPlanValue: null,
};

const editField = "inEdit";

const JobDetails = (restProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const defaultPageSize = getTenantCookieData("record_per_page");

  const [pageData, setPage] = React.useState({
    skip: 0,
    take: defaultPageSize.recordTake,
  });
  const [pageSize, setPageSize] = React.useState(defaultPageSize);
  const [visible, setVisible] = React.useState(restProps.visible);
  const [data, setData] = React.useState([]);
  const cookiesData = getUserCredential();
  const [exportData, setExportData] = React.useState([]);
  const [formFields, setFormFields] = React.useState({
    ...initialFormFields,
  });
  const [isPostionButton, setIsPostionButton] = React.useState(true);
  const [isCompPostionButton, setIsCompPostionButton] = React.useState(true);

  const [errorObj, setErrorObj] = React.useState({});
  const [isSaveClick, setSaveClick] = React.useState(false);
  const [modal, setModal] = React.useState(true);
  const [stageChange, setStateChange] = React.useState("DEFAULT");
  const [isHeaderChecked, setIsHeaderChecked] = React.useState(false);
  const [resetSort, setResetSort] = React.useState(false);
  const [dataLengthbyFilter, setDataLengthbyFilter] = React.useState(null);
  const [databyFilter, setDatabyFilter] = React.useState([]);
  const [positionPopup, showPositionPopup] = React.useState(false);
  const [isPoistionIntialNote, showisPoistionIntialNote] =
    React.useState(false);

  const currentMonth = new Date().getMonth() + 1; // getMonth() returns 0-11, so we add 1
  const currentMonthMaxDays = MONTH_OF_YEAR.find(
    (month) => month.value === currentMonth
  ).maxDays;
  //state to set and get filter, sort number, sort order
  const [filterData, setFilterData] = React.useState({
    isSort: false,
    isFilter: false,
    data: "",
    in_sort_number: DEFAULT_SORT.number,
    sort_order: DEFAULT_SORT.order,
  });

  const [isRecurringTypeListshow, setisRecurringTypeListshow] =
    React.useState(false);

  const [isRecurringTypeWeekly, setIsRecurringTypeWeekly] =
    React.useState(false);
  const [isRecurringTypeMonthly, setIsRecurringTypeMonthly] =
    React.useState(false);
  const [isRecurringTypeYearly, setIsRecurringTypeYearly] =
    React.useState(false);
  const [selectedDays, setSelectedDays] = React.useState([]);
  const [maxDays, setMaxDays] = React.useState(currentMonthMaxDays);
  const [onOpenDp, setonOpenDp] = React.useState(false);

  /** Use API Hooks */
  const [list, Action] = useAPI();
  const {
    postioncolumns,
    postionData,
    setPostionData,
    postionCount,
    originPeriod,
    periodsFields,
    setPeriodFields,
    originPeriodType,
    periodsTypeFields,
    setPeriodTypeFields,
    originCompPlan,
    compPlanFields,
    setcompPlanFields,
    isCompPlanDisable,
    setIsCompPlanDisable,
    isPostionAPICalling,
    initialPostionLoading,
    setPostionAPICallingState,
    recurringTypeFields,
    setRecurringTypeFields,
    originRecurringType,
    isRefreshLoading,
  } = list;

  // const ModalTitle = () => {
  //   let jobName = restProps.dataItemList.job_name;
  //   return <span>{`Add (${jobName}) Job to Queue `}</span>;

  // };

  const ModalTitle = () => {
    let jobName = restProps.dataItemList.job_name;
    let jobTypeName = restProps.dataItemList.job_type_name;
    return (
      <>
        <span className="custtableflex">
          <div className="custtableitem">{jobName}</div>
          <span className="custtableseprator"> &gt; </span>
          <span> {jobTypeName} Job</span>
        </span>
      </>
    );
  };

  React.useEffect(() => {
    Action.getPeriodTypesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const shouldCallCompPlanData =
      restProps.dataItemList.job_type_code === "stage_hook" &&
      restProps.dataItemList.plan_param === 1 &&
      periodsFields.periodsData.length > 0;

    const shouldCallPositionData =
      (restProps.dataItemList.position_param === 1 ||
        restProps.dataItemList.published_position === 1 ||
        restProps.dataItemList.job_type_code === "email_process") &&
      periodsFields.periodsData.length > 0;

    const postionReqData = {
      in_tenant_id: cookiesData.out_tenant_id,
      in_job_mstr_key: restProps.jobMstrKey,
      [restProps.dataItemList.job_type_code === "email_process"
        ? "in_stagehook_job_mstr_key"
        : "in_comp_plan_mstr_key"]:
        restProps.dataItemList.job_type_code === "email_process"
          ? restProps.dataItemList.stagehook_mstr_key
          : restProps.dataItemList.comp_plan_keys,
      in_period_mstr_key: periodsFields.periodsValue.id,
      in_filter: null,
      in_sort_number: DEFAULT_SORT.number,
      in_sort_order: DEFAULT_SORT.order,
      in_is_published: 0,
      in_acct_encrypt_key: cookiesData.out_acct_encrypt_key,
      job_type_code: restProps.dataItemList.job_type_code,
    };
    setFilterData({
      ...filterData,
      isSort: false,
      data: "",
      isFilter: false,
      in_sort_number: DEFAULT_SORT.number,
      in_sort_order: DEFAULT_SORT.order,
    });
    if (resetSort) {
      setResetSort(false);
    }
    setDataLengthbyFilter(null);
    if (!onOpenDp) {
      if (shouldCallCompPlanData) {
        Action.getCompPlanData()
          .then((compPlanData) => {
            if (shouldCallPositionData) {
              Action.getPostionListDataAsync(postionReqData);
            }
          })
          .catch((error) => {
            // Handle errors from the getCompPlanData API call
          });
      } else if (!shouldCallCompPlanData && shouldCallPositionData) {
        Action.getPostionListDataAsync(postionReqData);
      }
    }
    if (resetSort) {
      setResetSort(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodsFields]);

  React.useEffect(() => {
    setkHeaderCheckbox();
    // const assign = document.querySelector(
    //   "#content-" + restProps.headerAssignmentConfigId
    // );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postionData]);

  const handleRedirect = () => {
    history.push("/pipeline"); // Replace with your target route
  };

  const handleRecurringCheckboxChange = (event) => {
    setFormFields({ ...formFields, is_recurring_type_job: event.value });
    Action.getRecurringTypesData();
    event.value === true
      ? setisRecurringTypeListshow(true)
      : setisRecurringTypeListshow(false);
    if (event.value === false) {
      setFormFields(initialFormFields);
      setIsRecurringTypeMonthly(false);
      setIsRecurringTypeWeekly(false);
      setIsRecurringTypeYearly(false);
      setSelectedDays([]);
    }
  };

  const handleRecurringDateChange = (event) => {
    setFormFields({
      ...formFields,
      in_recurring_end_date: event.target.value,
    });
  };

  const handleDateChange = (event) => {
    setonOpenDp(false);
    setFormFields({ ...formFields, in_start_date: event.target.value });
  };

  const handleFieldChange = (e) => {
    setonOpenDp(false);
    const name = e.name ? e.name : e.target.name;
    setFormFields({
      ...formFields,
      [name]: e.value,
    });
    setErrorObj({ ...errorObj, [name]: "" });

    setFormFields({ ...formFields, [name]: e.value });
  };

  const weekButtonClick = (value) => {
    if (selectedDays.includes(value)) {
      setSelectedDays(selectedDays.filter((day) => day !== value)); // Deselect if already selected
    } else {
      setSelectedDays([...selectedDays, value]); // Select if not already selected
    }
  };

  const isDaySelected = (value) => {
    return selectedDays.includes(value);
  };

  const onClosePopup = () => {
    setVisible(!visible);
    restProps.setIsAddJobtoQueue(false);
    setSaveClick(false);
    setErrorObj({});
    setFormFields(initialFormFields);
  };

  const cancelPositionPopup = () => {
    showPositionPopup(false);
  };
  function getWeekAndDay(dayOfMonth, month) {
    if (dayOfMonth === "") {
      return { weekOfMonth: 0, dayOfWeek: 0 };
    }
    const date = new Date();

    if (month !== null) {
      date.setFullYear(new Date().getFullYear(), month - 1, dayOfMonth);
    } else {
      date.setFullYear(new Date().getFullYear(), date.getMonth(), dayOfMonth);
    }

    const firstDayOfMonth = new Date(
      date.getFullYear(),
      date.getMonth(),
      1
    ).getDay();
    const weekNumber = Math.ceil((dayOfMonth + firstDayOfMonth) / 7);
    const dayOfWeek = DAYS_OF_WEEK[date.getDay()].value;

    return { weekOfMonth: weekNumber, dayOfWeek: dayOfWeek };
  }
  function convertIdsToCommaSeparatedString(inputArray) {
    const idArray = inputArray.map((item) => item.id);
    return idArray.join(",");
  }
  const handleMonthChange = (e) => {
    setonOpenDp(false);
    const selectedMonth = MONTH_OF_YEAR.find(
      (month) => month.value === e.target.value.value
    );
    setFormFields({
      ...formFields,
      in_month_of_year: e.target.value,
      in_day_of_month: 1,
    });
    setMaxDays(selectedMonth ? selectedMonth.maxDays : 31);
  };
  const ShowError = React.useCallback(
    (props) => {
      return <Error>{errorObj[props.name]}</Error>;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errorObj, isSaveClick]
  );
  const { job_type_code, plan_param } = restProps.dataItemList;

  const handleValidation = () => {
    let isValid = true;
    if (formFields.is_recurring_type_job === true) {
      if (formFields.in_start_date === "") {
        isValid = false;
        errorObj.in_start_date = MessageSchema.actionStartDateRequired;
      }
      if (formFields.in_start_time === "") {
        isValid = false;
        errorObj.in_start_time = MessageSchema.actionStartTimeRequired;
      }

      if (formFields.in_recurring_end_date === "") {
        isValid = false;
        errorObj.in_recurring_end_date = MessageSchema.actionEndDateRequired;
      }

      if (formFields.in_start_date > formFields.in_recurring_end_date) {
        isValid = false;
        errorObj.in_recurring_end_date = MessageSchema.maxEndDate;
      }

      if (
        formFields.in_day_of_month === null &&
        (recurringTypeFields.recurringTypeValue.text === "Year" ||
          recurringTypeFields.recurringTypeValue.text === "Month")
      ) {
        isValid = false;
        errorObj.in_day_of_month = MessageSchema.jobMonthNameRequired;
      }
    }

    if (
      plan_param === 1 &&
      job_type_code === "stage_hook" &&
      formFields.compPlanValue === null
    ) {
      isValid = false;
      errorObj.compPlanValue = MessageSchema.jobCompPlanRequired;
    }

    setErrorObj((preVState) => ({ ...preVState, errorObj }));

    return isValid;
  };

  const handleAddJobtoQueue = async () => {
    let valid = handleValidation();

    if (valid) {
      setErrorObj({});
      let postionDataArray = [];
      let finalPositionData =
        databyFilter && databyFilter.length > 0 ? databyFilter : postionData;

      finalPositionData
        .filter((x) => x.is_assign === 1)
        .map((item) => {
          let obj = {};
          obj.id =
            restProps.dataItemList.job_type_code === "email_process"
              ? item.job_queue_param_assign_mstr_key
              : item.org_position_key;
          postionDataArray.push(obj);

          return 0;
        });
      let dataObject = {
        in_tenant_id: cookiesData.out_tenant_id,
        in_filter: "AND system_pref_code = 'sys_time_zone'",
        in_sort_number: null,
        in_sort_order: null,
        in_page_row_offset: null,
        in_page_rows: null,
      };
      const resDataObject = await GetSystemConfigData(dataObject, dispatch);

      const { weekOfMonth, dayOfWeek } = getWeekAndDay(
        formFields.in_day_of_month,
        formFields.in_month_of_year.value !== undefined
          ? formFields.in_month_of_year.value
          : null
      );
      const startDate = formFields.in_start_date;
      const startTime = formFields.in_start_time;
      let formattedDate = "";
      if (startDate !== "" && startTime !== "") {
        const year = startDate.getFullYear();
        const month = startDate.getMonth();
        const date = startDate.getDate();

        // Extract the time parts
        const hours = startTime.getHours();
        const minutes = startTime.getMinutes();
        const seconds = startTime.getSeconds();

        // Combine date and time into a single Date object
        const combinedDate = new Date(
          year,
          month,
          date,
          hours,
          minutes,
          seconds
        );
        formattedDate = transformDateTime({
          date: combinedDate,
          format: "YYYY-MM-DD HH:mm:ss",
        });
      }

      if (
        paginatedData.length > 0 &&
        postionDataArray.length === 0 &&
        restProps.dataItemList.job_type_code === "email_process"
      ) {
        showPositionPopup(true);
      } else {
        const insertRecurringJobData = {
          in_user_id: cookiesData.out_user_id,
          in_tenant_id: cookiesData.out_tenant_id,
          in_job_mstr_key: restProps.jobMstrKey,
          in_period_mstr_key: periodsFields.periodsValue.id,
          in_start_date_time:
            formFields.in_start_date === "" ? "" : formattedDate,
          in_end_date_time:
            formFields.in_recurring_end_date === ""
              ? ""
              : transformDateTime({
                  date: formFields.in_recurring_end_date,
                  format: "YYYY-MM-DD HH:mm:ss",
                }),
          in_is_recurring:
            formFields.is_recurring_type_job === true ? "Y" : "N",
          in_recurring_type_mstr_key: recurringTypeFields.recurringTypeValue.id,
          in_separation_count: formFields.in_separation_count,
          in_day_of_week:
            recurringTypeFields.recurringTypeValue.id === 3
              ? selectedDays.map((item) => {
                  return { day: item };
                })
              : dayOfWeek !== 0
              ? dayOfWeek
              : "",
          in_week_of_month: weekOfMonth,
          in_day_of_month: formFields.in_day_of_month,
          in_month_of_year:
            formFields.in_month_of_year.value !== undefined
              ? formFields.in_month_of_year.value
              : 0,
          in_param_src_mstr_key:
            postionDataArray.length > 0
              ? convertIdsToCommaSeparatedString(postionDataArray)
              : null,
          in_job_queue_param_mstr_key:
            restProps.dataItemList.job_param === "Position"
              ? restProps.dataItemList.jobQueueParamKey
              : null,
          in_batch_name:
            restProps.dataItemList.comp_plan_name +
            periodsFields.periodsValue.text +
            restProps.dataItemList.job_type_code,
        };
        const bodyData = {
          in_user_id: cookiesData.out_user_id,
          in_tenant_id: cookiesData.out_tenant_id,
          in_job_mstr_key: restProps.jobMstrKey,
          in_period_mstr_key: periodsFields.periodsValue.id,
          in_time_interval:
            formFields.in_start_date === "" ? "" : formattedDate,
          in_comp_plan_keys:
            compPlanFields.compPlanValue.length > 0 &&
            postionDataArray.length == 0
              ? convertIdsToCommaSeparatedString(compPlanFields.compPlanValue)
              : postionDataArray.length == 0
              ? restProps.compPlanKeys
              : null,
          in_position_keys:
            postionDataArray.length > 0
              ? convertIdsToCommaSeparatedString(postionDataArray)
              : null,
          in_user_tz: resDataObject[0].pref_value,
        };
        if (formFields.is_recurring_type_job === true) {
          Action.addJobRecurringJobdataDataAsync(insertRecurringJobData);
        } else {
          Action.addJobQueueDataAsync(bodyData);
        }
        restProps.setIsAddJobtoQueue(false);
        if (restProps.module !== "job_execution") {
          setTimeout(() => {
            handleRedirect();
          }, 2000); // 10000 milliseconds (10 seconds
        }
        if (restProps.module === "job_execution") {
          setTimeout(() => {
            restProps.setIsJobqueueAdd(true);
          }, 2000); // 10000 milliseconds (10 seconds
        }
        // setSaveClick(false);
      }
    } else {
      setSaveClick(true);
    }
  };
  const handleChange = (e, props) => {
    if (props.onChange) {
      setIsPostionButton(false);
      props.onChange({
        dataItem: props.dataItem,
        field: props.field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value === true ? 1 : 0,
      });

      const hasIsParamAssignOne = postionData.some(
        (item) => item.is_assign === 1
      );
      if (hasIsParamAssignOne) {
        setIsPostionButton(false);
        setIsCompPlanDisable(true);
      } else {
        setIsPostionButton(true);
        setIsCompPlanDisable(false);
      }
      setkHeaderCheckbox();
    }
  };
  const CheckboxCell = React.useCallback(
    (props) => {
      const { dataItem, render } = props;
      const hasIsParamAssignOne = postionData.some(
        (item) => item.is_assign === 1
      );
      if (hasIsParamAssignOne) {
        setIsPostionButton(false);
      } else {
        setIsPostionButton(true);
      }

      let value = props.field + dataItem.rowId;
      const defaultRendering = (
        <td>
          <Checkbox
            value={dataItem[props.field] === 1 ? true : false}
            onChange={(e) => {
              handleChange(e, props, value);
            }}
            autoComplete="off"
            name={`${props.field}`}
            disabled={isCompPostionButton ? false : true}
          />
        </td>
      );
      return render?.call(undefined, defaultRendering, props);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [postionData, isCompPostionButton]
  );

  const headerCheckboxOnChange = (e) => {
    setIsHeaderChecked(!isHeaderChecked);
    if (isHeaderChecked) {
      setIsCompPlanDisable(false);
    } else {
      setIsCompPlanDisable(true);
    }
    postionData
      .filter((x) => x.is_assign === (isHeaderChecked ? 1 : 0))
      .map((item) => {
        setIsPostionButton(false);
        item.is_assign = !isHeaderChecked ? 1 : 0;
        item.inIteamEdit = true;
        if (isHeaderChecked) {
          item.inIteamEdit = false;
        }
        return 0;
      });
    setPostionData(postionData);
  };
  const setkHeaderCheckbox = () => {
    const allData = postionData.filter((x) => x.is_assign === 1).length;
    if (allData === 0 && postionData.length !== 0) {
      showisPoistionIntialNote(true);
      postionData
        .filter((x) => x.is_assign === 0)
        .map((item) => {
          item.is_assign = 1;
          item.inIteamEdit = true;
          if (isHeaderChecked) {
            item.inIteamEdit = false;
          }
          return 0;
        });
      setPostionData(postionData);
    } else {
      showisPoistionIntialNote(false);
    }

    setIsHeaderChecked(
      allData === 0 && postionData.length !== 0 ? true : false
    );
  };

  const usePostionCol = (columns) => {
    return columns.map((column, idx) => {
      if (column.field === "is_assign") {
        column["aditionalParam"] = {
          assignCheckboxProps: {
            checked:
              isHeaderChecked || databyFilter.length
                ? !databyFilter.filter((x) => x.is_assign === 0).length
                : 0,
            onChange: headerCheckboxOnChange,
            disabled:
              postionData.length === 0 ||
              dataLengthbyFilter === 0 ||
              restProps.pageProps.pageProps.pageProps.writeAccess === 0 ||
              (isCompPostionButton ? false : true),
          },
          dataTableProps: {
            id: "is_assign",
          },
        };
      }
      return column.show && column.title !== "" ? (
        <Column
          {...columnMenuProps(column)}
          key={idx}
          // sortable={column.field === "is_assign" ? false : true}
          width={column.field === "is_assign" ? "130px" : column.minWidth}
          cell={column.field === "is_assign" ? CheckboxCell : ""}
          resizable={column.field === "is_assign" ? false : true}
          editable={false}
        />
      ) : (
        false
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };
  let PostionDataColumn = usePostionCol(postioncolumns);

  const customRowRender = (tr, props) => (
    <RowRender
      originalProps={props}
      tr={tr}
      editField={editField}
      exitEdit={exitEdit}
    />
  );
  const customCellRender = (td, props) => {
    return (
      <CellRender
        navigatable={true}
        originalProps={props}
        td={td}
        enterEdit={enterEdit}
        editField={editField}
        onRowClick={onRowClick}
        updateActBtn="grid-incell-update"
        cancelActBtn="grid-incell-cancel"
      />
    );
  };
  const onRowClick = () => {};

  const enterEdit = (dataItem, field) => {
    if (restProps.pageProps.writeAccess === 0) {
      return false;
    }

    let newData = postionData.map((item) => ({
      ...item,
      inEdit:
        item.org_position_key === dataItem.org_position_key ? field : undefined,
    }));

    setPostionData(newData);
  };
  const itemChange = React.useCallback(
    (event) => {
      //   restProps.setUnitEdit(true);
      const field = event.field || "";
      const newData = postionData.map((item) =>
        item.org_position_key === event.dataItem.org_position_key
          ? { ...item, [field]: event.value, inIteamEdit: true }
          : item
      );
      let editItem = event.dataItem;
      editItem[field] = event.value;
      setPostionData(newData);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [postionData]
  );
  const exitEdit = () => {
    let newData = data.map((item) => ({
      ...item,
      inEdit: undefined,
    }));
    setData(newData);
  };

  const getInSortNumber = () => {
    return filterData.isSort ? filterData.in_sort_number : DEFAULT_SORT.number;
  };

  const getInSortOrder = () => {
    return filterData.isSort ? filterData.sort_order : DEFAULT_SORT.order;
  };

  const getInFilter = () => {
    return filterData.isFilter ? filterData.data : null;
  };

  const handlePostionColumnMenu = (columnMenuData, isFilter, isSort) => {
    let index = 1;

    if (postionData.length > 0 && columnMenuData[0] !== undefined) {
      let obj = Object.keys(postionData[0]);
      index = obj.findIndex((x) => x === columnMenuData[0].field) + 1;
    }

    let filterString = isFilter
      ? getfilterDataString(columnMenuData, postioncolumns)
      : filterData.data;
    // Use a regular expression to find and capture the value inside the single quotes
    const regex = /.job_param = '([^']+)'/;
    const match = filterString.match(regex);
    if (match) {
      // Extract the captured value
      const capturedValue = match[1];
      filterString = filterString.replace(
        `job_param = '${capturedValue}'`,
        `${capturedValue} = 1`
      );
    }
    isFilter = false;
    if (filterString !== "") {
      isFilter = true;
    }

    let sortDir =
      filterData.isSort && columnMenuData.length
        ? filterData.sort_order
        : DEFAULT_SORT.order;
    let sortNumber =
      filterData.isSort && columnMenuData.length
        ? filterData.in_sort_number
        : DEFAULT_SORT.number;

    isSort = !columnMenuData.length ? false : filterData.isSort;

    if (
      columnMenuData[0] !== undefined &&
      columnMenuData[0].dir !== undefined
    ) {
      isSort = true;
      sortDir = columnMenuData[0].dir.toUpperCase();
      sortNumber = index;
    }

    const dataVal = {
      in_tenant_id: cookiesData.out_tenant_id,
      in_job_mstr_key: restProps.jobMstrKey,
      [restProps.dataItemList.job_type_code === "email_process"
        ? "in_stagehook_job_mstr_key"
        : "in_comp_plan_mstr_key"]:
        restProps.dataItemList.job_type_code === "email_process"
          ? restProps.dataItemList.stagehook_mstr_key
          : restProps.dataItemList.comp_plan_keys,
      in_period_mstr_key: periodsFields.periodsValue.id,
      in_filter: isFilter ? filterString : null,
      in_sort_number: sortNumber,
      in_sort_order: sortDir,
      in_is_published: 0,
      in_acct_encrypt_key: cookiesData.out_acct_encrypt_key,
      job_type_code: restProps.dataItemList.job_type_code,
    };

    setFilterData({
      ...filterData,
      isFilter: isFilter,
      isSort: isSort,
      data: filterString,
      in_sort_number: sortNumber,
      sort_order: sortDir,
    });

    setPostionAPICallingState(true);
    getPostiongridData(dataVal);
  };

  const getPostiongridData = async (inParams) => {
    Action.getPostionListDataAsync(inParams);
  };

  const onPostionClearFilter = () => {
    setPostionAPICallingState(true);

    const dataVal = {
      in_tenant_id: cookiesData.out_tenant_id,
      in_job_mstr_key: restProps.jobMstrKey,
      [restProps.dataItemList.job_type_code === "email_process"
        ? "in_stagehook_job_mstr_key"
        : "in_comp_plan_mstr_key"]:
        restProps.dataItemList.job_type_code === "email_process"
          ? restProps.dataItemList.stagehook_mstr_key
          : restProps.dataItemList.comp_plan_keys,
      in_period_mstr_key: periodsFields.periodsValue.id,
      in_filter: null,
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
      in_is_published: 0,
      in_acct_encrypt_key: cookiesData.out_acct_encrypt_key,
      job_type_code: restProps.dataItemList.job_type_code,
    };

    //set filter to false
    setFilterData({
      ...filterData,
      data: "",
      isFilter: false,
    });

    getPostiongridData(dataVal);
  };

  const onPostionRefreshClick = () => {
    setPostionAPICallingState(true);

    const dataVal = {
      in_tenant_id: cookiesData.out_tenant_id,
      in_job_mstr_key: restProps.jobMstrKey,
      [restProps.dataItemList.job_type_code === "email_process"
        ? "in_stagehook_job_mstr_key"
        : "in_comp_plan_mstr_key"]:
        restProps.dataItemList.job_type_code === "email_process"
          ? restProps.dataItemList.stagehook_mstr_key
          : restProps.dataItemList.comp_plan_keys,
      in_period_mstr_key: periodsFields.periodsValue.id,
      in_filter: getInFilter(),
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
      in_is_published: 0,
      in_acct_encrypt_key: cookiesData.out_acct_encrypt_key,
      job_type_code: restProps.dataItemList.job_type_code,
    };

    getPostiongridData(dataVal);
  };
  const handlePageChange = async (event) => {
    const pageData = event.page;

    setPostionAPICallingState(true);

    setPage({ skip: pageData.skip, take: pageData.take });
    setPostionAPICallingState(false);
  };

  const paginatedData =
    databyFilter && databyFilter.length > 0
      ? databyFilter.slice(pageData.skip, pageData.take + pageData.skip) // Slice filtered data if it exists
      : postionData.slice(pageData.skip, pageData.take + pageData.skip); // Slice original data otherwise
  const dataLengthbyFilterData =
    databyFilter && databyFilter.length > 0
      ? dataLengthbyFilter // Slice filtered data if it exists
      : postionData.length;

  const postionDataGrid = () => {
    return (
      <>
        {/* <div className="content">
          {" "}
          <div className="content-head">
            <div className="Page-title">
              <h1>Position Assignment</h1>
            </div>
          </div>
        </div> */}
        {isRefreshLoading && loadingPanel}
        {postioncolumns.length > 0 ? (
          <DataTable
            isAPICalling={isPostionAPICalling}
            initialLoading={initialPostionLoading}
            resetSort={resetSort}
            data={paginatedData}
            id="job-postion-grid"
            editField={editField}
            pageData={pageData}
            dataCount={dataLengthbyFilterData}
            customColumn={PostionDataColumn}
            columns={postioncolumns}
            dataItemKey={"org_position_key"}
            gridHeight={formFields.is_recurring_type_job ? "450px" : "450px"}
            module={"job-postion-grid"}
            fileName={"Export_job_configuration.xlsx"}
            exportData={exportData}
            rowRender={customRowRender}
            cellRender={customCellRender}
            itemChange={itemChange}
            defaultPageSize={defaultPageSize}
            pageSize={pageSize}
            setPageSize={setPageSize}
            handleColumnMenu={handlePostionColumnMenu}
            handlePageChange={handlePageChange}
            onClearFilter={onPostionClearFilter}
            refreshClick={onPostionRefreshClick}
            reorderable={false}
            setDataLengthbyFilter={setDataLengthbyFilter}
            setDatabyFilter={setDatabyFilter}
            // noDataFound={paginatedData.length === 0 ? true : false}
            isPagination={dataLengthbyFilter === 0 ? false : true}
          />
        ) : (
          <>{loadingPanel}</>
        )}
      </>
    );
  };

  const handleStageChange = (e) => {
    setStateChange(e.state);
    if (e.state === "MINIMIZED") {
      setModal(false);
    } else if (e.state === "DEFAULT") {
      setModal(true);
    }
  };

  return (
    <>
      <Window
        title={<ModalTitle />}
        onClose={onClosePopup}
        width={1085}
        // top={40}
        height={
          restProps.dataItemList.position_param === 1 ||
          restProps.dataItemList.published_position === 1 ||
          restProps.dataItemList.job_type_code === "email_process"
            ? formFields.is_recurring_type_job
              ? 750
              : 698
            : 300
        }
        resizable={false}
        stage={stageChange}
        onStageChange={handleStageChange}
        modal={modal}
        className="addjobtoqueuecont"
      >
        <div className="dtu-file-container-logs">
          <div className="content dtutitlebar">
            <FormElement className="addjobquecont form-row" horizontal={true}>
              {" "}
              <FieldWrapper className="addjobque">
                <div className={"k-form-field-wrap-popup"}>
                  <Label>{"Period Type* : "}</Label>
                  <CustomDropdown
                    name={"periodTypeValue"}
                    data={periodsTypeFields.periodTypeData}
                    textField="text"
                    dataItemKey="id"
                    valueData={originPeriodType.find(
                      (c) => c.text === periodsTypeFields.periodTypeValue.text
                    )}
                    onOpen={(dataValue) => {
                      setonOpenDp(true);
                      setPeriodTypeFields({
                        ...periodsTypeFields,
                        periodTypeData: originPeriodType,
                      });
                    }}
                    onChange={(e, name) => {
                      setonOpenDp(false);
                      setPeriodTypeFields({
                        ...periodsTypeFields,
                        [name]: e,
                      });
                      Action.getPeriodsData(e.id, e);
                    }}
                    onFilterChange={(dataValue) => {
                      setPeriodTypeFields({
                        ...periodsTypeFields,
                        periodTypeData: dataValue,
                      });
                    }}
                    filterable={true}
                    autoClose={false}
                    autoBind={true}
                    dataList={originPeriodType}
                    loading={periodsTypeFields.isPeriodTypeDataLoading}
                  />
                </div>
                <div className={"k-form-field-wrap-popup"}>
                  <Label>{"Period* : "}</Label>
                  <CustomDropdown
                    name={"periodsValue"}
                    data={periodsFields.periodsData}
                    textField="text"
                    dataItemKey="id"
                    valueData={originPeriod.find(
                      (c) => c.text === periodsFields.periodsValue.text
                    )}
                    onChange={(e, name) => {
                      setonOpenDp(false);
                      setPeriodFields({
                        ...periodsFields,
                        [name]: e,
                      });
                      setResetSort(true);

                      setPostionAPICallingState(true);

                      // handleChangePeriod();
                    }}
                    onOpen={(dataValue) => {
                      setonOpenDp(true);
                      setPeriodFields({
                        ...periodsFields,
                        periodsData: dataValue,
                      });
                    }}
                    filterable={true}
                    onFilterChange={(dataValue) => {
                      setPeriodFields({
                        ...periodsFields,
                        periodsData: dataValue,
                      });
                    }}
                    autoClose={false}
                    autoBind={true}
                    isSelectALl={true}
                    dataList={originPeriod}
                    loading={periodsFields.isPeriodDataLoading}
                  />
                </div>{" "}
                <div className={"k-form-field-wrap-popup"}>
                  <label>Start Date *:</label>
                  <DatePicker
                    value={formFields.in_start_date}
                    onChange={handleDateChange}
                    format="yyyy-MM-dd"
                    placeholder="yyyy-mm-dd"
                  />
                  <ShowError name={"in_start_date"}></ShowError>
                </div>
                <div className={"k-form-field-wrap-popup"}>
                  <label>Start time *:</label>
                  <TimePicker
                    value={formFields.in_start_time}
                    onChange={(e) => {
                      setFormFields({
                        ...formFields,
                        in_start_time: e.target.value,
                      });
                      // Action.getPeriodsData(e.id, e);
                    }}
                    // format={{
                    //   hour: "2-digit",
                    //   minute: "2-digit",
                    // }}
                    // nowButton={true}
                    steps={{
                      minute: 15,
                    }}
                  />
                  <ShowError name={"in_start_time"}></ShowError>
                </div>
                <div className={"k-form-field-addrecujob"}>
                  <label>Recurring Job? :</label>
                  <Checkbox
                    value={formFields.is_recurring_type_job}
                    onChange={handleRecurringCheckboxChange}
                    name="is_recurring_type_job"
                  />
                </div>
                {restProps.dataItemList.job_type_code === "stage_hook" &&
                restProps.dataItemList.plan_param === 1 ? (
                  <div className={"k-form-field-wrap-popup valueDropdown"}>
                    <Label>{"Comp Plan*: "}</Label>
                    <MultiSelectDropdown
                      name={"compPlanValue"}
                      data={compPlanFields.compPlanData}
                      textField="text"
                      dataItemKey="id"
                      // valueData={originCompPlan.find(
                      //   (c) => c.text === compPlanFields.compPlanValue.text
                      // )}
                      valueData={formFields.compPlanValue}
                      onOpen={(dataV) => {
                        setcompPlanFields({
                          ...compPlanFields,
                          compPlanData: dataV,
                        });
                      }}
                      onChange={(e, name) => {
                        setFormFields({
                          ...formFields,
                          [name]: e,
                        });
                        setcompPlanFields({
                          ...compPlanFields,
                          compPlanValue: e,
                        });
                        e.length !== 0
                          ? setIsCompPostionButton(false)
                          : setIsCompPostionButton(true);
                        setErrorObj({ ...errorObj, [name]: "" });
                        // Action.getPeriodsData(e.id, e);
                      }}
                      onFilterChange={(dataValue) => {
                        setcompPlanFields({
                          ...compPlanFields,
                          compPlanData: dataValue,
                        });
                      }}
                      filterable={true}
                      autoClose={false}
                      autoBind={true}
                      dataList={originCompPlan}
                      loading={compPlanFields.iscompPlanDataLoading}
                      placeholder={"Select Comp Plan"}
                      disabled={isCompPlanDisable}
                    />
                    <ShowError name={"compPlanValue"}></ShowError>
                  </div>
                ) : (
                  false
                )}
                {isRecurringTypeListshow ? (
                  <>
                    <div
                      className={"k-form-field-wrap-popup"}
                      // style={{ marginTop: "50px" }}
                    >
                      {/* <Label>{"End:"}</Label> */}
                      {/* <div style={{ marginTop: "5px" }}>
                        {" "}
                        <RadioButton
                          name="never"
                          value="never"
                          checked={formFields.is_repet_job === "never"}
                          label="Never"
                          onChange={handleRadioButtonChange}
                        />
                      </div>
                      <div style={{ marginTop: "5px" }}>
                        {" "}
                        <RadioButton
                          name="on"
                          value="on"
                          checked={formFields.is_repet_job === "on"}
                          label="On"
                          onChange={handleRadioButtonChange}
                        /> */}
                      <div>
                        <label>End Date*:</label>
                        <DatePicker
                          // disabled={
                          //   formFields.is_repet_job === "on" ? false : true
                          // }
                          value={formFields.in_recurring_end_date}
                          name="in_recurring_end_date"
                          format="yyyy-MM-dd"
                          onChange={(e) => {
                            handleRecurringDateChange(e);
                          }}
                          formatPlaceholder={{
                            year: "yyyy",
                            month: "mm",
                            day: "dd",
                          }}
                          placeholder="yyyy-mm-dd"
                        />
                        <ShowError name={"in_recurring_end_date"}></ShowError>
                      </div>
                    </div>
                    <div
                      className={"k-form-field-wrap-popup repeateeveryboxcont"}
                    >
                      <Label className="repeateeveryboxlabel">
                        {"Repeat every:"}
                      </Label>
                      <div className="repeateeverybox">
                        <NumericTextBox
                          name="in_separation_count"
                          value={formFields.in_separation_count}
                          onChange={(e) => {
                            handleFieldChange(e);
                          }}
                          min={1}
                          autoFocus={true}
                        />
                        <CustomDropdown
                          name={"recurringTypeValue"}
                          data={recurringTypeFields.recurringTypeData}
                          textField="text"
                          dataItemKey="id"
                          valueData={originRecurringType.find(
                            (c) =>
                              c.text ===
                              recurringTypeFields.recurringTypeValue.text
                          )}
                          onOpen={(dataValue) => {
                            setRecurringTypeFields({
                              ...recurringTypeFields,
                              recurringTypeData: dataValue,
                            });
                          }}
                          onChange={(e, name) => {
                            setRecurringTypeFields({
                              ...recurringTypeFields,
                              [name]: e,
                            });
                            setFormFields({
                              ...formFields,
                              in_day_of_month: 1,
                              in_month_of_year: MONTH_OF_YEAR[0],
                            });
                            setSelectedDays([]);
                            if (e.text === "Weeks") {
                              setIsRecurringTypeWeekly(true);
                              setIsRecurringTypeMonthly(false);
                              setIsRecurringTypeYearly(false);
                            } else if (e.text === "Month") {
                              setIsRecurringTypeWeekly(false);
                              setIsRecurringTypeMonthly(true);
                              setIsRecurringTypeYearly(false);
                            } else if (e.text === "Year") {
                              setIsRecurringTypeWeekly(false);
                              setIsRecurringTypeMonthly(false);
                              setIsRecurringTypeYearly(true);
                            } else {
                              setIsRecurringTypeWeekly(false);
                              setIsRecurringTypeMonthly(false);
                              setIsRecurringTypeYearly(false);
                            }
                          }}
                          onFilterChange={(dataValue) => {
                            setRecurringTypeFields({
                              ...recurringTypeFields,
                              recurringTypeData: dataValue,
                            });
                          }}
                          filterable={true}
                          autoClose={false}
                          autoBind={true}
                          dataList={originRecurringType}
                          loading={
                            recurringTypeFields.isRecurringTypeDataLoading
                          }
                        />
                      </div>
                    </div>
                    {isRecurringTypeWeekly ? (
                      <div className={"k-form-field-wrap-jobque"}>
                        <Label>{"Days of (Week):"}</Label>
                        <ButtonGroup>
                          {DAYS_OF_WEEK.map((item) => (
                            <Button
                              key={item.value}
                              togglable={true}
                              onClick={(event) => {
                                event.preventDefault(); // Prevent default form submission
                                weekButtonClick(item.value);
                              }}
                              active={isDaySelected(item.value)}
                              style={{
                                backgroundColor: isDaySelected(item.value)
                                  ? "#00a1e4"
                                  : "inherit",
                                color: isDaySelected(item.value)
                                  ? "#fff"
                                  : "inherit",
                              }} // Change the background color if selected
                            >
                              {item.text}
                            </Button>
                          ))}
                        </ButtonGroup>
                      </div>
                    ) : (
                      false
                    )}
                    {isRecurringTypeYearly ? (
                      <div className={"k-form-field-wrap-popup"}>
                        <Label>{"Month of Year:"}</Label>
                        <div>
                          <DropDownList
                            // onChange={(e) =>
                            //   setFormFields({
                            //     ...formFields,
                            //     in_month_of_year: e.target.value,
                            //   })
                            // }
                            onChange={handleMonthChange}
                            value={formFields.in_month_of_year}
                            data={MONTH_OF_YEAR}
                            dataItemKey="value"
                            textField="text"
                            placeholder={"Select"}
                          />
                        </div>
                      </div>
                    ) : (
                      false
                    )}
                    {isRecurringTypeMonthly || isRecurringTypeYearly ? (
                      <div className={"k-form-field-wrap-popup"}>
                        <Label>{"Day of Month:"}</Label>
                        <NumericTextBox
                          name="in_day_of_month"
                          placeholder="Repeat on"
                          min={1}
                          max={maxDays}
                          value={formFields.in_day_of_month}
                          onChange={(e) => {
                            handleFieldChange(e);
                          }}
                          autoFocus={true}
                        />
                        <ShowError name={"in_day_of_month"}></ShowError>
                      </div>
                    ) : (
                      false
                    )}
                  </>
                ) : (
                  false
                )}
              </FieldWrapper>
              {isPoistionIntialNote ? (
                <FieldWrapper>
                  NOTE : This positions are running for the first time.
                </FieldWrapper>
              ) : (
                false
              )}
            </FormElement>
          </div>
        </div>
        {restProps.dataItemList.position_param === 1 ||
        restProps.dataItemList.published_position === 1 ||
        restProps.dataItemList.job_type_code === "email_process"
          ? postionDataGrid()
          : false}
        <WindowActionsBar layout={"end"}>
          <Button
            className="primarybtn"
            onClick={(e) => {
              handleAddJobtoQueue();
            }}
            autoFocus={true}
            disabled={
              restProps.dataItemList.position_param === 1 &&
              (postionData.length === 0 ||
                (dataLengthbyFilter === 0 &&
                  (restProps.dataItemList.job_type_code === "email_process" ||
                    (["stage_hook", "comp_plan", "report_publish"].includes(
                      restProps.dataItemList.job_type_code
                    ) &&
                      isCompPostionButton))))
            }
          >
            {"Add"}
          </Button>
          <Button
            className="cancelbtn"
            onClick={(e) => {
              onClosePopup();
            }}
            autoFocus={true}
          >
            {"Cancel"}
          </Button>
        </WindowActionsBar>
      </Window>
      {positionPopup ? (
        <Dialog title={"Alert"} id="dialog-timeout" width={550}>
          <p
            style={{
              margin: "25px",
              textAlign: "center",
            }}
          >
            <img
              src={require("../../images/alert.png").default}
              alt="ProCARE"
              style={{ height: "25px" }} // Fixed the height value
            />{" "}
            Please Select Position
          </p>
          <DialogActionsBar layout="center">
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={cancelPositionPopup}
            >
              OK
            </button>
          </DialogActionsBar>{" "}
        </Dialog>
      ) : (
        false
      )}
    </>
  );
};
export default React.memo(JobDetails);
