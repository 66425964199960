import React from "react";
import { getUserCredential } from "../../lib/cookies";
import { errStatusHandler, POSTAPI } from "../../api-setup/api-helper";
import { GridColumnDataMapper } from "../../utils/utils";
import { useDispatch } from "react-redux";
import { setNewMessage } from "../../redux/actions/setting-actions";
import {
    UPDATE_RULE_RESULT_NAME,
    SELECT_RULE_RESULT_NAME,
    INSERT_RULE_RESULT_NAME,
    INSERT_RULE_TYPE_MASTER,
    DELETE_RULE_TYPE_MASTER,
    DELETE_RULE_RESULT_NAME,
    SELECT_RESULT_TYPE,
    SELECT_RESULT_GROUP,
} from "../../api-setup/api-endpoints";
import { useHistory } from "react-router-dom";

export default function Events() {
    const history = useHistory();
    const cookiesData = getUserCredential();
    const header = {
        "x-access-token": cookiesData.secretToken,
    };
    const dispatch = useDispatch();
    const [data, setData] = React.useState([]);
    const [columns, setColumns] = React.useState([]);
    const [dataCount, setDataCount] = React.useState(0);
    const [initialLoading, setLoadingState] = React.useState(false);
    const [isAPICalling, setAPICallingState] = React.useState(false);
    const [originalData, setOriginalData] = React.useState([]);
    const [noDataFound, setNoDataFound] = React.useState(false);
    const [autoScrollIndex, setAutoScrollIndex] = React.useState(0);
    const [confirmationPopup, setConfirmationPopup] = React.useState({
        title: "Delete",
        desc: "Are you sure you want to delete this record?",
        isVisible: false,
        type: "",
        actionBtn: [],
        data: {},
        action: "Delete",
    });
    const [isRefreshLoading, setRefreshLoading] = React.useState(false);
    const [loadingState, setLoadState] = React.useState({
        isSaveData: undefined,
        isDeleteData: undefined,
    });
    const [resultGroupData, setResultGroupData] = React.useState({
        resultGroupValue: {
            in_result_group: "",
        },
        resultGroupDataValue: [],
        isresultGroupDataLoading: true,
    });
    const [originResultGroupData, setoriginResultGroupData] = React.useState(
        []
    );

    const [resultTypeFields, setResultTypeData] = React.useState({
        resultTypeValue: {
            in_result_type: "",
        },
        resultTypeData: [],
        isresultTypeDataLoading: true,
    });
    const [originResultTypeData, setoriginResultTypeData] = React.useState([]);

    const getResultList = (listData, defaultKey) => {
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                SELECT_RULE_RESULT_NAME,
                null,
                header,
                listData,
                dispatch
            ).then((res) => {
                if (res) {
                    if (res.data.out_parameters.out_ret_code > 0) {
                        setLoadingState(true);
                        errStatusHandler(res, history, dispatch);
                    }
                    setAPICallingState(false);
                    setLoadingState(true);
                    // setEditData("");
                    setConfirmationPopup({
                        ...confirmationPopup,
                        isVisible: false,
                        type: "",
                        actionBtn: [],
                        data: {},
                        action: "",
                    });
                    let userData = JSON.parse(
                        JSON.stringify(res.data.row_result)
                    );

                    if (listData.in_load_type === "grid-reload") {
                        const columData = GridColumnDataMapper(
                            res.data.out_parameters.out_column_xml
                        );

                        setColumns(columData);
                    }
                    userData.map((item, idx) => {
                        item.rowId = idx;
                        item.selected = false;
                        return 0;
                    });

                    //TODO: Once mehul comes then we have to check with hidden header when auto scroll so temprorary we have commented this line. if you want auto scroll then enable below code.
                    if (defaultKey) {
                        setAutoScrollIndex(
                            userData.findIndex(
                                (it) => it.result_name_mstr_key === defaultKey
                            )
                        );
                    }

                    setData(userData);
                    setOriginalData(userData);
                    setDataCount(res.data.out_parameters.out_total_rows);
                    setRefreshLoading(false);
                    resolve(userData);
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    const addDataAsync = (bodyData) => {
        setRefreshLoading(true);
        setLoadState({ ...loadingState, isSaveData: false });
        POSTAPI(
            false,
            INSERT_RULE_RESULT_NAME,
            null,
            header,
            bodyData,
            dispatch
        ).then((res) => {
            if (res.status) {
                setRefreshLoading(false);
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                } else {
                    setLoadState({ ...loadingState, isSaveData: true });
                }
            }
        });
    };

    const deleteDataAsync = async (bodyData) => {
        setRefreshLoading(true);
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                DELETE_RULE_RESULT_NAME,
                null,
                header,
                bodyData,
                dispatch
            ).then((res) => {
                setRefreshLoading(false);
                if (res.data.out_parameters.out_ret_code > 0) {
                    let msg = res.data.out_parameters.out_error_msg;

                    setLoadState({ ...loadingState, isDeleteData: false });
                    setConfirmationPopup({
                        ...confirmationPopup,
                        isVisible: false,
                        type: "",
                        actionBtn: [],
                        data: {},
                        action: "",
                    });
                    dispatch(
                        setNewMessage({
                            message: msg,
                            type: "Error",
                            show: true,
                        })
                    );
                } else {
                    setConfirmationPopup({
                        ...confirmationPopup,
                        isVisible: false,
                        type: "",
                        actionBtn: [],
                        data: {},
                        action: "",
                    });
                    setLoadState({ ...loadingState, isDeleteData: true });
                    setTimeout(function () {
                        resolve("success");
                    });
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    const updateDataAsync = (bodyData) => {
        setRefreshLoading(true);
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                UPDATE_RULE_RESULT_NAME,
                null,
                header,
                bodyData,
                dispatch
            ).then((res) => {
                setRefreshLoading(false);
                if (res && res.data.out_parameters.out_ret_code === 0) {
                    resolve(true);
                } else {
                    dispatch(
                        setNewMessage({
                            message: res?.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    resolve([]);
                    errStatusHandler(res, history, dispatch);
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    const addResultTypeDataAsync = (bodyData, data) => {
        POSTAPI(
            false,
            INSERT_RULE_TYPE_MASTER,
            null,
            header,
            bodyData,
            dispatch
        ).then((res) => {
            if (res.status) {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                }
            }
        });
    };

    const deleteResultTypeDataAsync = (bodyData, data) => {
        POSTAPI(
            false,
            DELETE_RULE_TYPE_MASTER,
            null,
            header,
            bodyData,
            dispatch
        ).then((res) => {
            if (res.status) {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                }
            }
        });
    };

    const getResultTypeList = async () => {
        let data = {
            in_filter: null,
            in_sort_number: 1,
            "in_sort_order ": "ASC",
            in_page_row_offset: 0,
            in_page_rows: 9999,
        };
        POSTAPI(false, SELECT_RESULT_TYPE, null, header, data, dispatch).then(
            (res) => {
                if (res.status) {
                    let tempArray = [];
                    res.data.row_result.map((item) => {
                        let obj = {};
                        obj.text = item.result_type_name;
                        obj.value = item.result_type_mstr_key;
                        tempArray.push(obj);
                        return 0;
                    });
                    let blankOptions = {
                        text: "",
                        value: null,
                    };
                    tempArray.unshift(blankOptions);
                    setoriginResultTypeData(tempArray);

                    setResultTypeData({
                        ...resultTypeFields,
                        resultTypeData: tempArray,
                        resultTypeValue: tempArray[0],
                        isresultTypeDataLoading: false,
                    });
                } else {
                    // errStatusHandler(res, history, dispatch);
                    setResultTypeData({
                        ...resultTypeFields,
                        isresultTypeDataLoading: false,
                    });
                }
            }
        );
    };

    const getResultGroup = () => {
        let data = {
            in_filter: null,
            in_sort_number: 1,
            in_sort_order: "DESC",
            in_page_row_offset: 0,
            in_page_rows: 100,
        };
        POSTAPI(false, SELECT_RESULT_GROUP, null, header, data, dispatch).then(
            (res) => {
                if (res.status) {
                    let tempArray = [];
                    res.data.row_result.map((item) => {
                        let obj = {};
                        obj.text = item.result_group_name;
                        obj.value = item.result_group_mstr_key;
                        tempArray.push(obj);
                        return 0;
                    });
                    setoriginResultGroupData(tempArray);
                    setResultGroupData({
                        ...resultGroupData,
                        resultGroupDataValue: tempArray,
                        resultGroupValue: tempArray[0],
                        isresultGroupDataLoading: false,
                    });
                } else {
                    setResultGroupData({
                        ...resultGroupData,
                        isresultGroupDataLoading: false,
                    });
                }
            }
        );
    };
    const exportDataAsync = (bodyData) => {
        setRefreshLoading(true);
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                SELECT_RULE_RESULT_NAME,
                null,
                header,
                bodyData,
                dispatch
            ).then((res) => {
                setRefreshLoading(false);
                if (
                    res &&
                    (res.data.out_parameters.out_ret_code === 0 ||
                        res.data.out_parameters.out_ret_code === 2)
                ) {
                    resolve(res.data.row_result);
                } else {
                    dispatch(
                        setNewMessage({
                            message: res?.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    resolve([]);
                    errStatusHandler(res, history, dispatch);
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    return [
        {
            data,
            columns,
            dataCount,
            setData,
            setColumns,
            originalData,
            isAPICalling,
            initialLoading,
            setAPICallingState,
            setNoDataFound,
            noDataFound,
            setConfirmationPopup,
            confirmationPopup,
            setRefreshLoading,
            isRefreshLoading,
            loadingState,
            setLoadState,
            resultGroupData,
            setResultGroupData,
            originResultGroupData,
            resultTypeFields,
            setResultTypeData,
            originResultTypeData,
            autoScrollIndex,
            setAutoScrollIndex,
        },
        {
            getResultList,
            addDataAsync,
            deleteDataAsync,
            updateDataAsync,
            addResultTypeDataAsync,
            deleteResultTypeDataAsync,
            getResultGroup,
            getResultTypeList,
            exportDataAsync,
        },
    ];
}
