/* eslint-disable jsx-a11y/anchor-is-valid */
import {
    StackLayout,
    TabStrip,
    TabStripTab,
} from "@progress/kendo-react-layout";
import { savePDF } from "@progress/kendo-react-pdf";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { DialogComponent } from "src/components/dialog";
import { LoadingPanel } from "src/components/loadingPanel";
import {
    getDashboardPod,
    mapDashboardPodData,
    getDashboardPublishEnable,
} from "src/store/dashboard.store";
import { HeaderPage } from "../base/headerPage";
import { DashboardPodDialog } from "./components/dashboard-pod-dialog";
import DashboardCharts from "./components/dashboard-pod/components/dashboard-charts";
import { DashboardConstant } from "./index.const";
import { DashboardHook } from "./index.hook";
import "./index.scss";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { transformDateTime } from "src/lib/date-time.util";
import { AddDashboardTabDialog } from "./dashboard-tab-dialog";
import { getUserCredential } from "src/lib/cookies";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { Loader } from "@progress/kendo-react-indicators";

const DashboardPage = (pageProps) => {
    const {
        tabStripOnChange,
        activeTabStrip,
        dashboardAccountSummaryData,
        dashboardAccountSummaryisLoading,
        onDeleteDashTab,
        confirmationPopup,
        onCancelConfirm,
        handleCopyDashTab,
        reFetchDashTabData,
        handlePublishDashboard,
        isPublishLoading,
    } = DashboardHook();

    const addRef = useRef(null);
    const activeDashboardPod = getDashboardPod();
    const [dashTabsDialog, setDashTabsDialog] = useState(false);
    const [editDashTabsDialog, setEditDashTabsDialog] = useState(false);
    const cookiesData = getUserCredential();
    const {
        tabId,
        name,
        periodStartDate,
        periodEndDate,
        groupFilterLabel,
        specialtyFilterLabel,
        providerFilterLabel,
        reportPublishedFlag,
    } = mapDashboardPodData;
    const dashboardPublishEnable = getDashboardPublishEnable();

    const onClickAddDashTab = useCallback(() => {
        if (!dashTabsDialog) {
            setDashTabsDialog(true);
        }
    }, [dashTabsDialog]);

    const closeDashboardTabsDialog = useCallback(() => {
        if (dashTabsDialog) {
            setDashTabsDialog(false);
        }
        if (editDashTabsDialog) {
            setEditDashTabsDialog(false);
        }
    }, [dashTabsDialog, editDashTabsDialog]);

    const onClickEditDashTab = useCallback(() => {
        if (!editDashTabsDialog) {
            setEditDashTabsDialog(true);
        }
        if (!dashTabsDialog) {
            setDashTabsDialog(true);
        }
    }, [dashTabsDialog, editDashTabsDialog]);

    const pdfExportComponent = useRef(null);
    const handleDownloadPDF = async () => {
        const element = document.querySelector(".pdfExportComponent");
        const tabName = name.getData(
            dashboardAccountSummaryData[activeTabStrip]
        );
        const originalMaxWidth = element.style.maxWidth;
        element.style.maxWidth = "205mm";
        window.dispatchEvent(new Event("resize"));

        await savePDF(element, {
            paperSize: "A4",
            fileName: `${tabName}.pdf`,
            landscape: true,
            margin: "1cm",
        });

        element.style.maxWidth = originalMaxWidth;
        window.dispatchEvent(new Event("resize"));
    };

    const DeleteConfirmMsgComponent = () => {
        return (
            <p dangerouslySetInnerHTML={{ __html: confirmationPopup.desc }} />
        );
    };

    const handleDashTabClose = (tabSelection) => {
        closeDashboardTabsDialog();
        if (tabSelection >= 0) {
            reFetchDashTabData(-1, tabSelection);
        }
    };

    const [isDisabled, setIsDisabled] = useState(false);

    useEffect(() => {
        if (isPublishLoading === true && !isDisabled) {
            setIsDisabled(true);
        }
    }, [isPublishLoading, isDisabled]);

    useEffect(() => {
        const activeTabId =
            dashboardAccountSummaryData?.[activeTabStrip]
                ?.dashboard_tab_mstr_key;

        const roleTypeAccess =
            dashboardAccountSummaryData?.[activeTabStrip]?.role_type_access ||
            "0";

        const isEnable =
            (dashboardPublishEnable?.[activeTabId] ||
                dashboardAccountSummaryData?.[activeTabStrip]
                    ?.publish_enable !== 0) &&
            activeDashboardPod?.pod_count > 0 &&
            roleTypeAccess !== "0";
        setIsDisabled(!isEnable);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        dashboardPublishEnable,
        dashboardAccountSummaryData,
        activeTabStrip,
        activeDashboardPod?.pod_count,
    ]);

    const AddNewDashTab = () => {
        return (
            <StackLayout orientation="horizontal" gap={10}>
                {cookiesData?.out_db_role_type_abbr === "adm1" &&
                    pageProps?.writeAccess && (
                        <a
                            className={`cta-small ${
                                isDisabled ? "dashboardPublishDisabled" : ""
                            }`}
                            onClick={handlePublishDashboard}
                            aria-disabled={isDisabled}
                        >
                            {DashboardConstant.publishDashabord.title}
                        </a>
                    )}

                {cookiesData?.out_db_role_type_abbr === "adm1" && (
                    <a
                        className="cta-small"
                        ref={addRef}
                        onClick={onClickAddDashTab}
                    >
                        <i className="fas fa-plus"></i>{" "}
                        {DashboardConstant.addDashabordButton.title}
                    </a>
                )}
            </StackLayout>
        );
    };

    const headerConfig = {
        title: DashboardConstant.headerConfig.title,
        id: "dashboard",
        add_new_fx: AddNewDashTab,
        action_menu: {
            ...(cookiesData?.out_db_role_type_abbr === "adm1" && {
                copyDashTab: {
                    fx: () => {
                        handleCopyDashTab();
                    },
                },
            }),
            ...(cookiesData?.out_db_role_type_abbr !== "prv1" && {
                editDashTab: {
                    fx: () => {
                        onClickEditDashTab();
                    },
                },
            }),
            ...(cookiesData?.out_db_role_type_abbr === "adm1" && {
                deleteDashTab: {
                    fx: () => {
                        onDeleteDashTab();
                    },
                },
            }),
            exportToPDF: {
                fx: () => {
                    handleDownloadPDF();
                },
            },
        },
        disabled: {
            editDashTab: !dashboardAccountSummaryData?.length > 0,
            exportToPDF: !activeDashboardPod?.pod_count > 0,
        },
    };

    const [addDataSetDialog, setAddDataSetDialog] = useState(false);
    const addDashboardDataSet = useCallback(() => {
        setAddDataSetDialog(!addDataSetDialog);
    }, [addDataSetDialog]);

    const dashboardChartsRef = useRef();
    const refreshPODData = useCallback(() => {
        if (addDataSetDialog && dashboardChartsRef.current) {
            dashboardChartsRef.current.refreshDashboardPodDetail();
        }
    }, [addDataSetDialog]);

    const renderTabLayout = () => {
        const Title = (props) => {
            return (
                <div className="dashTab">
                    <span className="dashTabTitle">{props.title}</span>
                    {cookiesData?.out_db_role_type_abbr === "adm1" &&
                    pageProps?.writeAccess &&
                    props.isActive ? (
                        <button
                            title="Add Dashboard Pod"
                            className="dashTabIconBtn"
                            onClick={addDashboardDataSet}
                        >
                            <span className="fas fa-plus"></span>
                        </button>
                    ) : null}
                    <div className="dashTabIconBtn">
                        <Tooltip
                            anchorElement="target"
                            position="bottom"
                            openDelay={100}
                        >
                            <span
                                title={props.tootlipMessage}
                                className="fas fa-info infoIcon"
                            ></span>
                        </Tooltip>
                    </div>
                </div>
            );
        };

        const tooltipMsg = (summaryObj) => {
            const startDate = transformDateTime({
                date: periodStartDate.getData(summaryObj),
                format: "MM/DD/YYYY",
            });

            const endDate = transformDateTime({
                date: periodEndDate.getData(summaryObj),
                format: "MM/DD/YYYY",
            });

            const groupFltr = groupFilterLabel.getData(summaryObj);
            const specialtyFltr = specialtyFilterLabel.getData(summaryObj);
            const prvFltr = providerFilterLabel.getData(summaryObj);

            const groupFltrLbl = groupFltr
                ? groupFilterLabel.label + groupFltr
                : "";

            const specialtyFltrLbl = specialtyFltr
                ? specialtyFilterLabel.label + specialtyFltr
                : "";

            const prvFltrLbl = prvFltr
                ? providerFilterLabel.label + prvFltr + " > "
                : "";

            const pubFlag = prvFltrLbl
                ? `Publish/Current: ${reportPublishedFlag.getData(summaryObj)}`
                    ? "Publish"
                    : "Current"
                : "";

            return `Date Range: ${startDate} to
                      ${endDate}${groupFltrLbl}${specialtyFltrLbl}${prvFltrLbl}${pubFlag}`;
        };

        return (
            <>
                {dashTabsDialog && (
                    <AddDashboardTabDialog
                        isEditForm={editDashTabsDialog}
                        closeDialog={(tabSelection) =>
                            handleDashTabClose(tabSelection)
                        }
                    />
                )}
                <TabStrip
                    selected={activeTabStrip}
                    onSelect={tabStripOnChange}
                    scrollable={true}
                    className="tab-strip-width plantabsinnertabs dashboardTabs"
                    keepTabsMounted={false}
                >
                    {dashboardAccountSummaryData?.length > 0 &&
                        dashboardAccountSummaryData.map((summaryObj, index) => (
                            <TabStripTab
                                key={`dash-tab-${tabId.getData(
                                    summaryObj
                                )}-${index}`}
                                title={
                                    <Title
                                        title={name.getData(summaryObj)}
                                        isActive={activeTabStrip === index}
                                        tootlipMessage={tooltipMsg(summaryObj)}
                                    />
                                }
                                contentClassName="dashboard-pod-container"
                            >
                                <div
                                    className="pdfExportComponent"
                                    ref={pdfExportComponent}
                                >
                                    <DashboardCharts
                                        resultScreenReadAccess={
                                            pageProps?.resultScreenReadAccess ||
                                            0
                                        }
                                        writeAccess={pageProps?.writeAccess}
                                        ref={dashboardChartsRef}
                                    />
                                </div>
                            </TabStripTab>
                        ))}
                </TabStrip>
            </>
        );
    };

    return (
        <div className="Main dashboard">
            <div className="content">
                <div className="content-head">
                    {isPublishLoading && <LoadingPanel />}
                    <HeaderPage
                        pageProps={pageProps}
                        headerConfig={headerConfig}
                    />
                </div>
                <div className="content-body">
                    {dashboardAccountSummaryisLoading ? (
                        <Loader size="large" className="k-loading-procare" />
                    ) : (
                        renderTabLayout()
                    )}
                    {
                        <DialogComponent
                            title={confirmationPopup.title}
                            onCancelConfirm={onCancelConfirm}
                            width={"450"}
                            height={"auto"}
                            className={
                                confirmationPopup.action === "Delete"
                                    ? "Dialog-Delete"
                                    : null
                            }
                            desc={<DeleteConfirmMsgComponent />}
                            visible={confirmationPopup.isVisible}
                            actionBtn={confirmationPopup.actionBtn}
                            titleClassName={
                                confirmationPopup.action === "Delete"
                                    ? "delete-confirm"
                                    : "primaryTitleText"
                            }
                        />
                    }
                    {addDataSetDialog && (
                        <DashboardPodDialog
                            closeDialog={() => addDashboardDataSet()}
                            refreshDashboardPodDetail={refreshPODData}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default memo(DashboardPage);
