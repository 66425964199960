/**
 * function to use login validation
 *
 * @param {*} values Object
 * @param {*} formSchema Object
 *
 * @returns validation Object
 */
export const userNameValidator = (values, formSchema) => {
    let validationObj = { userNameValidation: [] };
    let errorObj = {};

    if (!formSchema.in_username.rules) {
        return errorObj;
    }

    //validate username
    const userName = values?.in_username ? values.in_username : "";

    validationObj.userNameValidation = userName.validateInputVal(
        formSchema.in_username.rules
    );

    if (validationObj.userNameValidation.length) {
        errorObj["in_username"] = validationObj.userNameValidation[0].message;
    }

    const errorCount = Object.keys(errorObj).length;

    if (errorCount > 0) {
        errorObj["VALIDATION_SUMMARY"] = "Please correct the following fields.";
    }

    return errorObj;
};

export const passwordValidator = (values, formSchema) => {
    let validationObj = { passwordValidation: [] };
    let errorObj = {};

    if (!formSchema.in_password.rules) {
        return errorObj;
    }

    //validate password
    const password = values?.in_password ? values.in_password : "";

    validationObj.passwordValidation = password.validateInputVal(
        formSchema.in_password.rules
    );

    if (validationObj.passwordValidation.length) {
        errorObj["in_password"] = validationObj.passwordValidation[0].message;
    }

    const errorCount = Object.keys(errorObj).length;

    if (errorCount > 0) {
        errorObj["VALIDATION_SUMMARY"] = "Please correct the following fields.";
    }

    return errorObj;
};
export const otpValidator = (values, formSchema) => {
    let validationObj = { userNameValidation: [] };
    let errorObj = {};

    if (!formSchema.otp.rules) {
        return errorObj;
    }

    //validate username
    const userName = values?.otp ? values.otp : "";

    validationObj.otpValidation = userName.validateInputVal(
        formSchema.otp.rules
    );

    if (validationObj.otpValidation.length) {
        errorObj["otp"] = validationObj.otpValidation[0].message;
    }

    const errorCount = Object.keys(errorObj).length;

    if (errorCount > 0) {
        errorObj["VALIDATION_SUMMARY"] = "Please correct the following fields.";
    }

    return errorObj;
};

export const dynamicFieldValidatorValidator = (field, values, formSchema) => {
    let validationObj = { userNameValidation: [] };
    let errorObj = {};

    if (!formSchema?.[field]?.rules) {
        return errorObj;
    }

    //validate username
    const userName = values?.[field] ? values?.[field] : "";

    validationObj[field+"Validation"] = userName.validateInputVal(
        formSchema[field].rules
    );

    if (validationObj[field+"Validation"].length) {
        errorObj[field] = validationObj[field+"Validation"][0].message;
    }

    const errorCount = Object.keys(errorObj).length;

    if (errorCount > 0) {
        errorObj["VALIDATION_SUMMARY"] = "Please correct the following fields.";
    }

    return errorObj;
};


export const chagnePasswordValidation = (values, formSchema) => {
    let validationObj = { subjectValidation: [], messageValidation: [] };
    let errorObj = {};

    //validate newPassword
    if (formSchema?.on_change_state?.newPassword) {
        const subject = values?.newPassword ? values.newPassword : "";

        validationObj.subjectValidation = subject.validateInputVal(
            formSchema?.newPassword?.rules
        );

        if (validationObj.subjectValidation.length) {
            errorObj["newPassword"] = validationObj.subjectValidation[0].message;
        }
    }

    //validate confirmNewPassword
    if (formSchema?.on_change_state?.confirmNewPassword) {
        const message = values?.confirmNewPassword ? values.confirmNewPassword : "";

        validationObj.messageValidation = message.validateInputVal(
            formSchema.confirmNewPassword.rules
        );

        if (validationObj.messageValidation.length) {
            errorObj["confirmNewPassword"] = validationObj.messageValidation[0].message;
        }
    }

    if (formSchema?.on_change_state?.confirmNewPassword) {
        const message = values?.in_user_first_name ? values.in_user_first_name : "";

        validationObj.messageValidation = message.validateInputVal(
            formSchema.in_user_first_name.rules
        );

        if (validationObj.messageValidation.length) {
            errorObj["in_user_first_name"] = validationObj.messageValidation[0].message;
        }
    }
    if (formSchema?.on_change_state?.in_user_last_name) {
        const message = values?.in_user_last_name ? values.in_user_last_name : "";

        validationObj.messageValidation = message.validateInputVal(
            formSchema.in_user_last_name.rules
        );

        if (validationObj.messageValidation.length) {
            errorObj["in_user_last_name"] = validationObj.messageValidation[0].message;
        }
    }

    const errorCount = Object.keys(errorObj).length;

    if (errorCount > 0) {
        errorObj["VALIDATION_SUMMARY"] = "Please fill in the following fields.";
    }

    return errorObj;
};


export const chagneUserDetailsValidation = (values, formSchema) => {
    let validationObj = { subjectValidation: [], messageValidation: [] };
    let errorObj = {};



    if (formSchema?.on_change_state?.confirmNewPassword) {
        const message = values?.in_user_first_name ? values.in_user_first_name : "";

        validationObj.messageValidation = message.validateInputVal(
            formSchema.in_user_first_name.rules
        );

        if (validationObj.messageValidation.length) {
            errorObj["in_user_first_name"] = validationObj.messageValidation[0].message;
        }
    }
    if (formSchema?.on_change_state?.in_user_last_name) {
        const message = values?.in_user_last_name ? values.in_user_last_name : "";

        validationObj.messageValidation = message.validateInputVal(
            formSchema.in_user_last_name.rules
        );

        if (validationObj.messageValidation.length) {
            errorObj["in_user_last_name"] = validationObj.messageValidation[0].message;
        }
    }

    const errorCount = Object.keys(errorObj).length;

    if (errorCount > 0) {
        errorObj["VALIDATION_SUMMARY"] = "Please fill in the following fields.";
    }

    return errorObj;
};
