import React from "react";
import { ProviderTableData } from "../providerDataRows/dataRowsGrid";

const DataRows = (restProps) => {
  //disable in-cell update and cancel button
  return (
    <>
      <div className="dtu-file-container plancheckboxgrid">
        <ProviderTableData
          pageProps={restProps.pageProps}
          compTableMasterKey={restProps.compTableMasterKey}
          effVersionDataItem={restProps.effVersionDataItem}
          selectedTab={restProps.selected}
          isProviderUpdateClick={restProps.isProviderUpdateClick}
          cancelProviderAwaitStatus={restProps.cancelProviderAwaitStatus}
          setCancelProviderAwaitStatus={restProps.setCancelProviderAwaitStatus}
          isProviderEdit={restProps.isProviderEdit}
          setisProviderEdit={restProps.setisProviderEdit}
          setScreenLock={restProps.setScreenLock}
          screenLock={restProps.screenLock}
          setVersionExportDataClick={restProps.setVersionExportDataClick}
          versionExportDataClick={restProps.versionExportDataClick}
          providerColumnsData={restProps.providerColumnsData}
          setproviderColumnsData={restProps.setproviderColumnsData}
          isproviderCheckColumn={restProps.isproviderCheckColumn}
          setproviderColumnCheck={restProps.setproviderColumnCheck}
          headerAssignmentConfigId={restProps.headerAssignmentConfigId}
          dateVersion={restProps.dateVersion}
          isReload={restProps.isReload}
          setReload={restProps.setReload}
          isRefreshBtnClick={restProps.isRefreshBtnClick}
          setRefreshBtnClick={restProps.setRefreshBtnClick}
        />
      </div>
    </>
  );
};

export default React.memo(DataRows);
