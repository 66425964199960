import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { GridToolbar } from "@progress/kendo-react-grid";
import { memo, useEffect, useState } from "react";
import {
    mapDashboardPodData,
    getDashboardPod,
} from "src/store/dashboard.store";
import {
    downloadIcon,
    pencilIcon,
    toggleFullScreenModeIcon,
    trashIcon,
    linkIcon,
} from "@progress/kendo-svg-icons";
import { DashboardPodHeaderHook } from "./index.hook";
import { DialogComponent } from "src/components/dialog";
import { DashboardWidgetWrapper } from "../dashboard-widget-wrapper";
import { Window } from "@progress/kendo-react-dialogs";
import { DashboardPodDialog } from "../../../dashboard-pod-dialog";
import { getUserCredential } from "src/lib/cookies";

const DashboardPodHeader = (props) => {
    const podDetail = { ...props.podDetail };
    const cookiesData = getUserCredential();
    const refreshDashboardPodDetail = () => {
        props.refreshDashboardPodDetail();
    };

    const {
        editDashboardDataSet,
        editDataSetDialog,
        onDeleteClick,
        onDownloadPOD,
        onDetailClick,
        closeDashboardDataSetDialog,
        confirmationPopup,
        onCancelConfirm,
        detailPopup,
        onDetailClose,
        linkToResult,
    } = DashboardPodHeaderHook(refreshDashboardPodDetail);
    const { name, periodTypeMstrKey } = mapDashboardPodData;

    const [initialWidth, setInitialWidth] = useState(1000);
    const [initialHeight, setInitialHeight] = useState(600);

    const dashboardData = getDashboardPod();

    useEffect(() => {
        setInitialWidth(window.innerWidth * 0.9);
        setInitialHeight(window.innerHeight * 0.9);
    }, []);

    const DeleteConfirmMsgComponent = () => {
        return (
            <p dangerouslySetInnerHTML={{ __html: confirmationPopup.desc }} />
        );
    };

    const DetailComponent = (data) => {
        return <DashboardWidgetWrapper podDetail={data.podDetail} />;
    };

    const dashboardType = podDetail?.dashboard_type_name?.toLowerCase() + "s";
    const resultScreenAccess = props?.resultScreenReadAccess?.[dashboardType];

    return (
        <GridToolbar>
            <div className="chart-header-title">{name.getData(podDetail)}</div>
            <ButtonGroup>
                {dashboardData.tab_period_mstr_key &&
                    props?.writeAccess === 1 &&
                    resultScreenAccess === 1 && (
                        <Button
                            title={
                                "View Related " +
                                podDetail?.dashboard_type_name +
                                " Data"
                            }
                            togglable
                            svgIcon={linkIcon}
                            onClick={() =>
                                linkToResult(podDetail, dashboardData)
                            }
                            disabled={editDataSetDialog}
                        />
                    )}
                {cookiesData?.out_db_role_type_abbr === "adm1" &&
                    props?.writeAccess === 1 && (
                        <>
                            <Button
                                title="Edit Dashboard Pod"
                                togglable
                                svgIcon={pencilIcon}
                                onClick={() => editDashboardDataSet(podDetail)}
                                disabled={editDataSetDialog}
                            />
                            <Button
                                title="Delete Dashboard Pod"
                                togglable
                                svgIcon={trashIcon}
                                onClick={() => onDeleteClick(podDetail)}
                                disabled={confirmationPopup.isVisible}
                            />
                        </>
                    )}
                <Button
                    title="Export To PDF"
                    togglable
                    svgIcon={downloadIcon}
                    onClick={() => onDownloadPOD(podDetail)}
                />
                <Button
                    title="Expand Chart"
                    togglable
                    svgIcon={toggleFullScreenModeIcon}
                    onClick={() => onDetailClick(podDetail)}
                    disabled={detailPopup.isVisible}
                />
            </ButtonGroup>
            {editDataSetDialog && (
                <DashboardPodDialog
                    refreshDashboardPodDetail={refreshDashboardPodDetail}
                    closeDialog={closeDashboardDataSetDialog}
                />
            )}
            <DialogComponent
                title={confirmationPopup.title}
                onCancelConfirm={onCancelConfirm}
                width={"450"}
                height={"auto"}
                className={"Dialog-Delete"}
                desc={<DeleteConfirmMsgComponent />}
                visible={confirmationPopup.isVisible}
                actionBtn={confirmationPopup.actionBtn}
                titleClassName={"delete-confirm"}
            />

            {detailPopup.isVisible && (
                <Window
                    title={detailPopup.title}
                    onClose={onDetailClose}
                    initialHeight={initialHeight}
                    initialWidth={initialWidth}
                    className={"dashboard-data-set-container"}
                    visible={detailPopup.isVisible}
                    actions={detailPopup.actionBtn}
                    resizable={false}
                    titleClassName={"dashboard-data-set-title"}
                >
                    <DetailComponent podDetail={detailPopup.data} />
                </Window>
            )}
        </GridToolbar>
    );
};

export default memo(DashboardPodHeader);
