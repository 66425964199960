import React from "react";
import { FieldWrapper, Form, FormElement } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import { Window, WindowActionsBar } from "@progress/kendo-react-dialogs";
import { getUserCredential } from "src/lib/cookies";
import { addSpecialtyDataAsync } from "src/lib/common-service";
import { Button } from "@progress/kendo-react-buttons";
import MessageSchema from "src/utils/messageSchema";
import { Error, Label } from "@progress/kendo-react-labels";import { changeCancelBtnState, changeUpdateBtnState } from "src/utils/utils";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";


const initialFormFields = {
  in_specialty_name: "",
  in_specialty_desc: "",
};


const ModalTitle = () => {
  return (
    <span>
      Add Specialty
    </span>
  );
};

const AddSpecialty = (restProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { showPopup, setShowPopup, setSpecialtySave } = restProps
  const [formFields, setFormFields] = React.useState({ ...initialFormFields });
  const [isEdit, setEdit] = React.useState(false)
  const [errorObj, setErrorObj] = React.useState({});
  const cookiesData = getUserCredential();

  const onClosePopup = () => {
    setFormFields({ ...initialFormFields })
    setErrorObj({})
    setShowPopup({
      ...showPopup,
      showSpecialtyPopup: false
    })
  }

  const ShowError = React.useCallback(
    (props) => {
      return <Error>{errorObj[props.name]}</Error>;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errorObj]
  );

  const handleValidation = () => {
    let isValid = true;
    if ((formFields.in_specialty_name).trim() === "") {
      isValid = false;
      errorObj.in_specialty_name = MessageSchema.specialityNameRequired;
    }
    setErrorObj((preVState) => ({ ...preVState, errorObj }));
    return isValid;
  };

  const handleSubmit = () => {
    let valid = handleValidation();
    if (valid) {
      setErrorObj({});
      const bodyData = {
        in_user_id: cookiesData.out_user_id,
        in_tenant_id: cookiesData.out_tenant_id,
        in_specialty_name: formFields.in_specialty_name,
        in_specialty_desc: formFields.in_specialty_desc,
        in_region_name: "",
      };
      let res = addSpecialtyDataAsync(bodyData, history, dispatch);
      if (res) {
        changeCancelBtnState('enable')
        changeUpdateBtnState('enable')
        setSpecialtySave(true)
        onClosePopup()
      }
    }
  };

  const handleFieldChange = (e) => {
    setEdit(true)
    const name = e.name ? e.name : e.target.name;
    setFormFields({ ...formFields, [name]: e.value })
    setErrorObj({ ...errorObj, [name]: "" })
  };


  return (

    <div>
      <Window
        title={<ModalTitle />}
        onClose={onClosePopup}
        width={390}
        height={200}
        resizable={false}
        modal={true}
        className="form-row specialitywin"
      >
        <Form  onSubmit={handleSubmit} render={() => (
          <FormElement
            horizontal={false}
            className="horizontal-form"
          >
            <FieldWrapper>
              <div className={"k-form-field-wrap-popup"}>
                <Label>{"Specialty Name* : "}</Label>
                <Input
                  type={"text"}
                  name="in_specialty_name"
                  value={formFields.in_specialty_name}
                  onChange={(e) => {
                    handleFieldChange(e);
                  }}
                  autoFocus={true}
                />
                <ShowError name={"in_specialty_name"} />
              </div>
            </FieldWrapper>

            <FieldWrapper>
              <div className={"k-form-field-wrap-popup"}>
                <Label>{"Description : "}</Label>
                <Input
                  type={"text"}
                  name="in_specialty_desc"
                  value={formFields.in_specialty_desc}
                  onChange={(e) => {
                    handleFieldChange(e);
                  }}
                />
                <ShowError name={"in_specialty_desc"} />
              </div>
            </FieldWrapper>

          </FormElement>

        )} />
        <WindowActionsBar layout={"end"}>
          <Button
            type={"submit"}
            className="cancelbtn"
            onClick={() => {
              onClosePopup()
            }}
          >
            {"Cancel"}
          </Button>
          <Button
            className="primarybtn"
            onClick={(e) => {
              handleSubmit();
            }}
            autoFocus={true}
            disabled={!isEdit}
          >
            {"Save"}
          </Button>
        </WindowActionsBar>
      </Window>
    </div>
  );
};

export default React.memo(AddSpecialty);
