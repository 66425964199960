// src/usePasswordToggle.js
import { useState } from 'react';
// import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const usePasswordToggle = () => {
  const [visible, setVisible] = useState(false);

  const IconClass = visible ? "passeyeicon-off" : "passeyeicon";
  const InputType = visible ? 'text' : 'password';

  const toggleVisibility = () => {
    setVisible(prevVisible => !prevVisible);
  };

  return [InputType, IconClass, toggleVisibility];
};

export default usePasswordToggle;
