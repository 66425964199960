/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { providerPlanStatementSelector } from "src/redux/selectors/custom-selector";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
  GridNoRecords,
} from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import {
  setExpandedState,
  setGroupIds,
} from "@progress/kendo-react-data-tools";

import { IntlService } from "@progress/kendo-react-intl";
import { Button } from "@progress/kendo-react-buttons";
import { Tooltip } from "@progress/kendo-react-tooltip";
import ExcelExportProviderStatement from "./ExcelExportProviderStatement";
import {
  clearReportsState,
  setReportLinkObjectData,
} from "src/redux/actions/reports-actions";
import { useHistory } from "react-router-dom";
import { setProviderSection2DataAction } from "src/redux/actions/providerplan-actions";

const intl = new IntlService("en");

const initialDataState = {
  group: [
    { field: "position_name" },
    {
      field: "kpi_name",
      compare: (a, b) => a.kpi_report_order - b.kpi_report_order,
    },
    { field: "result_group_name" },
    {
      field: "result_type_name",
      dir: "asc",
    },
  ],
};
const aggregates = [
  {
    field: "period1_value_total",
    aggregate: "sum",
  },
  {
    field: "period2_value_total",
    aggregate: "sum",
  },
  {
    field: "period3_value_total",
    aggregate: "sum",
  },
  {
    field: "period4_value_total",
    aggregate: "sum",
  },
  {
    field: "period5_value_total",
    aggregate: "sum",
  },
  {
    field: "period6_value_total",
    aggregate: "sum",
  },
  {
    field: "period7_value_total",
    aggregate: "sum",
  },
  {
    field: "period8_value_total",
    aggregate: "sum",
  },
  {
    field: "period9_value_total",
    aggregate: "sum",
  },
  {
    field: "period10_value_total",
    aggregate: "sum",
  },
  {
    field: "period11_value_total",
    aggregate: "sum",
  },
  {
    field: "period12_value_total",
    aggregate: "sum",
  },
  {
    field: "period13_value_total",
    aggregate: "sum",
  },
  {
    field: "period14_value_total",
    aggregate: "sum",
  },
  {
    field: "period15_value_total",
    aggregate: "sum",
  },
  {
    field: "period16_value_total",
    aggregate: "sum",
  },
  {
    field: "period17_value_total",
    aggregate: "sum",
  },
];

const processWithGroups = (data, dataState) => {
  const groups = dataState.group;
  if (groups) {
    groups.map((group) => (group.aggregates = aggregates));
  }
  dataState.group = groups;
  const newDataState = process(data, dataState);
  setGroupIds({
    data: newDataState.data,
    group: dataState.group,
  });
  return newDataState;
};

const ProviderSectionTwo = (props) => {
  const { isReportRunning, isExporting, reportHeight } = props;
  const {
    providerSection2Data,
    selectedCompPlan,
    selectedCompPlanPeriod,
    showSection1,
  } = useSelector(providerPlanStatementSelector, shallowEqual);
  const dispatch = useDispatch();

  const history = useHistory();

  const [result, setResult] = React.useState(
    processWithGroups(providerSection2Data?.data, initialDataState)
  );
  const [collapsedState, setCollapsedState] = React.useState([]);
  const excelExportRef = React.useRef(null);

  const expandChange = (event) => {
    const item = event.dataItem;
    if (item.groupId) {
      const newCollapsedIds = !event.value
        ? [...collapsedState, item.groupId]
        : collapsedState.filter((groupId) => groupId !== item.groupId);
      setCollapsedState(newCollapsedIds);
    }
  };

  const newData = setExpandedState({
    data: result.data,
    collapsedIds: collapsedState,
  });

  const rowRender = (row, props) => {
    if (props.rowType === "groupHeader" && !props.dataItem.value) return null;

    return row;
  };

  const cellRender = (tdElement, cellProps) => {
    if (tdElement && cellProps.rowType === "groupHeader") {
      const rowStyle = {
        position: "sticky",
        fontWeight: "bold",
        backgroundColor:
          cellProps.dataItem.field === "position_name"
            ? "#009DDC"
            : cellProps.dataItem.field === "kpi_name"
            ? "#7AC143"
            : cellProps.dataItem.field === "result_group_name"
            ? "#eae8e8"
            : cellProps.dataItem.field === "result_type_name"
            ? "#FDFD96"
            : undefined,
      };

      const valDisplay = tdElement.props.children?.props?.children?.[1];

      const headerVal =
        tdElement.props.children?.type === "p" ? (
          <>
            {tdElement.props.children?.props?.children?.[0]}
            <span title={valDisplay}>{valDisplay}</span>
          </>
        ) : undefined;
      return React.cloneElement(
        tdElement,
        {
          ...tdElement.props,
          style: { ...tdElement.props.style, ...rowStyle },
        },
        headerVal
      );
    } else if (
      tdElement &&
      cellProps.rowType === "data" &&
      cellProps.field.startsWith("period")
    ) {
      let period = "";
      const num = cellProps.field.match(/\d+/g);
      if (num.length) {
        period = providerSection2Data.columns.find(
          (it) => Number(it.per_number) === Number(num[0])
        );
      }
      const valItem = parseVal(
        cellProps.dataItem[cellProps.field],
        cellProps.dataItem.report_format
      );
      const dataVal =
        cellProps.dataItem.report_hyperlink === 1 ? (
          <a
            onClick={() => {
              dispatch(clearReportsState());
              setTimeout(() => {
                dispatch(
                  setReportLinkObjectData({
                    isFromProvider: true,
                    compPlanFilterValue: selectedCompPlan,
                    compPlanPeriodFilterValue: selectedCompPlanPeriod,
                    providerFilterValue: {
                      provider_key: cellProps.dataItem.provider_key,
                    },
                    periodFilterValue: period,
                    eventTypeFilterValue: {
                      event_type_mstr_key: Number(
                        cellProps.dataItem.event_type_keys
                      ),
                    },
                  })
                );
                history.push("/compensation_detail");
              }, 300);
            }}
            className="provider-plan-section2-link-text"
          >
            <span title={valItem}>{valItem}</span>
          </a>
        ) : (
          <span title={valItem}>{valItem}</span>
        );
      return React.cloneElement(tdElement, tdElement.props, dataVal);
    } else if (tdElement && cellProps.rowType === "data") {
      const dataVal = (
        <span title={cellProps.dataItem[cellProps.field]}>
          {cellProps.dataItem[cellProps.field]}
        </span>
      );
      return React.cloneElement(tdElement, tdElement.props, dataVal);
    }
    return tdElement;
  };
  const CustomHeaderCell = (props) => {
    return (
      <span className="k-cell-inner" title={props.title}>
        <span className="k-link k-text-center">
          {props.field === "period4_value" ||
          props.field === "period8_value" ||
          props.field === "period12_value" ||
          props.field === "period16_value" ? (
            <Button
              className="k-column-title"
              fillMode="solid"
              onClick={() => toggleColumn(props.field)}
            >
              <span title={props.title}>{props.title}</span>
            </Button>
          ) : (
            <Button
              className="k-column-title"
              fillMode="flat"
              style={{ color: "#424242", cursor: "default" }}
            >
              <span title={props.title}>{props.title}</span>
            </Button>
          )}
        </span>
      </span>
    );
  };

  const  getTotalValue = (field) => {
    let format = "$#,##0.00;-$#,##0.00";
    const item = providerSection2Data.data.filter(
      (it) => it.result_metadata_tags === "<grand_total>"
    );
    if (item.length) {
      format = item[0].report_format;
    }

    const total = item.reduce((it, it1) => it + it1[`${field}_total`], 0);
    const numberVal = intl.parseNumber(total || 0);
    return intl.formatNumber(numberVal, format);
  };


  const parseVal = (fieldVal, format) => {
    const numberVal = intl.parseNumber(fieldVal || 0);
    if (!format) {
      return !fieldVal ? "" : Boolean(numberVal) ? "True" : "False";
    }
    return intl.formatNumber(numberVal, format);
  };

  const toggleColumn = (field) => {
    let ids = field.match(/\d+/g).map(Number);
    if (!ids.length) return;

    const parNumber = ids[0];
    let finalGrids = [...providerSection2Data?.columns];
    for (let i = 2; i <= 4; i++) {
      finalGrids[parNumber - i] = {
        ...finalGrids[parNumber - i],
        show: !finalGrids[parNumber - i].show,
      };
    }

    dispatch(setProviderSection2DataAction({ columns: finalGrids }));
  };

  useEffect(() => {
    const newDataState = processWithGroups(
      providerSection2Data?.data,
      initialDataState
    );
    setResult(newDataState);

    setTimeout(() => {
      if (document.getElementsByTagName("p").length > 0) {
        let elements = document.getElementsByTagName("p");
        Array.from(elements)?.forEach((it) => {
          if (!it.textContent) {
            //TODO: Need to check with medonelater on.
            //it?.parentElement?.parentElement?.classList?.add("k-hidden");
          }
        });
      }
    }, 0);
  }, [providerSection2Data?.data]);

  useEffect(() => {
    if (document.getElementsByClassName("k-grouping-header").length > 0) {
      const element = document
        .getElementsByClassName("k-grouping-header")
        .item(0);
      element.classList.add("k-hidden");
    }
  }, [providerSection2Data, isReportRunning]);

  const TooltipContentTemplate = (props) => {
    const targetObj = props.title;
    const title = targetObj.title;

    return (
      <div className="pc-grid-tooltip">
        <strong>{title}</strong>
      </div>
    );
  };

  const isEllipsisActive = (element) => {
    let returnBool = false;

    const allowTags = ["SPAN", "TD"];

    if (!allowTags.includes(element.tagName)) {
      return returnBool;
    }

    let tdElement = element;
    let spanElement = element;

    if (element.tagName === "TD") {
      spanElement = element.firstChild;
    } else {
      tdElement = element.closest("td");
    }

    if (!tdElement || !spanElement) {
      return returnBool;
    }

    if (spanElement?.className?.includes("k-icon")) {
      return returnBool;
    }

    const bodyStyle = window.getElementStyle(tdElement);
    const tdPadding =
      parseFloat(bodyStyle["paddingLeft"]) +
      parseFloat(bodyStyle["paddingRight"]);

    const tdWidth = tdElement.offsetWidth - tdPadding;
    const spanWidth = spanElement.offsetWidth;

    // text was truncated.
    if (spanWidth > tdWidth) {
      spanElement.title = spanElement.innerText;
      returnBool = true;
    } else {
      spanElement.title = "";
    }

    return returnBool;
  };

  if (providerSection2Data?.columns.length === 0) return null;

  return (
    <Tooltip
      openDelay={100}
      content={(props) => <TooltipContentTemplate title={props} />}
      filter={isEllipsisActive}
      position="auto"
    >
      <ExcelExportProviderStatement
        newData={newData}
        group={initialDataState.group}
        ref={excelExportRef}
      />

      <Grid
        style={
          isExporting || isReportRunning
            ? {
                height: "100%",
                minHeight: isReportRunning ? reportHeight : undefined,
              }
            : {
                height: reportHeight,
                overflowY: "scroll",
              }
        }
        scrollable={isExporting ? "scrollable" : "scrollable"}
        className="provider-plan-section2"
        resizable={false}
        groupable={{ enabled: true, footer: "none" }}
        data={newData}
        {...initialDataState}
        lockGroups={true}
        onExpandChange={expandChange}
        expandField="expanded"
        cellRender={cellRender}
        rowRender={rowRender}
      >
        <GridNoRecords>
          {!isReportRunning ? <span>No Data Available</span> : <span></span>}
        </GridNoRecords>
        <GridToolbar>
          <div className="Page-title" style={{ flex: 1 }}>
            <h1>KPI Payment Details</h1>
          </div>
          {!isExporting && (
            <Button
              className="primarybtn"
              style={{ width: "150px", flex: "none" }}
              onClick={() => excelExportRef.current?.excelExport()}
              disabled={providerSection2Data?.data?.length === 0}
            >
              <span>
                <i className="fas fa-file-excel"></i> Export to Excel
              </span>
            </Button>
          )}
        </GridToolbar>

        <Column
          field="result_name"
          title="Result Name"
          width={180}
          headerCell={CustomHeaderCell}
          locked
          footerCell={(props) =>
            !isReportRunning || isExporting ? (
              <td
                className="k-text-center"
                colSpan={props.colSpan}
                style={props.style}
              >
                <span title="Total Payments">Total Payments</span>
              </td>
            ) : null
          }
        />
        {providerSection2Data?.columns
          .filter((it) => it.show)
          .map((it, index) => (
            <Column
              id={it.per_number}
              key={it.per_number}
              className="k-text-center"
              width={it.period_name.toLowerCase().includes("year") ? 90 : 85}
              field={`period${it.per_number}_value`}
              title={it.period_name}
              headerCell={CustomHeaderCell}
              footerCell={(props) =>
                !isReportRunning || isExporting ? (
                  <td
                    className="k-text-center"
                    colSpan={props.colSpan}
                    style={props.style}
                  >
                    <span title={getTotalValue(`period${it.per_number}_value`)}>
                      {getTotalValue(`period${it.per_number}_value`)}
                    </span>
                  </td>
                ) : null
              }
            />
          ))}
      </Grid>
    </Tooltip>
  );
};

export default ProviderSectionTwo;
