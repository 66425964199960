import React from "react";
import TmplAuth from "src/template/tmplAuth";
import ForgotPasswordForm from "./form/forgotPasswordForm";
import { AuthContent } from "./common/AuthContent";

const ForgotPassword = () => {
    return <TmplAuth form={<ForgotPasswordForm/>} pageContent={<AuthContent label="Forgot Password" showChangeUserBtn={false} showUserNameLabel={false}/>} />;
};

export default React.memo(ForgotPassword);
