import React, { useState } from "react";
import { getUserCredential } from "../../lib/cookies";
import { POSTAPI, errStatusHandler } from "../../api-setup/api-helper";
import { GridColumnDataMapper } from "../../utils/utils";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { setNewMessage } from "../../redux/actions/setting-actions";
import {
  DELETE_FORMULA_MASTER,
  INSERT_FORMULA_MASTER,
  SELECT_FORMULA_MASTER,
  SP_INSERT_UDF_DATA_OBJECT,
  UPDATE_FORMULA_MASTER,
} from "../../api-setup/api-endpoints";

export default function FormulaMaster() {
  const cookiesData = getUserCredential();
  const header = {
    "x-access-token": cookiesData.secretToken,
  };
  const history = useHistory();
  const dispatch = useDispatch();
  const [data, setData] = React.useState([]);
  const [columns, setColumns] = React.useState([]);
  const [dataCount, setDataCount] = React.useState(0);
  const [initialLoading, setLoadingState] = React.useState(false);
  const [editData, setEditData] = React.useState("");
  const [selectedFormula, setSelectedFormula] = useState();
  const [selectedState, setSelectedState] = React.useState({});
  const [confirmationPopup, setConfirmationPopup] = React.useState({
    title: "Delete",
    desc: "Are you sure you want to delete this record?",
    isVisible: false,
    type: "",
    actionBtn: [],
    data: {},
    action: "Delete",
  });
  const [originalData, setOriginalData] = React.useState([]);
  const [isAPICalling, setAPICallingState] = React.useState(false);
  const [isRefreshLoading, setRefreshLoading] = React.useState(false);
  const [autoScrollIndex, setAutoScrollIndex] = useState(0);

  const getListDataAsync = (listData, defaultKey, isReset) => {
    setAPICallingState(true);
    return new Promise(function (resolve, reject) {
      POSTAPI(false, SELECT_FORMULA_MASTER, null, header, listData, dispatch).then((res) => {
        if (res) {
          if (res.data.out_parameters.out_ret_code > 0) {
           setLoadingState(true);
            errStatusHandler(res, history, dispatch);
            setSelectedState({});
            setSelectedFormula(null);
          }

          setAPICallingState(false);
          setLoadingState(true);
          setEditData("");
          setConfirmationPopup({
            ...confirmationPopup,
            isVisible: false,
            type: "",
            actionBtn: [],
            data: {},
            action: "",
          });
          let userData = JSON.parse(JSON.stringify(res.data.row_result));

          if(listData.in_load_type === "grid-reload") {
            const columData = GridColumnDataMapper(res.data.out_parameters.out_column_xml)?.map(
              (obj) => {
                return { ...obj, width: 250 };
              }
            );
  
            columData.unshift({
              title: "ACTION",
              field: "expand",
              show: true,
              width: 80,
            });
            setColumns(columData);
          }
         

          setOriginalData(userData.map((item, idx) => ({ ...item, selected: false, rowId: idx })));
          let foundItem = { item: null, index: 0 };
          userData.map((item, idx) => {
            if (isReset) {
              item.rowId = idx;
              item.selected = false;
              return 0;
            }
            const isFound =
              (selectedFormula?.formula_mstr_key || defaultKey || "") === item.formula_mstr_key;
            if (isFound) {
              foundItem = { item: item, index: idx };
            }
            item.rowId = idx;
            item.selected = isFound;
            return 0;
          });

          if (!isReset && foundItem.item) {
            setSelectedState({ [foundItem.item.formula_mstr_key]: true });
            setSelectedFormula(foundItem.item);
          } else if (userData.length > 0) {
            userData[0] = { ...userData[0], selected: true };
            setSelectedState({ [userData[0].formula_mstr_key]: true });
            setSelectedFormula(userData[0]);
            setAutoScrollIndex(0);
          }

          if (defaultKey) {
            setAutoScrollIndex(foundItem.index);
          }

          setData(userData);
          setDataCount(res.data.out_parameters.out_total_rows);
          setRefreshLoading(false);
          resolve(userData);
        }
      });
    })
      .then((res) => {
        return res;
      })
      .catch((res) => {
        if (res.data.out_parameters.out_ret_code > 0) {
          dispatch(
            setNewMessage({
              message: res.data.out_parameters.out_error_msg,
              type: "Error",
              show: true,
            })
          );
          errStatusHandler(res, history, dispatch);
        }
      })
      .finally(() => {
        setAPICallingState(false);
      });
  };

  const spInsertUdfDataObjectAsync = (bodyData) => {
    setRefreshLoading(true);
    return new Promise(function (resolve, reject) {
      POSTAPI(false, SP_INSERT_UDF_DATA_OBJECT, null, header, bodyData, dispatch).then((res) => {
        setRefreshLoading(false);
        if (res.status) {
          if (res.data.out_parameters.out_ret_code > 0) {
            dispatch(
              setNewMessage({
                message: res.data.data.out_parameters.out_error_msg,
                type: "Error",
                show: true,
              })
            );
            resolve(false);
            errStatusHandler(res, history, dispatch);
          } else {
            resolve(true);
          }
        } else {
          resolve(false);
          dispatch(
            setNewMessage({
              message: res.data?.data?.out_parameters?.out_error_msg,
              type: "Error",
              show: true,
            })
          );
        }
      });
    })
      .then((res) => {
        return res;
      })
      .catch((res) => {
        if (res.data.out_parameters.out_ret_code > 0) {
          dispatch(
            setNewMessage({
              message: res.data.out_parameters.out_error_msg,
              type: "Error",
              show: true,
            })
          );
          errStatusHandler(res, history, dispatch);
        }
        return res;
      });
  };

  const deleteDataAsync = async (bodyData) => {
    setRefreshLoading(true);
    return new Promise(function (resolve, reject) {
      POSTAPI(false, DELETE_FORMULA_MASTER, null, header, bodyData, dispatch).then((res) => {
        setRefreshLoading(false);
        if (res.data.out_parameters.out_ret_code > 0) {
          let msg = res.data.out_parameters.out_error_msg;
          setConfirmationPopup({
            ...confirmationPopup,
            isVisible: false,
            type: "",
            actionBtn: [],
            data: {},
            action: "",
          });
          dispatch(
            setNewMessage({
              message: msg,
              type: "Error",
              show: true,
            })
          );
        } else {
          setConfirmationPopup({
            ...confirmationPopup,
            isVisible: false,
            type: "",
            actionBtn: [],
            data: {},
            action: "",
          });
          resolve("success");
        }
      });
    })
      .then((res) => {
        return res;
      })
      .catch((res) => {
        if (res.data.out_parameters.out_ret_code > 0) {
          dispatch(
            setNewMessage({
              message: res.data.out_parameters.out_error_msg,
              type: "Error",
              show: true,
            })
          );
          errStatusHandler(res, history, dispatch);
        }
      });
  };

  const addFormulaDataAsync = (bodyData) => {
    setAPICallingState(true);

    return new Promise(function (resolve, reject) {
      POSTAPI(false, INSERT_FORMULA_MASTER, null, header, bodyData, dispatch).then((res) => {
        setAPICallingState(false);
        if (res.data.out_parameters.out_ret_code > 0) {
          let msg = res.data.out_parameters.out_error_msg;
          dispatch(
            setNewMessage({
              message: msg,
              type: "Error",
              show: true,
            })
          );
        } else {
          resolve("success");
        }
      });
    })
      .then((res) => {
        return res;
      })
      .catch((res) => {
        setAPICallingState(false);
        if (res.data.out_parameters.out_ret_code > 0) {
          dispatch(
            setNewMessage({
              message: res.data.out_parameters.out_error_msg,
              type: "Error",
              show: true,
            })
          );
          errStatusHandler(res, history, dispatch);
        }
      })
      .finally(() => {
        setAPICallingState(false);
      });
  };

  const updateFormulaDataAsync = (bodyData) => {
    setAPICallingState(true);
    return new Promise(function (resolve, reject) {
      POSTAPI(false, UPDATE_FORMULA_MASTER, null, header, bodyData, dispatch).then((res) => {
        setAPICallingState(false);
        if (res.data.out_parameters.out_ret_code > 0) {
          let msg = res.data.out_parameters.out_error_msg;
          dispatch(
            setNewMessage({
              message: msg,
              type: "Error",
              show: true,
            })
          );
        } else {
          resolve("success");
        }
      });
    })
      .then((res) => {
        return res;
      })
      .catch((res) => {
        setAPICallingState(false);
        if (res.data.out_parameters.out_ret_code > 0) {
          dispatch(
            setNewMessage({
              message: res.data.out_parameters.out_error_msg,
              type: "Error",
              show: true,
            })
          );
          errStatusHandler(res, history, dispatch);
        }
      })
      .finally(() => {
        setAPICallingState(false);
      });
  };

  return [
    {
      data,
      columns,
      dataCount,
      setData,
      setDataCount,
      setColumns,
      editData,
      originalData,
      isAPICalling,
      setEditData,
      confirmationPopup,
      setConfirmationPopup,
      initialLoading,
      setAPICallingState,
      setOriginalData,
      setRefreshLoading,
      isRefreshLoading,
      selectedState,
      setSelectedState,
      selectedFormula,
      setSelectedFormula,
      autoScrollIndex,
      setAutoScrollIndex,
    },
    {
      getListDataAsync,
      spInsertUdfDataObjectAsync,
      deleteDataAsync,
      addFormulaDataAsync,
      updateFormulaDataAsync,
    },
  ];
}
