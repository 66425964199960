import React, { useCallback } from "react";
import { ChartSeriesItem } from "@progress/kendo-react-charts";
import { shortLabel } from "src/lib/widget.util";

const bubbleChartConfig = {
    type: "bubble",
    visibleInLegend: true,
    tooltip: {
        visible: true,
        format: "N2",
        render: (e) => {
            const formatNumber = (num) =>
                new Intl.NumberFormat("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                }).format(num);

            const xValue = formatNumber(e?.point?.value?.x);
            const yValue = formatNumber(e?.point?.value?.y);
            const sizeValue = formatNumber(e?.point?.value?.size);

            return `${e?.point?.category} - ${xValue} x ${yValue} (${sizeValue})`;
        },
    },
    labels: {
        font: "08px Poppins, sans-serif",
        content: shortLabel,
    },
    xField: "xValue",
    yField: "yValue",
    sizeField: "size",
    categoryField: "category",
};

export const BubbleParserHook = () => {
    const renderBubble = useCallback(({ chartValue }) => {
        return (
            <ChartSeriesItem
                colorField="color"
                key={"bubble"}
                data={chartValue.filter((o) => o.visible === true)}
                {...bubbleChartConfig}
            />
        );
    }, []);
    return {
        renderBubble,
    };
};
