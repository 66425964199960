import React from "react";
import {
    SELECT_JOB_POSTION_PARAM,
    SELECT_JOB_POSTION_STAGEHOOK_PARAM,
    ADD_JOB_SCHEDULER,
    GET_SELECT_GRID_COLUMN_XML,
    INSERT_JOB_QUEUE
} from "../../api-setup/api-endpoints";
import { getUserCredential, getTenantCookieData } from "../../lib/cookies";
import {
    POSTAPI,
    errStatusHandler,
} from "../../api-setup/api-helper";
import { GridColumnDataMapper } from "../../utils/utils";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { setNewMessage } from "../../redux/actions/setting-actions";
import { DEFAULT_SORT } from "../../constants/grid";
import {
    GetCompPlanDataApi,
    GeJobTypeAPI,
    GetStagehookTypesData,
    GetPeriodTypeAPI,
    GetPeriodsDataList,
    GetRecurringType
} from "../../lib/common-service";

export default function JobService() {
    /**
     * start to define default states for common grid functions (list/insert/update/delete)
     */
    const cookiesData = getUserCredential();
    const header = {
        "x-access-token": cookiesData.secretToken,
    };
    const history = useHistory();
    const dispatch = useDispatch();
    const [data, setData] = React.useState([]);
    const [columns, setColumns] = React.useState([]);
    const [dataCount, setDataCount] = React.useState(0);
    const [loadingState, setLoadState] = React.useState({
        isSaveData: undefined,
        isDeleteData: undefined,
    });
    const [visible, setVisible] = React.useState(false);

    const [initialLoading, setLoadingState] = React.useState(false);
    const [editData, setEditData] = React.useState("");
    const [confirmationPopup, setConfirmationPopup] = React.useState({
        title: "Delete",
        desc: "Are you sure you want to delete this record?",
        isVisible: false,
        type: "",
        actionBtn: [],
        data: {},
        action: "Delete",
    });
    const [originalData, setOriginalData] = React.useState([]);
    const [isAPICalling, setAPICallingState] = React.useState(false);
    const [isRefreshLoading, setRefreshLoading] = React.useState(false);
    const defaultPageSize = getTenantCookieData("record_per_page");
    const [periodsTypeFields, setPeriodTypeFields] = React.useState({
        periodTypeValue: {
            in_period_mstr_key: "",
            in_period_start_date: "",
            in_period_end_date: "",
        },
        periodTypeData: [],
        isPeriodTypeDataLoading: true,
    });
    const [originPeriodType, setOriginPeriodType] = React.useState([]);
    const [periodsFields, setPeriodFields] = React.useState({
        periodsValue: {
            id: "",
            text: "",
        },
        periodsData: [],
        isPeriodDataLoading: true,
    });
    const [originPeriod, setOriginPeriod] = React.useState([]);
    const [initialPostionLoading, setPostionLoadingState] =
    React.useState(false);
    const [isPostionAPICalling, setPostionAPICallingState] =
    React.useState(false);
    const [postioncolumns, setPostioncolumns] = React.useState([]);
    const [postionData, setPostionData] = React.useState([]);
    const [originalPostionData, setOriginalPostionData] = React.useState([]);
    const [isCompPlanDisable, setIsCompPlanDisable] = React.useState(false);
    const [postionCount, setPostionCount] = React.useState(0);


    /**
     * end to define default states for common grid functions (list/insert/update/delete)
     */

    /**
     * start to define default states for periods and period types
     */
    const [jobTypeFields, setJobTypeFields] = React.useState({
        jobTypeValue: {
            in_job_mstr_key: "",
        },
        jobTypeData: [],
        isJobTypeDataLoading: true,
    });
    const [originJobType, setoriginJobType] = React.useState([]);
    const [compPlanFields, setcompPlanFields] = React.useState({
        compPlanValue: {
            in_comp_plan_mstr_key: "",
        },
        compPlanData: [],
        iscompPlanDataLoading: true,
    });
    const [originCompPlan, setOriginCompPlan] = React.useState([]);
    const [stagehookFields, setStagehookFields] = React.useState({
        stagehookValue: {
            in_stagehook_mstr_key: "",
        },
        stagehookData: [],
        isstagehookDataLoading: true,
    });
    const [originStageHook, setOriginStagehook] = React.useState([]);
    const [recurringTypeFields, setRecurringTypeFields] = React.useState({
        recurringTypeValue: {
            in_recurring_key: "",
        },
        recurringTypeData: [],
        isRecurringTypeDataLoading: true,
    });
    const [originRecurringType, setoriginRecurringType] = React.useState([]);

 

    const getPostionListDataAsync = (listData) => {
        let endpoint = SELECT_JOB_POSTION_PARAM;
        if (listData.job_type_code === "email_process") {
            endpoint = SELECT_JOB_POSTION_STAGEHOOK_PARAM;
        }
        return new Promise(function (resolve, reject) {
            POSTAPI(false, endpoint, null, header, listData, dispatch).then(
                (res) => {
                    if (res) {
                        const reqData = {
                            in_tenant_id: cookiesData.out_tenant_id,
                            in_filter: null,
                            in_filter_db_name: "procare_system",
                            in_filter_table_name: "vw_position_param",
                            in_result_name_mstr_key: 0,
                            in_event_type_mstr_key: 0,
                            in_hide_column: 0,
                        };

                        setTimeout(function () {
                            getColumListXML(reqData);
                        }, 100);

                        if (res.data.out_parameters.out_ret_code > 0) {
                            setPostionLoadingState(true);
                            errStatusHandler(res, history, dispatch);
                        }
                        let userData = [];
                        userData = JSON.parse(
                            JSON.stringify(res.data.row_result)
                        );

                        setPostionAPICallingState(false);
                        setPostionLoadingState(true);
                        setEditData("");
                        setConfirmationPopup({
                            ...confirmationPopup,
                            isVisible: false,
                            type: "",
                            actionBtn: [],
                            data: {},
                            action: "",
                        });

                        setPostionData(userData);
                        //   const columData = GridColumnDataMapper(
                        //     res.data.out_parameters.out_column_xml
                        // );
                        //   setPostioncolumns(columData)
                        setOriginalPostionData(userData);
                        const hasIsParamAssignOne = userData.some(
                            (item) => item.is_param_assign === 1
                        );
                        if (hasIsParamAssignOne) {
                            setIsCompPlanDisable(true);
                        } else {
                            setIsCompPlanDisable(false);
                        }
                        setPostionCount(res.data.out_parameters.out_total_rows);
                        setRefreshLoading(false);

                        resolve(userData);
                    }
                }
            );
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };


    const getColumListXML = (reqData) => {
        POSTAPI(
            false,
            GET_SELECT_GRID_COLUMN_XML,
            null,
            header,
            reqData,
            dispatch
        ).then((res) => {
            if (res.data.out_parameters.out_ret_code > 0) {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                setAPICallingState(true);
                errStatusHandler(res, history, dispatch);
            } else {
                const columData = GridColumnDataMapper(
                    res.data.out_parameters.out_columns_xml
                );
                if (reqData.in_filter_table_name === "vw_job_master") {
                    const ACTION_COLUM_OBJECT = {
                        field: "job_mstr_key",
                        alias: "a",
                        title: "ACTION",
                        type: "string",
                        show: true,
                        width: "10px",
                    };
                    columData.unshift(ACTION_COLUM_OBJECT);
                    columData.map((cItem) => {
                        cItem.minWidth =
                            cItem.field === "job_mstr_key" ? 50 : 180;
                        return 0;
                    });
                    setColumns(columData);
                    setVisible(true);
                } else {
                    columData.map((cItem) => {
                        cItem.minWidth =
                            cItem.field === "is_param_assign" ? 90 : 180;
                        return 0;
                    });
                    setPostioncolumns(columData);
                }
            }
        });
    };

    const getJobTypesData = async () => {
        setoriginJobType([]);
        setJobTypeFields({
            ...jobTypeFields,
            isJobTypeDataLoading: true,
        });
        let jobTypeData = {
            in_filter: null,
            in_sort_number: DEFAULT_SORT.number,
            in_sort_order: "ASC",
        };

        let res = await GeJobTypeAPI(jobTypeData, dispatch, history);

        if (res.length > 0) {
            let tempArray = [];

            res.map((item) => {
                let obj = {};
                obj.text = item.job_type_name;
                obj.id = item.job_type_mstr_key;
                obj.code = item.job_type_code;

                tempArray.push(obj);
                return 0;
            });

            setoriginJobType(tempArray);
            setJobTypeFields({
                ...jobTypeFields,
                jobTypeData: tempArray,
                jobTypeValue: tempArray[0],
                isJobTypeDataLoading: false,
            });
        } else {
            setJobTypeFields({
                ...jobTypeFields,
                isJobTypeDataLoading: false,
            });
        }
    };
    const getCompPlanData = async () => {
        let dataObj = {
            in_tenant_id: cookiesData.out_tenant_id,
            in_filter: null,
            in_sort_number: null,
            in_sort_order: null,
            in_user_key: cookiesData.out_user_id,
            in_multi_sort_order: null,
        };
        let res = await GetCompPlanDataApi(dataObj, dispatch, history);

        if (res.length > 0) {
            let tempArray = [];

            res.map((item) => {
                let obj = {};
                obj.text = item.comp_plan_name;
                obj.id = item.comp_plan_mstr_key;

                tempArray.push(obj);
                return 0;
            });

            setOriginCompPlan(tempArray);
            setcompPlanFields({
                ...compPlanFields,
                compPlanData: tempArray,
                compPlanValue: tempArray[0],
                iscompPlanDataLoading: false,
            });
        } else {
            setcompPlanFields({
                ...compPlanFields,
                iscompPlanDataLoading: false,
            });
        }
    };
    const getStagehookTypesData = async () => {
        const listData = {
            in_tenant_id: cookiesData.out_tenant_id,
            in_db_role_key: cookiesData?.out_db_role_key,
            in_filter: "AND a.job_type_mstr_key=3",
            in_sort_number: DEFAULT_SORT.number,
            in_sort_order: DEFAULT_SORT.order,
            in_page_row_offset: 0,
            in_page_rows: defaultPageSize.recordTake,
        };
        let res = await GetStagehookTypesData(listData, dispatch, history);

        if (res.length > 0) {
            let tempArray = [];

            res.map((item) => {
                let obj = {};
                obj.text = item.job_name;
                obj.id = item.job_mstr_key;
                tempArray.push(obj);
                return 0;
            });

            setOriginStagehook(tempArray);
            setStagehookFields({
                ...stagehookFields,
                stagehookData: tempArray,
                stagehookValue: tempArray[0],
                isstagehookDataLoading: false,
            });
        } else {
            setStagehookFields({
                ...stagehookFields,
                stagehookData: [],
                isstagehookDataLoading: false,
            });
        }
    };

    const getPeriodsData = async (id) => {
        
        setPeriodFields({
            ...periodsFields,
            isPeriodDataLoading: true,
        });

        let periodsDataReq = {
            in_tenant_id: cookiesData.out_tenant_id,
            in_filter: `AND a.period_type_mstr_key = ${id}`,
            in_sort_number: null,
            in_sort_order: null,
            in_page_row_offset: 0,
            in_page_rows: 100,
            in_sort_number_concat:
                "a.period_end_date DESC, a.period_level_order DESC",
        };

        let resPeriods = await GetPeriodsDataList(
            periodsDataReq,
            dispatch,
            history
        );
        if (resPeriods.length > 0) {
            let tempArray = [];

            resPeriods.map((item) => {
                let obj = {};

                obj.text = item.period_name;
                obj.id = item.period_mstr_key;
                obj.in_period_start_date = item.period_start_date;
                obj.in_period_end_date = item.period_end_date;

                tempArray.push(obj);

                return 0;
            });

            setOriginPeriod(tempArray);

            setPeriodFields({
                ...periodsFields,
                periodsData: tempArray,
                periodsValue: tempArray[0],
                isPeriodDataLoading: false,
            });
        } else {
            setPeriodFields({
                periodsValue: { id: "", text: "" },
                periodsData: [],
                isPeriodDataLoading: false,
            });
        }
    };
    const getPeriodTypesData = async () => {
        let periodTypeData = {
            in_filter: "AND a.period_type_hierarchy_level IS NOT NULL",
            in_sort_number: 3,
            in_sort_order: null,
        };

        let res = await GetPeriodTypeAPI(periodTypeData, dispatch, history);


        if (res.length > 0) {
            let tempArray = [];

            res.map((item) => {
                let obj = {};
                obj.text = item.period_type_name;
                obj.id = item.period_type_mstr_key;

                tempArray.push(obj);
                return 0;
            });

            setOriginPeriodType(tempArray);

            //get period data from period type
            getPeriodsData(tempArray[0].id);

            setPeriodTypeFields({
                ...periodsTypeFields,
                periodTypeData: tempArray,
                periodTypeValue: tempArray[0],
                isPeriodTypeDataLoading: false,
            });
        } else {
            setPeriodTypeFields({
                ...periodsTypeFields,
                isPeriodTypeDataLoading: false,
            });
        }
    };
    const addJobQueueDataAsync = (bodyData) => {
        // setRefreshLoading(true);
        // setLoadState({ ...loadingState, isSaveData: false });

        POSTAPI(false, INSERT_JOB_QUEUE, null, header, bodyData, dispatch).then(
            (res) => {
                if (res.status) {
                    // setRefreshLoading(false);
                    if (res.data.out_parameters.out_ret_code > 0) {
                        dispatch(
                            setNewMessage({
                                message: res.data.out_parameters.out_error_msg,
                                type: "Error",
                                show: true,
                            })
                        );
                        errStatusHandler(res, history, dispatch);
                    } else {
                        // addJobRecurringJobdataDataAsync(insertRecurringJobData);
                        // setLoadState({ ...loadingState, isSaveData: true });
                    }
                }
            }
        );
    };
    const getRecurringTypesData = async () => {
        let recurringTypeData = {
            in_filter: "",
            in_sort_number: 1,
            in_sort_order: null,
        };

        let res = await GetRecurringType(recurringTypeData, dispatch, history);

        if (res.length > 0) {
            let tempArray = [];

            res.map((item) => {
                let obj = {};
                obj.text = item.recurring_type_name;
                obj.id = item.recurring_type_mstr_key;

                tempArray.push(obj);
                return 0;
            });

            setoriginRecurringType(tempArray);


            setRecurringTypeFields({
                ...recurringTypeFields,
                recurringTypeData: tempArray,
                recurringTypeValue: tempArray[0],
                isRecurringTypeDataLoading: false,
            });
        } else {
            setRecurringTypeFields({
                ...recurringTypeFields,
                isRecurringTypeDataLoading: false,
            });
        }
    };

    const addJobRecurringJobdataDataAsync = (bodyData) => {
        setRefreshLoading(true);
        setLoadState({ ...loadingState, isSaveData: false });

        POSTAPI(false, ADD_JOB_SCHEDULER, null, header, bodyData, dispatch).then(
            (res) => {
                if (res.status) {
                    setRefreshLoading(false);
                    if (res.data.out_parameters.out_ret_code > 0) {
                        dispatch(
                            setNewMessage({
                                message: res.data.out_parameters.out_error_msg,
                                type: "Error",
                                show: true,
                            })
                        );
                        errStatusHandler(res, history, dispatch);
                    } else {
                        setLoadState({ ...loadingState, isSaveData: true });
                    }
                }
            }
        );
    };


    return [
        {
            data,
            columns,
            dataCount,
            setData,
            editData,
            originalData,
            isAPICalling,
            setEditData,
            confirmationPopup,
            setConfirmationPopup,
            initialLoading,
            setAPICallingState,
            loadingState,
            setLoadState,
            setOriginalData,
            setRefreshLoading,
            isRefreshLoading,
            originJobType,
            jobTypeFields,
            setJobTypeFields,
            originCompPlan,
            compPlanFields,
            setcompPlanFields,
            stagehookFields,
            setStagehookFields,
            originStageHook,
            originPeriodType,
            periodsTypeFields,
            setPeriodTypeFields,
            originPeriod,
            periodsFields,
            setPeriodFields,
            setOriginalPostionData,
            setPostioncolumns,
            postioncolumns,
            postionData,
            setPostionData,
            postionCount,
            isPostionAPICalling,
            setPostionAPICallingState,
            initialPostionLoading,
            setIsCompPlanDisable,
            isCompPlanDisable,
            recurringTypeFields,
            setRecurringTypeFields,
            originRecurringType, 
            setoriginRecurringType,
            setDataCount,
            visible,
            setLoadingState,
            originalPostionData
        },
        {
          
            getJobTypesData,
            getCompPlanData,
            getStagehookTypesData,
            getPeriodTypesData,
            getPostionListDataAsync,
            addJobQueueDataAsync,
            getRecurringTypesData,
            getPeriodsData,
            addJobRecurringJobdataDataAsync
        }

    ];
}
