import { useMutation } from "react-query";
import { setNewMessage } from "src/redux/actions/setting-actions";
import { useDispatch } from "react-redux";
import React from "react";
import {
    mapDashboardPodData,
    getDashboardPod,
    setDashboardPublishEnable,
    getDashboardPublishEnable,
} from "src/store/dashboard.store";
import { getUserData, mapUserData } from "src/store/user.store";
import { TransformtDropdownUnique } from "src/components/form-wrapper";
import {
    DashboardBusinessGroupListApi,
    DashboardGroupListApi,
    DashboardProviderListApi,
    DashboardRoleAccessListApi,
    DashboardSpecialityListApi,
} from "src/service/dashboard.service";
import moment from "moment";
import {
    DashboardInsertTabApi,
    DashboardUpdateTabApi,
} from "src/service/dashboard.service";
import { GetCompPlanDataApi } from "src/lib/common-service";
import { useHistory } from "react-router";
import { getUserCredential } from "src/lib/cookies";
import {
    MULTI_SELECT_ALL_TEXT,
    MULTI_SELECT_ALL_ZERO_ID,
} from "src/constants/constants";

const { dbRoleKey, tenantId, userKey, acctEncryptKey } = mapUserData;
const { tabId } = mapDashboardPodData;

export default function DashTab(closeDialog, isEditForm) {
    const dispatch = useDispatch();
    const history = useHistory();
    const userData = getUserData();
    const cookiesData = getUserCredential();
    const getTenantId = tenantId.getData(userData) || "";
    const getUserKey = userKey.getData(userData) || "";
    const activeDashboardPod = isEditForm ? getDashboardPod() : {};
    const dashboardPublishEnable = getDashboardPublishEnable();
    const activeTabId = tabId.getData(activeDashboardPod);

    const defaultSelectAllOption = {
        text: MULTI_SELECT_ALL_TEXT,
        id: MULTI_SELECT_ALL_ZERO_ID,
    };
    const defaultSelfOption = {
        text: "self",
        id: MULTI_SELECT_ALL_ZERO_ID,
        key: MULTI_SELECT_ALL_ZERO_ID,
    };

    const [originRoleAccessData, setOriginRoleAccessData] = React.useState([]);
    const [originBgAccessData, setOriginBgAccessData] = React.useState([]);
    const [originCompPlanAccessData, setOriginCompPlanAccessData] =
        React.useState([]);
    const [originGroupFilterData, setOriginGroupFilterData] = React.useState(
        []
    );
    const [originSpecialtyFilterData, setOriginSpecialtyFilterData] =
        React.useState([]);
    const [originProviderFilterData, setOriginProviderFilterData] =
        React.useState([]);

    const [roleAccessDataFields, setRoleAccessDataFields] = React.useState({
        dropdownData: [],
        isDataLoading: true,
        isDataRefresh: false,
        isDataChanged: false,
    });

    const [bgAccessDataFields, setBgAccessDataFields] = React.useState({
        dropdownData: [],
        isDataLoading: true,
        isDataRefresh: false,
        isDataChanged: false,
        isDisabled: true,
    });

    const [compPlanDataFields, setCompPlanDataFields] = React.useState({
        dropdownData: [],
        isDataLoading: cookiesData?.out_db_role_type_abbr === "adm1",
        isDataRefresh: cookiesData?.out_db_role_type_abbr !== "adm1",
        isDataChanged: false,
        isDisabled: true,
    });

    const [groupFilterFields, setGroupFilterFields] = React.useState({
        dropdownData: [],
        isDataLoading: true,
        isDataRefresh: false,
        isDataChanged: false,
    });

    const [specialtyFilterFields, setSpecialtyFilterFields] = React.useState({
        dropdownData: [],
        isDataLoading: true,
        isDataRefresh: false,
        isDataChanged: false,
    });

    const [providerFilterFields, setProviderFilterFields] = React.useState({
        dropdownData: [],
        isDataLoading: true,
        isDataRefresh: false,
        isDataChanged: false,
    });

    const generateTempArray = (resName, type) => {
        let tempArray = [];

        if (!resName.length) {
            return tempArray;
        }

        if (type === "compPlan") {
            tempArray = TransformtDropdownUnique({
                data: resName,
                keyForLabel: "comp_plan_name",
                keyForValue: "comp_plan_mstr_key",
            });
        } else if (type === "roleAccess") {
            tempArray = TransformtDropdownUnique({
                data: resName,
                keyForLabel: "db_role_type_name",
                keyForValue: "db_role_type_key",
                keyForId: "db_role_type_abbr",
            });
        } else if (type === "businessGroup") {
            tempArray = TransformtDropdownUnique({
                data: resName,
                keyForLabel: "business_group_name",
                keyForValue: "business_group_mstr_key",
            });
        } else if (type === "groupFilter") {
            tempArray = TransformtDropdownUnique({
                data: resName,
                keyForLabel: "group_names",
                keyForValue: "group_mstr_keys",
            });
        } else if (type === "specialtyFilter") {
            tempArray = TransformtDropdownUnique({
                data: resName,
                keyForLabel: "specialty_names",
                keyForValue: "specialty_mstr_keys",
            });
        } else if (type === "providerFilter") {
            tempArray = TransformtDropdownUnique({
                data: resName,
                keyForLabel: "provider_names",
                keyForValue: "provider_mstr_keys",
            });
        }

        if (tempArray === null) {
            tempArray = [];
        }

        type === "roleAccess" && tempArray.unshift(defaultSelfOption);

        return tempArray;
    };

    const getValueFromIdArray = (data, key) => {
        if (!data?.length) {
            return [];
        }

        if (!activeDashboardPod?.[key || ""]) {
            return key === "role_type_access" ? [] : [defaultSelectAllOption];
        }

        const value = activeDashboardPod?.[key || ""];
        const listOfKey = value?.split(",").map(function (o) {
            return o.trim();
        });

        let getMultiDropDown = [];

        if (value === defaultSelectAllOption.id) {
            if (key === "role_type_access") {
                getMultiDropDown.push(defaultSelfOption);
            } else {
                getMultiDropDown.push(defaultSelectAllOption);
            }
        } else {
            data?.forEach((option) => {
                if (listOfKey.includes(String(option?.["id"] || ""))) {
                    getMultiDropDown.push(option);
                }
            });
        }

        return getMultiDropDown;
    };

    const getAdminRoleDataField = async () => {
        const dropdownName = "roleAccess";
        const list = await DashboardRoleAccessListApi();
        const tempArray = generateTempArray(list, dropdownName);

        let defaultVal = getValueFromIdArray(tempArray, "role_type_access");

        if (tempArray?.length && !defaultVal?.length) {
            defaultVal = tempArray?.filter(
                (o) => o.id === defaultSelfOption.id
            );
        }

        return {
            dropdownData: tempArray,
            isDataLoading: false,
            isDataRefresh: tempArray.length,
            [dropdownName]: defaultVal,
        };
    };

    const getOtherRoleDataField = async () => {
        const dropdownName = "roleAccess";
        const list = await DashboardRoleAccessListApi();
        const roleData = list.filter(
            (o) => o.db_role_type_abbr === cookiesData?.out_db_role_type_abbr
        );

        let tempArray = [];

        if (roleData.length) {
            tempArray = [
                {
                    text: roleData?.[0]?.db_role_type_name,
                    key: roleData?.[0]?.db_role_type_abbr,
                    id: roleData?.[0]?.db_role_type_key,
                },
            ];
        }

        return {
            dropdownData: tempArray,
            isDataLoading: false,
            isDataRefresh: tempArray.length ? true : false,
            [dropdownName]: tempArray,
        };
    };

    const getRoleAccessList = async () => {
        let dataField;
        if (cookiesData?.out_db_role_type_abbr === "adm1") {
            dataField = await getAdminRoleDataField();
        } else {
            dataField = await getOtherRoleDataField();
        }

        setOriginRoleAccessData(dataField.dropdownData);
        setRoleAccessDataFields(dataField);
    };

    const getAdminBusinessGroupDataField = async () => {
        const dropdownName = "businessGroup";
        const params = { tenantId: getTenantId || null };

        const list = await DashboardBusinessGroupListApi(params);
        const tempArray = generateTempArray(list, dropdownName);

        let defaultVal = getValueFromIdArray(
            tempArray,
            "business_group_access"
        );

        if (tempArray?.length && !defaultVal?.length) {
            defaultVal = tempArray.filter(
                (o) => o.id === defaultSelectAllOption.id
            );
        }

        return {
            dropdownData: tempArray,
            isDataLoading: false,
            isDataRefresh: true,
            isDisabled: true,
            [dropdownName]: defaultVal,
        };
    };

    const getOtherBusinessGroupDataField = async () => {
        const dropdownName = "businessGroup";
        const params = {
            tenantId: getTenantId || null,
            filter: " AND a.owner_user_key = " + getUserKey || 0,
        };

        const list = await DashboardBusinessGroupListApi(params);

        let tempArray = generateTempArray(list, dropdownName);

        tempArray = tempArray?.filter(
            (o) => o.id !== defaultSelectAllOption.id
        );

        let defaultVal = tempArray?.length
            ? tempArray
            : [defaultSelectAllOption];

        return {
            dropdownData: tempArray,
            isDataLoading: false,
            isDataRefresh: true,
            isDisabled: true,
            [dropdownName]: defaultVal,
        };
    };

    const getBusinessGroupList = async () => {
        let dataField;

        if (cookiesData?.out_db_role_type_abbr === "adm1") {
            dataField = await getAdminBusinessGroupDataField();
        } else {
            dataField = await getOtherBusinessGroupDataField();
        }

        setOriginBgAccessData(dataField.dropdownData);
        setBgAccessDataFields(dataField);
    };

    const getCompPlanDataField = async () => {
        const dropdownName = "compPlan";
        const dataObjValue = {
            in_tenant_id: getTenantId || null,
            in_user_key: getUserKey || null,
            in_filter: null,
            in_sort_number: null,
            in_sort_order: null,
            in_multi_sort_order:
                "a.comp_plan_name ASC, a.period_start_date DESC",
        };

        const list = await GetCompPlanDataApi(dataObjValue, history, dispatch);
        const tempArray = generateTempArray(list, dropdownName);

        let defaultVal = getValueFromIdArray(tempArray, "comp_plan_access");

        if (tempArray?.length && !defaultVal?.length) {
            defaultVal = tempArray.filter(
                (o) => o.id === defaultSelectAllOption.id
            );
        }

        return {
            dropdownData: tempArray,
            isDataLoading: false,
            isDataRefresh: true,
            isDisabled: true,
            [dropdownName]: defaultVal,
        };
    };

    const getCompPlanList = async () => {
        const dataField = await getCompPlanDataField();

        setOriginCompPlanAccessData(dataField.dropdownData);
        setCompPlanDataFields(dataField);
    };

    const mapAndJoinId = (arr) => {
        return arr?.length ? arr?.map((o) => o.id)?.join(",") : null;
    };

    const getSelectedRoleAccess = () => {
        return roleAccessDataFields?.roleAccess || [];
    };

    const getTabAccessParams = () => {
        const filterObj = {
            roleFilter: getSelectedRoleAccess(),
            bgFilter: bgAccessDataFields?.businessGroup || [],
            compPlanFilter: compPlanDataFields?.compPlan || [],
        };

        const roleFilter = filterObj?.roleFilter;
        const paramsObj = {};

        paramsObj.roleTypeAccess = mapAndJoinId(roleFilter);
        paramsObj.businessGroupAccess = mapAndJoinId(filterObj?.bgFilter);
        paramsObj.compPlanAccess = mapAndJoinId(filterObj?.compPlanFilter);

        if (
            !roleFilter.find((o) => o.key === "bgm1") &&
            paramsObj.businessGroupAccess
        ) {
            paramsObj.businessGroupAccess = defaultSelectAllOption.id;
        }

        if (
            !roleFilter.find((o) => o.key === "prv1") &&
            paramsObj.compPlanAccess
        ) {
            paramsObj.compPlanAccess = defaultSelectAllOption.id;
        }

        return paramsObj;
    };

    const getGroupDataField = async () => {
        const roleFilter = getSelectedRoleAccess();
        const dropdownName = "groupFilter";

        if (!roleFilter.length) {
            return {
                dropdownData: [],
                isDataLoading: false,
                isDataRefresh: true,
                [dropdownName]: [],
            };
        }

        const paramsObj = {
            tenantId: getTenantId || null,
            userKey: getUserKey || null,
            ...getTabAccessParams(),
        };

        const list = await DashboardGroupListApi(paramsObj);
        const tempArray = generateTempArray(list, dropdownName);

        let defaultVal = getValueFromIdArray(tempArray, "group_filter");

        if (tempArray?.length && !defaultVal?.length) {
            defaultVal = tempArray.filter(
                (o) => o.id === defaultSelectAllOption.id
            );
        }

        return {
            dropdownData: tempArray,
            isDataLoading: false,
            isDataRefresh: true,
            [dropdownName]: defaultVal,
        };
    };

    const getGroupFilterList = async () => {
        const dataField = await getGroupDataField();

        setOriginGroupFilterData(dataField.dropdownData);
        setGroupFilterFields(dataField);
    };

    const getSpecialtyDataField = async () => {
        const roleFilter = getSelectedRoleAccess();
        const groupFilter = groupFilterFields?.groupFilter || [];
        const dropdownName = "specialtyFilter";

        if (!roleFilter.length || !groupFilter.length) {
            return {
                dropdownData: [],
                isDataLoading: false,
                isDataRefresh: true,
                [dropdownName]: [],
            };
        }

        const paramsObj = {
            tenantId: getTenantId || null,
            userKey: getUserKey || null,
            groupFilter: mapAndJoinId(groupFilter),
            ...getTabAccessParams(),
        };

        const list = await DashboardSpecialityListApi(paramsObj);
        const tempArray = generateTempArray(list, dropdownName);

        let defaultVal = getValueFromIdArray(tempArray, "specialty_filter");

        if (tempArray?.length && !defaultVal?.length) {
            defaultVal = tempArray.filter(
                (o) => o.id === defaultSelectAllOption.id
            );
        }

        return {
            dropdownData: tempArray,
            isDataLoading: false,
            isDataRefresh: true,
            [dropdownName]: defaultVal,
        };
    };

    const getSpecialtyFilterList = async () => {
        const dataField = await getSpecialtyDataField();

        setOriginSpecialtyFilterData(dataField.dropdownData);
        setSpecialtyFilterFields(dataField);
    };

    const getProviderDataField = async () => {
        const roleFilter = getSelectedRoleAccess();
        const specialtyFilter = specialtyFilterFields?.specialtyFilter || [];
        const dropdownName = "providerFilter";

        if (!roleFilter.length || !specialtyFilter.length) {
            return {
                dropdownData: [],
                isDataLoading: false,
                isDataRefresh: true,
                [dropdownName]: [],
            };
        }

        const paramsObj = {
            tenantId: getTenantId || null,
            userKey: getUserKey || null,
            acctEncryptKey: acctEncryptKey.getData(userData),
            groupFilter: mapAndJoinId(groupFilterFields?.groupFilter),
            specialtyFilter: mapAndJoinId(specialtyFilter),
            ...getTabAccessParams(),
        };

        const list = await DashboardProviderListApi(paramsObj);
        const tempArray = generateTempArray(list, dropdownName);

        let defaultVal = getValueFromIdArray(tempArray, "provider_filter");

        if (tempArray?.length && !defaultVal?.length) {
            defaultVal = tempArray.filter(
                (o) => o.id === defaultSelectAllOption.id
            );
        }

        return {
            dropdownData: tempArray,
            isDataLoading: false,
            isDataRefresh: true,
            [dropdownName]: defaultVal,
        };
    };

    const getProviderFilterList = async () => {
        const dataField = await getProviderDataField();

        setOriginProviderFilterData(dataField.dropdownData);
        setProviderFilterFields(dataField);
    };

    const onSubmit = useMutation({
        mutationFn: async ({ formFields, isEditForm }) => {
            const request = {
                userKey: getUserKey,
                dbRoleKey: dbRoleKey.getData(userData),
                tenantId: getTenantId,
                dashboardTabMstrKey: tabId.getData(activeDashboardPod),
                periodStartDate: moment(formFields?.periodStart).format(
                    "YYYY-MM-DD 00:00:00"
                ),
                periodEndDate: moment(formFields?.periodEnd).format(
                    "YYYY-MM-DD 00:00:00"
                ),
                labelName: formFields?.labelName,
                tabOrder: formFields?.tabOrder,
                theme: formFields?.theme?.value,
                reportPublishedFlag: Boolean(formFields?.isPublished),
                roleTypeAccess: mapAndJoinId(getSelectedRoleAccess()),
                businessGroupAccess: mapAndJoinId(
                    bgAccessDataFields?.businessGroup || []
                ),
                compPlanAccess: mapAndJoinId(
                    compPlanDataFields?.compPlan || []
                ),
                groupFilter: mapAndJoinId(groupFilterFields?.groupFilter || []),
                specialtyFilter: mapAndJoinId(
                    specialtyFilterFields?.specialtyFilter || []
                ),
                providerFilter: mapAndJoinId(
                    providerFilterFields?.providerFilter || []
                ),
            };

            if (isEditForm) {
                await DashboardUpdateTabApi(request);
                const selectedTabId = tabId.getData(activeDashboardPod);
                return { selectedTabId, isEditForm };
            } else {
                const selectedTabId = await DashboardInsertTabApi(request);
                return { selectedTabId };
            }
        },
        onSuccess({ selectedTabId }) {
            closeDialog(selectedTabId);
            dashboardPublishEnable[activeTabId] = true;
            setDashboardPublishEnable({ ...dashboardPublishEnable });
        },
        onError(error) {
            dispatch(
                setNewMessage({
                    message: error || "Oops! Something went wrong ...",
                    type: "error",
                    show: true,
                })
            );
        },
    });

    return [
        {
            defaultSelectAllOption,
            defaultSelfOption,
            roleAccessDataFields,
            setRoleAccessDataFields,
            bgAccessDataFields,
            setBgAccessDataFields,
            compPlanDataFields,
            setCompPlanDataFields,
            groupFilterFields,
            setGroupFilterFields,
            specialtyFilterFields,
            setSpecialtyFilterFields,
            providerFilterFields,
            setProviderFilterFields,
            activeDashboardPod,
            originRoleAccessData,
            originBgAccessData,
            originCompPlanAccessData,
            originGroupFilterData,
            originSpecialtyFilterData,
            originProviderFilterData,
            mapAndJoinId,
        },
        {
            getRoleAccessList,
            getBusinessGroupList,
            getCompPlanList,
            getGroupFilterList,
            getSpecialtyFilterList,
            getProviderFilterList,
            onSubmit,
        },
    ];
}
