import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { Input } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import { getUserCredential } from "../../lib/cookies";
import useApi from "./service";
import {
  fetchCreditResultNameData,
  fetchCustomTableData,
  fetchCustomTableInputFieldData,
  fetchIncentiveResultNameData,
  fetchMeasurementResultNameData,
  fetchPaymentResultNameData,
  fetchPeriodTypeData,
  fetchRateTableData,
  fetchRateTypeData,
  fetchResultTypeData,
  fetchReturnFieldData,
  fetchReturnFieldProrationData,
  fetchRuleResultNameData,
  fetchRuleTypeData,
} from "./formula-helpers";
import { useDispatch, useSelector } from "react-redux";
import { DialogComponent } from "src/components/dialog";
import {
  CONFIRMATION_BTN_ACTIONS,
  ENTITY_REF_LEVEL,
  RULE_COND_FLAG,
  SELECT_DUMMY_DATA,
} from "src/constants/constants";
import { fetchCustomTableInputFieldDataSelector } from "src/redux/selectors/custom-selector";

const SystemFxElement = (props) => {
  const {
    item: elementItem,
    pageProps,
    onDataPopulate,
    onSelectChange,
  } = props;

  const cookiesData = getUserCredential();
  const dispatch = useDispatch();

  const [confirmation, setConfirmation] = useState({
    title: "Add",
    desc: "Are you sure you want to add item?",
    isVisible: false,
    type: "",
    actionBtn: [],
    data: {},
    action: "add",
  });

  const [, Action] = useApi();
  const [filterText, setFilterText] = useState("");
  const [selectBoxData, setSelectBoxData] = useState([]);
  const allSelectBoxData = useRef([]);
  const [isLoading, setIsLoading] = useState(false);
  const [elementValue, setElementValue] = useState(elementItem.value || "");
  const [errorMsg, setErrorMsg] = useState("");
  const [err, setErr] = useState(false);

  const { customTableInputFieldList } = useSelector(
    fetchCustomTableInputFieldDataSelector
  );

  const onInputChange = (event) => {
    setElementValue(event.target.value);
    if (elementItem.input_control_type === "number") {
      validateNumberField(event.target.value);
    } else if (elementItem.input_control_type !== "select") {
      validateStringField(event.target.value);
    }
  };

  const validateNumberField = (val) => {
    if (isNaN(val) || val === "") {
      setErr(true);
      setErrorMsg("Please enter valid number.");
    } else {
      setErr(false);
      setErrorMsg("");
    }
  };

  const validateStringField = (val) => {
    if (!val.trim()) {
      setErr(true);
      setErrorMsg(`${elementItem.system_fx_parm_label} is required.`);
    } else {
      setErr(false);
      setErrorMsg("");
    }
  };

  const onFilterChange = (event) => {
    setFilterText(event.filter.value);
    setSelectBoxData(filterBy(allSelectBoxData.current.slice(), event.filter));
  };

  const spInsertUdfDataObject = async (text) => {
    const finalText = text.replace(/['"]+/g, "");
    const bodyData = {
      in_user_id: cookiesData.out_user_id, //user_id
      in_tenant_id: cookiesData.out_tenant_id, // tenant_id
      in_udf_data_obj_field_name: finalText,
      in_udf_data_obj_field_label: "custom_string_value",
      in_udf_data_obj_label: "custom_string_value",
      in_rfct_field_name: "custom_string_value",
      in_rfct_field_metadata: "VARCHAR(75) NULL",
      in_custom_fld_col_mstr_key: 0,
    };
    try {
      const response = await Action.spInsertUdfDataObjectAsync(bodyData);
      if (response) {
        fetchCustomTableInputFieldData(dispatch).then((res) => {
          const foundItem = res.find((item) => item.text === finalText);
          if (foundItem) {
            onSelectChange?.({
              ...elementItem,
              value: foundItem,
              errorMsg: "",
              error: false,
            });
          }
        });
      }
    } catch (error) {
    } finally {
      onCancelConfirm();
    }
  };

  // const itemRender = (li) => {
  //   const itemChildren = <span>{li.props.children}</span>;
  //   return cloneElement(li, li.props, itemChildren);
  // };

  const onCancelConfirm = () => {
    setConfirmation({ ...confirmation, isVisible: false });
  };

  const fetchPeriodTypeItems = async () => {
    const periodTypeData = await fetchPeriodTypeData(dispatch);
    if (
      elementItem.system_fx_mstr_key === 4 ||
      elementItem.system_fx_mstr_key === 5 ||
      elementItem.system_fx_mstr_key === 6 ||
      elementItem.system_fx_mstr_key === 7
    ) {
      return [...periodTypeData, { text: "cur_mth", value: "cur_mth" }];
    }
    return periodTypeData;
  };

  const fetchDropdownData = async () => {
    try {
      setIsLoading(true);
      switch (elementItem.system_fx_parm_name) {
        case "goal_mstr_key":
          onDataPopulate?.(SELECT_DUMMY_DATA, null);
          break;
        case "period_freq":
          const periodItems = await fetchPeriodTypeItems();
          onDataPopulate?.(
            periodItems,
            periodItems.find((it) => it.value === elementItem.value) ||
              periodItems[0]
          );
          break;
        case "rule_cond_flag":
          onDataPopulate?.(
            RULE_COND_FLAG,
            RULE_COND_FLAG.find(
              (it) => Number(it.value) === Number(elementItem.value)
            ) || RULE_COND_FLAG[0]
          );
          break;
        case "rate_table_mstr_key":
          const rateTableData = await fetchRateTableData(dispatch);
          onDataPopulate?.(
            rateTableData,
            rateTableData.find(
              (it) => Number(it.value) === Number(elementItem.value)
            ) || rateTableData[0]
          );
          break;
        case "rate_type_name":
          const rateTypeData = await fetchRateTypeData(dispatch);
          onDataPopulate?.(
            rateTypeData,
            rateTypeData.find((it) => it.value === elementItem.value) ||
              rateTypeData[0]
          );
          break;
        case "result_name_mstr_key":
          const ruleResultNameData = await fetchRuleResultNameData(dispatch);
          onDataPopulate?.(
            ruleResultNameData,
            ruleResultNameData.find(
              (it) => Number(it.value) === Number(elementItem.value)
            ) || ruleResultNameData[0]
          );
          break;
        case "credit_result_name_mstr_key":
          const creditResultNameData = await fetchCreditResultNameData(
            dispatch
          );
          onDataPopulate?.(
            creditResultNameData,
            creditResultNameData.find(
              (it) => Number(it.value) === Number(elementItem.value)
            ) || creditResultNameData[0]
          );
          break;
        case "measurement_result_name_mstr_key":
          const measurementResultNameData =
            await fetchMeasurementResultNameData(dispatch);
          onDataPopulate?.(
            measurementResultNameData,
            measurementResultNameData.find(
              (it) => Number(it.value) === Number(elementItem.value)
            ) || measurementResultNameData[0]
          );
          break;
        case "incentive_result_name_mstr_key":
          const incentiveResultNameData = await fetchIncentiveResultNameData(
            dispatch
          );

          onDataPopulate?.(
            incentiveResultNameData,
            incentiveResultNameData.find(
              (it) => Number(it.value) === Number(elementItem.value)
            ) || incentiveResultNameData[0]
          );
          break;
        case "payment_result_name_mstr_key":
          const paymentResultNameData = await fetchPaymentResultNameData(
            dispatch
          );

          onDataPopulate?.(
            paymentResultNameData,
            paymentResultNameData.find(
              (it) => Number(it.value) === Number(elementItem.value)
            ) || paymentResultNameData[0]
          );
          break;
        case "rule_type_mstr_key":
          const ruleTypeData = await fetchRuleTypeData(dispatch);
          onDataPopulate?.(
            ruleTypeData,
            ruleTypeData.find(
              (it) => Number(it.value) === Number(elementItem.value)
            ) || ruleTypeData[0]
          );
          break;
        case "result_type_mstr_key":
          const resultTypeData = await fetchResultTypeData(dispatch);
          onDataPopulate?.(
            resultTypeData,
            resultTypeData.find(
              (it) => Number(it.value) === Number(elementItem.value)
            ) || resultTypeData[0]
          );
          break;
        case "entity_ref_level":
          onDataPopulate?.(
            ENTITY_REF_LEVEL,
            ENTITY_REF_LEVEL.find((it) => it.value === elementItem.value) ||
              ENTITY_REF_LEVEL[0]
          );
          break;
        case "return_field_result_rate":
          const returnFieldData = await fetchReturnFieldData(dispatch);

          onDataPopulate?.(
            returnFieldData,
            returnFieldData.find((it) => it.value === elementItem.value) || null
          );

          break;
        case "return_field_proration_result":
          const returnFieldProrationData = await fetchReturnFieldProrationData(
            dispatch
          );

          onDataPopulate?.(
            returnFieldProrationData,
            returnFieldProrationData.find(
              (it) => it.value === elementItem.value
            ) || null
          );
          break;
        case "custom_table_mstr_key":
          const customTableData = await fetchCustomTableData(
            dispatch,
            cookiesData?.out_db_role_key,
            elementItem.system_fx_name
          );
          const selectedTable =
            customTableData.find(
              (it) => it.value === Number(elementItem.value)
            ) || customTableData[0];
          onDataPopulate?.(customTableData, selectedTable);
          break;
        case "input_field1":
        case "input_field2":
        case "input_field3":
        case "input_field4":
        case "input_field5":
          let inputFieldList = customTableInputFieldList;
          if (
            elementItem.system_fx_parm_name === "input_field1" ||
            elementItem.system_fx_parm_name === "input_field2"
          ) {
            if (!inputFieldList.find((it) => it.value === "NA")) {
              inputFieldList = [...inputFieldList, { text: "NA", value: "NA" }];
            }
          }

          onDataPopulate?.(
            inputFieldList,
            inputFieldList.find((it) => it.value === elementItem.value) ||
              inputFieldList[0]
          );
          return;

        default:
          return;
      }
      // setIsLoading(false);
    } catch (error) {
      //console.error(error);
    }
  };

  useEffect(() => {
    setErr(elementItem.error || false);
    setErrorMsg(elementItem.errorMsg || "");
    setElementValue(elementItem.value);
    if (
      elementItem.system_fx_parm_name === "input_field1" ||
      elementItem.system_fx_parm_name === "input_field2" ||
      elementItem.system_fx_parm_name === "input_field3" ||
      elementItem.system_fx_parm_name === "input_field4" ||
      elementItem.system_fx_parm_name === "input_field5"
    ) {
      allSelectBoxData.current = customTableInputFieldList;
      setSelectBoxData(customTableInputFieldList);
    } else {
      allSelectBoxData.current = elementItem.data;
      setSelectBoxData(elementItem.data);
    }
  }, [elementItem, customTableInputFieldList]);

  useEffect(() => {
    if (
      !elementItem.data.length &&
      elementItem.value !== null &&
      typeof elementItem.value !== "object" &&
      elementItem.input_control_type === "select"
    ) {
      fetchDropdownData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementItem.data]);

  if (elementItem.input_control_type === "number") {
    return (
      <div style={{ width: "20%", margin: "10px" }}>
        <Tooltip anchorElement="target" position="right" openDelay={100}>
          <span
            style={{ fontSize: 12 }}
            title={elementItem.system_fx_parm_desc}
          >{`${elementItem.system_fx_parm_label}${
            elementItem.is_required === 1 ? "*" : ""
          }:`}</span>
        </Tooltip>
        <Input
          type="number"
          name={elementItem.system_fx_parm_name}
          value={elementValue}
          defaultValue="0"
          onChange={(e) => onInputChange(e)}
          style={{ width: "100%" }}
          onBlur={() => {
            onSelectChange({
              ...elementItem,
              value: elementValue,
              error: err,
              errorMsg: errorMsg,
            });
          }}
          disabled={pageProps.writeAccess === 0 ? true : false}
        />
        {err ? (
          <Label style={{ fontSize: 11, color: "red" }}>{errorMsg}</Label>
        ) : null}
      </div>
    );
  }

  if (elementItem.input_control_type === "select") {
    return (
      <div
        style={{
          width: "20%",
          margin: "10px",
          display:
            elementItem.system_fx_parm_name === "rule_cond_flag"
              ? "none"
              : "inline",
        }}
      >
        <Tooltip anchorElement="target" position="right" openDelay={100}>
          <span
            style={{ fontSize: 12 }}
            title={elementItem.system_fx_parm_desc}
          >{`${elementItem.system_fx_parm_label}${
            elementItem.is_required === 1 ? "*" : ""
          }:`}</span>
        </Tooltip>
        <DropDownList
          value={elementItem.value}
          name={`${elementItem.elementId}`}
          id={`${elementItem.elementId}`}
          data={selectBoxData}
          dataItemKey="value"
          textField="text"
          filterable
          filter={filterText}
          loading={isLoading}
          onChange={(event) => {
            setElementValue(event.value);

            onSelectChange?.({
              ...elementItem,
              value: event.value,
              errorMsg: "",
              error: false,
            });
          }}
          onFilterChange={onFilterChange}
          listNoDataRender={(element) => {
            if (
              elementItem.system_fx_parm_name === "input_field1" ||
              elementItem.system_fx_parm_name === "input_field2" ||
              elementItem.system_fx_parm_name === "input_field3" ||
              elementItem.system_fx_parm_name === "input_field4" ||
              elementItem.system_fx_parm_name === "input_field5"
            ) {
              const noData = (
                <span>
                  <br />
                  <h5>
                    NO DATA FOUND. DO YOU WANT TO ADD NEW FIELD - '{filterText}'
                  </h5>
                  <br />
                  <Button
                    className="primarybtn"
                    onClick={() => {
                      setConfirmation({
                        ...confirmation,
                        isVisible: true,
                        actionBtn: CONFIRMATION_BTN_ACTIONS(
                          onCancelConfirm,
                          () => {
                            spInsertUdfDataObject(filterText);
                          }
                        ),
                      });
                    }}
                  >
                    Add new field
                  </Button>
                </span>
              );
              return React.cloneElement(element, { ...element.props }, noData);
            } else {
              return <h4>No Data Found</h4>;
            }
          }}
          disabled={
            elementItem.disabled || pageProps.writeAccess === 0 ? true : false
          }
        />
        {err ? (
          <Label style={{ fontSize: 11, color: "red" }}>{errorMsg}</Label>
        ) : null}
        <DialogComponent
          title={confirmation.title}
          onCancelConfirm={onCancelConfirm}
          width={"450"}
          height={"auto"}
          className={"Dialog-Delete"}
          desc={confirmation.desc}
          visible={confirmation.isVisible}
          actionBtn={confirmation.actionBtn}
          titleClassName={"unSave-confirm"}
        />
      </div>
    );
  }

  return (
    <div style={{ width: "20%", margin: "10px" }}>
      <Tooltip anchorElement="target" position="right" openDelay={100}>
        <span
          style={{ fontSize: 12 }}
          title={elementItem.system_fx_parm_desc}
        >{`${elementItem.system_fx_parm_label}${
          elementItem.is_required === 1 ? "*" : ""
        }:`}</span>
      </Tooltip>
      <Input
        type="text"
        name={elementItem.system_fx_parm_name}
        value={elementValue}
        defaultValue=""
        onChange={(e) => onInputChange(e)}
        style={{ width: "100%" }}
        onBlur={() => {
          onSelectChange({
            ...elementItem,
            value: elementValue,
            error: err,
            errorMsg: errorMsg,
          });
        }}
        disabled={pageProps.writeAccess === 0 ? true : false}
      />
      {err ? (
        <Label style={{ fontSize: 11, color: "red" }}>{errorMsg}</Label>
      ) : null}
    </div>
  );
};

export default React.memo(SystemFxElement);
