import React from "react";
import { Form, FormElement, FieldWrapper } from "@progress/kendo-react-form";
import { Label, Error } from "@progress/kendo-react-labels";
import CustomDropdown from "../../../components/customDropdown";
import { Input } from "@progress/kendo-react-inputs";
import { getUserCredential } from "../../../lib/cookies";
import {
  GetPeriodTypeAPI,
  GetPeriodsDataList,
} from "../../../lib/common-service";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import useApi from "./service";
import { setResetState } from "../../../redux/actions/rule-library-actions";
import { setNewMessage } from "src/redux/actions/setting-actions";

const initialFormFields = {
  in_rule_name: "",
  in_rule_description: "",
};

const AddRule = (restProps) => {
  const { closePopup } = restProps;
  const cookiesData = getUserCredential();
  const dispatch = useDispatch();
  const history = useHistory();

  let periodTypeRef = React.useRef(null)
  let effPeriodRef = React.useRef(null)
  let ruleTypeRef = React.useRef(null)

  const [periodsFields, setPeriodFields] = React.useState({
    periodsValue: {
      id: "",
      text: "",
    },
    periodsData: [],
    isPeriodDataLoading: true,
  });
  const [periodsList, setPeriodsList] = React.useState([]);
  const [periodsTypeFields, setPeriodTypeFields] = React.useState({
    periodTypeValue: {
      in_period_mstr_key: "",
      in_period_start_date: "",
      in_period_end_date: "",
    },
    periodTypeData: [],
    isPeriodTypeDataLoading: true,
  });
  const [ruleTypeList, setRuleTypeList] = React.useState([]);
  const [ruleTypeFields, setRuleTypeFields] = React.useState({
    ruleTypeValue: {},
    typeData: [],
    isRuleTypeLoading: true,
  });
  const [periodTypeList, setPeriodTypeList] = React.useState([]);
  const [formFields, setFormFields] = React.useState(initialFormFields);
  const [errorObj, setErrorObj] = React.useState({});
  const [, Action] = useApi();

  /**
   * Get Periods Data Type
   */
  const getPeriodsDataType = async () => {
    let periodTypeData = {
      in_filter: "AND a.period_type_hierarchy_level IS NOT NULL",
      in_sort_number: null,
      in_sort_order: null,
    };
    let res = await GetPeriodTypeAPI(periodTypeData, dispatch, history);
    if (res.length > 0) {
      let tempArray = [];
      res.map((item) => {
        let obj = {};
        obj.text = item.period_type_name;
        obj.id = item.period_type_mstr_key;
        tempArray.push(obj);
        return 0;
      });
      getPeriodsDataList(tempArray[0].id);
      setPeriodTypeList(tempArray);
      setPeriodTypeFields({
        ...periodsTypeFields,
        periodTypeData: tempArray,
        periodTypeValue: tempArray[0],
        isPeriodTypeDataLoading: false,
      });
    } else {
      setPeriodTypeFields({
        ...periodsTypeFields,
        isPeriodTypeDataLoading: false,
      });
    }
  };

  /**
   * Get Rule Type List
   */
  const getRuleType = async () => {
    let periodTypeData = {
      in_rule_type_name: null,
    };
    let res = await Action.getRuleType(periodTypeData, dispatch, history);
    if (res.length > 0) {
      let tempArray = [];
      res.filter((x)=>x.rule_config_table_name !== "").map((item) => {
        let obj = {};
        obj.text = item.rule_type_name;
        obj.id = item.rule_type_mstr_key;
        tempArray.push(obj);
        return 0;
      });
      setRuleTypeList(tempArray);
      setRuleTypeFields({
        ...ruleTypeFields,
        ruleTypeData: tempArray,
        ruleTypeValue: tempArray[0],
        isRuleTypeLoading: false,
      });
    } else {
      setRuleTypeFields({
        ...ruleTypeFields,
        isRuleTypeLoading: false,
      });
    }
  };

  React.useEffect(() => {
    getPeriodsDataType();
    getRuleType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   *
   * Get Period data List
   */
  const getPeriodsDataList = async (id) => {
    setPeriodFields({
      ...periodsFields,
      isPeriodDataLoading: true,
    });
    let periodsDataReq = {
      in_tenant_id: cookiesData.out_tenant_id,
      in_filter: `AND a.period_type_mstr_key = ${id}`,
      in_sort_number: null,
      in_sort_order: null,
      in_page_row_offset: 0,
      in_page_rows: 100,
      in_sort_number_concat:
        "a.period_end_date DESC, a.period_level_order DESC",
    };
    let resPeriods = await GetPeriodsDataList(
      periodsDataReq,
      dispatch,
      history
    );
    if (resPeriods.length > 0) {
      let tempArray = [];
      resPeriods.map((item) => {
        let obj = {};
        obj.text = item.period_name;
        obj.id = item.period_mstr_key;
        obj.in_period_start_date = item.period_start_date;
        obj.in_period_end_date = item.period_end_date;
        tempArray.push(obj);
        return 0;
      });
      setPeriodsList(tempArray);
      setPeriodFields({
        ...periodsFields,
        periodsData: tempArray,
        periodsValue: tempArray[0],
        isPeriodDataLoading: false,
      });
    } else {
      setPeriodFields({
        ...periodsFields,
        isPeriodDataLoading: false,
      });
      setPeriodsList([]);
    }
  };

  /**
   * Handle on close Popup
   */
  const onClosePopup = (isGridRefresh) => {
    closePopup(isGridRefresh);
  };

  const handleCancel = () => {
    onClosePopup(false);
  };

  /**
   * Handle Save method
   */
  const handleSave = async () => {
    let isValid = handleValidation();
    if (isValid) {
      let reqData = {
        in_user_id: cookiesData.out_user_id,
        in_tenant_id: cookiesData.out_tenant_id,
        in_rule_config_key: null,
        in_date_version: null,
        in_rule_name: formFields.in_rule_name,
        in_rule_desc: formFields.in_rule_description,
        in_rule_type_mstr_key: ruleTypeFields.ruleTypeValue.id,
        in_period_mstr_key: periodsFields.periodsValue.id,
        in_period_type_mstr_key: periodsTypeFields.periodTypeValue.id,
        in_flag: "insertNewRule",
        in_current_version: 0,
        in_insert_id: 0,
        in_version: 0,
      };
      let response = await Action.addDataAsync(reqData);
      if (response) {
        onClosePopup(true);
        dispatch(setResetState());
      }
    }
  };

  /**
   *
   * handle Field Change
   */
  const handleFieldChange = React.useCallback(
    (e) => {
      const name = e.name ? e.name : e.target.name;
      if (e.value !== null) {
        setFormFields({ ...formFields, [name]: e.value });
        setErrorObj({ ...errorObj, [name]: "" });
      }
    },
    [formFields, errorObj]
  );

  /**
   * Handle Validation
   */
  const handleValidation = () => {
    let isValid = true;
    let errObj = {};
    if (formFields.in_rule_name.trim() === "") {
      isValid = false;
      errObj.in_rule_name = "Please enter rule name";
    }
    setErrorObj({ ...errObj });
    return isValid;
  };

  /**
   * Show Error message
   */
  const ShowError = React.useCallback(
    (props) => {
      return <Error>{errorObj[props.name]}</Error>;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errorObj]
  );

  const itemRender = (li, itemProps) => {
    const itemChildren = (
      <span
      className="text-capitalize"
      >
        {li.props.children}
      </span>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };

  return (
    <Form
      render={() => (
        <FormElement className="emailForm" horizontal={true}>
          
            <FieldWrapper>
              
                <div className={"k-form-field-wrap-popup"}>
                  <Label>{"Rule Name* : "}</Label>
                  <Input
                    type="text"
                    name="in_rule_name"
                    value={formFields.in_rule_name}
                    onChange={(e) => {
                      handleFieldChange(e);
                    }}
                    autoFocus={true}
                    autoComplete={false}
                  />
                  <ShowError name="in_rule_name" />
                </div>
             
            </FieldWrapper>

            <FieldWrapper>
              <div className={"k-form-field-wrap-popup"} ref={periodTypeRef}>
                <Label>{"Period Type* : "}</Label>
                <CustomDropdown
                  name={"periodTypeValue"}
                  data={periodsTypeFields.periodTypeData}
                  textField="text"
                  onOpen={(dataValue) => {
                    setPeriodTypeFields({
                      ...periodsTypeFields,
                      periodTypeData: dataValue,
                    });
                  }}
                  dataItemKey="id"
                  valueData={periodTypeList.find(
                    (c) => c.text === periodsTypeFields.periodTypeValue.text
                  )}
                  onChange={(e, name) => {
                    setPeriodTypeFields({
                      ...periodsTypeFields,
                      [name]: e,
                    });
                    getPeriodsDataList(e.id);
                  }}
                  filterable={true}
                  onFilterChange={(dataValue) => {
                    setPeriodTypeFields({
                      ...periodsTypeFields,
                      periodTypeData: dataValue,
                    });
                  }}
                  autoClose={false}
                  autoBind={true}
                  isSelectALl={true}
                  dataList={periodTypeList}
                  loading={periodsTypeFields.isPeriodTypeDataLoading}
                  appendTo={periodTypeRef.current}
                />
              </div>
            </FieldWrapper>

            <FieldWrapper>
              <div className={"k-form-field-wrap-popup"} ref={effPeriodRef}>
                <Label>{"Effective Period* : "}</Label>
                <CustomDropdown
                  name={"periodsValue"}
                  data={periodsFields.periodsData}
                  textField="text"
                  onOpen={(dataValue) => {
                    setPeriodFields({
                      ...periodsFields,
                      periodsData: dataValue,
                    });
                  }}
                  dataItemKey="id"
                  valueData={periodsList.find(
                    (c) => c.text === periodsFields.periodsValue.text
                  )}
                  onChange={(e, name) => {
                    setPeriodFields({ ...periodsFields, [name]: e });
                  }}
                  filterable={true}
                  onFilterChange={(dataValue) => {
                    setPeriodFields({
                      ...periodsFields,
                      periodsData: dataValue,
                    });
                  }}
                  autoClose={false}
                  autoBind={true}
                  isSelectALl={true}
                  dataList={periodsList}
                  loading={periodsFields.isPeriodDataLoading}
                  appendTo={effPeriodRef.current}
                />
              </div>
            </FieldWrapper>
            <FieldWrapper>
              <div className={"k-form-field-wrap-popup"} ref={ruleTypeRef}>
                <Label>{"Rule Type* : "}</Label>
                <CustomDropdown
                  name={"ruleTypeValue"}
                  data={ruleTypeFields.typeData}
                  textField="text"
                  onOpen={(dataValue) => {
                    setRuleTypeFields({
                      ...ruleTypeFields,
                      typeData: dataValue,
                    });
                  }}
                  dataItemKey="id"
                  valueData={ruleTypeList.find(
                    (c) => c.text === ruleTypeFields.ruleTypeValue.text
                  )}
                  onChange={(e, name) => {
                    setRuleTypeFields({ ...ruleTypeFields, [name]: e });
                  }}
                  filterable={true}
                  onFilterChange={(dataValue) => {
                    setRuleTypeFields({
                      ...ruleTypeFields,
                      typeData: dataValue,
                    });
                  }}
                  autoClose={false}
                  autoBind={true}
                  isSelectALl={true}
                  dataList={ruleTypeList}
                  loading={ruleTypeFields.isRuleTypeLoading}
                  appendTo={ruleTypeRef.current}
                  itemRender={itemRender}
                />
              </div>
              <ShowError name="in_rule_type" />
            </FieldWrapper>
          
          
            <FieldWrapper className="full-width">
              <fieldset className={"k-form-fieldset full-width martop9"}>
                <div className={"k-form-field-wrap-popup full-width"}>
                  <Label>{"Description : "}</Label>
                  <Input
                    type="text"
                    name="in_rule_description"
                    className="full-width"
                    value={formFields.in_rule_description}
                    onChange={(e) => {
                      handleFieldChange(e);
                    }}
                  />
                </div>
                <ShowError name="in_rule_description" />
              </fieldset>
            </FieldWrapper>
            
            <div className="k-form-buttons full-width popupbuttoncont dropdownFooter martop9">
            <button type={"submit"} className="k-button cancelbtn" onClick={handleCancel}>
              Cancel
            </button>
            <button
              onClick={handleSave}
              type={"submit"}
              disabled={!ruleTypeList.length}
              className="primarybtn k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
            >
              Save
            </button>
          </div>
            </FormElement>




      )}
    />
    
  );
};

export default React.memo(AddRule);
