import { UNSAVE_TEXT_WARNING } from "src/constants/constants";
import {
  NEW_MESSAGE,
  REMOVE_MESSAGE,
  SET_UNIT_TYPE_DATA,
  SET_LINKED_RULES,
  CLEAR_LINKED_RULES,
  SET_UNSAVED_CHANGES_POPUP,
} from "../actions/setting-actions";

const setting = (
  state = {
    dataPer: false,
    messageList: [],
    unsavedPopup: {
      title: "Unsaved Changes",
      desc: UNSAVE_TEXT_WARNING,
      type: "UNSAVED_CHANGES",
      action: "UnSave",
      isVisible: false,
      isUnSavedChange: false,
      onDiscardChange: null,
      onSaveChange: null,
      data: {},
    },
    redirectionUrl: "",
    linkedRules: {},
  },
  action
) => {
  switch (action.type) {
    case NEW_MESSAGE:
      state = {
        ...state,
        messageList: populateMessage(state.messageList, action.message),
      };
      break;
    case REMOVE_MESSAGE:
      state = {
        ...state,
        messageList: removeMessage(state.messageList, action.index),
      };
      break;
    case SET_UNSAVED_CHANGES_POPUP:
      state = {
        ...state,
        unsavedPopup: { ...state.unsavedPopup, ...action.data },
      };
      break;

    case SET_UNIT_TYPE_DATA:
      state = {
        ...state,
        unitTypeList: action.data,
      };
      break;
    case SET_LINKED_RULES:
      const { key, data } = action.data;
      state = {
        ...state,
        linkedRules: {
          ...state.linkedRules,
          [key]: data,
        },
      };
      break;
    case CLEAR_LINKED_RULES:
      state = {
        ...state,
        linkedRules: !action.data
          ? {}
          : Object.keys(state.linkedRules)
              .filter((key) => key !== action.data)
              .reduce((acc, key) => {
                acc[key] = state.linkedRules[key];
                return acc;
              }, {}),
      };
      break;
    default:
      return state;
  }
  return state;
};

export default setting;

const populateMessage = (list, msg) => {
  const id = list[list.length - 1] ? list[list.length - 1].id + 1 : 1;
  const newList = [{ ...msg, id }];
  return newList;
};

const removeMessage = () => {
  return [];
};
