import React, { useEffect, useState } from "react";
import { Label } from "@progress/kendo-react-labels";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Checkbox } from "@progress/kendo-react-inputs";
import CustomDropdown from "../../../components/customDropdown";
import { GetPeriodTypeAPI, GetUDFDataObj } from "../../../lib/common-service";
import "./rule-config.scss";
import { setProrationData } from "../../../redux/actions/rule-library-actions";
import {
    changeCancelBtnState,
    changeUpdateBtnState,
} from "../../../utils/utils";
import {
    executionDataSelector,
    prorateDataSelector,
} from "../../../redux/selectors/custom-selector";

const ProrationSettings = (restProps) => {
    const dispatch = useDispatch();
    const prorateReduxData = useSelector(prorateDataSelector, shallowEqual);
    const { onDataChange } = restProps;

    const [periodsTypeFields, setPeriodTypeFields] = useState({
        periodTypeValue: {
            period_type_mstr_key: "",
            in_period_start_date: "",
            in_period_end_date: "",
        },
        periodTypeData: [],
        isPeriodTypeDataLoading: true,
    });
    const [prorateTypeList, setProrateTypeList] = useState([]);
    const [prorateTypeFields, setProRateTypeFields] = useState({
        prorateTypeValue: { udf_data_object_key: "" },
        prorateTypeData: [],
        isProrateTypeLoading: true,
    });
    const [periodTypeList, setPeriodTypeList] = useState([]);
    const [originalPeriodTypeData, setOriginalPeriodTypeList] = useState([]);
    const [isProrateFlag, setProrateFlag] = useState(false);
    const executionReduxData = useSelector(executionDataSelector, shallowEqual);

    useEffect(() => {
        getDataList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setProrateFlag(
            prorateReduxData?.prorateData?.in_prorate_flag ? true : false
        );

        const firstUdfKey = prorateTypeFields?.prorateTypeData?.length
            ? prorateTypeFields?.prorateTypeData?.[0].udf_data_object_key
            : 0;

        const reduxUdfMstrKey =
            prorateReduxData?.prorateData?.in_prorate_udf_data_obj_key ||
            firstUdfKey;

        const reduxPeriodTypeMstrKey =
            prorateReduxData?.prorateData?.in_prorate_period_type_mstr_key || 0;

        setPeriodTypeFields({
            ...periodsTypeFields,
            periodTypeValue: {
                period_type_mstr_key: prorateReduxData?.prorateData
                    ?.in_prorate_flag
                    ? reduxPeriodTypeMstrKey
                    : 0,
            },
        });

        setProRateTypeFields({
            ...prorateTypeFields,
            prorateTypeValue: {
                udf_data_object_key: prorateReduxData?.prorateData
                    ?.in_prorate_flag
                    ? reduxUdfMstrKey
                    : firstUdfKey,
            },
        });
    }, [prorateReduxData?.prorateData]);

    const getDataList = async () => {
        await getPeriodsDataType();
        await getUDFDataObjList();
    };

    const getUDFDataObjList = async () => {
        let dataObj = {
            in_filter: `AND u.udf_data_obj_label IN ('position', 'provider') AND u.rfct_field_metadata LIKE 'DATETIME%'`,
            in_sort_number: 3,
            in_sort_order: "ASC",
            in_is_group: 0,
        };
        let res = await GetUDFDataObj(dataObj, dispatch);

        if (res.length > 0) {
            setProrateTypeList(res);
            setProRateTypeFields({
                ...prorateTypeFields,
                prorateTypeData: res,
                prorateTypeValue: res[0],
                isProrateTypeLoading: false,
            });
        } else {
            setProRateTypeFields({
                ...prorateTypeFields,
                isPeriodTypeDataLoading: false,
            });
        }
    };

    /**
     * Get Periods Data Type
     */
    const getPeriodsDataType = async () => {
        let periodTypeReqData = {
            in_filter: "AND a.period_type_hierarchy_level IS NOT NULL",
            in_sort_number: null,
            in_sort_order: null,
        };
        let res = await GetPeriodTypeAPI(periodTypeReqData, dispatch);
        if (res.length > 0) {
            res.unshift({
                period_type_hierarchy_level: 0,
                period_type_name: "N/A",
                period_type_mstr_key: 0,
            });
            setPeriodTypeList(res);
            setOriginalPeriodTypeList(res);
            setPeriodTypeFields({
                ...periodsTypeFields,
                periodTypeData: res,
                periodTypeValue: res[0],
                isPeriodTypeDataLoading: false,
            });
        } else {
            setPeriodTypeFields({
                ...periodsTypeFields,
                isPeriodTypeDataLoading: false,
            });
        }
    };

    const handleChange = (event) => {
        setProrateFlag(event.target.value);
        if (event.target.value == false) {
            dispatch(
                setProrationData({
                    ...prorateReduxData.prorateData,
                    in_prorate_flag: 0,
                    in_prorate_period_type_mstr_key: 0,
                    in_prorate_udf_data_obj_key: 0,
                })
            );
        } else {
            const firstUdfKey = prorateTypeFields?.prorateTypeData?.length
                ? prorateTypeFields?.prorateTypeData?.[0].udf_data_object_key
                : 0;

            const reduxUdfMstrKey =
                prorateReduxData?.prorateData?.in_prorate_udf_data_obj_key ||
                firstUdfKey;

            const reduxPeriodTypeMstrKey =
                prorateReduxData?.prorateData
                    ?.in_prorate_period_type_mstr_key || 0;

            dispatch(
                setProrationData({
                    ...prorateReduxData.prorateData,
                    in_prorate_flag: 1,
                    in_prorate_period_type_mstr_key: reduxPeriodTypeMstrKey,
                    in_prorate_udf_data_obj_key: reduxUdfMstrKey,
                })
            );
        }

        onDataChange?.();

        changeCancelBtnState("enable", "grid-incell-cancel-comp-rule");
        changeUpdateBtnState("enable", "grid-incell-update-comp-rule");
    };

    const handleDropdownChange = (e, name) => {
        if (name === "periodTypeValue") {
            setPeriodTypeFields({
                ...periodsTypeFields,
                [name]: e,
            });
            dispatch(
                setProrationData({
                    ...prorateReduxData.prorateData,
                    in_prorate_period_type_mstr_key: e.period_type_mstr_key,
                })
            );
        } else {
            setProRateTypeFields({
                ...prorateTypeFields,
                [name]: e,
            });
            dispatch(
                setProrationData({
                    ...prorateReduxData.prorateData,
                    in_prorate_udf_data_obj_key: e.udf_data_object_key,
                })
            );
        }
        onDataChange?.();

        changeCancelBtnState("enable", "grid-incell-cancel-comp-rule");
        changeUpdateBtnState("enable", "grid-incell-update-comp-rule");
    };

    return (
        <div>
            <div className="inner-title-head">
                <h1>Proration Settings</h1>
            </div>
            <div className="inner-title-cont">
                <div
                    className={"k-form-field-wrap-popup"}
                    style={{ width: "auto" }}
                >
                    <div className="k-form-field-wrap-popup execution-checkbox">
                        <Checkbox
                            checked={isProrateFlag}
                            value={isProrateFlag}
                            label={"Prorate Flag"}
                            onChange={handleChange}
                            className="k-checkbox-label innerlabletext"
                            style={{ display: "inline-block" }}
                            disabled={
                                restProps.pageProps.writeAccess === 0
                                    ? true
                                    : false
                            }
                        />
                    </div>
                </div>
                <div
                    className={"k-form-field-wrap-popup"}
                    style={{ width: "150px" }}
                >
                    <Label className="innerlabletext">
                        {"Prorate Period Type : "}
                    </Label>

                    <CustomDropdown
                        disabled={
                            restProps.pageProps.writeAccess === 0
                                ? true
                                : !isProrateFlag
                        }
                        name={"periodTypeValue"}
                        data={periodsTypeFields.periodTypeData}
                        textField="period_type_name"
                        onOpen={(dataValue) => {
                            setPeriodTypeFields({
                                ...periodsTypeFields,
                                periodTypeData: dataValue,
                            });
                            changeCancelBtnState(
                                "enable",
                                "grid-incell-cancel-comp-rule"
                            );
                            changeUpdateBtnState(
                                "enable",
                                "grid-incell-update-comp-rule"
                            );
                        }}
                        dataItemKey="period_type_mstr_key"
                        valueData={periodTypeList.find(
                            (c) =>
                                c.period_type_mstr_key ===
                                periodsTypeFields?.periodTypeValue
                                    ?.period_type_mstr_key
                        )}
                        onChange={(e, name) => {
                            handleDropdownChange(e, name);
                        }}
                        filterable={false}
                        autoClose={false}
                        autoBind={true}
                        isSelectALl={true}
                        dataList={periodTypeList}
                        loading={periodsTypeFields.isPeriodTypeDataLoading}
                    />
                </div>
                <div
                    className={"k-form-field-wrap-popup"}
                    style={{ width: "250px" }}
                >
                    <Label className="innerlabletext">{"Prorate Date: "}</Label>
                    <CustomDropdown
                        disabled={
                            restProps.pageProps.writeAccess === 0
                                ? true
                                : !isProrateFlag
                        }
                        name={"prorateTypeValue"}
                        data={prorateTypeFields.prorateTypeData}
                        textField="udf_data_obj_field_label"
                        onOpen={(dataValue) => {
                            setProRateTypeFields({
                                ...prorateTypeFields,
                                prorateTypeData: dataValue,
                            });
                            onDataChange?.();
                            changeCancelBtnState(
                                "enable",
                                "grid-incell-cancel-comp-rule"
                            );
                            changeUpdateBtnState(
                                "enable",
                                "grid-incell-update-comp-rule"
                            );
                        }}
                        dataItemKey="udf_data_object_key"
                        valueData={prorateTypeList.find(
                            (c) =>
                                c.udf_data_object_key ===
                                prorateTypeFields.prorateTypeValue
                                    .udf_data_object_key
                        )}
                        onChange={(e, name) => {
                            handleDropdownChange(e, name);
                        }}
                        autoClose={false}
                        autoBind={true}
                        filterable={false}
                        isSelectALl={true}
                        dataList={prorateTypeList}
                    />
                </div>
            </div>
        </div>
    );
};

export default React.memo(ProrationSettings);
