const emailRegex = new RegExp(/\S+@\S+\.\S+/);
const phoneRegex = new RegExp(/^[0-9 ()+-]+$/);
const ccardRegex = new RegExp(/^[0-9-]+$/);
const cvcRegex = new RegExp(/^[0-9]+$/);

export const emailValidator = (value) =>
  !value
    ? "Email field is required."
    : emailRegex.test(value)
    ? ""
    : "Email is not in a valid format.";

    export const requiredValidator = (value) =>
  value ? "" : "This field is required.";

export const userNameValidator = (value) =>
  !value
    ? "User Name is required"
    : value.length < 5
    ? "User name should be at least 5 characters long."
    : "";
export const phoneValidator = (value) =>
  !value
    ? "Phone number is required."
    : phoneRegex.test(value)
    ? ""
    : "Not a valid phone number.";
export const cardValidator = (value) =>
  !value
    ? "Credit card number is required. "
    : ccardRegex.test(value)
    ? ""
    : "Not a valid credit card number format.";
export const cvcValidator = (value) =>
  !value
    ? "CVC code is required,"
    : cvcRegex.test(value) || value.length !== 3
    ? ""
    : "Not a valid CVC code format.";
export const passwordValidator = (value) =>
  value && value.length > 8 ? "" : "Password must be at least 8 symbols.";
export const newPasswordValidator = (value) =>
  value && value.length > 7 && value.length < 14
    ? ""
    : "New password should be between 8 and 13 characters.";
export const confirmNewPasswordValidator = (value, allValues) => {
  return value && value.length > 7 && value.length < 14
    ? ""
    : "Confirm password should be between 8 and 13 characters.";
};

export const addressValidator = (value) =>
  value ? "" : "Address is required.";

export const startDateValidator = (periodStart, periodEnd) => {
  const startDate = new Date(periodStart);
  const endDate = new Date(periodEnd);
  return startDate > endDate ? "Period Start date cannot be later than Period End date." : "";
};

export const endDateValidator = (periodStart, periodEnd) => {
  const startDate = new Date(periodStart);
  const endDate = new Date(periodEnd);
  return endDate < startDate ? "Period End date cannot be earlier than Period Start date." : "";
};
