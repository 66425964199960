import { Button } from "@progress/kendo-react-buttons";
import { FieldWrapper, Form, FormElement } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Error, Label } from "@progress/kendo-react-labels";
import React from "react";
import Popup from "../../components/customPopup";
import { getUserCredential } from "../../lib/cookies";
import MessageSchema from "../../utils/messageSchema";
import { HeaderPage } from "../base/headerPage";
import { changeCancelBtnState, changeUpdateBtnState } from "../../utils/utils";
import { GridButton, CalendarData } from "../calendar/calendarGrid";
import { CALENDAR_PERIOD_TYPES } from "../../constants/constants";
import "./calendar.scss";

import useBusinessGroupApi from "./service";
import { transformDateTime } from "src/lib/date-time.util";


const initialFormFields = {
  in_year: "",
  in_period_start_date: "",
  in_calendar_type: {
    text: "Regular",
    value: "Regular",
  },
};

const Calendar = (pageProps) => {
  const [list, Action] = useBusinessGroupApi();
  const cookiesData = getUserCredential();
  const addRef = React.useRef(null);
  const [isAddNewConfig, setNewConfig] = React.useState(false);
  const [isShow, setShow] = React.useState({
    dtuLogs: false,
    sendEmails: false,
    dtuUpload: false,
    deleteRows: false,
  });
  const [exportDataClick, setExportDataClick] = React.useState(false);
  const [isAPICall, setAPICall] = React.useState(false);
  const [formFields, setFormFields] = React.useState({ ...initialFormFields });
  const [errorObj, setErrorObj] = React.useState({});
  const [isSaveClick, setSaveClick] = React.useState(false);
  const [selectedRowId, setSelectedRowId] = React.useState("");
  const [dataList, setDataList] = React.useState([]);
  const [calendarEdit, setCalendarEdit] = React.useState(false);
  const [columnsData, setColumnsData] = React.useState([]);
  const [isCheckColumn, setColumnCheck] = React.useState(false);
  let popupRef = React.useRef();

  let datePickerRef = React.useRef(null);
  let cTypeRef = React.useRef(null);

  React.useEffect(() => {
    window.$_UNSAVED_CHANGES_FLAG = false;
  }, []);

  React.useEffect(() => {
    if (list.loadingState.isSaveData) {
      setFormFields({ ...initialFormFields });
      setAPICall(true);
      setNewConfig(false);
    }
  }, [list.loadingState.isSaveData]);

  React.useEffect(() => {
    if (calendarEdit === false) {
      changeCancelBtnState();
      changeUpdateBtnState();
    }
  }, [calendarEdit]);

  const addNewConfig = (e) => {
    setNewConfig(!isAddNewConfig);
    e.preventDefault();
  };

  const ShowError = React.useCallback(
    (props) => {
      return <Error>{errorObj[props.name]}</Error>;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errorObj, isSaveClick]
  );

  const handleValidation = () => {
    let isValid = true;
    const regex = /^[^a-zA-Z]*$/; // Regex pattern to disallow alphabets

    if (formFields.in_year.trim() === "") {
      isValid = false;
      errorObj.in_year = MessageSchema.calendarYearRequired;
    }
    if (!regex.test(formFields.in_year)) {
      isValid = false;
      errorObj.in_year = MessageSchema.calendarYearValid;
    }
    if (
      formFields.in_calendar_type.text !== "Regular" &&
      formFields.in_period_start_date === ""
    ) {
      isValid = false;
      errorObj.in_period_start_date = MessageSchema.startDateRequired;
    } else {
      if (formFields.in_period_start_date !== "") {
        const d = new Date(formFields.in_period_start_date);
        let year = d.getFullYear();
        let validYear = year > 1990 && year < 2099;
        if (!validYear) {
          errorObj.in_period_start_date =
            "Please enter date using YYYY-MM-DD format";
        }
      }
    }
    setErrorObj((preVState) => ({ ...preVState, errorObj }));
    return isValid;
  };

  const handleSubmit = () => {
    let valid = handleValidation();
    if (valid) {
      const bodyData = {
        in_user_id: cookiesData.out_user_id,
        in_tenant_id: cookiesData.out_tenant_id,
        in_year: formFields.in_year,
        in_period_start_date:
          formFields.in_period_start_date &&
            formFields.in_period_start_date !== null
            ?
            transformDateTime({
              date: formFields.in_period_start_date,
              format: "yyyy-MM-DD",
            })
            : null,
      };
      Action.addDataAsync(bodyData);
      setErrorObj({});
    } else {
      setSaveClick(true);
    }
  };

  const onCancelClick = () => {
    setFormFields(initialFormFields);
    setErrorObj({});
    setNewConfig(false);
    setSaveClick(false);
  };

  const handleFieldChange = (e) => {
    const name = e.name ? e.name : e.target.name;
    if (e.value !== null) {
      if (e.value.text === "Regular") {
        setFormFields({
          ...formFields,
          in_period_start_date: "",
          [name]: e.value,
        });
        setErrorObj({ ...errorObj, [name]: "", in_period_start_date: "" });
      } else {
        setFormFields({ ...formFields, [name]: e.value });
        setErrorObj({ ...errorObj, [name]: "" });
      }
    }
  };
  const AddNewForm = () => {
    return (
      <Form
        onSubmit={handleSubmit}
        render={() => (
          <FormElement className="emailForm" horizontal={true}>
            <FieldWrapper>
              <div className={"k-form-field-wrap-popup"}>
                <Label>{"Year* : "}</Label>
                <Input
                  type={"text"}
                  name="in_year"
                  value={formFields.in_year}
                  onChange={(e) => {
                    handleFieldChange(e);
                  }}
                />
                <ShowError name={"in_year"} />
              </div>
            </FieldWrapper>
            <FieldWrapper></FieldWrapper>
            <FieldWrapper>
              <div
                className={"k-form-field-wrap-popup valueDropdown"}
                ref={cTypeRef}
              >
                <Label>{"Calendar Type : "}</Label>
                <DropDownList
                  onChange={handleFieldChange}
                  value={CALENDAR_PERIOD_TYPES.find(
                    (c) => c.text === formFields.in_calendar_type.text
                  )}
                  data={CALENDAR_PERIOD_TYPES}
                  textField="text"
                  name="in_calendar_type"
                  popupSettings={{ appendTo: cTypeRef.current }}
                />
              </div>
            </FieldWrapper>
            <FieldWrapper>
              <div
                className={"k-form-field-wrap-popup valueDropdown"}
                ref={datePickerRef}
              >
                <Label>{"Start Date : "}</Label>
                <DatePicker
                  disabled={formFields.in_calendar_type.text === "Regular"}
                  value={formFields.in_period_start_date}
                  name="in_period_start_date"
                  format="yyyy-MM-dd"
                  onChange={(e) => {
                    handleFieldChange(e);
                  }}
                  formatPlaceholder={{
                    year: "yyyy",
                    month: "mm",
                    day: "dd",
                  }}
                  placeholder="yyyy-mm-dd"
                  popupSettings={{
                    appendTo: datePickerRef.current,
                  }}
                />
                <ShowError name={"in_period_start_date"} />
              </div>
            </FieldWrapper>
          </FormElement>
        )}
      />
    );
  };

  const AddNewPopup = () => {
    return (
      <Popup anchor={addRef.current} show={isAddNewConfig} className="right0">
        <div className="dot-dropdown-open dot-dropdown-open-Form">
          <div className="caption">
            <span>Add New Calendar</span>
          </div>

          {AddNewForm()}

          <div className="dropdownFooter">
            <div className="k-form-buttons">
              <Button
                primary={true}
                type="submit"
                onClick={() => {
                  onCancelClick();
                }}
              >
                Cancel
              </Button>
              <Button
                primary={true}
                type="submit"
                onClick={() => {
                  handleSubmit(formFields, errorObj);
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </Popup>
    );
  };

  const AddNewLink = () => {
    return (
      <div ref={popupRef}>
        <a
          href="/#"
          className="cta-small"
          ref={addRef}
          onClick={(e) => {
            addNewConfig(e);
          }}
        >
          <i className="fas fa-plus"></i> Add New Calendar
        </a>

        {AddNewPopup()}
      </div>
    );
  };

  const handleMenuItemClick = (action) => {
    switch (action) {
      case "dtuLogs":
        setShow({
          ...isShow,
          dtuLogs: !isShow.dtuLogs,
        });
        break;
      case "export":
        setExportDataClick(true);
        break;
      case "delete":
        setShow({
          ...isShow,
          deleteRows: !isShow.deleteRows,
        });
        break;
      default:
        break;
    }
  };

  const headerConfig = {
    title: "Calendar",
    id: "calendar",
    buttons: { template_fx: GridButton },
    add_new_fx: AddNewLink,
    action_menu: {
      delete: { fx: handleMenuItemClick },
    },
    manage_col: {
      columnsData: columnsData,
      setColumnsData: setColumnsData,
      setColumnCheck: setColumnCheck,
    },
  };

  const parent = document.querySelector("#content-" + headerConfig.id);

  if (parent) {
    const exportToExcel = parent.querySelector(".export-to-excel");
    const deleteRows = parent.querySelector(".delete-rows");

    exportToExcel && exportToExcel.disableKendoMenu();
    deleteRows && deleteRows.disableKendoMenu();

    dataList.length && exportToExcel && exportToExcel.enableKendoMenu();

    if (selectedRowId !== "") {
      deleteRows && deleteRows.enableKendoMenu();
    }
  }

  return (
    <div className="Main">
      <div className="content">
        <div className="content-head">
          <HeaderPage pageProps={pageProps} headerConfig={headerConfig} />
        </div>

        <div className="content-body">
          <div className="dtu-file-container">
            <CalendarData
              isAPICall={isAPICall}
              setAPICall={setAPICall}
              setExportDataClick={setExportDataClick}
              exportDataClick={exportDataClick}
              selectedRowId={selectedRowId}
              setSelectedRowId={setSelectedRowId}
              setShow={setShow}
              isShow={isShow}
              setDataList={setDataList}
              pageProps={pageProps}
              calendarEdit={calendarEdit}
              setCalendarEdit={setCalendarEdit}
              setNewConfig={setNewConfig}
              columnsData={columnsData}
              setColumnsData={setColumnsData}
              setColumnCheck={setColumnCheck}
              isCheckColumn={isCheckColumn}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Calendar);
