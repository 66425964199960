import React from "react";
import {
    SELECT_JOB_EXEUCUTAION,
    UPDATE_JOB,
    DELETE_JOB_EXEUCUTAION,
    GET_SELECT_GRID_COLUMN_XML,
    INSERT_JOB,
    SELECT_JOB_PROCESS_MASTER,
    SELECT_JOB_END_TIME_STATUS,
    UPDATE_PIPELINE,
    RESTART_PIPELINE,
    SELECT_JOB_PROGRESS_STATUS,
        SELECT_AUTO_REPORT_STATUS,

} from "../../api-setup/api-endpoints";
import { getUserCredential, getTenantCookieData } from "../../lib/cookies";
import {
    POSTAPI,
    errStatusHandler,
    generateHeader,
} from "../../api-setup/api-helper";
import { GridColumnDataMapper } from "../../utils/utils";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { setNewMessage } from "../../redux/actions/setting-actions";
import { DEFAULT_SORT } from "../../constants/grid";
import {
    GetCompPlanDataApi,
    GeJobTypeAPI,
    GetStagehookTypesData,
} from "../../lib/common-service";

export default function Plan() {
    /**
     * start to define default states for common grid functions (list/insert/update/delete)
     */
    const cookiesData = getUserCredential();
    const header = {
        "x-access-token": cookiesData.secretToken,
    };
    const history = useHistory();
    const dispatch = useDispatch();
    const [data, setData] = React.useState([]);
    const [columns, setColumns] = React.useState([]);
    const [dataCount, setDataCount] = React.useState(0);
    const [loadingState, setLoadState] = React.useState({
        isSaveData: undefined,
        isDeleteData: undefined,
        isUpdateData: undefined,
        isrestartData: undefined,
    });
    const [initialLoading, setLoadingState] = React.useState(false);
    const [editData, setEditData] = React.useState("");
    const [confirmationPopup, setConfirmationPopup] = React.useState({
        title: "Delete",
        desc: "Are you sure you want to delete this record?",
        isVisible: false,
        type: "",
        actionBtn: [],
        data: {},
        action: "Delete",
    });
    const [originalData, setOriginalData] = React.useState([]);
    const [isAPICalling, setAPICallingState] = React.useState(false);
    const [isRefreshLoading, setRefreshLoading] = React.useState(false);
    const defaultPageSize = getTenantCookieData("record_per_page");
    const [isJobAPI, setIsJobAPI] = React.useState(undefined);

    /**
     * end to define default states for common grid functions (list/insert/update/delete)
     */

    /**
     * start to define default states for periods and period types
     */
    const [jobTypeFields, setJobTypeFields] = React.useState({
        jobTypeValue: {
            in_job_mstr_key: "",
        },
        jobTypeData: [],
        isJobTypeDataLoading: true,
    });
    const [originJobType, setoriginJobType] = React.useState([]);
    const [compPlanFields, setcompPlanFields] = React.useState({
        compPlanValue: {
            in_comp_plan_mstr_key: "",
        },
        compPlanData: [],
        iscompPlanDataLoading: true,
    });
    const [originCompPlan, setOriginCompPlan] = React.useState([]);
    const [stagehookFields, setStagehookFields] = React.useState({
        stagehookValue: {
            in_stagehook_mstr_key: "",
        },
        stagehookData: [],
        isstagehookDataLoading: true,
    });
    const [originStageHook, setOriginStagehook] = React.useState([]);

    const getListDataAsync = (listData) => {
        setIsJobAPI(false);

        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                SELECT_JOB_EXEUCUTAION,
                null,
                header,
                listData,
                dispatch
            ).then((res) => {
                if (res) {
                    setIsJobAPI(true);

                    const reqData = {
                        in_tenant_id: cookiesData.out_tenant_id,
                        in_filter: null,
                        in_filter_db_name: "procare_system",
                        in_filter_table_name: "vw_job_queue",
                        in_result_name_mstr_key: 0,
                        in_event_type_mstr_key: 0,
                        in_hide_column: 0,
                    };

                    setTimeout(function () {
                        getColumListXML(reqData);
                    }, 100);
                    if (res.data.out_parameters.out_ret_code > 0) {
                        setLoadingState(true);
                        errStatusHandler(res, history, dispatch);
                    }
                    let userData = [];
                    userData = JSON.parse(JSON.stringify(res.data.row_result));

                    userData.map((item, idx) => {
                        item.rowId = idx;
                        item.selected = false;
                        return 0;
                    });

                    setAPICallingState(false);
                    setLoadingState(true);
                    setEditData("");
                    setConfirmationPopup({
                        ...confirmationPopup,
                        isVisible: false,
                        type: "",
                        actionBtn: [],
                        data: {},
                        action: "",
                    });

                    setData(userData);
                    setOriginalData(userData);
                    setDataCount(res.data.out_parameters.out_total_rows);
                    setRefreshLoading(false);
                    resolve(userData);
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    // dispatch(
                    //   setNewMessage({
                    //     message: res.data.out_parameters.out_error_msg,
                    //     type: "Error",
                    //     show: true,
                    //   }),
                    // );
                    // errStatusHandler(res, history, dispatch);
                }
            });
    };

    const addDataAsync = (bodyData) => {
        setLoadState({ ...loadingState, isSaveData: false });

        POSTAPI(false, INSERT_JOB, null, header, bodyData, dispatch).then(
            (res) => {
                if (res.status) {
                    if (res.data.out_parameters.out_ret_code > 0) {
                        dispatch(
                            setNewMessage({
                                message: res.data.out_parameters.out_error_msg,
                                type: "Error",
                                show: true,
                            })
                        );
                        errStatusHandler(res, history, dispatch);
                    } else {
                        setLoadState({ ...loadingState, isSaveData: true });
                    }
                }
            }
        );
    };

    const deleteDataAsync = (bodyData) => {
        setLoadState({ ...loadingState, isDeleteData: false });

        POSTAPI(
            false,
            DELETE_JOB_EXEUCUTAION,
            null,
            header,
            bodyData,
            dispatch
        ).then((res) => {
            if (res.data.out_parameters.out_ret_code > 0) {
                let msg = "";

                setConfirmationPopup({
                    ...confirmationPopup,
                    isVisible: false,
                    type: "",
                    actionBtn: [],
                    data: {},
                    action: "",
                });
                dispatch(
                    setNewMessage({
                        message: msg,
                        type: "Error",
                        show: true,
                    })
                );
                setLoadState({ ...loadingState, isDeleteData: true });
            } else {
                setConfirmationPopup({
                    ...confirmationPopup,
                    isVisible: false,
                    type: "",
                    actionBtn: [],
                    data: {},
                    action: "",
                });
                // setAPICallingState(true);
                // getListDataAsync(apiCallData);
                setLoadState({ ...loadingState, isDeleteData: true });
            }
        });
    };

    const exportDataAsync = (bodyData) => {
        // return new Promise(function (resolve, reject) {
        //   POSTAPI(
        //     false,
        //     SELECT_JOB_CONFGURATION,
        //     null,
        //     generateHeader(),
        //     bodyData,
        //     dispatch,
        //   ).then((res) => {
        //     if (
        //       res &&
        //       (res.data.out_parameters.out_ret_code === 0 ||
        //         res.data.out_parameters.out_ret_code === 2)
        //     ) {
        //       resolve(res.data.row_result);
        //     } else {
        //       dispatch(
        //         setNewMessage({
        //           message: res?.data.out_parameters.out_error_msg,
        //           type: "Error",
        //           show: true,
        //         }),
        //       );
        //       resolve([]);
        //       errStatusHandler(res, history, dispatch);
        //     }
        //   });
        // })
        //   .then((res) => {
        //     return res;
        //   })
        //   .catch((res) => {
        //     if (res.data.out_parameters.out_ret_code > 0) {
        //       dispatch(
        //         setNewMessage({
        //           message: res.data.out_parameters.out_error_msg,
        //           type: "Error",
        //           show: true,
        //         }),
        //       );
        //       errStatusHandler(res, history, dispatch);
        //     }
        //   });
    };

    const updateDataAsync = (bodyData) => {
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                UPDATE_JOB,
                null,
                generateHeader(),
                bodyData,
                dispatch
            ).then((res) => {
                if (
                    res &&
                    (res.data.out_parameters.out_ret_code === 0 ||
                        res.data.out_parameters.out_ret_code === 2)
                ) {
                    resolve(true);
                } else {
                    dispatch(
                        setNewMessage({
                            message: res?.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    resolve([]);
                    errStatusHandler(res, history, dispatch);
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    const getColumListXML = (reqData) => {
        POSTAPI(
            false,
            GET_SELECT_GRID_COLUMN_XML,
            null,
            header,
            reqData,
            dispatch
        ).then((res) => {
            if (res.data.out_parameters.out_ret_code > 0) {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                setAPICallingState(true);
                errStatusHandler(res, history, dispatch);
            } else {
                const columData = GridColumnDataMapper(
                    res.data.out_parameters.out_columns_xml
                );
                columData.map((cItem) => {
                    // cItem.minWidth = ;
                    cItem.minWidth =
                        cItem.field === "proc_ctrl_mstr_key" ? 100 : 180;
                    return 0;
                });

                setColumns(columData);

                setColumns(columData);
            }
        });
    };

    const getJobTypesData = async () => {
        let jobTypeData = {
            in_filter: null,
            in_sort_number: DEFAULT_SORT.number,
            in_sort_order: "ASC",
        };

        let res = await GeJobTypeAPI(jobTypeData, dispatch, history);

        if (res.length > 0) {
            let tempArray = [];

            res.map((item) => {
                let obj = {};
                obj.text = item.job_type_name;
                obj.id = item.job_type_mstr_key;

                tempArray.push(obj);
                return 0;
            });

            setoriginJobType(tempArray);
            setJobTypeFields({
                ...jobTypeFields,
                jobTypeData: tempArray,
                jobTypeValue: tempArray[0],
                isJobTypeDataLoading: false,
            });
        } else {
            setJobTypeFields({
                ...jobTypeFields,
                isJobTypeDataLoading: false,
            });
        }
    };
    const getCompPlanData = async () => {
        let dataObj = {
            in_tenant_id: cookiesData.out_tenant_id,
            in_filter: null,
            in_sort_number: null,
            in_sort_order: null,
            in_user_key: cookiesData.out_user_id,
            in_multi_sort_order: null,
        };
        let res = await GetCompPlanDataApi(dataObj, dispatch, history);

        if (res.length > 0) {
            let tempArray = [];

            res.map((item) => {
                let obj = {};
                obj.text = item.comp_plan_name;
                obj.id = item.comp_plan_mstr_key;

                tempArray.push(obj);
                return 0;
            });

            setOriginCompPlan(tempArray);
            setcompPlanFields({
                ...compPlanFields,
                compPlanData: tempArray,
                compPlanValue: tempArray[0],
                iscompPlanDataLoading: false,
            });
        } else {
            setcompPlanFields({
                ...compPlanFields,
                iscompPlanDataLoading: false,
            });
        }
    };
    const getStagehookTypesData = async () => {
        const listData = {
            in_tenant_id: cookiesData.out_tenant_id,
            in_db_role_key: cookiesData?.out_db_role_key,
            in_filter: "AND a.job_type_mstr_key=3",
            in_sort_number: DEFAULT_SORT.number,
            in_sort_order: DEFAULT_SORT.order,
            in_page_row_offset: 0,
            in_page_rows: defaultPageSize.recordTake,
        };
        let res = await GetStagehookTypesData(listData, dispatch, history);

        if (res.length > 0) {
            let tempArray = [];

            res.map((item) => {
                let obj = {};
                obj.text = item.stagehook_name;
                obj.id = item.stagehook_mstr_key;
                tempArray.push(obj);
                return 0;
            });

            setOriginStagehook(tempArray);
            setStagehookFields({
                ...stagehookFields,
                stagehookData: tempArray,
                stagehookValue: tempArray[0],
                isstagehookDataLoading: false,
            });
        } else {
            setStagehookFields({
                ...stagehookFields,
                isstagehookDataLoading: false,
            });
        }
    };

    const getProcessJobMaster = async (listData) => {
        try {
            // Make the API call without waiting for the result or handling the response
            await POSTAPI(
                false,
                SELECT_JOB_PROCESS_MASTER,
                null,
                header,
                listData,
                dispatch
            );
        } catch (error) {
            // Ignore errors, no handling
        }
    };
    
    // const getProcessJobMaster = async (listData) => {
    //     return new Promise(function (resolve, reject) {
    //         POSTAPI(
    //             false,
    //             SELECT_JOB_PROCESS_MASTER,
    //             null,
    //             header,
    //             listData,
    //             dispatch
    //         ).then((res) => {
    //             if (res) {
    //                 let userData = JSON.parse(
    //                     JSON.stringify(res.data.row_result)
    //                 );
    //                 resolve(userData[0]);
    //             }
    //         });
    //     })
    //         .then((res) => {
    //             return res;
    //         })
    //         .catch((res) => {
    //             if (res.data.out_parameters.out_ret_code > 0) {
    //                 dispatch(
    //                     setNewMessage({
    //                         message: res.data.out_parameters.out_error_msg,
    //                         type: "Error",
    //                         show: true,
    //                     })
    //                 );
    //                 errStatusHandler(res, history, dispatch);
    //             }
    //         });
    // };
    const updatePipeLineAsync = (bodyData) => {
        setRefreshLoading(true);
        setLoadState({ ...loadingState, isUpdateData: false });

        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                UPDATE_PIPELINE,
                null,
                generateHeader(),
                bodyData,
                dispatch
            ).then((res) => {
                setRefreshLoading(false);
                if (res && res.data.out_parameters.out_ret_code === 0) {
                    resolve(res);

                    setLoadState({ ...loadingState, isUpdateData: true });
                } else {
                    dispatch(
                        setNewMessage({
                            message: res?.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    resolve([]);
                    errStatusHandler(res, history, dispatch);
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };
    const restartPipeLineAsync = (bodyData) => {
        setRefreshLoading(true);
        setLoadState({ ...loadingState, isrestartData: false });

        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                RESTART_PIPELINE,
                null,
                generateHeader(),
                bodyData,
                dispatch
            ).then((res) => {
                setRefreshLoading(false);
                if (res && res.data.out_parameters.out_ret_code === 0) {
                    resolve(res);
                    setLoadState({ ...loadingState, isrestartData: true });
                } else {
                    dispatch(
                        setNewMessage({
                            message: res?.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    resolve([]);
                    errStatusHandler(res, history, dispatch);
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    const getProcessListDataAsync = (listData) => {
        setIsJobAPI(false);

        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                SELECT_JOB_PROGRESS_STATUS,
                null,
                header,
                listData,
                dispatch
            ).then((res) => {
                // if (res && res.data.out_parameters.out_ret_code === 0) {
                //     let userData = [];
                //     userData = JSON.parse(JSON.stringify(res.data.row_result));
                //     userData.map((item, idx) => {
                //         item.rowId = idx;
                //         item.selected = false;
                //         return 0;
                //     });
                //     resolve(userData);
                // }
            });
        }).then((res) => {
            return res;
        });
    };

    const getProcessJobMasterEndTimeStatus = async (listData) => {
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                SELECT_JOB_END_TIME_STATUS,
                null,
                header,
                listData,
                dispatch
            ).then((res) => {
                if (res) {
                    let userData = JSON.parse(
                        JSON.stringify(res.data.row_result)
                    );
                    resolve(userData[0]);
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    const selectReportStatus = (apiCallData) => {
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                SELECT_AUTO_REPORT_STATUS,
                null,
                header,
                apiCallData,
                dispatch
            ).then((res) => {
                if (res) {
                    resolve(res);
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };
    return [
        {
            data,
            columns,
            dataCount,
            setData,
            editData,
            originalData,
            isAPICalling,
            setEditData,
            confirmationPopup,
            setConfirmationPopup,
            initialLoading,
            setAPICallingState,
            loadingState,
            setLoadState,
            setOriginalData,
            setRefreshLoading,
            isRefreshLoading,
            originJobType,
            jobTypeFields,
            setJobTypeFields,
            originCompPlan,
            compPlanFields,
            setcompPlanFields,
            stagehookFields,
            setStagehookFields,
            originStageHook,
            isJobAPI,
            setIsJobAPI,
        },
        {
            getListDataAsync,
            addDataAsync,
            deleteDataAsync,
            updateDataAsync,
            getJobTypesData,
            exportDataAsync,
            getCompPlanData,
            getStagehookTypesData,
            getProcessJobMaster,
            updatePipeLineAsync,
            restartPipeLineAsync,
            getProcessListDataAsync,
            getProcessJobMasterEndTimeStatus,
            selectReportStatus
        },
    ];
}
