// eslint-disable-next-line no-useless-concat
import { Button } from "@progress/kendo-react-buttons";
import { getter } from "@progress/kendo-react-common";
import { getSelectedState, GridColumn as Column } from "@progress/kendo-react-grid";
import { Input } from "@progress/kendo-react-inputs";
import * as React from "react";
import { useDispatch } from "react-redux";
import { columnMenuProps } from "../../components/customColumnMenu";
import DataTable from "../../components/dataTable";
import { DialogComponent } from "../../components/dialog";
import { CellRender, RowRender } from "../../components/renderers";
import { DELETE_BTN_ACTIONS } from "../../constants/constants";
import { DEFAULT_SORT } from "../../constants/grid";
import { getUserCredential, getTenantCookieData } from "../../lib/cookies";
import { removeMessage } from "../../redux/actions/setting-actions";
import MessageSchema from "../../utils/messageSchema";
import {
  getfilterDataString,
  changeCancelBtnState,
  changeUpdateBtnState,
  // isSameRow,
} from "../../utils/utils";
import useApi from "./service";
// import { unSaveChangesPopupSelector } from "src/redux/selectors/custom-selector";

const editField = "inEdit";
const SELECTED_FIELD = "selected";
const DATA_ITEM_KEY = "org_title_key";
const idGetter = getter(DATA_ITEM_KEY);

const loadingPanel = (
  <div className="k-loading-mask">
    <span className="k-loading-text">Loading</span>
    <div className="k-loading-image"></div>
    <div className="k-loading-color"></div>
  </div>
);

export const GroupData = (restProps) => {

  const dispatch = useDispatch();
  // const { unsavedPopup } = useSelector(unSaveChangesPopupSelector, shallowEqual);
  const cookiesData = getUserCredential();
  const defaultPageSize = getTenantCookieData("record_per_page");
  const [list, Action] = useApi();
  const [deleteSelectedIds, setDeleteSelectedIds] = React.useState([]);
  const {
    data,
    columns,
    dataCount,
    setData,
    originalData,
    isAPICalling,
    setConfirmationPopup,
    confirmationPopup,
    setAPICallingState,
    initialLoading,
    isRefreshLoading,
  } = list;

  //state to set and get limit, offset
  const [pageData, setPage] = React.useState({
    skip: 0,
    take: defaultPageSize.recordTake,
  });

  const [pageSize, setPageSize] = React.useState(defaultPageSize);

  //state to set and get filter, sort number, sort order
  const [filterData, setFilterData] = React.useState({
    isSort: false,
    isFilter: false,
    data: "",
    in_sort_number: DEFAULT_SORT.number,
    sort_order: DEFAULT_SORT.order,
  });

  const [exportData, setExportData] = React.useState([]);
  const [selectedState, setSelectedState] = React.useState({});
  const [errorObj, setErrorObj] = React.useState({});
  const [gridColumns, setGridColumns] = React.useState([]);

  const apiCallData = {
    in_tenant_id: cookiesData.out_tenant_id,
    in_filter: null,
    in_sort_number: DEFAULT_SORT.number,
    in_sort_order: DEFAULT_SORT.order,
    in_page_row_offset: 0,
    in_page_rows: defaultPageSize.recordTake,
    in_user_role_filter: "> 0",
    in_load_type: "data-reload",
  };

  if (document.getElementById("grid-incell-cancel")) {
    document.getElementById("grid-incell-cancel").onclick = function (e) {
      dispatch(
        removeMessage({
          message: "",
          type: "Error",
          show: false,
        })
      );
      originalData.map((item) => {
        item.inEdit = false;
        item.selected = false;
        return 0;
      });
      setData(originalData);
      setErrorObj({});
      restProps.setDataList(originalData);

      //disable in-cell update and cancel button
      e.target.disableKendoBtn();
      changeUpdateBtnState();
    };
  }

  if (document.getElementById("grid-incell-update")) {
    document.getElementById("grid-incell-update").onclick = function (e) {
      handleUpdate();
    };
  }

  React.useEffect(() => {
    restProps.setDataList(data);
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  React.useEffect(() => {
    if (restProps.isAPICall) {
      restProps.setAPICall(false);
      onRefreshClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restProps.isAPICall]);

  React.useEffect(() => {
    if (restProps.isAddNewConfig) {
      restProps.setNewConfig(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restProps.isAddNewConfig]);

  React.useEffect(() => {
    if (list.loadingState.isDeleteData) {
      list.setLoadState({ ...list.loadingState, isDeleteData: false });
      onRefreshClick();
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list.loadingState.isDeleteData]);

  React.useEffect(() => {
    if (restProps.isShow.deleteRows) {
      remove();
      restProps.setShow({ ...restProps.isShow, deleteRows: false });
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restProps.isShow.deleteRows]);

  React.useEffect(() => {
    if (columns.length > 0) {
      restProps.setDataList(data);
      restProps.setColumnsData(columns);
      setGridColumns(columns);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);

  React.useEffect(() => {
    const dataVal = {
      ...apiCallData,
      in_load_type: "grid-reload",
    };
    Action.getListDataAsync(dataVal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (restProps.isCheckColumn) {
      setGridColumns(restProps.columnsData);
      restProps.setColumnCheck(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restProps.isCheckColumn]);


  const getInFilter = () => {
    return filterData.isFilter ? filterData.data : null;
  };

  const getInSortNumber = () => {
    return filterData.isSort ? filterData.in_sort_number : DEFAULT_SORT.number;
  };

  const getInSortOrder = () => {
    return filterData.isSort ? filterData.sort_order : DEFAULT_SORT.order;
  };

  const getPageTake = () => {
    return pageData.take;
  };

  const getPageSkip = () => {
    return pageData.skip;
  };

  const handleValidation = () => {
    let isValid = true;
    data
      .filter((x) => x.inIteamEdit === true)
      .map((item) => {
        const { title_name, rowId } = item;
        let errorObj = {
          title_name: "",
        };
        if (title_name.trim() === "") {
          errorObj.title_name = MessageSchema.groupNameRequired;
        } else {
          errorObj.title_name = "";
        }

        let obj = {};
        for (const [key, value] of Object.entries(errorObj)) {
          obj[`${key + rowId}`] = `${value}`;
        }

        setErrorObj(obj);
        for (const [, value] of Object.entries(obj)) {
          if (value !== "") {
            isValid = false;
          }
        }
        return 0;
      });
    return isValid;
  };

  const handleUpdate = async () => {
    let valid = handleValidation();
    if (valid) {
      let updatedDataList = [];
      data
        .filter((x) => x.inIteamEdit === true)
        .map((item) => {
          let obj = {};
          obj.in_user_id = cookiesData.out_user_id.toString();
          obj.in_tenant_id = cookiesData.out_tenant_id;
          obj.in_cost_center = item.cost_center;
          obj.in_facility = item.facility;
          obj.in_group_code = item.group_code;
          obj.in_org_title_key = item.org_title_key;
          obj.in_region = item.region;
          obj.in_title_desc = item.title_desc;
          obj.in_title_name = item.title_name;
          obj.in_version = item.version;
          updatedDataList.push(obj);
          return 0;
        });

      const bodyData = {
        updateTitle: [...updatedDataList],
      };

      if (updatedDataList.length > 0) {
        let response = await Action.updateGroupDataAsync(bodyData);

        if (response) {
      // TODO : UNSAVED CHANGES
          
          // dispatch(setUnSavedChangesPopup({ ...unsavedPopup, isUnSavedChange: true }));
          data.map((item) => {
            item.inEdit = false;
            return 0;
          });

          setData(data);
          onRefreshClick();
          restProps.setGroupEdit(false);
        }
      }
    } else {
      const elements = document.getElementsByClassName("k-textbox");
      elements[0].focus();

      changeCancelBtnState("enable");
      changeUpdateBtnState("enable");
    }
  };

  const remove = (ids) => {
    setConfirmationPopup({
      ...confirmationPopup,
      isVisible: true,
      type: "DELETE_CONFIRM",
      actionBtn: DELETE_BTN_ACTIONS(onCancelConfirm, onDeleteConfirm),
      data: [],
      action: "Delete",
    });
  };

  const onDeleteConfirm = (event) => {
    const deleteArray = deleteSelectedIds.map((obj2) => {
      const obj1 = data.find((elem) => elem.org_title_key === obj2.id);
      return {
        id: obj2.id,
        in_version: obj1.version,
        groupName: obj1.title_name,
      };
    });
    let deleteData = {
      in_user_id: cookiesData.out_user_id,
      in_org_title_key: deleteArray,
      in_tenant_id: cookiesData.out_tenant_id,
    };

    Action.deleteDataAsync(deleteData).then(function (result) {
      if (result === "success") {
        setDeleteSelectedIds([]);
        restProps.setSelectedRowId("");
      }
    });

    setErrorObj({});
    restProps.setGroupEdit(false);
  };

  const setNewSelectedRow = (newState, isReset) => {
    setSelectedState(newState);
    let tempArray = [];

    const keys = Object.keys(newState).filter(key => newState[key] === true);
    
    const selectedKeyArr = keys.filter(function (x) {
      return newState[x] !== false;
    });


    keys.map((item) => {
      let obj = {};
      obj.id = Number(item);
      // obj.text = item.event_group_name;
      tempArray.push(obj);
      return 0;
    });
    setDeleteSelectedIds(tempArray);
    if (isReset) {
      originalData.map((item) => {
        item.inEdit = false;
        item.selected = Number(item.org_title_key) === Number(keys[0]);
        return 0;
      });
      setData(originalData);
      restProps.setDataList(originalData);
    } else {
      let newData = data.map((item) => ({
        ...item,
        selected: selectedKeyArr.includes(item.org_title_key.toString()),
      }));
      setData(newData);
      restProps.setSelectedRowId(Number(selectedKeyArr[0]));
      restProps.setDataList(newData);
    }
  };

  const onSelectionChange = (event) => {
    const newSelectedState = getSelectedState({
      event,
      selectedState: selectedState,
      dataItemKey: DATA_ITEM_KEY,
    });
    // let newKeys = Object.keys(newSelectedState);
    // let oldKeys = Object.keys(selectedState);
    // const isSame = isSameRow(oldKeys, newKeys);
    
    // TODO : UNSAVED CHANGES
    // if (unsavedPopup.isUnSavedChange && !isSame) {
    //   dispatch(setUnSavedChangesPopup({
    //     ...unsavedPopup, isVisible: true, callbackFn: () => {
    //       setNewSelectedRow(newSelectedState, true);
    //       setErrorObj({});
    //       changeUpdateBtnState("disable", "grid-incell-update");
    //       changeCancelBtnState("disable", "grid-incell-cancel");
    //     }
    //   }))

    //   return;
    // }
    setNewSelectedRow(newSelectedState, false);
  };

  const onCancelConfirm = (action) => {
    setConfirmationPopup({
      ...confirmationPopup,
      isVisible: false,
      type: "",
      actionBtn: [],
      data: {},
      action: "",
    });
    data.map((item) => {
      item.selected = false;
      return 0;
    });
    setData([...data]);
    if (action === "outSideClick") {
      // TODO : UNSAVED CHANGES
      // dispatch(setUnSavedChangesPopup({ ...unsavedPopup, isUnSavedChange: true }));
    }
  };

  const onRowClick = (dataItem) => {
    let newData = data.map((item) => ({
      ...item,
      selected: item.org_title_key === dataItem.org_title_key ? true : false,
    }));
    setData(newData);
    restProps.setSelectedRowId(dataItem.org_title_key);
    restProps.setDataList(newData);
  };

  const enterEdit = (dataItem, field) => {
    if (restProps.pageProps.writeAccess === 0) {
      return false;
    }

    let newData = data.map((item) => ({
      ...item,
      inEdit: item.org_title_key === dataItem.org_title_key ? field : undefined,
    }));

    setData(newData);
  };

  const itemChange = React.useCallback(
    (event) => {
      restProps.setGroupEdit(true);
      const field = event.field || "";
      const newData = data.map((item) =>
        item.org_title_key === event.dataItem.org_title_key
          ? { ...item, [field]: event.value, inIteamEdit: true }
          : item
      );
      let editItem = event.dataItem;
      editItem[field] = event.value;
      setData(newData);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  const setPageMenuState = (response) => {
    if (!deleteSelectedIds.length) {
      return false;
    }

    let ids = response.map((el) => el.org_title_key);

    let selectedKeys = [];
    let tempArray = [];
    let tempArrayData = [];

    Object.keys(selectedState).map((item) => {
      let obj = {};

      obj.id = Number(item);
      selectedKeys.push(item);
      tempArray.push(obj);

      return 0;
    });

    ids.map((item) => {
      if (selectedKeys.includes(item.toString())) {
        tempArrayData.push(item);
      }
      return 0;
    });

    let strSelectedKeys = "";

    if (tempArrayData.length > 0) {
      strSelectedKeys = parseInt(selectedKeys[0]);
    }

    restProps.setSelectedRowId(strSelectedKeys);
  };

  const handlePageChange = async (event) => {
    const pageData = event.page;

    setAPICallingState(true);

    setPage({ skip: pageData.skip, take: pageData.take });

    const dataVal = {
      ...apiCallData,
      in_filter: getInFilter(),
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
      in_page_row_offset: pageData.skip,
      in_page_rows: pageData.take,
    };

    getGridData(dataVal);
  };

  const handleColumnMenu = (columnMenuData, isFilter, isSort) => {
    let index = 1;
    let filterSkip = getPageSkip();
    let filterTake = getPageTake();

    if (isFilter) {
      filterSkip = 0;
      filterTake = pageSize.recordTake;

      //reset pager when filter applies
      setPage({ skip: filterSkip, take: filterTake });
    }

    if (data.length > 0 && columnMenuData[0] !== undefined) {
      let obj = Object.keys(data[0]);
      index = obj.findIndex((x) => x === columnMenuData[0].field) + 1;
    }

    const filterString = isFilter
      ? getfilterDataString(columnMenuData, gridColumns)
      : filterData.data;

    isFilter = false;
    if (filterString !== "") {
      isFilter = true;
    }

    let sortDir =
      filterData.isSort && columnMenuData.length ? filterData.sort_order : DEFAULT_SORT.order;
    let sortNumber =
      filterData.isSort && columnMenuData.length ? filterData.in_sort_number : DEFAULT_SORT.number;

    isSort = !columnMenuData.length ? false : filterData.isSort;

    if (columnMenuData[0] !== undefined && columnMenuData[0].dir !== undefined) {
      isSort = true;
      sortDir = columnMenuData[0].dir.toUpperCase();
      sortNumber = index;
    }

    const dataVal = {
      ...apiCallData,
      in_filter: isFilter ? filterString : null,
      in_sort_number: sortNumber,
      in_sort_order: sortDir,
      in_page_row_offset: filterSkip,
      in_page_rows: filterTake,
    };

    setFilterData({
      ...filterData,
      isFilter: isFilter,
      isSort: isSort,
      data: filterString,
      in_sort_number: sortNumber,
      sort_order: sortDir,
    });

    setAPICallingState(true);

    getGridData(dataVal);
  };

  const NameCell = React.useCallback(
    (props) => {
      const { ariaColumnIndex, columnIndex, dataItem, field, render } = props;
      let isInEdit = field === dataItem.inEdit;

      let value = props.field + dataItem.rowId;
      const handleChange = (e, props, value) => {
        if (props.onChange) {
          // TODO : UNSAVED CHANGES
          //dispatch(setUnSavedChangesPopup({ ...unsavedPopup, isUnSavedChange: true }));
          props.onChange({
            dataItem: props.dataItem,
            field: props.field,
            syntheticEvent: e.syntheticEvent,
            value: e.target.value,
          });
        }
      };

      const defaultRendering = (
        <td aria-colindex={ariaColumnIndex} data-grid-col-index={columnIndex}>
          {isInEdit ? (
            <Input
              // required
              value={dataItem[props.field]}
              onChange={(e) => {
                handleChange(e, props, value);
              }}
              name={`${props.field}`}
              autoComplete="off"
            />
          ) : (
            <span title={props.dataItem[props.field || ""]}>
              {props.dataItem[props.field || ""]}
            </span>
          )}
          {Object.keys(errorObj).length > 0 ? (
            <div role="alert" className="k-form-error k-text-start">
              {Object.keys(errorObj).length > 0 ? errorObj[value] : ""}
            </div>
          ) : (
            false
          )}
        </td>
      );
      return render?.call(undefined, defaultRendering, props);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errorObj]
  );

  const useCol = (columns) => {
    return columns.map((column, idx) => {
      return column.show && column.title !== "" ? (
        <Column {...columnMenuProps(column)} key={idx} cell={NameCell} sortable={true} />
      ) : (
        false
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  let GroupColumn = useCol(gridColumns);

  const onClearFilter = async () => {
    setAPICallingState(true);

    //set in_params
    const dataVal = {
      ...apiCallData,
      in_filter: null,
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
      in_page_row_offset: getPageSkip(),
      in_page_rows: getPageTake(),
    };

    //set filter to false
    setFilterData({
      ...filterData,
      data: "",
      isFilter: false,
    });

    getGridData(dataVal);
  };

  const getGridData = async (dataVal) => {
      // TODO : UNSAVED CHANGES
      // dispatch(setUnSavedChangesPopup({ ...unsavedPopup, isUnSavedChange: false }));
    let response = await Action.getListDataAsync(dataVal);

    restProps.setDataList(response);
    setPageMenuState(response);
    // restProps.setGroupEdit(false)
    changeCancelBtnState();
    changeUpdateBtnState();
  };

  const onRefreshClick = () => {
    setAPICallingState(true);

    const dataVal = {
      ...apiCallData,
      in_filter: getInFilter(),
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
      in_page_row_offset: getPageSkip(),
      in_page_rows: getPageTake(),
    };
    getGridData(dataVal);
  };

  const exitEdit = (dataItem, index, dataIndex, e) => {
    if (dataItem.title_name === "") {
      handleValidation();
    }
    // let newData = data.map((item) => ({
    //   ...item,
    //   inEdit: undefined
    // }));
    // setData(newData);
  };

  const customRowRender = (tr, props) => (
    <RowRender originalProps={props} tr={tr} editField={editField} exitEdit={exitEdit} />
  );

  const getExportData = async () => {
    const dataVal = {
      ...apiCallData,
      in_page_row_offset: 0,
      in_page_rows: dataCount,
      in_filter: getInFilter(),
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
    };

    let res = await Action.exportDataAsync(dataVal);

    if (res) {
      setExportData(res);
    }
  };

  const customCellRender = (td, props) => {
    return (
      <CellRender
        navigatable={true}
        originalProps={props}
        td={td}
        enterEdit={enterEdit}
        editField={editField}
        onRowClick={onRowClick}
        updateActBtn="grid-incell-update"
        cancelActBtn="grid-incell-cancel"
      />
    );
  };

  return (
    <>
      {isRefreshLoading && loadingPanel}
      {columns.length > 0 ? (
        <DataTable
          isAPICalling={isAPICalling}
          initialLoading={initialLoading}
          data={data.map((item) => ({
            ...item,
            [SELECTED_FIELD]: selectedState[idGetter(item)],
          }))}
          id="group-grid"
          editField={editField}
          pageData={pageData}
          dataCount={dataCount}
          customColumn={GroupColumn}
          columns={gridColumns}
          dataItemKey={DATA_ITEM_KEY}
          width={"auto"}
          module={"group"}
          getExportData={getExportData}
          setExportData={restProps.setExportDataClick}
          isExportDataClick={restProps.exportDataClick}
          fileName={"Export_group.xlsx"}
          exportData={exportData}
          rowRender={customRowRender}
          cellRender={customCellRender}
          itemChange={itemChange}
          defaultPageSize={defaultPageSize}
          pageSize={pageSize}
          setPageSize={setPageSize}
          handleColumnMenu={handleColumnMenu}
          handlePageChange={handlePageChange}
          refreshClick={onRefreshClick}
          onClearFilter={onClearFilter}
          onSelectionChange={onSelectionChange}
          reorderable={restProps.pageProps.writeAccess === 0 ? false : true}
        />
      ) : (
        <>{loadingPanel}</>
      )}
      <DialogComponent
        title={confirmationPopup.title}
        onCancelConfirm={onCancelConfirm}
        width={"450"}
        height={"auto"}
        className={"Dialog-Delete"}
        desc={confirmationPopup.desc}
        visible={confirmationPopup.isVisible}
        actionBtn={confirmationPopup.actionBtn}
        titleClassName={confirmationPopup.action === "Delete" ? "delete-confirm" : "unSave-confirm"}
      />
    </>
  );
};

export const GridButton = (restProps) => {
  return (
    <>
      <Button type="submit" className="cancelbtn" id="grid-incell-cancel">
        Cancel
      </Button>
      <Button type="submit" className="primarybtn" id="grid-incell-update">
        Save
      </Button>
    </>
  );
};
