import React from "react";
import TmplAuth from "src/template/tmplAuth";
import UserNameForm from "./form/userNameForm";

const LoginContent = () => {
    return (
        <div className="loginwelcometext">
            <h3>Sign in</h3>
            <p>to continue</p>
        </div>
    );
};

const Login = () => {
    return <TmplAuth form={<UserNameForm/>} pageContent={<LoginContent/>} />;
};

export default React.memo(Login);
