import React from "react";
import {
  GET_JOB_HISTORY,
  GET_SELECT_GRID_COLUMN_XML,
} from "../../api-setup/api-endpoints";
import { getTenantCookieData, getUserCredential } from "../../lib/cookies";
import {
  POSTAPI,
  errStatusHandler,
  generateHeader,
} from "../../api-setup/api-helper";
import { GridColumnDataMapper } from "../../utils/utils";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { setNewMessage } from "../../redux/actions/setting-actions";
import { GetPeriodTypeAPI, GetPeriodsDataList } from "../../lib/common-service";

export default function Title() {
  const defaultPageSize = getTenantCookieData("record_per_page");
  const cookiesData = getUserCredential();
  const header = {
    "x-access-token": cookiesData.secretToken,
  };
  const history = useHistory();
  const dispatch = useDispatch();
  const [data, setData] = React.useState([]);
  const [columns, setColumns] = React.useState([]);
  const [dataCount, setDataCount] = React.useState(0);
  const [loadingState, setLoadState] = React.useState({
    isSaveData: undefined,
    isDeleteData: undefined,
  });
  const [initialLoading, setLoadingState] = React.useState(false);
  const [isAPICalling, setAPICallingState] = React.useState(false);
  const [isRefreshLoading, setRefreshLoading] = React.useState(false);
  const [loading, setLoadingStateSearch] = React.useState({
    isSearchLoader: false,
    updateColumn: false,
  });
  const [periodsTypeFields, setPeriodTypeFields] = React.useState({
    periodTypeValue: {
      in_period_mstr_key: "",
    },
    periodTypeData: [],
    isPeriodTypeDataLoading: true,
  });
  const [jobNameFields, setJobNameFields] = React.useState({
    jobNameValue: [],
    jobNameData: [],
    isJobNameDataLoading: true,
  });
  const [jobNameList, setJobNameList] = React.useState([]);
  const [groupNameList, setGroupNameList] = React.useState([]);
  const [groupNameFields, setGroupNameFields] = React.useState({
    groupNameValue: [],
    groupValueData: [],
    isGroupNameDataLoading: true,
  });
  const [periodTypeList, setPeriodTypeList] = React.useState([]);
  const [periodsFields, setPeriodFields] = React.useState({
    periodsValue: {
      id: "",
      text: "",
    },
    periodsData: [],
    isPeriodDataLoading: true,
  });
  const [jobTypeFields, setJobTypeFields] = React.useState({
    jobType: {
      id: "",
      text: "",
    },
    jobTypeData: [],
    isJobTypeDataLoading: true,
  });
  const [jobTypeList, setJobTypeList] = React.useState([]);
  const [periodsList, setPeriodsList] = React.useState([]);

  const getListDataAsync = (listData, filterData) => {
    listData.in_filter =
      listData.in_filter !== null
        ? filterData + listData.in_filter
        : filterData;
    return new Promise(function (resolve, reject) {
      POSTAPI(false, GET_JOB_HISTORY, null, header, listData, dispatch).then(
        (res) => {
          if (res) {
            const reqData = {
              in_tenant_id: cookiesData.out_tenant_id,
              in_filter: null,
              in_filter_db_name: "procare_system",
              in_filter_table_name: "vw_job_history",
              in_result_name_mstr_key: 0,
              in_event_type_mstr_key: 0,
              in_hide_column: 0,
            };

            if (listData.in_load_type == "grid-reload") {
              setTimeout(function () {
                getColumListXMLData(reqData);
              }, 100);
            }
            if (res.data.out_parameters.out_ret_code > 0) {
              setLoadingState(true);
              errStatusHandler(res, history, dispatch);
            }
            let userData = [];
            userData = JSON.parse(JSON.stringify(res.data.row_result));
            userData.map((item, idx) => {
              item.rowId = idx;
              item.selected = false;
              // Check each key in POSTION_PARMAS

              return 0;
            });
            setAPICallingState(false);
            setLoadingState(true);

            setData(userData);
            setDataCount(res.data.out_parameters.out_total_rows);
            setRefreshLoading(false);

            resolve(userData);
          }
        }
      );
    })
      .then((res) => {
        return res;
      })
      .catch((res) => {
        if (res.data.out_parameters.out_ret_code > 0) {
          dispatch(
            setNewMessage({
              message: res.data.out_parameters.out_error_msg,
              type: "Error",
              show: true,
            })
          );
          errStatusHandler(res, history, dispatch);
        }
      });
  };

  const getColumListXMLData = (reqData) => {
    return new Promise(function (resolve, reject) {
      POSTAPI(
        false,
        GET_SELECT_GRID_COLUMN_XML,
        null,
        header,
        reqData,
        dispatch
      ).then((res) => {
        if (res.data && res.data.out_parameters.out_ret_code > 0) {
          dispatch(
            setNewMessage({
              message: res.data.out_parameters.out_error_msg,
              type: "Error",
              show: true,
            })
          );
          setAPICallingState(true);
          setLoadingState(true);
          errStatusHandler(res, history, dispatch);
        } else {
          const columData = GridColumnDataMapper(
            res.data.out_parameters.out_columns_xml
          );

          setColumns(columData);
          resolve(columData);
        }
      });
    }).then((res) => {
      return res;
    });
  };

  return [
    {
      data,
      columns,
      dataCount,
      setData,
      setColumns,
      isAPICalling,
      initialLoading,
      setAPICallingState,
      loadingState,
      setLoadState,
      setRefreshLoading,
      isRefreshLoading,
      loading,
      setLoadingStateSearch,
      setJobTypeFields,

      setGroupNameFields,
      setJobNameFields,

      setPeriodFields,

      setPeriodTypeList,

      setPeriodTypeFields,
      setPeriodsList,
    },
    {
      getListDataAsync,

      getColumListXMLData,
    },
  ];
}
