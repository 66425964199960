/* eslint-disable jsx-a11y/anchor-is-valid */
import { Window, WindowActionsBar } from "@progress/kendo-react-dialogs";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { FieldWrapper, Form, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import { Error, Label } from "@progress/kendo-react-labels";
import {
    StackLayout,
    TabStrip,
    TabStripTab,
} from "@progress/kendo-react-layout";
import CustomDropdown from "src/components/customDropdown";
import { dashboardPodDialogHook } from "./index.hook";
import { LoadingPanel } from "src/components/loadingPanel";
import { Hint } from "@progress/kendo-react-labels";
import MultiSelectDropdown from "src/components/multiSelect";
import dashboardPodApi from "./service";
import {
    getDashboardEditPodDialog,
    mapDashboardPodData,
} from "src/store/dashboard.store";
import MessageSchema from "src/utils/messageSchema";
import { requiredValidator } from "src/components/form-validators";
import {
    MULTI_SELECT_ALL_TEXT,
    MULTI_SELECT_ALL_ZERO_ID,
} from "src/constants/constants";

const { name, periodTypeMstrKey, dashboardTypeMstrKey } = mapDashboardPodData;

export const DashboardPodDialog = ({
    closeDialog,
    refreshDashboardPodDetail,
}) => {
    const {
        dashboardDataSet,
        initialValues,
        dashboardTypeDataSet,
        dashboardPeriodTypeDataSet,
    } = dashboardPodDialogHook();

    const podDatasetDropdownOptions = {
        aggregationTypeOptions: [
            {
                text: "Avg",
                value: "avg",
            },
            {
                text: "Count",
                value: "count",
            },
            {
                text: "Max",
                value: "max",
            },
            {
                text: "Min",
                value: "min",
            },
            {
                text: "Sum",
                value: "sum",
            },
        ],
        chartTypeOptions: [
            {
                text: "Bar",
                value: "bar",
            },
            {
                text: "Column",
                value: "column",
            },
            {
                text: "Line",
                value: "line",
            },
            {
                text: "Pie",
                value: "pie",
            },
            {
                text: "Bubble",
                value: "bubble",
            },
        ],
        groupDimensionOptions: [
            {
                text: "",
                value: "",
            },
            {
                text: "Group",
                value: "group",
            },
            {
                text: "Specialty",
                value: "specialty",
            },
            {
                text: "Provider",
                value: "provider",
            },
        ],
    };

    const [formFields, setFormFields] = React.useState({ ...initialValues });
    const [selectedTab, setSelectedTab] = React.useState(0);
    const [modal, setModal] = React.useState(true);
    const [stageChange, setStateChange] = React.useState("DEFAULT");

    const [list, Action] = dashboardPodApi(
        formFields,
        closeDialog,
        refreshDashboardPodDetail,
        selectedTab,
        podDatasetDropdownOptions,
        dashboardDataSet
    );
    const {
        compPlanDataList,
        compPlanDataFields,
        setCompPlanDataFields,
        compPlanData2List,
        compPlanData2Fields,
        setCompPlanData2Fields,
        kpiDataFields,
        kpiDataList,
        setKpiDataFields,
        kpiData2Fields,
        kpiData2List,
        setKpiData2Fields,
        setResultFilterDataFields,
        resultFilterDataFields,
        resultFilterDataList,
        setResultFilterData2Fields,
        resultFilterData2Fields,
        resultFilterData2List,
        isEditForm,
    } = list;

    const [formDatasetFields, setFormDatasetFields] = useState({});
    const [formDataset2Fields, setFormDataset2Fields] = useState({});

    const [initialWidth, setInitialWidth] = useState();
    const [initialHeight, setInitialHeight] = useState();
    const [errorObj, setErrorObj] = useState({});
    const [isSaveClick, setSaveClick] = useState(false);
    //const [isDashboardTypeChanged, setIsDashboardTypeChanged] = useState(false);

    const [initialDashboardType, setInitialDashboardType] = useState(true);
    const [initialDashboardPeriodType, setInitialDashboardPeriodType] =
        useState(true);
    const [dashboardType, setDashboardType] = useState(dashboardTypeDataSet);
    const [periordType, setPeriodType] = useState(dashboardPeriodTypeDataSet);
    const [aggregationType, setAggregationType] = useState(
        podDatasetDropdownOptions.aggregationTypeOptions
    );
    const [groupDimension, setGroupDimension] = useState(
        podDatasetDropdownOptions.groupDimensionOptions
    );
    const [yearPeriod, setYearPeriod] = useState({});

    const chartValue =
        selectedTab === 0
            ? formDatasetFields?.[
                  "dataset[" + selectedTab + "]chartType"
              ]?.text?.toLowerCase()
            : formDataset2Fields?.[
                  "dataset[" + selectedTab + "]chartType"
              ]?.text?.toLowerCase();

    const isLineChart = chartValue === "line";
    const isPieChart = chartValue === "pie";
    const isBubbleChart = chartValue === "bubble";
    const isStackedDisabled = isLineChart || isPieChart || isBubbleChart;
    const isDisableDataName =
        isPieChart ||
        (selectedTab === 0
            ? formDatasetFields?.["dataset[" + selectedTab + "]groupByResult"]
                  ?.value
            : formDataset2Fields?.["dataset[" + selectedTab + "]groupByResult"]
                  ?.value);

    const dashboardEditPodData = getDashboardEditPodDialog();

    const [updateInitialValue, setUpdateInitialValue] = useState(false);
    const [updateInitialDatasetValue, setUpdateInitialDatasetValue] =
        useState(false);

    // get comp plan after select dataset tabstrip
    useEffect(() => {
        if (updateInitialDatasetValue) {
            return;
        }
        if (!isEditForm) {
            Action.getCompPlanList(1);
            Action.getCompPlanList(2);

            const initialDatasetValue = {
                "dataset[0]dataName": "",
                "dataset[0]isStacked": { value: false },
                "dataset[0]groupByResult": { value: false },
                "dataset[0]aggregationType":
                    podDatasetDropdownOptions?.aggregationTypeOptions?.[0],
                "dataset[0]chartType":
                    podDatasetDropdownOptions?.chartTypeOptions?.[0],
                "dataset[0]groupDimension":
                    podDatasetDropdownOptions?.groupDimensionOptions?.[0],
            };
            setFormDatasetFields({ ...initialDatasetValue });

            const initialDataset2Value = {
                "dataset[1]dataName": "",
                "dataset[1]isStacked": { value: false },
                "dataset[0]groupByResult": { value: false },
                "dataset[1]aggregationType":
                    podDatasetDropdownOptions.aggregationTypeOptions[0],
                "dataset[1]chartType":
                    podDatasetDropdownOptions.chartTypeOptions[0],
                "dataset[1]groupDimension":
                    podDatasetDropdownOptions.groupDimensionOptions[0],
            };
            setFormDataset2Fields({ ...initialDataset2Value });
            setUpdateInitialDatasetValue(true);
        } else {
            if (dashboardDataSet?.data) {
                Action.getCompPlanList(1);
                Action.getCompPlanList(2);

                const podDatset1 = Action.getPodDatasetData(0);
                const initialDatasetValue = {
                    "dataset[0]dataName": podDatset1?.dataset_name || "",
                    "dataset[0]isStacked": {
                        value: podDatset1?.is_stacked ? true : false,
                    },
                    "dataset[0]groupByResult": {
                        value: podDatset1?.is_group_by_result ? true : false,
                    },
                    "dataset[0]aggregationType":
                        podDatasetDropdownOptions?.aggregationTypeOptions?.find(
                            (o) =>
                                o.value ===
                                podDatset1?.aggregation?.toLowerCase()
                        ) ||
                        podDatasetDropdownOptions?.aggregationTypeOptions?.[0],
                    "dataset[0]chartType":
                        podDatasetDropdownOptions?.chartTypeOptions?.find(
                            (o) =>
                                o.value ===
                                podDatset1?.chart_type?.toLowerCase()
                        ) || podDatasetDropdownOptions?.chartTypeOptions?.[0],
                    "dataset[0]groupDimension":
                        podDatasetDropdownOptions?.groupDimensionOptions?.find(
                            (o) =>
                                o.value ===
                                podDatset1?.group_dimension?.toLowerCase()
                        ) ||
                        podDatasetDropdownOptions?.groupDimensionOptions?.[0],
                };
                setFormDatasetFields({ ...initialDatasetValue });

                const podDatset2 = Action.getPodDatasetData(1);
                const initialDataset2Value = {
                    "dataset[1]dataName": podDatset2?.dataset_name || "",
                    "dataset[1]isStacked": {
                        value: podDatset2?.is_stacked ? true : false,
                    },
                    "dataset[1]groupByResult": {
                        value: podDatset2?.is_group_by_result ? true : false,
                    },
                    "dataset[1]aggregationType":
                        podDatasetDropdownOptions?.aggregationTypeOptions?.find(
                            (o) =>
                                o.value ===
                                podDatset2?.aggregation?.toLowerCase()
                        ) ||
                        podDatasetDropdownOptions?.aggregationTypeOptions?.[0],
                    "dataset[1]chartType":
                        podDatasetDropdownOptions?.chartTypeOptions?.find(
                            (o) =>
                                o.value ===
                                podDatset2?.chart_type?.toLowerCase()
                        ) || podDatasetDropdownOptions?.chartTypeOptions?.[0],
                    "dataset[1]groupDimension":
                        podDatasetDropdownOptions?.groupDimensionOptions?.find(
                            (o) =>
                                o.value ===
                                podDatset2?.group_dimension?.toLowerCase()
                        ) ||
                        podDatasetDropdownOptions?.groupDimensionOptions?.[0],
                };
                setFormDataset2Fields({ ...initialDataset2Value });
                setUpdateInitialDatasetValue(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateInitialDatasetValue, isEditForm, dashboardDataSet?.data]);

    // get KPI for dataset-1 after loading comp_plan
    useEffect(() => {
        if (
            !compPlanDataFields.isCompPlanDataLoading &&
            compPlanDataFields?.["dataset[0]compPlanFilter"]?.length
        ) {
            Action.getKpiDataList(1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        compPlanDataFields.isCompPlanDataLoading,
        compPlanDataFields?.["dataset[0]compPlanFilter"],
    ]);

    // get KPI for dataset-2 after loading comp_plan
    useEffect(() => {
        if (
            !compPlanData2Fields.isCompPlanDataLoading &&
            compPlanData2Fields?.["dataset[1]compPlanFilter"]?.length
        ) {
            Action.getKpiDataList(2);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        compPlanData2Fields.isCompPlanDataLoading,
        compPlanData2Fields?.["dataset[1]compPlanFilter"],
    ]);

    // get Result Name for dataset-1 after loading KPI
    useEffect(() => {
        if (!kpiDataFields.isKpiDataLoading) {
            Action.getResultDataList(1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        kpiDataFields.isKpiDataLoading,
        kpiDataFields?.["dataset[0]kpiFilter"],
    ]);

    // get Result Name for dataset-2 after loading KPI
    useEffect(() => {
        if (!kpiData2Fields.isKpiDataLoading) {
            Action.getResultDataList(2);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        kpiData2Fields.isKpiDataLoading,
        kpiData2Fields?.["dataset[1]kpiFilter"],
    ]);

    useEffect(() => {
        if (
            !initialDashboardType &&
            !initialDashboardPeriodType &&
            !updateInitialValue
        ) {
            let dashboardType = dashboardTypeDataSet.find(
                (dashboardTypeObj) =>
                    dashboardTypeObj.value ===
                    (dashboardTypeMstrKey.getData(
                        dashboardEditPodData?.podDetail
                    ) || dashboardTypeDataSet[0].value)
            );
            let dashboardPeriodType = dashboardPeriodTypeDataSet.find(
                (dashboardPeriodTypeObj) =>
                    dashboardPeriodTypeObj.value ===
                    (periodTypeMstrKey.getData(
                        dashboardEditPodData?.podDetail
                    ) || dashboardPeriodTypeDataSet[0].value)
            );

            if (dashboardType === null) {
                dashboardType = dashboardTypeDataSet[0].value;
            }

            if (dashboardPeriodType === null) {
                dashboardPeriodType = dashboardPeriodTypeDataSet[0].value;
            }

            setFormFields({
                ...formFields,
                labelName: name.getData(dashboardEditPodData?.podDetail) || "",
                dashboardType: dashboardType,
                dashboardPeriodType: dashboardPeriodType,
            });

            setUpdateInitialValue(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialDashboardType, initialDashboardPeriodType]);

    useEffect(() => {
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;

        const newInitialWidth = Math.min(screenWidth * 0.85, 1000);
        const newInitialHeight = Math.min(screenHeight * 0.85, 670);

        setInitialWidth(newInitialWidth);
        setInitialHeight(newInitialHeight);
    }, []);

    const ShowError = useCallback(
        (props) => {
            return <Error>{errorObj[props.name]}</Error>;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [errorObj, isSaveClick]
    );

    const handleFieldChange = (e) => {
        const name = e.name ? e.name : e.target.name;
        if (e.value !== null) {
            setFormFields({ ...formFields, [name]: e.value });
            setErrorObj({ ...errorObj, [name]: "" });
        }
    };

    const handleDatasetFieldChange = (e) => {
        const name = e.name ? e.name : e.target.name;
        if (e.value !== null) {
            if (selectedTab === 0) {
                setFormDatasetFields({
                    ...formDatasetFields,
                    [name]: e.value,
                });
            } else {
                setFormDataset2Fields({
                    ...formDataset2Fields,
                    [name]: e.value,
                });
            }
            setErrorObj({ ...errorObj, [name]: "" });
        }
    };

    useEffect(() => {
        if (
            initialDashboardType &&
            dashboardTypeDataSet.length > 0 &&
            initialDashboardPeriodType &&
            dashboardPeriodTypeDataSet.length > 0
        ) {
            setDashboardType(dashboardTypeDataSet);
            setPeriodType(dashboardPeriodTypeDataSet);
            setFormFields({
                ...formFields,
                dashboardType: dashboardTypeDataSet[0],
                dashboardPeriodType: dashboardPeriodTypeDataSet[0],
            });

            const year = dashboardPeriodTypeDataSet.find(
                (item) => item?.text?.toLowerCase() === "year"
            );

            setYearPeriod(year);

            setInitialDashboardType(false);
            setInitialDashboardPeriodType(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dashboardTypeDataSet, dashboardPeriodTypeDataSet]);

    const handleValidation = () => {
        let isValid = true;
        let errObj = {};

        if (formFields?.labelName?.trim() === "") {
            isValid = false;
            errObj.labelName = MessageSchema.labelnameRequired;
        }
        if (formFields?.dashboardType === null) {
            isValid = false;
            errObj.dashboardType = requiredValidator(formFields?.dashboardType);
        }
        if (formFields?.dashboardPeriodType === null) {
            isValid = false;
            errObj.dashboardPeriodType = requiredValidator(
                formFields?.dashboardPeriodType
            );
        }

        const isGroupBy = formDatasetFields?.["dataset[0]groupByResult"]?.value;
        const dataSetName = formDatasetFields?.["dataset[0]dataName"]?.trim();

        if (!isGroupBy && dataSetName === "") {
            selectedTab !== 0 && setSelectedTab(0);
            isValid = false;
            errObj["dataset[0]dataName"] = MessageSchema.datanameRequired;
        }

        setErrorObj({ ...errObj });
        return isValid;
    };

    const handleSubmitCall = async () => {
        if (handleValidation()) {
            setSaveClick(true);
            Action.onSubmit.mutate({
                formDatasetFields,
                formDataset2Fields,
            });
        }
    };

    const handleDatasetDropDownChange = (e, name) => {
        //const handleDatasetDropDownChange = useCallback(
        //(e, name) => {
        if (!e) return;

        if (selectedTab === 0) {
            const isPieChart =
                name.toLowerCase() === "dataset[0]charttype" &&
                e.text.toLowerCase() === "pie";
            const isBubbleChart =
                name.toLowerCase() === "dataset[0]charttype" &&
                e.text.toLowerCase() === "bubble";

            const updatedFields = {
                [name]: e,
                ...(isPieChart && {
                    "dataset[0]groupByResult": { value: true },
                }),
                ...(isBubbleChart && {
                    "dataset[0]groupByResult": { value: false },
                }),
                ...(isStackedDisabled && {
                    "dataset[0]isStacked": { value: false },
                }),
            };

            if (isPieChart || isBubbleChart) {
                setFormFields({
                    ...formFields,
                    dashboardPeriodType: yearPeriod,
                });
            }
            setFormDatasetFields((prevFields) => ({
                ...prevFields,
                ...updatedFields,
            }));
        } else {
            const updatedFields = {
                [name]: e,
                ...(isStackedDisabled && {
                    "dataset[1]isStacked": { value: false },
                }),
            };

            setFormDataset2Fields((prevFields) => ({
                ...prevFields,
                ...updatedFields,
            }));
        }
    };

    const handleSelect = (e) => {
        setSelectedTab(e.selected);
    };

    const handleClearDataset = () => {
        const initialDataset2Value = {
            "dataset[1]dataName": "",
            "dataset[1]isStacked": { value: false },
            "dataset[1]groupByResult": { value: false },
            "dataset[1]aggregationType":
                podDatasetDropdownOptions.aggregationTypeOptions[0],
            "dataset[1]chartType":
                podDatasetDropdownOptions.chartTypeOptions[0],
            "dataset[1]groupDimension":
                podDatasetDropdownOptions.groupDimensionOptions[0],
        };
        setFormDataset2Fields({ ...initialDataset2Value });

        const defaultSelectAllOption = {
            text: MULTI_SELECT_ALL_TEXT,
            id: MULTI_SELECT_ALL_ZERO_ID,
        };

        setCompPlanData2Fields({
            ...compPlanData2Fields,
            "dataset[1]compPlanFilter": [defaultSelectAllOption],
        });

        setKpiData2Fields({
            ...kpiData2Fields,
            "dataset[1]kpiFilter": [defaultSelectAllOption],
        });

        setResultFilterData2Fields({
            ...resultFilterData2Fields,
            "dataset[1]resultFilterY": [defaultSelectAllOption],
        });
    };

    const isLoading =
        dashboardDataSet?.isLoading ||
        compPlanDataFields.isCompPlanDataLoading ||
        compPlanData2Fields.isCompPlanDataLoading ||
        kpiDataFields.isKpiDataLoading ||
        kpiData2Fields.isKpiDataLoading ||
        resultFilterDataFields.isResultFilterLoading ||
        resultFilterData2Fields.isResultFilterLoading;

    const renderForm = () => (
        <Form
            initialValues={initialValues || {}}
            render={() => (
                <StackLayout orientation="vertical" className="form-container">
                    <div className="pod-scroll">
                        <StackLayout
                            orientation="vertical"
                            gap={15}
                            className="form-body"
                        >
                            <FormElement className="dashboardPodForm">
                                <div className="dashboardPodHeader">
                                    <StackLayout gap={15}>
                                        <FieldWrapper>
                                            <div
                                                className={
                                                    "k-form-field-wrap-popup"
                                                }
                                            >
                                                <Label>{"Label Name"}</Label>
                                                <Input
                                                    type={"text"}
                                                    name="labelName"
                                                    value={formFields.labelName}
                                                    onChange={(e) => {
                                                        handleFieldChange(e);
                                                    }}
                                                    autoFocus={true}
                                                    autoComplete="off"
                                                />
                                                <ShowError name={"labelName"} />
                                            </div>
                                        </FieldWrapper>

                                        <FieldWrapper>
                                            <div
                                                className={
                                                    "k-form-field-wrap-popup"
                                                }
                                            >
                                                <Label>
                                                    {"Dashboard Type"}
                                                </Label>
                                                <CustomDropdown
                                                    name={"dashboardType"}
                                                    data={dashboardType}
                                                    textField="text"
                                                    dataItemKey="value"
                                                    valueData={dashboardType.find(
                                                        (c) =>
                                                            c.text ===
                                                            formFields
                                                                ?.dashboardType
                                                                ?.text
                                                    )}
                                                    onChange={async (
                                                        data,
                                                        name
                                                    ) => {
                                                        setFormFields({
                                                            ...formFields,
                                                            [name]: data,
                                                        });
                                                        await Action.getResultDataList(
                                                            selectedTab + 1,
                                                            data?.text
                                                        );
                                                    }}
                                                    filterable={false}
                                                    onOpen={(dataV) => {
                                                        setDashboardType(dataV);
                                                    }}
                                                    autoClose={false}
                                                    autoBind={true}
                                                    dataList={dashboardType}
                                                />
                                            </div>
                                        </FieldWrapper>

                                        <FieldWrapper>
                                            <div
                                                className={
                                                    "k-form-field-wrap-popup"
                                                }
                                            >
                                                <Label>{"Period Type"}</Label>
                                                <CustomDropdown
                                                    name={"dashboardPeriodType"}
                                                    data={periordType}
                                                    textField="text"
                                                    dataItemKey="value"
                                                    valueData={periordType.find(
                                                        (c) =>
                                                            c.text ===
                                                            formFields
                                                                ?.dashboardPeriodType
                                                                ?.text
                                                    )}
                                                    onChange={(e, name) => {
                                                        setFormFields({
                                                            ...formFields,
                                                            [name]: e,
                                                        });
                                                        /*
                                                        setIsDashboardTypeChanged(
                                                            true
                                                        );
                                                        */
                                                    }}
                                                    filterable={false}
                                                    onOpen={(dataV) => {
                                                        setPeriodType(dataV);
                                                    }}
                                                    autoClose={false}
                                                    autoBind={true}
                                                    dataList={periordType}
                                                    disabled={
                                                        isPieChart ||
                                                        isBubbleChart
                                                    }
                                                />
                                            </div>
                                        </FieldWrapper>
                                    </StackLayout>
                                </div>
                                <TabStrip
                                    selected={selectedTab}
                                    onSelect={handleSelect}
                                    className="tab-strip-width kpitabsinnertabs kpitabs dashboardpodadd"
                                    keepTabsMounted={true}
                                >
                                    <TabStripTab title={"Dataset 1"}>
                                        <StackLayout
                                            orientation="vertical"
                                            gap={15}
                                        >
                                            <StackLayout gap={15}>
                                                <div
                                                    className={
                                                        "k-form-field-wrap-popup"
                                                    }
                                                >
                                                    <Label>{"Data Name"}</Label>
                                                    <Input
                                                        type={"text"}
                                                        name="dataset[0]dataName"
                                                        value={
                                                            formDatasetFields?.[
                                                                "dataset[0]dataName"
                                                            ]
                                                        }
                                                        onChange={(e) => {
                                                            handleDatasetFieldChange(
                                                                e
                                                            );
                                                        }}
                                                        disabled={
                                                            isDisableDataName
                                                        }
                                                        autoComplete="off"
                                                    />
                                                    <ShowError
                                                        name={
                                                            "dataset[0]dataName"
                                                        }
                                                    />
                                                </div>
                                                <div
                                                    className={
                                                        "k-form-field-wrap-popup"
                                                    }
                                                >
                                                    <Label>
                                                        {"Aggregation Type"}
                                                    </Label>
                                                    <CustomDropdown
                                                        name={
                                                            "dataset[0]aggregationType"
                                                        }
                                                        data={aggregationType}
                                                        textField="text"
                                                        dataItemKey="value"
                                                        valueData={aggregationType.find(
                                                            (c) =>
                                                                c.text ===
                                                                formDatasetFields?.[
                                                                    "dataset[0]aggregationType"
                                                                ]?.text
                                                        )}
                                                        onChange={(e, name) => {
                                                            handleDatasetDropDownChange(
                                                                e,
                                                                name
                                                            );
                                                        }}
                                                        filterable={false}
                                                        onOpen={(dataV) => {
                                                            setAggregationType(
                                                                dataV
                                                            );
                                                        }}
                                                        autoClose={false}
                                                        autoBind={true}
                                                        dataList={
                                                            aggregationType
                                                        }
                                                    />
                                                </div>
                                            </StackLayout>
                                            <StackLayout gap={15}>
                                                <div
                                                    className={
                                                        "k-form-field-wrap-popup"
                                                    }
                                                >
                                                    <Label>
                                                        {"Chart Type"}
                                                    </Label>
                                                    <CustomDropdown
                                                        name={
                                                            "dataset[0]chartType"
                                                        }
                                                        data={
                                                            podDatasetDropdownOptions.chartTypeOptions
                                                        }
                                                        textField="text"
                                                        dataItemKey="value"
                                                        valueData={podDatasetDropdownOptions.chartTypeOptions.find(
                                                            (c) =>
                                                                c.text ===
                                                                formDatasetFields?.[
                                                                    "dataset[0]chartType"
                                                                ]?.text
                                                        )}
                                                        onChange={(e, name) => {
                                                            handleDatasetDropDownChange(
                                                                e,
                                                                name
                                                            );
                                                        }}
                                                        filterable={false}
                                                        autoClose={false}
                                                        autoBind={true}
                                                        dataList={
                                                            podDatasetDropdownOptions.chartTypeOptions
                                                        }
                                                    />
                                                </div>
                                                <div
                                                    className={
                                                        "k-form-field-wrap-popup"
                                                    }
                                                >
                                                    <Label>
                                                        {"Group Dimension"}
                                                    </Label>
                                                    <CustomDropdown
                                                        name={
                                                            "dataset[0]groupDimension"
                                                        }
                                                        data={groupDimension}
                                                        textField="text"
                                                        dataItemKey="value"
                                                        valueData={groupDimension.find(
                                                            (c) =>
                                                                c.text ===
                                                                formDatasetFields?.[
                                                                    "dataset[0]groupDimension"
                                                                ]?.text
                                                        )}
                                                        onChange={(e, name) => {
                                                            handleDatasetDropDownChange(
                                                                e,
                                                                name
                                                            );
                                                        }}
                                                        filterable={false}
                                                        onOpen={(dataV) => {
                                                            setGroupDimension(
                                                                dataV
                                                            );
                                                        }}
                                                        autoClose={false}
                                                        autoBind={true}
                                                        dataList={
                                                            groupDimension
                                                        }
                                                    />
                                                </div>
                                            </StackLayout>
                                            <StackLayout gap={15}>
                                                <div
                                                    className={
                                                        "k-form-field-wrap checkbox"
                                                    }
                                                >
                                                    <Checkbox
                                                        name={
                                                            "dataset[0]isStacked"
                                                        }
                                                        label={"Stacked"}
                                                        checked={
                                                            isStackedDisabled
                                                                ? false
                                                                : formDatasetFields?.[
                                                                      "dataset[0]isStacked"
                                                                  ]?.value
                                                        }
                                                        onChange={(e) => {
                                                            handleDatasetDropDownChange(
                                                                {
                                                                    value: e.value,
                                                                },
                                                                "dataset[0]isStacked"
                                                            );
                                                        }}
                                                        disabled={
                                                            isStackedDisabled
                                                        }
                                                    />
                                                    <Hint>
                                                        Please select if it is
                                                        stacked
                                                    </Hint>
                                                </div>
                                                <div
                                                    className={
                                                        "k-form-field-wrap checkbox"
                                                    }
                                                >
                                                    <Checkbox
                                                        name={
                                                            "dataset[0]groupByResult"
                                                        }
                                                        label={
                                                            "Group By Result"
                                                        }
                                                        checked={
                                                            formDatasetFields?.[
                                                                "dataset[0]groupByResult"
                                                            ]?.value
                                                        }
                                                        onChange={(e) => {
                                                            handleDatasetDropDownChange(
                                                                {
                                                                    value: e.value,
                                                                },
                                                                "dataset[0]groupByResult"
                                                            );
                                                        }}
                                                        disabled={
                                                            isPieChart ||
                                                            isBubbleChart
                                                        }
                                                    />
                                                    <Hint>
                                                        Please select if it can
                                                        be Group By Result
                                                    </Hint>
                                                </div>
                                            </StackLayout>
                                            <StackLayout
                                                orientation="vertical"
                                                gap={15}
                                            >
                                                <div
                                                    className={
                                                        "k-form-field-wrap-popup valueDropdown"
                                                    }
                                                >
                                                    <Label>
                                                        {"Comp Plan Filter"}
                                                    </Label>
                                                    <MultiSelectDropdown
                                                        name={
                                                            "dataset[0]compPlanFilter"
                                                        }
                                                        module="compPlanFilter"
                                                        data={
                                                            compPlanDataFields.compPlanData
                                                        }
                                                        textField="text"
                                                        onOpen={(data) => {
                                                            setCompPlanDataFields(
                                                                {
                                                                    ...compPlanDataFields,
                                                                    compPlanData:
                                                                        data,
                                                                }
                                                            );
                                                        }}
                                                        dataItemKey="id"
                                                        valueData={
                                                            compPlanDataFields?.[
                                                                "dataset[0]compPlanFilter"
                                                            ]
                                                        }
                                                        onChange={(
                                                            data,
                                                            name
                                                        ) => {
                                                            setCompPlanDataFields(
                                                                {
                                                                    ...compPlanDataFields,
                                                                    [name]: data,
                                                                }
                                                            );
                                                            setErrorObj({
                                                                ...errorObj,
                                                                [name]: "",
                                                            });
                                                        }}
                                                        filterable={true}
                                                        onFilterChange={(
                                                            dataValue
                                                        ) => {
                                                            setCompPlanDataFields(
                                                                {
                                                                    ...compPlanDataFields,
                                                                    compPlanData:
                                                                        dataValue,
                                                                }
                                                            );
                                                        }}
                                                        autoClose={false}
                                                        autoBind={true}
                                                        isSelectALl={true}
                                                        selectAllId={"0"}
                                                        dataList={
                                                            compPlanDataList
                                                        }
                                                        loading={
                                                            compPlanDataFields.isCompPlanDataLoading
                                                        }
                                                    />
                                                </div>
                                                <div
                                                    className={
                                                        "k-form-field-wrap-popup valueDropdown"
                                                    }
                                                >
                                                    <Label>
                                                        {"KPI Filter"}
                                                    </Label>
                                                    <MultiSelectDropdown
                                                        name={
                                                            "dataset[0]kpiFilter"
                                                        }
                                                        module="kpiFilter"
                                                        data={
                                                            kpiDataFields.kpiData
                                                        }
                                                        textField="text"
                                                        onOpen={(data) => {
                                                            setKpiDataFields({
                                                                ...kpiDataFields,
                                                                kpiData: data,
                                                            });
                                                        }}
                                                        dataItemKey="id"
                                                        valueData={
                                                            kpiDataFields?.[
                                                                "dataset[0]kpiFilter"
                                                            ]
                                                        }
                                                        onChange={(
                                                            data,
                                                            name
                                                        ) => {
                                                            setKpiDataFields({
                                                                ...kpiDataFields,
                                                                [name]: data,
                                                            });
                                                            setErrorObj({
                                                                ...errorObj,
                                                                [name]: "",
                                                            });
                                                        }}
                                                        filterable={true}
                                                        onFilterChange={(
                                                            dataValue
                                                        ) => {
                                                            setKpiDataFields({
                                                                ...kpiDataFields,
                                                                kpiData:
                                                                    dataValue,
                                                            });
                                                        }}
                                                        autoClose={false}
                                                        autoBind={false}
                                                        isSelectALl={true}
                                                        selectAllId={"0"}
                                                        dataList={kpiDataList}
                                                        loading={
                                                            kpiDataFields.isKpiDataLoading
                                                        }
                                                    />
                                                </div>
                                                {isBubbleChart ? (
                                                    <div
                                                        className={
                                                            "k-form-field-wrap-popup valueDropdown"
                                                        }
                                                    >
                                                        <Label>
                                                            Result Filter X
                                                        </Label>
                                                        <MultiSelectDropdown
                                                            name={
                                                                "dataset[0]resultFilterX"
                                                            }
                                                            module="resultFilterX"
                                                            data={
                                                                resultFilterDataFields.resultDataX
                                                            }
                                                            textField="text"
                                                            onOpen={(data) => {
                                                                setResultFilterDataFields(
                                                                    {
                                                                        ...resultFilterDataFields,
                                                                        resultDataX:
                                                                            data,
                                                                    }
                                                                );
                                                            }}
                                                            dataItemKey="id"
                                                            valueData={
                                                                resultFilterDataFields?.[
                                                                    "dataset[0]resultFilterX"
                                                                ]
                                                            }
                                                            onChange={(
                                                                data,
                                                                name
                                                            ) => {
                                                                setResultFilterDataFields(
                                                                    {
                                                                        ...resultFilterDataFields,
                                                                        [name]: data,
                                                                    }
                                                                );
                                                                setErrorObj({
                                                                    ...errorObj,
                                                                    [name]: "",
                                                                });
                                                            }}
                                                            filterable={true}
                                                            onFilterChange={(
                                                                dataValue
                                                            ) => {
                                                                setResultFilterDataFields(
                                                                    {
                                                                        ...resultFilterDataFields,
                                                                        resultDataX:
                                                                            dataValue,
                                                                    }
                                                                );
                                                            }}
                                                            autoClose={false}
                                                            autoBind={true}
                                                            isSelectALl={true}
                                                            selectAllId={"0"}
                                                            dataList={
                                                                resultFilterDataList
                                                            }
                                                            loading={
                                                                resultFilterDataFields.isResultDataLoading
                                                            }
                                                        />
                                                    </div>
                                                ) : null}
                                                <div
                                                    className={
                                                        "k-form-field-wrap-popup valueDropdown"
                                                    }
                                                >
                                                    <Label>
                                                        {isBubbleChart
                                                            ? "Result Filter Y"
                                                            : "Result Filter"}
                                                    </Label>
                                                    <MultiSelectDropdown
                                                        name={
                                                            "dataset[0]resultFilterY"
                                                        }
                                                        module="resultFilterY"
                                                        data={
                                                            resultFilterDataFields.resultDataY
                                                        }
                                                        textField="text"
                                                        onOpen={(data) => {
                                                            setResultFilterDataFields(
                                                                {
                                                                    ...resultFilterDataFields,
                                                                    resultDataY:
                                                                        data,
                                                                }
                                                            );
                                                        }}
                                                        dataItemKey="id"
                                                        valueData={
                                                            resultFilterDataFields?.[
                                                                "dataset[0]resultFilterY"
                                                            ]
                                                        }
                                                        onChange={(
                                                            data,
                                                            name
                                                        ) => {
                                                            setResultFilterDataFields(
                                                                {
                                                                    ...resultFilterDataFields,
                                                                    [name]: data,
                                                                }
                                                            );
                                                            setErrorObj({
                                                                ...errorObj,
                                                                [name]: "",
                                                            });
                                                        }}
                                                        filterable={true}
                                                        onFilterChange={(
                                                            dataValue
                                                        ) => {
                                                            setResultFilterDataFields(
                                                                {
                                                                    ...resultFilterDataFields,
                                                                    resultDataY:
                                                                        dataValue,
                                                                }
                                                            );
                                                        }}
                                                        autoClose={false}
                                                        autoBind={true}
                                                        isSelectALl={true}
                                                        selectAllId={"0"}
                                                        dataList={
                                                            resultFilterDataList
                                                        }
                                                        loading={
                                                            resultFilterDataFields.isResultDataLoading
                                                        }
                                                    />
                                                </div>
                                                {isBubbleChart ? (
                                                    <div
                                                        className={
                                                            "k-form-field-wrap-popup valueDropdown"
                                                        }
                                                    >
                                                        <Label>
                                                            Result Filter Z
                                                        </Label>
                                                        <MultiSelectDropdown
                                                            name={
                                                                "dataset[0]resultFilterZ"
                                                            }
                                                            module="resultFilterZ"
                                                            data={
                                                                resultFilterDataFields.resultDataZ
                                                            }
                                                            textField="text"
                                                            onOpen={(data) => {
                                                                setResultFilterDataFields(
                                                                    {
                                                                        ...resultFilterDataFields,
                                                                        resultDataZ:
                                                                            data,
                                                                    }
                                                                );
                                                            }}
                                                            dataItemKey="id"
                                                            valueData={
                                                                resultFilterDataFields?.[
                                                                    "dataset[0]resultFilterZ"
                                                                ]
                                                            }
                                                            onChange={(
                                                                data,
                                                                name
                                                            ) => {
                                                                setResultFilterDataFields(
                                                                    {
                                                                        ...resultFilterDataFields,
                                                                        [name]: data,
                                                                    }
                                                                );
                                                                setErrorObj({
                                                                    ...errorObj,
                                                                    [name]: "",
                                                                });
                                                            }}
                                                            filterable={true}
                                                            onFilterChange={(
                                                                dataValue
                                                            ) => {
                                                                setResultFilterDataFields(
                                                                    {
                                                                        ...resultFilterDataFields,
                                                                        resultDataZ:
                                                                            dataValue,
                                                                    }
                                                                );
                                                            }}
                                                            autoClose={false}
                                                            autoBind={true}
                                                            isSelectALl={true}
                                                            selectAllId={"0"}
                                                            dataList={
                                                                resultFilterDataList
                                                            }
                                                            loading={
                                                                resultFilterDataFields.isResultDataLoading
                                                            }
                                                        />
                                                    </div>
                                                ) : null}
                                            </StackLayout>
                                        </StackLayout>
                                    </TabStripTab>

                                    <TabStripTab
                                        title={"Dataset 2"}
                                        disabled={isPieChart || isBubbleChart}
                                    >
                                        <StackLayout
                                            orientation="vertical"
                                            gap={15}
                                        >
                                            <StackLayout gap={15}>
                                                <div
                                                    className={
                                                        "k-form-field-wrap-popup"
                                                    }
                                                >
                                                    <Label>{"Data Name"}</Label>
                                                    <Input
                                                        type={"text"}
                                                        name="dataset[1]dataName"
                                                        value={
                                                            formDataset2Fields?.[
                                                                "dataset[1]dataName"
                                                            ]
                                                        }
                                                        onChange={(e) => {
                                                            handleDatasetFieldChange(
                                                                e
                                                            );
                                                        }}
                                                        disabled={
                                                            isDisableDataName
                                                        }
                                                    />
                                                    <ShowError
                                                        name={
                                                            "dataset[1]dataName"
                                                        }
                                                    />
                                                </div>

                                                <div
                                                    className={
                                                        "k-form-field-wrap-popup"
                                                    }
                                                >
                                                    <Label>
                                                        {"Aggregation Type"}
                                                    </Label>
                                                    <CustomDropdown
                                                        name={
                                                            "dataset[1]aggregationType"
                                                        }
                                                        data={aggregationType}
                                                        textField="text"
                                                        dataItemKey="value"
                                                        valueData={aggregationType.find(
                                                            (c) =>
                                                                c.text ===
                                                                formDataset2Fields?.[
                                                                    "dataset[1]aggregationType"
                                                                ]?.text
                                                        )}
                                                        onChange={(e, name) => {
                                                            handleDatasetDropDownChange(
                                                                e,
                                                                name
                                                            );
                                                        }}
                                                        filterable={false}
                                                        onOpen={(dataV) => {
                                                            setAggregationType(
                                                                dataV
                                                            );
                                                        }}
                                                        autoClose={false}
                                                        autoBind={true}
                                                        dataList={
                                                            aggregationType
                                                        }
                                                    />
                                                </div>
                                            </StackLayout>
                                            <StackLayout gap={15}>
                                                <div
                                                    className={
                                                        "k-form-field-wrap-popup"
                                                    }
                                                >
                                                    <Label>
                                                        {"Chart Type"}
                                                    </Label>
                                                    <CustomDropdown
                                                        name={
                                                            "dataset[1]chartType"
                                                        }
                                                        data={podDatasetDropdownOptions.chartTypeOptions.slice(
                                                            0,
                                                            3
                                                        )}
                                                        textField="text"
                                                        dataItemKey="value"
                                                        valueData={podDatasetDropdownOptions.chartTypeOptions
                                                            .slice(0, 3)
                                                            .find(
                                                                (c) =>
                                                                    c.text ===
                                                                    formDataset2Fields?.[
                                                                        "dataset[1]chartType"
                                                                    ]?.text
                                                            )}
                                                        onChange={(e, name) => {
                                                            handleDatasetDropDownChange(
                                                                e,
                                                                name
                                                            );
                                                        }}
                                                        filterable={false}
                                                        autoClose={false}
                                                        autoBind={true}
                                                        dataList={podDatasetDropdownOptions.chartTypeOptions.slice(
                                                            0,
                                                            3
                                                        )}
                                                    />
                                                </div>

                                                <div
                                                    className={
                                                        "k-form-field-wrap-popup"
                                                    }
                                                >
                                                    <Label>
                                                        {"Group Dimension"}
                                                    </Label>
                                                    <CustomDropdown
                                                        name={
                                                            "dataset[1]groupDimension"
                                                        }
                                                        data={groupDimension}
                                                        textField="text"
                                                        dataItemKey="value"
                                                        valueData={groupDimension.find(
                                                            (c) =>
                                                                c.text ===
                                                                formDataset2Fields?.[
                                                                    "dataset[1]groupDimension"
                                                                ]?.text
                                                        )}
                                                        onChange={(e, name) => {
                                                            handleDatasetDropDownChange(
                                                                e,
                                                                name
                                                            );
                                                        }}
                                                        filterable={false}
                                                        onOpen={(dataV) => {
                                                            setGroupDimension(
                                                                dataV
                                                            );
                                                        }}
                                                        autoClose={false}
                                                        autoBind={true}
                                                        dataList={
                                                            groupDimension
                                                        }
                                                    />
                                                </div>
                                            </StackLayout>
                                            <StackLayout gap={15}>
                                                <div
                                                    className={
                                                        "k-form-field-wrap checkbox"
                                                    }
                                                >
                                                    <Checkbox
                                                        name={
                                                            "dataset[1]isStacked"
                                                        }
                                                        label={"Stacked"}
                                                        checked={
                                                            isStackedDisabled
                                                                ? false
                                                                : formDataset2Fields?.[
                                                                      "dataset[1]isStacked"
                                                                  ]?.value
                                                        }
                                                        onChange={(e) => {
                                                            handleDatasetDropDownChange(
                                                                {
                                                                    value: e.value,
                                                                },
                                                                "dataset[1]isStacked"
                                                            );
                                                        }}
                                                        disabled={
                                                            isStackedDisabled
                                                        }
                                                    />
                                                    <Hint>
                                                        Please select if it is
                                                        stacked
                                                    </Hint>
                                                </div>

                                                <div
                                                    className={
                                                        "k-form-field-wrap checkbox"
                                                    }
                                                >
                                                    <Checkbox
                                                        name={
                                                            "dataset[1]groupByResult"
                                                        }
                                                        label={
                                                            "Group By Result"
                                                        }
                                                        checked={
                                                            isPieChart ||
                                                            isBubbleChart
                                                                ? false
                                                                : formDataset2Fields?.[
                                                                      "dataset[1]groupByResult"
                                                                  ]?.value
                                                        }
                                                        onChange={(e) => {
                                                            handleDatasetDropDownChange(
                                                                {
                                                                    value: e.value,
                                                                },
                                                                "dataset[1]groupByResult"
                                                            );
                                                        }}
                                                        disabled={
                                                            isPieChart ||
                                                            isBubbleChart
                                                        }
                                                    />
                                                    <Hint>
                                                        Please select if it can
                                                        be Group By Result
                                                    </Hint>
                                                </div>
                                            </StackLayout>
                                            <StackLayout
                                                orientation="vertical"
                                                gap={15}
                                            >
                                                <div
                                                    className={
                                                        "k-form-field-wrap-popup valueDropdown"
                                                    }
                                                >
                                                    <Label>
                                                        {"Comp Plan Filter"}
                                                    </Label>
                                                    <MultiSelectDropdown
                                                        name={
                                                            "dataset[1]compPlanFilter"
                                                        }
                                                        module="compPlanFilter"
                                                        data={
                                                            compPlanData2Fields.compPlanData
                                                        }
                                                        textField="text"
                                                        onOpen={(data) => {
                                                            setCompPlanData2Fields(
                                                                {
                                                                    ...compPlanData2Fields,
                                                                    compPlanData:
                                                                        data,
                                                                }
                                                            );
                                                        }}
                                                        dataItemKey="id"
                                                        valueData={
                                                            compPlanData2Fields?.[
                                                                "dataset[1]compPlanFilter"
                                                            ]
                                                        }
                                                        onChange={(
                                                            data,
                                                            name
                                                        ) => {
                                                            setCompPlanData2Fields(
                                                                {
                                                                    ...compPlanData2Fields,
                                                                    [name]: data,
                                                                }
                                                            );
                                                            setErrorObj({
                                                                ...errorObj,
                                                                [name]: "",
                                                            });
                                                        }}
                                                        filterable={true}
                                                        onFilterChange={(
                                                            dataValue
                                                        ) => {
                                                            setCompPlanData2Fields(
                                                                {
                                                                    ...compPlanData2Fields,
                                                                    compPlanData:
                                                                        dataValue,
                                                                }
                                                            );
                                                        }}
                                                        autoClose={false}
                                                        autoBind={false}
                                                        isSelectALl={true}
                                                        selectAllId={"0"}
                                                        dataList={
                                                            compPlanData2List
                                                        }
                                                        loading={
                                                            compPlanData2Fields.isCompPlanDataLoading
                                                        }
                                                    />
                                                </div>
                                                <div
                                                    className={
                                                        "k-form-field-wrap-popup valueDropdown"
                                                    }
                                                >
                                                    <Label>
                                                        {"KPI Filter"}
                                                    </Label>
                                                    <MultiSelectDropdown
                                                        name={
                                                            "dataset[1]kpiFilter"
                                                        }
                                                        module="kpiFilter"
                                                        data={
                                                            kpiData2Fields.kpiData
                                                        }
                                                        textField="text"
                                                        onOpen={(data) => {
                                                            setKpiData2Fields({
                                                                ...kpiData2Fields,
                                                                kpiData: data,
                                                            });
                                                        }}
                                                        dataItemKey="id"
                                                        valueData={
                                                            kpiData2Fields?.[
                                                                "dataset[1]kpiFilter"
                                                            ]
                                                        }
                                                        onChange={(
                                                            data,
                                                            name
                                                        ) => {
                                                            setKpiData2Fields({
                                                                ...kpiData2Fields,
                                                                [name]: data,
                                                            });
                                                            setErrorObj({
                                                                ...errorObj,
                                                                [name]: "",
                                                            });
                                                        }}
                                                        filterable={true}
                                                        onFilterChange={(
                                                            dataValue
                                                        ) => {
                                                            setKpiData2Fields({
                                                                ...kpiData2Fields,
                                                                kpiData:
                                                                    dataValue,
                                                            });
                                                        }}
                                                        autoClose={false}
                                                        autoBind={false}
                                                        isSelectALl={true}
                                                        selectAllId={"0"}
                                                        dataList={kpiData2List}
                                                        loading={
                                                            kpiData2Fields.isKpiDataLoading
                                                        }
                                                    />
                                                </div>
                                                <div
                                                    className={
                                                        "k-form-field-wrap-popup valueDropdown"
                                                    }
                                                >
                                                    <Label>
                                                        {"Result Filter"}
                                                    </Label>
                                                    <MultiSelectDropdown
                                                        name={
                                                            "dataset[1]resultFilterY"
                                                        }
                                                        module="resultFilterY"
                                                        data={
                                                            resultFilterData2Fields.resultDataY
                                                        }
                                                        textField="text"
                                                        onOpen={(data) => {
                                                            setResultFilterData2Fields(
                                                                {
                                                                    ...resultFilterData2Fields,
                                                                    resultDataY:
                                                                        data,
                                                                }
                                                            );
                                                        }}
                                                        dataItemKey="id"
                                                        valueData={
                                                            resultFilterData2Fields?.[
                                                                "dataset[1]resultFilterY"
                                                            ]
                                                        }
                                                        onChange={(
                                                            data,
                                                            name
                                                        ) => {
                                                            setResultFilterData2Fields(
                                                                {
                                                                    ...resultFilterData2Fields,
                                                                    [name]: data,
                                                                }
                                                            );
                                                            setErrorObj({
                                                                ...errorObj,
                                                                [name]: "",
                                                            });
                                                        }}
                                                        filterable={true}
                                                        onFilterChange={(
                                                            dataValue
                                                        ) => {
                                                            setResultFilterData2Fields(
                                                                {
                                                                    ...resultFilterData2Fields,
                                                                    resultDataY:
                                                                        dataValue,
                                                                }
                                                            );
                                                        }}
                                                        autoClose={false}
                                                        autoBind={true}
                                                        isSelectALl={true}
                                                        selectAllId={"0"}
                                                        dataList={
                                                            resultFilterData2List
                                                        }
                                                        loading={
                                                            resultFilterData2Fields.isResultDataLoading
                                                        }
                                                    />
                                                </div>
                                            </StackLayout>
                                            <div className="clear-dataset">
                                                <a
                                                    className="cta-small"
                                                    onClick={handleClearDataset}
                                                >
                                                    <span
                                                        style={{
                                                            color: "#00a1e4",
                                                        }}
                                                    >
                                                        Clear Dataset
                                                    </span>
                                                    <i className="fas fa-broom"></i>
                                                </a>
                                            </div>
                                        </StackLayout>
                                    </TabStripTab>
                                </TabStrip>
                            </FormElement>
                        </StackLayout>
                    </div>
                    <div className="form-action sticky-footer">
                        <WindowActionsBar layout="end">
                            <Button
                                type="submit"
                                className="cancelbtn"
                                onClick={closeDialog}
                                disabled={dashboardDataSet?.isLoading}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                onClick={() => handleSubmitCall()}
                                className="primarybtn"
                                disabled={isLoading}
                            >
                                Save
                            </Button>
                        </WindowActionsBar>
                    </div>
                </StackLayout>
            )}
        />
    );

    const handleStageChange = (e) => {
        setStateChange(e.state);
        if (e.state === "MINIMIZED") {
            setModal(false);
        } else if (e.state === "DEFAULT") {
            setModal(true);
        }
    };

    return (
        <>
            {dashboardDataSet?.isLoading ? (
                <LoadingPanel />
            ) : (
                <>
                    {initialHeight !== undefined &&
                        initialWidth !== undefined && (
                            <Window
                                title={`${
                                    isEditForm ? "Edit" : "Add"
                                } Dashboard Pod`}
                                initialHeight={initialHeight}
                                initialWidth={initialWidth}
                                onClose={closeDialog}
                                resizable={false}
                                className="dashboard-data-set-wrapper"
                                stage={stageChange}
                                onStageChange={handleStageChange}
                                modal={modal}
                            >
                                {Action.onSubmit?.isLoading ? (
                                    <LoadingPanel />
                                ) : (
                                    <div className="dashboard-data-set-container">
                                        {renderForm()}
                                    </div>
                                )}
                            </Window>
                        )}
                </>
            )}
        </>
    );
};

DashboardPodDialog.propTypes = {
    closeDialog: PropTypes.func.isRequired,
};
