import React from "react";
import { Button } from "@progress/kendo-react-buttons";
import { HeaderPage } from "../base/headerPage";
import userPositionApi from "./service";
import { getUserCredential } from "src/lib/cookies";
import { GetPositionType, GetSPECIALTYAPI, GetUnitType } from "src/lib/common-service";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { LoadingPanel } from "src/components/loadingPanel";
import { changeCancelBtnState, changeUpdateBtnState, formatDateOnly, isNullOrEmptyUndefined, parseEffDateString, toCamelCase } from "src/utils/utils";
import {
    ListBox,
} from "@progress/kendo-react-listbox";
import ProviderFields from "./providerFields";
import EffectiveVersions from "./effVersionList";
import { setNewMessage } from "src/redux/actions/setting-actions";

const SELECTED_FIELD = "selected";

export const EditFormFieldsContext = React.createContext([
    null,
    () => {
        /* noop */
    },
]);

export const ETLFieldsContext = React.createContext([
    null,
    () => {
        /* noop */
    },
]);



export const EditErrorContext = React.createContext([
    {},
    () => {
        /* noop */
    },
]);

export const EditDataListContext = React.createContext([
    [],
    () => {
        /* noop */
    },
]);

export const EditSpecialtyContext = React.createContext([
    [],
    () => {
        /* noop */
    },
]);

export const DragContext = React.createContext([
    null,
    () => {
        /* noop */
    },
]);
export const DropContext = React.createContext([
    null,
    () => {
        /* noop */
    },
]);

export const EffStartDateContext = React.createContext([
    null,
    () => {
        /* noop */
    },
]);

export const StandAloneContext = React.createContext([
    null,
    () => {
        /* noop */
    },
]);

export const CancelClickContext = React.createContext([
    null,
    () => {
        /* noop */
    },
]);

export const AccessContext = React.createContext([
    null,
    () => {
        /* noop */
    },
]);

const UpdateProviderPosition = (restProps) => {
    const [list, Action] = userPositionApi();
    const [categoryList, setCategoryList] = React.useState([])
    const [categoryFieldList, setCategoryFieldList] = React.useState([])
    const [originalCategoryFieldList, setOriginalCategoryFieldList] = React.useState([])
    const [originalCategoryList, setOriginalCategoryList] = React.useState([])
    const [isUpdateEffDate, setUpdateEffDate] = React.useState(false)
    const cookiesData = getUserCredential();
    const dispatch = useDispatch();
    const history = useHistory();
    const [state, setState] = React.useState({
        draggedItem: {},
    });
    const [dataList, setDataList] = React.useState({
        unitTypeDataList: [],
        positionTypeList: [],
        specialtyList: []
    })
    const [loadingState, setLoadingState] = React.useState({
        initialLoad: false
    })
    const [formFields, setFormFields] = React.useState('')
    const [errorObj, setErrorObj] = React.useState({})
    const [isSpecialtySave, setSpecialtySave] = React.useState(false)
    const [drag, setDrag] = React.useState(null);
    const [drop, setDrop] = React.useState(null);
    const dropTimeout = React.useRef();
    const [editFormFields, setEditFormFields] = React.useState()
    const [effVersionState, setEffVersionState] = React.useState({
        changeVersion: { rowId: -1, versionIdx: -1 },
        insertVersion: { rowId: -1, periodKey: 0 },
        deleteVersion: { rowId: -1 },
    });
    const [parentDataItem, setParentDataItem] = React.useState({})
    const [effVersionLoadingState, setEffVersionLoadingState] = React.useState(false)
    const [selectedVersion, setSelectedVersion] = React.useState(0)
    const [currentVersion, setCurrentVersion] = React.useState(restProps.editData?.data?.date_version)
    const [isUpdateData, setIsUpdateData] = React.useState(false)
    const [isUpdateClick, setIsUpdateClick] = React.useState(false)
    const [standAlone, setStandAlone] = React.useState({
        'provider': false,
        'position': false
    })
    const [isCancelClick, setCancelClick] = React.useState(false)
    const {
        documentData,
        disabledFields
    } = list

    const handleDropChange = React.useCallback((newDrop) => {
        if (!newDrop) {
            dropTimeout.current = window.setTimeout(() => {
                setDrop(newDrop);
            }, 50);
        } else {
            clearTimeout(dropTimeout.current);
            setDrop(newDrop);
        }
    }, []);

    React.useEffect(() => {
        if (editFormFields && documentData.mstr_key === '' && selectedVersion > 0 &&
            Object.keys(editFormFields).length > 0 && standAlone.provider === false &&
            editFormFields?.org_position_key
        ) {
            getDocumentDataList()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentData, selectedVersion, editFormFields])

    const getDocumentDataList = async () => {
        let obj = {
            mstr_key: editFormFields?.org_position_key,
            date_version: editFormFields?.date_version
        }
        await Action.getDocumentData(obj)
    }

    React.useEffect(() => {
        changeUpdateBtnState()
        changeCancelBtnState()
        getEffVersionsApi()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        if (editFormFields && Object.keys(editFormFields).length > 0 && categoryFieldList.length === 0) {
            getCategoryList();
            getUnitTypeData()
            getPositionTypeList()
            getSpecialtyList()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editFormFields])

    //change eff version
    React.useEffect(() => {
        if (effVersionState.changeVersion.versionIdx > -1) {
            getEffChangeVersion()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [effVersionState.changeVersion]);

    const getEffChangeVersion = async () => {
        setUpdateEffDate(false)
        setErrorObj({})
        setFormFields({})
        setEditFormFields({})
        setEffVersionLoadingState(true);
        const versionState = effVersionState.changeVersion;
        let thisEffVersionDeta = parentDataItem.data;

        thisEffVersionDeta
            .filter((x) => x.isSelected === 1)
            .map((item) => {
                item.isSelected = 0;
                return 0;
            });

        const newSelectedVersion = thisEffVersionDeta[versionState.versionIdx];
        setSelectedVersion(newSelectedVersion.date_version)
        newSelectedVersion.isSelected = 1;
        const reqData = {
            in_tenant_id: cookiesData.out_tenant_id,
            in_date_version: newSelectedVersion.date_version,
            in_provider_mstr_key: restProps.editData.data.provider_mstr_key,
            in_acct_encrypt_key: cookiesData.out_acct_encrypt_key,
            in_org_position_key: restProps.editData.data.org_position_key
        };
        changeUpdateBtnState("disable", "grid-incell-update");
        changeCancelBtnState("disable", "grid-incell-cancel");
        if (categoryFieldList.length > 0 && categoryList.length > 0) {
            let filterData = [...categoryList]
            if (categoryList.filter((x) => x.category_code === 'doc_attr').length === 0 &&
                newSelectedVersion.date_version === currentVersion
            ) {
                if (filterData.filter((x) => x.category_code === 'doc_attr').length === 0 && standAlone.provider === false
                    && restProps.pageProps.controlAccess === 1
                ) {
                    let obj = {
                        "category_code": "doc_attr",
                        "filter_column_datatype": "doc",
                        "filter_label": "Manage Documents",
                        "category_label": "Documents",
                        fields: [
                            {
                                "category_code": "doc_attr",
                                "filter_column_datatype": "doc",
                                "filter_label": "Manage Documents",
                                "documentData": documentData
                            }
                        ]
                    }
                    filterData.push(obj)
                    setCategoryList(filterData)
                    setOriginalCategoryList(filterData)
                }
            } else {
                let index = filterData.findIndex((x) => x.category_code === "doc_attr")
                if (index > -1) {
                    filterData.splice(index, 1);
                    setCategoryList(filterData)
                    setOriginalCategoryList(filterData)
                }
            }
        }
        if (standAlone.provider || reqData.in_org_position_key === '') {
            let providerReq = {
                in_tenant_id: cookiesData.out_tenant_id,
                in_date_version: newSelectedVersion.date_version,
                in_provider_mstr_key: restProps.editData.data.provider_mstr_key,
                in_acct_encrypt_key: cookiesData.out_acct_encrypt_key,
                in_org_position_key: 0
            }
            let providerResData = await Action.getProviderVersionsData(providerReq);
            if (providerResData.length) {
                setEffVersionLoadingState(false);
                setEditFormFields(providerResData[0])
                setFormData(providerResData)
            }
        } else {
            const resData = await Action.getProviderPositionVersionsData(reqData);
            if (resData.length) {
                setEffVersionLoadingState(false);
                setEditFormFields(resData[0])
                setFormData(resData)
            }
            let obj = {
                mstr_key: editFormFields?.org_position_key,
                date_version: newSelectedVersion.date_version
            }
            await Action.getDocumentData(obj)
        }
    }

    React.useEffect(() => {
        if (isSpecialtySave) {
            setSpecialtySave(false)
            getSpecialtyList()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSpecialtySave])

    React.useEffect(() => {
        const insertVersion = effVersionState.insertVersion;
        if (insertVersion.eff_start_date) {
            getInsertEffVersion(insertVersion)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [effVersionState.insertVersion]);

    const getInsertEffVersion = async (insertVersion) => {
        setIsUpdateData(true)
        setEffVersionLoadingState(true);
        setEffVersionState({
            ...effVersionState,
            insertVersion: { rowId: -1, periodKey: 0 },
        })
        let reqData = {
            in_user_id: cookiesData.out_user_id,
            in_tenant_id: cookiesData.out_tenant_id,
            in_provider_mstr_key: restProps.editData.data.provider_mstr_key,
            in_date_version: standAlone.provider ? selectedVersion : editFormFields.date_version,
            in_eff_start_date: formatDateOnly(insertVersion.eff_start_date)

        }
        let res = await Action.insertProviderVersions(reqData, dispatch, history)


        let positionReqData = {
            in_user_id: cookiesData.out_user_id,
            in_tenant_id: cookiesData.out_tenant_id,
            in_org_position_key: editFormFields.org_position_key,
            in_date_version: editFormFields.date_version,
            in_eff_start_date: formatDateOnly(insertVersion.eff_start_date)
        }

        if (standAlone.provider) {
            if (res) {
                if (res) {
                    await getEffVersionsApi('add-new-version')
                    setEffVersionLoadingState(false)
                } else {
                    setEffVersionState({
                        ...effVersionState,
                        insertVersion: { rowId: -1, periodKey: 0 },
                    })
                    setEffVersionLoadingState(false);
                }
            } else {
                setEffVersionState({
                    ...effVersionState,
                    insertVersion: { rowId: -1, periodKey: 0 },
                })
                setEffVersionLoadingState(false);
            }
        } else {

            if (res) {
                let positionRes = await Action.insertPositionVersions(positionReqData, dispatch, history)
                if (res && positionRes) {
                    await getEffVersionsApi('add-new-version')
                    setEffVersionLoadingState(false)
                } else {
                    setEffVersionState({
                        ...effVersionState,
                        insertVersion: { rowId: -1, periodKey: 0 },
                    })
                    setEffVersionLoadingState(false);
                }
            } else {
                setEffVersionState({
                    ...effVersionState,
                    insertVersion: { rowId: -1, periodKey: 0 },
                })
                setEffVersionLoadingState(false);
            }
        }

    }

    /**
 * call delete eff version API
 */
    React.useEffect(() => {
        const deleteVersion = effVersionState.deleteVersion;
        if (deleteVersion.dateVersion) {
            getDeleteEffVersion(deleteVersion)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [effVersionState.deleteVersion]);

    const getDeleteEffVersion = async (deleteVersion) => {
        setErrorObj({})
        setEffVersionLoadingState(true);
        const providerBodyData = {
            in_user_id: cookiesData.out_user_id,
            in_tenant_id: cookiesData.out_tenant_id,
            in_provider_mstr_key: [{
                id: editFormFields.provider_mstr_key,
                date_version: deleteVersion.dateVersion
            }]
        };
        const positionBodyData = {
            in_user_id: cookiesData.out_user_id,
            in_tenant_id: cookiesData.out_tenant_id,
            in_org_position_key: [{
                id: editFormFields.org_position_key,
                date_version: deleteVersion.dateVersion
            }]
        };
        if (standAlone.provider) {
            let resData = await Action.deleteProvider(providerBodyData, dispatch, history)
            if (resData) {
                setIsUpdateData(true)
                await getEffVersionsApi('delete-version')
                setEffVersionLoadingState(false);
            } else {
                setEffVersionLoadingState(false);
            }
        } else {
            let resData = await Action.deletePosition(positionBodyData, dispatch, history)
            if (resData) {
                setIsUpdateData(true)
                await getEffVersionsApi('delete-version')
                setEffVersionLoadingState(false);
            } else {
                setEffVersionLoadingState(false);
            }
        }
    }

    const getEffVersionsApi = async (module) => {
        const versionApiCallData = {
            in_tenant_id: cookiesData.out_tenant_id,
            in_provider_mstr_key: restProps.editData.data.provider_mstr_key,
        };
        let effVersionResponse = {}
        if(restProps.editData.data.org_position_key === 0){
             effVersionResponse = await Action.getProviderVersions(versionApiCallData);
        }else{
            const positionVersionApiCallData = {
                in_tenant_id: cookiesData.out_tenant_id,
                in_org_position_key: restProps.editData.data.org_position_key,
            };
             effVersionResponse = await Action.getPositionVersions(positionVersionApiCallData);
        }

        if (effVersionResponse.length > 0) {
            let selectedIndex = effVersionResponse.findIndex((x) => x.isCurrent === 1);
            let res = {}
            res["data"] = effVersionResponse
            if (effVersionResponse.length > 0) {
                if (module === 'updateEffDate' || module === 'updateOtherFields') {
                    if (categoryFieldList.length > 0 && categoryList.length > 0) {
                        let filterData = [...categoryList]
                        if (filterData.filter((x) => x.category_code === 'doc_attr').length === 0 && standAlone.provider === false
                            && restProps.pageProps.controlAccess === 1
                        ) {
                            let obj = {
                                "category_code": "doc_attr",
                                "filter_column_datatype": "doc",
                                "filter_label": "Manage Documents",
                                "category_label": "Documents",
                                fields: [
                                    {
                                        "category_code": "doc_attr",
                                        "filter_column_datatype": "doc",
                                        "filter_label": "Manage Documents",
                                        "documentData": documentData
                                    }
                                ]
                            }
                            filterData.push(obj)
                            setCategoryList(filterData)
                            setOriginalCategoryList(filterData)
                        }
                    }
                } else if (module === 'delete-version') {
                    let filterData = [...categoryList]
                    if (filterData.filter((x) => x.category_code === 'doc_attr').length === 0 && standAlone.provider === false
                        && restProps.pageProps.controlAccess === 1
                    ) {
                        let obj = {
                            "category_code": "doc_attr",
                            "filter_column_datatype": "doc",
                            "filter_label": "Manage Documents",
                            "category_label": "Documents",
                            fields: [
                                {
                                    "category_code": "doc_attr",
                                    "filter_column_datatype": "doc",
                                    "filter_label": "Manage Documents",
                                    "documentData": documentData
                                }
                            ]
                        }
                        filterData.push(obj)
                        setCategoryList(filterData)
                        setOriginalCategoryList(filterData)
                    }
                }
                if (res.data.length === 1) {
                    res.data[0].isSelected = 1
                    setCurrentVersion(res.data[0].date_version)
                    setSelectedVersion(res.data[0].date_version)
                    if (module === 'add-new-version') {
                        let providerCustomFieldData = await getCustomFields('vw_provider_master', 'provider_mstr_key')
                        providerCustomFieldData.forEach((item) => {
                            item.in_date_version = res.data[0].date_version
                        })
                        let pReqData = {
                            in_custom_field_data_key: providerCustomFieldData
                        }
                        if (providerCustomFieldData.filter((x) => x.in_data_value !== '').length > 0) {
                            await Action.insertCustomFields(pReqData)
                        }
                        if (standAlone.provider === false) {
                            let positionCustomFieldData = await getCustomFields('vw_org_position', 'org_position_key')
                            positionCustomFieldData.forEach((item) => {
                                item.in_date_version = res.data[0].date_version
                            })
                            let oReqData = {
                                in_custom_field_data_key: positionCustomFieldData.filter((x) => x.in_data_value !== '')
                            }

                            if (positionCustomFieldData.filter((x) => x.in_data_value !== '').length > 0) {
                                await Action.insertCustomFields(oReqData)
                            }
                        }
                        await getProviderProsByEffVersion(res.data[0].date_version)
                    } else {
                        await getProviderProsByEffVersion(res.data[0].date_version)
                    }
                } else {
                    res.data[selectedIndex].isSelected = 1
                    setCurrentVersion(res.data[selectedIndex].date_version)
                    setSelectedVersion(res.data[selectedIndex].date_version)
                    if (module === 'add-new-version') {
                        let providerCustomFieldData = await getCustomFields('vw_provider_master', 'provider_mstr_key')
                        providerCustomFieldData.forEach((item) => {
                            item.in_date_version = res.data[selectedIndex].date_version
                            item.in_custom_fld_data_date = parseEffDateString(res.data[selectedIndex].eff_start_date)
                        })
                        let pReqData = {
                            in_custom_field_data_key: providerCustomFieldData.filter((x) => x.in_data_value !== '')
                        }
                        if (providerCustomFieldData.filter((x) => x.in_data_value !== '').length > 0) {
                            await Action.insertCustomFields(pReqData)
                        }
                        if (standAlone.provider === false) {
                            let positionCustomFieldData = await getCustomFields('vw_org_position', 'org_position_key')

                            positionCustomFieldData.forEach((item) => {
                                item.in_date_version = res.data[selectedIndex].date_version
                                item.in_custom_fld_data_date = parseEffDateString(res.data[selectedIndex].eff_start_date)
                            })
                            let oReqData = {
                                in_custom_field_data_key: positionCustomFieldData.filter((x) => x.in_data_value !== '')
                            }
                            if (positionCustomFieldData.filter((x) => x.in_data_value !== '').length > 0) {
                                await Action.insertCustomFields(oReqData)
                            }
                        }
                        await getProviderProsByEffVersion(res.data[selectedIndex].date_version)
                    } else {
                        await getProviderProsByEffVersion(res.data[selectedIndex].date_version)
                    }

                }
                await Action.getSystemSettingETLSetting()
                setParentDataItem(res)
            }
        } else {
            restProps.setMode("Default")
            setIsUpdateData(false)
            restProps.setIsOneEffVersion(true)
        }
        return effVersionResponse;
    };

    const getProviderProsByEffVersion = async (newSelectedVersion) => {
        const reqData = {
            in_tenant_id: cookiesData.out_tenant_id,
            in_date_version: newSelectedVersion,
            in_provider_mstr_key: restProps.editData.data.provider_mstr_key,
            in_acct_encrypt_key: cookiesData.out_acct_encrypt_key,
            in_org_position_key: restProps.editData.data.org_position_key
        };
        if (standAlone.provider || reqData.in_org_position_key === '') {
            let providerReq = {
                in_tenant_id: cookiesData.out_tenant_id,
                in_date_version: newSelectedVersion,
                in_provider_mstr_key: restProps.editData.data.provider_mstr_key,
                in_acct_encrypt_key: cookiesData.out_acct_encrypt_key,
                in_org_position_key: 0
            }
            if (reqData.in_org_position_key === '') {
                setStandAlone({
                    ...standAlone,
                    provider: true
                })
            }
            let providerResData = await Action.getProviderVersionsData(providerReq);
            if (providerResData.length) {
                setEffVersionLoadingState(false);
                setEditFormFields(providerResData[0])
                setFormData(providerResData)
            }
        } else {
            const resData = await Action.getProviderPositionVersionsData(reqData);
            if (resData.length > 0) {
                if (resData[0].org_position_key === 0) {
                    let providerReq = {
                        in_tenant_id: cookiesData.out_tenant_id,
                        in_date_version: newSelectedVersion,
                        in_provider_mstr_key: restProps.editData.data.provider_mstr_key,
                        in_acct_encrypt_key: cookiesData.out_acct_encrypt_key,
                        in_org_position_key: 0
                    }
                    let providerResData = await Action.getProviderVersionsData(providerReq);
                    if (providerResData.length) {
                        setEffVersionLoadingState(false);
                        setEditFormFields(providerResData[0])
                        setFormData(providerResData)
                    }
                    setStandAlone({
                        ...standAlone,
                        provider: true
                    })
                } else {
                    setEditFormFields({ ...resData[0] })
                    setFormData(resData)
                }
            }
        }
        changeCancelBtnState()
        changeUpdateBtnState()
    }

    const setFormData = (resData) => {
        let salaryObj = {
            id: resData[0].salary_unittype,
            text: resData[0].salary_unit
        }
        let utilizeObj = {
            id: resData[0].utilization_unittype,
            text: resData[0].utilization_unit
        }

        let targetUnitObj = {
            id: resData[0].total_target_incentive_unittype,
            text: resData[0].target_unit
        }

        let positionObj = {
            id: resData[0].org_position_type_key,
            text: resData[0].position_type_name
        }

        let specialtyObj = {
            id: resData[0].specialty_mstr_key,
            text: resData[0].specialty_name
        }
        setFormFields({
            ...resData[0],
            unitTypeDataList: formFields.unitTypeDataList,
            specialtyList: formFields.specialtyList,
            positionTypeList: formFields.positionTypeList,
            'vw_provider_master_salary_unit': resData[0].salary_unit === '' ? {} : salaryObj,
            'vw_org_position_utilization_unit': resData[0].utilization_unit === '' || resData[0].org_position_key === 0 ? {} : utilizeObj,
            'vw_provider_master_target_unit': resData[0].target_unit === '' ? {} : targetUnitObj,
            'vw_org_position_position_type_name': resData[0].position_type_name === '' ? resData[0].org_position_key === 0 ? {} : formFields.positionTypeList[0] : positionObj,
            'vw_org_position_specialty_name': resData[0].specialty_name === '' ? resData[0].org_position_key === 0 ? {} : formFields.specialtyList[0] : specialtyObj,
        });
    }

    const getUnitTypeData = async () => {
        let unitTypeDataReq = {
            in_tenant_id: cookiesData.out_tenant_id,
            in_filter: " AND (a.system_unit_name NOT LIKE 'fdm_%')",
            in_sort_number: null,
            in_sort_order: null,
            in_page_row_offset: 0,
            in_page_rows: 9999,
        };

        let resUnitType = await GetUnitType(unitTypeDataReq, dispatch, history);
        if (resUnitType.length > 0) {
            let tempArray = [];

            resUnitType.map((item) => {
                let obj = {};

                obj.text = item.system_unit_name;
                obj.id = item.system_unit_mstr_key;

                tempArray.push(obj);

                return 0;
            });

            setDataList((preVState) => ({
                ...preVState,
                unitTypeDataList: tempArray
            }));
            let salaryObj = {
                id: editFormFields.salary_unittype,
                text: editFormFields.salary_unit
            }
            let utilizeObj = {
                id: editFormFields.utilization_unittype,
                text: editFormFields.utilization_unit
            }

            let targetUnitObj = {
                id: editFormFields.total_target_incentive_unittype,
                text: editFormFields.target_unit
            }
            setFormFields((preVState) => ({
                ...preVState,
                ...editFormFields,
                unitTypeDataList: tempArray,
                'vw_provider_master_salary_unit': editFormFields.salary_unit === '' ? {} : salaryObj,
                'vw_org_position_utilization_unit': editFormFields.utilization_unit === '' ? {} : utilizeObj,
                'vw_provider_master_target_unit': editFormFields.target_unit === '' ? {} : targetUnitObj
            }));
        }
    };

    const getPositionTypeList = async () => {
        let resPositionType = await GetPositionType({}, dispatch, history);
        if (resPositionType.length > 0) {
            let tempArray = [];
            resPositionType.map((item) => {
                let obj = {};

                obj.text = item.position_type_name;
                obj.id = item.org_position_type_key;

                tempArray.push(obj);

                return 0;
            });

            setDataList((preVState) => ({
                ...preVState,
                positionTypeList: tempArray
            }));

            let positionObj = {
                id: editFormFields.org_position_type_key,
                text: editFormFields.position_type_name
            }

            setFormFields((preVState) => ({
                ...preVState,
                ...editFormFields,
                positionTypeList: tempArray,
                'vw_org_position_position_type_name': editFormFields.position_type_name === '' ? tempArray[0] : positionObj
            }));
        }
    }

    const getSpecialtyList = async () => {
        let dataObjValue = {
            in_user_key: cookiesData.out_user_id,
            in_tenant_id: cookiesData.out_tenant_id,
            in_filter: null,
            in_sort_number: 3,
            in_sort_order: "ASC",
            in_page_row_offset: 0,
            in_page_rows: 9999,
        };
        let resSpecialty = await GetSPECIALTYAPI(
            dataObjValue,
            history,
            dispatch
        );
        if (resSpecialty) {
            let tempArray = [];
            resSpecialty.map((item) => {
                let obj = {};

                obj.text = item.specialty_name;
                obj.id = item.specialty_mstr_key;

                tempArray.push(obj);

                return 0;
            });
            setDataList((preVState) => ({
                ...preVState,
                specialtyList: tempArray
            }));

            let specialtyObj = {
                id: editFormFields.specialty_mstr_key,
                text: editFormFields.specialty_name
            }
            setFormFields((preVState) => ({
                ...preVState,
                ...editFormFields,
                specialtyList: tempArray,
                'vw_org_position_specialty_name': editFormFields.specialty_name === '' ? tempArray[0] : specialtyObj
            }));
        }
    }

    const getCategoryList = async (module) => {
        let reqData = {
            in_filter: null,
            in_module_name: 'provider_position',
            in_sort_number: 1,
            in_sort_order: 'ASC'
        }
        let res = await Action.getCategory(reqData)
        if (res) {
            getCategoryFieldList(res.sort((a, b) => a.category_order - b.category_order), module)
        }
    }

    const getCategoryFieldList = async (mainCategory, module) => {
        let reqData = {
            in_filter: null,
            in_module_name: 'provider_position',
            in_sort_number: 1,
            in_sort_order: 'ASC',
            in_tenant_id: cookiesData.out_tenant_id
        }
        let res = await Action.getCategoryField(reqData)
        if (res) {
            setLoadingState({
                ...loadingState,
                initialLoad: true,
                isUpdateCategoryFdm: false
            })
            const updatedRes = res.map((x) => {
                if (x.filter_column_name && x.filter_column_name.includes(".")) {
                    let col_name = x.filter_column_name.split(".")[1];
                    return { ...x, filter_column_name: col_name, isEditMode: true };
                }
                return x;
            });

            setCategoryFieldList([...updatedRes]);
            setOriginalCategoryFieldList([...updatedRes]);
            let mergedArray = []
            mainCategory.map((item) => {
                let filteredData = res.filter((x) => x.category_code === item.category_code)
                item['fields'] = filteredData.sort((a, b) => a.cat_fdm_order - b.cat_fdm_order).map((x) => {
                    let value = ""
                    if (x.custom_fld_mstr_key !== null) {
                        value = x.filter_table_name + 'customFields' + x.custom_fld_mstr_key +
                            x.custom_fld_col_mstr_key + '_' + x.custom_fld_data_type.toLowerCase();
                    } else {
                        if (x.filter_column_name && x.filter_column_name.includes(".")) {
                            let col_name = x.filter_column_name.split(".")[1];
                            value = x.filter_table_name + "_" + col_name
                        } else {
                            value = x.filter_table_name + "_" + x.filter_column_name
                        }

                    }
                    if (x.filter_column_name && x.filter_column_name.includes(".")) {
                        let col_name = x.filter_column_name.split(".")[1];
                        return {
                            ...x, filter_column_name: col_name, selected: false, isEditMode: true,
                            fields: filteredData,
                            [value]: editFormFields[col_name]
                        };
                    } else {
                        return {
                            ...x, selected: false, isEditMode: true, fields: filteredData,
                            [value]: x.filter_table_name === 'vw_provider_master' && standAlone.provider && x.filter_column_name === 'eff_start_date' ? editFormFields['provider_eff_start_date'] : editFormFields[x.filter_column_name]
                        };
                    }
                });
                mergedArray.push(item);
                return 0;
            });
            let filterData = [...mergedArray]
            if (mainCategory.filter((x) => x.category_code === 'doc_attr').length > 0
                && selectedVersion === currentVersion && standAlone.provider === false &&
                editFormFields.org_position_key && restProps.pageProps.controlAccess === 1
            ) {
                filterData.filter((x) => x.category_code === "doc_attr")[0].fields.push({
                    "category_code": "doc_attr",
                    "filter_column_datatype": "doc",
                    "filter_label": "Manage Documents",
                    "documentData": documentData
                })
            }
            setCategoryList(mergedArray)
            setOriginalCategoryList(mergedArray)
            if (module !== 'drag-n-drop') {
                changeUpdateBtnState()
                changeCancelBtnState()
            }

        }
    }


    if (document.getElementById("grid-incell-cancel")) {
        document.getElementById("grid-incell-cancel").onclick = async function (e) {
            let salaryObj = {
                id: editFormFields.salary_unittype,
                text: editFormFields.salary_unit
            }
            let utilizeObj = {
                id: editFormFields.utilization_unittype,
                text: editFormFields.utilization_unit
            }

            let targetUnitObj = {
                id: editFormFields.total_target_incentive_unittype,
                text: editFormFields.target_unit
            }

            let positionObj = {
                id: editFormFields.org_position_type_key,
                text: editFormFields.position_type_name
            }

            let specialtyObj = {
                id: editFormFields.specialty_mstr_key,
                text: editFormFields.specialty_name
            }
            if (isUpdateClick) {
                setFormFields({
                    ...editFormFields,
                    unitTypeDataList: formFields.unitTypeDataList,
                    specialtyList: formFields.specialtyList,
                    positionTypeList: formFields.positionTypeList,
                    'vw_provider_master_salary_unit': editFormFields.salary_unit === '' ? {} : salaryObj,
                    'vw_org_position_utilization_unit': editFormFields.utilization_unit === '' ? {} : utilizeObj,
                    'vw_provider_master_target_unit': editFormFields.target_unit === '' ? {} : targetUnitObj,
                    'vw_org_position_position_type_name': editFormFields.position_type_name === '' ? formFields.positionTypeList[0] : positionObj,
                    'vw_org_position_specialty_name': editFormFields.specialty_name === '' ? formFields.specialtyList[0] : specialtyObj,
                })
            } else {
                setFormFields({
                    ...editFormFields,
                    unitTypeDataList: formFields.unitTypeDataList,
                    specialtyList: formFields.specialtyList,
                    positionTypeList: formFields.positionTypeList,
                    'vw_provider_master_salary_unit': editFormFields.salary_unit === '' ? {} : salaryObj,
                    'vw_org_position_utilization_unit': editFormFields.utilization_unit === '' ? {} : utilizeObj,
                    'vw_provider_master_target_unit': editFormFields.target_unit === '' ? {} : targetUnitObj,
                    'vw_org_position_position_type_name': editFormFields.position_type_name === '' ? formFields.positionTypeList[0] : positionObj,
                    'vw_org_position_specialty_name': editFormFields.specialty_name === '' ? formFields.specialtyList[0] : specialtyObj,
                })
            }

            setOriginalCategoryFieldList([])
            setErrorObj({})
            setCategoryList(originalCategoryList)
            setOriginalCategoryFieldList(originalCategoryFieldList)
            changeUpdateBtnState('disable')
            changeCancelBtnState('disable')
            setCancelClick(true)
        };
    }


    const handleBackBtn = (e) => {
        e.preventDefault()
        setStandAlone({
            ...standAlone,
            provider: false,
            position: false
        })
        restProps.setMode('Default')
        if (isUpdateData) {
            setIsUpdateData(false)
            restProps.setIsUpdate(true)
        }
    };


    if (document.getElementById("grid-incell-update")) {
        document.getElementById("grid-incell-update").onclick = function (e) {
            let isValid = handleValidation();
            if (isValid) {
                handleSubmit()
            }
        };
    }

    const handleValidation = () => {
        let isValid = true;
        let errObj = {};

        const updatedCategoryList = categoryList.map(category => {
            const updatedFields = category.fields.map(field => {
                if (standAlone.provider && field.filter_table_name === "vw_provider_master") {
                    let jsonObject = field?.dropdown_control ? JSON.parse(field.dropdown_control) : {};
                    const isRequired = (field.is_nullable === 0 && field.filter_column_datatype !== 'boolean') || (jsonObject.required === "true");
                    const fieldValue = field.filter_column_name === 'eff_start_date' ? formFields.hasOwnProperty(field.filter_table_name + "_" + field.filter_column_name) ?
                        formFields[field.filter_table_name + "_" + field.filter_column_name] : formFields['provider_eff_start_date'] :
                        formFields.hasOwnProperty(field.filter_table_name + "_" + field.filter_column_name) ? formFields[field.filter_table_name + "_" + field.filter_column_name] :
                            formFields[field.filter_column_name]
                    let isEmpty = fieldValue === null || fieldValue === "" || fieldValue === undefined || fieldValue === null;

                    if (typeof fieldValue === 'string' && fieldValue.trim() === '') {
                        isEmpty = true;
                    }

                    if (isRequired && isEmpty) {
                        if (field.filter_column_name !== 'specialty_name') {
                            isValid = false;
                            errObj[field.filter_label] = `${toCamelCase(field.filter_label)} is required`;
                        }
                    }

                } else if (standAlone.position && field.filter_table_name === "vw_org_position") {
                    let jsonObject = field?.dropdown_control ? JSON.parse(field.dropdown_control) : {};
                    const isRequired = (field.is_nullable === 0 && field.filter_column_datatype !== 'boolean') || (jsonObject.required === "true");
                    const fieldValue = formFields.hasOwnProperty(field.filter_table_name + "_" + field.filter_column_name) ?
                        formFields[field.filter_table_name + "_" + field.filter_column_name] : formFields[field.filter_column_name];
                    let isEmpty = fieldValue === null || fieldValue === "" || fieldValue === undefined || fieldValue === null;

                    if (typeof fieldValue === 'string' && fieldValue.trim() === '') {
                        isEmpty = true;
                    }

                    if (isRequired && isEmpty) {
                        if (field.filter_column_name !== 'specialty_name') {
                            isValid = false;
                            errObj[field.filter_label] = `${toCamelCase(field.filter_label)} is required`;
                        }
                    }
                } else if (!(standAlone.position || standAlone.provider)) {
                    let jsonObject = field?.dropdown_control ? JSON.parse(field.dropdown_control) : {};
                    const isRequired = (field.is_nullable === 0 && field.filter_column_datatype !== 'boolean') || (jsonObject.required === "true");
                    const fieldValue = formFields.hasOwnProperty(field.filter_table_name + "_" + field.filter_column_name) ?
                        formFields[field.filter_table_name + "_" + field.filter_column_name] : formFields[field.filter_column_name];
                    let isEmpty = fieldValue === null || fieldValue === "" || fieldValue === undefined || fieldValue === null;

                    if (typeof fieldValue === 'string' && fieldValue.trim() === '') {
                        isEmpty = true;
                    }

                    if (isRequired && isEmpty) {
                        if (field.filter_column_name !== 'specialty_name') {
                            if (field.filter_column_name === 'title_name') {
                                if (formFields.groupData && Object.keys(formFields.groupData).length === 0) {
                                    isValid = false;
                                    errObj['GROUP NAME'] = 'Group name is required';
                                } else if (formFields.groupData) {
                                    errObj['GROUP NAME'] = '';
                                }
                            } else {
                                isValid = false;
                                errObj[field.filter_label] = `${toCamelCase(field.filter_label)} is required`;
                            }

                        }
                    }
                }

                return { ...field };
            });

            return {
                ...category,
                fields: updatedFields
            };
        });


        setErrorObj({ ...errObj });
        setCategoryList(updatedCategoryList);

        return isValid;
    };
    const handleCategoryData = async (categoryName) => {
        const categoryData = categoryFieldList.filter((x) => x.filter_table_name === categoryName);

        const reqData = {
            in_user_id: cookiesData.out_user_id.toString(),
            in_tenant_id: cookiesData.out_tenant_id,
            in_acct_encrypt_key: cookiesData.out_acct_encrypt_key,
        };
        categoryData.filter((x) => x.custom_fld_col_mstr_key === null).forEach((x) => {
            const columnName = 'in_' + x.filter_column_name;
            let fieldValue = typeof formFields[x.filter_table_name + '_' + x.filter_column_name] === 'object' && x.dropdown_control !== "0" ?
                formFields[x.filter_table_name + '_' + x.filter_column_name].text
                :
                formFields[x.filter_table_name + '_' + x.filter_column_name] || "";

            if (x.filter_column_datatype === 'date') {
                fieldValue = fieldValue ? formatDateOnly(fieldValue) : "";
            }
            if (fieldValue === "" || fieldValue === undefined) {
                fieldValue = formFields.hasOwnProperty(x.filter_table_name + '_' + x.filter_column_name) ?
                    formFields[x.filter_table_name + '_' + x.filter_column_name] :
                    formFields[x.filter_column_name]
            }
            
            if(x.filter_column_datatype === 'number'){
                fieldValue = fieldValue === 0 ? '0.00' : fieldValue
            }


            reqData[columnName] = typeof fieldValue === 'number' ? fieldValue : fieldValue || "";
        });
        return reqData;
    };

    const handleSubmit = async () => {
        if (isUpdateEffDate) {
            await updateProviderData('updateEffDate')

        } else {
            updateProviderData('updateOtherFields')
        }
    };

    const updateProviderData = async (module) => {
        setIsUpdateData(true)
        setLoadingState({ ...loadingState, isSaveLoading: true })
        const providerData = await handleCategoryData('vw_provider_master');
        const customFieldDataKeys = await getCustomFields('vw_provider_master', 'provider_mstr_key')
        let { provider_mstr_key, provider_id
        } = restProps.editData.data
        let providerReqData = {
            in_user_id: cookiesData.out_user_id.toString(),
            in_tenant_id: cookiesData.out_tenant_id,
            in_acct_encrypt_key: cookiesData.out_acct_encrypt_key,
            in_employee_id: providerData.in_employee_id,
            in_alternate_id: providerData.in_alternate_id || '',
            in_provider_id: providerData.in_provider_id || provider_id,
            in_provider_mstr_key: provider_mstr_key,
            in_date_version: formFields.provider_date_version,
            in_user_key: formFields?.userData?.user_key || formFields.user_key,
            in_first_name: providerData.in_firstname,
            in_last_name: providerData.in_lastname,
            in_middle_name: providerData.in_middlename,
            in_full_name: providerData.in_fullname,
            in_physician_flag: providerData.in_physician_flag || 0,
            in_department_id: providerData.in_department_id,
            in_credentials: providerData.in_credentials,
            in_npi: providerData.in_npi,
            in_salary: providerData.in_salary ? parseFloat(providerData.in_salary) : providerData.in_salary || null,
            in_salary_unittype: formFields.vw_provider_master_salary_unit.id || "",
            in_target: providerData.in_total_target_incentive || null,
            in_target_unittype: formFields.vw_provider_master_target_unit?.id || "",
            in_hire_date: typeof providerData.in_hire_date === 'object' ? formatDateOnly(providerData.in_hire_date) || null : providerData.in_hire_date || null,
            in_termination_date: providerData.in_termination_date || null,
            in_department_name: providerData.in_department_name,
            in_primary_loc_id: providerData.in_primary_loc_id,
            in_primary_loc_name: providerData.in_primary_loc_name,
            in_secondary_loc_id: providerData.in_secondary_loc_id,
            in_secondary_loc_name: providerData.in_secondary_loc_name,
            in_team_name: providerData.in_team_name,
            in_certification: providerData.in_certification,
            in_medical_school_name: providerData.in_medical_school_name,
            in_usa_training_flag: providerData.in_usa_training_flag || 0,
            in_residency_name: providerData.in_residency_name,
            in_residency_completion_date: typeof providerData.in_residency_completion_date === 'object' ? formatDateOnly(providerData.in_residency_completion_date) || null : providerData.in_residency_completion_date || null,
            in_fellowship_name: providerData.in_fellowship_name,
            in_version_desc: providerData.in_version_desc,
        }
        if (module === 'updateEffDate') {
            if (standAlone.provider) {
                providerReqData.in_eff_start_date =
                    typeof formFields['vw_provider_master_eff_start_date'] === 'object' ? formatDateOnly(formFields['vw_provider_master_eff_start_date']) :
                        formFields['vw_provider_master_eff_start_date'] ? formFields['vw_provider_master_eff_start_date'] : formFields['provider_eff_start_date']
            } else {
                providerReqData.in_eff_start_date = typeof formFields['vw_org_position_eff_start_date'] === 'object' ? formatDateOnly(formFields['vw_org_position_eff_start_date']) || null : formFields['vw_org_position_eff_start_date'] || null
            }
        } else {
            providerReqData.in_eff_start_date = standAlone.provider ?
                typeof editFormFields.provider_eff_start_date === 'object' ? formatDateOnly(editFormFields.provider_eff_start_date) || null : editFormFields.provider_eff_start_date || null
                :
                typeof providerData.in_eff_start_date === 'object' ? formatDateOnly(providerData.in_eff_start_date) || null : providerData.in_eff_start_date || null
        }
        providerReqData.in_custom_field_data_keys = customFieldDataKeys
        let res = await Action.updateProvider(providerReqData);
        if (res.out_ret_code === 0 && !isNullOrEmptyUndefined(editFormFields.position_id)) {
            handlePositionSubmit(module)
        } else if (standAlone.provider) {
            setLoadingState({ ...loadingState, isSaveLoading: false })
            if (module === 'updateEffDate' || module === 'updateOtherFields') {
                // setLoadingState({
                //     ...loadingState,
                //     initialLoad: false,
                // })
                changeUpdateBtnState()
                changeCancelBtnState()
                // setParentDataItem({})
                setEditFormFields({})
                setSelectedVersion(0)
                // setFormFields({})
                setEffVersionLoadingState(false);
                setUpdateEffDate(false)
                // setLoadingState({
                //     ...loadingState,
                //     initialLoad: true,
                // })
                await getEffVersionsApi(module)
            } else {
                setUpdateEffDate(false)
                await getProviderProsByEffVersion(selectedVersion)
            }
        }
        else {
            dispatch(
                setNewMessage({
                    message: res?.data?.out_parameters?.out_error_msg || res?.out_error_msg,
                    type: "Error",
                    show: true,
                })
            );
            setLoadingState({ ...loadingState, isSaveLoading: false })
            setUpdateEffDate(false)
            await getProviderProsByEffVersion(selectedVersion)
        }
    }



    const handlePositionSubmit = async (module) => {
        let positionReqData = await handleCategoryData('vw_org_position');
        const customFieldDataKeys = await getCustomFields('vw_org_position', 'org_position_key')
        let { provider_mstr_key, org_position_key,
        } = restProps.editData.data

        positionReqData.providerKeys = [{
            provider_id: formFields['vw_provider_master_provider_id'] || "",
        }, {
            employee_id: formFields['vw_provider_master_employee_id'] || "",

        }, {
            alternate_id: formFields['vw_provider_master_alternate_id'] || "",
        }]
        positionReqData = {
            in_user_id: cookiesData.out_user_id.toString(),
            in_tenant_id: cookiesData.out_tenant_id,
            in_org_position_key: org_position_key,
            in_date_version: selectedVersion,
            in_position_id: positionReqData.in_position_id || formFields.position_id,
            in_position_name: positionReqData.in_position_name,
            in_position_desc: positionReqData.in_position_desc || '',
            in_org_title_key: formFields?.groupData?.org_title_key || formFields.org_title_key,
            in_provider_mstr_key: provider_mstr_key,
            in_specialty_mstr_key: formFields.vw_org_position_specialty_name.id || '',
            in_org_position_type_key: formFields.vw_org_position_position_type_name.id || '',
            in_eff_start_date: typeof positionReqData.in_eff_start_date === 'object' ? formatDateOnly(positionReqData.in_eff_start_date) || null : positionReqData.in_eff_start_date || null,
            in_contract_type: positionReqData.in_contract_type,
            in_contract_term_months: positionReqData.in_contract_term_months  === '' ? null : positionReqData.in_contract_term_months,
            in_utilization_unittype: formFields.vw_org_position_utilization_unit?.id || '',
            in_utilization_clinical: positionReqData.in_utilization_clinical === '' ? null : positionReqData.in_utilization_clinical,
            in_utilization_admin: positionReqData.in_utilization_admin === '' ? null : positionReqData.in_utilization_admin, 
            in_utilization_research: positionReqData.in_utilization_research  === '' ? null : positionReqData.in_utilization_research, 
            in_utilization_academic: positionReqData.in_utilization_academic  === '' ? null : positionReqData.in_utilization_academic, 
            in_utilization_total: positionReqData.in_utilization_total  === '' ? null : positionReqData.in_utilization_total, 
            in_pay_frequency: positionReqData.in_pay_frequency || '',
        }
        positionReqData.in_custom_field_data_keys = customFieldDataKeys
        let res = await Action.updatePosition(positionReqData);
        if (res.out_ret_code === 0) {
            if (module === 'updateEffDate' || module === 'updateOtherFields') {
                // setLoadingState({
                //     ...loadingState,
                //     initialLoad: false,
                // })
                changeUpdateBtnState()
                changeCancelBtnState()
                // setParentDataItem({})
                setEditFormFields({})
                setSelectedVersion(0)
                // setFormFields({})
                setEffVersionLoadingState(false);
                setUpdateEffDate(false)
                // setLoadingState({
                //     ...loadingState,
                //     initialLoad: true,
                // })
                await getEffVersionsApi(module)
            } else {
                setUpdateEffDate(false)
                await getProviderProsByEffVersion(selectedVersion)
            }
            changeUpdateBtnState("disable", "grid-incell-update");
            changeCancelBtnState("disable", "grid-incell-cancel");
            setLoadingState({ ...loadingState, isSaveLoading: false })
            setIsUpdateClick(true)
        } else {
            dispatch(
                setNewMessage({
                    message: res?.data?.out_parameters?.out_error_msg || res?.out_error_msg,
                    type: "Error",
                    show: true,
                })
            );
            setLoadingState({ ...loadingState, isSaveLoading: false })
            setUpdateEffDate(false)
        }
    }

    const getCustomFields = async (categoryName, mstrKeyName) => {
        let tempData = []
        let obj = {}
        const categoryData = categoryFieldList.filter((x) => x.filter_table_name === categoryName && x.custom_fld_mstr_key !== null);
        categoryData.forEach((x) => {
            const columnName = x.filter_column_name
            let fieldValue = ''
            if (x.custom_fld_mstr_key !== null) {
                fieldValue = formFields.hasOwnProperty(
                    x.filter_table_name + 'customFields' + x.custom_fld_mstr_key +
                    x.custom_fld_col_mstr_key + '_' + x.custom_fld_data_type.toLowerCase()
                ) ? formFields[
                x.filter_table_name + 'customFields' + x.custom_fld_mstr_key +
                x.custom_fld_col_mstr_key + '_' + x.custom_fld_data_type.toLowerCase()
                ] : formFields[x.filter_column_name]
                if (x.filter_column_datatype === 'date') {
                    fieldValue = fieldValue && fieldValue !== null ? formatDateOnly(fieldValue) : "";
                } else if (x.filter_column_datatype === 'boolean') {
                    fieldValue = fieldValue && fieldValue !== null ? fieldValue : "0";
                } else if (x.filter_column_datatype === 'number') {
                    fieldValue =  fieldValue === 0 ? '0.00' : fieldValue || null
                }
            }
            obj = { ...x }
            obj[columnName] = typeof fieldValue === 'number' ? fieldValue : fieldValue || "";
            tempData.push(obj)
        });
        let reqData = []
        tempData.map((item) => {
            let customFields = {
                in_custom_fld_col_mstr_key: item.custom_fld_col_mstr_key,
                in_custom_fld_data_src_mstr_key: editFormFields[mstrKeyName],
                in_data_value: item[item.filter_column_name],
                in_mstr_key_name: mstrKeyName,
                in_date_version: selectedVersion,
                in_custom_fld_data_date: standAlone.provider ?
                    typeof editFormFields.provider_eff_start_date === 'object' ? formatDateOnly(editFormFields.provider_eff_start_date) || null : editFormFields.provider_eff_start_date || null
                    :
                    formFields[`eff_start_date`] ? formatDateOnly(formFields[`eff_start_date`]) : null
            }
            reqData.push(customFields)
            return 0;
        })
        return reqData
    }

    const handleDrop = (e) => {
        if (restProps.pageProps.writeAccess === 0) {
            return false
        }
        if (e.dataItem.category_code !== 'doc_attr' && state.draggedItem.cat_fdm_assign_key && state.draggedItem.filter_column_name !== 'eff_start_date') {
            let findIndex = categoryList.findIndex((x) => x.category_code === e.dataItem.category_code);
            if (findIndex !== -1) {
                let newData = categoryList.map((category) => {
                    if (category.category_code === e.dataItem.category_code) {
                        let sortedFields = category.fields
                        let findCurrentIndex = sortedFields.findIndex((x) => x.cat_fdm_assign_key === e.dataItem.cat_fdm_assign_key);
                        if (state.draggedItem.category_code !== e.dataItem.category_code) {
                            sortedFields.push({
                                ...state.draggedItem,
                                category_code: e.dataItem.category_code
                            })
                        }
                        let findDropIndex = sortedFields.findIndex((x) => x.cat_fdm_assign_key === state.draggedItem.cat_fdm_assign_key);

                        if (findCurrentIndex !== -1 && findDropIndex !== -1) {
                            sortedFields[findCurrentIndex] = state.draggedItem;
                            sortedFields[findDropIndex] = e.dataItem;
                        }

                        return { ...category, fields: sortedFields };
                    }
                    return category;
                });
                if (state.draggedItem.filter_column_name !== 'eff_start_date' && state.draggedItem.cat_fdm_assign_key) {
                    updateCategoryFdmOrder(newData.filter((x) => x.category_code === e.dataItem.category_code), state.draggedItem.cat_fdm_assign_key, categoryList[findIndex].category_config_key)
                }

            }
        }
    };

    const handleParentDragStart = (e, id, item) => {
        if (restProps.pageProps.writeAccess === 0) {
            return false
        }
        if (item.category_code !== 'doc_attr') {
            e.dataTransfer.setData('text/plain', id);
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleParentDrop = (e, dropId) => {
        if (restProps.pageProps.writeAccess === 0) {
            return false
        }
        e.preventDefault();
        if (e.target.id === 'header') {
            const dragId = e.dataTransfer.getData('text/plain');
            const updatedCategories = categoryList.slice();
            const dragIndex = categoryList.findIndex((x) => x.category_config_key === Number(dragId));
            let dropIndex = categoryList.findIndex((x) => x.category_config_key === dropId);
            if (categoryList[dropIndex].category_code === 'doc_attr') {
                dropIndex = dropIndex - 1
            }
            if (state.draggedItem.filter_column_name !== 'eff_start_date' && dragIndex !== dropIndex && dragIndex !== -1 && dropIndex !== -1) {
                const [draggedCategory] = updatedCategories.splice(dragIndex, 1);
                updatedCategories.splice(dropIndex, 0, draggedCategory);
                updateCategoryOrder(updatedCategories, Number(dragId), dropId)
            }
        }

    };

    const updateCategoryOrder = async (updatedCategories, dragId, dropId) => {
        setLoadingState({ ...loadingState, isUpdateCategory: true })
        let reqData = {
            in_user_id: cookiesData.out_user_id.toString(),
            in_tenant_id: cookiesData.out_tenant_id,
            in_category_config_key_list: updatedCategories
                .map((item) => item.category_config_key)
                .toString()
        }
        let res = await Action.updateCategoryOrder(reqData)
        if (res) {
            setLoadingState({ ...loadingState, isUpdateCategory: false })
            setCategoryList(updatedCategories);
        }
    }

    const updateCategoryFdmOrder = async (newData, keyList, configKey) => {
        setLoadingState({ ...loadingState, isUpdateCategoryFdm: true })
        let reqData = {
            "in_user_id": cookiesData.out_user_id.toString(),
            "in_tenant_id": cookiesData.out_tenant_id,
            "in_cat_fdm_assign_key_list": newData
                .map((item) => {
                    let ids = [];
                    item.fields.forEach((fItem) => {
                        ids.push(fItem.cat_fdm_assign_key);
                    });
                    return ids;
                })
                .toString(),
            "in_category_config_key": configKey.toString()
        }
        let res = await Action.updateCategoryFdmOrderAsync(reqData)
        if (res) {

            getCategoryList('drag-n-drop')
        }
    }

    const handleDragStart = (e) => {
        if (restProps.pageProps.writeAccess === 0) {
            return false
        }
        setState({
            ...state,
            draggedItem: e.dataItem,
        });
    };

    const handleItemClick = (event) => {
        if (restProps.pageProps.writeAccess === 0) {
            return false
        }
        let findIndex = categoryList.findIndex((x) => x.category_code === event.dataItem.category_code);

        setCategoryList((prevCategoryList) => {
            const updatedList = [...prevCategoryList];
            updatedList[findIndex].fields = updatedList[findIndex].fields
                .map((item) => {
                    if (item.cat_fdm_assign_key === event.dataItem.cat_fdm_assign_key) {
                        item[SELECTED_FIELD] = !item[SELECTED_FIELD];
                    } else if (!event.nativeEvent.ctrlKey) {
                        item[SELECTED_FIELD] = false;
                    }
                    return item;
                });

            return updatedList;
        });
    };


    const BackBtn = () => {
        return (
            <>
                <Button onClick={handleBackBtn} title='Back to Provider-Position Listing' type="submit" className="cancelbtn backbtn" id="grid-back-btn"><span className="k-button-text"><i className="fa fa-arrow-left"></i></span>
                </Button>
            </>
        )
    }


    const headerConfig = {
        title: "Update Provider-Position",
        id: "update-provider-position",
        buttons: { template_fx: GridButton },
        backBtn: { btn_fx: BackBtn }
    };

    return (
        <>

            {(loadingState.isSaveLoading || loadingState.isUpdateCategory ||
                loadingState.isUpdateCategoryFdm)
                && <LoadingPanel />}

            <>
                <AccessContext.Provider value={[restProps.pageProps]}>
                    <EditFormFieldsContext.Provider value={[formFields, setFormFields]}>
                        <CancelClickContext.Provider value={[isCancelClick, setCancelClick]}>
                            <StandAloneContext.Provider value={[standAlone]}>
                                <EffStartDateContext.Provider value={[isUpdateEffDate, setUpdateEffDate]}>
                                    <ETLFieldsContext.Provider value={[disabledFields]}>
                                        <EditErrorContext.Provider value={[errorObj, setErrorObj]}>
                                            <EditDataListContext.Provider value={[dataList, setDataList]}>
                                                <DragContext.Provider value={[drag, setDrag]}>
                                                    <DropContext.Provider value={[drop, handleDropChange]}>
                                                        <div>
                                                            <div>
                                                                <div className="content">
                                                                    <div className="content-head">
                                                                        <HeaderPage pageProps={restProps.pageProps} headerConfig={headerConfig} />
                                                                    </div>
                                                                    <div className="content-body">
                                                                        {effVersionLoadingState && <LoadingPanel />}
                                                                        <EffectiveVersions
                                                                            pageProps={restProps.pageProps}
                                                                            versionData={parentDataItem}
                                                                            effVersionState={effVersionState}
                                                                            setEffVersionState={setEffVersionState}
                                                                        />

                                                                        <div className="Propos-container">
                                                                            {loadingState.initialLoad && formFields !== '' && Object.keys(parentDataItem).length > 0 ?
                                                                                <div className="att-main">
                                                                                    {categoryList.map((item, i) => {
                                                                                        return (
                                                                                            <>
                                                                                                {item.fields.length > 0 ?
                                                                                                    <>
                                                                                                        <div className="attr-col"
                                                                                                            key={i + 'category'}
                                                                                                            draggable={restProps.pageProps.writeAccess === 1 ? true : false}
                                                                                                            onDragStart={(e) => handleParentDragStart(e, item.category_config_key, item)}
                                                                                                            onDragOver={handleDragOver}
                                                                                                            onDrop={(e) => handleParentDrop(e, item.category_config_key)}
                                                                                                        >
                                                                                                            <div className={item.category_code === 'doc_attr' ?
                                                                                                                'attr-col-heading default-cursor' :
                                                                                                                'attr-col-heading'} id='header'>
                                                                                                                {item.category_code !== 'doc_attr' &&
                                                                                                                    restProps.pageProps.writeAccess === 1 ? <i className="fas fa-arrows-alt" ></i> : false}{item.category_label}
                                                                                                            </div>
                                                                                                            <div className="attr-col-content">
                                                                                                                <div className="form-row providerbox">
                                                                                                                    <>
                                                                                                                        <ListBox
                                                                                                                            style={{
                                                                                                                                width: "100%",
                                                                                                                            }}
                                                                                                                            data={item.fields}
                                                                                                                            selectedField={SELECTED_FIELD}
                                                                                                                            onItemClick={(e) =>
                                                                                                                                handleItemClick(e, "notDiscontinued", "discontinued")
                                                                                                                            }
                                                                                                                            key='edit'
                                                                                                                            onDragStart={item.category_code === 'doc_attr' ? () => { } : handleDragStart}
                                                                                                                            onDrop={handleDrop}
                                                                                                                            item={ProviderFields}

                                                                                                                        >
                                                                                                                        </ListBox>
                                                                                                                    </>
                                                                                                                </div>
                                                                                                            </div>

                                                                                                        </div>
                                                                                                    </> : false}
                                                                                            </>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                                : <LoadingPanel />}
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </DropContext.Provider>
                                                </DragContext.Provider>
                                            </EditDataListContext.Provider>
                                        </EditErrorContext.Provider>
                                    </ETLFieldsContext.Provider>
                                </EffStartDateContext.Provider>
                            </StandAloneContext.Provider>
                        </CancelClickContext.Provider>
                    </EditFormFieldsContext.Provider>
                </AccessContext.Provider>
            </>
        </>
    );
};
export default React.memo(UpdateProviderPosition);

export const GridButton = () => {
    return (
        <>
            <Button type="submit" className="cancelbtn" id="grid-incell-cancel">
                Cancel
            </Button>
            <Button type="submit" className="primarybtn" id="grid-incell-update">
                Save
            </Button>
        </>
    );
};
