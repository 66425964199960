/* eslint-disable jsx-a11y/anchor-has-content, react/jsx-no-target-blank */
import React from "react";
import { Tooltip } from "@progress/kendo-react-tooltip";
import ManageColumns from "../../components/manageColumns";
import { ProgressBar } from "@progress/kendo-react-progressbars";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { linkedRulesSelector } from "src/redux/selectors/custom-selector";
import { clearLinkedRules } from "src/redux/actions/setting-actions";
import { DashboardConstant } from "../dashboard/index.const";
import { GetVersionBuildApi } from "../../lib/common-service";

let baseConfig = {
  headerConfig: {
    title: "ProCARE Page",
    id: "procare-page",
    info: "",
    search_fx: null,
    buttons: null,
    add_new_fx: null,
    upload_fx: null,
    action_menu: {},
    manage_col: {},
    progress_fx: {},
    backBtn: null,
  },
};

export const HeaderPage = (props) => {
  setHeaderConfig(props);
  const pageConfig = props.headerConfig;

  const dispatch = useDispatch();
  const { linkedRules } = useSelector(linkedRulesSelector, shallowEqual);
  let upload_title = pageConfig?.upload_title
    ? pageConfig.upload_title
    : pageConfig.title;
  let dtuUploadTitle = `Upload ${upload_title || ""}`;

  return (
    <>
      <div className="content-head-left">
        <div className="Page-title">
          <h1>{pageConfig.title}</h1>
          {pageConfig.info && <span>{pageConfig.info}</span>}
        </div>
      </div>

      <div className="content-head-right" id={"content-" + pageConfig.id}>
        <ul className="Quick-links">
          {pageConfig.progress_fx !== undefined &&
          pageConfig.progress_fx.visibale ? (
            <li className="text-center">
              <ProgressBar
                value={pageConfig.progress_fx.progressValue}
                label={(props) => {
                  return <strong>{props.value}% </strong>;
                }}
              />
            </li>
          ) : (
            false
          )}
          {pageConfig.buttons && (
            <li className="k-form-buttons">
              <pageConfig.buttons.template_fx
                args={pageConfig.buttons.args ? pageConfig.buttons.args : {}}
              />
            </li>
          )}
          {pageConfig.backBtn && (
            <li className="k-form-buttons">
              <pageConfig.backBtn.btn_fx
                args={pageConfig.backBtn.args ? pageConfig.backBtn.args : {}}
              />
            </li>
          )}
          {pageConfig.add_new_fx && (
            <li className="Add-new">
              {typeof pageConfig.add_new_fx === "function" ? (
                pageConfig.add_new_fx()
              ) : (
                <pageConfig.add_new_fx />
              )}
            </li>
          )}
          {pageConfig.search_fx && (
            <li className="hoverMenu">{pageConfig.search_fx()}</li>
          )}
          {pageConfig.add_sync_fx && (
            <li className="Add-new">{pageConfig.add_sync_fx()}</li>
          )}

          {pageConfig.upload_fx && (
            <li className="upload">
              <Tooltip openDelay={100} position="bottom" anchorElement="target">
                <a
                  href="/#"
                  className="cta-small"
                  onClick={(e) => {
                    pageConfig.upload_fx(e);
                  }}
                  title={`${dtuUploadTitle}`}
                >
                  <i title={`${dtuUploadTitle}`} className="fas fa-upload"></i>
                </a>
              </Tooltip>
            </li>
          )}
          {pageConfig.action_menu !== undefined &&
            Object.keys(pageConfig.action_menu).length > 0 && (
              <li className="hoverMenu">
                <a
                  // href="/#"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  className="cta-small"
                >
                  <i className="fas fa-ellipsis-v"></i>
                </a>
                <div className="dot-dropdown-open q-link">
                  <div className="caption">
                    <span>Quick Links</span>
                  </div>
                  <ul className="QLink">
                    {pageConfig.action_menu.addNew !== undefined && (
                      <li
                        onClick={() => {
                          pageConfig.action_menu.addNew.fx("addNew");
                        }}
                      >
                        <span>
                          <i className="far fa-add-alt"></i>
                          &nbsp;
                          {pageConfig.action_menu.addNew.caption}
                        </span>
                      </li>
                    )}
                    {pageConfig.action_menu.duplicateRow !== undefined && (
                      <li
                        onClick={() => {
                          pageConfig.action_menu.duplicateRow.fx(
                            "duplicateRow"
                          );
                        }}
                      >
                        <span>
                          <i className="fas fa-file-excel copy-data"></i>
                          &nbsp;
                          {pageConfig.action_menu.duplicateRow.caption}
                        </span>
                      </li>
                    )}

                    {pageConfig.action_menu.auditLog !== undefined && (
                      <li
                        onClick={() => {
                          pageConfig.action_menu.auditLog.fx("auditLogs");
                        }}
                      >
                        <span>
                          <i className="fas fa-layer-group audit-log"></i>
                          &nbsp;
                          {pageConfig.action_menu.auditLog.caption}
                        </span>
                      </li>
                    )}

                    {pageConfig.action_menu.dtuLog !== undefined && (
                      <li
                        onClick={() => {
                          pageConfig.action_menu.dtuLog.fx("dtuLogs");
                        }}
                      >
                        <span>
                          <i className="fas fa-clone dtu-log"></i>
                          {pageConfig.action_menu.dtuLog.caption}
                        </span>
                      </li>
                    )}

                    {pageConfig.action_menu.export !== undefined && (
                      <li
                        onClick={() => {
                          pageConfig.action_menu.export.fx("export");
                        }}
                      >
                        <span>
                          <i className="fas fa-file-excel export-to-excel"></i>
                          &nbsp;
                          {pageConfig.action_menu.export.caption}
                        </span>
                      </li>
                    )}

                    {pageConfig.action_menu.exportAllVersion !== undefined && (
                      <li
                        onClick={() => {
                          pageConfig.action_menu.exportAllVersion.fx(
                            "exportAllVersion"
                          );
                        }}
                      >
                        <span>
                          <i className="fas fa-file-excel export-to-excel"></i>
                          &nbsp;
                          {pageConfig.action_menu.exportAllVersion.caption}
                        </span>
                      </li>
                    )}

                    {pageConfig.action_menu.exportCurrentVersion !==
                      undefined && (
                      <li
                        onClick={() => {
                          pageConfig.action_menu.exportCurrentVersion.fx(
                            "exportCurrentVersion"
                          );
                        }}
                      >
                        <span>
                          <i className="fas fa-file-excel export-to-excel1"></i>
                          &nbsp;
                          {pageConfig.action_menu.exportCurrentVersion.caption}
                        </span>
                      </li>
                    )}

                    {pageConfig.action_menu.sendEmails !== undefined && (
                      <li
                        onClick={() => {
                          pageConfig.action_menu.sendEmails.fx("sendEmails");
                        }}
                      >
                        <span>
                          <i className="fas fa-envelope send-emails"></i>
                          &nbsp;
                          {pageConfig.action_menu.sendEmails.caption}
                        </span>
                      </li>
                    )}

                    {pageConfig.action_menu.delete !== undefined && (
                      <li
                        onClick={() => {
                          pageConfig.action_menu.delete.fx("delete");
                        }}
                      >
                        <span>
                          <i className="far fa-trash-alt delete-rows"></i>
                          &nbsp;
                          {pageConfig.action_menu.delete.caption}
                        </span>
                      </li>
                    )}
                    {pageConfig.action_menu.deleteAll !== undefined && (
                      <li
                        onClick={() => {
                          pageConfig.action_menu.deleteAll.fx(
                            "Delete All inPeriod"
                          );
                        }}
                      >
                        <span>
                          <i className="fas fa-layer-group delete-allrows-batch"></i>
                          &nbsp;
                          {pageConfig.action_menu.deleteAll.caption}
                        </span>
                      </li>
                    )}
                    {pageConfig.action_menu.deleteBatch !== undefined && (
                      <li
                        onClick={() => {
                          pageConfig.action_menu.deleteBatch.fx(
                            "Delete By Batch"
                          );
                        }}
                      >
                        <span>
                          <i className="fas fa-layer-group delete-allrows"></i>
                          &nbsp;
                          {pageConfig.action_menu.deleteBatch.caption}
                        </span>
                      </li>
                    )}

                    {pageConfig.action_menu.manageType !== undefined && (
                      <ul>
                        {pageConfig.action_menu.manageType.items.map(
                          (item, index) => (
                            <li
                              key={index}
                              onClick={() =>
                                pageConfig.action_menu.manageType.fx(
                                  item.module
                                )
                              }
                            >
                              <span>
                                <i className="fas fa-file-excel"></i>
                                &nbsp;
                                {item.caption}
                              </span>
                            </li>
                          )
                        )}
                      </ul>
                    )}

                    {pageConfig.action_menu.copyDashTab !== undefined && (
                      <li
                        onClick={() => {
                          pageConfig.action_menu.copyDashTab.fx("copyDashTab");
                        }}
                      >
                        <span>
                          <i className="fas fa-copy"></i>
                          &nbsp;
                          {pageConfig.action_menu.copyDashTab.caption}
                        </span>
                      </li>
                    )}
                    {pageConfig.action_menu.editDashTab !== undefined && (
                      <li
                        className={
                          pageConfig?.disabled?.editDashTab
                            ? "editDashTabDisabled"
                            : ""
                        }
                        onClick={() => {
                          pageConfig.action_menu.editDashTab.fx("editDashTab");
                        }}
                      >
                        <span>
                          <i className="fas fa-edit"></i>
                          &nbsp;
                          {pageConfig.action_menu.editDashTab.caption}
                        </span>
                      </li>
                    )}
                    {pageConfig.action_menu.deleteDashTab !== undefined && (
                      <li
                        onClick={() => {
                          pageConfig.action_menu.deleteDashTab.fx(
                            "deleteDashTab"
                          );
                        }}
                      >
                        <span>
                          <i className="fas fa-solid fa-trash"></i>
                          &nbsp;
                          {pageConfig.action_menu.deleteDashTab.caption}
                        </span>
                      </li>
                    )}
                    {pageConfig.action_menu.exportToPDF !== undefined && (
                      <li
                        className={
                          pageConfig.disabled?.exportToPDF
                            ? "exportToPDFDisabled"
                            : ""
                        }
                        onClick={() => {
                          pageConfig.action_menu.exportToPDF.fx("exportToPDF");
                        }}
                      >
                        <span>
                          <i className="fas fa-solid fa-file-pdf"></i>
                          &nbsp;
                          {pageConfig.action_menu.exportToPDF.caption}
                        </span>
                      </li>
                    )}
                  </ul>
                </div>
              </li>
            )}
          {pageConfig.manage_col !== undefined &&
          pageConfig.manage_col.columnsData.length ? (
            <ManageColumns
              columnsData={pageConfig.manage_col.columnsData}
              setColumnsData={pageConfig.manage_col.setColumnsData}
              setColumnCheck={pageConfig.manage_col.setColumnCheck}
              pageId={pageConfig.id}
            />
          ) : (
            false
          )}
        </ul>

        {pageConfig.id && linkedRules[pageConfig.id] && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 4,
              alignItems: "center",
            }}
          >
            <Tooltip anchorElement="target" position="top" openDelay={100}>
              <span
                style={{
                  textAlign: "center",
                  color: "#00a1e4",
                }}
                title={linkedRules[pageConfig.id]?.title}
              >
                Filtered Data
              </span>
            </Tooltip>

            <button
              onClick={() => {
                dispatch(clearLinkedRules(pageConfig.id));
              }}
              className="k-button k-button-md k-button-solid k-rounded-md cancelbtn"
              style={{ padding: 8 }}
            >
              Clear
            </button>
          </div>
        )}
      </div>
    </>
  );
};
const redirectTo = () => {
  window.location.href = "/";
};
export const ProCareLogo = React.memo(() => {
  return (
    <div className="loginlogolink">
      <p
        type="link"
        className="btn btn-secondary cursor-pointer"
        data-toggle="tooltip"
        data-placement="right"
        title="ProCARE - Provider Compensation Automation Rules Engine"
      >
        <img
          className="logo-img"
          onClick={redirectTo}
          src={require("../../images/logoImage.svg").default}
          alt="ProCARE"
        />
      </p>
    </div>
  );
});

export const ProCareFooter = () => {
  const [buildVesrion, setBuildVesrion] = React.useState("");

  React.useEffect(() => {
    const fetchVersionBuild = async () => {
      let res = await GetVersionBuildApi();
      setBuildVesrion(res?.out_parameters?.out_release_ver);
      localStorage.setItem("build-vesrion", res.out_parameters.out_release_ver);
    };
    fetchVersionBuild();
  }, []);
  return (
    <ul id="footerNav">
      <li>
        <a
          href="https://procareportal.com/privacy-policy/"
          title="Privacy Policy"
          target="_blank"
          className="privacy"
        >
          Privacy Policy
        </a>
      </li>
      <li>
        <a
          href="https://procareportal.com/terms-conditions/"
          target="_blank"
          title="Terms &amp; Conditions"
        >
          Terms &amp; Conditions
        </a>
      </li>
      <li className="build_ver">{buildVesrion}</li>
    </ul>
  );
};

const setHeaderConfig = (props) => {
  const reqHeaderConf =
    props.headerConfig === undefined ? {} : props.headerConfig;
  const appMenuOptions = {
    add: { caption: "Add New Record" },
    update: { caption: "Edit Selected Record" },
    delete: { caption: "Delete Selected Rows" },
    auditLog: { caption: "Show Audit Log" },
    dtuLog: { caption: "DTU Log" },
    export: { caption: "Export to Excel", check_write_access: 0 },
    sendEmails: { caption: "Send Emails" },
    progress_fx: { visibale: false, progressValue: 0 },
    duplicateRow: { caption: "Duplicate Rows" },
    manageType: { caption: "ManageType" },
    exportAllVersion: {
      caption: "Export All version",
      check_write_access: 0,
    },
    exportCurrentVersion: {
      caption: "Export Current version",
      check_write_access: 0,
    },
    copyDashTab: { caption: DashboardConstant.copyDashabordButton.title },
    editDashTab: { caption: DashboardConstant.editTabSettings.title },
    deleteDashTab: { caption: DashboardConstant.deleteDashboardTab.title },
    exportToPDF: {
      caption: DashboardConstant.exportToPDF.title,
      check_write_access: 0,
    },
    deleteBatch: { caption: "Delete By Batch" },
    deleteAll: { caption: "Delete All in Period" },
  };

  //remove write access related objects
  if (
    props.pageProps.writeAccess === 0 ||
    props.versionData?.data_write_access === 0
  ) {
    delete reqHeaderConf.buttons;
    delete reqHeaderConf.add_new_fx;
    delete reqHeaderConf.upload_fx;
  }

  if (reqHeaderConf.action_menu !== undefined) {
    Object.entries(appMenuOptions).map((val, idx) => {
      val[1].check_write_access =
        val[1].check_write_access === undefined ? 1 : val[1].check_write_access;

      //remove write access related action menu objects
      if (
        val[1].check_write_access &&
        (props.pageProps.writeAccess == 0 ||
          props.versionData?.data_write_access === 0)
      ) {
        delete reqHeaderConf.action_menu[val[0]];

        return;
      }

      if (
        reqHeaderConf.action_menu[val[0]] !== undefined &&
        !reqHeaderConf.action_menu[val[0]].caption
      ) {
        reqHeaderConf.action_menu[val[0]].caption = val[1].caption;
      }
      return 0;
    });
  }

  Object.assign(baseConfig.headerConfig, reqHeaderConf);
};
