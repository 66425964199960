import * as React from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { messageSelector } from "../redux/selectors/custom-selector";
import { useSource } from "../lib/hooks/custom-common-hooks";
import { removeMessage } from "../redux/actions/setting-actions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure();

export const NotificationToaster = React.memo((props) => {
  const reduxData = useSelector(messageSelector, shallowEqual);

  return (
    <>
      {reduxData.messageList &&
        reduxData.messageList.map((notification, index) => (
          <Message key={notification.id} notification={notification} />
        ))}
    </>
  );
});

const Message = React.memo(({ notification }) => {
  const otherConfig = notification.otherConfig;

  const sec = notification.sec ? notification.sec : 5000;
  const source = useSource();
  const dispatch = useDispatch();

  if (!source.timeout) {
    source.timeout = setTimeout(() => {
      dispatch(removeMessage(notification.id));
    }, sec);
  }

  let toastConfig = {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  };

  //overwrite or add new object in toastConfig
  if (otherConfig !== undefined) {
    toastConfig = Object.assign(toastConfig, otherConfig);
  }

  React.useEffect(() => {
    if (notification.message) {
      toast[notification.type.toLowerCase()](notification.message, toastConfig);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification.id]);

  return <></>;
});
