import React from "react";
import { JobRunLog } from "../dataRows/dataRowsGrid";

const DataRows = (restProps) => {
  const [selectedRowId, setSelectedRowId] = React.useState("");
  //disable in-cell update and cancel button
  return (
    <>
      <div className="dtu-file-container plancheckboxgrid rowhoverdisable">
        <JobRunLog
          isCancelClick={restProps.isCancelClick}
          setisCancelClick={restProps.setisCancelClick}
          pipeLogColumnsData={restProps.pipeLogColumnsData}
          setpipeLogColumnsData={restProps.setpipeLogColumnsData}
          isJobAPI={restProps.isJobAPI}
          setIsJobAPI={restProps.setIsJobAPI}
          setSelectedRowId={setSelectedRowId}
          selectedRowId={selectedRowId}
          setLogdaysInterVal={restProps.setLogdaysInterVal}
          setExportDataClick={restProps.setExportDataClick}
          exportDataClick={restProps.exportDataClick}
          isPublishDisable={restProps.isPublishDisable}
          setIsPublishDisable={restProps.setIsPublishDisable}
          publishJobData={restProps.publishJobData}
          progressBarPer={restProps.progressBarPer}
          setLogDataList={restProps.setLogDataList}
          isAPICallForReport={restProps.isAPICallForReport}
          setisAPICallForReport={restProps.setisAPICallForReport}
        />
      </div>
    </>
  );
};

export default React.memo(DataRows);
