import React, { useEffect, useState } from "react";
import { reportsSelector } from "../../redux/selectors/custom-selector";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Button } from "@progress/kendo-react-buttons";
import DataTable from "../../components/dataTable";
import { GridCell, GridColumn } from "@progress/kendo-react-grid";
import { columnMenuProps } from "../../components/customColumnMenu";
import {
  setReportsCompDetailSection2Filter,
  setReportsCompDetailSection2FilterString,
  setReportsCompDetailSection2GroupCollapsedState,
  setReportsCompDetailSection2Page,
  setReportsCompDetailSection2Sort,
  setReportsCompDetailSection2SortNumber,
} from "../../redux/actions/reports-actions";
import useReportsService from "./service";
import { getfilterDataString_V2 } from "../../utils/utils";
import { LoadingPanel } from "../../components/loadingPanel";
import {
  setExpandedState,
  setGroupIds,
} from "@progress/kendo-react-data-tools";
import { groupBy } from "@progress/kendo-data-query";
import { IntlService } from "@progress/kendo-react-intl";

const initialGroup = [
  {
    field: "h$title_name",
  },
];

const intl = new IntlService("en");

const processWithGroups = (data, group) => {
  const newDataState = groupBy(data, group);
  setGroupIds({
    data: newDataState,
    group: group,
  });
  return newDataState;
};

const SectionTwo = (props) => {
  const {
    providerFilterValue,
    eventTypeFilterValue,
    compPlanFilterValue,
    periodFilterValue,
    reportsCompDetailSection2Data,
    reportsCompDetailSection2DataTotalRows,
    reportsCompDetailSection2PageOffset,
    reportsCompDetailSection2PageSize,
    reportsCompDetailSection2Sort,
    reportsCompDetailSection2SortNumber,
    reportsCompDetailSection2FilterString,
    isReportsCompDetailSection2DataLoading,
    reportsCompDetailSection2DataColumns,
    reportsCompDetailSection2Filter,
    showReportsCompDetailSection1,
    reportsCompDetailSection1Data,
    reportsCompDetailSection2GroupCollapsedSate,
    isReorderLoading,
  } = useSelector(reportsSelector, shallowEqual);
  const [onExportClick, setOnExportClick] = useState(false);
  const { onColumnReorder, reportHeight } = props;

  const dispatch = useDispatch();
  const reportsServiceAction = useReportsService();

  const getSortNumber = (sortData = reportsCompDetailSection2Sort) => {
    let sortNumber = reportsCompDetailSection2SortNumber;
    let fields =
      reportsCompDetailSection2Data.length > 0
        ? Object.keys(reportsCompDetailSection2Data[0])
        : null;

    if (sortData.length > 0 && fields) {
      for (let i = 0; i < sortData.length; i++) {
        let index = fields.findIndex(
          (fieldName) => fieldName === sortData[i].field
        );
        if (i === 0) {
          sortNumber = `${index + 1} ${sortData[i].dir.toUpperCase()}`;
        } else {
          sortNumber =
            sortNumber + `, ${index + 1} ${sortData[i].dir.toUpperCase()}`;
        }
      }
    } else {
      sortNumber = " 1 ASC ";
    }

    dispatch(setReportsCompDetailSection2SortNumber(sortNumber));

    return sortNumber;
  };

  const BooleanCell = React.useCallback((props) => {
    const { ariaColumnIndex, columnIndex, dataItem, field, render } = props;
    if (props.rowType === "data") {
      const defaultRendering = (
        <td aria-colindex={ariaColumnIndex} data-grid-col-index={columnIndex}>
          {dataItem[field] !== ""
            ? dataItem[field] == 1
              ? "true"
              : "false"
            : props.children}
        </td>
      );

      return render?.call(undefined, defaultRendering, props);
    }

    return render?.call(undefined, <></>, props);
  }, []);

  const DateCell = React.useCallback((props) => {
    const { ariaColumnIndex, columnIndex, dataItem, field, render } = props;
    const defaultRendering = (
      <td aria-colindex={ariaColumnIndex} data-grid-col-index={columnIndex}>
        {dataItem[field]
          ? intl.format(props.format, new Date(dataItem[field]), "en")
          : ""}
      </td>
    );
    return render?.call(undefined, defaultRendering, props);
  }, []);

  const NumberCell = React.useCallback((props) => {
    const { ariaColumnIndex, columnIndex, dataItem, field, render } = props;
    const defaultRendering = (
      <td aria-colindex={ariaColumnIndex} data-grid-col-index={columnIndex}>
        {dataItem[field]
          ? intl.format(props.format || `{0:0}`, Number(dataItem[field]), "en")
          : ""}
      </td>
    );
    return render?.call(undefined, defaultRendering, props);
  }, []);

  const customColumns = () => {
    let counter = -1;
    return reportsCompDetailSection2DataColumns
      .filter((it) => it.title !== "")
      .map((column, idx) => {
        return column.show ? (
          <GridColumn
            {...columnMenuProps(column, "compensation_detail")}
            key={column.field}
            id={`${column.field}@${(counter += 1)}`}
            sortable={true}
            reorderable={true}
            width={column.title.length > 15 ? 200 : 170}
            locked={false}
            cell={
              column.type === "boolean"
                ? BooleanCell
                : column.type === "date"
                ? DateCell
                : column.type === "number"
                ? NumberCell
                : GridCell
            }
            format={column.format}
          />
        ) : null;
      });
  };

  const setPageSize = (pageSize) => {
    dispatch(
      setReportsCompDetailSection2Page(
        reportsCompDetailSection2PageOffset,
        pageSize
      )
    );
  };

  const handlePageChange = ({ page }) => {
    dispatch(
      setReportsCompDetailSection2Page(page.skip, {
        id: page.take,
        recordTake: page.take,
      })
    );
    reportsServiceAction.getSection2(
      providerFilterValue["provider_key"],
      eventTypeFilterValue["event_type_mstr_key"],
      compPlanFilterValue["comp_plan_key"],
      periodFilterValue["period_key"],
      periodFilterValue["period_start_date"],
      periodFilterValue["period_end_date"],
      page.skip,
      page.take,
      reportsCompDetailSection2SortNumber,
      reportsCompDetailSection2FilterString
    );
  };

  const onRefreshClick = () => {
    reportsServiceAction.getSection2(
      providerFilterValue["provider_key"],
      eventTypeFilterValue["event_type_mstr_key"],
      compPlanFilterValue["comp_plan_key"],
      periodFilterValue["period_key"],
      periodFilterValue["period_start_date"],
      periodFilterValue["period_end_date"],
      reportsCompDetailSection2PageOffset,
      reportsCompDetailSection2PageSize.recordTake,
      reportsCompDetailSection2SortNumber,
      reportsCompDetailSection2FilterString
    );
  };

  const handleColumnMenu = (columnMenuData, isFilter, isSort) => {
    let sortNumber = reportsCompDetailSection2SortNumber;
    let sortData = [...reportsCompDetailSection2Sort];
    let skip = reportsCompDetailSection2PageOffset;
    let pageSize = reportsCompDetailSection2PageSize;
    let filterString = reportsCompDetailSection2FilterString;
    if (isSort) {
      sortData = columnMenuData ? [...columnMenuData] : [];
      dispatch(setReportsCompDetailSection2Sort(sortData));
      sortNumber = getSortNumber(sortData);
    }

    if (isFilter) {
      skip = 0;
      filterString = getfilterDataString_V2(
        columnMenuData,
        reportsCompDetailSection2DataColumns
      );

      dispatch(setReportsCompDetailSection2Page(skip, pageSize)); //reset pager when filter applies
      dispatch(
        setReportsCompDetailSection2Filter({
          logic: "and",
          filters: columnMenuData,
        })
      );
      dispatch(setReportsCompDetailSection2FilterString(filterString));
    }

    reportsServiceAction.getSection2(
      providerFilterValue["provider_key"],
      eventTypeFilterValue["event_type_mstr_key"],
      compPlanFilterValue["comp_plan_key"],
      periodFilterValue["period_key"],
      periodFilterValue["period_start_date"],
      periodFilterValue["period_end_date"],
      skip,
      pageSize.recordTake,
      sortNumber,
      filterString
    );
  };

  const onClearFilter = () => {
    dispatch(setReportsCompDetailSection2FilterString(null));
    dispatch(setReportsCompDetailSection2Filter({ logic: "and", filters: [] }));
    reportsServiceAction.getSection2(
      providerFilterValue["provider_key"],
      eventTypeFilterValue["event_type_mstr_key"],
      compPlanFilterValue["comp_plan_key"],
      periodFilterValue["period_key"],
      periodFilterValue["period_start_date"],
      periodFilterValue["period_end_date"],
      reportsCompDetailSection2PageOffset,
      reportsCompDetailSection2PageSize.recordTake,
      reportsCompDetailSection2SortNumber,
      null
    );
  };

  const handleExportToExcelClick = () => {
    setOnExportClick(true);
    reportsServiceAction
      .compDetailExportToExcell(
        providerFilterValue["provider_key"],
        eventTypeFilterValue["event_type_mstr_key"],
        compPlanFilterValue["comp_plan_key"],
        periodFilterValue["period_key"],
        periodFilterValue["period_start_date"],
        periodFilterValue["period_end_date"],
        reportsCompDetailSection2SortNumber,
        reportsCompDetailSection2FilterString
      )
      .finally(() => {
        setOnExportClick(false);
      });
  };

  const onExpandChange = React.useCallback(
    (event) => {
      const item = event.dataItem;
      if (item.groupId) {
        const newCollapsedIds = !event.value
          ? [...reportsCompDetailSection2GroupCollapsedSate, item.groupId]
          : reportsCompDetailSection2GroupCollapsedSate.filter(
              (groupId) => groupId !== item.groupId
            );
        dispatch(
          setReportsCompDetailSection2GroupCollapsedState(newCollapsedIds)
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reportsCompDetailSection2GroupCollapsedSate]
  );

  const newData = setExpandedState({
    data: processWithGroups(reportsCompDetailSection2Data, initialGroup),
    collapsedIds: reportsCompDetailSection2GroupCollapsedSate,
  });

  const cellRender = (td, props) => {
    if (td && td.props.children && props.rowType === "groupHeader") {
      let children = (
        <>
          <p className="k-reset">
            {td.props?.children?.props?.children[0]}
            TITLE NAME: {props.dataItem[props.field]}
          </p>
        </>
      );
      return React.cloneElement(td, td.props, children);
    }
    return td;
  };

  const columnReorderData = async (columns) => {
    onColumnReorder?.(columns);
  };

  useEffect(() => {
    if (document.getElementsByClassName("k-grouping-header").length > 0) {
      const element = document
        .getElementsByClassName("k-grouping-header")
        .item(0);
      element.classList.add("k-hidden");
    }
  }, [
    reportsCompDetailSection2Data,
    isReportsCompDetailSection2DataLoading,
    isReorderLoading,
  ]);

  return (
    <div
      className={`${
        reportsCompDetailSection1Data && showReportsCompDetailSection1
          ? " page-break"
          : ""
      }`}
      style={{ display: "flex", flexDirection: "column" }}
    >
      {props.pageProps.exportControlAccess === 1 && (
        <Button
          style={{
            width: "150px",
            alignSelf: "flex-end",
            marginBottom: "10px",
          }}
          className="primarybtn"
          onClick={() =>
            reportsCompDetailSection2Data?.length > 0
              ? handleExportToExcelClick()
              : null
          }
          disabled={reportsCompDetailSection2Data?.length === 0}
        >
          <span>
            <i className="fas fa-file-excel"></i> Export to CSV
          </span>
        </Button>
      )}
      {/* <LoadingPanel /> */}
      {isReportsCompDetailSection2DataLoading || onExportClick ? (
        <LoadingPanel />
      ) : null}

      {!isReorderLoading ? (
        <DataTable
          width={"auto"}
          gridHeight={reportHeight - 50}
          isAPICalling={isReportsCompDetailSection2DataLoading}
          initialLoading={true}
          data={newData}
          cellRender={cellRender}
          sortMode={"multiple"}
          handleColumnMenu={handleColumnMenu}
          pageData={{
            skip: reportsCompDetailSection2PageOffset,
            take: reportsCompDetailSection2PageSize.recordTake,
          }}
          initialSort={reportsCompDetailSection2Sort}
          dataCount={reportsCompDetailSection2DataTotalRows}
          customColumn={customColumns()}
          columns={reportsCompDetailSection2DataColumns}
          pageSize={reportsCompDetailSection2PageSize}
          setPageSize={setPageSize}
          handlePageChange={handlePageChange}
          refreshClick={onRefreshClick}
          onClearFilter={onClearFilter}
          groupable={true}
          group={[
            {
              field: "h$title_name",
            },
          ]}
          onGroupChange={() => null}
          expandChange={(e) => onExpandChange(e)}
          module={"reports"}
          noDataFound={reportsCompDetailSection2Data?.length > 0 ? false : true}
          reorderable={true}
          columnReorderData={columnReorderData}
          initialFilter={reportsCompDetailSection2Filter}
        />
      ) : null}
    </div>
  );
};

export default React.memo(SectionTwo);
