import {
    Chart,
    ChartArea,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartLegend,
    ChartSeries,
    ChartTitle,
    ChartValueAxis,
    ChartValueAxisItem,
    ChartXAxis,
    ChartXAxisItem,
    ChartYAxis,
    ChartYAxisItem,
} from "@progress/kendo-react-charts";
import { chartWrapperConfig, chartTypeConfig } from "./chart.const";
import { ChartHook } from "./index.hook";
import React, { useMemo } from "react";
import "hammerjs"; //added for kendo dependencie

const ChartWrapper = ({ chartConfig, data }) => {
    const { renderChart } = ChartHook({ data });
    const [dataset, setDataset] = React.useState(data);
    const chartRef = React.useRef();

    const nFormatter = (num, digits) => {
        var si = [
            { value: 1, symbol: "" },
            { value: 1e3, symbol: "k" },
            { value: 1e6, symbol: "m" },
            { value: 1e9, symbol: "g" },
            { value: 1e12, symbol: "t" },
            { value: 1e15, symbol: "p" },
            { value: 1e18, symbol: "e" },
        ];

        var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        var i;

        for (i = si.length - 1; i > 0; i--) {
            if (Math.abs(num) >= si[i].value) {
                break;
            }
        }
        return (
            (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol
        );
    };

    const shortLabels = (e) => {
        if (!isNaN(e.value)) {
            return nFormatter(e.value, 2);
        } else {
            if (e.value.length > 15) {
                var value = e.value.substring(0, 15);
                return value + "...";
            }
        }
        return e.value;
    };

    const chartAreaProps = useMemo(() => {
        return chartConfig?.chartBackgroundColor
            ? { background: chartConfig.chartBackgroundColor }
            : {};
    }, [chartConfig?.chartBackgroundColor]);

    const categoryAxisItems = useMemo(() => {
        if (chartConfig?.chartCategories?.length > 0) {
            const categoryAxisConfig = {
                ...chartWrapperConfig?.categoryAxisConfig,
                labels: {
                    ...chartWrapperConfig?.categoryAxisConfig?.labels,
                    step: ["bar"].includes(
                        data?.[0]?.chartType || data?.[1]?.chartType
                    )
                        ? 2
                        : 1,
                },
            };
            return (
                <>
                    <ChartCategoryAxis>
                        <ChartCategoryAxisItem
                            categories={chartConfig.chartCategories}
                            {...categoryAxisConfig}
                        />
                    </ChartCategoryAxis>
                    <ChartValueAxis>
                        <ChartValueAxisItem
                            labels={{
                                content: shortLabels,
                                step: ["column", "line"].includes(
                                    data?.[0]?.chartType || data?.[1]?.chartType
                                )
                                    ? 2
                                    : 1,
                            }}
                        />
                    </ChartValueAxis>
                </>
            );
        }
        return null;
    }, [chartConfig?.chartCategories, shortLabels]);

    const handleLegendItemClick = (e, seriesItem, datasetItemIdx) => {
        let newDataset = dataset.map((series, datasetIdx) => {
            if (datasetIdx !== datasetItemIdx) {
                return series;
            }

            const chartConf = chartTypeConfig[series?.chartType];
            const seriesName =
                chartConf?.key === "bubble" ? "key" : chartConf?.name;

            const newSeriesData = series?.data?.map((item) => {
                if (item?.[seriesName] === seriesItem?.[seriesName]) {
                    item.visible = !item.visible;
                }
                return item;
            });
            return { ...series, data: newSeriesData };
        });

        setDataset(newDataset);
    };

    const handleLegendItem = (
        e,
        seriesItem,
        datasetItemIdx,
        seriesName,
        isHighlight = true
    ) => {
        const chartInstance =
            chartRef.current && chartRef.current.chartInstance;

        if (chartInstance) {
            if (dataset[0]?.chartType === "bubble") {
                chartInstance.toggleHighlight(
                    isHighlight,
                    (i) => i?.key === seriesItem?.key
                );
            } else {
                let i = 0;
                let seriesIdx = i;

                dataset.forEach((series, datasetIdx) => {
                    series?.data?.forEach((item) => {
                        if (
                            item?.[seriesName] === seriesItem?.[seriesName] &&
                            datasetIdx === datasetItemIdx
                        ) {
                            seriesIdx = i;
                        }
                        i++;
                    });
                });

                const thisSeries = chartInstance?.options?.series?.[seriesIdx];

                chartInstance.toggleHighlight(
                    isHighlight,
                    thisSeries?.[seriesName]
                );
            }

            /*
            chartInstance.toggleHighlight(
                true,
                (i) =>
                    i?.[datasetItemIdx]?.data?.[seriesName] ===
                    seriesItem?.[seriesName]
            );
            */

            /*
            chartInstance.showTooltip(
                (i) => i?.[seriesName] === seriesItem?.[seriesName]
            );
            chartInstance.toggleHighlight(true, i => i.category === "Starbucks");
            */
        }
    };

    return (
        <>
            <div className="chart-legend-set">
                {dataset?.map((series, datasetIdx) => {
                    const seriesName = chartTypeConfig[series?.chartType]?.name;
                    return series?.data?.map((item, idx) => {
                        return (
                            <span
                                className="chart-legend-item"
                                key={datasetIdx + "_" + idx}
                                style={{
                                    color: item.color,
                                    opacity: item.visible ? 1 : 0.5,
                                }}
                                onMouseEnter={(e) =>
                                    handleLegendItem(
                                        e,
                                        item,
                                        datasetIdx,
                                        seriesName
                                    )
                                }
                                onMouseLeave={(e) =>
                                    handleLegendItem(
                                        e,
                                        item,
                                        datasetIdx,
                                        seriesName,
                                        false
                                    )
                                }
                                onClick={(e) =>
                                    handleLegendItemClick(e, item, datasetIdx)
                                }
                            >
                                <span>{item?.[seriesName]}</span>
                            </span>
                        );
                    });
                })}
            </div>
            <Chart
                ref={chartRef}
                {...chartWrapperConfig?.chartConfig} /*onLegendItemHover={onLegendItemHover}*/
            >
                <ChartTitle text={chartConfig?.chartLabel || ""} />
                <ChartArea {...chartAreaProps} margin={10} />

                {/*<ChartLegend {...chartWrapperConfig?.chartLegend} height={50} />*/}
                <ChartLegend visible={false} />
                {categoryAxisItems}
                <ChartSeries>
                    {renderChart()}
                    <ChartXAxis>
                        <ChartXAxisItem
                            labels={{
                                content: shortLabels,
                            }}
                        />
                    </ChartXAxis>
                    <ChartYAxis>
                        <ChartYAxisItem
                            labels={{
                                content: shortLabels,
                            }}
                        />
                    </ChartYAxis>
                </ChartSeries>
            </Chart>
        </>
    );
};

/*
ChartWrapper.propTypes = {
    chartConfig: PropTypes.shape({
        chartLabel: PropTypes.string,
        chartCategories: PropTypes.any.isRequired,
        //mapChartType: PropTypes.func.isRequired,
    }).isRequired,
    data: PropTypes.any.isRequired,
};
*/

export default ChartWrapper;
