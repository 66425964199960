import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import DataTable from "src/components/dataTable";
import { providerPlanStatementSelector } from "src/redux/selectors/custom-selector";
import { GridColumn as Column } from "@progress/kendo-react-grid";
import { columnMenuProps } from "src/components/customColumnMenu";

const ProviderPlanSummaryHeader = ({ isExporting }) => {
  const [gridColumns, setGridColumns] = useState([]);
  const { providerPlanSummaryHeader } = useSelector(
    providerPlanStatementSelector,
    shallowEqual
  );

  const HeaderCell = (props) => {
    return (
      <span className="k-cell-inner">
        <span
          className={`k-link ${
            props.title.toLowerCase() === "utilization"
              ? "col-disabled-pointer-center"
              : "col-disabled-pointer-start"
          }`}
          style={{ cursor: "default" }}
        >
          <span
            data-field={props.field}
            className="k-column-title-export-header"
          >
            {props.title}
          </span>
        </span>
      </span>
    );
  };

  useEffect(() => {
    if (providerPlanSummaryHeader.columns.length > 0) {
      setGridColumns(providerPlanSummaryHeader.columns);
    }
  }, [providerPlanSummaryHeader]);

  const useCol = (columns) => {
    return columns.map((col, idx) => {
      if (!col.show || col.title === "") return false;
      return (
        <Column
          {...columnMenuProps(col)}
          headerCell={HeaderCell}
          key={idx}
          reorderable={false}
          children={col.childCols}
          resizable={false}
          filterable={false}
          width={130}
        />
      );
    });
  };

  const cellRender = (tdElement, cellProps) => {
    if (tdElement && cellProps.rowType === "data") {
      const dataVal = (
        <span title={cellProps.dataItem[cellProps.field]}>
          {cellProps.dataItem[cellProps.field]}
        </span>
      );
      return React.cloneElement(tdElement, tdElement.props, dataVal);
    }
    return tdElement;
  };

  let GroupColumn = useCol(gridColumns);

  return (
    <DataTable
      gridHeight=""
      cellRender={cellRender}
      initialLoading={true}
      columns={providerPlanSummaryHeader.columns}
      data={providerPlanSummaryHeader.data}
      customColumn={GroupColumn}
      id="provider-plan-summary-grid"
      isSortable={false}
      isPagination={false}
      isResizable={false}
      scrollable={isExporting ? "scrollable" : "scrollable"}

    />
  );
};

export default ProviderPlanSummaryHeader;
