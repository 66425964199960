// eslint-disable-next-line no-useless-concat
import { getter } from "@progress/kendo-react-common";
import {
  getSelectedState,
  GridColumn as Column,
} from "@progress/kendo-react-grid";
import * as React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { columnMenuProps } from "../../../../components/customColumnMenu";
import DataTable from "../../../../components/dataTable";
import { DialogComponent } from "../../../../components/dialog";
import { DEFAULT_SORT } from "../../../../constants/grid";
import {
  getUserCredential,
  getTenantCookieData,
} from "../../../../lib/cookies";
import { resultSettingSelector } from "../../../../redux/selectors/custom-selector";
import { getfilterDataString } from "../../../../utils/utils";
import useApi from "./service";
import { setResultSettingData } from "../../../../redux/actions/rule-library-actions";
import { HeaderPage } from "src/pages/base/headerPage";
import { CANCEL_BTN_ACTIONS, DELETE_BTN_ACTIONS } from "src/constants/constants";

const editField = "inEdit";
const SELECTED_FIELD = "selected";
const DATA_ITEM_KEY = "rate_table_mstr_key";
const idGetter = getter(DATA_ITEM_KEY);

const loadingPanel = (
  <div className="k-loading-mask">
    <span className="k-loading-text">Loading</span>
    <div className="k-loading-image"></div>
    <div className="k-loading-color"></div>
  </div>
);

export const RateTable = (restProps) => {
  const resultSettingReduxData = useSelector(
    resultSettingSelector,
    shallowEqual
  );
  const dispatch = useDispatch();
  const cookiesData = getUserCredential();
  const defaultPageSize = getTenantCookieData("record_per_page");
  const [list, Action] = useApi();
  const [deleteSelectedIds, setDeleteSelectedIds] = React.useState([]);
  const {
    data,
    columns,
    dataCount,
    setData,
    isAPICalling,
    setConfirmationPopup,
    confirmationPopup,
    setAPICallingState,
    initialLoading,
    isRefreshLoading,
  } = list;

  //state to set and get limit, offset
  const [pageData, setPage] = React.useState({
    skip: 0,
    take: defaultPageSize.recordTake,
  });

  const [pageSize, setPageSize] = React.useState(defaultPageSize);

  //state to set and get filter, sort number, sort order
  const [filterData, setFilterData] = React.useState({
    isSort: false,
    isFilter: false,
    data: "",
    in_sort_number: DEFAULT_SORT.number,
    sort_order: DEFAULT_SORT.order,
  });

  const [selectedState, setSelectedState] = React.useState({});
  const [gridColumns, setGridColumns] = React.useState([]);

  const apiCallData = {
    in_filter: null,
    in_sort_number: 1,
    in_sort_order: "ASC",
    in_db_role_key: cookiesData?.out_db_role_key,
  };

  React.useEffect(() => {
    if (list.loadingState.isDeleteData) {
      list.setLoadState({ ...list.loadingState, isDeleteData: false });
      onRefreshClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list.loadingState.isDeleteData]);

  React.useEffect(() => {
    if (columns.length > 0) {
      setGridColumns(columns);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);

  React.useEffect(() => {
    const dataVal = {
      ...apiCallData,
    };
    getGridData(dataVal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getInFilter = () => {
    return filterData.isFilter ? filterData.data : null;
  };

  const getInSortNumber = () => {
    return filterData.isSort ? filterData.in_sort_number : DEFAULT_SORT.number;
  };

  const getInSortOrder = () => {
    return filterData.isSort ? filterData.sort_order : DEFAULT_SORT.order;
  };

  const getPageTake = () => {
    return pageData.take;
  };

  const getPageSkip = () => {
    return pageData.skip;
  };

  const onSelectionChange = (event) => {
    const newSelectedState = getSelectedState({
      event,
      selectedState: selectedState,
      dataItemKey: DATA_ITEM_KEY,
    });
    setSelectedState(newSelectedState);
    let tempArray = [];
    let rateTableKeysArray = [];
    Object.keys(newSelectedState).map((item) => {
      if (newSelectedState[item] === false) {
        return 0;
      }
      let obj = {};
      obj.id = Number(item);
      obj.mstr_key = Number(item);
      tempArray.push(obj);
      rateTableKeysArray.push(obj);
      return 0;
    });
    setDeleteSelectedIds(tempArray);

    dispatch(
      setResultSettingData({
        resultSettingData: {
          ...resultSettingReduxData.resultSettingData,
          rate_table_name: data.find(
            (x) => x.rate_table_mstr_key === rateTableKeysArray[0].id
          ).rate_table_name,
          rate_table_key: rateTableKeysArray[0].id,
        },
      })
    );
  };

  const onCancelConfirm = (action) => {
    setConfirmationPopup({
      ...confirmationPopup,
      isVisible: false,
      type: "",
      actionBtn: [],
      data: {},
      action: "",
    });
    data.map((item) => {
      item.selected = false;
      return 0;
    });
    setData([...data]);
    if (action === "outSideClick") {
      // TODO: UNSAVED CHANGES
      // dispatch(setUnSavedChangesPopup({  ...unsavedPopup, isUnSavedChange: true }));
    }
  };

  const setPageMenuState = (response) => {
    if (!deleteSelectedIds.length) {
      return false;
    }

    let ids = response.map((el) => el.rate_table_master_key);

    let selectedKeys = [];
    let tempArray = [];
    let tempArrayData = [];

    Object.keys(selectedState).map((item) => {
      let obj = {};

      obj.id = Number(item);
      selectedKeys.push(item);
      tempArray.push(obj);

      return 0;
    });

    ids.map((item) => {
      if (selectedKeys.includes(item.toString())) {
        tempArrayData.push(item);
      }
      return 0;
    });
  };

  const handlePageChange = async (event) => {
    const pageData = event.page;

    setAPICallingState(true);

    setPage({ skip: pageData.skip, take: pageData.take });

    const dataVal = {
      ...apiCallData,
      in_filter: getInFilter(),
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
      in_page_row_offset: pageData.skip,
      in_page_rows: pageData.take,
    };

    getGridData(dataVal);
  };

  const handleColumnMenu = (columnMenuData, isFilter, isSort) => {
    let index = 1;
    let filterSkip = getPageSkip();
    let filterTake = getPageTake();

    if (isFilter) {
      filterSkip = 0;
      filterTake = pageSize.recordTake;

      //reset pager when filter applies
      setPage({ skip: filterSkip, take: filterTake });
    }

    if (data.length > 0 && columnMenuData[0] !== undefined) {
      let obj = Object.keys(data[0]);
      index = obj.findIndex((x) => x === columnMenuData[0].field) + 1;
    }

    const filterString = isFilter
      ? getfilterDataString(columnMenuData, gridColumns)
      : filterData.data;

    isFilter = false;
    if (filterString !== "") {
      isFilter = true;
    }

    let sortDir =
      filterData.isSort && columnMenuData.length
        ? filterData.sort_order
        : DEFAULT_SORT.order;
    let sortNumber =
      filterData.isSort && columnMenuData.length
        ? filterData.in_sort_number
        : DEFAULT_SORT.number;

    isSort = !columnMenuData.length ? false : filterData.isSort;

    if (
      columnMenuData[0] !== undefined &&
      columnMenuData[0].dir !== undefined
    ) {
      isSort = true;
      sortDir = columnMenuData[0].dir.toUpperCase();
      sortNumber = index;
    }

    const dataVal = {
      ...apiCallData,
      in_filter: isFilter ? filterString : null,
      in_sort_number: sortNumber,
      in_sort_order: sortDir,
      in_page_row_offset: filterSkip,
      in_page_rows: filterTake,
    };

    setFilterData({
      ...filterData,
      isFilter: isFilter,
      isSort: isSort,
      data: filterString,
      in_sort_number: sortNumber,
      sort_order: sortDir,
    });

    setAPICallingState(true);

    getGridData(dataVal);
  };
    const UnitTypeCell = React.useCallback(
      (props) => {
          const { ariaColumnIndex, columnIndex, dataItem, field, render } =
              props;

          let val = dataItem[field || ""];

          const nameField = field.replace("_mstr_key", "_name");
          if (field.indexOf("_mstr_key") !== -1) {
              val = dataItem[nameField || ""];
          }
          
          const defaultRendering = (
            <td
                  // onDoubleClick={}
                  aria-colindex={ariaColumnIndex}
                  data-grid-col-index={columnIndex}
                  >
                  <span title={val}>{val}</span>
              </td>
          );
          return defaultRendering
          return render?.call(undefined, defaultRendering, props);
      },
      []
  );

  const StringReadAndOnlyCell = React.useCallback((props) => {
    const { ariaColumnIndex, columnIndex, dataItem, field } = props;

    let val = dataItem[field || ""];

    const defaultRendering = (
        <td aria-colindex={ariaColumnIndex} data-grid-col-index={columnIndex}>
            <span title={val}>{val}</span>
        </td>
    );

    return defaultRendering;
  }, []);

  const useCol = (columns) => {
    return columns.map((column, idx) => {
      return column.show && column.title !== "" ? (
        <Column
          {...columnMenuProps(column)}
          key={idx}
          cell={
            column.field === "system_unit_mstr_key"
                ? UnitTypeCell
                : StringReadAndOnlyCell
          }
          sortable={true}
        />
      ) : (
        false
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  let GroupColumn = useCol(gridColumns);

  const onClearFilter = async () => {
    setAPICallingState(true);

    //set in_params
    const dataVal = {
      ...apiCallData,
      in_filter: null,
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
      in_page_row_offset: getPageSkip(),
      in_page_rows: getPageTake(),
    };

    //set filter to false
    setFilterData({
      ...filterData,
      data: "",
      isFilter: false,
    });

    getGridData(dataVal);
  };

  const getGridData = async (dataVal) => {
    let response = await Action.getListDataAsync(dataVal);
    setPageMenuState(response);
  };

  const onRefreshClick = () => {
    setAPICallingState(true);

    const dataVal = {
      ...apiCallData,
      in_filter: getInFilter(),
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
      in_page_row_offset: getPageSkip(),
      in_page_rows: getPageTake(),
    };
    getGridData(dataVal);
  };

  const remove = (ids) => {
    if(deleteSelectedIds?.length == 0){
      setConfirmationPopup({
        ...confirmationPopup,
        isVisible: true,
        type: "Message",
        actionBtn: CANCEL_BTN_ACTIONS(onCancelConfirm),
        title: "Warning",
        desc: "Please select a rate table record ?",
        type: "Action",
        action: "Delete",
    });
    return
    }
    setConfirmationPopup({
        ...confirmationPopup,
        isVisible: true,
        type: "DELETE_CONFIRM",
        actionBtn: DELETE_BTN_ACTIONS(onCancelConfirm, onDeleteConfirm),
        data: [],
        action: "Delete",
    });
};
const onDeleteConfirm = (event) => {
  const deleteArray = deleteSelectedIds.map((obj2) => {
      const obj1 = data.find(
          (elem) => elem.rate_table_mstr_key === obj2.mstr_key
      );
      return {
          mstr_key: obj2.mstr_key,
          rateTableName: obj1.rate_table_name,
          date_version: obj1.date_version,
      };
  });
  let deleteData = {
      in_user_id: cookiesData.out_user_id,
      in_tenant_id: cookiesData.out_tenant_id,
      in_version_data: deleteArray,
  };

  Action.deleteDataAsync(deleteData);

  setDeleteSelectedIds([]);
  // setErrorObj({});
  // restProps.setSelectedRowId("");
};
  const handleMenuItemClick = (action) => {
    switch (action) {
      case "delete":
        remove();
        break;
      default:
        break;
    }
  };
  const headerConfig = {
    title: "Rate Tables",
    id: "rate-tables",
    action_menu: {
      delete: { fx: handleMenuItemClick },
    },
  };
  return (
    <>
      {isRefreshLoading && loadingPanel}
      {columns.length > 0 ? (
        <>
          <div className="Main">
            <div className="content">
              <div className="content-head">
                <HeaderPage pageProps={restProps} headerConfig={headerConfig} />
              </div>
              <div className="content-body">
                <DataTable
                  isAPICalling={isAPICalling}
                  initialLoading={initialLoading}
                  data={data.map((item) => ({
                    ...item,
                    [SELECTED_FIELD]: selectedState[idGetter(item)],
                  }))}
                  id="group-grid"
                  editField={editField}
                  pageData={pageData}
                  dataCount={dataCount}
                  customColumn={GroupColumn}
                  columns={gridColumns}
                  dataItemKey={DATA_ITEM_KEY}
                  width={"auto"}
                  height={"400px"}
                  module={"group"}
                  defaultPageSize={defaultPageSize}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  handleColumnMenu={handleColumnMenu}
                  handlePageChange={handlePageChange}
                  refreshClick={onRefreshClick}
                  onClearFilter={onClearFilter}
                  onSelectionChange={onSelectionChange}
                  gridFooterClasses={"rateTableGridFooter"}
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <>{loadingPanel}</>
      )}
      <DialogComponent
        title={confirmationPopup.title}
        onCancelConfirm={onCancelConfirm}
        width={"450"}
        height={"auto"}
        className={"Dialog-Delete"}
        desc={confirmationPopup.desc}
        visible={confirmationPopup.isVisible}
        actionBtn={confirmationPopup.actionBtn}
        titleClassName={
          confirmationPopup.action === "Delete"
            ? "delete-confirm"
            : "unSave-confirm"
        }
      />
    </>
  );
};
