/* eslint-disable jsx-a11y/anchor-is-valid */
import { changeCancelBtnState, changeUpdateBtnState } from "../../utils/utils";
import React from "react";
import Popup from "../../components/customPopup";
import { HeaderPage } from "../base/headerPage";
import DtuBatchLogs from "../common/dtuBatchLogs";
import { GridButton, UserData } from "./userGrid";
import UploadDTU from "../user/uploadDtu";
import useServiceApi from "./service";
import { useDispatch } from "react-redux";
import {
  setUserStatusData,
  setRoleData,
} from "../../redux/actions/user-actions";
import AddNew from "./addNew";
import SendEmails from "./sendEmails";


const User = (pageProps) => {
  const [list, Action] = useServiceApi();
  const dispatch = useDispatch();
  const addRef = React.useRef(null);
  const [isAddNewConfig, setNewConfig] = React.useState(false);
  const [isShow, setShow] = React.useState({
    dtuLogs: false,
    sendEmails: false,
    dtuUpload: false,
    deleteRows: false,
    isAddNew: false,
  });
  const [exportDataClick, setExportDataClick] = React.useState(false);
  const [isAPICall, setAPICall] = React.useState(false);
  const [selectedRowId, setSelectedRowId] = React.useState("");
  const [dataList, setDataList] = React.useState([]);
  const [userEdit, setUserEdit] = React.useState(false);
  const [columnsData, setColumnsData] = React.useState([]);
  const [isCheckColumn, setColumnCheck] = React.useState(false);

  // let popupRef = React.useRef();
  const windowRef = React.useRef(null);

  // useDocumentClickEventListener(isAddNewConfig, setNewConfig, popupRef);

  React.useEffect(() => {
    Action.getUserStatusTypeDataAsync();
    Action.getDbRoleDataAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    dispatch(setUserStatusData({ userStatusData: list.originUserStatusType }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list.originUserStatusType]);

  React.useEffect(() => {
    dispatch(setRoleData({ roleData: list.originDbRole }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list.originDbRole]);

  React.useEffect(() => {
    if (userEdit === false) {
      changeCancelBtnState();
      changeUpdateBtnState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userEdit]);

  const addNewConfig = (e) => {
    setNewConfig(!isAddNewConfig);
    e.preventDefault();
  };

  const closePopup = (isGridRefresh) => {
    setNewConfig(false);
    if (isGridRefresh) {
      setAPICall(true);
    }
  };

  const AddNewPopup = () => {
    return (
      <Popup
        anchor={addRef.current}
        show={isAddNewConfig}
        setShow={setNewConfig}
        className="right0"
      >
        <div className="dot-dropdown-open dot-dropdown-open-Form">
          <div className="caption">
            <span>Add New User</span>
          </div>
          <AddNew closePopup={closePopup} />
        </div>
      </Popup>
    );
  };

  const AddNewLink = () => {
    return (
      <div>
        <a
          className="cta-small"
          ref={addRef}
          onClick={(e) => {
            //TODO: UNSAVED CHANGES
            // if(unsavedPopup.isUnSavedChange) {
            //     document.getElementById("grid-incell-cancel").click();
            //     dispatch(setUnSavedChangesPopup({...unsavedPopup, isVisible: true, callbackFn: () => {
            //         addNewConfig(e);
            //     }}))
            // } else {
            //     addNewConfig(e);
            // }
            addNewConfig(e);
          }}
        >
          <i className="fas fa-plus"></i> Add New User
        </a>
        {AddNewPopup()}
      </div>
    );
  };

  const handleMenuItemClick = (action) => {
    switch (action) {
      case "dtuLogs":
        setShow({
          ...isShow,
          dtuLogs: !isShow.dtuLogs,
        });
        break;
      case "export":
        setExportDataClick(true);
        break;
      case "sendEmails":
        setShow({
          ...isShow,
          sendEmails: !isShow.sendEmails,
        });
        break;
      case "delete":
        setShow({
          ...isShow,
          deleteRows: !isShow.deleteRows,
        });
        break;
      default:
        break;
    }
  };

  const handleUpload = (e) => {
    setShow({
      ...isShow,
      dtuUpload: !isShow.dtuUpload,
    });
    e.preventDefault();
  };

  const headerConfig = {
    title: "Users",
    id: "user",
    buttons: { template_fx: GridButton },
    add_new_fx: AddNewLink,
    upload_fx: handleUpload,
    action_menu: {
      export: { fx: handleMenuItemClick },
      dtuLog: { fx: handleMenuItemClick },
      delete: { fx: handleMenuItemClick },
      sendEmails: { fx: handleMenuItemClick },
    },
    manage_col: {
      columnsData: columnsData,
      setColumnsData: setColumnsData,
      setColumnCheck: setColumnCheck,
    },
  };

  const parent = document.querySelector("#content-" + headerConfig.id);

  if (parent) {
    const exportToExcel = parent.querySelector(".export-to-excel");
    const auditLog = parent.querySelector(".audit-log");
    const deleteRows = parent.querySelector(".delete-rows");

    exportToExcel && exportToExcel.disableKendoMenu();
    auditLog && auditLog.disableKendoMenu();
    deleteRows && deleteRows.disableKendoMenu();

    dataList.length && exportToExcel && exportToExcel.enableKendoMenu();

    if (selectedRowId !== "") {
      auditLog && auditLog.enableKendoMenu();
      deleteRows && deleteRows.enableKendoMenu();
    }
  }

  const onSelectionChange = (event) => { };

  return (
    <div className="Main">
      <div className="window-content" ref={windowRef} />
      <div className="content">
        <div className="content-head">
          <HeaderPage pageProps={pageProps} headerConfig={headerConfig} />
        </div>

        <div className="content-body userscont">
          <div className="dtu-file-container">
            <UserData
              isAPICall={isAPICall}
              setAPICall={setAPICall}
              setExportDataClick={setExportDataClick}
              exportDataClick={exportDataClick}
              selectedRowId={selectedRowId}
              setSelectedRowId={setSelectedRowId}
              setShow={setShow}
              isShow={isShow}
              setDataList={setDataList}
              isAddNewConfig={isAddNewConfig}
              pageProps={pageProps}
              userEdit={userEdit}
              setUserEdit={setUserEdit}
              columnsData={columnsData}
              setColumnsData={setColumnsData}
              setColumnCheck={setColumnCheck}
              isCheckColumn={isCheckColumn}
              onSelectionChange={onSelectionChange}
            />
          </div>
        </div>
      </div>
      {isShow.dtuLogs && (
        <DtuBatchLogs
          visible={isShow}
          setShow={setShow}
          module={"user"}
          pageProps={pageProps}
        />
      )}

      {isShow.dtuUpload && (
        <UploadDTU
          visible={isShow}
          setShow={setShow}
          title={"Upload Users"}
          pageId={"user"}
          setAPICall={setAPICall}
        />
      )}

      {isShow.sendEmails && (
        <SendEmails
          setShow={setShow}
          isShow={isShow}
          dropDownAppendTo={windowRef.current}
          setAPICall={setAPICall}
        />
      )}

      {isShow.isAddNew && <AddNew setShow={setShow} isShow={isShow} />}
    </div>
  );
};

export default React.memo(User);
