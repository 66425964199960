import {
  CLEAR_COMP_PLAN_STATE,
  PREFETCH_COMPPLAN_DROPDOWNS,
  SET_COMPPLAN_DATA,
  SET_COMPPLAN_KPI_PAYMENT_SUMMARY,
  SET_COMPPLAN_LOADING,
  SET_COMPPLAN_SECTION2_DATA,
  SET_COMPPLAN_SUMMARY_HEADER,
  SET_GROUP_LOADING,
  SET_GROUPS_DATA,
  SET_PLANPERIOD_DATA,
  SET_PLANPERIOD_LOADING,
  SET_PROVIDER_DATA,
  SET_PROVIDER_DETAILS_FLAG,
  SET_PROVIDER_LOADING,
  SET_SELECTED_COMPPLANS,
  SET_SELECTED_GROUPS,
  SET_SELECTED_PLAN_PERIOD,
  SET_SELECTED_PROVIDERS,
  SET_SELECTED_SPECIALITIES,
  SET_SHOW_COMP_PLAN_REPORT_RESULT,
  SET_SHOW_SECTION_1,
  SET_SHOW_SECTION_2,
  SET_SPCIALITY_LOADING,
  SET_SPECIALITIES_DATA,
} from "../actions/compplan-actions";

const initialData = {
  compPlanData: [],
  isCompPlanLoading: false,
  selectedCompPlan: null,

  compPlanPeriodData: [],
  isCompPlanPeriodLoading: false,
  selectedCompPlanPeriod: null,

  specialityData: [],
  isSpecialityLoading: false,
  selectedSpecialities: [],

  groupData: [],
  isGroupLoading: false,
  selectedGroups: [],

  providerData: [],
  isProviderLoading: false,
  selectedProviders: [],

  isProviderDetail: false,

  showSection1: false,
  showSection2: true,

  compPlanSummaryHeader: {
    columns: [],
    data: [],
  },
  kpiPaymentSummary: {
    columns: [],
    data: [],
  },
  compPlanSection2Data: {
    columns: [],
    data: [],
  },
  showReportResults: false,
  prefetchDropdowns: true,
};

const compPlanReducer = (state = initialData, action) => {
  switch (action.type) {
    case SET_COMPPLAN_DATA:
      return { ...state, compPlanData: action.data };
    case SET_COMPPLAN_LOADING:
      return { ...state, isCompPlanLoading: action.data };
    case SET_SELECTED_COMPPLANS:
      return { ...state, selectedCompPlan: action.data };

    case SET_PLANPERIOD_DATA:
      return { ...state, compPlanPeriodData: action.data };
    case SET_PLANPERIOD_LOADING:
      return { ...state, isCompPlanPeriodLoading: action.data };
    case SET_SELECTED_PLAN_PERIOD:
      return { ...state, selectedCompPlanPeriod: action.data };

    case SET_SPECIALITIES_DATA:
      return { ...state, specialityData: action.data };
    case SET_SPCIALITY_LOADING:
      return { ...state, isSpecialityLoading: action.data };
    case SET_SELECTED_SPECIALITIES:
      return { ...state, selectedSpecialities: action.data };

    case SET_GROUPS_DATA:
      return { ...state, groupData: action.data };
    case SET_GROUP_LOADING:
      return { ...state, isGroupLoading: action.data };
    case SET_SELECTED_GROUPS:
      return { ...state, selectedGroups: action.data };

    case SET_PROVIDER_DATA:
      return { ...state, providerData: action.data };
    case SET_PROVIDER_LOADING:
      return { ...state, isProviderLoading: action.data };
    case SET_SELECTED_PROVIDERS:
      return { ...state, selectedProviders: action.data };

    case SET_PROVIDER_DETAILS_FLAG:
      return { ...state, isProviderDetail: action.data };

    case SET_SHOW_SECTION_1:
      return { ...state, showSection1: action.data };
    case SET_SHOW_SECTION_2:
      return { ...state, showSection2: action.data };
    case SET_COMPPLAN_SUMMARY_HEADER:
      return {
        ...state,
        compPlanSummaryHeader: action.data,
        showReportResults: action.data ? true : state.showReportResults,
      };
    case SET_COMPPLAN_KPI_PAYMENT_SUMMARY:
      return {
        ...state,
        kpiPaymentSummary: action.data,
        showReportResults: action.data ? true : state.showReportResults,
      };
    case SET_COMPPLAN_SECTION2_DATA:
      return {
        ...state,
        compPlanSection2Data: { ...state.compPlanSection2Data, ...action.data },
        showReportResults: action.data ? true : state.showReportResults,
      };

    case SET_SHOW_COMP_PLAN_REPORT_RESULT:
      return {
        ...state,
        showReportResults: action.data,
        compPlanSummaryHeader: {
          columns: [],
          data: [],
        },
        kpiPaymentSummary: {
          columns: [],
          data: [],
        },
        compPlanSection2Data: {
          columns: [],
          data: [],
        },
      };

    case PREFETCH_COMPPLAN_DROPDOWNS:
      return {...state, prefetchDropdowns: action.data }

    case CLEAR_COMP_PLAN_STATE:
      return initialData;

    default:
      return state;
  }
};

export default compPlanReducer;
