import {
  CLEAR_REPORTS_COMP_DETAIL_RESULTS,
  CLEAR_REPORTS_STATE,
  PREFETCH_REPORT_DROPDOWNS,
  SET_COMP_PLAN_FILTER_DATA,
  SET_COMP_PLAN_FILTER_DATA_LOADING,
  SET_COMP_PLAN_FILTER_VALUE,
  SET_COMP_PLAN_PERIOD_FILTER_DATA,
  SET_COMP_PLAN_PERIOD_FILTER_DATA_LOADING,
  SET_COMP_PLAN_PERIOD_FILTER_VALUE,
  SET_EVENT_TYPE_FILTER_DATA,
  SET_EVENT_TYPE_FILTER_DATA_LOADING,
  SET_EVENT_TYPE_FILTER_VALUE,
  SET_IS_FROM_PROVIDER,
  SET_PERIOD_FILTER_DATA,
  SET_PERIOD_FILTER_DATA_LOADING,
  SET_PERIOD_FILTER_VALUE,
  SET_PROVIDER_FILTER_DATA,
  SET_PROVIDER_FILTER_DATA_LOADING,
  SET_PROVIDER_FILTER_VALUE,
  SET_REORDER_LOADING,
  SET_REPORT_INITIAL_LINK_OBJECT,
  SET_REPORTS_COMP_DETAIL_SECTION1_DATA,
  SET_REPORTS_COMP_DETAIL_SECTION1_DATA_LOADING,
  SET_REPORTS_COMP_DETAIL_SECTION2_DATA,
  SET_REPORTS_COMP_DETAIL_SECTION2_DATA_COLUMNS,
  SET_REPORTS_COMP_DETAIL_SECTION2_DATA_LOADING,
  SET_REPORTS_COMP_DETAIL_SECTION2_FILTER,
  SET_REPORTS_COMP_DETAIL_SECTION2_FILTER_STRING,
  SET_REPORTS_COMP_DETAIL_SECTION2_GROUP_COLLAPSED_STATE,
  SET_REPORTS_COMP_DETAIL_SECTION2_PAGE,
  SET_REPORTS_COMP_DETAIL_SECTION2_PAGE_SIZE,
  SET_REPORTS_COMP_DETAIL_SECTION2_SORT,
  SET_REPORTS_COMP_DETAIL_SECTION2_SORT_NUMBER,
  SET_SHOW_REPORTS_COMP_DETAIL_SECTION,
} from "../actions/reports-actions";

const initialState = {
  compPlanFilterData: [],
  compPlanFilterValue: null,
  isCompPlanFilterDataLoading: false,
  compPlanPeriodFilterData: [],
  compPlanPeriodFilterValue: null,
  isCompPlanPeriodFilterDataLoading: false,
  providerFilterData: [],
  providerFilterValue: null,
  isProviderFilterDataLoading: false,
  periodFilterData: [],
  periodFilterValue: null,
  isPeriodFilterDataLoading: false,
  eventTypeFilterData: [],
  eventTypeFilterValue: null,
  isEventTypeFilterDataLoading: false,
  isReportsFiltersLoading: false,
  showReportsResults: false,
  showReportsCompDetailSection1: false,
  showReportsCompDetailSection2: true,
  reportsCompDetailSection1Data: null,
  isReportsCompDetailSection1DataLoading: false,
  reportsCompDetailSection2Data: [],
  reportsCompDetailSection2DataColumns: [],
  reportsCompDetailSection2DataTotalRows: 0,
  reportsCompDetailSection2PageOffset: 0,
  reportsCompDetailSection2PageSize: null,
  reportsCompDetailSection2Sort: [],
  reportsCompDetailSection2SortNumber: "1 ASC",
  reportsCompDetailSection2FilterString: null,
  reportsCompDetailSection2Filter: { logic: "and", filters: [] },
  isReportsCompDetailSection2DataLoading: false,
  reportsCompDetailSection2GroupCollapsedSate: [],
  isReorderLoading: false,
  isFromProvider: false,
  prefetchDropdowns: true, 
};

const reportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COMP_PLAN_FILTER_DATA:
      return {
        ...state,
        compPlanFilterData: action.data.compPlanFilterData,
        compPlanFilterValue: action.data.compPlanFilterValue,
        isCompPlanFilterDataLoading: false,
      };
    case SET_COMP_PLAN_FILTER_VALUE:
      return {
        ...state,
        compPlanFilterValue: action.data,
      };
    case SET_COMP_PLAN_FILTER_DATA_LOADING:
      return {
        ...state,
        isCompPlanFilterDataLoading: action.data,
      };
    case SET_COMP_PLAN_PERIOD_FILTER_DATA:
      return {
        ...state,
        compPlanPeriodFilterData: action.data.compPlanPeriodFilterData,
        compPlanPeriodFilterValue: action.data.compPlanPeriodFilterValue,
        isCompPlanPeriodFilterDataLoading: false,
      };
    case SET_COMP_PLAN_PERIOD_FILTER_VALUE:
      return {
        ...state,
        compPlanPeriodFilterValue: action.data,
      };
    case SET_COMP_PLAN_PERIOD_FILTER_DATA_LOADING:
      return {
        ...state,
        isCompPlanPeriodFilterDataLoading: action.data,
      };
    case SET_PROVIDER_FILTER_DATA:
      return {
        ...state,
        providerFilterData: action.data.providerFilterData,
        providerFilterValue: action.data.providerFilterValue,
        isProviderFilterDataLoading: false,
      };
    case SET_PROVIDER_FILTER_VALUE:
      return {
        ...state,
        providerFilterValue: action.data,
      };
    case SET_PROVIDER_FILTER_DATA_LOADING:
      return {
        ...state,
        isProviderFilterDataLoading: action.data,
      };
    case SET_PERIOD_FILTER_DATA:
      return {
        ...state,
        periodFilterData: action.data.periodFilterData,
        periodFilterValue: action.data.periodFilterValue,
        isPeriodFilterDataLoading: false,
      };
    case SET_PERIOD_FILTER_VALUE:
      return {
        ...state,
        periodFilterValue: action.data,
      };
    case SET_PERIOD_FILTER_DATA_LOADING:
      return {
        ...state,
        isPeriodFilterDataLoading: action.data,
      };
    case SET_EVENT_TYPE_FILTER_DATA:
      return {
        ...state,
        eventTypeFilterData: action.data.eventTypeFilterData,
        eventTypeFilterValue: action.data.eventTypeFilterValue,
        isEventTypeFilterDataLoading: false,
      };
    case SET_EVENT_TYPE_FILTER_VALUE:
      return {
        ...state,
        eventTypeFilterValue: action.data,
      };
    case SET_EVENT_TYPE_FILTER_DATA_LOADING:
      return {
        ...state,
        isEventTypeFilterDataLoading: action.data,
      };

    case SET_SHOW_REPORTS_COMP_DETAIL_SECTION:
      let newState = { ...state };
      if (action.data.section === 1) {
        newState = {
          ...newState,
          showReportsCompDetailSection1: action.data.flag,
        };
      }
      if (action.data.section === 2) {
        newState = {
          ...newState,
          showReportsCompDetailSection2: action.data.flag,
        };
      }
      return newState;
    case SET_REPORTS_COMP_DETAIL_SECTION1_DATA:
      return {
        ...state,
        reportsCompDetailSection1Data: action.data,
        showReportsResults: action.data ? true : state.showReportsResults,
        isReportsCompDetailSection1DataLoading: false,
      };
    case SET_REPORTS_COMP_DETAIL_SECTION1_DATA_LOADING:
      return {
        ...state,
        isReportsCompDetailSection1DataLoading: action.data,
      };
    case SET_REPORTS_COMP_DETAIL_SECTION2_DATA:
      return {
        ...state,
        reportsCompDetailSection2Data: action.data.data,
        showReportsResults:
          action.data.columns && action.data.columns.length > 0
            ? true
            : state.showReportsResults,
        reportsCompDetailSection2DataColumns: action.data.columns
          ? action.data.columns
          : state.reportsCompDetailSection2DataColumns,
        reportsCompDetailSection2DataTotalRows: action.data.totalRows,
        isReportsCompDetailSection2DataLoading: false,
      };
    case SET_REPORTS_COMP_DETAIL_SECTION2_DATA_COLUMNS:
      return {
        ...state,
        reportsCompDetailSection2DataColumns: action.data,
      };
    case SET_REPORTS_COMP_DETAIL_SECTION2_DATA_LOADING:
      return {
        ...state,
        isReportsCompDetailSection2DataLoading: action.data,
      };
    case SET_REPORTS_COMP_DETAIL_SECTION2_PAGE:
      return {
        ...state,
        reportsCompDetailSection2PageOffset: action.data.skip,
        reportsCompDetailSection2PageSize: action.data.pageSize,
      };
    case SET_REORDER_LOADING:
      return { ...state, isReorderLoading: action.data };

    case SET_REPORTS_COMP_DETAIL_SECTION2_PAGE_SIZE:
      return {
        ...state,
        reportsCompDetailSection2PageSize: action.data,
      };
    case SET_REPORTS_COMP_DETAIL_SECTION2_SORT:
      return {
        ...state,
        reportsCompDetailSection2Sort: action.data,
      };
    case SET_REPORTS_COMP_DETAIL_SECTION2_SORT_NUMBER:
      return {
        ...state,
        reportsCompDetailSection2SortNumber: action.data,
      };
    case SET_REPORTS_COMP_DETAIL_SECTION2_FILTER_STRING:
      return {
        ...state,
        reportsCompDetailSection2FilterString: action.data,
      };

    case SET_REPORTS_COMP_DETAIL_SECTION2_FILTER:
      return {
        ...state,
        reportsCompDetailSection2Filter: action.data,
      };
    case SET_REPORTS_COMP_DETAIL_SECTION2_GROUP_COLLAPSED_STATE:
      return {
        ...state,
        reportsCompDetailSection2GroupCollapsedSate: action.data,
      };
    case CLEAR_REPORTS_STATE:
      return initialState;

    case SET_REPORT_INITIAL_LINK_OBJECT:
      return { ...state, ...action.data };

    case SET_IS_FROM_PROVIDER:
      return { ...state, isFromProvider: action.data};

    case CLEAR_REPORTS_COMP_DETAIL_RESULTS:
      return {
        ...state,
        reportsCompDetailSection1Data: null,
        reportsCompDetailSection2Data: [],
        reportsCompDetailSection2DataColumns: [],
        reportsCompDetailSection2DataTotalRows: 0,
        reportsCompDetailSection2PageOffset: 0,
        reportsCompDetailSection2PageSize: action.data,
        reportsCompDetailSection2Sort: [],
        reportsCompDetailSection2FilterString: null,
        reportsCompDetailSection2Filter: { logic: "and", filters: [] },
        showReportsResults: false,
        showReportsCompDetailSection2: true,
        reportsCompDetailSection2SortNumber: "1 ASC",
        reportsCompDetailSection2GroupCollapsedSate: [],
      };

      case PREFETCH_REPORT_DROPDOWNS: 
      return {...state, prefetchDropdowns: action.data};
    default:
      return state;
  }
};

export default reportsReducer;
