import React from "react";
import {
    SELECT_JOB_ENGINE_PHASE_ASSIGNMENT,
    UPDATE_JOB_ENGINE_PHASE_ASSIGNMENT,
} from "../../../api-setup/api-endpoints";
import { getUserCredential } from "../../../lib/cookies";
import { POSTAPI, errStatusHandler } from "../../../api-setup/api-helper";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { setNewMessage } from "../../../redux/actions/setting-actions";

export default function DataRows() {
    const cookiesData = getUserCredential();
    const header = {
        "x-access-token": cookiesData.secretToken,
    };
    const history = useHistory();
    const dispatch = useDispatch();
    const [data, setData] = React.useState([]);
    const [columns, setColumns] = React.useState([]);
    const [dataCount, setDataCount] = React.useState(0);
    const [assigndataCount, setAssignDataCount] = React.useState(0);
    const [assignData, setassignData] = React.useState([]);
    const [isAPICalling, setAPICallingState] = React.useState(false);
    const [isAssignAPICalling, setAssignAPICallingState] =
        React.useState(false);

    const [isSaveData, setSaveData] = React.useState(false);

    const getListDataAsync = (listData) => {
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                SELECT_JOB_ENGINE_PHASE_ASSIGNMENT,
                null,
                header,
                listData,
                dispatch
            ).then((res) => {
                if (res) {
                    let userData = [];
                    if (res.data.out_parameters.out_ret_code === 0) {
                        userData = JSON.parse(
                            JSON.stringify(res.data.row_result)
                        );

                        userData.map((item, idx) => {
                            item.rowId = idx;
                            item.selected = false;
                            item.unassigned = true;
                            item.class = "";
                            return 0;
                        });
                    }
                    setData(userData);
                    setAPICallingState(false);
                    resolve(userData);
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };
    const getAssignListDataAsync = (listData) => {
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                SELECT_JOB_ENGINE_PHASE_ASSIGNMENT,
                null,
                header,
                listData,
                dispatch
            ).then((res) => {
                if (res) {
                    let userData = [];
                    if (res.data.out_parameters.out_ret_code === 0) {
                        userData = JSON.parse(
                            JSON.stringify(res.data.row_result)
                        );

                        userData.map((item, idx) => {
                            item.rowId = idx;
                            item.selected = false;
                            item.unassigned = false;
                            return 0;
                        });
                    }
                    setassignData(userData);
                    setAssignDataCount(res.data.out_parameters.out_total_rows);
                    setAssignAPICallingState(false);
                    resolve(userData);
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    const assignEnginePhaseAsync = (bodyData) => {
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                UPDATE_JOB_ENGINE_PHASE_ASSIGNMENT,
                null,
                header,
                bodyData,
                dispatch
            ).then((res) => {
                if (
                    res &&
                    res.data.out_parameters.out_ret_code === 0
                    // res.data.out_parameters.out_ret_code === 2)
                ) {
                    resolve(true);
                } else {
                    dispatch(
                        setNewMessage({
                            message: res?.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    resolve([]);
                    errStatusHandler(res, history, dispatch);
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );

                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    return [
        {
            data,
            columns,
            dataCount,
            setData,
            setColumns,
            isAPICalling,
            setAPICallingState,
            setSaveData,
            isSaveData,
            isAssignAPICalling,
            setAssignAPICallingState,
            assignData,
            setassignData,
            assigndataCount,
            setAssignDataCount,
            setDataCount
        },
        {
            getListDataAsync,
            assignEnginePhaseAsync,
            getAssignListDataAsync,
        },
    ];
}
