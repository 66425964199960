import React from "react";
import { DELETE_RATE_TABLE, GET_RATE_TABLE } from "../../../../api-setup/api-endpoints";
import { getUserCredential } from "../../../../lib/cookies";
import { POSTAPI, errStatusHandler } from "../../../../api-setup/api-helper";
import { GridColumnDataMapper } from "../../../../utils/utils";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { setNewMessage } from "../../../../redux/actions/setting-actions";

export default function RateTables() {
  const cookiesData = getUserCredential();
  const header = {
    "x-access-token": cookiesData.secretToken,
  };
  const history = useHistory();
  const dispatch = useDispatch();
  const [data, setData] = React.useState([]);
  const [columns, setColumns] = React.useState([]);
  const [dataCount, setDataCount] = React.useState(0);
  const [loadingState, setLoadState] = React.useState({
    isSaveData: undefined,
    isDeleteData: undefined,
  });

  const [initialLoading, setLoadingState] = React.useState(false);
  const [editData, setEditData] = React.useState("");
  const [confirmationPopup, setConfirmationPopup] = React.useState({
    title: "Delete",
    desc: "Are you sure you want to delete this record?",
    isVisible: false,
    type: "",
    actionBtn: [],
    data: {},
    action: "Delete",
  });
  const [originalData, setOriginalData] = React.useState([]);
  const [isAPICalling, setAPICallingState] = React.useState(false);
  const [isRefreshLoading, setRefreshLoading] = React.useState(false);

  const getListDataAsync = (listData) => {
    return new Promise(function (resolve, reject) {
      POSTAPI(false, GET_RATE_TABLE, null, header, listData, dispatch).then(
        (res) => {
          if (res) {
            if (res.data.out_parameters.out_ret_code > 0) {
              setLoadingState(true);
              errStatusHandler(res, history, dispatch);
            }
            setAPICallingState(false);
            setLoadingState(true);
            setEditData("");
            setConfirmationPopup({
              ...confirmationPopup,
              isVisible: false,
              type: "",
              actionBtn: [],
              data: {},
              action: "",
            });
            let userData = JSON.parse(JSON.stringify(res.data.row_result));

            const columData = GridColumnDataMapper(
              res.data.out_parameters.out_column_xml
            );

            setColumns(columData);

            userData.map((item, idx) => {
              item.rowId = idx;
              item.selected = false;
              return 0;
            });
            setData(userData);
            setDataCount(res.data.out_parameters.out_total_rows);
            setRefreshLoading(false);
            resolve(userData);
          }
        }
      );
    })
      .then((res) => {
        return res;
      })
      .catch((res) => {
        if (res.data.out_parameters.out_ret_code > 0) {
          dispatch(
            setNewMessage({
              message: res.data.out_parameters.out_error_msg,
              type: "Error",
              show: true,
            })
          );
          errStatusHandler(res, history, dispatch);
        }
      });
  };
  const deleteDataAsync = async (bodyData) => {
    setRefreshLoading(true);
    return new Promise(function (resolve, reject) {
        POSTAPI(
            false,
            DELETE_RATE_TABLE,
            null,
            header,
            bodyData,
            dispatch
        ).then((res) => {
            setRefreshLoading(false);
            if (res.data.out_parameters.out_ret_code > 0) {
                let msg = res.data.out_parameters.out_error_msg;

                setLoadState({ ...loadingState, isDeleteData: false });
                setConfirmationPopup({
                    ...confirmationPopup,
                    isVisible: false,
                    type: "",
                    actionBtn: [],
                    data: {},
                    action: "",
                });
                dispatch(
                    setNewMessage({
                        message: msg,
                        type: "Error",
                        show: true,
                    })
                );
            } else {
                setConfirmationPopup({
                    ...confirmationPopup,
                    isVisible: false,
                    type: "",
                    actionBtn: [],
                    data: {},
                    action: "",
                });
                setLoadState({ ...loadingState, isDeleteData: true });
                setTimeout(function () {
                    resolve("success");
                });
            }
        });
    })
        .then((res) => {
            return res;
        })
        .catch((res) => {
            if (res.data.out_parameters.out_ret_code > 0) {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                errStatusHandler(res, history, dispatch);
            }
        });
};

  return [
    {
      data,
      columns,
      dataCount,
      setData,
      setColumns,
      editData,
      originalData,
      isAPICalling,
      setEditData,
      confirmationPopup,
      setConfirmationPopup,
      initialLoading,
      setAPICallingState,
      loadingState,
      setLoadState,
      setOriginalData,
      setRefreshLoading,
      isRefreshLoading,
    },
    {
      getListDataAsync,
      deleteDataAsync,
    },
  ];
}
