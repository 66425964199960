import { GET_PROGRESS_PERCENTAGE } from "../actions/jobexecution-actions";
import { SET_PROGRESS_END_TIME } from "../actions/jobexecution-actions";
import { SET_PROGRESS_STATUS } from "../actions/jobexecution-actions";
import { SET_PROGRESS_COMPLETE } from "../actions/jobexecution-actions";





const jobexecution = (state = {
    dataProgressPercentage: null,
    dataProgressendTime: 0,
    dataProgressStatus:null,
    dataProgressComplete:null

}, action) => {
    switch (action.type) {
        case GET_PROGRESS_PERCENTAGE:
            state = {
                ...state,
                dataProgressPercentage: action.dataProgressPercentage
            };
            break;
            case SET_PROGRESS_END_TIME:
                return {
                  ...state,
                  dataProgressendTime: action.dataProgressendTime,
                };
            case SET_PROGRESS_STATUS:
                return {
                    ...state,
                    dataProgressStatus: action.dataProgressStatus,
                };   
            case SET_PROGRESS_COMPLETE:
                return {
                    ...state,
                    dataProgressComplete: action.dataProgressComplete,
                };       
        default:
            break;
    }
    return state
}

export default jobexecution;

