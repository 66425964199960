import {
  SET_PRORATION_DATA,
  SET_EXECUTION_DATA,
  SET_RESULT_SETTING_DATA,
  SET_RESET_STATE,
  SET_ALL_DATA,
  SET_COMP_RULE_UPDATE,
  SET_RULE_FILTER_ITEMS_DATA,
} from "../actions/rule-library-actions";

const rulesLibrary = (
  state = {
    rule_name: "",
    isUpdateRuleClick: false,
    in_prorate_udf_data_obj_key: 0,
    in_prorate_flag: false,
    in_prorate_period_type_mstr_key: 0,
    rule_filter_desc: "",
    rule_filter_text: null,
    formula_filter_text: "",
    ruleFilterItems: [],
    is_clear_event_flag: 0,
    in_udf_data_obj_key: 0,
    executionData: {
      generate_zero_result: 0,
      generate_quarter_total: 0,
      generate_year_total: 0,
      generate_qtd_value: 0,
      generate_ytd_value: 0,
      gl_mstr_key: 0,
      is_pass_thru: 1,
      period_type_mstr_key: 0,
    },
    resultSettingData: {
      result_name: "",
      value_fx: "",
      value: "",
      value_unit_type: "",
      is_enable: false,
      result_name_key: "",
      rate_table_name: "",
      rate_table_key: "",
      in_rate_type_mstr_key: "",
      is_kpi_wt_applied: 0,
    },

    systemFxDropdownList: [],
  },
  action
) => {
  switch (action.type) {
    
    case SET_PRORATION_DATA:
      state = {
        ...state,
        in_prorate_flag: action.data.in_prorate_flag,
        in_prorate_udf_data_obj_key: action.data.in_prorate_udf_data_obj_key,
        in_prorate_period_type_mstr_key:
          action.data.in_prorate_period_type_mstr_key,
        in_udf_data_obj_key: action.data.in_udf_data_obj_key,
        is_clear_event_flag:action.data.is_clear_event_flag,
        // rule_filter_desc: action.data.rule_filter_desc
        //   ? action.data.rule_filter_desc
        //   : state.rule_filter_desc,
        rule_filter_desc: action?.data?.rule_filter_desc
      };
      break;
    case SET_EXECUTION_DATA:
      state = {
        ...state,
        executionData: action.data.executionData,
      };
      break;
    case SET_RESULT_SETTING_DATA:
      state = {
        ...state,
        resultSettingData: action.data.resultSettingData,
      };
      break;
    case SET_RESET_STATE:
      state = {
        ...state,
        resultSettingData: state.resultSettingData,
        executionData: state.executionData,
        isUpdateRuleClick: false,
        in_prorate_udf_data_obj_key: 0,
        in_prorate_flag: false,
        in_prorate_period_type_mstr_key: 0,
        is_clear_event_flag: 0,
        in_rate_type_mstr_key: state.rate_type_mstr_key,
      };
      break;
    case SET_ALL_DATA:
      state = { ...state, ...action.data };
      break;
    case SET_COMP_RULE_UPDATE:
      state = {
        ...state,
        isUpdateRuleClick: action.data.isUpdateRuleClick,
      };
      break;

    case SET_RULE_FILTER_ITEMS_DATA:
      state = { ...state, ruleFilterItems: action.data };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default rulesLibrary;
