import { useEffect, useState } from "react";
import CustomDropdown from "../../../../components/customDropdown";
import { GetRateTypeData, GetUnitType } from "../../../../lib/common-service";
import { Label } from "@progress/kendo-react-labels";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Checkbox, Input, NumericTextBox } from "@progress/kendo-react-inputs";
import { setResultSettingData } from "../../../../redux/actions/rule-library-actions";
import {
    changeCancelBtnState,
    changeUpdateBtnState,
    isNullOrEmptyUndefined,
} from "../../../../utils/utils";
import { DialogComponent } from "../../../../components/dialog";
import ResultName from "../../../resultType/index";
import {
    resultSettingSelector,
    updateRuleSelector,
} from "../../../../redux/selectors/custom-selector";
import { RateTable } from "./rateTable";
import { FormulaMaster } from "../../../formula-master/formula-master";
import { numericFloatRegex } from "../../../../utils/regex";
import { useRef } from "react";
import { setUnitTypeData } from "src/redux/actions/setting-actions";

const CHECKBOX_ARRAY = [
    {
        label: "Enable",
        key: "enable",
    },
];

const ResultSetting = (restProps) => {
    const resultNameRef = useRef();
    const dispatch = useDispatch();
    const formulaRef = useRef();
    const resultSettingReduxData = useSelector(
        resultSettingSelector,
        shallowEqual
    );
    const isUpdateRuleClick = useSelector(updateRuleSelector, shallowEqual);
    const [unitTypeFields, setUnitTypeFields] = useState({
        unitDataValue: {
            system_unit_mstr_key: "",
            in_period_start_date: "",
            in_period_end_date: "",
        },
        unitData: [],
        isUnitTypeLoading: true,
    });
    const [rateTypeFields, setRateTypeFields] = useState({
        rateDataValue: {
            in_rate_type_mstr_key: "",
            in_rate_type_name: "",
        },
        rateData: [],
        isRateTypeLoading: true,
    });
    const [useTargetFields, setUseTargetFields] = useState({
        useTargetDataValue: {
            in_rate_type_mstr_key: "",
            in_rate_type_name: "",
        },
        useTargetData: [
            {
                rate_type_mstr_key: 0,
                rate_type_name: "Select Target",
                rate_type_desc: "",
            },
            {
                rate_type_mstr_key: 1,
                rate_type_name: "Quality Payment",
                rate_type_desc: "",
            },
            {
                rate_type_mstr_key: 2,
                rate_type_name: "Guarantee Payment",
                rate_type_desc: "",
            },
        ],
        isUseTargetLoading: false,
        useTargetDataValue: "",
    });

    const [rateTypeList, setRateTypeList] = useState([]);
    const [useTargetList, setUseTargetList] = useState([
        {
            rate_type_mstr_key: 0,
            rate_type_name: "Select Target",
            rate_type_desc: "",
        },
        {
            rate_type_mstr_key: 1,
            rate_type_name: "Quality Payment",
            rate_type_desc: "",
        },
        {
            rate_type_mstr_key: 2,
            rate_type_name: "Guarantee Payment",
            rate_type_desc: "",
        },
    ]);
    const [unitList, setUnitDataList] = useState([]);
    const [formFields, setFormFields] = useState({
        result_name: "",
        value_fx: "",
        value: "",
        value_unit_type: "",
        is_enable: false,
        is_kpi_Weight: false,
    });
    const [openPopup, setOpenPopup] = useState({
        isShowResultName: false,
        isShowRateTable: false,
        isShowFormulaMaster: false,
    });
    useEffect(() => {
        getDataTypeList();
        getRateTypeList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getRateTypeList = async () => {
        await getRateTypeListData();
    };

    const getRateTypeListData = async () => {
        let data = {
            in_filter: null,
            in_sort_number: 1,
            in_sort_order: "ASC",
            in_page_row_offset: 0,
            in_page_rows: 9999,
        };
        let res = await GetRateTypeData(data, dispatch);
        setRateTypeList(res);
        setRateTypeFields({
            ...rateTypeFields,
            rateData: res,
            rateDataValue: res[1],
            isRateTypeLoading: false,
        });
    };

    useEffect(() => {
        if (
            resultSettingReduxData?.resultSettingData &&
            rateTypeList.length > 0
        ) {
            setRateTypeFields({
                ...rateTypeFields,
                isRateTypeLoading: false,
                rateDataValue:
                    resultSettingReduxData?.resultSettingData
                        .in_rate_type_mstr_key > 0
                        ? rateTypeList.find(
                            (x) =>
                                x.rate_type_mstr_key ===
                                resultSettingReduxData.resultSettingData
                                    .in_rate_type_mstr_key
                        )
                        : rateTypeList[1],
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resultSettingReduxData?.resultSettingData, rateTypeList]);

    useEffect(() => {
        if (resultSettingReduxData?.resultSettingData && unitList.length > 0) {
            setFormFields({
                ...formFields,
                result_name:
                    resultSettingReduxData?.resultSettingData.result_name,
                value_fx:
                    resultSettingReduxData?.resultSettingData.formula_name ===
                        null
                        ? ""
                        : resultSettingReduxData?.resultSettingData
                            .formula_name,
                value: resultSettingReduxData?.resultSettingData.value,
            });
            setUnitTypeFields({
                ...unitTypeFields,
                isUnitTypeLoading: false,
                unitDataValue:
                    resultSettingReduxData?.resultSettingData.value_unit_type >
                        0
                        ? unitList.find(
                            (x) =>
                                x.system_unit_mstr_key ===
                                resultSettingReduxData.resultSettingData
                                    .value_unit_type
                        )
                        : unitList[0],
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [unitList, resultSettingReduxData?.resultSettingData]);

    useEffect(() => {
        if (resultSettingReduxData?.resultSettingData) {
            const defaultUnitType =
                unitTypeFields?.unitData?.[0]?.system_unit_mstr_key;

            if (!resultSettingReduxData?.resultSettingData.value_unit_type) {
                resultSettingReduxData.resultSettingData.value_unit_type =
                    defaultUnitType;
            }
            setFormFields({
                ...formFields,
                result_name:
                    resultSettingReduxData?.resultSettingData.result_name ===
                        null
                        ? ""
                        : resultSettingReduxData?.resultSettingData.result_name,
                rate_table_name:
                    resultSettingReduxData?.resultSettingData
                        .rate_table_name === null
                        ? ""
                        : resultSettingReduxData?.resultSettingData
                            .rate_table_name,
                value_fx:
                    resultSettingReduxData?.resultSettingData.formula_name ===
                        null
                        ? ""
                        : resultSettingReduxData?.resultSettingData
                            .formula_name,
                value: resultSettingReduxData?.resultSettingData.value === null
                    ? ""
                    : resultSettingReduxData?.resultSettingData.value,
                value_unit_type:
                    resultSettingReduxData?.resultSettingData.value_unit_type,
                is_kpi_Weight:
                    resultSettingReduxData?.resultSettingData.is_kpi_wt_applied,
                is_enable:
                    resultSettingReduxData?.resultSettingData.value !== "" &&
                        resultSettingReduxData?.resultSettingData.value !== null && resultSettingReduxData?.resultSettingData.value !== 0
                        ? true
                        : false,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resultSettingReduxData?.resultSettingData ,isUpdateRuleClick]);

    const getDataTypeList = async () => {
        await getDataTypes();
    };

    /**
     * Get Periods Data Type
     */
    const getDataTypes = async () => {
        let data = {
            in_filter: "AND (a.system_unit_name NOT LIKE 'fdm_%')",
            in_sort_number: null,
            in_sort_order: null,
            in_page_row_offset: 0,
            in_page_rows: 9999,
        };
        let res = await GetUnitType(data, dispatch);
        if (res.length > 0) {
            setUnitDataList(res);
            dispatch(setUnitTypeData(res));
            setUnitTypeFields({
                ...unitTypeFields,
                unitData: res,
                unitDataValue:
                    resultSettingReduxData?.resultSettingData.value_unit_type >
                        0
                        ? res.find(
                            (x) =>
                                x.system_unit_mstr_key ===
                                resultSettingReduxData.resultSettingData
                                    .value_unit_type
                        )
                        : res[0],
                isUnitTypeLoading: false,
            });
        } else {
            setUnitTypeFields({
                ...unitTypeFields,
                isUnitTypeLoading: false,
            });
        }
    };

    const handleCheckBoxChange = (e, module) => {
        setFormFields({
            ...formFields,
            [module]: e.target.value,
        });

        if (module === "is_kpi_Weight") {
            dispatch(
                setResultSettingData({
                    resultSettingData: {
                        ...resultSettingReduxData.resultSettingData,
                        is_kpi_wt_applied: e.target.value,
                    },
                })
            );
        }
        restProps?.onDataChange?.();
        changeCancelBtnState("enable", "grid-incell-cancel-comp-rule");
        changeUpdateBtnState("enable", "grid-incell-update-comp-rule");
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormFields({
            ...formFields,
            [name]: value,
        });
        dispatch(
            setResultSettingData({
                resultSettingData: {
                    ...resultSettingReduxData.resultSettingData,
                    [name]: value,
                },
            })
        );
        restProps?.onDataChange?.();
        changeCancelBtnState("enable", "grid-incell-cancel-comp-rule");
        changeUpdateBtnState("enable", "grid-incell-update-comp-rule");
    };

    const handleModuleClick = (module) => {
        if (module === "result_name") {
            // TODO: Unsaved changes
            //dispatch(setUnSavedChangesPopup({ isUnSavedChange: false }));
            setOpenPopup({
                ...openPopup,
                isShowResultName: true,
            });
        } else if (module === "rate_table") {
            // TODO: Unsaved changes
            // dispatch(setUnSavedChangesPopup({ isUnSavedChange: false }));
            setOpenPopup({
                ...openPopup,
                isShowRateTable: true,
            });
        } else if (module === "flat_rate") {
            // TODO: Unsaved changes
            // dispatch(setUnSavedChangesPopup({ isUnSavedChange: false }));
            setOpenPopup({
                ...openPopup,
                isShowFormulaMaster: true,
            });
        }
    };

    const onClosePopup = () => {
        setOpenPopup({
            ...openPopup,
            isShowResultName: false,
            isShowRateTable: false,
            isShowFormulaMaster: false,
        });
    };

    const onCancelPopup = () => {
        setOpenPopup({
            ...openPopup,
            isShowResultName: false,
            isShowRateTable: false,
            isShowFormulaMaster: false,
        });
    };

    const FORMULA_MASTER_ACTIONS = [
        {
            name: "Cancel",
            onClick: onClosePopup,
        },
        {
            name: "Ok",
            onClick: () => {
                const selectedFormula = formulaRef.current?.chooseFormula();
                if (!selectedFormula) {
                    alert("Please select filter row");
                    return;
                }
                restProps?.onDataChange?.();
                changeCancelBtnState("enable", "grid-incell-cancel-comp-rule");
                changeUpdateBtnState("enable", "grid-incell-update-comp-rule");
                onCancelPopup();
            },
        },
    ];

    const RESULT_NAME_ACTIONS = [
        {
            name: "Cancel",
            onClick: onClosePopup,
        },
        {
            name: "Ok",
            onClick: () => {
                let resultNameList = resultNameRef?.current?.chooseResult();
                if (resultNameList === "") {
                    alert("Please select filter row");
                    return;
                }
                setFormFields({
                    ...formFields,
                    result_name:
                        resultSettingReduxData.resultSettingData
                            .selected_result_name,
                    result_name_key:
                        resultSettingReduxData.resultSettingData
                            .selected_result_name_key,
                });
                dispatch(
                    setResultSettingData({
                        resultSettingData: {
                            ...resultSettingReduxData.resultSettingData,
                            result_name:
                                resultSettingReduxData.resultSettingData
                                    .selected_result_name,
                            result_name_key:
                                resultSettingReduxData.resultSettingData
                                    .selected_result_name_key,
                        },
                    })
                );
                restProps?.onDataChange?.();
                changeCancelBtnState("enable", "grid-incell-cancel-comp-rule");
                changeUpdateBtnState("enable", "grid-incell-update-comp-rule");
                onCancelPopup();
            },
        },
    ];

    const RATE_TABLE_ACTIONS = [
        {
            name: "Cancel",
            onClick: onClosePopup,
        },
        {
            name: "Ok",
            onClick: () => {
                if (
                    isNullOrEmptyUndefined(
                        resultSettingReduxData.resultSettingData.rate_table_name
                    )
                ) {
                    alert("Please select filter row");
                } else {
                    setFormFields({
                        ...formFields,
                        rate_table_name:
                            resultSettingReduxData.resultSettingData
                                .rate_table_name,
                        rate_table_key:
                            resultSettingReduxData.resultSettingData
                                .rate_table_key,
                    });
                    restProps?.onDataChange?.();
                    changeCancelBtnState(
                        "enable",
                        "grid-incell-cancel-comp-rule"
                    );
                    changeUpdateBtnState(
                        "enable",
                        "grid-incell-update-comp-rule"
                    );
                    onCancelPopup();
                }
            },
        },
    ];

    const onResetClick = (module) => {
        if (module === "result_name") {
            setFormFields({
                ...formFields,
                result_name: "",
                result_name_key: "",
            });
            dispatch(
                setResultSettingData({
                    resultSettingData: {
                        ...resultSettingReduxData.resultSettingData,
                        result_name: "",
                        result_name_key: "",
                    },
                })
            );
        } else if (module === "rate_table") {
            setFormFields({
                ...formFields,
                rate_table_name: "",
                rate_table_key: "",
            });
            dispatch(
                setResultSettingData({
                    resultSettingData: {
                        ...resultSettingReduxData.resultSettingData,
                        rate_table_name: "",
                        rate_table_key: "",
                    },
                })
            );
        } else if (module === "formula_master") {
            setFormFields({
                ...formFields,
                value_fx: "",
                formula_mstr_key: "",
            });
            dispatch(
                setResultSettingData({
                    resultSettingData: {
                        ...resultSettingReduxData.resultSettingData,
                        value_fx: "",
                        formula_mstr_key: "",
                        formula_name: "",
                    },
                })
            );
        }
        restProps?.onDataChange?.();
        changeCancelBtnState("enable", "grid-incell-cancel-comp-rule");
        changeUpdateBtnState("enable", "grid-incell-update-comp-rule");
    };

    const handleChangeDropdown = (e, name) => {
        if (name === "rateDataValue") {
            setRateTypeFields({
                ...rateTypeFields,
                [name]: e,
            });
            if (e.rate_type_name === "Flat") {
                setFormFields({
                    ...formFields,
                    rate_table_name: "",
                    rate_table_key: "",
                    is_enable: false,
                });
                dispatch(
                    setResultSettingData({
                        resultSettingData: {
                            ...resultSettingReduxData.resultSettingData,
                            rate_table_name: "",
                            rate_table_key: "",
                            is_enable: false,
                            in_rate_type_mstr_key: e.rate_type_mstr_key,
                        },
                    })
                );
            } else if (
                e.rate_type_name === "Cliff" ||
                e.rate_type_name === "Step"
            ) {
                setFormFields({
                    ...formFields,
                    value_fx: "",
                    is_enable: false,
                });
                dispatch(
                    setResultSettingData({
                        resultSettingData: {
                            ...resultSettingReduxData.resultSettingData,
                            value: "",
                            formula_name: null,
                            value_fx: "",
                            in_rate_type_mstr_key: e.rate_type_mstr_key,
                            formula_mstr_key: "",
                        },
                    })
                );
            }
        } else if (name == "useTargetDataValue") {
            setUseTargetFields({
                ...useTargetFields,
                [name]: e.rate_type_name == "Select Target" ? "" : e,
            });
        } else {
            setUnitTypeFields({
                ...unitTypeFields,
                [name]: e,
            });
            dispatch(
                setResultSettingData({
                    resultSettingData: {
                        ...resultSettingReduxData.resultSettingData,
                        value_unit_type: e.system_unit_mstr_key,
                    },
                })
            );
        }
        restProps?.onDataChange?.();
        changeCancelBtnState("enable", "grid-incell-cancel-comp-rule");
        changeUpdateBtnState("enable", "grid-incell-update-comp-rule");
    };

    return (
        <>
            <div className="inner-title-head">
                <h1>Result Settings</h1>
            </div>
            <div className="inner-title-cont border-bottom-none">
                <div className="k-form-field-wrap-popup reseticon">
                    <Label className="innerlabletext" style={{ width: "100%" }}>
                        {"Result Name*: "}
                    </Label>
                    <Input
                        value={formFields.result_name}
                        name="result_name"
                        onChange={handleChange}
                        onClick={() => handleModuleClick("result_name")}
                        className="reseticoninput"
                        required={isUpdateRuleClick}
                        autoComplete="off"
                    />
                    <span
                         className={restProps.pageProps.writeAccess === 0
                            ? 'k-icon k-i-x disable-class'
                            : 'k-icon k-i-x'} 
                        onClick={() => onResetClick("result_name")}
                    />
                </div>
                <div className="k-form-field-wrap-popup reseticon">
                    <Label className="innerlabletext" style={{ width: "100%" }}>
                        {"Flat Rate Fx: "}
                    </Label>
                    <Input
                        value={formFields.is_enable ? "" : formFields.value_fx}
                        name="value_fx"
                        onClick={() => handleModuleClick("flat_rate")}
                        disabled={
                            restProps.pageProps.writeAccess === 0
                                ? true
                                : rateTypeFields.rateDataValue
                                    ?.rate_type_name !== "Flat" ||
                                formFields.is_enable
                        }
                        onChange={handleChange}
                        className={
                            rateTypeFields.rateDataValue?.rate_type_name !==
                                "Flat" || formFields.is_enable
                                ? "reseticoninput customDisabled"
                                : "reseticoninput"
                        }
                        autoComplete="off"
                    />
                    <span
                     className={restProps.pageProps.writeAccess === 0 || formFields.is_enable
                        ? 'k-icon k-i-x disable-class'
                        : 'k-icon k-i-x'}
                        onClick={() => onResetClick("formula_master")}
                    />
                </div>

                <div className="k-form-field-wrap-popup">
                    <div className="groupexecution-checkbox">
                        <Checkbox
                            id="value_fx_cb"
                            checked={formFields.is_enable}
                            // label={item.label}
                            className={"k-checkbox-label innerlabletext"}
                            style={{
                                display: "inline-block",
                            }}
                            onChange={(e) =>
                                handleCheckBoxChange(e, "is_enable")
                            }
                            disabled={
                                restProps.pageProps.writeAccess === 0
                                    ? true
                                    : rateTypeFields.rateDataValue
                                        ?.rate_type_name !== "Flat"
                            }
                        />
                    </div>
                    <div className="enablevaluecont">
                        <Label className="innerlabletext  rules-value">
                            {"Flat Rate Value:"}
                        </Label>
                        <NumericTextBox
                            value={formFields?.value || null}
                            name="value"
                            format={"n8"}
                            onChange={handleChange}
                            disabled={
                                restProps.pageProps.writeAccess === 0
                                    ? true
                                    : !formFields.is_enable
                            }
                            style={{ width: "100%" }}
                            className={
                                !formFields.is_enable
                                    ? "rules-value customDisabled"
                                    : "rules-value"
                            }
                        />
                    </div>
                </div>
            </div>
            <div className="inner-title-cont">
                <div className="k-form-field-wrap-popup">
                    <Label className="innerlabletext">
                        {"Value Unit Type*: "}
                    </Label>
                    <CustomDropdown
                        name={"unitDataValue"}
                        data={unitTypeFields.unitData}
                        textField="system_unit_name"
                        onOpen={(dataValue) => {
                            setUnitTypeFields({
                                ...unitTypeFields,
                                unitData: dataValue,
                            });
                        }}
                        dataItemKey="system_unit_mstr_key"
                        valueData={unitList?.find(
                            (c) =>
                                c.system_unit_name ===
                                unitTypeFields.unitDataValue.system_unit_name
                        )}
                        onChange={(e, name) => {
                            handleChangeDropdown(e, name);
                        }}
                        filterable={true}
                        onFilterChange={(dataValue) => {
                            setUnitTypeFields({
                                ...unitTypeFields,
                                unitData: dataValue,
                            });
                        }}
                        autoClose={false}
                        autoBind={true}
                        isSelectALl={true}
                        dataList={unitList}
                        loading={unitTypeFields.isUnitTypeLoading}
                        style={{
                            width: "100%",
                        }}
                        disabled={
                            restProps.pageProps.writeAccess === 0 ? true : false
                        }
                    />
                </div>
                <div className="k-form-field-wrap-popup ">
                    <Label className="innerlabletext" style={{ width: "100%" }}>
                        {"Rate Type*: "}
                    </Label>
                    <CustomDropdown
                        name={"rateDataValue"}
                        data={rateTypeFields.rateData.sort((a, b) =>
                            a.rate_type_name.localeCompare(b.rate_type_name)
                        )}
                        textField="rate_type_name"
                        onOpen={(dataValue) => {
                            setRateTypeFields({
                                ...rateTypeFields,
                                rateData: dataValue,
                            });
                        }}
                        dataItemKey="rate_type_mstr_key"
                        valueData={rateTypeList.find(
                            (c) =>
                                c.rate_type_name ===
                                rateTypeFields.rateDataValue.rate_type_name
                        )}
                        onChange={(e, name) => {
                            handleChangeDropdown(e, name);
                        }}
                        filterable={true}
                        onFilterChange={(dataValue) => {
                            setRateTypeFields({
                                ...rateTypeFields,
                                rateData: dataValue,
                            });
                        }}
                        autoClose={false}
                        autoBind={true}
                        isSelectALl={true}
                        dataList={rateTypeList}
                        loading={rateTypeFields.isRateTypeLoading}
                        style={{
                            width: "100%",
                        }}
                        disabled={
                            restProps.pageProps.writeAccess === 0 ? true : false
                        }
                    />
                </div>

                <div className="k-form-field-wrap-popup reseticon">
                    <Label className="innerlabletext" style={{ width: "100%" }}>
                        {"Rate Table: "}
                    </Label>
                    <Input
                        value={formFields.rate_table_name}
                        name="rate_table"
                        onChange={handleChange}
                        onClick={() => handleModuleClick("rate_table")}
                        className="reseticoninput"
                        required={isUpdateRuleClick}
                        disabled={
                            restProps.pageProps.writeAccess === 0
                                ? true
                                : rateTypeFields.rateDataValue
                                    ?.rate_type_name === "Flat"
                        }
                    />
                    <span
                        className={restProps.pageProps.writeAccess === 0
                            ? 'k-icon k-i-x disable-class'
                            : 'k-icon k-i-x'} 
                        onClick={() => onResetClick("rate_table")}
                    />
                </div>
                <div className="k-form-field-wrap-popup ">
                    <Label className="innerlabletext" style={{ width: "100%" }}>
                        {"Use Target: "}
                    </Label>
                    <CustomDropdown
                        name={"useTargetDataValue"}
                        data={useTargetFields.useTargetData}
                        textField="rate_type_name"
                        onOpen={(dataValue) => {
                            setUseTargetFields({
                                ...useTargetFields,
                                useTargetData: dataValue,
                            });
                        }}
                        dataItemKey="rate_type_mstr_key"
                        valueData={useTargetList.find(
                            (c) =>
                                c.rate_type_name ===
                                useTargetFields.useTargetDataValue
                                    .rate_type_name
                        )}
                        onChange={(e, name) => {
                            handleChangeDropdown(e, name);
                        }}
                        filterable={true}
                        onFilterChange={(dataValue) => {
                            setUseTargetFields({
                                ...useTargetFields,
                                useTargetData: dataValue,
                            });
                        }}
                        autoClose={false}
                        autoBind={true}
                        isSelectALl={true}
                        dataList={useTargetList}
                        loading={useTargetFields.isUseTargetLoading}
                        style={{
                            width: "100%",
                        }}
                        // disabled={restProps.pageProps.writeAccess === 0 ? true : false}
                        disabled={true} //made this diasabled for temprary till not getting dropdown api
                    />
                </div>
                <div className="k-form-field-wrap-popup execution-checkbox">
                    <Checkbox
                        checked={formFields.is_kpi_Weight ? true : false}
                        value={formFields.is_kpi_Weight ? true : false}
                        label={"Use KPI Weight:"}
                        className={"k-checkbox-label innerlabletext"}
                        style={{
                            display: "inline-block",
                        }}
                        onChange={(e) =>
                            handleCheckBoxChange(e, "is_kpi_Weight")
                        }
                        disabled={
                            restProps.pageProps.writeAccess === 0
                                ? true
                                : !useTargetFields.useTargetDataValue
                                    ? true
                                    : false
                        }
                    />
                </div>
            </div>
            <DialogComponent
                title={
                    <span>
                        {openPopup.isShowRateTable
                            ? "Rate Tables"
                            : openPopup.isShowFormulaMaster
                                ? "Select a Formula"
                                : "Select a Result Name"}
                    </span>
                }
                desc={
                    <>
                        {openPopup.isShowRateTable ? (
                            <RateTable />
                        ) : openPopup.isShowFormulaMaster ? (
                            <FormulaMaster
                                ref={formulaRef}
                                closePopup={onClosePopup}
                                filterType="valueFx"
                                pageProps={restProps.pageProps}
                            />
                        ) : (
                            <ResultName
                                defaultResultNameKey={
                                    resultSettingReduxData.resultSettingData
                                        ?.result_name_key || null
                                }
                                showNameResultPopup={true}
                                ref={resultNameRef}
                            />
                        )}
                    </>
                }
                visible={
                    openPopup.isShowRateTable
                        ? openPopup.isShowRateTable
                        : openPopup.isShowFormulaMaster
                            ? openPopup.isShowFormulaMaster
                            : openPopup.isShowResultName
                }
                primBtnText={"Cancel"}
                width={"95%"}
                height={"95%"}
                actionBtn={
                    openPopup.isShowRateTable
                        ? RATE_TABLE_ACTIONS
                        : openPopup.isShowFormulaMaster
                            ? FORMULA_MASTER_ACTIONS
                            : RESULT_NAME_ACTIONS
                }
                onPrimButton={onClosePopup}
                onCancelConfirm={onClosePopup}
            />
        </>
    );
};

export default ResultSetting;
