/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { setNewMessage } from "../../../redux/actions/setting-actions";
import { useDispatch } from "react-redux";
import { removeOtpData, removeUserCredential } from "../../../lib/cookies";
import { useHistory } from "react-router";
import schema from "./schema/changePasswordSchema";
import { Button } from "@progress/kendo-react-buttons";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import useServiceApi from "../service";
import { LoadingPanel } from "src/components/loadingPanel";
import { BackToLoginComponent } from "src/pages/common/BackToLoginComponent";
import { chagnePasswordValidation } from "src/pages/login/form/validator";
import { encrypt } from "src/utils/crypto";
import usePasswordToggle from "src/custom-hooks/usePasswordToggle";
import { useLocation } from "react-router-dom";

const ChangePasswordForm = ({ loggedInData }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const redirectPath = searchParams.get('redirect')

  const [InputType, IconClass, toggleVisibility] = usePasswordToggle();
  const [ inputTypeConfirmPassword = InputType, IconClassConfirmPassword=IconClass, toggleVisibilityConfirmPassword=toggleVisibility] = usePasswordToggle();

  const [List, Action] = useServiceApi();
  const { isRefreshLoading } = List;

  const formSchema = schema;
  const dispatch = useDispatch();
  const history = useHistory();

  const initialValues = {
    in_user_first_name: loggedInData?.userData?.out_user_first_name,
    in_user_last_name: loggedInData?.userData?.out_user_last_name,
    in_user_email: loggedInData?.userData?.out_user_name,
  };

  /**
   * handle submit
   */

  const handleSubmit = async (dataItem) => {
    if (dataItem.newPassword !== dataItem.confirmNewPassword) {
      dispatch(
        setNewMessage({
          message: "password and confirm password not match",
          type: "Error",
          show: true,
        })
      );
      return;
    }
    const reqObj = {
      in_user_first_name: dataItem.in_user_first_name,
      in_user_last_name: dataItem.in_user_last_name,
      in_user_password: encrypt(dataItem.confirmNewPassword),
    };
    let response = await Action.changePasswordAsync(reqObj);
    if (response?.msg && response.data.out_parameters.out_ret_code == 0) {
      removeUserCredential();
      removeOtpData();
      if(redirectPath){
        history.push(`/login?redirection=${redirectPath}`)
      } else {
        history.push("/login");
      }
    }
  };

  return (
    <>
      <div>
        <Form
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validator={(value) => {
            return chagnePasswordValidation(value, formSchema);
          }}
          render={(formRenderProps) => (
            <div className="form">
              {isRefreshLoading && <LoadingPanel />}

              <div>
                <FormElement
                  className="formwrapper-form-cont"
                  horizontal={true}
                  id={"userChangePasswordForm"}
                >
                  <div className="formwrapper-elm-cont input-field-style">
                    <Field {...formSchema.newPassword} type={InputType} />
                    <a
                      onClick={toggleVisibility}
                      className={IconClass}
                    >
                      {" "}
                    </a>
                  </div>
                  <div className="formwrapper-elm-cont input-field-style">
                    <Field
                      {...formSchema.confirmNewPassword}
                      type={inputTypeConfirmPassword}
                    />
                    <a
                      href="#"
                      onClick={toggleVisibilityConfirmPassword}
                      className={IconClassConfirmPassword}
                    >
                      {" "}
                    </a>
                  </div>
                  <div className="k-form-buttons"></div>
                </FormElement>
              </div>
              <div className="loginbuttoncont change-password-btn-section">
                <BackToLoginComponent />
                <Button
                  type={"submit"}
                  className="btn-active bttn"
                  form="userChangePasswordForm"
                >
                  Update
                </Button>
              </div>
            </div>
          )}
        />
      </div>
    </>
  );
};
export default React.memo(ChangePasswordForm);
