import React from "react";
import {
  UPDATE_ROLE,
  GET_DB_ROLES,
  ADD_ROLE,
  DELETE_ROLE,
} from "../../api-setup/api-endpoints";
import { getUserCredential } from "../../lib/cookies";
import {
  POSTAPI,
  errStatusHandler,
} from "../../api-setup/api-helper";
import { GridColumnDataMapper } from "../../utils/utils";
import { useHistory } from "react-router";
import { USER_GRID_DEFAULT_PAGE_SIZE } from "../../constants/grid";
import { useDispatch } from "react-redux";
import { setNewMessage } from "../../redux/actions/setting-actions";
import { EXPAND_ACTION_ROLE_COLUM_OBJECT } from "src/constants/constants";

const initialDataState = {
  skip: 0,
  take: USER_GRID_DEFAULT_PAGE_SIZE,
};
let defaultItem = {
  text: USER_GRID_DEFAULT_PAGE_SIZE,
  id: 2,
};

export default function CustomTables() {
  const cookiesData = getUserCredential();
  const header = {
    "x-access-token": cookiesData.secretToken,
  };
  const history = useHistory();
  const dispatch = useDispatch();
  const [data, setData] = React.useState([]);
  const [columns, setColumns] = React.useState([]);
  const [dataCount, setDataCount] = React.useState(0);
  const [isColumnMenuSearch, setIsColumnMenuSearch] = React.useState(false);
  const [paginationData, setPaginationData] = React.useState({
    pageData: 0,
    rows: 10,
  });
  const [loadingState, setLoadState] = React.useState({
    isSaveData: undefined,
    isDeleteData: undefined,
    isupdateData: undefined,
  });
  const [noDataFound, setNoDataFound] = React.useState(false);
  const [addClick, setaddClick] = React.useState(false);
  const [isError, setisError] = React.useState(false);
  const [filterArray, setfilterArray] = React.useState([]);
  const [roleTypeKeyList, setRoleTypeKeyList] = React.useState([]);
  const [initialLoading, setLoadingState] = React.useState(false);
  const [pageData, setPage] = React.useState(initialDataState);
  const [page, setPageData] = React.useState(0);
  const [editData, setEditData] = React.useState("");
  const [pageSize, setPageSize] = React.useState(defaultItem);
  const [confirmationPopup, setConfirmationPopup] = React.useState({
    title: "Delete",
    desc: "Are you sure you want to delete this record?",
    isVisible: false,
    type: "",
    actionBtn: [],
    data: {},
    action: "Delete",
  });
  const [originalData, setOriginalData] = React.useState([]);
  const [isAPICalling, setAPICallingState] = React.useState(false);
  const [isRefreshLoading, setRefreshLoading] = React.useState(false);

  const getRoleList = (data) => {
    setAPICallingState(true);
    return new Promise(function (resolve, reject) {
      POSTAPI(false, GET_DB_ROLES, null, header, data, dispatch).then((res) => {
        if (res.data.out_parameters.out_ret_code === 0 || res.data.out_parameters.out_ret_code === 2) {
          let userData = JSON.parse(JSON.stringify(res.data.row_result));
          userData.map((item, idx) => {
            item.rowId = idx;
            item.selected = false;

            item.isValidateRow = false;
            return 0;
          });
          if (res.data.out_parameters.out_columns_xml) {
            const filterColoumData = JSON.parse(
              JSON.stringify(res.data.out_parameters.out_columns_xml),
            );

            if (data.in_load_type === "grid-reload") {
              const columData = GridColumnDataMapper(
                res.data.out_parameters.out_columns_xml,
              );
              columData.unshift(EXPAND_ACTION_ROLE_COLUM_OBJECT);
              setColumns(columData);
            }
            if (filterColoumData) {
              let tempArray = [];
              JSON.parse(filterColoumData.replace(/\r?\n|\r/g, ""));
              let tempDataValue = JSON.parse(filterColoumData);
              let dataField = tempDataValue.resultset.metadata[0].field;
              dataField.map((item) => {
                tempArray.push(item.$);
                return 0;
              });
              let coloumArray = [];
              tempArray.map((item, i) => {
                let obj = {};
                if (item.label !== "") {
                  obj.label = item.label;
                  obj.value = item.name;
                  coloumArray.push(obj);
                }
                return 0;
              });
              setfilterArray(coloumArray);
            }
            resolve(userData)
          }
          setNoDataFound(false);
          setAPICallingState(false);
          setEditData("");
          setLoadingState(true);
          setData(userData);
          setOriginalData(userData);
          setDataCount(res.data.out_parameters.out_total_rows);
          setIsColumnMenuSearch(false);
          setRefreshLoading(false);
          resolve(userData)
        } else {
          setData([]);
          setNoDataFound(false);
          setAPICallingState(false);
          setLoadingState(true);
          errStatusHandler(res, history, dispatch);
          setIsColumnMenuSearch(false);
          resolve([])
        }
      });
    }).then((res) => {
      return res;
    })
      .catch((res) => {
        if (res.data.out_parameters.out_ret_code > 0) {
          dispatch(
            setNewMessage({
              message: res.data.out_parameters.out_error_msg,
              type: "Error",
              show: true,
            })
          );
          errStatusHandler(res, history, dispatch);
        }
      });
  };

  const addDataAsync = async (bodyData, apiCallData) => {
    // setRefreshLoading(true);
    setAPICallingState(true);
    // setLoadingState(true);
    // setLoadState({ ...loadingState, isSaveData: false });
    return new Promise(function (resolve, reject) {
      POSTAPI(false, ADD_ROLE, null, header, bodyData, dispatch).then((res) => {
        setRefreshLoading(false);
        if (res.data.out_parameters.out_ret_code === 0) {
          setaddClick(true);
          setAPICallingState(false);
          resolve(true)
        } else {
          setAPICallingState(false);
          dispatch(
            setNewMessage({
              message: res.data.out_parameters.out_error_msg,
              type: "Error",
              show: true,
            }),
          );
          resolve(false)
        }
      });
    }).then((res) => {
      return res;
    })
      .catch((res) => {
        if (res.data.out_parameters.out_ret_code > 0) {
          dispatch(
            setNewMessage({
              message: res.data.out_parameters.out_error_msg,
              type: "Error",
              show: true,
            })
          );
          errStatusHandler(res, history, dispatch);
        }
      });
  };

  const updateDataAsync = (bodyData) => {
    setRefreshLoading(true);
    return new Promise(function (resolve, reject) {
      POSTAPI(false, UPDATE_ROLE, null, header, bodyData, dispatch).then(
        (res) => {
          setRefreshLoading(false);
          if (res && res.data.out_parameters.out_ret_code === 0) {
            resolve(true);
          } else {
            dispatch(
              setNewMessage({
                message: res.data.out_parameters.out_error_msg,
                type: "Error",
                show: true,
              }),
            );
            resolve([]);
            errStatusHandler(res, history, dispatch);
          }
        },
      );
    })
      .then((res) => {
        return res;
      })
      .catch((res) => {
        if (res.data.out_parameters.out_ret_code > 0) {
          dispatch(
            setNewMessage({
              message: res.data.out_parameters.out_error_msg,
              type: "Error",
              show: true,
            }),
          );
          errStatusHandler(res, history, dispatch);
        }
      });
  };

  const deleteDataAsync = async (bodyData) => {
    setRefreshLoading(true);
    return new Promise(function (resolve, reject) {
      POSTAPI(false, DELETE_ROLE, null, header, bodyData, dispatch).then(
        (res) => {
          const totalCustomFields = bodyData.dbRoleKeyList.length;
          const unSuccessFullCount = res.data.successCount;
          setRefreshLoading(false);
          if (res.data.out_parameters.out_ret_code > 0) {
            let msg = res.data.out_parameters.out_error_msg;
            setLoadState({ ...loadingState, isDeleteData: false });
            if (totalCustomFields !== unSuccessFullCount) {
              msg =
                unSuccessFullCount +
                " Roles (" +
                res.data.successName +
                ") out of " +
                totalCustomFields +
                " as " +
                msg;
              setLoadState({ ...loadingState, isDeleteData: true });
              setTimeout(function () {
                resolve("success");
              });
            } else {
              setLoadState({ ...loadingState, isDeleteData: false });
              setTimeout(function () {
                resolve("error");
              });
            }
            setConfirmationPopup({
              ...confirmationPopup,
              isVisible: false,
              type: "",
              actionBtn: [],
              data: {},
              action: "",
            });
            dispatch(
              setNewMessage({
                message: msg,
                type: "Error",
                show: true,
              })
            );
          } else {
            setConfirmationPopup({
              ...confirmationPopup,
              isVisible: false,
              type: "",
              actionBtn: [],
              data: {},
              action: "",
            });
            setLoadState({ ...loadingState, isDeleteData: true });
            setTimeout(function () {
              resolve("success");
            });
          }
        },
      );
    })
      .then((res) => {
        return res;
      })
      .catch((res) => {
        if (res.data.out_parameters.out_ret_code > 0) {
          dispatch(
            setNewMessage({
              message: res.data.out_parameters.out_error_msg,
              type: "Error",
              show: true,
            }),
          );
          errStatusHandler(res, history, dispatch);
        }
      });
  };

  return [
    {
      data,
      columns,
      dataCount,
      setData,
      setColumns,
      isColumnMenuSearch,
      pageData,
      page,
      originalData,
      pageSize,
      isAPICalling,
      setEditData,
      confirmationPopup,
      setConfirmationPopup,
      initialLoading,
      setPage,
      setPageData,
      setPageSize,
      setPaginationData,
      setAPICallingState,
      paginationData,
      loadingState,
      setOriginalData,
      roleTypeKeyList,
      setRoleTypeKeyList,
      editData,
      filterArray,
      setfilterArray,
      noDataFound,
      setaddClick,
      addClick,
      isError,
      setisError,
      setRefreshLoading,
      isRefreshLoading,
      setLoadState,
    },
    {
      getRoleList,
      addDataAsync,
      updateDataAsync,
      deleteDataAsync,
    },
  ];
}
