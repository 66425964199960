export const GET_PROGRESS_PERCENTAGE = 'GET_PROGRESS_PERCENTAGE'
export const SET_PROGRESS_END_TIME = 'SET_PROGRESS_END_TIME';
export const SET_PROGRESS_STATUS = 'SET_PROGRESS_STATUS';
export const SET_PROGRESS_COMPLETE = 'SET_PROGRESS_COMPLETE';




export function setProgressPercentage(data){
    return{
        type : GET_PROGRESS_PERCENTAGE,
        dataProgressPercentage : data
    }
}
export const setProgressEndTime = (data) => {
    return {
      type: SET_PROGRESS_END_TIME,
        dataProgressendTime: data,
      
    };
  };

  export const setProgressStatus = (data) => {
    return {
      type: SET_PROGRESS_STATUS,
        dataProgressStatus: data,
      
    };
  };

  export const setProgressComplete = (data) => {
    return {
      type: SET_PROGRESS_COMPLETE,
      dataProgressComplete: data,
      
    };
  };

