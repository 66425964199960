import React from "react";
import { Window, WindowActionsBar } from "@progress/kendo-react-dialogs";
import { Upload } from "@progress/kendo-react-upload";
import { useDispatch } from "react-redux";
import { setNewMessage } from "../../redux/actions/setting-actions";
import { getUserCredential } from "../../lib/cookies";
import CustomDropdown from "src/components/customDropdown";
import { Label } from "@progress/kendo-react-labels";
import MessageSchema from "src/utils/messageSchema";
import useAPI from "./dtuService"
import { DTU_UPLOAD_ALLOWED_EXTENSIONS, DTU_UPLOAD_MAX_FILE_SIZE } from "src/constants/constants";
import { LoadingPanel } from "src/components/loadingPanel";
import { Button } from "@progress/kendo-react-buttons";

let filesObj = "";
const uploadRef = React.createRef();

const UploadDTU = (restProps) => {
    const dispatch = useDispatch();
    const cookiesData = getUserCredential();

    /** State Variables  */
    const [visible, setVisible] = React.useState(restProps.visible);
    const [files, setFiles] = React.useState([]);
    const [loadingState, setLoadingState] = React.useState({
        isDownloading: false,
        isUploading: false
    })
    const [stageChange, setStateChange] = React.useState('DEFAULT')
    const [modal ,setModal] = React.useState(true)
    /** Use API Hooks */
    const [list, Action] = useAPI()

    const {
        eventsTypeFields,
        eventTypeList,
        setEventTypeFields,
        preConfigPath
    } = list

    React.useEffect(() => {
        if (restProps.isShowEventType) {
            Action.getEventTypeListDataAsync();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        if (visible && preConfigPath === "") {
            Action.systemSettingDataAsync();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [preConfigPath, visible]);

    /** Upload file */
    const onUpload = async () => {
        setLoadingState({
            ...loadingState,
            isUploading: true
        })
        const dataReq = new FormData();
        dataReq.append("file", filesObj);
        const payloadData = {
            uploadPath: preConfigPath + "/",
            userId: parseInt(cookiesData.out_user_id),
            tenantId: cookiesData.out_tenant_id,
            pageId: restProps.pageId,
        };


        if (restProps.isShowEventType) {
            payloadData.eventGroup = `${eventsTypeFields.eventTypeValue.id} | ${eventsTypeFields.eventTypeValue.group_name}`
        }

        if(restProps.versionData){
            payloadData.customTableMstrkey = restProps.versionData.custom_table_mstr_key
            payloadData.dateVersion = restProps.versionData.date_version
        }
      
        let res = await Action.uploadFileDataAsync(payloadData, dataReq)
        if (res) {
            setLoadingState({
                ...loadingState,
                isUploading: false
            })
            onClosePopup();
            restProps.setAPICall && restProps.setAPICall(true);
        } else {
            setLoadingState({
                ...loadingState,
                isUploading: false
            })
        }
    };

    /** Close window Popup */
    const onClosePopup = () => {
        setVisible(!visible);
        restProps.setShow({ ...restProps.visible, dtuUpload: false });
    };


    /** Method To Select File */
    const onFileChange = (e) => {
        /** File Size In MB */
        if (e.newState[0].size / (1024 * 1024).toFixed(2) > DTU_UPLOAD_MAX_FILE_SIZE) {
            dispatch(
                setNewMessage({
                    message: MessageSchema.validFile,
                    type: "Error",
                    show: true,
                })
            );
        } else {
            if (e.newState[0].extension === DTU_UPLOAD_ALLOWED_EXTENSIONS) {
                filesObj = e.newState[0].getRawFile();
                setFiles(e.newState);
            } else {
                dispatch(
                    setNewMessage({
                        message: MessageSchema.validFileType,
                        type: "Error",
                        show: true,
                    })
                );
            }
        }
    };

    /** Method To Remove File */
    const onRemove = (event) => {
        setFiles(event.newState);
    };

    /** Method To Download File */
    const downloadFile = async () => {
        setLoadingState({
            ...loadingState,
            isDownloading: true
        })
        const payloadData = {
            userId: parseInt(cookiesData.out_user_id),
            tenantId: cookiesData.out_tenant_id,
            pageId: restProps.pageId,
        };
        if (restProps.isShowEventType) {
            payloadData.eventGroup = `${eventsTypeFields.eventTypeValue.id} | ${eventsTypeFields.eventTypeValue.group_name}`
            payloadData.eventName = `event_${eventsTypeFields.eventTypeValue.text.replace(/ /g, "_").toLowerCase()}`
        }
        if(restProps.versionData){
            payloadData.customTableMstrkey = restProps.versionData.custom_table_mstr_key
            payloadData.dateVersion = restProps.versionData.date_version
        }

        let res = await Action.downloadFileAsync(payloadData)
        if (res) {
            setLoadingState({
                ...loadingState,
                isDownloading: false
            })
        } else {
            setLoadingState({
                ...loadingState,
                isDownloading: false
            })
        }
    }

    /** Show Modal Title */
    const ModalTitle = () => {
        return (
            <span>
                {restProps.title}
            </span>
        );
    };

    const handleStageChange = (e) => {
        setStateChange(e.state)
        if(e.state === 'MINIMIZED'){
            setModal(false)
        }else if(e.state === 'DEFAULT'){
            setModal(true)
        }
    };

    return (
        <>
            <Window
                title={<ModalTitle />}
                onClose={onClosePopup}
                width={900}
                height={restProps.pageId === 'events' ? 380 : 350}
                resizable={false}
                stage={stageChange}
                onStageChange={handleStageChange}
                modal={modal}
            >
                {(loadingState.isDownloading || loadingState.isUploading) && <LoadingPanel />}
                <div className="upload-config">
                <div className="intro-upload-config">
                    <div className="intro-drag-text"> Drag and Drop files here to upload. Maximum file
                        size is <span>{DTU_UPLOAD_MAX_FILE_SIZE} MB</span></div>
                    <span className="intro-btn">

                        {" "}
                        <button className="download-button" onClick={downloadFile}><i className="fas fa-download"></i> Download Sample File</button>
                    </span>
                </div>
                {restProps.isShowEventType &&
                    <div className="eventtypeuploadmodel">
                        <Label>{"Event Type:"}</Label>
                        <CustomDropdown
                            name={"eventTypeValue"}
                            data={eventsTypeFields.eventTypeData}
                            textField="text"
                            onOpen={(dataValue) => {
                                setEventTypeFields({
                                    ...eventsTypeFields,
                                    eventTypeData: dataValue,
                                });
                            }}
                            dataItemKey="id"
                            valueData={eventTypeList.find(
                                (c) => c.text === eventsTypeFields.eventTypeValue.text
                            )}
                            onChange={(e, name) => {
                                setEventTypeFields({
                                    ...eventsTypeFields,
                                    [name]: e,
                                });
                            }}
                            onFilterChange={(dataValue) => {
                                setEventTypeFields({
                                    ...eventsTypeFields,
                                    eventTypeData: dataValue,
                                });
                            }}
                            autoClose={false}
                            autoBind={true}
                            isSelectALl={true}
                            dataList={eventTypeList}
                            loading={eventsTypeFields.isEventTypeDataLoading}
                        />
                        </div>}
                <div className="box">
                    <div className="drag-container">
                        {/* <span>
                            <i className="fas fa-file-upload"></i>
                        </span> */}
                        <p>

                        </p>
                    </div>
                    <div className="dtudrop-box">
                        <Upload
                            ref={uploadRef}
                            batch={false}
                            multiple={false}
                            autoUpload={false}
                            withCredentials={false}
                            className={"file-upload"}
                            onAdd={onFileChange}
                            files={files}
                            onRemove={onRemove}
                            restrictions={{
                                allowedExtensions: [DTU_UPLOAD_ALLOWED_EXTENSIONS],
                                maxFileSize: 4000000,
                            }}
                        />

                    </div>
                </div>
            </div>
                {/* <UploadComponent /> */}
                <WindowActionsBar layout={'end'}>
                    <>
                        <Button
                            className="cancelbtn"
                            onClick={(e) => onClosePopup()}
                            autoFocus={true}
                        >
                            {'Cancel'}
                        </Button>

                        <Button
                            className="primarybtn"
                            onClick={(e) => onUpload(e)}
                            autoFocus={true}
                            disabled={files.length > 0 ? false : true}
                        >
                            {'Upload'}
                        </Button>
                    </>
                </WindowActionsBar>
            </Window>
        </>
    );
};
export default React.memo(UploadDTU);
