export const ResponseStatus = {
  validStatus: 200,
  invalidStatus: 400,
  notFoundStatus: 404,
  notAuthorized: 401,
}

export const ResponseError = {
  ERR_BAD_OPTION_VALUE: "ERR_BAD_OPTION_VALUE",
  ERR_BAD_OPTION: "ERR_BAD_OPTION",
  ECONNABORTED: "ECONNABORTED",
  ETIMEDOUT: "ETIMEDOUT",
  ERR_NETWORK: "ERR_NETWORK",
  ERR_FR_TOO_MANY_REDIRECTS: "ERR_FR_TOO_MANY_REDIRECTS",
  ERR_DEPRECATED: "ERR_DEPRECATED",
  ERR_BAD_RESPONSE: "ERR_BAD_RESPONSE",
  ERR_BAD_REQUEST: "ERR_BAD_REQUEST",
  ERR_CANCELED: "ERR_CANCELED",
}


export const ErrorMessage = {
  [ResponseError.ERR_NETWORK]: "Error in network, Please try again later",
  [ResponseError.ERR_BAD_REQUEST]: "Error in network, Please try again later",
  [ResponseStatus.notAuthorized]:
    "User can not be authorized, Please try again",
};

export const DataOnLoadErrorMessage = {
  emptyData: "Currently not able to fetch data, Please try again later",
  invalidData: "Currently not able to fetch data, Please try again later",
};
