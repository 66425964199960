import React, { useEffect, useState } from "react";
import { Input } from "@progress/kendo-react-inputs";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Label } from "@progress/kendo-react-labels";
import { filterBy } from "@progress/kendo-data-query";
import {
  LOGICAL_OPERATORS,
  MATH_SQL_OPERATORS,
  MULTIPLE_NUMBER_REGEX,
  MULTIPLE_STRING_REGEX,
} from "src/constants/constants";
import { fetchRfctFieldData } from "./formula-helpers";

const FormulaItemElements = (props) => {
  const { item, dropDownItem, onDataPopulate, onObjectChange, pageProps } = props;

  const [dropDownList, setDropdownList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [text, setText] = useState(item.value || "");
  const [errorInfo, setErrorInfo] = useState({
    error: item.error || false,
    errorMsg: item.errorMsg || "",
  });
  const [filter, setFilter] = useState("");
  const [dropdownData, setDropdownData] = useState(
    dropDownItem || {
      value: null,
      dataKey: "",
      dataText: "",
    }
  );

  useEffect(() => {
    if (item.key) {
      if (
        //!item.data &&
        item.key === "logical_op" ||
        item.key === "math_op" ||
        item.key === "rfct_field"
      ) {
        setupDropdownBasedOnKey(item.key);
      } else {
        //setDropdownList(item.data);
      }

      setText(item.value);
      setErrorInfo({
        error: item.error || false,
        errorMsg: item.errorMsg || "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  const setupDropdownBasedOnKey = async (key) => {
    let data = item.data;
    try {
      setIsLoading(true);

      if (item.key === "math_op") {
        if (!data) {
          data = MATH_SQL_OPERATORS;
        }
      } else if (item.key === "logical_op") {
        if (!data) {
          data = LOGICAL_OPERATORS;
        }
      } else if (item.key === "rfct_field") {
        if (!data) {
          const rfctFieldData = await fetchRfctFieldData();
          data = rfctFieldData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }

    setDropdownList(data);

    let selectedItem;
    // set the initial value of dropdown.
    if (dropDownItem && dropDownItem.value) {
       selectedItem = data.find(
        (it) => it[dropdownData.dataKey] === item.value
      );
      if (selectedItem) {
        setDropdownData({ ...dropdownData, value: selectedItem });
      } else {
        selectedItem = data[0];
      }
    } else {
      selectedItem = data[0];
      setDropdownData({
        ...dropdownData,
        value: data[0],
      });
    }
    if (item.isProcessing && (!item.data || !item.data.length)) {
      onDataPopulate?.(data, item.uId, selectedItem);
    }
  };

  const onInputChange = (e) => {
    const val = e.target.value;
    setText(val);
    switch (item.key) {
      case "number":
        if (isNaN(val) || val === "") {
          setErrorInfo({ error: true, errorMsg: "Please enter valid number." });
        } else {
          setErrorInfo({ error: false, errorMsg: "" });
        }
        break;
      case "number_in":
        if (!val) {
          setErrorInfo({
            error: true,
            errorMsg: "Insert multiple numbers required.",
          });
        } else if (!MULTIPLE_NUMBER_REGEX.test(val)) {
          setErrorInfo({ error: true, errorMsg: "Invalid multiple numbers" });
        } else {
          setErrorInfo({ error: false, errorMsg: "" });
        }
        break;
      case "string":
        if (!val) {
          setErrorInfo({ error: true, errorMsg: "Insert string required." });
        } else {
          setErrorInfo({ error: false, errorMsg: "" });
        }
        break;

      case "string_in":
        if (!val) {
          setErrorInfo({
            error: true,
            errorMsg: "Insert multiple string required.",
          });
        } else if (!MULTIPLE_STRING_REGEX.test(val)) {
          setErrorInfo({ error: true, errorMsg: "Invalid multiple string." });
        } else {
          setErrorInfo({ error: false, errorMsg: "" });
        }
        break;
      case "date":
        if (!val) {
          setErrorInfo({ error: true, errorMsg: "Please enter valid date." });
        } else {
          setErrorInfo({ error: false, errorMsg: "" });
        }
        break;

      default:
        break;
    }
  };

  const onDropdownChange = (val) => {
    onObjectChange({
      ...item,
      error: errorInfo.error,
      errorMsg: errorInfo.errorMsg,
      value: val,
    });
    setDropdownData({ ...dropdownData, value: val });
  };

  if (item.key === "number" || item.key === "number_in") {
    return (
      <div>
        <Label style={{ fontSize: 12 }}>
          {item.key === "number"
            ? "Insert Number*"
            : "Insert Multiple Numbers*"}
          :
        </Label>
        <Input
          type={item.key === "number" ? "number" : "text"}
          name={item.key}
          value={text}
          placeholder={item.key === "number" ? "0" : "(123,456)"}
          onChange={(e) => onInputChange(e)}
          style={{ width: "100%" }}
          onBlur={(e) => {
            onObjectChange({
              ...item,
              error: errorInfo.error,
              errorMsg: errorInfo.errorMsg,
              value: text,
            });
          }}
          disabled={pageProps.writeAccess === 0 ? true : false}
        />
        {errorInfo.error ? (
          <Label style={{ fontSize: 11, color: "red" }}>
            {errorInfo.errorMsg}
          </Label>
        ) : (
          <Label />
        )}
      </div>
    );
  }

  if (item.key === "string" || item.key === "string_in") {
    return (
      <div>
        <Label style={{ fontSize: 12 }}>
          {item.key === "string"
            ? "Insert String*"
            : "Insert Multiple Strings*"}
          :
        </Label>
        <Input
          type="text"
          name={item.key}
          value={text}
          placeholder={
            item.key === "string" ? "Insert string" : "('abc','def')"
          }
          onChange={(e) => onInputChange(e)}
          style={{ width: "100%" }}
          onBlur={() =>
            onObjectChange({
              ...item,
              error: errorInfo.error,
              errorMsg: errorInfo.errorMsg,
              value: text,
            })
          }
          disabled={pageProps.writeAccess === 0 ? true : false}
        />
        {errorInfo.error ? (
          <Label style={{ fontSize: 11, color: "red" }}>
            {errorInfo.errorMsg}
          </Label>
        ) : (
          <Label />
        )}
      </div>
    );
  }

  if (item.key === "date") {
    return (
      <div>
        <div style={{ width: "100%" }}>
          <Label style={{ fontSize: 12 }}>Insert Date*:</Label>
        </div>
        <DatePicker
          name={item.key}
          value={text}
          format="yyyy-MM-dd"
          onChange={(e) => onInputChange(e)}
          formatPlaceholder={{
            year: "yyyy",
            month: "mm",
            day: "dd",
          }}
          width="100%"
          placeholder="yyyy-mm-dd"
          onBlur={() =>
            onObjectChange({
              ...item,
              error: errorInfo.error,
              errorMsg: errorInfo.errorMsg,
              value: text,
            })
          }
          disabled={pageProps.writeAccess === 0 ? true : false}
        />
        {errorInfo.error ? (
          <Label style={{ fontSize: 11, color: "red" }}>
            {errorInfo.errorMsg}
          </Label>
        ) : (
          <Label />
        )}
      </div>
    );
  }

  if (
    item.key === "logical_op" ||
    item.key === "math_op" ||
    item.key === "rfct_field"
  ) {
    return (
      <div>
        <Label style={{ fontSize: 12 }}>
          {item.key === "logical_op"
            ? "Select Logical Operator*"
            : item.key === "math_op"
            ? "Select Math/SQL Operator*"
            : "Select RFCT Field*"}
          :
        </Label>
        <DropDownList
          name={item.key}
          value={
            typeof dropdownData.value === "object" && dropdownData.value
              ? dropdownData.value
              : undefined
          }
          style={{ width: "100%" }}
          loading={isLoading}
          onChange={(e) => onDropdownChange(e.target.value)}
          data={dropDownList}
          dataItemKey={dropDownItem.dataKey}
          textField={dropDownItem.dataText}
          filterable={true}
          filter={filter}
          onFilterChange={(event) => {
            setFilter(event.filter.value);
            setDropdownList(filterBy(item.data?.slice(), event.filter));
          }}
          disabled={pageProps.writeAccess === 0 ? true : false}
        />
      </div>
    );
  }

  return <>{item.key}</>;
};

export default React.memo(FormulaItemElements);
