/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { setNewMessage } from "../../../redux/actions/setting-actions";
import { useDispatch } from "react-redux";
import schema from "./schema/changePasswordSchema";
import { Button } from "@progress/kendo-react-buttons";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import useServiceApi from "../service";
import { LoadingPanel } from "src/components/loadingPanel";
import {
    chagnePasswordValidation,
    chagneUserDetailsValidation,
} from "src/pages/login/form/validator";
import { encrypt } from "src/utils/crypto";
import { Checkbox } from "@progress/kendo-react-inputs";
import usePasswordToggle from "src/custom-hooks/usePasswordToggle";
import { CreateUserLocalStorage } from "src/utils/loginUtils";
import { getLocalStorage, getUserCredential, setUserCredential } from "src/lib/cookies";
import { useHistory } from "react-router";


const ManageProfile = ({ loggedInData }) => {
   const cookiesData =  getUserCredential()

  const [List, Action] = useServiceApi();
  const history = useHistory()
  
    const [InputType, IconClass, toggleVisibility] = usePasswordToggle();
    const [
        inputTypeConfirmPassword = InputType,
        IconClassConfirmPassword = IconClass,
        toggleVisibilityConfirmPassword = toggleVisibility,
    ] = usePasswordToggle();

    const processEnv = process.env;
    const localStorageData = getLocalStorage(
        processEnv.REACT_APP_CREDENTIAL_KEY
    );

    const { isRefreshLoading } = List;
    const [isDisabledBtn, setIsDisabledBtn] = useState(true)
    const [formData, setFormData] = useState({
        in_user_first_name: cookiesData?.out_user_first_name,
        in_user_last_name:  cookiesData?.out_user_last_name,
        in_user_email: cookiesData?.out_impersonate ? cookiesData?.out_user_name : loggedInData?.userData?.out_user_name,
        isChecked: false,
        newPassword: null,
        confirmNewPassword: null,
    });
    const [formKey, setFormKey] = React.useState(1);

    const formSchema = schema;
    const dispatch = useDispatch();

    const resetForm = () => {
        setFormKey(formKey + 1);
    };

    const handleSubmit = async (dataItem) => {
        if (
            !dataItem.in_user_first_name.trim() ||
            !dataItem.in_user_last_name.trim()
        ) {
            dispatch(
                setNewMessage({
                    message: "First Name and Last Name empty not allowed",
                    type: "Error",
                    show: true,
                })
            );
            return;
        }
        if (
            formData?.isChecked &&
            dataItem.newPassword !== dataItem.confirmNewPassword
        ) {
            dispatch(
                setNewMessage({
                    message: "New password and confirm new password not match",
                    type: "Error",
                    show: true,
                })
            );
            return;
        }
        const reqObj = {
            in_user_first_name: dataItem.in_user_first_name.trim(),
            in_user_last_name: dataItem.in_user_last_name.trim(),
            in_user_password: formData?.isChecked
                ? encrypt(dataItem.confirmNewPassword)
                : "",
            updatingFrom: "manage-profile",
        };

        let response = await Action.changePasswordAsync(reqObj);
        if (response?.msg && response.data.out_parameters.out_ret_code == 0) {
            setFormData({
                ...formData,
                isChecked: false,
                newPassword: "",
                confirmNewPassword: "",
            });
            CreateUserLocalStorage({
                ...localStorageData,
                out_user_first_name: reqObj.in_user_first_name,
                out_user_last_name: reqObj.in_user_last_name,
            });
            setUserCredential({
                ...cookiesData,
                out_user_first_name: reqObj.in_user_first_name,
                out_user_last_name: reqObj.in_user_last_name,
            });
            resetForm();
            setIsDisabledBtn(true)
            // removeUserCredential();
            // removeOtpData();
            // history.push("/login");
        }
    };
    const isCheckedHandler = (e) => {
        // if (e.target.value == false) {
        // resetForm();
        setFormData({
            ...formData,
            newPassword: "",
            confirmNewPassword: "",
            isChecked: e.target.value,
        });
        // }
    };
    const onChangeHandler = (e) => {
        setIsDisabledBtn(false)
        setFormData({
            ...formData,
            [e.target.name]: e.value,
        });
    };

    const redirectTo = () => {
        // history.goBack()
        history.push(`/${cookiesData?.out_db_obj_code}`)
    };

    
    return (
        <>
            <div className="login-main">
                <div className="login-box">
                    <Form
                        initialValues={formData}
                        onSubmit={handleSubmit}
                        key={formKey}
                        validator={(value) => {
                            if (!formData.isChecked) {
                                return chagneUserDetailsValidation(
                                    value,
                                    formSchema
                                );
                            } else {
                                return chagnePasswordValidation(
                                    value,
                                    formSchema
                                );
                            }
                        }}
                        render={(formRenderProps) => (
                            <div className="form">
                                {isRefreshLoading && <LoadingPanel />}
                                <div>
                                    <FormElement
                                        className="formwrapper-form-cont"
                                        horizontal={true}
                                        id={"userChangePasswordForm"}
                                    >
                                        <div className="login-box-cont">
                                            <div className="login-box-left manage-profile-box">
                                                <h1>Manage Profile</h1>
                                                <div className="change-password-form-border">
                                                    <p className="change-password-form-label">
                                                        Profile
                                                    </p>
                                                    <div className="formwrapper-elm-cont">
                                                        <Field
                                                            {...formSchema.in_user_first_name}
                                                            autoComplete="off"
                                                            value={
                                                                formData.in_user_first_name
                                                            }
                                                            name="in_user_first_name"
                                                            onChange={
                                                                onChangeHandler
                                                            }
                                                        />
                                                    </div>
                                                    <div className="formwrapper-elm-cont">
                                                        <Field
                                                            autoComplete="off"
                                                            {...formSchema.in_user_last_name}
                                                            onChange={
                                                                onChangeHandler
                                                            }
                                                            value={
                                                                formData.in_user_last_name
                                                            }
                                                            name="in_user_last_name"
                                                        />
                                                    </div>
                                                    <div className="formwrapper-elm-cont">
                                                        <Field
                                                            {...formSchema.in_user_email}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="login-box-right manage-profile-box">
                                                <h1 className="visibility-hidden">
                                                    Change Password
                                                </h1>
                                                <div>
                                                    <div className="change-password-form-border">
                                                        <div
                                                            className="change-password-form-label"
                                                            style={{
                                                                display: "flex",
                                                                gap: "7px",
                                                            }}
                                                        >
                                                            <Checkbox
                                                                value={
                                                                    formData.isChecked
                                                                }
                                                                onChange={
                                                                    isCheckedHandler
                                                                }
                                                            />
                                                            <p>
                                                                Change Password
                                                            </p>
                                                        </div>
                                                        <div className="formwrapper-elm-cont">
                                                            {formData.isChecked ? (
                                                                <Field
                                                                    autoComplete="off"
                                                                    {...formSchema.newPassword}
                                                                    value={
                                                                        formData.newPassword
                                                                    }
                                                                    onChange={
                                                                        onChangeHandler
                                                                    }
                                                                    type={
                                                                        InputType
                                                                    }
                                                                />
                                                            ) : (
                                                                <Field
                                                                    {...formSchema.newPassword2}
                                                                    disabled={
                                                                        !formData.isChecked
                                                                    }
                                                                    value={
                                                                        formData.newPassword
                                                                    }

                                                                />
                                                            )}
                                                            <a
                                                                onClick={
                                                                    formData.isChecked
                                                                        ? toggleVisibility
                                                                        : () => {
                                                                              return false;
                                                                          }
                                                                }
                                                                className={`${IconClass} top35`}
                                                            >
                                                                {" "}
                                                            </a>
                                                        </div>
                                                        <div className="formwrapper-elm-cont">
                                                            {formData.isChecked ? (
                                                                <Field
                                                                    autoComplete="off"
                                                                    {...formSchema.confirmNewPassword}
                                                                    value={
                                                                        formData.confirmNewPassword
                                                                    }
                                                                    onChange={
                                                                        onChangeHandler
                                                                    }
                                                                    disabled={
                                                                        !formData.isChecked
                                                                    }
                                                                    type={
                                                                        inputTypeConfirmPassword
                                                                    }
                                                                />
                                                            ) : (
                                                                <Field
                                                                    {...formSchema.confirmNewPassword2}
                                                                    value={
                                                                        formData.confirmNewPassword
                                                                    }
                                                                    disabled={
                                                                        !formData.isChecked
                                                                    }
                                                                />
                                                            )}
                                                            <a
                                                                href="#"
                                                                onClick={
                                                                    formData.isChecked
                                                                        ? toggleVisibilityConfirmPassword
                                                                        : () => {
                                                                              return false;
                                                                          }
                                                                }
                                                                className={`${IconClassConfirmPassword} top35`}
                                                            >
                                                                {" "}
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="loginbuttoncont change-password-btn-section">
                                                        <div className="dropdownFooter">
                                                            <div className="k-form-buttons">
                                                                <Button
                                                                    className="manage-profile-btn"                                                               
                                                                    onClick={redirectTo}
                                                                    type="button"
                                                                >
                                                                    Cancel
                                                                </Button>
                                                                <Button
                                                                    type={
                                                                        "submit"
                                                                    }
                                                                    disabled={isDisabledBtn}
                                                                    className="btn-active bttn"
                                                                    form="userChangePasswordForm"
                                                                >
                                                                    Update
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </FormElement>
                                </div>
                            </div>
                        )}
                    />
                </div>
            </div>
        </>
    );
};
export default React.memo(ManageProfile);
