import React from "react";
import { KpiTableData } from "../dataRows/dataRowsGrid";

const DataRows = (restProps) => {
  //disable in-cell update and cancel button
  return (
    <>
      <div className="dtu-file-container plancheckboxgrid plan-padding">
        <KpiTableData
          pageProps={restProps.pageProps}
          compTableMasterKey={restProps.compTableMasterKey}
          effVersionDataItem={restProps.effVersionDataItem}
          selectedTab={restProps.selected}
          isKpiUpdateClick={restProps.isKpiUpdateClick}
          isCancelClick={restProps.isCancelClick}
          setCancelProviderAwaitStatus={restProps.setCancelProviderAwaitStatus}
          cancelKpiAwaitStatus={restProps.cancelKpiAwaitStatus}
          setCancelKpiAwaitStatus={restProps.setCancelKpiAwaitStatus}
          isKpiEdit={restProps.isKpiEdit}
          setisKpiEdit={restProps.setisKpiEdit}
          setScreenLock={restProps.setScreenLock}
          screenLock={restProps.screenLock}
          kpiColumnsData={restProps.kpiColumnsData}
          setKpiColumnsData={restProps.setKpiColumnsData}
          isKpiCheckColumn={restProps.isKpiCheckColumn}
          setKpiColumnCheck={restProps.setKpiColumnCheck}
          dateVersion={restProps.dateVersion}
          isReload={restProps.isReload}
          setReload={restProps.setReload}
          isRefreshBtnClick={restProps.isRefreshBtnClick}
          setRefreshBtnClick={restProps.setRefreshBtnClick}
          setisKpiUpdateClick={restProps.setisKpiUpdateClick}
        />
      </div>
    </>
  );
};

export default React.memo(DataRows);
