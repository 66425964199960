import React from "react";
import { useLocation } from "react-router-dom";
import useServiceApi from "./service";
import { setNewMessage } from "../../redux/actions/setting-actions";
import { toast, ToastContainer } from "react-toastify";
import { CreateAuthCookie } from "src/utils/loginUtils";
import { getLocalStorage } from "../../lib/cookies";
import "react-toastify/dist/ReactToastify.css";

const SsoAuthentication = () => {
    const location = useLocation();
    const search = location.search;
    const searchClass = new URLSearchParams(search);

    const [List, Action] = useServiceApi();

    let tokenParam = searchClass.get("token");

    const [errorParam, setErrorParam] = React.useState(
        searchClass.get("error")
    );

    const processEnv = process.env;
    const localStorageData = getLocalStorage(
        processEnv.REACT_APP_CREDENTIAL_KEY
    );

    React.useEffect(async () => {
        if (!errorParam && tokenParam) {
            await handleAuth({ in_sso_token: tokenParam });
            tokenParam = "";
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tokenParam]);

    React.useEffect(async () => {
        errorParam &&
            toast.error(
                <div className="maintainancemessage-wrapper">
                    {errorParam.includes("<CLOSE_LINK>") ? (
                        <>
                            {errorParam.replace("<CLOSE_LINK>", "")} <br />
                            <span
                                className="link-class"
                                title="Close this window"
                                onClick={() => {
                                    window.close();
                                }}
                            >
                                Close this window
                            </span>{" "}
                            and change ProCARE user or IdP user
                        </>
                    ) : (
                        errorParam
                    )}
                </div>,
                {
                    position: "top-center",
                    autoClose: false,
                    hideProgressBar: false,
                    closeButton: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                    className: "error-page",
                    containerId: "error-page",
                }
            );
    }, [errorParam]);

    const handleAuth = async (dataItem) => {
        const reqObj = {
            in_username: localStorageData.out_user_name,
            in_sso_token: dataItem?.in_sso_token,
        };

        const apiResponse = await Action.authenticateAsync(reqObj);
		const response = apiResponse?.data?.out_parameters || {};

        if (response?.out_ret_code > 0) {
            let errorMsg = response?.out_error_msg;
            if (response?.out_ret_code === 3) {
                errorMsg += ". <CLOSE_LINK>";
            }
            setErrorParam(errorMsg);
        }

        if (response?.out_secret_token) {
            CreateAuthCookie(response);

            setTimeout(() => {
                window.close();
            });
        }
    };

    return <div className="login-main" id="error-page"></div>;
};

export default React.memo(SsoAuthentication);
