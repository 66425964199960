/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-loop-func */
import React from "react";
import {
    GridColumn as Column,
    getSelectedState,
} from "@progress/kendo-react-grid";
import { getUserCredential } from "../../lib/cookies";
import { useDispatch } from "react-redux";
import {
    Form,
    FormElement,
    FieldWrapper
} from "@progress/kendo-react-form";
import CustomDropdown from "../../components/customDropdown";
import { Label, Error } from "@progress/kendo-react-labels";
import {
    GetDataTypes,
    GetPeriodsDataList,
    GetPeriodTypeAPI,
} from "../../lib/common-service";
import { useHistory } from "react-router";
import MessageSchema from "../../utils/messageSchema";
import {
    INSERT_CUSTOM_TABLE,
    INSERT_CUSTOM_TABLE_COLUMNS,
} from "../../api-setup/api-endpoints";
import { generateHeader, POSTAPI } from "../../api-setup/api-helper";
import { CellRender, RowRender } from "../../components/renderers";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { setNewMessage } from "../../redux/actions/setting-actions";
import { DialogComponent } from "../../components/dialog";
import { Input, TextArea } from "@progress/kendo-react-inputs";
import { microTime } from "../../utils/utils";
import DataTable from "../../components/dataTable";
import { columnMenuProps } from "../../components/customColumnMenu";
import { getter } from "@progress/kendo-data-query";
import { HeaderPage } from "../base/headerPage";
import { Window, WindowActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";

const EDIT_FIELD = "inEdit";

const DATA_ITEM_KEY = "in_custom_table_mstr_key";
const DATA_TABLE_ID = "custom-config-table-grid";
const editField = "inEdit";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

export const CUSTOM_TABLE_TYPE = [
    {
        id: 'standard',
        text: 'Standard'
    },
    {
        id: 'hierarchical',
        text: 'Hierarchical'
    },
]

export const gridColumns = [
    {
        field: "expand",
        minWidth: 40,
        show: true,
        title: "ACTION",
    },
    {
        field: "in_custom_col_name",
        minWidth: 100,
        show: true,
        title: "FIELD",
    },
    {
        field: "in_custom_col_desc",
        minWidth: 100,
        show: true,
        title: "DESCRIPTION",
    },
    {
        field: "in_custom_data_type",
        minWidth: 100,
        show: true,
        title: "DATA TYPE",
    },
    {
        field: "in_is_key",
        minWidth: 100,
        show: true,
        title: "KEY",
    },
    {
        field: "in_custom_col_order",
        minWidth: 100,
        show: true,
        title: "FIELD ORDER",
    },
];

const ModalTitle = () => {
    return <span>Add Custom Table</span>;
};

const checkEmptyColumns = (obj) => {
    let isValid = true;
    if (obj.in_custom_col_name === "" || obj.in_custom_data_type === "") {
        isValid = false;
    }
    return isValid;
};

let initialObj = {
    in_custom_table_mstr_key: "",
    in_custom_col_name: "",
    in_custom_col_desc: "",
    in_custom_col_order: 0,
    in_custom_data_type: "",
    in_is_key: false,
};

const checkBoxCell = (props) => {
    const { dataItem } = props;

    const handleChange = (e) => {
        if (props.onChange) {
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: e.syntheticEvent,
                value: e.target.checked,
            });
        }
    };
    return (
        <td title={dataItem[props.field]}>
            <input
                disabled={dataItem.disabled ? dataItem.disabled : false}
                type="checkbox"
                onChange={handleChange}
                value={dataItem[props.field]}
                checked={dataItem[props.field]}
            />
        </td>
    );
};

const AddCustomTable = (restProps) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [visible, setVisible] = React.useState(false);
    const cookiesData = getUserCredential();
    const [data, setData] = React.useState([]);
    const [periodsFields, setPeriodFields] = React.useState({
        periodsValue: {
            id: "",
            text: "",
        },
        periodsData: [],
        isPeriodDataLoading: true,
    });
    const [periodsTypeFields, setPeriodTypeFields] = React.useState({
        periodTypeValue: {
            in_period_mstr_key: "",
            in_period_start_date: "",
            in_period_end_date: "",
        },
        periodTypeData: [],
        isPeriodTypeDataLoading: true,
    });
    const [customTableTypeFields, setCustomTableTypeFields] = React.useState({
        customTableTypeValue: CUSTOM_TABLE_TYPE[0],
        customTableTypeData: CUSTOM_TABLE_TYPE,
    });
    const [periodTypeList, setPeriodTypeList] = React.useState([]);
    const [periodsList, setPeriodsList] = React.useState([]);
    const [errorObj, setErrorObj] = React.useState({});
    const [editData, setEditData] = React.useState({});
    const [dataTypes, setDataTypes] = React.useState([]);
    const [confirmationPopup, setConfirmationPopup] = React.useState({
        title: "Delete",
        desc: "Are you sure you want to delete this record?",
        isVisible: false,
        type: "",
        actionBtn: [],
        data: {},
        action: "Delete",
    });
    const [formFields, setFormFields] = React.useState({
        in_custom_table_desc: "",
        in_custom_table_name: "",
    });
    const [isEdit, setIsEdit] = React.useState(false);
    const [configErrorObj, setConfigErrorObj] = React.useState({});
    const [selectedState, setSelectedState] = React.useState({});
    const [deleteSelectedIds, setDeleteSelectedIds] = React.useState([]);
    const [modal, setModal] = React.useState(true)
    const [stageChange, setStateChange] = React.useState('DEFAULT')

    React.useEffect(() => {
        setVisible(restProps.visible);
        getPeriodsDataType();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getPeriodsDataType = async () => {
        let periodTypeData = {
            in_filter: "AND a.period_type_hierarchy_level IS NOT NULL",
            in_sort_number: null,
            in_sort_order: null,
        };
        let res = await GetPeriodTypeAPI(periodTypeData, dispatch, history);
        if (res.length > 0) {
            let tempArray = [];
            res.map((item) => {
                let obj = {};
                obj.text = item.period_type_name;
                obj.id = item.period_type_mstr_key;
                tempArray.push(obj);
                return 0;
            });
            getPeriodsDataList(tempArray[0].id);
            setPeriodTypeList(tempArray);
            setPeriodTypeFields({
                ...periodsTypeFields,
                periodTypeData: tempArray,
                periodTypeValue: tempArray[0],
                isPeriodTypeDataLoading: false,
            });
        } else {
            setPeriodTypeFields({
                ...periodsTypeFields,
                isPeriodTypeDataLoading: false,
            });
        }
    };

    const ShowError = React.useCallback(
        (props) => {
            return <Error>{errorObj[props.name]}</Error>;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [errorObj]
    );

    const getPeriodsDataList = async (id, resultDataValue) => {
        setPeriodFields({
            ...periodsFields,
            isPeriodDataLoading: true,
        });
        let periodsDataReq = {
            in_tenant_id: cookiesData.out_tenant_id,
            in_filter: `AND a.period_type_mstr_key = ${id}`,
            in_sort_number: null,
            in_sort_order: null,
            in_page_row_offset: 0,
            in_page_rows: 100,
            in_sort_number_concat:
                "a.period_end_date DESC, a.period_level_order DESC",
        };
        let resPeriods = await GetPeriodsDataList(
            periodsDataReq,
            dispatch,
            history
        );
        if (resPeriods.length > 0) {
            let tempArray = [];
            resPeriods.map((item) => {
                let obj = {};
                obj.text = item.period_name;
                obj.id = item.period_mstr_key;
                obj.in_period_start_date = item.period_start_date;
                obj.in_period_end_date = item.period_end_date;
                tempArray.push(obj);
                return 0;
            });
            setPeriodsList(tempArray);
            setPeriodFields({
                ...periodsFields,
                periodsData: tempArray,
                periodsValue: tempArray[0],
                isPeriodDataLoading: false,
            });
        } else {
            setPeriodFields({
                ...periodsFields,
                isPeriodDataLoading: false,
            });
            setPeriodsList([]);
        }
    };

    const onClosePopup = () => {
        setVisible(!visible);
        restProps.setShow({ ...restProps.visible, dtuLogs: false });
    };


    const handleValidation = (dataItem) => {
        let configError = {};
        let isValid = true;
        if (isValid) {
            isValid = false;
            errorObj.customTableValidate =
                data.length === 0 ? MessageSchema.customTableRowsValidate : "";
            if (data.length > 0) {
                isValid = false;
                let isCheckedKey = data.filter((x) => x.in_is_key === true);
                if (isCheckedKey.length > 0) {
                    isValid = true;
                }
                errorObj.customTableValidate =
                    isCheckedKey.length === 0
                        ? MessageSchema.customTableRowsRequired
                        : "";
            }
        }

        data.map((item, key) => {
            if (item.in_custom_col_name.trim() === "") {
                configError[
                    `in_custom_col_name_${item.rowId}`
                ] = `Name is required`;
                isValid = false;
            } else {

            }
            if (item.in_custom_data_type === "") {
                configError[
                    `in_custom_data_type_${item.rowId}`
                ] = `Data type is required`;
                isValid = false;
            }
            return 0;
        });


        setConfigErrorObj({ ...configError });
        if (formFields.in_custom_table_name === "") {
            isValid = false;
            errorObj.in_custom_table_name =
                MessageSchema.customTableNameRequired;
        }
        setErrorObj((preVState) => ({ ...preVState, errorObj }));
        return isValid;
    };

    const insertCustomTables = (addedKey) => {
        let tempData = [];
        data.map((item) => {
            let obj = {};
            obj["in_user_id"] = cookiesData.out_user_id;
            obj["in_tenant_id"] = cookiesData.out_tenant_id;
            obj["in_custom_table_mstr_key"] = addedKey;
            obj["in_custom_col_name"] = item.in_custom_col_name;
            obj["in_custom_col_desc"] = item.in_custom_col_desc;
            obj["in_custom_col_order"] = item.in_custom_col_order;
            obj["in_custom_data_type"] = item.in_custom_data_type
                ? dataTypes.find(
                    (x) => x.data_type_name === item.in_custom_data_type
                ).data_type_mstr_key
                : 0;
            obj["in_is_key"] = item.in_is_key ? 1 : 0;
            tempData.push(obj);
            return 0;
        });
        const bodyData = {
            insertColumnsArray: [...tempData],
        };
        POSTAPI(
            false,
            INSERT_CUSTOM_TABLE_COLUMNS,
            null,
            generateHeader(),
            bodyData,
            dispatch
        ).then((res) => {
            if (res && res.data.out_parameters.out_ret_code === 0) {
                handleCancel();
                restProps.setAPICall(true);
            } else {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
            }
        });
    };

    const handleCancel = () => {
        setVisible(false);
        setIsEdit(false);
        restProps.setShow({ ...restProps.visible, addCustomTable: false });
    };

    const exitEdit = (dataItem, index, dataIndex, e) => {
        const newData = data.map((item) => ({
            ...item,
            [EDIT_FIELD]: undefined,
        }));
        if (editData) {
            if (JSON.stringify(editData) === JSON.stringify(dataItem)) {
                setEditData("");
                setData(newData);
            }
        } else {
            setData(newData);
        }
    };

    const DropDownCell = React.useCallback(
        (props) => {
            const { ariaColumnIndex, columnIndex, dataItem, field, render } =
                props;
            const rowId = dataItem.rowId;
            const isInEdit = field === dataItem.inEdit;
            const value = field && dataItem[field] ? dataItem[field] : "";
            let valueData = dataItem.dataTypes;
            let dataValue = "";
            if (props && valueData.length > 0) {
                let value = valueData?.find(
                    (x) => x.data_type_name === dataItem[props.field]
                );
                dataValue = value ? value.data_type_name : "";
            }
            const handleChange = (e) => {
                if (props.onChange) {
                    props.onChange({
                        dataIndex: 0,
                        dataItem: props.dataItem,
                        field: props.field,
                        syntheticEvent: e.syntheticEvent,
                        value: e.target.value.data_type_name,
                    });
                }
            };
            const defaultRendering = (
                <td
                    aria-colindex={ariaColumnIndex}
                    data-grid-col-index={columnIndex}
                >
                    {isInEdit ? (
                        <div>
                            <DropDownList
                                style={{
                                    width: "150px",
                                }}
                                popupSettings={{
                                    height: "100px",
                                }}
                                onChange={handleChange}
                                value={
                                    valueData &&
                                    valueData.find(
                                        (c) => c.data_type_name === dataValue
                                    )
                                }
                                data={valueData}
                                textField="data_type_name"
                                placeholder={"Select"}
                                disabled={
                                    dataItem.disabled
                                        ? dataItem.disabled
                                        : false
                                }
                            />
                        </div>
                    ) : value ? (
                        <div>{dataValue.toString()}</div>
                    ) : (
                        ""
                    )}
                    {Object.keys(configErrorObj).length > 0 ? (
                        <div role="alert" className="k-form-error k-text-start">
                            {Object.keys(configErrorObj).length > 0
                                ? configErrorObj[`${field}_${rowId}`]
                                : ""}
                        </div>
                    ) : (
                        false
                    )}
                </td>
            );
            return render?.call(undefined, defaultRendering, props);
        },
        [configErrorObj]
    );

    const enterEdit = (dataItem, field) => {
        if (restProps.pageProps.writeAccess === 0) {
            return false;
        }
        let newData = data.map((item) => ({
            ...item,
            inEdit:
                item.in_custom_table_mstr_key ===
                    dataItem.in_custom_table_mstr_key
                    ? field
                    : undefined,
        }));
        setData(newData);
        setDeleteSelectedIds([...deleteSelectedIds, { 'id': dataItem.in_custom_table_mstr_key }])
    };

    // function removeInEdit(array) {
    //     const newArray = array.map((obj) => {
    //         const { inEdit, ...rest } = obj;
    //         return rest;
    //     });
    //     return newArray;
    // }


    const customCellRender = (td, props) => {
        return (
            <CellRender
                navigatable={true}
                originalProps={props}
                td={td}
                enterEdit={enterEdit}
                editField={editField}
            />);
    };

    const customRowRender = (tr, props) => (
        <RowRender
            originalProps={props}
            tr={tr}
            editField={EDIT_FIELD}
            errorObj={errorObj}
            exitEdit={exitEdit}
        />
    );

    const itemChange = React.useCallback(
        (event) => {
            const field = event.field || "";
            const newData = data.map((item) =>
                item.in_custom_table_mstr_key ===
                    event.dataItem.in_custom_table_mstr_key
                    ? { ...item, [field]: event.value, inItemEdit: true }
                    : {...item ,inItemEdit : false}
            );
            let editItem = event.dataItem;
            editItem[field] = event.value;
            delete editItem.inEdit;
            setEditData(editItem);
            setData(newData);
            setIsEdit(true)
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [data]
    );

    const remove = (id) => {
        setDeleteSelectedIds([{ "id": id }]);
        setConfirmationPopup({
            ...confirmationPopup,
            isVisible: true,
            type: "DELETE_CONFIRM",
            actionBtn: [
                {
                    name: "Cancel",
                    onClick: onCancelConfirm,
                },
                {
                    name: "Yes",
                    onClick: () => {
                        onDeleteConfirm(id);
                    },
                },
            ],
            data: id,
            action: "Delete",
        });
    };

    const ActionCell = (props) => {
        return (
            <td className="text-center" title="Delete">
                {!props.dataItem.showDeleteIcon ?
                    <button className="k-button k-grid-save-command delete-i" onClick={() => { remove(props.dataItem.in_custom_col_mstr_key); }}>
                        <i
                            title="Delete"
                            className="far fa-trash-alt"

                        ></i>
                    </button> : <span></span>}
            </td>
        );
    };


    const onDeleteConfirm = (id) => {
        if (id) {
            let newData = data.filter((x) => Number(x.in_custom_col_mstr_key) !== Number(id));
            setData(newData)
            setConfirmationPopup({
                title: "Delete",
                desc: "Are you sure you want to delete this record?",
                isVisible: false,
                type: "",
                actionBtn: [],
                data: {},
                action: "Delete",
            });
            setConfigErrorObj({})
            setSelectedState({});
            setDeleteSelectedIds([]);
        } else {
            const deleteArray = deleteSelectedIds.map((obj2) => Number(obj2.id));
            const newData = data.filter((item) => !deleteArray.includes(Number(item.in_custom_table_mstr_key)));
            setData(newData);
            setConfirmationPopup({
                title: "Delete",
                desc: "Are you sure you want to delete this record?",
                isVisible: false,
                type: "",
                actionBtn: [],
                data: {},
                action: "Delete",
            });
            setConfigErrorObj({})
            setSelectedState({});
            setDeleteSelectedIds([]);
        }

    };

    const onCancelConfirm = () => {
        setConfirmationPopup({
            ...confirmationPopup,
            isVisible: false,
            type: "",
            actionBtn: [],
            data: {},
            action: "",
        });
    };

    const addNewRow = async () => {
        setErrorObj({});
        let reqData = {
            in_filter: null,
            in_sort_number: 1,
            in_sort_order: "ASC",
        };
        let dataTypesRes = [];
        if (dataTypes.length === 0) {
            dataTypesRes = await GetDataTypes(reqData, history, dispatch);
            setDataTypes(dataTypesRes);
        }
        let dataIndex =
            data.length > 0 ? data.length - 1 : data.length === 0 ? 0 : -1;
        if (dataIndex > -1) {
            let isValid =
                data.length > 0 ? checkEmptyColumns(data[0]) : !data.length;
            let uniqueValid = handleUniqueValid()
            if (isValid && uniqueValid) {
                setConfigErrorObj({})
                let initObj = JSON.parse(JSON.stringify(initialObj));
                initObj.in_custom_col_mstr_key = microTime();
                initObj.in_custom_table_mstr_key = microTime() + 1;
                initObj.dataTypes = dataTypesRes.length > 0 ? dataTypesRes : dataTypes;
                initObj.in_custom_col_order = data[0] ? data[0].in_custom_col_order + 1 : 1;
                initObj.in_custom_data_type = ""
                initObj.inEdit = 'in_custom_col_name'
                let tempData = [...data]
                tempData.unshift(initObj);
                let index = tempData.findIndex((x) => x.in_custom_col_mstr_key === initObj.in_custom_col_mstr_key)
                tempData[index].rowId = tempData.length - 1
                let findIndex = tempData.findIndex((x) => x.inItemEdit === true && x.in_custom_col_mstr_key !== initObj.in_custom_col_mstr_key);
                if (findIndex > -1) {
                    tempData[findIndex].inEdit = undefined
                }
                setData([...tempData]);
                setDeleteSelectedIds([{ 'id': initObj.in_custom_table_mstr_key }])
                setSelectedState({})
            }
            const elements = document.getElementsByClassName("table-input");
            if (elements.length) {
                elements[0].focus();
            }
        }
        setIsEdit(true)
    };

    const handleUniqueValid = () => {
        let isValid = true
        let configError = {}
        const nameMap = new Map();
        for (const item of data) {
            const lowerCaseName = item.in_custom_col_name.toLowerCase().trim();
            if (nameMap.has(lowerCaseName)) {
                data.filter((x) => x.inItemEdit === true).map((item) => {
                    configError[
                        `in_custom_col_name_${item.rowId}`
                    ] = `Name should be unique`;
                    isValid = false
                    return 0;
                })
                setConfigErrorObj({ ...configError })
            }
            nameMap.set(lowerCaseName, true);
        }

        return isValid
    }

    const handleSubmit = (dataItem) => {
        let isValid = handleValidation(formFields);
        let uniqueValid = handleUniqueValid(formFields)
        if (isValid && uniqueValid) {
            let dataObj = {
                in_user_id: cookiesData.out_user_id.toString(),
                in_tenant_id: cookiesData.out_tenant_id,
                in_custom_table_mstr_key: null,
                in_custom_table_name: formFields.in_custom_table_name.trim(),
                in_custom_table_desc: formFields.in_custom_table_desc.trim(),
                in_period_mstr_key: periodsFields.periodsValue.id,
                in_custom_table_type:
                    customTableTypeFields.customTableTypeValue.id === "standard" ? "S" : "H",
            };
            POSTAPI(
                false,
                INSERT_CUSTOM_TABLE,
                null,
                generateHeader(),
                dataObj,
                dispatch
            ).then((res) => {
                if (res && res.data.out_parameters.out_ret_code === 0) {
                    insertCustomTables(
                        res.data.out_parameters.out_last_insert_key
                    );
                } else {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                }
            });
        }
    };
    const handleChange = (e) => {
        setFormFields({
            ...formFields,
            [e.target.name]: e.value ? e.value : e.target.value || '',
        });
        setErrorObj({
            ...errorObj,
            [e.target.name]: "",
        });
        setIsEdit(true);
    };

    const handleCancelBtn = () => {
        setIsEdit(false);
        setFormFields({
            ...formFields,
            in_custom_table_desc: "",
            in_custom_table_name: "",
        });
        setData([]);
        setErrorObj({});
        restProps.setShow({ ...restProps.visible, addCustomTable: false });
    };

    const clearData = async () => {
        setData([]);
        setConfirmationPopup({
            ...confirmationPopup,
            isVisible: false,
            type: "",
            actionBtn: [],
            data: "",
            action: "",
        });
        setDeleteSelectedIds([])
        setSelectedState({})
        setErrorObj({});
        let reqData = {
            in_filter: null,
            in_sort_number: 1,
            in_sort_order: "ASC",
        };
        let dataTypesRes = [];
        if (dataTypes.length === 0) {
            dataTypesRes = await GetDataTypes(reqData, history, dispatch);
            setDataTypes(dataTypesRes);
        }
        let dataIndex =
            data.length > 0 ? data.length - 1 : data.length === 0 ? 0 : -1;
        if (dataIndex > -1) {
            let initialDataArray = [];
            if (dataTypesRes.length > 0 || dataTypes.length > 0) {
                initialDataArray = [
                    {
                        in_custom_table_mstr_key: microTime() + 1,
                        in_custom_col_name: "Node Name",
                        in_custom_col_desc: "",
                        in_custom_col_order: 2,
                        in_custom_data_type: "String",
                        in_is_key: true,
                        dataTypes: dataTypesRes,
                        disabled: true,
                        showDeleteIcon: true,
                    },
                    {
                        in_custom_table_mstr_key: microTime(),
                        in_custom_col_name: "Node",
                        in_custom_col_desc: "",
                        in_custom_col_order: 1,
                        in_custom_data_type: "String",
                        in_is_key: true,
                        dataTypes: dataTypesRes,
                        disabled: true,
                        showDeleteIcon: true,
                    },
                ];
            }
            setData(initialDataArray);
        }
        setIsEdit(true);
    };

    const onYesBtnClick = () => {
        setConfirmationPopup({
            ...confirmationPopup,
            isVisible: false,
            type: "WARNING",
            actionBtn: [],
            data: "",
            action: "",
        });
        setData([...[]]);
        setIsEdit(true);
    };

    const handleCustomTypeDrpChange = async (e,name) => {   
        if (e.id === "hierarchical") {
            if (data.length > 0) {
                setConfirmationPopup({
                    ...confirmationPopup,
                    title: "Data Will be lost",
                    isVisible: true,
                    type: "WARNING",
                    actionBtn: [
                        {
                            name: "Cancel",
                            onClick: onCancelConfirm,
                        },
                        {
                            name: "Yes",
                            onClick: () => {
                                setIsEdit(true);
                                setCustomTableTypeFields({
                                    ...customTableTypeFields,
                                    [name]: e,
                                });
                                clearData();
                            },
                        },
                    ],
                    data: "",
                    action: "Delete",
                });
            } else {
                setIsEdit(true);
                setCustomTableTypeFields({
                    ...customTableTypeFields,
                    [name]: e,
                });
                clearData();
            }
        } else if (e.id === "standard") {
            setConfirmationPopup({
                ...confirmationPopup,
                title: "Data Will be lost",
                isVisible: true,
                type: "WARNING",
                actionBtn: [
                    {
                        name: "Cancel",
                        onClick: onCancelConfirm,
                    },
                    {
                        name: "Yes",
                        onClick: () => {
                            setIsEdit(true);
                            setCustomTableTypeFields({
                                ...customTableTypeFields,
                                [name]: e,
                            });
                            onYesBtnClick();
                        },
                    },
                ],
                data: "",
                action: "Delete",
            });
        }
    };

    const NumberCell = React.useCallback(
        (props) => {
            const { ariaColumnIndex, columnIndex, dataItem, field, render } =
                props;
            let isInEdit = field === dataItem.inEdit;

            let value = props.field + dataItem.rowId;
            const handleChange = (e, props, value) => {
                if (props.onChange) {
                    props.onChange({
                        // dataIndex: 0,
                        dataItem: props.dataItem,
                        field: props.field,
                        syntheticEvent: e.syntheticEvent,
                        value:
                            parseFloat(e.target.value) < 0 ? 0 : e.target.value,
                    });
                }
            };

            const defaultRendering = (
                <td
                    aria-colindex={ariaColumnIndex}
                    data-grid-col-index={columnIndex}
                >
                    {isInEdit ? (
                        <Input
                            disabled={
                                dataItem.disabled ? dataItem.disabled : false
                            }
                            value={dataItem[props.field]}
                            type="number"
                            onChange={(e) => {
                                handleChange(e, props, value);
                            }}
                            name={`${props.field}`}
                            autoComplete="off"
                        />
                    ) : (
                        <span title={props.dataItem[props.field || ""]}>
                            {props.dataItem[props.field || ""]}
                        </span>
                    )}
                </td>
            );
            return render?.call(undefined, defaultRendering, props);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const NameCell = React.useCallback(
        (props) => {
            const { ariaColumnIndex, columnIndex, dataItem, field, render } =
                props;
            let isInEdit = field === dataItem.inEdit;
            const rowId = dataItem.rowId;
            let value = props.field;
            const handleChange = (e, props, value) => {
                if (props.onChange) {
                    props.onChange({
                        // dataIndex: 0,
                        dataItem: props.dataItem,
                        field: props.field,
                        syntheticEvent: e.syntheticEvent,
                        value: e.target.value,
                    });
                }
            };

            const defaultRendering = (
                <td
                    aria-colindex={ariaColumnIndex}
                    data-grid-col-index={columnIndex}
                >
                    {isInEdit ? (
                        <Input
                            disabled={
                                dataItem.disabled ? dataItem.disabled : false
                            }
                            value={dataItem[props.field]}
                            onChange={(e) => {
                                handleChange(e, props, value);
                            }}
                            name={`${props.field}`}
                            autoComplete="off"
                            className="table-input"
                            autoFocus={true}
                        />
                    ) : (
                        <span title={props.dataItem[props.field || ""]}>
                            {props.dataItem[props.field || ""]}
                        </span>
                    )}
                    {Object.keys(configErrorObj).length > 0 ? (
                        <div role="alert" className="k-form-error k-text-start">
                            {Object.keys(configErrorObj).length > 0
                                ? configErrorObj[`${value}_${rowId}`]
                                : ""}
                        </div>
                    ) : (
                        false
                    )}
                </td>
            );
            return render?.call(undefined, defaultRendering, props);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [configErrorObj]
    );
    const useCol = (columns) => {
        return columns.map((column, idx) => {
            return column.show && column.title !== "" ? (
                <Column
                    {...columnMenuProps(column)}
                    key={idx}
                    sortable={false}
                    cell={
                        column.field === "expand"
                            ? ActionCell
                            : column.field === "in_custom_data_type"
                                ? DropDownCell
                                : column.field === "in_is_key"
                                    ? checkBoxCell
                                    : column.field === "in_custom_col_order"
                                        ? NumberCell
                                        : NameCell
                    }
                    resizable={column.field === "expand" ? false : true}
                    width={column.minWidth}
                />
            ) : (
                false
            );
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    let CustomColumn = useCol(gridColumns);

    const onSelectionChange = (event) => {
        const newSelectedState = getSelectedState({
            event,
            selectedState: selectedState,
            dataItemKey: "in_custom_table_mstr_key",
        });
        setSelectedState(newSelectedState);
        let tempArray = [];
        let tempData = [];
        Object.keys(newSelectedState).map((item) => {
            if (newSelectedState[item] === false) {
                return 0;
            }
            let obj = {};
            obj.id = Number(item);
            tempArray.push(obj);
            tempData.push(obj);
            return 0;
        });
        let newData = [...data]

        newData.forEach((item) => {
            const itemKey = item.in_custom_table_mstr_key;
            if (tempArray.some(obj => obj.id === Number(itemKey))) {
                if (item.in_custom_col_name === 'Node' || item.in_custom_col_name === 'Node Name') {
                    tempData = tempData.filter((x) => x.id !== Number(itemKey))
                }
            }
        })

        setDeleteSelectedIds([...tempData])
    };



    const AddNewRow = () => {
        return (
            <>
                <a
                    className="cta-small"
                    onClick={(e) => {
                        addNewRow(e);
                    }}
                >
                    <i className="fas fa-plus"></i> Add New Field
                </a>
            </>
        );
    };

    const handleMenuItemClick = (action) => {
        switch (action) {
            case "delete":
                remove();
                break;
            default:
                break;
        }
    };

    const headerConfig = {
        title: "Configure Custom Table",
        id: "config_custom_tables",
        add_new_fx: AddNewRow,
        action_menu: {
            delete: { fx: handleMenuItemClick },
        },
    };

    const parent = document.querySelector("#content-" + headerConfig.id);

    if (parent) {
        const deleteRows = parent.querySelector(".delete-rows");
        deleteRows && deleteRows.disableKendoMenu();
        if (deleteSelectedIds.length > 0) {
            deleteRows && deleteRows.enableKendoMenu();
        }
    }

    const handleStageChange = (e) => {
        setStateChange(e.state)
        if (e.state === 'MINIMIZED') {
            setModal(false)
        } else if (e.state === 'DEFAULT') {
            setModal(true)
        }
    };

    return (
        <>
            <Window
                className={"Dialog-dtulogs Dialog-lModal"}
                title={<ModalTitle />}
                onClose={onClosePopup}
                width={1024}
                height={700}
                resizable={false}
                stage={stageChange}
                onStageChange={handleStageChange}
                modal={modal}
            >
                <Form
                    render={(formRenderProps) => (
                        <FormElement
                            className="addcustomtableform"
                            horizontal={true}
                        >
                            <FieldWrapper>
                                <div className={"k-form-field-wrap-popup"}>
                                    <Label>{"Custom Table Type : "}</Label>
                                    <CustomDropdown
                                        name={"customTableTypeValue"}
                                        data={customTableTypeFields.customTableTypeData}
                                        textField="text"
                                        onOpen={(dataValue) => {
                                            setCustomTableTypeFields({
                                                ...customTableTypeFields,
                                                customTableTypeData: dataValue,
                                            });
                                        }}
                                        dataItemKey="id"
                                        valueData={CUSTOM_TABLE_TYPE.find(
                                            (c) =>
                                                c.text ===
                                                customTableTypeFields
                                                    .customTableTypeValue.text
                                        )}
                                        onChange={(e, name) => {
                                            handleCustomTypeDrpChange(e, name)
                                        }}
                                        filterable={true}
                                        onFilterChange={(dataValue) => {
                                            setCustomTableTypeFields({
                                                ...customTableTypeFields,
                                                customTableTypeData: dataValue,
                                            });
                                        }}
                                        autoClose={false}
                                        autoBind={true}
                                        isSelectALl={true}
                                        dataList={CUSTOM_TABLE_TYPE}
                                    />
                                </div>
                            </FieldWrapper>
                            <FieldWrapper>
                                <fieldset className={"k-form-fieldset"}>
                                    <div className={"k-form-field-wrap-popup"}>
                                        <Label>{"Custom Table Name* : "}</Label>
                                        <Input
                                            name={"in_custom_table_name"}
                                            value={
                                                formFields.in_custom_table_name
                                            }
                                            onChange={handleChange}
                                           
                                        />
                                        <ShowError
                                            name={"in_custom_table_name"}
                                        />
                                    </div>
                                </fieldset>
                            </FieldWrapper>
                            <FieldWrapper>
                                <div className={"k-form-field-wrap-popup"}>
                                    <Label>{"Effective Period* : "}</Label>
                                    <CustomDropdown
                                        name={"periodTypeValue"}
                                        data={periodsTypeFields.periodTypeData}
                                        textField="text"
                                        onOpen={(dataValue) => {
                                            setPeriodTypeFields({
                                                ...periodsTypeFields,
                                                periodTypeData: dataValue,
                                            });
                                        }}
                                        dataItemKey="id"
                                        valueData={periodTypeList.find(
                                            (c) =>
                                                c.text ===
                                                periodsTypeFields
                                                    .periodTypeValue.text
                                        )}
                                        onChange={(e, name) => {
                                            setIsEdit(true);
                                            setPeriodTypeFields({
                                                ...periodsTypeFields,
                                                [name]: e,
                                            });
                                            getPeriodsDataList(e.id, e);
                                        }}
                                        filterable={true}
                                        onFilterChange={(dataValue) => {
                                            setPeriodTypeFields({
                                                ...periodsTypeFields,
                                                periodTypeData: dataValue,
                                            });
                                        }}
                                        autoClose={false}
                                        autoBind={true}
                                        isSelectALl={true}
                                        dataList={periodTypeList}
                                        loading={
                                            periodsTypeFields.isPeriodTypeDataLoading
                                        }
                                    />
                                </div>
                            </FieldWrapper>
                            <FieldWrapper>
                                <div className={"k-form-field-wrap-popup"}>
                                    <Label>{"Period Type* : "}</Label>
                                    <CustomDropdown
                                        name={"periodsValue"}
                                        data={periodsFields.periodsData}
                                        textField="text"
                                        onOpen={(dataValue) => {
                                            setPeriodFields({
                                                ...periodsFields,
                                                periodsData: dataValue,
                                            });
                                        }}
                                        dataItemKey="id"
                                        valueData={periodsList.find(
                                            (c) =>
                                                c.text ===
                                                periodsFields.periodsValue.text
                                        )}
                                        onChange={(e, name) => {
                                            setIsEdit(true);
                                            setPeriodFields({
                                                ...periodsFields,
                                                [name]: e,
                                            });
                                        }}
                                        filterable={true}
                                        onFilterChange={(dataValue) => {
                                            setPeriodFields({
                                                ...periodsFields,
                                                periodsData: dataValue,
                                            });
                                        }}
                                        autoClose={false}
                                        autoBind={true}
                                        isSelectALl={true}
                                        dataList={periodsList}
                                        loading={
                                            periodsFields.isPeriodDataLoading
                                        }
                                    />
                                </div>
                            </FieldWrapper>
                            <FieldWrapper>
                                <fieldset className={"k-form-fieldset"}>
                                    <div className={"k-form-field-wrap-popup"}>
                                        <Label>{"Description : "}</Label>
                                        <TextArea
                                            rows={2}
                                            type={"text"}
                                            name={"in_custom_table_desc"}
                                            value={
                                                formFields.in_custom_table_desc
                                            }
                                            onChange={handleChange}
                                        />
                                    </div>
                                </fieldset>
                            </FieldWrapper>
                        </FormElement>
                    )}
                />
                <div className="error-bottom">
                </div>

                <div className="inner-title" style={{ paddingTop: "10px" }}>
                    <div className="popup-head">
                        <HeaderPage
                            pageProps={restProps.pageProps}
                            headerConfig={headerConfig}
                        />
                    </div>
                </div>

                <div className="error-bottom">
                    <ShowError name={"customTableValidate"} />
                </div>

                <div className="configuretable" style={{ paddingTop: "10px" }}>
                    <DataTable
                        isAPICalling={false}
                        initialLoading={true}
                        data={data.map((item) => ({
                            ...item,
                            [SELECTED_FIELD]: selectedState[idGetter(item)],
                        }))}
                        id={DATA_TABLE_ID}
                        editField={editField}
                        customColumn={CustomColumn}
                        columns={gridColumns}
                        dataItemKey={"in_custom_table_mstr_key"}
                        gridHeight={300}
                        module={"custom_table"}
                        rowRender={customRowRender}
                        cellRender={customCellRender}
                        itemChange={itemChange}
                        onSelectionChange={onSelectionChange}
                        isPagination={false}
                        reorderable={false}
                    />
                </div>
                <WindowActionsBar layout={"end"}>
                    <Button
                        type={"submit"}
                        className="k-button cancelbtn"
                        onClick={handleCancelBtn}
                    // disabled={!isEdit}
                    >
                        Cancel
                    </Button>
                    <Button
                        type={"submit"}
                        className="primarybtn k-button k-button-md k-rounded-md k-button-solid"
                        disabled={!isEdit}
                        onClick={handleSubmit}
                    >
                        Save
                    </Button>
                </WindowActionsBar>
            </Window>


            <DialogComponent
                title={confirmationPopup.title}
                onCancelConfirm={onCancelConfirm}
                width={"450"}
                height={"auto"}
                className={"Dialog-Delete"}
                desc={confirmationPopup.desc}
                visible={confirmationPopup.isVisible}
                actionBtn={confirmationPopup.actionBtn}
                titleClassName={
                    confirmationPopup.action === "Delete"
                        ? "delete-confirm"
                        : "unSave-confirm"
                }
            />
        </>
    );
};
export default React.memo(AddCustomTable);
