import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import DataTable from "src/components/dataTable";
import { compPlanStatementSelector } from "src/redux/selectors/custom-selector";
import { GridColumn as Column } from "@progress/kendo-react-grid";
import { columnMenuProps } from "src/components/customColumnMenu";
import { IntlService } from "@progress/kendo-react-intl";
import useCompPlanStatementService from "./service";
import { getfilterDataString } from "src/utils/utils";
import { CommonHeaderCellTemplate } from "src/components/template/gridHeaderCellTemplate";
import { HeaderPage } from "../base/headerPage";
import { setKPIPaymentSummaryAction } from "src/redux/actions/compplan-actions";

const intl = new IntlService("en");

const KPIPaymentSummary = (props) => {
  const { isExporting, isReportRunning } = props;

  const [gridColumns, setGridColumns] = useState([]);
  const { kpiPaymentSummary } = useSelector(
    compPlanStatementSelector,
    shallowEqual
  );

  const dispatch = useDispatch();

  const reportService = useCompPlanStatementService();
  const [sortField, setSortField] = React.useState([]);
  const [filterField, setFilterField] = React.useState({
    logic: "and",
    filters: [],
  });

  const [filterData, setFilterData] = React.useState({
    isSort: false,
    isFilter: false,
    data: null,
    in_sort_number: 2,
    sort_order: "ASC",
  });

  useEffect(() => {
    setGridColumns(kpiPaymentSummary.columns);
  }, [kpiPaymentSummary]);

  const parseVal = (fieldVal, format) => {
    const numberVal = intl.parseNumber(fieldVal || 0);
    const val = intl.format(format, numberVal);
    return val;
  };

  const getInSortNumber = () => {
    return filterData.isSort ? filterData.in_sort_number : 2;
  };

  const getInSortOrder = () => {
    return filterData.isSort ? filterData.sort_order : "ASC";
  };

  const handleColumnMenu = (columnMenuData, isFilter, isSort, filterObj) => {
    if (isSort) {
      setSortField(columnMenuData);
    } else {
      setFilterField(filterObj);
    }

    let index = 1;

    if (columnMenuData[0] !== undefined) {
      let obj = kpiPaymentSummary?.columns.map((it) => it.field);
      let sortNameField = "";

      //replace mstr key with name field only when sorting is applied
      if (
        columnMenuData[0].field &&
        columnMenuData[0].field.indexOf("_mstr_key")
      ) {
        let sortKeyField = columnMenuData[0].field;
        sortNameField = sortKeyField.replace("_mstr_key", "_name");
      }

      const sortOriginField =
        sortNameField !== "" ? sortNameField : columnMenuData[0].field;

      index = obj.findIndex((x) => x === sortOriginField) + 1;
    }

    const filterString = isFilter
      ? getfilterDataString(columnMenuData, gridColumns)
      : filterData.data;

    isFilter = false;
    if (filterString !== "") {
      isFilter = true;
    }

    let sortDir =
      filterData.isSort && columnMenuData.length
        ? filterData.sort_order
        : "ASC";
    let sortNumber =
      filterData.isSort && columnMenuData.length
        ? filterData.in_sort_number
        : 2;

    isSort = !columnMenuData.length ? false : filterData.isSort;

    if (
      columnMenuData[0] !== undefined &&
      columnMenuData[0].dir !== undefined
    ) {
      isSort = true;
      sortDir = columnMenuData[0].dir.toUpperCase();
      sortNumber = index;
    }

    const dataVal = {
      in_filter: isFilter ? filterString : null,
      in_sort_number: sortNumber,
      in_sort_order: sortDir,
    };

    setFilterData({
      ...filterData,
      isFilter: isFilter,
      isSort: isSort,
      data: filterString,
      in_sort_number: sortNumber,
      sort_order: sortDir,
    });

    // setAPICallingState(true);
    reportService.fetchPaymentSummary(dataVal);
    // getGridData(dataVal, isFilter, isSort);
  };

  const onClearFilter = () => {
    setFilterField({ logic: "and", filters: [] });
    setFilterData({
      ...filterData,
      data: null,
      isFilter: false,
      in_filter: null,
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
    });

    //set in_params
    const dataVal = {
      in_filter: null,
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
    };
    reportService.fetchPaymentSummary(dataVal);
    //set filter to false
    //getGridData(dataVal, true);
  };

  const FooterCell = (props) => {
    const filterdTotal = kpiPaymentSummary.data
      .filter((item) => props.col.type === "number")
      .reduce((acc, item) => acc + item[props.field], 0);
      
    return (
      <td
        colSpan={props.colSpan}
        style={{ ...props.style, backgroundColor: "#7AC143", color: "black" }}
      >
        {/* && !props.col.footerTemplate */}
        {props.ariaColumnIndex === 1 
          ? <>Total</>
          : props.col.format && props.col.footerTemplate && filterdTotal !== 0
          ?   parseVal(filterdTotal, props.col.format)
          : ""}
      </td>
    );
  };

  const HeaderCell = (props) => {
    return (
      <span className="k-cell-inner">
        <span className="k-link">
          <span
            data-field={props.field}
            className="k-column-title-export-header"
          >
            {props.title}
          </span>
        </span>
      </span>
    );
  };

  const useCol = (columns) => {
    return columns.map((col, idx) => {
      if (!col.show || col.title === "") return false;
      return (
        <Column
          {...columnMenuProps(col)}
          key={idx}
          reorderable={false}
          sortable
          headerCell={isExporting || isReportRunning ? HeaderCell : CommonHeaderCellTemplate}
          children={col.childCols}
          resizable={true}
          width={130}
          footerCell={(props) => <FooterCell {...props} col={col} />}
        />
      );
    });
  };

  const cellRender = (tdElement, cellProps) => {
    if (tdElement && cellProps.rowType === "data") {
      const dataVal = (
        <span title={cellProps.dataItem[cellProps.field]}>
          {typeof cellProps.dataItem[cellProps.field] === "number" && cellProps.format
            ? parseVal(cellProps.dataItem[cellProps.field], cellProps.format)
            : cellProps.dataItem[cellProps.field]}
        </span>
      );
      return React.cloneElement(tdElement, tdElement.props, dataVal);
    }
    return tdElement;
  };

  let GroupColumn = useCol(gridColumns);

  const updateColumnStringData = (data) => {
    let columnString = "";
    data.forEach((element, index) => {
      let field = element.field.replace("egt_", "");
      let currentString = `"${element.gname}","comp_report_grid","${
        element.alias ? element.alias + "." : ""
      }${field}",${index},${element.show ? 1 : 0}`;

      if (index === 0) {
        columnString = currentString;
      } else {
        columnString = `${columnString}|${currentString}`;
      }
    });

    // reportsServiceActions
    //   .compDetailUpdateColumnOrder(columnString)
    //   .then(() => {
    //     dispatch(setReportsCompDetailSection2DataColumns(data));
    //   })
    //   .finally(() => {
    //     dispatch(setReportsCompDetailSection2DataLoading(false));
    //   });
  };

  const headerConfig = {
    title: "KPI Payment Summary",
    id: "comp_plan_kpi_payment_summary",
    manage_col: {
      columnsData: [...kpiPaymentSummary.columns],
      setColumnsData: (columnsList) => {
        const newItems = kpiPaymentSummary.columns.map((it) => {
          const dataITem = columnsList.find((item) => item.field === it.field);
          const newItem = { ...it };
          if (dataITem) {
            newItem.show = dataITem.show;
          }
          return newItem;
        });
        updateColumnStringData(columnsList);
        dispatch(
          setKPIPaymentSummaryAction({
            columns: newItems,
            data: kpiPaymentSummary.data,
          })
        );
      },
      setColumnCheck: () => null,
    },
  };

  return (
    <div>
      <div className="content-head">
        <HeaderPage pageProps={props} headerConfig={headerConfig} />
      </div>

      <DataTable
        gridHeight=""
        cellRender={cellRender}
        initialLoading={true}
        columns={kpiPaymentSummary.columns}
        data={kpiPaymentSummary.data}
        customColumn={GroupColumn}
        id="comp-plan-kpi-summary-grid"
        isPagination={false}
        onClearFilter={onClearFilter}
        initialFilter={filterField}
        initialSort={sortField}
        reorderable={false}
        scrollable={isExporting ? "scrollable": "scrollable"}
        handleColumnMenu={handleColumnMenu}
      />
    </div>
  );
};

export default KPIPaymentSummary;
