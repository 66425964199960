import { SET_DB_OBJ_DATA, SET_READ_WRITE_ACCESS } from "../actions/main-actions";

const initialState = {
  dbObjData: [],
  readAccessDeniend: [],
  writeAccessDeniend: []
};

const mainReducer = (state = initialState, action) => {
  switch(action.type) {
    case SET_DB_OBJ_DATA:
      return {
        ...state,
        dbObjData: action.data,
      };
    case SET_READ_WRITE_ACCESS:
      return {
        ...state,
        readAccessDeniend: action.data.readAccessDenied,
        writeAccessDeniend: action.data.writeAccessDenied
      }
    default:
      return state;
  }
}

export default mainReducer;

