/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import useServiceApi from "../service";
import { getLocalStorage } from "../../../lib/cookies";
import { getFlash } from "../../../lib/cookies";
import { useHistory } from "react-router";
import schema from "./schema/forgotPasswordSchema";
import { userNameValidator } from "./validator";
import { BackToLoginComponent } from "src/pages/common/BackToLoginComponent";
import { useDispatch } from "react-redux";
import { setNewMessage } from "src/redux/actions/setting-actions";

const ForgotPasswordForm = () => {
  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>
  );
  const dispatch = useDispatch();
  const formSchema = schema;
  const [List, Action] = useServiceApi();
  const { isRefreshLoading } = List;
  const processEnv = process.env;
  const localStorageData = getLocalStorage(processEnv.REACT_APP_CREDENTIAL_KEY);
  const [flashMessage, setFlashMessage] = useState('')

  const history = useHistory();

  const initialValues = {
    in_username: localStorageData.out_user_name,
  };

  useEffect(()=>{
    setFlashMessage(getFlash("forgot_password"))
  },[])
  /**
   * handle form validation
   */

  const handleSubmit = async (dataItem) => {
    const reqObj = {
      in_tenant_id: localStorageData?.out_tenant_id ?? "", //out_tenant_id from local storage
      in_username: dataItem.in_username ?? "",
    };

    let response = await Action.forgotPasswordAsync(reqObj);
    if (response?.data?.out_parameters?.out_ret_code == 0) {
      dispatch(
        setNewMessage({
          message: "Your Temporary Login Details Send In Your Register Mail Address.",
          type: "Success",
          show: true,
        })
      );
      history.push("/authentication");
    } else {
      setFlashMessage(response?.data?.out_parameters?.out_error_msg)
    }
  };

  const renderForgotPasswordForm = () => {
    return (
      <div className="">
        {/* <h1>Forgot Your Password?</h1> */}
        <Form
          initialValues={initialValues}
          onSubmit={handleSubmit}
          ignoreModified={true}
          validator={(value) => {
            return userNameValidator(value, formSchema);
          }}
          render={(formRenderProps) => (
            <div className="form">
              {isRefreshLoading && loadingPanel}

              <div className="formwrapper-elm-cont">
                <FormElement
                  className="formwrapper-form-cont"
                  horizontal={true}
                  id={"userForgotPasswordForm"}
                >
                  <div className="formwrapper-elm-cont">
                    <Field {...formSchema.in_username} />
                  </div>
                  <div className="flash-forgot-password">{flashMessage}</div>

                  <div className="k-form-buttons"></div>
                </FormElement>
              </div>
              <div className="loginbuttoncont">
                {renderBackToLogin()}
                <Button
                  type={"submit"}
                  className="btn-active bttn"
                  form="userForgotPasswordForm"
                >
                  Submit
                </Button>
              </div>
            </div>
          )}
        />
      </div>
    );
  };

  const renderBackToLogin = () => {
    return <BackToLoginComponent />;
  };

  return <>{renderForgotPasswordForm()}</>;
};

export default React.memo(ForgotPasswordForm);
