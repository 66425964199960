import React from "react";
import { DialogComponent } from "./dialog";
import { DELETE_BTN_ACTIONS } from "../constants/constants";

import {
  TabStrip,
  TabStripTab,
  Menu,
  MenuItem,
} from "@progress/kendo-react-layout";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { unSaveChangesPopupSelector } from "src/redux/selectors/custom-selector";
import { setUnSavedChangesPopup } from "src/redux/actions/setting-actions";

export const EffectiveVersions = (restProps) => {
  const dispatch = useDispatch();
  const { unsavedPopup } = useSelector(
    unSaveChangesPopupSelector,
    shallowEqual
  );
  const effVersionDetails = restProps?.versionData?.effVersionDetails;
  const effTabData = effVersionDetails?.data;
  const effMenuData = effVersionDetails?.periodsData;
  const { isShowRightMenu = true } = restProps;

  const [selectedIdx, setSelectedIdx] = React.useState(0);
  const [confirmationPopup, setConfirmationPopup] = React.useState({
    title: "Delete",
    desc: "Are you sure you want to delete this version?",
    isVisible: false,
    type: "",
    actionBtn: [],
    data: {},
    action: "Delete",
  });
  
  React.useEffect(() => {
    if (effTabData && effTabData.length) {
      const selected = effTabData.findIndex((elem) => elem.isSelected === 1);
      setSelectedIdx(selected);
    }
  }, [restProps.versionData, effTabData]);

  const remove = () => {
    setConfirmationPopup({
      ...confirmationPopup,
      isVisible: true,
      type: "DELETE_CONFIRM",
      actionBtn: DELETE_BTN_ACTIONS(onCancelConfirm, onDeleteConfirm),
      action: "Delete",
    });
  };

  const onCancelConfirm = () => {
    setConfirmationPopup({
      ...confirmationPopup,
      isVisible: false,
      type: "",
      actionBtn: [],
      data: {},
      action: "",
    });
  };

  const onDeleteConfirm = () => {
    restProps.setEffVersionState({
      ...restProps.effVersionState,
      deleteVersion: { rowId: restProps.versionData.rowId },
    });
    onCancelConfirm();
  };

  const inertPeriod = (e) => {
    let module = e.item.data;

    if (module === undefined) {
      return false;
    }

    let indexMOdule = module.indexOf("add-new-version");
    let periodMstrKey = 0;

    if (indexMOdule !== -1) {
      let periodData = module.split("add-new-version");
      module = "add-new-version";
      periodMstrKey = periodData[1];
    }
    switch (module) {
      case "delete-version":
        remove();
        break;
      default:
        return false;
      case "add-new-version":
        //TODO: UNSAVED CHANGES
        // if(unsavedPopup.isUnSavedChange) {
        //   dispatch(setUnSavedChangesPopup({...unsavedPopup, isVisible: true, callbackFn: () => {
        //     saveAssignment(periodMstrKey);
        //   }}))
        // } else {
        //   saveAssignment(periodMstrKey);
        // }
        saveAssignment(periodMstrKey);
        break;
    }
  };

  const saveAssignment = (reqPeriodMstrKey) => {
    restProps.setEffVersionState({
      ...restProps.effVersionState,
      insertVersion: {
        rowId: restProps.versionData.rowId,
        periodKey: reqPeriodMstrKey,
      },
    });
  };

  const handleSelect = (e) => {
    if (unsavedPopup.isUnSavedChange) {
      dispatch(
        setUnSavedChangesPopup({
          ...unsavedPopup,
          isVisible: true,
          callbackFn: () => {
            restProps.setEffVersionState({
              ...restProps.effVersionState,
              changeVersion: {
                rowId: restProps.versionData.rowId,
                versionIdx: e.selected,
              },
            });
            setSelectedIdx(e.selected);
          },
        })
      );
    } else {
      restProps.setEffVersionState({
        ...restProps.effVersionState,
        changeVersion: {
          rowId: restProps.versionData.rowId,
          versionIdx: e.selected,
        },
      });
      setSelectedIdx(e.selected);
    }
  };

  const effVersionMenu = () => {
    if (
      restProps.pageProps.writeAccess === 0 ||
      restProps.versionData?.data_write_access === 0
    ) {
      return false;
    }

    return (
      <Menu
        vertical={true}
        dir={"rtl"}
        style={{
          display: "inline-block",
        }}
        onSelect={inertPeriod}
        openOnClick={true}
        hoverOpenDelay={400}
        hoverCloseDelay={400}
      >
        <MenuItem
          icon="more-vertical"
          id="effmenu"
          className="effectivemenuitem"
        >
          <MenuItem text="Add New Version" id="add" data="add">
            {effMenuData.length > 0 ? (
              effMenuData.map((item, i) => {
                return (
                  <MenuItem
                    text={item.period_type_name}
                    data="periodtype"
                    key={i}
                  >
                    {item.items.length > 0 ? (
                      item.items.map((item1, j) => {
                        return (
                          <MenuItem
                            text={item1.period_name}
                            data={"add-new-version" + item1.period_mstr_key}
                            key={i + "_" + j}
                          />
                        );
                      })
                    ) : (
                      <MenuItem text="No available periods"></MenuItem>
                    )}
                  </MenuItem>
                );
              })
            ) : (
              <MenuItem text="No available periods"></MenuItem>
            )}
          </MenuItem>
          <MenuItem
            text="Delete Selected Version"
            data="delete-version"
          ></MenuItem>
        </MenuItem>
      </Menu>
    );
  };

  const effVersoinTab = () => {
    return (
      <TabStrip
        selected={selectedIdx}
        onSelect={handleSelect}
        className="tab-strip-width cusomtab dashboardTabs"
        scrollable={true}
      >
        {effTabData.map((item, index) => {
          return (
            <TabStripTab
              title={item.periodName}
              key={index}
              id={"eff-tabs-" + index}
            ></TabStripTab>
          );
        })}
      </TabStrip>
    );
  };

  const effVersionLeft = () => {
    return (
      <>
        <div className="innerhead">
          <div className="Page-title">
            <h1>Effective Versions</h1>
          </div>
        </div>
        <div className="innertabarea">{effVersoinTab()}</div>
      </>
    );
  };

  return (
    <>
      {effTabData && effTabData.length > 0 && (
        <div className="Page-title">
          <div className="content-head effectiversionbg effectivepad15">
            <div className="content-head-left customtablecenteralign">
              {effVersionLeft()}
            </div>
            {isShowRightMenu && (
              <div className="content-head-right ">{effVersionMenu()}</div>
            )}
          </div>
          <DialogComponent
            title={confirmationPopup.title}
            onCancelConfirm={onCancelConfirm}
            width={"450"}
            height={"auto"}
            disabled={false}
            className={"Dialog-Delete"}
            desc={confirmationPopup.desc}
            visible={confirmationPopup.isVisible}
            actionBtn={confirmationPopup.actionBtn}
            titleClassName={
              confirmationPopup.action === "Delete"
                ? "delete-confirm"
                : "unSave-confirm"
            }
          />
        </div>
      )}
    </>
  );
};
