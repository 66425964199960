import React from "react";
import DataTable from "../../components/dataTable";
import { GridColumn as Column } from "@progress/kendo-react-grid";
import { GridColumnDataMapper } from "../../utils/utils";
import { columnMenuProps } from "../../components/customColumnMenu";
import { LoadingPanel } from "../../components/loadingPanel";
import { useDispatch } from "react-redux";
import { getErrorLogAsync } from "src/lib/common-service";
import { useHistory } from "react-router-dom";
import { Window, WindowActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";

const ErrorDetailLogs = (restProps) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [visible, setVisible] = React.useState(restProps.visible);
  const [data, setData] = React.useState([]);
  const [columns, setColumns] = React.useState([]);
  const [dataCount, setDataCount] = React.useState(0);
  const [initialLoading, setLoadingState] = React.useState(false);
  const [isAPICalling, setAPICallingState] = React.useState(false);
  const apiData = {
    in_error_code: restProps.errorObj.error_code,
  };
  React.useEffect(() => {
    getLogsAsync(apiData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLogsAsync = async (data) => {
    setAPICallingState(true);
    setLoadingState(true);

    let res = await getErrorLogAsync(data, dispatch, history);
    if (res.status) {
      setAPICallingState(false);
      const columnData = GridColumnDataMapper(
        res.data.out_parameters.out_columns_xml
      );
      columnData.forEach((item) => {
        item.filterable = 0;
      });
      let columnsList = [...columnData];
      if (restProps.module === "job") {
        columnsList = columnData.filter((x) => x.field !== "etl_batch_name");
      }
      setColumns(columnsList);
      let resData = res.data.row_result;
      if (res.data.row_result.length && restProps?.errorObj.etl_batch_name) {
        resData.forEach((item) => {
          item.etl_batch_name = restProps?.errorObj.etl_batch_name;
        });
      }
      setData(resData);
      setDataCount(res.data.out_parameters.out_total_rows);
    }
  };

  const DescriptionCell = React.useCallback((props) => {
    const { ariaColumnIndex, columnIndex, dataItem, field } = props;
    let val = dataItem[field || ""];
    const defaultRendering = (
      <td aria-colindex={ariaColumnIndex} data-grid-col-index={columnIndex}>
        <span title={val} className="text-wrap">
          {val}
        </span>
      </td>
    );

    return defaultRendering;
  }, []);

  const StringReadAndOnlyCell = React.useCallback((props) => {
    const { ariaColumnIndex, columnIndex, dataItem, field } = props;

    let val = dataItem[field || ""];

    const defaultRendering = (
      <td aria-colindex={ariaColumnIndex} data-grid-col-index={columnIndex}>
        <span title={val}>{val}</span>
      </td>
    );

    return defaultRendering;
  }, []);

  const DtuErrorLogsColumn = (columns) => {
    return columns.map((column, idx) => {
      return column.show && column.title !== "" ? (
        <Column
          {...columnMenuProps(column)}
          key={idx}
          sortable={false}
          width={column.field === "error_code" ? 50 : 150}
          cell={
            column.field === "error_resolution_desc" ||
            column.field === "error_desc"
              ? DescriptionCell
              : StringReadAndOnlyCell
          }
        />
      ) : (
        false
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  let ErrorColumn = DtuErrorLogsColumn(columns);

  const onRefreshClick = () => {
    setAPICallingState(true);
    getLogsAsync(apiData);
  };

  const onClosePopup = () => {
    setVisible(!visible);
    restProps.setIsErrorDetail(false);
  };

  const ModalTitle = () => {
    return <span>Error Detail</span>;
  };

  return (
    <>
      {initialLoading ? (
        <>
          <Window
            title={<ModalTitle />}
            onClose={onClosePopup}
            width={900}
            height={restProps.modalHeight}
            resizable={false}
            top={200}
          >
            <div className="dtu-file-container-logs">
              <DataTable
                isAPICalling={isAPICalling}
                initialLoading={initialLoading}
                data={data}
                dataCount={dataCount}
                customColumn={ErrorColumn}
                columns={columns}
                width={"auto"}
                gridHeight={restProps.gridHeight}
                isPagination={false}
                refreshClick={onRefreshClick}
                className={"DataTable-dtuLog"}
                reorderable={false}
                gridHeight={160}
              />
            </div>
            <WindowActionsBar layout={"end"}>
              <Button
                className="cancelbtn"
                onClick={(e) => onClosePopup()}
                autoFocus={true}
              >
                {"Cancel"}
              </Button>
            </WindowActionsBar>
          </Window>
        </>
      ) : (
        <LoadingPanel />
      )}
    </>
  );
};
export default React.memo(ErrorDetailLogs);
