import React from "react";
import DataTable from "../../components/dataTable";
import { GridColumn as Column } from "@progress/kendo-react-grid";
import { getUserCredential, getTenantCookieData } from "../../lib/cookies";
import { columnMenuProps } from "../../components/customColumnMenu";
import { HeaderPage } from "../base/headerPage";
import "./style.scss";
import useAPI from "./service";
import { formatDate, formatDateOnly, getfilterDataString, replaceBlankAndMerge } from "src/utils/utils";
import { Window } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { Label, Error } from "@progress/kendo-react-labels";
import { CustomToggleButton } from "./index";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import MessageSchema from "src/utils/messageSchema";
import { LoadingPanel } from "src/components/loadingPanel";

const ModalTitle = () => {
    return (
        <span>
           Audit Log
        </span>
    );
};

const CustomTableDataRowAuditLog = (restProps) => {
    const DEFAULT_SORT = { number: 1, order: "DESC" };

    const defaultPageSize = getTenantCookieData("record_per_page");
    const [stageChange, setStateChange] = React.useState('DEFAULT')
    const [modal ,setModal] = React.useState(true)
    const cookiesData = getUserCredential();
    const [pageSize, setPageSize] = React.useState(defaultPageSize);
    const [columnsData, setColumnsData] = React.useState([]);
    const [isCheckColumn, setColumnCheck] = React.useState(false);
    const [exportDataClick, setExportDataClick] = React.useState(false);
    const [exportData, setExportData] = React.useState([]);
    const [dateFields, setDateFields] = React.useState({
        start_date: "",
        end_date: "",
    });
    const [filterData, setFilterData] = React.useState({
        isSort: false,
        isFilter: false,
        data: "",
        in_sort_number: DEFAULT_SORT.number,
        sort_order: DEFAULT_SORT.order,
    });
    //state to set and get limit, offset
    const [pageData, setPage] = React.useState({
        skip: 0,
        take: defaultPageSize.recordTake,
    });
    const [errorObj, setErrorObj] = React.useState({
        start_date: "",
        end_date: "",
        db_obj_type: "",
        commonDateValidate: "",
    });
    const [gridColumns, setGridColumns] = React.useState([]);

    const DATA_TABLE_ID = "audit-log-grid";

    const [list, Action] = useAPI();



    const {
        setDataRowsColumns,
        setDataRowsData,
        dataRowsColumns,
        dataRowsDataCount,
        dataRowsData,
        initialDataRowsLoading,
        noDataRowsFound,
        isAPIDataRowsCalling,
        setAPIDataRowsCallingState,
        setDataRowSearchClick,
        isDataRowSearchClick
    } = list;

    const getEnableOptions = () => {
        const parent = document.querySelector("#content-" + headerConfig.id);
        if (parent) {
            const exportToExcel = parent.querySelector(".export-to-excel");
            exportToExcel && exportToExcel.disableKendoMenu();
            dataRowsData.length && exportToExcel && exportToExcel.enableKendoMenu();
        }
    }

    React.useEffect(() => {
        if (dataRowsColumns.length === 0 && isDataRowSearchClick) {
            let apiCall = {
                in_filter: null,
                in_sort_number: getInSortNumber(),
                in_sort_order: getInSortOrder(),
                in_page_row_offset: getPageSkip(),
                in_page_rows: getPageTake(),
            }
            gridData(apiCall)
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [dataRowsColumns, isDataRowSearchClick])

    React.useEffect(() => {
        getEnableOptions()
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        if (isCheckColumn) {
            setGridColumns(columnsData);
            setColumnCheck(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCheckColumn]);

    React.useEffect(() => {
        if (dataRowsColumns.length > 0) {
            setColumnsData(dataRowsColumns);
            setGridColumns(dataRowsColumns);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataRowsColumns]);

    const getPageTake = () => {
        return pageData.take;
    };

    const getPageSkip = () => {
        return pageData.skip;
    };

    const handleMenuItemClick = (action) => {
        switch (action) {
            case "export":
                setExportDataClick(true);
                break;
            default:
                break;
        }
    };

    const getAPIData = () => {
        let apiData = {};
        apiData.in_tenant_id = cookiesData.out_tenant_id;
        apiData.in_filter = null;
        apiData.in_sort_number = 2;
        apiData.in_sort_order = "DESC";
        apiData.in_page_row_offset = 0;
        apiData.in_page_rows = defaultPageSize.recordTake;
        return apiData;
    };

    const itemChange = (event) => {
        const field = event.field || "";
        const newData = dataRowsData.map((item) =>
            item.user_key === event.dataItem.user_key
                ? { ...item, [field]: event.value }
                : item
        );
        setDataRowsData(newData);
    };

    const getInFilter = () => {
        return filterData.isFilter ? filterData.data : null;
    };

    const getInSortNumber = () => {
        return filterData.isSort ? filterData.in_sort_number : DEFAULT_SORT.number;
    };

    const getInSortOrder = () => {
        return filterData.isSort ? filterData.sort_order : DEFAULT_SORT.order;
    };

    const gridData = async (reqBody) => {
        let apiData = getAPIData()
        let startDate = formatDateOnly(dateFields.start_date);
        let endDate = formatDateOnly(dateFields.end_date);


        if (dateFields.start_date !== "" && dateFields.end_date !== "") {
            if (reqBody.in_filter !== null) {
                reqBody.in_filter = ` AND  (DATE (a.action_date) BETWEEN "${startDate}" AND  "${endDate}")  ${reqBody.in_filter}`;
            } else {
                reqBody.in_filter = ` AND  (DATE (a.action_date) BETWEEN "${startDate}" AND  "${endDate}")`;
            }
        }
        const dataVal = {
            ...apiData,
            in_custom_table_mstr_key: restProps.openAuditLog.data.custom_table_mstr_key,
            in_date_version: restProps.openAuditLog.data.date_version,
            in_custom_data_order: restProps.openAuditLog.data.custom_data_order,
            in_filter: reqBody.in_filter,
            in_sort_number: reqBody.in_sort_number,
            in_sort_order: reqBody.in_sort_order,
            in_page_row_offset: reqBody.in_page_row_offset,
            in_page_rows: reqBody.in_page_rows
        };

        let res = await Action.getCustomTableDataRowsAudit(dataVal)
        if (res) {
            const parent = document.querySelector("#content-" + headerConfig.id);
            if (parent) {
                const exportToExcel = parent.querySelector(".export-to-excel");
                exportToExcel && exportToExcel.disableKendoMenu();
                res.length && exportToExcel && exportToExcel.enableKendoMenu();
            }
        }
    }


    const onClearFilter = () => {
        let apiData = getAPIData();
        //set in_params
        const dataVal = {
            ...apiData,
            in_filter: null,
            in_sort_number: getInSortNumber(),
            in_sort_order: getInSortOrder(),
            in_page_row_offset: getPageSkip(),
            in_page_rows: getPageTake(),
        };

        //set filter to false
        setFilterData({
            ...filterData,
            data: "",
            isFilter: false,
        });
        gridData(dataVal)
    }

    const onRefreshClick = async () => {

        setAPIDataRowsCallingState(true);
        let apiData = getAPIData();
        const dataVal = {
            ...apiData,
            in_filter: getInFilter(),
            in_sort_number: getInSortNumber(),
            in_sort_order: getInSortOrder(),
            in_page_row_offset: getPageSkip(),
            in_page_rows: getPageTake(),
        };
        gridData(dataVal)
    }

    const handlePageChange = async (event) => {
        const pageData = event.page;
        setAPIDataRowsCallingState(true);
        setPage({ skip: pageData.skip, take: pageData.take });
        let apiCallData = getAPIData();
        apiCallData.in_filter = getInFilter();
        apiCallData.in_sort_number = getInSortNumber();
        apiCallData.in_sort_order = getInSortOrder();
        apiCallData.in_page_rows = pageData.take;
        apiCallData.in_page_row_offset = pageData.skip;
        gridData(apiCallData)
    }

    const handleColumnMenu = (columnMenuData, isFilter, isSort) => {
        let index = 1;
        let filterSkip = getPageSkip();
        let filterTake = getPageTake();

        if (isFilter) {
            filterSkip = 0;
            filterTake = pageSize.recordTake;

            //reset pager when filter applies
            setPage({ skip: filterSkip, take: filterTake });
        }

        if (dataRowsData.length > 0 && columnMenuData[0] !== undefined) {
            let obj = Object.keys(dataRowsData[0]);
            index = obj.findIndex((x) => x === columnMenuData[0].field) + 1;
        }

        let filterString = isFilter
            ? getfilterDataString(columnMenuData, gridColumns)
            : filterData.data;
        isFilter = false;
        filterString = replaceBlankAndMerge(filterString);

        if (filterString !== "") {
            isFilter = true;
        }

        let sortDir =
            filterData.isSort && columnMenuData.length
                ? filterData.sort_order
                : DEFAULT_SORT.order;
        let sortNumber =
            filterData.isSort && columnMenuData.length
                ? filterData.in_sort_number
                : DEFAULT_SORT.number;

        isSort = !columnMenuData.length ? false : filterData.isSort;

        if (
            columnMenuData[0] !== undefined &&
            columnMenuData[0].dir !== undefined
        ) {
            isSort = true;
            sortDir = columnMenuData[0].dir.toUpperCase();
            sortNumber = index;
        }
        const dataVal = {
            in_tenant_id: cookiesData.out_tenant_id,
            in_filter: isFilter ? filterString : null,
            in_sort_number: sortNumber,
            in_sort_order: sortDir,
            in_page_row_offset: filterSkip,
            in_page_rows: filterTake,
            in_user_key: cookiesData.out_user_id.toString(),
            in_user_role_filter: "> 0",
        };
        setFilterData({
            ...filterData,
            isFilter: isFilter,
            isSort: isSort,
            data: filterString,
            in_sort_number: sortNumber,
            sort_order: sortDir,
        });

        setAPIDataRowsCallingState(true);
        gridData(dataVal)
    }

    const headerConfig = {
        id: "data_rows_audit_log",
        action_menu: {
            export: { fx: handleMenuItemClick },
        },
        manage_col: {
            columnsData: columnsData,
            setColumnsData: setColumnsData,
            setColumnCheck: setColumnCheck,
        },
    };

    const getExportData = async () => {
        const dataVal = {
            in_custom_table_mstr_key: restProps.openAuditLog.data.custom_table_mstr_key,
            in_date_version: restProps.openAuditLog.data.date_version,
            in_custom_data_order: restProps.openAuditLog.data.custom_data_order,
            in_filter: getInFilter(),
            in_sort_number: getInSortNumber(),
            in_sort_order: getInSortOrder(),
            in_page_row_offset: 0,
            in_page_rows: dataRowsDataCount,
            in_tenant_id: cookiesData.out_tenant_id
        };
        let res = await Action.getCustomRowExportDataAsync(dataVal);
        if (res) {
            setExportData(res);
        }
    }


    const StringReadAndOnlyCell = React.useCallback((props) => {
        const { ariaColumnIndex, columnIndex, dataItem, field } = props;

        let val = dataItem[field || ""];

        const defaultRendering = (
            <td aria-colindex={ariaColumnIndex} data-grid-col-index={columnIndex}>
                <span title={val}>{val}</span>
            </td>
        );

        return defaultRendering;
    }, []);

    const BooleanCell = React.memo((props) => {
        const { ariaColumnIndex, columnIndex, dataItem, field } = props;
        const val = Number(dataItem[field]) ? "true" : "false";
    
        return (
            <td aria-colindex={ariaColumnIndex} data-grid-col-index={columnIndex}>
                <span title={val}>{val}</span>
            </td>
        );
    });
    

    const DateCell = React.memo((props) => {
        const { ariaColumnIndex, columnIndex, dataItem, field } = props;
        const val =
          dataItem[field] && dataItem[field] !== "0000-00-00 00:00:00"
            ? formatDate(new Date(dataItem[field]))
            : "";
    
        return (
          <td aria-colindex={ariaColumnIndex} data-grid-col-index={columnIndex}>
            <span title={val}>{val}</span>
          </td>
        );
      });

    const cellType = (type) => {
        switch (type) {
          case "date":
            return DateCell;
            case "boolean":
            return BooleanCell;
          default:
            return StringReadAndOnlyCell;
        }
      };

    const useCol = (columns) => {
        return columns.map((column, idx) => {
            return column.show && column.title !== "" ? (
                <Column
                    {...columnMenuProps(column)}
                    key={idx}
                    width={"150px"}
                    sortable={true}
                    format={column.format}
                    cell={cellType(column.type)}
                />
            ) : (
                false
            );
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    let AuditColumn = useCol(gridColumns);

    const onClosePopup = () => {
        restProps.setOpenAuditLog({
            ...restProps.openAuditLog,
            isOpen: false,
            data: {}
        });
    };

    const handleDateChange = (e) => {
        setDateFields({
            ...dateFields,
            [e.target.name]: e.target.value,
        });
        setErrorObj({
            ...errorObj,
            [e.target.name]: "",
            commonDateValidate: "",
        });
    };

    const handleValidation = () => {
        let isValid = true;
        let errObj = {};

        const { start_date, end_date } = dateFields;
        if (start_date && end_date) {
            const startDateObj = new Date(start_date);
            const endDateObj = new Date(end_date);

            if (start_date && end_date) {
                if (startDateObj > endDateObj) {
                    errObj.commonDateValidate = MessageSchema.validDate;
                } else {
                    errObj.commonDateValidate = "";
                }
            }
        } else {
            if (start_date === "" || start_date === null) {
                errObj.start_date = MessageSchema.actionStartDateRequired;
            }
            if (end_date === "" || end_date === null) {
                errObj.end_date = MessageSchema.actionEndDateRequired;
            }
        }

        setErrorObj({ ...errObj });
        for (const [, value] of Object.entries(errObj)) {
            if (value !== "") {
                isValid = false;
            }
        }
        return isValid;
    };


    const onSearchButtonClick = () => {
        let isValid = handleValidation();
        if (isValid) {
            setPageSize(defaultPageSize);
            setPage({
                skip: 0,
                take: defaultPageSize.recordTake,
            });
            setDataRowsData([]);
            setDataRowsColumns([]);
            setDataRowSearchClick(true);
            setFilterData({
                isSort: false,
                isFilter: false,
                data: "",
                in_sort_number: DEFAULT_SORT.number,
                sort_order: DEFAULT_SORT.order,
            });
        }
    }

    const checkSearchButton = () => {
        const isStartDateEmpty = dateFields.start_date === "";
        const isEndDateEmpty = dateFields.end_date === "";
        let isDisabled =
            (isStartDateEmpty || isEndDateEmpty)
        return isDisabled;
    };

    const handleStageChange = (e) => {
        setStateChange(e.state)
        if(e.state === 'MINIMIZED'){
            setModal(false)
        }else if(e.state === 'DEFAULT'){
            setModal(true)
        }
    };

    return (
        <>
            <Window
                title={<ModalTitle />}
                onClose={onClosePopup}
                width={window.innerWidth * 0.8}
                height={window.innerHeight * 0.8}
                resizable={false}
                stage={stageChange}
                onStageChange={handleStageChange}
                modal={modal}
            >

                <div className="dtu-file-container-logs">
                    <div className="content-body">
                        <div className="">
                            <div className="Filter-links auditlogform">
                                <div className="row customtablerowdialog">
                                <div className="customtablerowdialogcont">
                                        <div className="k-form-field-wrap-popup valueDropdown width20em">
                                            <Label>{"Start Date* : "}</Label>
                                            <DatePicker
                                                value={dateFields.start_date}
                                                name="start_date"
                                                format="yyyy-MM-dd"
                                                onChange={(e) => {
                                                    handleDateChange(e);
                                                }}
                                                formatPlaceholder={{
                                                    year: "yyyy",
                                                    month: "mm",
                                                    day: "dd",
                                                }}
                                                placeholder="yyyy-mm-dd"
                                                toggleButton={CustomToggleButton}
                                            />
                                            <Error>{errorObj.start_date}</Error>
                                        </div>
                                    
                                        <div className="k-form-field-wrap-popup valueDropdown width20em">
                                            <Label>{"End Date* : "}</Label>
                                            <DatePicker
                                                toggleButton={CustomToggleButton}
                                                value={dateFields.end_date}
                                                name="end_date"
                                                format="yyyy-MM-dd"
                                                onChange={(e) => {
                                                    handleDateChange(e);
                                                }}
                                                formatPlaceholder={{
                                                    year: "yyyy",
                                                    month: "mm",
                                                    day: "dd",
                                                }}
                                                placeholder="yyyy-mm-dd"
                                            />
                                            <Error>{errorObj.end_date}</Error>
                                            <Error>{errorObj.commonDateValidate}</Error>
                                        </div>
                                    
                                        <Button
                                            primary={true}
                                            className="searchbtn martop9"
                                            disabled={checkSearchButton()}
                                            type="submit"
                                            onClick={(e) => {
                                                onSearchButtonClick();
                                            }}
                                        >
                                            <i className="fas fa-search"></i>
                                        </Button>
                                        </div>
                                        <div className="customtopmenuitem">
                                            <HeaderPage
                                            pageProps={restProps.pageProps}
                                            headerConfig={headerConfig}
                                            />
                                        </div>
                                </div>
                                <Error>{dateFields.validationError}</Error>
                            </div>
                            <div className="Quick-links eventtypequicklinks martop15">
                                
                            </div>
                        </div>
                    </div>
                    {isDataRowSearchClick ? <LoadingPanel /> : false}
                    {dataRowsColumns.length > 0 ? (
                        <div >
                            <DataTable
                                isAPICalling={isAPIDataRowsCalling}
                                initialLoading={initialDataRowsLoading}
                                data={dataRowsData}
                                id={DATA_TABLE_ID}
                                itemChange={itemChange}
                                pageData={pageData}
                                dataCount={dataRowsDataCount}
                                customColumn={AuditColumn}
                                columns={gridColumns}
                                module={"auditLog"}
                                width={"auto"}
                                fileName={'Export_Audit_Log_Custom_Table_Data_Rows'}
                                defaultPageSize={defaultPageSize}
                                pageSize={pageSize}
                                setPageSize={setPageSize}
                                setPage={setPage}
                                handlePageChange={handlePageChange}
                                handleColumnMenu={handleColumnMenu}
                                refreshClick={onRefreshClick}
                                onClearFilter={onClearFilter}
                                noDataFound={noDataRowsFound}
                                isExportDataClick={exportDataClick}
                                setExportData={setExportDataClick}
                                getExportData={getExportData}
                                exportData={exportData}
                                reorderable={false}
                            />
                        </div>
                    ) : (
                        false
                    )}

                </div>
            </Window>
        </>
    );
};
export default React.memo(CustomTableDataRowAuditLog);
