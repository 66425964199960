import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { reportsSelector } from "../../redux/selectors/custom-selector";
import DataTable from "../../components/dataTable";
import { GridColumn } from "@progress/kendo-react-grid";
import { LoadingPanel } from "../../components/loadingPanel";
import { IntlService } from "@progress/kendo-react-intl";
import { transformDateTime } from "src/lib/date-time.util";

const intl = new IntlService("en");

const ProviderInfoCellComponent = ({ dataItem }) => {
    return (
        <>
            <td className="title-column">{dataItem.key}</td>
            <td>{dataItem.value}</td>
        </>
    );
};

const ProviderSalaryCellComponent = ({ dataItem }) => {
    return (
        <>
            <td className="title-column">{dataItem.salaryKey}</td>
            <td>{dataItem.salaryValue}</td>
            <td className="title-column">{dataItem.targetKey}</td>
            <td>{dataItem.targetValue}</td>
        </>
    );
};

const table1DataMap = {
    provider_id: "Provider ID",
    department_id: "Department",
    employee_id: "Employee ID",
    hire_date: "Hire Date",
};

const table2DataMap = {
    provider_name: "Provider Name",
    credentials: "Credentials",
    physician_flag: "Physician",
};

const table3DataMap = {
    utilization_unit_text: "Utilization",
    utilization_clinical: "Clinical",
    utilization_admin: "Admin",
    utilization_research: "Research",
    utilization_academic: "Academic",
    utilization_total: "Total",
};

const table4DataMap = {
    salary: "Salary",
    target: "Target Goal",
};

const SectionOne = (props) => {
    const {
        reportsCompDetailSection1Data,
        isReportsCompDetailSection1DataLoading,
    } = useSelector(reportsSelector, shallowEqual);
    const [providerInfoData1, setProviderInfoData1] = React.useState([]);
    const [providerInfoData2, setProviderInfoData2] = React.useState([]);
    const [providerData1, setProviderData1] = React.useState([]);
    const [providerData2, setProviderData2] = React.useState([]);

    React.useEffect(() => {
        if (reportsCompDetailSection1Data) {
            let tmp1 = [];
            let tmp2 = [];
            let tmp3 = [{}];
            let tmp4 = [{}];
            for (const [key, value] of Object.entries(
                reportsCompDetailSection1Data
            )) {
                if (table1DataMap[key]) {
                    let formatedValue = value;
                    if (
                        table1DataMap[key] === "Hire Date" &&
                        value &&
                        value !== ""
                    ) {
                        formatedValue = transformDateTime({ date: value, format: "MM/DD/YYYY" });
                    }
                    tmp1.push({
                        key: table1DataMap[key],
                        value: formatedValue,
                    });
                }
                if (table2DataMap[key]) {
                    let formatedValue = value;
                    if (table2DataMap[key] === "Physician") {
                        formatedValue = !!value ? "Yes" : "No";
                    }
                    tmp2.push({
                        key: table2DataMap[key],
                        value: formatedValue,
                    });
                }
                if (table3DataMap[key]) {
                    tmp3[0][table3DataMap[key]] = value ? value : "";
                }
                if (table4DataMap[key]) {
                    if (key === "salary") {
                        tmp4[0].salaryKey = table4DataMap[key];
                        tmp4[0].salaryValue = `${intl.formatNumber(
                            value,
                            "#,##0.00;-#,##0.00"
                        )} ${reportsCompDetailSection1Data["salary_unit_text"] || ""}`;
                    }

                    if (key === "target") {
                        tmp4[0].targetKey = table4DataMap[key];
                        tmp4[0].targetValue = `${intl.formatNumber(
                            value,
                            "#,##0.00;-#,##0.00"
                        )} ${reportsCompDetailSection1Data["target_unit_text"] || ""}`;
                    }
                }
            }

            setProviderInfoData1(tmp1);
            setProviderInfoData2(tmp2);
            setProviderData1(tmp3);
            setProviderData2(tmp4);
        }
    }, [reportsCompDetailSection1Data]);

    return isReportsCompDetailSection1DataLoading ? (
        <LoadingPanel />
    ) : reportsCompDetailSection1Data ? (
        <div className="reports-comp-detail-section-container section1-container">
            <div className="section1-provider-info-container">
                <DataTable
                    isAPICalling={false}
                    initialLoading={true}
                    data={providerInfoData1}
                    columns={[]}
                    gridHeight={""}
                    isPagination={false}
                    isSortable={false}
                    id="data-grid"
                    customColumn={
                        <GridColumn cell={ProviderInfoCellComponent} />
                    }
                />
                <div>
                    <DataTable
                        isAPICalling={false}
                        initialLoading={true}
                        data={providerInfoData2}
                        gridHeight={""}
                        columns={[]}
                        isPagination={false}
                        isSortable={false}
                        id="data-grid"
                        customColumn={
                            <GridColumn cell={ProviderInfoCellComponent} />
                        }
                    />
                </div>
            </div>
            <div className="section1-provider-data-container">
                <DataTable
                    isAPICalling={false}
                    initialLoading={true}
                    data={providerData1}
                    columns={[]}
                    gridHeight={""}
                    isSortable={false}
                    isPagination={false}
                    reorderable={false}
                    id="data-grid"
                    className="section1-provider-data-salary-info"
                />
                {props?.pageProps.salaryInfoControlAccess === 1 ? (
                    <div>
                        <DataTable
                            isAPICalling={false}
                            initialLoading={true}
                            data={providerData2}
                            columns={[]}
                            gridHeight={""}
                            isSortable={false}
                            isPagination={false}
                            reorderable={false}
                            id="data-grid"
                            customColumn={
                                <GridColumn
                                    cell={ProviderSalaryCellComponent}
                                />
                            }
                        />
                    </div>
                ) : (
                    false
                )}
            </div>
        </div>
    ) : null;
};

export default React.memo(SectionOne);
