import axios from "axios";
import ProjectSetting from "../setting";
import {
    removeUserCredential,
    removeOtpData,
    getUserCredential,
    getLocalStorage,
} from "../lib/cookies";
import { setNewMessage } from "../redux/actions/setting-actions";

const baseURL =
    ProjectSetting.baseURL + ProjectSetting.backend + ProjectSetting.version;

export const API_CALL_METHODS = {
    POST: "POST",
    GET: "GET",
    PUT: "PUT",
    DELETE: "DELETE",
};

export const API_CALL_RESP_CODES = {
    OK: 200,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
};
export const errStatusHandler = (resp, history, dispatch) => {
    if (resp && resp.data) {
        const { statusCode } = resp?.data;
        if (
            statusCode === API_CALL_RESP_CODES.UNAUTHORIZED ||
            statusCode === API_CALL_RESP_CODES.FORBIDDEN
        ) {
            dispatch(
                setNewMessage({
                    message: resp?.data?.data?.out_parameters.out_error_msg,
                    type: "Error",
                    show: true,
                })
            );
            removeUserCredential();
            removeOtpData();
            history.push("/login");
            localStorage.removeItem("resultData");
        }
    }
};

export const generateHeader = () => {
    const cookiesData = getUserCredential();
    return {
        "x-access-token": cookiesData?.secretToken || "",
    };
};

export const getUserToken = () => {
    const processEnv = process.env;
    const storageData = getLocalStorage(processEnv.REACT_APP_CREDENTIAL_KEY);

    return {
        "x-access-token": storageData?.out_secret_token || "",
    };
};

export const makeApi = (URL, method, apiStore, headers, body) => {
    const config = {
        method,
        url: URL,
        headers,
    };

    if (apiStore) {
        if (apiStore.has(URL)) {
            // const cancel = apiStore.get(URL);
        }
    }

    if (body) {
        config.data = body;
    }

    const apiCall = axios(config)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            if (error.response) {
                const errResponse = error.response;
                if (errResponse.status === 422) {
                    return {
                        data: {
                            data: {
                                out_parameters: {
                                    out_ret_code: errResponse.status,
                                    out_error_msg: errResponse.data.errors.msg,
                                },
                            },
                            status: true,
                        },
                    };
                } else {
                    return errResponse;
                }
            }
        });
    /*
        .catch((response) => {
            // dispatch(
            //     setNewMessage({ message: "Not Authorized", type: 'Error', show: true })
            //   );
            // removeUserCredential();
            // removeOtpData();
            // localStorage.clear()
            // window.location.href = '/login';
        });
        */

    return apiCall;
};

export const POSTAPI = (isFake, URL, apiStore, headers, body, dispatch) => {
    return makeApi(
        baseURL + URL,
        API_CALL_METHODS.POST,
        apiStore,
        headers,
        body,
        dispatch
    );
};

export const GETAPI = (isFake, URL, apiStore, headers, body, dispatch) => {
    return makeApi(
        baseURL + URL,
        API_CALL_METHODS.GET,
        apiStore,
        headers,
        body,
        dispatch
    );
};
