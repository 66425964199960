export const SET_COMP_PLAN_FILTER_DATA = "SET_COMP_PLAN_FILTER_DATA";
export const SET_COMP_PLAN_FILTER_VALUE = "SET_COMP_PLAN_FILTER_VALUE";
export const SET_COMP_PLAN_FILTER_DATA_LOADING = "SET_COMP_PLAN_FILTER_DATA_LOADING";
export const SET_COMP_PLAN_PERIOD_FILTER_DATA = "SET_COMP_PLAN_PERIOD_FILTER_DATA";
export const SET_COMP_PLAN_PERIOD_FILTER_VALUE = "SET_COMP_PLAN_PERIOD_FILTER_VALUE";
export const SET_COMP_PLAN_PERIOD_FILTER_DATA_LOADING = "SET_COMP_PLAN_PERIOD_FILTER_DATA_LOADING";
export const SET_PROVIDER_FILTER_DATA = "SET_PROVIDER_FILTER_DATA";
export const SET_PROVIDER_FILTER_VALUE = "SET_PROVIDER_FILTER_VALUE";
export const SET_PROVIDER_FILTER_DATA_LOADING = "SET_PROVIDER_FILTER_DATA_LOADING";
export const SET_PERIOD_FILTER_DATA = "SET_PERIOD_FILTER_DATA";
export const SET_PERIOD_FILTER_VALUE = "SET_PERIOD_FILTER_VALUE";
export const SET_PERIOD_FILTER_DATA_LOADING = "SET_PERIOD_FILTER_DATA_LOADING";
export const SET_EVENT_TYPE_FILTER_DATA = "SET_EVENT_TYPE_FILTER_DATA";
export const SET_EVENT_TYPE_FILTER_VALUE = "SET_EVENT_TYPE_FILTER_VALUE";
export const SET_EVENT_TYPE_FILTER_DATA_LOADING = "SET_EVENT_TYPE_FILTER_DATA_LOADING";

export const SET_SHOW_REPORTS_COMP_DETAIL_SECTION = "SET_SHOW_REPORTS_COMP_DETAIL_SECTION";
export const SET_REPORTS_COMP_DETAIL_SECTION1_DATA = "SET_REPORTS_COMP_DETAIL_SECTION1_DATA";
export const SET_REPORTS_COMP_DETAIL_SECTION1_DATA_LOADING =
  "SET_REPORTS_COMP_DETAIL_SECTION1_DATA_LOADING";
export const SET_REPORTS_COMP_DETAIL_SECTION2_DATA = "SET_REPORTS_COMP_DETAIL_SECTION2_DATA";
export const SET_REPORTS_COMP_DETAIL_SECTION2_DATA_LOADING =
  "SET_REPORTS_COMP_DETAIL_SECTION2_DATA_LOADING";
export const SET_REPORTS_COMP_DETAIL_SECTION2_PAGE = "SET_REPORTS_COMP_DETAIL_SECTION2_PAGE";
export const SET_REPORTS_COMP_DETAIL_SECTION2_PAGE_SIZE =
  "SET_REPORTS_COMP_DETAIL_SECTION2_PAGE_SIZE";
export const SET_REPORTS_COMP_DETAIL_SECTION2_SORT = "SET_REPORTS_COMP_DETAIL_SECTION2_SORT";
export const SET_REORDER_LOADING = "SET_REORDER_LOADING";
export const SET_REPORTS_COMP_DETAIL_SECTION2_SORT_NUMBER =
  "SET_REPORTS_COMP_DETAIL_SECTION2_SORT_NUMBER";
export const SET_REPORTS_COMP_DETAIL_SECTION2_FILTER_STRING =
  "SET_REPORTS_COMP_DETAIL_SECTION2_FILTER_STRING";
export const CLEAR_REPORTS_STATE = "CLEAR_REPORTS_STATE";
export const CLEAR_REPORTS_COMP_DETAIL_RESULTS = "CLEAR_REPORTS_COMP_DETAIL_RESULTS";
export const SET_REPORTS_COMP_DETAIL_SECTION2_DATA_COLUMNS =
  "SET_REPORTS_COMP_DETAIL_SECTION2_DATA_COLUMNS";
export const SET_REPORTS_COMP_DETAIL_SECTION2_GROUP_COLLAPSED_STATE =
  "SET_REPORTS_COMP_DETAIL_SECTION2_GROUP_COLLAPSED_STATE";
export const SET_REPORTS_COMP_DETAIL_SECTION2_FILTER = "SET_REPORTS_COMP_DETAIL_SECTION2_FILTER";

export const SET_REPORT_INITIAL_LINK_OBJECT= "SET_REPORT_INITIAL_LINK_OBJECT";
export const SET_IS_FROM_PROVIDER = "SET_IS_FROM_PROVIDER";

export const PREFETCH_REPORT_DROPDOWNS = "PREFETCH_REPORT_DROPDOWNS";

export const setCompPlanFilterData = (data) => {
  return {
    type: SET_COMP_PLAN_FILTER_DATA,
    data,
  };
};

export const setCompPlanFilterValue = (value) => {
  return {
    type: SET_COMP_PLAN_FILTER_VALUE,
    data: value,
  };
};

export const setCompPlanFilterDataLoading = (flag) => {
  return {
    type: SET_COMP_PLAN_FILTER_DATA_LOADING,
    data: flag,
  };
};

export const setCompPlanPeriodFilterData = (data) => {
  return {
    type: SET_COMP_PLAN_PERIOD_FILTER_DATA,
    data,
  };
};

export const setCompPlanPeriodFilterValue = (value) => {
  return {
    type: SET_COMP_PLAN_PERIOD_FILTER_VALUE,
    data: value,
  };
};

export const setCompPlanPeriodFilterDataLoading = (flag) => {
  return {
    type: SET_COMP_PLAN_PERIOD_FILTER_DATA_LOADING,
    data: flag,
  };
};

export const setProviderFilterData = (data) => {
  return {
    type: SET_PROVIDER_FILTER_DATA,
    data,
  };
};

export const setProviderFilterValue = (value) => {
  return {
    type: SET_PROVIDER_FILTER_VALUE,
    data: value,
  };
};

export const setProviderFilterDataLoading = (flag) => {
  return {
    type: SET_PROVIDER_FILTER_DATA_LOADING,
    data: flag,
  };
};

export const setPeriodFilterData = (data) => {
  return {
    type: SET_PERIOD_FILTER_DATA,
    data,
  };
};

export const setPeriodFilterValue = (value) => {
  return {
    type: SET_PERIOD_FILTER_VALUE,
    data: value,
  };
};

export const setPeriodFilterDataLoading = (flag) => {
  return {
    type: SET_PERIOD_FILTER_DATA_LOADING,
    data: flag,
  };
};

export const setEventTypeFilterData = (data) => {
  return {
    type: SET_EVENT_TYPE_FILTER_DATA,
    data,
  };
};

export const setEventTypeFilterValue = (value) => {
  return {
    type: SET_EVENT_TYPE_FILTER_VALUE,
    data: value,
  };
};

export const setEventTypeFilterDataLoading = (flag) => {
  return {
    type: SET_EVENT_TYPE_FILTER_DATA_LOADING,
    data: flag,
  };
};


export const setShowReportsCompDetailSection = (flag, section) => {
  return {
    type: SET_SHOW_REPORTS_COMP_DETAIL_SECTION,
    data: {
      flag,
      section,
    },
  };
};

export const setReportsCompDetailSection1Data = (data) => {
  return {
    type: SET_REPORTS_COMP_DETAIL_SECTION1_DATA,
    data,
  };
};

export const setReportsCompDetailSection1DataLoading = (flag) => {
  return {
    type: SET_REPORTS_COMP_DETAIL_SECTION1_DATA_LOADING,
    data: flag,
  };
};

export const setReportsCompDetailSection2Data = (data, columns, totalRows) => {
  return {
    type: SET_REPORTS_COMP_DETAIL_SECTION2_DATA,
    data: {
      data,
      columns,
      totalRows,
    },
  };
};

export const setReportsCompDetailSection2DataColumns = (data) => {
  return {
    type: SET_REPORTS_COMP_DETAIL_SECTION2_DATA_COLUMNS,
    data,
  };
};

export const setReportsCompDetailSection2DataLoading = (flag) => {
  return {
    type: SET_REPORTS_COMP_DETAIL_SECTION2_DATA_LOADING,
    data: flag,
  };
};

export const setColumnReorderLoading = (flag) => {
  return {
    type: SET_REORDER_LOADING,
    data: flag,
  };
};

export const setReportsCompDetailSection2Page = (skip, pageSize) => {
  return {
    type: SET_REPORTS_COMP_DETAIL_SECTION2_PAGE,
    data: {
      skip,
      pageSize,
    },
  };
};

export const setReportsCompDetailSection2PageSize = (pageSize) => {
  return {
    type: SET_REPORTS_COMP_DETAIL_SECTION2_PAGE_SIZE,
    data: pageSize,
  };
};

export const setReportsCompDetailSection2Sort = (sortData) => {
  return {
    type: SET_REPORTS_COMP_DETAIL_SECTION2_SORT,
    data: sortData,
  };
};

export const setReportsCompDetailSection2SortNumber = (sortNumber) => {
  return {
    type: SET_REPORTS_COMP_DETAIL_SECTION2_SORT_NUMBER,
    data: sortNumber,
  };
};

export const setReportsCompDetailSection2FilterString = (data) => {
  return {
    type: SET_REPORTS_COMP_DETAIL_SECTION2_FILTER_STRING,
    data,
  };
};

export const setReportsCompDetailSection2Filter = (data) => {
  return {
    type: SET_REPORTS_COMP_DETAIL_SECTION2_FILTER,
    data,
  };
};

export const setReportsCompDetailSection2GroupCollapsedState = (data) => {
  return {
    type: SET_REPORTS_COMP_DETAIL_SECTION2_GROUP_COLLAPSED_STATE,
    data,
  };
};

export const clearReportsState = () => {
  return {
    type: CLEAR_REPORTS_STATE,
  };
};

export const clearReportsCompDetailResults = (defaultPageSize) => {
  return {
    type: CLEAR_REPORTS_COMP_DETAIL_RESULTS,
    data: defaultPageSize,
  };
};

export const setReportLinkObjectData = (obj) => {
  return { type: SET_REPORT_INITIAL_LINK_OBJECT, data: obj}
}

export const setIsFromProvider = (val) => {
  return { type: SET_IS_FROM_PROVIDER, data: val};
}

export const setPrefetchDropdownAction = (data) => {
  return { type: PREFETCH_REPORT_DROPDOWNS, data: data};
}
