/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import DataTable from "../../components/dataTable";
import {
  GridColumn as Column,
  getSelectedState,
} from "@progress/kendo-react-grid";
import { DialogComponent } from "../../components/dialog";
import { getUserCredential, getTenantCookieData } from "../../lib/cookies";
import { columnMenuProps } from "../../components/customColumnMenu";
import Popup from "../../components/customPopup";
import { FieldWrapper, Form, FormElement } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { Error, Label } from "@progress/kendo-react-labels";
import CustomDropdown from "../../components/customDropdown";
import {
  getfilterDataString,
  changeCancelBtnState,
  changeUpdateBtnState,
  replaceBlankAndMerge,
} from "../../utils/utils";
import { HeaderPage } from "../base/headerPage";
import { DEFAULT_SORT } from "../../constants/grid";
import { LoadingPanel } from "../../components/loadingPanel";
import { DELETE_BTN_ACTIONS, pattern } from "../../constants/constants";
import { CellRender, RowRender } from "../../components/renderers";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import eventTypeData from "./manageEventService";
import { getter } from "@progress/kendo-react-common";
// import { setUnSavedChangesPopup } from "src/redux/actions/setting-actions";
// import { shallowEqual, useSelector } from "react-redux";
// import { unSaveChangesPopupSelector } from "src/redux/selectors/custom-selector";
import { Window } from "@progress/kendo-react-dialogs";
import MessageSchema from "src/utils/messageSchema";

const SELECTED_FIELD = "selected";
const editField = "inEdit";
const DATA_ITEM_KEY = "event_type_mstr_key";
const idGetter = getter(DATA_ITEM_KEY);

const ModalTitle = () => {
  return <span>Manage Event Type</span>;
};

const initialFormFields = {
  event_type_desc: "",
  event_group_name: "",
  event_type_name: "",
  groupData: [],
  inEventGroup: false,
};

const initialSort = [
  {
    field: "user_first_name",
    dir: "asc",
  },
];

const ManageEventType = (restProps) => {

  let eventTypeAccess = {
    ...restProps.pageProps,
    writeAccess: restProps.pageProps.eventTypeWriteAccess,
    readAccess: restProps.pageProps.eventTypeReadAccess
  }
  // const dispatch = useDispatch();
  const cookiesData = getUserCredential();
  const defaultPageSize = getTenantCookieData("record_per_page");
  const [list, Action] = eventTypeData();
  const [sort, setSort] = React.useState(initialSort);
  const [deleteSelectedIds, setDeleteSelectedIds] = React.useState([]);
  const [errorObj, setErrorObj] = React.useState({});
  const [addNew, setAddNew] = React.useState(false);
  const [selectedState, setSelectedState] = React.useState({});
  const [selectedRowId, setSelectedRowId] = React.useState("");
  const [isAPICall, setAPICall] = React.useState(false);
  const [formFields, setFormFields] = React.useState({
    ...initialFormFields,
  });
  const [isShow, setShow] = React.useState({
    dtuLogs: false,
    deleteSelectedRow: false,
    sendEmails: false,
  });
  const [modal, setModal] = React.useState(true);
  const [stageChange, setStateChange] = React.useState("DEFAULT");
  const [eventTypeAddUpdate, seteventTypeAddUpdate] = React.useState(false);
  const [filterData, setFilterData] = React.useState({
    isSort: false,
    isFilter: false,
    data: "",
    in_sort_number: DEFAULT_SORT.number,
    sort_order: DEFAULT_SORT.order,
  });
  // const { unsavedPopup } = useSelector(
  //   unSaveChangesPopupSelector,
  //   shallowEqual
  // );
  const [columnsData, setColumnsData] = React.useState([]);
  const [isCheckColumn, setColumnCheck] = React.useState(false);
  const [gridColumns, setGridColumns] = React.useState([]);
  const addRef = React.useRef(null);
  let popupRef = React.useRef();
  // Get APIdata from service file
  const {
    data,
    columns,
    dataCount,
    setData,
    isColumnMenuSearch,
    editData,
    originalData,
    isAPICalling,
    setEditData,
    setAPICallingState,
    initialLoading,
    visible,
    setVisible,
    eventGroupData,
    noDataFound,
    isRefreshLoading,
    originEventGroupData,
    setEventGroupData,
    setConfirmationPopup,
    confirmationPopup,
  } = list;

  const [pageData, setPage] = React.useState({
    skip: 0,
    take: defaultPageSize.recordTake,
  });

  const [pageSize, setPageSize] = React.useState(defaultPageSize);
  const [resetSort, setResetSort] = React.useState(false);
  // intial APIdata

  const apiCallData = {
    in_tenant_id: cookiesData.out_tenant_id,
    in_filter: null,
    in_sort_number: 1,
    in_sort_order: "DESC",
    in_page_row_offset: 0,
    in_page_rows: defaultPageSize.recordTake,
    in_load_type: "data-reload",
  };

  if (document.getElementById("grid-incell-cancel")) {
    document.getElementById("grid-incell-cancel").onclick = function (e) {
      originalData.map((item) => {
        item.inEdit = false;
        item.selected = false;
        return 0;
      });
      setData(originalData);
      setErrorObj({});

      //disable in-cell update and cancel button
      e.target.disableKendoBtn();
      changeUpdateBtnState("disable");
    };
  }

  if (document.getElementById("grid-incell-update")) {
    document.getElementById("grid-incell-update").onclick = function (e) {
      handleUpdate();
    };
  }

  React.useEffect(() => {
    if (columns.length > 0) {
      setColumnsData(columns);
      setGridColumns(columns);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);

  React.useEffect(() => {
    const dataVal = {
      ...apiCallData,
      in_load_type: "grid-reload",
    };
    Action.getResultList(dataVal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restProps.isAPICall]);

  React.useEffect(() => {
    if (isCheckColumn) {
      setGridColumns(columnsData);
      setColumnCheck(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheckColumn]);

  React.useEffect(() => {
    if (list.loadingState.isSaveData) {
      list.setLoadState({ ...list.loadingState, isSaveData: false });
      setAPICall(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list.loadingState.isSaveData]);

  React.useEffect(() => {
    if (isAPICall) {
      setAPICall(false);
      onRefreshClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAPICall]);

  React.useEffect(() => {
    if (isShow.deleteRows) {
      remove();
      setShow({ ...isShow, deleteRows: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShow.deleteRows]);

  React.useEffect(() => {
    if (list.loadingState.isDeleteData) {
      list.setLoadState({ ...list.loadingState, isDeleteData: false });
      onRefreshClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list.loadingState.isDeleteData]);

  React.useEffect(() => {
    if (eventTypeAddUpdate === false) {
      changeCancelBtnState("disable");
      changeUpdateBtnState("disable");
    }
    if (eventTypeAddUpdate === true) {
      changeCancelBtnState("enable");
      changeUpdateBtnState("enable");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventTypeAddUpdate, columns]);

  React.useEffect(() => {
    setTimeout(function () {
      Action.getEventGroupData();
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getInFilter = () => {
    return filterData.isFilter ? filterData.data : null;
  };

  const getInSortNumber = () => {
    return filterData.isSort ? filterData.in_sort_number : DEFAULT_SORT.number;
  };

  const getInSortOrder = () => {
    return filterData.isSort ? filterData.sort_order : DEFAULT_SORT.order;
  };

  const getPageTake = () => {
    return pageData.take;
  };

  const getPageSkip = () => {
    return pageData.skip;
  };
  const addNewData = async (e) => {
    setAddNew(!addNew);
    e.preventDefault();
  };

  const remove = (ids) => {
    setConfirmationPopup({
      ...confirmationPopup,
      isVisible: true,
      type: "DELETE_CONFIRM",
      actionBtn: DELETE_BTN_ACTIONS(onCancelConfirm, onDeleteConfirm),
      data: [],
      action: "Delete",
    });
  };

  // Delete event type
  const onDeleteConfirm = async (key) => {
    const deleteArray = deleteSelectedIds.map((obj2) => {
      const obj1 = data.find((elem) => elem.event_type_mstr_key === obj2.id);
      return {
        id: obj2.id,
        eventTypeName: obj1.event_type_name,
      };
    });
    let reqData = {
      in_user_id: cookiesData.out_user_id.toString(),
      in_event_type_mstr_key: deleteArray,
      in_tenant_id: cookiesData.out_tenant_id,
    };
    setConfirmationPopup({
      ...confirmationPopup,
      isVisible: false,
      type: "",
      actionBtn: [],
      data: {},
      action: "",
    });
    let res = await Action.deleteDataAsync(reqData, apiCallData);
    if (res === "success") {
      setDeleteSelectedIds([]);
      onRefreshClick();
      setSelectedRowId("");
      setErrorObj({});
      setConfirmationPopup({
        ...confirmationPopup,
        isVisible: false,
        type: "",
        actionBtn: [],
        data: {},
        action: "",
      });

      setTimeout(() => {
        restProps.setNewlyAddedEventType(true);
      }, 1000);
    }
  };

  const onCancelConfirm = (action) => {
    setConfirmationPopup({
      ...confirmationPopup,
      isVisible: false,
      type: "",
      actionBtn: [],
      data: {},
      action: "",
    });

    data.map((item) => {
      item.selected = false;
      return 0;
    });
    setEditData(editData);
    setData([...data]);
    if (action === "outSideClick") {
      // TODO : UNSAVED CHANGES
      // dispatch(
      //   setUnSavedChangesPopup({ ...unsavedPopup, isUnSavedChange: true })
      // );
    }
  };

  const handleValidation = () => {
    let isValid = true;

    if (formFields.event_type_name.trim() === "") {
      isValid = false;
      errorObj.event_type_name = "Please enter event type name";
    } else {
      errorObj.event_type_name = "";
      if (
        !pattern.test(formFields.event_type_name) &&
        formFields.event_type_name.trim() !== ""
      ) {
        isValid = false;
        errorObj.event_type_name = MessageSchema.EventTypeValid;
      }
    }

    if (formFields.event_type_desc.trim() === "") {
      isValid = false;
      errorObj.event_type_desc = "Please enter event type description";
    } else {
      errorObj.event_type_desc = "";
    }

    setErrorObj((preVState) => ({ ...preVState, errorObj }));
    return isValid;
  };
  const handleUpdateValidation = () => {
    let isValid = true;
    data
      .filter((x) => x.inIteamEdit === true)
      .map((item) => {
        const { event_type_name, event_type_desc, rowId } = item;
        let errorObj = {
          event_type_name: "",
          event_type_desc: "",
        };
        if (event_type_name.trim() === "") {
          errorObj.event_type_name = "Please enter event type name";
        } else {
          errorObj.event_type_name = "";
          if (
            !pattern.test(event_type_name) &&
            event_type_name.trim() !== ""
          ) {
            isValid = false;
            errorObj.event_type_name = MessageSchema.EventTypeValid;
          }
        }

        if (event_type_desc.trim() === "") {
          errorObj.event_type_desc = "Please enter event type description";
        } else {
          errorObj.event_type_desc = "";
        }

        let obj = {};
        for (const [key, value] of Object.entries(errorObj)) {
          obj[`${key + rowId}`] = `${value}`;
        }

        setErrorObj(obj);
        for (const [, value] of Object.entries(obj)) {
          if (value !== "") {
            isValid = false;
          }
        }
        return 0;
      });
    return isValid;
  };

  const handleSubmit = () => {
    let valid = handleValidation();
    if (valid) {
      setErrorObj("");

      const bodyData = {
        in_user_id: cookiesData.out_user_id.toString(),
        in_tenant_id: cookiesData.out_tenant_id,
        in_event_type_name: formFields.event_type_name,
        in_event_type_desc: formFields.event_type_desc,
        in_event_group_mstr_key: eventGroupData.eventGroupValue.value,
      };
      Action.addDataAsync(bodyData);
      setAddNew(false);
      seteventTypeAddUpdate(false);
      onRefreshClick();
      setFormFields(initialFormFields);
      setTimeout(() => {
        restProps.setNewlyAddedEventType(true);
      }, 1000);
      Action.getEventGroupData();
    }
  };

  const handleColumnMenu = (columnMenuData, isFilter, isSort) => {
    let index = 1;
    let filterSkip = getPageSkip();
    let filterTake = getPageTake();

    if (isFilter) {
      filterSkip = 0;
      filterTake = pageSize.recordTake;

      //reset pager when filter applies
      setPage({ skip: filterSkip, take: filterTake });
    }

    if (data.length > 0 && columnMenuData[0] !== undefined) {
      let obj = Object.keys(data[0]);
      index = obj.findIndex((x) => x === columnMenuData[0].field) + 1;
    }

    let filterString = isFilter
      ? getfilterDataString(columnMenuData, gridColumns)
      : filterData.data;

    isFilter = false;
    filterString = replaceBlankAndMerge(filterString)
    if (filterString !== "") {
      isFilter = true;
    }

    let sortDir =
      filterData.isSort && columnMenuData.length
        ? filterData.sort_order
        : DEFAULT_SORT.order;
    let sortNumber =
      filterData.isSort && columnMenuData.length
        ? filterData.in_sort_number
        : DEFAULT_SORT.number;

    isSort = !columnMenuData.length ? false : filterData.isSort;

    if (
      columnMenuData[0] !== undefined &&
      columnMenuData[0].dir !== undefined
    ) {
      isSort = true;
      sortDir = columnMenuData[0].dir.toUpperCase();
      sortNumber = index;
    }

    const dataVal = {
      ...apiCallData,
      in_filter: isFilter ? filterString : null,
      in_sort_number: sortNumber,
      in_sort_order: sortDir,
      in_page_row_offset: filterSkip,
      in_page_rows: filterTake,
    };

    setFilterData({
      ...filterData,
      isFilter: isFilter,
      isSort: isSort,
      data: filterString,
      in_sort_number: sortNumber,
      sort_order: sortDir,
    });

    setAPICallingState(true);

    getGridData(dataVal);
  };

  const handlePageChange = async (event) => {
    const pageData = event.page;

    setAPICallingState(true);

    setPage({ skip: pageData.skip, take: pageData.take });

    const dataVal = {
      ...apiCallData,
      in_filter: getInFilter(),
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
      in_page_row_offset: pageData.skip,
      in_page_rows: pageData.take,
    };
    getGridData(dataVal);
  };
  const setPageMenuState = (response) => {
    if (!deleteSelectedIds.length) {
      return false;
    }

    let ids = response.map((el) => el.event_type_mstr_key);

    let selectedKeys = [];
    let tempArray = [];
    let tempArrayData = [];

    Object.keys(selectedState).map((item) => {
      let obj = {};

      obj.id = Number(item);
      selectedKeys.push(item);
      tempArray.push(obj);

      return 0;
    });

    ids.map((item) => {
      if (selectedKeys.includes(item.toString())) {
        tempArrayData.push(item);
      }
      return 0;
    });

    let strSelectedKeys = "";

    if (tempArrayData.length > 0) {
      strSelectedKeys = parseInt(selectedKeys[0]);
    }

    setSelectedRowId(strSelectedKeys);
  };

  const onRefreshClick = () => {
    setAPICallingState(true);
    setResetSort(false);
    const dataVal = {
      ...apiCallData,
      in_filter: getInFilter(),
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
      in_page_row_offset: getPageSkip(),
      in_page_rows: getPageTake(),
    };
    getGridData(dataVal);
  };

  const getGridData = async (dataVal) => {
    let response = await Action.getResultList(dataVal);
    setPageMenuState(response);
    changeCancelBtnState();
    changeUpdateBtnState();
    setErrorObj({});
  };

  const handleMenuItemClick = (module) => {
    switch (module) {
      case "delete":
        setShow({
          ...isShow,
          deleteRows: !isShow.deleteRows,
        });
        break;
      default:
        break;
    }
  };

  const onClosePopup = () => {
    setVisible(!visible);
    restProps.setShow({ ...restProps.visible, isResult: false });
  };



  const onRowClick = (dataItem) => {
    let newData = data.map((item) => ({
      ...item,
      selected:
        item.event_type_mstr_key === dataItem.event_type_mstr_key
          ? true
          : false,
    }));
    setSelectedRowId(dataItem.event_type_mstr_key);
    setData(newData);
  };

  const onSelectionChange = (event) => {
    const newSelectedState = getSelectedState({
      event,
      selectedState: selectedState,
      dataItemKey: DATA_ITEM_KEY,
    });
    setSelectedState(newSelectedState);
    let tempArray = [];

    Object.keys(newSelectedState).map((item) => {
      if (newSelectedState[item] === false) {
        return 0;
      }
      let obj = {};
      obj.id = Number(item);

      // obj.text = item.event_group_name;
      tempArray.push(obj);
      return 0;
    });
    setDeleteSelectedIds(tempArray);
    setSelectedRowId(tempArray[0])
  };

  const exitEdit = (dataItem, index, dataIndex, e) => {
    if (!addNew) {
      if (dataItem.event_type_name === "" || dataItem.event_type_desc) {
        handleUpdateValidation();
      }
    }
    // let newData = data.map((item) => ({
    //   ...item,
    //   inEdit: undefined
    // }));
    // setData(newData);
  };
  const onClearFilter = async () => {
    setAPICallingState(true);

    //set in_params
    const dataVal = {
      ...apiCallData,
      in_filter: null,
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
      in_page_row_offset: getPageSkip(),
      in_page_rows: getPageTake(),
    };

    //set filter to false
    setFilterData({
      ...filterData,
      data: "",
      isFilter: false,
      isSort: false,
    });

    let response = await Action.getResultList(dataVal);
    setPageMenuState(response);
  };

  const customCellRender = (td, props) => {
    return (
      <CellRender
        navigatable={true}
        originalProps={props}
        td={td}
        enterEdit={enterEdit}
        editField={editField}
        onRowClick={onRowClick}
        updateActBtn="grid-incell-update"
        cancelActBtn="grid-incell-cancel"
      />
    );
  };
  const customRowRender = (tr, props) => (
    <RowRender
      originalProps={props}
      tr={tr}
      editField={editField}
      errorObj={errorObj}
      exitEdit={exitEdit}
    />
  );

  const NameCell = React.useCallback(
    (props) => {
      const { ariaColumnIndex, columnIndex, dataItem, field, render } = props;
      let isInEdit = field === dataItem.inEdit;

      if (addNew) {
        isInEdit = dataItem.inEdit;
      }

      let value = props.field + dataItem.rowId;
      const handleChange = (e, props, value) => {
        if (props.onChange) {
          seteventTypeAddUpdate(true);
          // TODO : UNSAVED CHANGES
          // dispatch(
          //   setUnSavedChangesPopup({ ...unsavedPopup, isUnSavedChange: true })
          // );
          props.onChange({
            dataIndex: 0,
            dataItem: props.dataItem,
            field: props.field,
            syntheticEvent: e.syntheticEvent,
            value: e.target.value,
          });
        }
      };

      const defaultRendering = (
        <td aria-colindex={ariaColumnIndex} data-grid-col-index={columnIndex}>
          {isInEdit ? (
            <Input
              // required
              value={dataItem[props.field]}
              onChange={(e) => {
                handleChange(e, props, value);
              }}
              name={`${props.field}`}
            />
          ) : (
            <span>{props.dataItem[props.field || ""]}</span>
          )}
          {/* {!minMaxValidation(props.dataItem[props.field])&& <div className="manage-event"><Error>This Field Is required</Error></div>} */}
          {Object.keys(errorObj).length > 0 ? (
            <div role="alert" className="k-form-error k-text-start text-wrap">
              {Object.keys(errorObj).length > 0 ? errorObj[value] : ""}
            </div>
          ) : (
            false
          )}
        </td>
      );
      return render?.call(undefined, defaultRendering, props);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errorObj, addNew]
  );

  // const DropDownCell = React.useCallback(
  //   (props) => {
  //     const { ariaColumnIndex, columnIndex, dataItem, field, render } = props;
  //     let isInEdit = field === dataItem.inEdit;

  //     if (addNew) {
  //       isInEdit = dataItem.inEdit;
  //     }
  //     const value = field && dataItem[field] ? dataItem[field] : "";
  //     const onChange = (e) => {
  //       if (props.onChange) {
  //         seteventTypeAddUpdate(true);
  //         props.onChange({
  //           dataIndex: 0,
  //           dataItem: props.dataItem,
  //           field: props.field,
  //           syntheticEvent: e.syntheticEvent,
  //           value: e.target.value.text,
  //         });
  //       }
  //     };

  //     let tempArray = [{ text: "", value: null }];

  //     let valueData = tempArray;
  //     const defaultRendering = (
  //       <td aria-colindex={ariaColumnIndex} data-grid-col-index={columnIndex}>
  //         {isInEdit ? (
  //           <DropDownList
  //             style={{
  //               width: "150px",
  //             }}
  //             onChange={onChange}
  //             value={valueData && valueData.find((c) => c.text === value)}
  //             data={valueData}
  //             textField="text"
  //             placeholder={"Select"}
  //           />
  //         ) : (
  //           props.dataItem[props.field || ""]
  //         )}
  //       </td>
  //     );
  //     return render?.call(undefined, defaultRendering, props);
  //   },
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [eventGroupData.eventGroupDataValue, addNew]
  // );
  const DropDownCell = React.useCallback(
    (props) => {
      const { ariaColumnIndex, columnIndex, dataItem, field, render } = props;
      let isInEdit = field === dataItem.inEdit;

      let erroeValue = props.field + dataItem.rowId;
      let valueData = eventGroupData.eventGroupDataValue;

      let dataValue = "";
      if (props && valueData.length > 0) {
        let value = valueData?.find(
          (x) =>
            x.value === dataItem[props.field] ||
            x.text === dataItem[props.field]
        );
        dataValue = value ? value.text : "";
      }
      if (field.indexOf("_mstr_key") !== -1 && dataItem.inEdit === undefined) {
        dataValue = dataItem[field.replace("_mstr_key", "_name") || ""];
      }
      const handleChange = (e) => {
        if (props.onChange) {
          changeUpdateBtnState("enable");
          props.onChange({
            dataIndex: 0,
            dataItem: props.dataItem,
            field: props.field,
            syntheticEvent: e.syntheticEvent,
            value: e.target.value.text,
          });
        }
      };
      const defaultRendering = (
        <td aria-colindex={ariaColumnIndex} data-grid-col-index={columnIndex}>
          {isInEdit ? (
            <DropDownList
              style={{
                width: "250px",
              }}
              onChange={handleChange}
              value={valueData && valueData.find((c) => c.text === dataValue)}
              data={valueData}
              textField="text"
              placeholder={"Select"}
            />
          ) : (
            <span>{dataValue}</span>
          )}

          {Object.keys(errorObj).length > 0 ? (
            <div role="alert" className="k-form-error k-text-start">
              {Object.keys(errorObj).length > 0 ? errorObj[erroeValue] : ""}
            </div>
          ) : (
            false
          )}
        </td>
      );
      return render?.call(undefined, defaultRendering, props);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [eventGroupData.eventGroupDataValue, errorObj]
  );

  const enterEdit = (dataItem, field) => {
    if (restProps.pageProps.eventTypeWriteAccess === 0) {
      return false
    }
    const newData = data.map((item) => ({
      ...item,
      [editField]:
        item.event_type_mstr_key === dataItem.event_type_mstr_key
          ? field
          : undefined,
    }));

    setData(newData);
  };

  const itemChange = React.useCallback(
    (event) => {
      const field = event.field || "";
      let newData = "";

      newData = data.map((item) =>
        item.event_type_mstr_key === event.dataItem.event_type_mstr_key
          ? { ...item, [field]: event.value, inIteamEdit: true }
          : item
      );

      setData(newData);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  const handleUpdate = async () => {
    let valid = handleUpdateValidation();
    if (valid) {
      let updatedDataList = [];
      data
        .filter((x) => x.inIteamEdit === true && x.inEdit !== true)
        .map((item) => {
          let obj = {};
          obj.in_user_id = cookiesData.out_user_id.toString();
          obj.in_tenant_id = cookiesData.out_tenant_id;
          obj.in_event_type_mstr_key = item.event_type_mstr_key;
          obj.in_event_type_name = item.event_type_name;
          obj.in_event_type_desc = item.event_type_desc;
          obj.in_event_group_mstr_key =
            typeof item.event_group_mstr_key === "string"
              ? eventGroupData.eventGroupDataValue.find(
                (x) => x.text === item.event_group_mstr_key
              ).value
              : item.event_group_mstr_key;
          updatedDataList.push(obj);
          return 0;
        });

      const bodyData = {
        updateEvent: [...updatedDataList],
      };
      if (updatedDataList.length > 0) {
        let response = await Action.updateDataAsync(bodyData);
        if (response) {
          onRefreshClick();
          seteventTypeAddUpdate(false);
          setTimeout(() => {
            restProps.setNewlyAddedEventType(true);
          }, 1000);
          // TODO : UNSAVED CHANGES
          // dispatch(
          //   setUnSavedChangesPopup({ ...unsavedPopup, isUnSavedChange: false })
          // );
        }
      }
    } else {
      changeCancelBtnState("enable");
      changeUpdateBtnState("enable");
    }
  };

  const useCol = (columns) => {
    return columns.map((column, idx) => {
      return column.show && column.title !== "" ? (
        <Column
          {...columnMenuProps(column)}
          key={idx}
          cell={
            column.field === "event_group_mstr_key" ? DropDownCell : NameCell
          }
          sortable={true}
        />
      ) : (
        false
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const AddNewLink = () => {
    return (
      <div ref={popupRef}>
        <a
          // href="/#"
          className="cta-small"
          onClick={(e) => {
            // TODO : UNSAVED CHANGES
            // if (unsavedPopup.isUnSavedChange) {
            //   dispatch(
            //     setUnSavedChangesPopup({
            //       ...unsavedPopup,
            //       isVisible: true,
            //       callbackFn: () => {
            //         addNewData(e);
            //         document.getElementById("grid-incell-cancel")?.click();
            //       },
            //     })
            //   );
            // } else {
            //   addNewData(e);
            // }
            addNewData(e);
          }}
        >
          <i className="fas fa-plus"></i> Add New Event Type
        </a>

        {AddNewPopup()}
      </div>
    );
  };
  const onCancelClick = () => {
    setFormFields(initialFormFields);
    setErrorObj({});
    setAddNew(false);
    Action.getEventGroupData();
  };
  const handleFieldChange = (e) => {
    const name = e.name ? e.name : e.target.name;
    setFormFields({ ...formFields, [name]: e.value });
    setErrorObj({ ...errorObj, [name]: "" });
  };
  const ShowError = React.useCallback(
    (props) => {
      return <Error>{errorObj[props.name]}</Error>;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errorObj]
  );
  const AddNewForm = () => {
    return (
      <Form
        onSubmit={handleSubmit}
        render={() => (
          <FormElement className="emailForm addeventfieldcont" horizontal={true}>
            <FieldWrapper>
              <div className="k-form-field-wrap-popup">
                <Label>Event Type Name*: </Label>
                <Input
                  type="text"
                  name="event_type_name"
                  value={formFields.event_type_name}
                  onChange={(e) => {
                    handleFieldChange(e);
                  }}
                  autoFocus={true}
                  placeholder="Event Type Name"
                />
                <ShowError name="event_type_name" />
              </div>
            </FieldWrapper>
            <FieldWrapper>
              <div className="k-form-field-wrap-popup">
                <Label>Event Type Description*: </Label>
                <Input
                  type="text"
                  name="event_type_desc"
                  value={formFields.event_type_desc}
                  onChange={(e) => {
                    handleFieldChange(e);
                  }}
                  placeholder="Event Type Description"
                />
                <ShowError name="event_type_desc" />
              </div>
            </FieldWrapper>
            <FieldWrapper>
              <div className={"k-form-field-wrap-popup"}>
                <Label>{"Event Group Name*: "}</Label>
                <CustomDropdown
                  name={"eventGroupValue"}
                  data={eventGroupData.eventGroupDataValue}
                  textField="text"
                  dataItemKey="value"
                  valueData={originEventGroupData.find(
                    (c) => c.value === eventGroupData.eventGroupValue.value
                  )}
                  onOpen={(dataV) => {
                    setEventGroupData({
                      ...eventGroupData,
                      eventGroupDataValue: dataV,
                    });
                  }}
                  onChange={(e, name) => {
                    setEventGroupData({
                      ...eventGroupData,
                      [name]: e,
                    });
                  }}
                  onFilterChange={(dataValue) => {
                    setEventGroupData({
                      ...eventGroupData,
                      eventGroupDataValue: dataValue,
                    });
                  }}
                  filterable={true}
                  autoClose={false}
                  autoBind={true}
                  dataList={originEventGroupData}
                  loading={eventGroupData.iseventGroupDataLoading}
                />
              </div>
            </FieldWrapper>
          </FormElement>
        )}
      />
    );
  };
  const AddNewPopup = () => {
    return (
      <Popup anchor={addRef.current} show={addNew} setShow={setAddNew}>
        <div className="dot-dropdown-open dot-dropdown-open-Form">
          <div className="caption">
            <span>Add New Event Type</span>
          </div>

          {AddNewForm()}

          <div className="dropdownFooter">
            <div className="k-form-buttons">
              <Button
                primary={true}
                type="submit"
                onClick={() => {
                  onCancelClick();
                }}
              >
                Cancel
              </Button>
              <Button
                primary={true}
                type="submit"
                onClick={() => {
                  handleSubmit(formFields, errorObj);
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </Popup>
    );
  };
  const headerConfig = {
    id: "manage-event",
    buttons: { template_fx: GridButton },
    add_new_fx: AddNewLink,
    action_menu: {
      delete: { fx: handleMenuItemClick },
    },
    manage_col: {
      columnsData: columnsData,
      setColumnsData: setColumnsData,
      setColumnCheck: setColumnCheck,
    },
  };

  const parent = document.querySelector("#content-" + headerConfig.id);

  if (parent) {
    const auditLog = parent.querySelector(".audit-log");
    const deleteRows = parent.querySelector(".delete-rows");

    auditLog && auditLog.disableKendoMenu();
    deleteRows && deleteRows.disableKendoMenu();

    if (selectedRowId !== "") {
      auditLog && auditLog.enableKendoMenu();
      deleteRows && deleteRows.enableKendoMenu();
    }
  }
  let customColumn = useCol(gridColumns);
  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>
  );

  const handleStageChange = (e) => {
    setStateChange(e.state);
    if (e.state === "MINIMIZED") {
      setModal(false);
    } else if (e.state === "DEFAULT") {
      setModal(true);
    }
  };

  return (
    <>
      <>
        <Window
          title={<ModalTitle />}
          onClose={onClosePopup}
          width={1200}
          height={600}
          resizable={false}
          stage={stageChange}
          onStageChange={handleStageChange}
          modal={modal}
        >
          <div className="dtu-file-container-logs manageeventtype-dialogue">
            <div className="content">
              <div className="Quick-links eventtypequicklinks">
                <HeaderPage
                  pageProps={
                    eventTypeAccess
                  }
                  headerConfig={headerConfig}
                />
              </div>
            </div>
            {isRefreshLoading && loadingPanel}
            {columns.length > 0 ? (
              <DataTable
                isAPICalling={isAPICalling}
                initialLoading={initialLoading}
                data={data.map((item) => ({
                  ...item,
                  [SELECTED_FIELD]: selectedState[idGetter(item)],
                }))}
                filterable={false}
                handleColumnMenu={handleColumnMenu}
                itemChange={itemChange}
                editField={editField}
                pageData={pageData}
                dataCount={dataCount}
                cellRender={customCellRender}
                rowRender={customRowRender}
                sort={sort}
                customColumn={customColumn}
                isColumnMenuSearch={isColumnMenuSearch}
                columns={gridColumns}
                dataItemKey={DATA_ITEM_KEY}
                setSort={setSort}
                defaultPageSize={defaultPageSize}
                pageSize={pageSize}
                setPageSize={setPageSize}
                handlePageChange={handlePageChange}
                isExportDataClick={restProps.exportDataClick}
                setExportData={restProps.setExportDataClick}
                fileName={"Export_user.xlsx"}
                module={"event-type"}
                onClearFilter={onClearFilter}
                isAddClick={restProps.isAddNewConfig}
                refreshClick={onRefreshClick}
                onSelectionChange={onSelectionChange}
                noDataFound={noDataFound}
                resetSort={resetSort}
                reorderable={false}
              />
            ) : initialLoading && data.length === 0 ? (
              false
            ) : (
              <LoadingPanel />
            )}
            <DialogComponent
              title={confirmationPopup.title}
              onCancelConfirm={onCancelConfirm}
              width={"450"}
              height={"auto"}
              className={"Dialog-Delete"}
              desc={confirmationPopup.desc}
              visible={confirmationPopup.isVisible}
              actionBtn={confirmationPopup.actionBtn}
              titleClassName={
                confirmationPopup.action === "Delete"
                  ? "delete-confirm"
                  : "unSave-confirm"
              }
            />
          </div>
        </Window>
      </>
    </>
  );
};

export const GridButton = (restProps) => {
  return (
    <>
      <Button type="submit" className="cancelbtn" id="grid-incell-cancel">
        Cancel
      </Button>
      <Button type="submit" className="primarybtn" id="grid-incell-update">
        Save
      </Button>
    </>
  );
};

export default React.memo(ManageEventType);
