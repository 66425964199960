/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { HeaderPage } from "../base/headerPage";
import useAPI from "./service";
import { getUserCredential } from "src/lib/cookies";
import { Input, TextArea } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { LoadingPanel } from "src/components/loadingPanel";
import projectSetting from "src/setting";
import { bytesToKB, downloadURI } from "src/utils/utils";

const ProviderProfile = (pageProps) => {
    const cookiesData = getUserCredential();
    const [, Action] = useAPI();
    const [categoryFieldList, setCategoryFieldList] = React.useState([]);
    const [providerFormFields, setProviderFormFields] = React.useState("");
    const [documentData, selectDocumentData] = React.useState([]);
    const [loadingState, setLoadingState] = React.useState({
        initialLoad: true,
    });
    const headerConfig = {
        title: "Provider Profile",
        id: "provider_profile",
    };

    const getCategoryFieldList = async () => {
        let reqData = {
            in_filter: `AND a.category_code = 'comp_attr'`,
            in_module_name: "provider_position",
            in_sort_number: 1,
            in_sort_order: "ASC",
            in_tenant_id: cookiesData.out_tenant_id,
        };
        let res = await Action.getCategoryField(reqData);
        if (res) {
            let filteredData = res
                .sort((a, b) => a.cat_fdm_order - b.cat_fdm_order)
                .map((x) => {
                    if (
                        x.filter_column_name &&
                        x.filter_column_name.includes(".")
                    ) {
                        let col_name = x.filter_column_name.split(".")[1];
                        return {
                            ...x,
                            filter_column_name: col_name,
                            selected: false,
                        };
                    }
                    return x;
                });
            setCategoryFieldList([...filteredData]);
        }
    };

    React.useEffect(() => {
        getCategoryListData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getCategoryListData = async () => {
        await getCategoryFieldList();
        let reqData = {
            in_tenant_id: cookiesData.out_tenant_id,
            in_user_key: cookiesData.out_user_id.toString(),
            in_acct_encrypt_key: cookiesData.out_acct_encrypt_key,
            in_category_code: '"comp_attr"',
        };
        let res = await Action.getProviderPosition(reqData);
        if (res.length > 0) {
            setProviderFormFields(res[0]);
            getDocumentData(res[0]);
        } else {
            setLoadingState({
                ...loadingState,
                initialLoad: false,
            });
        }
    };

    const getDocumentData = async (obj) => {
        let reqData = {
            in_filter: `AND a.document_src_mstr_key = ${obj.org_position_key}`,
            in_sort_number: 1,
            in_sort_order: "DESC",
            in_page_row_offset: 0,
            in_page_rows: 9999,
        };
        let res = await Action.selectDocument(reqData);
        if (res) {
            setLoadingState({
                ...loadingState,
                initialLoad: false,
            });
            selectDocumentData(res.data.row_result);
        } else {
            setLoadingState({
                ...loadingState,
                initialLoad: false,
            });
        }
    };

    const renderFileTypeElement = (fileType) => {
        let element = "";
        switch (fileType) {
            case ".xls":
                element = <i className="fa fa-file-excel"></i>;
                break;
            case ".doc":
                element = <i className="fa fa-file-word"></i>;
                break;
            case ".png":
                element = <i className="fa fa-file-image"></i>;
                break;
            case ".pdf":
                element = <i className="fa fa-file-pdf"></i>;
                break;
            default:
                element = <i className="fa fa-file-pdf"></i>;
                break;
        }
        return element;
    };

    const onDownloadClick = (item) => {
        let url = `${projectSetting.baseURL}download-document/${cookiesData.out_tenant_id}/position/${item.document_name}`;
        downloadURI(item?.original_name || item.document_name, url);
    };
    return (
        <>
            <div className="Main">
                <div className="content">
                    <div className="content-head">
                        <HeaderPage
                            pageProps={pageProps}
                            headerConfig={headerConfig}
                        />
                    </div>
                    <div className="content-body provprofres">
                        {loadingState.initialLoad ? (
                            <LoadingPanel />
                        ) : (
                            <>
                                <div className="provprofcont">
                                    <div
                                        className={`${
                                            pageProps.controlAccess === 1
                                                ? "compattrobutescont"
                                                : "compattrobutescont comp-width"
                                        }`}
                                    >
                                        <h3>Comp Attributes</h3>
                                        <div className="managedoccontinner">
                                            {categoryFieldList.map(
                                                (item, i) => {
                                                    let jsonString =
                                                        item?.dropdown_control;
                                                    let jsonObject = jsonString
                                                        ? JSON.parse(jsonString)
                                                        : "";
                                                    return (
                                                        <div
                                                            key={`category${i}`}
                                                            className="compattrwidth"
                                                        >
                                                            <Label>
                                                                {
                                                                    item.filter_label
                                                                }
                                                            </Label>
                                                            {jsonObject.input_type ===
                                                            "textarea" ? (
                                                                <TextArea
                                                                    rows={4}
                                                                    value={
                                                                        providerFormFields[
                                                                            item
                                                                                .filter_column_name
                                                                        ]
                                                                    }
                                                                    disabled={
                                                                        true
                                                                    }
                                                                />
                                                            ) : (
                                                                <>
                                                                    <Input
                                                                        disabled={
                                                                            true
                                                                        }
                                                                        value={
                                                                            providerFormFields[
                                                                                item
                                                                                    .filter_column_name
                                                                            ]
                                                                        }
                                                                    />
                                                                </>
                                                            )}
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </div>
                                    </div>
                                    {pageProps.controlAccess === 1 && (
                                        <div className="managedoccont">
                                            <h3> Manage Documents</h3>
                                            <div className="managedoccontinner">
                                                {documentData.map((item, i) => {
                                                    return (
                                                        <>
                                                            <div
                                                                className="attached-file"
                                                                key={`document${i}`}
                                                            >
                                                                {renderFileTypeElement(
                                                                    item.document_type
                                                                )}
                                                                <a
                                                                    className="link-class"
                                                                    onClick={() =>
                                                                        onDownloadClick(
                                                                            item
                                                                        )
                                                                    }
                                                                >
                                                                    {
                                                                        item.original_name
                                                                    }
                                                                    <div className="filesize">
                                                                        {bytesToKB(
                                                                            item.document_size
                                                                        )}
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default React.memo(ProviderProfile);
