import axios from "axios";
import AxiosRequest from "./api-handlers/request-handler/request.handler";
import AxiosRequestError from "./api-handlers/request-handler/request-error.handler";
import AxiosResponse from "./api-handlers/response-handler/response.handler";
import AxiosResponseError from "./api-handlers/response-handler/response-error.handler";

export default function AxiosInterceptor() {
  axios.interceptors.request.use(AxiosRequest, AxiosRequestError);
  axios.interceptors.response.use(AxiosResponse, AxiosResponseError);
  return axios;
}
