/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState } from "react";
import { Splitter } from "@progress/kendo-react-layout";
import { HeaderPage } from "../../base/headerPage";
import DtuBatchLogs from "../../common/dtuBatchLogs";
import { CompenSationData, GridButton } from "./compensationData";
import AddRule from "./addRule";
import { useDocumentClickEventListener } from "../../../lib/hooks/custom-common-hooks";
import Popup from "../../../components/customPopup";
import {
    DELETE_COMP_RULE,
    INSERT_COMP_RULE,
    SELECT_COMP_RULE_EFF_VERSION,
} from "../../../api-setup/api-endpoints";
import useApi from "./service";
import { getUserCredential } from "../../../lib/cookies";
import "./comp-rule.scss";
import {
    changeCancelBtnState,
    changeUpdateBtnState,
} from "../../../utils/utils";
import ProrationSettings from "../rule-config/proration";
import WritebackEvent from "../rule-config/creditRule/writebackEvent";
import { Input } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import PaymentResultSetting from "../rule-config/payment/resultSetting";
import CreditAllocationResultSetting from "../rule-config/credit-allocation/resultSetting";
import IncentiveResultSetting from "../rule-config/incentive/resultSetting";
import CreditRuleResultSetting from "../rule-config/creditRule/resultSetting";
import {
    setAllData,
    setProrationData,
} from "../../../redux/actions/rule-library-actions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import MeasurementResultSetting from "../rule-config/measurement/resultSetting";
import { prorateDataSelector } from "../../../redux/selectors/custom-selector";
import { EffectiveVersions } from "../../../components/effectiveVersions";
import EffDataRowsApi from "../../../components/effectiveVesionService";
import { LoadingPanel } from "../../../components/loadingPanel";
import PaymentExecutionSettings from "../rule-config/payment/execution";
import IncentiveExecutionSettings from "../rule-config/incentive/execution";
import MeasurementExecutionSettings from "../rule-config/measurement/execution";
import CreditRuleExecutionSettings from "../rule-config/creditRule/execution";
import RuleFilterConditions from "../rule-filters/ruleFilterConditions";
import { Loader } from "@progress/kendo-react-indicators";

const DATA_ITEM_KEY = "primary_key";
const CompensationRules = (pageProps) => {
    const [isCopyLoading, setIsCopyLoading] = useState(false);
    const prorateReduxData = useSelector(prorateDataSelector, shallowEqual);
    const [isRuleFilterEnabled, setRuleFilterEnabled] = useState(false);
    const dispatch = useDispatch();
    const [list, Action] = useApi();
    const [listVersion, ActionVersion] = EffDataRowsApi();
    const { data, setRefreshLoading, setData, isRefreshLoading, columns } =
        list;
    const cookiesData = getUserCredential();
    const addRef = React.useRef(null);
    const [isShow, setShow] = React.useState({
        deleteRows: false,
        isKpiType: false,
        isAddNewRule: false,
    });
    const [isVersionShow, setVersionShow] = React.useState({
        dtuLogs: false,
        dtuUpload: false,
    });
    const [selectedRowId, setSelectedRowId] = React.useState("");
    const [selectedState, setSelectedState] = React.useState({});
    const [panes, setPanes] = React.useState([
        {
            size: "35%",
            collapsible: true,
            scrollable: false,
        },
        {
            scrollable: false,
            min: "300px",
        },
    ]);
    const [columnsData, setColumnsData] = React.useState([]);
    const [isCheckColumn, setColumnCheck] = React.useState(false);

    const [isAddNewConfig, setNewConfig] = React.useState(false);
    const [effVersionDataItem, setEffVersionDataItem] = React.useState({});
    const [isAPICall, setAPICall] = React.useState(false);
    const [dataItem, setDataItem] = React.useState({});
    const [isRuleEdit, setRuleEdit] = React.useState(false);
    const [formFields, setFormFields] = React.useState({
        description: "",
    });
    const [loadingState, setLoadingState] = React.useState({
        isCompConfigLDetailLoad: false,
        isDeleteVersion: false,
    });
    const [effVersionState, setEffVersionState] = React.useState({
        changeVersion: { rowId: -1, versionIdx: -1 },
        insertVersion: { rowId: -1, periodKey: 0 },
        deleteVersion: { rowId: -1 },
    });
    const [dataList, setDataList] = React.useState([]);
    const [isUpdateCompRule, setUpdateCompRule] = React.useState(false);
    const [isCompRuleGridRefresh, setCompRuleGridRefresh] =
        React.useState(false);

    const _ref = useRef(null);
    let popupRef = React.useRef();
    useDocumentClickEventListener(isAddNewConfig, setNewConfig, popupRef);

    React.useEffect(() => {
        if (prorateReduxData?.prorateData) {
            setFormFields({
                ...formFields,
                description:
                    prorateReduxData?.prorateData?.rule_filter_desc || "",
                rule_filter_text:
                    prorateReduxData?.prorateData?.rule_filter_text || "",
                formula_filter_text:
                    prorateReduxData?.prorateData?.rule_filter_text || "",
                in_prorate_flag:
                    prorateReduxData?.prorateData?.in_prorate_flag || 0,
                in_prorate_period_type_mstr_key:
                    prorateReduxData?.prorateData
                        ?.in_prorate_period_type_mstr_key || 0,
                in_prorate_udf_data_obj_key:
                    prorateReduxData?.prorateData
                        ?.in_prorate_udf_data_obj_key || 0,
                is_clear_event_flag:
                    prorateReduxData?.prorateData?.is_clear_event_flag || 0,
                in_udf_data_obj_key:
                    prorateReduxData?.prorateData?.in_udf_data_obj_key || 0,
                ruleFilterItems:
                    prorateReduxData?.prorateData?.ruleFilterItems || [],
            });
        }
    }, [prorateReduxData.prorateData]);

    React.useEffect(() => {
        if (isCompRuleGridRefresh) {
            setCompRuleGridRefresh(false);
            getEffectiveVersionListReload();
        }
    }, [isCompRuleGridRefresh]);

    //change eff version
    React.useEffect(async () => {
        let masterTableData = dataList.slice();
        if (masterTableData.length > 0) {
            setLoadingState({
                ...loadingState,
                effVersionStateLoading: true,
            });
            const versionState = effVersionState.changeVersion;
            const mstrRow = masterTableData[versionState.rowId];
            if (mstrRow?.effVersionDetails) {
                let thisEffVersionDeta = mstrRow?.effVersionDetails?.data || [];
                thisEffVersionDeta
                    .filter((x) => x.isSelected === 1)
                    .map((item) => {
                        item.isSelected = 0;
                        return 0;
                    });

                if (thisEffVersionDeta.length) {
                    const newSelectedVersion =
                        thisEffVersionDeta[versionState.versionIdx];
                    newSelectedVersion.isSelected = 1;
                    setEffVersionDataItem({
                        ...effVersionDataItem,
                        dateVersion: newSelectedVersion.dateVersion,
                        periodName: newSelectedVersion.periodName,
                        periodMstrKey: newSelectedVersion?.periodMstrKey,
                    });
                    setData(masterTableData);
                    setDataList(masterTableData)
                    await getCompConfigData(newSelectedVersion.dateVersion);
                } else {
                    getEffectiveVersionListReload();
                }
            } else {
                await getEffectiveVersionListReload();
            }
        }
    }, [effVersionState.changeVersion]);

    React.useEffect(() => {
        if (
            Object.keys(dataItem).length > 0 &&
            !loadingState.isCompConfigLDetailLoad
        ) {
            getCompConfigData();
            setLoadingState({
                ...loadingState,
                isCompConfigLDetailLoad: true,
            });
        }
    }, [dataItem]);

    React.useEffect(() => {
        if (isUpdateCompRule) {
            getCompConfigData(effVersionDataItem.dateVersion);
            setUpdateCompRule(false);
        }
    }, [isUpdateCompRule]);

    /**
     * call add eff version API
     */
    React.useEffect(() => {
        let masterTableData = dataList.slice();
        const insertVersion = effVersionState.insertVersion;
        if (masterTableData.length > 0 && insertVersion.periodKey) {
            const mstrDeta = masterTableData[insertVersion.rowId];
            const bodyData = {
                in_copy_assignment: null,
                in_user_id: cookiesData.out_user_id,
                in_tenant_id: cookiesData.out_tenant_id,
                in_rule_config_key: dataItem.mstr_key,
                in_date_version: effVersionDataItem.dateVersion,
                in_rule_name: mstrDeta.rule_name,
                in_rule_desc: mstrDeta.rule_desc,
                in_rule_type_mstr_key: mstrDeta.rule_type_mstr_key,
                in_period_mstr_key: parseInt(insertVersion.periodKey),
                in_period_type_mstr_key:
                    mstrDeta.period_type_mstr_key === null
                        ? 4
                        : mstrDeta.period_type_mstr_key,
                in_flag: "copyVersion",
                in_current_version: 0,
                in_insert_id: 0,
                in_version: 0,
            };
            ActionVersion.addDataAsync({
                api: INSERT_COMP_RULE,
                bodyData: bodyData,
            });
        }
    }, [effVersionState.insertVersion]);

    React.useEffect(() => {
        if (selectedRowId) {
            getSelectedData();
        }
    }, [selectedRowId]);

    React.useEffect(async () => {
        if (listVersion.loadingState.isSaveData) {
            const selectedData = dataList.filter(
                (data) =>
                    data[DATA_ITEM_KEY].toString() === selectedRowId.toString()
            );
            let effVersionResponse = await getEffVersionsApi(
                selectedData[0].rule_config_key,
                selectedData[0].rule_type_mstr_key
            );
            let currentVersion =
                effVersionResponse.data.length > 0
                    ? effVersionResponse.data.filter((x) => x.isCurrent === 1)
                    : [];
            if (currentVersion.length > 0) {
                await getSelectedData("add", currentVersion[0].dateVersion);
            }
        }
    }, [listVersion.loadingState.isSaveData]);

    /**
     * update dataseta fter delete an existing version
     */
    React.useEffect(async () => {
        if (listVersion.loadingState.isDeleteData === true) {
            setEffVersionState({
                ...effVersionState,
                deleteVersion: { rowId: -1 },
            });
            let masterTableData = dataList.slice();
            const mstrIdx = effVersionState.deleteVersion.rowId;
            const mstrDeta = masterTableData[mstrIdx];
            setLoadingState({
                ...loadingState,
                isDeleteVersion: true,
            });
            let effVersionResponse = await getEffVersionsApi(
                mstrDeta.rule_config_key,
                mstrDeta.rule_type_mstr_key
            );
            if (!effVersionResponse.data.length) {
                setSelectedState({});
                setSelectedRowId("");
                setAPICall(true);
            } else {
                masterTableData[mstrIdx].effVersionDetails = effVersionResponse;
            }
            setData(masterTableData);
            setDataList(masterTableData)
            setLoadingState({
                ...loadingState,
                isDeleteVersion: false,
            });
            if (
                mstrDeta.effVersionDetails.data.length > 0 &&
                effVersionResponse.data.length > 0
            ) {
                let index = mstrDeta.effVersionDetails.data.findIndex(
                    (x) => x.isCurrent === 1
                );
                await getSelectedData(
                    "delete",
                    mstrDeta.effVersionDetails.data[index].dateVersion
                );
                await getCompConfigData(
                    mstrDeta.effVersionDetails.data[index].dateVersion
                );
            } else {
                let reqData = {
                    in_tenant_id: cookiesData.out_tenant_id,
                    in_rule_config_mstr_key: masterTableData[1].rule_config_key,
                    in_rule_type_mstr_key:
                        masterTableData[1].rule_type_mstr_key,
                    in_date_version: masterTableData[1].date_version,
                    in_filter: null,
                    in_sort_number: 1,
                    in_sort_order: "DESC",
                };
                getCompRuleConfigList(reqData);
            }
            // setRefreshLoading(true);
        }
    }, [listVersion.loadingState.isDeleteData]);

    const deleteEffVersion = async (deleteVersion) => {
        let masterTableData = dataList.slice();
        const mstrDeta = masterTableData[deleteVersion.rowId];
        const thisEffVersionDeta = mstrDeta?.effVersionDetails?.data || [];
        const selectedVersion = thisEffVersionDeta.filter(
            (x) => x.isSelected === 1
        )[0];

        setEffVersionDataItem({
            ...effVersionDataItem,
            dateVersion: selectedVersion.dateVersion,
            periodName: selectedVersion.periodName,
            periodMstrKey: selectedVersion?.periodMstrKey,
        });
        const bodyData = {
            in_user_id: cookiesData.out_user_id,
            in_version_data: [
                {
                    mstr_key: mstrDeta.rule_config_key,
                    date_version: selectedVersion.dateVersion,
                    rule_type_mstr_key: mstrDeta.rule_type_mstr_key,
                },
            ],
            in_tenant_id: cookiesData.out_tenant_id,
        };

        const res = await ActionVersion.deleteDataAsync({
            api: DELETE_COMP_RULE,
            bodyData: bodyData,
        });
        // window.location.reload(false)
    };

    /**
     * call delete eff version API
     */
    React.useEffect(async () => {
        let masterTableData = dataList.slice();
        const deleteVersion = effVersionState.deleteVersion;
        if (masterTableData.length > 0 && deleteVersion.rowId !== -1) {
            const mstrDeta = masterTableData[deleteVersion.rowId];
            const thisEffVersionDeta = mstrDeta?.effVersionDetails?.data;
            if (thisEffVersionDeta) {
                deleteEffVersion(deleteVersion);
            } else {
                await getEffectiveVersionListReload();
                await deleteEffVersion(deleteVersion);
            }
        }
    }, [effVersionState.deleteVersion]);

    const getEffectiveVersionListReload = () => {
        const selectedData = dataList.filter(
            (data) =>
                data[DATA_ITEM_KEY].toString() === selectedRowId.toString()
        );
        let newSelectedData = {};

        if (selectedData[0]) {
            newSelectedData["primary_key"] =
                selectedData[0].rule_config_key +
                "_" +
                selectedData[0].rule_type_mstr_key;
            newSelectedData["mstr_key"] = selectedData[0].rule_config_key;
            newSelectedData["mstr_name"] = selectedData[0].rule_name;
            newSelectedData["date_version"] = selectedData[0].date_version;
            newSelectedData["period_mstr_key"] =
                selectedData[0].period_mstr_key;
            newSelectedData["period_start_date"] =
                selectedData[0].period_start_date;
            newSelectedData["period_name"] = selectedData[0].plan_year;
            newSelectedData["rule_type_mstr_key"] =
                selectedData[0].rule_type_mstr_key;
            newSelectedData["rule_type_name"] = selectedData[0].rule_type_name;
        } else {
            //   setDataItem({});
        }
        if (selectedData.length > 0) {
            const index = dataList.findIndex(
                (data) => data[DATA_ITEM_KEY] === selectedRowId
            );
            let masterTableData = dataList.slice();
            masterTableData[index].effVersionDetails = effVersionDataItem;
            newSelectedData["effVersionDetails"] = effVersionDataItem;
            newSelectedData["rowId"] = index;
            setDataItem(newSelectedData);
            if (Object.keys(effVersionDataItem).length > 0) {
                let selectedEffVersion = effVersionDataItem?.data.find(
                    (x) => x.isSelected === 1
                );
                if (selectedEffVersion) {
                    setEffVersionDataItem({
                        ...effVersionDataItem,
                        dateVersion: selectedEffVersion?.dateVersion,
                        periodName: selectedEffVersion?.periodName,
                        periodMstrKey: selectedEffVersion?.periodMstrKey,
                    });
                }
            }

            setData(masterTableData);
            setDataList(masterTableData)
            setLoadingState({
                ...loadingState,
                effVersionStateLoading: false,
            });
        }
    };

    const getSelectedData = (module, dateVersion) => {
        const selectedData = dataList.filter(
            (data) => data[DATA_ITEM_KEY] === selectedRowId
        );
        let newSelectedData = {};

        if (selectedData[0]) {
            newSelectedData["primary_key"] =
                selectedData[0].rule_config_key +
                "_" +
                selectedData[0].rule_type_mstr_key;
            newSelectedData["mstr_key"] = selectedData[0].rule_config_key;
            newSelectedData["mstr_name"] = selectedData[0].rule_name;
            newSelectedData["date_version"] =
                module === "add" || module === "delete"
                    ? dateVersion
                    : selectedData[0].date_version;
            newSelectedData["period_mstr_key"] =
                selectedData[0].period_mstr_key;
            newSelectedData["rule_type_mstr_key"] =
                selectedData[0].rule_type_mstr_key;
            newSelectedData["rule_type_name"] = selectedData[0].rule_type_name;
        }
        if (selectedData.length > 0) {
            getEffectiveVersionList(newSelectedData);
        } else {
            //   setDataItem({});
        }
    };

    /**
     * call list eff version API
     */
    const getEffVersionsApi = async (mstrKey, rule_type_mstr_key) => {
        setRefreshLoading(true);
        const versionProps = {
            apiEndPoints: { listApi: SELECT_COMP_RULE_EFF_VERSION },
            dataItem: {
                mstr_key: mstrKey,
                in_rule_type_mstr_key: rule_type_mstr_key,
            },
            pageProps: { writeAccess: pageProps.writeAccess },
        };

        const versionApiCallData = {
            in_tenant_id: cookiesData.out_tenant_id,
            in_mstr_key: mstrKey,
            in_rule_type_mstr_key: rule_type_mstr_key,
            in_sort_number: 2,
            in_sort_order: "ASC",
        };
        //get response for eff version tab
        let effVersionResponse = await ActionVersion.getListDataAsync(
            versionApiCallData,
            versionProps
        );

        const currentEndDate = effVersionResponse.currentVersionEndDate;

        //get response for eff version period menu
        const periodDataResponse = await ActionVersion.getPeriodsDataTypeAsync(
            currentEndDate
        );

        effVersionResponse["periodsData"] = periodDataResponse;

        setRefreshLoading(false);
        return effVersionResponse;
    };
    const getEffectiveVersionList = async (newSelectedData) => {
        setDataItem({});
        const index = dataList.findIndex(
            (data) => data[DATA_ITEM_KEY] === selectedRowId
        );
        let effVersionDataItem = await getEffVersionsApi(
            newSelectedData.mstr_key,
            newSelectedData.rule_type_mstr_key
        );
        let masterTableData = dataList.slice();
        masterTableData[index].effVersionDetails = effVersionDataItem;
        newSelectedData["effVersionDetails"] = effVersionDataItem;
        newSelectedData["rowId"] = index;
        setDataItem(newSelectedData);
        let selectedEffVersion = effVersionDataItem?.data?.find(
            (x) => x.isSelected === 1
        );
        setEffVersionDataItem({
            ...effVersionDataItem,
            dateVersion: selectedEffVersion?.dateVersion,
            periodName: selectedEffVersion?.periodName,
            periodMstrKey: selectedEffVersion?.periodMstrKey,
        });
        setData(masterTableData);
        setDataList(masterTableData)
    };

    const getCompConfigData = async (selectedDateVersion) => {
        let reqData = {
            in_tenant_id: cookiesData.out_tenant_id,
            in_rule_config_mstr_key: dataItem.mstr_key,
            in_rule_type_mstr_key: dataItem.rule_type_mstr_key,
            in_date_version: selectedDateVersion
                ? selectedDateVersion
                : dataItem.date_version,
            in_filter: null,
            in_sort_number: 1,
            in_sort_order: "DESC",
        };
        await getCompRuleConfigList(reqData);
    };

    const getCompRuleConfigList = async (reqData) => {
        let res = await Action.getCompRuleConfig(reqData);
        if (res && res.length > 0) {
            let response = res[0];
            setTimeout(() => {
                dispatch(
                    setAllData({
                        in_prorate_udf_data_obj_key:
                            response.prorate_udf_data_obj_key,
                        in_prorate_flag: response.prorate_flag,
                        in_prorate_period_type_mstr_key:
                            response.prorate_period_type_mstr_key,
                        rule_filter_desc:
                            response.rule_filter_desc === null
                                ? ""
                                : response.rule_filter_desc,
                        rule_filter_text:
                            !response.rule_filter_text ||
                            response.rule_filter_text === "NULL"
                                ? null
                                : response.rule_filter_text,
                        formula_filter_text: response.formula_filter_text,
                        clear_event_flag: response.clear_event_flag,
                        in_udf_data_obj_key: response.udf_data_object_key,
                        is_clear_event_flag:
                            response.clear_event_flag === null
                                ? 0
                                : response.clear_event_flag,
                        rule_name: response.rule_name,
                        ruleFilterItems: [],
                        executionData: {
                            generate_zero_result: response.generate_zero_result,
                            generate_quarter_total:
                                response.generate_quarter_total,
                            generate_year_total: response.generate_year_total,
                            generate_qtd_value: response.generate_qtd_value,
                            generate_ytd_value: response.generate_ytd_value,
                            gl_mstr_key: response.gl_mstr_key,
                            is_pass_thru: response.is_pass_thru,
                            period_type_mstr_key: response.period_type_mstr_key,
                            is_rollable: response.is_rollable,
                        },
                        resultSettingData: {
                            result_name: response.result_name,
                            value_fx:
                                response.value_fx || response.formula_name,
                            value:
                                response.value === null ? "" : response.value,
                            value_unit_type: response.value_unittype,
                            is_enable: false,
                            result_name_key: response.result_name_mstr_key,
                            rate_table_name: response.rate_table_name,
                            rate_table_key: response.rate_table_mstr_key,
                            formula_name: response.formula_name,
                            formula_mstr_key: response.value_formula_mstr_key,
                            in_rate_type_mstr_key: response.rate_type_mstr_key,
                            is_kpi_wt_applied: response.is_kpi_wt_applied,
                        },
                    })
                );
            }, 50);
        }
        setLoadingState({
            ...loadingState,
            effVersionStateLoading: false,
        });
        if (_ref.current) {
            _ref.current.scrollTop = 0;
        }
    };

    React.useEffect(() => {
        if (selectedRowId) {
            const selectedData = dataList.filter(
                (item) => item[DATA_ITEM_KEY] === selectedRowId
            );
            let newSelectedData = {};
            if (selectedData[0]) {
                let selectedVersion = {};
                if (
                    selectedData[0].effVersionDetails &&
                    Object.keys(selectedData[0].effVersionDetails).length > 0
                ) {
                    let thisEffVersionDeta =
                        selectedData[0]?.effVersionDetails?.data;
                    selectedVersion = thisEffVersionDeta?.filter(
                        (x) => x.isSelected === 1
                    )[0];
                }
                newSelectedData["rule_type_mstr_key"] =
                    selectedData[0].rule_type_mstr_key;
                newSelectedData["primary_key"] =
                    selectedData[0].rule_config_key +
                    "_" +
                    selectedData[0].rule_type_mstr_key;
                newSelectedData["mstr_key"] = selectedData[0].rule_config_key;
                newSelectedData["mstr_name"] = selectedData[0].rule_name;
                newSelectedData["date_version"] = selectedData[0].date_version
                    ? selectedData[0].date_version
                    : selectedVersion?.dateVersion;
                newSelectedData["period_mstr_key"] =
                    selectedData[0].period_mstr_key;
                newSelectedData["period_type_mstr_key"] =
                    selectedData[0].period_type_mstr_key;
                newSelectedData["rule_description"] = selectedData[0].rule_desc;
                newSelectedData["rule_type_name"] =
                    selectedData[0].rule_type_name;
            }
            setDataItem(newSelectedData);
            setLoadingState({
                ...loadingState,
                isCompConfigLDetailLoad: false,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRowId]);

    const onPaneChange = (event) => {
        setPanes(event.newState);
    };

    const handleMenuItemClick = (action) => {
        switch (action) {
            case "delete":
                setShow({
                    ...isShow,
                    deleteRows: !isShow.deleteRows,
                });
                break;
            case "duplicateRow":
                setShow({
                    ...isShow,
                    duplicateRow: !isShow.duplicateRow,
                });
                break;
            default:
                setShow({
                    ...isShow,
                    deleteRows: false,
                });
                break;
        }
    };

    const addNewConfig = (e) => {
        setNewConfig(!isAddNewConfig);
        e.preventDefault();
    };

    const closePopup = (isGridRefresh) => {
        setNewConfig(false);
        if (isGridRefresh) {
            setSelectedState({});
            setAPICall(true);
        }
    };

    const AddNewLink = () => {
        return (
            <div ref={popupRef}>
                <a
                    //href="/#"
                    className="cta-small"
                    ref={addRef}
                    onClick={(e) => {
                        //TODO : UNSAVED CHANGES

                        // if (unsavedPopup.isUnSavedChange) {
                        //   dispatch(
                        //     setUnSavedChangesPopup({
                        //       ...unsavedPopup,
                        //       isVisible: true,
                        //       callbackFn: () => {
                        //         addNewConfig(e);
                        //       },
                        //     })
                        //   );
                        // } else {
                        addNewConfig(e);
                        // }
                    }}
                >
                    <i className="fas fa-plus"></i> Add New Rule
                </a>
                <Popup
                    anchor={addRef.current}
                    show={isAddNewConfig}
                    setShow={setNewConfig}
                    className="right0"
                >
                    <div className="dot-dropdown-open dot-dropdown-open-Form">
                        <div className="caption">
                            <span>Add New Rule</span>
                        </div>
                        <AddRule closePopup={closePopup} />
                    </div>
                </Popup>
            </div>
        );
    };

    const headerConfig = {
        title: "Compensation Rules",
        id: "Compensation_Rules",
        add_new_fx: AddNewLink,
        action_menu: {
            delete: {
                fx: handleMenuItemClick,
                caption: "Delete Selected Rules",
            },
            duplicateRow: {
                fx: handleMenuItemClick,
                caption: "Copy Selected Rules",
            },
        },
        manage_col: {
            columnsData: columnsData,
            setColumnsData: setColumnsData,
            setColumnCheck: setColumnCheck,
        },
    };

    const parent = document.querySelector("#content-" + headerConfig.id);

    if (parent) {
        const deleteRows = parent.querySelector(".delete-rows");
        deleteRows && deleteRows.disableKendoMenu();
        const copyData = parent.querySelector(".copy-data");
        copyData && copyData.disableKendoMenu();

        if (selectedRowId !== "") {
            deleteRows && deleteRows.enableKendoMenu();
            copyData && copyData.enableKendoMenu();
        }
    }

    const leftPane = () => {
        return (
            <>
                <div className="content-head">
                    <HeaderPage
                        pageProps={pageProps}
                        headerConfig={headerConfig}
                    />
                </div>

                <div className="content-body">
                    <div className="dtu-file-container">
                        <CompenSationData
                            setIsCopyLoading={setIsCopyLoading}
                            headerId={headerConfig.id}
                            setColumnsData={setColumnsData}
                            setSelectedRowId={setSelectedRowId}
                            selectedRowId={selectedRowId}
                            isShow={isShow}
                            isRuleFilterEnabled={isRuleFilterEnabled}
                            setShow={setShow}
                            setDataList={setDataList}
                            isCheckColumn={isCheckColumn}
                            setColumnCheck={setColumnCheck}
                            columnsData={columnsData}
                            apiProps={list}
                            setSelectedState={setSelectedState}
                            selectedState={selectedState}
                            pageProps={pageProps}
                            setRuleEdit={setRuleEdit}
                            isRuleEdit={isRuleEdit}
                            formFields={formFields}
                            setFormFields={setFormFields}
                            setAPICall={setAPICall}
                            isAPICall={isAPICall}
                            setUpdateCompRule={setUpdateCompRule}
                            dataItem={dataItem}
                            setCompRuleGridRefresh={setCompRuleGridRefresh}
                            effVersionDataItem={effVersionDataItem}
                        />
                    </div>
                </div>
            </>
        );
    };

    const headerAssignmentConfig = {
        title: (
            <div className="capitalize">
                {dataItem.rule_type_name
                    ? dataItem.rule_type_name +
                      " Rule Configuration | " +
                      prorateReduxData.prorateData.rule_name
                    : "Rule Configuration"}
            </div>
        ),
        buttons: { template_fx: GridButton },
    };

    const getEffVersions = () => {
        return (
            <>
                {dataItem ? (
                    <>
                        <EffectiveVersions
                            pageProps={pageProps}
                            versionData={dataItem}
                            effVersionState={effVersionState}
                            setEffVersionState={setEffVersionState}
                        />
                    </>
                ) : (
                    false
                )}
            </>
        );
    };

    const handleChange = (e) => {
        dispatch(
            setProrationData({
                ...prorateReduxData.prorateData,
                rule_filter_desc: e.target.value,
            })
        );
        changeCancelBtnState("enable", "grid-incell-cancel-comp-rule");
        changeUpdateBtnState("enable", "grid-incell-update-comp-rule");
    };

    const rightPane = () => {
        // if (!dataItem.mstr_key) {
        //   return <Loader size="large" className="k-loading-procare" />;
        // }
        const versionData = dataItem?.effVersionDetails?.data;
        const selectedVersionData = versionData?.find(
            (o) => o.isSelected === 1
        );

        return (
            <>
                {/* {isCopyLoading ? <LoadingPanel /> : false} */}
                {loadingState.effVersionStateLoading && <LoadingPanel />}
                <div className="scroll-container compborder" ref={_ref}>
                    <div className="content-head ">
                        <HeaderPage
                            pageProps={pageProps}
                            headerConfig={headerAssignmentConfig}
                        />
                    </div>

                    {getEffVersions()}

                    {loadingState.isDeleteVersion && <LoadingPanel />}
                    {dataItem &&
                    dataItem?.effVersionDetails &&
                    Object.keys(dataItem?.effVersionDetails).length > 0 ? (
                        <>
                            <div className="innerscrollrules">
                                <div className="inner-title-head">
                                    <h1>Filter Condition</h1>
                                </div>

                                <div className="inner-title-cont">
                                    <div className={"k-form-field-wrap-popup"}>
                                        <Label className="innerlabletext">
                                            {"Description : "}
                                        </Label>
                                        <Input
                                            disabled={
                                                pageProps.writeAccess === 0
                                                    ? true
                                                    : false
                                            }
                                            className={"description-input"}
                                            value={formFields.description}
                                            onChange={(e) => {
                                                handleChange(e);
                                                //TODO : UNSAVED CHANGES
                                                // dispatch(
                                                //   setUnSavedChangesPopup({
                                                //     ...unsavedPopup,
                                                //     isUnSavedChange: true,
                                                //   })
                                                // );
                                            }}
                                            autoFocus={false}
                                        />
                                    </div>
                                </div>
                                <RuleFilterConditions
                                    setRuleFilterEnabled={setRuleFilterEnabled}
                                    pageProps={pageProps}
                                    effVersionState={effVersionState}
                                />

                                {(() => {
                                    switch (
                                        dataItem.rule_type_name
                                            .replace(/\s/g, "")
                                            .toLowerCase()
                                    ) {
                                        case "incentive":
                                            return (
                                                <>
                                                    <IncentiveResultSetting
                                                        onDataChange={() => {
                                                            //TODO : UNSAVED CHANGES
                                                            // dispatch(
                                                            //   setUnSavedChangesPopup({
                                                            //     ...unsavedPopup,
                                                            //     isUnSavedChange: true,
                                                            //   })
                                                            // );
                                                        }}
                                                        pageProps={pageProps}
                                                    />

                                                    <IncentiveExecutionSettings
                                                        onDataChange={() => {
                                                            //TODO : UNSAVED CHANGES
                                                            // dispatch(
                                                            //   setUnSavedChangesPopup({
                                                            //     ...unsavedPopup,
                                                            //     isUnSavedChange: true,
                                                            //   })
                                                            // );
                                                        }}
                                                        pageProps={pageProps}
                                                        key={
                                                            selectedVersionData?.unique_key
                                                        }
                                                    />

                                                    <ProrationSettings
                                                        onDataChange={() => {
                                                            //TODO : UNSAVED CHANGES
                                                            // dispatch(
                                                            //   setUnSavedChangesPopup({
                                                            //     ...unsavedPopup,
                                                            //     isUnSavedChange: true,
                                                            //   })
                                                            // );
                                                        }}
                                                        pageProps={pageProps}
                                                    />
                                                </>
                                            );
                                        case "payment":
                                            return (
                                                <>
                                                    <PaymentResultSetting
                                                        onDataChange={() => {
                                                            //TODO : UNSAVED CHANGES
                                                            // dispatch(
                                                            //   setUnSavedChangesPopup({
                                                            //     ...unsavedPopup,
                                                            //     isUnSavedChange: true,
                                                            //   })
                                                            // );
                                                        }}
                                                        pageProps={pageProps}
                                                    />

                                                    <PaymentExecutionSettings
                                                        onDataChange={() => {
                                                            //TODO : UNSAVED CHANGES
                                                            // dispatch(
                                                            //   setUnSavedChangesPopup({
                                                            //     ...unsavedPopup,
                                                            //     isUnSavedChange: true,
                                                            //   })
                                                            // );
                                                        }}
                                                        pageProps={pageProps}
                                                        key={
                                                            selectedVersionData?.unique_key
                                                        }
                                                    />

                                                    <ProrationSettings
                                                        onDataChange={() => {
                                                            //TODO : UNSAVED CHANGES
                                                            // dispatch(
                                                            //   setUnSavedChangesPopup({
                                                            //     ...unsavedPopup,
                                                            //     isUnSavedChange: true,
                                                            //   })
                                                            // );
                                                        }}
                                                        pageProps={pageProps}
                                                    />
                                                </>
                                            );
                                        case "measurement":
                                            return (
                                                <>
                                                    <MeasurementResultSetting
                                                        onDataChange={() => {
                                                            //TODO : UNSAVED CHANGES
                                                            // dispatch(
                                                            //   setUnSavedChangesPopup({
                                                            //     ...unsavedPopup,
                                                            //     isUnSavedChange: true,
                                                            //   })
                                                            // );
                                                        }}
                                                        pageProps={pageProps}
                                                    />

                                                    <MeasurementExecutionSettings
                                                        onDataChange={() => {
                                                            //TODO : UNSAVED CHANGES
                                                            // dispatch(
                                                            //   setUnSavedChangesPopup({
                                                            //     ...unsavedPopup,
                                                            //     isUnSavedChange: true,
                                                            //   })
                                                            // );
                                                        }}
                                                        pageProps={pageProps}
                                                        key={
                                                            selectedVersionData?.unique_key
                                                        }
                                                    />

                                                    <ProrationSettings
                                                        onDataChange={() => {
                                                            //TODO : UNSAVED CHANGES
                                                            // dispatch(
                                                            //   setUnSavedChangesPopup({
                                                            //     ...unsavedPopup,
                                                            //     isUnSavedChange: true,
                                                            //   })
                                                            // );
                                                        }}
                                                        pageProps={pageProps}
                                                    />
                                                </>
                                            );
                                        case "creditallocation":
                                            return (
                                                <>
                                                    <CreditAllocationResultSetting
                                                        onDataChange={() => {
                                                            //TODO : UNSAVED CHANGES
                                                            // dispatch(
                                                            //   setUnSavedChangesPopup({
                                                            //     ...unsavedPopup,
                                                            //     isUnSavedChange: true,
                                                            //   })
                                                            // );
                                                        }}
                                                        pageProps={pageProps}
                                                    />
                                                </>
                                            );
                                        case "directcredit":
                                        case "indirectcredit":
                                            return (
                                                <>
                                                    <CreditRuleResultSetting
                                                        onDataChange={() => {
                                                            //TODO : UNSAVED CHANGES
                                                            // dispatch(
                                                            //   setUnSavedChangesPopup({
                                                            //     ...unsavedPopup,
                                                            //     isUnSavedChange: true,
                                                            //   })
                                                            // );
                                                        }}
                                                        pageProps={pageProps}
                                                    />
                                                    <CreditRuleExecutionSettings
                                                        onDataChange={() => {
                                                            //TODO : UNSAVED CHANGES
                                                            // dispatch(
                                                            //   setUnSavedChangesPopup({
                                                            //     ...unsavedPopup,
                                                            //     isUnSavedChange: true,
                                                            //   })
                                                            // );
                                                        }}
                                                        pageProps={pageProps}
                                                        key={
                                                            selectedVersionData?.unique_key
                                                        }
                                                    />
                                                    <ProrationSettings
                                                        onDataChange={() => {
                                                            //TODO : UNSAVED CHANGES
                                                            // dispatch(
                                                            //   setUnSavedChangesPopup({
                                                            //     ...unsavedPopup,
                                                            //     isUnSavedChange: true,
                                                            //   })
                                                            // );
                                                        }}
                                                        pageProps={pageProps}
                                                    />

                                                    <WritebackEvent
                                                        onDataChange={() => {
                                                            //TODO : UNSAVED CHANGES
                                                            // dispatch(
                                                            //   setUnSavedChangesPopup({
                                                            //     ...unsavedPopup,
                                                            //     isUnSavedChange: true,
                                                            //   })
                                                            // );
                                                        }}
                                                        pageProps={pageProps}
                                                    />
                                                </>
                                            );

                                        default:
                                            break;
                                    }
                                })()}
                            </div>
                        </>
                    ) : (
                        <>
                            {dataList.length === 0 &&
                            !dataItem.mstr_key &&
                            columnsData.length > 0 ? (
                                <div style={{ height: "70vh" }}>
                                    {noRecordScreenLayout()}
                                </div>
                            ) : (
                                <Loader
                                    size="large"
                                    className="k-loading-procare"
                                />
                            )}
                        </>
                    )}
                </div>
            </>
        );
    };

    React.useEffect(() => {
        if (Object.keys(effVersionDataItem).length > 0) {
            if (isRuleEdit === false) {
                changeCancelBtnState("disable", "grid-incell-cancel-comp-rule");
                changeUpdateBtnState("disable", "grid-incell-update-comp-rule");
            }
        }
    }, [effVersionDataItem, isRuleEdit]);

    return (
        <div className="Main mobileblock">
            <div className="overlaymobile">
                <div className="overlaycont">
                    <div className="procarelogomob"></div>
                    <div className="mobnotificationlogo">
                        <h3>Please use tablet or desktop to view this page</h3>
                        <button className="primarybtn" href="/dashboard">
                            Back to Home
                        </button>
                    </div>
                </div>
            </div>
            <div className="content">
                <Splitter
                    panes={panes}
                    onChange={onPaneChange}
                    className="splitviewcustom"
                >
                    <div className="pane-content">{leftPane()}</div>
                    <div className="pane-content kpiassignment">
                        {dataList.length === 0 && dataItem.mstr_key
                            ? noRecordScreenLayout()
                            : false}
                        {rightPane()}
                    </div>
                </Splitter>
            </div>

            {isVersionShow.dtuLogs && (
                <DtuBatchLogs
                    visible={isVersionShow}
                    setShow={setVersionShow}
                    module={"org_position"}
                    pageProps={pageProps}
                />
            )}
            {isShow.isAddNewRule && (
                <AddRule setShow={setShow} isShow={isShow} />
            )}
        </div>
    );
};

const noRecordScreenLayout = () => {
    return (
        <div className="assign-nodata">
            <div className="overlaycont">
                <div className="procarelogomob"></div>
                <div className="mobnotificationlogo">
                    <h3>
                        {" "}
                        There is no record on the left side of the screen.
                        Please clear the filter or refresh the screen.
                    </h3>
                </div>
            </div>
        </div>
    );
};
export default React.memo(CompensationRules);
