import React from "react";
import { getUserCredential } from "../../lib/cookies";
import { errStatusHandler, POSTAPI } from "../../api-setup/api-helper";
import { useDispatch } from "react-redux";
import { setNewMessage } from "../../redux/actions/setting-actions";
import {
    SELECT_RULE_TYPE_MASTER,
    DELETE_RULE_TYPE_MASTER,
    UPDATE_RULE_TYPE_MASTER,
    INSERT_RULE_TYPE_MASTER,
} from "../../api-setup/api-endpoints";
import { useHistory } from "react-router-dom";
import { getColumListXML } from "../../lib/common-service";

export default function Events() {
    const history = useHistory();
    const cookiesData = getUserCredential();
    const header = {
        "x-access-token": cookiesData.secretToken,
    };
    const dispatch = useDispatch();
    const [data, setData] = React.useState([]);
    const [columns, setColumns] = React.useState([]);
    const [dataCount, setDataCount] = React.useState(0);
    const [initialLoading, setLoadingState] = React.useState(false);
    const [loadingState, setLoadState] = React.useState({
        isSaveData: undefined,
        isDeleteData: undefined,
    });
    const [isAPICalling, setAPICallingState] = React.useState(false);
    const [originalData, setOriginalData] = React.useState([]);
    const [visible, setVisible] = React.useState(false);
    const [noDataFound, setNoDataFound] = React.useState(false);
    const [dataState, setDataState] = React.useState();
    const [isRefreshLoading, setRefreshLoading] = React.useState(false);
    const [confirmationPopup, setConfirmationPopup] = React.useState({
        title: "Delete",
        desc: "Are you sure you want to delete this record?",
        isVisible: false,
        type: "",
        actionBtn: [],
        data: {},
        action: "Delete",
    });

    const getResultDataList = (listData) => {
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                SELECT_RULE_TYPE_MASTER,
                null,
                header,
                listData,
                dispatch
            ).then((res) => {
                if (res) {
                    if (res.data.out_parameters.out_ret_code > 0) {
                        setNoDataFound(true);
                        setLoadingState(true);
                        errStatusHandler(res, history, dispatch);
                    }
                    res.data.row_result.map((item, idx) => {
                        item.rowId = idx;
                        return 0;
                    });

                    setAPICallingState(false);
                    setLoadingState(true);
                    setVisible(true);
                    setData(res.data.row_result);

                    setDataCount(res.data.out_parameters.out_total_rows);
                    setOriginalData(res.data.row_result);
                    let coloumRealod = listData.in_load_type;
                    GetColumnXmlListAsync(coloumRealod);
                    setNoDataFound(false);
                    setRefreshLoading(false);
                    resolve(res.data.row_result);
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    const GetColumnXmlListAsync = async (coloumRealod) => {
        const reqData = {
            in_tenant_id: cookiesData.out_tenant_id,
            in_filter: " AND ( (a.result_name_column = 0) )",
            in_filter_db_name: "procare_system",
            in_filter_table_name: "vw_result_type_master",
            in_result_name_mstr_key: 0,
            in_event_type_mstr_key: "0",
            in_hide_column: 0,
            in_load_type: coloumRealod,
        };
        let resData = await getColumListXML(reqData, history, dispatch);
        if (resData) {
            if (reqData.in_load_type === "grid-reload") {
                setColumns(resData);
            }
        }
    };

    const updateResultDataAsync = (bodyData) => {
        setRefreshLoading(true);
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                UPDATE_RULE_TYPE_MASTER,
                null,
                header,
                bodyData,
                dispatch
            ).then((res) => {
                setRefreshLoading(false);
                if (res && res.data.out_parameters.out_ret_code === 0) {
                    resolve(true);
                } else {
                    dispatch(
                        setNewMessage({
                            message: res?.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    resolve([]);
                    errStatusHandler(res, history, dispatch);
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    const addResultTypeDataAsync = (bodyData) => {
        setRefreshLoading(true);
        setLoadState({ ...loadingState, isSaveData: false });
        POSTAPI(
            false,
            INSERT_RULE_TYPE_MASTER,
            null,
            header,
            bodyData,
            dispatch
        ).then((res) => {
            if (res.status) {
                setRefreshLoading(false);
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                } else {
                    setLoadState({ ...loadingState, isSaveData: true });
                }
            }
        });
    };

    const deleteResultTypeDataAsync = async (bodyData, resultArray) => {
        setRefreshLoading(true);
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                DELETE_RULE_TYPE_MASTER,
                null,
                header,
                bodyData,
                dispatch
            ).then((res) => {
                const totalResultTypes = bodyData.in_result_type_mstr_key.length;
                const unSucessFullCount = res.data.successCount;
                setRefreshLoading(false);
                if (res.data.out_parameters.out_ret_code > 0) {
                    let msg = res.data.out_parameters.out_error_msg;

                    

                    if (totalResultTypes !== unSucessFullCount) {
                        msg =
                            unSucessFullCount +
                            " Result Type (" +
                            res.data.successName +
                            ") out of " +
                            totalResultTypes + ' '+
                            "selected linked to active result name - cannot delete.";


                        setLoadState({ ...loadingState, isDeleteData: true });
                        setTimeout(function () {
                            resolve("success");
                        });
                    } else {
                        setLoadState({ ...loadingState, isDeleteData: false });
                        setTimeout(function () {
                            resolve("error");
                        });
                    }
                    setConfirmationPopup({
                        ...confirmationPopup,
                        isVisible: false,
                        type: "",
                        actionBtn: [],
                        data: {},
                        action: "",
                    });
                    dispatch(
                        setNewMessage({
                            message: msg,
                            type: "Error",
                            show: true,
                        })
                    );
                } else {
                    setConfirmationPopup({
                        ...confirmationPopup,
                        isVisible: false,
                        type: "",
                        actionBtn: [],
                        data: {},
                        action: "",
                    });
                    setLoadState({ ...loadingState, isDeleteData: true });
                    setTimeout(function () {
                        resolve("success");
                    });
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };
    const exportDataAsync = (bodyData) => {
        setRefreshLoading(true);
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                SELECT_RULE_TYPE_MASTER,
                null,
                header,
                bodyData,
                dispatch
            ).then((res) => {
                setRefreshLoading(false);
                if (
                    res &&
                    (res.data.out_parameters.out_ret_code === 0 ||
                        res.data.out_parameters.out_ret_code === 2)
                ) {
                    resolve(res.data.row_result);
                } else {
                    dispatch(
                        setNewMessage({
                            message: res?.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    resolve([]);
                    errStatusHandler(res, history, dispatch);
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };
    return [
        {
            data,
            columns,
            dataCount,
            setData,
            setColumns,
            isAPICalling,
            initialLoading,
            setAPICallingState,
            originalData,
            visible,
            setVisible,
            setNoDataFound,
            noDataFound,
            dataState,
            loadingState,
            setLoadState,
            setRefreshLoading,
            isRefreshLoading,
            setDataState
        },
        {
            getResultDataList,
            updateResultDataAsync,
            addResultTypeDataAsync,
            deleteResultTypeDataAsync,
            exportDataAsync,
        },
    ];
}
