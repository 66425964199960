import React, { useEffect } from "react";

export function useSource() {
    const ref = React.useRef();
    if (!ref.current) {
        ref.current = { SOURCE: {} };
    } else {
        ref.current.SOURCE = {};
    }
    return ref.current.SOURCE;
}

export const usePrevious = (value) => {
    const ref = React.useRef();

    useEffect(() => {
        ref.current = value;
    }, [value]);

    return ref.current;
};

export function useDocumentClickEventListener(
    isAddNewConfig,
    setNewConfig,
    divRef
) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleDocumentClick = (e) => {
        if (divRef.current && !divRef.current.contains(e.target)) {
            // setNewConfig(false);
        }
    };
    useEffect(() => {
        if (isAddNewConfig) {
            document.body.addEventListener("click", handleDocumentClick);
        } else {
            document.body.removeEventListener("click", handleDocumentClick);
        }

        return () => {
            document.body.removeEventListener("click", handleDocumentClick);
        };
    }, [isAddNewConfig, handleDocumentClick]);
}
