// eslint-disable-next-line no-useless-concat
import React from "react";
import { HeaderPage } from "../base/headerPage";
import { GridColumn as Column } from "@progress/kendo-react-grid";
import CustomDropdown from "../../components/customDropdown";
import { Label } from "@progress/kendo-react-labels";
import { getTenantCookieData, getUserCredential } from "../../lib/cookies";
import useApi from "./service";
import { LoadingPanel } from "../../components/loadingPanel";
import DataTable from "../../components/dataTable";
import { DEFAULT_SORT } from "../../constants/grid";
import {
  executeGetColumnOrderInfo,
  formatDate,
  formatDateforTimeZone,
  getfilterDataString,
  dateFormateCellForJob,
} from "../../utils/utils";
import { columnMenuProps } from "../../components/customColumnMenu";
import MultiSelectDropdown from "../../components/multiSelect";
import { Button } from "@progress/kendo-react-buttons";
import "./job-history.module.scss";
import { jobHistoryGridWidth } from "../../utils/calculateGridWidth";
import { updateColumnReorderAsync } from "src/lib/common-service";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";

const JobHistory = (pageProps) => {
  const divRef = React.createRef();
  const cookiesData = getUserCredential();
  const history = useHistory();
  const dispatch = useDispatch();
  const [list, Action] = useApi();
  const {
    data,
    columns,
    dataCount,
    isColumnMenuSearch,
    isAPICalling,
    setAPICallingState,
    initialLoading,
    setLoadingStateSearch,
    loading,
    noDataFound,
    setJobTypeFields,
    jobTypeFields,
    groupNameFields,
    jobNameFields,
    setGroupNameFields,
    setJobNameFields,
    jobTypeList,
    setPeriodFields,
    periodsFields,
    jobNameList,
    periodTypeList,
    groupNameList,
    periodsTypeFields,
    setPeriodTypeFields,
    periodsList,
    setData,
    setColumns,
  } = list;
  const defaultPageSize = getTenantCookieData("record_per_page");
  const [exportDataClick, setExportDataClick] = React.useState(false);

  const [pageData, setPage] = React.useState({
    skip: 0,
    take: defaultPageSize.recordTake,
  });
  const [pageSize, setPageSize] = React.useState(defaultPageSize);
  const [filterData, setFilterData] = React.useState({
    isSort: false,
    isFilter: false,
    data: "",
    in_sort_number: 11,
    sort_order: DEFAULT_SORT.order,
  });

  const [gridColumns, setGridColumns] = React.useState([]);
  const [apiCallData, setAPICallData] = React.useState({});
  const [exportData, setExportData] = React.useState([]);
  const [columnsData, setColumnsData] = React.useState([]);
  const [isCheckColumn, setColumnCheck] = React.useState(false);
  const [filterField, setFilterField] = React.useState({
    logic: "and",
    filters: [],
  });
  const [sortField, setSortField] = React.useState([]);

  let columnReqData = {
    in_tenant_id: cookiesData.out_tenant_id,
    in_filter: null,
    in_filter_db_name: "procare_system",
    in_filter_table_name: "vw_job_history",
    in_result_name_mstr_key: 0,
    in_event_type_mstr_key: 0,
    in_hide_column: 0,
  };

  React.useEffect(() => {
    Action.getPeriodTypeList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (columns.length > 0) {
      setColumnsData(columns);
      setGridColumns(columns);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);

  React.useEffect(() => {
    if (columns.length === 0 && loading.isSearchLoader) {
      getJobListData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns, loading.isSearchLoader]);

  const getJobListData = async () => {
    let columnRes = await Action.getColumListXMLData(columnReqData);
    if (columnRes.length > 0) {
      let jobResultData = {
        in_acct_encrypt_key: cookiesData.out_acct_encrypt_key,
        in_period_mstr_key: periodsFields.periodsValue.id,
        in_job_type_mstr_key: jobTypeFields.jobType.id,
        in_job_mstr_keys:
          jobNameFields?.jobNameValue.filter((x) => x.text !== "Select All")
            .length > 0
            ? jobNameFields.jobNameValue
                .map((item) => {
                  return item.id;
                })
                .toString()
            : "0",
        in_org_title_keys:
          groupNameFields?.groupNameValue.filter((x) => x.text !== "Select All")
            .length > 0
            ? groupNameFields.groupNameValue
                .map((item) => {
                  return item.id;
                })
                .toString()
            : "0",
        in_filter: null,
        in_group_by: null,
        in_sort_number: 11,
        in_sort_order: "DESC",
        in_page_row_offset: 0,
        in_page_rows: defaultPageSize.recordTake,
      };
      setAPICallData(jobResultData);
      let response = await Action.getListDataAsync(jobResultData);
      if (response) {
        setLoadingStateSearch({ ...loading, isSearchLoader: false });
      }
    }
  };

  React.useEffect(() => {
    if (isCheckColumn) {
      setGridColumns(columnsData);
      setColumnCheck(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheckColumn]);

  const handleMenuItemClick = (action) => {
    switch (action) {
      case "export":
        setExportDataClick(true);
        break;
      default:
        break;
    }
  };
  const headerConfig = {
    title: "Job History",
    id: "jobHistory",
    action_menu: {
      export: { fx: handleMenuItemClick },
    },
    manage_col: {
      columnsData: columnsData,
      setColumnsData: setColumnsData,
      setColumnCheck: setColumnCheck,
    },
  };

  const onClearFilter = async () => {
    setAPICallingState(true);
    setFilterField({ logic: "and", filters: [] });
    //set in_params
    const dataVal = {
      ...apiCallData,
      in_filter: null,
      in_load_type: "grid-reload",
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
      in_page_row_offset: 0,
      in_page_rows: getPageTake(),
    };
    setFilterData({
      ...filterData,
      data: "",
      isFilter: false,
    });
    getGridData(dataVal);
  };

  const getGridData = async (dataVal, module) => {
    await Action.getListDataAsync(dataVal, module);
  };

  const handleColumnMenu = (columnMenuData, isFilter, isSort, filterObj) => {
    if (isSort) {
      setSortField(columnMenuData);
    } else {
      setFilterField(filterObj);
    }

    let index = 1;
    let filterSkip = getPageSkip();
    let filterTake = getPageTake();

    if (isFilter) {
      filterSkip = 0;
      filterTake = pageSize.recordTake;

      //reset pager when filter applies
      setPage({ skip: filterSkip, take: filterTake });
    }

    if (data.length > 0 && columnMenuData[0] !== undefined) {
      let obj = Object.keys(data[0]);
      index = obj.findIndex((x) => x === columnMenuData[0].field) + 1;
    }

    const filterString = isFilter
      ? getfilterDataString(columnMenuData, gridColumns)
      : filterData.data;

    isFilter = false;
    if (filterString !== "") {
      isFilter = true;
    }

    let sortDir =
      filterData.isSort && columnMenuData.length
        ? filterData.sort_order
        : DEFAULT_SORT.order;
    let sortNumber =
      filterData.isSort && columnMenuData.length
        ? filterData.in_sort_number
        : 11;

    isSort = !columnMenuData.length ? false : filterData.isSort;

    if (
      columnMenuData[0] !== undefined &&
      columnMenuData[0].dir !== undefined
    ) {
      isSort = true;
      sortDir = columnMenuData[0].dir.toUpperCase();
      sortNumber = index;
    }
    const dataVal = {
      ...apiCallData,
      in_load_type: "data-reload",
      in_filter: isFilter ? filterString : null,
      in_sort_number: sortNumber,
      in_sort_order: sortDir,
      in_page_row_offset: filterSkip,
      in_page_rows: filterTake,
    };

    setFilterData({
      ...filterData,
      isFilter: isFilter,
      isSort: isSort,
      data: filterString,
      in_sort_number: sortNumber,
      sort_order: sortDir,
    });

    setAPICallingState(true);

    getGridData(dataVal);
  };

  const getInFilter = () => {
    return filterData.isFilter ? filterData.data : null;
  };

  const getInSortNumber = () => {
    return filterData.isSort ? filterData.in_sort_number : 11;
  };

  const getInSortOrder = () => {
    return filterData.isSort ? filterData.sort_order : DEFAULT_SORT.order;
  };

  const getPageTake = () => {
    return pageData.take;
  };

  const getPageSkip = () => {
    return pageData.skip;
  };

  const onRefreshClick = () => {
    setAPICallingState(true);

    const dataVal = {
      ...apiCallData,
      in_filter: getInFilter(),
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
      in_page_row_offset: 0,
      in_page_rows: defaultPageSize.recordTake,
      in_load_type: "data-reload",
    };
    getGridData(dataVal);
  };

  const handlePageChange = async (event) => {
    const pageData = event.page;
    setAPICallingState(true);

    setPage({ skip: pageData.skip, take: pageData.take });

    const dataVal = {
      ...apiCallData,
      in_filter: getInFilter(),
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
      in_page_row_offset: pageData.skip,
      in_page_rows: pageData.take,
      in_load_type: "grid-reload",
    };

    await getGridData(dataVal);
  };

  const StringReadAndOnlyCell = React.useCallback((props) => {
    const { ariaColumnIndex, columnIndex, dataItem, field } = props;

    let val = dataItem[field || ""];

    if (field.indexOf("_mstr_key") !== -1) {
      val = dataItem[field.replace("_mstr_key", "_name") || ""];
    }

    const defaultRendering = (
      <td aria-colindex={ariaColumnIndex} data-grid-col-index={columnIndex}>
        <span title={val}>{val}</span>
      </td>
    );

    return defaultRendering;
  }, []);

  const useCol = (useColumns) => {
    return useColumns.map((column, idx) => {
      return column.show && column.title !== "" ? (
        <Column
          {...columnMenuProps(column)}
          cell={
            column.field === "start_time" || column.field === "end_time"
              ? dateFormateCellForJob
              : StringReadAndOnlyCell
          }
          key={idx}
          sortable={true}
          width={
            data.length > 0 ? jobHistoryGridWidth(data, column.title) : "100"
          }
          format={column.format}
          filterable={column.field === "job_type_name" ? false : true}
        />
      ) : (
        false
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  let CustomColumn = useCol(gridColumns);

  const onJobSearch = async () => {
    setData([]);
    setColumns([]);
    setColumnsData([]);
    setPage({
      skip: 0,
      take: defaultPageSize.recordTake,
    });
    setFilterField({ logic: "and", filters: [] });
    setSortField([]);
    setPageSize(defaultPageSize);
    setFilterData({
      ...filterData,
      isFilter: false,
      in_sort_number: 11,
      in_sort_order: "DESC",
      data: "",
    });
    setLoadingStateSearch({ ...loading, isSearchLoader: true });
  };

  const handleChangeDropDown = (module, id) => {
    let jobResultData = {
      in_acct_encrypt_key: cookiesData.out_acct_encrypt_key,
      in_period_mstr_key: periodsFields.periodsValue.id,
      in_job_type_mstr_key: jobTypeFields.jobType.id,
      in_job_mstr_keys: jobNameFields.jobNameData.id,
      in_org_title_keys: groupNameFields.groupNameValue.id,
      in_filter: null,
      in_group_by: null,
      in_sort_number: 11,
      in_sort_order: "DESC",
      in_page_row_offset: 0,
      in_page_rows: defaultPageSize.recordTake,
    };
    switch (module) {
      case "periodType":
        setJobTypeFields({
          ...jobTypeFields,
          isJobTypeDataLoading: true,
        });
        setGroupNameFields({
          ...groupNameFields,
          isGroupNameDataLoading: true,
        });
        setJobNameFields({
          ...jobNameFields,
          isJobNameDataLoading: true,
        });
        Action.getJobTypeList(id);
        break;
      case "jobType":
        setJobNameFields({
          ...jobNameFields,
          isJobNameDataLoading: true,
        });
        setGroupNameFields({
          ...groupNameFields,
          isGroupNameDataLoading: true,
        });
        jobResultData.in_group_by = "a.job_mstr_key";
        jobResultData.in_job_type_mstr_key = id;
        Action.getJobNameList(jobResultData);
        break;
      case "jobName":
        setGroupNameFields({
          ...groupNameFields,
          isGroupNameDataLoading: true,
        });
        jobResultData.in_group_by = "a.org_title_key";
        jobResultData.in_job_mstr_keys = id;
        Action.getGroupName(jobResultData);
        break;
      default:
        break;
    }
  };
  const getExportData = async () => {
    const dataVal = {
      ...apiCallData,
      in_page_row_offset: 0,
      in_page_rows: dataCount,
      in_filter: getInFilter(),
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
    };

    let res = await Action.exportDataAsync(dataVal);
    if (res.length > 0) {
      let JSONData = [...res];
      JSONData.forEach((item) => {
        item.start_time = formatDateforTimeZone(item.start_time);
        item.end_time = formatDateforTimeZone(item.end_time);
        item.is_processed = item.is_processed ? "true" : "false";
      });
      setExportData(JSONData);
    }
  };

  const parent = document.querySelector("#content-" + headerConfig.id);

  if (parent) {
    const exportToExcel = parent.querySelector(".export-to-excel");
    exportToExcel && exportToExcel.disableKendoMenu();
    data.length && exportToExcel && exportToExcel.enableKendoMenu();
  }

  const prepareColumnString = (colData) => {
    return executeGetColumnOrderInfo(colData, "job-history");
  };

  const columnReorderData = async (columnData) => {
    const str = prepareColumnString(columnData);
    setLoadingStateSearch({ ...loading, updateColumn: true });
    let reqData = {
      in_db_name: "procare_system",
      in_tenant_id: cookiesData.out_tenant_id,
      in_filter_logical_cond: "N",
      in_filter_label_cond: "N",
      in_column_info: str,
    };
    let res = await updateColumnReorderAsync(reqData, dispatch, history);
    if (res) {
      setColumns([]);
      let colDataList = JSON.parse(JSON.stringify(columnData));
      colDataList.forEach((item) => {
        item.hidden = false;
      });
      let colData = colDataList.sort(function (a, b) {
        return a.ariaColumnIndex - b.ariaColumnIndex;
      });
      setColumns(colData);
      setLoadingStateSearch({ ...loading, updateColumn: false });
    } else {
      setLoadingStateSearch({ ...loading, updateColumn: false });
    }
  };

  return (
    <>
      <div className="Main eventspage">
        {(loading.updateColumn || loading.isSearchLoader) && <LoadingPanel />}
        <div className="content">
          <div className="content-head">
            <HeaderPage pageProps={pageProps} headerConfig={headerConfig} />
          </div>
          <div className="content-body">
            <ul className="Filter-links jobhistory-filterlink">
              <div className={"k-form-field-wrap-popup width20em"} ref={divRef}>
                <Label>{"Period Type:"}</Label>
                <CustomDropdown
                  name={"periodTypeValue"}
                  data={periodsTypeFields.periodTypeData}
                  textField="text"
                  onOpen={(dataValue) => {
                    setPeriodTypeFields({
                      ...periodsTypeFields,
                      periodTypeData: dataValue,
                    });
                  }}
                  dataItemKey="id"
                  valueData={periodTypeList.find(
                    (c) => c.text === periodsTypeFields.periodTypeValue.text
                  )}
                  onChange={(e, name) => {
                    setPeriodTypeFields({
                      ...periodsTypeFields,
                      [name]: e,
                    });
                    Action.getPeriodsDataList(e.id);
                  }}
                  filterable={true}
                  onFilterChange={(dataValue) => {
                    setPeriodTypeFields({
                      ...periodsTypeFields,
                      periodTypeData: dataValue,
                    });
                  }}
                  autoClose={false}
                  autoBind={true}
                  isSelectALl={true}
                  dataList={periodTypeList}
                  loading={periodsTypeFields.isPeriodTypeDataLoading}
                />
              </div>
              <div
                className={"k-form-field-wrap-popup valueDropdown width20em"}
                ref={divRef}
              >
                <Label>{"Periods:"}</Label>

                <CustomDropdown
                  name={"periodsValue"}
                  data={periodsFields.periodsData}
                  textField="text"
                  onOpen={(dataValue) => {
                    setPeriodFields({
                      ...periodsFields,
                      periodsData: dataValue,
                    });
                  }}
                  dataItemKey="id"
                  valueData={periodsList.find(
                    (c) => c.text === periodsFields.periodsValue.text
                  )}
                  onChange={(e, name) => {
                    setPeriodFields({ ...periodsFields, [name]: e });
                    handleChangeDropDown("periodType", e.id);
                  }}
                  filterable={true}
                  onFilterChange={(dataValue) => {
                    setPeriodFields({
                      ...periodsFields,
                      periodsData: dataValue,
                    });
                  }}
                  autoClose={false}
                  autoBind={true}
                  isSelectALl={true}
                  dataList={periodsList}
                  loading={periodsFields.isPeriodDataLoading}
                />
              </div>

              <div
                className={"k-form-field-wrap-popup valueDropdown width20em"}
                ref={divRef}
              >
                <Label>{"Job Type:"}</Label>

                <CustomDropdown
                  name={"jobType"}
                  data={jobTypeFields.jobTypeData}
                  textField="text"
                  onOpen={(dataValue) => {
                    setJobTypeFields({
                      ...jobTypeFields,
                      jobTypeData: dataValue,
                    });
                  }}
                  dataItemKey="id"
                  valueData={jobTypeList.find(
                    (c) => c.text === jobTypeFields.jobType.text
                  )}
                  onChange={(e, name) => {
                    setJobTypeFields({ ...jobTypeFields, [name]: e });
                    handleChangeDropDown("jobType", e.id);
                  }}
                  filterable={true}
                  onFilterChange={(dataValue) => {
                    setJobTypeFields({
                      ...jobTypeFields,
                      jobTypeData: dataValue,
                    });
                  }}
                  autoClose={false}
                  autoBind={true}
                  isSelectALl={true}
                  dataList={jobTypeList}
                  loading={jobTypeFields.isJobTypeDataLoading}
                />
              </div>

              <div
                className={"k-form-field-wrap-popup valueDropdown"}
                ref={divRef}
              >
                <Label>{"Job Name:"}</Label>
                <MultiSelectDropdown
                  name={"jobNameValue"}
                  module="jobNameValue"
                  data={jobNameFields.jobNameData}
                  textField="text"
                  onOpen={(data) => {
                    setJobNameFields({
                      ...jobNameFields,
                      jobNameData: data,
                    });
                  }}
                  dataItemKey="id"
                  valueData={jobNameFields.jobNameValue}
                  onChange={(e, name) => {
                    setJobNameFields({
                      ...jobNameFields,
                      [name]: e,
                      // jobNameData: jobNameList,
                    });
                    handleChangeDropDown(
                      "jobName",
                      e[0].id === "Select All" ? "0" : e[0].id
                    );
                  }}
                  filterable={true}
                  onFilterChange={(data) => {
                    setJobNameFields({
                      ...jobNameFields,
                      jobNameData: data,
                    });
                  }}
                  autoClose={false}
                  autoBind={true}
                  isSelectALl={true}
                  dataList={jobNameList}
                  loading={jobNameFields.isJobNameDataLoading}
                />
              </div>

              <div
                className={"k-form-field-wrap-popup valueDropdown"}
                ref={divRef}
              >
                <Label>{"Group Name:"}</Label>

                <MultiSelectDropdown
                  name={"groupNameValue"}
                  module="groupNameValue"
                  data={groupNameFields.groupValueData}
                  textField="text"
                  onOpen={(data) => {
                    setGroupNameFields({
                      ...groupNameFields,
                      groupValueData: data,
                    });
                  }}
                  dataItemKey="id"
                  valueData={groupNameFields.groupNameValue}
                  onChange={(data, name) => {
                    setGroupNameFields({
                      ...groupNameFields,
                      [name]: data,
                      // groupValueData: groupNameList,
                    });
                  }}
                  filterable={true}
                  onFilterChange={(data) => {
                    setGroupNameFields({
                      ...groupNameFields,
                      groupValueData: data,
                    });
                  }}
                  autoClose={false}
                  autoBind={true}
                  isSelectALl={true}
                  dataList={groupNameList}
                  loading={groupNameFields.isGroupNameDataLoading}
                />
              </div>

              <Button
                primary={true}
                className="searchbtn"
                disabled={
                  periodsFields.isPeriodDataLoading ||
                  periodsTypeFields.isPeriodTypeDataLoading ||
                  jobTypeFields.isJobTypeDataLoading ||
                  jobNameFields.isJobNameDataLoading ||
                  groupNameFields.isGroupNameDataLoading ||
                  jobTypeList.length === 0
                }
                type={"submit"}
                onClick={(e) => {
                  onJobSearch();
                }}
              >
                <i className="fas fa-search"></i>
              </Button>
            </ul>
            <div className="dtu-file-container padtop15">
              {!loading.isSearchLoader && columns.length > 0 && (
                <DataTable
                  isAPICalling={isAPICalling}
                  initialLoading={initialLoading}
                  data={data}
                  pageData={pageData}
                  dataCount={dataCount}
                  customColumn={CustomColumn}
                  isColumnMenuSearch={isColumnMenuSearch}
                  columns={gridColumns}
                  dataItemKey={"user_key"}
                  width={"auto"}
                  defaultPageSize={defaultPageSize}
                  pageSize={pageSize}
                  isExportDataClick={exportDataClick}
                  exportData={exportData}
                  noDataFound={noDataFound}
                  fileName={"Export_job_history.xlsx"}
                  module={"job-history"}
                  setPageSize={setPageSize}
                  handlePageChange={handlePageChange}
                  refreshClick={onRefreshClick}
                  handleColumnMenu={handleColumnMenu}
                  onClearFilter={onClearFilter}
                  setExportData={setExportDataClick}
                  getExportData={getExportData}
                  reorderable={pageProps.writeAccess === 0 ? false : true}
                  columnReorderData={columnReorderData}
                  initialFilter={filterField}
                  initialSort={sortField}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(JobHistory);
