import React from "react";
import {
    GET_CALENDAR_DATA,
    ADD_CALENDAR_DATA,
    UPDATE_CALENDAR_DATA,
    DELETE_CALENDAR_DATA,
} from "../../api-setup/api-endpoints";
import { getUserCredential } from "../../lib/cookies";
import { POSTAPI, errStatusHandler } from "../../api-setup/api-helper";
import { formatDateCalendar, GridColumnDataMapper } from "../../utils/utils";
import { useHistory } from "react-router";
import { USER_GRID_DEFAULT_PAGE_SIZE } from "../../constants/grid";
import { useDispatch } from "react-redux";
import { setNewMessage } from "../../redux/actions/setting-actions";


const initialDataState = {
    skip: 0,
    take: USER_GRID_DEFAULT_PAGE_SIZE,
};
let defaultItem = {
    text: USER_GRID_DEFAULT_PAGE_SIZE,
    id: 2,
};

export default function Calendar() {
    const cookiesData = getUserCredential();
    const header = {
        "x-access-token": cookiesData.secretToken,
    };
    const history = useHistory();
    const dispatch = useDispatch();
    const [data, setData] = React.useState([]);
    const [columns, setColumns] = React.useState([]);
    const [dataCount, setDataCount] = React.useState(0);
    const [isColumnMenuSearch, setIsColumnMenuSearch] = React.useState(false);
    const [paginationData, setPaginationData] = React.useState({
        pageData: 0,
        rows: 10,
    });

    const [initialLoading, setLoadingState] = React.useState(false);
    const [pageData, setPage] = React.useState(initialDataState);
    const [page, setPageData] = React.useState(0);
    const [editData, setEditData] = React.useState("");
    const [pageSize, setPageSize] = React.useState(defaultItem);
    const [confirmationPopup, setConfirmationPopup] = React.useState({
        title: "Delete",
        desc: "Are you sure you want to delete this record?",
        isVisible: false,
        type: "",
        actionBtn: [],
        data: {},
        action: "Delete",
    });
    const [originalData, setOriginalData] = React.useState([]);
    const [isAPICalling, setAPICallingState] = React.useState(false);
    const [isRefreshLoading, setRefreshLoading] = React.useState(false);
    const [loadingState, setLoadState] = React.useState({
        isSaveData: undefined,
        isDeleteData: undefined,
    });

    const getListDataAsync = (listData) => {
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                GET_CALENDAR_DATA,
                null,
                header,
                listData,
                dispatch
            ).then((res) => {
                if (res) {
                    if (res.data.out_parameters.out_ret_code > 0) {
                        setLoadingState(true);
                        errStatusHandler(res, history, dispatch);
                    }
                    setAPICallingState(false);
                    setLoadingState(true);
                    setEditData("");
                    setConfirmationPopup({
                        ...confirmationPopup,
                        isVisible: false,
                        type: "",
                        actionBtn: [],
                        data: {},
                        action: "",
                    });
                    let userData = JSON.parse(
                        JSON.stringify(res.data.row_result)
                    );
                    const columData = GridColumnDataMapper(
                        res.data.out_parameters.out_column_xml
                    );
                    userData.map((item, idx) => {
                        item.rowId = idx;
                        item.selected = false;
                        item.period_start_date = formatDateCalendar(
                            item.period_start_date
                        );
                        item.period_end_date = formatDateCalendar(
                            item.period_end_date
                        );
                        return 0;
                    });

                   
                    setData(userData);
                    setOriginalData(userData);
                    if (listData.in_load_type === "grid-reload") {
                        setColumns(columData);
                    }
                    setDataCount(res.data.out_parameters.out_total_rows);
                    setRefreshLoading(false);
                    resolve(userData);
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    const addDataAsync = (bodyData) => {
        setRefreshLoading(true);
        setLoadState({ ...loadingState, isSaveData: false });
        POSTAPI(
            false,
            ADD_CALENDAR_DATA,
            null,
            header,
            bodyData,
            dispatch
        ).then((res) => {
            setRefreshLoading(false);
            if (res.status) {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                } else {
                    setLoadState({ ...loadingState, isSaveData: true });
                }
            }
        });
    };

    const updateCalendarDataAsync = (bodyData) => {
        setRefreshLoading(true);
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                UPDATE_CALENDAR_DATA,
                null,
                header,
                bodyData,
                dispatch
            ).then((res) => {
                setRefreshLoading(false);
                if (res && res.data.out_parameters.out_ret_code === 0) {
                    resolve(true);
                } else {
                    dispatch(
                        setNewMessage({
                            message: res?.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    resolve([]);
                    errStatusHandler(res, history, dispatch);
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    const deleteDataAsync = async (bodyData) => {
        setRefreshLoading(true);
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                DELETE_CALENDAR_DATA,
                null,
                header,
                bodyData,
                dispatch
            ).then((res) => {
                setRefreshLoading(false);
                if (res.data.out_parameters.out_ret_code > 0) {
                    let msg = res.data.out_parameters.out_error_msg;

                    setLoadState({ ...loadingState, isDeleteData: false });
                    setConfirmationPopup({
                        ...confirmationPopup,
                        isVisible: false,
                        type: "",
                        actionBtn: [],
                        data: {},
                        action: "",
                    });
                    dispatch(
                        setNewMessage({
                            message: msg,
                            type: "Error",
                            show: true,
                        })
                    );
                } else {
                    setConfirmationPopup({
                        ...confirmationPopup,
                        isVisible: false,
                        type: "",
                        actionBtn: [],
                        data: {},
                        action: "",
                    });
                    setLoadState({ ...loadingState, isDeleteData: true });
                    setTimeout(function () {
                        resolve("success");
                    });
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    return [
        {
            data,
            columns,
            dataCount,
            setData,
            setColumns,
            isColumnMenuSearch,
            pageData,
            editData,
            page,
            originalData,
            pageSize,
            isAPICalling,
            setEditData,
            confirmationPopup,
            setConfirmationPopup,
            initialLoading,
            setPage,
            setPageData,
            setPageSize,
            setPaginationData,
            setAPICallingState,
            paginationData,
            loadingState,
            setOriginalData,
            isRefreshLoading,
            setLoadState,
            setIsColumnMenuSearch
        },
        {
            getListDataAsync,
            addDataAsync,
            updateCalendarDataAsync,
            deleteDataAsync,
        },
    ];
}
