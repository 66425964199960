import React from "react";
import DataTable from "../../components/dataTable";
import { GridColumn as Column } from "@progress/kendo-react-grid";
import { POSTAPI } from "../../api-setup/api-helper";
import { getUserCredential, getTenantCookieData } from "../../lib/cookies";
import {
    GET_BATCh_DTU_LOGS,
    GET_DTU_LOGS,
} from "../../api-setup/api-endpoints";
import {
    GridColumnDataMapper, convertToSnakeCase, formatDate,
} from "../../utils/utils";
import { DEFAULT_SORT } from "../../constants/grid";
import { columnMenuProps } from "../../components/customColumnMenu";
import DtuErrorLogs from "./dtuErrorLogs";
import { LoadingPanel } from "../../components/loadingPanel";
import { setNewMessage } from "../../redux/actions/setting-actions";
import { useDispatch } from "react-redux";
import { getfilterDataString } from "../../utils/utils";
import { Window, WindowActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { PROVIDER_POSITION_DATA } from "src/constants/constants";
import { Label } from "@progress/kendo-react-labels";
import ErrorDetailLogs from "./errorDetailLogs";
import { HeaderPage } from "../base/headerPage";
import useAPI from "../user/dtuService"
import CustomDropdown from "src/components/customDropdown";


const ModalTitle = () => {
    return (
        <span>
            DTU Logs
        </span>
    );
};

const DtuLogs = (restProps) => {
    const dispatch = useDispatch();
    const defaultPageSize = getTenantCookieData("record_per_page");
    const [stageChange, setStateChange] = React.useState('DEFAULT')
    const [visible, setVisible] = React.useState(restProps.visible);
    const [isShow, setShow] = React.useState({
        dtuErrorLogsModal: false,
    });
    const [selectedBatchKey, setSelectedBatchKey] = React.useState("");
    const [etlTableName, setEtlTableName] = React.useState(null);
    const [etlTableMasterKey, setEtlTableMasterKey] = React.useState("");
    const [data, setData] = React.useState([]);
    const cookiesData = getUserCredential();
    const [columns, setColumns] = React.useState([]);
    const [dataCount, setDataCount] = React.useState(0);
    const [initialLoading, setLoadingState] = React.useState(false);
    const [isAPICalling, setAPICallingState] = React.useState(false);
    const [exportDataClick, setExportDataClick] = React.useState(false);
    const [noDataFound, setNoDataFound] = React.useState(false);
    const [pageData, setPage] = React.useState({
        skip: 0,
        take: defaultPageSize.recordTake,
    });
    const [pageSize, setPageSize] = React.useState(defaultPageSize);
    const [isErrorDetail, setIsErrorDetail] = React.useState(false);
    const [errorObj, setErrorObj] = React.useState("");
    const [exportData, setExportData] = React.useState([]);
    const [modal, setModal] = React.useState(true)
    const apiData = {
        in_filter: 'AND (a.etl_table_mstr_key = "1")',
        in_etl_table_mstr_key: 1,
        in_etl_event_type_mstr_key: null,
        in_sort_number: DEFAULT_SORT.number,
        in_sort_order: DEFAULT_SORT.order,
        in_page_row_offset: 0,
        in_page_rows: defaultPageSize.recordTake,
    };
    const apiCalldata = {
        in_filter: null,
        in_sort_number: DEFAULT_SORT.number,
        in_sort_order: DEFAULT_SORT.order,
        in_page_row_offset: 0,
        in_page_rows: defaultPageSize.recordTake,
    };
    //state to set and get filter, sort number, sort order
    const [filterData, setFilterData] = React.useState({
        isSort: false,
        isFilter: false,
        data: "",
        in_sort_number: DEFAULT_SORT.number,
        sort_order: DEFAULT_SORT.order,
    });
    const [providerFields, setProviderPosition] = React.useState({
        providerFields: PROVIDER_POSITION_DATA[0],
    });
    const [loadState, setLoadState] = React.useState({
        isResetLoading: false,
        isEventDtuLoaded: true
    })
    const [resetSort, setResetSort] = React.useState(false)


    const header = {
        "x-access-token": cookiesData.secretToken,
    };

    /** Use API Hooks */
    const [list, Action] = useAPI()

    const {
        eventsTypeFields,
        eventTypeList,
        setEventTypeFields,
    } = list

    React.useEffect(() => {
        if (restProps.module === "event") {
            Action.getEventTypeListDataAsync();

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getEventInParams = (val) => {
        let obj = {}
        let groupName = val.group_name !== null ? convertToSnakeCase(`Event ${val.group_name}`) : 'event_master'
        obj.in_etl_event_type_mstr_key = val.id
        obj.in_filter = `AND a.etl_table_name = '${groupName}'`;
        return obj
    }

    // const getEventBatchInParams =(val) =>{
    //     let obj = {}
    //     obj.in_etl_event_type_mstr_key = val.id
    //     obj.in_filter = `AND a.etl_event_type_mstr_key  = '${val.id}'`;
    //     return obj
    // }

    React.useEffect(() => {
        if (restProps.module === "event" && eventsTypeFields.eventTypeData.length > 0 && loadState.isEventDtuLoaded) {
            let reqData = {
                ...apiCalldata,
                ...getEventInParams(eventsTypeFields.eventTypeValue)
            }
            getDtuLogsAsync(reqData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventsTypeFields])


    React.useEffect(() => {
        if (restProps.module !== "event") {
            getDtuLogsAsync(apiCalldata);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getInFilter = () => {
        let etlFilter = ''
        if (restProps.module === 'event') {
            etlFilter = `AND (a.etl_event_type_mstr_key = '${eventsTypeFields.eventTypeValue.id}')`;
        } else {
            etlFilter = `AND a.etl_table_mstr_key = '${etlTableMasterKey}'`;
        }
        return filterData.isFilter
            ? etlFilter +
            filterData.data
            : etlFilter;
    };

    const getInSortNumber = () => {
        return filterData.isSort
            ? filterData.in_sort_number
            : DEFAULT_SORT.number;
    };

    const getInSortOrder = () => {
        return filterData.isSort ? filterData.sort_order : DEFAULT_SORT.order;
    };

    const getPageTake = () => {
        return pageData.take;
    };

    const getPageSkip = () => {
        return pageData.skip;
    };


    const getDtuLogsAsync = (data, value) => {
        var inFilter = data.in_filter;
        if (restProps.module !== "event") {
            data.in_sort_number = null;
            data.in_sort_order = null;
            data.in_filter = `AND a.etl_table_name = '${restProps.module}'`;
        }
        if (restProps.module === 'provider_master') {
            let pDataValue = value ? value.table : providerFields.providerFields.table
            data.in_filter = `AND a.etl_table_name = '${pDataValue}'`;
        }
        return new Promise(async function (resolve, reject) {
            POSTAPI(false, GET_DTU_LOGS, null, header, data, dispatch).then(
                (res) => {
                    setAPICallingState(true);
                    if (res.data.out_parameters.out_ret_code === 0) {
                        setLoadState({
                            ...loadState, isResetLoading: false,
                            isEventDtuLoaded: false
                        })
                        setAPICallingState(false);
                        if (res.data.row_result.length > 0) {
                            setEtlTableMasterKey(
                                res.data.row_result[0].etl_table_mstr_key
                            );
                            if (
                                inFilter !==
                                `AND a.etl_table_name = '${restProps.module}'` &&
                                inFilter !== null
                            ) {
                                apiData.in_filter = `AND a.etl_table_mstr_key = '${res.data.row_result[0].etl_table_mstr_key}'${inFilter}`;
                            } else {
                                apiData.in_filter = `AND (a.etl_table_mstr_key = "${res.data.row_result[0].etl_table_mstr_key}")`;
                            }
                            apiData.in_etl_table_mstr_key =
                                res.data.row_result[0].etl_table_mstr_key;
                            if (restProps.module === "event") {
                                apiData.in_etl_event_type_mstr_key = data.in_etl_event_type_mstr_key
                                apiData.in_filter = `AND (a.etl_event_type_mstr_key = '${data.in_etl_event_type_mstr_key}')`;
                            } else {
                                apiData.in_etl_event_type_mstr_key = null;
                            }
                            let response = getDtuBatchLogsAsync(apiData);
                            setEtlTableName(res.data.row_result[0].etl_table_name);
                            resolve(response)
                        } else {
                            setAPICallingState(false);
                            dispatch(
                                setNewMessage({
                                    message: res.data.out_parameters.out_error_msg,
                                    type: "Error",
                                    show: true,
                                })
                            );
                        }
                    } else {
                        setLoadState({
                            ...loadState, isResetLoading: false
                        })
                        dispatch(
                            setNewMessage({
                                message: res.data.out_parameters.out_error_msg,
                                type: "Error",
                                show: true,
                            })
                        );
                    }
                }
            );
        })
    };

    const getDtuBatchLogsAsync = (data, extraColumn) => {
        setAPICallingState(true);
        setLoadingState(true);
        return new Promise(function (resolve, reject) {
            POSTAPI(false, GET_BATCh_DTU_LOGS, null, header, data, dispatch).then(
                (res) => {
                    setLoadingState(true);
                    setAPICallingState(false);
                    setNoDataFound(false);
                    const columnData = GridColumnDataMapper(
                        res.data.out_parameters.out_columns_xml
                    );
                    columnData.map((item) => {
                        item.width = 150;
                        return 0;
                    });
                    setColumns(columnData);
                    res.data.row_result.forEach((item) => {
                        [
                            "action_date",
                        ].forEach((field) => {
                            if (item[field]) {
                                item[field] = new Date(item[field]);
                            }
                        });
                    });
                    setData(res.data.row_result)
                    setDataCount(res.data.out_parameters.out_total_rows);
                    resolve(true)
                }
            );
        }).then((res) => {
            return res;
        })
    };

    const LinkCell = React.useCallback((props) => {
        const { dataItem } = props;

        const onLinkClick = () => {
            setShow({ ...isShow, dtuErrorLogsModal: true });
            setSelectedBatchKey(dataItem.etl_batch_mstr_key);
        };

        return (
            <td>
                {dataItem.error_rec_count === 0 ? (
                    dataItem.error_rec_count
                ) : (
                    <span
                        className={"link-class"}
                        onClick={() => {
                            onLinkClick();
                        }}
                    >
                        {dataItem.error_rec_count}
                    </span>
                )}
            </td>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const ErrorLinkCell = React.useCallback((props) => {
        const { dataItem } = props;

        const onLinkClick = () => {
            setIsErrorDetail(true)
            setErrorObj(dataItem)
        };

        return (
            <td>
                {dataItem.error_code === 0 ? (
                    dataItem.error_code
                ) : (
                    <span
                        className={"link-class"}
                        onClick={() => {
                            onLinkClick();
                        }}
                    >
                        {dataItem.error_code}
                    </span>
                )}
            </td>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const StringReadAndOnlyCell = React.useCallback((props) => {
        const { ariaColumnIndex, columnIndex, dataItem, field } = props;

        let val = dataItem[field || ""];

        const defaultRendering = (
            <td aria-colindex={ariaColumnIndex} data-grid-col-index={columnIndex}>
                <span title={val}>{val}</span>
            </td>
        );

        return defaultRendering;
    }, []);

    const cellType = (module) => {
        switch (module) {
            case "error_rec_count":
                return LinkCell;
            case 'error_code':
                return ErrorLinkCell
            default:
                return StringReadAndOnlyCell;
        }
    };


    const DtuColumn = columns.map((column, idx) => {
        return column.show && column.title !== "" ? (
            <Column
                {...columnMenuProps(column)}
                key={idx}
                field={column.field}
                title={<span title={column.title}>{column.title}</span>}
                cell={column.type === 'date' ? '' : cellType(column.field)}
                format={column.format}
                width={'150px'}
            />
        ) : (
            false
        );
    });

    const handleColumnMenu = async (columnMenuData, isFilter, isSort) => {
        let index = 1;
        let filterSkip = getPageSkip();
        let filterTake = getPageTake();

        if (isFilter) {
            filterSkip = 0;
            filterTake = pageSize.recordTake;

            //reset pager when filter applies
            setPage({ skip: filterSkip, take: filterTake });
        }

        if (data.length > 0 && columnMenuData[0] !== undefined) {
            let obj = Object.keys(data[0]);
            index = obj.findIndex((x) => x === columnMenuData[0].field) + 1;
        }

        const filterString = isFilter
            ? getfilterDataString(columnMenuData, columns)
            : filterData.data;

        isFilter = false;
        if (filterString !== "") {
            isFilter = true;
        }

        let sortDir =
            filterData.isSort && columnMenuData.length
                ? filterData.sort_order
                : DEFAULT_SORT.order;
        let sortNumber =
            filterData.isSort && columnMenuData.length
                ? filterData.in_sort_number
                : DEFAULT_SORT.number;

        isSort = !columnMenuData.length ? false : filterData.isSort;

        if (
            columnMenuData[0] !== undefined &&
            columnMenuData[0].dir !== undefined
        ) {
            isSort = true;
            sortDir = columnMenuData[0].dir.toUpperCase();
            sortNumber = index;
        }
        let filterVal = ''
        if (restProps.module === 'event') {
            filterVal = `AND (a.etl_event_type_mstr_key = "${eventsTypeFields.eventTypeValue.id}")`
        } else {
            filterVal = `AND (a.etl_table_mstr_key = "${etlTableMasterKey}")`
        }

        const dataVal = {
            ...apiData,
            in_filter: isFilter
                ? filterVal +
                filterString
                : filterVal,
            in_sort_number: sortNumber,
            in_sort_order: sortDir,
            in_page_row_offset: filterSkip,
            in_page_rows: filterTake,
            in_etl_table_mstr_key: etlTableMasterKey,
            in_etl_event_type_mstr_key: eventsTypeFields.eventTypeValue.id
        };

        setFilterData({
            ...filterData,
            isFilter: isFilter,
            isSort: isSort,
            data: filterString,
            in_sort_number: sortNumber,
            sort_order: sortDir,
        });

        setAPICallingState(true);

        getDtuBatchLogsAsync(dataVal);
    };

    const itemChange = (event) => {
        const field = event.field || "";
        const newData = data.map((item) =>
            item.user_key === event.dataItem.user_key
                ? { ...item, [field]: event.value }
                : item
        );
        setData(newData);
    };

    const handlePageChange = async (event) => {
        const pageData = event.page;
        setAPICallingState(true);
        setPage({ skip: pageData.skip, take: pageData.take });

        const dataVal = {
            ...apiData,
            in_filter: getInFilter(),
            in_sort_number: getInSortNumber(),
            in_sort_order: getInSortOrder(),
            in_page_row_offset: pageData.skip,
            in_page_rows: pageData.take,
            in_etl_table_mstr_key: etlTableMasterKey,
            in_etl_event_type_mstr_key: eventsTypeFields.eventTypeValue.id
        };

        getDtuBatchLogsAsync(dataVal);
    };

    const onRefreshClick = async () => {
        setAPICallingState(true);
        const dataVal = {
            ...apiData,
            in_filter: getInFilter(),
            in_sort_number: getInSortNumber(),
            in_sort_order: getInSortOrder(),
            in_page_row_offset: getPageSkip(),
            in_page_rows: getPageTake(),
            in_etl_table_mstr_key: etlTableMasterKey,
            in_etl_event_type_mstr_key: eventsTypeFields.eventTypeValue.id
        };

        getDtuBatchLogsAsync(dataVal);
    };
    const onClearFilter = async () => {
        setAPICallingState(true);
        let etlFilter = ''
        if (restProps.module === 'event') {
            etlFilter = `AND (a.etl_event_type_mstr_key = '${eventsTypeFields.eventTypeValue.id}')`;
        } else {
            etlFilter = `AND a.etl_table_mstr_key = '${etlTableMasterKey}'`;
        }
        //set in_params
        const dataVal = {
            ...apiData,
            in_filter: etlFilter,
            in_sort_number: getInSortNumber(),
            in_sort_order: getInSortOrder(),
            in_page_row_offset: getPageSkip(),
            in_page_rows: getPageTake(),
            in_etl_table_mstr_key: etlTableMasterKey,
            in_etl_event_type_mstr_key: eventsTypeFields.eventTypeValue.id
        };

        //set filter to false
        setFilterData({
            ...filterData,
            data: "",
            isFilter: false,
        });

        getDtuBatchLogsAsync(dataVal);
    };

    const onClosePopup = () => {
        setVisible(!visible);
        restProps.setShow({ ...restProps.visible, dtuLogs: false });
    };

    const handleMenuItemClick = (module) => {
        switch (module) {
            case "export":
                setExportDataClick(true);
                break;
            default:
                break;
        }
    };
    const headerConfig = {
        id: "dtu_batch_logs",
        action_menu: {
            export: { fx: handleMenuItemClick },
        },
    };

    const parent = document.querySelector("#content-" + headerConfig.id);
    if (parent) {
        const exportToExcel = parent.querySelector(".export-to-excel");
        exportToExcel && exportToExcel.disableKendoMenu();
        data.length && exportToExcel && exportToExcel.enableKendoMenu();
    }

    const resetState = () => {
        setColumns([])
        setData([])
        setFilterData({
            isSort: false,
            isFilter: false,
            data: "",
            in_sort_number: DEFAULT_SORT.number,
            sort_order: DEFAULT_SORT.order,
        })
        setPage({
            skip: 0,
            take: defaultPageSize.recordTake,
        });
        setLoadState({
            ...loadState, isResetLoading: true
        })

    }


    const handleProviderPosition = (e) => {
        resetState()
        setProviderPosition({
            ...providerFields,
            providerFields: e.value,
        });
        getDtuLogsAsync(apiCalldata, e.value)

    };

    const getExportData = async () => {
        const dataVal = {
            ...apiData,
            in_filter: filterData.isFilter
                ? getInFilter()
                : `AND (a.etl_table_mstr_key = "${etlTableMasterKey}")`,
            in_sort_number: getInSortNumber(),
            in_sort_order: getInSortOrder(),
            in_page_row_offset: getPageSkip(),
            in_page_rows: dataCount,
            in_etl_table_mstr_key: etlTableMasterKey,
        };

        if (restProps.module === 'event') {
            dataVal.in_etl_event_type_mstr_key = eventsTypeFields.eventTypeValue.id
            dataVal.in_filter = filterData.isFilter
                ? getInFilter() : `AND (a.etl_event_type_mstr_key = "${eventsTypeFields.eventTypeValue.id}")`
        }
        POSTAPI(false, GET_BATCh_DTU_LOGS, null, header, dataVal, dispatch).then(
            (res) => {
                const columnData = GridColumnDataMapper(
                    res.data.out_parameters.out_columns_xml
                );
                columnData.map((item) => {
                    item.width = 150;
                    return 0;
                });
                setColumns(columnData);
                if (res.data.out_parameters.out_ret_code === 0) {
                    if (res.data.row_result.length > 0) {
                        res.data.row_result.forEach((item) => {
                            [
                                "action_date",
                            ].forEach((field) => {
                                if (item[field]) {
                                    item[field] = formatDate(item[field]);
                                }
                            });
                        });
                        setExportData(res.data.row_result)
                    }
                }
            }
        );
    };

    const handleEventChange = async (e, name) => {
        setResetSort(false)
        resetState()
        setEventTypeFields({
            ...eventsTypeFields,
            [name]: e,
        });
        setLoadState({
            ...loadState, isResetLoading: false
        })
        let groupName = e.group_name !== null ? convertToSnakeCase(`Event ${e.group_name}`) : 'event_master'
        apiCalldata.in_etl_event_type_mstr_key = e.id
        apiCalldata.in_filter = `AND a.etl_table_name = '${groupName}'`;
        apiData.in_filter = `AND a.etl_event_type_mstr_key = '${e.id}'`;
        let res = await getDtuLogsAsync(apiCalldata);
        if (res) {
            setResetSort(true)
        }
        setResetSort(false)
    }



    const handleStageChange = (e) => {
        setStateChange(e.state)
        if (e.state === 'MINIMIZED') {
            setModal(false)
        } else if (e.state === 'DEFAULT') {
            setModal(true)
        }
    };


    return (
        <>
            {initialLoading ? (
                <>
                    <Window
                        title={<ModalTitle />}
                        onClose={onClosePopup}
                        width={window.innerWidth * 0.8}
                        height={window.innerHeight * 0.8666}
                        resizable={false}
                        stage={stageChange}
                        onStageChange={handleStageChange}
                        modal={modal}
                    >
                        <div className="dtu-file-container-logs">
                            <div className="content dtutitlebar">
                                {restProps.module === "provider_master" && (
                                    <div className={"k-form-field-wrap-popup twocolumlabel"}>
                                        <Label className="font11">{"Provider/Position:"}</Label>
                                        <DropDownList
                                            data={PROVIDER_POSITION_DATA}
                                            textField="text"
                                            dataItemKey="id"
                                            value={providerFields.providerFields}
                                            onChange={handleProviderPosition}
                                        />
                                    </div>
                                )}
                                <div className="Quick-links eventtypequicklinks-dtu-Logs">
                                    {restProps.module === "event" && (
                                        <div className={"k-form-field-wrap-popup twocolumlabel"}>
                                            <Label>{"Event Type:"}</Label>
                                            <CustomDropdown
                                                name={"eventTypeValue"}
                                                data={eventsTypeFields.eventTypeData}
                                                textField="text"
                                                onOpen={(dataValue) => {
                                                    setEventTypeFields({
                                                        ...eventsTypeFields,
                                                        eventTypeData: dataValue,
                                                    });
                                                }}
                                                dataItemKey="id"
                                                valueData={eventTypeList.find(
                                                    (c) => c.text === eventsTypeFields.eventTypeValue.text
                                                )}
                                                onChange={(e, name) => {
                                                    handleEventChange(e, name)
                                                }}
                                                onFilterChange={(dataValue) => {
                                                    setEventTypeFields({
                                                        ...eventsTypeFields,
                                                        eventTypeData: dataValue,
                                                    });
                                                }}
                                                autoClose={false}
                                                autoBind={true}
                                                isSelectALl={true}
                                                dataList={eventTypeList}
                                                loading={eventsTypeFields.isEventTypeDataLoading}
                                            />
                                        </div>
                                    )}
                                    <HeaderPage
                                        pageProps={restProps.pageProps}
                                        headerConfig={headerConfig}
                                    />
                                </div>
                            </div>
                            {!loadState.isResetLoading &&
                                <DataTable
                                    isAPICalling={isAPICalling}
                                    initialLoading={initialLoading}
                                    data={data}
                                    handleColumnMenu={handleColumnMenu}
                                    itemChange={itemChange}
                                    pageData={pageData}
                                    dataCount={dataCount}
                                    customColumn={DtuColumn}
                                    columns={columns}
                                    width={"auto"}
                                    className={"DataTable-dtuLog"}
                                    defaultPageSize={defaultPageSize}
                                    pageSize={pageSize}
                                    setPageSize={setPageSize}
                                    handlePageChange={handlePageChange}
                                    refreshClick={onRefreshClick}
                                    noDataFound={noDataFound}
                                    onClearFilter={onClearFilter}
                                    setExportData={setExportDataClick}
                                    isExportDataClick={exportDataClick}
                                    fileName={"Export_DTU_Logs"}
                                    getExportData={getExportData}
                                    exportData={exportData}
                                    reorderable={false}
                                    resetSort={resetSort}
                                />}
                        </div>
                        <WindowActionsBar layout={"end"}>
                            <Button
                                className="cancelbtn"
                                onClick={(e) => {
                                    onClosePopup();
                                }}
                                autoFocus={true}
                            >
                                {"Cancel"}
                            </Button>
                        </WindowActionsBar>
                    </Window>
                </>
            ) : initialLoading && data.length === 0 ? (
                false
            ) : (
                <LoadingPanel />
            )
            }

            {isShow.dtuErrorLogsModal && (
                <DtuErrorLogs
                    visible={isShow.dtuErrorLogsModal}
                    setShow={setShow}
                    selectedBatchKey={
                        selectedBatchKey || restProps.filter
                    }
                    etlTableName={etlTableName}
                    pageProps={restProps.pageProps}
                />
            )}
            {
                isErrorDetail && (
                    <ErrorDetailLogs
                        visible={isErrorDetail}
                        setIsErrorDetail={setIsErrorDetail}
                        errorObj={errorObj}
                        gridHeight={150}
                        modalHeight={314}
                    ></ErrorDetailLogs>
                )
            }
        </>
    );
};
export default React.memo(DtuLogs);
