import React from "react";
const Footer = () => {
    React.useEffect(() => {
        const envType = process.env.REACT_APP_ENV_TYPE;
    
        if (envType === "dev") {
          const script = document.createElement("script");
          script.src = "//code.tidio.co/cx28evyzdsndhgq84fregsuglpjlzcdv.js";
          script.async = true;
          script.id = "tidio-script";
    
          document.head.appendChild(script);
    
          // Cleanup function to remove the script if the component unmounts
          return () => {
            const existingScript = document.getElementById("tidio-script");
            if (existingScript) {
              document.head.removeChild(existingScript);
            }
          };
        }
      }, [])
    
    
    return (
        <>
           <div>
                <ul>
                    <li><a target="_" href="https://procareportal.com/privacy-policy/">Privacy Policy</a></li>
                    <li><a target="_" href="https://procareportal.com/terms-conditions/">Terms & Conditions</a></li>
                    <li><a href="/#">{localStorage.getItem("build-vesrion")}</a></li>
                </ul>
            </div>
        </>
    )
}

export default React.memo(Footer)