import React from "react";
import { MensurmentsData } from "./dataRowsGrid";

const MensurmentsDataRows = (restProps) => {
  //disable in-cell update and cancel button
  return (
    <>
      <div className="dtu-file-container">
        <MensurmentsData
          pageProps={restProps.pageProps}
          compTableMasterKey={restProps.compTableMasterKey}
          selectedTab={restProps.selected}
          setIsMeasurmentsUpdateClick={restProps.setIsMeasurmentsUpdateClick}
          setisMeasurmentsCancelClick={restProps.setisMeasurmentsCancelClick}
          setScreenLock={restProps.setScreenLock}
          screenLock={restProps.screenLock}
          view={restProps.view}
          mensurmentsColumnsData={restProps.mensurmentsColumnsData}
          setMensurmentsColumnsData={restProps.setMensurmentsColumnsData}
          isMensurmentsCheckColumn={restProps.isMensurmentsCheckColumn}
          setMensurmentsColumnCheck={restProps.setMensurmentsColumnCheck}
          cancelKpiMensurmentsElementAwaitStatus={
            restProps.cancelKpiMensurmentsElementAwaitStatus
          }
          setCancelKpiMensurmentsElementAwaitStatus={
            restProps.setCancelKpiMensurmentsElementAwaitStatus
          }
          isCreditMensurmentsEdit={restProps.isCreditMensurmentsEdit}
          setisMensurmentsEdit={restProps.setisMensurmentsEdit}
          isMeasurmentsUpdateClick={restProps.isMeasurmentsUpdateClick}
          isMeasurmentsCancelClick={restProps.isMeasurmentsCancelClick}
          isRefreshBtnClick={restProps.isRefreshBtnClick}
          setRefreshBtnClick={restProps.setRefreshBtnClick}
          selectedRowId={restProps.selectedRowId}
        />
      </div>
    </>
  );
};

export default React.memo(MensurmentsDataRows);
