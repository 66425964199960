import { Sortable } from "@progress/kendo-react-sortable";
import { forwardRef, memo, useEffect, useImperativeHandle } from "react";
import { LoadingPanel } from "src/components/loadingPanel";
import { widgetLayoutFormation } from "src/lib/widget.util";
import DashboardPodHeader from "../dashboard-pod-header";
import { DashboardWidgetWrapper } from "../dashboard-widget-wrapper";
import { DashboardChartsHook } from "./index.hook";
import { getDashboardPod, setDashboardPod } from "src/store/dashboard.store";

const DashboardCharts = forwardRef((props, ref) => {
    const {
        dashboardPodList,
        handleReposition,
        updatePodOrder,
        tilesLayout,
        refreshDashboardPodDetail,
    } = DashboardChartsHook();

    const dashboardPodDetail = dashboardPodList?.data;
    const dashboardPodDetailIsLoading = dashboardPodList?.isLoading;
    const currentDashboardPod = getDashboardPod();

    const itemConfig = (podDetail, index) => ({
        header: (
            <DashboardPodHeader
                resultScreenReadAccess={props?.resultScreenReadAccess || 0}
                writeAccess={props?.writeAccess}
                podDetail={podDetail}
                refreshDashboardPodDetail={refreshDashboardPodDetail}
            />
        ),
        body: <DashboardWidgetWrapper podDetail={podDetail} key={index} />,
        id: podDetail.dashboard_pod_key,
    });

    useImperativeHandle(ref, () => ({
        refreshDashboardPodDetail,
    }));

    useEffect(() => {
        const widgetConfig = widgetLayoutFormation({
            podDetailList: dashboardPodDetail,
            itemConfig,
        });
        setDashboardPod({
            ...currentDashboardPod,
            pod_count: dashboardPodDetail?.length,
        });
        tilesLayout.setTilesLayout(widgetConfig?.items || []);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dashboardPodDetail]);

    const SortableItemUI = (props) => {
        const { style, attributes, dataItem, forwardRef } = props;
        return (
            <div
                ref={forwardRef}
                {...attributes}
                style={{
                    ...style,
                }}
                className={`chart-grid-item pod-key_${dataItem.id}`}
            >
                {dataItem.header}
                {dataItem.body}
            </div>
        );
    };

    return dashboardPodDetailIsLoading ? (
        <LoadingPanel />
    ) : (
        <Sortable
            idField={"id"}
            data={tilesLayout?.tilesLayout || []}
            className="chart-grid"
            itemUI={SortableItemUI}
            onDragOver={handleReposition}
            onDragEnd={updatePodOrder}
        />
    );
});

export default memo(DashboardCharts);
