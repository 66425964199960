// eslint-disable-next-line no-useless-concat
import { GridColumn as Column } from "@progress/kendo-react-grid";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import * as React from "react";
import { useDispatch } from "react-redux";
import { columnMenuProps } from "../../../components/customColumnMenu";
import DataTable from "../../../components/dataTable";
import { CellRender, RowRender } from "../../../components/renderers";
import { removeMessage } from "../../../redux/actions/setting-actions";
import {
  changeCancelBtnState,
  changeUpdateBtnState,
  getfilterDataString,
} from "../../../utils/utils";
import useApi from "./service";

import { DEFAULT_SORT } from "../../../constants/grid";
import { getTenantCookieData, getUserCredential } from "../../../lib/cookies";

const editField = "inEdit";
const DATA_ITEM_KEY = "kpi_mstr_key";

const loadingPanel = (
  <div className="k-loading-mask">
    <span className="k-loading-text">Loading</span>
    <div className="k-loading-image"></div>
    <div className="k-loading-color"></div>
  </div>
);

export const KpiTableData = (restProps) => {
  const dispatch = useDispatch();
  const [list, Action] = useApi();
  const defaultPageSize = getTenantCookieData("record_per_page");

  const pageId = "plan_kpi_assign";

  const {
    data,
    setData,
    columns,
    dataCount,
    isAPICalling,
    setAPICallingState,
    isRefreshLoading,
  } = list;

  const [isHeaderChecked, setIsHeaderChecked] = React.useState(false);
  const [pageData, setPage] = React.useState({
    skip: 0,
    take: defaultPageSize.recordTake,
  });

  const [pageSize, setPageSize] = React.useState(defaultPageSize);
  // const [checkboxCheck, setCheckboxFlag] = React.useState(false);
  const [uniqueueKey, setUniqueueKey] = React.useState("0_0");
  const [gridColumns, setGridColumns] = React.useState([]);
  const [resetSort, setResetSort] = React.useState(true);
  let selectedKPIRowId = "";

  const cookiesData = getUserCredential();

  //state to set and get filter, sort number, sort order
  const [filterData, setFilterData] = React.useState({
    isSort: false,
    isFilter: false,
    data: "",
    in_sort_number: DEFAULT_SORT.number,
    sort_order: DEFAULT_SORT.order,
  });

  const [errorObj, setErrorObj] = React.useState({});
  const [checkboxFlag, setCheckboxFlag] = React.useState(false);
  const apiCallData = {
    in_tenant_id: cookiesData.out_tenant_id,
    in_user_key: cookiesData.out_user_id,
    in_filter: null,
    in_sort_number: DEFAULT_SORT.number,
    in_sort_order: DEFAULT_SORT.order,
    in_page_row_offset: 0,
    in_page_rows: pageSize.recordTake,
    in_comp_plan_mstr_key: restProps.compTableMasterKey.toString(),
    in_date_version: restProps.effVersionDataItem.dateVersion,
    in_load_type: "data-reload",
  };

  React.useEffect(() => {
    if (restProps.isReload.kpiReload) {
      restProps.setReload({
        ...restProps.isReload,
        kpiReload: false,
      });
      const dataaVal = {
        ...apiCallData,
        in_load_type: "grid-reload",
        in_page_row_offset: 0,
        in_page_rows: defaultPageSize.recordTake,
      };

      setAPICallingState(true);
      getGridData(dataaVal);
      setResetSort(true);
      setFilterData({
        isSort: false,
        isFilter: false,
        data: "",
        in_sort_number: DEFAULT_SORT.number,
        sort_order: DEFAULT_SORT.order,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restProps.isReload]);

  React.useEffect(() => {
    setkHeaderCheckbox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  React.useEffect(() => {
    if (restProps.selectedRowId !== selectedKPIRowId) {
      setUniqueueKey("0_0");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restProps.selectedRowId]);

  React.useEffect(() => {
    if (restProps.selectedTab == 0) {
      setUniqueueKey(
        restProps.compTableMasterKey +
          "_" +
          restProps.effVersionDataItem.periodMstrKey
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    restProps.effVersionDataItem.uniqueKey,
    restProps.selectedTab,
    restProps.dateVersion,

    restProps.selectedRowId,
  ]);

  React.useEffect(() => {
    if (uniqueueKey !== "0_0") {
      setPageSize(defaultPageSize);
      setPage({
        skip: 0,
        take: defaultPageSize.recordTake,
      });
      setResetSort(true);
      setFilterData({
        isSort: false,
        isFilter: false,
        data: "",
        in_sort_number: DEFAULT_SORT.number,
        sort_order: DEFAULT_SORT.order,
      });

      const dataaVal = {
        ...apiCallData,
        in_load_type: "grid-reload",
        in_page_row_offset: 0,
        in_page_rows: defaultPageSize.recordTake,
      };

      setAPICallingState(true);
      getGridData(dataaVal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uniqueueKey, restProps.dateVersion]);

  React.useEffect(() => {
    if (restProps.cancelKpiAwaitStatus === false) {
      setTimeout(() => {
        restProps.setCancelKpiAwaitStatus(undefined);
      }, 100);
      if (restProps.isKpiEdit === true) {
        cancelAssignment();
      } else {
        restProps.isCancelClick &&
          restProps.setCancelProviderAwaitStatus(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restProps.cancelKpiAwaitStatus]);

  React.useEffect(() => {
    if (restProps.isKpiUpdateClick === true) {
      restProps.setisKpiUpdateClick(false);
      updateAssignment();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restProps.isKpiUpdateClick]);

  React.useEffect(() => {
    if (columns.length > 0) {
      restProps.setKpiColumnsData(columns);
      setGridColumns(columns);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);

  const getGridData = async (inParams) => {
    if (restProps.screenLock !== true) {
      restProps.setScreenLock(true);
    }
    inParams.in_date_version = restProps.effVersionDataItem.dateVersion;
    await Action.getListDataAsync(inParams);

    restProps.isCancelClick && restProps.setCancelProviderAwaitStatus(false);
    restProps.setisKpiEdit(false);

    setResetSort(false);
    restProps.setScreenLock(false);
  };

  React.useEffect(() => {
    if (restProps.isKpiCheckColumn) {
      setGridColumns(restProps.kpiColumnsData);
      restProps.setKpiColumnCheck(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restProps.isKpiCheckColumn]);

  const getInFilter = () => {
    return filterData.isFilter ? filterData.data : null;
  };

  const getInSortNumber = () => {
    return filterData.isSort ? filterData.in_sort_number : DEFAULT_SORT.number;
  };

  const getInSortOrder = () => {
    return filterData.isSort ? filterData.sort_order : DEFAULT_SORT.order;
  };

  const getPageTake = () => {
    return pageData.take;
  };

  const getPageSkip = () => {
    return pageData.skip;
  };

  //hook use to check / uncheck header checkbox
  const setkHeaderCheckbox = () => {
    setIsHeaderChecked(
      data.length ? !data.filter((x) => x.is_kpi_assign === 0).length : 0
    );
  };

  const onRowClick = (dataItem) => {
    let newData = data.map((item) => ({
      ...item,
      selected: item.kpi_mstr_key === dataItem.kpi_mstr_key ? true : false,
    }));
    setData(newData);
  };

  const enterEdit = (dataItem, field) => {
    if (restProps.pageProps.writeAccess === 0) {
      return false;
    }

    if (field !== "kpi_weight_pct") {
      return false;
    }

    let newData = data.map((item) => ({
      ...item,
      inEdit:
        item.kpi_mstr_key === dataItem.kpi_mstr_key && item.is_kpi_assign == 1
          ? field
          : undefined,
    }));

    setData(newData);
    restProps.setisKpiEdit(true);
  };

  const exitEdit = (dataItem, index, dataIndex, e) => {
    let newData = data.map((item) => ({
      ...item,
      inEdit: undefined,
    }));
    setData(newData);
  };

  const itemChange = React.useCallback(
    (event) => {
      const field = event.field || "";
      const newData = data.map((item) =>
        item.kpi_mstr_key === event.dataItem.kpi_mstr_key
          ? { ...item, [field]: event.value, inIteamEdit: true }
          : item
      );
      let editItem = event.dataItem;
      editItem[field] = event.value;
      setData(newData);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  // const onColumnsSubmit = (columnsState) => {
  //   setColumns(columnsState);
  // };

  const handlePageChange = (event) => {
    const pageData = event.page;

    setAPICallingState(true);

    setPage({ skip: pageData.skip, take: pageData.take });

    const dataVal = {
      ...apiCallData,
      in_filter: getInFilter(),
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
      in_page_row_offset: pageData.skip,
      in_page_rows: pageData.take,
    };

    getGridData(dataVal);
  };

  const handleColumnMenu = (columnMenuData, isFilter, isSort) => {
    let index = 1;
    let filterSkip = getPageSkip();
    let filterTake = getPageTake();

    if (isFilter) {
      filterSkip = 0;
      filterTake = pageSize.recordTake;

      //reset pager when filter applies
      setPage({ skip: filterSkip, take: filterTake });
    }

    if (data.length > 0 && columnMenuData[0] !== undefined) {
      let obj = Object.keys(data[0]);
      let sortNameField = "";
      //replace mstr key with name field only when sorting is applied
      if (
        columnMenuData[0].field &&
        columnMenuData[0].field.indexOf("_mstr_key")
      ) {
        let sortKeyField = columnMenuData[0].field;
        sortNameField = sortKeyField.replace("_mstr_key", "_name");
      }

      const sortOriginField =
        sortNameField !== "" ? sortNameField : columnMenuData[0].field;

      index = obj.findIndex((x) => x === sortOriginField) + 1;
    }
    const filterString = isFilter
      ? getfilterDataString(columnMenuData, gridColumns)
      : filterData.data;

    isFilter = false;
    if (filterString !== "") {
      isFilter = true;
    }

    let sortDir =
      filterData.isSort && columnMenuData.length
        ? filterData.sort_order
        : DEFAULT_SORT.order;
    let sortNumber =
      filterData.isSort && columnMenuData.length
        ? filterData.in_sort_number
        : DEFAULT_SORT.number;

    isSort = !columnMenuData.length ? false : filterData.isSort;

    if (
      columnMenuData[0] !== undefined &&
      columnMenuData[0].dir !== undefined
    ) {
      isSort = true;
      sortDir = columnMenuData[0].dir.toUpperCase();
      sortNumber = index;
    }

    const dataVal = {
      ...apiCallData,
      in_filter: isFilter ? filterString : null,
      in_sort_number: sortNumber,
      in_sort_order: sortDir,
      in_page_row_offset: filterSkip,
      in_page_rows: filterTake,
    };

    setFilterData({
      ...filterData,
      isFilter: isFilter,
      isSort: isSort,
      data: filterString,
      in_sort_number: sortNumber,
      sort_order: sortDir,
    });

    setAPICallingState(true);

    getGridData(dataVal);
  };

  const headerCheckboxOnChange = (e) => {
    restProps.setisKpiEdit(true);

    setIsHeaderChecked(!isHeaderChecked);

    data
      .filter((x) => x.is_kpi_assign === (isHeaderChecked ? 1 : 0))
      .map((item) => {
        item.is_kpi_assign = !isHeaderChecked ? 1 : 0;
        item.inIteamEdit = true;

        if (isHeaderChecked) {
          item.inEdit = false;
        }
        return 0;
      });

    // TODO : UNSAVED CHANGES
    // dispatch(setUnSavedChangesPopup({ isUnSavedChange: true }));
    changeCancelBtnState("enable");
    changeUpdateBtnState("enable");
    setData(data);
  };

  const cellCheckboxOnChange = (e, props) => {
    restProps.setisKpiEdit(true);

    setCheckboxFlag(true);
    props.dataItem.is_kpi_assign = e.target.checked ? 1 : 0;
    props.dataItem.inEdit = false;
    props.dataItem.inIteamEdit = true;

    setTimeout(function () {
      // TODO : UNSAVED CHANGES
      // dispatch(setUnSavedChangesPopup({ isUnSavedChange: true }));

      changeCancelBtnState("enable");
      changeUpdateBtnState("enable");
      setCheckboxFlag(false);
      setkHeaderCheckbox();
    });
  };

  const CheckboxCell = React.memo((props) => {
    let opts = {};

    if (restProps.pageProps.writeAccess === 0) {
      opts["disabled"] = "disabled";
    }

    return (
      <td className="k-command-cell delRecord">
        <input
          className="k-checkbox"
          type="checkbox"
          value={props.dataItem.is_kpi_assign}
          checked={props.dataItem.is_kpi_assign}
          onChange={(e) => cellCheckboxOnChange(e, props)}
          {...opts}
        />
      </td>
    );
  });

  const BooleanReandOnlyCell = React.useCallback((props) => {
    const { ariaColumnIndex, columnIndex, dataItem, field, render } = props;

    let val =
      dataItem[field] !== "" ? (dataItem[field] === 1 ? "True" : "False") : "";

    const defaultRendering = (
      <td aria-colindex={ariaColumnIndex} data-grid-col-index={columnIndex}>
        <span title={val}>{val}</span>
      </td>
    );

    return render?.call(undefined, defaultRendering, props);
  }, []);

  const StringReandOnlyCell = React.useCallback((props) => {
    const { ariaColumnIndex, columnIndex, dataItem, field, render } = props;

    let val = dataItem[field || ""];

    if (field.indexOf("_mstr_key") !== -1) {
      val = dataItem[field.replace("_mstr_key", "_name") || ""];
    }

    const defaultRendering = (
      <td aria-colindex={ariaColumnIndex} data-grid-col-index={columnIndex}>
        <span title={val}>{val}</span>
      </td>
    );

    return render?.call(undefined, defaultRendering, props);
  }, []);

  const NumberCell = React.useCallback(
    (props) => {
      const { ariaColumnIndex, columnIndex, dataItem, field, render } = props;

      let isInEdit = field === dataItem.inEdit;

      let value = props.field + dataItem.rowId;
      const handleChange = (e, props, value) => {
        if (props.onChange) {
          changeCancelBtnState("enable");
          changeUpdateBtnState("enable");
          // TODO : UNSAVED CHANGES
          // dispatch(setUnSavedChangesPopup({ isUnSavedChange: true }));

          props.onChange({
            // dataIndex: 0,
            dataItem: props.dataItem,
            field: props.field,
            syntheticEvent: e.syntheticEvent,
            value: e.target.value,
          });
        }
      };

      const defaultRendering = (
        <td
          aria-colindex={ariaColumnIndex}
          data-grid-col-index={columnIndex}
          className="kpi-field"
        >
          {isInEdit ? (
            <NumericTextBox
              value={dataItem[field]}
              onChange={(e) => {
                handleChange(e, props, value);
              }}
              name={`${field}`}
            />
          ) : (
            <span title={dataItem[field || ""]}>{dataItem[field || ""]}</span>
          )}
          {Object.keys(errorObj).length > 0 ? (
            <div role="alert" className="k-form-error k-text-start">
              {Object.keys(errorObj).length > 0 ? errorObj[value] : ""}
            </div>
          ) : (
            false
          )}
        </td>
      );
      return render?.call(undefined, defaultRendering, props);
    },
    [errorObj]
  );

  const useCol = (columns) => {
    return columns.map((column, idx) => {
      if (column.field === "is_kpi_assign") {
        column["aditionalParam"] = {
          assignCheckboxProps: {
            checked: isHeaderChecked,
            onChange: headerCheckboxOnChange,
            disabled: !data.length || restProps.pageProps.writeAccess === 0,
          },
          dataTableProps: {
            id: "plan_kpi_assign",
          },
        };
      }
      return column.show ? (
        <Column
          {...columnMenuProps(column)}
          key={idx}
          width={column.field === "is_kpi_assign" ? "130px" : "150px"}
          cell={
            column.type === "boolean"
              ? column.field === "is_kpi_assign"
                ? CheckboxCell
                : BooleanReandOnlyCell
              : column.type === "number" && column.field === "kpi_weight_pct"
              ? NumberCell
              : StringReandOnlyCell
          }
          resizable={column.field === "is_assign" ? false : true}
        />
      ) : (
        false
      );
    });
  };

  let kpiColumn = useCol(gridColumns);

  const onClearFilter = () => {
    setAPICallingState(true);

    //set in_params
    const dataVal = {
      ...apiCallData,
      in_filter: null,
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
      in_page_row_offset: getPageSkip(),
      in_page_rows: getPageTake(),
    };

    //set filter to false
    setFilterData({
      ...filterData,
      data: "",
      isFilter: false,
    });

    getGridData(dataVal);
  };

  const onRefreshClick = () => {
    setAPICallingState(true);
    restProps.setRefreshBtnClick(true);
    const dataVal = {
      ...apiCallData,
      in_filter: getInFilter(),
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
      in_page_row_offset: getPageSkip(),
      in_page_rows: getPageTake(),
    };

    getGridData(dataVal);
  };

  const customCellRender = (td, props) => {
    return (
      <CellRender
        navigatable={true}
        originalProps={props}
        td={td}
        enterEdit={enterEdit}
        editField={editField}
        onRowClick={onRowClick}
        onSelectionChange={onRowClick}
        updateActBtn="grid-incell-update"
        cancelActBtn="grid-incell-cancel"
      />
    );
  };

  const customRowRender = (tr, props) => (
    <RowRender
      originalProps={props}
      tr={tr}
      editField={editField}
      exitEdit={exitEdit}
    />
  );

  const updateAssignment = async () => {
    restProps.setisKpiUpdateClick(false);
    const bodydata = {
      in_user_id: cookiesData.out_user_id,
      in_tenant_id: cookiesData.out_tenant_id,
      in_comp_plan_mstr_key: apiCallData.in_comp_plan_mstr_key,
      in_date_version: apiCallData.in_date_version,
    };

    let updatedKpiAssignDataList = [];

    data
      .filter((x) => x.inIteamEdit === true)
      .map((item) => {
        let obj = {
          in_kpi_mstr_key: item.kpi_mstr_key,
          in_is_kpi_assign: item.is_kpi_assign,
          in_kpi_weight_pct: item.kpi_weight_pct ? item.kpi_weight_pct : 0,
        };

        updatedKpiAssignDataList.push(obj);

        return 0;
      });

    bodydata["in_assign_array"] = updatedKpiAssignDataList;

    if (bodydata.in_assign_array.length > 0) {
      let response = await Action.assignPlanKpiAsync(bodydata);

      if (response) {
        data.map((item) => {
          item.inEdit = false;
          return 0;
        });
        restProps.setisKpiEdit(false);
        setAPICallingState(true);
        const dataVal = {
          ...apiCallData,
          in_filter: getInFilter(),
          in_sort_number: getInSortNumber(),
          in_sort_order: getInSortOrder(),
          in_page_row_offset: getPageSkip(),
          in_page_rows: getPageTake(),
        };

        getGridData(dataVal);
      }
    } else {
      cancelAssignment();
    }
  };

  const cancelAssignment = () => {
    dispatch(
      removeMessage({
        message: "",
        type: "Error",
        show: false,
      })
    );
    setErrorObj({});
    setAPICallingState(true);
    const dataVal = {
      ...apiCallData,
      in_filter: getInFilter(),
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
      in_page_row_offset: getPageSkip(),
      in_page_rows: getPageTake(),
    };

    getGridData(dataVal);
  };

  return (
    <>
      {isRefreshLoading && loadingPanel}
      {columns.length > 0 ? (
        <>
          <DataTable
            isAPICalling={isAPICalling}
            initialLoading={true}
            resetSort={resetSort}
            data={data}
            id={pageId}
            module={pageId}
            dataItemKey={DATA_ITEM_KEY}
            width={"auto"}
            editField={editField}
            dataCount={dataCount}
            customColumn={kpiColumn}
            columns={gridColumns}
            rowRender={customRowRender}
            cellRender={customCellRender}
            itemChange={itemChange}
            pageData={pageData}
            defaultPageSize={defaultPageSize}
            pageSize={pageSize}
            setPageSize={setPageSize}
            handleColumnMenu={handleColumnMenu}
            handlePageChange={handlePageChange}
            refreshClick={onRefreshClick}
            onClearFilter={onClearFilter}
            reorderable={false}
          />
        </>
      ) : (
        <>{loadingPanel}</>
      )}
    </>
  );
};
