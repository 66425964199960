
/* eslint-disable jsx-a11y/anchor-is-valid */
import { changeCancelBtnState, changeUpdateBtnState } from "../../utils/utils";
import React from "react";
import { HeaderPage } from "../base/headerPage";
import { CustomTableData } from "./customTablesGrid";
import AddCustomTable from "./addCustomTable";
import useServiceApi from "./service";

const CustomTable = (pageProps) => {
  const [list] = useServiceApi();
  const addRef = React.useRef(null);
  const [isAddNewConfig, setNewConfig] = React.useState(false);
  const [isShow, setShow] = React.useState({
    dtuLogs: false,
    sendEmails: false,
    dtuUpload: false,
    deleteRows: false,
    addCustomTable: false
  });
  const [exportDataClick, setExportDataClick] = React.useState(false);
  const [isAPICall, setAPICall] = React.useState(false);
  const [selectedRowId, setSelectedRowId] = React.useState("");
  const [dataList, setDataList] = React.useState([]);
  const [groupEdit, setGroupEdit] = React.useState(false);
  const [columnsData, setColumnsData] = React.useState([]);
  const [isCheckColumn, setColumnCheck] = React.useState(false);

  React.useEffect(() => {
    if (list.loadingState.isSaveData) {
      setAPICall(true);
      setNewConfig(false);
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list.loadingState.isSaveData]);

  React.useEffect(() => {
    if (groupEdit === false) {
      changeCancelBtnState();
      changeUpdateBtnState();
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupEdit]);


  React.useEffect(() => {
    if (selectedRowId !== '') {
      const deleteRows = parent.querySelector(".delete-rows");
      deleteRows && deleteRows.enableKendoMenu();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowId])

  const addNewConfig = () => {
    setShow({ ...isShow, addCustomTable: true })
    setNewConfig(!isAddNewConfig);
  };

  const AddNewLink = () => {
    return (
      <>
        <a
          className="cta-small"
          ref={addRef}
          onClick={(e) => {
            addNewConfig(e);
          }}
        >
          <i className="fas fa-plus"></i> Add New Custom Table
        </a>

      </>
    );
  };

  const handleMenuItemClick = (action) => {
    switch (action) {
      case "dtuLogs":
        setShow({
          ...isShow,
          dtuLogs: !isShow.dtuLogs,
        });
        break;
      case "export":
        setExportDataClick(true);
        break;
      case "delete":
        setShow({
          ...isShow,
          deleteRows: !isShow.deleteRows,
        });
        break;
      default:
        break;
    }
  };



  const headerConfig = {
    title: "Custom Tables",
    id: "custom_tables",
    add_new_fx: AddNewLink,
    action_menu: {
      export: { fx: handleMenuItemClick },
      delete: { fx: handleMenuItemClick },
    },
    manage_col: {
      columnsData: columnsData,
      setColumnsData: setColumnsData,
      setColumnCheck: setColumnCheck,
    },
  };



  const parent = document.querySelector("#content-" + headerConfig.id);

  if (parent) {
    const exportToExcel = parent.querySelector(".export-to-excel");
    const deleteRows = parent.querySelector(".delete-rows");

    exportToExcel && exportToExcel.disableKendoMenu();
    deleteRows && deleteRows.disableKendoMenu();

    dataList.length && exportToExcel && exportToExcel.enableKendoMenu();
    if (selectedRowId !== "") {
      deleteRows && deleteRows.enableKendoMenu();
    }
  }

  return (
    <div className="Main">
      <div className="content">
        <div className="content-head">
          <HeaderPage pageProps={pageProps} headerConfig={headerConfig} />
        </div>

        <div className="content-body">
          <div className="dtu-file-container rowhoverdisable">
            <CustomTableData
              isAPICall={isAPICall}
              setAPICall={setAPICall}
              setExportDataClick={setExportDataClick}
              exportDataClick={exportDataClick}
              selectedRowId={selectedRowId}
              setSelectedRowId={setSelectedRowId}
              setShow={setShow}
              isShow={isShow}
              setDataList={setDataList}
              pageProps={pageProps}
              groupEdit={groupEdit}
              setGroupEdit={setGroupEdit}
              columnsData={columnsData}
              setColumnsData={setColumnsData}
              setColumnCheck={setColumnCheck}
              isCheckColumn={isCheckColumn}
            />
          </div>
        </div>
      </div>

      {isShow.addCustomTable ?
        <AddCustomTable visible={isShow.addCustomTable} setShow={setShow} setAPICall={setAPICall} pageProps={pageProps} /> : false}
    </div>
  );
};

export default React.memo(CustomTable);
