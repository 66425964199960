import React from "react";
import { getUserCredential, getTenantCookieData } from "../../lib/cookies";
import { errStatusHandler, POSTAPI } from "../../api-setup/api-helper";
import {
    downloadURI,
    GridColumnDataMapper,
} from "../../utils/utils";
import { USER_GRID_DEFAULT_PAGE_SIZE } from "../../constants/grid";
import { useDispatch } from "react-redux";
import { setNewMessage } from "../../redux/actions/setting-actions";
import {
    GET_SELECT_GRID_COLUMN_XML,
    SELECT_KPI,
    EXPORT_RESULT_MODULE,
    INSERT_KPI,
    UPDATE_KPI,
    DELETE_KPI,
} from "../../api-setup/api-endpoints";
import { GetPeriodTypeAPI, GetKPIType } from "../../lib/common-service";
import { DEFAULT_SORT } from "../../constants/grid";
import { useHistory } from "react-router-dom";

const initialDataState = {
    skip: 0,
    take: USER_GRID_DEFAULT_PAGE_SIZE,
};
let defaultItem = {
    text: USER_GRID_DEFAULT_PAGE_SIZE,
    id: 2,
};

export default function Results() {
    const history = useHistory();
    const cookiesData = getUserCredential();
    const header = {
        "x-access-token": cookiesData.secretToken,
    };
    const dispatch = useDispatch();
    const defaultPageSize = getTenantCookieData("record_per_page");

    const [data, setData] = React.useState([]);
    const [columns, setColumns] = React.useState([]);
    const [dataCount, setDataCount] = React.useState(0);
    const [isColumnMenuSearch, setIsColumnMenuSearch] = React.useState(false);
    const [paginationData, setPaginationData] = React.useState({
        pageData: 0,
        rows: 10,
    });
    const [noDataFound, setNoDataFound] = React.useState(false);

    const [initialLoading, setLoadingState] = React.useState(false);
    const [pageData, setPage] = React.useState(initialDataState);
    const [page, setPageData] = React.useState(0);
    const [editData, setEditData] = React.useState("");
    const [pageSize, setPageSize] = React.useState(defaultItem);
    const [isAPICalling, setAPICallingState] = React.useState(false);
    const [errorMsg, SetErrorMsg] = React.useState("");
    const [group, setGroup] = React.useState([]);
    const [resultState, setResultState] = React.useState([]);
    const [collapsedState, setCollapsedState] = React.useState([]);
    const [originalData, setOriginalData] = React.useState([]);
    const [aliasData, setaliasData] = React.useState([]);
    const [loading, setLoadingStateSearch] = React.useState({
        isSearchLoader: false,
        updateColumn: false
    });
    const [isRefreshLoading, setRefreshLoading] = React.useState(false);
    const [periodsTypeFields, setPeriodTypeFields] = React.useState({
        periodTypeValue: {
            in_period_mstr_key: "",
            in_period_start_date: "",
            in_period_end_date: "",
        },
        periodTypeData: [],
        isPeriodTypeDataLoading: true,
    });
    const [originKPIType, setOriginKPIType] = React.useState([]);
    const [KPITypeFields, setKPIdTypeFields] = React.useState({
        KPITypeValue: {
            in_kpi_mstr_key: "",
        },
        KPITypeData: [],
        isKPITypeDataLoading: true,
    });
    const [originPeriodType, setOriginPeriodType] = React.useState([]);
    const [loadingState, setLoadState] = React.useState({
        isSaveData: undefined,
        isDeleteData: undefined,
    });
    const [confirmationPopup, setConfirmationPopup] = React.useState({
        title: "Delete",
        desc: "Are you sure you want to delete this record?",
        isVisible: false,
        type: "",
        actionBtn: [],
        data: {},
        action: "Delete",
    });


    let newData = [];
    let processedGroupData = [];
    const getListDataAsync = (listData) => {
        return new Promise(function (resolve, reject) {
            POSTAPI(false, SELECT_KPI, null, header, listData, dispatch).then(
                (res) => {
                    if (res) {
                        if (res.data.out_parameters.out_ret_code > 0) {
                            setLoadingState(true);
                            errStatusHandler(res, history, dispatch);
                        }
                        setAPICallingState(false);
                        setLoadingState(true);
                        setEditData("");

                        let userData = JSON.parse(
                            JSON.stringify(res.data.row_result)
                        );
                        if (listData.in_load_type === "grid-reload") {
                            const columData = GridColumnDataMapper(
                                res.data.out_parameters.out_column_xml
                            );
                            columData.map((cItem) => {
                                cItem.minWidth = 260;
                                return 0;
                            });
                            setColumns(columData);
                        }
                        userData.map((item, idx) => {
                            item.rowId = idx;
                            item.selected = false;
                            item.defaultSelected = idx === 0;
                            item.rowCount = userData.length;
                            return 0;
                        });

                        setData(userData);
                        setOriginalData(userData);

                        setDataCount(res.data.out_parameters.out_total_rows);
                        setRefreshLoading(false);
                        setLoadingStateSearch({
                            ...loading,
                            isSearchLoader: false,
                        });
                        resolve(userData);
                    }
                }
            );
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    const getColumListXML = (reqData) => {
        POSTAPI(
            false,
            GET_SELECT_GRID_COLUMN_XML,
            null,
            header,
            reqData,
            dispatch
        ).then((res) => {
            if (res.data.out_parameters.out_ret_code > 0) {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                setLoadingState(true);
                setAPICallingState(true);
                errStatusHandler(res, history, dispatch);
            } else {
                const columData = GridColumnDataMapper(
                    res.data.out_parameters.out_columns_xml,
                    "field_proceed"
                );
                setColumns(columData);
            }
        });
    };

    const exportResultData = (reqData) => {
        POSTAPI(
            false,
            EXPORT_RESULT_MODULE,
            null,
            header,
            reqData,
            dispatch
        ).then((res) => {
            if (res.data.out_parameters.out_ret_code > 0) {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                setLoadingState(true);
                setAPICallingState(true);
                errStatusHandler(res, history, dispatch);
            } else {
                downloadURI(
                    res.data.fileData.fileName,
                    res.data.fileData.fileLink
                );
            }
        });
    };
    const getPeriodTypesData = async () => {
        let periodTypeData = {
            in_filter: "AND a.period_type_hierarchy_level IS NOT NULL",
            in_sort_number: null,
            in_sort_order: null,
        };

        let res = await GetPeriodTypeAPI(periodTypeData, dispatch, history);

        if (res.length > 0) {
            let tempArray = [];

            res.map((item) => {
                let obj = {};
                obj.text = item.period_type_name;
                obj.id = item.period_type_mstr_key;

                tempArray.push(obj);
                return 0;
            });
            setOriginPeriodType(tempArray);

            setPeriodTypeFields({
                ...periodsTypeFields,
                periodTypeData: tempArray,
                periodTypeValue: tempArray[0],
                isPeriodTypeDataLoading: false,
            });
        } else {
            setPeriodTypeFields({
                ...periodsTypeFields,
                isPeriodTypeDataLoading: false,
            });
        }
    };
    const getKpiTypesData = async () => {
        const kpiTypeData = {
            in_tenant_id: cookiesData.out_tenant_id,
            in_filter: null,
            in_sort_number: DEFAULT_SORT.number,
            in_sort_order: DEFAULT_SORT.order,
            in_page_row_offset: 0,
            in_page_rows: defaultPageSize.recordTake,
        };

        let res = await GetKPIType(kpiTypeData, dispatch, history);

        if (res.length > 0) {
            let tempArray = [];

            res.map((item) => {
                let obj = {};
                obj.text = item.kpi_type_name;
                obj.id = item.kpi_type_mstr_key;

                tempArray.push(obj);
                return 0;
            });

            setOriginKPIType(tempArray);
            setKPIdTypeFields({
                ...KPITypeFields,
                KPITypeData: tempArray,
                KPITypeValue: tempArray[0],
                isKPITypeDataLoading: false,
            });
        } else {
            setKPIdTypeFields({
                ...KPITypeFields,
                isKPITypeDataLoading: false,
            });
        }
    };

    const addDataAsync = async (bodyData) => {
        setRefreshLoading(true);
    
            return new Promise(function (resolve, reject) {
                POSTAPI(false, INSERT_KPI, null, header, bodyData, dispatch).then(
                    (res) => {
                        setRefreshLoading(false);
                        if (res.data.out_parameters.out_ret_code > 0) {
                            let msg = res.data.out_parameters.out_error_msg;
                            setLoadState({ ...loadingState, isSaveData: false });
                            dispatch(
                                setNewMessage({
                                    message: msg,
                                    type: "Error",
                                    show: true,
                                })
                            )
                         
                          
                        } else {
                          
                            setLoadState({ ...loadingState, isSaveData: true });
                            setTimeout(function () {
                                resolve("success");
                            });
                        }
                    }
                );
            })
                .then((res) => {
                    return res;
                })
                .catch((res) => {
                    if (res.data.out_parameters.out_ret_code > 0) {
                        dispatch(
                            setNewMessage({
                                message: res.data.out_parameters.out_error_msg,
                                type: "Error",
                                show: true,
                            })
                        );
                        errStatusHandler(res, history, dispatch);
                    }
                });
        };

    const updateDataAsync = (bodyData) => {
        setRefreshLoading(true);
        return new Promise(function (resolve, reject) {
            POSTAPI(false, UPDATE_KPI, null, header, bodyData, dispatch).then(
                (res) => {
                    setRefreshLoading(false);
                    if (res && res.data.out_parameters.out_ret_code === 0) {
                        resolve(true);
                    } else {
                        dispatch(
                            setNewMessage({
                                message: res?.data.out_parameters.out_error_msg,
                                type: "Error",
                                show: true,
                            })
                        );
                        resolve([]);
                        errStatusHandler(res, history, dispatch);
                    }
                }
            );
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    const deleteDataAsync = async (bodyData) => {
        setRefreshLoading(true);
        return new Promise(function (resolve, reject) {
            POSTAPI(false, DELETE_KPI, null, header, bodyData, dispatch).then(
                (res) => {
                    const totalKpiTypes = bodyData.in_kpi_mstr_key.length;
                    const unSucessFullCount = res.data.successCount;
                    setRefreshLoading(false);
                    if (res.data.out_parameters.out_ret_code > 0) {
                        let msg = res.data.out_parameters.out_error_msg;

                        if (totalKpiTypes !== unSucessFullCount) {
                            msg =
                                unSucessFullCount +
                                " KPI (" +
                                res.data.successName +
                                ") out of " +
                                totalKpiTypes + ' '+
                                "selected linked to active Comp Plan - cannot delete.";
    
    
                            setLoadState({ ...loadingState, isDeleteData: true });
                            setTimeout(function () {
                                resolve("success");
                            });
                        } else {
                            setLoadState({ ...loadingState, isDeleteData: false });
                            setTimeout(function () {
                                resolve("error");
                            });
                        }
                        setConfirmationPopup({
                            ...confirmationPopup,
                            isVisible: false,
                            type: "",
                            actionBtn: [],
                            data: {},
                            action: "",
                        });
                        dispatch(
                            setNewMessage({
                                message: msg,
                                type: "Error",
                                show: true,
                            })
                        );
                    } else {
                        setConfirmationPopup({
                            ...confirmationPopup,
                            isVisible: false,
                            type: "",
                            actionBtn: [],
                            data: {},
                            action: "",
                        });
                        setLoadState({ ...loadingState, isDeleteData: true });
                        setTimeout(function () {
                            resolve("success");
                        });
                    }
                }
            );
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    const exportDataAsync = (bodyData) => {
        setRefreshLoading(true);
        return new Promise(function (resolve, reject) {
            POSTAPI(false, SELECT_KPI, null, header, bodyData, dispatch).then(
                (res) => {
                    setRefreshLoading(false);
                    if (
                        res &&
                        (res.data.out_parameters.out_ret_code === 0 ||
                            res.data.out_parameters.out_ret_code === 2)
                    ) {
                        resolve(res.data.row_result);
                    } else {
                        dispatch(
                            setNewMessage({
                                message: res?.data.out_parameters.out_error_msg,
                                type: "Error",
                                show: true,
                            })
                        );
                        resolve([]);
                        errStatusHandler(res, history, dispatch);
                    }
                }
            );
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };
    const getListDataLengthAsync = (listData) => {
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                SELECT_KPI,
                null,
                header,
                listData,
                dispatch
            ).then((res) => {
                if (res) {
                    let userData = [];
                    userData = JSON.parse(JSON.stringify(res.data.row_result));
                    resolve(userData);
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    return [
        {
            data,
            columns,
            dataCount,
            setData,
            setColumns,
            isColumnMenuSearch,
            pageData,
            editData,
            page,
            pageSize,
            isAPICalling,
            setEditData,
            initialLoading,
            setPage,
            setPageData,
            setPageSize,
            setPaginationData,
            setAPICallingState,
            paginationData,
            errorMsg,
            collapsedState,
            setCollapsedState,
            group,
            setResultState,
            setGroup,
            newData,
            originalData,
            resultState,
            processedGroupData,
            setLoadingStateSearch,
            loading,
            setLoadingState,
            noDataFound,
            aliasData,
            originPeriodType,
            periodsTypeFields,
            setPeriodTypeFields,
            originKPIType,
            KPITypeFields,
            setKPIdTypeFields,
            loadingState,
            setLoadState,
            setConfirmationPopup,
            confirmationPopup,
            isRefreshLoading,
            setRefreshLoading,
            setNoDataFound,
            SetErrorMsg,
            setIsColumnMenuSearch,
            setaliasData
        },
        {
            getListDataAsync,
            getColumListXML,
            exportResultData,
            getPeriodTypesData,
            getKpiTypesData,
            addDataAsync,
            updateDataAsync,
            deleteDataAsync,
            exportDataAsync,
            getListDataLengthAsync
        },
    ];
}
