import React from "react";
import { GridButton, RoleTableData } from "./roleTableGrid";
import MessageSchema from "../../utils/messageSchema";
import { getUserCredential } from "../../lib/cookies";
import useCustomRoleAPI from "./service";
import Popup from "../../components/customPopup";
import { Form, FormElement, FieldWrapper } from "@progress/kendo-react-form";
import { Label, Error } from "@progress/kendo-react-labels";
import { Input } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { changeCancelBtnState, changeUpdateBtnState } from "../../utils/utils";

import { generateHeader, GETAPI } from "../../api-setup/api-helper";
import {
  GET_DB_ROLE_TYPE_KEY_LIST
} from "../../api-setup/api-endpoints";

import { DialogComponent } from "../../components/dialog";

import {
  setNewMessage,
} from "../../redux/actions/setting-actions";
import { LANDING_PAGE } from "../../../src/constants/constants";
// import AddCustomTable from "./addCustomTable";
import { Button } from "@progress/kendo-react-buttons";
// import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { HeaderPage } from "../base/headerPage";
import { useDocumentClickEventListener } from "../../lib/hooks/custom-common-hooks";


const initialFormFields = {
  in_user_id: "",
  in_tenant_id: "",
  in_db_obj_key: 45,
  in_db_role_name: "",
  in_db_role_desc: "",
  in_db_role_type_key: "",
};


// const initialqueryNameString = {
//   name_string: "",
// };

// let apiCallData = {
//   in_filter: null,
//   in_sort_number: 3,
//   in_sort_order: "ASC",
//   in_page_row_offset: 0,
//   in_page_rows: defaultPageSize.recordTake,
//   in_user_role_filter: ">0",
// };

const Role = (pageProps) => {
  const dispatch = useDispatch();
 // const history = useHistory();

  const [list] = useCustomRoleAPI();
  const cookiesData = getUserCredential();
  const addRef = React.useRef(null);
  // const [filterqueryArray, setfilterqueryArray] = React.useState([]);

  const [columnsData, setColumnsData] = React.useState([]);
  const [isAddNewConfig, setNewConfig] = React.useState(false);
  // const [isupdate, setIsUpdate] = React.useState(false);
  // const [filterArrayname, setfilterArrayname] = React.useState([]);
  // const [filterArray, setfilterArray] = React.useState([]);
  // const [filterMstrKey, setfilterMstrKey] = React.useState([]);
  const [editClick, seteditClick] = React.useState(false);
  const [cancelClick, setcancelClick] = React.useState(false);
  const [buttonClick, setbuttonClick] = React.useState(true);
  const [cancelbuttonClick, setcancelbuttonClick] = React.useState(true);
  // const { unsavedPopup } = useSelector(
  //   unSaveChangesPopupSelector,
  //   shallowEqual
  // );

  const [saveClick, setsaveClick] = React.useState(false);
  const [roleDataObj, setroleDataObj] = React.useState({});

  // const [queryNameString, setQueryNameString] = React.useState({
  //   ...initialqueryNameString,
  // });

  const [isShow, setShow] = React.useState({
    deleteRows: false,
  });
  // const [showFilterPopup, setShowshowFilterPopup] = React.useState(false);
  const [exportDataClick, setExportDataClick] = React.useState(false);
  const [isAPICall, setAPICall] = React.useState(false);
  const [isCheckColumn, setColumnCheck] = React.useState(false);
  const [formFields, setFormFields] = React.useState({ ...initialFormFields });
  const [errorObj, setErrorObj] = React.useState({});
  const [isSaveClick, setSaveClick] = React.useState(false);
  const [selectedRowId, setSelectedRowId] = React.useState("");

  const [loadingState, setLoadingState] = React.useState({
    isCancelClick: false,
    isUpdateClick: false,
  });
  const [dataList, setDataList] = React.useState([]);
  //const anchor = React.useRef(null);
  // const [filterValues, setfilterValues] = React.useState([
  //   { coloum: "", value: "", condtions: "", one: "", textvalue: false },
  // ]);
  const [roleTypeKeyList, setRoleTypeKeyList] = React.useState([]);
  const [isFilterClick, setIsFilterClick] = React.useState(false);
  const [isShowQuery, setisShowQuery] = React.useState(false);
  // const [filterApiData, setfilterApiData] = React.useState();
  const [confirmationPopup, setConfirmationPopup] = React.useState({
    title: "Delete",
    desc: "Are you sure you want to delete this record?",
    isVisible: false,
    type: "",
    actionBtn: [],
    data: {},
    action: "Delete",
  });
  const [roleEdit, setRoleEdit] = React.useState(false);

  // const pathName = history.location.pathname;
  // const dbObjectCode = pathName.replace("/", "");
  let popupRef = React.useRef();
  useDocumentClickEventListener(isAddNewConfig, setNewConfig, popupRef);

  React.useEffect(() => {
    if (roleEdit === false) {
      changeCancelBtnState();
      changeUpdateBtnState();
    }
  }, [roleEdit]);

  // const searchPopup = () => {
  //   return (
  //     <Popup anchor={anchor.current} show={showFilterPopup}>
  //       <div className="SearchPopup">
  //         <div className="title">
  //           <h3 className="pt-10">Select Persisted Query Filter</h3>
  //           <div className="runQuery">
  //             <select
  //               className="procare-dropdown-nosearch"
  //               name="filterquery"
  //               id="filterquery"
  //               onChange={(e) => handleChangeFilterQuery(e)}
  //               value={filterArrayname}
  //             >
  //               <option value="select">Select Persisted Query Filter</option>
  //               {filterqueryArray.map((value, key) => (
  //                 <option value={value.value}>{value.text}</option>
  //               ))}
  //             </select>
  //           </div>

  //           <div className="closeicon ">
  //             <i className="fas fa-plus tooltip" onClick={oncloseFilter}></i>
  //           </div>
  //         </div>
  //         <div className="query">
  //           <div className="queryrow">
  //             <div className="table">
  //               <label className="caption">TABLE COLUMN</label>
  //             </div>
  //             <div className="condition">
  //               <label className="caption">CONDITION</label>
  //             </div>
  //             <div className="value">
  //               {" "}
  //               <label className="caption">VALUE</label>
  //             </div>
  //             <div className="ml-5"></div>
  //             <div className="action"></div>
  //           </div>
  //         </div>
  //         <div className="rolequerycont">
  //           {filterValues.map((element, index) => (
  //             <div className="query">
  //               <div className="queryrow">
  //                 <div className="table">
  //                   <select
  //                     className="procare-dropdown-nosearch"
  //                     name="coloum"
  //                     id=""
  //                     onChange={(e) => handleChange(index, e)}
  //                     value={element.coloum}
  //                     defaultValue={{
  //                       label: "ROLE",
  //                       value: "a.db_role_name",
  //                     }}
  //                   >
  //                     {filterArray.map((value, key) => (
  //                       <option
  //                         value={value.value}
  //                         defaultValue={"a.db_role_name"}
  //                       >
  //                         {value.label}
  //                       </option>
  //                     ))}
  //                   </select>
  //                 </div>
  //                 <div className="condition">
  //                   <select
  //                     className="procare-dropdown-nosearch"
  //                     name="condtions"
  //                     id=""
  //                     onChange={(e) => handleChange(index, e)}
  //                     value={element.condtions}
  //                   >
  //                     <option value="contains">Contains</option>
  //                     <option value="Does_not_contain">Does Not Contain</option>
  //                     <option value="is_equal_to">Is Equal To</option>
  //                     <option value="is_not_equal_to">Is Not Equal To </option>
  //                     <option value="is_empty">Is Empty</option>
  //                     <option value="is_not_empty">Is Not Empty</option>
  //                   </select>
  //                 </div>
  //                 <div className="value">
  //                   <input
  //                     type="text"
  //                     name="value"
  //                     value={element.value}
  //                     onChange={(e) => handleChange(index, e)}
  //                     disabled={element.textvalue}

  //                     // placeholder="value"
  //                   />
  //                 </div>
  //                 <div className="ml-5">
  //                   <select
  //                     className="procare-dropdown-nosearch"
  //                     name="one"
  //                     id=""
  //                     value={element.one}
  //                     onChange={(e) => handleChange(index, e)}
  //                   >
  //                     <option value="AND">AND</option>
  //                     <option value="OR">OR</option>
  //                   </select>
  //                 </div>

  //                 <div className="delete">
  //                   {/* {index || filterValues.length > 1 ? (
  //                     <a href="javascript:void(0)">
  //                       <i
  //                         className="fas fa-trash tooltip"
  //                         onClick={() => removeFormFields(index)}
  //                       >
  //                         <span className="tooltiptext plaintooltip">
  //                           Remove
  //                         </span>
  //                       </i>
  //                     </a>
  //                   ) : null} */}
  //                 </div>

  //                 <div className="action">
  //                   {/* <a href="javascript:void(0)">
  //                     <i
  //                       className="fas fa-plus tooltip"
  //                       onClick={() => addFormFields()}
  //                     >
  //                       <span className="tooltiptext plaintooltip">Add</span>
  //                     </i>
  //                   </a> */}
  //                 </div>
  //               </div>
  //             </div>
  //           ))}
  //         </div>
  //         <div className="query-footer">
  //           <div className="btnsearchcont">
  //             {!isupdate ? (
  //               <>
  //                 <label>Query Name</label>
  //                 <div className="querycont">
  //                   <input
  //                     type="text"
  //                     name="name_string"
  //                     value={queryNameString.name_string}
  //                     onChange={(e) => handleChangeQueryName(e)}
  //                   />
  //                   <ShowError name={"name_string"} />
  //                 </div>
  //               </>
  //             ) : null}
  //           </div>
  //         </div>

  //         <div className="btnsearchcontbtn actions">
  //           <button
  //             type="submit"
  //             className="save ml-0"
  //             onClick={() => {
  //               resetFilterValue();
  //             }}
  //           >
  //             RESET
  //           </button>
  //           <button
  //             type="submit"
  //             className="save mr-0 ml-0"
  //             onClick={() => {
  //               saveFilterValue("reset");
  //             }}
  //           >
  //             SAVE & RESET
  //           </button>
  //           <button
  //             type="submit"
  //             className="save"
  //             onClick={() => {
  //               saveFilterValue();
  //             }}
  //           >
  //             SAVE
  //           </button>
  //           <button
  //             type="submit"
  //             className="save mr-0 ml-0"
  //             onClick={() => {
  //               submitFilterValue();
  //             }}
  //           >
  //             Show Results{" "}
  //           </button>
  //         </div>
  //       </div>
  //     </Popup>
  //   );
  // };

  // const searchLink = () => {
  //   return (
  //     <>
  //       <a
  //         // href="/#"
  //         ref={anchor}
  //         className="searchclick"
  //         title="Search Filter"
  //         onClick={(e) => {
  //           onClicksearch(e);
  //         }}
  //       >
  //         <i className="fas fa-filter"></i>
  //       </a>

  //       {searchPopup()}
  //     </>
  //   );
  // };
  const AddNewLink = () => {
    return (
      // <div ref={popupRef}>
      //   <a
      //     // href="/#"
      //     className="cta-small-role"
      //     ref={addRef}
      //     onClick={(e) => {
      //         addNewRole(e);
      //     }}
      //   >
      //     <i className="fas fa-plus"></i> Add New Role
      //   </a>

      //   {AddNewPopup()}
      // </div>
      <div ref={popupRef}>
      <a
          href="/#"
          className="cta-small"
          ref={addRef}
          onClick={(e) => { addNewRole(e); }}
        >
          <i className="fas fa-plus"></i> Add New Role
        </a>

        { AddNewPopup() }
      </div>
    );
  };
  const AddNewPopup = () => {
    return (
      <Popup
        anchor={addRef.current}
        show={isAddNewConfig}
        className="right0"
        horizontal="left"
      >
        <div className="dot-dropdown-open dot-dropdown-open-Form">
          <div className="caption">
            <span>Add New Role</span>
          </div>

          {AddNewForm()}

          <div className="dropdownFooter">
            <div className="k-form-buttons">
              <Button
                primary={true}
                type="submit"
                onClick={() => {
                  onCancelClickForm();
                }}
              >
                Cancel
              </Button>
              <Button
                primary={true}
                type="submit"
                onClick={() => {
                  handleSubmit(formFields, errorObj);
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </Popup>
    );
  };
  const AddNewForm = () => {
    return (
      <Form
        onSubmit={handleSubmit}
        render={() => (
          <FormElement className="emailForm" horizontal={true}>
            <FieldWrapper className="roleseparator">
              <div className={"k-form-field-wrap-popup"}>
                <Label>{"Role* : "}</Label>
                <Input
                  type={"text"}
                  name="in_db_role_name"
                  value={formFields.in_db_role_name}
                  placeholder="Role"
                  onChange={(e) => {
                    handleFieldChange(e);
                  }}
                  autoFocus={true}
                />
                <ShowError name={"in_db_role_name"} />
              </div>
              <div className={"k-form-field-wrap-popup"}>
                <Label>{"Role Description* : "}</Label>
                <Input
                  type={"text"}
                  name="in_db_role_desc"
                  value={formFields.in_db_role_desc}
                  placeholder="Role Description"
                  onChange={(e) => {
                    handleFieldChange(e);
                  }}
                />
                <ShowError name={"in_db_role_desc"} />
              </div>
            </FieldWrapper>

            <FieldWrapper className="roleseparator">
              <div className={"k-form-field-wrap-popup valueDropdown"}>
                <Label>{"Role Type* : "}</Label>
                <DropDownList
                  onChange={handleFieldChange}
                  value={roleTypeKeyList.find(
                    (c) =>
                      c.db_role_type_name === formFields.in_db_role_type_key
                  )}
                  data={roleTypeKeyList}
                  textField="db_role_type_name"
                  name="in_db_role_type_key"
                />
                <ShowError name={"in_db_role_type_key"} />
              </div>
              <div className={"k-form-field-wrap-popup valueDropdown"}>
                <Label>{"Landing Page* : "}</Label>
                <DropDownList
                  onChange={(e) => {
                    setFormFields({
                      ...formFields,
                      in_db_obj_key: e.value,
                    });
                  }}
                  defaultValue={LANDING_PAGE[0]}
                  value={LANDING_PAGE.find(
                    (c) => c.text === formFields.in_db_obj_key
                  )}
                  data={LANDING_PAGE}
                  disabled={true}
                  textField="text"
                  placeholder={"Select"}
                />
                <ShowError name={"in_db_obj_key"} />
              </div>
            </FieldWrapper>
          </FormElement>
        )}
      />
    );
  };
  const handleMenuItemClick = (module) => {
    switch (module) {
      case "delete":
        setShow({
          ...isShow,
          deleteRows: !isShow.deleteRows,
        });
        break;
      default:
        break;
    }
  };
  const headerConfig = {
    title: "Roles",
    id: "roles",
    buttons: { template_fx: GridButton },
    // search_fx: searchLink,
    add_new_fx: AddNewLink,
    action_menu: {
      delete: { fx: handleMenuItemClick, caption: "Delete Selected Rows" },
    },
    manage_col: {
      columnsData: columnsData,
      setColumnsData: setColumnsData,
      setColumnCheck: setColumnCheck,
    },
  };

  const parent = document.querySelector("#content-" + headerConfig.id);

  if (parent) {
    const deleteRows = parent.querySelector(".delete-rows");
    deleteRows && deleteRows.disableKendoMenu();
    if (selectedRowId !== "") {
      deleteRows && deleteRows.enableKendoMenu();
    }
  }

  const getRoleTypeKeyList = () => {
    GETAPI(
      false,
      GET_DB_ROLE_TYPE_KEY_LIST,
      null,
      generateHeader(),
      null,
      dispatch
    ).then((res) => {
      if (res && res.data) {
        if (res.data.out_parameters.out_ret_code === 0) {
          setRoleTypeKeyList(res.data.row_result);
          setFormFields({
            ...formFields,
            in_db_role_type_key: res.data.row_result[0].db_role_type_name,
          });
        } else {
          dispatch(
            setNewMessage({
              message: res.data.out_parameters.out_error_msg,
              type: "Error",
              show: true,
            })
          );
        }
      }
    });
  };

  const ShowError = React.useCallback(
    (props) => {
      return <Error>{errorObj[props.name]}</Error>;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errorObj, isSaveClick]
  );
  // let removeFormFields = (i) => {
  //   let newFormValues = [...filterValues];
  //   newFormValues.splice(i, 1);
  //   setfilterValues(newFormValues);
  // };

  // let addFormFields = () => {
  //   const length = filterValues.length;
  //   if (length !== 10) {
  //     setfilterValues([
  //       ...filterValues,
  //       { coloum: "", value: "", condtions: "", one: "" },
  //     ]);
  //   }
  // };

  // const handleValidationForFilter = () => {
  //   let isValid = true;
  //   if (queryNameString.name_string.trim().length === 0 && isupdate === false) {
  //     isValid = false;
  //     errorObj.name_string = MessageSchema.roleQuery;
  //   }
  //   setErrorObj((preVState) => ({ ...preVState, errorObj }));
  //   return isValid;
  // };

  const handleValidation = () => {
    let isValid = true;
    var dbRoleName = formFields.in_db_role_name;
    if (dbRoleName.trim() === "") {
      isValid = false;
      errorObj.in_db_role_name = MessageSchema.roleName;
    } else {
      isValid = true;
      errorObj.in_db_role_name = "";
    }

    var dbRoleDesc = formFields.in_db_role_desc;
    if (dbRoleDesc.trim() === "") {
      isValid = false;
      errorObj.in_db_role_desc = MessageSchema.roleDesc;
    }

    setErrorObj((preVState) => ({ ...preVState, errorObj }));
    return isValid;
  };
  const handleSubmit = () => {
    let isValid = handleValidation();
    if (isValid) {
      setsaveClick(true);
      let data = {
        in_user_id: cookiesData.out_user_id.toString(),
        in_tenant_id: cookiesData.out_tenant_id,
        in_db_obj_key: formFields.in_db_obj_key,
        in_db_role_name: formFields.in_db_role_name,
        in_db_role_desc: formFields.in_db_role_desc,
        in_db_role_type_key: roleTypeKeyList.find(
          (x) => x.db_role_type_name === formFields.in_db_role_type_key
        ).db_role_type_key,
      };
      setroleDataObj(data);
      setNewConfig(false);
      setFormFields(initialFormFields);
    } else {
      setSaveClick(true);
    }
  };

  const onCancelClickForm = () => {
    setFormFields(initialFormFields);
    setErrorObj({});
    setNewConfig(false);
    // setcancelClick(true)
  };

  // const onClicksearch = () => {
  //   setShowshowFilterPopup(!showFilterPopup);
  //   setFilterQueryList();
  //   setIsFilterClick(true);
  // };

  // const setFilterQueryList = () => {
  //   let val = `${"user_key" + " = '" + cookiesData.out_user_id.toString()}`;
  //   let queryName = "AND " + val + "'";
  //   let data = {
  //     in_filter: queryName,
  //     in_grid_table_name: dbObjectCode,
  //     in_sort_number: 1,
  //     in_sort_order: "DESC",
  //     in_page_row_offset: 0,
  //     in_page_rows: 9999,
  //     in_user_id: cookiesData.out_user_id.toString(),
  //   };
  //   POSTAPI(
  //     false,
  //     GET_QUERY_FILTER_LIST,
  //     null,
  //     generateHeader(),
  //     data,
  //     dispatch
  //   ).then((res) => {
  //     if (res && res.data) {
  //       if (res.data.out_parameters.out_ret_code === 0) {
  //         var data = res.data.row_result;
  //         if (data) {
  //           let filterQueryNameArray = [];
  //           data.map((element) => {
  //             let obj = {};
  //             obj.text = element.grid_query_name;
  //             obj.value = element.grid_query_name;
  //             filterQueryNameArray.push(obj);
  //             return 0;
  //           });
  //           const uniqueIds = [];
  //           const uniquefilterQueryNameArray = filterQueryNameArray.filter(
  //             (element) => {
  //               const isDuplicate = uniqueIds.includes(element.value);
  //               if (!isDuplicate) {
  //                 uniqueIds.push(element.value);
  //                 return true;
  //               }
  //               return false;
  //             }
  //           );
  //           // setfilterqueryArray(uniquefilterQueryNameArray);
  //         }
  //       } else {
  //         // dispatch(
  //         //   setNewMessage({
  //         //     message: res.data.out_parameters.out_error_msg,
  //         //     type: "Error",
  //         //     show: true,
  //         //   })
  //         // );
  //       }
  //     }
  //   });
  // };

  // const saveFilterValue = (reset = null) => {
  //   if (isupdate) {
  //     let filterString = "";
  //     filterValues.forEach((data, index) => {
  //       let coloumdata = data.coloum;
  //       if (coloumdata === "") {
  //         coloumdata = "a.db_role_name";
  //       }
  //       let radioInput = data.one;
  //       var lastIndex = filterValues.length - 1;
  //       if (
  //         radioInput === "" &&
  //         filterValues.length > 1 &&
  //         index !== lastIndex
  //       ) {
  //         radioInput = "AND";
  //       }

  //       var arr = coloumdata.split(".");
  //       var coloumName = arr.pop();
  //       var alais = arr.join(".");

  //       const value = data.value.trim();
  //       const condtion = data.condtions;
  //       let queryCondtion = "";
  //       if (condtion === "is_equal_to") {
  //         queryCondtion = "=";
  //       } else if (condtion === "is_not_equal_to") {
  //         queryCondtion = "<>";
  //       } else if (condtion === "contains") {
  //         queryCondtion = "%";
  //       } else if (condtion === "Does_not_contain") {
  //         queryCondtion = "<>%";
  //       } else if (condtion === "is_empty") {
  //         queryCondtion = "NULL";
  //       } else if (condtion === "is_not_empty") {
  //         queryCondtion = "IS NOT NULL";
  //       } else {
  //         queryCondtion = "%";
  //       }

  //       let val = "";
  //       if (queryCondtion === "NULL") {
  //         val = `${alais + "." + coloumName + "= ''"}`;
  //       } else if (queryCondtion === "IS NOT NULL") {
  //         val = `${alais + "." + coloumName + "<> ''"}`;
  //       } else if (queryCondtion === "%") {
  //         val = `${
  //           alais +
  //           "." +
  //           coloumName +
  //           " " +
  //           "LIKE" +
  //           " " +
  //           '"' +
  //           "%" +
  //           value +
  //           "%" +
  //           '"'
  //         }`;
  //       } else if (queryCondtion === "<>%") {
  //         val = `${
  //           alais +
  //           "." +
  //           coloumName +
  //           " " +
  //           "NOT LIKE" +
  //           " " +
  //           '"' +
  //           "%" +
  //           value +
  //           "%" +
  //           '"'
  //         }`;
  //       } else {
  //         val = `${
  //           alais + "." + coloumName + queryCondtion + "'" + value + "'"
  //         }`;
  //       }

  //       if (index === 0) {
  //         filterString += val + " " + radioInput;
  //       } else {
  //         filterString += " " + val + " " + radioInput;
  //       }
  //     });
  //     var lastCondtion = filterString.split(" ").pop();
  //     if (lastCondtion === "AND" || lastCondtion === "OR") {
  //       var lastIndexFilter = filterString.lastIndexOf(" ");
  //       filterString = filterString.substring(0, lastIndexFilter);
  //     }

  //     let dataObject = {
  //       in_user_id: cookiesData.out_user_id.toString(),
  //       in_tenant_id: cookiesData.out_tenant_id,
  //       in_grid_query_filter_mstr_key: filterMstrKey,
  //       in_query_string: "AND" + " " + filterString,
  //       in_grid_table_name: dbObjectCode,
  //     };
  //     updateFilterAPIValue(dataObject);
  //     if (reset !== null) {
  //       apiCallData.in_filter = null;
  //       setfilterApiData(apiCallData);
  //       setisShowQuery(true);
  //     }
  //   } else {
  //     let valid = handleValidationForFilter();
  //     if (valid) {
  //       let filterString = "";
  //       filterValues.forEach((data, index) => {
  //         let coloumdata = data.coloum;
  //         if (coloumdata === "") {
  //           coloumdata = "a.db_role_name";
  //         }
  //         let radioInput = data.one;
  //         var lastIndex = filterValues.length - 1;
  //         if (
  //           radioInput === "" &&
  //           filterValues.length > 1 &&
  //           index !== lastIndex
  //         ) {
  //           radioInput = "AND";
  //         }
  //         var arr = coloumdata.split(".");
  //         var coloumName = arr.pop();
  //         var alais = arr.join(".");

  //         const value = data.value.trim();
  //         const condtion = data.condtions;
  //         let queryCondtion = "";
  //         if (condtion === "is_equal_to") {
  //           queryCondtion = "=";
  //         } else if (condtion === "is_not_equal_to") {
  //           queryCondtion = "<>";
  //         } else if (condtion === "contains") {
  //           queryCondtion = "%";
  //         } else if (condtion === "Does_not_contain") {
  //           queryCondtion = "<>%";
  //         } else if (condtion === "is_empty") {
  //           queryCondtion = "NULL";
  //         } else if (condtion === "is_not_empty") {
  //           queryCondtion = "IS NOT NULL";
  //         } else {
  //           queryCondtion = "%";
  //         }

  //         let val = "";
  //         if (queryCondtion === "NULL") {
  //           val = `${alais + "." + coloumName + "= ''"}`;
  //         } else if (queryCondtion === "IS NOT NULL") {
  //           val = `${alais + "." + coloumName + "<> ''"}`;
  //         } else if (queryCondtion === "%") {
  //           val = `${
  //             alais +
  //             "." +
  //             coloumName +
  //             " " +
  //             "LIKE" +
  //             " " +
  //             '"' +
  //             "%" +
  //             value +
  //             "%" +
  //             '"'
  //           }`;
  //         } else if (queryCondtion === "<>%") {
  //           val = `${
  //             alais +
  //             "." +
  //             coloumName +
  //             " " +
  //             "NOT LIKE" +
  //             " " +
  //             '"' +
  //             "%" +
  //             value +
  //             "%" +
  //             '"'
  //           }`;
  //         } else {
  //           val = `${
  //             alais + "." + coloumName + queryCondtion + '"' + value + '"'
  //           }`;
  //         }

  //         if (index === 0) {
  //           filterString += "AND" + " " + val + " " + radioInput;
  //         } else {
  //           filterString += " " + val + " " + radioInput;
  //         }
  //       });

  //       let dataObject = {
  //         in_user_id: cookiesData.out_user_id.toString(),
  //         in_tenant_id: cookiesData.out_tenant_id,
  //         in_grid_query_name: queryNameString.name_string,
  //         in_query_string: filterString,

  //         in_grid_table_name: dbObjectCode,
  //       };

  //       saveFilterAPIValue(dataObject);
  //       if (reset !== null) {
  //         apiCallData.in_filter = null;
  //         setfilterApiData(apiCallData);
  //         setisShowQuery(true);
  //         setfilterValues([{ coloum: "", value: "", condtions: "", one: "" }]);
  //         setShowshowFilterPopup(false);
  //         setQueryNameString({
  //           ...queryNameString,
  //           name_string: "",
  //         });
  //       }
  //     } else {
  //       setSaveClick(true);
  //     }
  //   }
  // };

  // const resetFilterValue = () => {
  //   apiCallData.in_filter = null;
  //   setfilterApiData(apiCallData);
  //   setisShowQuery(true);
  //   setfilterValues([{ coloum: "", value: "", condtions: "", one: "" }]);
  //   setfilterArrayname("select");
  //   setShowshowFilterPopup(false);
  //   setIsUpdate(false);
  // };

  // const updateFilterAPIValue = (data) => {
  //   POSTAPI(
  //     false,
  //     UPDATE_QUERY_FILTER,
  //     null,
  //     generateHeader(),
  //     data,
  //     dispatch
  //   ).then((res) => {
  //     setShowshowFilterPopup(false);
  //     setfilterValues([]);
  //     setfilterArrayname("select");
  //     setQueryNameString({
  //       ...queryNameString,
  //       name_string: "",
  //     });
  //     setIsUpdate(false);
  //     // getRoleList();
  //     let obj = {};
  //     obj.coloum = "";
  //     obj.value = "";
  //     obj.condtions = "";
  //     obj.one = "";
  //     setfilterValues((current) => [...current, obj]);
  //   });
  // };

  // const saveFilterAPIValue = (data) => {
  //   POSTAPI(
  //     false,
  //     INSERT_QUERY_FILTER,
  //     null,
  //     generateHeader(),
  //     data,
  //     dispatch
  //   ).then((res) => {
  //     if (res.data.out_parameters.out_ret_code === 0) {
  //       setShowshowFilterPopup(false);
  //       setfilterValues([]);
  //       // getRoleList();
  //       setQueryNameString({
  //         ...queryNameString,
  //         name_string: "",
  //       });
  //       let obj = {};
  //       obj.coloum = "";
  //       obj.value = "";
  //       obj.condtions = "";
  //       obj.one = "";
  //       setfilterValues((current) => [...current, obj]);
  //     } else {
  //       dispatch(
  //         setNewMessage({
  //           message: res.data.out_parameters.out_error_msg,
  //           type: "Error",
  //           show: true,
  //         })
  //       );
  //     }
  //   });
  // };

  // let submitFilterValue = (filterQueryString = null) => {
  //   let filterString = "";
  //   filterValues.forEach((data, index) => {
  //     let coloumdata = data.coloum;
  //     if (coloumdata === "") {
  //       coloumdata = "a.db_role_name";
  //     }
  //     let radioInput = data.one;

  //     if (radioInput === "") {
  //       radioInput = "AND";
  //     }

  //     var arr = coloumdata.split(".");
  //     var coloumName = arr.pop();
  //     var alais = arr.join(".");

  //     const value = data.value.trim();

  //     const condtion = data.condtions;
  //     let queryCondtion = "";
  //     if (condtion === "is_equal_to") {
  //       queryCondtion = "=";
  //     } else if (condtion === "is_not_equal_to") {
  //       queryCondtion = "<>";
  //     } else if (condtion === "contains") {
  //       queryCondtion = "%";
  //     } else if (condtion === "Does_not_contain") {
  //       queryCondtion = "<>%";
  //     } else if (condtion === "is_empty") {
  //       queryCondtion = "NULL";
  //     } else if (condtion === "is_not_empty") {
  //       queryCondtion = "IS NOT NULL";
  //     } else {
  //       queryCondtion = "%";
  //     }

  //     let val = "";
  //     if (queryCondtion === "NULL") {
  //       val = `${alais + "." + coloumName + "= ''"}`;
  //     } else if (queryCondtion === "IS NOT NULL") {
  //       val = `${alais + "." + coloumName + "<> ''"}`;
  //     } else if (queryCondtion === "%") {
  //       val = `${
  //         alais +
  //         "." +
  //         coloumName +
  //         " " +
  //         "LIKE" +
  //         " " +
  //         '"' +
  //         "%" +
  //         value +
  //         "%" +
  //         '"'
  //       }`;
  //     } else if (queryCondtion === "<>%") {
  //       val = `${
  //         alais +
  //         "." +
  //         coloumName +
  //         " " +
  //         "NOT LIKE" +
  //         " " +
  //         '"' +
  //         "%" +
  //         value +
  //         "%" +
  //         '"'
  //       }`;
  //     } else {
  //       val = `${alais + "." + coloumName + queryCondtion + '"' + value + '"'}`;
  //     }

  //     if (index === 0) {
  //       filterString += "AND" + " " + val + " " + radioInput;
  //     } else {
  //       filterString += " " + val + " " + radioInput;
  //     }
  //   });
  //   var lastCondtion = filterString.split(" ").pop();

  //   if (lastCondtion === "AND" || lastCondtion === "OR") {
  //     var lastIndex = filterString.lastIndexOf(" ");
  //     filterString = filterString.substring(0, lastIndex);

  //     var elementDataresult = filterString.substr(
  //       filterString.indexOf(" ") + 1
  //     );
  //     if (elementDataresult.indexOf("OR") > -1) {
  //       const resulttest = elementDataresult.indexOf("OR");
  //       const regxtest = elementDataresult.substring(resulttest);
  //       var firstSpace = regxtest.indexOf(" ");
  //       var newStr = regxtest.slice(firstSpace);
  //       filterString =
  //         "AND" +
  //         " " +
  //         "(" +
  //         "(" +
  //         elementDataresult.substring(0, resulttest) +
  //         ")" +
  //         " " +
  //         "OR" +
  //         " " +
  //         "(" +
  //         newStr +
  //         ")" +
  //         ")";
  //     } else {
  //       filterString = "AND" + " " + "(" + elementDataresult + ")";
  //     }
  //   }

  //   filterQueryString !== null
  //     ? (apiCallData.in_filter = filterQueryString)
  //     : (apiCallData.in_filter = filterString);

  //   setisShowQuery(true);
  //   setfilterApiData(apiCallData);

  //   setShowshowFilterPopup(!showFilterPopup);
  //   // Action.getRoleList(apiCallData);
  //   // filterQueryString !== null
  //   //   ? Action.getRoleList(filterQueryString)
  //   //   : Action.getRoleList(filterString);
  // };

  // const oncloseFilter = () => {
  //   // setErrorObj({});
  //   filterValues.forEach((data) => {
  //     if (
  //       (data.coloum !== "" || data.condtions !== "" || data.value) &&
  //       !isupdate
  //     ) {

  //       // TODO: UNSAVED CHANGES
  //       // dispatch(
  //       //   setUnSavedChangesPopup({
  //       //     ...unsavedPopup,
  //       //     isVisible: true,
  //       //     callbackFn: () => {
  //       //       onUnsavedChanges();
  //       //     },
  //       //   })
  //       // );
  //                 onUnsavedChanges();
  //     } else {
  //       setShowshowFilterPopup(!showFilterPopup);
  //       setFilterQueryList();
  //       setfilterValues([{ coloum: "", value: "", condtions: "", one: "" }]);
  //       setErrorObj({});
  //     }
  //   });
  // };

  // const handleUnSavedPopup = (action) => {
  //   // dispatch(setUnSavedChangesPopup({ isUnSavedChangePopup: true }));
  //   setConfirmationPopup({
  //     ...confirmationPopup,
  //     isVisible: true,
  //     title: "Unsaved Changes",
  //     desc: UNSAVEQUERY_TEXT_WARNING,
  //     type: "UNSAVED_CHANGES",
  //     actionBtn: UNSAVE_BTN_ACTIONS(
  //       onUnsavedChanges,
  //       action === "outSideClick"
  //         ? () => {
  //             redirectUrl(reduxData.redirectionUrl);
  //           }
  //         : onCancelConfirmPopup
  //     ),
  //     action: "UnSave",
  //   });
  // };

  const onCancelConfirm = (action) => {
    setConfirmationPopup({
      ...confirmationPopup,
      isVisible: false,
      type: "",
      actionBtn: [],
      data: {},
      action: "",
    });
  };

  // const onCancelConfirmPopup = (action) => {
  //   setConfirmationPopup({
  //     ...confirmationPopup,
  //     isVisible: false,
  //     type: "",
  //     actionBtn: [],
  //     data: {},
  //     action: "",
  //   });
  // };

  // const onUnsavedChanges = () => {
  //   setShowshowFilterPopup(!showFilterPopup);
  //   setFilterQueryList();
  //   setfilterValues([{ coloum: "", value: "", condtions: "", one: "" }]);
  //   apiCallData.in_filter = null;
  //   setfilterApiData(apiCallData);
  //   setisShowQuery(true);
  //   setErrorObj({});
  //   setConfirmationPopup({
  //     ...confirmationPopup,
  //     isVisible: false,
  //     type: "",
  //     actionBtn: [],
  //     data: {},
  //     action: "",
  //   });
  // };

  // const handleChangeFilterQuery = (e) => {
  //   if (e.target.value !== "select") {
  //     setfilterValues([]);
  //     getFilterQueryList(e.target.value);
  //     setIsUpdate(true);
  //     setErrorObj({});
  //   } else {
  //     setfilterValues([]);
  //     setfilterArrayname("select");
  //     getFilterQueryList(e.target.value);
  //     Action.getRoleList(apiCallData);
  //   }
  // };

  // const getFilterQueryList = (queryData = null) => {
  //   if (queryData === "select") {
  //     let obj = {};
  //     obj.coloum = "";
  //     obj.value = "";
  //     obj.condtions = "";
  //     obj.one = "";
  //     setfilterValues((current) => [...current, obj]);
  //     setIsUpdate(false);
  //     setQueryNameString({
  //       ...queryNameString,
  //       name_string: "",
  //     });
  //   }
  //   let val = `${
  //     "a.grid_query_name" +
  //     " = '" +
  //     queryData +
  //     "'" +
  //     " " +
  //     "AND" +
  //     " " +
  //     "a.user_key" +
  //     " = '" +
  //     cookiesData.out_user_id.toString()
  //   }`;
  //   let userId = `${"user_key" + " = '" + cookiesData.out_user_id.toString()}`;
  //   let queryName = "AND " + val + "'";
  //   setfilterArrayname(queryData);
  //   let data = {
  //     in_filter: queryName,
  //     in_grid_table_name: dbObjectCode,
  //     in_sort_number: 1,
  //     in_sort_order: "DESC",
  //     in_page_row_offset: 0,
  //     in_page_rows: 9999,
  //     in_user_id: cookiesData.out_user_id.toString(),
  //   };
  //   POSTAPI(
  //     false,
  //     GET_QUERY_FILTER_LIST,
  //     null,
  //     generateHeader(),
  //     data,
  //     dispatch
  //   ).then((res) => {
  //     if (res && res.data) {
  //       if (res.data.out_parameters.out_total_rows !== 0) {
  //         if (res.data.out_parameters.out_ret_code === 0) {
  //           var data = res.data.row_result;
  //           // submitFilterValue
  //           if (data) {
  //             let resultString = data[0].query_string;
  //             var elementDataresult = resultString.substr(
  //               resultString.indexOf(" ") + 1
  //             );
  //             if (elementDataresult.indexOf("OR") > -1) {
  //               const resulttest = elementDataresult.indexOf("OR");
  //               const regxtest = elementDataresult.substring(resulttest);
  //               var firstSpace = regxtest.indexOf(" ");
  //               var newStr = regxtest.slice(firstSpace);
  //               resultString =
  //                 "AND" +
  //                 " " +
  //                 "(" +
  //                 "(" +
  //                 elementDataresult.substring(0, resulttest) +
  //                 ")" +
  //                 " " +
  //                 "OR" +
  //                 " " +
  //                 "(" +
  //                 newStr +
  //                 ")" +
  //                 ")";
  //             } else {
  //               resultString = "AND" + " " + "(" + elementDataresult + ")";
  //             }
  //             //const regx =  /([\s\S]+?(AND|OR))|([\w.=<>!'\s]+)/g;
  //             //([\s\S]+?(AND|OR))|([\w.=<>!%'\s]+)
  //             const regx = /AND|OR/g;

  //             const array = [...elementDataresult.matchAll(regx)];

  //             const result = elementDataresult.split(regx);
  //             let stringArray = [];

  //             result.map((element, index) => {
  //               if (element.length > 0) {
  //                 if (array.length > 0 && array[index] !== undefined) {
  //                   stringArray.push(element + array[index]);
  //                 } else {
  //                   stringArray.push(element);
  //                 }
  //               }
  //             });
  //             setfilterMstrKey(data[0].grid_query_filter_mstr_key);
  //             stringArray.map((element) => {
  //               let querycondtions = "";
  //               let textValue = false;
  //               let condtionsName = "is_equal_to";
  //               if (element.match("= ''") !== null) {
  //                 condtionsName = "is_empty";
  //               } else if (
  //                 element.match("=") !== null &&
  //                 condtionsName !== "is_empty"
  //               ) {
  //                 querycondtions = element.match("=")[0];
  //                 condtionsName = "is_equal_to";
  //               } else if (element.match("<> ''") !== null) {
  //                 querycondtions = "NOT NULL";
  //                 condtionsName = "is_not_empty";
  //               } else if (element.match("NOT LIKE") !== null) {
  //                 querycondtions = element.match("NOT LIKE")[0];
  //               } else if (element.match("LIKE") !== null) {
  //                 querycondtions = element.match("LIKE")[0];
  //               } else if (
  //                 element.match("<>") !== null &&
  //                 condtionsName !== "is_not_empty"
  //               ) {
  //                 querycondtions = element.match("<>")[0];
  //               } else {
  //                 querycondtions = "NULL";
  //               }
  //               let filterValue = "";
  //               let coloumValue = element.substring(
  //                 0,
  //                 element.indexOf(querycondtions)
  //               );

  //               if (querycondtions == "<>") {
  //                 condtionsName = "is_not_equal_to";
  //               } else if (querycondtions === "LIKE") {
  //                 condtionsName = "contains";
  //               } else if (querycondtions === "NOT LIKE") {
  //                 condtionsName = "Does_not_contain";
  //               } else if (querycondtions === "NULL") {
  //                 condtionsName = "is_empty";
  //                 filterValue = "";
  //                 coloumValue = element.replace("''", "");
  //                 textValue = true;
  //               }

  //               if (condtionsName === "is_not_empty") {
  //                 filterValue = "";
  //               } else if (condtionsName !== "is_empty") {
  //                 var stringIndex = element.slice(
  //                   element.indexOf(querycondtions)
  //                 );
  //                 var stringValue = stringIndex.replace(querycondtions, "");

  //                 filterValue = stringValue.replace(/['"]+/g, "");
  //               }
  //               var radioInput = element.split(" ").pop();
  //               let filterValuestring = "";
  //               let filterStringValue = "";
  //               if (filterValue.indexOf("%") > -1) {
  //                 let filterValuestringreplace = filterValue.replace("%", "");
  //                 filterValuestring = filterValuestringreplace.replace("%", "");
  //               } else {
  //                 filterValuestring = filterValue;
  //               }

  //               if (filterValuestring.indexOf("AND") > -1) {
  //                 filterStringValue = filterValuestring.replace("AND", "");
  //               } else if (filterValuestring.indexOf("OR") > -1) {
  //                 filterStringValue = filterValuestring.replace("OR", "");
  //               } else {
  //                 filterStringValue = filterValuestring;
  //               }
  //               let radioInputValue = "";
  //               if (radioInput === "AND" || radioInput === "OR") {
  //                 radioInputValue = radioInput;
  //               }

  //               let coloumValuestring = "";

  //               if (coloumValue.indexOf("AND") > -1) {
  //                 coloumValuestring = coloumValue.replace("AND", "");
  //               } else {
  //                 coloumValuestring = coloumValue;
  //               }
  //               let obj = {};

  //               obj.coloum = coloumValuestring.trim();
  //               obj.value = filterStringValue.trim();
  //               obj.condtions = condtionsName;
  //               obj.one = radioInputValue;
  //               obj.textvalue = textValue;
  //               setfilterValues((current) => [...current, obj]);
  //               return 0;
  //             });
  //             submitFilterValue(resultString);
  //           }
  //         } else {
  //           // dispatch(
  //           //   setNewMessage({
  //           //     message: res.data.out_parameters.out_error_msg,
  //           //     type: "Error",
  //           //     show: true,
  //           //   })
  //           // );
  //         }
  //       } else {
  //         // dispatch(
  //         //   setNewMessage({
  //         //     message: res.data.out_parameters.out_error_msg,
  //         //     type: "Error",
  //         //     show: true,
  //         //   })
  //         // );
  //       }
  //     }
  //   });
  // };

  // const handleChangeQueryName = (e) => {
  //   // let value =  e.target.value;
  //   // if(/^\s/.test(input.value))
  //   // input.value = '';
  //   setQueryNameString({
  //     ...queryNameString,
  //     name_string: e.target.value.trimStart(),
  //   });
  //   if (e.target.value !== null) {
  //     setErrorObj({ ...errorObj, name_string: "" });
  //   }
  // };

  // let handleChange = (i, e) => {
  //   let newFormValues = [...filterValues];
  //   newFormValues[i][e.target.name] = e.target.value;

  //   if (e.target.name === "value") {
  //     newFormValues[i]["value"] = e.target.value.trimStart();
  //   }
  //   if (e.target.name === "condtions") {
  //     newFormValues[i]["textvalue"] = false;
  //     setfilterValues(newFormValues);
  //     if (e.target.value === "is_empty" || e.target.value === "is_not_empty") {
  //       let formValues = [...filterValues];
  //       formValues[i]["value"] = "";
  //       formValues[i]["textvalue"] = true;
  //       setfilterValues(formValues);
  //     }
  //   } else {
  //     setfilterValues(newFormValues);
  //   }
  // };
  const addNewRole = (e) => {
    setNewConfig(!isAddNewConfig);
    getRoleTypeKeyList();
    e.preventDefault();
  };

  const handleFieldChange = (e) => {
    const name = e.name ? e.name : e.target.name;
    if (e.value !== null && name !== "in_db_role_type_key") {
      setFormFields({ ...formFields, [name]: e.value });
      setErrorObj({ ...errorObj, [name]: "" });
    } else {
      setFormFields({
        ...formFields,
        in_db_role_type_key: e.value.db_role_type_name,
      });
    }
  };
  return (
    <>
      <div>
        <div className="Main customtablepage">
          <div className="content">
            <div className="content-head">
              <HeaderPage pageProps={pageProps} headerConfig={headerConfig} />
            </div>
            <div className="content-body">
              <div className="dtu-file-container rolespage">
                <RoleTableData
                  setColumnCheck={setColumnCheck}
                  isCheckColumn={isCheckColumn}
                  isAPICall={isAPICall}
                  setAPICall={setAPICall}
                  setShow={setShow}
                  setExportDataClick={setExportDataClick}
                  exportDataClick={exportDataClick}
                  columnsData={columnsData}
                  setColumnsData={setColumnsData}
                  selectedRowId={selectedRowId}
                  setSelectedRowId={setSelectedRowId}
                  loadingState={loadingState}
                  setLoadingState={setLoadingState}
                  setDataList={setDataList}
                  editClick={editClick}
                  seteditClick={seteditClick}
                  cancelClick={cancelClick}
                  setcancelClick={setcancelClick}
                  setbuttonClick={setbuttonClick}
                  buttonClick={buttonClick}
                  setIsFilterClick={setIsFilterClick}
                  isFilterClick={isFilterClick}
                  // setfilterArray={setfilterArray}
                  setisShowQuery={setisShowQuery}
                  isShowQuery={isShowQuery}
                 // filterApiData={filterApiData}
                  addclick={list.addClick}
                  setaddClick={list.setaddClick}
                  saveClick={saveClick}
                  roleDataObj={roleDataObj}
                  setsaveClick={setsaveClick}
                  setcancelbuttonClick={setcancelbuttonClick}
                  cancelbuttonClick={cancelbuttonClick}
                  isShow={isShow}
                  roleEdit={roleEdit}
                  setRoleEdit={setRoleEdit}
                  pageProps={pageProps}
                  dataList={dataList}
                />
              </div>
            </div>
          </div>
          <DialogComponent
            title={confirmationPopup.title}
            onCancelConfirm={onCancelConfirm}
            width={"450"}
            height={"auto"}
            disabled={loadingState.isDeleteLoad}
            className={"Dialog-Delete"}
            desc={confirmationPopup.desc}
            visible={confirmationPopup.isVisible}
            actionBtn={confirmationPopup.actionBtn}
            titleClassName={
              confirmationPopup.action === "Delete"
                ? "delete-confirm"
                : "unSave-confirm"
            }
          />
        </div>
      </div>
    </>
  );
};

export default React.memo(Role);
