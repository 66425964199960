import AxiosInterceptor from "./axios.interceptors";

export const axios = AxiosInterceptor();

export const axiosGet = (baseUrl, endPoint, config) => {
  return axios.get(`${baseUrl}/${endPoint}`, config);
};
export const axiosPost = (baseUrl, endPoint, data, config) => {
  return axios.post(`${baseUrl}/${endPoint}`, data, config);
};
export const axiosPut = (baseUrl, endPoint, data, config) => {
  return axios.put(`${baseUrl}/${endPoint}`, data, config);
};
export const axiosPatch = (baseUrl, endPoint, data, config) => {
  return axios.put(`${baseUrl}/${endPoint}`, data, config);
};
export const axiosDelete = (baseUrl, endPoint, config) => {
  return axios.delete(`${baseUrl}/${endPoint}`, config);
};
