// import { mapDashboardData } from "src/store/dashboard.store";
import {
    chartTypeConfig,
    chartThemeConfig,
} from "src/components/chart/chart.const";

//const { categoryAxis, seriesData, seriesName } = mapDashboardData;

export const widgetLayoutFormation = ({ podDetailList, itemConfig }) => {
    const items = [];
    podDetailList?.forEach((podDetail, index) => {
        items.push(itemConfig(podDetail, index));
    });
    return {
        items,
    };
};

export const formatNumber = (event) => {
    let formattedNumber = event?.value?.toFixed(2) || 0;
    if (formattedNumber.endsWith(".00")) {
        formattedNumber = formattedNumber.slice(0, -3);
    }
    formattedNumber = parseFloat(formattedNumber).toLocaleString();
    return formattedNumber;
};

export const shortenLargeNumber = (num, digits) => {
    var units = ["k", "M", "G", "T", "P", "E", "Z", "Y"],
        decimal;
    for (var i = units.length - 1; i >= 0; i--) {
        decimal = Math.pow(1000, i + 1);
        if (num <= -decimal || num >= decimal) {
            return +(num / decimal).toFixed(digits) + units[i];
        }
    }
    return num;
};

export const shortLabel = (event) => {
    if (event?.value) {
        return shortenLargeNumber(event?.value, 2);
    }
    if (event.value.length > 15) {
        const value = event.value.substring(0, 15);
        return `${value}...`;
    }
    return event?.value || "";
};

export const generateCategoryChartData = (
    resultData,
    datasetData,
    tabThemeColors,
    colorIdx
) => {
    //get result data of current dataset
    const datasetResultData = resultData.filter(
        (o) => o.dataset_index === datasetData.dashboard_dataset_num
    );

    //get unique series from current dataset result data
    const uniqueSeries = [
        ...new Set(datasetResultData.map((item) => item.series_name)),
    ];

    //get unique categories from current dataset result data
    const uniqueCategories = [
        ...new Set(datasetResultData.map((item) => item.category_axis)),
    ];

    let seriesObj = {};

    uniqueSeries?.forEach((series) => {
        let seriesDataArr = [];

        if (colorIdx + 1 > tabThemeColors?.length) {
            colorIdx = 0;
        }

        uniqueCategories?.forEach((category) => {
            const seriesData = datasetResultData.find(
                (o) => o.series_name === series && o.category_axis === category
            );

            seriesDataArr = [...seriesDataArr, seriesData?.series_data || 0];

            seriesObj = {
                ...seriesObj,
                [series]: {
                    name: series,
                    visible: true,
                    color: tabThemeColors[colorIdx],
                    data: [...seriesDataArr],
                },
            };
        });
        colorIdx++;
    });

    return { seriesObj, colorIdx };
};

const appendColorToRoundChart = (data, targetEle, tabThemeColors) => {
    let uniqueList = [...new Set(data.map((item) => item?.[targetEle]))];
    let colorIdx = 0;

    const result = data.map((o, i) => {
        if (colorIdx + 1 > tabThemeColors?.length) {
            colorIdx = 0;
        }

        o.visible = true;
        o.color = tabThemeColors[colorIdx];
        o.key = i;

        colorIdx++;

        return o;
    });

    return result;

    /*
    uniqueList.forEach((sourceEle) => {
        if (colorIdx + 1 > tabThemeColors?.length) {
            colorIdx = 0;
        }

        data = data.reduce((acc, obj) => {
            if (obj?.[targetEle] === sourceEle) {
                //clone the object and modify the property
                const modifiedObj = {
                    ...obj,
                    visible: true,
                    color: tabThemeColors[colorIdx],
                };
                acc.push(modifiedObj);
            } else {
                // No modification needed, push the original object
                acc.push(obj);
            }
            return acc;
        }, []);

        colorIdx++;
    });

    console.log("after data :: ", data);
    return data;
    */
};

export const dataParser = ({ data, dataSetData, chartTheme }) => {
    if (!data && data?.length > 0) {
        return {
            categoryAxisList: [],
            chartData: [],
            chartBackgroundColor: "",
        };
    }

    let categoryAxisList = [];
    let chartData = [];

    const pieKey = chartTypeConfig.pie.key;
    const bubbleKey = chartTypeConfig.bubble.key;

    if (dataSetData?.[0]?.chart_type?.toLowerCase() === bubbleKey) {
        categoryAxisList = [...new Set(data.map((item) => item.category))];
    } else {
        categoryAxisList = [...new Set(data.map((item) => item.category_axis))];
    }

    const tabThemeConfig = chartThemeConfig.find(
        (o) => o.value.toLocaleLowerCase() === chartTheme.toLowerCase()
    );

    const tabThemeColors = tabThemeConfig?.color;
    const chartBackgroundColor = tabThemeConfig?.background || "";

    let colorIdx = 0;

    dataSetData?.forEach((dataSet) => {
        let seriesObjValues = [];
        const dataSetChartType = dataSet?.chart_type?.toLowerCase();

        if ([pieKey, bubbleKey].includes(dataSetChartType)) {
            seriesObjValues = appendColorToRoundChart(
                data,
                chartTypeConfig[dataSetChartType]?.name,
                tabThemeColors
            );
        } else {
            const seriesData = generateCategoryChartData(
                data,
                dataSet,
                tabThemeColors,
                colorIdx
            );

            const seriesObj = seriesData?.seriesObj;
            colorIdx = seriesData?.colorIdx;

            if (seriesObj && Object.values(seriesObj)?.length) {
                seriesObjValues = Object.values(seriesObj);
            }
        }

        chartData = [
            ...chartData,
            {
                data: seriesObjValues,
                isStacked: dataSet?.is_stacked,
                chartType: dataSetChartType,
            },
        ];
    });

    return {
        categoryAxisList,
        chartData,
        chartBackgroundColor,
    };
};
