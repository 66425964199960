/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import DataTable from "../../components/dataTable";
import { GridColumn as Column, getSelectedState } from "@progress/kendo-react-grid";
import { DialogComponent } from "../../components/dialog";
import { getUserCredential, getTenantCookieData } from "../../lib/cookies";
import { columnMenuProps } from "../../components/customColumnMenu";
import Popup from "../../components/customPopup";
import { FieldWrapper, Form, FormElement } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { Error, Label } from "@progress/kendo-react-labels";
import CustomDropdown from "../../components/customDropdown";
import { getfilterDataString, changeCancelBtnState, changeUpdateBtnState } from "../../utils/utils";
import { HeaderPage } from "../base/headerPage";
import { DEFAULT_SORT } from "../../constants/grid";
import { LoadingPanel } from "../../components/loadingPanel";
import { setNewMessage } from "../../redux/actions/setting-actions";
import { useDispatch } from "react-redux";
import { DELETE_BTN_ACTIONS, pattern } from "../../constants/constants";
import { CellRender, RowRender } from "../../components/renderers";
import CustomFieldApi from "./manageCustomFieldsService";
import { getter } from "@progress/kendo-react-common";
import { useHistory } from "react-router-dom";
import MessageSchema from "../../utils/messageSchema";
import { useDocumentClickEventListener } from "../../lib/hooks/custom-common-hooks";
import { DropDownList } from "@progress/kendo-react-dropdowns";
// import { unSaveChangesPopupSelector } from "src/redux/selectors/custom-selector";
import { Window } from "@progress/kendo-react-dialogs";

const SELECTED_FIELD = "selected";
const editField = "inEdit";
const DATA_ITEM_KEY = "custom_fld_col_mstr_key";
const idGetter = getter(DATA_ITEM_KEY);

const initialFormFields = {
  field_desc: "",
  field_name: "",
};

const ManageCustomField = (restProps) => {
  const dispatch = useDispatch();
  // const { unsavedPopup } = useSelector(unSaveChangesPopupSelector, shallowEqual);
  const cookiesData = getUserCredential();
  const defaultPageSize = getTenantCookieData("record_per_page");
  const [list, Action] = CustomFieldApi();
  const [deleteSelectedIds, setDeleteSelectedIds] = React.useState([]);
  const [errorObj, setErrorObj] = React.useState({});
  const [addNew, setAddNew] = React.useState(false);
  const [selectedState, setSelectedState] = React.useState({});
  const [selectedRowId, setSelectedRowId] = React.useState("");
  const [isAPICall, setAPICall] = React.useState(false);
  const [formFields, setFormFields] = React.useState({
    ...initialFormFields,
  });
  const [isShow, setShow] = React.useState({
    dtuLogs: false,
    deleteSelectedRow: false,
    sendEmails: false,
  });
  const [modal, setModal] = React.useState(true)
  const [stageChange, setStateChange] = React.useState('DEFAULT')

  const [confirmationPopup, setConfirmationPopup] = React.useState({
    title: "Delete",
    desc: `<p><strong>NOTE:</strong> Deleting a custom field will not remove it from any rules, rule filters, formulas, system functions or DTU files. You will have to remove the custom field from those areas to completely delete the field from the system.</p> <p>Are you sure you want to delete this record?</p>`,
    isVisible: false,
    type: "",
    actionBtn: [],
    data: {},
    action: "Delete",
  });
  const [fieldTypeAddUpdate, setFieldTypeAddUpdate] = React.useState(false);
  const [filterData, setFilterData] = React.useState({
    isSort: false,
    isFilter: false,
    data: "",
    in_sort_number: DEFAULT_SORT.number,
    sort_order: DEFAULT_SORT.order,
  });
  const [columnsData, setColumnsData] = React.useState([]);
  const [isCheckColumn, setColumnCheck] = React.useState(false);
  const [gridColumns, setGridColumns] = React.useState([]);

  const dataTypeDropDownRef = React.useRef(null);
  const unitTypeDropdownRef = React.useRef(null);
  const baseDropDownRef = React.useRef(null);
  const addRef = React.useRef(null);
  let popupRef = React.useRef();
  // Get APIdata from service file
  const {
    data,
    columns,
    dataCount,
    setData,
    editData,
    originalData,
    isAPICalling,
    setEditData,
    setAPICallingState,
    initialLoading,
    visible,
    setVisible,
    isRefreshLoading,
    unitTypeDataSource,
    dataTypeSource,
    customFieldMasterSource,
    addUnitTypeFields,
    setAddUnitTypeFields,
    addDataTypeFields,
    setAddDataTypeFields,
    addBaseTableFields,
    setAddBaseTableFields,
    loadingState,
  } = list;
  useDocumentClickEventListener(addNew, setAddNew, popupRef);

  const [pageData, setPage] = React.useState({
    skip: 0,
    take: defaultPageSize.recordTake,
  });

  const [pageSize, setPageSize] = React.useState(defaultPageSize);
  const [resetSort, setResetSort] = React.useState(false);
  // intial APIdata

  const history = useHistory();

  const apiCallData = {
    in_tenant_id: cookiesData.out_tenant_id,
    in_filter: null,
    in_sort_number: 1,
    in_sort_order: "DESC",
    in_page_row_offset: 0,
    in_page_rows: defaultPageSize.recordTake,
    in_load_type: "data-reload",
  };

  if (document.getElementById("grid-incell-cancel")) {
    document.getElementById("grid-incell-cancel").onclick = function (e) {
      originalData.map((item) => {
        item.inEdit = false;
        item.selected = false;
        return 0;
      });
      setData(originalData);
      setErrorObj({});
      //disable in-cell update and cancel button
      changeCancelBtnState("disable");
      changeUpdateBtnState("disable");
    };
  }

  if (document.getElementById("grid-incell-update")) {
    document.getElementById("grid-incell-update").onclick = function (e) {
      handleUpdate();
    };
  }

  React.useEffect(() => {
    if (columns.length > 0) {
      setColumnsData(columns);
      setGridColumns(columns);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);

  React.useEffect(() => {
    getAddList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (unitTypeDataSource.length > 0 && dataTypeSource.length > 0 && customFieldMasterSource.length > 0) {
      list.setLoadState({
        ...loadingState,
        initLoading: false
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitTypeDataSource, dataTypeSource, customFieldMasterSource])

  const getAddList = async () => {
    await Action.getUnitTypeData();
    await Action.getDataTypeList();
    let reqData = {
      in_tenant_id: cookiesData.out_tenant_id,
      in_filter: null,
      in_sort_number: 1,
      in_sort_order: "ASC",
    };
    await Action.getCustomFieldsMaster(reqData, dispatch, history);
    changeUpdateBtnState();
  };

  const getFilterStringBasedOnModule = () => {
    let filterString = "";
    switch (history.location.pathname) {
      case "/events":
        filterString = " AND a.custom_fld_table_name = 'vw_event_master' ";
        break;
      case "/prv_pos":
        filterString = 'AND a.custom_fld_table_name IN ("vw_provider_master","vw_org_position")'
        break;
      default:
        break;
    }
    return filterString;
  };

  const getBaseTableName = (baseTableName) => {
    let tableName = "";
    switch (history.location.pathname) {
      case "/events":
        tableName = "vw_event_master";
        break;
      case "/prv_pos":
        tableName = baseTableName;
        break;
      default:
        tableName = "vw_event_master";
        break;
    }
    return tableName;
  };

  const getCustomField = () => {
    let tableName = "";
    switch (history.location.pathname) {
      case "/events":
        tableName = "events";
        break;
      case "/prv_pos":
        tableName = "provider";
        break;
      default:
        break;
    }
    return tableName;
  };

  React.useEffect(() => {
    const dataVal = {
      ...apiCallData,
      in_load_type: "grid-reload",
      in_filter: getFilterStringBasedOnModule(),
    };
    Action.getDataList(dataVal);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (isCheckColumn) {
      setGridColumns(columnsData);
      setColumnCheck(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheckColumn]);

  React.useEffect(() => {
    if (list.loadingState.isSaveData) {
      setAPICall(true);
    }
  }, [list.loadingState.isSaveData]);

  React.useEffect(() => {
    if (isAPICall) {
      setAPICall(false);
      onRefreshClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAPICall]);

  React.useEffect(() => {
    if (isShow.deleteRows) {
      remove();
      setShow({ ...isShow, deleteRows: false });
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShow.deleteRows]);

  React.useEffect(() => {
    if (list.loadingState.isDeleteData) {
      list.setLoadState({ ...list.loadingState, isDeleteData: false });
    } else {
      setConfirmationPopup({
        ...confirmationPopup,
        title: "Delete",
        isVisible: false,
        type: "",
        actionBtn: [],
        data: {},
        action: "Delete",
      });
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list.loadingState.isDeleteData]);

  React.useEffect(() => {
    if (fieldTypeAddUpdate === false) {
      changeCancelBtnState("disable");
      changeUpdateBtnState("disable");
    }
    if (fieldTypeAddUpdate === true) {
      changeCancelBtnState("enable");
      changeUpdateBtnState("enable");
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldTypeAddUpdate, columns]);

  React.useEffect(() => {
    if (visible) {
      changeCancelBtnState("disable");
      changeUpdateBtnState("disable");
      const parent = document.querySelector("#content-" + headerConfig.id);
      if (parent) {
        const deleteRows = parent.querySelector(".delete-rows");
        deleteRows && deleteRows.disableKendoMenu();
        if (selectedRowId !== "") {
          deleteRows && deleteRows.enableKendoMenu();
        }
      }
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const getInFilter = () => {
    return filterData.isFilter
      ? filterData.data + getFilterStringBasedOnModule()
      : getFilterStringBasedOnModule();
  };

  const getInSortNumber = () => {
    return filterData.isSort ? filterData.in_sort_number : DEFAULT_SORT.number;
  };

  const getInSortOrder = () => {
    return filterData.isSort ? filterData.sort_order : DEFAULT_SORT.order;
  };

  const getPageTake = () => {
    return pageData.take;
  };

  const getPageSkip = () => {
    return pageData.skip;
  };
  const addNewData = () => {
    setAddNew(!addNew);
    setFormFields({ ...initialFormFields });
    if (customFieldMasterSource.length) {
      setAddBaseTableFields({
        baseTableList: customFieldMasterSource,
        baseTableValue: customFieldMasterSource[0],
      });
    }
    if (dataTypeSource.length) {
      setAddDataTypeFields({
        dataTypeList: dataTypeSource,
        dataTypeValue: dataTypeSource[0],
      });
    }
    if (unitTypeDataSource.length) {
      setAddUnitTypeFields({
        unitTypeValue: {
          id: "",
          text: "",
        },
        unitTypeData: unitTypeDataSource,
      });
    }
  };

  const remove = (ids) => {
    setConfirmationPopup({
      ...confirmationPopup,
      isVisible: true,
      type: "DELETE_CONFIRM",
      actionBtn: DELETE_BTN_ACTIONS(onCancelConfirm, onDeleteConfirm),
      data: [],
      action: "Delete",
    });
  };

  const customMsgForManageCustomField = (filteredData, customFieldNames) => {
    let msg =
      filteredData.length +
      " Custom Fields (" +
      `${customFieldNames.toString()}` +
      ") out of " +
      deleteSelectedIds.length +
      " as cannot delete the selected field as it currently contains data values.";
    dispatch(
      setNewMessage({
        message: msg,
        type: "Error",
        show: true,
      })
    );
  };

  const getRefreshData = async () => {
    const dataVal = {
      ...apiCallData,
      in_filter: getInFilter(),
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
      in_page_row_offset: getPageSkip(),
      in_page_rows: getPageTake(),
    };
    let response = Action.getDataList(dataVal);
    if (response) {
      setDeleteSelectedIds([]);
      setSelectedRowId("");
      setSelectedState({});
      setErrorObj({});
      changeCancelBtnState()
      changeUpdateBtnState()
      setResetSort(false)
    }
  };

  // Delete event type
  const onDeleteConfirm = async (key) => {
    const deleteArray = deleteSelectedIds.map((obj2) => {
      const obj1 = data.find((elem) => elem.custom_fld_col_mstr_key === obj2.id);
      return { id: obj2.id, customFieldName: obj1?.custom_fld_col_name || '', dataFlag: obj1?.data_flag };
    });

    setConfirmationPopup({
      ...confirmationPopup,
      isVisible: false,
      type: "",
      actionBtn: [],
      data: {},
      action: "",
    });
    let usedDataValues = deleteArray.filter((x) => x.dataFlag === 0);
    let unUsedDataValues = deleteArray.filter((x) => x.dataFlag === 1);
    let customFieldNames = deleteArray
      .filter((x) => x.dataFlag === 0)
      .map((obj1) => {
        return obj1.customFieldName;
      });
    let reqData = {
      in_user_id: cookiesData.out_user_id.toString(),
      in_custom_fld_col_mstr_key: deleteArray.filter((x) => x.dataFlag === 1),
      in_tenant_id: cookiesData.out_tenant_id,
    };
    if (unUsedDataValues.length > 0) {
      await Action.deleteDataAsync(reqData, apiCallData).then(function (result) {
        if (result === "success") {
          if (usedDataValues.length > 0) {
            customMsgForManageCustomField(usedDataValues, customFieldNames);
          }
          // if (data.length === 1) {
          //   setFilterData({
          //     ...filterData,
          //     data: "",
          //     isFilter: false,
          //     isSort: false
          //   });
          //   setResetSort(true);
          // }
          getRefreshData();
        }
      });
    } else {
      if (usedDataValues.length === 1) {
        dispatch(
          setNewMessage({
            message: "Cannot delete the selected field as it currently contains data values.",
            type: "Error",
            show: true,
          })
        );
      } else {
        customMsgForManageCustomField(usedDataValues, customFieldNames);
      }
    }
  };

  const onCancelConfirm = (action) => {
    if (!isRefreshLoading) {
      setConfirmationPopup({
        ...confirmationPopup,
        isVisible: false,
        type: "",
        actionBtn: [],
        data: {},
        action: "",
      });

      data.map((item) => {
        item.selected = false;
        return 0;
      });
      setEditData(editData);
      setData([...data]);
      // TODO : UNSAVED CHANGES

      // dispatch(setUnSavedChangesPopup({ ...unsavedPopup, isUnSavedChange: false }));
    }
  };

  const handleValidation = () => {
    let isValid = true;
    if (formFields.field_name.trim() === "") {
      isValid = false;
      errorObj.field_name = MessageSchema.fieldNameRequired;
    } else {
      errorObj.field_name = "";
      if (
        !pattern.test(formFields.field_name) &&
        formFields.field_name.trim() !== ""
      ) {
        isValid = false;
        errorObj.field_name = MessageSchema.customFieldValid;
      }
    }

    if (formFields.field_desc.trim() === "") {
      isValid = false;
      errorObj.field_desc = MessageSchema.fieldDescriptionRequired;
    } else {
      errorObj.field_desc = "";
    }



    setErrorObj((preVState) => ({ ...preVState, errorObj }));
    return isValid;
  };
  const handleUpdateValidation = () => {
    let isValid = true;
    data
      .filter((x) => x.inIteamEdit === true)
      .map((item) => {
        const { custom_fld_col_name, custom_fld_col_desc, rowId } = item;
        let errorObj = {
          custom_fld_col_name: "",
          custom_fld_col_desc: "",
        };
        if (custom_fld_col_name.trim() === "") {
          errorObj.custom_fld_col_name = MessageSchema.fieldNameRequired;
        } else {
          errorObj.custom_fld_col_name = "";
          if (
            !pattern.test(custom_fld_col_name) &&
            custom_fld_col_name.trim() !== ""
          ) {
            isValid = false;
            errorObj.custom_fld_col_name = MessageSchema.customFieldValid;
          }
        }

        if (custom_fld_col_desc.trim() === "") {
          errorObj.custom_fld_col_desc = MessageSchema.fieldDescriptionRequired;
        } else {
          errorObj.custom_fld_col_desc = "";
        }

        let obj = {};
        for (const [key, value] of Object.entries(errorObj)) {
          obj[`${key + rowId}`] = `${value}`;
        }

        setErrorObj(obj);
        for (const [, value] of Object.entries(obj)) {
          if (value !== "") {
            isValid = false;
          }
        }
        return 0;
      });
    return isValid;
  };

  const handleSubmit = () => {
    let valid = handleValidation();
    if (valid) {
      setErrorObj("");

      const bodyData = {
        in_user_id: cookiesData.out_user_id.toString(),
        in_tenant_id: cookiesData.out_tenant_id,
        in_field_name: formFields.field_name,
        in_field_desc: formFields.field_desc,
        in_custom_fld_mstr_key: addBaseTableFields.baseTableValue.id,
        in_data_type_mstr_key: addDataTypeFields.dataTypeValue.id,
        in_system_unit_mstr_key: addUnitTypeFields.unitTypeValue.id,
        in_base_table_name: getBaseTableName(addBaseTableFields.baseTableValue.baseTable),
      };
      Action.addDataAsync(bodyData);
      setAddNew(false);
      setFieldTypeAddUpdate(false);
      setFormFields(initialFormFields);
      onRefreshClick();
    }
  };

  const handleColumnMenu = (columnMenuData, isFilter, isSort) => {
    let index = 1;
    let filterSkip = getPageSkip();
    let filterTake = getPageTake();

    if (isFilter) {
      filterSkip = 0;
      filterTake = pageSize.recordTake;

      //reset pager when filter applies
      setPage({ skip: filterSkip, take: filterTake });
    }

    if (data.length > 0 && columnMenuData[0] !== undefined) {
      let obj = Object.keys(data[0]);
      index = obj.findIndex((x) => x === columnMenuData[0].field) + 1;
    }

    let filterString = isFilter
      ? getfilterDataString(columnMenuData, gridColumns)
      : filterData.data;

    isFilter = false;
    if (filterString !== "") {
      isFilter = true;
    }

    let sortDir =
      filterData.isSort && columnMenuData.length ? filterData.sort_order : DEFAULT_SORT.order;
    let sortNumber =
      filterData.isSort && columnMenuData.length ? filterData.in_sort_number : DEFAULT_SORT.number;

    isSort = !columnMenuData.length ? false : filterData.isSort;

    if (columnMenuData[0] !== undefined && columnMenuData[0].dir !== undefined) {
      isSort = true;
      sortDir = columnMenuData[0].dir.toUpperCase();
      sortNumber = index;
    }

    const dataVal = {
      ...apiCallData,
      in_filter: isFilter
        ? filterString + getFilterStringBasedOnModule()
        : getFilterStringBasedOnModule(),
      in_sort_number: sortNumber,
      in_sort_order: sortDir,
      in_page_row_offset: filterSkip,
      in_page_rows: filterTake,
    };

    setFilterData({
      ...filterData,
      isFilter: isFilter,
      isSort: isSort,
      data: filterString,
      in_sort_number: sortNumber,
      sort_order: sortDir,
    });

    setAPICallingState(true);

    getGridData(dataVal);
  };

  const handlePageChange = async (event) => {
    const pageData = event.page;

    setAPICallingState(true);

    setPage({ skip: pageData.skip, take: pageData.take });

    const dataVal = {
      ...apiCallData,
      in_filter: getInFilter(),
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
      in_page_row_offset: pageData.skip,
      in_page_rows: pageData.take,
    };

    getGridData(dataVal)
  };
  const setPageMenuState = (response) => {
    if (!deleteSelectedIds.length) {
      return false;
    }

    let ids = response.map((el) => el.custom_fld_col_mstr_key);

    let selectedKeys = [];
    let tempArray = [];
    let tempArrayData = [];

    Object.keys(selectedState).map((item) => {
      let obj = {};

      obj.id = Number(item);
      selectedKeys.push(item);
      tempArray.push(obj);

      return 0;
    });

    ids.map((item) => {
      if (selectedKeys.includes(item.toString())) {
        tempArrayData.push(item);
      }
      return 0;
    });

    let strSelectedKeys = "";

    if (tempArrayData.length > 0) {
      strSelectedKeys = parseInt(selectedKeys[0]);
    }
    setSelectedRowId(strSelectedKeys);
  };

  const onRefreshClick = () => {
    setAPICallingState(true);

    const dataVal = {
      ...apiCallData,
      in_filter: getInFilter(),
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
      in_page_row_offset: getPageSkip(),
      in_page_rows: getPageTake(),
    };
    getGridData(dataVal);
  };

  const getGridData = async (dataVal) => {
    let response = await Action.getDataList(dataVal);
    setPageMenuState(response);
    changeCancelBtnState();
    changeUpdateBtnState();
    setErrorObj({});
  };

  const handleMenuItemClick = (module) => {
    switch (module) {
      case "delete":
        setShow({
          ...isShow,
          deleteRows: !isShow.deleteRows,
        });
        break;
      default:
        break;
    }
  };

  const onClosePopup = () => {
    if (!isRefreshLoading) {
      setVisible(!visible);
    }
    restProps.setShow({
      ...restProps.isShow,
      isManageCustomType: false,
    });
  };

  const ModalTitle = () => {
    return <span>Manage Custom Fields</span>;
  };

  const onRowClick = (dataItem) => {
    let newData = data.map((item) => ({
      ...item,
      selected: item.custom_fld_col_mstr_key === dataItem.custom_fld_col_mstr_key ? true : false,
    }));
    setSelectedRowId(dataItem.custom_fld_col_mstr_key);
    setData(newData);
  };

  const onSelectionChange = (event) => {
    const newSelectedState = getSelectedState({
      event,
      selectedState: selectedState,
      dataItemKey: DATA_ITEM_KEY,
    });
    setSelectedState(newSelectedState);
    let tempArray = [];

    Object.keys(newSelectedState).map((item) => {
      if (newSelectedState[item] === false) {
        return 0;
      }
      let obj = {};
      obj.id = Number(item);
      tempArray.push(obj);
      return 0;
    });
    //setSelectedRowId(tempArray[0].id)
    setDeleteSelectedIds(tempArray);
    setSelectedRowId(tempArray[0])
  };

  const onClearFilter = async () => {
    setAPICallingState(true);

    //set in_params
    const dataVal = {
      ...apiCallData,
      in_filter: getFilterStringBasedOnModule(),
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
      in_page_row_offset: getPageSkip(),
      in_page_rows: getPageTake(),
    };

    //set filter to false
    setFilterData({
      ...filterData,
      data: "",
      isFilter: false,
    });

    getGridData(dataVal);
  };

  const customCellRender = (td, props) => {
    let tooltipMsg =
      getCustomField() === "events"
        ? MessageSchema.eventFiledNonEditable
        : MessageSchema.providerFieldNonEditable;
    return (
      <CellRender
        navigatable={true}
        originalProps={props}
        td={td}
        enterEdit={enterEdit}
        editField={editField}
        onRowClick={onRowClick}
        updateActBtn="grid-incell-update"
        cancelActBtn="grid-incell-cancel"
        title={props.field === "custom_fld_table_label" ||
          props.field === "data_type_name" ||
          props.field === "system_unit_name" ? props.dataItem.data_flag === 0 ? `${tooltipMsg}` : "" : ''}
      />
    );
  };

  const exitEdit = (dataItem, index, dataIndex, e) => {
    if (!addNew) {
      if (dataItem.event_type_name === "" || dataItem.event_type_desc) {
        handleUpdateValidation();
      }
    }
    // let newData = data.map((item) => ({
    //   ...item,
    //   inEdit: undefined
    // }));
    // setData(newData);
  };

  const customRowRender = (tr, props) => {
    return (
      <>
        <RowRender
          originalProps={props}
          tr={tr}
          editField={editField}
          errorObj={errorObj}
          exitEdit={exitEdit}
        /></>)
  }

  const NameCell = React.useCallback(
    (props) => {
      const { ariaColumnIndex, columnIndex, dataItem, field, render } = props;
      let isInEdit = field === dataItem.inEdit;

      if (addNew) {
        isInEdit = dataItem.inEdit;
      }

      let value = props.field + dataItem.rowId;
      const handleChange = (e, props, value) => {
        if (props.onChange) {
          setFieldTypeAddUpdate(true);
          props.onChange({
            dataIndex: 0,
            dataItem: props.dataItem,
            field: props.field,
            syntheticEvent: e.syntheticEvent,
            value: e.target.value,
          });
        }
      };

      const defaultRendering = (
        <td aria-colindex={ariaColumnIndex} data-grid-col-index={columnIndex}>
          {isInEdit ? (
            <Input
              // required
              value={dataItem[props.field]}
              onChange={(e) => {
                handleChange(e, props, value);
              }}
              name={`${props.field}`}
            />
          ) : (
            <span>{props.dataItem[props.field || ""]}</span>

          )}

          {Object.keys(errorObj).length > 0 ? (
            <div role="alert" className="k-form-error k-text-start text-wrap">
              {Object.keys(errorObj).length > 0 ? errorObj[value] : ""}
            </div>
          ) : (
            false
          )}
        </td>
      );
      return render?.call(undefined, defaultRendering, props);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errorObj]
  );

  const DropDownCell = React.useCallback(
    (props) => {
      const { ariaColumnIndex, columnIndex, dataItem, field, render } = props;
      let tooltipMsg =
        getCustomField() === "events"
          ? MessageSchema.eventFiledNonEditable
          : MessageSchema.providerFieldNonEditable;
      const isInEdit = field === dataItem.inEdit;
      const value = field && dataItem[field] ? dataItem[field] : "";
      let valueData = "";
      switch (field) {
        case "custom_fld_table_label":
          valueData = customFieldMasterSource;
          break;
        case "data_type_name":
          valueData = dataTypeSource;
          break;
        case "system_unit_name":
          valueData = unitTypeDataSource;
          break;
        default:
          break;
      }

      if (props.field === "data_type_name" && dataItem.data_type_name.toLowerCase() !== "number") {
        dataItem.system_unit_name = "";
      }

      let dataValue = "";
      if (props && valueData && valueData.length > 0) {
        if (dataItem[props.field]) {
          let value = valueData?.find((x) => x.text === dataItem[props.field]);
          dataValue = value ? value.text : "";
        }
      }
      const handleChange = (e) => {
        if (props.onChange) {
          setFieldTypeAddUpdate(true);
          props.onChange({
            dataIndex: 0,
            dataItem: props.dataItem,
            field: props.field,
            syntheticEvent: e.syntheticEvent,
            value: e.target.value.text,
          });
        }
      };
      const checkDisabledUnitTypeField = () => {
        let isDisabled = true;
        if (dataItem.data_type_name.toLowerCase() === "number") {
          isDisabled = false;
        }
        return isDisabled;
      };

      const getValue = () => {
        let drpValue = "";
        if (
          props.field === "data_type_name" &&
          dataItem.data_type_name.toLowerCase() !== "number"
        ) {
          dataItem.system_unit_name = "";
          drpValue = valueData && valueData.find((c) => c.text === dataValue);
        } else {
          drpValue = valueData && valueData.find((c) => c.text === dataValue);
        }
        return drpValue;
      };

      const checkSystemNameEditMode = () => {
        let isDisabled = true;
        if (field === "system_unit_name") {
          isDisabled = !checkDisabledUnitTypeField();
        }
        return isDisabled;
      };

      const defaultRendering = (
        <td aria-colindex={ariaColumnIndex} data-grid-col-index={columnIndex}>
          {isInEdit && dataItem.data_flag === 1 && checkSystemNameEditMode() ? (
            <div>
              <DropDownList
                style={{
                  width: "150px",
                }}
                popupSettings={{
                  height: "100px",
                }}
                onChange={handleChange}
                value={getValue()}
                data={valueData}
                textField="text"
                placeholder={"Select"}
                disabled={dataItem.data_flag ? false : true}
              />
            </div>
          ) : value ? (
            <span
              title={dataItem.data_flag === 0 ? `${tooltipMsg}` : ""}
              disabled={field === "system_unit_name" ? checkDisabledUnitTypeField() : true}
            >
              {dataValue.toString()}
            </span>
          ) : (

            <span title={dataItem.data_flag === 0 ? `${tooltipMsg}` : ""}></span>
          )}
        </td>
      );
      return render?.call(undefined, defaultRendering, props);
    },
      // eslint-disable-next-line react-hooks/exhaustive-deps
    [customFieldMasterSource, dataTypeSource, unitTypeDataSource]
  );

  const enterEdit = (dataItem, field) => {
    const newData = data.map((item) => ({
      ...item,
      [editField]:
        item.custom_fld_col_mstr_key === dataItem.custom_fld_col_mstr_key ? field : undefined,
    }));
    setData(newData);
  };

  const itemChange = React.useCallback(
    (event) => {
      const field = event.field || "";
      let newData = "";

      newData = data.map((item) =>
        item.custom_fld_col_mstr_key === event.dataItem.custom_fld_col_mstr_key
          ? {
            ...item,
            [field]: event.value,
            inIteamEdit: true,
            system_unit_name:
              field === "data_type_name" && event.value.toLowerCase() === "number"
                ? unitTypeDataSource[0].text
                : event.field === "system_unit_name"
                  ? event.value
                  : item.system_unit_name,
          }
          : item
      );
      changeCancelBtnState("enable");
      changeUpdateBtnState("enable");
      setData(newData);
    },
      // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  const handleUpdate = async () => {
    let valid = handleUpdateValidation();
    if (valid) {
      let updatedDataList = [];
      data
        .filter((x) => x.inIteamEdit === true && x.inEdit !== true)
        .map((item) => {
          if (item.data_type_name.toLowerCase() !== "number") {
            item.system_unit_name = "";
          }
          let obj = {};
          obj.in_user_id = cookiesData.out_user_id.toString();
          obj.in_tenant_id = cookiesData.out_tenant_id;
          obj.in_custom_fld_col_mstr_key = item.custom_fld_col_mstr_key;
          obj.in_custom_fld_col_name = item.custom_fld_col_name;
          obj.in_custom_fld_col_desc = item.custom_fld_col_desc;
          obj.in_custom_fld_mstr_key = customFieldMasterSource.find(
            (c) => c.text === item.custom_fld_table_label
          ).id;
          obj.in_data_type_mstr_key = dataTypeSource.find((c) => c.text === item.data_type_name).id;
          obj.in_custom_fld_col_unittype = item.system_unit_name
            ? unitTypeDataSource.find((c) => c.text === item.system_unit_name).id
            : "";
          obj.in_base_table_name = getBaseTableName(customFieldMasterSource.find(
            (c) => c.text === item.custom_fld_table_label
          ).baseTable);
          updatedDataList.push(obj);
          return 0;
        });

      const bodyData = {
        updateCustomField: [...updatedDataList],
      };
      if (updatedDataList.length > 0) {
        let response = await Action.updateDataAsync(bodyData);
        if (response) {
          onRefreshClick();
          setFieldTypeAddUpdate(false);
          // TODO : UNSAVED CHANGES

          // dispatch(setUnSavedChangesPopup({ ...unsavedPopup, isUnSavedChange: false }));
        }
      }
    } else {
      changeCancelBtnState("enable");
      changeUpdateBtnState("enable");
    }
  };

  const useCol = (columns) => {
    return columns.map((column, idx) => {
      return column.show && column.title !== "" ? (
        <Column
          {...columnMenuProps(column)}
          key={idx}
          cell={
            column.field === "custom_fld_table_label" ||
              column.field === "data_type_name" ||
              column.field === "system_unit_name"
              ? DropDownCell
              : NameCell
          }
          sortable={true}
        />
      ) : (
        false
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const AddNewLink = () => {
    return (
      <div ref={popupRef}>
        <a
          className="cta-small"
          onClick={(e) => {
            addNewData(e);
          }}
        >
          <i className="fas fa-plus"></i> Add New Custom Fields
        </a>

        {AddNewPopup()}
      </div>
    );
  };
  const onCancelClick = () => {
    setFormFields(initialFormFields);
    setErrorObj({});
    setAddNew(false);
    setAddUnitTypeFields({
      unitTypeValue: {
        id: "",
        text: "",
      },
      unitTypeData: [],
    });
    setAddDataTypeFields({
      dataTypeValue: {
        id: "",
        text: "",
      },
      dataTypeList: [],
    });
    setAddBaseTableFields({
      baseTableValue: {
        id: "",
        text: "",
      },
      baseTableList: [],
    });
  };
  const handleFieldChange = (e) => {
    const name = e.name ? e.name : e.target.name;
    setFormFields({ ...formFields, [name]: e.value });
    setErrorObj({ ...errorObj, [name]: "" });
  };
  const ShowError = React.useCallback(
    (props) => {
      return <Error>{errorObj[props.name]}</Error>;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errorObj]
  );

  const AddNewForm = () => {
    return (
      <Form
        onSubmit={handleSubmit}
        render={() => (
          <FormElement className="emailForm addcustomfieldcont" horizontal={true}>
            <FieldWrapper>
              <div className={"k-form-field-wrap-popup"} ref={baseDropDownRef}>
                <Label>{"Base Table*: "}</Label>
                <CustomDropdown
                  name={"baseTableValue"}
                  data={addBaseTableFields.baseTableList}
                  textField="text"
                  dataItemKey="id"
                  onOpen={() => {
                    setAddBaseTableFields({
                      ...addBaseTableFields,
                      baseTableList: customFieldMasterSource,
                    });
                  }}
                  valueData={customFieldMasterSource.find(
                    (c) => c.text === addBaseTableFields.baseTableValue.text
                  )}
                  onChange={(e, name) => {
                    setAddBaseTableFields({
                      ...addBaseTableFields,
                      [name]: e,
                    });
                  }}
                  onFilterChange={(dataValue) => {
                    setAddBaseTableFields({
                      ...addBaseTableFields,
                      baseTableList: dataValue,
                    });
                  }}
                  filterable={true}
                  autoClose={false}
                  autoBind={true}
                  dataList={customFieldMasterSource}
                  appendTo={baseDropDownRef.current}
                />
              </div>
            </FieldWrapper>
            <FieldWrapper>
              <div className="k-form-field-wrap-popup">
                <Label>Field Name *: </Label>
                <Input
                  type="text"
                  name="field_name"
                  value={formFields.field_name}
                  onChange={(e) => {
                    handleFieldChange(e);
                  }}
                  placeholder="Field Name"
                />
                <ShowError name="field_name" />
              </div>
            </FieldWrapper>
            <FieldWrapper>
              <div className="k-form-field-wrap-popup">
                <Label>Field Description * : </Label>
                <Input
                  type="text"
                  name="field_desc"
                  value={formFields.field_desc}
                  onChange={(e) => {
                    handleFieldChange(e);
                  }}
                  placeholder="Field Description"
                />
                <ShowError name="field_desc" />
              </div>
            </FieldWrapper>

            <FieldWrapper>
              <div className={"k-form-field-wrap-popup"} ref={dataTypeDropDownRef}>
                <Label>{"Data Type* : "}</Label>
                <CustomDropdown
                  name={"dataTypeValue"}
                  data={addDataTypeFields.dataTypeList}
                  textField="text"
                  dataItemKey="id"
                  valueData={dataTypeSource.find(
                    (c) => c.id === addDataTypeFields.dataTypeValue.id
                  )}
                  onOpen={() => {
                    setAddDataTypeFields({ ...addDataTypeFields, dataTypeList: dataTypeSource });
                  }}
                  onChange={(e, name) => {
                    if (e.text.toLowerCase() === "number") {
                      setAddUnitTypeFields({
                        ...addUnitTypeFields,
                        unitTypeValue: addUnitTypeFields.unitTypeData[0],
                      });
                    } else {
                      setAddUnitTypeFields({
                        ...addUnitTypeFields,
                        unitTypeValue: { id: "", text: "" },
                      });
                    }
                    setAddDataTypeFields({
                      ...addDataTypeFields,
                      [name]: e,
                    });
                  }}
                  onFilterChange={(dataValue) => {
                    setAddDataTypeFields({
                      ...addDataTypeFields,
                      dataTypeList: dataValue,
                    });
                  }}
                  filterable={true}
                  autoClose={false}
                  autoBind={true}
                  dataList={dataTypeSource}
                  appendTo={dataTypeDropDownRef.current}
                />
              </div>
            </FieldWrapper>
            <FieldWrapper>
              <div className={"k-form-field-wrap-popup"} ref={unitTypeDropdownRef}>
                <Label>{"Unit Type* : "}</Label>
                <CustomDropdown
                  name={"unitTypeValue"}
                  data={addUnitTypeFields.unitTypeData}
                  textField="text"
                  dataItemKey="id"
                  onOpen={() => {
                    setAddUnitTypeFields({
                      ...addUnitTypeFields,
                      dataTypeList: unitTypeDataSource,
                    });
                  }}
                  disabled={
                    addDataTypeFields?.dataTypeValue?.text?.toLowerCase() === "number"
                      ? false
                      : true
                  }
                  valueData={unitTypeDataSource.find(
                    (c) => c.id === addUnitTypeFields?.unitTypeValue?.id
                  )}
                  onChange={(e, name) => {
                    setAddUnitTypeFields({
                      ...addUnitTypeFields,
                      [name]: e,
                    });
                  }}
                  onFilterChange={(dataValue) => {
                    setAddUnitTypeFields({
                      ...addUnitTypeFields,
                      unitTypeData: dataValue,
                    });
                  }}
                  filterable={true}
                  autoClose={false}
                  autoBind={true}
                  dataList={unitTypeDataSource}
                  appendTo={unitTypeDropdownRef.current}
                />
              </div>
            </FieldWrapper>
          </FormElement>
        )}
      />
    );
  };
  const AddNewPopup = () => {
    return (
      <Popup anchor={addRef.current} show={addNew} setShow={setAddNew}>
        <div className="dot-dropdown-open dot-dropdown-open-Form">
          <div className="caption">
            <span>Add New Custom Field</span>
          </div>

          {AddNewForm()}

          <div className="dropdownFooter">
            <div className="k-form-buttons">
              <Button
                primary={true}
                type="submit"
                onClick={() => {
                  onCancelClick();
                }}
              >
                Cancel
              </Button>
              <Button
                primary={true}
                type="submit"
                onClick={() => {
                  handleSubmit(formFields, errorObj);
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </Popup>
    );
  };
  const headerConfig = {
    id: "manage-event",
    buttons: { template_fx: GridButton },
    add_new_fx: AddNewLink,
    action_menu: {
      delete: { fx: handleMenuItemClick },
    },
    manage_col: {
      columnsData: columnsData,
      setColumnsData: setColumnsData,
      setColumnCheck: setColumnCheck,
    },
  };

  const parent = document.querySelector("#content-" + headerConfig.id);
  if (parent) {
    const deleteRows = parent.querySelector(".delete-rows");
    deleteRows && deleteRows.disableKendoMenu();
    if (selectedRowId !== "") {
      deleteRows && deleteRows.enableKendoMenu();
    }
  }
  let customColumn = useCol(gridColumns);
  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>
  );

  const CustomComponent = () => {
    return <p dangerouslySetInnerHTML={{ __html: confirmationPopup.desc }} />;
  };

  const handleStageChange = (e) => {
    setStateChange(e.state)
    if (e.state === 'MINIMIZED') {
      setModal(false)
    } else if (e.state === 'DEFAULT') {
      setModal(true)
    }
  };

  return (
    <>
      <>
        {loadingState.initLoading && <LoadingPanel />}
        <Window
          title={<ModalTitle />}
          onClose={onClosePopup}
          width={1200}
          height={600}
          resizable={false}
          stage={stageChange}
          onStageChange={handleStageChange}
          modal={modal}
        >

          <div className="dtu-file-container-logs ManageCustomField-dialogue">
            <div className="content">
              <div className="Quick-links eventtypequicklinks">
                <HeaderPage pageProps={restProps.pageProps} headerConfig={headerConfig} />
              </div>
            </div>
            {isRefreshLoading && loadingPanel}
            {columns.length > 0 && !loadingState.initLoading ? (
              <DataTable
                isAPICalling={isAPICalling}
                initialLoading={initialLoading}
                data={data.map((item) => ({
                  ...item,
                  [SELECTED_FIELD]: selectedState[idGetter(item)],
                }))}
                id="manage-field-grid"
                editField={editField}
                pageData={pageData}
                dataCount={dataCount}
                customColumn={customColumn}
                columns={gridColumns}
                dataItemKey={DATA_ITEM_KEY}
                width={"auto"}
                // gridHeight={400}
                module={"manage-field-type"}
                cellRender={customCellRender}
                rowRender={customRowRender}
                itemChange={itemChange}
                defaultPageSize={defaultPageSize}
                pageSize={pageSize}
                setPageSize={setPageSize}
                handlePageChange={handlePageChange}
                onClearFilter={onClearFilter}
                isAddClick={restProps.isAddNewConfig}
                handleColumnMenu={handleColumnMenu}
                refreshClick={onRefreshClick}
                onSelectionChange={onSelectionChange}
                resetSort={resetSort}
                reorderable={false}
              />
            ) : initialLoading && data.length === 0 ? (
              false
            ) : (
              <LoadingPanel />
            )}
            <DialogComponent
              title={confirmationPopup.title}
              onCancelConfirm={onCancelConfirm}
              width={"450"}
              height={"auto"}
              className={"Dialog-Delete"}
              desc={<CustomComponent />}
              visible={confirmationPopup.isVisible}
              actionBtn={confirmationPopup.actionBtn}
              titleClassName={
                confirmationPopup.action === "Delete" ? "delete-confirm" : "unSave-confirm"
              }
            />
          </div>
        </Window>

      </>
    </>
  );
};

export default React.memo(ManageCustomField);

export const GridButton = (restProps) => {
  return (
    <>
      <Button type="submit" className="cancelbtn" id="grid-incell-cancel">
        Cancel
      </Button>
      <Button type="submit" className="primarybtn" id="grid-incell-update">
        Save
      </Button>
    </>
  );
};
