import * as React from "react";
import { Button } from "@progress/kendo-react-buttons";
import {
  Form,
  Field,
  FormElement,
  FieldWrapper,
} from "@progress/kendo-react-form";
import { GridLayoutItem, GridLayout } from "@progress/kendo-react-layout";

export const FormWrapper = ({
  onSubmit,
  formActionConfig,
  formFieldsConfig,
}) => {
  return (
    <Form
      onSubmit={onSubmit}
      render={(formRenderProps) => (
        <FormElementContainer
          formActionConfig={formActionConfig}
          formFieldsConfig={formFieldsConfig}
          formRenderProps={formRenderProps}
        />
      )}
    />
  );
};

export const FormElementContainer = ({
  formActionConfig = [],
  formFieldsConfig = [],
}) => {
  return (
    <FormElement>
      <GridLayout
        cols={
          formFieldsConfig &&
          formFieldsConfig?.length > 0 &&
          formFieldsConfig?.map((formField) => ({
            width: formField?.width || "auto",
          }))
        }
        gap={{
          cols: 20,
          rows: 20,
        }}
      >
        {formFieldsConfig &&
          formFieldsConfig?.length > 0 &&
          formFieldsConfig.map((formField, key) => (
            <GridLayoutItem key={key}>
              <FieldWrapper>
                <Field {...formField} />
              </FieldWrapper>
            </GridLayoutItem>
          ))}
      </GridLayout>
      {formActionConfig && formActionConfig?.length > 0 && (
        <div className="k-form-buttons">
          {formActionConfig.map(({ label, ...buttonConfig }) => (
            <Button {...buttonConfig}>{label}</Button>
          ))}
        </div>
      )}
    </FormElement>
  );
};

export const TransFormSelectDropdown = ({ data, keyForLabel, keyForValue }) => {
  if (data && data?.length > 0) {
    return data?.map((selectDropDownObj) => ({
      label: selectDropDownObj?.[keyForLabel] || "",
      value: selectDropDownObj?.[keyForValue] || "",
    }));
  }
  return null;
};

export const TransFormSelectDropdownUnique = ({
  data,
  keyForLabel,
  keyForValue,
  keyForId
}) => {
  if (data && data?.length > 0) {
    const uniqueValues = new Set();
    return data.reduce((result, selectDropDownObj) => {
      const label = selectDropDownObj?.[keyForLabel] || "";
      const value = selectDropDownObj?.[keyForValue] || "";
      const key = selectDropDownObj?.[keyForId] || "";
      if (!uniqueValues.has(value)) {
        uniqueValues.add(value);
        if(key) {
          result.push({ label, value, key });
        } else {
          result.push({ label, value });
        }
      }
      return result;
    }, []);
  }
  return null;
};

export const TransformDropdown = ({ data, keyForLabel, keyForValue }) => {
  if (data && data?.length > 0) {
    return data?.map((selectDropDownObj) => ({
      text: selectDropDownObj?.[keyForLabel] || "",
      value: selectDropDownObj?.[keyForValue] || "",
    }));
  }
  return null;
};

export const TransformtDropdownUnique = ({
  data,
  keyForLabel,
  keyForValue,
  keyForId
}) => {
  if (data && data?.length > 0) {
    const uniqueValues = new Set();
    return data.reduce((result, selectDropDownObj) => {
      const label = selectDropDownObj?.[keyForLabel] || "";
      const value = selectDropDownObj?.[keyForValue] || "";
      const key = selectDropDownObj?.[keyForId] || "";
      if (!uniqueValues.has(value)) {
        uniqueValues.add(value);
        if(key) {
          result.push({ text: label, id: value, key });
        } else {
          result.push({ text: label, id: value });
        }
      }
      return result;
    }, []);
  }
  return null;
};
