import React from "react";
import { Button } from "@progress/kendo-react-buttons";
import { FieldWrapper, Form, FormElement } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import { Error, Label } from "@progress/kendo-react-labels";
import { Splitter, TabStrip, TabStripTab } from "@progress/kendo-react-layout";

import { HeaderPage } from "../base/headerPage";
import CustomDropdown from "../../components/customDropdown";
import AuditLogs from "../../components/auditLogs";
import Popup from "../../components/customPopup";
import { getUserCredential } from "../../lib/cookies";
import MessageSchema from "../../utils/messageSchema";
import DtuBatchLogs from "../common/dtuBatchLogs";
import { changeCancelBtnState, changeUpdateBtnState } from "../../utils/utils";
import { GridButton, PlanData } from "./planGrid";
import useServiceApi from "./service";
import DataRows from "./dataRows/dataRows";
import ProviderDataRows from "./providerDataRows/dataRows";
import {
  GET_PLAN_EFF_VERSION,
  DELETE_COMP_PLAN,
  INSERT_COMP_PLAN,
} from "../../api-setup/api-endpoints";
import "./plan.css";

import EffDataRowsApi from "../../components/effectiveVesionService";
import { EffectiveVersions } from "../../components/effectiveVersions";
import AddPlanEffectiveVersion from "./addPlanEffectiveVersion";
import { LoadingPanel } from "../../components/loadingPanel";
import UploadDtu from "../user/uploadDtu";
import { Loader } from "@progress/kendo-react-indicators";

const initialFormFields = {
  plan_name: "",
  description: "",
  in_period_mstr_key: "",
};

const Plan = (pageProps) => {
  const [list, Action] = useServiceApi();
  const cookiesData = getUserCredential();
  const addRef = React.useRef(null);

  const [isAddNewConfig, setNewConfig] = React.useState(false);
  const [isShow, setShow] = React.useState({
    auditLogs: false,
    deleteRows: false,
  });
  const [isVersionShow, setVersionShow] = React.useState({
    dtuLogs: false,
    dtuUpload: false,
  });
  const [exportDataClick, setExportDataClick] = React.useState(false);
  const [versionExportDataClick, setVersionExportDataClick] =
    React.useState(false);
  const [isAPICall, setAPICall] = React.useState(false);
  const [formFields, setFormFields] = React.useState({
    ...initialFormFields,
  });
  const [errorObj, setErrorObj] = React.useState({});
  const [isSaveClick, setSaveClick] = React.useState(false);
  const [selectedRowId, setSelectedRowId] = React.useState("");
  const [dataList, setDataList] = React.useState([]);
  const [dataItem, setDataItem] = React.useState({});
  const [panes, setPanes] = React.useState([
    {
      size: "35%",
      collapsible: true,
      scrollable: false,
    },
    {
      scrollable: false,
      min: "300px",
    },
  ]);
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [isKpiUpdateClick, setisKpiUpdateClick] = React.useState(false);
  const [isProviderUpdateClick, setisProviderUpdateClick] =
    React.useState(false);

  const [cancelKpiAwaitStatus, setCancelKpiAwaitStatus] =
    React.useState(undefined);
  const [cancelProviderAwaitStatus, setCancelProviderAwaitStatus] =
    React.useState(undefined);
  const [isCancelClick, setisCancelClick] = React.useState(false);

  const [effVersionDataItem, setEffVersionDataItem] = React.useState({});

  const [isPlanEdit, setisPlanEdit] = React.useState(false);
  const [isKpiEdit, setisKpiEdit] = React.useState(false);
  const [isProviderEdit, setisProviderEdit] = React.useState(false);

  const [screenLock, setScreenLock] = React.useState(false);
  const [isCollapsed, setIsCollapsed] = React.useState(false);
  const [columnsData, setColumnsData] = React.useState([]);
  const [isCheckColumn, setColumnCheck] = React.useState(false);
  const [kpiColumnsData, setKpiColumnsData] = React.useState([]);
  const [isKpiCheckColumn, setKpiColumnCheck] = React.useState(false);
  const [providerColumnsData, setproviderColumnsData] = React.useState([]);
  const [isproviderCheckColumn, setproviderColumnCheck] = React.useState(false);
  const [isEffectiveVersionDelete, setIsEffectiveVersionDelete] =
    React.useState(false);

  let popupRef = React.useRef();
  // useDocumentClickEventListener(isAddNewConfig, setNewConfig, popupRef);
  const {
    loadingState,

    originPeriod,
    periodsFields,
    setPeriodFields,
    originPeriodType,
    periodsTypeFields,
    setPeriodTypeFields,
    setData,
    setRefreshLoading,
  } = list;
  const [listVersion, ActionVersion] = EffDataRowsApi();
  const [effVersionState, setEffVersionState] = React.useState({
    changeVersion: { rowId: -1, versionIdx: -1 },
    insertVersion: { rowId: -1, periodKey: 0 },
    deleteVersion: { rowId: -1 },
    insertPlanVersion: { rowId: -1, periodKey: 0 },
  });
  const [isAddEffPlanPopup, setEffectivePlanPopup] = React.useState(false);
  const [isEffectiveVersionLoadState, setEffVersionLoadState] =
    React.useState(false);
  const [isReload, setReload] = React.useState({
    kpiReload: false,
    providerReload: false,
  });
  const [isRefreshBtnClick, setRefreshBtnClick] = React.useState(false);
  const [isPlanGridRefresh, setPlanGridRefresh] = React.useState(false);
  let periodTypeRef = React.useRef(null);
  let effPeriodRef = React.useRef(null);

  React.useEffect(() => {
    window.$_UNSAVED_CHANGES_FLAG = false;
  }, []);

  React.useEffect(() => {
    if (isPlanGridRefresh) {
      setPlanGridRefresh(false);
      getEffectiveVersionListReload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlanGridRefresh]);

  const getEffectiveVersionListReload = () => {
    const selectedData = dataList.filter(
      (data) => data.comp_plan_mstr_key == selectedRowId
    );
    let newSelectedData = {};

    if (selectedData[0]) {
      newSelectedData["mstr_key"] = selectedData[0].comp_plan_mstr_key;
      newSelectedData["mstr_name"] = selectedData[0].comp_plan_name;
      newSelectedData["date_version"] = selectedData[0].date_version;
      newSelectedData["period_mstr_key"] = selectedData[0].period_mstr_key;
      newSelectedData["period_start_date"] = selectedData[0].period_start_date;
      newSelectedData["period_name"] = selectedData[0].plan_year;
    }
    if (selectedData.length > 0) {
      const index = dataList.findIndex(
        (data) => data.comp_plan_mstr_key == selectedRowId
      );
      let masterTableData = dataList.slice();
      masterTableData[index].effVersionDetails = effVersionDataItem;
      newSelectedData["effVersionDetails"] = effVersionDataItem;
      newSelectedData["rowId"] = index;
      setDataItem(newSelectedData);
      let selectedEffVersion = effVersionDataItem.data.find(
        (x) => x.isSelected === 1
      );
      setEffVersionDataItem({
        ...effVersionDataItem,
        dateVersion: selectedEffVersion?.dateVersion,
        periodName: selectedEffVersion?.periodName,
      });
      setData(masterTableData);
    }
  };

  React.useEffect(() => {
    if (loadingState.isSaveData) {
      setFormFields({ ...initialFormFields });
      setAPICall(true);
      setNewConfig(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingState.isSaveData]);

  //change eff version
  React.useEffect(() => {
    let masterTableData = dataList.slice();
    if (masterTableData.length > 0) {
      const versionState = effVersionState.changeVersion;
      const mstrRow = masterTableData[versionState.rowId];
      if (mstrRow?.effVersionDetails) {
        let thisEffVersionDeta = mstrRow?.effVersionDetails.data;
        thisEffVersionDeta
          .filter((x) => x.isSelected === 1)
          .map((item) => {
            item.isSelected = 0;
            return 0;
          });

        const newSelectedVersion = thisEffVersionDeta[versionState.versionIdx];
        newSelectedVersion.isSelected = 1;
        setEffVersionDataItem({
          ...effVersionDataItem,
          dateVersion: newSelectedVersion.dateVersion,
          periodName: newSelectedVersion.periodName,
        });
        setData(masterTableData);
      } else {
        getEffectiveVersionListReload();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [effVersionState.changeVersion]);

  React.useEffect(() => {
    const insertVersion = effVersionState.insertVersion;
    if (insertVersion.periodKey) {
      setEffectivePlanPopup(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [effVersionState.insertVersion]);

  /**
   * call delete eff version API
   */
  React.useEffect(() => {
    let masterTableData = dataList.slice();

    const deleteVersion = effVersionState.deleteVersion;

    if (masterTableData.length > 0 && deleteVersion.rowId !== -1) {
      const mstrDeta = masterTableData[deleteVersion.rowId];
      const thisEffVersionDeta = mstrDeta?.effVersionDetails.data;
      const selectedVersion = thisEffVersionDeta.filter(
        (x) => x.isSelected === 1
      )[0];

      setEffVersionDataItem({
        ...effVersionDataItem,
        dateVersion: selectedVersion.dateVersion,
        periodName: selectedVersion.periodName,
      });

      const bodyData = {
        in_user_id: cookiesData.out_user_id,
        in_version_data: [
          {
            mstr_key: mstrDeta.comp_plan_mstr_key,
            date_version: selectedVersion.dateVersion,
          },
        ],
        in_tenant_id: cookiesData.out_tenant_id,
      };

      ActionVersion.deleteDataAsync({
        api: DELETE_COMP_PLAN,
        bodyData: bodyData,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [effVersionState.deleteVersion]);

  const getSelectedData = () => {
    const selectedData = dataList.filter(
      (data) => data.comp_plan_mstr_key == selectedRowId
    );
    let newSelectedData = {};
    if (selectedData[0]) {
      newSelectedData["mstr_key"] = selectedData[0].comp_plan_mstr_key;
      newSelectedData["mstr_name"] = selectedData[0].comp_plan_name;
      newSelectedData["date_version"] = selectedData[0].date_version;
      newSelectedData["period_mstr_key"] = selectedData[0].period_mstr_key;
      newSelectedData["period_start_date"] = selectedData[0].period_start_date;
      newSelectedData["period_name"] = selectedData[0].plan_year;
    }
    if (selectedData.length > 0) {
      getEffectiveVersionList(newSelectedData);
    } else {
      setDataItem({});
    }
  };

  React.useEffect(() => {
    getSelectedData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowId]);

  const getEffectiveVersionList = async (newSelectedData) => {
    const index = dataList.findIndex(
      (data) => data.comp_plan_mstr_key == selectedRowId
    );
    let effVersionDataItem = await getEffVersionsApi(newSelectedData.mstr_key);
    setEffVersionLoadState(false);
    let masterTableData = dataList.slice();
    masterTableData[index].effVersionDetails = effVersionDataItem;
    newSelectedData["effVersionDetails"] = effVersionDataItem;
    newSelectedData["rowId"] = index;
    setDataItem(newSelectedData);
    let selectedEffVersion = effVersionDataItem.data.find(
      (x) => x.isSelected === 1
    );
    setEffVersionDataItem({
      ...effVersionDataItem,
      dateVersion: selectedEffVersion?.dateVersion,
      periodName: selectedEffVersion?.periodName,
    });
    if (selectedTab === 0) {
      setReload({
        ...isReload,
        kpiReload: true,
      });
    } else {
      setReload({
        ...isReload,
        providerReload: true,
      });
    }
    setData(masterTableData);
  };

  //disable in-cell update and cancel button
  React.useEffect(() => {
    if (Object.keys(effVersionDataItem).length > 0) {
      if (isKpiEdit === false) {
        isKpiUpdateClick === true && setisKpiUpdateClick(false);
      }

      if (isProviderEdit === false) {
        isProviderUpdateClick === true && setisProviderUpdateClick(false);
      }

      if (
        isPlanEdit === false &&
        isKpiEdit === false &&
        isProviderEdit === false
      ) {
        changeCancelBtnState();
        changeUpdateBtnState();
      }
    } else {
      changeCancelBtnState();
      changeUpdateBtnState();
      isCancelClick === true && setisCancelClick(false);
      isKpiUpdateClick === true && setisKpiUpdateClick(false);
      isProviderUpdateClick === true && setisProviderUpdateClick(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProviderEdit, isKpiEdit, isPlanEdit, effVersionDataItem]);

  const onPaneChange = (event) => {
    const eventTarget = event.nativeEvent.target;

    if (eventTarget === undefined) {
      setPanes(event.newState);
      return;
    }

    if (eventTarget.classList.contains("k-collapse-prev")) {
      if (isCollapsed === false) {
        setIsCollapsed(true);
        event.newState[0].collapsed = false;
        event.newState[0].size = 0;
        eventTarget.classList.remove("k-i-arrow-60-left");
        eventTarget.classList.add("k-i-arrow-60-right");
      } else {
        setIsCollapsed(false);
        event.newState[0].collapsed = false;
        event.newState[0].size = "35%";
        eventTarget.classList.remove("k-i-arrow-60-right");
        eventTarget.classList.add("k-i-arrow-60-left");
      }
      setPanes(event.newState);
    }
  };

  const handleSelect = (e) => {
    setSelectedTab(e.selected);
    setNewConfig(false);
  };

  const addNewConfig = (e) => {
    setFormFields(initialFormFields);
    setNewConfig(!isAddNewConfig);
    list.setLoadState({ ...list.loadingState, isSaveData: false });
    Action.getPeriodTypesData();
    e.preventDefault();
  };

  const onRefreshClick = () => {
    setAPICall(true);
  };

  const ShowError = React.useCallback(
    (props) => {
      return <Error>{errorObj[props.name]}</Error>;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errorObj, isSaveClick]
  );

  const handleValidation = () => {
    let isValid = true;

    if (periodsFields.periodsValue.id === "") {
      if (formFields.in_period_mstr_key === "") {
        isValid = false;
        errorObj.in_period_mstr_key = "Please select effctive period";
      }
    } else {
      errorObj.in_period_mstr_key = "";
      isValid = true;
    }

    if (formFields.plan_name.trim() === "") {
      isValid = false;
      errorObj.plan_name = MessageSchema.planTableRequired;
    }

    setErrorObj((preVState) => ({ ...preVState, errorObj }));

    return isValid;
  };

  const handleSubmit = async () => {
    let valid = handleValidation();

    if (valid) {
      setErrorObj({});
      const bodyData = {
        in_user_id: cookiesData.out_user_id,
        in_tenant_id: cookiesData.out_tenant_id,
        in_comp_plan_mstr_key: null,
        in_comp_plan_name: formFields.plan_name,
        in_comp_plan_desc: formFields.description,
        in_period_mstr_key: periodsFields.periodsValue.id,
        in_select_date_version: null,
        in_copy_assignment: null,
      };
      let res = await Action.addDataAsync(bodyData);
      if (res) {
        setSelectedTab(0);
        onRefreshClick();
      }
    } else {
      setSaveClick(true);
    }
  };

  const onCancelClick = () => {
    setFormFields(initialFormFields);
    setErrorObj({});
    setNewConfig(false);
    setSaveClick(false);
  };

  const handleFieldChange = (e) => {
    const name = e.name ? e.name : e.target.name;
    if (e.value !== null) {
      if (e.value.text === "Regular") {
        setFormFields({
          ...formFields,
          [name]: e.value,
        });
        setErrorObj({ ...errorObj, [name]: "" });
      } else {
        setFormFields({ ...formFields, [name]: e.value });
        setErrorObj({ ...errorObj, [name]: "" });
      }
    }
  };

  const AddNewForm = () => {
    return (
      <Form
        onSubmit={handleSubmit}
        render={() => (
          <FormElement className="emailForm" horizontal={true}>
            <FieldWrapper>
              <div className={"k-form-field-wrap-popup"}>
                <Label>{"Plan Name*:"}</Label>
                <Input
                  type={"text"}
                  name="plan_name"
                  placeholder="Plan Name"
                  value={formFields.plan_name}
                  onChange={(e) => {
                    handleFieldChange(e);
                  }}
                  autoFocus={true}
                />
                <ShowError name={"plan_name"}></ShowError>
              </div>
            </FieldWrapper>

            <FieldWrapper>
              <div className={"k-form-field-wrap-popup"}>
                <Label>{"Description:"}</Label>
                <Input
                  type={"text"}
                  name="description"
                  placeholder="Description"
                  value={formFields.description}
                  onChange={(e) => {
                    handleFieldChange(e);
                  }}
                />
                <ShowError name={"description"}></ShowError>
              </div>
            </FieldWrapper>

            <FieldWrapper>
              <div className={"k-form-field-wrap-popup"} ref={periodTypeRef}>
                <Label>{"Period Type* : "}</Label>
                <CustomDropdown
                  name={"periodTypeValue"}
                  data={periodsTypeFields.periodTypeData}
                  textField="text"
                  dataItemKey="id"
                  valueData={originPeriodType.find(
                    (c) => c.text === periodsTypeFields.periodTypeValue.text
                  )}
                  onOpen={(dataValue) => {
                    setPeriodTypeFields({
                      ...periodsTypeFields,
                      periodTypeData: originPeriodType,
                    });
                  }}
                  onChange={(e, name) => {
                    setPeriodTypeFields({
                      ...periodsTypeFields,
                      [name]: e,
                    });
                    Action.getPeriodsData(e.id, e);
                  }}
                  onFilterChange={(dataValue) => {
                    setPeriodTypeFields({
                      ...periodsTypeFields,
                      periodTypeData: dataValue,
                    });
                  }}
                  filterable={true}
                  autoClose={false}
                  autoBind={true}
                  dataList={originPeriodType}
                  loading={periodsTypeFields.isPeriodTypeDataLoading}
                  appendTo={periodTypeRef.current}
                />
              </div>
            </FieldWrapper>
            <FieldWrapper>
              <div className={"k-form-field-wrap-popup"} ref={effPeriodRef}>
                <Label>{"Effective Period* : "}</Label>
                <CustomDropdown
                  name={"periodsValue"}
                  data={periodsFields.periodsData}
                  textField="text"
                  dataItemKey="id"
                  valueData={originPeriod.find(
                    (c) => c.text === periodsFields.periodsValue.text
                  )}
                  onOpen={(dataValue) => {
                    setPeriodFields({
                      ...periodsFields,
                      periodsData: originPeriod,
                    });
                  }}
                  onChange={(e, name) => {
                    setPeriodFields({
                      ...periodsFields,
                      [name]: e,
                    });
                  }}
                  filterable={true}
                  onFilterChange={(dataValue) => {
                    setPeriodFields({
                      ...periodsFields,
                      periodsData: dataValue,
                    });
                  }}
                  autoClose={false}
                  autoBind={true}
                  isSelectALl={true}
                  dataList={originPeriod}
                  loading={periodsFields.isPeriodDataLoading}
                  appendTo={effPeriodRef.current}
                />
                <ShowError name={"in_period_mstr_key"}></ShowError>
              </div>
            </FieldWrapper>
          </FormElement>
        )}
      />
    );
  };

  const AddNewPopup = () => {
    return (
      <Popup anchor={addRef.current} show={isAddNewConfig} className="right0">
        <div className="dot-dropdown-open dot-dropdown-open-Form">
          <div className="caption">
            <span>Add New Comp Plan</span>
          </div>

          {AddNewForm()}

          <div className="dropdownFooter">
            <div className="k-form-buttons">
              <Button
                primary={true}
                type="submit"
                onClick={() => {
                  onCancelClick();
                }}
              >
                Cancel
              </Button>
              <Button
                primary={true}
                type="submit"
                onClick={() => {
                  handleSubmit(formFields, errorObj);
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </Popup>
    );
  };

  const AddNewLink = () => {
    return (
      <div ref={popupRef}>
        <a
          href="/#"
          className="cta-small"
          ref={addRef}
          onClick={(e) => {
            addNewConfig(e);
          }}
        >
          <i className="fas fa-plus"></i> Add New Plan
        </a>

        {AddNewPopup()}
      </div>
    );
  };

  const handleMenuItemClick = (action) => {
    switch (action) {
      case "auditLogs":
        setShow({
          ...isShow,
          auditLogs: !isShow.auditLogs,
        });
        break;
      case "export":
        setExportDataClick(true);
        break;
      case "delete":
        setShow({
          ...isShow,
          deleteRows: !isShow.deleteRows,
        });
        break;
      default:
        break;
    }
  };

  const handleVersionMenuItemClick = (action) => {
    switch (action) {
      case "dtuLogs":
        setVersionShow({
          ...isVersionShow,
          dtuLogs: !isVersionShow.dtuLogs,
        });
        break;
      case "export":
        setVersionExportDataClick(true);
        break;
      default:
        break;
    }
  };

  const handleUpload = (e) => {
    setVersionShow({
      ...isVersionShow,
      dtuUpload: !isVersionShow.dtuUpload,
    });
    e.preventDefault();
  };

  const headerConfig = {
    title: "Comp Plan",
    id: "plan",
    add_new_fx: AddNewLink,
    action_menu: {
      export: { fx: handleMenuItemClick },
      delete: { fx: handleMenuItemClick },
    },
    manage_col: {
      columnsData: columnsData,
      setColumnsData: setColumnsData,
      setColumnCheck: setColumnCheck,
    },
  };

  const headerAssignmentConfig = {
    title: dataItem.mstr_name
      ? "KPIs-Providers Assignments | " + dataItem.mstr_name
      : "KPIs-Providers Assignments",
    id: "plan-assignment",
    upload_title: "Plan Assignments",
    buttons: { template_fx: GridButton },
    upload_fx: handleUpload,
    action_menu: {
      export: { fx: handleVersionMenuItemClick },
      dtuLog: { fx: handleVersionMenuItemClick },
    },
    manage_col: {
      columnsData: selectedTab === 0 ? kpiColumnsData : providerColumnsData,
      setColumnsData:
        selectedTab === 0 ? setKpiColumnsData : setproviderColumnsData,
      setColumnCheck:
        selectedTab === 0 ? setKpiColumnCheck : setproviderColumnCheck,
    },
  };

  const parent = document.querySelector("#content-" + headerConfig.id);

  if (parent) {
    const exportToExcel = parent.querySelector(".export-to-excel");
    const deleteRows = parent.querySelector(".delete-rows");

    exportToExcel && exportToExcel.disableKendoMenu();
    deleteRows && deleteRows.disableKendoMenu();

    dataList.length && exportToExcel && exportToExcel.enableKendoMenu();

    if (selectedRowId !== "" && dataList.length > 0) {
      deleteRows && deleteRows.enableKendoMenu();
    }
  }
  const assign = document.querySelector(
    "#content-" + headerAssignmentConfig.id
  );

  if (assign) {
    const dtuUpload = assign.querySelector(".upload");
    const actionMenu = assign.querySelector(".hoverMenu");

    if (dtuUpload) {
      dtuUpload.style.display = selectedTab === 0 ? "none" : "block";
    }

    if (actionMenu) {
      actionMenu.style.display = selectedTab === 0 ? "none" : "block";
    }
  }

  const leftPane = () => {
    return (
      <>
        <div className="content-head">
          <HeaderPage pageProps={pageProps} headerConfig={headerConfig} />
        </div>

        <div className="content-body">
          <div className="dtu-file-container">
            <PlanData
              isAPICall={isAPICall}
              setAPICall={setAPICall}
              setExportDataClick={setExportDataClick}
              exportDataClick={exportDataClick}
              selectedRowId={selectedRowId}
              setSelectedRowId={setSelectedRowId}
              setShow={setShow}
              isShow={isShow}
              setDataList={setDataList}
              setisKpiUpdateClick={setisKpiUpdateClick}
              setisProviderUpdateClick={setisProviderUpdateClick}
              setisCancelClick={setisCancelClick}
              setCancelKpiAwaitStatus={setCancelKpiAwaitStatus}
              isKpiEdit={isKpiEdit}
              setisPlanEdit={setisPlanEdit}
              isProviderEdit={isProviderEdit}
              isPlanEdit={isPlanEdit}
              pageProps={pageProps}
              columnsData={columnsData}
              setColumnsData={setColumnsData}
              setColumnCheck={setColumnCheck}
              isCheckColumn={isCheckColumn}
              setRefreshBtnClick={setRefreshBtnClick}
              isRefreshBtnClick={isRefreshBtnClick}
              setPlanGridRefresh={setPlanGridRefresh}
              isEffectiveVersionDelete={isEffectiveVersionDelete}
              setIsEffectiveVersionDelete={setIsEffectiveVersionDelete}
            />
          </div>
        </div>
      </>
    );
  };

  /**
   * call add eff version API
   */
  React.useEffect(() => {
    let masterTableData = dataList.slice();
    const insertPlanVersion = effVersionState.insertPlanVersion;
    if (masterTableData.length > 0 && insertPlanVersion.periodKey) {
      // const mstrDeta = masterTableData[insertPlanVersion.rowId];
      const bodyData = {
        in_comp_plan_mstr_key: dataItem.mstr_key,
        in_select_date_version:
          effVersionDataItem === undefined
            ? dataItem.date_version
            : effVersionDataItem.dateVersion,
        in_comp_plan_name: dataItem.mstr_name,
        in_comp_plan_desc: "",
        in_user_id: cookiesData.out_user_id.toString(),
        in_tenant_id: cookiesData.out_tenant_id,
        in_copy_assignment: insertPlanVersion.copyAssignment,
        in_period_mstr_key: insertPlanVersion.periodKey,
      };

      ActionVersion.addDataAsync({
        api: INSERT_COMP_PLAN,
        bodyData: bodyData,
      });
      setEffectivePlanPopup(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [effVersionState.insertPlanVersion]);

  /**
   * update dataset after insert a new version
   */
  React.useEffect(() => {
    if (listVersion.loadingState.isSaveData === true) {
      getSaveData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listVersion.loadingState.isSaveData]);

  const getSaveData = async () => {
    let masterTableData = dataList.slice();
    // let masterTableData = [];
    const mstrIdx = effVersionState.insertPlanVersion.rowId;
    const mstrDeta = masterTableData[mstrIdx];

    let effVersionResponse = await getEffVersionsApi(
      mstrDeta.comp_plan_mstr_key
    );

    masterTableData[mstrIdx].effVersionDetails = effVersionResponse;

    setEffVersionState({
      ...effVersionState,
      insertPlanVersion: { rowId: -1, periodKey: 0 },
    });
    setData(masterTableData);
    if (mstrDeta.effVersionDetails.data.length > 1) {
      getSelectedData();
    } else {
      setAPICall(true);
    }
    setRefreshLoading(true);
  };

  React.useEffect(() => {
    if (listVersion.loadingState.isDeleteData === true) {
      getDeleteData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listVersion.loadingState.isDeleteData]);

  const getDeleteData = async () => {
    setEffVersionState({
      ...effVersionState,
      deleteVersion: { rowId: -1 },
    });
    let masterTableData = dataList.slice();
    const mstrIdx = effVersionState.deleteVersion.rowId;
    const mstrDeta = masterTableData[mstrIdx];

    let effVersionResponse = await getEffVersionsApi(
      mstrDeta.comp_plan_mstr_key
    );

    // if (!effVersionResponse.data.length) {
    //   onRefreshClick();
    // } else {
    masterTableData[mstrIdx].effVersionDetails = effVersionResponse;
    // }
    setData(masterTableData);
    if (mstrDeta.effVersionDetails.data.length >= 1) {
      getSelectedData();
    } else {
      setAPICall(true);
    }
    setRefreshLoading(true);
  };

  const getEffVersionsApi = async (mstrKey) => {
    setRefreshLoading(true);
    const versionProps = {
      apiEndPoints: { listApi: GET_PLAN_EFF_VERSION },
      dataItem: { mstr_key: mstrKey },
      pageProps: { writeAccess: pageProps.writeAccess },
    };

    const versionApiCallData = {
      in_tenant_id: cookiesData.out_tenant_id,
      in_mstr_key: mstrKey,
      in_sort_number: 1,
      in_sort_order: "ASC",
    };
    setEffVersionLoadState(true);
    //get response for eff version tab
    let effVersionResponse = await ActionVersion.getListDataAsync(
      versionApiCallData,
      versionProps
    );

    const currentEndDate = effVersionResponse.currentVersionEndDate;

    //get response for eff version period menu
    const periodDataResponse = await ActionVersion.getPeriodsDataTypeAsync(
      currentEndDate
    );
    setEffVersionLoadState(false);
    effVersionResponse["periodsData"] = periodDataResponse;

    // const selectedVersion = effVersionResponse.data.filter(
    //   (x) => x.isSelected === 1
    // )[0];

    setRefreshLoading(false);
    return effVersionResponse;
  };

  /**
   * render Eff version Tabstrip and Menu
   */
  const getEffVersions = () => {
    return (
      <>
        {dataItem && (
          <EffectiveVersions
            pageProps={pageProps}
            versionData={dataItem}
            effVersionState={effVersionState}
            setEffVersionState={setEffVersionState}
          />
        )}
      </>
    );
  };

  // function checkAndAssignClass() {
  //   document.querySelectorAll(" .planassignloader").forEach(function (element) {
  //     element.classList.add("assign-nodata");
  //   });
  //   // Create the main overlay div
  //   const overlayDiv = document.createElement("div");
  //   overlayDiv.className = "assign-nodata";

  //   // Create the overlay content div
  //   const overlayContentDiv = document.createElement("div");
  //   overlayContentDiv.className = "overlaycont";

  //   const procareLogoDiv = document.createElement("div");
  //   procareLogoDiv.className = "procarelogomob";

  //   const mobNotificationLogoDiv = document.createElement("div");
  //   mobNotificationLogoDiv.className = "mobnotificationlogo";

  //   const notificationMessage = document.createElement("h3");
  //   notificationMessage.textContent =
  //     "There is no record extis on the left side scrren please clear the filter or refresh the scrren ";

  //   mobNotificationLogoDiv.appendChild(notificationMessage);

  //   overlayContentDiv.appendChild(procareLogoDiv);
  //   overlayContentDiv.appendChild(mobNotificationLogoDiv);

  //   overlayDiv.appendChild(overlayContentDiv);

  //   // Append the main  div to the body or any other desired parent element
  //   document.body.appendChild(overlayDiv);
  // }

  const rightPane = () => {
    if (!dataItem.mstr_key) {
      return <Loader size="large" className="k-loading-procare" />;
    }

    return (
      <>
        <div className="content-head">
          <HeaderPage
            pageProps={pageProps}
            headerConfig={headerAssignmentConfig}
          />
        </div>
        {isEffectiveVersionLoadState && <LoadingPanel />}
        {getEffVersions()}

        <TabStrip
          selected={selectedTab}
          onSelect={handleSelect}
          className="tab-strip-width plantabsinnertabs"
          keepTabsMounted={true}
        >
          <TabStripTab title={"KPIs"} key={"kpi_data"} keepTabsMounted={true}>
            {effVersionDataItem !== undefined &&
              effVersionDataItem.dateVersion && (
                <DataRows
                  pageProps={pageProps}
                  compTableMasterKey={dataItem.mstr_key}
                  effVersionDataItem={effVersionDataItem}
                  selected={selectedTab}
                  isKpiUpdateClick={isKpiUpdateClick}
                  setisKpiUpdateClick={setisKpiUpdateClick}
                  isCancelClick={isCancelClick}
                  setCancelProviderAwaitStatus={setCancelProviderAwaitStatus}
                  cancelKpiAwaitStatus={cancelKpiAwaitStatus}
                  setCancelKpiAwaitStatus={setCancelKpiAwaitStatus}
                  isKpiEdit={isKpiEdit}
                  setisKpiEdit={setisKpiEdit}
                  setScreenLock={setScreenLock}
                  screenLock={screenLock}
                  kpiColumnsData={kpiColumnsData}
                  setKpiColumnsData={setKpiColumnsData}
                  isKpiCheckColumn={isKpiCheckColumn}
                  setKpiColumnCheck={setKpiColumnCheck}
                  dateVersion={effVersionDataItem.dateVersion}
                  isReload={isReload}
                  setReload={setReload}
                  isRefreshBtnClick={isRefreshBtnClick}
                  setRefreshBtnClick={setRefreshBtnClick}
                />
              )}
          </TabStripTab>
          <TabStripTab
            title={"Providers"}
            key={"provider_data"}
            keepTabsMounted={true}
          >
            {effVersionDataItem !== undefined &&
            effVersionDataItem.dateVersion ? (
              <ProviderDataRows
                pageProps={pageProps}
                dateVersion={effVersionDataItem.dateVersion}
                compTableMasterKey={dataItem.mstr_key}
                effVersionDataItem={effVersionDataItem}
                selected={selectedTab}
                isProviderUpdateClick={isProviderUpdateClick}
                cancelProviderAwaitStatus={cancelProviderAwaitStatus}
                setCancelProviderAwaitStatus={setCancelProviderAwaitStatus}
                isProviderEdit={isProviderEdit}
                setisProviderEdit={setisProviderEdit}
                setScreenLock={setScreenLock}
                screenLock={screenLock}
                setVersionExportDataClick={setVersionExportDataClick}
                versionExportDataClick={versionExportDataClick}
                providerColumnsData={providerColumnsData}
                setproviderColumnsData={setproviderColumnsData}
                isproviderCheckColumn={isproviderCheckColumn}
                setproviderColumnCheck={setproviderColumnCheck}
                headerAssignmentConfigId={headerAssignmentConfig.id}
                isReload={isReload}
                setReload={setReload}
                isRefreshBtnClick={isRefreshBtnClick}
                setRefreshBtnClick={setRefreshBtnClick}
              />
            ) : (
              false
            )}
          </TabStripTab>
        </TabStrip>
      </>
    );
  };

  const reloadPlanAssignment = () => {
    setReload({
      ...isReload,
      providerReload: true,
    });
  };

  return (
    <div className="Main mobileblock">
      <div className="overlaymobile">
        <div className="overlaycont">
          <div className="procarelogomob"></div>
          <div className="mobnotificationlogo">
            <h3>Please use tablet or desktop to view this page</h3>
            <button className="primarybtn" href="/dashboard">
              Back to Home
            </button>
          </div>
        </div>
      </div>
      <div className="content">
        <>
          {screenLock ? <div className="k-loading-mask"></div> : false}

          <Splitter
            panes={panes}
            onChange={onPaneChange}
            className="splitviewcustom"
          >
            <div className="pane-content">{leftPane()}</div>
            <div className="pane-content planassignloader">
              {" "}
              {dataList.length == 0 && dataItem.mstr_key ? (
                <div className="assign-nodata">
                  <div className="overlaycont">
                    <div className="procarelogomob"></div>
                    <div className="mobnotificationlogo">
                      <h3>
                        {" "}
                        There is no record on the left side of the screen.
                        Please clear the filter or refresh the screen.
                      </h3>
                    </div>
                  </div>
                </div>
              ) : (
                false
              )}
              {rightPane()}
            </div>
          </Splitter>
        </>
      </div>

      {isVersionShow.dtuLogs && (
        <DtuBatchLogs
          visible={isVersionShow}
          setShow={setVersionShow}
          module={"plan_provider_assignment"}
          pageProps={pageProps}
        />
      )}

      {isShow.auditLogs && (
        <AuditLogs
          visible={isShow.auditLogs}
          setShow={setShow}
          selectedRowId={selectedRowId}
          module={"COMPPLAN"}
          dataItemKey={"in_comp_plan_mstr_key"}
        />
      )}

      {isVersionShow.dtuUpload && (
        <UploadDtu
          visible={isVersionShow}
          setShow={setVersionShow}
          title={"Upload Plan Assignments"}
          pageId={"plan_assignment"}
          setAPICall={reloadPlanAssignment}
        />
      )}
      {effVersionDataItem !== undefined &&
      effVersionDataItem.dateVersion &&
      effVersionState.insertVersion.periodKey ? (
        <AddPlanEffectiveVersion
          periodKey={effVersionState.insertVersion.periodKey}
          rowId={effVersionState.insertVersion.rowId}
          setEffVersionState={setEffVersionState}
          effVersionState={effVersionState}
          setEffectivePlanPopup={setEffectivePlanPopup}
          isAddEffPlanPopup={isAddEffPlanPopup}
          effVersionDataItem={effVersionDataItem}
        />
      ) : (
        false
      )}
    </div>
  );
};

export default React.memo(Plan);
