// eslint-disable-next-line no-useless-concat
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import {
    GridColumn as Column,
    getSelectedState,
} from "@progress/kendo-react-grid";
import { Label } from "@progress/kendo-react-labels";
import { getUserCredential, getTenantCookieData } from "../../lib/cookies";
import { Button } from "@progress/kendo-react-buttons";
import { DEFAULT_SORT } from "../../constants/grid";
import { LoadingPanel } from "../../components/loadingPanel";
import DtuBatchLogs from "../common/dtuBatchLogs";
import { columnMenuProps } from "../../components/customColumnMenu";
import {
    applyFormat,
    executeGetColumnOrderInfo,
    applyDateFormat,
    getfilterDataString,
} from "../../utils/utils";
import { HeaderPage } from "../base/headerPage";
import userPositionApi from "./service";
import ManageCustomField from "../custom-fields/manage-custom-field";
import AddProviderPosition from "./addProviderPosition";
import UploadDtu from "../user/uploadDtu";
import { getter } from "@progress/kendo-react-common";
import { DialogComponent } from "src/components/dialog";
import { DELETE_BTN_ACTIONS } from "src/constants/constants";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import UpdateProviderPosition from "./updateProviderPosition";
import { updateColumnReorderAsync } from "src/lib/common-service";
import { ProviderRowRender } from "src/components/renderers";
import { filterBy } from "@progress/kendo-data-query";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import DataTableV2 from "src/components/dataTableV2";

const DATA_ITEM_KEY = "primary_key";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

const CompData = React.memo(
    (props) => {
        const {
            isAPICalling,
            initialLoading,
            data,
            pageData,
            dataCount,
            customColumn,
            dataItemKey,
            columns,
            defaultPageSize,
            pageSize,
            setPageSize,
            noDataFound,
            isExportDataClick,
            setExportData,
            exportData,
            resetSort,
            fileName,
            reorderable,
            initialFilter,
            initialSort,
            handleColumnMenu,
            columnReorderData,
            onSelectionChange,
            getExportData,
            onClearFilter,
            handlePageChange,
            refreshClick,
            rowRender,
        } = props;

        // Memoize DataTable props to avoid re-creating the object on each render
        const dataTableProps = useMemo(
            () => ({
                isAPICalling,
                initialLoading,
                data,
                pageData,
                dataCount,
                customColumn,
                columns,
                dataItemKey,
                width: "auto",
                defaultPageSize,
                pageSize,
                setPageSize,
                noDataFound,
                isExportDataClick,
                setExportData: setExportData,
                exportData,
                fileName,
                reorderable,
                initialFilter,
                initialSort,
                resetSort,
                handleColumnMenu,
                rowRender: rowRender,
                columnReorderData,
                onSelectionChange: onSelectionChange,
                getExportData,
                onClearFilter: onClearFilter,
                handlePageChange: handlePageChange,
                refreshClick: refreshClick,
                gridHeight: "auto",
            }),
            [
                isAPICalling,
                initialLoading,
                data,
                pageData,
                dataCount,
                customColumn,
                columns,
                dataItemKey,
                defaultPageSize,
                pageSize,
                setPageSize,
                noDataFound,
                isExportDataClick,
                setExportData,
                exportData,
                fileName,
                reorderable,
                initialFilter,
                initialSort,
                resetSort,
                handleColumnMenu,
                columnReorderData,
                onSelectionChange,
                getExportData,
                onClearFilter,
                handlePageChange,
                refreshClick,
                rowRender,
            ]
        );

        return (
            <>
                <DataTableV2 {...dataTableProps} />
            </>
        );
    },
    (prevProps, nextProps) => {
        return (
            prevProps.dataCount === nextProps.dataCount &&
            prevProps.isAPICalling === nextProps.isAPICalling &&
            prevProps.isSelection === nextProps.isSelection &&
            prevProps.isExportDataClick === nextProps.isExportDataClick &&
            prevProps.exportData === nextProps.exportData &&
            prevProps.columns === nextProps.columns &&
            prevProps.isDTUUploadReload === nextProps.isDTUUploadReload
        );
    }
);

const StringReadAndOnlyCell = React.memo((props) => {
    const { ariaColumnIndex, columnIndex, dataItem, field } = props;
    const val = dataItem[field || ""];

    return (
        <td aria-colindex={ariaColumnIndex} data-grid-col-index={columnIndex}>
            <span title={val}>{val}</span>
        </td>
    );
});

const BooleanCell = React.memo((props) => {
    const { ariaColumnIndex, columnIndex, dataItem, field } = props;
    const val = Number(dataItem[field]) ? "true" : "false";

    return (
        <td aria-colindex={ariaColumnIndex} data-grid-col-index={columnIndex}>
            <span title={val}>{val}</span>
        </td>
    );
});

const NumberCell = React.memo((props) => {
    const { ariaColumnIndex, columnIndex, dataItem, field } = props;
    const value = props.dataItem[props.field];
    const format = props.format;
    let formattedValue =
        dataItem[field] === null
            ? ""
            : dataItem[field] === "0.00000000" || dataItem[field] === ""
            ? ""
            : !isNaN(value)
            ? applyFormat(value, format)
            : value;

    return (
        <td aria-colindex={ariaColumnIndex} data-grid-col-index={columnIndex}>
            <span title={formattedValue}>{formattedValue}</span>
        </td>
    );
});

const DateCell = React.memo((props) => {
    const { ariaColumnIndex, columnIndex, dataItem, field } = props;
    const val =
        dataItem[field] && dataItem[field] !== "0000-00-00 00:00:00"
            ? applyDateFormat(dataItem[field], props.format)
            : "";

    return (
        <td aria-colindex={ariaColumnIndex} data-grid-col-index={columnIndex}>
            <span title={val}>{val}</span>
        </td>
    );
});

// Memoize the cellType function
const cellType = (type) => {
    switch (type) {
        case "date":
            return DateCell;
        case "boolean":
            return BooleanCell;
        case "number":
            return NumberCell;
        default:
            return StringReadAndOnlyCell;
    }
};

// useCol Hook with useMemo
const useCol = (columns) => {
    return useMemo(() => {
        return columns.map((column, idx) => {
            if (column.show && column.title !== "") {
                return (
                    <Column
                        key={idx}
                        {...columnMenuProps(column)}
                        sortable={true}
                        width={"150px"}
                        cell={cellType(column.type)}
                        format={column.format}
                    />
                );
            }
            return null;
        });
    }, [columns, cellType]);
};

const procareGetFilterValue = (data, alias) => {
    let tempArray = [];
    data.map((item) => {
        let str = item.id ? `a$${item.id}` : `a$is_null`;
        tempArray.push(str);
        return 0;
    });
    return tempArray.toString();
};

const getKeys = (key, selectedState) => {
    let keys = Object.keys(selectedState);
    let tempArray = [];
    keys.map((item) => {
        let item_Key = item.split("_");
        if (key === "provider") {
            tempArray.push(item_Key[0]);
        } else {
            tempArray.push(item_Key[1]);
        }
        return 0;
    });
    return tempArray;
};

const procareGetNullInFilter = (
    data,
    filterParamName,
    dataType = "string",
    alias = "a"
) => {
    if (!data || data.length === 0) return "";
    const nullKeyword = "a$is_null";
    let nullFilterStr = "";

    const idList = data.map((item) => item.id).join(",");

    if (idList.includes(nullKeyword)) {
        idList.replace(new RegExp(nullKeyword, "g"), "");
        nullFilterStr = ` AND (COALESCE(${alias}.${filterParamName}, ${
            dataType === "string" ? "''" : "0"
        }) ${dataType === "string" ? "= ''" : "= 0"})`;
    }

    return ` AND (${alias}.${filterParamName} IN (${idList}))${nullFilterStr}`;
};

const ProviderPosition = (pageProps) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const divRef = React.createRef();
    const [list, Action] = userPositionApi();
    const cookiesData = getUserCredential();
    const defaultPageSize = getTenantCookieData("record_per_page");
    const {
        setConfirmationPopup,
        confirmationPopup,
        specialtyDataList,
        specialtyDataFields,
        setSpecialtyDataFields,
        groupDataFields,
        groupDataList,
        setGroupDataFields,
        setEmployeeDataFields,
        employeeDataFields,
        employeeDataList,
        npiDataFields,
        npiDataList,
        setNpiDataFields,
        positionDataFields,
        positionDataList,
        setPositionDataFields,
        searchListLoading,
        data,
        columns,
        dataCount,
        setData,
        isAPICalling,
        noDataFound,
        initialLoading,
        setAPICallingState,
        loading,
        setLoadingStateSearch,
        setColumns,
        setSearchListLoading,
        setDataCount,
    } = list;

    const [selectedState, setSelectedState] = React.useState({});
    const [isShow, setShow] = React.useState({
        isManageCustomType: false,
        exportCurrentVersion: false,
        exportAllVersion: false,
    });

    const [filterData, setFilterData] = React.useState({
        isSort: false,
        isFilter: false,
        data: "",
        in_sort_number: "org_position_key",
        sort_order: DEFAULT_SORT.order,
    });
    const [columnsData, setColumnsData] = React.useState([]);
    const [isCheckColumn, setColumnCheck] = React.useState(false);
    const [specialtyValue, setSpecialtyValue] = React.useState([]);
    const [groupValue, setGroupValue] = React.useState([]);
    const [employeeValue, setEmployeeValue] = React.useState([]);
    const [npiValue, setNpiValue] = React.useState([]);
    const [positionValue, setPositionValue] = React.useState([]);
    const [editData, setEditData] = React.useState({
        isEdit: false,
        data: "",
    });
    const [filterField, setFilterField] = React.useState({
        logic: "and",
        filters: [],
    });
    const [sortField, setSortField] = React.useState([]);
    const [mode, setMode] = React.useState("Default");
    const [resetSort, setResetSort] = React.useState(false);
    const [pageData, setPage] = React.useState({
        skip: 0,
        take: defaultPageSize.recordTake,
    });

    const [pageSize, setPageSize] = React.useState(defaultPageSize);
    const [exportDataClick, setExportDataClick] = React.useState(false);
    const [exportData, setExportData] = React.useState([]);
    const [deleteSelectedIds, setDeleteSelectedIds] = React.useState([]);
    const [gridColumns, setGridColumns] = React.useState([]);
    const [isUpdate, setIsUpdate] = React.useState(false);
    const [isOneEffVersion, setIsOneEffVersion] = React.useState(false);
    const [isSearchClick, setSearchClick] = React.useState(false);
    const [loadState, setLoadState] = React.useState({
        isUploadState: false,
    });
    const [selectedRowId, setSelectedRowId] = React.useState("");
    const [searchFields, setSearchFields] = React.useState({});
    const [isAdd, setAdd] = React.useState(false);
    const [isSelection, setSelection] = React.useState(false);
    const [isDTUUploadReload, setDtUUploadReload] = React.useState(false);

    const getInFilter = () => {
        return filterData.isFilter ? filterData.data : null;
    };

    const getInSortNumber = () => {
        return filterData.isSort
            ? filterData.in_sort_number
            : "org_position_key";
    };

    const getInSortOrder = () => {
        return filterData.isSort ? filterData.sort_order : DEFAULT_SORT.order;
    };

    const getAPICallReq = () => {
        const { out_tenant_id, out_user_id, out_acct_encrypt_key } =
            cookiesData;
        let apiCallData = {
            in_tenant_id: out_tenant_id,
            in_acct_encrypt_key: out_acct_encrypt_key,
            in_user_key: out_user_id,
            in_user_role_filter: ">0",
            in_filter: getInFilter(),
            in_sort_number: getInSortNumber(),
            in_sort_order: getInSortOrder(),
            in_page_row_offset: getPageSkip(),
            in_page_rows: getPageTake(),
            in_export_flag: 0,
            in_is_current_flag: 1,
            in_title_filter:
                searchFields?.groupValue.filter((x) => x.text !== "Select All")
                    .length === 0
                    ? null
                    : procareGetNullInFilter(
                          searchFields?.groupValue,
                          "org_title_key",
                          "number",
                          "a"
                      ),
            in_specialty_filter:
                searchFields?.specialtyValue.filter(
                    (x) => x.text !== "Select All"
                ).length === 0
                    ? null
                    : procareGetNullInFilter(
                          searchFields?.specialtyValue,
                          "specialty_mstr_key",
                          "number",
                          "a"
                      ),
            in_employee_id_filter:
                searchFields?.employeeValue.filter(
                    (x) => x.text !== "Select All"
                ).length === 0
                    ? null
                    : procareGetFilterValue(
                          searchFields?.employeeValue.filter(
                              (x) => x.text !== "Select All"
                          ),
                          "a"
                      ),
            in_npi_filter:
                searchFields?.npiValue.filter((x) => x.text !== "Select All")
                    .length === 0
                    ? null
                    : procareGetFilterValue(
                          searchFields?.npiValue.filter(
                              (x) => x.text !== "Select All"
                          ),
                          "a"
                      ),
            in_position_filter:
                searchFields?.positionValue.filter(
                    (x) => x.text !== "Select All"
                ).length === 0
                    ? null
                    : procareGetNullInFilter(
                          searchFields?.positionValue,
                          "org_position_key",
                          "number",
                          "a"
                      ),
        };
        return apiCallData;
    };

    React.useEffect(() => {
        if (columns.length > 0) {
            setColumnsData(columns);
            setGridColumns(columns);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columns]);

    React.useEffect(() => {
        const parent = document.querySelector("#content-" + headerConfig.id);

        if (parent) {
            const exportAllVersion = parent.querySelector(".export-to-excel");
            const exportCurrentVersion =
                parent.querySelector(".export-to-excel1");
            const deleteRows = parent.querySelector(".delete-rows");
            exportAllVersion && exportAllVersion.disableKendoMenu();
            exportCurrentVersion && exportCurrentVersion.disableKendoMenu();
            deleteRows && deleteRows.disableKendoMenu();
            data.length &&
                exportAllVersion &&
                exportAllVersion.enableKendoMenu();
            data.length &&
                exportCurrentVersion &&
                exportCurrentVersion.enableKendoMenu();
            if (selectedRowId) {
                deleteRows && deleteRows.enableKendoMenu();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mode, data, selectedRowId]);

    React.useEffect(() => {
        if (isCheckColumn) {
            if (pageProps.writeAccess === 0) {
                return false;
            }
            updateColumDataAsync();
            // setGridColumns(columnsData);
            // setColumnCheck(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCheckColumn]);

    const updateColumDataAsync = async () => {
        setLoadingStateSearch({ ...loading, updateColumn: true });
        let columnString = prepareColumnString(columnsData);
        let reqData = {
            in_db_name: "procare_system",
            in_tenant_id: cookiesData.out_tenant_id,
            in_filter_logical_cond: "N",
            in_filter_label_cond: "N",
            in_column_info: columnString,
        };
        let res = await updateColumnReorderAsync(reqData, dispatch, history);
        if (res) {
            setLoadingStateSearch({ ...loading, updateColumn: false });
            setColumns(columnsData);
            setColumnCheck(false);
        } else {
            setLoadingStateSearch({ ...loading, updateColumn: false });
        }
    };

    React.useEffect(() => {
        if (isOneEffVersion) {
            getProviderList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOneEffVersion]);

    const getProviderList = async () => {
        setData([...data.filter((x) => x.primary_key !== selectedRowId)]);
        setDataCount(dataCount - 1);
        setIsOneEffVersion(false);
        setSearchListLoading(false);
        setAPICallingState(true);
        let reqData = await getAPICallReq();
        await Action.getListDataAsync(reqData);
        setSelectedRowId("");
        setDeleteSelectedIds([]);
        setSelectedState({});
        setSearchListLoading(false);
    };

    React.useEffect(() => {
        if (isUpdate) {
            getUpdateProviders();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUpdate]);

    const getUpdateProviders = async () => {
        setIsUpdate(false);
        setLoadState((prevState) => ({ ...prevState, isUploadState: true }));
        fetchAllDataLists();
        let reqData = {
            ...getAPICallReq(),
        };
        let res = await Action.getListDataAsync(reqData, "update");
        setSelectedRowId("");
        setPageMenuState(res);
        setLoadState({ ...loadState, isUploadState: false });
        setSearchListLoading(false);
    };

    const setPageMenuState = (response) => {
        if (!deleteSelectedIds.length) {
            return false;
        }

        let ids = response.map((el) => el[DATA_ITEM_KEY]);

        let selectedKeys = [];
        let tempArray = [];
        let tempArrayData = [];

        Object.keys(selectedState).map((item) => {
            let obj = {};

            obj.id = Number(item);
            selectedKeys.push(item);
            tempArray.push(obj);

            return 0;
        });

        ids.map((item) => {
            if (selectedKeys.includes(item.toString())) {
                tempArrayData.push(item);
            }
            return 0;
        });

        let strSelectedKeys = "";

        if (tempArrayData.length > 0) {
            strSelectedKeys = parseInt(selectedKeys[0]);
        }
        setSelectedRowId(strSelectedKeys);
    };

    const getPageTake = () => {
        return pageData.take;
    };
    const getPageSkip = () => {
        return pageData.skip;
    };

    React.useEffect(() => {
        if (isAdd && !isSearchClick) {
            setAdd(false);
            setMode("Default");
            ["specialty", "employee", "npi", "position"].forEach(
                handleOpenDropdown
            );
        } else if (isAdd && isSearchClick) {
            setAdd(false);
            setMode("Default");
            addProviders();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAdd]);

    const fetchAllDataLists = async () => {
        await Promise.all([
            Action.getSpecialtyDataList(
                specialtyDataFields.specialtyDataValue,
                groupDataFields.groupDataValue,
                "add-update"
            ),
            Action.getEmployeeDataList(
                specialtyDataFields.specialtyDataValue,
                groupDataFields.groupDataValue,
                "add-update"
            ),
            Action.getNpiDataList(
                specialtyDataFields.specialtyDataValue,
                groupDataFields.groupDataValue,
                "add-update"
            ),
            Action.getPostionDataList(
                specialtyDataFields.specialtyDataValue,
                groupDataFields.groupDataValue,
                "add-update"
            ),
        ]);
    };

    const addProviders = async () => {
        setLoadState((prevState) => ({ ...prevState, isUploadState: true }));
        fetchAllDataLists();
        let reqData = await getAPICallReq();
        await Action.getListDataAsync(reqData);
        setLoadState({ ...loadState, isUploadState: false });
        setSearchListLoading(false);
    };

    React.useEffect(() => {
        ["specialty", "employee", "npi", "position"].forEach(
            handleOpenDropdown
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOpenDropdown = async (module) => {
        switch (module) {
            case "specialty":
                await Action.getSpecialtyDataList();
                break;
            case "employee":
                await Action.getEmployeeDataList(
                    specialtyDataFields.specialtyDataValue,
                    groupDataFields.groupDataValue
                );
                break;
            case "npi":
                await Action.getNpiDataList(
                    specialtyDataFields.specialtyDataValue,
                    groupDataFields.groupDataValue
                );
                break;
            case "position":
                await Action.getPostionDataList(
                    specialtyDataFields.specialtyDataValue,
                    groupDataFields.groupDataValue
                );
                break;
            default:
                break;
        }
    };

    const onPositionClick = async () => {
        setSearchClick(true);
        setFilterField({ logic: "and", filters: [] });
        setSortField([]);
        setPageSize(defaultPageSize);
        setPage({
            skip: 0,
            take: defaultPageSize.recordTake,
        });
        setDataCount(0);
        setSelectedRowId("");
        setDeleteSelectedIds([]);
        setSelectedState({});
        setData([]);
        setColumns([]);
        setFilterData({
            isSort: false,
            isFilter: false,
            data: "",
            in_sort_number: "org_position_key",
            sort_order: DEFAULT_SORT.order,
        });
        setMode("Default");
        setLoadingStateSearch({ ...loading, isSearchLoader: true });
        const { out_tenant_id, out_user_id, out_acct_encrypt_key } =
            cookiesData;
        let apiCallData = {
            in_tenant_id: out_tenant_id,
            in_acct_encrypt_key: out_acct_encrypt_key,
            in_user_key: out_user_id,
            in_user_role_filter: ">0",
            in_export_flag: 0,
            in_is_current_flag: 1,
            in_filter: null,
            in_sort_number: "org_position_key",
            in_sort_order: DEFAULT_SORT.order,
            in_page_row_offset: 0,
            in_page_rows: defaultPageSize.recordTake,
            in_title_filter:
                groupValue.filter((x) => x.text !== "Select All").length === 0
                    ? null
                    : procareGetNullInFilter(
                          groupValue,
                          "org_title_key",
                          "number",
                          "a"
                      ),
            in_specialty_filter:
                specialtyValue.filter((x) => x.text !== "Select All").length ===
                0
                    ? null
                    : procareGetNullInFilter(
                          specialtyValue,
                          "specialty_mstr_key",
                          "number",
                          "a"
                      ),
            in_employee_id_filter:
                employeeValue.filter((x) => x.text !== "Select All").length ===
                0
                    ? null
                    : procareGetFilterValue(
                          employeeValue.filter((x) => x.text !== "Select All"),
                          "a"
                      ),
            in_npi_filter:
                npiValue.filter((x) => x.text !== "Select All").length === 0
                    ? null
                    : procareGetFilterValue(
                          npiValue.filter((x) => x.text !== "Select All"),
                          "a"
                      ),
            in_position_filter:
                positionValue.filter((x) => x.text !== "Select All").length ===
                0
                    ? null
                    : procareGetNullInFilter(
                          positionValue,
                          "org_position_key",
                          "number",
                          "a"
                      ),
        };

        if (groupValue.filter((x) => x.text !== "Select All").length > 0) {
            apiCallData.in_filter = " AND org_position_key  > 0 ";
        }

        if (specialtyValue.filter((x) => x.text !== "Select All").length > 0) {
            apiCallData.in_filter = " AND org_position_key  > 0 ";
        }

        if (npiValue.filter((x) => x.text !== "Select All").length > 0) {
            apiCallData.in_filter = " AND provider_mstr_key  > 0 ";
        }

        if (positionValue.filter((x) => x.text !== "Select All").length > 0) {
            apiCallData.in_filter = " AND org_position_key  > 0 ";
        }

        if (employeeValue.filter((x) => x.text !== "Select All").length > 0) {
            apiCallData.in_filter = " AND provider_mstr_key  > 0 ";
        }

        setSearchFields({
            ...searchFields,
            employeeValue: employeeValue,
            npiValue: npiValue,
            positionValue: positionValue,
            groupValue: groupValue,
            specialtyValue: specialtyValue,
        });
        await Action.getListDataAsync(apiCallData);
    };

    const getGridData = async (reqData, module) => {
        setResetSort(false);
        let response = await Action.getListDataAsync(reqData, module);
        setPageMenuState(response);
    };

    const handlePageChange = async (event) => {
        const pageData = event.page;

        setAPICallingState(true);
        setPage({ skip: pageData.skip, take: pageData.take });
        const dataVal = {
            ...getAPICallReq(),
            in_page_row_offset: pageData.skip,
            in_page_rows: pageData.take,
        };
        getGridData(dataVal, "page");
    };

    const onRefreshClick = () => {
        setAPICallingState(true);
        let reqData = getAPICallReq();
        getGridData(reqData);
    };

    const onRefreshOnManualUpload = async () => {
        if (isSearchClick) {
            setDtUUploadReload(true);
            setAPICallingState(true);
            let reqData = getAPICallReq();
            await getGridData(reqData);
            setDtUUploadReload(false);
        }
    };

    const onClearFilter = () => {
        setFilterField({ logic: "and", filters: [] });
        setAPICallingState(true);
        let dataVal = {
            ...getAPICallReq(),
            in_filter: null,
            in_sort_number: getInSortNumber(),
            in_sort_order: getInSortOrder(),
            in_page_row_offset: getPageSkip(),
            in_page_rows: getPageTake(),
        };
        //set filter to false
        setFilterData({
            ...filterData,
            isFilter: false,
            data: "",
        });

        getGridData(dataVal);
    };

    const onRowDoubleClick = (props) => {
        // setAPICallingState(true)
        setEditData({
            ...editData,
            isEdit: true,
            data: props.originalProps.dataItem,
        });
        setMode("Edit");
        setSelection(false);
    };
    let CustomColumn = useCol(gridColumns);

    const handleColumnMenu = (columnMenuData, isFilter, isSort, filterObj) => {
        let filterSkip = getPageSkip();
        let filterTake = getPageTake();
        setAPICallingState(true);
        if (isSort) {
            setSortField(columnMenuData);
        } else {
            filterSkip = 0;
            filterTake = pageSize.recordTake;
            setFilterField(filterObj);
            setPage({ skip: filterSkip, take: filterTake });
        }
        let index = "org_position_key";
        if (data.length > 0 && columnMenuData[0] !== undefined) {
            index = columnMenuData[0].field;
        }

        let filterString = isFilter
            ? getfilterDataString(
                  columnMenuData,
                  gridColumns,
                  "provider-position"
              )
            : filterData.data;

        isFilter = false;
        if (filterString !== "") {
            isFilter = true;
        }

        let sortDir =
            filterData.isSort && columnMenuData.length
                ? filterData.sort_order
                : DEFAULT_SORT.order;
        let sortNumber =
            filterData.isSort && columnMenuData.length
                ? filterData.in_sort_number
                : "org_position_key";

        isSort = !columnMenuData.length ? false : filterData.isSort;

        if (
            columnMenuData[0] !== undefined &&
            columnMenuData[0].dir !== undefined
        ) {
            isSort = true;
            sortDir = columnMenuData[0].dir.toUpperCase();
            sortNumber = index;
        }

        let checkPrPos = [];
        columnMenuData.map((filterItem, j) => {
            const filters = filterItem.filters;
            let thisFilter = filters[0];
            let columnObj = gridColumns.find(
                (o) => o.field === thisFilter.field
            );
            checkPrPos.push(columnObj);
        });

        if (
            checkPrPos.filter(
                (x) =>
                    x.gname === "vw_provider_master" &&
                    x.gname === "vw_org_position"
            ).length > 0
        ) {
            filterString = filterString;
        } else if (
            checkPrPos.filter((x) => x.gname === "vw_provider_master").length >
            0
        ) {
            filterString = filterString + " AND provider_mstr_key  > 0 ";
        } else if (
            checkPrPos.filter((x) => x.gname === "vw_org_position").length > 0
        ) {
            filterString = filterString + " AND org_position_key  > 0 ";
        }

        setFilterData({
            ...filterData,
            isFilter: isFilter,
            isSort: isSort,
            data: filterString,
            in_sort_number: sortNumber,
            sort_order: sortDir,
        });

        let dataVal = {
            ...getAPICallReq(),
            in_filter: isFilter ? filterString : null,
            in_sort_number: sortNumber,
            in_sort_order: sortDir,
            in_page_row_offset: filterSkip,
            in_page_rows: filterTake,
        };
        getGridData(
            dataVal,
            !isFilter ? (isSort ? "update" : "update") : "filter"
        );
    };

    const onDeleteConfirm = async () => {
        let newProviderArray = [];
        let newPositionArray = [];
        data.map((item) => {
            if (
                getKeys("provider", selectedState).includes(
                    item.provider_mstr_key.toString()
                )
            ) {
                if (item.org_position_key.toString() === "0") {
                    let obj = {
                        id: item.provider_mstr_key,
                        date_version: null,
                        position_name: item.position_name,
                    };
                    newProviderArray.push(obj);
                }
            }

            if (
                getKeys("position", selectedState).includes(
                    item.org_position_key.toString()
                )
            ) {
                if (item.org_position_key.toString() !== "0") {
                    let obj = {
                        id: item.org_position_key,
                        date_version: null,
                        position_name: item.position_name,
                    };
                    newPositionArray.push(obj);
                }
            }

            return 0;
        });

        let deletePositionData = {
            in_user_id: cookiesData.out_user_id.toString(),
            in_org_position_key: newPositionArray,
            in_tenant_id: cookiesData.out_tenant_id,
        };

        let deleteProviderData = {
            in_user_id: cookiesData.out_user_id.toString(),
            in_provider_mstr_key: newProviderArray,
            in_tenant_id: cookiesData.out_tenant_id,
        };

        setConfirmationPopup({
            title: "Delete",
            desc: "Are you sure you want to delete this record?",
            isVisible: false,
            type: "",
            actionBtn: [],
            data: {},
            action: "Delete",
        });

        if (newProviderArray.length > 0) {
            let resProvider = await Action.deleteProvider(
                deleteProviderData,
                dispatch,
                history
            );
            if (resProvider) {
                let reqData = {
                    ...getAPICallReq(),
                    in_sort_number: "org_position_key",
                };
                setSelectedRowId("");
                setSelectedState({});
                setDeleteSelectedIds([]);
                setAPICallingState(true);
                //  setResetSort(false);
                await Action.getListDataAsync(reqData);
                setSelection(false);
            } else {
                setSelectedRowId("");
                setSelectedState({});
                setDeleteSelectedIds([]);
                setSelection(false);
            }
        }

        if (newPositionArray.length > 0) {
            let res = await Action.deletePosition(
                deletePositionData,
                dispatch,
                history
            );
            let reqData = {
                ...getAPICallReq(),
                in_sort_number: "org_position_key",
            };
            if (res) {
                setSelectedRowId("");
                setSelectedState({});
                setDeleteSelectedIds([]);
                setAPICallingState(true);
                //  setResetSort(false);
                await Action.getListDataAsync(reqData);
                setSelection(false);
            } else {
                setSelectedRowId("");
                setSelectedState({});
                setDeleteSelectedIds([]);
                setSelection(false);
            }
        }
    };

    const handleMenuItemClick = (module) => {
        switch (module) {
            case "manageCustomType":
                setShow({
                    ...isShow,
                    isManageCustomType: !isShow.isManageCustomType,
                });
                break;
            case "dtuLogs":
                setShow({
                    ...isShow,
                    dtuLogs: !isShow.dtuLogs,
                });
                break;
            case "exportAllVersion":
                setShow({
                    ...isShow,
                    exportCurrentVersion: false,
                    exportAllVersion: true,
                });
                setExportDataClick(true);
                break;
            case "exportCurrentVersion":
                setShow({
                    ...isShow,
                    exportAllVersion: false,
                    exportCurrentVersion: true,
                });
                setExportDataClick(true);
                break;
            case "delete":
                setConfirmationPopup({
                    ...confirmationPopup,
                    isVisible: true,
                    type: "DELETE_CONFIRM",
                    actionBtn: DELETE_BTN_ACTIONS(
                        onCancelConfirm,
                        onDeleteConfirm
                    ),
                    data: [],
                    action: "Delete",
                });
                break;
            default:
                break;
        }
    };

    const getExportData = async () => {
        let apiCallData = {
            ...getAPICallReq(),
            in_is_current_flag: isShow.exportAllVersion ? 0 : 1,
            in_page_rows: dataCount,
            in_export_flag: 1,
        };

        if (filterData.isFilter === false) {
            if (
                searchFields.groupValue.filter((x) => x.text !== "Select All")
                    .length > 0
            ) {
                apiCallData.in_filter = " AND org_position_key  > 0 ";
            }

            if (
                searchFields.specialtyValue.filter(
                    (x) => x.text !== "Select All"
                ).length > 0
            ) {
                apiCallData.in_filter = " AND org_position_key  > 0 ";
            }

            if (
                searchFields.npiValue.filter((x) => x.text !== "Select All")
                    .length > 0
            ) {
                apiCallData.in_filter = " AND provider_mstr_key  > 0 ";
            }

            if (
                searchFields.positionValue.filter(
                    (x) => x.text !== "Select All"
                ).length > 0
            ) {
                apiCallData.in_filter = " AND org_position_key  > 0 ";
            }

            if (
                searchFields.employeeValue.filter(
                    (x) => x.text !== "Select All"
                ).length > 0
            ) {
                apiCallData.in_filter = " AND provider_mstr_key  > 0 ";
            }
        }

        let response = await Action.getExportListDataAsync(apiCallData);
        if (response.data.length > 0) {
            response.columData.map((item) => {
                if (item.type === "date") {
                    response.data.forEach((rItem) => {
                        if (rItem[item.field]) {
                            if (
                                rItem[item.field] === "0000-00-00 00:00:00" ||
                                rItem[item.field] === null ||
                                rItem[item.field] === ""
                            ) {
                                rItem[item.field] = "";
                            } else {
                                rItem[item.field] = applyDateFormat(
                                    rItem[item.field],
                                    item.format
                                );
                            }
                        }
                    });
                }

                if (item.type === "number") {
                    response.data.forEach((rItem) => {
                        rItem[item.field] =
                            rItem[item.field] === "0.00000000"
                                ? ""
                                : rItem[item.field] === "0"
                                ? Number(rItem[item.field])
                                : Number(rItem[item.field]) || "";
                    });
                }

                if (item.type === "boolean") {
                    response.data.forEach((rItem) => {
                        rItem[item.field] = Number(rItem[item.field])
                            ? "true"
                            : "false";
                    });
                }
                return 0;
            });
            setExportData(response.data);
            setExportDataClick(false);
        }
    };

    const AddNewLink = React.memo(() => {
        return (
            <div>
                <a
                    className="cta-small"
                    onClick={(e) => {
                        setMode("Add");
                    }}
                >
                    <i className="fas fa-plus"></i> Add New Provider Position
                </a>
            </div>
        );
    });

    const handleUpload = (e) => {
        setShow({
            ...isShow,
            dtuUpload: !isShow.dtuUpload,
        });
        e.preventDefault();
    };

    const headerConfig = {
        title: "Provider-Position",
        id: "provider-position",
        add_new_fx: AddNewLink,
        upload_fx: handleUpload,
        action_menu: {
            dtuLog: { fx: handleMenuItemClick },
            exportAllVersion: { fx: handleMenuItemClick },
            exportCurrentVersion: { fx: handleMenuItemClick },
            delete: { fx: handleMenuItemClick },
            manageType: {
                fx: handleMenuItemClick,
                items: [
                    {
                        caption: "Manage Custom Fields",
                        module: "manageCustomType",
                    },
                ],
            },
        },
        ...(pageProps.writeAccess === 1 && {
            manage_col: {
                columnsData: columnsData,
                setColumnsData: setColumnsData,
                setColumnCheck: setColumnCheck,
            },
        }),
    };

    const onSelectionChange = (event) => {
        setSelection(false);
        const newSelectedState = getSelectedState({
            event,
            selectedState: selectedState,
            dataItemKey: DATA_ITEM_KEY,
        });

        let tempArray = [];

        Object.keys(newSelectedState).map((item) => {
            if (newSelectedState[item] === false) {
                return 0;
            }
            let obj = {};
            obj.id = item;
            obj.date_version = item.date_version;

            tempArray.push(obj);
            return 0;
        });

        const newSelectedStateKeys = Object.keys(newSelectedState);

        const selectedKeyArr = newSelectedStateKeys.filter(function (x) {
            return newSelectedState[x] !== false;
        });

        let newData = data.map((item) => ({
            ...item,
            selected: selectedKeyArr.includes(
                item.provider_mstr_key + "_" + item.org_position_key
            ),
        }));
        setSelectedRowId(selectedKeyArr[0]);
        setData(newData);
        setDataCount(dataCount);
        setSelectedState(newSelectedState);
        setDeleteSelectedIds(tempArray);
        setSelection(true);
    };

    const onCancelConfirm = () => {
        setConfirmationPopup({
            ...confirmationPopup,
            isVisible: false,
            type: "",
            actionBtn: [],
            data: {},
            action: "",
        });
        data.map((item) => {
            item.selected = false;
            return 0;
        });
        setData([...data]);
    };

    const prepareColumnString = (colData) => {
        return executeGetColumnOrderInfo(colData, "provider-position");
    };

    const columnReorderData = async (columnData) => {
        if (pageProps.writeAccess === 0) {
            return false;
        }
        const str = prepareColumnString(columnData);
        setLoadingStateSearch({ ...loading, updateColumn: true });
        let reqData = {
            in_db_name: "procare_system",
            in_tenant_id: cookiesData.out_tenant_id,
            in_filter_logical_cond: "N",
            in_filter_label_cond: "N",
            in_column_info: str,
        };
        let res = await updateColumnReorderAsync(reqData, dispatch, history);
        if (res) {
            setLoadingStateSearch({ ...loading, updateColumn: false });
            let colDataList = JSON.parse(JSON.stringify(columnData));
            colDataList.forEach((item) => {
                item.hidden = false;
            });
            const mergedArray = columns.map((item1) => {
                const item2 = colDataList.find(
                    (item) => item.field === item1.field
                );
                return {
                    ...item1,
                    ...item2,
                };
            });
            setColumns([]);
            let colData = mergedArray.sort(function (a, b) {
                return a?.ariaColumnIndex - b?.ariaColumnIndex;
            });
            setColumnsData(colData);
            setColumns(colData);
        } else {
            setLoadingStateSearch({ ...loading, updateColumn: false });
        }
    };

    const customRowRender = (tr, props) => (
        <ProviderRowRender
            originalProps={props}
            tr={tr}
            onRowDoubleClick={onRowDoubleClick}
            title={"Double click to edit record"}
        />
    );

    const handleOnChange = async (
        e,
        name,
        setDataFields,
        dataList,
        dataFields
    ) => {
        let dataValue = [];
        if (e.length === 1 && e.some((x) => x.text === "Select All")) {
            dataValue.push({ text: "Select All", id: "Select All" });
        } else {
            dataValue = [...e];
        }
        setDataFields({
            ...dataFields,
            [name]: dataValue,
        });

        if (name === "specialtyDataValue") {
            setSpecialtyValue(e);
            setGroupValue([]);
            setEmployeeValue([]);
            setNpiValue([]);
            setPositionValue([]);
            await Action.getGroupDataList(e);
            await Action.getEmployeeDataList(e);
            await Action.getNpiDataList(e);
            await Action.getPostionDataList(e);
        } else if (name === "groupDataValue") {
            setGroupValue(e);
            setEmployeeValue([]);
            setNpiValue([]);
            setPositionValue([]);
            await Action.getEmployeeDataList(
                specialtyDataFields.specialtyDataValue,
                e
            );
            await Action.getNpiDataList(
                specialtyDataFields.specialtyDataValue,
                e
            );
            await Action.getPostionDataList(
                specialtyDataFields.specialtyDataValue,
                e
            );
        } else if (name === "employeeDataValue") {
            setEmployeeValue(e);
        } else if (name === "npiDataValue") {
            setNpiValue(e);
        } else if (name === "positionDataValue") {
            setPositionValue(e);
        }
    };

    const filterChange = (event, setDataFields, moduleData, key) => {
        let dataValue = filterBy(moduleData.slice(), event.filter);
        setDataFields((prev) => ({
            ...prev,
            [`${key}`]: dataValue,
            filterValue: event.filter.value,
            search: event.filter,
        }));
    };

    const onOpenChange = (
        setDataFields,
        moduleData,
        key,
        dataFields,
        dataFieldsValue
    ) => {
        let dataValue =
            dataFields.filterValue && dataFields.filterValue !== ""
                ? filterBy(dataFieldsValue.slice(), dataFields.search)
                : moduleData;
        setDataFields((prev) => ({
            ...prev,
            [`${key}`]: dataValue,
        }));
    };

    const handleChangeDropdownValue = (
        e,
        name,
        setDataFields,
        dataList,
        valueData,
        dataFields
    ) => {
        if (
            valueData &&
            valueData.filter((x) => x.text === "Select All").length > 0
        ) {
            let index = e.value.findIndex((x) => x.text === "Select All");
            e.value.splice(index, 1);
        } else if (
            e.value &&
            e.value.filter((x) => x.text === "Select All").length > 0 &&
            e.value.length > 1
        ) {
            e.value = [];
            e.value.push({ text: "Select All", id: "Select All" });
        }
        if (e.value.length === 0) {
            e.value.push({ text: "Select All", id: "Select All" });
        }

        handleOnChange(e.value, name, setDataFields, dataList, dataFields);
    };

    const memoizedData = useMemo(
        () =>
            data.map((item) => ({
                ...item,
                [SELECTED_FIELD]: selectedState[idGetter(item)],
            })),
        [data, selectedState]
    );

    const handleBlurDropdown = (setDataFields) => {
        setDataFields((prev) => ({
            ...prev,
            filterValue: "",
            search: "",
        }));
    };

    return (
        <>
            <div className="Main providerpositionaling">
                {mode === "Default" ? (
                    <>
                        <div className="content">
                            <div className="content-head ">
                                <HeaderPage
                                    pageProps={pageProps}
                                    headerConfig={headerConfig}
                                />
                            </div>
                            <div className="content-body">
                                <>
                                    <ul className="Filter-links">
                                        <div
                                            className="k-form-field-wrap-popup valueDropdown"
                                            ref={divRef}
                                        >
                                            <Label>Specialty:</Label>
                                            <MultiSelect
                                                name="specialtyDataValue"
                                                dataItemKey="id"
                                                textField="text"
                                                data={
                                                    specialtyDataFields.specialtyData
                                                }
                                                filterable
                                                value={
                                                    specialtyDataFields.specialtyDataValue
                                                }
                                                autoClose={false}
                                                filter={
                                                    specialtyDataFields.filterValue
                                                }
                                                loading={
                                                    specialtyDataFields.isSpecialtyDataLoading
                                                }
                                                disabled={
                                                    specialtyDataFields.isSpecialtyDataLoading
                                                }
                                                onOpen={() =>
                                                    onOpenChange(
                                                        setSpecialtyDataFields,
                                                        specialtyDataList,
                                                        "specialtyData",
                                                        specialtyDataFields,
                                                        specialtyDataFields.specialtyDataValue
                                                    )
                                                }
                                                onChange={(e) =>
                                                    handleChangeDropdownValue(
                                                        e,
                                                        "specialtyDataValue",
                                                        setSpecialtyDataFields,
                                                        specialtyDataList,
                                                        specialtyDataFields.specialtyDataValue,
                                                        specialtyDataFields
                                                    )
                                                }
                                                onFilterChange={(e) =>
                                                    filterChange(
                                                        e,
                                                        setSpecialtyDataFields,
                                                        specialtyDataList,
                                                        "specialtyData"
                                                    )
                                                }
                                                onBlur={() =>
                                                    handleBlurDropdown(
                                                        setSpecialtyDataFields,
                                                        "specialtyData"
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className="k-form-field-wrap-popup valueDropdown">
                                            <Label>Group:</Label>
                                            <MultiSelect
                                                name="groupDataValue"
                                                dataItemKey="id"
                                                textField="text"
                                                data={groupDataFields.groupData}
                                                filterable
                                                value={
                                                    groupDataFields.groupDataValue
                                                }
                                                autoClose={false}
                                                filter={
                                                    groupDataFields.filterValue
                                                }
                                                loading={
                                                    groupDataFields.isGroupDataLoading
                                                }
                                                disabled={
                                                    groupDataFields.isGroupDataLoading
                                                }
                                                onOpen={() =>
                                                    onOpenChange(
                                                        setGroupDataFields,
                                                        groupDataList,
                                                        "groupData",
                                                        groupDataFields,
                                                        groupDataFields.groupDataValue
                                                    )
                                                }
                                                onChange={(e) =>
                                                    handleChangeDropdownValue(
                                                        e,
                                                        "groupDataValue",
                                                        setGroupDataFields,
                                                        groupDataList,
                                                        groupDataFields.groupDataValue,
                                                        groupDataFields
                                                    )
                                                }
                                                onFilterChange={(e) =>
                                                    filterChange(
                                                        e,
                                                        setGroupDataFields,
                                                        groupDataList,
                                                        "groupData"
                                                    )
                                                }
                                                onBlur={() =>
                                                    handleBlurDropdown(
                                                        setGroupDataFields,
                                                        "groupData"
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className="k-form-field-wrap-popup valueDropdown">
                                            <Label>Employee ID:</Label>
                                            <MultiSelect
                                                name="employeeDataValue"
                                                dataItemKey="id"
                                                textField="text"
                                                data={
                                                    employeeDataFields.employeeData
                                                }
                                                filterable
                                                filter={
                                                    employeeDataFields.filterValue
                                                }
                                                value={
                                                    employeeDataFields.employeeDataValue
                                                }
                                                autoClose={false}
                                                loading={
                                                    employeeDataFields.isEmployeeDataLoading
                                                }
                                                disabled={
                                                    employeeDataFields.isEmployeeDataLoading
                                                }
                                                onOpen={() =>
                                                    onOpenChange(
                                                        setEmployeeDataFields,
                                                        employeeDataList,
                                                        "employeeData",
                                                        employeeDataFields,
                                                        employeeDataFields.employeeDataValue
                                                    )
                                                }
                                                onChange={(e) =>
                                                    handleChangeDropdownValue(
                                                        e,
                                                        "employeeDataValue",
                                                        setEmployeeDataFields,
                                                        employeeDataList,
                                                        employeeDataFields.employeeDataValue,
                                                        employeeDataFields
                                                    )
                                                }
                                                onFilterChange={(e) =>
                                                    filterChange(
                                                        e,
                                                        setEmployeeDataFields,
                                                        employeeDataList,
                                                        "employeeData"
                                                    )
                                                }
                                                onBlur={() =>
                                                    handleBlurDropdown(
                                                        setEmployeeDataFields,
                                                        "employeeData"
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className="k-form-field-wrap-popup valueDropdown">
                                            <Label>NPI:</Label>
                                            <MultiSelect
                                                name="npiDataValue"
                                                dataItemKey="id"
                                                textField="text"
                                                data={npiDataFields.npiData}
                                                filterable
                                                value={
                                                    npiDataFields.npiDataValue
                                                }
                                                autoClose={false}
                                                filter={
                                                    npiDataFields.filterValue
                                                }
                                                loading={
                                                    npiDataFields.isNpiDataLoading
                                                }
                                                disabled={
                                                    npiDataFields.isNpiDataLoading
                                                }
                                                onOpen={() =>
                                                    onOpenChange(
                                                        setNpiDataFields,
                                                        npiDataList,
                                                        "npiData",
                                                        npiDataFields,
                                                        npiDataFields.npiDataValue
                                                    )
                                                }
                                                onChange={(e) =>
                                                    handleChangeDropdownValue(
                                                        e,
                                                        "npiDataValue",
                                                        setNpiDataFields,
                                                        npiDataList,
                                                        npiDataFields.npiDataValue,
                                                        npiDataFields
                                                    )
                                                }
                                                onFilterChange={(e) =>
                                                    filterChange(
                                                        e,
                                                        setNpiDataFields,
                                                        npiDataList,
                                                        "npiData"
                                                    )
                                                }
                                                onBlur={() =>
                                                    handleBlurDropdown(
                                                        setNpiDataFields,
                                                        "npiData"
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className="k-form-field-wrap-popup valueDropdown">
                                            <Label>Position Name:</Label>
                                            <MultiSelect
                                                name="positionDataValue"
                                                dataItemKey="id"
                                                textField="text"
                                                data={
                                                    positionDataFields.positionData
                                                }
                                                filterable
                                                value={
                                                    positionDataFields.positionDataValue
                                                }
                                                autoClose={false}
                                                filter={
                                                    positionDataFields.filterValue
                                                }
                                                loading={
                                                    positionDataFields.isPositionDataLoading
                                                }
                                                disabled={
                                                    positionDataFields.isPositionDataLoading
                                                }
                                                onOpen={() =>
                                                    onOpenChange(
                                                        setPositionDataFields,
                                                        positionDataList,
                                                        "positionData",
                                                        positionDataFields,
                                                        positionDataFields.positionDataValue
                                                    )
                                                }
                                                onChange={(e) =>
                                                    handleChangeDropdownValue(
                                                        e,
                                                        "positionDataValue",
                                                        setPositionDataFields,
                                                        positionDataList,
                                                        positionDataFields.positionDataValue,
                                                        positionDataFields
                                                    )
                                                }
                                                onFilterChange={(e) =>
                                                    filterChange(
                                                        e,
                                                        setPositionDataFields,
                                                        positionDataList,
                                                        "positionData"
                                                    )
                                                }
                                                onBlur={() =>
                                                    handleBlurDropdown(
                                                        setPositionDataFields,
                                                        "positionData"
                                                    )
                                                }
                                            />
                                        </div>
                                        <Button
                                            primary
                                            className="searchbtn"
                                            disabled={
                                                specialtyDataFields.isSpecialtyDataLoading ||
                                                groupDataFields.isGroupDataLoading ||
                                                employeeDataFields.isEmployeeDataLoading ||
                                                npiDataFields.isNpiDataLoading ||
                                                positionDataFields.isPositionDataLoading
                                            }
                                            type="submit"
                                            onClick={onPositionClick}
                                        >
                                            <i className="fas fa-search"></i>
                                        </Button>
                                    </ul>
                                    <div className="dtu-file-container padtop15">
                                        {loading.updateColumn ||
                                        loading.isSearchLoader ||
                                        loadState.isUploadState ||
                                        isAPICalling ? (
                                            <LoadingPanel />
                                        ) : (
                                            false
                                        )}
                                        {!loading.isSearchLoader &&
                                            columns.length > 0 && (
                                                <CompData
                                                    isAPICalling={isAPICalling}
                                                    initialLoading={
                                                        initialLoading
                                                    }
                                                    data={memoizedData}
                                                    pageData={pageData}
                                                    dataCount={dataCount}
                                                    customColumn={CustomColumn}
                                                    columns={gridColumns}
                                                    dataItemKey={DATA_ITEM_KEY}
                                                    width={"auto"}
                                                    defaultPageSize={
                                                        defaultPageSize
                                                    }
                                                    pageSize={pageSize}
                                                    setPageSize={setPageSize}
                                                    noDataFound={noDataFound}
                                                    isExportDataClick={
                                                        exportDataClick
                                                    }
                                                    setExportData={
                                                        setExportData
                                                    }
                                                    exportData={exportData}
                                                    fileName={
                                                        isShow.exportAllVersion
                                                            ? "Export_ProviderPosition_All"
                                                            : "Export_ProviderPosition_Current"
                                                    }
                                                    reorderable={
                                                        !!pageProps.writeAccess
                                                    }
                                                    initialFilter={filterField}
                                                    initialSort={sortField}
                                                    resetSort={resetSort}
                                                    handleColumnMenu={
                                                        handleColumnMenu
                                                    }
                                                    rowRender={customRowRender}
                                                    columnReorderData={
                                                        columnReorderData
                                                    }
                                                    onSelectionChange={
                                                        onSelectionChange
                                                    }
                                                    getExportData={
                                                        getExportData
                                                    }
                                                    onClearFilter={
                                                        onClearFilter
                                                    }
                                                    handlePageChange={
                                                        handlePageChange
                                                    }
                                                    refreshClick={
                                                        onRefreshClick
                                                    }
                                                    gridHeight={"auto"}
                                                    id={"provider-position"}
                                                    isSelection={isSelection}
                                                    isDTUUploadReload={
                                                        isDTUUploadReload
                                                    }
                                                />
                                            )}
                                    </div>
                                </>
                                {isShow.isManageCustomType && (
                                    <ManageCustomField
                                        visible={isShow.isManageCustomType}
                                        setShow={setShow}
                                        pageProps={pageProps}
                                    />
                                )}
                            </div>
                        </div>
                    </>
                ) : mode === "Add" ? (
                    <AddProviderPosition
                        pageProps={pageProps}
                        setMode={setMode}
                        isSearchClick={isSearchClick}
                        setSearchClick={setSearchClick}
                        setAdd={setAdd}
                    />
                ) : mode === "Edit" ? (
                    <UpdateProviderPosition
                        setIsOneEffVersion={setIsOneEffVersion}
                        setIsUpdate={setIsUpdate}
                        pageProps={pageProps}
                        setMode={setMode}
                        setEditData={setEditData}
                        editData={editData}
                    />
                ) : (
                    false
                )}
            </div>
            {isShow.dtuLogs && (
                <DtuBatchLogs
                    visible={isShow}
                    setShow={setShow}
                    module={"provider_master"}
                    pageProps={pageProps}
                />
            )}
            {isShow.dtuUpload && (
                <UploadDtu
                    visible={isShow}
                    setShow={setShow}
                    title={"Upload Provider Positions"}
                    pageId={"provider_position"}
                    setAPICall={onRefreshOnManualUpload}
                />
            )}
            <DialogComponent
                title={confirmationPopup.title}
                onCancelConfirm={onCancelConfirm}
                width={"450"}
                height={"auto"}
                className={"Dialog-Delete"}
                desc={confirmationPopup.desc}
                visible={confirmationPopup.isVisible}
                actionBtn={confirmationPopup.actionBtn}
                titleClassName={
                    confirmationPopup.action === "Delete"
                        ? "delete-confirm"
                        : "unSave-confirm"
                }
            />
        </>
    );
};

export default React.memo(ProviderPosition);

export const GridButton = (restProps) => {
    return (
        <>
            <Button type="submit" className="cancelbtn" id="grid-incell-cancel">
                Cancel
            </Button>
            <Button
                type="submit"
                className="primarybtn"
                id="grid-incell-update"
            >
                Save
            </Button>
        </>
    );
};
