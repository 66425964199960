import React from "react";
import { Button } from "@progress/kendo-react-buttons";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setShowReportsCompDetailSection } from "../../redux/actions/reports-actions";
import { reportsSelector } from "../../redux/selectors/custom-selector";

const SectionHeader = ({ handleExport, pageProps }) => {
  const dispatch = useDispatch();
  // const [dropDownValue, setDropDownValue] = React.useState("PDF");
  const {
    showReportsCompDetailSection1,
    showReportsCompDetailSection2,
    // reportsCompDetailSection1Data,
    // reportsCompDetailSection2DataColumns,
  } = useSelector(reportsSelector, shallowEqual);

  // const handleExportDropDownChange = (e) => {
  //   setDropDownValue(e.target.value);
  // };

  return (
    <div className={"reports-comp-detail-section-header"}>
      <div className={"reports-comp-detail-section-selectors-container"}>
        <div
          onClick={() =>
            dispatch(
              setShowReportsCompDetailSection(!showReportsCompDetailSection1, 1)
            )
          }
          className={`reports-comp-detail-section-button${
            showReportsCompDetailSection1
              ? " reports-comp-detail-section-selected"
              : ""
          }`}
        >
          Section 1
        </div>
        <div
          onClick={() =>
            dispatch(
              setShowReportsCompDetailSection(!showReportsCompDetailSection2, 2)
            )
          }
          className={`reports-comp-detail-section-button${
            showReportsCompDetailSection2
              ? " reports-comp-detail-section-selected"
              : ""
          }`}
        >
          Section 2
        </div>
      </div>
      {pageProps.exportControlAccess === 1 && (
        <div className="reports-comp-detail-section-export-container">
          <Button
            className="reports-comp-detail-section-export-button primarybtn"
            onClick={handleExport}
            primary={true}
            disabled={
              !showReportsCompDetailSection1 && !showReportsCompDetailSection2
            }
          >
            Export To PDF
          </Button>
        </div>
      )}
    </div>
  );
};

export default React.memo(SectionHeader);
