import React from "react";
import {
    DELETE_CUSTOM_TABLE_DATA,
    GET_CUSTOM_TABLE_DATA,
    INSERT_UPDATE_CUSTOM_TABLE_DATA,
    SELECT_CUSTOM_TABLE_COLUMNS_DATA,
    SELECT_GOAL_HIERARCHY_VALUE,
} from "../../../api-setup/api-endpoints";
import { getUserCredential } from "../../../lib/cookies";
import { POSTAPI, errStatusHandler } from "../../../api-setup/api-helper";
import { GridColumnDataMapper } from "../../../utils/utils";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { setNewMessage } from "../../../redux/actions/setting-actions";
import {
    GetCompPlanDataApi,
    GetGroupNameAPI,
    GetPositionNoCFDDataAPI,
    GetSPECIALTYAPI,
    GetTeamNameApi,
} from "src/lib/common-service";

export default function DataRows() {
    const cookiesData = getUserCredential();
    const header = {
        "x-access-token": cookiesData.secretToken,
    };
    const history = useHistory();
    const dispatch = useDispatch();
    const [data, setData] = React.useState([]);
    const [columns, setColumns] = React.useState([]);
    const [dataRowsCount, setDataRowsCount] = React.useState(0);
    const [loadingState, setLoadState] = React.useState({
        isSaveData: undefined,
        isDeleteData: undefined,
    });
    const [initialLoading, setLoadingState] = React.useState(false);
    const [editData, setEditData] = React.useState("");
    const [confirmationPopup, setConfirmationPopup] = React.useState({
        title: "Delete",
        desc: "Are you sure you want to delete this record?",
        isVisible: false,
        type: "",
        actionBtn: [],
        data: {},
        action: "Delete",
    });
    const [originalData, setOriginalData] = React.useState([]);
    const [isAPICalling, setAPICallingState] = React.useState(false);
    const [isRefreshLoading, setRefreshLoading] = React.useState(false);

    let rowCount = 0;

    const updateUserData = async (
        userData,
        editIndex,
        apiCall,
        apiData,
        name,
        key,
        setNodeNameList
    ) => {
        let response = await apiCall(apiData, history, dispatch);
        if (response && response.length) {
            const tempData = response.map((item) => ({
                text: item[name].toString(),
                value: item[key].toString(),
            }));
            userData[editIndex].nodeNameList.data = tempData;
        } else {
            userData[editIndex].nodeNameList.data = [];
        }
        userData[editIndex].nodeNameList.loadingState = true;
    };

    const processNode = async (
        userData,
        editIndex,
        node,
        cookiesData,
        selectedVersion
    ) => {
        const tenantId = cookiesData.out_tenant_id;
        const userId = cookiesData.out_user_id;
        const acctEncryptKey = cookiesData.out_acct_encrypt_key;

        switch (node.toLowerCase()) {
            case "position":
                console.log("Position Data Load");
                await updateUserData(
                    userData,
                    editIndex,
                    GetPositionNoCFDDataAPI,
                    {
                        in_tenant_id: tenantId,
                        in_filter: null,
                        in_user_key: userId,
                        in_sort_number: 2,
                        in_sort_order: "ASC",
                        in_page_row_offset: 0,
                        in_page_rows: 9999,
                        in_acct_encrypt_key: acctEncryptKey,
                        in_export_flag: 0,
                        in_is_current_flag: 1,
                    },
                    "position_name",
                    "org_position_key"
                );
                break;
            case "team":
                await updateUserData(
                    userData,
                    editIndex,
                    GetTeamNameApi,
                    {
                        in_tenant_id: tenantId,
                        in_eff_end_date: selectedVersion?.endDate,
                    },
                    "team_name",
                    "team_name"
                );
                break;
            case "group":
                await updateUserData(
                    userData,
                    editIndex,
                    GetGroupNameAPI,
                    {
                        in_tenant_id: tenantId,
                        in_filter: null,
                        in_user_role_filter: null,
                        in_sort_number: 3,
                        in_sort_order: null,
                        in_page_row_offset: 0,
                        in_page_rows: 9999,
                    },
                    "title_name",
                    "org_title_key"
                );
                break;
            case "specialty":
                await updateUserData(
                    userData,
                    editIndex,
                    GetSPECIALTYAPI,
                    {
                        in_user_key: userId,
                        in_tenant_id: tenantId,
                        in_filter: null,
                        in_sort_number: 3,
                        in_sort_order: "DESC",
                        in_page_row_offset: 0,
                        in_page_rows: 9999,
                    },
                    "specialty_name",
                    "specialty_mstr_key"
                );
                break;
            case "plan":
                await updateUserData(
                    userData,
                    editIndex,
                    GetCompPlanDataApi,
                    {
                        in_tenant_id: tenantId,
                        in_filter: null,
                        in_sort_number: null,
                        in_sort_order: null,
                        in_user_key: userId,
                        in_multi_sort_order: null,
                    },
                    "comp_plan_name",
                    "comp_plan_mstr_key"
                );
                break;
            default:
                break;
        }
    };

    const processData = async (
        rateTableObj,
        userData,
        resolve,
        listData,
        columData,
        res
    ) => {
        for (const item of columData) {
            if (item.field === "Node") {
                for (
                    let editIndex = 0;
                    editIndex < userData.length;
                    editIndex++
                ) {
                    rowCount++;
                    await processNode(
                        userData,
                        editIndex,
                        userData[editIndex].Node,
                        cookiesData,
                        listData?.selectedVersion
                    );
                }
            } else {
                rowCount = userData.length;
            }
        }

        rateTableObj["rateTierData"] = userData;
        rateTableObj["dataCount"] = res.data.out_parameters.out_total_rows;

        if (rowCount === userData.length) {
            setData(userData);
            setOriginalData(userData);
            setRefreshLoading(false);
            if (listData.in_init === 1) {
                resolve(rateTableObj);
            } else {
                resolve(userData);
            }
        }
    };

    const getTierPromiseData = (defaultData, columnData, dataCount) => {
        return new Promise(function (resolve, reject) {
            setAPICallingState(false);
            setLoadingState(true);
            setEditData("");
            setConfirmationPopup({
                ...confirmationPopup,
                isVisible: false,
                type: "",
                actionBtn: [],
                data: {},
                action: "",
            });
            setColumns(columnData);
            setData(defaultData);
            setOriginalData(defaultData);
            // setDataRowsCount(dataCount);
            resolve([]);
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                dispatch(
                    setNewMessage({
                        message: "Error",
                        type: "Error",
                        show: true,
                    })
                );
                errStatusHandler(res, history, dispatch);
            });
    };

    const getListDataAsync = (listData, access) => {
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                GET_CUSTOM_TABLE_DATA,
                null,
                header,
                listData,
                dispatch
            ).then(async (res) => {
                if (res) {
                    if (res.data.out_parameters.out_ret_code === 3) {
                        setAPICallingState(false);
                        setLoadingState(true);
                        errStatusHandler(res, history, dispatch);
                        resolve([]);
                    } else {
                        setEditData("");
                        setConfirmationPopup({
                            ...confirmationPopup,
                            isVisible: false,
                            type: "",
                            actionBtn: [],
                            data: {},
                            action: "",
                        });

                        const rateTableObj = {};

                        let userData = JSON.parse(
                            JSON.stringify(res.data.row_result)
                        );
                        let columData = GridColumnDataMapper(
                            res.data.out_parameters.out_columns_xml
                        );
                        if (listData.in_load_type === "grid-reload") {
                            columData.map((cItem) => {
                                cItem.minWidth = 210;
                                cItem.show =
                                    cItem.field === "custom_table_mstr_key" ||
                                    cItem.field === "custom_data_order" ||
                                    cItem.field === "version" ||
                                    cItem.field === "date_version"
                                        ? false
                                        : true;
                                return 0;
                            });

                            rateTableObj["rateTierColumn"] = columData;
                            setColumns(columData);
                        }
                        setDataRowsCount(
                            res.data.out_parameters.out_total_rows
                        );
                        userData.map((item, idx) => {
                            item.rowId = idx + 1;
                            item.selected = false;
                            item.nodeNameList = {
                                data: [],
                                loadingState: false,
                            };
                            return 0;
                        });
                        //  await processData(rateTableObj, userData, resolve, listData, columData, res)

                        rateTableObj["rateTierData"] = userData;
                        rateTableObj["dataCount"] =
                            res.data.out_parameters.out_total_rows;

                        setData(userData);
                        setOriginalData(userData);
                        setRefreshLoading(false);
                        if (listData.in_init === 1) {
                            resolve(rateTableObj);
                        } else {
                            resolve(userData);
                        }

                        setLoadingState(true);
                        setAPICallingState(false);
                    }
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    const getCustomTableListDataAsync = (listData) => {
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                GET_CUSTOM_TABLE_DATA,
                null,
                header,
                listData,
                dispatch
            ).then((res) => {
                if (res) {
                    if (res.data.out_parameters.out_ret_code === 3) {
                        setAPICallingState(false);
                        setLoadingState(true);
                        errStatusHandler(res, history, dispatch);
                        resolve([]);
                    } else {
                        setAPICallingState(false);
                        setLoadingState(true);
                        setEditData("");
                        setConfirmationPopup({
                            ...confirmationPopup,
                            isVisible: false,
                            type: "",
                            actionBtn: [],
                            data: {},
                            action: "",
                        });

                        const rateTableObj = {};

                        let userData = JSON.parse(
                            JSON.stringify(res.data.row_result)
                        );
                        if (listData.in_load_type === "grid-reload") {
                            const columData = GridColumnDataMapper(
                                res.data.out_parameters.out_columns_xml
                            );

                            columData.map((cItem) => {
                                cItem.minWidth = 210;
                                cItem.show =
                                    cItem.field === "custom_table_mstr_key" ||
                                    cItem.field === "custom_data_order" ||
                                    cItem.field === "version" ||
                                    cItem.field === "date_version"
                                        ? false
                                        : true;
                                return 0;
                            });

                            rateTableObj["rateTierColumn"] = columData;
                            setColumns(columData);
                        }
                        setDataRowsCount(
                            res.data.out_parameters.out_total_rows
                        );
                        userData.map((item, idx) => {
                            item.rowId = idx + 1;
                            item.selected = false;
                            item.nodeNameList = {
                                data: [],
                                loadingState: false,
                            };
                            return 0;
                        });
                        rateTableObj["rateTierData"] = [];
                        rateTableObj["dataCount"] =
                            res.data.out_parameters.out_total_rows;
                        setData([]);
                        setOriginalData([]);
                        setRefreshLoading(false);
                        if (listData.in_init === 1) {
                            resolve(rateTableObj);
                        } else {
                            resolve([]);
                        }
                    }
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    const addUpdateDataAsync = (bodyData) => {
        setRefreshLoading(true);
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                INSERT_UPDATE_CUSTOM_TABLE_DATA,
                null,
                header,
                bodyData,
                dispatch
            ).then((res) => {
                if (res.status) {
                    if (res.data.out_parameters.out_ret_code === 0) {
                        setRefreshLoading(false);
                        resolve(true);
                    } else {
                        dispatch(
                            setNewMessage({
                                message: res.data.out_parameters.out_error_msg,
                                type: "Error",
                                show: true,
                            })
                        );
                    }
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    /*
    const selectRateTableData = (bodyData) => {
        POSTAPI(
            false,
            SELECT_RATE_DATA_COLUMNS,
            null,
            header,
            bodyData,
            dispatch
        ).then((res) => {
            if (res.data.out_parameters.out_ret_code === 0) {
                setCustomDataColumns(res.data.row_result);
            }
        });
    };
    */
    const exportDataAsync = (bodyData) => {
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                GET_CUSTOM_TABLE_DATA,
                null,
                header,
                bodyData,
                dispatch
            ).then((res) => {
                if (
                    res &&
                    (res.data.out_parameters.out_ret_code === 0 ||
                        res.data.out_parameters.out_ret_code === 2)
                ) {
                    resolve(res.data.row_result);
                } else {
                    dispatch(
                        setNewMessage({
                            message: res?.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    resolve([]);
                    errStatusHandler(res, history, dispatch);
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    const selectGoalHierarchyValue = (listData) => {
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                SELECT_GOAL_HIERARCHY_VALUE,
                null,
                header,
                listData,
                dispatch
            ).then((res) => {
                if (res) {
                    if (res.data.out_parameters.out_ret_code === 3) {
                        setAPICallingState(false);
                        setLoadingState(true);
                        errStatusHandler(res, history, dispatch);
                        resolve([]);
                    } else {
                        resolve([...res]);
                    }
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    const deleteDataAsync = async (bodyData) => {
        setRefreshLoading(true);
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                DELETE_CUSTOM_TABLE_DATA,
                null,
                header,
                bodyData,
                dispatch
            ).then((res) => {
                setRefreshLoading(false);
                if (res.data.out_parameters.out_ret_code > 0) {
                    let msg = res.data.out_parameters.out_error_msg;

                    setLoadState({ ...loadingState, isDeleteData: false });
                    setConfirmationPopup({
                        ...confirmationPopup,
                        isVisible: false,
                        type: "",
                        actionBtn: [],
                        data: {},
                        action: "",
                    });
                    dispatch(
                        setNewMessage({
                            message: msg,
                            type: "Error",
                            show: true,
                        })
                    );
                } else {
                    setConfirmationPopup({
                        ...confirmationPopup,
                        isVisible: false,
                        type: "",
                        actionBtn: [],
                        data: {},
                        action: "",
                    });
                    setLoadState({ ...loadingState, isDeleteData: true });
                    setTimeout(function () {
                        resolve("success");
                    });
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    const getCustomTableColumnsData = (listData) => {
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                SELECT_CUSTOM_TABLE_COLUMNS_DATA,
                null,
                header,
                listData,
                dispatch
            ).then((res) => {
                if (res) {
                    if (res.data.out_parameters.out_ret_code === 3) {
                        setAPICallingState(false);
                        setLoadingState(true);
                        errStatusHandler(res, history, dispatch);
                        resolve([]);
                    } else {
                        resolve(res.data.row_result);
                    }
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    return [
        {
            data,
            columns,
            dataRowsCount,
            setData,
            setColumns,
            editData,
            originalData,
            isAPICalling,
            setEditData,
            confirmationPopup,
            setConfirmationPopup,
            initialLoading,
            setAPICallingState,
            loadingState,
            setLoadState,
            setOriginalData,
            setRefreshLoading,
            isRefreshLoading,
            setDataRowsCount,
            setLoadingState,
        },
        {
            getListDataAsync,
            addUpdateDataAsync,
            getTierPromiseData,
            exportDataAsync,
            selectGoalHierarchyValue,
            deleteDataAsync,
            getCustomTableListDataAsync,
            getCustomTableColumnsData,
        },
    ];
}
