/**
 * Form schema for email send popup
 */
import { FormInput } from "src/components/form/form-elements";
import { OTP_LENGTH } from "src/constants/constants";

const schema = {
    otp: {
        id: "otp",
        name: "otp",
        placeholder: "One Time Password",
        // label: "ProCARE User Name:",
        wrapperClass: "formelement fullwidthelm logininputs input-number-without-arrow",
        component: FormInput,
        required:true,
        maxLength:6,
        type: 'number',
        style: {
            // width: "340px",
        },
        autoFocus: true,
        autoComplete: "off",
        rules: {
            required: "Please enter OTP",
            minLength: {
                value: OTP_LENGTH,
                message: "OTP should have 6 characters",
            },
            maxLength: {
                value: OTP_LENGTH,
                message: "OTP should have 6 characters",
            },
        },
    },
};

export default schema;
