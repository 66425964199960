import React, { useEffect } from "react";
import TmplAuth from "src/template/tmplAuth";
import ChangePasswordForm from "./form/changePasswordForm";
import { getUserCredential } from "src/lib/cookies";
import { useHistory } from "react-router";
import ManageProfile from "./form/manageProfile";
import { AuthContent } from "../login/common/AuthContent";

const ChangePassword = (props) => {
    const history = useHistory();
    const cookiesData = getUserCredential();
    const [loggedInData, setLoggedInData] = React.useState({
        userData: cookiesData ? cookiesData : {},
        isFromLogin: true,
      });
    useEffect(() => {
        initilizeLoggedInData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [props]);

    const initilizeLoggedInData = () =>{
        if (
            props &&
            props?.location?.state &&
            props?.location?.state?.userData &&
            Object.keys(loggedInData?.userData)?.length === 0
          ) {
            setLoggedInData({
              ...loggedInData,
              userData: props.location.state.userData,
              isFromLogin: props.location.state.isFromLogin,
            });
            history.replace("/change-password");
          }
    }
    if(loggedInData?.userData?.out_credential == "CHANGE_PWD_ACCESS"){
        return <TmplAuth form={<ChangePasswordForm loggedInData={loggedInData}/>} pageContent={<AuthContent label="Change Password" showChangeUserBtn={false}/>} /> 
    } else {
        return <ManageProfile loggedInData={loggedInData}/>
        }
};

export default React.memo(ChangePassword);
