import React from "react";
import {
    GET_RATE_TABLE_TIER,
    DELETE_RATE_TABLE_TIER,
    INSERT_UPDATE_RATE_TABLE_TIER,
} from "../../../api-setup/api-endpoints";
import { getUserCredential } from "../../../lib/cookies";
import {
    POSTAPI,
    errStatusHandler,
    generateHeader,
} from "../../../api-setup/api-helper";
import { GridColumnDataMapper } from "../../../utils/utils";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { setNewMessage } from "../../../redux/actions/setting-actions";

export default function DataRows() {
    const cookiesData = getUserCredential();
    const header = {
        "x-access-token": cookiesData.secretToken,
    };
    const history = useHistory();
    const dispatch = useDispatch();
    const [data, setData] = React.useState([]);
    const [columns, setColumns] = React.useState([]);
    const [dataCount, setDataCount] = React.useState(0);
    const [loadingState, setLoadState] = React.useState({
        isSaveData: undefined,
        isDeleteData: undefined,
    });
    const [initialLoading, setLoadingState] = React.useState(false);
    const [editData, setEditData] = React.useState("");
    const [confirmationPopup, setConfirmationPopup] = React.useState({
        title: "Delete",
        desc: "Are you sure you want to delete this record?",
        isVisible: false,
        type: "",
        actionBtn: [],
        data: {},
        action: "Delete",
    });
    const [originalData, setOriginalData] = React.useState([]);
    const [isAPICalling, setAPICallingState] = React.useState(false);
    const [isRefreshLoading, setRefreshLoading] = React.useState(false);
    const [isSaveLoading ,setSaveLoading] = React.useState(false)

    const getTierPromiseData = (defaultData, columnData) => {
        return new Promise(function (resolve, reject) {
            setAPICallingState(false);
            setLoadingState(true);
            setEditData("");
            setConfirmationPopup({
                ...confirmationPopup,
                isVisible: false,
                type: "",
                actionBtn: [],
                data: {},
                action: "",
            });

            setColumns(columnData);
            setData(defaultData);
            setOriginalData(defaultData);
            setDataCount(defaultData.length);
            resolve([]);
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                dispatch(
                    setNewMessage({
                        message: "Error",
                        type: "Error",
                        show: true,
                    })
                );
                errStatusHandler(res, history, dispatch);
            });
    };

    const getListDataAsync = (listData) => {
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                GET_RATE_TABLE_TIER,
                null,
                header,
                listData,
                dispatch
            ).then((res) => {
                if (res) {
                    if (res.data.out_parameters.out_ret_code > 0) {
                        setLoadingState(true);
                        errStatusHandler(res, history, dispatch);
                    }
                    setAPICallingState(false);
                    setLoadingState(true);
                    setEditData("");
                    setConfirmationPopup({
                        ...confirmationPopup,
                        isVisible: false,
                        type: "",
                        actionBtn: [],
                        data: {},
                        action: "",
                    });

                    const rateTableObj = {};

                    let userData = JSON.parse(
                        JSON.stringify(res.data.row_result)
                    );

                    if (listData.in_load_type === "grid-reload") {
                        const columData = GridColumnDataMapper(
                            res.data.out_parameters.out_column_xml
                        );

                        columData.map((cItem) => {
                            cItem.minWidth = 210;
                            cItem.show =
                                cItem.field === "rate_table_mstr_key" ||
                                cItem.field === "rate_table_tier_key" ||
                                cItem.field === "version" ||
                                cItem.field === "date_version"
                                    ? false
                                    : true;
                            return 0;
                        });

                        rateTableObj["rateTierColumn"] = columData;
                        setColumns(columData);
                    }
                    userData.map((item, idx) => {
                        item.rowId = idx;
                        item.selected = false;
                        return 0;
                    });
                    rateTableObj["rateTierData"] = userData;
                    setData(userData);
                    setOriginalData(userData);
                    setDataCount(res.data.out_parameters.out_total_rows);
                    setRefreshLoading(false);
                    if (listData.in_init === 1) {
                        resolve(rateTableObj);
                    } else {
                        resolve(userData);
                    }
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    const updateDataAsync = (bodyData) => {
        setSaveLoading(true)
        return POSTAPI(
            false,
            INSERT_UPDATE_RATE_TABLE_TIER,
            null,
            header,
            bodyData,
            dispatch
        ).then((res) => {
            setSaveLoading(false)
            const response = res;
            let masterTableData = response.slice();
            const falseStatus = masterTableData.find(
                (elem) => elem.status !== true
            );
            if (falseStatus) {
                dispatch(
                    setNewMessage({
                        message:
                            falseStatus.row_name +
                            ": " +
                            falseStatus.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                errStatusHandler(res, history, dispatch);
            } else {
                return true;
            }
        });
    };

    const deleteDataAsync = async (bodyData) => {
        setRefreshLoading(true);
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                DELETE_RATE_TABLE_TIER,
                null,
                header,
                bodyData,
                dispatch
            ).then((res) => {
                setRefreshLoading(false);
                if (res.data.out_parameters.out_ret_code > 0) {
                    let msg = res.data.out_parameters.out_error_msg;

                    setLoadState({ ...loadingState, isDeleteData: false });
                    setConfirmationPopup({
                        ...confirmationPopup,
                        isVisible: false,
                        type: "",
                        actionBtn: [],
                        data: {},
                        action: "",
                    });
                    dispatch(
                        setNewMessage({
                            message: msg,
                            type: "Error",
                            show: true,
                        })
                    );
                } else {
                    setConfirmationPopup({
                        ...confirmationPopup,
                        isVisible: false,
                        type: "",
                        actionBtn: [],
                        data: {},
                        action: "",
                    });
                    setLoadState({ ...loadingState, isDeleteData: true });
                    setTimeout(function () {
                        resolve("success");
                    });
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    const exportDataAsync = (bodyData) => {
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                GET_RATE_TABLE_TIER,
                null,
                generateHeader(),
                bodyData,
                dispatch
            ).then((res) => {
                if (
                    res &&
                    (res.data.out_parameters.out_ret_code === 0 ||
                        res.data.out_parameters.out_ret_code === 2)
                ) {
                    resolve(res.data.row_result);
                } else {
                    dispatch(
                        setNewMessage({
                            message: res?.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    resolve([]);
                    errStatusHandler(res, history, dispatch);
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    return [
        {
            data,
            columns,
            dataCount,
            setData,
            setColumns,
            editData,
            originalData,
            isAPICalling,
            setEditData,
            confirmationPopup,
            setConfirmationPopup,
            initialLoading,
            setAPICallingState,
            loadingState,
            setLoadState,
            setOriginalData,
            setRefreshLoading,
            isRefreshLoading,
            isSaveLoading
        },
        {
            getListDataAsync,
            updateDataAsync,
            getTierPromiseData,
            deleteDataAsync,
            exportDataAsync,
        },
    ];
}
