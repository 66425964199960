import React from "react";

import { getUserCredential } from "../../lib/cookies";
import {
  POSTAPI,
  errStatusHandler,
  generateHeader,
} from "../../api-setup/api-helper";
import { GridColumnDataMapper, parseEffDateString } from "../../utils/utils";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { setNewMessage } from "../../redux/actions/setting-actions";
import { GetSystemConfigDataByCode, getProvidePostionFilter, selectDocument } from "../../lib/common-service";
import {
  DELETE_POSITION,
  DELETE_PROVIDER,
  GET_CATEGORY,
  GET_CATEGORY_FIELD,
  GET_POSITION_EFF_DATES,
  GET_PROVIDER_EFF_DATES,
  GET_PROVIDER_POSITION_VERSION_DATA,
  INSERT_CUSTOM_FIELD_DATA,
  INSERT_POSITION,
  INSERT_POSITION_EFF_VERSION,
  INSERT_PROVIDER,
  INSERT_PROVIDER_EFF_VERSION,
  SELECT_PROVIDER_POSTION_LIST,
  SELECT_PROVIDER_VERSION_DATA,
  UPDATE_CATEGORY_FDM_ORDER,
  UPDATE_CATEGORY_ORDER,
  UPDATE_POSITION,
  UPDATE_PROVIDER,
} from "../../api-setup/api-endpoints";
import { setProviderAction } from "src/redux/actions/provider-action";
export default function Plan() {
  /**
   * start to define default states for common grid functions (list/insert/update/delete)
   */
  const cookiesData = getUserCredential();
  const header = {
    "x-access-token": cookiesData.secretToken,
  };


  const history = useHistory();
  const dispatch = useDispatch();
  const [data, setData] = React.useState([]);
  const [columns, setColumns] = React.useState([]);
  const [dataCount, setDataCount] = React.useState(0);

  const [editData, setEditData] = React.useState("");
  const [confirmationPopup, setConfirmationPopup] = React.useState({
    title: "Delete",
    desc: "Are you sure you want to delete this record?",
    isVisible: false,
    type: "",
    actionBtn: [],
    data: {},
    action: "Delete",
  });
  const [originalData, setOriginalData] = React.useState([]);
  const [isAPICalling, setAPICallingState] = React.useState(false);
  const [isRefreshLoading, setRefreshLoading] = React.useState(false);
  const [specialtyDataList, setSpecialtyDataList] = React.useState([]);
  const [searchListLoading, setSearchListLoading] = React.useState(false);
  const [noDataFound, setNoDataFound] = React.useState(false);
  const [initialLoading, setLoadingState] = React.useState(false);
  const [loading, setLoadingStateSearch] = React.useState({
    isSearchLoader: false,
  });
  const [preConfigData, setPreConfigData] = React.useState('')
  const [specialtyDataFields, setSpecialtyDataFields] = React.useState({
    specialtyDataValue: [],
    specialtyData: [],
    isSpecialtyDataLoading: true,
    search: '',
    filterValue: ''
  });
  const [groupDataList, setGroupDataList] = React.useState([]);
  const [groupDataFields, setGroupDataFields] = React.useState({
    groupDataValue: [],
    groupData: [],
    isGroupDataLoading: true,
    search: '',
    filterValue: ''
  });
  const [employeeDataList, setemployeDataList] = React.useState([]);
  const [employeeDataFields, setEmployeeDataFields] = React.useState({
    employeeDataValue: [],
    employeeData: [],
    isEmployeeDataLoading: true,
    search: '',
    filterValue: ''
  });
  const [npiDataList, setNpiDataList] = React.useState([]);
  const [npiDataFields, setNpiDataFields] = React.useState({
    npiDataValue: [],
    npiData: [],
    isNpiDataLoading: true,
    search: '',
    filterValue: ''
  });
  const [positionDataList, setPositionDataList] = React.useState([]);
  const [positionDataFields, setPositionDataFields] = React.useState({
    positionDataValue: [],
    positionData: [],
    isPositionDataLoading: true,
    search: '',
    filterValue: ''
  });
  const [documentData, setDocumentData] = React.useState({
    document: [],
    mstr_key: '',
    date_version: ''
  })
  const [disabledFields, setDisabledFields] = React.useState([])

  const procareGetNullInFilter = ((data,
    filterParamName,
    dataType = "string",
    alias = "a") => {
    if (!data || data.length === 0) return "";
    const nullKeyword = "a$is_null";
    let nullFilterStr = "";

    const idList = data.map((item) => item.id).join(",");

    if (idList.includes(nullKeyword)) {
      idList.replace(new RegExp(nullKeyword, "g"), "");
      nullFilterStr = ` AND (COALESCE(${alias}.${filterParamName}, ${dataType === "string" ? "''" : "0"
        }) ${dataType === "string" ? "= ''" : "= 0"})`;
    }
    return ` AND (${alias}.${filterParamName} IN (${idList}))${nullFilterStr}`;
  });
  const getListDataAsync = (data, module) => {
    return new Promise(function (resolve, reject) {
      POSTAPI(false, SELECT_PROVIDER_POSTION_LIST, null, header, data, dispatch).then((res) => {
        if (res.data.out_parameters.out_ret_code === 0) {
          setAPICallingState(false);
          setLoadingState(true);
          if (res.data.out_parameters?.out_column_xml) {
            let userData = JSON.parse(
              JSON.stringify(res.data.row_result)
            );
            userData.map((item, idx) => {
              item.primary_key = `${item.provider_mstr_key}_${item.org_position_key}`
              return 0;
            });
            const columData = GridColumnDataMapper(
              res.data.out_parameters.out_column_xml,
              "",
              true
            );
            setColumns(columData);
            setNoDataFound(false)
            switch (module) {
              case 'update':
                setDataCount(-1)
                break;
              case 'filter':
                setDataCount(-1)
                break;
              case 'page':
                setDataCount(-1)
                break;
              default:
                break
            }
            setData(userData);
            setOriginalData(userData);
            setDataCount(res.data.out_parameters.out_total_rows);
            setLoadingStateSearch({ ...loading, isSearchLoader: false });
            resolve(userData)
          } else {
            setLoadingStateSearch({ ...loading, isSearchLoader: false });
            setNoDataFound(true)
            setData([]);
            setOriginalData([]);
            setDataCount(0);
            resolve([])
          }
        } else {
          setAPICallingState(false);
          setNoDataFound(true)
          setLoadingState(true);
          errStatusHandler(res, history, dispatch);
          setLoadingStateSearch({ ...loading, isSearchLoader: false });
          errStatusHandler(res, history, dispatch);
          resolve([])
        }
      });
    }).then((res) => {
      return res
    })
  };

  const getExportListDataAsync = (data) => {
    return new Promise(function (resolve, reject) {
      POSTAPI(false, SELECT_PROVIDER_POSTION_LIST, null, header, data, dispatch).then((res) => {
        if (res.data.out_parameters.out_ret_code === 0) {
          setAPICallingState(false);
          setLoadingState(true);
          if (res.data.out_parameters?.out_column_xml) {
            setLoadingStateSearch({ ...loading, isSearchLoader: false });
            let userData = JSON.parse(
              JSON.stringify(res.data.row_result)
            );
            const columData = GridColumnDataMapper(
              res.data.out_parameters.out_column_xml,
              "",
              true
            );

            let obj={
              data: userData,
              columData: columData
            }
            resolve(obj)
          } else {
            setLoadingStateSearch({ ...loading, isSearchLoader: false });
            setDataCount(0);
            let obj={
              data: [],
              columData: columns
            }
            resolve(obj)
          }
        } else {
          setAPICallingState(false);
          errStatusHandler(res, history, dispatch);
          let obj={
            data: [],
            columData: columns
          }
          resolve(obj)
        }
      });
    }).then((res) => {
      return res
    })
  };

  const getSpecialtyDataList = async (specialtyData, groupData, module) => {
    try {
      // Set loading state
      setSpecialtyDataFields(prevState => ({
        ...prevState,
        isSpecialtyDataLoading: true,
      }));

      // Define data object value
      const dataObjValue = {
        in_user_key: cookiesData.out_user_id,
        in_filter: null,
        in_user_role_filter: "> 0",
        in_sort_number: 11,
        in_sort_order: 'ASC',
        in_group_by: 'a.specialty_mstr_key',
        in_specialty_filter: '0',
        in_title_filter: '0',
        in_acct_encrypt_key: cookiesData.out_acct_encrypt_key,
      };

      // Set loading state if not already set
      if (!searchListLoading) {
        setSearchListLoading(true);
      }

      // Fetch specialty data
      const resName = await getProvidePostionFilter(dataObjValue, dispatch, history);

      if (resName.length > 0) {
        // Create an array of specialties
        const tempArray = [
          // { text: 'Select All', id: 'Select All' },
          { text: '(Empty Value)', id: '0' },
          ...resName.map(item => ({
            text: item.specialty_mstr_key === '' ? 'is_null' : item.specialty_name,
            id: item.specialty_mstr_key === '' ? '' : item.specialty_mstr_key,
          })),
        ];

        // Update state with new specialty data
        setSpecialtyDataList(tempArray);

        // Set specialty data fields based on module
        setSpecialtyDataFields(prevState => ({
          ...prevState,
          specialtyData: tempArray,
          isSpecialtyDataLoading: false,
          specialtyDataValue: module === 'add-update'
            ? prevState.specialtyDataValue
            : [{ text: 'Select All', id: 'Select All' }],
        }));

        // Fetch group data list
        getGroupDataList(specialtyDataFields.specialtyDataValue || null, module);

        return true;
      } else {
        // Handle no data case
        setSpecialtyDataList([]);
        setSpecialtyDataFields(prevState => ({
          ...prevState,
          isSpecialtyDataLoading: false,
          specialtyData: [],
        }));

        return false;
      }
    } catch (error) {
      // Handle errors
      setSpecialtyDataFields(prevState => ({
        ...prevState,
        isSpecialtyDataLoading: false,
      }));

      return false;
    }
  };

  const getGroupDataList = async (specialtyKeyData = null, module) => {
    // Set loading state
    setGroupDataFields(prevState => ({
      ...prevState,
      isGroupDataLoading: true,
    }));

    const checkIsSelectAll = specialtyKeyData?.find(x => x.id === 'Select All');
    if (checkIsSelectAll) specialtyKeyData = null;

    const specialtyKey = specialtyKeyData === null ? '0' :
      (specialtyKeyData.length === 1 && specialtyKeyData[0].id === '0') ?
        " AND ( IFNULL(b.specialty_mstr_key, 0) = 0 ) " :
        procareGetNullInFilter(specialtyKeyData.filter(x => x.id !== '0'), "specialty_mstr_key", "number", "b");

    const specialtyFilter = specialtyKeyData && specialtyKeyData.some(x => x.id === '0') && specialtyKeyData.length > 1 ?
      `${specialtyKey} OR ( IFNULL(b.specialty_mstr_key, 0) = 0 )` : specialtyKey;

    const dataObjValue = {
      in_user_key: cookiesData.out_user_id,
      in_filter: null,
      in_user_role_filter: "> 0",
      in_sort_number: 10,
      in_sort_order: 'ASC',
      in_group_by: 'a.org_title_key',
      in_specialty_filter: specialtyFilter || '0',
      in_title_filter: '0',
      in_acct_encrypt_key: cookiesData.out_acct_encrypt_key,
    };

    try {
      const resName = await getProvidePostionFilter(dataObjValue, dispatch, history);
      const tempArray = resName.length > 0 ? [
        // { text: 'Select All', id: 'Select All' },
        { text: '(Empty Value)', id: '0' },
        ...resName.map(item => ({
          text: item.org_title_key === '' ? 'is_null' : item.title_name,
          id: item.org_title_key === '' ? '' : item.org_title_key,
        }))
      ] : [
        // { text: 'Select All', id: 'Select All' },
        { text: '(Empty Value)', id: '0' }
      ];

      setGroupDataList(tempArray);

      setGroupDataFields(prevState => ({
        ...prevState,
        groupData: tempArray,
        isGroupDataLoading: false,
        ...(module !== 'add-update' && {
          groupDataValue: [{ text: 'Select All', id: 'Select All' }],
        }),
      }));
    } catch (error) {
      setGroupDataList([
        // { text: 'Select All', id: 'Select All' },
        { text: '(Empty Value)', id: '0' }
      ]);
      setGroupDataFields(prevState => ({
        ...prevState,
        isGroupDataLoading: false,
        groupData: [],
      }));
    }
  };

  const getEmployeeDataList = async (specialtyKeyData = null, groupKeyData = null, module) => {
    setEmployeeDataFields(prevState => ({
      ...prevState,
      isEmployeeDataLoading: true,
    }));

    const checkIsSpecialtySelectAll = specialtyKeyData?.some(x => x.id === 'Select All');
    if (checkIsSpecialtySelectAll) specialtyKeyData = null;

    const checkIsGroupSelectAll = groupKeyData?.some(x => x.id === 'Select All');
    if (checkIsGroupSelectAll) groupKeyData = null;

    const specialtyKey = specialtyKeyData === null ? '0' :
      (specialtyKeyData.length === 1 && specialtyKeyData[0].id === '0') ?
        " AND ( IFNULL(b.specialty_mstr_key, 0) = 0 ) " :
        procareGetNullInFilter(specialtyKeyData.filter(x => x.id !== '0'), "specialty_mstr_key", "number", "b");

    const specialtyFilter = specialtyKeyData?.some(x => x.id === '0') && specialtyKeyData?.length > 1 ?
      `${specialtyKey} OR ( IFNULL(b.specialty_mstr_key, 0) = 0 )` : specialtyKey;

    const groupKey = groupKeyData === null ? '0' :
      (groupKeyData.length === 1 && groupKeyData[0].id === '0') ?
        " AND ( IFNULL(b.org_title_key, 0) = 0 ) " :
        procareGetNullInFilter(groupKeyData.filter(x => x.id !== '0'), "org_title_key", "number", "b");

    const groupFilter = groupKeyData?.some(x => x.id === '0') && groupKeyData?.length > 1 ?
      `${groupKey} OR ( IFNULL(b.org_title_key, 0) = 0 )` : groupKey;

    const dataObjValue = {
      in_user_key: cookiesData.out_user_id,
      in_filter: null,
      in_user_role_filter: "> 0",
      in_sort_number: 8,
      in_sort_order: 'ASC',
      in_group_by: 'a.employee_id',
      in_specialty_filter: specialtyFilter || '0',
      in_title_filter: groupFilter || '0',
      in_acct_encrypt_key: cookiesData.out_acct_encrypt_key,
    };

    try {
      const resName = await getProvidePostionFilter(dataObjValue, dispatch, history);

      const tempArray = [
        ...resName.map(item => ({
          text: item.employee_id === '' ? '(Empty Value)' : item.employee_id,
          id: item.employee_id === '' ? '' : item.employee_id,
        }))
      ] 

      setemployeDataList(tempArray);

      setEmployeeDataFields(prevState => ({
        ...prevState,
        employeeData: tempArray,
        isEmployeeDataLoading: false,
        ...(module !== 'add-update' && {
          employeeDataValue: [{ text: 'Select All', id: 'Select All' }],
        }),
      }));
    } catch (error) {
      // setemployeDataList([
      //   { text: 'Select All', id: 'Select All' },
      // ]);
      setEmployeeDataFields(prevState => ({
        ...prevState,
        isEmployeeDataLoading: false,
        employeeData: [],
        employeeDataValue: [{ text: 'Select All', id: 'Select All' }],
      }));
    }
  };

  const getNpiDataList = async (specialtyKeyData = null, groupKeyData = null, module) => {
    // Set loading state
    setNpiDataFields(prevState => ({
      ...prevState,
      isNpiDataLoading: true,
    }));

    // Check for "Select All" and update data accordingly
    if (specialtyKeyData?.some(x => x.id === 'Select All')) specialtyKeyData = null;
    if (groupKeyData?.some(x => x.id === 'Select All')) groupKeyData = null;

    // Determine specialty filter
    const specialtyKey = specialtyKeyData === null ? '0' :
      (specialtyKeyData.length === 1 && specialtyKeyData[0].id === '0') ?
        " AND ( IFNULL(b.specialty_mstr_key, 0) = 0 ) " :
        procareGetNullInFilter(specialtyKeyData.filter(x => x.id !== '0'), "specialty_mstr_key", "number", "b");

    const specialtyFilter = specialtyKeyData?.some(x => x.id === '0') && specialtyKeyData.length > 1 ?
      `${specialtyKey} OR ( IFNULL(b.specialty_mstr_key, 0) = 0 )` : specialtyKey;

    // Determine group filter
    const groupKey = groupKeyData === null ? '0' :
      (groupKeyData.length === 1 && groupKeyData[0].id === '0') ?
        " AND ( IFNULL(b.org_title_key, 0) = 0 ) " :
        procareGetNullInFilter(groupKeyData.filter(x => x.id !== '0'), "org_title_key", "number", "b");

    const groupFilter = groupKeyData?.some(x => x.id === '0') && groupKeyData.length > 1 ?
      `${groupKey} OR ( IFNULL(b.org_title_key, 0) = 0 )` : groupKey;

    // Define data object value
    const dataObjValue = {
      in_user_key: cookiesData.out_user_id,
      in_filter: null,
      in_user_role_filter: "> 0",
      in_sort_number: 9,
      in_sort_order: 'ASC',
      in_group_by: 'a.npi',
      in_specialty_filter: specialtyFilter || '0',
      in_title_filter: groupFilter || '0',
      in_acct_encrypt_key: cookiesData.out_acct_encrypt_key,
    };

    try {
      // Fetch NPI data
      const resName = await getProvidePostionFilter(dataObjValue, dispatch, history);

      const tempArray = [
        // { text: 'Select All', id: 'Select All' }, 
        ...resName.map(item => ({
          text: item.npi === '' ? '(Empty Value)' : item.npi,
          id: item.npi === '' ? '' : item.npi,
        }))
      ];


      // Update state with NPI data
      setNpiDataList(tempArray);

      setNpiDataFields(prevState => ({
        ...prevState,
        npiData: tempArray,
        isNpiDataLoading: false,
        ...(module !== 'add-update' && {
          npiDataValue: [{ text: 'Select All', id: 'Select All' }],
        }),
      }));
    } catch (error) {
      // Handle errors and set default state
      // setNpiDataList([
      //   { text: 'Select All', id: 'Select All' },
      // ]);
      setNpiDataFields(prevState => ({
        ...prevState,
        isNpiDataLoading: false,
        npiData: [],
        npiDataValue: [{ text: 'Select All', id: 'Select All' }],
      }));
    }
  };

  const getPostionDataList = async (specialtyKeyData = null, groupKeyData = null, module) => {
    // Set loading state
    setPositionDataFields(prevState => ({
      ...prevState,
      isPositionDataLoading: true,
    }));

    // Check for "Select All" and update data accordingly
    if (specialtyKeyData?.some(x => x.id === 'Select All')) specialtyKeyData = null;
    if (groupKeyData?.some(x => x.id === 'Select All')) groupKeyData = null;

    // Determine specialty filter
    const specialtyKey = specialtyKeyData === null ? '0' :
      (specialtyKeyData.length === 1 && specialtyKeyData[0].id === '0') ?
        " AND ( IFNULL(b.specialty_mstr_key, 0) = 0 ) " :
        procareGetNullInFilter(specialtyKeyData.filter(x => x.id !== '0'), "specialty_mstr_key", "number", "b");

    // Determine group filter
    const groupKey = groupKeyData === null ? '0' :
      (groupKeyData.length === 1 && groupKeyData[0].id === '0') ?
        " AND ( IFNULL(b.org_title_key, 0) = 0 ) " :
        procareGetNullInFilter(groupKeyData, "org_title_key", "number", "b");

    // Define data object value
    const dataObjValue = {
      in_user_key: cookiesData.out_user_id,
      in_filter: null,
      in_user_role_filter: "> 0",
      in_sort_number: 12,
      in_sort_order: 'ASC',
      in_group_by: 'a.org_position_key',
      in_specialty_filter: specialtyKey || '0',
      in_title_filter: groupKey || '0',
      in_acct_encrypt_key: cookiesData.out_acct_encrypt_key,
    };

    try {
      // Fetch position data
      const resName = await getProvidePostionFilter(dataObjValue, dispatch, history);


      const tempArray = [
        // { text: 'Select All', id: 'Select All' },
        // { text: '(Empty Value)', id: '0' },
        ...resName.map(item => ({
          text: item.org_position_key === '' ? '(Empty Value)' : item.position_name,
          id: item.org_position_key === '' ? '' : item.org_position_key,
        }))
      ] 

      setPositionDataList(tempArray);

      let dataValue = positionDataFields.positionDataValue.map(item => {
        const matchingObject = tempArray.find(tempItem => tempItem.id === item.id);
        return matchingObject ? { ...item, ...matchingObject } : item;
      });

      setPositionDataFields(prevState => ({
        ...prevState,
        positionDataValue: module === 'add-update'
          ? dataValue
          : [{ text: "Select All", id: "Select All" }],
        positionData: tempArray,
        isPositionDataLoading: false,
      }));

      // Update search list loading state
      setSearchListLoading(false);

    } catch (error) {
      // Handle errors and set default state
      setPositionDataFields(prevState => ({
        ...prevState,
        isPositionDataLoading: false,
        positionData: [],
        positionDataValue: [{ text: "Select All", id: "Select All" }],
      }));
    }
  };

  const getCategory = (reqData, dispatch, history) => {
    return new Promise(function (resolve, reject) {
      POSTAPI(false, GET_CATEGORY, null, generateHeader(), reqData).then(
        (res) => {
          if (res?.data?.out_parameters.out_ret_code > 0) {
            errStatusHandler(res, history, dispatch);
          }
          if (res.data) {
            let userData = JSON.parse(
              JSON.stringify(res.data.row_result)
            );
            resolve(userData);
          }
        }
      );
    })
      .then((res) => {
        return res;
      })
      .catch((res) => {
        if (res.data.out_parameters.out_ret_code > 0) {
          dispatch(
            setNewMessage({
              message: res.data.out_parameters.out_error_msg,
              type: "Error",
              show: true,
            })
          );
          errStatusHandler(res, history, dispatch);
        }
      });
  };

  const getCategoryField = (reqData, dispatch, history) => {
    return new Promise(function (resolve, reject) {
      POSTAPI(false, GET_CATEGORY_FIELD, null, generateHeader(), reqData).then(
        (res) => {
          if (res?.data?.out_parameters.out_ret_code > 0) {
            errStatusHandler(res, history, dispatch);
          }
          if (res.data) {
            let userData = JSON.parse(
              JSON.stringify(res.data.row_result)
            );
            resolve(userData);
          }
        }
      );
    })
      .then((res) => {
        return res;
      })
      .catch((res) => {
        if (res.data.out_parameters.out_ret_code > 0) {
          dispatch(
            setNewMessage({
              message: res.data.out_parameters.out_error_msg,
              type: "Error",
              show: true,
            })
          );
          errStatusHandler(res, history, dispatch);
        }
      });
  };

  const insertPosition = (reqData, dispatch, history) => {
    return new Promise(function (resolve, reject) {
      POSTAPI(false, INSERT_POSITION, null, generateHeader(), reqData).then(
        (res) => {
          if (res?.data?.out_parameters.out_ret_code > 0) {
            let deleteReqData = {
              in_user_id: reqData.in_user_id,
              in_tenant_id: reqData.in_tenant_id,
              in_provider_mstr_key: [{
                id: reqData.in_provider_mstr_key,
                date_version: 1
              }]
            }
            let resData = deleteProvider(deleteReqData, dispatch, history)
            if (resData) {
              resolve(res.data.out_parameters)
            }
            errStatusHandler(res, history, dispatch);
          } else {
            resolve(res.data.out_parameters)
          }

        }
      );
    })
      .then((res) => {
        return res;
      })
      .catch((res) => {
        if (res.data.out_parameters.out_ret_code > 0) {
          dispatch(
            setNewMessage({
              message: res.data.out_parameters.out_error_msg,
              type: "Error",
              show: true,
            })
          );
          errStatusHandler(res, history, dispatch);
        }

      });
  };


  const insertProvider = (reqData, dispatch, history) => {
    return new Promise(function (resolve, reject) {
      POSTAPI(false, INSERT_PROVIDER, null, generateHeader(), reqData).then(
        (res) => {
          if (res?.data?.out_parameters.out_ret_code > 0) {
            dispatch(
              setNewMessage({
                message: res.data.out_parameters.out_error_msg,
                type: "Error",
                show: true,
              })
            );
            resolve(res.data.out_parameters)
          } else {
            resolve(res.data.out_parameters)
          }

        }
      );
    })
      .then((res) => {
        return res;
      })
      .catch((res) => {
        if (res.data.out_parameters.out_ret_code > 0) {
          dispatch(
            setNewMessage({
              message: res.data.out_parameters.out_error_msg,
              type: "Error",
              show: true,
            })
          );
          errStatusHandler(res, history, dispatch);
        }
      });
  };


  const deleteProvider = (reqData, dispatch, history) => {
    return new Promise(function (resolve, reject) {
      POSTAPI(false, DELETE_PROVIDER, null, generateHeader(), reqData).then(
        (res) => {
          const totalData = reqData.in_provider_mstr_key.length;
          const unSuccessFullCount = res.data.successCount;
          if (res?.data?.out_parameters.out_ret_code > 0) {
            // let msg = res.data.out_parameters.out_error_msg;
            if (totalData !== unSuccessFullCount) {
              resolve(true)
              setAPICallingState(false)
            }
            dispatch(
              setNewMessage({
                message: res.data.out_parameters.out_error_msg,
                type: "Error",
                show: true,
              })
            );
            setAPICallingState(false)
            resolve(false)
          } else {
            resolve(true)
            setAPICallingState(false)
          }

        }
      );
    })
      .then((res) => {
        return res;
      })
      .catch((res) => {
        if (res.data.out_parameters.out_ret_code > 0) {
          dispatch(
            setNewMessage({
              message: res.data.out_parameters.out_error_msg,
              type: "Error",
              show: true,
            })
          );
          errStatusHandler(res, history, dispatch);
        }
      });
  };

  const deletePosition = (reqData, dispatch, history) => {
    return new Promise(function (resolve, reject) {
      POSTAPI(false, DELETE_POSITION, null, generateHeader(), reqData).then(
        (res) => {
          const totalData = reqData.in_org_position_key.length;
          const unSuccessFullCount = res.data.successCount;
          if (res?.data?.out_parameters.out_ret_code > 0) {
            let msg = res.data.out_parameters.out_error_msg;
            if (totalData !== unSuccessFullCount) {
              let name = unSuccessFullCount > 1 ? 'Positions' : 'Position'
              msg =
                unSuccessFullCount +
                ` ${name} (` +
                res.data.successName +
                ") out of " +
                totalData +
                " as " +
                msg;
              dispatch(
                setNewMessage({
                  message: msg,
                  type: "Error",
                  show: true,
                }))

              resolve(true)
              setAPICallingState(false)
            } else {
              dispatch(
                setNewMessage({
                  message: res.data.out_parameters.out_error_msg,
                  type: "Error",
                  show: true,
                })
              );
              setAPICallingState(false)
              errStatusHandler(res, history, dispatch);
              resolve(false)
            }
          } else {
            resolve(true)
            setAPICallingState(false)
          }

        }
      );
    })
      .then((res) => {
        return res;
      })
      .catch((res) => {
        if (res.data.out_parameters.out_ret_code > 0) {
          dispatch(
            setNewMessage({
              message: res.data.out_parameters.out_error_msg,
              type: "Error",
              show: true,
            })
          );
          errStatusHandler(res, history, dispatch);
        }
      });
  };

  const insertCustomFields = (reqData, dispatch, history) => {
    return new Promise(function (resolve, reject) {
      POSTAPI(false, INSERT_CUSTOM_FIELD_DATA, null, generateHeader(), reqData).then(
        (res) => {
          if (res?.data?.out_parameters.out_ret_code > 0) {
            resolve(false)
            errStatusHandler(res, history, dispatch);
          } else {
            resolve(true)
          }

        }
      );
    })
      .then((res) => {
        return res;
      })
      .catch((res) => {
        if (res.data.out_parameters.out_ret_code > 0) {
          dispatch(
            setNewMessage({
              message: res.data.out_parameters.out_error_msg,
              type: "Error",
              show: true,
            })
          );
          errStatusHandler(res, history, dispatch);
        }
      });
  };

  const updateCategoryOrder = (reqData, dispatch, history) => {
    return new Promise(function (resolve, reject) {
      POSTAPI(false, UPDATE_CATEGORY_ORDER, null, generateHeader(), reqData).then(
        (res) => {
          if (res?.data?.out_parameters.out_ret_code > 0) {
            resolve(false)
            errStatusHandler(res, history, dispatch);
          } else {
            resolve(true)
          }

        }
      );
    })
      .then((res) => {
        return res;
      })
      .catch((res) => {
        if (res.data.out_parameters.out_ret_code > 0) {
          dispatch(
            setNewMessage({
              message: res.data.out_parameters.out_error_msg,
              type: "Error",
              show: true,
            })
          );
          errStatusHandler(res, history, dispatch);
        }
      });
  };


  const updateCategoryFdmOrderAsync = (reqData, dispatch, history) => {
    return new Promise(function (resolve, reject) {
      POSTAPI(false, UPDATE_CATEGORY_FDM_ORDER, null, generateHeader(), reqData).then(
        (res) => {
          if (res?.data?.out_parameters.out_ret_code > 0) {
            resolve(false)
            errStatusHandler(res, history, dispatch);
          } else {
            resolve(true)
          }

        }
      );
    })
      .then((res) => {
        return res;
      })
      .catch((res) => {
        if (res.data.out_parameters.out_ret_code > 0) {
          dispatch(
            setNewMessage({
              message: res.data.out_parameters.out_error_msg,
              type: "Error",
              show: true,
            })
          );
          errStatusHandler(res, history, dispatch);
        }
      });
  };


  const updatePosition = (reqData, dispatch, history) => {
    return new Promise(function (resolve, reject) {
      POSTAPI(false, UPDATE_POSITION, null, generateHeader(), reqData).then(
        (res) => {
          if (res?.data?.out_parameters.out_ret_code > 0) {
            resolve(res.data.out_parameters)
            errStatusHandler(res, history, dispatch);
          } else {
            resolve(res.data.out_parameters)
          }

        }
      );
    })
      .then((res) => {
        return res;
      })
      .catch((res) => {
        if (res.data.out_parameters.out_ret_code > 0) {
          dispatch(
            setNewMessage({
              message: res.data.out_parameters.out_error_msg,
              type: "Error",
              show: true,
            })
          );
          errStatusHandler(res, history, dispatch);
        }
      });
  };

  const updateProvider = (reqData, dispatch, history) => {
    return new Promise(function (resolve, reject) {
      POSTAPI(false, UPDATE_PROVIDER, null, generateHeader(), reqData).then(
        (res) => {
          if (res?.data?.out_parameters.out_ret_code > 0) {
            resolve(res.data.out_parameters)
          } else {
            resolve(res.data.out_parameters)
          }

        }
      );
    })
      .then((res) => {
        return res;
      })
      .catch((res) => {
        if (res.data.out_parameters.out_ret_code > 0) {
          dispatch(
            setNewMessage({
              message: res.data.out_parameters.out_error_msg,
              type: "Error",
              show: true,
            })
          );
          errStatusHandler(res, history, dispatch);
        }
      });
  };

  const getProviderVersions = (reqData, dispatch, history) => {
    return new Promise(function (resolve, reject) {
      POSTAPI(false, GET_PROVIDER_EFF_DATES, null, generateHeader(), reqData).then(
        (res) => {
          if (res?.data?.out_parameters.out_ret_code > 0) {
            resolve([])
          } else {
            res.data.row_result.forEach((x) => {
              x.isSelected = x.is_current ? 1 : 0
              x.periodName = parseEffDateString(x.eff_start_date)
              x.isCurrent = x.is_current
            })
            resolve(res.data.row_result)
          }

        }
      );
    })
      .then((res) => {
        return res;
      })
      .catch((res) => {
        if (res.data.out_parameters.out_ret_code > 0) {
          dispatch(
            setNewMessage({
              message: res.data.out_parameters.out_error_msg,
              type: "Error",
              show: true,
            })
          );
          errStatusHandler(res, history, dispatch);
        }
      });
  };

  const getPositionVersions = (reqData, dispatch, history) => {
    return new Promise(function (resolve, reject) {
      POSTAPI(false, GET_POSITION_EFF_DATES, null, generateHeader(), reqData).then(
        (res) => {
          if (res?.data?.out_parameters.out_ret_code > 0) {
            resolve([])
          } else {
            res.data.row_result.forEach((x) => {
              x.isSelected = x.is_current ? 1 : 0
              x.periodName = parseEffDateString(x.eff_start_date)
              x.isCurrent = x.is_current
            })
            resolve(res.data.row_result)
          }

        }
      );
    })
      .then((res) => {
        return res;
      })
      .catch((res) => {
        if (res.data.out_parameters.out_ret_code > 0) {
          dispatch(
            setNewMessage({
              message: res.data.out_parameters.out_error_msg,
              type: "Error",
              show: true,
            })
          );
          errStatusHandler(res, history, dispatch);
        }
      });
  };

  const getProviderPositionVersionsData = (reqData, dispatch, history) => {
    return new Promise(function (resolve, reject) {
      POSTAPI(false, GET_PROVIDER_POSITION_VERSION_DATA, null, generateHeader(), reqData).then(
        (res) => {
          if (res?.data?.out_parameters.out_ret_code > 0) {
            resolve(res.data.out_parameters)
            errStatusHandler(res, history, dispatch);
          } else {
            // res.data.row_result.forEach((x) => {
            //   x.isSelected = false
            //   x.periodName = parseEffDateString(x.eff_start_date)
            // })
            resolve(res.data.row_result)
          }

        }
      );
    })
      .then((res) => {
        return res;
      })
      .catch((res) => {
        if (res.data.out_parameters.out_ret_code > 0) {
          dispatch(
            setNewMessage({
              message: res.data.out_parameters.out_error_msg,
              type: "Error",
              show: true,
            })
          );
          errStatusHandler(res, history, dispatch);
        }
      });
  };


  const insertProviderVersions = (reqData, dispatch, history) => {
    return new Promise(function (resolve, reject) {
      POSTAPI(false, INSERT_PROVIDER_EFF_VERSION, null, generateHeader(), reqData).then(
        (res) => {
          if (res?.data?.out_parameters.out_ret_code > 0) {
            dispatch(
              setNewMessage({
                message: res.data.out_parameters.out_error_msg,
                type: "Error",
                show: true,
              })
            );
            resolve(false)
            errStatusHandler(res, history, dispatch);
          } else {
            resolve(res.data.out_parameters)
          }

        }
      );
    })
      .then((res) => {
        return res;
      })
      .catch((res) => {
        if (res.data.out_parameters.out_ret_code > 0) {
          dispatch(
            setNewMessage({
              message: res.data.out_parameters.out_error_msg,
              type: "Error",
              show: true,
            })
          );
          errStatusHandler(res, history, dispatch);
        }
      });
  };


  const insertPositionVersions = (reqData, dispatch, history) => {
    return new Promise(function (resolve, reject) {
      POSTAPI(false, INSERT_POSITION_EFF_VERSION, null, generateHeader(), reqData).then(
        (res) => {
          if (res?.data?.out_parameters.out_ret_code > 0) {
            dispatch(
              setNewMessage({
                message: res.data.out_parameters.out_error_msg,
                type: "Error",
                show: true,
              })
            );
            resolve(false)
            errStatusHandler(res, history, dispatch);
          } else {
            resolve(res.data.out_parameters)
          }

        }
      );
    })
      .then((res) => {
        return res;
      })
      .catch((res) => {
        if (res.data.out_parameters.out_ret_code > 0) {
          dispatch(
            setNewMessage({
              message: res.data.out_parameters.out_error_msg,
              type: "Error",
              show: true,
            })
          );
          errStatusHandler(res, history, dispatch);
        }
      });
  };

  /** Method to Get System Setting Data */
  const systemSettingDataAsync = async () => {
    let dataObject = {
      in_tenant_id: cookiesData.out_tenant_id,
      pref_code: ["doc_dir", 'allow_max_no_of_doc', 'allow_max_doc_size'],
    };
    const resDataObject = await GetSystemConfigDataByCode(dataObject, dispatch);
    if (resDataObject) {
      setPreConfigData(resDataObject);
    }
  }

  const getSystemSettingETLSetting = async () => {
    let dataObject = {
      in_tenant_id: cookiesData.out_tenant_id,
      pref_code: ["etl_position_id_pattern"],
    };
    const resDataObject = await GetSystemConfigDataByCode(dataObject, dispatch);
    if (resDataObject) {
      const pattern = resDataObject.etl_position_id_pattern;
      const cleanedPattern = pattern.replace(/\|\s*_\s*\|/g, '|');
      const asPosIdPattern = cleanedPattern.split('|').map(item => item.trim());
      setDisabledFields(asPosIdPattern)
    }
  }


  const getDocumentData = async (obj) => {
    let reqData = {
      in_filter: `AND a.document_src_mstr_key = ${obj.mstr_key}`,
      in_sort_number: 1,
      in_sort_order: "DESC",
      in_page_row_offset: 0,
      in_page_rows: 9999
    }
    let res = await selectDocument(reqData, dispatch, history)
    if (res) {
      let resObj = {
        ...obj,
        document: res.data.row_result
      }
      setDocumentData(resObj)
      dispatch(
        setProviderAction(resObj)
      );
    }
  }

  const getProviderVersionsData = (reqData) => {
    return new Promise(function (resolve, reject) {
      POSTAPI(false, SELECT_PROVIDER_VERSION_DATA, null, generateHeader(), reqData).then(
        (res) => {
          if (res?.data?.out_parameters.out_ret_code > 0) {
            resolve(res.data.out_parameters)
            errStatusHandler(res, history, dispatch);
          } else {
            resolve(res.data.row_result)
          }

        }
      );
    })
      .then((res) => {
        return res;
      })
      .catch((res) => {
        if (res.data.out_parameters.out_ret_code > 0) {
          dispatch(
            setNewMessage({
              message: res.data.out_parameters.out_error_msg,
              type: "Error",
              show: true,
            })
          );
          errStatusHandler(res, history, dispatch);
        }
      });
  };



  return [
    {
      specialtyDataList,
      specialtyDataFields,
      setSpecialtyDataFields,
      groupDataFields,
      groupDataList,
      setGroupDataFields,
      setEmployeeDataFields,
      employeeDataFields,
      employeeDataList,
      npiDataFields,
      npiDataList,
      setNpiDataFields,
      positionDataFields,
      positionDataList,
      setPositionDataFields,
      searchListLoading,
      data,
      columns,
      dataCount,
      setData,
      editData,
      originalData,
      isAPICalling,
      setEditData,
      confirmationPopup,
      setConfirmationPopup,
      initialLoading,
      setAPICallingState,
      loading,
      setOriginalData,
      setRefreshLoading,
      isRefreshLoading,
      noDataFound,
      setLoadingStateSearch,
      setColumns,
      preConfigData,
      documentData,
      setDisabledFields,
      disabledFields,
      setSearchListLoading,
      setDataCount
    },
    {
      getSpecialtyDataList,
      getGroupDataList,
      getEmployeeDataList,
      getNpiDataList,
      getPostionDataList,
      getListDataAsync,
      getCategory,
      getCategoryField,
      insertProvider,
      insertPosition,
      insertCustomFields,
      deleteProvider,
      updateCategoryOrder,
      updateCategoryFdmOrderAsync,
      deletePosition,
      updatePosition,
      updateProvider,
      getProviderVersions,
      insertProviderVersions,
      getProviderPositionVersionsData,
      systemSettingDataAsync,
      getDocumentData,
      insertPositionVersions,
      getExportListDataAsync,
      getSystemSettingETLSetting,
      getProviderVersionsData,
      getPositionVersions
    },
  ];
}
    