export const DashboardConstant = {
    tabKey: "activeTabKey",
    defaultTabKey: 0,
    headerConfig: {
        title: "System Dashboards",
        id: "group",
    },
    publishDashabord: {
        title: "Publish",
    },
    addDashabordButton: {
        title: "Add Dashboard Tab",
    },
    addPODButton: {
        title: "Add Dashboard Pod",
    },
    copyDashabordButton: {
        title: "Copy Dashboard Tab",
    },
    editTabSettings: {
        title: "Edit Tab Settings",
    },
    deleteDashboardTab: {
        title: "Delete Dashboard Tab",
    },
    exportToPDF: {
        title: "Export to PDF",
    },
    notification: {
        success: {
            type: "success",
            text: "Data has been deleted successfully",
        },
        copySuccess: {
            type: "success",
            text: "Data has been copied successfully",
        },
        error: {
            type: "error",
            text: "Oops! Something went wrong ...",
        },
    },
};
