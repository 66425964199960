/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { BusinessGroupList } from "./businessGroupList";
import Popup from "../../components/customPopup";
import { Form, FormElement, FieldWrapper } from "@progress/kendo-react-form";
import { Label, Error } from "@progress/kendo-react-labels";
import { Input, TextArea } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Button } from "@progress/kendo-react-buttons";
import { BUSINESS_OBJECT_TYPES } from "../../constants/constants";
import MessageSchema from "../../utils/messageSchema";
import { getUserCredential } from "../../lib/cookies";
import { HeaderPage } from "../base/headerPage";

import "./businessgroup.scss";

import useBusinessGroupApi from "./service";
import DtuBatchLogs from "../common/dtuBatchLogs";
import {
  GetCompPlanDataApi,
  GetGroupNameAPI,
  GetPositionDataAPI,
  GetSPECIALTYAPI,
  GetUserDataAPI,
} from "../../lib/common-service";
import MultiSelectDropdown from "../../components/multiSelect";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useDocumentClickEventListener } from "../../lib/hooks/custom-common-hooks";
import UploadDtu from "../user/uploadDtu";
import { LoadingPanel } from "src/components/loadingPanel";

const initialFormFields = {
  in_business_group_name: "",
  in_business_group_desc: "",
  in_object_type: {
    text: "Position",
    value: "position",
  },
  userValue: "",
  userData: [],
  compPlanData: [],
  groupData: [],
  specialtyData: [],
  postionData: [],
  assignCompPlanValue: [],
};

const BusinessGroup = (pageProps) => {
  const history = useHistory();
  let divRef = React.createRef();

  const [list, Action] = useBusinessGroupApi();
  const dispatch = useDispatch();
  const cookiesData = getUserCredential();
  const addRef = React.useRef(null);
  const [columnsData, setColumnsData] = React.useState([]);
  const [isAddNewConfig, setNewConfig] = React.useState(false);
  const [isShow, setShow] = React.useState({
    dtuLogs: false,
    sendEmails: false,
    dtuUpload: false,
    deleteRows: false,
  });
  const [exportDataClick, setExportDataClick] = React.useState(false);
  const [isAPICall, setAPICall] = React.useState(false);
  const [isCheckColumn, setColumnCheck] = React.useState(false);
  const [formFields, setFormFields] = React.useState({ ...initialFormFields });
  const [errorObj, setErrorObj] = React.useState({});
  const [isSaveClick, setSaveClick] = React.useState(false);
  const [userDataList, setUserDataList] = React.useState([]);
  const [compPlanDataList, setAssignCompPlanList] = React.useState([]);
  const [specliatyList, setspecliatyList] = React.useState([]);
  const [postionList, setpostionList] = React.useState([]);
  const [groupList, setgroupList] = React.useState([]);

  const [loadingState, setLoadingState] = React.useState({
    isOwnerUserListLoading: false,
    isAssignCompListLoading: false,
    isCancelClick: false,
    isUpdateClick: false,
    isAddNewLoader: false,
  });
  const [dataList, setDataList] = React.useState([]);
  const [selectedRowId, setSelectedRowId] = React.useState("");
  const [isUpdatenewConfig, setUpdatenewConfig] = React.useState(false);

  const objDrpRef = React.useRef(null);
  let assignObjRef = React.useRef(null);
  let ownerUserRef = React.useRef(null);
  let popupRef = React.useRef();
  useDocumentClickEventListener(isAddNewConfig, setNewConfig, popupRef);

  React.useEffect(() => {
    if (list.loadingState.isSaveData) {
      setFormFields({ ...initialFormFields });
      setAPICall(true);
      setNewConfig(false);
    }
  }, [list.loadingState.isSaveData]);

  const addNewConfig = () => {
    setErrorObj({});
    setNewConfig(!isAddNewConfig);
    setFormFields(initialFormFields);
    // close update popup while add
    setUpdatenewConfig(false);
    handleOpenDropdown("user");
    handleOpenDropdown(formFields.in_object_type.text);
  };

  const handleMenuItemClick = (action) => {
    switch (action) {
      case "dtuLogs":
        setShow({
          ...isShow,
          dtuLogs: !isShow.dtuLogs,
        });
        break;
      case "export":
        setExportDataClick(true);
        break;
      case "delete":
        setShow({
          ...isShow,
          deleteRows: !isShow.deleteRows,
        });
        break;
      default:
        break;
    }
  };
  const ShowError = React.useCallback(
    (props) => {
      return <Error>{errorObj[props.name]}</Error>;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errorObj, isSaveClick]
  );

  const handleValidation = () => {
    let isValid = true;
    if (formFields.in_business_group_name.trim() === "") {
      isValid = false;
      errorObj.in_business_group_name = MessageSchema.groupNameRequired;
    }
    if (formFields.userValue.length === 0) {
      isValid = false;
      errorObj.userValue = MessageSchema.ownerUserRequired;
    }
    if (
      formFields.assignCompPlanValue &&
      formFields.assignCompPlanValue.length === 0
    ) {
      isValid = false;
      errorObj.assignCompPlanValue = MessageSchema.assignmentRequired;
    }

    setErrorObj((preVState) => ({ ...preVState, errorObj }));

    return isValid;
  };

  const handleSubmit = async () => {
    let valid = handleValidation();
    if (valid) {
      setLoadingState({ ...loadingState, isAddNewLoader: true });
      setErrorObj({});
      const bodyData = {
        in_user_id: cookiesData.out_user_id.toString(),
        in_tenant_id: cookiesData.out_tenant_id,
        in_business_group_name: formFields.in_business_group_name,
        in_business_group_desc: formFields.in_business_group_desc,
        in_object_type: formFields.in_object_type.value,
        in_owner_user_key:
          formFields.userValue.length > 0
            ? formFields.userValue.map((item) => {
                return { id: item.id };
              })
            : [],
        in_object_key: formFields.assignCompPlanValue
          ? formFields.assignCompPlanValue.map((item) => {
              return { id: item.id };
            })
          : [],
      };
      let res = await Action.addDataAsync(bodyData);
      if (res) {
        setLoadingState({ ...loadingState, isAddNewLoader: false });
      } else {
        setLoadingState({ ...loadingState, isAddNewLoader: false });
      }
    }
  };

  const onCancelClick = () => {
    setFormFields(initialFormFields);
    setErrorObj({});
    setNewConfig(false);
    setSaveClick(false);
  };

  const handleFieldChange = (e) => {
    const name = e.name ? e.name : e.target.name;
    if (name === "in_object_type") {
      setFormFields({
        ...formFields,
        [name]: e.value,
        assignCompPlanValue: [],
      });
      handleOpenDropdown(e.value.text);
    } else {
      setFormFields({ ...formFields, [name]: e.value });
    }
    setErrorObj({ ...errorObj, [name]: "" });
  };

  const handleOpenDropdown = async (module) => {
    switch (module) {
      case "user":
        let data = {
          in_tenant_id: cookiesData.out_tenant_id,
          in_filter: "AND a.db_role_type_name = 'Business Group Manager'",
          in_sort_number: 1,
          in_sort_order: "DESC",
          in_page_row_offset: 0,
          in_page_rows: 9999,
        };
        setLoadingState((preVState) => ({
          ...preVState,
          isOwnerUserListLoading: true,
        }));
        let res = await GetUserDataAPI(data, history, dispatch);
        if (res) {
          let tempArray = [];
          res.map((item) => {
            let obj = {};
            obj.text = item.user_username;
            obj.id = item.user_key;
            tempArray.push(obj);
            return 0;
          });
          setUserDataList(tempArray);
          setFormFields((preVState) => ({
            ...preVState,
            userData: tempArray,
            compPlanData: formFields.compPlanData,
          }));
          setLoadingState((preVState) => ({
            ...preVState,
            isOwnerUserListLoading: false,
          }));
        }
        break;
      case "Position":
        let dataObject = {
          in_tenant_id: cookiesData.out_tenant_id,
          in_filter: null,
          in_user_key: cookiesData.out_user_id,
          in_sort_number: 2,
          in_sort_order: "ASC",
          in_page_row_offset: 0,
          in_page_rows: 9999,
          in_acct_encrypt_key: cookiesData.out_acct_encrypt_key,
          in_user_role_filter: null,
          in_export_flag: 0,
          in_is_current_flag: 1,
        };
        setLoadingState((preVState) => ({
          ...preVState,
          isAssignCompListLoading: true,
        }));
        setpostionList([]);
        setFormFields((preVState) => ({ ...preVState, postionData: [] }));
        let resDataObject = await GetPositionDataAPI(
          dataObject,
          history,
          dispatch
        );
        if (resDataObject) {
          let tempArray = [];
          resDataObject.map((item) => {
            let obj = {};
            obj.text = item.position_name;
            obj.id = item.org_position_key;
            tempArray.push(obj);
            return 0;
          });
          setpostionList(tempArray);
          setFormFields((preVState) => ({
            ...preVState,
            postionData: tempArray,
          }));
          setLoadingState((preVState) => ({
            ...preVState,
            isAssignCompListLoading: false,
          }));
        }
        break;
      case "Group":
        let dataValue = {
          in_tenant_id: cookiesData.out_tenant_id,
          in_filter: null,
          in_user_role_filter: null,
          in_sort_number: 3,
          in_sort_order: null,
          in_page_row_offset: 0,
          in_page_rows: 9999,
        };
        setLoadingState((preVState) => ({
          ...preVState,
          isAssignCompListLoading: true,
        }));
        setgroupList([]);
        setFormFields((preVState) => ({ ...preVState, groupData: [] }));
        let resData = await GetGroupNameAPI(dataValue, history, dispatch);
        if (resData) {
          let tempArray1 = [];
          resData.map((item) => {
            let obj = {};
            obj.text = item.title_name;
            obj.id = item.org_title_key;
            tempArray1.push(obj);
            return 0;
          });
          setgroupList(tempArray1);
          setFormFields((preVState) => ({
            ...preVState,

            groupData: tempArray1,
          }));
          setLoadingState((preVState) => ({
            ...preVState,
            isAssignCompListLoading: false,
          }));
        }

        break;
      case "Specialty":
        let dataObjValue = {
          in_tenant_id: cookiesData.out_tenant_id,
          in_user_key: cookiesData.out_user_id,
          in_filter: null,
          in_sort_number: 3,
          in_sort_order: "DESC",
          in_page_row_offset: 0,
          in_page_rows: 9999,
        };
        setLoadingState((preVState) => ({
          ...preVState,
          isAssignCompListLoading: true,
        }));
        setspecliatyList([]);
        setFormFields((preVState) => ({ ...preVState, specialtyData: [] }));
        let resDataObjValue = await GetSPECIALTYAPI(
          dataObjValue,
          history,
          dispatch
        );
        if (resDataObjValue) {
          let tempArray = [];
          resDataObjValue.map((item) => {
            let obj = {};
            obj.text = item.specialty_name;
            obj.id = item.specialty_mstr_key;
            tempArray.push(obj);
            return 0;
          });
          setspecliatyList(tempArray);
          setFormFields((preVState) => ({
            ...preVState,
            specialtyData: tempArray,
          }));
          setLoadingState((preVState) => ({
            ...preVState,
            isAssignCompListLoading: false,
          }));
        }
        break;
      case "Comp plan":
        let dataObj = {
          in_tenant_id: cookiesData.out_tenant_id,
          in_filter: null,
          in_user_role_filter: null,
          in_user_key: cookiesData.out_user_id,
          in_sort_number: null,
          in_sort_order: null,
          in_rule_result_table: null,
          in_multi_sort_order: null,
        };
        setLoadingState((preVState) => ({
          ...preVState,
          isAssignCompListLoading: true,
        }));
        setAssignCompPlanList([]);
        setFormFields((preVState) => ({ ...preVState, compPlanData: [] }));
        let resDataObj = await GetCompPlanDataApi(dataObj, history, dispatch);
        if (resDataObj) {
          let tempArray = [];
          resDataObj.map((item) => {
            let obj = {};
            obj.text = item.comp_plan_name;
            obj.id = item.comp_plan_mstr_key;
            tempArray.push(obj);
            return 0;
          });
          setAssignCompPlanList(tempArray);
          setFormFields((preVState) => ({
            ...preVState,
            compPlanData: tempArray,
          }));
          setLoadingState((preVState) => ({
            ...preVState,
            isAssignCompListLoading: false,
          }));
        }

        break;
      default:
        break;
    }
  };

  const AddNewForm = () => {
    return (
      <Form
        onSubmit={handleSubmit}
        render={() => (
          <FormElement className="emailForm" horizontal={true}>
            <FieldWrapper className="full-width">
              <div className={"k-form-field-wrap-popup width-full"}>
                <Label>{"Group Name*:"}</Label>
                <Input
                  type={"text"}
                  name="in_business_group_name"
                  placeholder="Group Name"
                  value={formFields.in_business_group_name}
                  onChange={(e) => {
                    handleFieldChange(e);
                  }}
                  autoFocus={true}
                />
                <ShowError name={"in_business_group_name"} />
              </div>
            </FieldWrapper>
            <FieldWrapper className="full-width">
              <div className={"k-form-field-wrap-popup width-full"}>
                <Label>{"Group Description:"}</Label>
                <TextArea
                  rows={4}
                  value={formFields.in_business_group_desc}
                  onChange={(e) => {
                    handleFieldChange(e);
                  }}
                  name="in_business_group_desc"
                  placeholder="Group Description"
                />
                <ShowError name={"in_business_group_desc"} />
              </div>
            </FieldWrapper>
            <FieldWrapper className="full-width">
              <div className={"k-form-field-wrap-popup"} ref={objDrpRef}>
                <Label>{"Assign Object Type:"}</Label>
                <DropDownList
                  onChange={handleFieldChange}
                  value={BUSINESS_OBJECT_TYPES.find(
                    (c) => c.text === formFields.in_object_type.text
                  )}
                  data={BUSINESS_OBJECT_TYPES}
                  textField="text"
                  name="in_object_type"
                  popupSettings={{ appendTo: objDrpRef.current }}
                />
              </div>
            </FieldWrapper>
            <FieldWrapper className="full-width">
              <div
                className={"k-form-field-wrap-popup valueDropdown width-full"}
                ref={assignObjRef}
              >
                <Label>{`Assign ${formFields.in_object_type.text} * :`}</Label>
                <MultiSelectDropdown
                  name={"assignCompPlanValue"}
                  module="assignCompPlan"
                  data={
                    formFields.in_object_type.value === "group"
                      ? formFields.groupData
                      : formFields.in_object_type.value === "specialty"
                      ? formFields.specialtyData
                      : formFields.in_object_type.value === "position"
                      ? formFields.postionData
                      : formFields.compPlanData
                  }
                  textField="text"
                  dataItemKey="id"
                  valueData={formFields.assignCompPlanValue}
                  onOpen={(dataV) => {
                    setFormFields({ ...formFields, compPlanData: dataV });
                  }}
                  onChange={(data, name) => {
                    setErrorObj({ ...errorObj, [name]: "" });
                    switch (formFields.in_object_type.value) {
                      case "group":
                        setFormFields({
                          ...formFields,
                          groupData: groupList,
                          [name]: data,
                        });
                        break;
                      case "specialty":
                        setFormFields({
                          ...formFields,
                          specialtyData: specliatyList,
                          [name]: data,
                        });
                        break;
                      case "position":
                        setFormFields({
                          ...formFields,
                          postionData: postionList,
                          [name]: data,
                        });
                        break;
                      default:
                        setFormFields({
                          ...formFields,
                          compPlanData: compPlanDataList,
                          [name]: data,
                        });
                        break;
                    }
                  }}
                  filterable={true}
                  onFilterChange={(dataV) => {
                    switch (formFields.in_object_type.value) {
                      case "group":
                        setFormFields({ ...formFields, groupData: dataV });
                        break;
                      case "specialty":
                        setFormFields({ ...formFields, specialtyData: dataV });
                        break;
                      case "position":
                        setFormFields({ ...formFields, postionData: dataV });
                        break;
                      default:
                        setFormFields({ ...formFields, compPlanData: dataV });
                        break;
                    }
                  }}
                  appendTo={assignObjRef.current}
                  autoClose={false}
                  autoBind={true}
                  loading={loadingState.isAssignCompListLoading}
                  isSelectALl={false}
                  dataList={
                    formFields.in_object_type.value === "group"
                      ? groupList
                      : formFields.in_object_type.value === "specialty"
                      ? specliatyList
                      : formFields.in_object_type.value === "position"
                      ? postionList
                      : compPlanDataList
                  }
                  placeholder={"Select Assignment"}
                />
                <ShowError name={"assignCompPlanValue"} />
              </div>
            </FieldWrapper>
            <FieldWrapper className="full-width">
              <div
                className={"k-form-field-wrap-popup valueDropdown width-full"}
                ref={ownerUserRef}
              >
                <Label>{"Owner User*:"}</Label>
                <MultiSelectDropdown
                  name={"userValue"}
                  module="user"
                  data={formFields.userData}
                  textField="text"
                  placeholder={"Select Owner User"}
                  dataItemKey="id"
                  valueData={formFields.userValue}
                  onOpen={(dataV) => {
                    setFormFields({ ...formFields, userData: dataV });
                  }}
                  onChange={(data, name) => {
                    setFormFields({
                      ...formFields,
                      [name]: data,
                      userData: userDataList,
                    });
                    setErrorObj({ ...errorObj, [name]: "" });
                  }}
                  filterable={true}
                  onFilterChange={(data) => {
                    setFormFields({
                      ...formFields,
                      userData: data,
                    });
                  }}
                  autoClose={false}
                  autoBind={true}
                  isSelectALl={false}
                  dataList={userDataList}
                  loading={loadingState.isOwnerUserListLoading}
                  appendTo={ownerUserRef.current}
                />
                <ShowError name={"userValue"} />
              </div>
            </FieldWrapper>
            <FieldWrapper className="full-width"></FieldWrapper>
          </FormElement>
        )}
      />
    );
  };

  const AddNewPopup = () => {
    return (
      <Popup anchor={addRef.current} show={isAddNewConfig} className="right0">
        <div className="dot-dropdown-open dot-dropdown-open-Form">
          <div className="caption">
            <span>Add New Business Group</span>
          </div>

          {AddNewForm()}

          <div className="dropdownFooter">
            <div className="k-form-buttons">
              <Button
                primary={true}
                type="submit"
                onClick={() => {
                  onCancelClick();
                }}
              >
                Cancel
              </Button>
              <Button
                primary={true}
                type="submit"
                onClick={() => {
                  handleSubmit(formFields, errorObj);
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </Popup>
    );
  };

  const AddNewLink = () => {
    return (
      <div ref={popupRef}>
        <a
          // href="/#"
          className="cta-small"
          ref={addRef}
          onClick={(e) => {
            addNewConfig(e);
          }}
        >
          <i className="fas fa-plus"></i> Add New Business Group
        </a>

        {AddNewPopup()}
      </div>
    );
  };

  const handleUpload = (e) => {
    setShow({
      ...isShow,
      dtuUpload: !isShow.dtuUpload,
    });
    e.preventDefault();
  };

  const headerConfig = {
    title: "Business Group",
    id: "business_group",
    // buttons: { template_fx: GridButton },
    add_new_fx: AddNewLink,
    upload_fx: handleUpload,
    action_menu: {
      export: { fx: handleMenuItemClick },
      dtuLog: { fx: handleMenuItemClick },
      delete: { fx: handleMenuItemClick },
    },
    manage_col: {
      columnsData: columnsData,
      setColumnsData: setColumnsData,
      setColumnCheck: setColumnCheck,
    },
  };

  const parent = document.querySelector("#content-" + headerConfig.id);

  if (parent) {
    const exportToExcel = parent.querySelector(".export-to-excel");
    const auditLog = parent.querySelector(".audit-log");
    const deleteRows = parent.querySelector(".delete-rows");

    exportToExcel && exportToExcel.disableKendoMenu();
    auditLog && auditLog.disableKendoMenu();
    deleteRows && deleteRows.disableKendoMenu();
    dataList.length && exportToExcel && exportToExcel.enableKendoMenu();

    if (selectedRowId !== "") {
      auditLog && auditLog.enableKendoMenu();
      deleteRows && deleteRows.enableKendoMenu();
    }
  }

  return (
    <>
      {loadingState.isAddNewLoader ? <LoadingPanel /> : false}
      <div className="Main businessgroup">
        <div className="content">
          <div className="content-head">
            <HeaderPage pageProps={pageProps} headerConfig={headerConfig} />
          </div>
          <div className="content-body">
            <div
              className="dtu-file-container"
              ref={divRef}
              id="edit-raw-container"
            >
              <BusinessGroupList
                isAPICall={isAPICall}
                setAPICall={setAPICall}
                setShow={setShow}
                setDataList={setDataList}
                setExportDataClick={setExportDataClick}
                exportDataClick={exportDataClick}
                setdivRef={divRef}
                selectedRowId={selectedRowId}
                setSelectedRowId={setSelectedRowId}
                isShow={isShow}
                addNewConfig={addNewConfig}
                setNewConfig={setNewConfig}
                isUpdatenewConfig={isUpdatenewConfig}
                setUpdatenewConfig={setUpdatenewConfig}
                pageProps={pageProps}
                columnsData={columnsData}
                setColumnsData={setColumnsData}
                setColumnCheck={setColumnCheck}
                isCheckColumn={isCheckColumn}
              />
            </div>
          </div>
        </div>
        {isShow.dtuLogs && (
          <DtuBatchLogs
            visible={isShow}
            setShow={setShow}
            module={"business_group_master"}
            pageProps={pageProps}
          />
        )}

        {isShow.dtuUpload && (
          <UploadDtu
            visible={isShow}
            setShow={setShow}
            title={"Upload Business Groups"}
            pageId={"business_group"}
            setAPICall={setAPICall}
          />
        )}
      </div>
    </>
  );
};

export default React.memo(BusinessGroup);
