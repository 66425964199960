import React, { useEffect, useRef, useState } from "react";
import { HeaderPage } from "../base/headerPage";
import PlanCompFilter from "./filter";
import { LoadingPanel } from "src/components/loadingPanel";
import SectionHeader from "./sectionHeader";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { compPlanStatementSelector } from "src/redux/selectors/custom-selector";
import useCompPlanStatementService from "./service";
import CompPlanSummaryHeader from "./CompPlanSummaryHeader";
import KPIPaymentSummary from "./KPIPaymentSummary";
import CompPlanSectionTwo from "./CompPlanSectionTwo";
import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import { renderToString } from "react-dom/server";
import PDFPageTemplate from "./PDFPageTemplate";
import { saveAs } from "@progress/kendo-file-saver";
import { getReportScreenHeight } from "src/utils/utils";
import {
  setPrefetchDropdownAction,
  setShowReportResult,
  setShowSection2,
} from "src/redux/actions/compplan-actions";

const PlanCompStatementReport = (pageProps) => {
  const [isReportRunning, setIsRunningReport] = useState(false);
  const [isExporting, setIsExporting] = useState(false);

  const exportSec1Ref = useRef(null);
  const exportSec2Ref = useRef(null);

  const {
    selectedCompPlan,
    selectedCompPlanPeriod,

    showSection1,
    showSection2,
    compPlanSummaryHeader,
    kpiPaymentSummary,
    showReportResults,
  } = useSelector(compPlanStatementSelector, shallowEqual);

  const [reportHeight, setReportHeight] = useState(0);
  const [isReady, setIsReady] = useState(false);
  const reportService = useCompPlanStatementService();
  const dispatch = useDispatch();
  const headerConfig = {
    title: "Plan Comp Statement",
    id: "plan_comp_statement_report",
  };

  useEffect(() => {
    if(isReady) {
      if (showReportResults) {
        setReportHeight(getReportScreenHeight() - 20);
      } else {
        setReportHeight(0);
      }
    }
    
  }, [showReportResults, isReady]);

  const runReports = async () => {
    setIsRunningReport(true);
    //dispatch(setPrefetchDropdownAction(false));
    dispatch(setShowReportResult(false));
    dispatch(setShowSection2(true));
    try {
      await reportService.getAutoHideSection1();
      await reportService.fetchSummaryHeaderProvider();
      await reportService.fetchPaymentSummary({
        in_filter: null,
        in_sort_number: 2,
        in_sort_order: "ASC",
      });
      await reportService.fetchReportSummaryColumnHeaders();
      await reportService.fetchReportPlanGridDetails();
    } catch (error) {
    } finally {
      setIsRunningReport(false);
    }
  };

  const handleExport = async () => {
    const option = {
      paperSize: "A4",
      landscape: true,
      repeatHeaders: true,
      allPages: true,
      forcePageBreak: ".page-break",
      avoidLinks: true,
      margin: { top: "2cm", left: "0.5cm", right: "0.5cm", bottom: "1.5cm" },
      scale: 0.5,
      template: (props) => {
        let newProps = { ...props };
        newProps.compPlan = selectedCompPlan.comp_plan_name;
        newProps.period = selectedCompPlanPeriod.period_name;
        let z = document.createElement("div");
        z.innerHTML = renderToString(PDFPageTemplate(newProps));
        return z;
      },
    };
    try {
      setIsExporting(true);
      setTimeout(async () => {
        let group1;
        let group2;
        if (showSection1) {
          group1 = await drawDOM(exportSec1Ref.current, option);
        }
        if (showSection2) {
          await new Promise((r) => setTimeout(r, 1000));
          group2 = await drawDOM(exportSec2Ref.current, {
            ...option,
            scale: 0.46,
          });
        }

        if (group1 && group2) {
          group2.children.forEach((it) => {
            group1.children.push(it);
          });
          exportPDF(group1, { multiPage: true })
            .then((uri) => {
              saveAs(uri, "plan_comp_statement.pdf");
              setIsExporting(false);
            })
            .catch(() => {
              setIsExporting(false);
            });
        } else if (group1) {
          exportPDF(group1, { multiPage: true })
            .then((uri) => {
              saveAs(uri, "plan_comp_statement.pdf");
              setIsExporting(false);
            })
            .catch(() => {
              setIsExporting(false);
            });
        } else if (group2) {
          exportPDF(group2, { multiPage: true })
            .then((uri) => {
              saveAs(uri, "plan_comp_statement.pdf");
              setIsExporting(false);
            })
            .catch(() => {
              setIsExporting(false);
            });
        }
      }, 2000);
    } catch (error) {}
  };


  useEffect(() => {
    let timer = setTimeout(() => setIsReady(true), 1500);
    return () => clearTimeout(timer);
  }, []);
 
  if(!isReady) {
    return <LoadingPanel />
    }
    
  return (
    <div className="Main">
      <div className="content">
        <div className="content-head">
          <HeaderPage pageProps={pageProps} headerConfig={headerConfig} />
        </div>
        <div className="content-body">
          <div id="topSection">
            <PlanCompFilter runReports={runReports} />

            {showReportResults ? (
              <SectionHeader handleExport={handleExport} />
            ) : null}
          </div>
          <div
            style={{
              overflowY: showSection1 ? "scroll" : "hidden",
              overflowX: "hidden",
              maxHeight: reportHeight,
              marginTop: 20,
            }}
          >
            <div>
              <div ref={exportSec1Ref}>
                {showReportResults &&
                showSection1 &&
                compPlanSummaryHeader.columns.length ? (
                  <CompPlanSummaryHeader isExporting={isExporting} />
                ) : null}
                {showReportResults &&
                showSection1 &&
                kpiPaymentSummary.columns.length ? (
                  <div className="page-break">
                    <KPIPaymentSummary
                      isReportRunning={isReportRunning}
                      isExporting={isExporting}
                    />
                  </div>
                ) : null}
              </div>
              {showReportResults && showSection2 ? (
                <div
                  ref={exportSec2Ref}
                  className={showSection1 ? "page-break" : ""}
                >
                  <CompPlanSectionTwo
                    isReportRunning={isReportRunning}
                    isExporting={isExporting}
                    reportHeight={reportHeight}
                  />
                </div>
              ) : null}
            </div>
          </div>
          {isReportRunning || isExporting ? <LoadingPanel /> : null}
        </div>
      </div>
    </div>
  );
};

export default PlanCompStatementReport;
