import {

    SET_CUSTOM_TABLE_INPUT_FIELD_DATA,
  } from "../actions/formula-actions";

  const initialState = {
    customTableInputFieldList: []
  }
  
  const formulaReducer = (
    state = initialState,
    action
  ) => {
    switch (action.type) {
      
      case SET_CUSTOM_TABLE_INPUT_FIELD_DATA:
        state = { ...state, customTableInputFieldList: action.data };
        break;
      
      default:
        state =  initialState;
        break;
    }
    return state;
  };
  
  export default formulaReducer;
  