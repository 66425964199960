import { SET_PROVIDER_ACTION } from "../actions/provider-action";

const initialState = {
    providerData: {},
};

const providerReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PROVIDER_ACTION:
            return {
                ...state,
                providerData: action.data.data,
            };
        default:
            return state;
    }
};

export default providerReducer;
