import React from "react";
import { CHANGE_PASSWORD, TENANT_SYSTEM_CONFIG } from "../../api-setup/api-endpoints";
import {
    POSTAPI,
    errStatusHandler,
    generateHeader,
} from "../../api-setup/api-helper";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { setNewMessage } from "../../redux/actions/setting-actions";

export default function Profile() {
    const history = useHistory();
    const dispatch = useDispatch();
    const [isRefreshLoading, setRefreshLoading] = React.useState(false);

    
    const changePasswordAsync = async (bodyData) => {

        setRefreshLoading(true);
        return new Promise(function (resolve, reject) {
            POSTAPI(false, CHANGE_PASSWORD, null, generateHeader(), bodyData, dispatch).then(
                (res) => {
                setRefreshLoading(false)
                  if (res.data.out_parameters.out_ret_code === 0) {
                    if (res.msg) {
                      dispatch(
                        setNewMessage({
                          message: bodyData?.updatingFrom === "manage-profile" ? "Profile updated successfully" :
                            "Password changed successfully - login with new password",
                          type: "Success",
                          show: true,
                        })
                      );
                      resolve(res)
                    }
                  } else {
                
                    dispatch(
                      setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                      })
                    );
                  }
                  resolve(res)
                });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    const tenantSystemConfigAsync = async (bodyData, header) => {
        setRefreshLoading(true);
        return new Promise(function (resolve, reject) {
            POSTAPI(false, TENANT_SYSTEM_CONFIG, null, header, bodyData, dispatch).then(
                (res) => {
                  setRefreshLoading(false);
                  if (res.status) {
                    resolve(res)
                  }
                }
              );
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };
    
    return [
        {
            setRefreshLoading,
            isRefreshLoading,
        },
        {
            changePasswordAsync,
            tenantSystemConfigAsync,
        },
    ];
}
