import * as React from "react";
import { Grid, GridNoRecords } from "@progress/kendo-react-grid";
import { LoadingPanel } from "./loadingPanel";
import { Tooltip } from "@progress/kendo-react-tooltip";
import GridPager from "./gridPager";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { process } from "@progress/kendo-data-query";
import { useCallback } from "react";
import { INIT_GRID_HEIGHT } from "src/constants/grid";
import { getGridHeight } from "src/utils/utils";
import { filterBy } from "@progress/kendo-data-query";
import { useMemo } from "react";

const SELECTED_FIELD = "selected";

const filterOperators = {
    text: [
        { text: "grid.filterContainsOperator", operator: "contains" },
        {
            text: "grid.filterNotContainsOperator",
            operator: "doesnotcontain",
        },
        { text: "grid.filterEqOperator", operator: "eq" },
        { text: "grid.filterNotEqOperator", operator: "neq" },
        { text: "grid.filterIsEmptyOperator", operator: "isempty" },

        { text: "grid.filterIsNotEmptyOperator", operator: "isnotempty" },
    ],
    numeric: [
        { text: "grid.filterEqOperator", operator: "eq" },
        { text: "grid.filterNotEqOperator", operator: "neq" },
        { text: "grid.filterGteOperator", operator: "gte" },
        { text: "grid.filterGtOperator", operator: "gt" },
        { text: "grid.filterLteOperator", operator: "lte" },
        { text: "grid.filterLtOperator", operator: "lt" },
        { text: "grid.filterIsEmptyOperator", operator: "isempty" },
        { text: "grid.filterIsNotEmptyOperator", operator: "isnotempty" },
    ],
    date: [
        { text: "grid.filterEqOperator", operator: "eq" },
        { text: "grid.filterNotEqOperator", operator: "neq" },
        { text: "grid.filterAfterOrEqualOperator", operator: "gte" },
        { text: "grid.filterAfterOperator", operator: "gt" },
        { text: "grid.filterBeforeOperator", operator: "lt" },
        { text: "grid.filterBeforeOrEqualOperator", operator: "lte" },
        { text: "grid.filterIsNullOperator", operator: "isnull" },
        { text: "grid.filterIsNotNullOperator", operator: "isnotnull" },
    ],
    boolean: [{ text: "grid.filterEqOperator", operator: "eq" }],
};


const TooltipContentTemplate = React.memo((props) => {
    const title = props.title?.title || '';

    return (
        <div className="pc-grid-tooltip">
            <strong>{title}</strong>
        </div>
    );
});

const isEllipsisActive = (element) => {
    let returnBool = false;

    const allowTags = ["SPAN", "TD"];

    if (!allowTags.includes(element.tagName)) {
        return returnBool;
    }

    let tdElement = element;
    let spanElement = element;

    if (element.tagName === "TD") {
        spanElement = element.firstChild;
    } else {
        tdElement = element.closest("td");
    }

    if (!tdElement || !spanElement) {
        return returnBool;
    }

    if (spanElement?.className?.includes("k-icon")) {
        return returnBool;
    }

    const bodyStyle = window.getElementStyle(tdElement);
    const tdPadding =
        parseFloat(bodyStyle["paddingLeft"]) +
        parseFloat(bodyStyle["paddingRight"]);

    const tdWidth = tdElement.offsetWidth - tdPadding;
    const spanWidth = spanElement.offsetWidth;

    // text was truncated.
    if (spanWidth > tdWidth) {
        spanElement.title = spanElement.innerText;
        returnBool = true;
    } else {
        spanElement.title = "";
    }

    return returnBool;
};


const DataTable = (restProps) => {
    const _export = React.useRef(null);
    const _grid = React.useRef();
    const [isExportLoading, setExportLoading] = React.useState(false);
    const [dataState, setDataState] = React.useState("");
    const [filteredMembersState, setFilteredMembersState] = React.useState({});
    const [generateHeight, setGenerateHeight] =
        React.useState(INIT_GRID_HEIGHT);

    const {
        isAPICalling,
        initialLoading,
        itemChange,
        handleColumnMenu,
        editField,
        pageData,
        dataCount,
        onRowDoubleClick,
        onRowClick,
        dataItemKey,
        customColumn,
        columns,
        gridHeight = "auto",
        pageSize,
        setPageSize,
        handlePageChange,
        isExportDataClick,
        setExportData,
        fileName,
        getExportData,
        exportData,
        className = "",
        onClearFilter,
        cellRender,
        rowRender,
        expandChange,
        DetailComponent,
        errorMsg = "No records available",
        refreshClick,
        reorderable = true,
        groupable,
        onGroupChange,
        group,
        module,
        isSortable = true,
        isResizable = true,
        isPagination = true,
        onSelectionChange,
        noDataFound = false,
        id = "data-grid",
        resetSort,
        initialSort = [],
        initialFilter = { logic: "and", filters: [] },
        sortMode = "single",
        collapseRows,
        columnReorderData,
        isFilterData,
        stageChange,
        autoScrollIndex = -1,
        scrollable = "scrollable",
        data,
        isDragMode = true,
    } = restProps;

    const [sortField, setSortField] = React.useState(initialSort);
    const [filterField, setFilterField] = React.useState(initialFilter);


    const reApplyFilter = useCallback(
        (colObj) => {
            for (let e_filter of colObj) {
                e_filter.classList.remove("filter-highlight");

                for (let filteredKey in filteredMembersState) {
                    let dataField = e_filter.querySelector(
                        "span[data-field='" + filteredKey + "']"
                    );
                    dataField && e_filter.classList.add("filter-highlight");
                }
            }
        },
        [filteredMembersState]
    );

    React.useEffect(() => {
        if (_grid.current) {
            const currentGridElement = _grid.current.element;
            reApplyFilter(
                currentGridElement.querySelectorAll(
                    ".k-grid-header thead tr th"
                )
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columns, dataCount, reApplyFilter]);

    React.useEffect(() => {
        if (
            _grid.current &&
            gridHeight === "auto" &&
            generateHeight === INIT_GRID_HEIGHT
        ) {
            const newHeight = getGridHeight(_grid.current.element);
            setGenerateHeight(newHeight);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_grid.current]);

    React.useEffect(() => {
        if (_grid.current && gridHeight === "auto") {
            const newHeight = getGridHeight(_grid.current.element);
            setGenerateHeight(newHeight);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_grid.current, stageChange]);

    React.useEffect(() => {
        if (_grid.current && autoScrollIndex >= 0) {
            setTimeout(() => {
                _grid.current?.scrollIntoView({
                    rowIndex: autoScrollIndex,
                });
            }, 500);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoScrollIndex]);

    React.useEffect(() => {
        if (resetSort === true) {
            //manually clear grid sorting
            setSortField([]);
            setFilterField({ logic: "and", filters: [] });
            setFilteredMembersState({});

            let thisDataState = { ...dataState };

            //manually clear grid filtering
            if (thisDataState.filter) {
                thisDataState.filter = [];

                setDataState({
                    thisDataState,
                });
            }

            const currentGridElement = _grid.current.element;
            const allElements =
                currentGridElement.querySelectorAll(".filter-highlight");

            //manually remove highlight grid header
            allElements.forEach((element) => {
                element.classList.remove("filter-highlight");
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetSort, dataState]);

    const createDataState = useCallback((dataState) => {
        return {
            result: process(data.slice(0), dataState),
            dataState,
        };
    }, [data]);

    React.useEffect(() => {
        if (exportData && exportData.length > 0 && isExportLoading) {
            if (_export.current !== null) {
                if (typeof collapseRows === "function") collapseRows();

                const columnsValue = columns
                    .filter(col => col.show && col.title && col.field !== "expand")
                    .map(col => {
                        if (col.field.includes("_mstr_key")) {
                            return { ...col, type: "string", field: col.field.replace("_mstr_key", "_name") };
                        }
                        return col;
                    });

                _export.current.save(exportData, columnsValue);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [exportData, isExportLoading]);

    React.useEffect(() => {
        if (isExportDataClick) {
            setExportData(false);
            setExportLoading(true);
            getExportData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isExportDataClick]);


    const dataStateChange = async (event) => {
        if (typeof collapseRows === "function") {
            collapseRows();
        }

        let isFilter = false;
        let isSort = false;
        let updatedState = createDataState(event.dataState);

        setDataState(updatedState.dataState);

        if (event.dataState.filter) {
            isFilter = true;

            const filterObj = event.dataState.filter;

            await handleColumnMenu(
                filterObj.filters,
                isFilter,
                isSort,
                filterObj
            );
            setFilterField(filterObj);
        } else if (event.dataState.sort && event.nativeEvent.type !== "reset") {
            isSort = true;
            handleColumnMenu(event.dataState.sort, isFilter, isSort);
        } else {
            setFilteredMembersState({});
            setFilterField({ logic: "and", filters: [] });
            onClearFilter();
        }
    };

    const handleOnSortChange = async (event) => {
        if (typeof collapseRows === "function") {
            collapseRows();
        }

        const sortField = event.sort;

        handleColumnMenu && handleColumnMenu(sortField, false, true);

        await setSortField(sortField);
        await setFilterField(event.target.props.filter);
    };

    const pageChange = (event) => {
        if (
            event.syntheticEvent.value &&
            event.syntheticEvent.value.recordTake
        ) {
            setPageSize(event.syntheticEvent.value);
        }
        handlePageChange(event);
    };

    const CustomPager = (props) => {
        return isPagination ? (
            <GridPager
                {...props}
                defaultItem={pageSize}
                data={data}
                refreshClick={refreshClick}
                module={module}
                group={group}
                isFilterData={isFilterData}
                dataCount={dataCount}
            />
        ) : (
            false
        );
    };

    // Export Handling
    const handleExport = useCallback(() => {
        setExportLoading(false);
    }, []);


    // Column Reorder Handling
    const onColumnReorder = useCallback((e) => {
        if (typeof columnReorderData === "function") {
            columnReorderData(e.target.columns);
        }
    }, [columnReorderData]);


    // Filter Change Handling
    const onFilterChange = useCallback((e) => {
        if (module === "job-postion-grid") {
            setFilterField(e.filter);
        }
    }, [module]);

    const showLoadingPanel = useMemo(() => isAPICalling || isExportLoading, [isAPICalling, isExportLoading]);
    const isDataAvailable = useMemo(() => initialLoading && data && data.length, [initialLoading, data]);

    const gridStyle = useMemo(() => ({
        height:
            gridHeight === "auto"
                ? generateHeight
                : gridHeight === ""
                    ? "100%"
                    : gridHeight,
    }), [gridHeight, generateHeight]);

    return (
        <>
            {showLoadingPanel && <LoadingPanel gridId={id} />}
            {isDataAvailable ? (
                <Tooltip
                    content={(props) => (
                        <TooltipContentTemplate title={props} />
                    )}
                    filter={isEllipsisActive}
                    openDelay={100}
                    position="auto"
                >
                    <ExcelExport
                        fileName={fileName}
                        ref={_export}
                        onExportComplete={handleExport}
                    />
                    {noDataFound ? (
                        <Grid
                            data={[]}
                            {...dataState}
                            style={gridStyle}
                            onDataStateChange={dataStateChange}
                            ref={_grid}
                            id={id}
                            reorderable={reorderable}
                            onColumnReorder={onColumnReorder}
                            filterOperators={filterOperators}
                        >
                            <GridNoRecords>{errorMsg}</GridNoRecords>
                            {customColumn}
                        </Grid>
                    ) : (
                        <Grid
                            ref={_grid}
                            style={gridStyle}
                            id={id}
                            resizable={isResizable}
                            data={module !== "job-postion-grid" ? data : filterBy(data, filterField)}
                            {...dataState}
                            onDataStateChange={dataStateChange}
                            onFilterChange={module === "job-postion-grid" ? onFilterChange : undefined}
                            className={className}
                            onItemChange={itemChange}
                            editField={editField}
                            dataItemKey={dataItemKey}
                            sortable={isSortable ? { allowUnsort: true, mode: sortMode } : false}
                            scrollable={scrollable}
                            pageable={true}
                            pageSize={10}
                            skip={pageData?.skip}
                            take={pageData?.take}
                            onPageChange={pageChange}
                            total={dataCount}
                            onRowDoubleClick={onRowDoubleClick}
                            onRowClick={onRowClick}
                            pager={CustomPager}
                            sort={sortField}
                            filter={filterField}
                            onSortChange={handleOnSortChange}
                            selectedField={SELECTED_FIELD}
                            cellRender={cellRender}
                            rowRender={rowRender}
                            expandField="expanded"
                            onExpandChange={expandChange}
                            detail={DetailComponent}
                            groupable={groupable}
                            reorderable={reorderable}
                            onGroupChange={onGroupChange}
                            group={group}
                            onSelectionChange={onSelectionChange}
                            filterOperators={filterOperators}
                            onColumnReorder={onColumnReorder}
                            selectable={{ enabled: true, drag: isDragMode, mode: "selectionMode" }}
                            navigatable={true}
                        >
                            {customColumn}
                        </Grid>
                    )}
                </Tooltip>
            ) : ((data.length === 0 && columns.length === 0) || !initialLoading) ? (
                <LoadingPanel gridId={id} />
            ) : (
                <Grid
                    data={[]}
                    {...dataState}
                    onDataStateChange={dataStateChange}
                    onColumnReorder={onColumnReorder}
                    filterOperators={filterOperators}
                    reorderable={reorderable}
                    ref={_grid}
                    id={id}
                    resizable={true}
                    style={gridStyle}
                >
                    <GridNoRecords>{errorMsg}</GridNoRecords>
                    {customColumn}
                </Grid>
            )}
        </>
    );
};

export default React.memo(DataTable);
