import React from "react";
import { CompAllocationData } from "../compAllocation/dataRowsGrid";

const CompAllocationDataRows = (restProps) => {
  //disable in-cell update and cancel button
  return (
    <>
      <div className="dtu-file-container">
        <CompAllocationData
          pageProps={restProps.pageProps}
          compTableMasterKey={restProps.compTableMasterKey}
          selectedTab={restProps.selected}
          isUpdateClick={restProps.isUpdateClick}
          isCancelClick={restProps.isCancelClick}
          setScreenLock={restProps.setScreenLock}
          screenLock={restProps.screenLock}
          view={restProps.view}
          isCreditAllocationEdit={restProps.isCreditAllocationEdit}
          setisCreditAllocationEdit={restProps.setisCreditAllocationEdit}
          CreditrulesColumnsData={restProps.CreditrulesColumnsData}
          setCreditRulesColumnsData={restProps.setCreditRulesColumnsData}
          isCreditRuleCheckColumn={restProps.isCreditRuleCheckColumn}
          setCreditRuleColumnCheck={restProps.setCreditRuleColumnCheck}
          cancelKpiElementAwaitStatus={restProps.cancelKpiElementAwaitStatus}
          setCancelKpiElementAwaitStatus={
            restProps.setCancelKpiElementAwaitStatus
          }
          setisUpdateClick={restProps.setisUpdateClick}
          isRefreshBtnClick={restProps.isRefreshBtnClick}
          setRefreshBtnClick={restProps.setRefreshBtnClick}
          setCancelKpiMensurmentsElementAwaitStatus={
            restProps.setCancelKpiMensurmentsElementAwaitStatus
          }
          setCancelIncentivesAwaitStatus={
            restProps.setCancelIncentivesAwaitStatus
          }
          setCancelKpiPaymentsAwaitStatus={
            restProps.setCancelKpiPaymentsAwaitStatus
          }
          selectedRowId={restProps.selectedRowId}
        />
      </div>
    </>
  );
};

export default React.memo(CompAllocationDataRows);
