import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import { IntlService } from "@progress/kendo-react-intl";
import React, { forwardRef, useImperativeHandle } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { providerPlanStatementSelector } from "src/redux/selectors/custom-selector";

const ExcelExportProviderStatement = forwardRef((props, ref) => {
  const _export = React.useRef(null);
  const intl = new IntlService("en");
  const { newData, group } = props;

  const { providerSection2Data } = useSelector(
    providerPlanStatementSelector,
    shallowEqual
  );
  const parseVal = (fieldVal, format) => {
    const numberVal = intl.parseNumber(fieldVal || 0);
    if (!format) {
      return !fieldVal ? "" : Boolean(numberVal) ? "True" : "False";
    }
    return intl.formatNumber(numberVal, format);
  };

  const  getTotalValue = (field) => {
    let format = "$#,##0.00;-$#,##0.00";
    const item = providerSection2Data.data.filter(
      (it) => it.result_metadata_tags === "<grand_total>"
    );
    if (item.length) {
      format = item[0].report_format;
    }

    const total = item.reduce((it, it1) => it + it1[`${field}_total`], 0);
    const numberVal = intl.parseNumber(total || 0);
    return intl.formatNumber(numberVal, format);
  };
  useImperativeHandle(
    ref,
    () => {
      return {
        excelExport: excelExport,
      };
    },
      // eslint-disable-next-line react-hooks/exhaustive-deps
    [newData, group, providerSection2Data]
  );

  const saveExcel = (component) => {
    let format = "$#,##0.00;-$#,##0.00";

    const options = component.current.workbookOptions();
    const rows = options.sheets[0].rows;

    const finalRows = [];
    rows.forEach((it) => {
      if (it.type === "group-header") {
        const cell = it.cells[it.cells.length - 1];
        if (cell.value) {
          cell.value = cell.value.replace(/position_name:|kpi_name:|result_group_name:|result_type_name:/g, '');
          if(cell.value.trim() !== "null") {
            finalRows.push(it);
          }
        } else {
          finalRows.push(it);
        }
      } else if (it.type === "data") {
        const cell = it.cells.find((it) => it.value !== undefined);
        if (cell) {
          const cellIt = providerSection2Data.data.find(
            (it) => it.result_name === cell.value
          );
          it.cells.forEach((item) => {
            if (cellIt.unit_type_name !== "Boolean") {
              item.format = cellIt?.report_format || format;
            }
          });
        }
        finalRows.push(it);
      } else {
        finalRows.push(it);
      }
    });

    finalRows.forEach((row, rowIdx) => {
      if (row.type === "data") {
        row.cells.forEach((cell) => {
          if (!isNaN(Number(cell.value))) {
            cell.value = parseVal(cell.value, cell.format);
          }
        });
      } else if (row.type === "group-header") {
        row.cells.forEach((cell, index) => {
          cell.background = row.cells[row.cells.length - 1].background;
        });
      } else if (row.type === "footer") {
        row.cells.forEach((cell) => {
          cell.background = "#7AC143";
        });
      }
    });
    options.sheets[0].rows = finalRows;
    component.current.save(options);
  };

  const excelExport = () => {
    if (_export.current !== null) {
      saveExcel(_export);
    }
  };

  return (
    <ExcelExport
      data={newData}
      ref={_export}
      group={group}
      fileName="Provider_Comp_Statement.xlsx"
    >
      <ExcelExportColumn
        field="position_name"
        title=""
        groupHeaderCellOptions={{
          background: "#009DDC",
          bold: true,
        }}
        locked
        hidden={true}
      />
      <ExcelExportColumn
        field="kpi_name"
        title=""
        locked
        groupHeaderCellOptions={{
          background: "#7AC143",
          bold: true,
        }}
        hidden={true}
      />
      <ExcelExportColumn
        field="result_group_name"
        title=""
        locked
        groupHeaderCellOptions={{
          background: "#EAE8E8",
          bold: true,
        }}
        hidden={true}
      />
      <ExcelExportColumn
        field="result_type_name"
        title=""
        locked
        groupHeaderCellOptions={{
          background: "#FDFD96",
          bold: true,
        }}
        hidden={true}
      />

      <ExcelExportColumn
        field="result_name"
        title="Result Name"
        locked
        width={250}
        headerCellOptions={{
          textAlign: "center",
          bold: true,
        }}
        footerCellOptions={{
          textAlign: "center",
          bold: true,
        }}
        footer={(props) => <>Total Payments</>}
      />
      {providerSection2Data.columns
        .filter((it) => it.show)
        .map((item) => (
          <ExcelExportColumn
            key={item.per_number}
            field={`period${item.per_number}_value`}
            cellOptions={{ textAlign: "center" }}
            title={item.period_name}
            headerCellOptions={{
              textAlign: "center",
              bold: true,
            }}
            footer={(props) => {
              return getTotalValue(`period${item.per_number}_value`);
            }}
            footerCellOptions={{
              textAlign: "center",
              bold: true,
            }}
          />
        ))}
    </ExcelExport>
  );
});

export default ExcelExportProviderStatement;
