/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Window, WindowActionsBar } from "@progress/kendo-react-dialogs";
import { Upload } from "@progress/kendo-react-upload";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setNewMessage } from "../../redux/actions/setting-actions";
import { getUserCredential } from "../../lib/cookies";
import MessageSchema from "src/utils/messageSchema";
import useAPI from "./service"
import { LoadingPanel } from "src/components/loadingPanel";
import { Button } from "@progress/kendo-react-buttons";
import { useHistory } from "react-router-dom";
import { deleteDocument, insertDocument } from "src/lib/common-service";
import { DialogComponent } from "src/components/dialog";
import { mainSelector, providerSelector } from "src/redux/selectors/custom-selector";
import projectSetting from "src/setting";
import { bytesToKB, downloadURI } from "src/utils/utils";
import { setProviderAction } from "src/redux/actions/provider-action";
import { ALLOW_PROVIDER_DOC_EXTENSION } from "src/constants/constants";

// let filesObj = [];
const uploadRef = React.createRef();

const UploadFile = (restProps) => {
    const { writeAccessDeniend } = useSelector(
        mainSelector,
        shallowEqual
    );

    let writeAccess = typeof writeAccessDeniend?.find((obj) => {
        return obj === "prv_pos";
    }) === "undefined"
        ? 1
        : 0
    const dispatch = useDispatch();
    const cookiesData = getUserCredential();
    const history = useHistory()
    /** State Variables  */
    const [files, setFiles] = React.useState([]);
    const [loadingState, setLoadingState] = React.useState({
        isDownloading: false,
        isUploading: false
    })
    const [confirmationPopup, setConfirmationPopup] = React.useState({
        title: "Delete",
        desc: "Do you want to unlink the document?",
        isVisible: false,
        type: "",
        actionBtn: [],
        data: {},
        action: "Delete",
    });
    /** Use API Hooks */
    const [list, Action] = useAPI()
    const [openUploadPopup, setOpenUploadPopup] = React.useState(false)
    const {
        preConfigData,
    } = list

    const providerSelectorData = useSelector(
        providerSelector,
        shallowEqual
    ).providerData;

    React.useEffect(() => {
        if (preConfigData === "") {
            Action.systemSettingDataAsync();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [preConfigData]);

    /** Upload file */
    const onUpload = async () => {
        setLoadingState({
            ...loadingState,
            isUploading: true
        })
        const dataReq = new FormData();
        files.map(async (item) => {
            let filesObj = item.getRawFile()
            dataReq.append("file", filesObj);
            const payloadData = {
                uploadPath: `${preConfigData.doc_dir}/position/`,
                userId: parseInt(cookiesData.out_user_id),
                tenantId: cookiesData.out_tenant_id,
                inDocumentName: item.name,
                inDocumentType: item.extension,
                inDocumentSize: item.size,
                inDocumentSrcDbName: 'procare_system',
                inDocumentSrcTableName: 'vw_org_position',
                inDocumentSrcMstrKey: providerSelectorData.mstr_key,
                inDocumentSrcDateVersion: providerSelectorData.date_version
            };
            let res = await insertDocument(payloadData, dataReq, dispatch, history)
            if (res) {
                setLoadingState({
                    ...loadingState,
                    isUploading: false
                })
                onClosePopup();
                let obj = {
                    mstr_key: providerSelectorData.mstr_key,
                    date_version: providerSelectorData.date_version
                }
                Action.getDocumentData(obj)
            } else {
                setLoadingState({
                    ...loadingState,
                    isUploading: false
                })
            }
        })

    };

    /** Close window Popup */
    const onClosePopup = () => {
        setOpenUploadPopup(false);
        setFiles([])
    };


    /** Method To Select File */
    const onFileChange = (e) => {
        if (e.newState.length + providerSelectorData?.document?.length <= Number(preConfigData.allow_max_no_of_doc)) {
            /** File Size In MB */
            if (e.affectedFiles[0].size / (1024 * 1024).toFixed(2) > parseInt(preConfigData.allow_max_doc_size)) {
                dispatch(
                    setNewMessage({
                        message: MessageSchema.validFile,
                        type: "Error",
                        show: true,
                    })
                );
            } else {
                if (ALLOW_PROVIDER_DOC_EXTENSION.includes(e.affectedFiles[0].extension)) {
                    setFiles(e.newState);
                } else {
                    dispatch(
                        setNewMessage({
                            message: MessageSchema.validFileType,
                            type: "Error",
                            show: true,
                        })
                    );
                }
            }
        } else {
            dispatch(
                setNewMessage({
                    message: `You can upload up to ${Number(preConfigData.allow_max_no_of_doc)} files for this record`,
                    type: "Error",
                    show: true,
                })
            );

        }
    };

    /** Method To Remove File */
    const onRemove = (event) => {
        setFiles(event.newState);
    };


    /** Show Modal Title */
    const ModalTitle = () => {
        return (
            <span>
                {"Upload Document"}
            </span>
        );
    };

    const removeFile = (i) => {
        let fileData = [...files];
        fileData.splice(i, 1);
        setFiles([...fileData]);
    }

    const CustomListItemUI = (props) => {
        return (
            <ul>
                {props.files.map((file, i) => (
                    <>
                        <li key={file.name}><i className="fas fa-file"></i>{file.name}</li>
                        <span onClick={() => removeFile(i)}><i className="fa fa-times"></i></span>
                    </>
                ))}
            </ul>
        );
    };

    const onAttachedFile = () => {
        if (writeAccess === 0) {
            return false
        }
        setOpenUploadPopup(true)
    }


    const onRemoveFile = async (item) => {
        if (writeAccess === 0) {
            return false
        } else {
            setConfirmationPopup({
                ...confirmationPopup,
                isVisible: true,
                type: "DELETE_CONFIRM",
                actionBtn: [
                    {
                        name: "No",
                        onClick: onCancelConfirm,
                    },
                    {
                        name: "Yes",
                        onClick: () => {
                            onDeleteConfirm(item);
                        },
                    },
                ],
                action: "Delete",
            });
        }
    }

    const onDeleteConfirm = async (item) => {
        let reqData = {
            in_user_id: cookiesData.out_user_id.toString(),
            in_tenant_id: cookiesData.out_tenant_id,
            in_document_mstr_key: item.document_mstr_key
        }
        setConfirmationPopup({
            ...confirmationPopup,
            isVisible: false,
            type: "DELETE_CONFIRM",
            actionBtn: [],
            action: "Delete",
        });
        let res = await deleteDocument(reqData, dispatch, history)
        if (res) {
            let resObj = providerSelectorData
            resObj.document = resObj.document.filter((x) => x.document_mstr_key !== item.document_mstr_key)
            let obj = {
                ...resObj
            }
            dispatch(
                setProviderAction(obj)
            );
        }
    }

    const onCancelConfirm = () => {
        setConfirmationPopup({
            ...confirmationPopup,
            isVisible: false,
            type: "",
            actionBtn: [],
            data: {},
            action: "",
        });
    }

    const onDownloadClick = (item) => {
        // if (writeAccess === 0) {
            // return false
        // } else {
            let url = `${projectSetting.baseURL}download-document/${cookiesData.out_tenant_id}/position/${item.document_name}`
            //Static code
            downloadURI(item.original_name, url);
        // }

    }

    const renderFileTypeElement = (fileType) => {
        let element = ''
        switch (fileType) {
            case '.xls':
                element = <i className="fa fa-file-excel"></i>
                break;
            case '.word':
                element = <i className="fa fa-file-word"></i>
                break;
            case '.png':
                element = <i className="fa fa-file-image"></i>
                break;
            case '.pdf':
                element = <i className="fa fa-file-pdf"></i>
                break;
            default:
                element = <i className="fa fa-file-pdf"></i>
                break
        }
        return element
    }


    return (
        <>
            <Button onClick={onAttachedFile} className={writeAccess === 0 ? "k-button attachedfiledrag disable-class" : "k-button attachedfiledrag"}>
                Attach New Document(s)
            </Button>
           
            {providerSelectorData && providerSelectorData?.document?.map((item) => {
                return (
                    <>
                        <div className="attached-file manageattachfile">
                            {renderFileTypeElement(item.document_type)}
                            <a onClick={() => onDownloadClick(item)}>{item.original_name}
                                <div className="filesize">{bytesToKB(item.document_size)}</div></a>
                            <i title={'Delete document'} className="fa fa-minus" onClick={() => { onRemoveFile(item) }}></i>
                        </div>
                    </>
                )
            })}

            {openUploadPopup &&
                <Window
                    title={<ModalTitle />}
                    onClose={onClosePopup}
                    width={900}
                    height={files.length > 0 ? 320 : 270}
                    resizable={false}
                >
                    {(loadingState.isDownloading || loadingState.isUploading) && <LoadingPanel />}
                    <div className="upload-config">
                        <div className="intro-upload-config">
                            <div className="intro-drag-text">
                                {`You can upload up to ${parseInt(preConfigData.allow_max_no_of_doc)} documents or total of ${parseInt(preConfigData.allow_max_doc_size)}MB of file data,whichever limit is reached first`}
                            </div>

                        </div>

                        <div className="box">
                            <div className="drag-container">
                            </div>
                            <div className="dtudrop-box">
                                <Upload
                                    ref={uploadRef}
                                    batch={true}
                                    multiple={true}
                                    autoUpload={false}
                                    withCredentials={false}
                                    className={"file-upload proposiupload"}
                                    onAdd={onFileChange}
                                    files={files}
                                    listItemUI={CustomListItemUI}
                                    onRemove={onRemove}
                                    restrictions={{
                                        maxFileSize: 4000000,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <WindowActionsBar layout={'end'}>
                        <>
                            <Button
                                className="cancelbtn"
                                onClick={(e) => onClosePopup()}
                                autoFocus={true}
                            >
                                {'Cancel'}
                            </Button>

                            <Button
                                className="primarybtn"
                                onClick={(e) => onUpload(e)}
                                autoFocus={true}
                                disabled={files.length > 0 ? false : true}
                            >
                                {'Upload'}
                            </Button>
                        </>
                    </WindowActionsBar>
                </Window>
            }
            <DialogComponent
                title={confirmationPopup.title}
                onCancelConfirm={onCancelConfirm}
                width={"450"}
                height={"auto"}
                disabled={false}
                className={"Dialog-Delete"}
                desc={confirmationPopup.desc}
                visible={confirmationPopup.isVisible}
                actionBtn={confirmationPopup.actionBtn}
                titleClassName={
                    confirmationPopup.action === "Delete"
                        ? "delete-confirm"
                        : "unSave-confirm"
                }
            />
        </>
    );
};
export default React.memo(UploadFile);
