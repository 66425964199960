import * as React from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import "../scss/customModal.scss";

export const DialogComponent = (props) => {
    let {
        visible,
        onCancelConfirm,
        title,
        desc,
        primBtnText,
        isShowOneButton = false,
        width = 500,
        height = 220,
        onPrimButton,
        actionBtn,
        className = "",
        titleClassName = "",
        isCustomDesc,
        customComponent,
    } = props;
    const onNoBtnClick = () => {
        onCancelConfirm();
        visible = false;
    };

    const ModalTitle = () => {
        return <span className={titleClassName}>{title}</span>;
    };

    return (
        <div>
            {visible && (
                <Dialog
                    className={className}
                    title={<ModalTitle />}
                    onClose={onNoBtnClick}
                    width={width}
                    height={height}
                >
                    {isCustomDesc ? customComponent : false}
                    {desc}
                    {isShowOneButton ? (
                        <DialogActionsBar layout={"end"}>
                            <button
                                className="k-button"
                                onClick={onPrimButton}
                                autoFocus={true}
                            >
                                {primBtnText}
                            </button>
                        </DialogActionsBar>
                    ) : (
                        <DialogActionsBar layout={"end"}> 
                            {actionBtn &&
                                actionBtn.map((eachBtn, BtnIdx) => {
                                    return (
                                        <button
                                        id={`dialog-btn_${BtnIdx}`}
                                            className="k-button"
                                            onClick={(e) => {
                                                eachBtn.onClick(e);
                                            }}
                                            disabled={eachBtn.disabled}
                                            autoFocus={true}
                                            key={BtnIdx}
                                        >
                                            {eachBtn.name}
                                        </button>
                                    );
                                })}
                        </DialogActionsBar>
                    )}
                </Dialog>
            )}
        </div>
    );
};
