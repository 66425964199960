import {
    DashboardTabApi,
    DashboardPodApi,
    DashboardCompPlanApi,
    DashboardChartRuleApi,
    DashboardChartDataSetApi,
    DashboardTypeListApi as DashboardTypeDataSetListApi,
    DashboardPeriodTypeListApi as DashboardPeriodTypeDataSetListApi,
    DashboardCompPlanListApi as DashboardCompPlanDataSetListApi,
    DashboardKPIFilterListApi as DashboardKPIFilterDataSetListApi,
    DashboardResultFilterListApi as DashboardResultFilterDataSetListApi,
    DashboardPODInsertListApi as DashboardPODInsertList,
    DashboardPODDeleteApi as DashboardPODDelete,
    DashboardPODUpdateListApi as DashboardPODUpdateList,
    DashboardPODDatasetInsertListApi as DashboardPODDatasetInsertList,
    DashboardPODDatasetUpdateListApi as DashboardPODDatasetUpdateList,
    DashboardPODDatasetDeleteApi as DashboardPODDatasetDelete,
    DashboardTabDeleteApi as DashboardTabDelete,
    DashboardRoleAccessApi as DashboardRoleAccess,
    DashboardBusinessGroupApi as DashboardBusinessGroup,
    DashboardGroupApi as DashboardGroup,
    DashboardSpecialtyGroupApi as DashboardSpecialtyGroup,
    DashboardProviderApi as DashboardProvider,
    DashboardInsertTabApi as DashboardInsertTab,
    DashboardUpdateTabApi as DashboardUpdateTab,
    DashboardUpdatePodOrderApi as DashboardUpdatePodOrder,
    DashboardCopyTabApi as DashboardCopyTab,
    PublishDashboardApi as PublishDashboard,
} from "src/service/service.config";
import ProjectSetting from "src/setting";
import { axiosGet, axiosPost } from "src/middleware/axios-api-wrapper";
import {
    DashboardTabListApiRequest,
    DashboardPODApiResponse,
    CommonListApiResponse,
    DashboardPodListApiRequest,
    DashboardCompPlanConfigApiRequest,
    DashboardChartRuleApiRequest,
    DashboardChartDataSetApiRequest,
    DashboardTypeApiRequest,
    DashboardPeriodTypeApiRequest,
    DashboardCompPlanApiRequest,
    DashboardKPIFilterApiRequest,
    DashboardResultFilterApiRequest,
    DashboardPODInsertApiRequest,
    DashboardPODUpdateApiRequest,
    DashboardPODInsertDatasetApiRequest,
    DashboardPODUpdateDatasetApiRequest,
    DashboardPODDeleteDatasetApiRequest,
    DashboardPODDeleteApiRequest,
    DashboardTabDeleteApiRequest,
    DashboardBusinessGroupApiRequest,
    DashboardGroupApiRequest,
    DashboardSpecialtyApiRequest,
    DashboardProviderApiRequest,
    DashboardInsertTabApiRequest,
    DashboardUpdateTabApiRequest,
    DashboardUpdatePodOrderApiRequest,
    DashboardTabCopyApiRequest,
    PublishDashboardApiRequest,
} from "./dao/dashboard.dao";

const baseUrl = `${ProjectSetting.baseURL}${ProjectSetting.backend}${ProjectSetting.version}`;

export const DashboardTabListApi = async (request = null) => {
    const requestParams = new DashboardTabListApiRequest(request);
    return axiosPost(
        baseUrl,
        DashboardTabApi.endpointUrl(),
        requestParams
    ).then((response) => new CommonListApiResponse(response)?.response || []);
};

export const DashboardPodListApi = async (request = null) => {
    const requestParams = new DashboardPodListApiRequest(request);
    return axiosPost(
        baseUrl,
        DashboardPodApi.endpointUrl(),
        requestParams
    ).then((response) => new CommonListApiResponse(response)?.response || []);
};

export const DashboardCompPlanConfigApi = async (request = null) => {
    const requestParams = new DashboardCompPlanConfigApiRequest(request);
    return axiosPost(
        baseUrl,
        DashboardCompPlanApi.endpointUrl(),
        requestParams
    ).then((response) => new CommonListApiResponse(response)?.response || []);
};

export const DashboardChartRuleListApi = async (request = null) => {
    const requestParams = new DashboardChartRuleApiRequest(request);
    return axiosPost(
        baseUrl,
        DashboardChartRuleApi.endpointUrl(),
        requestParams
    ).then((response) => new CommonListApiResponse(response)?.response || []);
};

export const DashboardChartDataSetListApi = async (request = null) => {
    const requestParams = new DashboardChartDataSetApiRequest(request);
    return axiosPost(
        baseUrl,
        DashboardChartDataSetApi.endpointUrl(),
        requestParams
    ).then((response) => new CommonListApiResponse(response)?.response || []);
};

export const DashboardTypeListApi = async (request = null) => {
    const requestParams = new DashboardTypeApiRequest(request);
    return axiosPost(
        baseUrl,
        DashboardTypeDataSetListApi.endpointUrl(),
        requestParams
    ).then((response) => new CommonListApiResponse(response)?.response || []);
};

export const DashboardPeriodTypeListApi = async (request = null) => {
    const requestParams = new DashboardPeriodTypeApiRequest(request);
    return axiosPost(
        baseUrl,
        DashboardPeriodTypeDataSetListApi.endpointUrl(),
        requestParams
    ).then((response) => new CommonListApiResponse(response)?.response || []);
};

export const DashboardCompPlanListApi = async (request = null) => {
    const requestParams = new DashboardCompPlanApiRequest(request);
    return axiosPost(
        baseUrl,
        DashboardCompPlanDataSetListApi.endpointUrl(),
        requestParams
    ).then((response) => new CommonListApiResponse(response)?.response || []);
};

export const DashboardKPIFilterListApi = async (request = null) => {
    const requestParams = new DashboardKPIFilterApiRequest(request);
    return axiosPost(
        baseUrl,
        DashboardKPIFilterDataSetListApi.endpointUrl(),
        requestParams
    ).then((response) => new CommonListApiResponse(response)?.response || []);
};

export const DashboardResultFilterListApi = async (request = null) => {
    const requestParams = new DashboardResultFilterApiRequest(request);
    return axiosPost(
        baseUrl,
        DashboardResultFilterDataSetListApi.endpointUrl(),
        requestParams
    ).then((response) => new CommonListApiResponse(response)?.response || []);
};

export const DashboardPODInsertListApi = async (request = null) => {
    const requestParams = new DashboardPODInsertApiRequest(request);
    return axiosPost(
        baseUrl,
        DashboardPODInsertList.endpointUrl(),
        requestParams
    ).then((response) => new DashboardPODApiResponse(response)?.response || []);
};

export const DashboardPODDataSetInsertListApi = async (request = null) => {
    const requestParams = new DashboardPODInsertDatasetApiRequest(request);
    return axiosPost(
        baseUrl,
        DashboardPODDatasetInsertList.endpointUrl(),
        requestParams
    ).then((response) => new CommonListApiResponse(response)?.response || []);
};

export const DashboardPODUpdateListApi = async (request = null) => {
    const requestParams = new DashboardPODUpdateApiRequest(request);
    return axiosPost(
        baseUrl,
        DashboardPODUpdateList.endpointUrl(),
        requestParams
    ).then((response) => {
        if (response?.data?.out_parameters?.out_ret_code === 0) {
            return true;
        } else {
            throw response?.data?.out_parameters?.out_error_msg || "";
        }
    });
};

export const DashboardPODDataSetUpdateListApi = async (request = null) => {
    const requestParams = new DashboardPODUpdateDatasetApiRequest(request);
    return axiosPost(
        baseUrl,
        DashboardPODDatasetUpdateList.endpointUrl(),
        requestParams
    ).then((response) => new CommonListApiResponse(response)?.response || []);
};

export const DashboardPODDatasetDeleteApi = async (request = null) => {
    const requestParams = new DashboardPODDeleteDatasetApiRequest(request);
    return axiosPost(
        baseUrl,
        DashboardPODDatasetDelete.endpointUrl(),
        requestParams
    ).then((response) => new CommonListApiResponse(response)?.response || []);
};

export const DashboardPODUpdate = async (request = null) => {
    if (request) {
        console.log(request);
    }
};

export const DashboardPODDeleteApi = async (request = null) => {
    const requestParams = new DashboardPODDeleteApiRequest(request);
    return axiosPost(
        baseUrl,
        DashboardPODDelete.endpointUrl(),
        requestParams
    ).then((response) => new CommonListApiResponse(response)?.response || []);
};

export const DashboardTabDeleteApi = async (request = null) => {
    const requestParams = new DashboardTabDeleteApiRequest(request);
    return axiosPost(
        baseUrl,
        DashboardTabDelete.endpointUrl(),
        requestParams
    ).then((response) => new CommonListApiResponse(response)?.response || []);
};

export const DashboardRoleAccessListApi = async () => {
    return axiosGet(baseUrl, DashboardRoleAccess.endpointUrl()).then(
        (response) => new CommonListApiResponse(response)?.response || []
    );
};

export const DashboardBusinessGroupListApi = async (request = null) => {
    const requestParams = new DashboardBusinessGroupApiRequest(request);
    return axiosPost(
        baseUrl,
        DashboardBusinessGroup.endpointUrl(),
        requestParams
    ).then((response) => new CommonListApiResponse(response)?.response || []);
};

export const DashboardGroupListApi = async (request = null) => {
    const requestParams = new DashboardGroupApiRequest(request);
    return axiosPost(baseUrl, DashboardGroup.endpointUrl(), requestParams).then(
        (response) => new CommonListApiResponse(response)?.response || []
    );
};

export const DashboardSpecialityListApi = async (request = null) => {
    const requestParams = new DashboardSpecialtyApiRequest(request);
    return axiosPost(
        baseUrl,
        DashboardSpecialtyGroup.endpointUrl(),
        requestParams
    ).then((response) => new CommonListApiResponse(response)?.response || []);
};

export const DashboardProviderListApi = async (request = null) => {
    const requestParams = new DashboardProviderApiRequest(request);
    return axiosPost(
        baseUrl,
        DashboardProvider.endpointUrl(),
        requestParams
    ).then((response) => new CommonListApiResponse(response)?.response || []);
};

export const DashboardInsertTabApi = async (request = null) => {
    const requestParams = new DashboardInsertTabApiRequest(request);
    return axiosPost(
        baseUrl,
        DashboardInsertTab.endpointUrl(),
        requestParams
    ).then((response) => {
        if (response?.data?.out_parameters?.out_ret_code === 0) {
            return response?.data?.out_parameters?.out_last_insert_key;
        } else {
            throw response?.data?.out_parameters?.out_error_msg || undefined;
        }
    });
};

export const DashboardUpdateTabApi = async (request = null) => {
    const requestParams = new DashboardUpdateTabApiRequest(request);
    return axiosPost(
        baseUrl,
        DashboardUpdateTab.endpointUrl(),
        requestParams
    ).then((response) => {
        if (response?.data?.out_parameters?.out_ret_code === 0) {
            return new CommonListApiResponse(response)?.response || [];
        } else {
            throw response?.data?.out_parameters?.out_error_msg || undefined;
        }
    });
};

export const DashboardUpdatePodOrderApi = async (request = null) => {
    const requestParams = new DashboardUpdatePodOrderApiRequest(request);
    return axiosPost(
        baseUrl,
        DashboardUpdatePodOrder.endpointUrl(),
        requestParams
    ).then((response) => new CommonListApiResponse(response)?.response || []);
};

export const DashboardTabCopyApi = async (request = null) => {
    const requestParams = new DashboardTabCopyApiRequest(request);
    return axiosPost(
        baseUrl,
        DashboardCopyTab.endpointUrl(),
        requestParams
    ).then((response) => {
        if (response?.data?.out_parameters?.out_ret_code === 0) {
            return response?.data?.out_parameters?.out_last_insert_tab_key;
        } else {
            throw response?.data?.out_parameters?.out_error_msg || undefined;
        }
    });
};

export const PublishDashboardApi = async (request = null) => {
    const requestParams = new PublishDashboardApiRequest(request);
    return axiosPost(
        baseUrl,
        PublishDashboard.endpointUrl(),
        requestParams
    ).then((response) => {
        if (response?.data?.out_parameters?.out_ret_code === 0) {
            return response?.data?.out_parameters?.out_last_insert_tab_key;
        } else {
            throw response?.data?.out_parameters?.out_error_msg || undefined;
        }
    });
};
