import moment from "moment";

export const transformDateTime = ({
    date,
    format = "YYYY-MM-DD",
    delimiter = "",
    includeTime = false,
    defaultTime = false,
    returnDate = false,
}) => {
    if (!date) {
        return "";
    }

    let momentDate = moment.utc(date.toLocaleString());
    let formattedDate;

    formattedDate = momentDate.format(format || "YYYY-MM-DD");

    if (delimiter !== "") {
        formattedDate = formattedDate.replaceAll("-", delimiter);
    }

    if (includeTime) {
        const time = defaultTime ? "00:00:00" : momentDate.format("HH:mm:ss");

        formattedDate += returnDate ? "T" + time : " " + time;
    }

    if (returnDate) {
        formattedDate = new Date(formattedDate);
    }

    return formattedDate;
};
