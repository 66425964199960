import { LoadingMessage } from "src/constants/api/loading-message.const";
import {
    SuccessMessage,
    ResponseStatus,
} from "src/constants/api/success-message.const";
import { DataOnLoadErrorMessage } from "src/constants/api/error-messages.const";

export const DashboardTabApi = {
    uniqueKey: "DashboardTabApiQuery",
    endpointUrl: () => "dashboard/select-dashboard-tab",
    statusMessage: {
        pending: {
            loadingMsg: LoadingMessage.apiLoading,
        },
        fulfilled: {
            successMsg: SuccessMessage[ResponseStatus.validStatus],
            errorMessage: DataOnLoadErrorMessage.emptyData,
        },
        rejected: {},
    },
};
export const DashboardPodApi = {
    uniqueKey: "DashboardPodApiQuery",
    endpointUrl: () => "dashboard/select-dashboard-pod",
    statusMessage: {
        pending: {
            loadingMsg: LoadingMessage.apiLoading,
        },
        fulfilled: {
            successMsg: SuccessMessage[ResponseStatus.validStatus],
            errorMessage: DataOnLoadErrorMessage.emptyData,
        },
        rejected: {},
    },
};
export const DashboardCompPlanApi = {
    uniqueKey: "DashboardCompPlanApiQuery",
    endpointUrl: () => "dashboard/select-dashboard-comp-plan",
    statusMessage: {
        pending: {
            loadingMsg: LoadingMessage.apiLoading,
        },
        fulfilled: {
            successMsg: SuccessMessage[ResponseStatus.validStatus],
            errorMessage: DataOnLoadErrorMessage.emptyData,
        },
        rejected: {},
    },
};
export const DashboardChartRuleApi = {
    uniqueKey: "DashboardChartRuleApiQuery",
    endpointUrl: () => "dashboard/select-dashboard-chart-rule",
    statusMessage: {
        pending: {
            loadingMsg: LoadingMessage.apiLoading,
        },
        fulfilled: {
            successMsg: SuccessMessage[ResponseStatus.validStatus],
            errorMessage: DataOnLoadErrorMessage.emptyData,
        },
        rejected: {},
    },
};

export const DashboardChartDataSetApi = {
    uniqueKey: "DashboardChartDataSetApiQuery",
    endpointUrl: () => "dashboard/select-dashboard-dataset",
    statusMessage: {
        pending: {
            loadingMsg: LoadingMessage.apiLoading,
        },
        fulfilled: {
            successMsg: SuccessMessage[ResponseStatus.validStatus],
            errorMessage: DataOnLoadErrorMessage.emptyData,
        },
        rejected: {},
    },
};

export const DashboardTypeListApi = {
    uniqueKey: "DashboardTypeListApiQuery",
    endpointUrl: () => "dashboard/select-dashboard-type",
    statusMessage: {
        pending: {
            loadingMsg: LoadingMessage.apiLoading,
        },
        fulfilled: {
            successMsg: SuccessMessage[ResponseStatus.validStatus],
            errorMessage: DataOnLoadErrorMessage.emptyData,
        },
        rejected: {},
    },
};

export const DashboardPeriodTypeListApi = {
    uniqueKey: "DashboardPeriodTypeListApiQuery",
    endpointUrl: () => "dashboard/select-dashboard-period-type",
    statusMessage: {
        pending: {
            loadingMsg: LoadingMessage.apiLoading,
        },
        fulfilled: {
            successMsg: SuccessMessage[ResponseStatus.validStatus],
            errorMessage: DataOnLoadErrorMessage.emptyData,
        },
        rejected: {},
    },
};

export const DashboardCompPlanListApi = {
    uniqueKey: "DashboardCompPlanListApiQuery",
    endpointUrl: () => "dashboard/select-dashboard-comp-plan",
    statusMessage: {
        pending: {
            loadingMsg: LoadingMessage.apiLoading,
        },
        fulfilled: {
            successMsg: SuccessMessage[ResponseStatus.validStatus],
            errorMessage: DataOnLoadErrorMessage.emptyData,
        },
        rejected: {},
    },
};

export const DashboardKPIFilterListApi = {
    uniqueKey: "DashboardKPIFilterListApiQuery",
    endpointUrl: () => "comp-config/select-plan-kpi-assign",
    statusMessage: {
        pending: {
            loadingMsg: LoadingMessage.apiLoading,
        },
        fulfilled: {
            successMsg: SuccessMessage[ResponseStatus.validStatus],
            errorMessage: DataOnLoadErrorMessage.emptyData,
        },
        rejected: {},
    },
};

export const DashboardResultFilterListApi = {
    uniqueKey: "DashboardResultFilterListApi",
    endpointUrl: () => "results/select-result-name",
    statusMessage: {
        pending: {
            loadingMsg: LoadingMessage.apiLoading,
        },
        fulfilled: {
            successMsg: SuccessMessage[ResponseStatus.validStatus],
            errorMessage: DataOnLoadErrorMessage.emptyData,
        },
        rejected: {},
    },
};

export const DashboardPODUpdateListApi = {
    uniqueKey: "DashboardPODUpdateListApi",
    endpointUrl: () => "dashboard/update-dashboard-pod",
    statusMessage: {
        pending: {
            loadingMsg: LoadingMessage.apiLoading,
        },
        fulfilled: {
            successMsg: SuccessMessage[ResponseStatus.validStatus],
            errorMessage: DataOnLoadErrorMessage.emptyData,
        },
        rejected: {},
    },
};

export const DashboardPODDatasetUpdateListApi = {
    uniqueKey: "DashboardPODDatasetUpdateListApi",
    endpointUrl: () => "dashboard/update-dashboard-dataset",
    statusMessage: {
        pending: {
            loadingMsg: LoadingMessage.apiLoading,
        },
        fulfilled: {
            successMsg: SuccessMessage[ResponseStatus.validStatus],
            errorMessage: DataOnLoadErrorMessage.emptyData,
        },
        rejected: {},
    },
};

export const DashboardPODDatasetDeleteApi = {
    uniqueKey: "DashboardPODDatasetDeleteApi",
    endpointUrl: () => "dashboard/delete-dashboard-dataset",
    statusMessage: {
        pending: {
            loadingMsg: LoadingMessage.apiLoading,
        },
        fulfilled: {
            successMsg: SuccessMessage[ResponseStatus.validStatus],
            errorMessage: DataOnLoadErrorMessage.emptyData,
        },
        rejected: {},
    },
};

export const DashboardPODInsertListApi = {
    uniqueKey: "DashboardPODInsertListApi",
    endpointUrl: () => "dashboard/insert-dashboard-pod",
    statusMessage: {
        pending: {
            loadingMsg: LoadingMessage.apiLoading,
        },
        fulfilled: {
            successMsg: SuccessMessage[ResponseStatus.validStatus],
            errorMessage: DataOnLoadErrorMessage.emptyData,
        },
        rejected: {},
    },
};

export const DashboardPODDatasetInsertListApi = {
    uniqueKey: "DashboardPODDatasetInsertListApi",
    endpointUrl: () => "dashboard/insert-dashboard-dataset",
    statusMessage: {
        pending: {
            loadingMsg: LoadingMessage.apiLoading,
        },
        fulfilled: {
            successMsg: SuccessMessage[ResponseStatus.validStatus],
            errorMessage: DataOnLoadErrorMessage.emptyData,
        },
        rejected: {},
    },
};

export const DashboardPODDeleteApi = {
    uniqueKey: "DashboardPODDeleteApi",
    endpointUrl: () => "dashboard/delete-dashboard-pod",
    statusMessage: {
        pending: {
            loadingMsg: LoadingMessage.apiLoading,
        },
        fulfilled: {
            successMsg: SuccessMessage[ResponseStatus.validStatus],
            errorMessage: DataOnLoadErrorMessage.emptyData,
        },
        rejected: {},
    },
};

export const DashboardTabDeleteApi = {
    uniqueKey: "DashboardTabDeleteApi",
    endpointUrl: () => "dashboard/delete-dashboard-tab",
    statusMessage: {
        pending: {
            loadingMsg: LoadingMessage.apiLoading,
        },
        fulfilled: {
            successMsg: SuccessMessage[ResponseStatus.validStatus],
            errorMessage: DataOnLoadErrorMessage.emptyData,
        },
        rejected: {},
    },
};

export const DashboardRoleAccessApi = {
    uniqueKey: "DashboardRoleAccessApiQuery",
    endpointUrl: () => "db-role-user/select-type-of-db-role",
    statusMessage: {
        pending: {
            loadingMsg: LoadingMessage.apiLoading,
        },
        fulfilled: {
            successMsg: SuccessMessage[ResponseStatus.validStatus],
            errorMessage: DataOnLoadErrorMessage.emptyData,
        },
        rejected: {},
    },
};

export const DashboardBusinessGroupApi = {
    uniqueKey: "DashboardBusinessGroupApiQuery",
    endpointUrl: () => "business-group/select-business-group",
    statusMessage: {
        pending: {
            loadingMsg: LoadingMessage.apiLoading,
        },
        fulfilled: {
            successMsg: SuccessMessage[ResponseStatus.validStatus],
            errorMessage: DataOnLoadErrorMessage.emptyData,
        },
        rejected: {},
    },
};

export const DashboardGroupApi = {
    uniqueKey: "DashboardGroupApiQuery",
    endpointUrl: () => "dashboard/select-dashboard-group",
    statusMessage: {
        pending: {
            loadingMsg: LoadingMessage.apiLoading,
        },
        fulfilled: {
            successMsg: SuccessMessage[ResponseStatus.validStatus],
            errorMessage: DataOnLoadErrorMessage.emptyData,
        },
        rejected: {},
    },
};

export const DashboardSpecialtyGroupApi = {
    uniqueKey: "DashboardSpecialtyApiQuery",
    endpointUrl: () => "dashboard/select-dashboard-specialty",
    statusMessage: {
        pending: {
            loadingMsg: LoadingMessage.apiLoading,
        },
        fulfilled: {
            successMsg: SuccessMessage[ResponseStatus.validStatus],
            errorMessage: DataOnLoadErrorMessage.emptyData,
        },
        rejected: {},
    },
};

export const DashboardProviderApi = {
    uniqueKey: "DashboardProviderApiQuery",
    endpointUrl: () => "dashboard/select-dashboard-provider",
    statusMessage: {
        pending: {
            loadingMsg: LoadingMessage.apiLoading,
        },
        fulfilled: {
            successMsg: SuccessMessage[ResponseStatus.validStatus],
            errorMessage: DataOnLoadErrorMessage.emptyData,
        },
        rejected: {},
    },
};

export const DashboardInsertTabApi = {
    uniqueKey: "DashboardInsertTabQuery",
    endpointUrl: () => "dashboard/insert-dashboard-tab",
    statusMessage: {
        pending: {
            loadingMsg: LoadingMessage.apiLoading,
        },
        fulfilled: {
            successMsg: SuccessMessage[ResponseStatus.validStatus],
            errorMessage: DataOnLoadErrorMessage.emptyData,
        },
        rejected: {},
    },
};

export const DashboardUpdateTabApi = {
    uniqueKey: "DashboardUpdateTabQuery",
    endpointUrl: () => "dashboard/update-dashboard-tab",
    statusMessage: {
        pending: {
            loadingMsg: LoadingMessage.apiLoading,
        },
        fulfilled: {
            successMsg: SuccessMessage[ResponseStatus.validStatus],
            errorMessage: DataOnLoadErrorMessage.emptyData,
        },
        rejected: {},
    },
};

export const DashboardUpdatePodOrderApi = {
    uniqueKey: "DashboardUpdatePodOrderQuery",
    endpointUrl: () => "dashboard/update-dashboard-pod-order",
    statusMessage: {
        pending: {
            loadingMsg: LoadingMessage.apiLoading,
        },
        fulfilled: {
            successMsg: SuccessMessage[ResponseStatus.validStatus],
            errorMessage: DataOnLoadErrorMessage.emptyData,
        },
        rejected: {},
    },
};

export const DashboardCopyTabApi = {
    uniqueKey: "DashboardCopyTabApiQuery",
    endpointUrl: () => "dashboard/copy-dashboard",
    statusMessage: {
        pending: {
            loadingMsg: LoadingMessage.apiLoading,
        },
        fulfilled: {
            successMsg: SuccessMessage[ResponseStatus.validStatus],
            errorMessage: DataOnLoadErrorMessage.emptyData,
        },
        rejected: {},
    },
};

export const PublishDashboardApi = {
    uniqueKey: "PublishDashboardApiQuery",
    endpointUrl: () => "dashboard/publish-dashboard",
    statusMessage: {
        pending: {
            loadingMsg: LoadingMessage.apiLoading,
        },
        fulfilled: {
            successMsg: SuccessMessage[ResponseStatus.validStatus],
            errorMessage: DataOnLoadErrorMessage.emptyData,
        },
        rejected: {},
    },
};
