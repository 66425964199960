// eslint-disable-next-line no-useless-concat
import React, { useMemo } from "react";
import {
  getSelectedState,
  GridColumn as Column,
} from "@progress/kendo-react-grid";
import { useHistory } from "react-router";
import MultiSelectDropdown from "../../components/multiSelect";
import {
  GetEventDataSourceAPI,
  GetEventTypeAPI,
  GetEventCompPlanAPI,
  GetEventServiceLocationAPI,
  GetEventPositionAPI,
  updateColumnReorderAsync,
  getApprovalStatus,
  GetSystemConfigDataByCode,
} from "../../lib/common-service";
import { useDispatch } from "react-redux";
import { Error, Label } from "@progress/kendo-react-labels";
import { getUserCredential, getTenantCookieData } from "../../lib/cookies";
import { Button } from "@progress/kendo-react-buttons";
import userResultsApi from "./service";
import { DEFAULT_SORT } from "../../constants/grid";
import MessageSchema from "../../utils/messageSchema";
import {
  DELETE_EVENTS,
  DELETE_EVENTS_BATCHES,
} from "../../api-setup/api-endpoints";
import {
  applyDateFormat,
  applyFormat,
  changeCancelBtnState,
  changeUpdateBtnState,
  downloadURI,
  executeGetColumnOrderInfo,
  formatDateOnly,
  isYearValidate,
  toCamelCase,
} from "../../utils/utils";
import { DELETE_BTN_ACTIONS } from "../../constants/constants";
import { LoadingPanel } from "../../components/loadingPanel";
import CustomDropdown from "../../components/customDropdown";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { DialogComponent } from "../../components/dialog";
import { generateHeader, POSTAPI } from "../../api-setup/api-helper";
import { setNewMessage } from "../../redux/actions/setting-actions";
import DtuBatchLogs from "../common/dtuBatchLogs";
import { columnMenuProps } from "../../components/customColumnMenu";
import ManageEventType from "./manage-event-type";
import { getfilterDataString, removeAliasAndDot } from "../../utils/utils";
import { HeaderPage } from "../base/headerPage";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { getter } from "@progress/kendo-react-common";
import ManageCustomField from "../custom-fields/manage-custom-field";
import UploadDtu from "../user/uploadDtu";
import { CellRender, EventRowRender } from "src/components/renderers";
import { Input } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { transformDateTime } from "src/lib/date-time.util";
import DataTableV2 from "src/components/dataTableV2";

const editField = "inEdit";
const SELECTED_FIELD = "selected";
const DATA_ITEM_KEY = "event_mstr_key";
const idGetter = getter(DATA_ITEM_KEY);
const EVENT_TYPE_ID = 225;

const generateExactString = (colName, value) => {
  //eslint-disable-next-line
  const escapedColName = `\"${colName}\"`;
  //eslint-disable-next-line
  const escapedValue = `\"${value}\"`;
  let ex = `<data col_name= ${escapedColName} value= ${escapedValue} />`;
  return ex;
};

const convertDate = (date) => {
  return transformDateTime({
    date: date,
    format: "yyyy-MM-DD",
  });
};

const StringReadAndOnlyCell = React.memo((props) => {
  const { ariaColumnIndex, columnIndex, dataItem, field } = props;
  const val = dataItem[field || ""];

  return (
    <td aria-colindex={ariaColumnIndex} data-grid-col-index={columnIndex}>
      <span title={val}>{val}</span>
    </td>
  );
});

const DynamicNumberCell = React.memo((props) => {
  const { ariaColumnIndex, columnIndex, dataItem, field } = props;
  const value = props.dataItem[props.field];
  const format = props.format;
  const formattedValue = !isNaN(value) ? applyFormat(value, format) : value;

  return (
    <td aria-colindex={ariaColumnIndex} data-grid-col-index={columnIndex}>
      <span title={formattedValue}>{formattedValue}</span>
    </td>
  );
});

const CompData = React.memo(
  (props) => {
    const {
      isAPICalling,
      initialLoading,
      data,
      id,
      editField,
      pageData,
      dataCount,
      customColumn,
      columns,
      dataItemKey,
      defaultPageSize,
      pageSize,
      setPageSize,
      reorderable,
      initialFilter,
      initialSort,
      resetSort,
      columnReorderData,
      rowRender,
      cellRender,
      itemChange,
      handleColumnMenu,
      handlePageChange,
      refreshClick,
      onClearFilter,
      onSelectionChange,
      isSelection,
    } = props;

    // Memoize DataTable props to avoid re-creating the object on each render
    const dataTableProps = useMemo(
      () => ({
        isAPICalling,
        initialLoading,
        data,
        pageData,
        dataCount,
        customColumn,
        columns,
        dataItemKey,
        width: "auto",
        defaultPageSize,
        pageSize,
        setPageSize,
        reorderable,
        initialFilter,
        initialSort,
        resetSort,
        handleColumnMenu,
        rowRender: rowRender,
        columnReorderData,
        onSelectionChange: onSelectionChange,
        onClearFilter: onClearFilter,
        handlePageChange: handlePageChange,
        refreshClick: refreshClick,
        gridHeight: "auto",
        cellRender,
        itemChange,
        editField,
        isSelection,
        isDragMode: false,
      }),
      [
        isAPICalling,
        initialLoading,
        data,
        pageData,
        dataCount,
        customColumn,
        columns,
        dataItemKey,
        defaultPageSize,
        pageSize,
        setPageSize,
        reorderable,
        initialFilter,
        initialSort,
        resetSort,
        handleColumnMenu,
        columnReorderData,
        onSelectionChange,
        onClearFilter,
        handlePageChange,
        refreshClick,
        rowRender,
        cellRender,
        itemChange,
        editField,
        isSelection,
      ]
    );
    return (
      <>
        <DataTableV2 {...dataTableProps} />
      </>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.dataCount === nextProps.dataCount &&
      prevProps.isAPICalling === nextProps.isAPICalling &&
      prevProps.data === nextProps.data &&
      prevProps.columns === nextProps.columns
    );
  }
);

const inSourceFilterValue = (data) => {
  let string = "";
  if (data.filter((x) => x.text !== "Select All").length > 0) {
    let source_filter = data
      .map((item) => {
        return `"${item.text}"`;
      })
      .toString();
    string = source_filter;
  }

  return string;
};

const Events = (pageProps) => {
  const divRef = React.useRef();
  const [apiCallData, setAPICallData] = React.useState({});
  const [list, Action] = userResultsApi();
  const history = useHistory();
  const cookiesData = getUserCredential();
  const dispatch = useDispatch();
  const defaultPageSize = getTenantCookieData("record_per_page");
  const [selectedState, setSelectedState] = React.useState({});
  const [isSelection, setSelection] = React.useState(false);
  const [confirmationPopup, setConfirmationPopup] = React.useState({
    title: "Delete",
    desc: "Are you sure you want to delete this record?",
    isVisible: false,
    type: "",
    actionBtn: [],
    data: {},
    action: "Delete",
  });
  const [isShow, setShow] = React.useState({
    auditLogs: false,
    dtuLogs: false,
    deleteSelectedRow: false,
    sendEmails: false,
  });
  const [filterData, setFilterData] = React.useState({
    isSort: false,
    isFilter: false,
    data: "",
    in_sort_number: DEFAULT_SORT.number,
    sort_order: DEFAULT_SORT.order,
  });
  const [columnsData, setColumnsData] = React.useState([]);
  const [isCheckColumn, setColumnCheck] = React.useState(false);
  const [searchListLoading, setSearchListLoading] = React.useState(false);
  const [isDeleteByBatch, setIsDeleteByBatch] = React.useState(false);
  const [isDeleteByBatchData, setIsDeleteByBatchData] = React.useState(false);
  const [deleteSelectedIds, setDeleteSelectedIds] = React.useState([]);
  const [selectedRowId, setSelectedRowId] = React.useState("");
  const [approvalStatusData, setApprovalStatusData] = React.useState([]);
  const [isEventApproval, setEventApproval] = React.useState(false);
  const [isNewlyAddedEventType, setNewlyAddedEventType] = React.useState(false);
  const [filterField, setFilterField] = React.useState({
    logic: "and",
    filters: [],
  });
  const [sortField, setSortField] = React.useState([]);
  const [isSearchClick, setIsSearchClick] = React.useState(false);
  const [searchStateFields, setSearchStateFields] = React.useState({});
  const {
    data,
    columns,
    dataCount,
    setColumns,
    isAPICalling,
    setAPICallingState,
    initialLoading,
    fileData,
    setFileData,
    setLoadingStateSearch,
    loading,
    batchList,
    batchFields,
    setBatchFields,
    setData,
    originalData,
  } = list;
  const [gridColumns, setGridColumns] = React.useState([]);
  const [pageData, setPage] = React.useState({
    skip: 0,
    take: defaultPageSize.recordTake,
  });
  const [pageSize, setPageSize] = React.useState(defaultPageSize);

  const [eventFields, setEventFields] = React.useState({
    in_period_start_date: "",
    in_period_end_date: "",
  });
  const [eventsTypeFields, setEventTypeFields] = React.useState({
    eventTypeValue: {
      event_type_mstr_key: "",
    },
    eventTypeData: [],
    isEventTypeDataLoading: true,
  });
  const [eventDataSourceFields, setEventDataSourceFields] = React.useState({
    eventDataSourceValue: [],
    eventDataSourceData: [],
    isEventDataSourceDataLoading: false,
  });
  const [serviceLocationFields, setServiceLocationFields] = React.useState({
    serviceLocationValue: [],
    serviceLocationData: [],
    isServiceLocationDataLoading: false,
    serviceLocationDataList: [],
  });
  const [positionFields, setPositionFields] = React.useState({
    positionValue: [],
    positionData: [],
    isPositionDataLoading: false,
    positionDataList: [],
  });

  const [comPlanValueFields, setComPlanValueFields] = React.useState({
    complanValue: [],
    complanValueData: [],
    isComPlanValueDataLoading: false,
    compPlanDataList: [],
  });

  const [eventTypeList, setEventTypeList] = React.useState([
    {
      text: "Select All",
      id: "Select All",
    },
  ]);
  const [eventDataSourceList, setEventDataSourceList] = React.useState([
    // {
    //   text: "Select All",
    //   id: "Select All",
    // },
  ]);
  const [errorObj, setErrorObj] = React.useState({});
  const [isEventClick, setEventClick] = React.useState(false);
  const [eventErrorObj, setEventErrorObj] = React.useState({});
  const [resetSort, setResetSort] = React.useState(false);

  React.useEffect(() => {
    getSystemConfigCallAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSystemConfigCallAsync = async () => {
    let reqData = {
      in_tenant_id: cookiesData.out_tenant_id,
      pref_code: ["sys_event_approval"],
    };
    let res = await GetSystemConfigDataByCode(reqData, history, dispatch);
    if (res) {
      setEventApproval(res.sys_event_approval === "0" ? false : true);
    }
  };

  React.useEffect(() => {
    setConfirmationPopup({
      ...confirmationPopup,
      actionBtn: DELETE_BTN_ACTIONS(
        onCancelConfirm,
        onDeleteConfirmByPeriod,
        batchFields.batchValue.length
      ),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batchFields]);

  React.useEffect(() => {
    if (columns.length > 0) {
      setColumnsData(columns);
      setGridColumns(columns);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);

  React.useEffect(() => {
    if (isShow.deleteSelectedRow) {
      remove();
      setShow({ ...isShow, deleteSelectedRow: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShow.deleteSelectedRow]);

  React.useEffect(() => {
    if (isCheckColumn) {
      updateColumDataAsync();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheckColumn]);

  React.useEffect(() => {
    if (fileData.fileName) {
      setTimeout(async () => {
        await downloadURI(fileData.fileName, fileData.fileLink);
      }, 1000);
      setFileData({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileData]);

  React.useEffect(() => {
    if (isNewlyAddedEventType) {
      handleOpenDropdown("eventType");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewlyAddedEventType]);

  React.useEffect(() => {
    handleOpenDropdown("eventType");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (isDeleteByBatchData) {
      handleDeleteByBatch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeleteByBatchData]);

  const updateColumDataAsync = async () => {
    setLoadingStateSearch({ ...loading, updateColumn: true });
    let columnString = executeGetColumnOrderInfo(columnsData, "event");
    let reqData = {
      in_db_name: "procare_system",
      in_tenant_id: cookiesData.out_tenant_id,
      in_filter_logical_cond: "N",
      in_filter_label_cond: "N",
      in_column_info: columnString,
    };
    let res = await updateColumnReorderAsync(reqData, dispatch, history);
    if (res) {
      setLoadingStateSearch({ ...loading, updateColumn: false });
      setColumns(columnsData);
      setColumnCheck(false);
      setGridColumns(columnsData);
    } else {
      setLoadingStateSearch({ ...loading, updateColumn: false });
    }
  };

  const getInFilter = () => {
    return filterData.isFilter ? filterData.data : null;
  };

  const getInSortNumber = () => {
    return filterData.isSort ? filterData.in_sort_number : DEFAULT_SORT.number;
  };

  const getInSortOrder = () => {
    return filterData.isSort ? filterData.sort_order : DEFAULT_SORT.order;
  };

  const getPageTake = () => {
    return pageData.take;
  };

  const getPageSkip = () => {
    return pageData.skip;
  };

  const handlePageChange = async (event) => {
    const pageData = event.page;
    setAPICallingState(true);
    setPage({ skip: pageData.skip, take: pageData.take });
    const dataVal = {
      ...apiCallData,
      in_event_type_mstr_key: searchStateFields.in_event_type_mstr_key,
      in_period_start_date: searchStateFields.in_period_start_date
        ? convertDate(searchStateFields.in_period_start_date)
        : "",
      in_period_end_date: searchStateFields.in_period_end_date
        ? convertDate(searchStateFields.in_period_end_date)
        : "",
      in_position_filter: searchStateFields.in_position_filter,
      in_comp_plan_filter: searchStateFields.in_comp_plan_filter,
      in_data_source_filter: searchStateFields.in_data_source_filter,
      in_service_location: searchStateFields.in_service_location,
      in_filter: getInFilter(),
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
      in_page_row_offset: pageData.skip,
      in_page_rows: pageData.take,
    };

    GetListAsync(dataVal);
  };

  const getEventDataSourceDataList = async (req) => {
    setEventDataSourceFields({
      ...eventDataSourceFields,
      isEventDataSourceDataLoading: true,
      eventDataSourceData: [],
      eventDataSourceValue: [],
    });

    if (!searchListLoading) {
      setSearchListLoading(true);
    }

    let serviceLocationDataObj = {
      in_tenant_id: cookiesData.out_tenant_id,
      in_filter: null,
      in_period_start_date: req.in_period_start_date
        ? convertDate(req.in_period_start_date)
        : "",
      in_period_end_date: req.in_period_end_date
        ? convertDate(req.in_period_end_date)
        : "",
      in_event_type_mstr_key: req.in_event_type_mstr_key,
      in_data_source_filter: 0,
      in_sort_number: 1,
      in_sort_order: "ASC",
    };
    let resName = await GetEventDataSourceAPI(req, dispatch, history);

    if (resName.length > 0) {
      let tempArray = [];
      resName.map((item) => {
        let obj = {};
        obj.text = item.event_data_source;
        obj.id = item.event_data_source;
        tempArray.push(obj);
        return 0;
      });
      // tempArray.unshift({
      //   text: "Select All",
      //   id: "Select All",
      // });

      setEventDataSourceList(tempArray);

      setEventDataSourceFields({
        ...eventDataSourceFields,
        eventDataSourceData: tempArray,
        isEventDataSourceDataLoading: false,
        eventDataSourceValue: [
          {
            text: "Select All",
            id: "Select All",
          },
        ],
      });

      serviceLocationDataObj.in_data_source_filter = 0;

      GetEventServiceLocationDataList(serviceLocationDataObj);
    } else {
      setEventDataSourceList([]);
      setEventDataSourceFields({
        ...eventDataSourceFields,
        isEventDataSourceDataLoading: false,
        // eventDataSourceData: [
        //   {
        //     text: "Select All",
        //     id: "Select All",
        //   },
        // ],
        eventDataSourceValue: [],
      });

      GetEventServiceLocationDataList(serviceLocationDataObj);
    }
  };

  const GetEventServiceLocationDataList = async (req) => {
    if (!searchListLoading) {
      setSearchListLoading(true);
    }
    setServiceLocationFields({
      ...serviceLocationFields,
      isServiceLocationDataLoading: true,
      serviceLocationDataList: [],
      serviceLocationValue: [],
    });
    let positionObj = {
      in_tenant_id: cookiesData.out_tenant_id,
      in_filter: null,
      in_period_start_date: req.in_period_start_date
        ? convertDate(req.in_period_start_date)
        : "",
      in_period_end_date: req.in_period_end_date
        ? convertDate(req.in_period_end_date)
        : "",
      in_event_type_mstr_key: req.in_event_type_mstr_key,
      in_data_source_filter: req.in_data_source_filter,
      in_data_source_loc_filter: 0,
      in_sort_number: 1,
      in_sort_order: "ASC",
    };
    let resName = await GetEventServiceLocationAPI(req, dispatch, history);
    if (resName.length > 0) {
      let tempArray = [];
      resName.map((item) => {
        let obj = {};
        obj.text = item.service_location;
        obj.id = item.service_location;
        tempArray.push(obj);
        return 0;
      });
      // tempArray.unshift({
      //   text: "Select All",
      //   id: "Select All",
      // });
      setServiceLocationFields({
        ...serviceLocationFields,
        serviceLocationData: tempArray,
        isServiceLocationDataLoading: false,
        serviceLocationDataList: tempArray,
        serviceLocationValue: [
          {
            text: "Select All",
            id: "Select All",
          },
        ],
      });
      positionObj.in_data_source_loc_filter = inSourceFilterValue([
        {
          text: "Select All",
          id: "Select All",
        },
      ]);
      GetPositionDataList(positionObj);
    } else {
      setServiceLocationFields({
        ...serviceLocationFields,
        serviceLocationData: [],
        serviceLocationDataList: [],
        isServiceLocationDataLoading: false,
        serviceLocationValue: [],
      });
      GetPositionDataList(positionObj);
    }
  };

  const GetPositionDataList = async (req) => {
    setPositionFields({
      ...positionFields,
      isPositionDataLoading: true,
    });
    if (!searchListLoading) {
      setSearchListLoading(true);
    }
    const compObj = {
      in_tenant_id: cookiesData.out_tenant_id,
      in_filter: null,
      in_period_start_date: req.in_period_start_date
        ? convertDate(req.in_period_start_date)
        : "",
      in_period_end_date: req.in_period_end_date
        ? convertDate(req.in_period_end_date)
        : "",
      in_data_source_filter: req.in_data_source_filter,
      in_service_loc_filter: req.in_data_source_loc_filter
        ? req.in_data_source_loc_filter
        : 0,
      //  in_event_type_mstr_key: eventsTypeFields.eventTypeValue.id || req.in_event_type_mstr_key,
      in_event_type_mstr_key: req.in_event_type_mstr_key,
      in_sort_number: 1,
      in_sort_order: "ASC",
    };
    let resName = await GetEventPositionAPI(req, dispatch, history);
    if (resName.length > 0) {
      let tempArray = [];
      resName.map((item) => {
        let obj = {};
        obj.text = item.position_name;
        obj.id = item.org_position_key;
        tempArray.push(obj);
        return 0;
      });
      // tempArray.unshift({
      //   text: "Select All",
      //   id: "Select All",
      // });
      setPositionFields({
        ...positionFields,
        positionData: tempArray,
        isPositionDataLoading: false,
        positionDataList: tempArray,
        positionValue: [
          {
            text: "Select All",
            id: "Select All",
          },
        ],
      });
      // setComPlanValueFields({
      //   ...comPlanValueFields,
      //   isComPlanValueDataLoading: true,
      // });
      GetCompPlanDataList(compObj);
    } else {
      setPositionFields({
        ...positionFields,
        positionData: [],
        positionDataList: [],
        isPositionDataLoading: false,
        positionValue: [],
      });
      // setComPlanValueFields({
      //   ...comPlanValueFields,
      //   isComPlanValueDataLoading: true,
      // });
      if (!comPlanValueFields.isComPlanValueDataLoading) {
        GetCompPlanDataList(compObj);
      }
    }
  };

  const GetCompPlanDataList = async (req) => {
    setComPlanValueFields({
      ...comPlanValueFields,
      isComPlanValueDataLoading: true,
    });
    if (req.in_period_end_date !== "") {
      let resName = await GetEventCompPlanAPI(req, dispatch, history);
      if (resName.length > 0) {
        let tempArray = [];
        resName.map((item) => {
          let obj = {};
          obj.text = item.comp_plan_name;
          obj.id = item.comp_plan_mstr_key;
          tempArray.push(obj);
          return 0;
        });

        setComPlanValueFields({
          ...comPlanValueFields,
          complanValueData: tempArray,
          isComPlanValueDataLoading: false,
          compPlanDataList: tempArray,
          complanValue: [
            {
              text: "Select All",
              id: "Select All",
            },
          ],
        });
        setSearchListLoading(false);
      } else {
        setSearchListLoading(false);
        setComPlanValueFields({
          ...comPlanValueFields,
          complanValueData: [],
          compPlanDataList: [],
          isComPlanValueDataLoading: false,
          complanValue: [],
        });
      }
    } else {
      setSearchListLoading(false);
      setComPlanValueFields({
        ...comPlanValueFields,
        complanValueData: [],
        compPlanDataList: [],
        isComPlanValueDataLoading: false,
        complanValue: [],
      });
    }
  };

  const handleDataSourceDropdown = (dateFields) => {
    if (
      isYearValidate(dateFields?.in_period_start_date) &&
      isYearValidate(dateFields?.in_period_end_date)
    ) {
      let req = {
        in_tenant_id: cookiesData.out_tenant_id,
        in_filter: null,
        in_period_start_date: dateFields?.in_period_start_date
          ? convertDate(dateFields?.in_period_start_date)
          : "",
        in_period_end_date: dateFields?.in_period_end_date
          ? convertDate(dateFields?.in_period_end_date)
          : "",
        in_event_type_mstr_key: eventsTypeFields.eventTypeValue.id,
        in_sort_number: 1,
        in_sort_order: "ASC",
      };
      getEventDataSourceDataList(req);
    }
  };

  const handleOpenDropdown = async (module, dateFields) => {
    switch (module) {
      case "eventType":
        let eventTypeData = {
          in_tenant_id: cookiesData.out_tenant_id,
          in_filter: null,
          in_sort_number: 3,
          in_sort_order: "ASC",
          in_page_row_offset: 0,
          in_page_rows: 9999,
        };
        if (!searchListLoading) {
          setSearchListLoading(true);
        }
        let res = await GetEventTypeAPI(eventTypeData, dispatch, history);
        if (res.length > 0) {
          let tempArray = [];
          res.map((item) => {
            let obj = {};
            obj.text = item.event_type_name;
            obj.id = item.event_type_mstr_key;
            tempArray.push(obj);
            return 0;
          });
          setEventTypeList(tempArray);

          if (
            isYearValidate(eventFields?.in_period_start_date) &&
            isYearValidate(eventFields?.in_period_end_date)
          ) {
            let req = {
              in_tenant_id: cookiesData.out_tenant_id,
              in_filter: null,
              in_period_start_date: eventFields?.in_period_start_date
                ? convertDate(eventFields?.in_period_start_date)
                : "",
              in_period_end_date: eventFields?.in_period_end_date
                ? convertDate(eventFields?.in_period_end_date)
                : "",
              in_event_type_mstr_key: eventsTypeFields.eventTypeValue.id,
              in_sort_number: 1,
              in_sort_order: "ASC",
            };
            if (isNewlyAddedEventType) {
              let eventTypeValue = {};
              if (
                tempArray.findIndex(
                  (x) => x.text === eventsTypeFields.eventTypeValue.text
                ) === -1 &&
                isSearchClick
              ) {
                eventTypeValue = tempArray[0];
              } else {
                eventTypeValue = eventsTypeFields.eventTypeValue;
              }
              setEventTypeFields({
                ...eventsTypeFields,
                eventTypeData: tempArray,
                eventTypeValue: isSearchClick ? eventTypeValue : tempArray[0],
                isEventTypeDataLoading: false,
              });
              setNewlyAddedEventType(false);
              if (isSearchClick) {
                req.in_event_type_mstr_key = isSearchClick
                  ? eventTypeValue.id
                  : tempArray[0].id;
              }

              if (
                tempArray.findIndex(
                  (x) => x.text === eventsTypeFields.eventTypeValue.text
                ) > -1 &&
                !isSearchClick
              ) {
                getEventDataSourceDataList(req);
              }
            } else {
              setEventTypeFields({
                ...eventsTypeFields,
                eventTypeData: tempArray,
                eventTypeValue: tempArray[0],
                isEventTypeDataLoading: false,
              });
              setNewlyAddedEventType(false);
              getEventDataSourceDataList(req);
            }
          }
        } else {
          setEventTypeFields({
            ...eventsTypeFields,
            isEventTypeDataLoading: false,
          });
        }
        break;
      default:
        break;
    }
  };

  const GetListAsync = async (prepareRequestData) => {
    setResetSort(false);
    let res = await Action.getListDataAsync(prepareRequestData);
    if (res) {
      setEventErrorObj({});
      setPageMenuState(res);
      setLoadingStateSearch({ ...loading, updateColumn: false });
      changeUpdateBtnState("disable", "grid-incell-update-event");
      changeCancelBtnState("disable", "grid-incell-cancel-event");
    }
  };

  const ShowError = React.useCallback(
    (props) => {
      return <Error>{errorObj[props.name]}</Error>;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errorObj]
  );

  const handleValidation = () => {
    let isValid = true;

    if (eventFields.in_period_start_date === "") {
      isValid = false;
      errorObj.in_period_start_date = MessageSchema.StartDateRequired;
    }

    if (eventFields.in_period_end_date === "") {
      isValid = false;
      errorObj.in_period_end_date = MessageSchema.EndDateRequired;
    }

    if (eventFields.in_period_start_date > eventFields.in_period_end_date) {
      isValid = false;
      errorObj.in_period_end_date = MessageSchema.maxEndDate;
    }

    if (eventFields.in_period_end_date !== "") {
      let validCheck = isYearValidate(eventFields.in_period_start_date);
      if (!validCheck) {
        isValid = false;
        errorObj.in_period_start_date = "Period start date is not valid";
      }
    }

    if (eventFields.in_period_end_date !== "") {
      let validCheck = isYearValidate(eventFields.in_period_end_date);
      if (!validCheck) {
        isValid = false;
        errorObj.in_period_end_date = "Period end date is not valid";
      }
    }

    setErrorObj((preVState) => ({ ...preVState, errorObj }));
    return isValid;
  };

  const getColumnListAsync = async () => {
    const reqData = {
      in_tenant_id: cookiesData.out_tenant_id,
      in_filter: null,
      in_filter_db_name: "procare_system",
      in_filter_table_name: "vw_event_master",
      in_result_name_mstr_key: 0,
      in_event_type_mstr_key: eventsTypeFields.eventTypeValue.id,
      in_hide_column: 0,
    };
    let columnRes = await Action.getColumListXML(
      reqData,
      isEventApproval,
      eventsTypeFields
    );
    return columnRes;
  };

  const onEventClick = async (module) => {
    setIsSearchClick(true);
    setData([]);
    setDeleteSelectedIds([]);
    let valid = handleValidation();
    if (valid) {
      setEventClick(true);
      setPageSize(defaultPageSize);
      setPage({
        skip: 0,
        take: defaultPageSize.recordTake,
      });
      setData([]);
      setColumns([]);
      setFilterData({
        isSort: false,
        isFilter: false,
        data: "",
        in_sort_number: DEFAULT_SORT.number,
        sort_order: DEFAULT_SORT.order,
      });
      setFilterField({ logic: "and", filters: [] });
      setSortField([]);
      setLoadingStateSearch({ ...loading, isSearchLoader: true });
      let columnRes = getColumnListAsync();
      if (columnRes) {
        const postionids = [];
        for (const item of positionFields.positionValue) {
          if (item.id !== "Select All") {
            postionids.push(item.id.toString());
          }
        }
        const compids = [];
        for (const item of comPlanValueFields.complanValue) {
          if (item.id !== "Select All") {
            compids.push(item.id.toString());
          }
        }

        const postionData = postionids.join(",");
        const compDataIds = compids.join(",");
        const prepareRequestData = {
          in_tenant_id: cookiesData.out_tenant_id,
          in_filter: null,
          in_event_type_mstr_key: eventsTypeFields?.eventTypeValue.id,
          in_period_start_date: eventFields?.in_period_start_date
            ? convertDate(eventFields.in_period_start_date)
            : "",
          in_period_end_date: eventFields?.in_period_end_date
            ? convertDate(eventFields.in_period_end_date)
            : "",
          in_position_filter: postionData,
          in_comp_plan_filter: compDataIds,
          in_data_source_filter: inSourceFilterValue(
            eventDataSourceFields.eventDataSourceValue
          ),
          in_service_location: inSourceFilterValue(
            serviceLocationFields.serviceLocationValue
          ),
          in_sort_number: 1,
          in_sort_order: "DESC",
          in_page_row_offset: 0,
          in_page_rows: defaultPageSize.recordTake,
          in_acct_encrypt_key: cookiesData.out_acct_encrypt_key,
        };

        setAPICallData(prepareRequestData);
        setAPICallingState(true);
        await GetListAsync(prepareRequestData);
        await getApprovalStatusData();
        setSearchStateFields(prepareRequestData);
        changeUpdateBtnState("disable", "grid-incell-update-event");
        changeCancelBtnState("disable", "grid-incell-cancel-event");
        setSelectedRowId("");
        setSelectedState({});
        setErrorObj({});
      }
    }
  };

  const handleColumnMenu = async (
    columnMenuData,
    isFilter,
    isSort,
    filterObj
  ) => {
    let index = 1;
    let filterSkip = getPageSkip();
    let filterTake = getPageTake();
    if (isSort) {
      setSortField(columnMenuData);
    } else {
      setFilterField(filterObj);
    }
    if (data.length > 0 && columnMenuData[0] !== undefined) {
      let obj = Object.keys(data[0]);
      let sortNameField = "";

      //replace mstr key with name field only when sorting is applied
      if (
        columnMenuData[0].field &&
        columnMenuData[0].field.indexOf("_mstr_key")
      ) {
        let sortKeyField = columnMenuData[0].field;
        sortNameField = sortKeyField.replace("_mstr_key", "_name");
      }
      const sortOriginField =
        sortNameField !== "" ? sortNameField : columnMenuData[0].field;

      index = obj.findIndex((x) => x === sortOriginField) + 1;
    }

    if (isFilter) {
      filterSkip = 0;
      filterTake = pageSize.recordTake;

      //reset pager when filter applies
      setPage({ skip: filterSkip, take: filterTake });
    }
    const filterString = isFilter
      ? getfilterDataString(columnMenuData, gridColumns, "event")
      : filterData.data;

    isFilter = false;
    if (filterString !== "") {
      isFilter = true;
    }

    let sortDir =
      filterData.isSort && columnMenuData.length
        ? filterData.sort_order
        : DEFAULT_SORT.order;
    let sortNumber =
      filterData.isSort && columnMenuData.length
        ? filterData.in_sort_number
        : DEFAULT_SORT.number;

    isSort = !columnMenuData.length ? false : filterData.isSort;

    if (
      columnMenuData[0] !== undefined &&
      columnMenuData[0].dir !== undefined
    ) {
      isSort = true;
      sortDir = columnMenuData[0].dir.toUpperCase();
      sortNumber = index;
    }
    setFilterData({
      ...filterData,
      isFilter: isFilter,
      isSort: isSort,
      data: filterString,
      in_sort_number: sortNumber,
      sort_order: sortDir,
    });
    setAPICallingState(true);

    let prepareRequestData = {
      in_tenant_id: cookiesData.out_tenant_id,
      in_filter: isFilter ? filterString : null,
      in_event_type_mstr_key: searchStateFields.in_event_type_mstr_key,
      in_period_start_date: searchStateFields.in_period_start_date
        ? convertDate(searchStateFields.in_period_start_date)
        : "",
      in_period_end_date: searchStateFields.in_period_end_date
        ? convertDate(searchStateFields.in_period_end_date)
        : "",
      in_position_filter: searchStateFields.in_position_filter,
      in_comp_plan_filter: searchStateFields.in_comp_plan_filter,
      in_data_source_filter: searchStateFields.in_data_source_filter,
      in_service_location: searchStateFields.in_service_location,
      in_sort_number: sortNumber,
      in_sort_order: sortDir,
      in_page_row_offset: filterSkip,
      in_page_rows: filterTake,
      in_acct_encrypt_key: cookiesData.out_acct_encrypt_key,
    };
    GetListAsync(prepareRequestData);
  };

  const NameCell = React.useCallback(
    (props) => {
      const { ariaColumnIndex, columnIndex, dataItem, field, render } = props;
      let isInEdit = field === dataItem.inEdit;

      let value = props.field + dataItem.rowId;
      const handleChange = (e, props, value) => {
        if (props.onChange) {
          props.onChange({
            dataItem: props.dataItem,
            field: props.field,
            syntheticEvent: e.syntheticEvent,
            value: e.target.value,
          });
        }
      };

      const defaultRendering = (
        <td aria-colindex={ariaColumnIndex} data-grid-col-index={columnIndex}>
          {isInEdit ? (
            <Input
              value={dataItem[props.field]}
              onChange={(e) => {
                handleChange(e, props, value);
              }}
              name={`${props.field}`}
              autoComplete="off"
            />
          ) : (
            <span title={props.dataItem[props.field || ""]}>
              {props.dataItem[props.field || ""]}
            </span>
          )}
          <div role="alert" className="k-form-error k-text-start">
            {dataItem[value]}
          </div>
        </td>
      );
      return render?.call(undefined, defaultRendering, props);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  const CustomFieldsCell = React.useCallback(
    (props) => {
      const { ariaColumnIndex, columnIndex, dataItem, field, render } = props;
      let isInEdit = field === dataItem.inEdit;
      let value = props.field + dataItem.rowId;

      const handleChange = (e, props, value) => {
        if (props.onChange) {
          let val = "";
          if (props.field === "procedure_units") {
            val = e.target.value < 0 ? 0 : e.target.value;
          } else {
            val = e.target.value;
          }
          props.onChange({
            dataItem: props.dataItem,
            field: props.field,
            syntheticEvent: e.syntheticEvent,
            value: val,
          });
        }
      };

      const defaultRendering = (
        <td aria-colindex={ariaColumnIndex} data-grid-col-index={columnIndex}>
          {isInEdit ? (
            <Input
              type={props.field === "procedure_units" ? "number" : "text"}
              value={
                props.field === "procedure_units"
                  ? !isNaN(props.dataItem[props.field || ""])
                    ? parseFloat(props.dataItem[props.field || ""], "number")
                    : props.dataItem[props.field || ""]
                  : props.dataItem[props.field || ""]
              }
              onChange={(e) => {
                handleChange(e, props, value);
              }}
              name={`${props.field}`}
              autoComplete="off"
            />
          ) : (
            <span title={props.dataItem[props.field || ""]}>
              {props.field === "procedure_units"
                ? !isNaN(props.dataItem[props.field || ""])
                  ? parseFloat(props.dataItem[props.field || ""], "number")
                  : props.dataItem[props.field || ""]
                : props.dataItem[props.field || ""]}
            </span>
          )}
          <div role="alert" className="k-form-error k-text-start">
            {dataItem[value]}
          </div>
        </td>
      );
      return render?.call(undefined, defaultRendering, props);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  const DropDownCell = React.useCallback(
    (props) => {
      const { ariaColumnIndex, columnIndex, dataItem, field, render } = props;
      let isInEdit = field === dataItem.inEdit;
      let val = dataItem[field || ""];
      let selectedVal = dataItem[field] ? parseInt(dataItem[field]) : "";
      if (field.indexOf("_mstr_key") !== -1) {
        val = dataItem[field.replace("_mstr_key", "_name") || ""];
      }
      let valueData = dataItem.approvalStatusData;
      const onChange = (e) => {
        if (props.onChange) {
          changeUpdateBtnState("enable", "grid-incell-update-event");
          changeCancelBtnState("enable", "grid-incell-cancel-event");
          props.onChange({
            dataIndex: 0,
            dataItem: props.dataItem,
            field: props.field,
            syntheticEvent: e.syntheticEvent,
            value: e.target.value.id,
          });
        }
      };
      const defaultRendering = (
        <td aria-colindex={ariaColumnIndex} data-grid-col-index={columnIndex}>
          {isInEdit ? (
            <DropDownList
              style={{
                width: "350px",
              }}
              onChange={onChange}
              value={
                valueData &&
                valueData.find((c) => parseInt(c.id) === selectedVal)
              }
              data={valueData}
              textField="text"
              placeholder={"Select"}
            />
          ) : (
            <span>{val}</span>
          )}
        </td>
      );
      return render?.call(undefined, defaultRendering, props);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [approvalStatusData]
  );

  const DateCell = React.memo((props) => {
    const { ariaColumnIndex, columnIndex, dataItem, field } = props;

    const val =
      dataItem[field] && dataItem[field] !== "0000-00-00 00:00:00"
        ? applyDateFormat(new Date(dataItem[field]), props.format)
        : "";

    return (
      <td aria-colindex={ariaColumnIndex} data-grid-col-index={columnIndex}>
        <span title={val}>{val}</span>
      </td>
    );
  });

  // Memoize the cellType function
  const cellType = (type) => {
    switch (type) {
      case "date":
        return DateCell;
      case "boolean":
        return BooleanCell;
      case "number":
        return DynamicNumberCell;
      default:
        return StringReadAndOnlyCell;
    }
  };

  const BooleanCell = React.memo((props) => {
    const { ariaColumnIndex, columnIndex, dataItem, field } = props;
    const val = Number(dataItem[field]) ? "true" : "false";

    return (
      <td aria-colindex={ariaColumnIndex} data-grid-col-index={columnIndex}>
        <span title={val}>{val}</span>
      </td>
    );
  });

  const useCol = React.useCallback((useColumns) => {
    return useColumns.map((column, idx) => {
      return (
        column.show &&
        column.title !== "" && (
          <Column
            {...columnMenuProps(column, "event")}
            cell={
              column.field === "approval_status_mstr_key"
                ? DropDownCell
                : column.field === "approval_notes"
                ? NameCell
                : column.isEditable === true
                ? CustomFieldsCell
                : cellType(column.type)
            }
            editable={
              column.isEditable &&
              isEventApproval &&
              eventsTypeFields.eventTypeValue.id === EVENT_TYPE_ID
                ? true
                : false
            }
            key={idx}
            sortable={true}
            width={
              column.isEditable === true
                ? "180px"
                : column.type === "date"
                ? "180px"
                : column.min_width
            }
            format={column.format}
          />
        )
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let CustomColumn = useCol(gridColumns);

  const onRefreshClick = () => {
    setAPICallingState(true);
    const dataVal = {
      ...apiCallData,
      in_acct_encrypt_key: cookiesData.out_acct_encrypt_key,
      in_event_type_mstr_key: searchStateFields.in_event_type_mstr_key,
      in_period_start_date: searchStateFields.in_period_start_date
        ? convertDate(searchStateFields.in_period_start_date)
        : "",
      in_period_end_date: searchStateFields.in_period_end_date
        ? convertDate(searchStateFields.in_period_end_date)
        : "",
      in_position_filter: searchStateFields.in_position_filter,
      in_comp_plan_filter: searchStateFields.in_comp_plan_filter,
      in_data_source_filter: searchStateFields.in_data_source_filter,
      in_service_location: searchStateFields.in_service_location,
      in_filter: getInFilter(),
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
      in_page_row_offset: getPageSkip(),
      in_page_rows: getPageTake(),
    };
    GetListAsync(dataVal);
  };

  const onRefreshManualUploadClick = () => {
    if (isSearchClick) {
      setAPICallingState(true);
      const dataVal = {
        ...apiCallData,
        in_acct_encrypt_key: cookiesData.out_acct_encrypt_key,
        in_event_type_mstr_key: searchStateFields.in_event_type_mstr_key,
        in_period_start_date: searchStateFields.in_period_start_date
          ? convertDate(searchStateFields.in_period_start_date)
          : "",
        in_period_end_date: searchStateFields.in_period_end_date
          ? convertDate(searchStateFields.in_period_end_date)
          : "",
        in_position_filter: searchStateFields.in_position_filter,
        in_comp_plan_filter: searchStateFields.in_comp_plan_filter,
        in_data_source_filter: searchStateFields.in_data_source_filter,
        in_service_location: searchStateFields.in_service_location,
        in_filter: getInFilter(),
        in_sort_number: getInSortNumber(),
        in_sort_order: getInSortOrder(),
        in_page_row_offset: getPageSkip(),
        in_page_rows: getPageTake(),
      };
      GetListAsync(dataVal);
    }
  };

  const onClearFilter = async () => {
    setAPICallingState(true);
    setFilterField({ logic: "and", filters: [] });
    const dataVal = {
      ...apiCallData,
      in_event_type_mstr_key: searchStateFields.in_event_type_mstr_key,
      in_period_start_date: searchStateFields.in_period_start_date
        ? convertDate(searchStateFields.in_period_start_date)
        : "",
      in_period_end_date: searchStateFields.in_period_end_date
        ? convertDate(searchStateFields.in_period_end_date)
        : "",
      in_position_filter: searchStateFields.in_position_filter,
      in_comp_plan_filter: searchStateFields.in_comp_plan_filter,
      in_data_source_filter: searchStateFields.in_data_source_filter,
      in_service_location: searchStateFields.in_service_location,
      in_filter: null,
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
      in_page_row_offset: getPageSkip(),
      in_page_rows: getPageTake(),
    };

    //set filter to false
    setFilterData({
      ...filterData,
      data: "",
      isFilter: false,
    });

    GetListAsync(dataVal);
  };

  const remove = (ids) => {
    setConfirmationPopup({
      ...confirmationPopup,
      isVisible: true,
      type: "DELETE_CONFIRM",
      actionBtn: DELETE_BTN_ACTIONS(onCancelConfirm, onDeleteConfirm),
      data: [],
      action: "DeleteRows",
    });
  };

  const onDeleteConfirm = async (event) => {
    const deleteArray = deleteSelectedIds.map((obj2) => {
      const obj1 = data.find(
        (elem) => elem.event_mstr_key === obj2.mstr_key.toString()
      );
      const postionids = [];
      for (const item of positionFields.positionValue) {
        if (item.id !== "Select All") {
          postionids.push(item.id.toString());
        }
      }
      const postionData = postionids.join(",");
      return {
        in_user_id: cookiesData.out_user_id,
        in_tenant_id: cookiesData.out_tenant_id,
        id: obj1.event_mstr_key,
        in_event_type_mstr_key: obj1.event_type_mstr_key,
        in_period_start_date: eventFields?.in_period_start_date
          ? convertDate(eventFields.in_period_start_date)
          : "",
        in_period_end_date: eventFields?.in_period_end_date
          ? convertDate(eventFields.in_period_end_date)
          : "",
        in_position_filter: postionData,
        in_data_source_filter: inSourceFilterValue(
          eventDataSourceFields.eventDataSourceValue
        ),
        in_batch_load_keys: "",
      };
    });
    let deleteData = {
      eventArray: deleteArray,
    };

    let res = await Action.deleteDataAsync(deleteData);
    if (res) {
      setDeleteSelectedIds([]);
      setSelectedRowId("");
      setSelectedState({});
      setConfirmationPopup({
        ...confirmationPopup,
        isVisible: false,
        type: "",
        actionBtn: [],
        data: {},
        action: "",
      });
      setAPICallingState(true);
      const dataVal = {
        ...apiCallData,
        in_event_type_mstr_key: searchStateFields.in_event_type_mstr_key,
        in_period_start_date: searchStateFields.in_period_start_date
          ? convertDate(searchStateFields.in_period_start_date)
          : "",
        in_period_end_date: searchStateFields.in_period_end_date
          ? convertDate(searchStateFields.in_period_end_date)
          : "",
        in_position_filter: searchStateFields.in_position_filter,
        in_comp_plan_filter: searchStateFields.in_comp_plan_filter,
        in_data_source_filter: searchStateFields.in_data_source_filter,
        in_service_location: searchStateFields.in_service_location,
        in_filter: getInFilter(),
        in_sort_number: getInSortNumber(),
        in_sort_order: getInSortOrder(),
        in_page_row_offset: pageData.skip,
        in_page_rows: pageData.take,
      };
      GetListAsync(dataVal);
    }
  };

  const handleDeleteByBatch = async () => {
    const eventSourceIds = [];
    for (const item of eventDataSourceFields.eventDataSourceValue) {
      if (item.id !== "Select All") {
        eventSourceIds.push(item.id.toString());
      }
    }

    const postionids = [];
    for (const item of positionFields.positionValue) {
      if (item.id !== "Select All") {
        postionids.push(item.id.toString());
      }
    }
    const postionData = postionids.join(",");
    setIsDeleteByBatch(false);
    const batchreqData = {
      in_tenant_id: cookiesData.out_tenant_id,

      in_event_type_mstr_key: eventsTypeFields?.eventTypeValue.id,
      in_period_start_date: eventFields?.in_period_start_date
        ? convertDate(eventFields.in_period_start_date)
        : "",
      in_period_end_date: eventFields?.in_period_end_date
        ? convertDate(eventFields.in_period_end_date)
        : "",
      in_position_filter: postionData,

      in_data_source_filter: inSourceFilterValue(
        eventDataSourceFields.eventDataSourceValue
      ),
    };
    await Action.selecteEventByBatch(batchreqData);
    setConfirmationPopup({
      ...confirmationPopup,
      title: "Delete Events by Batch",
      desc: "You are about to delete events which will reset any results data that is associated with these events.Do you want to continue with this deletion?",
      isVisible: true,
      type: "delete-by-batch",
      actionBtn: DELETE_BTN_ACTIONS(
        onCancelConfirm,
        onDeleteConfirmByPeriod,
        batchFields.batchValue.length
      ),
      data: {},
      action: "delete-by-batch",
    });
  };

  const onDeleteConfirmByPeriod = () => {
    const eventSourceIds = [];
    for (const item of eventDataSourceFields.eventDataSourceValue) {
      if (item.id !== "Select All") {
        eventSourceIds.push(item.id.toString());
      }
    }

    const postionids = [];
    for (const item of positionFields.positionValue) {
      if (item.id !== "Select All") {
        postionids.push(item.id.toString());
      }
    }
    const postionData = postionids.join(",");

    if (confirmationPopup.action === "delete") {
      let deleteData = {
        in_user_id: cookiesData.out_user_id.toString(),
        in_tenant_id: cookiesData.out_tenant_id,
        in_period_start_date: eventFields.in_period_start_date
          ? convertDate(eventFields.in_period_start_date)
          : "",
        in_period_end_date: eventFields.in_period_end_date
          ? convertDate(eventFields.in_period_end_date)
          : "",
        in_event_type_mstr_key: eventsTypeFields.eventTypeValue.id,
        in_position_filter: postionData,
        in_data_source_filter: inSourceFilterValue(
          eventDataSourceFields.eventDataSourceValue
        ),
        in_batch_load_keys: "",
      };
      setConfirmationPopup({
        ...confirmationPopup,
        isVisible: false,
        type: "",
        actionBtn: [],
        data: {},
        action: "",
      });
      setAPICallingState(true);
      POSTAPI(
        false,
        DELETE_EVENTS,
        null,
        generateHeader(),
        deleteData,
        dispatch
      ).then((res) => {
        if (res.data.out_parameters.out_ret_code > 0) {
          setIsDeleteByBatchData(true);
          setAPICallingState(false);
          setConfirmationPopup({
            ...confirmationPopup,
            isVisible: false,
            type: "",
            actionBtn: [],
            data: {},
            action: "",
          });
          dispatch(
            setNewMessage({
              message: res.data.out_parameters.out_error_msg,
              type: "Error",
              show: true,
            })
          );
          setSelection(false);
        } else {
          apiCallData.in_filter = getInFilter();
          apiCallData.in_sort_number = getInSortNumber();
          apiCallData.in_sort_order = getInSortOrder();
          apiCallData.in_page_row_offset = 0;
          apiCallData.in_page_rows = getPageTake();
          apiCallData.in_event_type_mstr_key =
            searchStateFields.in_event_type_mstr_key;
          apiCallData.in_period_start_date =
            searchStateFields.in_period_start_date
              ? convertDate(searchStateFields.in_period_start_date)
              : "";
          apiCallData.in_period_end_date = searchStateFields.in_period_end_date
            ? convertDate(searchStateFields.in_period_end_date)
            : "";
          apiCallData.in_position_filter = searchStateFields.in_position_filter;
          apiCallData.in_comp_plan_filter =
            searchStateFields.in_comp_plan_filter;
          apiCallData.in_data_source_filter =
            searchStateFields.in_data_source_filter;
          apiCallData.in_service_location =
            searchStateFields.in_service_location;
          GetListAsync(apiCallData);
          setSelection(false);
        }
      });
    } else {
      let deleteData = {
        in_user_id: cookiesData.out_user_id.toString(),
        in_tenant_id: cookiesData.out_tenant_id,
        in_event_mstr_key: null,
        in_period_start_date: eventFields.in_period_start_date
          ? convertDate(eventFields.in_period_start_date)
          : "",
        in_period_end_date: eventFields.in_period_end_date
          ? convertDate(eventFields.in_period_end_date)
          : "",
        in_event_type_mstr_key: eventsTypeFields.eventTypeValue.id,
        in_position_filter: postionData,

        in_data_source_filter: inSourceFilterValue(
          eventDataSourceFields.eventDataSourceValue
        ),
        in_batch_load_keys:
          batchFields.batchValue.length > 0
            ? batchFields.batchValue
                .map((item) => {
                  return item.id;
                })
                .toString()
            : "",
      };

      setAPICallingState(true);
      setConfirmationPopup({
        ...confirmationPopup,
        isVisible: false,
        type: "",
        actionBtn: [],
        data: {},
        action: "",
      });
      POSTAPI(
        false,
        DELETE_EVENTS_BATCHES,
        null,
        generateHeader(),
        deleteData,
        dispatch
      ).then((res) => {
        if (res.data.out_parameters.out_ret_code > 0) {
          setConfirmationPopup({
            ...confirmationPopup,
            isVisible: false,
            type: "",
            actionBtn: [],
            data: {},
            action: "",
          });
          dispatch(
            setNewMessage({
              message: res.data.out_parameters.out_error_msg,
              type: "Error",
              show: true,
            })
          );
          setAPICallingState(false);
        } else {
          setBatchFields({
            batchValue: [],
            batchData: [],
            isBatchDataLoading: true,
          });
          setIsDeleteByBatchData(false);
          let dataVal = {
            ...apiCallData,
            in_filter: getInFilter(),
            in_sort_number: getInSortNumber(),
            in_sort_order: getInSortOrder(),
            in_page_row_offset: 0,
            in_page_rows: getPageTake(),
            in_event_type_mstr_key: searchStateFields.in_event_type_mstr_key,
            in_period_start_date: searchStateFields.in_period_start_date
              ? convertDate(searchStateFields.in_period_start_date)
              : "",
            in_period_end_date: searchStateFields.in_period_end_date
              ? convertDate(searchStateFields.in_period_end_date)
              : "",
            in_position_filter: searchStateFields.in_position_filter,
            in_comp_plan_filter: searchStateFields.in_comp_plan_filter,
            in_data_source_filter: searchStateFields.in_data_source_filter,
            in_service_location: searchStateFields.in_service_location,
            in_load_type: "grid-load",
          };
          GetListAsync(dataVal);
          setSelection(false);
        }
      });
    }
  };

  const handleDeleteByPeriod = () => {
    setConfirmationPopup({
      ...confirmationPopup,
      title: "Delete All Events in Period",
      desc: "You are about to delete events which will reset any results data that is associated with these events.Do you want to continue with this deletion?",
      isVisible: true,
      type: "",
      actionBtn: DELETE_BTN_ACTIONS(onCancelConfirm, onDeleteConfirmByPeriod),
      data: {},
      action: "Delete",
    });
  };

  const onCancelConfirm = () => {
    setConfirmationPopup({
      title: "Delete",
      desc: "Are you sure you want to delete this record?",
      isVisible: false,
      type: "",
      actionBtn: [],
      data: {},
      action: "Delete",
    });
    setIsDeleteByBatchData(false);
    setBatchFields({
      batchValue: [],
      batchData: [],
      isBatchDataLoading: true,
    });
  };

  const handleMenuItemClick = (module) => {
    switch (module) {
      case "dtuLogs":
        setShow({
          ...isShow,
          dtuLogs: !isShow.dtuLogs,
        });
        break;
      case "delete":
        setShow({
          ...isShow,
          deleteSelectedRow: !isShow.deleteSelectedRow,
        });
        break;
      case "Delete By Batch":
        setIsDeleteByBatchData(true);
        break;
      case "manageType":
        setShow({
          ...isShow,
          isResult: !isShow.isResult,
        });
        break;
      case "manageCustomType":
        setShow({
          ...isShow,
          isManageCustomType: !isShow.isManageCustomType,
        });
        break;
      default:
        break;
    }
  };

  const onDeleteBatchConfirm = async () => {
    setIsDeleteByBatch(false);
    setIsDeleteByBatchData(true);
  };

  const handleExportToExcel = async () => {
    const eventSourceIds = [];
    for (const item of eventDataSourceFields.eventDataSourceValue) {
      if (item.id !== "Select All") {
        eventSourceIds.push(item.id.toString());
      }
    }
    const serviceIds = [];
    for (const item of serviceLocationFields.serviceLocationValue) {
      if (item.id !== "Select All") {
        serviceIds.push(item.id.toString());
      }
    }
    const postionids = [];
    for (const item of positionFields.positionValue) {
      if (item.id !== "Select All") {
        postionids.push(item.id.toString());
      }
    }
    const compids = [];
    for (const item of comPlanValueFields.complanValue) {
      if (item.id !== "Select All") {
        compids.push(item.id.toString());
      }
    }

    const postionData = postionids.join(",");
    const compDataIds = compids.join(",");
    let data = {
      in_filter: filterData.isFilter ? removeAliasAndDot(filterData.data) : "",
      in_event_type_mstr_key: eventsTypeFields?.eventTypeValue.id,
      in_period_start_date: eventFields?.in_period_start_date
        ? convertDate(eventFields.in_period_start_date)
        : "",
      in_period_end_date: eventFields?.in_period_end_date
        ? convertDate(eventFields.in_period_end_date)
        : "",
      in_position_filter: postionData,
      in_comp_plan_filter: compDataIds,
      in_data_source_filter: inSourceFilterValue(
        eventDataSourceFields.eventDataSourceValue
      ),
      in_service_location: inSourceFilterValue(
        serviceLocationFields.serviceLocationValue
      ),
      in_sort_number: filterData.isSort ? sortField[0].field : 1,
      in_sort_order: "DESC",
      in_page_row_offset: 0,
      in_page_rows: getPageTake(),
      in_acct_encrypt_key: cookiesData.out_acct_encrypt_key,
      in_export_flag: 0,
      in_tenant_id: cookiesData.out_tenant_id,
      in_user_key: cookiesData.out_user_id,
    };
    setAPICallingState(true);
    await Action.exportToExcelAsync(data);
  };

  const handleUpload = (e) => {
    setShow({
      ...isShow,
      dtuUpload: !isShow.dtuUpload,
    });
    e.preventDefault();
  };

  const editFieldsBasedOnTenantIdAndEventId = () => {
    let isEdit = false;
    if (
      cookiesData.out_tenant_id === "medonehp0001" &&
      eventsTypeFields.eventTypeValue.id === EVENT_TYPE_ID &&
      isEventClick &&
      isEventApproval
    ) {
      isEdit = true;
    }
    return isEdit;
  };

  const editFieldsBasedOnTenantId = () => {
    let isEdit = false;
    if (
      cookiesData.out_tenant_id === "medonehp0001" &&
      isEventApproval &&
      isEventClick
    ) {
      isEdit = true;
    }
    return isEdit;
  };

  const editFieldsBasedOnEventTypeId = (field) => {
    let isEdit = false;
    if (
      cookiesData.out_tenant_id === "medonehp0001" &&
      isEventApproval &&
      isEventClick &&
      (field === "approval_status_name" ||
        field === "approval_notes" ||
        field === "approval_status_mstr_key")
    ) {
      isEdit = true;
    }
    return isEdit;
  };

  const headerConfig = {
    title: "Events",
    id: "events",
    ...(editFieldsBasedOnTenantId()
      ? { buttons: { template_fx: GridButton } }
      : {}),
    upload_fx: handleUpload,
    action_menu: {
      export: { fx: handleExportToExcel, caption: "Export to CSV" },
      deleteAll: { fx: handleDeleteByPeriod, caption: "Delete All in Period" },
      dtuLog: { fx: handleMenuItemClick },
      deleteBatch: {
        fx: handleMenuItemClick,
        caption: "Delete By Batch",
      },
      delete: { fx: handleMenuItemClick },
      manageType: {
        fx: handleMenuItemClick,
        items: [
          ...(pageProps.eventTypeReadAccess === 1
            ? [{ caption: "Manage Event Type", module: "manageType" }]
            : []),
          { caption: "Manage Custom Fields", module: "manageCustomType" },
        ],
      },
    },
    ...(pageProps.writeAccess === 1 && {
      manage_col: {
        columnsData: columnsData,
        setColumnsData: setColumnsData,
        setColumnCheck: setColumnCheck,
      },
    }),
  };

  const parent = document.querySelector("#content-" + headerConfig.id);

  if (parent) {
    const exportToExcel = parent.querySelector(".export-to-excel");
    const deleteRows = parent.querySelector(".delete-rows");
    const deleteAllRows = parent.querySelector(".delete-allrows");
    const deleteAllRowsBatch = parent.querySelector(".delete-allrows-batch");

    exportToExcel && exportToExcel.disableKendoMenu();
    deleteRows && deleteRows.disableKendoMenu();
    deleteAllRows && deleteAllRows.disableKendoMenu();
    deleteAllRowsBatch && deleteAllRowsBatch.disableKendoMenu();

    data.length && exportToExcel && exportToExcel.enableKendoMenu();
    if (selectedRowId !== "") {
      data.length && deleteRows && deleteRows.enableKendoMenu();
    }
    data.length && deleteAllRows && deleteAllRows.enableKendoMenu();
    data.length && deleteAllRowsBatch && deleteAllRowsBatch.enableKendoMenu();
  }

  const setPageMenuState = (response) => {
    if (!deleteSelectedIds.length) {
      return false;
    }

    let ids = response.map((el) => el[DATA_ITEM_KEY]);

    let selectedKeys = [];
    let tempArray = [];
    let tempArrayData = [];

    Object.keys(selectedState).map((item) => {
      let obj = {};

      obj.id = Number(item);
      selectedKeys.push(item);
      tempArray.push(obj);

      return 0;
    });

    ids.map((item) => {
      if (selectedKeys.includes(item.toString())) {
        tempArrayData.push(item);
      }
      return 0;
    });

    let strSelectedKeys = "";

    if (tempArrayData.length > 0) {
      strSelectedKeys = parseInt(selectedKeys[0]);
    }

    setSelectedRowId(strSelectedKeys);
  };

  const onSelectionChange = (event) => {
    setSelection(false);
    const newSelectedState = getSelectedState({
      event,
      selectedState: selectedState,
      dataItemKey: DATA_ITEM_KEY,
    });

    let tempArray = [];

    Object.keys(newSelectedState).map((item) => {
      if (newSelectedState[item] === false) {
        return 0;
      }

      let obj = {};
      obj.mstr_key = Number(item);

      tempArray.push(obj);
      return 0;
    });

    const newSelectedStateKeys = Object.keys(newSelectedState);

    const selectedKeyArr = newSelectedStateKeys.filter(function (x) {
      return newSelectedState[x] !== false;
    });

    let newData = data.map((item) => ({
      ...item,
      selected: selectedKeyArr.includes(item.event_mstr_key),
    }));
    setData(newData);
    setSelectedState(newSelectedState);
    setDeleteSelectedIds(tempArray);
    setSelectedRowId(tempArray[0].id);
    setSelection(true);
  };

  const handleDatePickerChange = (e) => {
    setErrorObj({ ...errorObj, in_period_start_date: "" });
    setEventFields({ ...eventFields, in_period_start_date: e.value });
    let dateFields = {
      in_period_start_date: e.value,
      in_period_end_date: eventFields.in_period_end_date,
    };
    handleDataSourceDropdown(dateFields);
  };

  const handleEndDateChange = (e) => {
    setErrorObj({ ...errorObj, in_period_end_date: "" });
    setEventFields({ ...eventFields, in_period_end_date: e.value });
    let dateFields = {
      in_period_start_date: eventFields.in_period_start_date,
      in_period_end_date: e.value,
    };
    handleDataSourceDropdown(dateFields);
  };

  const columnReorderData = async (columnData) => {
    let columnString = executeGetColumnOrderInfo(columnData, "event");
    setLoadingStateSearch({ ...loading, updateColumn: true });
    let reqData = {
      in_db_name: "procare_system",
      in_tenant_id: cookiesData.out_tenant_id,
      in_filter_logical_cond: "N",
      in_filter_label_cond: "N",
      in_column_info: columnString,
    };
    let res = await updateColumnReorderAsync(reqData, dispatch, history);
    if (res) {
      setLoadingStateSearch({ ...loading, updateColumn: false });
      let colDataList = JSON.parse(JSON.stringify(columnData));
      colDataList.forEach((item) => {
        item.hidden = false;
      });
      const mergedArray = columns.map((item1) => {
        const item2 = colDataList.find((item) => item.field === item1.field); // Find matching item by id
        return {
          ...item1,
          ...item2,
        };
      });
      let colData = mergedArray.sort(function (a, b) {
        return a?.ariaColumnIndex - b?.ariaColumnIndex;
      });
      setColumns([]);
      setColumnsData(colData);
      setColumns(colData);
    } else {
      setLoadingStateSearch({ ...loading, updateColumn: false });
    }
  };

  const enterEdit = (dataItem, field) => {
    setSelection(false);
    if (pageProps.writeAccess === 0) {
      return false;
    }
    if (
      eventsTypeFields.eventTypeValue.id === EVENT_TYPE_ID
        ? editFieldsBasedOnTenantIdAndEventId()
        : editFieldsBasedOnEventTypeId(field)
    ) {
      let newData = data.map((item) => ({
        ...item,
        approvalStatusData: approvalStatusData,
        inEdit:
          item.event_mstr_key === dataItem.event_mstr_key ? field : undefined,
      }));
      setData(newData);
      setSelection(true);
    }
  };

  const itemChange = React.useCallback(
    (event) => {
      setSelection(false);
      const field = event.field || "";
      const updatedData = data.map((item) => {
        if (item.event_mstr_key === event.dataItem.event_mstr_key) {
          let tempObj = approvalStatusData.find(
            (x) => x.id === Number(event.value)
          );
          return {
            ...item,
            [field]: event.value,
            inItemEdit: true,
            ...(field === "approval_status_mstr_key" && {
              approval_status_name: tempObj
                ? tempObj.text
                : item.approval_status_name,
              approval_status_type: tempObj
                ? tempObj.text
                : item.approval_status_type,
            }),
          };
        }
        return item;
      });

      let editItem = updatedData.find(
        (item) => item.event_mstr_key === event.dataItem.event_mstr_key
      );
      if (editItem) {
        editItem[field] = event.value;
        setData(updatedData);
      }
      setSelection(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  const customRowRender = (tr, props) => (
    <EventRowRender originalProps={props} tr={tr} />
  );

  const customCellRender = (td, props) => {
    return (
      <CellRender
        navigatable={true}
        originalProps={props}
        td={td}
        enterEdit={enterEdit}
        editField={editField}
        updateActBtn="grid-incell-update-event"
        cancelActBtn="grid-incell-cancel-event"
      />
    );
  };

  const handleEventValidation = () => {
    let isValid = true;
    let newData = [...data];
    if (editFieldsBasedOnTenantIdAndEventId()) {
      let customFields = columns
        .filter((item) => item.isRequired === true && item.isEditable === true)
        .map((x) => {
          return {
            field: x.field,
            title: x.title,
          };
        });
      newData
        .filter((x) => x.inItemEdit === true)
        .map((item) => {
          const { rowId } = item;
          let eventErrorObj = {
            procedure_code: "",
          };
          let keys = Object.keys(item);
          let value = keys.filter((a1) =>
            customFields.some((a2) => a2.field === a1)
          );

          if (value.length > 0) {
            value.map((vItem) => {
              let value = item[vItem].trim();
              if (value === "") {
                let findLabel = customFields.find((x) => x.field === vItem);
                let label = "";
                if (findLabel) {
                  label = toCamelCase(findLabel.title);
                }
                isValid = false;
                eventErrorObj[vItem] = `${label} is required`;
              }
              return 0;
            });
          }
          let obj = {};
          for (const [key, value] of Object.entries(eventErrorObj)) {
            if (`${value}` !== "") {
              obj[`${key + rowId}`] = `${value}`;
            }
            item[`${key + rowId}`] = `${value}`;
          }

          if (Object.keys(obj).length) {
            setEventErrorObj(obj);
            for (const [, value] of Object.entries(obj)) {
              if (value !== "") {
                isValid = false;
              }
            }
          }
          return 0;
        });
    }
    setData([...newData]);
    return isValid;
  };

  const handleUpdate = async () => {
    let isValid = await handleEventValidation();
    if (isValid) {
      setLoadingStateSearch({ ...loading, updateColumn: true });
      let tempArray = [];
      let customFields = columns
        .filter((item) => item.alias === "t" && item.isEditable === true)
        .map((x) => {
          return x.field;
        });

      let editableFields = columns
        .filter((item) => item.isEditable === true)
        .map((x) => {
          return x.field;
        });
      data
        .filter((x) => x.inItemEdit === true)
        .map((item) => {
          let keys = Object.keys(item);
          let value = keys.filter((value) => customFields.includes(value));
          let tempFieldArray = [];
          if (value.length > 0) {
            value.map((vItem) => {
              let colName = `t.` + vItem;
              let value = ` ${item[vItem].trim()}`;
              let exactString = generateExactString(colName, value);
              tempFieldArray.push(`${exactString}`);
              return 0;
            });
          }
          let obj = {
            in_user_id: cookiesData.out_user_id,
            in_tenant_id: cookiesData.out_tenant_id,
            in_event_mstr_key: item.event_mstr_key || "",
            in_approval_status_key: item.approval_status_mstr_key || "",
            in_approval_notes: item.approval_notes || "",
            in_comp_date: item.compensation_date
              ? formatDateOnly(item.compensation_date)
              : "",
            in_cpt_modifier1_key: item.cpt_modifier1_key || "",
            in_cpt_modifier2_key: item.cpt_modifier2_key || "",
            in_other_modifier: item.other_modifier || "",
            in_procedure_code: item.procedure_code || "",
            in_procedure_units: item.procedure_units || "",
            in_custom_field_xml: "",
          };
          if (eventsTypeFields.eventTypeValue.id === EVENT_TYPE_ID) {
            obj["in_custom_field_xml"] =
              `<cfd>${tempFieldArray.join("")}</cfd>` || "";
          }

          editableFields.map((eItem) => {
            if (item[eItem.field]) {
              obj[eItem.field] = item[eItem.field];
            }
            return 0;
          });

          tempArray.push(obj);
          return 0;
        });
      let reqData = {
        UpdateEventsArray: tempArray,
      };
      if (tempArray.length > 0) {
        let newData = [...data];
        newData
          .filter((x) => x.inItemEdit === true)
          .map((item) => {
            if (
              item.approval_status_mstr_key &&
              approvalStatusData.length > 0
            ) {
              let tempObj = approvalStatusData.find(
                (x) => x.id === Number(item.approval_status_mstr_key)
              );
              if (tempObj) {
                item.approval_status_name = tempObj.text;
              }
            }
            return 0;
          });
        newData.map((item) => {
          item.inItemEdit = false;
          item.inEdit = undefined;
          return 0;
        });
        setData([...newData]);
        setEventErrorObj({});
        let res = await Action.updateEventAsync(reqData);
        if (res) {
          apiCallData.in_filter = getInFilter();
          apiCallData.in_sort_number = getInSortNumber();
          apiCallData.in_sort_order = getInSortOrder();
          apiCallData.in_page_row_offset = 0;
          apiCallData.in_page_rows = getPageTake();
          apiCallData.in_event_type_mstr_key =
            searchStateFields.in_event_type_mstr_key;
          apiCallData.in_period_start_date =
            searchStateFields.in_period_start_date
              ? convertDate(searchStateFields.in_period_start_date)
              : "";
          apiCallData.in_period_end_date = searchStateFields.in_period_end_date
            ? convertDate(searchStateFields.in_period_end_date)
            : "";
          apiCallData.in_position_filter = searchStateFields.in_position_filter;
          apiCallData.in_comp_plan_filter =
            searchStateFields.in_comp_plan_filter;
          apiCallData.in_data_source_filter =
            searchStateFields.in_data_source_filter;
          apiCallData.in_service_location =
            searchStateFields.in_service_location;
          await GetListAsync(apiCallData);
          changeUpdateBtnState("disable", "grid-incell-update-event");
          changeCancelBtnState("disable", "grid-incell-cancel-event");
        } else {
          setLoadingStateSearch({ ...loading, updateColumn: false });
        }
      }
    } else {
      setEventErrorObj({ ...eventErrorObj });
    }
  };

  if (document.getElementById("grid-incell-cancel-event")) {
    document.getElementById("grid-incell-cancel-event").onclick = function (e) {
      originalData.map((item) => {
        item.inEdit = false;
        item.selected = false;
        return 0;
      });
      setData(originalData);
      //disable in-cell update and cancel button
      e.target.disableKendoBtn();
      changeUpdateBtnState("disable", "grid-incell-update-event");
      changeCancelBtnState("disable", "grid-incell-cancel-event");
    };
  }

  if (document.getElementById("grid-incell-update-event")) {
    document.getElementById("grid-incell-update-event").onclick = function (e) {
      handleUpdate();
    };
  }

  const getApprovalStatusData = async () => {
    let reqData = {
      in_filter: null,
      in_sort_number: 1,
      in_sort_order: "DESC",
      in_page_row_offset: 0,
      in_page_rows: 9999,
    };
    let res = await getApprovalStatus(reqData, dispatch, history);
    if (res) {
      let tempArray = [];
      res.map((item) => {
        let obj = {};
        obj.text = item.approval_status_name;
        obj.id = item.approval_status_mstr_key;
        tempArray.push(obj);

        return 0;
      });
      setApprovalStatusData(tempArray);
    }
  };

  const memoizedData = useMemo(
    () =>
      data.map((item) => ({
        ...item,
        [SELECTED_FIELD]: selectedState[idGetter(item)],
      })),
    [data, selectedState]
  );

  const getDisableSearchBtn = () => {
    let loadingState =
      comPlanValueFields.isComPlanValueDataLoading ||
      positionFields.isPositionDataLoading ||
      serviceLocationFields.isServiceLocationDataLoading ||
      eventDataSourceFields.isEventDataSourceDataLoading;

    let dataState =
      !eventDataSourceList.length &&
      !serviceLocationFields.serviceLocationDataList.length &&
      !positionFields.positionDataList.length &&
      !comPlanValueFields.compPlanDataList.length;

    let value = loadingState || dataState;

    return value;
  };

  return (
    <>
      <div className="Main eventspage event-drp">
        {loading.isAPICalling || loading.updateColumn ? (
          <LoadingPanel />
        ) : (
          false
        )}

        <div className="content">
          <div className="content-head eventhead">
            <HeaderPage pageProps={pageProps} headerConfig={headerConfig} />
          </div>
          <div className="content-body eventscont">
            <ul className="Filter-links">
              <div
                className={"k-form-field-wrap-popup valueDropdown width20em"}
              >
                <Label>{"Period Start Date * : "}</Label>
                <DatePicker
                  value={eventFields.in_period_start_date}
                  name="in_period_start_date"
                  format="yyyy-MM-dd"
                  onChange={(e) => {
                    handleDatePickerChange(e);
                  }}
                  formatPlaceholder={{
                    year: "yyyy",
                    month: "mm",
                    day: "dd",
                  }}
                  placeholder="yyyy-mm-dd"
                />
                <ShowError name={"in_period_start_date"} />
              </div>
              <div
                className={"k-form-field-wrap-popup valueDropdown width20em"}
              >
                <Label>{"Period End Date * : "}</Label>
                <DatePicker
                  value={eventFields.in_period_end_date}
                  name="in_period_end_date"
                  format="yyyy-MM-dd"
                  onChange={(e) => {
                    handleEndDateChange(e);
                  }}
                  formatPlaceholder={{
                    year: "yyyy",
                    month: "mm",
                    day: "dd",
                  }}
                  placeholder="yyyy-mm-dd"
                />
                <ShowError name={"in_period_end_date"} />
              </div>
              <div
                className={"k-form-field-wrap-popup widtheventype"}
                ref={divRef}
              >
                <Label>{"Event Type:"}</Label>
                <CustomDropdown
                  name={"eventTypeValue"}
                  data={eventsTypeFields.eventTypeData}
                  textField="text"
                  onOpen={(dataValue) => {
                    setEventTypeFields({
                      ...eventsTypeFields,
                      eventTypeData: eventTypeList,
                    });
                  }}
                  dataItemKey="id"
                  valueData={eventTypeList.find(
                    (c) => c.text === eventsTypeFields.eventTypeValue.text
                  )}
                  onChange={(e, name) => {
                    setEventTypeFields({
                      ...eventsTypeFields,
                      [name]: e,
                    });
                    setEventDataSourceFields({
                      ...eventDataSourceFields,
                      eventDataSourceValue: [
                        {
                          text: "Select All",
                          id: "Select All",
                        },
                      ],
                    });
                    let req = {
                      in_tenant_id: cookiesData.out_tenant_id,
                      in_filter: null,
                      in_period_start_date: eventFields.in_period_start_date
                        ? convertDate(eventFields.in_period_start_date)
                        : "",
                      in_period_end_date: eventFields.in_period_end_date
                        ? convertDate(eventFields.in_period_end_date)
                        : "",
                      in_event_type_mstr_key: e.id,
                      in_sort_number: 1,
                      in_sort_order: "ASC",
                    };
                    getEventDataSourceDataList(req);
                  }}
                  filterable={true}
                  onFilterChange={(dataValue) => {
                    setEventTypeFields({
                      ...eventsTypeFields,
                      eventTypeData: dataValue,
                    });
                  }}
                  autoClose={false}
                  autoBind={true}
                  isSelectALl={true}
                  dataList={eventTypeList}
                  loading={eventsTypeFields.isEventTypeDataLoading}
                />
              </div>
              <div
                className={"k-form-field-wrap-popup valueDropdown"}
                ref={divRef}
              >
                <Label>{"Event Data Source:"}</Label>
                <MultiSelectDropdown
                  name={"eventDataSourceValue"}
                  module="eventDataSourceValue"
                  data={eventDataSourceFields.eventDataSourceData}
                  textField="text"
                  onOpen={(data) => {
                    setEventDataSourceFields({
                      ...eventDataSourceFields,
                      eventDataSourceData: data,
                    });
                  }}
                  dataItemKey="id"
                  valueData={eventDataSourceFields.eventDataSourceValue}
                  onChange={(e, name) => {
                    setEventDataSourceFields({
                      ...eventDataSourceFields,
                      [name]: e,
                      // eventDataSourceData: eventDataSourceList
                    });
                    setErrorObj({ ...errorObj, [name]: "" });

                    let locationReq = {
                      in_tenant_id: cookiesData.out_tenant_id,
                      in_filter: null,
                      in_period_start_date: eventFields.in_period_start_date
                        ? convertDate(eventFields.in_period_start_date)
                        : "",
                      in_period_end_date: eventFields.in_period_end_date
                        ? convertDate(eventFields.in_period_end_date)
                        : "",
                      in_event_type_mstr_key:
                        eventsTypeFields.eventTypeValue.id,
                      in_data_source_filter: inSourceFilterValue(e),
                      in_sort_number: 1,
                      in_sort_order: "ASC",
                    };
                    GetEventServiceLocationDataList(locationReq);
                  }}
                  filterable={true}
                  onFilterChange={(data) => {
                    setEventDataSourceFields({
                      ...eventDataSourceFields,
                      eventDataSourceData: data,
                    });
                  }}
                  autoClose={false}
                  autoBind={true}
                  isSelectALl={true}
                  dataList={eventDataSourceList}
                  loading={eventDataSourceFields.isEventDataSourceDataLoading}
                  disabled={eventDataSourceFields.isEventDataSourceDataLoading}
                  appendTo={divRef.current}
                />
              </div>
              <div
                className={"k-form-field-wrap-popup valueDropdown"}
                ref={divRef}
              >
                <Label>{"Service Location:"}</Label>
                <MultiSelectDropdown
                  name={"serviceLocationValue"}
                  module="serviceLocationValue"
                  data={serviceLocationFields.serviceLocationData}
                  textField="text"
                  onOpen={(data) => {
                    setServiceLocationFields({
                      ...serviceLocationFields,
                      serviceLocationData: data,
                    });
                  }}
                  dataItemKey="id"
                  valueData={serviceLocationFields.serviceLocationValue}
                  onChange={(data, name) => {
                    setServiceLocationFields({
                      ...serviceLocationFields,
                      [name]: data,
                      //  serviceLocationData: serviceLocationFields.serviceLocationDataList
                    });
                    setErrorObj({ ...errorObj, [name]: "" });
                    let positionReq = {
                      in_tenant_id: cookiesData.out_tenant_id,
                      in_filter: null,
                      in_period_start_date: eventFields.in_period_start_date
                        ? convertDate(eventFields.in_period_start_date)
                        : "",
                      in_period_end_date: eventFields.in_period_end_date
                        ? convertDate(eventFields.in_period_end_date)
                        : "",
                      in_event_type_mstr_key:
                        eventsTypeFields.eventTypeValue.id,
                      in_data_source_filter: inSourceFilterValue(
                        eventDataSourceFields.eventDataSourceValue
                      ),
                      in_data_source_loc_filter: inSourceFilterValue(data),
                      in_sort_number: 1,
                      in_sort_order: "ASC",
                    };
                    GetPositionDataList(positionReq);
                  }}
                  filterable={true}
                  onFilterChange={(data) => {
                    setServiceLocationFields({
                      ...serviceLocationFields,
                      serviceLocationData: data,
                    });
                  }}
                  autoClose={false}
                  autoBind={true}
                  isSelectALl={true}
                  dataList={serviceLocationFields.serviceLocationDataList}
                  loading={serviceLocationFields.isServiceLocationDataLoading}
                  disabled={serviceLocationFields.isServiceLocationDataLoading}
                  //  appendTo={divRef.current}
                />
              </div>
              <div
                className={"k-form-field-wrap-popup valueDropdown"}
                ref={divRef}
              >
                <Label>{"Position:"}</Label>
                <MultiSelectDropdown
                  name={"positionValue"}
                  module="positionValue"
                  data={positionFields.positionData}
                  textField="text"
                  onOpen={(data) => {
                    setPositionFields({
                      ...positionFields,
                      positionData: data,
                    });
                  }}
                  dataItemKey="id"
                  valueData={positionFields.positionValue}
                  onChange={(data, name) => {
                    setPositionFields({
                      ...positionFields,
                      [name]: data,
                      // positionData: positionFields.positionDataList
                    });
                    setErrorObj({ ...errorObj, [name]: "" });
                  }}
                  filterable={true}
                  onFilterChange={(data) => {
                    setPositionFields({
                      ...positionFields,
                      positionData: data,
                    });
                  }}
                  autoClose={false}
                  autoBind={true}
                  isSelectALl={true}
                  dataList={positionFields.positionDataList}
                  loading={positionFields.isPositionDataLoading}
                  disabled={positionFields.isPositionDataLoading}
                  // appendTo={divRef.current}
                />
              </div>
              <div
                className={"k-form-field-wrap-popup valueDropdown"}
                ref={divRef}
              >
                <Label>{"Comp Plan:"}</Label>
                <MultiSelectDropdown
                  name={"complanValue"}
                  module="complanValue"
                  data={comPlanValueFields.complanValueData}
                  textField="text"
                  onOpen={(data) => {
                    setComPlanValueFields({
                      ...comPlanValueFields,
                      complanValueData: data,
                    });
                  }}
                  dataItemKey="id"
                  valueData={comPlanValueFields.complanValue}
                  onChange={(data, name) => {
                    setComPlanValueFields({
                      ...comPlanValueFields,
                      [name]: data,
                      // complanValueData: comPlanValueFields.compPlanDataList
                    });
                    setErrorObj({ ...errorObj, [name]: "" });
                  }}
                  filterable={true}
                  onFilterChange={(data) => {
                    setComPlanValueFields({
                      ...comPlanValueFields,
                      complanValueData: data,
                    });
                  }}
                  autoClose={false}
                  autoBind={true}
                  isSelectALl={true}
                  dataList={comPlanValueFields.compPlanDataList}
                  loading={comPlanValueFields.isComPlanValueDataLoading}
                  disabled={comPlanValueFields.isComPlanValueDataLoading}
                  appendTo={divRef.current}
                />
              </div>

              <Button
                primary={true}
                className="searchbtn"
                disabled={getDisableSearchBtn()}
                type={"submit"}
                onClick={(e) => {
                  onEventClick(history.location.pathname);
                }}
              >
                <i className="fas fa-search"></i>
              </Button>
            </ul>
            <div className="dtu-file-container padtop15">
              {loading.isSearchLoader ? <LoadingPanel /> : false}
              {!loading.isSearchLoader && columns.length > 0 && (
                <CompData
                  isAPICalling={isAPICalling}
                  initialLoading={initialLoading}
                  data={memoizedData}
                  id="event-grid"
                  pageData={pageData}
                  dataCount={dataCount}
                  customColumn={CustomColumn}
                  columns={gridColumns}
                  dataItemKey={DATA_ITEM_KEY}
                  width={"auto"}
                  module={"event"}
                  defaultPageSize={defaultPageSize}
                  pageSize={pageSize}
                  reorderable={pageProps.writeAccess === 0 ? false : true}
                  initialFilter={filterField}
                  initialSort={sortField}
                  resetSort={resetSort}
                  isSelection={isSelection}
                  columnReorderData={columnReorderData}
                  cellRender={customCellRender}
                  rowRender={customRowRender}
                  itemChange={itemChange}
                  setPageSize={setPageSize}
                  handleColumnMenu={handleColumnMenu}
                  handlePageChange={handlePageChange}
                  refreshClick={onRefreshClick}
                  onClearFilter={onClearFilter}
                  onSelectionChange={onSelectionChange}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {isShow.dtuLogs && (
        <DtuBatchLogs
          visible={isShow}
          setShow={setShow}
          module={"event"}
          filter={eventsTypeFields.eventTypeValue.id}
          pageProps={pageProps}
        />
      )}
      {isShow.isResult && (
        <ManageEventType
          visible={isShow.isResult}
          setShow={setShow}
          pageProps={pageProps}
          setNewlyAddedEventType={setNewlyAddedEventType}
        />
      )}
      {isShow.isManageCustomType && (
        <ManageCustomField
          visible={isShow.isManageCustomType}
          isShow={isShow}
          setShow={setShow}
          pageProps={pageProps}
        />
      )}
      <DialogComponent
        title={confirmationPopup.title}
        onCancelConfirm={onCancelConfirm}
        width={"450"}
        height={"auto"}
        className={`Dialog-Delete ${
          confirmationPopup.action === "delete-by-batch"
            ? "event-dialog-batch"
            : ""
        }`}
        desc={confirmationPopup.desc}
        visible={confirmationPopup.isVisible}
        actionBtn={confirmationPopup.actionBtn}
        titleClassName={"delete-confirm"}
        isCustomDesc={confirmationPopup.action === "delete-by-batch"}
        customComponent={
          <MultiSelectDropdown
            name={"batchValue"}
            style={{
              width: 500,
            }}
            data={batchFields.batchData}
            textField="text"
            dataItemKey="id"
            valueData={batchFields.batchValue}
            onOpen={(dataValue) => {
              setBatchFields({
                ...batchFields,
                batchData: dataValue,
              });
            }}
            onChange={(e, name) => {
              setBatchFields({
                ...batchFields,
                [name]: e,
                batchData: batchList,
              });
            }}
            onFilterChange={(dataValue) => {
              setBatchFields({
                ...batchFields,
                batchData: dataValue,
              });
            }}
            filterable={true}
            autoClose={false}
            autoBind={true}
            dataList={batchList}
            loading={batchFields.isBatchDataLoading}
            placeholder={"Select Batch"}
          />
        }
      />
      {isDeleteByBatch && (
        <Dialog
          title={"Delete All Events in Period"}
          id="dialog-timeout"
          width={550}
        >
          <p
            style={{
              margin: "25px",
              textAlign: "center",
            }}
          >
            You are about to the delete events which will reset any result data
            that is associated with these events. Do you want to continue with
            this deletion?{" "}
          </p>
          <DialogActionsBar layout="center">
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={onDeleteBatchConfirm}
              disabled={batchFields.batchValue.length > 0 ? false : true}
            >
              Yes
            </button>
            <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base">
              Cancel
            </button>
          </DialogActionsBar>{" "}
        </Dialog>
      )}
      {isShow.dtuUpload && (
        <UploadDtu
          visible={isShow}
          setShow={setShow}
          title={"Upload Events"}
          pageId={"events"}
          isShowEventType={true}
          setAPICall={onRefreshManualUploadClick}
        />
      )}
    </>
  );
};

export default React.memo(Events);

export const GridButton = (restProps) => {
  return (
    <>
      <Button type="submit" className="cancelbtn" id="grid-incell-cancel-event">
        Cancel
      </Button>
      <Button
        type="submit"
        className="primarybtn"
        id="grid-incell-update-event"
      >
        Save
      </Button>
    </>
  );
};
