import * as React from "react";
import { useCallback } from "react";

export const CellRender = (props) => {
    const dataItem = props.originalProps.dataItem;
    const cellField = props.originalProps.field;
    const inEditField = dataItem[props.editField || ""];
    const objCancelBtn = props.cancelActBtn
        ? document.getElementById(props.cancelActBtn)
        : null;
    const objUpdateBtn = props.updateActBtn
        ? document.getElementById(props.updateActBtn)
        : null;

    const additionalProps =
        cellField && cellField === inEditField
            ? {
                  ref: (td) => {
                      let input = null;
                      if (td) {
                          input = td.querySelector("input");

                          if (input === null) {
                              input = td.querySelector("select");
                          }
                          if (input === null) {
                              input = td.querySelector("textarea");
                          }
                      }

                      if (!input) {
                          return;
                      }

                      objCancelBtn && objCancelBtn.enableKendoBtn();
                  },
                  onChange: () => {
                      objUpdateBtn && objUpdateBtn.enableKendoBtn();
                      window.$_UNSAVED_CHANGES_FLAG = true;
                  },
                  onKeyDown: (e) => {
                      if (e.keyCode === 27) {
                          setTimeout(function () {
                              objCancelBtn.click();
                          });
                      }
                  },
                  title: props?.title || "",
              }
            : {
                  onDoubleClick: (e) => {
                      if (!props.enterEdit) {
                          return false;
                      }

                      props.enterEdit(dataItem, cellField);
                      const td = e.target.closest("td");
                      setTimeout(function () {
                          let input = td.querySelector("input");

                          if (input === null) {
                              input = td.querySelector("select");
                          }

                          if (input !== null) {
                              input.focus();
                          }
                      });
                  },
                  onClick: (e) => {
                      props?.onRowClick &&
                          props?.onRowClick(dataItem, e, cellField);
                  },
                  /*
                  onMouseUp: (e) => {
                      if (
                          e.target
                              .closest("tr")
                              .classList.contains("k-state-selected")
                      ) {
                          props?.onRowClick &&
                              props?.onRowClick(dataItem, e, cellField);
                      }
                  },
                  */
                  onFocus: (e) => {
                      if (inEditField) {
                          props.enterEdit(dataItem, cellField);
                          const td = e.target.closest("td");
                         // setTimeout(function () {
                              let input = td.querySelector("input");

                              if (input === null) {
                                  input = td.querySelector("select");
                              }

                              if (input !== null) {
                                  input.focus();
                              }
                          //});
                      }
                  },
                  tabIndex: "0",
                  title: props?.title || "",
              };

    const clonedProps = { ...props.td.props, ...additionalProps };
    return React.cloneElement(props.td, clonedProps, props.td.props.children);
};
export const RowRender = (props) => {
    const trProps = {
        ...props.tr.props,
        title: props?.title || "",
        onBlur: (e) => {
            props?.exitEdit &&
                props?.exitEdit(
                    props.originalProps.dataItem,
                    props.tr.props["data-grid-row-index"],
                    props.originalProps.dataIndex,
                    e
                );
        },
        onFocus: (e) => {},
        onDoubleClick: (e) => {
            if (props?.onRowDoubleClick) {
                props.onRowDoubleClick(props);
            }
        },
        onClick: (e) => {
            if (props?.onRowClick) {
                props.onRowClick(props, e);
            }
        },
    };

    return React.cloneElement(
        props.tr,
        { ...trProps },
        props.tr.props.children
    );
};

export const EventRowRender = React.memo((props) => {
    // Memoized onBlur handler
    const handleBlur = useCallback(
        (e) => {
            if (props.exitEdit) {
                props.exitEdit(
                    props.originalProps.dataItem,
                    props.tr.props["data-grid-row-index"],
                    props.originalProps.dataIndex,
                    e
                );
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [
            props.exitEdit,
            props.originalProps.dataItem,
            props.tr.props,
            props.originalProps.dataIndex,
        ]
    );

    // Memoized onClick handler
    const handleClick = useCallback(
        (e) => {
            if (props.onRowClick) {
                props.onRowClick(props, e);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [props.onRowClick, props]
    );

    // Create new trProps object
    const trProps = {
        ...props.tr.props,
        onClick: handleClick,
    };

    if (props.exitEdit) {
        trProps.onBlur = handleBlur;
    }

    return React.cloneElement(
        props.tr,
        { ...trProps },
        props.tr.props.children
    );
});

export const ProviderRowRender = React.memo((props) => {
    // Memoized onBlur handler
    const handleBlur = useCallback(
        (e) => {
            if (props.exitEdit) {
                props.exitEdit(
                    props.originalProps.dataItem,
                    props.tr.props["data-grid-row-index"],
                    props.originalProps.dataIndex,
                    e
                );
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [
            props.exitEdit,
            props.originalProps.dataItem,
            props.tr.props,
            props.originalProps.dataIndex,
        ]
    );

    // Memoized onDoubleClick handler
    const handleDoubleClick = useCallback(
        (e) => {
            if (props.onRowDoubleClick) {
                props.onRowDoubleClick(props);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [props.onRowDoubleClick, props]
    );

    // Memoized onClick handler
    const handleClick = useCallback(
        (e) => {
            if (props.onRowClick) {
                props.onRowClick(props, e);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [props.onRowClick, props]
    );

    // Create new trProps object
    const trProps = {
        ...props.tr.props,
        title: props.title || "",
        onFocus: () => {},
        onDoubleClick: handleDoubleClick,
        onClick: handleClick,
    };

    if (props.exitEdit) {
        trProps.onBlur = handleBlur;
    }

    return React.cloneElement(
        props.tr,
        { ...trProps },
        props.tr.props.children
    );
});
