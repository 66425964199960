import { Button } from "@progress/kendo-react-buttons";
import { FieldWrapper, Form, FormElement } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import { Error, Label } from "@progress/kendo-react-labels";
import React from "react";
import Popup from "../../components/customPopup";
import { getUserCredential } from "../../lib/cookies";
import MessageSchema from "../../utils/messageSchema";
import { HeaderPage } from "../base/headerPage";
import CustomDropdown from "../../components/customDropdown";
import { RateTablesData } from "./rateTablesGrid";
import useServiceApi from "./service";
import "./rateTables.css";

const initialFormFields = {
    in_rate_table_name: "",
    in_rate_table_desc: "",
    in_period_mstr_key: "",
    in_rate_table_unittype: "",
};

const RateTables = (pageProps) => {
    const [list, Action] = useServiceApi();
    const cookiesData = getUserCredential();
    const addRef = React.useRef(null);
    const [isAddNewConfig, setNewConfig] = React.useState(false);
    const [isShow, setShow] = React.useState({
        sendEmails: false,
        deleteRows: false,
    });
    const [exportDataClick, setExportDataClick] = React.useState(false);
    const [isAPICall, setAPICall] = React.useState(false);
    const [formFields, setFormFields] = React.useState({
        ...initialFormFields,
    });
    const [errorObj, setErrorObj] = React.useState({});
    const [isSaveClick, setSaveClick] = React.useState(false);
    const [selectedRowId, setSelectedRowId] = React.useState("");
    const [dataList, setDataList] = React.useState([]);

    const [columnsData, setColumnsData] = React.useState([]);
    const [isCheckColumn, setColumnCheck] = React.useState(false);

    const [addUnitTypeFields, setAddUnitTypeFields] = React.useState({
        unitTypeValue: {
            id: "",
            text: "",
        },
        unitTypeData: [],
    });
    const [screenLock, setScreenLock] = React.useState(false);

    const {
        loadingState,
        originPeriod,
        periodsFields,
        setPeriodFields,
        originPeriodType,
        periodsTypeFields,
        setPeriodTypeFields,
        unitTypeDataSource,
    } = list;

    React.useEffect(() => {
        Action.getUnitTypeData();
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (loadingState.isSaveData) {
            setFormFields({ ...initialFormFields });
            setAPICall(true);
            setNewConfig(false);
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingState.isSaveData]);

    React.useEffect(() => {
        if (list.loadingState.isSaveData) {
            setFormFields({ ...initialFormFields });
            setAPICall(true);
            setNewConfig(false);
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [list.loadingState.isSaveData]);

    const addNewConfig = (e) => {
        setNewConfig(!isAddNewConfig);
        Action.getPeriodTypesData();
        setAddUnitTypeFields({
            ...addUnitTypeFields,
            unitTypeData: unitTypeDataSource,
            unitTypeValue: unitTypeDataSource[0],
        });
        e.preventDefault();
    };

    const ShowError = React.useCallback(
        (props) => {
            return <Error>{errorObj[props.name]}</Error>;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [errorObj, isSaveClick]
    );

    const handleValidation = () => {
        let isValid = true;

        if (!periodsFields.periodsValue) {
            isValid = false;
            errorObj.in_period_mstr_key = "Please select effctive period";
        }
        if (periodsFields.periodsValue?.id === "") {
            if (formFields.in_period_mstr_key === "") {
                isValid = false;
                errorObj.in_period_mstr_key = "Please select effctive period";
            }
        } else {
            errorObj.in_period_mstr_key = "";
            isValid = true;
        }

        if (addUnitTypeFields.unitTypeValue.id === "") {
            if (formFields.in_rate_table_unittype === "") {
                isValid = false;
                errorObj.in_rate_table_unittype = "Please select unit type";
            }
        } else {
            errorObj.in_rate_table_unittype = "";
            isValid = true;
        }

        if (formFields.in_rate_table_name.trim() === "") {
            isValid = false;
            errorObj.in_rate_table_name = MessageSchema.rateTableRequired;
        }

        setErrorObj((preVState) => ({ ...preVState, errorObj }));

        return isValid;
    };

    const handleSubmit = () => {
        let valid = handleValidation();

        if (valid) {
            setErrorObj({});
            const bodyData = {
                in_user_id: cookiesData.out_user_id.toString(),
                in_tenant_id: cookiesData.out_tenant_id,
                in_rate_table_name: formFields.in_rate_table_name,
                in_rate_table_desc: formFields.in_rate_table_desc,
                in_period_mstr_key: periodsFields.periodsValue?.id,
                in_rate_table_unittype: addUnitTypeFields.unitTypeValue?.id,
            };
            Action.addDataAsync(bodyData);
        } else {
            setSaveClick(true);
        }
    };

    const onCancelClick = () => {
        setFormFields(initialFormFields);
        setErrorObj({});
        setNewConfig(false);
        setSaveClick(false);
    };

    const handleFieldChange = (e) => {
        const name = e.name ? e.name : e.target.name;
        if (e.value !== null) {
            if (e.value.text === "Regular") {
                setFormFields({
                    ...formFields,
                    [name]: e.value,
                });
                setErrorObj({ ...errorObj, [name]: "" });
            } else {
                setFormFields({ ...formFields, [name]: e.value });
                setErrorObj({ ...errorObj, [name]: "" });
            }
        }
    };

    const AddNewForm = () => {
        return (
            <Form
                onSubmit={handleSubmit}
                render={() => (
                    <FormElement className="emailForm" horizontal={true}>
                        <FieldWrapper>
                            <div className={"k-form-field-wrap-popup"}>
                                <Label>{"Rate Table Name*:"}</Label>
                                <Input
                                    type={"text"}
                                    name="in_rate_table_name"
                                    placeholder="Rate Table Name"
                                    value={formFields.in_rate_table_name}
                                    onChange={(e) => {
                                        handleFieldChange(e);
                                    }}
                                    autoFocus={true}
                                />
                                <ShowError
                                    name={"in_rate_table_name"}
                                ></ShowError>
                            </div>
                        </FieldWrapper>

                        <FieldWrapper>
                            <div className={"k-form-field-wrap-popup"}>
                                <Label>{"Description:"}</Label>
                                <Input
                                    type={"text"}
                                    name="in_rate_table_desc"
                                    placeholder="Description"
                                    value={formFields.in_rate_table_desc}
                                    onChange={(e) => {
                                        handleFieldChange(e);
                                    }}
                                />
                                <ShowError
                                    name={"in_rate_table_desc"}
                                ></ShowError>
                            </div>
                        </FieldWrapper>

                        <FieldWrapper>
                            <div className={"k-form-field-wrap-popup"}>
                                <Label>{"Period Type* : "}</Label>
                                <CustomDropdown
                                    name={"periodTypeValue"}
                                    data={periodsTypeFields.periodTypeData}
                                    textField="text"
                                    dataItemKey="id"
                                    valueData={originPeriodType.find(
                                        (c) =>
                                            c.text ===
                                            periodsTypeFields.periodTypeValue
                                                .text
                                    )}
                                    onChange={(e, name) => {
                                        setPeriodTypeFields({
                                            ...periodsTypeFields,
                                            [name]: e,
                                        });
                                        Action.getPeriodsData(e.id, e);
                                    }}
                                    onOpen={(datavalue) => {
                                        setPeriodTypeFields({
                                            ...periodsTypeFields,
                                            periodTypeData: originPeriodType,
                                        });
                                    }}
                                    onFilterChange={(dataValue) => {
                                        setPeriodTypeFields({
                                            ...periodsTypeFields,
                                            periodTypeData: dataValue,
                                        });
                                    }}
                                    filterable={true}
                                    autoClose={false}
                                    autoBind={true}
                                    dataList={originPeriodType}
                                    loading={
                                        periodsTypeFields.isPeriodTypeDataLoading
                                    }
                                />
                            </div>
                        </FieldWrapper>
                        <FieldWrapper>
                            <div className={"k-form-field-wrap-popup"}>
                                <Label>{"Effective Period* : "}</Label>
                                <CustomDropdown
                                    name={"periodsValue"}
                                    data={periodsFields.periodsData}
                                    textField="text"
                                    dataItemKey="id"
                                    valueData={originPeriod.find(
                                        (c) =>
                                            c.text ===
                                            periodsFields.periodsValue.text
                                    )}
                                    onOpen={(datavalue) => {
                                        setPeriodFields({
                                            ...periodsFields,
                                            periodsData: originPeriod,
                                        });
                                    }}
                                    onChange={(e, name) => {
                                        setPeriodFields({
                                            ...periodsFields,
                                            [name]: e,
                                        });
                                    }}
                                    filterable={true}
                                    onFilterChange={(dataValue) => {
                                        setPeriodFields({
                                            ...periodsFields,
                                            periodsData: dataValue,
                                        });
                                    }}
                                    autoClose={false}
                                    autoBind={true}
                                    isSelectALl={true}
                                    dataList={originPeriod}
                                    loading={periodsFields.isPeriodDataLoading}
                                />
                                <ShowError
                                    name={"in_period_mstr_key"}
                                ></ShowError>
                            </div>
                        </FieldWrapper>

                        <FieldWrapper>
                            <div className={"k-form-field-wrap-popup"}>
                                <Label>{"Unit Type* : "}</Label>
                                <CustomDropdown
                                    name={"unitTypeValue"}
                                    data={addUnitTypeFields.unitTypeData}
                                    textField="text"
                                    dataItemKey="id"
                                    onOpen={(dataValue) => {
                                        setAddUnitTypeFields({
                                            ...addUnitTypeFields,
                                            unitTypeData: unitTypeDataSource,
                                        });
                                    }}
                                    valueData={unitTypeDataSource.find(
                                        (c) =>
                                            c.id ===
                                            addUnitTypeFields.unitTypeValue.id
                                    )}
                                    onChange={(e, name) => {
                                        setAddUnitTypeFields({
                                            ...addUnitTypeFields,
                                            [name]: e,
                                        });
                                    }}
                                    onFilterChange={(dataValue) => {
                                        setAddUnitTypeFields({
                                            ...addUnitTypeFields,
                                            unitTypeData: dataValue,
                                        });
                                    }}
                                    filterable={true}
                                    autoClose={false}
                                    autoBind={true}
                                    dataList={unitTypeDataSource}
                                />
                                <ShowError
                                    name={"in_rate_table_unittype"}
                                ></ShowError>
                            </div>
                        </FieldWrapper>
                    </FormElement>
                )}
            />
        );
    };

    const AddNewPopup = () => {
        return (
            <Popup
                anchor={addRef.current}
                show={isAddNewConfig}
                className="right0"
            >
                <div className="dot-dropdown-open dot-dropdown-open-Form">
                    <div className="caption">
                        <span>Add New Rate Table</span>
                    </div>

                    {AddNewForm()}

                    <div className="dropdownFooter">
                        <div className="k-form-buttons">
                            <Button
                                primary={true}
                                type="submit"
                                onClick={() => {
                                    onCancelClick();
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                primary={true}
                                type="submit"
                                onClick={() => {
                                    handleSubmit(formFields, errorObj);
                                }}
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                </div>
            </Popup>
        );
    };

    const AddNewLink = () => {
        return (
            <>
                <a
                    href="/#"
                    className="cta-small"
                    ref={addRef}
                    onClick={(e) => {
                        addNewConfig(e);
                    }}
                >
                    <i className="fas fa-plus"></i> Add New Rate Table
                </a>

                {AddNewPopup()}
            </>
        );
    };

    const handleMenuItemClick = (action) => {
        switch (action) {
            case "export":
                setExportDataClick(true);
                break;
            case "delete":
                setShow({
                    ...isShow,
                    deleteRows: !isShow.deleteRows,
                });
                break;
            default:
                break;
        }
    };

    const headerConfig = {
        title: "Rate Tables",
        id: "rate-tables",
        add_new_fx: AddNewLink,
        action_menu: {
            export: { fx: handleMenuItemClick },
            delete: { fx: handleMenuItemClick },
        },
        manage_col: {
            columnsData: columnsData,
            setColumnsData: setColumnsData,
            setColumnCheck: setColumnCheck,
        },
    };

    const parent = document.querySelector("#content-" + headerConfig.id);

    if (parent) {
        const exportToExcel = parent.querySelector(".export-to-excel");
        const auditLog = parent.querySelector(".audit-log");
        const deleteRows = parent.querySelector(".delete-rows");

        exportToExcel && exportToExcel.disableKendoMenu();
        auditLog && auditLog.disableKendoMenu();
        deleteRows && deleteRows.disableKendoMenu();

        dataList.length && exportToExcel && exportToExcel.enableKendoMenu();

        if (selectedRowId !== "") {
            auditLog && auditLog.enableKendoMenu();
            deleteRows && deleteRows.enableKendoMenu();
        }
    }

    return (
        <div className="Main">
            <div className="content">
                {screenLock ? <div className="k-loading-mask"></div> : false}
                <div className="content-head">
                    <HeaderPage
                        pageProps={pageProps}
                        headerConfig={headerConfig}
                    />
                </div>

                <div className="content-body">
                    <div className="dtu-file-container rowhoverdisable">
                        {unitTypeDataSource.length > 0 && (
                            <RateTablesData
                                isAPICall={isAPICall}
                                setAPICall={setAPICall}
                                setExportDataClick={setExportDataClick}
                                exportDataClick={exportDataClick}
                                selectedRowId={selectedRowId}
                                setSelectedRowId={setSelectedRowId}
                                setShow={setShow}
                                isShow={isShow}
                                setDataList={setDataList}
                                pageProps={pageProps}
                                columnsData={columnsData}
                                setColumnsData={setColumnsData}
                                setColumnCheck={setColumnCheck}
                                isCheckColumn={isCheckColumn}
                                unitTypeDataSource={unitTypeDataSource}
                                setScreenLock={setScreenLock}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(RateTables);
