import React, { useState, memo } from "react";
import ReactDOM from "react-dom";
import "../src/scss/reset.scss";
import App from "./App";
import { QueryClient, QueryClientProvider } from "react-query";
import { Subscribe } from "@react-rxjs/core";
import reportWebVitals from "./reportWebVitals";
import "@fortawesome/fontawesome-free/css/all.min.css";
// eslint-disable-next-line
import "../src/scss/main.scss";
import { enableMapSet } from "immer";

// redux
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { BrowserRouter as Router } from 'react-router-dom';

enableMapSet();


const AppProvider = memo(() => {
    const [queryClient] = useState(() => new QueryClient());
    return (
        <QueryClientProvider client={queryClient}>
            <Subscribe>
                <Provider store={store}>
                    <Router basename="/">
                        <App />
                    </Router>
                </Provider>
            </Subscribe>
        </QueryClientProvider>
    );
}, []);

ReactDOM.render(
    // <React.StrictMode>
    <AppProvider />,
    // </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
