export const chartTypeConfig = {
    line: {
        key: "line",
        name: "name",
    },
    column: {
        key: "column",
        name: "name",
    },
    pie: {
        key: "pie",
        name: "series_name",
    },
    bubble: {
        key: "bubble",
        name: "category",
    },
    area: {
        key: "area",
        name: "name",
    },
    bar: {
        key: "bar",
        name: "name",
    },
};

export const chartWrapperConfig = {
    chartConfig: {
        seriesDefaults: {
            type: "bar",
            stack: 0,
        },
        transitions: false,
    },
    chartLegend: {
        position: "bottom",
        align: "start",
        width: "100",
        orientation: "vertical",
        labels: {
            font: "08px Poppins, sans-serif",
        },
    },
    categoryAxisConfig: {
        visible: true,
        startAngle: 45,
        labels: {
            rotation: -45,
            position: "start",
        },
    },
};

export const chartThemeConfig = [
    {
        value: "blueopal",
        text: "Blue Opal",
        color: [
            "#0069A5",
            "#0098EE",
            "#7BD2F6",
            "#FFB800",
            "#FF8517",
            "#E34A00",
        ],
    },
    {
        value: "bootstrap",
        text: "Bootstrap",
        color: [
            "#428BCA",
            "#5BC0DE",
            "#5CB85C",
            "#F2B661",
            "#E67D4A",
            "#DA3B36",
        ],
    },
    {
        value: "default",
        text: "Default",
        color: [
            "#FF6800",
            "#A04300",
            "#FF8D00",
            "#678900",
            "#28b4c8",
            "#FFB53C",
            "#396000",
        ],
    },
    {
        value: "fiori",
        text: "Fiori",
        color: [
            "#008FD3",
            "#99D101",
            "#F39B02",
            "#F03862",
            "#C03C53",
            "#ACACAC",
        ],
    },
    {
        value: "flat",
        text: "Flat",
        color: [
            "#10C4B2",
            "#FF7663",
            "#FFB74F",
            "#00E959",
            "#1C9EC4",
            "#FF63A5",
            "#1CC47B",
        ],
    },
    {
        value: "metro",
        text: "Metro",
        color: [
            "#8EBC00",
            "#309B46",
            "#25A0DA",
            "#FF6900",
            "#E61E26",
            "#D80004",
            "#16ABA9",
            "#D851A1",
            "#313131",
            "#ED1691",
        ],
    },
    {
        value: "nova",
        text: "Nova",
        color: [
            "#FF4350",
            "#FF9EA5",
            "#00ACC1",
            "#80DEEA",
            "#FFBF46",
            "#FFD78C",
        ],
    },
    {
        value: "office365",
        text: "Office 365",
        color: [
            "#0072C6",
            "#5DB2FF",
            "#008A17",
            "#82BA00",
            "#FF8F32",
            "#AC193D",
        ],
    },
    {
        value: "silver",
        text: "Silver",
        color: [
            "#007BC3",
            "#76B800",
            "#FFAE00",
            "#EF4C00",
            "#A419BA",
            "#430B62",
        ],
        background: "#eee",
    },
    {
        value: "uniform",
        text: "Uniform",
        color: [
            "#527AA3",
            "#6F91B3",
            "#8CA7C2",
            "#A8BDD1",
            "#C5D3E0",
            "#E2E9F0",
        ],
    },
];
