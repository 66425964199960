import React from "react";
import {
  removeUserCredential,
  removeOtpData,
  getUserCredential,
  getTenantCookieData,
  removeLocalStorage,
  clearLocalStorage,
} from "../lib/cookies";

import { useHistory } from "react-router";
import { useSelector, shallowEqual } from "react-redux";
import { unSaveChangesPopupSelector } from "../redux/selectors/custom-selector";
import { useDispatch } from "react-redux";
import {
  CONFIRMATION_BTN_ACTIONS,
  CUSTOMER_SUCCESS_AND_SUCCESS,
  RELEASE_NOTE,
} from "../constants/constants";
import useApi from "../pages/JobExeucution/service";
import adminUserApi from "../pages/adminUser/service";
import { DEFAULT_SORT } from "../constants/grid";
import { socket } from "../App";
import { setProgressStatus } from "../redux/actions/jobexecution-actions";
import { setUnSavedChangesPopup } from "src/redux/actions/setting-actions";
import { CreateAuthCookie } from "src/utils/loginUtils";
import { ProCareLogo } from "src/pages/base/headerPage";
import { DialogComponent } from "./dialog";

const Header = () => {
  const cookiesData = getUserCredential();
  const defaultPageSize = getTenantCookieData("record_per_page");

  const [list, Action] = useApi();
  const [, ActionAdminUser] = adminUserApi();

  const { setConfirmationPopup, confirmationPopup } = list;

  const fullName =
    cookiesData.out_user_first_name + " " + cookiesData.out_user_last_name;
  const originFullName =
    cookiesData &&
    cookiesData.out_origin_user_first_name +
      " " +
      cookiesData.out_origin_user_last_name;
  const role = cookiesData && cookiesData.out_db_role_type_name;
  const out_origin_user_name = cookiesData && cookiesData.out_origin_user_name;
  const loggedInUserEmail = cookiesData.loggedInUserEmail
    ? cookiesData.loggedInUserEmail
    : "";
  const username = cookiesData?.out_user_name;
  const history = useHistory();
  const { unsavedPopup } = useSelector(
    unSaveChangesPopupSelector,
    shallowEqual
  );

  const dispatch = useDispatch();

  const onLogoutClick = () => {
    removeLocalStorage("resultData");
    removeUserCredential();
    removeOtpData();
    // window.location.href = "/authentication";
    history.push("/authentication");
  };

  const onImpersonateClick = async () => {
    const bodyData = {};
    setConfirmationPopup({
      ...confirmationPopup,
      isVisible: false,
      type: "",
      actionBtn: [],
      data: {},
      action: "",
    });
    const response = await ActionAdminUser.impersonateAsync(bodyData);

    if (response?.out_secret_token) {
      //clear cookie
      //removeUserCredential();

      //set new cookie
      CreateAuthCookie(response);

      //redirection
      switch (response.out_credential) {
        case "MFA_ACCESS":
          window.location.href = "/otp";
          break;
        case "CHANGE_PWD_ACCESS":
          window.location.href = "/change-password";
          break;
        case "AUTH_ACCESS":
          window.location.href = "/users";
          break;
        default:
          window.location.href = "/users";
          break;
      }
    }
  };

  const statusFilterString =
    'AND ((a.job_status_key IN (4)) OR (a.job_status_key = 3 AND a.comment IS NOT NULL AND a.comment <> ""))';
  const processApiCallData = {
    in_tenant_id: cookiesData.out_tenant_id,
    in_db_role_key: cookiesData?.out_db_role_key,
    in_filter: statusFilterString,
    in_sort_number: DEFAULT_SORT.number,
    in_sort_order: DEFAULT_SORT.order,
    in_page_row_offset: 0,
    in_page_rows: defaultPageSize.recordTake,
  };

  React.useEffect(() => {
    getProcessListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProcessListData = async () => {
    const fetchData = async () => {
      await Action.getProcessListDataAsync(processApiCallData);
    };
    fetchData();
  };

  React.useEffect(() => {
    let hasJobCommentRun = false;
    socket.on("JOB_STATUS_PROGRESS", (dataStatus) => {
      if (
        dataStatus.jobStatus !== null &&
        cookiesData.out_tenant_id == dataStatus.tenantId
      ) {
        var imageDiv = document.querySelector(".k-loading-imagegear");

        if (dataStatus.jobStatus === "Running") {
          if (imageDiv) {
            imageDiv.style.display = "block";
          }
        } else if (dataStatus.jobComment !== null && !hasJobCommentRun) {
          hasJobCommentRun = true;
          if (imageDiv) {
            imageDiv.style.display = "none";
          }
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const handleJobStatusPerProgress = async (dataPer) => {
      const roundedPercentage = Math.floor(dataPer.percentage);
      if (
        roundedPercentage === 100 &&
        cookiesData.out_tenant_id == dataPer.tenantId
      ) {
        var imageDiv = document.querySelector(".k-loading-imagegear");
        if (imageDiv) {
          imageDiv.style.display = "none";
        }
      }
    };

    socket.on("SUCCESS_PROGRESS_JOB_PER", handleJobStatusPerProgress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const handleJobStatusPerProgress = async (dataPer) => {
      const roundedPercentage = Math.floor(dataPer.processCtrlPer);
      if (
        roundedPercentage === 100 &&
        cookiesData.out_tenant_id == dataPer.tenantId
      ) {
        var imageDiv = document.querySelector(".k-loading-imagegear");
        if (imageDiv) {
          imageDiv.style.display = "none";
        }
      }
    };
    socket.on("SUCCESS_PROGRESS_REPORT", handleJobStatusPerProgress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (module) => {
    switch (module) {
      case "manage-profile":
        if (unsavedPopup.isUnSavedChange) {
          dispatch(
            setUnSavedChangesPopup({
              ...unsavedPopup,
              isVisible: true,
              callbackFn: () => {
                history.push("/manage-profile");
              },
            })
          );
        } else {
          history.push("/manage-profile");
        }
        break;
      case "release-note":
        window.open(RELEASE_NOTE, "_blank");
        break;
      case "customer-support":
        window.open(CUSTOMER_SUCCESS_AND_SUCCESS, "_blank");
        break;
      default:
        break;
    }
  };

  const headerRight = () => {
    if (cookiesData.out_credential !== "AUTH_ACCESS") {
      return "";
    }
    const checkMatch = (a, b) => {
      if (a.includes("|")) {
        const aParts = a.split("|");
        return aParts.includes(b);
      } else {
        return a === b;
      }
    };
    const outCssLinks = cookiesData.out_css_link;
    const outRoleType = cookiesData.out_db_role_type_abbr;
    const isMatch = checkMatch(outCssLinks, outRoleType);

    const onCancelConfirm = (action) => {
      setConfirmationPopup({
        ...confirmationPopup,
        isVisible: false,
        type: "",
        actionBtn: [],
        data: {},
        action: "",
      });
    };
    const BackToLogin = () => {
      setConfirmationPopup({
        ...confirmationPopup,
        isVisible: true,
        title: "Impersonate User",
        desc: "Do you want to return to the original user?",
        actionBtn: CONFIRMATION_BTN_ACTIONS(
          onCancelConfirm,
          onImpersonateClick
        ),
        width: "550",
        action: "Delete",
      });
    };

    return (
      <div className="Header-right">
        <ul className="user-dropdown">
          <li>
            <label>
              <img
                src={require("../images/profile-pic.png").default}
                className="profile-thumg"
                alt="User Profile Pic"
              />
              <span>
                {username} <b>{role}</b>
              </span>
              <i className="fas fa-caret-down"></i>
            </label>
            <div className="user-dropdown-details">
              <div className="profile-cont">
                <div className="profilearea">
                  <div className="profile-photo">
                    <img
                      src={require("../images/profile-pic.png").default}
                      width="75"
                      className="profile-pic"
                      alt="Chris Barko"
                    />
                    <label>{role}</label>
                  </div>
                  <div className="profile-name">
                    <div className="usernameloginsection">
                      {cookiesData.out_impersonate ? originFullName : fullName}
                      <a href={`mailto: ${loggedInUserEmail}`}>
                        {loggedInUserEmail}
                      </a>
                    </div>
                    <div className="usernameaflogin">
                      {cookiesData.out_impersonate
                        ? out_origin_user_name
                        : username}
                    </div>
                  </div>
                  <div className="userswitchcont">
                    {cookiesData.out_impersonate ? (
                      <div className="userswitchicon">
                        <i
                          className="fas fa-sign-in-alt"
                          data-toggle="tooltip"
                          data-placement="right"
                          title="Back to your login"
                          onClick={() => {
                            BackToLogin();
                          }}
                        ></i>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {cookiesData.out_impersonate && (
                  <div className="profilearea">
                    <div className="profile-photo">
                      <img
                        src={require("../images/profile-pic.png").default}
                        width="75"
                        className="profile-pic"
                        alt="Chris Barko"
                      />
                      <label>{role}</label>
                    </div>
                    <div className="profile-name">
                      {fullName}
                      <a href={`mailto: ${loggedInUserEmail}`}>
                        {loggedInUserEmail}
                      </a>
                      <div className="usernameaflogin">{username}</div>
                    </div>
                  </div>
                )}

                {/* <div className="profilearea">
                           <div className="profile-photo">
                                <img
                                    src={
                                        require("../images/profile-pic.png")
                                            .default
                                    }
                                    width="75"
                                    className="profile-pic"
                                    alt="Chris Barko"
                                />
                                <label>{role}</label>
                            </div>
                            <div className="profile-name">
                                {fullName}
                                <a href={`mailto: ${loggedInUserEmail}`}>
                                    {loggedInUserEmail}
                                </a>
                            </div>
                           </div> */}
              </div>
              <ul className="profile-links">
                <li
                  className="pointer-class"
                  onClick={() => {
                    handleClick("manage-profile");
                  }}
                >
                  <i className="fas fa-user"></i> Manage User profile
                </li>
                {cookiesData.out_css_link !== 0 &&
                (cookiesData.out_css_link ===
                  cookiesData.out_db_role_type_abbr ||
                  isMatch ||
                  cookiesData.out_css_link === "1") ? (
                  <li
                    onClick={() => {
                      handleClick("customer-support");
                    }}
                    className="pointer-class"
                  >
                    <i className="fas fa-headset"></i> Customer Success and
                    Support
                  </li>
                ) : (
                  false
                )}
                <li
                  onClick={() => {
                    handleClick("release-note");
                  }}
                  className="pointer-class"
                >
                  <i className="far fa-sticky-note"></i> Release Notes
                </li>
                <li className="line"></li>
                {/* {cookiesData.out_impersonate ? (
                  <li
                    className="cursor-pointer"
                    onClick={() => {
                      onImpersonateClick();
                    }}
                  >
                    <i className="fas fa-sign-in-alt"></i> Back to Your Login
                  </li>
                ) : (
                  ""
                )} */}
                <li
                  className="cursor-pointer"
                  onClick={() => {
                    onLogoutClick();
                  }}
                >
                  <i className="fas fa-sign-out-alt"></i> Logout
                </li>
              </ul>
            </div>
          </li>
        </ul>
        <DialogComponent
          title={confirmationPopup.title}
          onCancelConfirm={onCancelConfirm}
          width={confirmationPopup?.width || "450"}
          height={"auto"}
          className={confirmationPopup.width ? "" : "Dialog-Delete"}
          desc={confirmationPopup.desc}
          visible={confirmationPopup.isVisible}
          actionBtn={confirmationPopup.actionBtn}
          titleClassName={
            confirmationPopup.action === "Delete"
              ? "delete-confirm"
              : "unSave-confirm"
          }
        />
      </div>
    );
  };

  return (
    <>
      <div className="Header">
        <div className="Header-row">
          <div className="Header-left">
            <ProCareLogo />
          </div>
          <div className="k-loading-imagegear hide" style={{ display: "none" }}>
            <img
              src={require("../images/pipeline_gears.gif").default}
              alt="ProCARE"
              className="procare-icon"
              style={{ height: "40px" }}
            />{" "}
          </div>
          {headerRight()}
        </div>
      </div>
    </>
  );
};

export default React.memo(Header);
