import * as React from "react";
import { GridColumn as Column } from "@progress/kendo-react-grid";
// import MyCommandCell from "../../components/commandCell";
import { getUserCredential, getTenantCookieData } from "../../lib/cookies";
import { columnMenuProps } from "../../components/customColumnMenu";
import { Input, TextArea } from "@progress/kendo-react-inputs";
import { DialogComponent } from "../../components/dialog";
import { DEFAULT_SORT } from "../../constants/grid";
import DataTable from "../../components/dataTable";
import { useDispatch } from "react-redux";
import useBusinessGroupApi from "./service";
import { Label, Error } from "@progress/kendo-react-labels";
import MultiSelectDropdown from "../../components/multiSelect";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import {
  GetCompPlanDataApi,
  GetGroupNameAPI,
  GetPositionDataAPI,
  GetSPECIALTYAPI,
  GetUserDataAPI,
} from "../../lib/common-service";
import { useHistory } from "react-router-dom";
import {
  BUSINESS_OBJECT_TYPES,
  DELETE_BTN_ACTIONS,
} from "../../constants/constants";
import MessageSchema from "../../utils/messageSchema";
import { getter } from "@progress/kendo-react-common";
import { getSelectedState } from "@progress/kendo-react-grid";
import Popup from "../../components/customPopup";
import { Form, FormElement, FieldWrapper } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { getfilterDataString } from "../../utils/utils";
import { setUnSavedChangesPopup } from "src/redux/actions/setting-actions";
import { RowRender } from "src/components/renderers";

const editField = "inEdit";
const SELECTED_FIELD = "selected";
const DATA_ITEM_KEY = "business_group_mstr_key";
const idGetter = getter(DATA_ITEM_KEY);

const loadingPanel = (
  <div className="k-loading-mask">
    <span className="k-loading-text">Loading</span>
    <div className="k-loading-image"></div>
    <div className="k-loading-color"></div>
  </div>
);

const initialFormFields = {
  business_group_name: "",
  business_group_desc: "",
  in_object_type: {
    text: "Position",
    value: "position",
  },
  userValue: "",
  userData: [],
  compPlanData: [],
  assignCompPlanValue: [],
  business_group_mstr_key: "",
};

const anchorAlign = {
  horizontal: "right",
  vertical: "top",
};
const popupAlign = {
  horizontal: "left",
  vertical: "top",
  className: "testssss",
};

export const BusinessGroupList = (restProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const cookiesData = getUserCredential();
  const defaultPageSize = getTenantCookieData("record_per_page");

  const anchor = React.useRef(null);
  const [list, Action] = useBusinessGroupApi();
  const [deleteSelectedIds, setDeleteSelectedIds] = React.useState([]);
  const [updatedData, setUpdatedData] = React.useState([]);
  const [selectedState, setSelectedState] = React.useState({});
  const [popupAppendTo, setPopupAppendTo] = React.useState(null);
  const [formFields, setFormFields] = React.useState({ ...initialFormFields });
  const [version, setVersion] = React.useState(null);
  const [isDisable, setIsDisable] = React.useState(true);
  //state to set and get limit, offset
  const [pageData, setPage] = React.useState({
    skip: 0,
    take: defaultPageSize.recordTake,
  });
  const [pageSize, setPageSize] = React.useState(defaultPageSize);
  const [originalobjType, setoriginalobjType] = React.useState();
  const [originalcompPlan, setoriginalcompPlan] = React.useState();
  const [errorObj, setErrorObj] = React.useState({});
  const [dropDownAppendTo, setDropdownAppendTo] = React.useState(null);
  const objDrpRef = React.useRef(null);
  let assignObjRef = React.useRef(null);
  let ownerUserRef = React.useRef(null);

  const {
    data,
    columns,
    dataCount,
    setData,
    // setColumns,
    isColumnMenuSearch,
    editData,
    // originalData,
    isAPICalling,
    setConfirmationPopup,
    confirmationPopup,
    setEditData,
    setAPICallingState,
    initialLoading,
    paginationData,
    isRefreshLoading,
  } = list;

  const apiCallData = {
    in_tenant_id: cookiesData.out_tenant_id,
    in_filter: null,
    in_sort_number: DEFAULT_SORT.number,
    in_sort_order: DEFAULT_SORT.order,
    in_page_row_offset: 0,
    in_page_rows: defaultPageSize.recordTake,
    in_load_type: "data-reload",
  };
  const [filterData, setFilterData] = React.useState({
    isSort: false,
    isFilter: false,
    data: "",
    in_sort_number: DEFAULT_SORT.number,
    sort_order: DEFAULT_SORT.order,
  });

  const [exportData, setExportData] = React.useState([]);

  const [userDataList, setUserDataList] = React.useState([]);
  const [compPlanDataList, setAssignCompPlanList] = React.useState([]);
  const [loadingState, setLoadingState] = React.useState({
    isOwnerUserListLoading: false,
    isUploadLoader: false,
    isAssignCompListLoading: false,
  });
  const [isSaveClick, setSaveClick] = React.useState(false);
  const [gridColumns, setGridColumns] = React.useState([]);

  React.useEffect(() => {
    setDropdownAppendTo(restProps.setdivRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setPopupAppendTo(restProps.setdivRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (restProps.isAPICall) {
      restProps.setAPICall(false);
      onRefreshClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restProps.isAPICall]);

  React.useEffect(() => {
    if (restProps.isAddNewConfig) {
      restProps.setNewConfig(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restProps.isAddNewConfig]);

  React.useEffect(() => {
    if (restProps.isShow.deleteRows) {
      remove();
      restProps.setShow({ ...restProps.isShow, deleteRows: false });
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restProps.isShow.deleteRows]);

  React.useEffect(() => {
    if (columns.length > 0) {
      restProps.setDataList(data);
      restProps.setColumnsData(columns);
      setGridColumns(columns);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);

  React.useEffect(() => {
    const dataVal = {
      ...apiCallData,
      in_load_type: "grid-reload",
    };
    Action.getListDataAsync(dataVal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (restProps.isCheckColumn) {
      setGridColumns(restProps.columnsData);
      restProps.setColumnCheck(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restProps.isCheckColumn]);

  React.useEffect(() => {
    if (list.loadingState.isDeleteData) {
      list.setLoadState({ ...list.loadingState, isDeleteData: false });
      onRefreshClick();
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list.loadingState.isDeleteData]);

  React.useEffect(() => {
    if (list.loadingState.isupdateData) {
      list.setLoadState({ ...list.loadingState, isupdateData: false });
      onRefreshClick();
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list.loadingState.isupdateData]);

  const getInFilter = () => {
    return filterData.isFilter ? filterData.data : null;
  };

  const getInSortNumber = () => {
    return filterData.isSort ? filterData.in_sort_number : DEFAULT_SORT.number;
  };

  const getInSortOrder = () => {
    return filterData.isSort ? filterData.sort_order : DEFAULT_SORT.order;
  };

  const getPageTake = () => {
    return pageData.take;
  };

  const getPageSkip = () => {
    return pageData.skip;
  };

  const onSelectionChange = (event) => {
    const newSelectedState = getSelectedState({
      event,
      selectedState: selectedState,
      dataItemKey: DATA_ITEM_KEY,
    });

    let tempArray = [];

    Object.keys(newSelectedState).map((item) => {
      if (newSelectedState[item] === false) {
        return 0;
      }

      let obj = {};
      obj.id = Number(item);

      tempArray.push(obj);
      return 0;
    });

    const newSelectedStateKeys = Object.keys(newSelectedState);

    const selectedKeyArr = newSelectedStateKeys.filter(function (x) {
      return newSelectedState[x] !== false;
    });

    let newData = data.map((item) => ({
      ...item,
      selected: selectedKeyArr.includes(item.business_group_mstr_key),
    }));

    setData(newData);
    setSelectedState(newSelectedState);
    setDeleteSelectedIds(tempArray);
    restProps.setSelectedRowId(Number(selectedKeyArr[0]));
  };

  const remove = (ids) => {
    setConfirmationPopup({
      ...confirmationPopup,
      isVisible: true,
      type: "DELETE_CONFIRM",
      actionBtn: DELETE_BTN_ACTIONS(onCancelConfirm, onDeleteConfirm),
      // data: ids[0],
      action: "Delete",
    });
  };

  const onDeleteConfirm = (key) => {
    let tempArray = [];
    const selectedIdsArray = {};
    selectedIdsArray.id = deleteSelectedIds;
    tempArray.push(selectedIdsArray);
    let deleteData = {
      in_user_id: cookiesData.out_user_id.toString(),
      in_business_group_mstr_key: deleteSelectedIds,
      in_tenant_id: cookiesData.out_tenant_id,
    };
    Action.deleteDataAsync(deleteData);

    setDeleteSelectedIds([]);
    setErrorObj({});
    restProps.setSelectedRowId("");
  };

  const onCancelConfirm = () => {
    setConfirmationPopup({
      ...confirmationPopup,
      isVisible: false,
      type: "",
      actionBtn: [],
      data: {},
      action: "",
    });
    restProps.setShow({ ...restProps.visible, deleteSelectedRow: false });
    data.map((item) => {
      item.selected = false;
      return 0;
    });
    setEditData(editData);
    setData([...data]);
  };

  // const discard = () => {
  //   const newData = JSON.parse(JSON.stringify(data));
  //   newData.splice(0, 1);
  //   setData(newData);
  //   setEditData("");
  // };

  const enterEdit = async (dataItem) => {
    // close add popup while edit
    restProps.setNewConfig(false);
    let dataValue = JSON.parse(JSON.stringify(data));

    let findIndex = dataValue.findIndex(
      (x) => x.business_group_mstr_key === dataItem.business_group_mstr_key
    );

    dataValue[findIndex].expanded = false;
    dataValue[findIndex].selected = true;
    // dataValue[findIndex]["isOwnerUserListLoading"] = true;
    dataValue[findIndex]["isAssignCompListLoading"] = true;
    dataValue[findIndex]["userData"] = userDataList;
    dataValue[findIndex]["compPlanData"] = compPlanDataList;
    dataValue[findIndex]["error"] = {
      business_group_name: "",
      userValue: "",
      assignCompPlanValue: "",
    };
    generateDataForEdit(dataValue[findIndex], findIndex);
    getownerUserData(findIndex);
    handleOpenDropdown(
      dataValue[findIndex].in_object_type.text,
      findIndex,
      dataValue
    );
    setUpdatedData([...updatedData, ...dataValue]);
  };

  const itemChange = React.useCallback(
    (event) => {
      const field = event.field || "";
      const newData = data.map((item) =>
        item.business_group_mstr_key === event.dataItem.business_group_mstr_key
          ? { ...item, [field]: event.value }
          : item
      );
      let editItem = event.dataItem;
      editItem[field] = event.value;
      delete editItem.inEdit;
      setEditData(editItem);
      setData(newData);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  // const onColumnsSubmit = (columnsState) => {
  //   setColumns(columnsState);
  // };

  const handlePageChange = async (event) => {
    const pageData = event.page;

    setAPICallingState(true);

    setPage({ skip: pageData.skip, take: pageData.take });

    const dataVal = {
      ...apiCallData,
      in_filter: getInFilter(),
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
      in_page_row_offset: pageData.skip,
      in_page_rows: pageData.take,
    };

    let response = await Action.getListDataAsync(dataVal);

    restProps.setDataList(response);
    setPageMenuState(response);
  };
  const setPageMenuState = (response) => {
    if (!deleteSelectedIds.length) {
      return false;
    }

    let ids = response.map((el) => el.business_group_mstr_key);

    let selectedKeys = [];
    let tempArray = [];
    let tempArrayData = [];

    Object.keys(selectedState).map((item) => {
      let obj = {};

      obj.id = Number(item);
      selectedKeys.push(item);
      tempArray.push(obj);

      return 0;
    });

    ids.map((item) => {
      if (selectedKeys.includes(item.toString())) {
        tempArrayData.push(item);
      }
      return 0;
    });

    let strSelectedKeys = "";

    if (tempArrayData.length > 0) {
      strSelectedKeys = parseInt(selectedKeys[0]);
    }

    restProps.setSelectedRowId(strSelectedKeys);
  };

  React.useEffect(() => {
    restProps.setDataList(data);
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  React.useEffect(() => {
    if (columns.length > 0) {
      restProps.setDataList(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);

  const handleColumnMenu = async (columnMenuData, isFilter, isSort) => {
    let index = 1;
    let filterSkip = getPageSkip();
    let filterTake = getPageTake();

    if (isFilter) {
      filterSkip = 0;
      filterTake = pageSize.recordTake;

      //reset pager when filter applies
      setPage({ skip: filterSkip, take: filterTake });
    }

    if (data.length > 0 && columnMenuData[0] !== undefined) {
      let obj = Object.keys(data[0]);
      index = obj.findIndex((x) => x === columnMenuData[0].field) + 1;
    }

    const filterString = isFilter
      ? getfilterDataString(columnMenuData, gridColumns)
      : filterData.data;

    isFilter = false;
    if (filterString !== "") {
      isFilter = true;
    }

    let sortDir =
      filterData.isSort && columnMenuData.length
        ? filterData.sort_order
        : DEFAULT_SORT.order;
    let sortNumber =
      filterData.isSort && columnMenuData.length
        ? filterData.in_sort_number
        : DEFAULT_SORT.number;

    isSort = !columnMenuData.length ? false : filterData.isSort;

    if (
      columnMenuData[0] !== undefined &&
      columnMenuData[0].dir !== undefined
    ) {
      isSort = true;
      sortDir = columnMenuData[0].dir.toUpperCase();
      sortNumber = index;
    }

    const dataVal = {
      ...apiCallData,
      in_filter: isFilter ? filterString : null,
      in_sort_number: sortNumber,
      in_sort_order: sortDir,
      in_page_row_offset: filterSkip,
      in_page_rows: filterTake,
    };

    setFilterData({
      ...filterData,
      isFilter: isFilter,
      isSort: isSort,
      data: filterString,
      in_sort_number: sortNumber,
      sort_order: sortDir,
    });

    setAPICallingState(true);

    let response = await Action.getListDataAsync(dataVal);

    restProps.setDataList(response);
    setPageMenuState(response);
    restProps.setSelectedRowId("");
    setSelectedState({});
  };

  // const handleUnSavedPopup = (action) => {
  //   setConfirmationPopup({
  //     ...confirmationPopup,
  //     isVisible: true,
  //     title: "Unsaved Changes",
  //     desc: UNSAVE_TEXT_WARNING,
  //     type: "UNSAVED_CHANGES",
  //     actionBtn: UNSAVE_BTN_ACTIONS(
  //       onCancelConfirm,
  //       action === "outSideClick"
  //         ? redirectUrl(reduxData.redirectionUrl)
  //         : onUnsavedChanges
  //     ),
  //     action: "UnSave",
  //   });
  // };

  // const onUnsavedChanges = () => {
  //   if (
  //     data.filter((x) => x.business_group_mstr_key === undefined).length > 0
  //   ) {
  //     discard();
  //   } else {
  //     let newData = originalData.map((item) =>
  //       item.business_group_mstr_key === editData.business_group_mstr_key
  //         ? { ...item, inEdit: false }
  //         : { ...item, inEdit: false }
  //     );
  //     if (editData.business_group_mstr_key === undefined) {
  //       discard();
  //     } else {
  //       setData(newData);
  //     }
  //   }
  //   setEditData("");
  //   setConfirmationPopup({
  //     ...confirmationPopup,
  //     isVisible: false,
  //     type: "",
  //     actionBtn: [],
  //     data: {},
  //     action: "",
  //   });
  // };

  const useCol = (columns) => {
    return columns.map((column, idx) => {
      return column.show && column.title !== "" ? (
        <Column
          {...columnMenuProps(column)}
          key={idx}
          cell={StringReadAndOnlyCell}
          sortable={true}
        />
      ) : (
        false
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const StringReadAndOnlyCell = React.useCallback((props) => {
    const { ariaColumnIndex, columnIndex, dataItem, field } = props;

    let val = dataItem[field || ""];

    const defaultRendering = (
        <td aria-colindex={ariaColumnIndex} data-grid-col-index={columnIndex}>
            <span title={val}>{val}</span>
        </td>
    );

    return defaultRendering;
}, []);

  let customColumn = useCol(gridColumns);

  const getExportData = async () => {
    const dataVal = {
      ...apiCallData,
      in_page_row_offset: 0,
      in_page_rows: dataCount,
      in_filter: getInFilter(),
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
    };

    dataVal.in_tenant_id = cookiesData.out_tenant_id;
    dataVal.in_page_row_offset = 0;
    dataVal.in_page_rows = dataCount;
    dataVal.in_filter = getInFilter();
    dataVal.in_sort_number = getInSortNumber();
    dataVal.in_sort_order = getInSortOrder();
    dataVal.in_export_flag = 1;

    dataVal.in_encrypt_key = cookiesData.out_acct_encrypt_key;
    dataVal.in_org_title_keys = null;
    let res = await Action.exportDataAsync(dataVal);

    if (res) {
      setExportData(res);
    }
  };
  const onClearFilter = async () => {
    setAPICallingState(true);

    //set in_params
    const dataVal = {
      ...apiCallData,
      in_filter: null,
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
      in_page_row_offset: getPageSkip(),
      in_page_rows: getPageTake(),
    };

    //set filter to false
    setFilterData({
      ...filterData,
      data: "",
      isFilter: false,
    });

    let response = await Action.getListDataAsync(dataVal);
    restProps.setDataList(response);
    setPageMenuState(response);
  };

  const handleFieldChange = (e) => {
    setIsDisable(true);
    const name = e.name ? e.name : e.target.name;
    let updatedAttrFormFields = { ...formFields };

    let dataIndex = data.findIndex(
      (x) => x.business_group_mstr_key === formFields.business_group_mstr_key
    );
    if (data[dataIndex][name] === e.value) {
      setIsDisable(true);
      // TODO : UNSAVED CHANGES
      //dispatch(setUnSavedChangesPopup({ isUnSavedChange: false }));
    } else {
      setIsDisable(false);
      // TODO : UNSAVED CHANGES
      // dispatch(setUnSavedChangesPopup({ isUnSavedChange: true }));
    }

    if (name === "in_object_type") {
      let dataIndex = data.findIndex(
        (x) => x.business_group_mstr_key === formFields.business_group_mstr_key
      );
      data[dataIndex][name] = e.value;
      data[dataIndex]["assignCompPlanValue"] = [];
      data[dataIndex]["isAssignCompListLoading"] = true;
      data[dataIndex]["error"][name] = "";
      setData([...data]);
      handleOpenDropdown(e.value.text, dataIndex);
      if (originalobjType.text !== e.value.text) {
        updatedAttrFormFields.assignCompPlanValue = [];
        // setFormFields({ ...formFields, 'assignCompPlanValue': []});
      } else {
        let ids = originalcompPlan.map((item) => item.id);
        let listData = formFields.compPlanData.filter((item) =>
          ids.includes(item.id)
        );
        updatedAttrFormFields.assignCompPlanValue =
          originalobjType.text.toLowerCase() === "position"
            ? listData
            : originalcompPlan;
      }
    }
    updatedAttrFormFields[name] = e.value;
    setFormFields(updatedAttrFormFields);
  };

  const getownerUserData = async (dataIndex) => {
    let dataList = JSON.parse(JSON.stringify(data));

    let dataReq = {
      in_tenant_id: cookiesData.out_tenant_id,
      in_filter: "AND a.db_role_type_name = 'Business Group Manager'",
      in_sort_number: 1,
      in_sort_order: "DESC",
      in_page_row_offset: 0,
      in_page_rows: 9999,
    };
    setLoadingState({ ...loadingState, isOwnerUserListLoading: true });
    let res = await GetUserDataAPI(dataReq, history, dispatch);
    if (res) {
      let tempArray = [];
      res.map((item) => {
        let obj = {};
        obj.text = item.user_username;
        obj.id = item.user_key;
        tempArray.push(obj);
        return 0;
      });
      dataList[dataIndex]["userData"] = tempArray;
      dataList[dataIndex]["isOwnerUserListLoading"] = false;

      setData([...dataList]);
      setUserDataList([...tempArray]);
      setFormFields((preVState) => ({ ...preVState, userData: tempArray }));
      setLoadingState((preVState) => ({
        ...preVState,
        isOwnerUserListLoading: false,
      }));
    }
  };

  const handleOpenDropdown = async (module, dataIndex, dataVal) => {
    let dataList = JSON.parse(JSON.stringify(data));
    switch (module) {
      case "Position":
        let dataObject = {
          in_tenant_id: cookiesData.out_tenant_id,
          in_filter: null,
          in_user_key: cookiesData.out_user_id,
          in_sort_number: 2,
          in_sort_order: "ASC",
          in_page_row_offset: 0,
          in_page_rows: 9999,
          in_acct_encrypt_key: cookiesData.out_acct_encrypt_key,
          in_user_role_filter: null,
          in_export_flag: 0,
          in_is_current_flag: 1,
        };
        setAssignCompPlanList([]);
        setFormFields((preVState) => ({
          ...preVState,
          compPlanData: [],
          assignCompPlanValue: [],
        }));
        let resDataObject = await GetPositionDataAPI(
          dataObject,
          history,
          dispatch
        );
        if (resDataObject) {
          let tempArray = [];
          resDataObject.map((item) => {
            let obj = {};
            obj.text = item.position_name.trim();
            obj.id = item.org_position_key;
            tempArray.push(obj);
            return 0;
          });
          setAssignCompPlanList(tempArray);
          // setUserDataList([...tempArray]);
          dataList[dataIndex]["compPlanData"] = tempArray;
          dataList[dataIndex]["isAssignCompListLoading"] = false;
          // dataList[dataIndex]["isOwnerUserListLoading"] = false;
          // setData([...dataList])
          setData((prev) => {
            return prev.map((section) => {
              return {
                ...section,
                compPlanData: tempArray,
                isAssignCompListLoading: false,
              };
            });
          });
          if (dataVal) {
            let ids = dataVal[dataIndex].assignCompPlanValue.map(
              (item) => item.id
            );
            let listData = tempArray.filter((item) => ids.includes(item.id));
            setFormFields((prevState) => ({
              ...prevState,
              compPlanData: tempArray,
              assignCompPlanValue: listData,
            }));
          } else {
            let ids = originalcompPlan.map((item) => item.id);
            let listData = tempArray.filter((item) => ids.includes(item.id));

            setFormFields((preVState) => ({
              ...preVState,
              assignCompPlanValue: listData,
              compPlanData: tempArray,
            }));
          }

          setLoadingState((preVState) => ({
            ...preVState,
            isAssignCompListLoading: false,
            // isOwnerUserListLoading: false,
          }));
        }
        break;
      case "Group":
        let dataValue = {
          in_tenant_id: cookiesData.out_tenant_id,
          in_filter: null,
          in_user_role_filter: null,
          in_sort_number: 3,
          in_sort_order: null,
          in_page_row_offset: 0,
          in_page_rows: 9999,
        };
        setAssignCompPlanList([]);
        setFormFields((preVState) => ({ ...preVState, compPlanData: [] }));
        let resData = await GetGroupNameAPI(dataValue, history, dispatch);
        if (resData) {
          let tempArray = [];
          resData.map((item) => {
            let obj = {};
            obj.text = item.title_name;
            obj.id = item.org_title_key;
            tempArray.push(obj);
            return 0;
          });
          setAssignCompPlanList(tempArray);
          // setUserDataList([...tempArray]);
          dataList[dataIndex]["compPlanData"] = tempArray;
          dataList[dataIndex]["isAssignCompListLoading"] = false;
          // dataList[dataIndex]["isOwnerUserListLoading"] = false;
          setData((prev) => {
            return prev.map((section) => {
              return {
                ...section,
                compPlanData: tempArray,
                isAssignCompListLoading: false,
                isOwnerUserListLoading: false,
              };
            });
          });
          if (dataVal) {
            setFormFields((preVState) => ({
              ...preVState,
              compPlanData: tempArray,
              assignCompPlanValue: dataVal[dataIndex].assignCompPlanValue,
            }));
          } else {
            setFormFields((preVState) => ({
              ...preVState,
              compPlanData: tempArray,
            }));
          }

          setLoadingState((preVState) => ({
            ...preVState,
            isAssignCompListLoading: false,
            isOwnerUserListLoading: false,
          }));
        }

        break;
      case "Specialty":
        let dataObjValue = {
          in_user_key: cookiesData.out_user_id,
          in_tenant_id: cookiesData.out_tenant_id,
          in_filter: null,
          in_sort_number: 3,
          in_sort_order: "DESC",
          in_page_row_offset: 0,
          in_page_rows: 9999,
        };
        setAssignCompPlanList([]);
        setFormFields((preVState) => ({ ...preVState, compPlanData: [] }));
        let resDataObjValue = await GetSPECIALTYAPI(
          dataObjValue,
          history,
          dispatch
        );
        if (resDataObjValue) {
          let tempArray = [];
          resDataObjValue.map((item) => {
            let obj = {};
            obj.text = item.specialty_name;
            obj.id = item.specialty_mstr_key;
            tempArray.push(obj);
            return 0;
          });
          setAssignCompPlanList(tempArray);
          dataList[dataIndex]["compPlanData"] = tempArray;
          dataList[dataIndex]["isAssignCompListLoading"] = false;
          dataList[dataIndex]["isOwnerUserListLoading"] = false;
          setData((prev) => {
            return prev.map((section) => {
              return {
                ...section,
                compPlanData: tempArray,
                isAssignCompListLoading: false,
                isOwnerUserListLoading: false,
              };
            });
          });
          if (dataVal) {
            setFormFields((preVState) => ({
              ...preVState,
              compPlanData: tempArray,
              assignCompPlanValue: dataVal[dataIndex].assignCompPlanValue,
            }));
          } else {
            setFormFields((preVState) => ({
              ...preVState,
              compPlanData: tempArray,
            }));
          }
          setLoadingState((preVState) => ({
            ...preVState,
            isAssignCompListLoading: false,
            isOwnerUserListLoading: false,
          }));
        }
        break;
      case "Comp plan":
        let dataObj = {
          in_tenant_id: cookiesData.out_tenant_id,
          in_filter: null,
          in_sort_number: null,
          in_sort_order: null,
          in_user_key: cookiesData.out_user_id,
          in_multi_sort_order: null,
        };
        setAssignCompPlanList([]);
        setFormFields((preVState) => ({ ...preVState, compPlanData: [] }));
        let resDataObj = await GetCompPlanDataApi(dataObj, history, dispatch);
        if (resDataObj) {
          let tempArray = [];
          resDataObj.map((item) => {
            let obj = {};
            obj.text = item.comp_plan_name;
            obj.id = item.comp_plan_mstr_key;
            tempArray.push(obj);
            return 0;
          });
          setAssignCompPlanList(tempArray);
          // setUserDataList([...tempArray]);

          dataList[dataIndex]["compPlanData"] = tempArray;
          dataList[dataIndex]["isAssignCompListLoading"] = false;
          dataList[dataIndex]["isOwnerUserListLoading"] = false;
          setData((prev) => {
            return prev.map((section) => {
              return {
                ...section,
                compPlanData: tempArray,
                isAssignCompListLoading: false,
                isOwnerUserListLoading: false,
              };
            });
          });
          if (dataVal) {
            setFormFields((preVState) => ({
              ...preVState,
              compPlanData: tempArray,
              assignCompPlanValue: dataVal[dataIndex].assignCompPlanValue,
            }));
          } else {
            setFormFields((preVState) => ({
              ...preVState,
              compPlanData: tempArray,
            }));
          }
          setLoadingState((preVState) => ({
            ...preVState,
            isAssignCompListLoading: false,
            isOwnerUserListLoading: false,
          }));
        }
        break;
      default:
        break;
    }
  };
  const generateDataForEdit = (dataVal, findIndex) => {
    // const postionArray = [];
    let objTypeArray =
      dataVal.object_key !== null ? dataVal.object_key.split(",") : [];
    let objNameArray =
      dataVal.object_name !== null ? dataVal.object_name.split(",") : [];

    let arrayOfObject = [];
    objTypeArray.map(function (value, index) {
      let obj = {};
      obj.id = parseInt(value);

      obj.text = objNameArray[index];
      if (objNameArray[index]) {
        arrayOfObject.push(obj);
      }

      return 0;
    });

    let userKeyArray =
      dataVal.owner_user_key !== null ? dataVal.owner_user_key.split(",") : [];
    let userNameArray =
      dataVal.owner_user !== null ? dataVal.owner_user.split(",") : [];
    let userArrayOfObject = [];
    userKeyArray.map(function (value, index) {
      let obj = {};
      obj.id = parseInt(value);
      obj.text = userNameArray[index];
      if (userNameArray[index]) {
        userArrayOfObject.push(obj);
      }
      return 0;
    });
    dataVal.assignCompPlanValue = arrayOfObject;
    dataVal.userValue = userArrayOfObject;
    dataVal.in_object_type =
      dataVal.object_type !== "" && dataVal.object_type !== null
        ? {
            text: BUSINESS_OBJECT_TYPES.find(
              (x) =>
                x.value === dataVal.object_type ||
                x.text === dataVal.object_type
            ).text,
            value: BUSINESS_OBJECT_TYPES.find(
              (x) =>
                x.value === dataVal.object_type ||
                x.text === dataVal.object_type
            ).value,
          }
        : initialFormFields.in_object_type;
    dataVal.in_version = dataVal.version;
    setVersion(dataVal.version);
    setoriginalobjType(dataVal.in_object_type);
    setoriginalcompPlan(dataVal.assignCompPlanValue);

    setFormFields({
      ...formFields,
      business_group_name: dataVal.business_group_name,
      business_group_desc: dataVal.business_group_desc,
      userData: dataVal.userData,
      userValue: dataVal.userValue,
      business_group_mstr_key: dataVal.business_group_mstr_key,
      in_object_type: dataVal.in_object_type,
      // compPlanData: dataVal.compPlanData,
      // assignCompPlanValue: dataVal.assignCompPlanValue,
    });

    data[findIndex] = dataVal;
    setData(data);
    restProps.setDataList([...data, dataVal]);
  };

  const handleValidation = () => {
    let isValid = true;
    if (formFields.business_group_name.trim() === "") {
      isValid = false;
      errorObj.business_group_name = MessageSchema.groupNameRequired;
    }
    if (formFields.userValue && formFields.userValue.length === 0) {
      isValid = false;
      errorObj.userValue = MessageSchema.ownerUserRequired;
    }
    if (
      formFields.assignCompPlanValue &&
      formFields.assignCompPlanValue.length === 0
    ) {
      isValid = false;
      errorObj.assignCompPlanValue = MessageSchema.assignmentRequired;
    }
    setErrorObj((preVState) => ({ ...preVState, errorObj }));
    return isValid;
  };

  const ShowError = React.useCallback(
    (props) => {
      return <Error>{errorObj[props.name]}</Error>;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errorObj, isSaveClick]
  );

  const handleUpdate = () => {
    setSaveClick(true);
    let valid = handleValidation();
    if (valid) {
      setErrorObj("");
      let updatedDataList = [];
      // data
      //   .filter((x) => x.expanded === true)
      //   .map((item) => {
      let obj = {};
      obj.in_user_id = cookiesData.out_user_id.toString();
      obj.in_business_group_mstr_key =
        formFields.business_group_mstr_key.toString();
      obj.in_tenant_id = cookiesData.out_tenant_id;
      obj.in_business_group_name = formFields.business_group_name;
      obj.in_business_group_desc = formFields.business_group_desc;
      obj.in_object_type = formFields.in_object_type.value;
      obj.in_version = version;
      obj.in_owner_user_key =
        formFields.userValue.length > 0
          ? formFields.userValue.map((itemV) => {
              return { id: itemV.id };
            })
          : [];
      obj.in_object_key = formFields.assignCompPlanValue
        ? formFields.assignCompPlanValue.map((itemV) => {
            return { id: itemV.id };
          })
        : [];

      updatedDataList.push(obj);
      // return 0;
      // });
      const bodyData = {
        updateBusinessGroup: [...updatedDataList],
      };
      Action.UpdateDataAsync(bodyData);
      setLoadingState({ ...loadingState, isUploadLoader: true });
      restProps.setSelectedRowId("");
      setIsDisable(true);
      // TODO : UNSAVED CHANGES
      //dispatch(setUnSavedChangesPopup({ isUnSavedChange: false }));
      setErrorObj({});
      restProps.setUpdatenewConfig(false);
    }
  };

  const onRowDoubleClick = (props) => {
    if (restProps.pageProps.writeAccess === 0) {
      return false;
    }
    enterEdit(props.originalProps.dataItem);
    restProps.setUpdatenewConfig(!restProps.isUpdatenewConfig);
  };

  const onRowClick = (props) => {
    const { dataItem } = props;
    let newData = data.map((item) => ({
      ...item,
      selected:
        item.business_group_mstr_key === dataItem.business_group_mstr_key
          ? true
          : false,
    }));
    setData(newData);
    restProps.setSelectedRowId(dataItem.business_group_mstr_key);
    restProps.setDataList(newData);
  };

  const onCancelClick = () => {
    setErrorObj({});
    setIsDisable(true);
    // TODO : UNSAVED CHANGES
    //dispatch(setUnSavedChangesPopup({ isUnSavedChange: false }));
    setFormFields(initialFormFields);
    restProps.setUpdatenewConfig(false);
  };

  const onRefreshClick = async () => {
    setAPICallingState(true);

    const dataVal = {
      ...apiCallData,
      in_filter: getInFilter(),
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
      in_page_row_offset: getPageSkip(),
      in_page_rows: getPageTake(),
    };

    let response = await Action.getListDataAsync(dataVal);
    restProps.setDataList(response);
    setPageMenuState(response);
  };

  const getObjectTypeVale = (a, b) => {
    const bTextToIdMap = Object.fromEntries(
      b.map((item) => [item.text, item.id])
    );
    a.forEach((item) => {
      if (bTextToIdMap[item.text]) {
        item.id = bTextToIdMap[item.text];
      }
    });
    return a;
  };


  const customRowRender = (tr, props) => (
    <RowRender
        originalProps={props}
        tr={tr}
        onRowDoubleClick={onRowDoubleClick}
        title={restProps.pageProps.writeAccess ? "Double click to edit record" : ''}
    />
);


  return (
    <>
      {isRefreshLoading && loadingPanel}
      {columns.length > 0 ? (
        <DataTable
          isAPICalling={isAPICalling}
          initialLoading={initialLoading}
          // data={data}
          data={data.map((item) => ({
            ...item,
            [SELECTED_FIELD]: selectedState[idGetter(item)],
          }))}
          handleColumnMenu={handleColumnMenu}
          itemChange={itemChange}
          editField={editField}
          pageData={pageData}
          dataCount={dataCount}
          customColumn={customColumn}
          isColumnMenuSearch={isColumnMenuSearch}
          columns={gridColumns}
          dataItemKey={"business_group_mstr_key"}
          width={"auto"}
          paginationData={paginationData}
          defaultPageSize={defaultPageSize}
          pageSize={pageSize}
          setPageSize={setPageSize}
          handlePageChange={handlePageChange}
          isExportDataClick={restProps.exportDataClick}
          setExportData={restProps.setExportDataClick}
          fileName={"Export_businessGroup.xlsx"}
          module={"business_group"}
          exportData={exportData}
          getExportData={getExportData}
          onClearFilter={onClearFilter}
          onSelectionChange={onSelectionChange}
          // onRowDoubleClick={onRowDoubleClick}
          onRowClick={onRowClick}
          refreshClick={onRefreshClick}
          rowRender={customRowRender}
          reorderable={false}
        />
      ) : (
        <>{loadingPanel}</>
      )}
      <DialogComponent
        title={confirmationPopup.title}
        onCancelConfirm={onCancelConfirm}
        width={"450"}
        height={"auto"}
        className={"Dialog-Delete"}
        desc={confirmationPopup.desc}
        visible={confirmationPopup.isVisible}
        actionBtn={confirmationPopup.actionBtn}
        titleClassName={
          confirmationPopup.action === "Delete"
            ? "delete-confirm"
            : "unSave-confirm"
        }
      />
      <Popup
        anchor={anchor.current}
        show={restProps.isUpdatenewConfig}
        anchorAlign={anchorAlign}
        className={"edit-custom-popup"}
        popupAlign={popupAlign}
        appendTo={popupAppendTo}
      >
        <div className="dot-dropdown-open dot-dropdown-open-Form rightsidebar">
          <div className="caption">
            <span>Update Business Group</span>
          </div>
          <Form
            // onSubmit={handleSubmit}
            render={() => (
              <FormElement
                className="emailForm updatebusinessgroupcont"
                horizontal={true}
              >
                <FieldWrapper className="full-width flex-display">
                  <div className={"k-form-field-wrap-popup width-full"}>
                    <Label>{"Group Name*:"}</Label>
                    <Input
                      type={"text"}
                      id="business_group_name"
                      placeholder="Group Name"
                      name="business_group_name"
                      value={formFields.business_group_name}
                      onChange={(e) => {
                        handleFieldChange(e);
                      }}
                      autoFocus={true}
                    />
                    <ShowError name={"business_group_name"} />
                    {/* <ShowError
                  name={dataItem.error && dataItem.error["business_group_name"]}
                /> */}
                  </div>
                  <div className={"k-form-field-wrap-popup width-full"}>
                    <Label>{"Group Description:"}</Label>
                    <TextArea
                      rows={4}
                      type={"text"}
                      id="business_group_desc"
                      placeholder="Group Description"
                      name="business_group_desc"
                      value={formFields.business_group_desc}
                      onChange={(e) => {
                        handleFieldChange(e);
                      }}
                    />
                  </div>
                </FieldWrapper>

                <FieldWrapper className="full-width">
                  <div
                    className={"k-form-field-wrap-popup valueDropdown"}
                    ref={objDrpRef}
                  >
                    <Label>{"Assign Object Type:"}</Label>
                    <DropDownList
                      onChange={(e) => {
                        handleFieldChange(e);
                      }}
                      value={formFields.in_object_type}
                      data={BUSINESS_OBJECT_TYPES}
                      textField="text"
                      name="in_object_type"
                      className="assignobkecttypedropdown"
                      dataItemKey="value"
                      popupSettings={{ appendTo: objDrpRef.current }}
                    />
                  </div>
                </FieldWrapper>

                <FieldWrapper className="full-width">
                  <div
                    className={
                      "k-form-field-wrap-popup valueDropdown assignPostionWrap"
                    }
                    ref={assignObjRef}
                  >
                    <Label>{`Assign ${formFields.in_object_type?.text}*:`}</Label>
                    <MultiSelectDropdown
                      name={"assignCompPlanValue"}
                      module="assignCompPlan"
                      data={formFields.compPlanData}
                      textField="text"
                      dataItemKey="id"
                      valueData={getObjectTypeVale(
                        formFields.assignCompPlanValue,
                        formFields.compPlanData
                      )}
                      onOpen={(dataV) => {
                        setFormFields({ ...formFields, compPlanData: dataV });
                      }}
                      onChange={(dataV, name) => {
                        setIsDisable(false);
                        dispatch(
                          setUnSavedChangesPopup({ isUnSavedChange: true })
                        );
                        let dataList = JSON.parse(JSON.stringify(data));
                        let dataIndex = data.findIndex(
                          (x) =>
                            x.business_group_mstr_key ===
                            formFields.business_group_mstr_key
                        );
                        dataList[dataIndex]["error"]["assignCompPlanValue"] =
                          "";
                        dataList[dataIndex][name] = dataV;

                        setFormFields({
                          ...formFields,
                          assignCompPlanValue: dataV,
                          compPlanData: compPlanDataList,
                        });
                        setData([...dataList]);
                      }}
                      filterable={true}
                      onFilterChange={(dataV) => {
                        let dataList = JSON.parse(JSON.stringify(data));
                        let dataIndex = data.findIndex(
                          (x) =>
                            x.business_group_mstr_key ===
                            formFields.business_group_mstr_key
                        );
                        dataList[dataIndex]["compPlanData"] = dataV;
                        setFormFields({ ...formFields, compPlanData: dataV });

                        setData([...dataList]);
                      }}
                      popupSettings={{ appendTo: dropDownAppendTo }}
                      autoClose={false}
                      autoBind={true}
                      loading={!compPlanDataList.length}
                      isSelectALl={false}
                      dataList={compPlanDataList}
                      placeholder={"Select Assignment"}
                      appendTo={assignObjRef.current}
                    />
                    <ShowError name={"assignCompPlanValue"} />
                    {/* <ShowError
                      name={dataItem.error && dataItem.error["assignCompPlanValue"]}
                    /> */}
                  </div>
                </FieldWrapper>
                <FieldWrapper className="full-width">
                  <div
                    className={
                      "k-form-field-wrap-popup valueDropdown ownerUserWrap"
                    }
                    ref={ownerUserRef}
                  >
                    <Label>{"Owner User*:"}</Label>

                    <MultiSelectDropdown
                      className={"ownerUserWrap"}
                      name={"userValue"}
                      module="user"
                      data={formFields.userData}
                      textField="text"
                      placeholder={"Select Owner User"}
                      dataItemKey="id"
                      valueData={formFields.userValue}
                      onOpen={(dataV) => {
                        setFormFields({ ...formFields, userData: dataV });
                      }}
                      onChange={(dataV, name) => {
                        setIsDisable(false);
                        dispatch(
                          setUnSavedChangesPopup({ isUnSavedChange: true })
                        );
                        let dataList = JSON.parse(JSON.stringify(data));
                        let dataIndex = data.findIndex(
                          (x) =>
                            x.business_group_mstr_key ===
                            formFields.business_group_mstr_key
                        );
                        // dataList[dataIndex]["error"]["userValue"] = "";
                        dataList[dataIndex][name] = dataV;
                        dataList[dataIndex]["userData"] = dataV;
                        setFormFields({
                          ...formFields,
                          [name]: dataV,
                          userData: userDataList,
                        });

                        setData([...dataList]);
                      }}
                      filterable={false}
                      onFilterChange={(dataV) => {
                        let dataList = JSON.parse(JSON.stringify(data));
                        let dataIndex = data.findIndex(
                          (x) =>
                            x.business_group_mstr_key ===
                            formFields.business_group_mstr_key
                        );
                        dataList[dataIndex]["userData"] = dataV;
                        setFormFields({ ...formFields, userData: dataV });
                        // dataList[dataIndex]["error"]["userValue"] = "";
                        setData([...dataList]);
                      }}
                      autoClose={false}
                      autoBind={true}
                      isSelectALl={false}
                      dataList={userDataList}
                      loading={!userDataList.length}
                      appendTo={ownerUserRef.current}
                    />
                    <ShowError name={"userValue"} />
                    {/* <ShowError
                  name={dataItem.error && dataItem.error["userValue"]}
                /> */}
                  </div>
                </FieldWrapper>
              </FormElement>
            )}
          />
          <div className="dropdownFooter updatebusinessgroupbuttons">
            <div className="k-form-buttons">
              <Button
                primary={true}
                type={"submit"}
                onClick={() => {
                  onCancelClick();
                }}
              >
                Cancel
              </Button>
              <Button
                primary={true}
                type={"submit"}
                disabled={isDisable}
                onClick={() => {
                  handleUpdate(formFields);
                }}
              >
                Update
              </Button>
            </div>
          </div>
        </div>
      </Popup>
    </>
  );
};
