import React from "react";
import { GetEventTypeAPI, GetSystemConfigData } from "src/lib/common-service";
import { getUserCredential } from "src/lib/cookies";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { DOWNLOAD_DTU_FILE, UPLOAD_CSV_FILE } from "src/api-setup/api-endpoints";
import { setNewMessage } from "src/redux/actions/setting-actions";
import { POSTAPI } from "src/api-setup/api-helper";
import { generateHeader } from "../../api-setup/api-helper";
import { downloadURI } from "src/utils/utils";
import projectSetting from "src/setting";

export default function DTUService() {
    const history = useHistory();
    const cookiesData = getUserCredential();
    const dispatch = useDispatch();

    /**State Variables */
    const [eventsTypeFields, setEventTypeFields] = React.useState({
        eventTypeValue: {
            event_type_mstr_key: "",
        },
        eventTypeData: [],
        isEventTypeDataLoading: true,
    });
    const [eventTypeList, setEventTypeList] = React.useState([]);
    const [preConfigPath, setPreConfigPath] = React.useState("");

    /** Method To Get Event Type List */
    const getEventTypeListDataAsync = async () => {
        let eventTypeData = {
            in_tenant_id: cookiesData.out_tenant_id,
            in_filter: null,
            in_sort_number: 3,
            in_sort_order: "ASC",
            in_page_row_offset: 0,
            in_page_rows: 9999,
        };
        let res = await GetEventTypeAPI(eventTypeData, dispatch, history);
        if (res.length > 0) {
            let tempArray = [];
            res.map((item) => {
                let obj = {};
                obj.text = item.event_type_name;
                obj.id = item.event_type_mstr_key;
                obj.group_name = item.event_group_name;
                tempArray.push(obj);
                return 0;
            });
            setEventTypeList(tempArray);
            setEventTypeFields({
                ...eventsTypeFields,
                eventTypeData: tempArray,
                eventTypeValue: tempArray[0],
                isEventTypeDataLoading: false,
            });

        }
    }

    /** Method To Upload DTU FILE */
    const uploadFileDataAsync = async (payloadData, dataReq) => {
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                UPLOAD_CSV_FILE,
                null,
                { ...generateHeader(), ...payloadData },
                dataReq,
                dispatch
            ).then((res) => {
                if (res.status) {
                    resolve(true)
                } else {
                    dispatch(
                        setNewMessage({
                            message: res.data.msg || res.msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    resolve(false)
                }
            }).then((res) => {
                return res;
            })
        })
    }

    /** Method To download File */
    const downloadFileAsync = async(payloadData) =>{
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                DOWNLOAD_DTU_FILE,
                null,
                { ...payloadData },
                null,
                dispatch
            ).then((res) => {
                if (res.status) {
                    let fileLink = projectSetting.baseURL + res.data.file.path + res.data.file.fileName
                    downloadURI(res.data.file.fileName,fileLink)
                    resolve(true)
                } else {
                    dispatch(
                        setNewMessage({
                            message: res.data.errors.msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    resolve(false)
                  
                }
            }).then((res) => {
                return res;
            })
        })
    }

    /** Method to Get System Setting Data */
    const systemSettingDataAsync = async () => {
        let dataObject = {
            in_tenant_id: cookiesData.out_tenant_id,
            in_filter: "AND system_pref_code = 'etl_dir'",
            in_sort_number: null,
            in_sort_order: null,
            in_page_row_offset: null,
            in_page_rows: null,
        };
        const resDataObject = await GetSystemConfigData(dataObject, dispatch);
        if (resDataObject) {
            setPreConfigPath(resDataObject[0].pref_value);
        }
    }

    return [
        {
            eventsTypeFields,
            eventTypeList,
            setEventTypeFields,
            setEventTypeList,
            preConfigPath
        },
        {
            getEventTypeListDataAsync,
            uploadFileDataAsync,
            downloadFileAsync,
            systemSettingDataAsync 
        },
    ];
}