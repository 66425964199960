import * as React from "react";

import { GridColumn as Column } from "@progress/kendo-react-grid";
import { columnMenuProps } from "../../../components/customColumnMenu";
import DataTable from "../../../components/dataTable";
import { CellRender, RowRender } from "../../../components/renderers";
import { DEFAULT_SORT } from "../../../constants/grid";
import { getUserCredential, getTenantCookieData } from "../../../lib/cookies";
import {
  getfilterDataString,
  dateFormateCell,
  dateFormateCellForJob,
} from "../../../utils/utils";
import useApi from "./processService";
import usePhaseRuleApi from "./processRuleService";
import ErrorDetailLogs from "src/pages/common/errorDetailLogs";
import PhaseRuleDataGrid from "./phaseRuleDataGrid";

const editFieldTier = "inEdit";
const DATA_ITEM_KEY = "proc_ctrl_phase_key";
const loadingPanel = (
  <div className="k-loading-mask">
    <span className="k-loading-text">Loading</span>
    <div className="k-loading-image"></div>
    <div className="k-loading-color"></div>
  </div>
);

export const PhaseDataGrid = (restProps) => {
  const cookiesData = getUserCredential();
  const defaultPageSize = getTenantCookieData("record_per_page");
  const [list, Action] = useApi();
  const [, phaseRuleAction] = usePhaseRuleApi();

  const {
    data,
    columns,
    isAPICalling,
    setAPICallingState,
    initialLoading,
    isRefreshLoading,
    setData,
    setRefreshLoading,
  } = list;

  //const [resetSort, setResetSort] = React.useState(true);

  //state to set and get limit, offset
  let pageData = {
    skip: 0,
    take: defaultPageSize.recordTake,
  };

  //state to set and get filter, sort number, sort order
  const [filterData, setFilterData] = React.useState({
    isSort: false,
    isFilter: false,
    data: "",
    in_sort_number: 7,
    sort_order: DEFAULT_SORT.order,
  });
  const [procCtrlPhaseMstrKey, setprocCtrlPhaseMstrKey] = React.useState("");
  const [isErrorDetail, setIsErrorDetail] = React.useState(false);
  const [errorObj, setIsErrorObj] = React.useState("");
  const [refreshTableKey, setRefreshTableKey] = React.useState({
    refreshData: 0,
  });

  const apiCallData = {
    in_tenant_id: cookiesData.out_tenant_id,
    in_filter: null,
    in_proc_ctrl_mstr_key: 0,
    in_proc_ctrl_phase_key: 0,
    in_sort_number: 7,
    in_db_role_key: cookiesData?.out_db_role_key,
    in_sort_order: DEFAULT_SORT.order,
    in_init: 1,
  };

  const getInFilter = () => {
    return filterData.isFilter ? filterData.data : null;
  };

  const getInSortNumber = () => {
    return filterData.isSort ? filterData.in_sort_number : 7;
  };

  const getInSortOrder = () => {
    return filterData.isSort ? filterData.sort_order : DEFAULT_SORT.order;
  };

  const getPageTake = () => {
    return pageData.take;
  };

  const getPageSkip = () => {
    return pageData.skip;
  };

  React.useEffect(() => {
    Action.getPhasePromiseData(
      restProps.parentDataItem.phaseData,
      restProps.parentDataItem.phaseColumn
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleColumnMenu = (columnMenuData, isFilter, isSort) => {
    let index = 1;

    if (data.length > 0 && columnMenuData[0] !== undefined) {
      let obj = Object.keys(data[0]);
      index = obj.findIndex((x) => x === columnMenuData[0].field) + 1;
    }

    const filterString = isFilter
      ? getfilterDataString(columnMenuData, columns)
      : filterData.data;

    isFilter = false;
    if (filterString !== "") {
      isFilter = true;
    }

    let sortDir =
      filterData.isSort && columnMenuData.length
        ? filterData.sort_order
        : DEFAULT_SORT.order;
    let sortNumber =
      filterData.isSort && columnMenuData.length
        ? filterData.in_sort_number
        : 7;

    isSort = !columnMenuData.length ? false : filterData.isSort;

    if (
      columnMenuData[0] !== undefined &&
      columnMenuData[0].dir !== undefined
    ) {
      isSort = true;
      sortDir = columnMenuData[0].dir.toUpperCase();
      sortNumber = index;
    }

    const dataVal = {
      ...apiCallData,
      in_filter: isFilter ? filterString : null,
      in_sort_number: sortNumber,
      in_sort_order: sortDir,
    };

    setFilterData({
      ...filterData,
      isFilter: isFilter,
      isSort: isSort,
      data: filterString,
      in_sort_number: sortNumber,
      sort_order: sortDir,
    });

    setAPICallingState(true);
    getGridData(dataVal);
  };
  const customLinkCell = React.useCallback((props) => {
    const { dataItem, field, render } = props;
    let val = dataItem[field || ""];
    const defaultRendering = (
      <td title={val}>
        {val === 0 ? (
          <span title={val}>{val}</span>
        ) : (
          <span title={val}>
            <a
              className="tablelink"
              onClick={(e) => {
                openErrorDetail(dataItem);
                e.preventDefault();
              }}
            >
              {val}
            </a>
          </span>
        )}
      </td>
    );
    return render?.call(undefined, defaultRendering, props);
  }, []);
  const useCol = (columns) => {
    return columns.map((column, idx) => {
      return column.show && column.title !== "" ? (
        <Column
          {...columnMenuProps(column)}
          key={idx}
          cell={
            column.field === "start_time" || column.field === "end_time"
              ? dateFormateCellForJob
              : column.field === "error_code"
              ? customLinkCell
              : StringReandOnlyCell
          }
          sortable={true}
          // width={
          //   column.field === "start_time" || column.field === "end_time"
          //     ? "140"
          //     : ""
          // }
        />
      ) : (
        false
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const StringReandOnlyCell = React.useCallback((props) => {
    const { ariaColumnIndex, columnIndex, dataItem, field } = props;

    let val = dataItem[field || ""];

    // if (field.indexOf("_mstr_key") !== -1) {
    //   val = dataItem[field.replace("_mstr_key", "_name") || ""];
    // }

    const defaultRendering = (
      <td aria-colindex={ariaColumnIndex} data-grid-col-index={columnIndex}>
        <span title={val}>{val}</span>
      </td>
    );

    return defaultRendering;
  }, []);

  let GroupColumn = useCol(columns);

  const openErrorDetail = (val) => {
    setIsErrorDetail(true);
    setIsErrorObj(val);
    if (val.error_code === 0) {
      setIsErrorDetail(false);
    }
  };

  const onClearFilter = async () => {
    setAPICallingState(true);

    //set in_params
    const dataVal = {
      ...apiCallData,
      in_filter: null,
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
      in_page_row_offset: getPageSkip(),
      in_page_rows: getPageTake(),
    };

    //set filter to false
    setFilterData({
      ...filterData,
      data: "",
      isFilter: false,
    });

    getGridData(dataVal);
  };
  const getGridData = async (dataVal) => {
    dataVal = {
      ...dataVal,
      in_proc_ctrl_mstr_key: restProps.proc_ctrl_mstr_key,
    };
    await Action.getProcessListDataAsync(dataVal);
  };

  const onRefreshClick = () => {
    setAPICallingState(true);

    const dataVal = {
      ...apiCallData,
      in_filter: getInFilter(),
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
      in_page_row_offset: getPageSkip(),
      in_page_rows: getPageTake(),
    };
    getGridData(dataVal);
  };

  const exitEdit = (dataItem, index, dataIndex, e) => {
    // let newData = data.map((item) => ({
    //   ...item,
    //   inEdit: undefined
    // }));
    // setData(newData);
  };

  const customRowRender = (tr, props) => (
    <RowRender
      originalProps={props}
      tr={tr}
      editField={editFieldTier}
      exitEdit={exitEdit}
    />
  );

  const customCellRender = (td, props) => {
    return (
      <CellRender
        navigatable={true}
        originalProps={props}
        td={td}
        updateActBtn="grid-incell-update"
        cancelActBtn="grid-incell-cancel"
      />
    );
  };

  const DetailComponent = (props) => {
    let parentDataItem = props.dataItem.phaseRuleDataItem;

    return (
      <>
        {parentDataItem ? (
          <>
            <PhaseRuleDataGrid
              pageProps={restProps.pageProps}
              proc_ctrl_mstr_key={restProps.procCtrlMstrKey}
              proc_ctrl_phase_key={procCtrlPhaseMstrKey}
              parentDataItem={parentDataItem}
              setRefreshTableKey={setRefreshTableKey}
            />
          </>
        ) : (
          false
        )}
      </>
    );
  };

  const getPhaseDataGrid = (parentDataItem) => {
    return (
      <>
        {parentDataItem ? (
          <>
            <PhaseRuleDataGrid
              pageProps={restProps.pageProps}
              proc_ctrl_mstr_key={restProps.procCtrlMstrKey}
              proc_ctrl_phase_key={procCtrlPhaseMstrKey}
              parentDataItem={parentDataItem}
              setRefreshTableKey={setRefreshTableKey}
            />
          </>
        ) : (
          false
        )}
      </>
    );
  };

  const collapseRows = () => {
    const newData = data.filter((d) => d.expanded === true);

    if (newData.length) {
      newData.map((item) => {
        item.expanded = false;
        return 0;
      });
      setData([...data]);
    }
  };
  const getPhaseGridData = async (mstrKey) => {
    setRefreshLoading(true);

    const processRuleApiCallData = {
      in_tenant_id: cookiesData.out_tenant_id,
      in_filter: null,
      in_proc_ctrl_mstr_key: restProps.proc_ctrl_mstr_key,
      in_proc_ctrl_phase_key: mstrKey,
      in_sort_number: 2,
      in_db_role_key: cookiesData?.out_db_role_key,
      in_sort_order: DEFAULT_SORT.order,
      in_init: 1,
    };
    let phaseRuleDataResponse = await phaseRuleAction.getProcessListDataAsync(
      processRuleApiCallData
    );
    setRefreshLoading(false);
    return phaseRuleDataResponse;
  };

  const expandChange = async (event) => {
    let mstrKey = event.dataItem.proc_ctrl_phase_key;

    let masterTableData = data.slice();

    let index = masterTableData.findIndex(
      (d) => d.proc_ctrl_phase_key === mstrKey
    );

    masterTableData
      .filter((x) => x.expanded === true)
      .map((item) => {
        item.expanded = false;
        return 0;
      });
    setprocCtrlPhaseMstrKey(mstrKey);

    if (!event.value || event.dataItem.phaseRuleDataItem) {
      if (event.value === true && refreshTableKey === mstrKey) {
        setRefreshTableKey(0);
      } else {
        masterTableData[index].expanded = event.value;
        masterTableData[index].selected = true;
        setData(masterTableData);
        return;
      }
    }

    let phaseRuleDataItem = await getPhaseGridData(mstrKey);
    masterTableData[index].phaseRuleDataItem = phaseRuleDataItem;
    masterTableData[index].expanded = event.value;
    masterTableData[index].selected = true;
    setData(masterTableData);
  };

  return (
    <>
      {isRefreshLoading && <>{loadingPanel}</>}

      {columns && columns.length > 0 ? (
        <DataTable
          isAPICalling={isAPICalling}
          initialLoading={initialLoading}
          data={data}
          id="job-table-grid"
          editField={editFieldTier}
          // pageData={pageData}
          customColumn={GroupColumn}
          columns={columns}
          dataItemKey={DATA_ITEM_KEY}
          gridwidth={"10%"}
          gridHeight={"60%"}
          rowRender={customRowRender}
          cellRender={customCellRender}
          // defaultPageSize={defaultPageSize}
          // pageSize={pageSize}
          // setPageSize={setPageSize}
          handleColumnMenu={handleColumnMenu}
          // handlePageChange={handlePageChange}
          refreshClick={onRefreshClick}
          onClearFilter={onClearFilter}
          DetailComponent={DetailComponent}
          expandChange={expandChange}
          collapseRows={collapseRows}
          reorderable={false}
          isPagination={false}
        />
      ) : (
        false
      )}
      {isErrorDetail && (
        <ErrorDetailLogs
          visible={isErrorDetail}
          setIsErrorDetail={setIsErrorDetail}
          errorObj={errorObj}
          gridHeight={100}
          modalHeight={250}
          module={"job"}
        ></ErrorDetailLogs>
      )}
    </>
  );
};
export default React.memo(PhaseDataGrid);
