/**
 * Form schema for email send popup
 */
import { FormInput, FormInput2 } from "src/components/form/form-elements";
import { PASSWORD_MAX_Length, PASSWORD_MIN_Length } from "src/constants/constants";

const schema = {
    on_change_state: {
        newPassword: true,
        confirmNewPassword: true,
        in_user_first_name: true,
        in_user_last_name: true,
    },
    in_user_first_name: {
        id: "in_user_first_name",
        name: "in_user_first_name",
        // label: "Confirm New Password",
        placeholder:'First Name',
        wrapperClass: "formelement fullwidthelm logininputs",
        component: FormInput,
        type: "text",
        style: {
            // width: "340px",
        },
        autoFocus: false,
        autoComplete: "off",
        rules: {
            required: "First Name is required",
        },
    },
    in_user_last_name: {
        id: "in_user_last_name",
        name: "in_user_last_name",
        // label: "Confirm New Password",
        placeholder:'Last Name',
        wrapperClass: "formelement fullwidthelm logininputs",
        component: FormInput,
        type: "text",
        style: {
            // width: "340px",
        },
        autoFocus: false,
        autoComplete: "off",
        rules: {
            required: "Last Name is required",
        },
    },
    in_user_email: {
        id: "in_user_email",
        name: "in_user_email",
        // label: "Confirm New Password",
        placeholder:'Email',
        wrapperClass: "formelement fullwidthelm logininputs",
        component: FormInput,
        type: "email",
        style: {
            // width: "340px",
        },
        disabled: true,
        autoFocus: false,
        autoComplete: "off",
    },
    newPassword: {
        id: "newPassword",
        name: "newPassword",
        // label: "New Password",
        wrapperClass: "formelement fullwidthelm logininputs eye-icon",
        component: FormInput,
        type: "password",
        placeholder:'New Password',
        style: {
            // width: "340px",
        },
        autoFocus: false,
        autoComplete: "off",
        rules: {
            required: "Enter New Password",
            minLength: {
                value: PASSWORD_MIN_Length,
                message: "New Password should be between 8 and 13 characters.",
            },
            maxLength: {
                value: PASSWORD_MAX_Length,
                message: "New Password should be between 8 and 13 characters.",
            },
        },
    },
    newPassword2: {
        id: "newPassword2",
        name: "newPassword2",
        // label: "New Password",
        wrapperClass: "formelement fullwidthelm logininputs eye-icon",
        component: FormInput2,
        type: "password",
        placeholder:'New Password',

    },
    confirmNewPassword: {
        id: "confirmNewPassword",
        name: "confirmNewPassword",
        // label: "Confirm New Password",
        placeholder:'Confirm Password',
        wrapperClass: "formelement fullwidthelm logininputs eye-icon",
        component: FormInput,
        type: "password",
        style: {
            // width: "340px",
        },
        autoFocus: false,
        autoComplete: "off",
        rules: {
            required: "Enter Confirm Password",
            minLength: {
                value: PASSWORD_MIN_Length,
                message: "Confirm password should be between 8 and 13 characters.",
            },
            maxLength: {
                value: PASSWORD_MAX_Length,
                message: "Confirm password should be between 8 and 13 characters.",
            },
        },
    },
    confirmNewPassword2: {
        id: "confirmNewPassword2",
        name: "confirmNewPassword2",
        // label: "Confirm New Password",
        placeholder:'Confirm Password',
        wrapperClass: "formelement fullwidthelm logininputs eye-icon",
        component: FormInput2,
        type: "password",
        style: {
            // width: "340px",
        },
        autoFocus: false,
        autoComplete: "off",
        rules: {
        
        }
    },
    
};

export default schema;
