import {
    getLocalStorage,
    setLocalStorage,
    setUserCredential,
} from "../lib/cookies";
import { setUserData } from "src/store/user.store";

//function use to generate local storage data, used after user name
export const CreateUserLocalStorage = (response) => {
    const key = process.env.REACT_APP_CREDENTIAL_KEY;

    const userLocalStorageData = {
        out_db_role_key: response?.out_db_role_key,
        out_user_id: response?.out_user_id,
        out_tenant_id: response?.out_tenant_id,
        out_acct_encrypt_key: response?.out_acct_encrypt_key,
        out_status_type_name: response?.out_status_type_name,
        out_pw_change_status: response?.out_pw_change_status,
        out_db_role_type_abbr: response?.out_db_role_type_abbr,
        out_user_first_name: response?.out_user_first_name,
        out_user_last_name: response?.out_user_last_name,
        out_credential: response.out_credential || "USER_ACCESS",
        out_user_name: response?.out_user_name,
        out_css_link: response?.out_css_link,
        out_sso_type: response?.out_sso_type,
        out_secret_token: response?.out_secret_token,
    };

    setLocalStorage(key, userLocalStorageData);
};

//function use to generate cookie data, used after authentication
export const CreateAuthCookie = (response) => {
    const localStorageData = getLocalStorage(
        process.env.REACT_APP_CREDENTIAL_KEY
    );

    const authCookieData = {
        out_db_role_key: response?.out_db_role_key,
        out_user_id: response?.out_user_id,
        out_tenant_id: response?.out_tenant_id,
        out_acct_encrypt_key: response?.out_acct_encrypt_key,
        out_status_type_name: response?.out_status_type_name,
        out_pw_change_status: response?.out_pw_change_status,
        out_db_role_type_abbr: response?.out_db_role_type_abbr,
        out_user_first_name: response?.out_user_first_name,
        out_user_last_name: response?.out_user_last_name,
        out_credential: response?.out_credential,
        out_user_name: response?.out_user_name,
        out_css_link: localStorageData?.out_css_link,
        out_sso_type: localStorageData?.out_sso_type,
        out_db_obj_code: response?.out_db_obj_code,
        out_impersonate: response?.is_impersonate || false,
        secretToken: response?.out_secret_token,        
        out_origin_user_id: response?.out_origin_user_id || "",
        out_origin_user_name: response?.out_origin_user_name || "",
        out_origin_user_first_name: response?.out_origin_user_first_name || "",
        out_origin_user_last_name: response?.out_origin_user_last_name || "",


    };

    //convert pageList string to json
    const defaultPageList = JSON.parse(response.out_page_list);

    //rename key and convert key value to integer
    let userGridPageSize = [];
    defaultPageList.forEach(function (val, key) {
        userGridPageSize.push({
            recordTake: parseInt(val.value),
            id: parseInt(val.key),
        });
    });

    const recPerPage = userGridPageSize.find(
        (pageSize) => pageSize.recordTake == response.out_grid_records_per_page
    );

    const obj = {
        tenant_data: {
            system_time_out: response.out_sys_time_out,
            is_mfa: response.out_mfa_value,
            record_per_page_list: userGridPageSize,
            record_per_page: recPerPage,
        },
    };

    setUserData(authCookieData);
    setUserCredential({
        ...authCookieData,
        ...obj,
    });
};
