import React from "react";
import {
    SELECT_PIPELINE_LOG,
    PUBLISH_JOB,
    GET_SYSTEM_CONFIG,
    SELECT_ETL_LOAD_STATUS,
    INSERT_JOB_QUEUE,
    SELECT_REPORT_PUBLISH_DATA,
    INSERT_JOB
} from "../../../api-setup/api-endpoints";
import { getUserCredential,getTenantCookieData } from "../../../lib/cookies";
import { POSTAPI, errStatusHandler } from "../../../api-setup/api-helper";
import { GridColumnDataMapper } from "../../../utils/utils";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { setNewMessage } from "../../../redux/actions/setting-actions";
import { DEFAULT_SORT } from "../../../constants/grid";

export default function DataRows() {
    const cookiesData = getUserCredential();
    const header = {
        "x-access-token": cookiesData.secretToken,
    };
    const history = useHistory();
    const dispatch = useDispatch();
    const defaultPageSize = getTenantCookieData("record_per_page");

    const [data, setData] = React.useState([]);
    const [columns, setColumns] = React.useState([]);
    const [dataCount, setDataCount] = React.useState(0);
    const [processDataCount, setprocessDataCount] = React.useState(0);
    const [processData, setprocessData] = React.useState([]);
    const [isAPICalling, setAPICallingState] = React.useState(false);
    const [originalData, setOriginalData] = React.useState([]);
    const [isRefreshLoading, setRefreshLoading] = React.useState(false);
    const [isProcessAPICalling, setProcessAPICallingState] =
        React.useState(false);
    const [processcolumns, setProcessColumns] = React.useState([]);
    const [ruleData, setRuleData] = React.useState([]);
    const [isRuleAPICalling, setRuleAPICallingState] = React.useState(false);
    const [rulecolumns, setRuleColumns] = React.useState([]);
    const [ruleDataCount, setRuleDataCount] = React.useState(0);
    const [initialLoading, setLoadingState] = React.useState(false);
    const [phaseinitialLoading, setPhaseLoadingState] = React.useState(false);

    const [isSaveData, setSaveData] = React.useState(false);
    const [prefValue, setprefValue] = React.useState("");
    const [outMaxProcCtrlMstrKey, setoutMaxProcCtrlMstrKey] =
        React.useState("");


    const getSysteamPrefConfig = (apiCallData) => {
        const data = {
            in_tenant_id: cookiesData.out_tenant_id,
            in_filter: "AND system_pref_code = 'sys_job_exec_log'",
            in_sort_number: null,
            in_sort_order: null,
            in_page_row_offset: null,
            in_page_rows: null,
        };
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                GET_SYSTEM_CONFIG,
                null,
                header,
                data,
                dispatch
            ).then((res) => {
                if (res) {
                    let userData = JSON.parse(
                        JSON.stringify(res.data.row_result[0])
                    );

                    getListDataAsync(apiCallData, userData.pref_value);
                    localStorage.setItem(
                        "intervalDays",
                        parseInt(parseFloat(userData.pref_value))
                    );

                    setprefValue(userData.pref_value);
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    const getListDataAsync = (listData, logInterval) => {
        let logIntervalString = `AND a.start_time > DATE_SUB(NOW(), INTERVAL ${logInterval} DAY)`;
        listData.in_filter =
            listData.in_filter !== null && listData.in_filter !== logIntervalString
                ? logIntervalString + listData.in_filter
                : logIntervalString;

        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                SELECT_PIPELINE_LOG,
                null,
                header,
                listData,
                dispatch
            ).then((res) => {

                if (res) {
                    if (
                        res.data.out_parameters.out_ret_code > 0 ||
                        res.data.out_parameters.out_ret_code > 2
                    ) {
                        setLoadingState(true);
                        errStatusHandler(res, history, dispatch);
                    }
                    setAPICallingState(false);
                    setLoadingState(true);
                    let userData = JSON.parse(
                        JSON.stringify(res.data.row_result)
                    );

                    if (res.data.out_parameters.out_columns_xml !== null) {
                        const columData = GridColumnDataMapper(
                            res.data.out_parameters.out_columns_xml
                        );

                        const ACTION_COLUM_OBJECT = {
                            field: "publish_button",
                            alias: "a",
                            title: "ACTION",
                            type: "string",
                            show: true,
                            width: "10px",
                        };
                        columData.push(ACTION_COLUM_OBJECT);
                        setColumns(columData);
                    }
                    userData.map((item, idx) => {
                        item.rowId = idx;
                        item.selected = false;

                        if (item.job_type_code === "report_publish") {
                            item.proc_ctrl_mstr_key = null;
                        }
                        return 0;
                    });
                    setoutMaxProcCtrlMstrKey(
                        res.data.out_parameters.out_max_proc_ctrl_mstr_key
                    );
                    setData(userData);
                    setOriginalData(userData);
                    setDataCount(res.data.out_parameters.out_total_rows);
                    setRefreshLoading(false);
                    resolve(userData);
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    const getRuleListAsync = (listData) => {
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                SELECT_PIPELINE_LOG,
                null,
                header,
                listData,
                dispatch
            ).then((res) => {
                if (res) {
                    let userData = [];
                    if (res.data.out_parameters.out_ret_code === 0) {
                        const columData = GridColumnDataMapper(
                            res.data.out_parameters.out_columns_xml
                        );

                        setRuleColumns(columData);
                        userData.map((item, idx) => {
                            item.rowId = idx;
                            item.selected = false;
                            if (item.job_type_code === "report_publish") {
                                item.proc_ctrl_mstr_key = null;
                            }
                            return 0;
                        });
                        userData = JSON.parse(
                            JSON.stringify(res.data.row_result)
                        );

                        userData.map((item, idx) => {
                            item.rowId = idx;
                            item.selected = false;
                            return 0;
                        });
                    }
                    setRuleData(userData);
                    setRuleAPICallingState(false);

                    setRuleDataCount(res.data.out_parameters.out_total_rows);

                    resolve(userData);
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    const publishReport = (apiCallData) => {
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                PUBLISH_JOB,
                null,
                header,
                apiCallData,
                dispatch
            ).then((res) => {
                if (res) {
                    resolve(res);
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    const multipalpublishReport = async (apiCallData) => {
        try {
            const res = await POSTAPI(
                false,
                PUBLISH_JOB,
                null,
                header,
                apiCallData,
                dispatch
            );

            if (res) {
                return res;
            }
        } catch (error) {
            if (error.data.out_parameters.out_ret_code > 0) {
                dispatch(
                    setNewMessage({
                        message: error.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                errStatusHandler(error, history, dispatch);
            }
        }
    };
    
    const exportDataAsync = (bodyData) => {
        let logInterval = localStorage.getItem("intervalDays");
        let logIntervalString = `AND a.start_time > DATE_SUB(NOW(), INTERVAL ${logInterval} DAY)`;
        bodyData.in_filter =
            bodyData.in_filter !== null
                ? logIntervalString + bodyData.in_filter
                : logIntervalString;
        setRefreshLoading(true);
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                SELECT_PIPELINE_LOG,
                null,
                header,
                bodyData,
                dispatch
            ).then((res) => {
                setRefreshLoading(false);
                if (res && res.data.out_parameters.out_ret_code === 0) {
                    resolve(res.data.row_result);
                } else {
                    dispatch(
                        setNewMessage({
                            message: res?.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    resolve([]);
                    errStatusHandler(res, history, dispatch);
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };
    const getEtlLoadDataAsync = (listData) => {
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                SELECT_ETL_LOAD_STATUS,
                null,
                header,
                listData,
                dispatch
            ).then((res) => {
                if (res) {
                    resolve(res);
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    const addJobQueueDataAsync = (bodyData) => {
        setSaveData(false)

        POSTAPI(false, INSERT_JOB_QUEUE, null, header, bodyData, dispatch).then(
            (res) => {
                if (res.status) {
                    setRefreshLoading(false);
                    if (res.data.out_parameters.out_ret_code > 0) {
                        dispatch(
                            setNewMessage({
                                message: res.data.out_parameters.out_error_msg,
                                type: "Error",
                                show: true,
                            })
                        );
                        errStatusHandler(res, history, dispatch);
                    } else {
                        setSaveData(true)
                        // setLoadState({ ...loadingState, isSaveData: true });
                    }
                }
            }
        );
    };
    const getReportLoadDataAsync = (listData) => {
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                SELECT_REPORT_PUBLISH_DATA,
                null,
                header,
                listData,
                dispatch
            ).then((res) => {
                if (res) {
                    resolve(res);
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    const addDataAsync = async (bodyData) => {

       

      
        // setLoadState({ ...loadingState, isSaveData: false });

        POSTAPI(false, INSERT_JOB, null, header, bodyData, dispatch).then(
            (res) => {
                // setRefreshLoading(false);
                if (res.status) {
                    if (res.data.out_parameters.out_ret_code > 0) {
                        dispatch(
                            setNewMessage({
                                message: res.data.out_parameters.out_error_msg,
                                type: "Error",
                                show: true,
                            })
                        );
                        errStatusHandler(res, history, dispatch);
                    } else {
                        // setLoadState({ ...loadingState, isSaveData: true });
                    }
                }
            }
        );
    };

    return [
        {
            data,
            columns,
            dataCount,
            setData,
            setColumns,
            isAPICalling,
            setAPICallingState,
            setSaveData,
            isSaveData,
            isProcessAPICalling,
            setProcessAPICallingState,
            processData,
            setprocessData,
            processDataCount,
            setprocessDataCount,
            isRefreshLoading,
            initialLoading,
            processcolumns,
            setProcessColumns,
            setRuleColumns,
            rulecolumns,
            setRuleData,
            ruleData,
            ruleDataCount,
            isRuleAPICalling,
            setRuleAPICallingState,
            prefValue,
            setPhaseLoadingState,
            phaseinitialLoading,
            outMaxProcCtrlMstrKey,
            setRefreshLoading,
            originalData
        },
        {
            getListDataAsync,
            getRuleListAsync,
            getSysteamPrefConfig,
            publishReport,
            exportDataAsync,
            multipalpublishReport,
            getEtlLoadDataAsync,
            addJobQueueDataAsync,
            getReportLoadDataAsync,
            addDataAsync
        },
    ];
}
