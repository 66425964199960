/**
 * function to use send email popup validation
 *
 * @param {*} values Object
 * @param {*} formSchema Object
 *
 * @returns validation Object
 */
export const sendEmailValidator = (values, formSchema) => {
    let validationObj = { subjectValidation: [], messageValidation: [] };
    let errorObj = {};

    //validate subject
    if (formSchema.on_change_state.in_subject) {
        const subject = values?.in_subject ? values.in_subject : "";

        validationObj.subjectValidation = subject.validateInputVal(
            formSchema.in_subject.rules
        );

        if (validationObj.subjectValidation.length) {
            errorObj["in_subject"] = validationObj.subjectValidation[0].message;
        }
    }

    //validate message
    if (formSchema.on_change_state.in_message) {
        const message = values?.in_message ? values.in_message : "";

        validationObj.messageValidation = message.validateInputVal(
            formSchema.in_message.rules
        );

        if (validationObj.messageValidation.length) {
            errorObj["in_message"] = validationObj.messageValidation[0].message;
        }
    }

    const errorCount = Object.keys(errorObj).length;

    if (errorCount > 0) {
        errorObj["VALIDATION_SUMMARY"] = "Please fill in the following fields.";
    }

    return errorObj;
};

