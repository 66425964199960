import React, { useState, useRef, useEffect } from "react";
import {
  getUserCredential,
  setUserCredential,
  setOtpTimer,
  getOtpTimer,
  removeOtpData,
  removeUserCredential,
} from "../../../lib/cookies";
import projectSetting from "../../../setting";
import { setNewMessage } from "../../../redux/actions/setting-actions";
import { useDispatch } from "react-redux";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { LoadingPanel } from "src/components/loadingPanel";
import useServiceApi from "../service";
import { Button } from "@progress/kendo-react-buttons";
import schema from "./schema/otpSchema";
import { otpValidator } from "./validator";
import { BackToLoginComponent } from "src/pages/common/BackToLoginComponent";
import { useHistory, useLocation } from "react-router-dom";

const Otp = () => {
  const effectRan = useRef(false);
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const redirectPath = searchParams.get("redirect");

  const [List, Action] = useServiceApi();
  const { isRefreshLoading } = List;
  const formSchema = schema;

  const dispatch = useDispatch();
  const cookiesData = getUserCredential();
  const otpTimerCookiesData = getOtpTimer();

  let otp_timer_val =
    otpTimerCookiesData && otpTimerCookiesData.otp_timer
      ? otpTimerCookiesData.otp_timer === "00:00"
        ? otpTimerCookiesData.otp_timer
        : projectSetting.valid_otp_time
      : projectSetting.valid_otp_time;
  const [timer, setTimer] = useState(otp_timer_val);
  const [disabledBtn, setDisabledBtn] = useState(false)
  const Ref = useRef(null);

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    return {
      total,
      minutes,
      seconds,
    };
  };
  const startTimer = (e) => {
    let { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
      let obj = {
        otp_timer:
          (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds),
        timeMili: e,
      };
      setOtpTimer(
        {
          ...otpTimerCookiesData,
          ...obj,
        },
        "otpData"
      );
    }
  };

  const clearTimer = (e) => {
    setTimer(otp_timer_val);
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();
    if (otpTimerCookiesData?.timeMili && otpTimerCookiesData?.timeMili !== "") {
      let val = new Date(otpTimerCookiesData?.timeMili);
      val.setMinutes(val.getMinutes() + 5);
    } else {
      deadline.setMinutes(deadline.getMinutes() + 5);
    }
    return otpTimerCookiesData?.timeMili
      ? otpTimerCookiesData?.timeMili
      : deadline;
  };

  const getResendTime = () => {
    let deadline = new Date();
    deadline.setMinutes(deadline.getMinutes() + 5);
    return deadline;
  };
  useEffect(() => {
    if (otpTimerCookiesData) {
      clearTimer(getDeadTime());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (timer === "00:01" && otpTimerCookiesData) {
      expireOtpAPI();
      setDisabledBtn(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer]);

  const expireOtpAPI = async(isToast=true) => {
    const data = {
      in_user_id: cookiesData.out_user_id.toString(),
      in_tenant_id: cookiesData.out_tenant_id,
      in_auto_expire: true,
    };
    await Action.expireOtpAsync(data).then((res) => {
      if (res?.data?.out_parameters?.out_ret_code === 0) {
        let obj = {
          timeMili: "",
        };
        setOtpTimer(
          {
            ...otpTimerCookiesData,
            ...obj,
          },
          "otpData"
        );
        if (res.data.msg && isToast) {
          dispatch(
            setNewMessage({
              message: res.msg,
              type: "Success",
              show: true,
            })
          );
        }
      } else {
        if (res?.data?.out_parameters?.out_error_msg && isToast) {
          dispatch(
            setNewMessage({
              message: res?.msg,
              type: "Error",
              show: true,
            })
          );
        }
      }
    });

  };

  React.useEffect(() => {
    generateOtpOnLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generateOtpOnLoad = async() => {
    if(timer === "00:00"){
      return
    }
    if (effectRan.current === false) {
      setDisabledBtn(false)
      effectRan.current = true;
      if (!otpTimerCookiesData || otpTimerCookiesData?.timeMili === "") {
        const data = {
          in_user_id: cookiesData.out_user_id.toString(),
          in_tenant_id: cookiesData.out_tenant_id,
          in_user_email: cookiesData.out_user_name,
          in_first_name: cookiesData.out_user_first_name,
          in_last_name: cookiesData.out_user_last_name,
        };
        await expireOtpAPI(false);
        Action.otpGenerationAsync(data).then((res) => {
          clearTimer(getDeadTime());
          dispatch(
            setNewMessage({
              message:
                "We’ve sent a One Time Password (OTP) to your registered email address",
              type: "Success",
              show: true,
            })
          );
          if (res?.data?.out_parameters?.out_error_msg) {
            dispatch(
              setNewMessage({
                message: res?.data?.out_parameters?.out_error_msg,
                type: "Success",
                show: true,
              })
            );
          }
        });
      }
    }
  }

  const onSubmitClick = async (dataItem) => {
    const data = {
      in_filter: null,
      in_sort_number: null,
      in_sort_order: "DESC",
      in_page_row_offset: 0,
      in_page_rows: 1,
      in_login_otp: dataItem.otp,
      in_user_key: cookiesData.out_user_id.toString(),
    };
    let res = await Action.otpAsync(data);
    if (res?.data?.out_parameters?.out_ret_code === 0) {
      const obj = {
        is_verified_otp: 1,
        secretToken: res?.data?.out_parameters?.out_secret_token,
        out_credential: res?.data?.out_parameters?.out_credential,
      };
      setUserCredential(
        {
          ...cookiesData,
          ...obj,
        },
        "userData"
      );
      if (redirectPath) {
        window.location.href = `/${redirectPath}`;
        // history.push(`/${redirectPath}`)
      } else {
        window.location.href = "/" + cookiesData?.out_db_obj_code;
        // history.push("/" + cookiesData?.out_db_obj_code)
      }
      if (res?.data?.msg) {
      }
    } else if (res?.data?.out_parameters?.out_ret_code === 8) {
      dispatch(
        setNewMessage({
          message: res?.data?.out_parameters?.out_error_msg,
          type: "Error",
          show: true,
        })
      );
      handleBackToLogin();
    } else {
      if (res?.data?.out_parameters?.out_error_msg) {
        dispatch(
          setNewMessage({
            message: res?.data?.out_parameters?.out_error_msg,
            type: "Error",
            show: true,
          })
        );
      }
    }
  };

  const onResendBtnClick = async () => {
    if (otpTimerCookiesData) {
      setDisabledBtn(false)
      await expireOtpAPI(false);
      const data = {
        in_user_id: cookiesData.out_user_id.toString(),
        in_tenant_id: cookiesData.out_tenant_id,
        in_user_email: cookiesData.out_user_name,
        in_first_name: cookiesData.out_user_first_name,
        in_last_name: cookiesData.out_user_last_name,
      };
      Action.otpGenerationAsync(data).then((res) => {
        clearTimer(getResendTime());
        dispatch(
          setNewMessage({
            message:
              "We’ve sent a One Time Password (OTP) to your registered email address",
            type: "Success",
            show: true,
          })
        );
        if (res.data.msg) {
        }
      });
    }
  };

  const handleBackToLogin = async () => {
    clearTimer();
    removeUserCredential();
    removeOtpData();
    if (redirectPath) {
      history.push(`/authentication?redirect=${redirectPath}`);
    } else {
      history.push("/authentication");
    }
  };

  return (
    <>
      <Form
        onSubmit={onSubmitClick}
        ignoreModified={true}
        validator={(value) => {
            return otpValidator(value, formSchema);
        }}
        render={(formRenderProps) => (
          <div className="form">
            {isRefreshLoading && <LoadingPanel />}

            <div className="formwrapper-elm-cont">
              <FormElement
                className="formwrapper-form-cont input-field-style"
                horizontal={true}
                id={"userForgotPasswordForm"}
              >
                <div className="formwrapper-elm-cont">
                  <Field
                    {...formSchema.otp}
                  />
                </div>
                {/* <div className="k-form-buttons"></div> */}
              </FormElement>
            </div>

            <div className="login-links" mt={0}>
              <span className="validmints">
                Valid for <span className="bold-text">{timer} mins</span>
              </span>
              {timer < "04:50" ? (
                <span className="otp">
                  <span
                    className={
                      timer > "04:50"
                        ? "disabled resend-button"
                        : "resend-button"
                    }
                    onClick={() => {
                      onResendBtnClick();
                    }}
                  >
                    {" "}
                    Resend OTP
                  </span>
                </span>
              ) : (
                false
              )}
            </div>
            <div className="loginbuttoncont">
              <BackToLoginComponent clickHandler={handleBackToLogin} />
              <div className="form-button line">
                <Button
                  type={"submit"}
                  className="btn-active bttn"
                  form="userForgotPasswordForm"
                  disabled={disabledBtn}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        )}
      />
    </>
  );
};
export default React.memo(Otp);
