/* eslint-disable no-useless-concat */
import React from "react";
import moment from "moment";
import { emailRegex } from "./regex";
import projectSetting from "src/setting";
import { setUnSavedChangesPopup } from "src/redux/actions/setting-actions";
import { store } from "src/redux/store";
import { transformDateTime } from "src/lib/date-time.util";

export const isEmpty = (mixedVar) => {
    let key;
    let i;
    let len;
    const emptyValues = ["undefined", null, false, 0, "", "0", undefined];
    for (i = 0, len = emptyValues.length; i < len; i++) {
        if (mixedVar === emptyValues[i]) {
            return true;
        }
    }
    if (typeof mixedVar === "object") {
        for (key in mixedVar) {
            if (key) {
                return false;
            }
        }
        return true;
    }
    return false;
};

export const ResultGridDataMapper_V2 = (data) => {
    if (data) {
        let tempData = JSON.parse(JSON.stringify(data));
        let tempArray = [];
        JSON.parse(tempData.replace(/\r?\n|\r/g, ""));
        let tempDataValue = JSON.parse(tempData);
        let dataField = tempDataValue.resultset.rows[0].row;

        dataField.map((item) => {
            let obj = {};
            item.field.map((xItem) => {
                let val = Object.values(xItem);

                if (val[1] && val[1].name) {
                    let string = val[1].name.replace(".", "$");
                    obj[string] = val[0];
                } else {
                    let string = val[0].name.replace(".", "$");
                    obj[string] = "";
                }
                return 0;
            });
            tempArray.push(obj);
            return 0;
        });

        return tempArray;
    }
};

export const ResultGridDataMapper = (data, alias = null) => {
    if (data) {
        let tempData = JSON.parse(JSON.stringify(data));
        let tempArray = [];
        JSON.parse(tempData.replace(/\r?\n|\r/g, ""));
        let tempDataValue = JSON.parse(tempData);
        let dataField = tempDataValue.resultset.rows[0].row;

        dataField.map((item) => {
            let obj = {};
            item.field.map((xItem) => {
                let val = Object.values(xItem);

                if (val[1] && val[1].name) {
                    let string = val[1].name.replace(".", "$");
                    obj[string] = val[0];
                } else {
                    let string = val[0].name.replace(".", "$");
                    obj[string] = "";
                }
                return 0;
            });
            tempArray.push(obj);
            return 0;
        });
        let outputArray = transformInputToOutput(tempArray, alias);
        return outputArray;
    }
};

function transformInputToOutput(inputArray, alias = null) {
    const outputArray = inputArray.map((input) => {
        const output = {};
        for (const key in input) {
            if (input.hasOwnProperty(key)) {
                const outputKey =
                    alias === null
                        ? key.replace(/^.+\$/, "")
                        : key.replace(/\$/, ".");
                output[outputKey] = input[key];
            }
        }
        return output;
    });
    return outputArray;
}

export const GridColumnDataMapper = (
    data,
    defaultAlias = "a",
    dynamicShow = false,
    module
) => {
    let tempArrayData = [];
    let tempData = JSON.parse(JSON.stringify(data));
    let tempArray = [];

    if (tempData !== null) {
        JSON.parse(tempData.replace(/\r?\n|\r/g, ""));
        let tempDataValue = JSON.parse(tempData);
        let dataField = tempDataValue.resultset.metadata[0].field;

        dataField.map((item) => {
            tempArray.push(item.$);
            return 0;
        });
        tempArray.map((item, i) => {
            const itemNameArr = item.name.split(".");
            let itemAlias = module === "event" ? "" : defaultAlias;
            let itemName = itemNameArr[0];
            if (itemNameArr.length > 1) {
                itemAlias = itemNameArr[0];
                itemName = itemNameArr[1];
            }

            let obj = {};

            let col_min_width = 0;
            switch (typeof item?.min_width) {
                case "string":
                    col_min_width = parseInt(item.min_width);
                    break;
                case "number":
                    col_min_width = item.min_width;
                    break;
                default:
                    col_min_width = 0;
                    break;
            }
            if (item.type === 'number' && dataField[i].format[0]) {
                obj.format = dataField[i].format[0] === '{0:n}' ? '{0:0.00}' : dataField[i].format[0];
            } else {
                obj.format = dataField[i].format[0] ? dataField[i].format[0] : "";
            }
            //set columns value
            obj.field = itemName;
            obj.alias = itemAlias;
            obj.title = item.label;
            obj.type = item.type;
            obj.filterable = item.filterable;
            obj.gname = item.gname ? item.gname : null;
            obj.encrypted = item.encrypted === "0" ? false : true;
            obj.min_width = col_min_width;
            //set fields value
            obj.aggregates = dataField[i].aggregate
                ? dataField[i].aggregate[0]
                : "";
            obj.footerTemplate = dataField[i].footerTemplate
                ? dataField[i].footerTemplate[0]
                : "";
            obj.groupHeaderTemplate = dataField[i].groupHeaderTemplate
                ? dataField[i].groupHeaderTemplate[0]
                : "";
            obj.groupFooterTemplate = dataField[i].groupFooterTemplate
                ? dataField[i].groupFooterTemplate[0]
                : "";
            obj.isRequired = item.is_nullable == "0" ? true : false;

            if (obj.isRequired && obj.title && !item.required_msg) {
                item.required_msg = obj.title + " is Required";
            }
            obj.required_msg = item.required_msg;
            //set hard-coded values
            obj.show = !dynamicShow
                ? true
                : item.hidden && item.hidden === "1"
                    ? true
                    : false;
            obj.minResizableWidth = 60;
            obj.isEditable = item.editable === "0" ? false : true;

            tempArrayData.push(obj);
            return 0;
        });
    }
    return tempArrayData;
};

export const validateEmail = (emailAdress) => {
    let regexEmail = emailRegex;
    if (emailAdress.match(regexEmail)) {
        return true;
    } else {
        return false;
    }
};

export const defaultCell = (props) => {
    return (
        <td
            title={
                props.field !== "period_type_mstr_key"
                    ? props.field === "job_type_mstr_key"
                        ? props.dataItem["job_type_name"]
                        : props.dataItem[props.field]
                    : props.dataItem["period_type_name"]
            }
        >
            {props.field !== "period_type_mstr_key"
                ? props.field === "job_type_mstr_key"
                    ? props.dataItem["job_type_name"]
                    : props.dataItem[props.field]
                : props.dataItem["period_type_name"]}
        </td>
    );
};

export const disabledCell = (props) => {
    return (
        <td title={props.dataItem[props.field]}>
            {props.dataItem[props.field]}
        </td>
    );
};

export const bgAssignCell = (props) => {
    let val =
        props.dataItem[props.field] !== ""
            ? props.dataItem[props.field] === 1
                ? "true"
                : "false"
            : "";
    return <td title={val}>{val}</td>;
};

export const booleanCell = (props) => {
    let val =
        props.dataItem[props.field] !== ""
            ? props.dataItem[props.field] === 1
                ? "true"
                : "false"
            : "";
    return <td title={val}>{val}</td>;
};

export const formatDate = (date, dateFormat) => {
    return transformDateTime({
        date: date,
        format: dateFormat ? dateFormat : "MM/DD/YYYY hh:mm:ss",
    })
};

export const formatDateforTimeZone = (date) => {
    // Function to convert a date string to a specific timezone and format it like '2024-07-25 09:45:01'
    function convertToLocalFormat(dateString, timeZone) {
        const date = new Date(dateString);
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,
            timeZone,
        };
        const formatter = new Intl.DateTimeFormat('en-US', options);
        const parts = formatter.formatToParts(date);
        const formattedDate = parts.map(({ type, value }) => {
            switch (type) {
                case 'year':
                case 'month':
                case 'day':
                    return value.padStart(2, '0');
                case 'hour':
                case 'minute':
                case 'second':
                    return value.padStart(2, '0');
                case 'literal':
                    return type === 'literal' && value === ', ' ? ' ' : value;
                default:
                    return value;
            }
        }).join('');
        return formattedDate.replace(/\//g, '-');
    }

    // Example usage

    // Convert constA to the desired format in a specific timezone
    return convertToLocalFormat(date, 'America/New_York'); // Replace with desired timezone



}
export const formatSystemDate = (date) => {
    return transformDateTime({
        date: date,
        format: "YYYY-MM-DD hh:mm:ss",
    })
};

export const formatDateCalendar = (date) => {
    return transformDateTime({
        date: date,
        format: "MM/DD/YYYY 00:00:00",
    })
};

export const formatDateOnly = (date) => {
    return transformDateTime({
        date: date,
        format: "YYYY-MM-DD",
    })
}

export const formatDateOnlyMoment = (date, format="YYYY-MM-DD") => {
    return moment(date).format(format)
}

export const formatDateTime = (date) => {
    const originalDate = new Date(date);
    const options = {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        timeZone: "UTC",
    };
    const formattedDate = originalDate.toLocaleDateString("en-US", options);
    return formattedDate;
};

export const formatCalendarDate = (date) => {
    const originalDate = new Date(date);
    const options = {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
        timeZone: "UTC",
    };
    const formattedDate = originalDate.toLocaleDateString("en-IN", options);
    return formattedDate;
};

export const convertDateFormat = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
};

export const dateOnlyFormateCell = (props) => {
    let date = props.dataItem[props.field];
    return (
        <td title={date !== null ? formatCalendarDate(date) : ""}>
            {date !== null ? formatCalendarDate(date) : ""}
        </td>
    );
};

export const dateFormateCell = (props) => {
    let date = props.dataItem[props.field];
    return (
        <td title={date !== null ? formatDate(date) : ""}>
            {date !== null ? formatDate(date) : ""}
        </td>
    );
};

export const dateFormateCellForJob = (props) => {
    let date = props.dataItem[props.field];
    return (
        <td title={date !== null ? formatDateforTimeZone(date) : ""}>
            {date !== null ? formatDateforTimeZone(date) : ""}
        </td>
    );
};

export const dateTimeFormateCell = (props) => {
    let date = props.dataItem[props.field];
    return (
        <td title={date !== null ? formatDateTime(date) : ""}>
            {date !== null ? formatDateTime(date) : ""}
        </td>
    );
};

let mergeArray = [];
export const mergeArrayObjects = (arr1, arr2) => {
    arr1.map((item, i) => {
        if (arr2.length > 0) {
            let index = arr2.findIndex((x) => x.field === item.field);
            if (index > -1) {
                mergeArray[index] = item;
            } else {
                mergeArray.push(item);
            }
        } else {
            mergeArray.push(item);
        }
        return 0;
    });
    return mergeArray;
};

export const redirectUrl = (url) => {
    window.location.href = url;
};

export const generateComboBoxFilterString = (val, field) => {
    let filterValue = {
        "filter[filters][0][value]": val,
        "filter[filters][0][field]": field,
        "filter[filters][0][operator]": "contains",
        "filter[filters][0][ignoreCase]": true,
        "filter[logic]": "and",
    };

    return filterValue;
};

export const generateComboBoxFilterStringVal = (
    filter,
    defaultAlias = "a",
    otherFilter = ""
) => {
    let filterString = "NULL";

    let filterCol = [];
    let filterArr = [];

    filter["filters"].forEach((item, filterKey) => {
        if (!hasOwnProperty(filterArr["value"])) {
            return filterString;
        }

        if (defaultAlias !== "") {
            filter["filters"][filterKey]["field"] =
                defaultAlias + ".$." + filterArr["field"];
        }

        delete filter["filters"][filterKey]["ignoreCase"];
        // ksort(filter['filters'][filterKey]);

        filterCol[filterKey]["type"] = "string";
        filterCol[filterKey]["field"] = filter["filters"][filterKey]["field"];
        filterCol[filterKey]["encrypted"] = false;
    });
};

export const scrollTop = (ref) => {
    if (ref) {
        // ref.current.firstElementChild.className = 'accordian-row-head active'
        window.scrollTo({
            top: ref.current.element.offsetTop,
            behavior: "smooth",
        });
        return;
    }
    setTimeout(scrollTop, 100);
};

export const removeDuplicate = (value, key) => {
    return [...new Map(value.map((x) => [key(x), x])).values()];
};

export const downloadURI = async (fileName, fileLink) => {
    const link = await document.createElement("a");
    link.download = fileName;
    link.href = fileLink;
    await link.click();
};

const nullFilterField = (fieldName, dataType = "string") => {
    switch (dataType) {
        case "string":
        case "date":
            return "IFNULL(" + fieldName + ", '')";
        case "number":
            return "IFNULL(" + fieldName + ", 0)";
        default:
            return fieldName;
    }
};
const dateConverstion = (value) => {
    var date = new Date(value);

    // Get year, month, and day part from the date
    var year = date.toLocaleString("default", { year: "numeric" });
    var month = date.toLocaleString("default", { month: "2-digit" });
    var day = date.toLocaleString("default", { day: "2-digit" });

    return year + "-" + month + "-" + day;
};
const convertFilterClause = (
    fieldName,
    operatorStr,
    valueStr,
    fieldType = "string"
) => {
    let returnVal = valueStr;

    const quoteOp = ["eq", "neq", "gt", "lt", "gte", "lte"];
    const quoteType = ["string", "date", "text"];

    if (fieldType === "string" && typeof valueStr === "string" && valueStr) {
        returnVal = valueStr.addslashes();
    }

    if (fieldType === "text" && typeof valueStr === "string" && valueStr) {
        returnVal = valueStr.addslashes();
    }

    if (fieldType === "date" && valueStr) {
        returnVal = dateConverstion(valueStr);
    }
    if (
        quoteOp.indexOf(operatorStr) > -1 &&
        quoteType.indexOf(fieldType) > -1
    ) {
        returnVal =
            "'" +
            (operatorStr === "string"
                ? returnVal.convertYmdDate()
                : returnVal) +
            "'";
    }

    if (fieldType !== "date" && fieldType !== "number") {
        switch (operatorStr) {
            case "eq":
                return fieldName + " = " + returnVal;
            case "neq":
                return (
                    nullFilterField(fieldName, fieldType) + " <> " + returnVal
                );
            case "doesnotcontain":
                return (
                    nullFilterField(fieldName) +
                    " NOT LIKE '%" +
                    returnVal +
                    "%'"
                );
            case "contains":
                return fieldName + " LIKE '%" + returnVal + "%'";
            case "startswith":
                return fieldName + " LIKE '" + returnVal + "%'";
            case "endswith":
                return fieldName + " LIKE '%" + returnVal + "'";
            case "gt":
                return (
                    nullFilterField(fieldName, fieldType) + " > " + returnVal
                );
            case "lt":
                return (
                    nullFilterField(fieldName, fieldType) + " < " + returnVal
                );
            case "gte":
                return (
                    nullFilterField(fieldName, fieldType) + " >= " + returnVal
                );
            case "lte":
                return (
                    nullFilterField(fieldName, fieldType) + " <= " + returnVal
                );
            case "isempty":
                return nullFilterField(fieldName, fieldType) + " = ''";
            case "isnotempty":
                return nullFilterField(fieldName, fieldType) + " <> ''";
            default:
                throw new Error("operator not defined in converter method");
        }
    }

    // Add condition for check null and nullable value
    if (fieldType === "number") {
        switch (operatorStr) {
            case "eq":
                return fieldName + " = " + returnVal;
            case "neq":
                return (
                    nullFilterField(fieldName, fieldType) + " <> " + returnVal
                );
            case "doesnotcontain":
                return (
                    nullFilterField(fieldName) +
                    " NOT LIKE '%" +
                    returnVal +
                    "%'"
                );
            case "contains":
                return fieldName + " LIKE '%" + returnVal + "%'";
            case "startswith":
                return fieldName + " LIKE '" + returnVal + "%'";
            case "endswith":
                return fieldName + " LIKE '%" + returnVal + "'";
            case "gt":
                return (
                    nullFilterField(fieldName, fieldType) + " > " + returnVal
                );
            case "lt":
                return (
                    nullFilterField(fieldName, fieldType) + " < " + returnVal
                );
            case "gte":
                return (
                    nullFilterField(fieldName, fieldType) + " >= " + returnVal
                );
            case "lte":
                return (
                    nullFilterField(fieldName, fieldType) + " <= " + returnVal
                );
            case "isempty":
                return fieldName + " is null";
            case "isnotempty":
                return fieldName + " is not null";
            default:
                throw new Error("operator not defined in converter method");
        }
    }
    if (fieldType === "date") {
        switch (operatorStr) {
            case "eq":
                return "DATE (" + fieldName + ")" + " = " + returnVal;
            case "neq":
                return ("DATE (" + fieldName + ")" + " <> " + returnVal
                    + " OR " +
                    nullFilterField(
                        fieldName,
                        fieldType
                    ) +
                    " = '' " +
                    " OR " +
                    nullFilterField(
                        fieldName,
                        fieldType
                    ) +
                    " = '0000-00-00 00:00:00'"

                )
            case "gt":
                return "DATE (" + fieldName + ")" + " > " + returnVal;
            case "lt":
                return (
                    nullFilterField(
                        fieldName,
                        "DATE (" + fieldName + ")" + " = "
                    ) +
                    " < " +
                    returnVal
                );
            case "gte":
                return (
                    nullFilterField(
                        fieldName,
                        "DATE (" + fieldName + ")" + " = "
                    ) +
                    " >= " +
                    returnVal
                );
            case "lte":
                return ("DATE (" + fieldName + ")" + " <= " + returnVal
                    + " OR " +
                    nullFilterField(
                        fieldName,
                        fieldType
                    ) +
                    " = '' " +
                    " OR " +
                    nullFilterField(
                        fieldName,
                        fieldType
                    ) +
                    " = '0000-00-00 00:00:00'"

                )
            case "isnull":
                return (
                    nullFilterField(
                        fieldName,
                        fieldType
                    ) +
                    " = '' " +
                    " OR " +
                    nullFilterField(
                        fieldName,
                        fieldType
                    ) +
                    " = '0000-00-00 00:00:00'"
                );
            case "isnotnull":
                return (
                    nullFilterField(
                        fieldName,
                        fieldType
                    ) +
                    " != '' " +
                    " AND " +
                    nullFilterField(
                        fieldName,
                        fieldType
                    ) +
                    " <> '0000-00-00 00:00:00'"
                );
            default:
                throw new Error("operator not defined in converter method");
        }
    }
};

export const getfilterDataString_V2 = (columnMenuData, gridColumns) => {
    let filterString = "";

    columnMenuData.map((filterItem, j) => {
        const filters = filterItem.filters;

        if (!filters.length) {
            throw new Error("Filter should not be empty");
        }

        let thisFilter = filters[0];
        let columnObj = gridColumns.find(
            (o) =>
                (o.alias ? `${o.alias}$${o.field}` : o.field) ===
                thisFilter.field
        );

        let thisField = thisFilter.field.replace("$", ".");

        filterString += " AND ( ";
        filterString += convertFilterClause(
            thisField,
            thisFilter.operator,
            thisFilter.value,
            columnObj.type
        );

        if (filterItem.filters.length > 1) {
            thisFilter = filters[1];
            columnObj = gridColumns.find(
                (o) =>
                    (o.alias ? `${o.alias}$${o.field}` : o.field) ===
                    thisFilter.field
            );

            thisField = thisFilter.field.replace("$", ".");

            filterString += " " + filterItem.logic.toUpperCase() + " ";
            filterString += convertFilterClause(
                thisField,
                thisFilter.operator,
                thisFilter.value,
                columnObj.type
            );
        }

        filterString += " ) ";

        return 0;
    });

    return filterString;
};

const convertResultFilterClause = (
    fieldName,
    operatorStr,
    valueStr,
    fieldType,
    encryptKey
) => {
    let returnVal = valueStr;

    const quoteOp = ["eq", "neq", "gt", "lt", "gte", "lte"];
    const quoteType = ["string", "date", "text"];

    if (fieldType === "string" && valueStr) {
        returnVal = valueStr.addslashes();
    }

    if (fieldType === "text" && valueStr) {
        returnVal = valueStr.addslashes();
    }

    if (fieldType === "date" && valueStr) {
        returnVal = dateConverstion(valueStr);
    }
    if (
        quoteOp.indexOf(operatorStr) > -1 &&
        quoteType.indexOf(fieldType) > -1
    ) {
        returnVal =
            "'" +
            (operatorStr === "string"
                ? returnVal.convertYmdDate()
                : returnVal) +
            "'";
    }
    if (fieldType !== "date") {
        switch (operatorStr) {
            case "eq":
                return `(${fieldName}, '${encryptKey}')` + " = " + returnVal;
            case "neq":
                return (
                    nullFilterField(
                        `(${fieldName}, '${encryptKey}')`,
                        fieldType
                    ) +
                    " <> " +
                    returnVal
                );
            case "doesnotcontain":
                return (
                    nullFilterField(`(${fieldName}, '${encryptKey}')`) +
                    " NOT LIKE '%" +
                    returnVal +
                    "%'"
                );
            case "contains":
                return (
                    `(${fieldName}, '${encryptKey}')` + ` LIKE '%${returnVal}%'`
                );
            case "startswith":
                return (
                    `(${fieldName}, '${encryptKey}')` +
                    " LIKE '" +
                    returnVal +
                    "%'"
                );
            case "endswith":
                return (
                    `(${fieldName}, '${encryptKey}')` +
                    " LIKE '%" +
                    returnVal +
                    "'"
                );
            case "gt":
                return (
                    nullFilterField(
                        `(${fieldName}, '${encryptKey}')`,
                        fieldType
                    ) +
                    " > " +
                    returnVal
                );
            case "lt":
                return (
                    nullFilterField(
                        `(${fieldName}, '${encryptKey}')`,
                        fieldType
                    ) +
                    " < " +
                    returnVal
                );
            case "gte":
                return (
                    nullFilterField(
                        `(${fieldName}, '${encryptKey}')`,
                        fieldType
                    ) +
                    " >= " +
                    returnVal
                );
            case "lte":
                return (
                    nullFilterField(
                        `(${fieldName}, '${encryptKey}')`,
                        fieldType
                    ) +
                    " <= " +
                    returnVal
                );
            case "isempty":
                return (
                    nullFilterField(
                        `(${fieldName}, '${encryptKey}')`,
                        fieldType
                    ) + " = ''"
                );
            case "isnotempty":
                return (
                    nullFilterField(
                        `(${fieldName}, '${encryptKey}')`,
                        fieldType
                    ) + " <> ''"
                );
            default:
                throw new Error("operator not defined in converter method");
        }
    }

    if (fieldType === "date") {
        switch (operatorStr) {
            case "eq":
                return (
                    "DATE (" +
                    `(${fieldName}, '${encryptKey}')` +
                    ")" +
                    " = " +
                    returnVal
                );
            case "neq":
                return (
                    nullFilterField(
                        fieldName,
                        "DATE (" + fieldName + ")" + " = "
                    ) +
                    " <> " +
                    returnVal
                );
            case "gt":
                return (
                    nullFilterField(
                        fieldName,
                        "DATE (" + fieldName + ")" + " = "
                    ) +
                    " > " +
                    returnVal
                );
            case "lt":
                return (
                    nullFilterField(
                        fieldName,
                        "DATE (" + fieldName + ")" + " = "
                    ) +
                    " < " +
                    returnVal
                );
            case "gte":
                return (
                    nullFilterField(
                        fieldName,
                        "DATE (" + fieldName + ")" + " = "
                    ) +
                    " >= " +
                    returnVal
                );
            case "lte":
                return (
                    nullFilterField(
                        fieldName,
                        "DATE (" + fieldName + ")" + " = "
                    ) +
                    " <= " +
                    returnVal
                );
            case "isnull":
                return (
                    nullFilterField(
                        fieldName,
                        "DATE (" + fieldName + ")" + " = "
                    ) + " IS NULL"
                );
            case "isnotnull":
                return (
                    nullFilterField(
                        fieldName,
                        "DATE (" + fieldName + ")" + " = "
                    ) + " IS NOT NULL"
                );
            default:
                throw new Error("operator not defined in converter method");
        }
    }
};

export const getResultFilterDataString = (
    columnMenuData,
    gridColumns,
    encryptKey
) => {
    let filterString = "";

    columnMenuData.map((filterItem, j) => {
        const filters = filterItem.filters;

        if (!filters.length) {
            throw new Error("Filter should not be empty");
        }

        let thisFilter = filters[0];
        let columnObj = gridColumns.find((o) => o.field === thisFilter.field);

        let thisField = columnObj.alias
            ? columnObj.alias + "." + thisFilter.field
            : thisFilter.field;

        if (thisFilter.field === "full_name") {
            filterString += " AND ( (procare_system.fx_aes_decrypt";
            filterString += convertResultFilterClause(
                thisField,
                thisFilter.operator,
                thisFilter.value,
                columnObj.type,
                encryptKey
            );
        } else {
            filterString += " AND ( ( ";
            filterString += convertFilterClause(
                thisField,
                thisFilter.operator,
                thisFilter.value,
                columnObj.type,
                encryptKey
            );
        }

        if (filterItem.filters.length > 1) {
            thisFilter = filters[1];
            columnObj = gridColumns.find((o) => o.field === thisFilter.field);

            thisField = columnObj.alias
                ? columnObj.alias + "." + thisFilter.field
                : thisFilter.field;

            filterString += " " + filterItem.logic.toUpperCase() + " ";
            filterString += convertResultFilterClause(
                thisField,
                thisFilter.operator,
                thisFilter.value,
                columnObj.type,
                encryptKey
            );
        }
        filterString += ` ) ) `;
        return 0;
    });
    return filterString;
};

export const getfilterDataString = (columnMenuData, gridColumns, module) => {
    let filterString = "";

    columnMenuData.map((filterItem, j) => {
        const filters = filterItem.filters;

        if (!filters.length) {
            throw new Error("Filter should not be empty");
        }

        let thisFilter = filters[0];
        let columnObj = gridColumns.find((o) => o.field === thisFilter.field);
        if (module === "event") {
            if (columnObj.alias === "t") {
                columnObj.alias = "";
            }
            if (columnObj.alias === "d") {
                columnObj.alias = "";
            }
            if (columnObj.alias === "c") {
                columnObj.alias = "";
            }
            if (columnObj.alias === "e") {
                columnObj.alias = "";
            }
        }
        if (module === "provider-position") {
            columnObj.alias = "";
        }
        let thisField = columnObj.alias
            ? columnObj.alias + "." + thisFilter.field
            : thisFilter.field;
        filterString += " AND ( ";
        filterString += convertFilterClause(
            thisField,
            thisFilter.operator,
            thisFilter.value,
            columnObj.type
        );

        if (filterItem.filters.length > 1) {
            thisFilter = filters[1];
            columnObj = gridColumns.find((o) => o.field === thisFilter.field);

            thisField = columnObj.alias
                ? columnObj.alias + "." + thisFilter.field
                : thisFilter.field;

            filterString += " " + filterItem.logic.toUpperCase() + " ";
            filterString += convertFilterClause(
                thisField,
                thisFilter.operator,
                thisFilter.value,
                columnObj.type
            );
        }

        filterString += " ) ";

        return 0;
    });

    return filterString;
};

export const changeCancelBtnState = (
    state = "disable",
    id = "grid-incell-cancel"
) => {
    if (!document.getElementById(id)) {
        return false;
    }
    switch (state) {
        case "enable":
            document.getElementById(id).enableKendoBtn();
            break;
        default:
            window.$_UNSAVED_CHANGES_FLAG = false;
            document.getElementById(id).disableKendoBtn();
    }
};

export const changeUpdateBtnState = (
    state = "disable",
    id = "grid-incell-update"
) => {
    if (!document.getElementById(id)) {
        return false;
    }
    switch (state) {
        case "enable":
            document.getElementById(id).enableKendoBtn();
            break;
        default:
            window.$_UNSAVED_CHANGES_FLAG = false;
            document.getElementById(id).disableKendoBtn();
    }
};

export const camelize = (str) => {
    return str
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join("_");
};

export const sortArrayOfObjectsAlphabetically = (arr, property) => {
    return arr.sort((a, b) => a[property].localeCompare(b[property]));
};

export const replaceBlankAndMerge = (inputString, module = null) => {
    const conditions = inputString.split("AND");
    const mergedConditions = [];
    const conditionToReplace = "a.unit_type_group_fx = 'blank'";
    const nullCheck = "a.unit_type_group_fx IS NULL";
    conditions.forEach((condition) => {
        if (condition.includes(" = blank")) {
            let blankvalue = module === "resultName" ? "=0" : "IS NULL";
            condition = condition.replace("= blank", blankvalue).trim();
        } else if (condition.includes(conditionToReplace)) {
            condition = condition.replace(conditionToReplace, nullCheck).trim();
        }
        mergedConditions.push(condition);
    });

    return mergedConditions.join(" AND ");
};

export function microTime() {
    var now = new Date().getTime() / 1000;
    var s = parseInt(now, 10);
    return String((now - s).toFixed(6)).slice(2, 8);
}

export function executeGetColumnOrderInfo(request, module) {
    let columnOrder = [...request];
    let columnStr = "";
    // Iterate through columnOrder and construct columnStr
    columnOrder.forEach((columArr, index) => {
        let filter_table_name = columArr.gname;
        let filter_logical_name =
            module === "event"
                ? "event_grid"
                : module === "provider-position"
                    ? "pos_prov_grid"
                    : module === "compensation-detail"
                        ? "comp_report_grid"
                        : "";
        let filter_column_name =
            module === "event" ||
                module === "job-history" ||
                module === "role" ||
                module === "provider-position" ||
                module === "compensation-detail"
                ? columArr.alias === "" || columArr.alias === null
                    ? columArr.field
                    : columArr.alias + "." + columArr.field
                : columArr.alias + "." + columArr.field;
        let columnIndex =
            module === "role" || module === "rule"
                ? columArr.ariaColumnIndex - 1
                : columArr.ariaColumnIndex
                    ? columArr.ariaColumnIndex
                    : index + 1;
        if (filter_column_name.startsWith("egt_")) {
            filter_column_name = filter_column_name.replace("egt_", "");
        }
        if (filter_column_name === 'provider_eff_start_date' || filter_column_name === 'eff_start_date') {
            filter_column_name = 'eff_start_date'
        }

        columnStr += `"${filter_table_name}","${filter_logical_name}","${filter_column_name}",${columnIndex},${columArr.show ? 1 : 0
            }|`;
    });
    columnStr = columnStr.slice(0, -1);
    return columnStr;
}

let debounceTimer;
export const debounceForFunc = (func, delayTime) => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(func, delayTime);
};

export const isNullOrEmptyUndefined = (value) => {
    return value === null || value === undefined || value === "";
};

export const isNullOrEmptyUndefinedOrZero = (value) => {
    return value === null || value === undefined || value === "" || value === 0;
};

export const removeAliasAndDot = (inputString) => {
    // Remove alias and dot from columns (e.g., a.claim_id -> claim_id)
    const modifiedString = inputString.replace(/\b\w\./g, "");
    return modifiedString;
};


export const isSameRow = (currentKeys, newKeys) => {
    return (
        currentKeys.length === newKeys.length && currentKeys[0] === newKeys[0]
    );
};

export const isYearValidate = (date) => {
    let isValid = true;
    if (date) {
        let inputDate = moment(date, "DD/MM/YYYY");
        let minDateLimit = moment("01/01/1999", "DD/MM/YYYY");
        if (!inputDate.isAfter(minDateLimit)) {
            isValid = false;
        }
    }
    return isValid;
};

export const replaceEncKey = (query, encryptKey) => {
    const replacementString = "ENCKEY";
    const searchString = encryptKey;
    const regex = new RegExp(
        `(?<=procare_system\\.fx_aes_decrypt\\(f\\.full_name, ')[^']+(?=')`,
        "g"
    );
    return query?.replace(regex, function (match) {
        return match === searchString ? replacementString : match;
    });
};

export const getWidthFromTitle = (title) => {
    // Create a temporary span element
    const tempSpan = document.createElement("span");

    // Set the text content to the title
    tempSpan.textContent = title;

    // Set the styles to match your title styles if needed
    tempSpan.style.visibility = "hidden";
    tempSpan.style.position = "absolute";
    tempSpan.style.whiteSpace = "nowrap";
    tempSpan.style.fontSize = "16px"; // Adjust font size as needed

    // Append the span to the body to measure its width
    document.body.appendChild(tempSpan);

    // Get the width of the span
    const width = tempSpan.getBoundingClientRect().width;

    // Remove the temporary span
    document.body.removeChild(tempSpan);

    return width;
};

export const getReportScreenHeight = () => {
    if (!window) return "0";

    let contentHead = null;
    let contentBody = null;
    let gridHeight = 0;
    let calc = {};

    if (!contentHead) {
        contentHead = document.getElementsByClassName("content-head")[0];
    }

    if (!contentBody) {
        contentBody = document.getElementsByClassName("content-body")[0];
    }

    //get required elements

    calc["contentHeadHeight"] = contentHead?.offsetHeight;

    if (contentBody) {
        const bodyStyle = window.getElementStyle(contentBody);
        calc["contentBodyPaddingTop"] = parseFloat(bodyStyle["paddingTop"]);
        calc["contentBodyPaddingBottom"] = parseFloat(
            bodyStyle["paddingBottom"]
        );
    }

    let topSection = document.getElementById("topSection");

    //object values addition
    const sum = Object.values(calc).reduce((a, b) => a + b);

    const content = document.getElementsByClassName("content")[0];
    if (content) {
        const contentHeight = content.offsetHeight;
        //set grid height
        gridHeight = Math.floor(contentHeight - sum);
        if (topSection) {
            gridHeight = gridHeight - topSection.offsetHeight;
        }
    }

    return gridHeight;
};

export const getGridHeight = (e) => {
    let contentHead = null;
    let contentBody = null;
    if (e) {
        //try to find content header and body element from k-pane (splitter)
        const kPane = e.closest(".k-pane");
        if (kPane) {
            contentHead = kPane.querySelector(".content-head");
            contentBody = kPane.querySelector(".content-body");
        }

        //try to find tabstrip element
        // const kTabStrip = e.closest(".k-tabstrip");
        // if (kTabStrip) {

        // }
    }

    if (!contentHead) {
        contentHead = document.getElementsByClassName("content-head")[0];
    }

    if (!contentBody) {
        contentBody = document.getElementsByClassName("content-body")[0];
    }

    //get required elements
    const content = document.getElementsByClassName("content")[0];

    const contentHeight = content?.offsetHeight;
    const bodyStyle = window.getElementStyle(contentBody);

    let calc = {};
    calc["contentHeadHeight"] = contentHead?.offsetHeight;
    calc["contentBodyPaddingTop"] = parseFloat(bodyStyle["paddingTop"]);
    calc["contentBodyPaddingBottom"] = parseFloat(bodyStyle["paddingBottom"]);

    // const formButtons = document.getElementsByClassName("k-form-buttons");

    // // if (formButtons.length === 0) {
    // //     calc["contentHeadHeight"] = contentHead.offsetHeight + 10;
    // // }

    let filterLinks = document.getElementsByClassName("Filter-links");

    let filterLinksAudit = document.getElementsByClassName(
        "Filter-links auditlogform"
    );

    let effVersionTab = document.getElementsByClassName("effectiversionbg");

    let tabStripItem = document.getElementsByClassName(
        "k-tabstrip-items-wrapper"
    );

    let tabItem = document.getElementsByClassName("k-hstack");

    let tabStrip = document.getElementsByClassName("k-tabstrip");

    //object values addition
    const sum = Object.values(calc).reduce((a, b) => a + b);

    //set grid height
    let gridHeight = Math.floor(parseInt(contentHeight) - parseInt(sum));

    if (filterLinks.length > 0) {
        gridHeight = gridHeight - filterLinks[0]?.offsetHeight;
    }

    if (filterLinksAudit.length > 0 && effVersionTab.length === 0) {
        gridHeight = gridHeight - filterLinksAudit[0]?.offsetHeight;
      //  let pageTitle = document.getElementsByClassName("Page-title");
        // if (pageTitle.length > 0) {
        //     gridHeight = gridHeight - pageTitle[0]?.offsetHeight;
        // }
    }

    if (tabStripItem.length > 0) {
        gridHeight = gridHeight - tabStripItem[0]?.offsetHeight;
    }
 
    if (effVersionTab.length > 0) {
        if (tabStrip.length > 0) {
            gridHeight = gridHeight - tabStrip[0]?.offsetHeight;
        }
        gridHeight = gridHeight - effVersionTab[0]?.offsetHeight;

        if (tabItem.length > 0) {
            gridHeight = gridHeight - tabItem[0]?.offsetHeight;
        }
    }
    let ele = document.getElementsByClassName("k-window-content");
    if (ele.length > 0) {
        let kendoFooterButtons = document.getElementsByClassName(
            "k-window-buttongroup"
        );
        let quickLinks = document.getElementsByClassName("eventtypequicklinks");
        let index = ele.length > 1 ? 1 : 0
        if (quickLinks.length > 0 && quickLinks[0]?.offsetHeight) {
            gridHeight =
                ele[index]?.offsetHeight - quickLinks[0]?.offsetHeight - 15;
        } else {
            let dtuUpload = document.getElementsByClassName("upload-config");
            if (dtuUpload.length === 0) {
                let gridLength = ele.length > 1 ? 1 : 0
                gridHeight =
                    ele[gridLength]?.offsetHeight -
                    (kendoFooterButtons[gridLength]?.offsetHeight || 0);
            }

        }
        let jobToQueue = document.getElementsByClassName("addjobquecont");
        if (jobToQueue.length > 0) {
            gridHeight = gridHeight - jobToQueue[0]?.offsetHeight;
        }

        let jobDetails = document.getElementsByClassName("jobdetailspopup");
        if (jobDetails.length > 0) {
            gridHeight = gridHeight + 30;
        }

        let customTableDataPopup = document.getElementsByClassName("customtabledatapopup")
        if (customTableDataPopup.length > 0) {
            gridHeight = gridHeight - customTableDataPopup[0]?.offsetHeight - 130;
        }
        let customFilterDropdown = document.getElementById("#custom-filter-dropdown")
        if (customFilterDropdown && customFilterDropdown.length > 0) {
            gridHeight = gridHeight - customFilterDropdown[0]?.offsetHeight;
        }


        let customTable = document.getElementsByClassName(
            "customtablerowdialog"
        );
        if (customTable.length > 0) {
            gridHeight = gridHeight - customTable[0]?.offsetHeight - 50;
        }

        let prov = document.getElementsByClassName("twocolumlabel")
        if (prov.length > 0) {
            gridHeight = gridHeight + 15;
        }
    }

    let customTableDataRow = document.getElementsByClassName("custom-table-filter-dropdown")

    if (customTableDataRow.length > 0) {
        gridHeight = gridHeight - customTableDataRow[0]?.offsetHeight;
    }

    // let resultFooter = document.getElementsByClassName("result-group-footer")

    // if(resultFooter.length > 0){
    //     gridHeight = gridHeight + resultFooter[0]?.offsetHeight;
    // }

    let dtuErrorLogs = document.getElementsByClassName("DataTable-dtuLogs")

    if (dtuErrorLogs.length > 0) {
        gridHeight = gridHeight + 15;
    }
    let padTop = document.getElementsByClassName("padtop15");
    if (padTop.length > 0) {
        gridHeight = gridHeight - 30;
    }

    return gridHeight + "px";
};

export const bytesToKB = (sizeInBytes) => {
    return (sizeInBytes / 1024).toFixed(2) + " KB";
};

export const toCamelCase = (str) => {
    return str
        .toLowerCase()
        .replace(/(?:^|\s)\w/g, (match) => match.toUpperCase());
};

export const convertToSnakeCase = (input) => {
    // Replace spaces with underscores
    let output = input.replace(/ /g, "_");

    // Convert to lowercase
    output = output.toLowerCase();

    return output;
};

export const getHostURL = () => {
    return projectSetting.baseURL;
};

export const formateDate = (date) => {
    const padZero = (num) => (num < 10 ? `0${num}` : num);
    const day = padZero(date.getDate());
    const month = padZero(date.getMonth() + 1); // Months are zero-based in JavaScript
    const year = date.getFullYear();
    return `${month}/${day}/${year} 00:00:00`
};

export const parseDateString = (dateString) => {
    const [datePart, timePart] = dateString.split(" ");
    const [day, month, year] = datePart.split("/");
    const [hours, minutes, seconds] = timePart.split(":");

    // Create a new Date object
    return new Date(year, month - 1, day, hours, minutes, seconds);
};

export const parseEffDateString = (dateString) => {
    const date = new Date(dateString);

    const padZero = (num) => (num < 10 ? `0${num}` : num);

    const day = padZero(date.getUTCDate());
    const month = padZero(date.getUTCMonth() + 1); // Months are zero-based
    const year = date.getUTCFullYear();

    return `${year}-${month}-${day}`;
};

export const performClickOnId = (id) => {
    document.getElementById(id).click();
};

export const showUnSavedChangesFn = (
    dispatch,
    actionFn,
    cancelId = "grid-incell-cancel",
    saveId = "grid-incell-update"
) => {
    const unsavedPopup = store?.getState()?.OTHER?.unsavedPopup;
    if (unsavedPopup?.isUnSavedChange) {
        dispatch(
            setUnSavedChangesPopup({
                isVisible: true,
                onDiscardChange: () => {
                    performClickOnId(cancelId);
                    actionFn?.();
                },
                onSaveChange: () => {
                    performClickOnId(saveId);
                    actionFn?.();
                },
            })
        );
    } else {
        actionFn?.();
    }
};

export const GridColumnDataMapperV2 = (
    data,
    defaultAlias = "a",
    dynamicShow = false,
    module
) => {
    if (!data) return [];

    let tempArrayData = [];
    let parsedData;

    try {
        parsedData = JSON.parse(data.replace(/\r?\n|\r/g, ""));
    } catch (e) {
        console.error("Error parsing JSON:", e);
        return [];
    }

    const dataField = parsedData?.resultset?.metadata[0]?.field || [];

    tempArrayData = dataField.map((item, i) => {
        const [alias, name] = item.name.split(".");
        const itemAlias = module === "event" ? "" : alias || defaultAlias;

        const col_min_width = parseInt(item.min_width) || 0;
        return {
            field: name || alias,
            alias: itemAlias,
            title: item.label,
            type: item.type,
            filterable: item.filterable,
            gname: item.gname || null,
            encrypted: item.encrypted !== "0",
            min_width: col_min_width,
            format: dataField[i].format?.[0] || "",
            aggregates: dataField[i].aggregate?.[0] || "",
            footerTemplate: dataField[i].footerTemplate?.[0] || "",
            groupHeaderTemplate: dataField[i].groupHeaderTemplate?.[0] || "",
            groupFooterTemplate: dataField[i].groupFooterTemplate?.[0] || "",
            isRequired: item.is_nullable == "0",
            required_msg: item.required_msg || `${item.label} is Required`,
            show: !dynamicShow || (item.hidden !== "1"),
            minResizableWidth: 60,
            isEditable: item.editable !== "0",
        };
    });

    return tempArrayData;
};

export const applyDateFormat = (dateValue, format) => {
    let dateFormat = format.replace("{0:", "").replace("}", "");
    let [datePart, timePart] = dateFormat.split(' ');
    let capitalizedResult = datePart
        .split('/') // Split by slashes
        .map(word => word.toUpperCase()) // Capitalize each part
        .join("/");

    let formattedDate = transformDateTime({
        date: dateValue,
        format: `${capitalizedResult} ${timePart}`
    });
    return formattedDate

}
export const applyFormat = (value, format) => {
    if (value === "") {
        return value;
    }

    //apply default format
    if (!format) {
        format = "{0:0}";
    }

    //trim 0 if decimal not found from format
    if (format === "{0:0}") {
        return value
            .toString()
            .replace(/\.0+$/, "")
            .replace(/(\.\d+?)0+$/, "$1");
    }

    //asume both format are same
    if (format === "{0:n}") {
        format = "{0:0.00}";
    }

    const match = format.match(/0\.(0+)/);
    const decimalPlaces = match ? match[1].length : 0;

    return parseFloat(value).toFixed(decimalPlaces);

    /*
    if (format && format === "") {
        const match = format.match(/0\.(0+)/);
        const decimalPlaces = match ? match[1].length : 0;
        return new Intl.NumberFormat("en-US", {
            minimumFractionDigits: decimalPlaces,
            maximumFractionDigits: decimalPlaces,
            useGrouping: false,
        }).format(value);
    } else {
        return new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            useGrouping: false,
        }).format(value);
    }
        */
};
