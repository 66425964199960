import React from "react";
import { PaymentsData } from "./dataRowsGrid";

const PaymentsDataRows = (restProps) => {
  //disable in-cell update and cancel button
  return (
    <>
      <div className="dtu-file-container">
        <PaymentsData
          pageProps={restProps.pageProps}
          compTableMasterKey={restProps.compTableMasterKey}
          selectedTab={restProps.selected}
          setIsPaymentsUpdateClick={restProps.setIsPaymentsUpdateClick}
          setisPaymentsCancelClick={restProps.setisPaymentsCancelClick}
          setScreenLock={restProps.setScreenLock}
          screenLock={restProps.screenLock}
          view={restProps.view}
          paymentsColumnsData={restProps.paymentsColumnsData}
          setPaymentsColumnsData={restProps.setPaymentsColumnsData}
          isPaymentsCheckColumn={restProps.isPaymentsCheckColumn}
          setPaymentsColumnCheck={restProps.setPaymentsColumnCheck}
          cancelKpiPaymentsAwaitStatus={restProps.cancelKpiPaymentsAwaitStatus}
          setCancelKpiPaymentsAwaitStatus={
            restProps.setCancelKpiPaymentsAwaitStatus
          }
          isPaymentsEdit={restProps.isPaymentsEdit}
          setisPaymentsEdit={restProps.setisPaymentsEdit}
          isPaymentsUpdateClick={restProps.isPaymentsUpdateClick}
          isPaymentsCancelClick={restProps.isPaymentsCancelClick}
          isRefreshBtnClick={restProps.isRefreshBtnClick}
          setRefreshBtnClick={restProps.setRefreshBtnClick}
          selectedRowId={restProps.selectedRowId}
        />
      </div>
    </>
  );
};

export default React.memo(PaymentsDataRows);
