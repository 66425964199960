import React from "react";
import BaseSysTimeOut from "./base/baseSysTimeOut";

class SysTimeOut extends BaseSysTimeOut {

  constructor(props) {

    super(props)

    const dialogOpenTimeMs = Math.round(((props.tenantTimeOut * 60) - this.state.leftSecond) * 1000)

    this.setupDialog({dialogOpenTimeMs: dialogOpenTimeMs})

    this.state.events.forEach((item) => {

      window.addEventListener(item, () => {

        this.setupDialog({dialogOpenTimeMs: dialogOpenTimeMs})
      })
    })
  }

  render() {

    return <this.dialogHTML />
  }
}

export default React.memo(SysTimeOut);
