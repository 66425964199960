export const dialogConfig = {
  notification: {
    success: {
      type: "success",
      text: "Data has been deleted successfully"
    },
    error: {
      type: "error",
      text: "Oops! Something went wrong ..."
    }
  }
};

