import {
    POSTAPI,
    GETAPI,
    generateHeader,
    errStatusHandler,
} from "../api-setup/api-helper";
import {
    GET_GROUP_NAME,
    GET_USER_ADMIN_DATA,
    GET_SELECT_SPECIALTY_DATA,
    GET_COMP_PLAN_DATA,
    GET_POSITION_DATA,
    GET_POSITION_NO_CFD_DATA,
    GET_SYSTEM_CONFIG,
    GET_PERIOD_TYPE,
    GET_RESULT_KPI,
    GET_RESULT_NAME,
    GET_CALENDAR_DATA,
    GET_EVENT_TYPE,
    GET_EVENT_SERVICE_LOCATION,
    GET_EVENT_DATA_SOURCE,
    GET_EVENT_POSITION,
    GET_EVENT_COMP_PLAIN,
    SELECT_UNIT_TYPE,
    GET_SELECT_GRID_COLUMN_XML,
    GET_DATA_TYPES,
    SELECT_KPI_TYPE,
    GET_EVENT_GROUP,
    GET_DTU_LOGS,
    SELECT_JOB_TYPE,
    SELECT_JOB_CONFGURATION,
    GET_PARENT_CHILD_ROLE_OBJ_ASSIGNMENT,
    SELECT_UDF_DATA_OBJECT,
    SELECT_SYSTEM_FX,
    SELECT_SYSTEM_FX_PARM_ASSIGNMENT,
    SELECT_GENERAL_LEDGER,
    SELECT_ROLE_LIST_BY_AUDIT_LOG,
    GET_CUSTOM_TABLE,
    SELECT_RULE_TYPE,
    SELECT_RATE_TYPE,
    GET_USER_STATUS,
    GET_RATE_TABLE,
    SELECT_RULE_RESULT_NAME,
    SELECT_RESULT_TYPE,
    SELECT_TEAM_NAME,
    GET_DB_ROLES,
    SELECT_PROVIDER_POSTION_FILTER,
    SELECT_CUSTOM_DATA_COLUMNS,
    GET_POSITION_TYPE_LIST,
    ADD_SPECIALITY_DATA,
    UPDATE_COLUMN_ORDER,
    GET_RECURRING_TYPE_LIST,
    SELECT_CUSTOM_FIELDS,
    SELECT_APPROVAL_STATUS,
    SELECT_KPI,
    SELECT_OPERATOR,
    SELECT_SYSTEM_PREF_CONFIG_BY_CODE,
    SELECT_ERROR_DETAIL_LOG,
    GET_DB_ROLE_TYPE_KEY_LIST,
    INSERT_DOCUMENT,
    DELETE_DOCUMENT,
    SELECT_DOCUMENT,
    SELECT_FORMULA_MASTER,
    SELECT_BUILD_DATA,
    GET_MAINTENACE_DATA,
} from "../api-setup/api-endpoints";
import { getUserCredential } from "../lib/cookies";
import { setNewMessage } from "../redux/actions/setting-actions";
import { GridColumnDataMapper } from "../utils/utils";
const cookiesData = getUserCredential();

export const GetGroupNameAPI = async (data, history, dispatch) => {
    return new Promise(function (resolve, reject) {
        POSTAPI(
            false,
            GET_GROUP_NAME,
            null,
            generateHeader(),
            data,
            dispatch
        ).then((res) => {
            if (res && res.data.out_parameters.out_ret_code === 0) {
                resolve(res.data.row_result);
            } else {
                reject(res);
            }
        });
    })
        .then((res) => {
            return res;
        })
        .catch((res) => {
            if (res.data.out_parameters.out_ret_code > 0) {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                errStatusHandler(res, history, dispatch);
            }
        });
};

export const addSpecialtyDataAsync = (reqData, history, dispatch) => {
    return new Promise(function (resolve, reject) {
        POSTAPI(
            false,
            ADD_SPECIALITY_DATA,
            null,
            generateHeader(),
            reqData,
            dispatch
        ).then((res) => {
            if (res.status) {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                    resolve(false);
                } else {
                    resolve(true);
                }
            }
        });
    })
        .then((res) => {
            return res;
        })
        .catch((res) => {
            if (res.data.out_parameters.out_ret_code > 0) {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                errStatusHandler(res, history, dispatch);
            }
        });
};

export const GetSPECIALTYAPI = async (data, history, dispatch) => {
    return new Promise(function (resolve, reject) {
        POSTAPI(
            false,
            GET_SELECT_SPECIALTY_DATA,
            null,
            generateHeader(),
            data,
            dispatch
        ).then((res) => {
            if (res && res.data.out_parameters.out_ret_code === 0) {
                resolve(res.data.row_result);
            } else {
                reject(res);
            }
        });
    })
        .then((res) => {
            return res;
        })
        .catch((res) => {
            if (res.data.out_parameters.out_ret_code > 0) {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                errStatusHandler(res, history, dispatch);
            }
        });
};

export const GetUserDataAPI = (data, history, dispatch) => {
    return new Promise(function (resolve, reject) {
        data.in_encrypt_key = cookiesData.out_acct_encrypt_key;
        data.in_export_flag = 0;
        data.in_org_title_keys = null;
        POSTAPI(
            false,
            GET_USER_ADMIN_DATA,
            null,
            generateHeader(),
            data,
            dispatch
        ).then((res) => {
            if (
                res &&
                (res.data.out_parameters.out_ret_code === 0 ||
                    res.data.out_parameters.out_ret_code === null)
            ) {
                resolve(res.data.row_result);
            } else {
                reject(res);
            }
        });
    })
        .then((res) => {
            return res;
        })
        .catch((res) => {
            if (res.data.out_parameters.out_ret_code > 0) {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                errStatusHandler(res, history, dispatch);
            }
        });
};

export const GetCompPlanDataApi = (data, history, dispatch) => {
    return new Promise(function (resolve, reject) {
        POSTAPI(
            false,
            GET_COMP_PLAN_DATA,
            null,
            generateHeader(),
            data,
            dispatch
        ).then((res) => {
            if (
                res &&
                (res.data.out_parameters.out_ret_code === 0 ||
                    res.data.out_parameters.out_ret_code === 2)
            ) {
                resolve(res.data.row_result);
            } else {
                reject(res);
            }
        });
    })
        .then((res) => {
            return res;
        })
        .catch((res) => {
            if (res.data.out_parameters.out_ret_code > 0) {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                errStatusHandler(res, history, dispatch);
            }
        });
};

export const GetTeamNameApi = (data, history, dispatch) => {
    return new Promise(function (resolve, reject) {
        POSTAPI(
            false,
            SELECT_TEAM_NAME,
            null,
            generateHeader(),
            data,
            dispatch
        ).then((res) => {
            if (res && res.data.out_parameters.out_ret_code === 0) {
                resolve(res.data.row_result);
            } else {
                reject(res);
            }
        });
    })
        .then((res) => {
            return res;
        })
        .catch((res) => {
            if (res.data.out_parameters.out_ret_code > 0) {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                errStatusHandler(res, history, dispatch);
            }
        });
};

export const GetPositionDataAPI = (data, history, dispatch) => {
    return new Promise(function (resolve, reject) {
        POSTAPI(
            false,
            GET_POSITION_DATA,
            null,
            generateHeader(),
            data,
            dispatch
        ).then((res) => {
            if (res && res.data.out_parameters.out_ret_code === 0) {
                resolve(res.data.row_result);
            } else {
                reject(res);
            }
        });
    })
        .then((res) => {
            return res;
        })
        .catch((res) => {
            if (res.data.out_parameters.out_ret_code > 0) {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                errStatusHandler(res, history, dispatch);
            }
        });
};

export const GetSystemConfigData = (data, history, dispatch) => {
    return new Promise(function (resolve, reject) {
        POSTAPI(
            false,
            GET_SYSTEM_CONFIG,
            null,
            generateHeader(),
            data,
            dispatch
        ).then((res) => {
            if (res && res.data.out_parameters.out_ret_code === 0) {
                resolve(res.data.row_result);
            } else {
                reject(res);
            }
        });
    })
        .then((res) => {
            return res;
        })
        .catch((res) => {
            if (res.data.out_parameters.out_ret_code > 0) {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                errStatusHandler(res, history, dispatch);
            }
        });
};

export const GetSystemConfigDataByCode = (data, history, dispatch) => {
    return new Promise(function (resolve, reject) {
        POSTAPI(
            false,
            SELECT_SYSTEM_PREF_CONFIG_BY_CODE,
            null,
            generateHeader(),
            data,
            dispatch
        ).then((res) => {
            if (res && res.status) {
                resolve(res.data.out_parameters);
            } else {
                reject(res);
            }
        });
    })
        .then((res) => {
            return res;
        })
        .catch((res) => {
            if (res.data.out_parameters.out_ret_code > 0) {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                errStatusHandler(res, history, dispatch);
            }
        });
};

export const GetPeriodTypeAPI = (data, dispatch, history) => {
    return new Promise(function (resolve, reject) {
        POSTAPI(
            false,
            GET_PERIOD_TYPE,
            null,
            generateHeader(),
            data,
            dispatch
        ).then((res) => {
            if (res) {
                if (res && res.data.out_parameters.out_ret_code === 0) {
                    resolve(res.data.row_result);
                } else {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                    resolve([]);
                }
            }
        });
    })
        .then((res) => {
            return res;
        })
        .catch((res) => {
            if (res.data.out_parameters.out_ret_code > 0) {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                errStatusHandler(res, history, dispatch);
            }
        });
};

export const GetResultKPI = (data, dispatch, history) => {
    return new Promise(function (resolve, reject) {
        POSTAPI(
            false,
            GET_RESULT_KPI,
            null,
            generateHeader(),
            data,
            dispatch
        ).then((res) => {
            if (res) {
                if (res && res.data.out_parameters.out_ret_code === 0) {
                    resolve(res.data.row_result);
                } else {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    resolve([]);
                    errStatusHandler(res, history, dispatch);
                }
            }
        });
    })
        .then((res) => {
            return res;
        })
        .catch((res) => {
            if (res.data.out_parameters.out_ret_code > 0) {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                errStatusHandler(res, history, dispatch);
            }
        });
};

export const getCompConfigKPI = (data, dispatch, history) => {
    return new Promise(function (resolve, reject) {
        POSTAPI(false, SELECT_KPI, null, generateHeader(), data, dispatch).then(
            (res) => {
                if (res) {
                    if (res && res.data.out_parameters.out_ret_code === 0) {
                        resolve(res.data.row_result);
                    } else {
                        dispatch(
                            setNewMessage({
                                message: res.data.out_parameters.out_error_msg,
                                type: "Error",
                                show: true,
                            })
                        );
                        resolve([]);
                        errStatusHandler(res, history, dispatch);
                    }
                }
            }
        );
    })
        .then((res) => {
            return res;
        })
        .catch((res) => {
            if (res.data.out_parameters.out_ret_code > 0) {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                errStatusHandler(res, history, dispatch);
            }
        });
};

export const GetKPIType = (data, dispatch, history) => {
    return new Promise(function (resolve, reject) {
        POSTAPI(
            false,
            SELECT_KPI_TYPE,
            null,
            generateHeader(),
            data,
            dispatch
        ).then((res) => {
            if (res) {
                if (res && res.data.out_parameters.out_ret_code === 0) {
                    resolve(res.data.row_result);
                } else {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                    resolve([]);
                }
            }
        });
    })
        .then((res) => {
            return res;
        })
        .catch((res) => {
            if (res.data.out_parameters.out_ret_code > 0) {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                errStatusHandler(res, history, dispatch);
            }
        });
};

export const GetEventType = (data, dispatch, history) => {
    return new Promise(function (resolve, reject) {
        POSTAPI(
            false,
            GET_EVENT_GROUP,
            null,
            generateHeader(),
            data,
            dispatch
        ).then((res) => {
            if (res) {
                if (res && res.data.out_parameters.out_ret_code === 0) {
                    resolve(res.data.row_result);
                } else {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    resolve([]);
                    errStatusHandler(res, history, dispatch);
                }
            }
        });
    })
        .then((res) => {
            return res;
        })
        .catch((res) => {
            if (res.data.out_parameters.out_ret_code > 0) {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                errStatusHandler(res, history, dispatch);
            }
        });
};

export const GetResultName = (data, dispatch, history) => {
    return new Promise(function (resolve, reject) {
        POSTAPI(
            false,
            GET_RESULT_NAME,
            null,
            generateHeader(),
            data,
            dispatch
        ).then((res) => {
            if (res && res.data.out_parameters.out_ret_code === 0) {
                resolve(res.data.row_result);
            } else {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                resolve([]);
                errStatusHandler(res, history, dispatch);
            }
        });
    })
        .then((res) => {
            return res;
        })
        .catch((res) => {
            if (res.data.out_parameters.out_ret_code > 0) {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                errStatusHandler(res, history, dispatch);
            }
        });
};

export const GetPeriodsDataList = (data, dispatch, history) => {
    return new Promise(function (resolve, reject) {
        POSTAPI(
            false,
            GET_CALENDAR_DATA,
            null,
            generateHeader(),
            data,
            dispatch
        ).then((res) => {
            if (res && res.data.out_parameters.out_ret_code === 0) {
                resolve(res.data.row_result);
            } else {
                resolve([]);
            }
        });
    })
        .then((res) => {
            return res;
        })
        .catch((res) => {
            if (res.data.out_parameters.out_ret_code > 0) {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                errStatusHandler(res, history, dispatch);
            }
        });
};

export const GetEventTypeAPI = (data, dispatch, history) => {
    return new Promise(function (resolve, reject) {
        POSTAPI(
            false,
            GET_EVENT_TYPE,
            null,
            generateHeader(),
            data,
            dispatch
        ).then((res) => {
            if (
                res &&
                (res.data.out_parameters.out_ret_code === 0 ||
                    res.data.out_parameters.out_ret_code === 2)
            ) {
                resolve(res.data.row_result);
            } else {
                // dispatch(
                //     setNewMessage({
                //         message: res?.data.out_parameters.out_error_msg,
                //         type: "Error",
                //         show: true,
                //     })
                // );
                resolve([]);
                errStatusHandler(res, history, dispatch);
            }
        });
    })
        .then((res) => {
            return res;
        })
        .catch((res) => {
            if (res.data.out_parameters.out_ret_code > 0) {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                errStatusHandler(res, history, dispatch);
            }
        });
};
export const GetEventTableAPI = (data, dispatch, history) => {
    return new Promise(function (resolve, reject) {
        POSTAPI(
            false,
            GET_DTU_LOGS,
            null,
            generateHeader(),
            data,
            dispatch
        ).then((res) => {
            if (
                res &&
                (res.data.out_parameters.out_ret_code === 0 ||
                    res.data.out_parameters.out_ret_code === 2)
            ) {
                resolve(res.data.row_result);
            } else {
                dispatch(
                    setNewMessage({
                        message: res?.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                resolve([]);
                errStatusHandler(res, history, dispatch);
            }
        });
    })
        .then((res) => {
            return res;
        })
        .catch((res) => {
            if (res.data.out_parameters.out_ret_code > 0) {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                errStatusHandler(res, history, dispatch);
            }
        });
};

export const GetEventServiceLocationAPI = (data, dispatch, history) => {
    return new Promise(function (resolve, reject) {
        POSTAPI(
            false,
            GET_EVENT_SERVICE_LOCATION,
            null,
            generateHeader(),
            data,
            dispatch
        ).then((res) => {
            if (
                res &&
                (res.data.out_parameters.out_ret_code === 0 ||
                    res.data.out_parameters.out_ret_code === 2)
            ) {
                resolve(res.data.row_result);
            } else {
                dispatch(
                    setNewMessage({
                        message: res?.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                resolve([]);
                errStatusHandler(res, history, dispatch);
            }
        });
    })
        .then((res) => {
            return res;
        })
        .catch((res) => {
            if (res.data.out_parameters.out_ret_code > 0) {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                errStatusHandler(res, history, dispatch);
            }
        });
};

export const GetEventDataSourceAPI = (data, dispatch, history) => {
    return new Promise(function (resolve, reject) {
        POSTAPI(
            false,
            GET_EVENT_DATA_SOURCE,
            null,
            generateHeader(),
            data,
            dispatch
        ).then((res) => {
            if (
                res &&
                (res.data.out_parameters.out_ret_code === 0 ||
                    res.data.out_parameters.out_ret_code === 2)
            ) {
                resolve(res.data.row_result);
            } else {
                // dispatch(
                //     setNewMessage({
                //         message: res?.data.out_parameters.out_error_msg,
                //         type: "Error",
                //         show: true,
                //     })
                // );
                resolve([]);
                errStatusHandler(res, history, dispatch);
            }
        });
    }).catch((res) => {
        if (res.data.out_parameters.out_ret_code > 0) {
            dispatch(
                setNewMessage({
                    message: res.data.out_parameters.out_error_msg,
                    type: "Error",
                    show: true,
                })
            );
            errStatusHandler(res, history, dispatch);
        }
    });
};

export const GetEventPositionAPI = (data, dispatch, history) => {
    return new Promise(function (resolve, reject) {
        POSTAPI(
            false,
            GET_EVENT_POSITION,
            null,
            generateHeader(),
            data,
            dispatch
        ).then((res) => {
            if (
                res &&
                (res.data.out_parameters.out_ret_code === 0 ||
                    res.data.out_parameters.out_ret_code === 2)
            ) {
                resolve(res.data.row_result);
            } else {
                // dispatch(
                //     setNewMessage({
                //         message: res?.data.out_parameters.out_error_msg,
                //         type: "Error",
                //         show: true,
                //     })
                // );
                resolve([]);
                errStatusHandler(res, history, dispatch);
            }
        });
    }).catch((res) => {
        if (res.data.out_parameters.out_ret_code > 0) {
            dispatch(
                setNewMessage({
                    message: res.data.out_parameters.out_error_msg,
                    type: "Error",
                    show: true,
                })
            );
            errStatusHandler(res, history, dispatch);
        }
    });
};

export const GetEventCompPlanAPI = (data, dispatch, history) => {
    return new Promise(function (resolve, reject) {
        POSTAPI(
            false,
            GET_EVENT_COMP_PLAIN,
            null,
            generateHeader(),
            data,
            dispatch
        ).then((res) => {
            if (
                res &&
                (res.data.out_parameters.out_ret_code === 0 ||
                    res.data.out_parameters.out_ret_code === 2)
            ) {
                resolve(res.data.row_result);
            } else {
                // dispatch(
                //     setNewMessage({
                //         message: res?.data.out_parameters.out_error_msg,
                //         type: "Error",
                //         show: true,
                //     })
                // );
                resolve([]);
                errStatusHandler(res, history, dispatch);
            }
        });
    }).catch((res) => {
        if (res.data.out_parameters.out_ret_code > 0) {
            dispatch(
                setNewMessage({
                    message: res.data.out_parameters.out_error_msg,
                    type: "Error",
                    show: true,
                })
            );
            errStatusHandler(res, history, dispatch);
        }
    });
};

export const GetUnitType = (data, dispatch, history) => {
    return new Promise(function (resolve, reject) {
        POSTAPI(
            false,
            SELECT_UNIT_TYPE,
            null,
            generateHeader(),
            data,
            dispatch
        ).then((res) => {
            if (
                res &&
                (res.data.out_parameters.out_ret_code === 0 ||
                    res.data.out_parameters.out_ret_code === 2)
            ) {
                resolve(res.data.row_result);
            } else {
                dispatch(
                    setNewMessage({
                        message: res?.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                resolve([]);
                errStatusHandler(res, history, dispatch);
            }
        });
    })
        .then((res) => {
            return res;
        })
        .catch((res) => {
            if (res.data.out_parameters.out_ret_code > 0) {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                errStatusHandler(res, history, dispatch);
            }
        });
};

export const getColumListXML = (data, dispatch, history) => {
    return new Promise(function (resolve, reject) {
        POSTAPI(
            false,
            GET_SELECT_GRID_COLUMN_XML,
            null,
            generateHeader(),
            data,
            dispatch
        ).then((res) => {
            if (
                res &&
                (res.data.out_parameters.out_ret_code === 0 ||
                    res.data.out_parameters.out_ret_code === 2)
            ) {
                const columData = GridColumnDataMapper(
                    res.data.out_parameters.out_columns_xml
                );
                resolve(columData);
            } else {
                // dispatch(
                //   setNewMessage({
                //     message: res?.data.out_parameters.out_error_msg,
                //     type: "Error",
                //     show: true,
                //   })
                // );
                resolve([]);
                errStatusHandler(res, history, dispatch);
            }
        });
    })
        .then((res) => {
            return res;
        })
        .catch((res) => {
            if (res.data.out_parameters.out_ret_code > 0) {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                errStatusHandler(res, history, dispatch);
            }
        });
};

export const GetDataTypes = (data, dispatch, history) => {
    return new Promise(function (resolve, reject) {
        POSTAPI(
            false,
            GET_DATA_TYPES,
            null,
            generateHeader(),
            data,
            dispatch
        ).then((res) => {
            if (
                res &&
                (res.data.out_parameters.out_ret_code === 0 ||
                    res.data.out_parameters.out_ret_code === 2)
            ) {
                resolve(res.data.row_result);
            } else {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                resolve([]);
                errStatusHandler(res, history, dispatch);
            }
        });
    })
        .then((res) => {
            return res;
        })
        .catch((res) => {
            if (res.data.out_parameters.out_ret_code > 0) {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                errStatusHandler(res, history, dispatch);
            }
        });
};
export const GeJobTypeAPI = (data, dispatch, history) => {
    return new Promise(function (resolve, reject) {
        POSTAPI(
            false,
            SELECT_JOB_TYPE,
            null,
            generateHeader(),
            data,
            dispatch
        ).then((res) => {
            if (res) {
                if (res && res.data.out_parameters.out_ret_code === 0) {
                    resolve(res.data.row_result);
                } else {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                    resolve([]);
                }
            }
        });
    })
        .then((res) => {
            return res;
        })
        .catch((res) => {
            if (res.data.out_parameters.out_ret_code > 0) {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                errStatusHandler(res, history, dispatch);
            }
        });
};
export const GetStagehookTypesData = (data, dispatch, history) => {
    return new Promise(function (resolve, reject) {
        POSTAPI(
            false,
            SELECT_JOB_CONFGURATION,
            null,
            generateHeader(),
            data,
            dispatch
        ).then((res) => {
            if (res) {
                if (res && res.data.out_parameters.out_ret_code === 0) {
                    resolve(res.data.row_result);
                } else {
                    // dispatch(
                    //     setNewMessage({
                    //         message: res.data.out_parameters.out_error_msg,
                    //         type: "Error",
                    //         show: true,
                    //     })
                    // );
                    errStatusHandler(res, history, dispatch);
                    resolve([]);
                }
            }
        });
    })
        .then((res) => {
            return res;
        })
        .catch((res) => {
            if (res.data.out_parameters.out_ret_code > 0) {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                errStatusHandler(res, history, dispatch);
            }
        });
};

export const GetDBObjectList = (data, dispatch, history) => {
    return new Promise(function (resolve, reject) {
        POSTAPI(
            false,
            GET_PARENT_CHILD_ROLE_OBJ_ASSIGNMENT,
            null,
            generateHeader(),
            data,
            dispatch
        ).then((res) => {
            if (res) {
                if (res && res.data.out_parameters.out_ret_code === 0) {
                    resolve(res.data.row_result);
                } else {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                    resolve([]);
                }
            }
        });
    })
        .then((res) => {
            return res;
        })
        .catch((res) => {
            if (res.data.out_parameters.out_ret_code > 0) {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                errStatusHandler(res, history, dispatch);
            }
        });
};

export const GetDBObjectListByAuditLog = (data, dispatch, history) => {
    return new Promise(function (resolve, reject) {
        POSTAPI(
            false,
            SELECT_ROLE_LIST_BY_AUDIT_LOG,
            null,
            generateHeader(),
            data,
            dispatch
        ).then((res) => {
            if (res) {
                if (res && res.data.out_parameters.out_ret_code === 0) {
                    resolve(res.data.row_result);
                } else {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                    resolve([]);
                }
            }
        });
    })
        .then((res) => {
            return res;
        })
        .catch((res) => {
            if (res.data.out_parameters.out_ret_code > 0) {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                errStatusHandler(res, history, dispatch);
            }
        });
};

export const GetUDFDataObj = (data, dispatch, history) => {
    return new Promise(function (resolve, reject) {
        POSTAPI(
            false,
            SELECT_UDF_DATA_OBJECT,
            null,
            generateHeader(),
            data,
            dispatch
        ).then((res) => {
            if (res) {
                if (res && res.data.out_parameters.out_ret_code === 0) {
                    resolve(res.data.row_result);
                } else {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                    resolve([]);
                }
            }
        });
    })
        .then((res) => {
            return res;
        })
        .catch((res) => {
            if (res.data.out_parameters.out_ret_code > 0) {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                errStatusHandler(res, history, dispatch);
            }
        });
};

export const GetSelectOperators = (data, dispatch, history) => {
    return new Promise(function (resolve, reject) {
        POSTAPI(
            false,
            SELECT_OPERATOR,
            null,
            generateHeader(),
            data,
            dispatch
        ).then((res) => {
            if (res) {
                if (res && res.data.out_parameters.out_ret_code === 0) {
                    const keys = Object.keys(res.data.row_result);
                    let finalRes = {
                        logicalOperator: [],
                        stringOperator: [],
                        numericOperator: [],
                    };

                    keys.forEach((key, idx) => {
                        const items = res.data.row_result[key];
                        finalRes[key] = items.map((it) => ({
                            ...it,
                            value: it.operator_value,
                            text: it.operator_label,
                        }));
                    });
                    resolve(finalRes);
                } else {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                    resolve([]);
                }
            }
        });
    })
        .then((res) => {
            return res;
        })
        .catch((res) => {
            if (res.data.out_parameters.out_ret_code > 0) {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                errStatusHandler(res, history, dispatch);
            }
        });
};

export const GetSeletedSystemFxData = (data, dispatch, history) => {
    return new Promise(function (resolve, reject) {
        POSTAPI(
            false,
            SELECT_SYSTEM_FX_PARM_ASSIGNMENT,
            null,
            generateHeader(),
            data,
            dispatch
        ).then((res) => {
            if (res) {
                if (res && res.data.out_parameters.out_ret_code === 0) {
                    resolve(res.data.row_result);
                } else {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                    resolve([]);
                }
            }
        });
    })
        .then((res) => {
            return res;
        })
        .catch((res) => {
            if (res.data.out_parameters.out_ret_code > 0) {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                errStatusHandler(res, history, dispatch);
            }
        });
};

export const GetSystemFxData = (data, dispatch, history) => {
    return new Promise(function (resolve, reject) {
        POSTAPI(
            false,
            SELECT_SYSTEM_FX,
            null,
            generateHeader(),
            data,
            dispatch
        ).then((res) => {
            if (res) {
                if (res && res.data.out_parameters.out_ret_code === 0) {
                    resolve(res.data.row_result);
                } else {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                    resolve([]);
                }
            }
        });
    })
        .then((res) => {
            return res;
        })
        .catch((res) => {
            if (res.data.out_parameters.out_ret_code > 0) {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                errStatusHandler(res, history, dispatch);
            }
        });
};

export const GetGeneralLedger = (data, dispatch, history) => {
    return new Promise(function (resolve, reject) {
        POSTAPI(
            false,
            SELECT_GENERAL_LEDGER,
            null,
            generateHeader(),
            data,
            dispatch
        ).then((res) => {
            if (res) {
                if (res && res.data.out_parameters.out_ret_code === 0) {
                    resolve(res.data.row_result);
                } else {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                    resolve([]);
                }
            }
        });
    })
        .then((res) => {
            return res;
        })
        .catch((res) => {
            if (res.data.out_parameters.out_ret_code > 0) {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                errStatusHandler(res, history, dispatch);
            }
        });
};

export const getCustomTableListAsync = (listData, dispatch, history) => {
    return new Promise(function (resolve, reject) {
        POSTAPI(false, GET_CUSTOM_TABLE, null, generateHeader(), listData).then(
            (res) => {
                if (res?.data?.out_parameters.out_ret_code > 0) {
                    errStatusHandler(res, history, dispatch);
                }
                if (res.data) {
                    let userData = JSON.parse(
                        JSON.stringify(res.data.row_result)
                    );
                    resolve(userData);
                }
            }
        );
    })
        .then((res) => {
            return res;
        })
        .catch((res) => {
            if (res.data.out_parameters.out_ret_code > 0) {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                errStatusHandler(res, history, dispatch);
            }
        });
};

export const GetCustomTableColumns = (listData, dispatch, history) => {
    return new Promise(function (resolve, reject) {
        POSTAPI(
            false,
            SELECT_CUSTOM_DATA_COLUMNS,
            null,
            generateHeader(),
            listData
        ).then((res) => {
            if (res?.data?.out_parameters.out_ret_code > 0) {
                errStatusHandler(res, history, dispatch);
            }
            if (res.data) {
                let userData = JSON.parse(JSON.stringify(res.data.row_result));
                resolve(userData);
            }
        });
    })
        .then((res) => {
            return res;
        })
        .catch((res) => {
            if (res.data.out_parameters.out_ret_code > 0) {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                errStatusHandler(res, history, dispatch);
            }
        });
};

export const GeUserStatusTypeAPI = (data, dispatch, history) => {
    return new Promise(function (resolve, reject) {
        GETAPI(
            false,
            GET_USER_STATUS,
            null,
            generateHeader(),
            data,
            dispatch
        ).then((res) => {
            if (res) {
                if (res && res.data.out_parameters.out_ret_code === 0) {
                    resolve(res.data.row_result);
                } else {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                    resolve([]);
                }
            }
        });
    })
        .then((res) => {
            return res;
        })
        .catch((res) => {
            if (res.data.out_parameters.out_ret_code > 0) {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                errStatusHandler(res, history, dispatch);
            }
        });
};

export const GeDbRoleAPI = (data, dispatch, history) => {
    return new Promise(function (resolve, reject) {
        POSTAPI(
            false,
            GET_DB_ROLES,
            null,
            generateHeader(),
            data,
            dispatch
        ).then((res) => {
            if (res) {
                if (res && res.data.out_parameters.out_ret_code === 0) {
                    resolve(res.data.row_result);
                } else {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                    resolve([]);
                }
            }
        });
    })
        .then((res) => {
            return res;
        })
        .catch((res) => {
            if (res.data.out_parameters.out_ret_code > 0) {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                errStatusHandler(res, history, dispatch);
            }
        });
};

export const GetRuleTypeAPI = (data, dispatch, history) => {
    return new Promise(function (resolve, reject) {
        POSTAPI(
            false,
            SELECT_RULE_TYPE,
            null,
            generateHeader(),
            data,
            dispatch
        ).then((res) => {
            if (res) {
                if (res && res.data.out_parameters.out_ret_code === 0) {
                    resolve(res.data.row_result);
                } else {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                    resolve([]);
                }
            }
        });
    })
        .then((res) => {
            return res;
        })
        .catch((res) => {
            if (res.data.out_parameters.out_ret_code > 0) {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                errStatusHandler(res, history, dispatch);
            }
        });
};
export const getProvidePostionFilter = (data, dispatch, history) => {
    return new Promise(function (resolve, reject) {
        POSTAPI(
            false,
            SELECT_PROVIDER_POSTION_FILTER,
            null,
            generateHeader(),
            data,
            dispatch
        ).then((res) => {
            if (res) {
                if (res && res.data.out_parameters.out_ret_code === 0) {
                    resolve(res.data.row_result);
                } else {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                    resolve([]);
                }
            }
        });
    })
        .then((res) => {
            return res;
        })
        .catch((res) => {
            if (res.data.out_parameters.out_ret_code > 0) {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                errStatusHandler(res, history, dispatch);
            }
        });
};
export const GetRateTypeData = (data, dispatch, history) => {
    return new Promise(function (resolve, reject) {
        POSTAPI(
            false,
            SELECT_RATE_TYPE,
            null,
            generateHeader(),
            data,
            dispatch
        ).then((res) => {
            if (res) {
                if (
                    (res && res.data.out_parameters.out_ret_code === 0) ||
                    res.data.out_parameters.out_ret_code === null
                ) {
                    resolve(res.data.row_result);
                } else {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                    resolve([]);
                }
            }
        });
    }).catch((res) => {
        if (res.data.out_parameters.out_ret_code > 0) {
            dispatch(
                setNewMessage({
                    message: res.data.out_parameters.out_error_msg,
                    type: "Error",
                    show: true,
                })
            );
            errStatusHandler(res, history, dispatch);
        }
    });
};

export const GetRateTableData = (data, dispatch, history) => {
    return new Promise(function (resolve, reject) {
        POSTAPI(
            false,
            GET_RATE_TABLE,
            null,
            generateHeader(),
            data,
            dispatch
        ).then((res) => {
            if (res) {
                if (res && res.data.out_parameters.out_ret_code === 0) {
                    resolve(res.data.row_result);
                } else {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                    resolve([]);
                }
            }
        });
    })
        .then((res) => {
            return res;
        })
        .catch((res) => {
            if (res.data.out_parameters.out_ret_code > 0) {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                errStatusHandler(res, history, dispatch);
            }
        });
};

export const GetResultNameData = (data, dispatch, history) => {
    return new Promise(function (resolve, reject) {
        POSTAPI(
            false,
            SELECT_RULE_RESULT_NAME,
            null,
            generateHeader(),
            data,
            dispatch
        ).then((res) => {
            if (res) {
                if (res && res.data.out_parameters.out_ret_code === 0) {
                    resolve(res.data.row_result);
                } else {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                    resolve([]);
                }
            }
        });
    })
        .then((res) => {
            return res;
        })
        .catch((res) => {
            if (res.data.out_parameters.out_ret_code > 0) {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                errStatusHandler(res, history, dispatch);
            }
        });
};

export const GetResultTypeData = (data, dispatch, history) => {
    return new Promise(function (resolve, reject) {
        POSTAPI(
            false,
            SELECT_RESULT_TYPE,
            null,
            generateHeader(),
            data,
            dispatch
        ).then((res) => {
            if (res) {
                if (res && res.data.out_parameters.out_ret_code === 0) {
                    resolve(res.data.row_result);
                } else {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                    resolve([]);
                }
            }
        });
    })
        .then((res) => {
            return res;
        })
        .catch((res) => {
            if (res.data.out_parameters.out_ret_code > 0) {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                errStatusHandler(res, history, dispatch);
            }
        });
};

export const GetFormulaListData = (data, dispatch, history) => {
    return new Promise(function (resolve, reject) {
        POSTAPI(
            false,
            SELECT_FORMULA_MASTER,
            null,
            generateHeader(),
            data,
            dispatch
        ).then((res) => {
            if (res) {
                if (res && res.data.out_parameters.out_ret_code === 0) {
                    resolve(res.data.row_result);
                } else {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                    resolve([]);
                }
            }
        });
    })
        .then((res) => {
            return res;
        })
        .catch((res) => {
            if (res.data.out_parameters.out_ret_code > 0) {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                errStatusHandler(res, history, dispatch);
            }
        });
};

export const GetPositionType = (data, dispatch, history) => {
    return new Promise(function (resolve, reject) {
        POSTAPI(
            false,
            GET_POSITION_TYPE_LIST,
            null,
            generateHeader(),
            data,
            dispatch
        ).then((res) => {
            if (
                res &&
                (res.data.out_parameters.out_ret_code === 0 ||
                    res.data.out_parameters.out_ret_code === 2)
            ) {
                resolve(res.data.row_result);
            } else {
                dispatch(
                    setNewMessage({
                        message: res?.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                resolve([]);
                errStatusHandler(res, history, dispatch);
            }
        });
    })
        .then((res) => {
            return res;
        })
        .catch((res) => {
            if (res.data.out_parameters.out_ret_code > 0) {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                errStatusHandler(res, history, dispatch);
            }
        });
};
export const updateColumnReorderAsync = async (data, dispatch, history) => {
    return new Promise(function (resolve, reject) {
        POSTAPI(
            false,
            UPDATE_COLUMN_ORDER,
            null,
            generateHeader(),
            data,
            dispatch
        ).then((res) => {
            if (res && res.data.out_parameters.out_ret_code === 0) {
                resolve(true);
            } else {
                reject(false);
            }
        });
    })
        .then((res) => {
            return res;
        })
        .catch((res) => {
            if (res.data.out_parameters.out_ret_code > 0) {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                errStatusHandler(res, history, dispatch);
            }
        });
};

export const GetRecurringType = (data, dispatch, history) => {
    return new Promise(function (resolve, reject) {
        POSTAPI(
            false,
            GET_RECURRING_TYPE_LIST,
            null,
            generateHeader(),
            data,
            dispatch
        ).then((res) => {
            if (
                res &&
                (res.data.out_parameters.out_ret_code === 0 ||
                    res.data.out_parameters.out_ret_code === 2)
            ) {
                resolve(res.data.row_result);
            } else {
                dispatch(
                    setNewMessage({
                        message: res?.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                resolve([]);
                errStatusHandler(res, history, dispatch);
            }
        });
    })
        .then((res) => {
            return res;
        })
        .catch((res) => {
            if (res.data.out_parameters.out_ret_code > 0) {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                errStatusHandler(res, history, dispatch);
            }
        });
};

export const getCustomFieldsMaster = (data, dispatch, history) => {
    return new Promise(function (resolve, reject) {
        POSTAPI(
            false,
            SELECT_CUSTOM_FIELDS,
            null,
            generateHeader(),
            data,
            dispatch
        ).then((res) => {
            if (
                res &&
                (res.data.out_parameters.out_ret_code === 0 ||
                    res.data.out_parameters.out_ret_code === 2)
            ) {
                if (res.data.row_result.length > 0) {
                    resolve(res.data.row_result);
                }
            } else {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                errStatusHandler(res, history, dispatch);
            }
        });
    }).then((res) => {
        return res;
    });
};

export const getApprovalStatus = (data, dispatch, history) => {
    return new Promise(function (resolve, reject) {
        POSTAPI(
            false,
            SELECT_APPROVAL_STATUS,
            null,
            generateHeader(),
            data,
            dispatch
        ).then((res) => {
            if (
                res &&
                (res.data.out_parameters.out_ret_code === 0 ||
                    res.data.out_parameters.out_ret_code === 2)
            ) {
                resolve(res.data.row_result);
            } else {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                errStatusHandler(res, history, dispatch);
            }
        });
    }).then((res) => {
        return res;
    });
};

export const getRoleTypeData = (data, dispatch, history) => {
    return new Promise(function (resolve, reject) {
        GETAPI(
            false,
            GET_DB_ROLE_TYPE_KEY_LIST,
            null,
            generateHeader(),
            data,
            dispatch
        ).then((res) => {
            if (
                res &&
                (res.data.out_parameters.out_ret_code === 0 ||
                    res.data.out_parameters.out_ret_code === 2)
            ) {
                resolve(res.data.row_result);
            } else {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                errStatusHandler(res, history, dispatch);
            }
        });
    }).then((res) => {
        return res;
    });
};

export const getErrorLogAsync = (data, dispatch, history) => {
    return new Promise(function (resolve, reject) {
        POSTAPI(
            false,
            SELECT_ERROR_DETAIL_LOG,
            null,
            generateHeader(),
            data,
            dispatch
        ).then((res) => {
            if (res && res.data.out_parameters.out_ret_code === 0) {
                resolve(res);
            } else {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                errStatusHandler(res, history, dispatch);
            }
        });
    }).then((res) => {
        return res;
    });
};

export const insertDocument = (payloadData, dataReq, dispatch, history) => {
    return new Promise(function (resolve, reject) {
        POSTAPI(
            false,
            INSERT_DOCUMENT,
            null,
            { ...generateHeader(), ...payloadData },
            dataReq,
            dispatch
        ).then((res) => {
            if (res && res.data.out_parameters.out_ret_code === 0) {
                resolve(res);
            } else {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                errStatusHandler(res, history, dispatch);
            }
        });
    }).then((res) => {
        return res;
    });
};

export const deleteDocument = (data, dispatch, history) => {
    return new Promise(function (resolve, reject) {
        POSTAPI(
            false,
            DELETE_DOCUMENT,
            null,
            generateHeader(),
            data,
            dispatch
        ).then((res) => {
            if (res && res.data.out_parameters.out_ret_code === 0) {
                resolve(res);
            } else {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                errStatusHandler(res, history, dispatch);
            }
        });
    }).then((res) => {
        return res;
    });
};

export const selectDocument = (data, dispatch, history) => {
    return new Promise(function (resolve, reject) {
        POSTAPI(
            false,
            SELECT_DOCUMENT,
            null,
            generateHeader(),
            data,
            dispatch
        ).then((res) => {
            if (res && res.data.out_parameters.out_ret_code === 0) {
                resolve(res);
            } else {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                errStatusHandler(res, history, dispatch);
            }
        });
    }).then((res) => {
        return res;
    });
};

export const GetVersionBuildApi = (history, dispatch) => {
    const data = {
        in_filter: null,
        in_sort_number: null,
        in_sort_order: null,
    };

    return new Promise(function (resolve, reject) {
        POSTAPI(
            false,
            SELECT_BUILD_DATA,
            null,
            generateHeader(),
            data,
            dispatch
        ).then((res) => {
            if (res && res.data.out_parameters.out_ret_code === 0) {
                resolve(res.data);
            } else {
                reject(res);
            }
        });
    })
        .then((res) => {
            return res;
        })
        .catch((res) => {
            if (res?.data?.out_parameters?.out_ret_code > 0) {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                errStatusHandler(res, history, dispatch);
            }
        });
};
export const GetMaintanceData = (history, dispatch) => {
    return new Promise(function (resolve, reject) {
        POSTAPI(
            false,
            GET_MAINTENACE_DATA,
            null,
            generateHeader(),
            [],
            dispatch
        ).then((res) => {
            if (res && res.data.out_parameters.out_ret_code === 0) {
                resolve(res.data.row_result);
            } else {
                reject(res);
            }
        });
    })
        .then((res) => {
            return res;
        })
        .catch((res) => {
            if (res?.data?.out_parameters?.out_ret_code > 0) {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                errStatusHandler(res, history, dispatch);
            }
        });
};

/**
 * get positions w/o custom field data API
 *
 */
export const GetPositionNoCFDDataAPI = (data, history, dispatch) => {
    return new Promise(function (resolve, reject) {
        POSTAPI(
            false,
            GET_POSITION_NO_CFD_DATA,
            null,
            generateHeader(),
            data,
            dispatch
        ).then((res) => {
            if (res && res.data.out_parameters.out_ret_code === 0) {
                resolve(res.data.row_result);
            } else {
                reject(res);
            }
        });
    })
        .then((res) => {
            return res;
        })
        .catch((res) => {
            if (res.data.out_parameters.out_ret_code > 0) {
                dispatch(
                    setNewMessage({
                        message: res.data.out_parameters.out_error_msg,
                        type: "Error",
                        show: true,
                    })
                );
                errStatusHandler(res, history, dispatch);
            }
        });
};
