import React, { useCallback } from "react";
import { ChartSeriesItem } from "@progress/kendo-react-charts";

const formatNumber = (event) => {
    let formattedNumber = event?.dataItem?.series_data?.toFixed(2) || 0;
    if (formattedNumber.endsWith(".00")) {
        formattedNumber = formattedNumber.slice(0, -3);
    }
    formattedNumber = parseFloat(formattedNumber).toLocaleString();
    return formattedNumber;
};

const pieChartConfig = {
    type: "pie",
    visibleInLegend: true,
    position: "insideEnd",
    tooltip: {
        visible: true,
        render: (e) => {
            const total = e?.point?.series?.data.reduce(
                (acc, point) => acc + point.series_data,
                0
            );
            const value = e?.point?.value ?? 0;
            const formattedPercentage =
                new Intl.NumberFormat("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                }).format((value / total) * 100) + "%";

            return `${e?.point?.category} - ${formattedPercentage}`;
        },
    },
    overlay: {
        gradient: "none",
    },
    labels: {
        visible: true,
        font: "500 11.998px Poppins, sans-serif",
        content: formatNumber,
        distance: 20,
    },
};

export const PieParserHook = () => {
    const transformPieData = (data) => {
        if (data && data?.length > 0) {
            return data?.map((chartObj) => ({
                data: chartObj?.data / data.length,
                ...chartObj,
            }));
        }
        return [];
    };

    const renderPie = useCallback(({ chartValue }) => {
        return (
            <ChartSeriesItem
                categoryField="series_name"
                field="series_data"
                colorField="color"
                visible="visible"
                key="pie"
                data={transformPieData(chartValue)}
                {...pieChartConfig}
            />
        );
    }, []);

    return {
        renderPie,
    };
};
