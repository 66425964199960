import React from "react";
import { getUserCredential } from "../../lib/cookies";
import { errStatusHandler, POSTAPI } from "../../api-setup/api-helper";
import { GridColumnDataMapper, ResultGridDataMapper } from "../../utils/utils";
import { USER_GRID_DEFAULT_PAGE_SIZE } from "../../constants/grid";
import { useDispatch } from "react-redux";
import { setNewMessage } from "../../redux/actions/setting-actions";
import {
    EXPORT_CUSTOM_TABLES,
    GET_EVENT_DATA,
    GET_SELECT_GRID_COLUMN_XML,
    SELECT_EVENTS_DATA,
    DELETE_EVENTS_DATA,
    UPDATE_EVENT,
} from "../../api-setup/api-endpoints";
import { useHistory } from "react-router-dom";
import { EVENT_TYPE_ID } from "src/constants/constants";

const initialDataState = {
    skip: 0,
    take: USER_GRID_DEFAULT_PAGE_SIZE,
};
let defaultItem = {
    text: USER_GRID_DEFAULT_PAGE_SIZE,
    id: 2,
};

export default function Events() {
    const history = useHistory();
    const cookiesData = getUserCredential();
    const header = {
        "x-access-token": cookiesData.secretToken,
    };
    const dispatch = useDispatch();
    const [data, setData] = React.useState([]);
    const [columns, setColumns] = React.useState([]);
    const [dataCount, setDataCount] = React.useState(0);
    const [paginationData, setPaginationData] = React.useState({
        pageData: 0,
        rows: 10,
    });
    const [initialLoading, setLoadingState] = React.useState(false);
    const [loading, setLoadingStateSearch] = React.useState({
        isSearchLoader: false,
        updateColumn: false
    });
    const [pageData, setPage] = React.useState(initialDataState);
    const [page, setPageData] = React.useState(0);
    const [editData, setEditData] = React.useState("");
    const [pageSize, setPageSize] = React.useState(defaultItem);
    const [isAPICalling, setAPICallingState] = React.useState(false);
    const [group, setGroup] = React.useState([]);
    const [resultState, setResultState] = React.useState([]);
    const [collapsedState, setCollapsedState] = React.useState([]);
    const [originalData, setOriginalData] = React.useState([]);
    let newData = [];
    let processedGroupData = [];
    const [fileData, setFileData] = React.useState({});
    const [noDataFound, setNoDataFound] = React.useState(false);
    const [batchFields, setBatchFields] = React.useState({
        batchValue: [],
        batchData: [],
        isBatchDataLoading: true,
    });
    const [batchList, setbatchList] = React.useState([
    ]);

    const getListDataAsync = (data, extraColumn) => {
        return new Promise(function (resolve, reject) {
            data.in_encrypt_key = cookiesData.out_acct_encrypt_key;
            data.in_export_flag = 0;
            data.in_org_title_keys = null;
            POSTAPI(false, GET_EVENT_DATA, null, header, data, dispatch).then(
                (res) => {
                    const { out_ret_code, out_results_xml } = res.data.out_parameters;
                    if (out_ret_code === 0 || out_ret_code === 2) {
                        setAPICallingState(false);
                        setLoadingState(true);
                        setEditData("");
                        if (res.data.out_parameters?.out_results_xml) {
                            setLoadingStateSearch({
                                ...loading,
                                isSearchLoader: false,
                            });
                            setDataCount(res.data.out_parameters.out_total_rows);
                            setData([]);
                            const userData = ResultGridDataMapper(out_results_xml).map((item, idx) => ({
                                ...item,
                                rowId: idx,
                                selected: false,
                                isValidateRow: false,
                                expanded: false,
                              }));
                            setData(userData);
                            setOriginalData(userData);
                            setNoDataFound(false);
                            resolve(userData)
                        } else {
                            setLoadingStateSearch({
                                ...loading,
                                isSearchLoader: false,
                            });
                            setNoDataFound(true);
                            setData([]);
                            setOriginalData([]);
                            setDataCount(0);
                            resolve([])
                        }
                    } else {
                        setAPICallingState(false);
                        setNoDataFound(true);
                        setLoadingState(true);
                        errStatusHandler(res, history, dispatch);
                        setLoadingStateSearch({
                            ...loading,
                            isSearchLoader: false,
                        });
                        errStatusHandler(res, history, dispatch);
                    }
                }
            );
        })
    };

    const getColumListXML = (reqData ,isEventApproval ,eventsTypeFields) => {
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                GET_SELECT_GRID_COLUMN_XML,
                null,
                header,
                reqData,
                dispatch
            ).then((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    setLoadingState(true);
                    setAPICallingState(true);
                    resolve(false)
                    errStatusHandler(res, history, dispatch);
                } else {
                    setColumns([])
                    const columData = GridColumnDataMapper(
                        res.data.out_parameters.out_columns_xml, '', true, 'event');
                        let tempColRes = [...columData]
                        if(tempColRes.length > 0){
                          tempColRes.forEach((item)=>{
                            if(item.field === 'approval_status_name' || item.field === 'approval_status_mstr_key' || item.field === 'approval_notes'){
                                item.isEditable =  item.isEditable && isEventApproval ? true : false
                            }else{
                                item.isEditable = item.isEditable && eventsTypeFields.eventTypeValue.id === EVENT_TYPE_ID ? true : false
                            }
                          })
                        }
                    setColumns(tempColRes);
                    resolve(true)
                }
            });
        })
    };

    const exportToExcelAsync = async (reqData) => {
        await POSTAPI(
            false,
            EXPORT_CUSTOM_TABLES,
            null,
            header,
            reqData,
            dispatch
        ).then(async (res) => {
            if (res) {
                if (res.status) {
                    setAPICallingState(false);
                    let fileData = {
                        fileName: res.data.fileName,
                        fileLink: res.data.fileLink,
                    };
                    setFileData(fileData);
                } else {
                    dispatch(
                        setNewMessage({
                            message: res.data.msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            }
        });
    };

    const selecteEventByBatch = async (reqData) => {
        POSTAPI(
            false,
            SELECT_EVENTS_DATA,
            null,
            header,
            reqData,
            dispatch
        ).then(async (res) => {
            if (res) {
                if (res.data.out_parameters.out_ret_code === 0) {
                    let tempArray = [];
                    res.data.row_result.map((item) => {
                        let obj = {};
                        obj.text = item.etl_batch_name;
                        obj.id = item.etl_batch_mstr_key;
                        tempArray.push(obj);
                        return 0;
                    });

                    setbatchList(tempArray);
                    setBatchFields({
                        ...batchFields,
                        batchData: tempArray,
                        isBatchDataLoading: false,
                    });
                } else {
                    setBatchFields({
                        ...batchFields,
                        isBatchDataLoading: false,
                    });
                }
            }
        });
    };

    const deleteDataAsync = async (bodyData) => {
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                DELETE_EVENTS_DATA,
                null,
                header,
                bodyData,
                dispatch
            ).then((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    resolve(false)
                } else {
                    resolve(true)
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    const updateEventAsync = async (bodyData) => {
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                UPDATE_EVENT,
                null,
                header,
                bodyData,
                dispatch
            ).then((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    resolve(false)
                } else {
                    resolve(true)
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    return [
        {
            data,
            columns,
            dataCount,
            setData,
            setColumns,
            pageData,
            editData,
            page,
            pageSize,
            isAPICalling,
            setEditData,
            initialLoading,
            setPage,
            setPageData,
            setPageSize,
            setPaginationData,
            setAPICallingState,
            paginationData,
            collapsedState,
            setCollapsedState,
            group,
            setResultState,
            setGroup,
            newData,
            originalData,
            resultState,
            processedGroupData,
            fileData,
            setFileData,
            setLoadingStateSearch,
            loading,
            noDataFound,
            batchList,
            batchFields,
            setBatchFields,
        },
        {
            getListDataAsync,
            getColumListXML,
            exportToExcelAsync,
            selecteEventByBatch,
            deleteDataAsync,
            updateEventAsync
        },
    ];
}
