import React, { useEffect } from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";

const DropDownListComponent = (props) => {
  const {
    style,
    placeholder,
    loading = false,
    dataList,
    name,
    module,
    textField,
    dataItemKey,
    valueData,
    data,
    appendTo,
    autoClose,
    autoBind,
    onOpen,
    onChange,
    itemRender,
    onFilterChange,
    disabled = false,
    filterable = true,
    onClose,
    className
  } = props;
  const [search, setSearch] = React.useState("");
  const [filterValue, setFilterValue] = React.useState("");

  const filterDropdownChangeValue = (event) => {
    handleFocus();
    setSearch(event.filter);
    setFilterValue(event.filter.value);
    let dataValue = filterBy(dataList.slice(), event.filter);
    onFilterChange(dataValue);
  };

  const onOpenValue = () => {
    let dataValue = filterValue && filterValue !== "" ? filterBy(data.slice(), search) : dataList;
    onOpen && onOpen(dataValue);
    setFilterValue("");
  };

  const handleChangeDropdownValue = (e) => {
    onChange && onChange(e.value, e.target.name);
  };

  const listLoadingRender = (element) => {
    const noData = (
      <h4
        style={{
          fontSize: "1em",
        }}
      >
        <br />
        Loading
      </h4>
    );
    return React.cloneElement(element, { ...element.props }, noData);
  };

  const listNoDataRender = (element) => {
    const noData = (
      <h4
        style={{
          fontSize: "1em",
        }}
      >
        <br />
        No data found
      </h4>
    );
    return React.cloneElement(element, { ...element.props }, noData);
  };

  const onClosePopup = () => {
    setFilterValue("");
    setSearch("");
    onClose?.();
  };
  const handleBlur = (e) => {
    setFilterValue("");
  };

  const handleFocus = () => { };

  // const onClosePopup =()=>{
  //   setSearch(search)
  // }
  // const itemRender = (li, itemProps) => {
  //   const { dataItem, index } = itemProps;
  //   const itemChildren = <span>{li.props.children}</span>;
  //   return React.cloneElement(
  //     li,
  //     { ...li.props, className: `k-item ${dataItem.disabled ? "k-disabled" : ""}` },
  //     itemChildren
  //   );
  // };

  useEffect(() => {
    setFilterValue("");
  }, [dataList]);

 
  const renderDropdownItem = itemRender ? itemRender : (li, itemProps) => (
    <li onClick={itemProps.onClick} className={itemProps.selected ? 'k-state-selected text-capitalize' : 'text-capitalize'}>
      {itemProps.dataItem[textField]}
    </li>
  );

  return (
    <DropDownList
      name={name}
      data={data || []}
      filter={filterValue}
      //  itemRender={itemRender}
      {...(itemRender && { itemRender: renderDropdownItem })}
      skipDisabledItems={false}
      textField={textField}
      onOpen={() => {
        onOpenValue(module);
      }}
      // onClose={()=>{
      //   onClosePopup()
      // }}
      onClose={onClosePopup}
      dataItemKey={dataItemKey}
      value={valueData || ""}
      onChange={(e) => {
        handleChangeDropdownValue(e);
      }}
      filterable={filterable}
      onFilterChange={(e) => {
        filterDropdownChangeValue(e, module);
      }}
      popupSettings={{ appendTo: appendTo }}
      autoClose={autoClose}
      autoBind={autoBind}
      loading={loading}
      placeholder={placeholder}
      style={style}
      onBlur={handleBlur}
      onFocus={handleFocus}
      disabled={disabled}
      className={className}
      listNoDataRender={
        loading
          ? listLoadingRender
          : loading === false && data && data.length === 0
            ? listNoDataRender
            : listNoDataRender
      }
    />
  );
};

export default React.memo(DropDownListComponent);
