import { SET_USER_STATUS_DATA, SET_ROLE_DATA } from "../actions/user-actions";

const initialState = {
    userStatusData: [],
    roleData: [],
};

const userGridReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER_STATUS_DATA:
            return {
                ...state,
                userStatusData: action.data.userStatusData,
            };
        case SET_ROLE_DATA:
            return {
                ...state,
                roleData: action.data.roleData,
            };
        default:
            return state;
    }
};

export default userGridReducer;
