import React from "react";
import {
  DELETE_COMP_RULE,
  GET_COMPENSATION_RULES_DATA,
  INSERT_COMP_RULE,
  SELECT_COMP_RULE,
  SELECT_COMP_RULE_EFF_VERSION,
  SELECT_RULE_TYPE,
  UPDATE_CREDIT_ALLOC_RULE_CONFIG,
  UPDATE_CREDIT_RULE_CONFIG,
  UPDATE_INCENTIVE_CONFIG,
  UPDATE_MEASUREMENTS_RULE_CONFIG,
  UPDATE_PAYMENT_RULE_CONFIG,
} from "../../../api-setup/api-endpoints";
import { getTenantCookieData, getUserCredential } from "../../../lib/cookies";
import { POSTAPI, errStatusHandler } from "../../../api-setup/api-helper";
import { GridColumnDataMapper } from "../../../utils/utils";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { DEFAULT_SORT } from "src/constants/grid";
import {
  getCompConfigKPI,
  GetEventTypeAPI,
  GetFormulaListData,
  GetGroupNameAPI,
  GetPeriodsDataList,
  GetPeriodTypeAPI,
  GetResultNameData,
} from "src/lib/common-service";
import { setNewMessage } from "src/redux/actions/setting-actions";

export default function CompensationRules() {
  const cookiesData = getUserCredential();
  const defaultPageSize = getTenantCookieData("record_per_page");
  const header = {
    "x-access-token": cookiesData.secretToken,
  };
  const history = useHistory();
  const dispatch = useDispatch();
  const [data, setData] = React.useState([]);
  const [columns, setColumns] = React.useState([]);
  const [dataCount, setDataCount] = React.useState(0);
  const [loadingState, setLoadState] = React.useState({
    isSaveData: undefined,
  });
  const [initialLoading, setLoadingState] = React.useState(false);
  const [isAPICalling, setAPICallingState] = React.useState(false);
  const [isRefreshLoading, setRefreshLoading] = React.useState(false);
  const [loading, setLoadingStateSearch] = React.useState({
    isSearchLoader: false,
  });
  const [confirmationPopup, setConfirmationPopup] = React.useState({
    title: "Delete",
    desc: "Are you sure you want to delete this version?",
    isVisible: false,
    type: "",
    actionBtn: [],
    data: {},
    action: "Delete",
  });
  const [originalData, setOriginalData] = React.useState([]);
  const [noDataFound,setNoDataFound] = React.useState(false)

  const getListDataAsync = (listData) => {
    return new Promise(function (resolve, reject) {
      POSTAPI(false, GET_COMPENSATION_RULES_DATA, null, header, listData, dispatch).then((res) => {
        if (res) {
          if (
            res.data.out_parameters.out_ret_code === 0 ||
            res.data.out_parameters.out_ret_code === 2
          ) {
            let userData = JSON.parse(JSON.stringify(res.data.row_result));
            userData.map((item, idx) => {
              item.rowId = idx;
              item.selected = idx === 0;
              item.primary_key = item.rule_config_key + "_" + item.rule_type_mstr_key;
              return 0;
            });
            if(listData?.in_load_type === "grid-reload"){

              const columData = GridColumnDataMapper(res.data.out_parameters.out_column_xml);
              columData.unshift({
                title: "ACTION",
                field: "action",
                show: true,
                width: 100,
              });
              setColumns(columData);
            }
            setAPICallingState(false);
            setLoadingState(true);
            setData(userData);
            setDataCount(res.data.out_parameters.out_total_rows);
            setOriginalData(userData);
            if(userData.length === 0){
              setNoDataFound(true)
            }
            resolve(userData);
          } else {
            setLoadingState(true);
            errStatusHandler(res, history, dispatch);
          }
        }
      });
    })
      .then((res) => {
        return res;
      })
      .catch((res) => {
        if (res.data.out_parameters.out_ret_code > 0) {
          dispatch(
            setNewMessage({
              message: res.data.out_parameters.out_error_msg,
              type: "Error",
              show: true,
            })
          );
          errStatusHandler(res, history, dispatch);
        }
      });
  };

  const deleteDataAsync = (bodyData) => {
    return new Promise(function (resolve, reject) {
      POSTAPI(false, DELETE_COMP_RULE, null, header, bodyData, dispatch).then((res) => {
        if (res.data.out_parameters.out_ret_code > 0) {
          setConfirmationPopup({
            ...confirmationPopup,
            isVisible: false,
            type: "",
            actionBtn: [],
            data: {},
            action: "",
          });
          dispatch(
            setNewMessage({
              message: res.data.out_parameters.out_error_msg,
              type: "Error",
              show: true,
            })
          );
          resolve(true);
        } else {
          setConfirmationPopup({
            ...confirmationPopup,
            isVisible: false,
            type: "",
            actionBtn: [],
            data: {},
            action: "",
          });
          resolve(true);
        }
      });
    });
  };

  const getRuleType = (bodyData) => {
    setLoadState({ ...loadingState, isSaveData: false });
    return new Promise(function (resolve, reject) {
      POSTAPI(false, SELECT_RULE_TYPE, null, header, bodyData, dispatch).then((res) => {
        if (res.status) {
          resolve(res.data.row_result);
          setLoadState({ ...loadingState, isSaveData: true });
        }
      });
    });
  };

  const addDataAsync = (bodyData) => {
    setLoadState({ ...loadingState, isSaveData: false });
    return new Promise(function (resolve, reject) {
      POSTAPI(false, INSERT_COMP_RULE, null, header, bodyData, dispatch).then((res) => {
        if (res.status) {
          if (res.data.out_parameters.out_ret_code > 0) {
            dispatch(
              setNewMessage({
                message: res.data.out_parameters.out_error_msg,
                type: "Error",
                show: true,
              })
            );
            errStatusHandler(res, history, dispatch);
            resolve(false);
          } else {
            setLoadState({ ...loadingState, isSaveData: true });
            resolve(true);
          }
        }
        dispatch(
          setNewMessage({
            message: res?.data?.data?.out_parameters?.out_error_msg,
            type: "Error",
            show: true,
          })
          );
        setLoadState({ ...loadingState, isSaveData: true });
        resolve(true);
      });
    });
  };

  const copyMultipleDataAsync = (bodyData) => {
    setLoadState({ ...loadingState, isSaveData: false });
    return new Promise(function (resolve, reject) {
      POSTAPI(false, INSERT_COMP_RULE, null, header, bodyData, dispatch).then((res) => {
        if (res.status) {
          if (res.data.out_parameters.out_ret_code > 0) {
            dispatch(
              setNewMessage({
                message: res.data.out_parameters.out_error_msg,
                type: "Error",
                show: true,
              })
            );
            errStatusHandler(res, history, dispatch);
            resolve(false);
          } else {
            setLoadState({ ...loadingState, isSaveData: true });
            resolve(res.data);
          }
        }
        dispatch(
          setNewMessage({
            message: res?.data?.data?.out_parameters?.out_error_msg,
            type: "Error",
            show: true,
          })
          );
        setLoadState({ ...loadingState, isSaveData: true });
        resolve(true);
      });
    });
  };


  const UpdateDataAsync = (bodyData, module) => {
    
    let api = "";
    switch (module) {
      case "incentive":
        api = UPDATE_INCENTIVE_CONFIG;
        break;
      case "payment":
        api = UPDATE_PAYMENT_RULE_CONFIG;
        break;
      case "measurement":
        api = UPDATE_MEASUREMENTS_RULE_CONFIG;
        break;
      case "directcredit":
        api = UPDATE_CREDIT_RULE_CONFIG;
        break;
      case "indirectcredit":
        api = UPDATE_CREDIT_RULE_CONFIG;
        break;
      case "creditallocation":
        api = UPDATE_CREDIT_ALLOC_RULE_CONFIG;
        break;
      default:
        api = UPDATE_CREDIT_ALLOC_RULE_CONFIG;
        break;
    }
    setRefreshLoading(true);
    setLoadState({ ...loadingState, isupdateData: false });
    return new Promise(function (resolve, reject) {
      POSTAPI(false, api, null, header, bodyData, dispatch).then((res) => {
        setRefreshLoading(false);
        if (res.status) {
          if (res.data.out_parameters.out_ret_code > 0) {
            dispatch(
              setNewMessage({
                message: res.data.out_parameters.out_error_msg,
                type: "Error",
                show: true,
              })
            );
            errStatusHandler(res, history, dispatch);
            setLoadState({
              ...loadingState,
              isupdateData: true,
            });
            resolve(false);
          } else {
            setLoadState({
              ...loadingState,
              isupdateData: true,
            });
            resolve(true);
          }
        }
      });
    });
  };

  const getCompRuleConfig = (bodyData) => {
    return new Promise(function (resolve, reject) {
      POSTAPI(false, SELECT_COMP_RULE, null, header, bodyData, dispatch).then((res) => {
        if (res.data && res.status) {
          if (res.data.out_parameters.out_ret_code > 0) {
            dispatch(
              setNewMessage({
                message: res.data.out_parameters.out_error_msg,
                type: "Error",
                show: true,
              })
            );
            errStatusHandler(res, history, dispatch);
            resolve(false);
          } else {
            resolve(res.data.row_result);
          }
        }
      });
    });
  };

  // APIS for rule filter condition Items
  const getKPIsListAsync = async () => {
    const apiData = {
      in_tenant_id: cookiesData.out_tenant_id,
      in_filter: null,
      in_user_role_filter: "> 0",
      in_user_key: cookiesData.out_user_id,
      in_rule_filter: null,
      in_sort_number: DEFAULT_SORT.number,
      in_sort_order: DEFAULT_SORT.order,
      in_page_row_offset: 0,
      in_page_rows: defaultPageSize.recordTake,
      in_load_type: "data-reload",
      filter: {},
    };

    const res = await getCompConfigKPI(apiData, dispatch, history);
    return res.map((it) => ({ ...it, text: it.kpi_name, value: it.kpi_mstr_key }));
  };

  const getEventTypesListAsync = async () => {
    const apiData = {
      in_tenant_id: cookiesData.out_tenant_id,
      in_filter: null,
      in_sort_number: 3,
      in_sort_order: "ASC",
      in_page_row_offset: 0,
      in_page_rows: 9999,
    };
    const res = await GetEventTypeAPI(apiData, dispatch, history);
    return res.map((it) => ({ ...it, text: it.event_type_name, value: it.event_type_mstr_key }));
  };

  const getPeriodNamesListAsync = async () => {
    const apiData = {
      in_tenant_id: cookiesData.out_tenant_id,
      in_filter: null,
      in_sort_number: 1,
      in_sort_order: "DESC",
      in_page_row_offset: 0,
      in_page_rows: 2000,
      in_sort_number_concat: "a.period_end_date ASC, a.period_level_order DESC",
      in_load_type: "grid-reload",
    };
    const res = await GetPeriodsDataList(apiData, dispatch, history);
    return res.map((it) => ({ ...it, text: it.period_name, value: it.period_mstr_key }));
  };

  const getGroupNameListAsync = async () => {
    const apiData = {
      in_tenant_id: cookiesData.out_tenant_id,
      in_filter: null,
      in_sort_number: 4,
      in_sort_order: "ASC",
      in_page_row_offset: 0,
      in_page_rows: 9999,
      in_user_role_filter: " > 0",
    };
    const res = await GetGroupNameAPI(apiData, history, dispatch);
    return res.map((it) => ({ ...it, text: it.title_name, value: it.org_title_key }));
  };

  const getPeriodTypesListAsync = async () => {
    const apiData = {
      in_filter: "AND a.period_type_hierarchy_level IS NOT NULL",
      in_sort_number: null,
      in_sort_order: null,
    };
    const res = await GetPeriodTypeAPI(apiData, dispatch, history);
    return res.map((it) => ({ ...it, text: it.period_type_name, value: it.period_type_mstr_key }));
  };

  const getResultTypeListAsync = async () => {
    const apiData = {
      in_filter: null,
      in_load_type: "grid-reload",
      in_page_row_offset: 0,
      in_page_rows: 2000,
      in_sort_number: 1,
      in_sort_order: "DESC",
      in_tenant_id: cookiesData.out_tenant_id,
    };
    const res = await GetResultNameData(apiData, dispatch, history);
    return res.map((it) => ({ ...it, text: it.result_name, value: it.result_name_mstr_key }));
  };

  const getFormulaListAsync = async () => {
    const apiData = {
      in_filter: " AND ( (a.formula_type = 'boolean') ) ",
      in_sort_number: DEFAULT_SORT.number,
      in_sort_order: DEFAULT_SORT.order,
      in_db_role_key: cookiesData?.out_db_role_key,
      in_page_row_offset: 0,
      in_page_rows: 100000000,
    };
    const res = await GetFormulaListData(apiData, dispatch, history);
    return res.map((it) => ({ ...it, text: it.formula_name, value: it.formula_mstr_key }));
  };


  const getEffectiveVersions = (bodyData) => {
    return new Promise(function (resolve, reject) {
      POSTAPI(false, SELECT_COMP_RULE_EFF_VERSION, null, header, bodyData, dispatch).then((res) => {
        if (res.status) {
          resolve(res.data.row_result);
        }
      });
    });
  };


  return [
    {
      data,
      dataCount,
      columns,
      setData,
      setDataCount,
      setColumns,
      isAPICalling,
      initialLoading,
      setAPICallingState,
      loadingState,
      setLoadState,
      setRefreshLoading,
      isRefreshLoading,
      loading,
      setLoadingStateSearch,
      setConfirmationPopup,
      confirmationPopup,
      setOriginalData,
      originalData,
      noDataFound
    },
    {
      getListDataAsync,
      deleteDataAsync,
      getRuleType,
      addDataAsync,
      UpdateDataAsync,
      getCompRuleConfig,
      getKPIsListAsync,
      getEventTypesListAsync,
      getPeriodNamesListAsync,
      getGroupNameListAsync,
      getPeriodTypesListAsync,
      getResultTypeListAsync,
      getFormulaListAsync,
      getEffectiveVersions,
      copyMultipleDataAsync
    },
  ];
}
