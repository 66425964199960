import React from "react";
import DataTable from "../../components/dataTable";
import { GridColumn as Column } from "@progress/kendo-react-grid";
import { DEFAULT_SORT } from "../../constants/grid";
import { columnMenuProps } from "../../components/customColumnMenu";
import { useDispatch } from "react-redux";
import { dateFormateCellForJob, getfilterDataString } from "../../utils/utils";
import { Window, WindowActionsBar } from "@progress/kendo-react-dialogs";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { DAYS_OF_WEEK, MONTH_OF_YEAR } from "src/constants/constants";
import { Error, Label } from "@progress/kendo-react-labels";
import useAPI from "./jobDetailService";
import CustomDropdown from "src/components/customDropdown";
import { FieldWrapper, FormElement } from "@progress/kendo-react-form";
import { NumericTextBox, Checkbox } from "@progress/kendo-react-inputs";
import { DatePicker, TimePicker } from "@progress/kendo-react-dateinputs";
import MultiSelectDropdown from "../../components/multiSelect";
import { GetSystemConfigData } from "../../lib/common-service";
import { CellRender, RowRender } from "../../components/renderers";
import MessageSchema from "../../utils/messageSchema";
import { useHistory } from "react-router-dom";
import { transformDateTime } from "src/lib/date-time.util";
import useApi from "./assignService";
import { getTenantCookieData, getUserCredential } from "../../lib/cookies";
import { jobHistoryGridWidth } from "../../utils/calculateGridWidth";

const loadingPanel = (
  <div className="k-loading-mask">
    <span className="k-loading-text">Loading</span>
    <div className="k-loading-image"></div>
    <div className="k-loading-color"></div>
  </div>
);

const AssignPosition = (restProps) => {
  const cookiesData = getUserCredential();
  const history = useHistory();
  const dispatch = useDispatch();
  const [list, Action] = useApi();

  const [visible, setVisible] = React.useState(restProps.visible);
  const [stageChange, setStateChange] = React.useState("DEFAULT");
  const [modal, setModal] = React.useState(true);
  const defaultPageSize = getTenantCookieData("record_per_page");
  const [pageData, setPage] = React.useState({
    skip: 0,
    take: defaultPageSize.recordTake,
  });
  const [pageSize, setPageSize] = React.useState(defaultPageSize);
  const [filterField, setFilterField] = React.useState({
    logic: "and",
    filters: [],
  });
  const [sortField, setSortField] = React.useState([]);
  const [filterData, setFilterData] = React.useState({
    isSort: false,
    isFilter: false,
    data: "",
    in_sort_number: 11,
    sort_order: DEFAULT_SORT.order,
  });
  const {
    data,
    columns,
    dataCount,
    isColumnMenuSearch,
    isAPICalling,
    setAPICallingState,
    initialLoading,
    setLoadingStateSearch,
    loading,
    noDataFound,
    setJobTypeFields,
    jobTypeFields,
    groupNameFields,
    jobNameFields,
    setGroupNameFields,
    setJobNameFields,
    jobTypeList,
    setPeriodFields,
    periodsFields,
    jobNameList,
    periodTypeList,
    groupNameList,
    periodsTypeFields,
    setPeriodTypeFields,
    periodsList,
    setData,
    setColumns,
    isRefreshLoading,
  } = list;

  const apiCallData = {
    in_acct_encrypt_key: cookiesData.out_acct_encrypt_key,
    in_period_mstr_key: restProps.dataItemList.period_mstr_key,
    in_job_type_mstr_key: restProps.dataItemList.job_type_mstr_key,
    in_job_mstr_keys: restProps.dataItemList.job_mstr_key,
    in_org_title_keys: "0",
    in_filter: `AND ( a.proc_ctrl_mstr_key = '${restProps.dataItemList.job_his_ref_key}' )  AND ( a.is_processed = 1 )  `,
    in_group_by: null,
    in_sort_number: 11,
    in_sort_order: "DESC",
    in_page_row_offset: 0,
    in_page_rows: defaultPageSize.recordTake,
  };

  let columnReqData = {
    in_tenant_id: cookiesData.out_tenant_id,
    in_filter: null,
    in_filter_db_name: "procare_system",
    in_filter_table_name: "vw_job_history",
    in_result_name_mstr_key: 0,
    in_event_type_mstr_key: 0,
    in_hide_column: 0,
  };
  React.useEffect(() => {
    const dataaVal = {
      ...apiCallData,
      in_load_type: "grid-reload",
    };
    getGridData(dataaVal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getGridData = async (inParams) => {
    let res = await Action.getListDataAsync(inParams, "");
    // if (res) {
    //   restProps.setjobDetailLoading(false);
    // }
  };

  const IsprocessedCell = React.useCallback((props) => {
    const { ariaColumnIndex, columnIndex, dataItem, field } = props;

    let val = dataItem[field || ""];

    if (field == "is_processed") {
      val = dataItem[field || ""] == 1 ? "true" : "false";
    }
    const defaultRendering = (
      <td aria-colindex={ariaColumnIndex} data-grid-col-index={columnIndex}>
        <span title={val}>{val == 1 ? true : false}</span>
      </td>
    );

    return defaultRendering;
  }, []);
  const StringReadAndOnlyCell = React.useCallback((props) => {
    const { ariaColumnIndex, columnIndex, dataItem, field } = props;

    let val = dataItem[field || ""];

    if (field.indexOf("_mstr_key") !== -1) {
      val = dataItem[field.replace("_mstr_key", "_name") || ""];
    }
    if (field == "is_processed") {
      val = dataItem[field || ""] == 1 ? "true" : "false";
    }
    const defaultRendering = (
      <td aria-colindex={ariaColumnIndex} data-grid-col-index={columnIndex}>
        <span title={val}>{val}</span>
      </td>
    );

    return defaultRendering;
  }, []);

  const useCol = (useColumns) => {
    return useColumns.map((column, idx) => {
      return column.show && column.title !== "" ? (
        <Column
          {...columnMenuProps(column)}
          cell={
            column.field === "start_time" || column.field === "end_time"
              ? dateFormateCellForJob
              : StringReadAndOnlyCell
          }
          key={idx}
          sortable={true}
          width={
            data.length > 0 ? jobHistoryGridWidth(data, column.title) : "100"
          }
          format={column.format}
          filterable={column.field === "job_type_name" ? false : true}
        />
      ) : (
        false
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  let CustomColumn = useCol(columns);

  const onRefreshClick = () => {
    setAPICallingState(true);

    const dataVal = {
      ...apiCallData,
      in_filter: getInFilter(),
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
      in_page_row_offset: 0,
      in_page_rows: defaultPageSize.recordTake,
      in_load_type: "data-reload",
    };
    let filterStringData = `AND ( a.proc_ctrl_mstr_key = '${restProps.dataItemList.job_his_ref_key}' )  AND ( a.is_processed = 1 )  `;

    Action.getListDataAsync(dataVal, filterStringData);
  };

  const getInFilter = () => {
    return filterData.isFilter ? filterData.data : null;
  };

  const getInSortNumber = () => {
    return filterData.isSort ? filterData.in_sort_number : 11;
  };

  const getInSortOrder = () => {
    return filterData.isSort ? filterData.sort_order : DEFAULT_SORT.order;
  };

  const getPageTake = () => {
    return pageData.take;
  };

  const getPageSkip = () => {
    return pageData.skip;
  };

  const handlePageChange = async (event) => {
    const pageData = event.page;
    setAPICallingState(true);

    setPage({ skip: pageData.skip, take: pageData.take });

    const dataVal = {
      ...apiCallData,
      in_filter: getInFilter(),
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
      in_page_row_offset: pageData.skip,
      in_page_rows: pageData.take,
      in_load_type: "grid-reload",
    };
    let filterStringData = `AND ( a.proc_ctrl_mstr_key = '${restProps.dataItemList.job_his_ref_key}' )  AND ( a.is_processed = 1 )  `;

    Action.getListDataAsync(dataVal, filterStringData);
  };

  const ModalTitle = () => {
    const dataItem = restProps.dataItemList;
    const jobName = dataItem.job_name;
    const objectType = dataItem.job_type || dataItem.object_type; // Use job_type if object_type is not available
    const periodsName = dataItem.plan_year;
    // return `Executed Positions | ${jobName} | ${periodsName} | ${objectType}`;

    return (
      <>
        <span className="custtableflex">
          <div className="custtableitem">
            {jobName} | {periodsName} | {objectType}
          </div>
          <span className="custtableseprator"> &gt; </span>
          <span> Executed Positions</span>
        </span>
      </>
    );
  };

  //   let jobName = restProps.dataItemList.job_name;
  //   let jobTypeName = restProps.dataItemList.job_type_name;
  //   return (
  //     <>
  //       <span className="custtableflex">
  //         <div className="custtableitem">{jobName}</div>
  //         <span className="custtableseprator"> &gt; </span>
  //         <span> {jobTypeName} Job</span>
  //       </span>
  //     </>
  //   );
  // };

  const onClosePopup = () => {
    setVisible(!visible);
    restProps.setIsClickAssignPosition(false);
    // setSaveClick(false);
    // setErrorObj({});
    // setFormFields(initialFormFields);
  };

  const handleStageChange = (e) => {
    setStateChange(e.state);
    if (e.state === "MINIMIZED") {
      setModal(false);
    } else if (e.state === "DEFAULT") {
      setModal(true);
    }
  };
  const handleColumnMenu = (columnMenuData, isFilter, isSort, filterObj) => {
    if (isSort) {
      setSortField(columnMenuData);
    } else {
      setFilterField(filterObj);
    }

    let index = 1;
    let filterSkip = getPageSkip();
    let filterTake = getPageTake();

    if (isFilter) {
      filterSkip = 0;
      filterTake = pageSize.recordTake;

      //reset pager when filter applies
      setPage({ skip: filterSkip, take: filterTake });
    }

    if (data.length > 0 && columnMenuData[0] !== undefined) {
      let obj = Object.keys(data[0]);
      index = obj.findIndex((x) => x === columnMenuData[0].field) + 1;
    }

    const filterString = isFilter
      ? getfilterDataString(columnMenuData, columns)
      : filterData.data;

    isFilter = false;
    if (filterString !== "") {
      isFilter = true;
    }

    let sortDir =
      filterData.isSort && columnMenuData.length
        ? filterData.sort_order
        : DEFAULT_SORT.order;
    let sortNumber =
      filterData.isSort && columnMenuData.length
        ? filterData.in_sort_number
        : 11;

    isSort = !columnMenuData.length ? false : filterData.isSort;

    if (
      columnMenuData[0] !== undefined &&
      columnMenuData[0].dir !== undefined
    ) {
      isSort = true;
      sortDir = columnMenuData[0].dir.toUpperCase();
      sortNumber = index;
    }
    const dataVal = {
      ...apiCallData,
      in_load_type: "data-reload",
      in_filter: isFilter ? filterString : null,
      in_sort_number: sortNumber,
      in_sort_order: sortDir,
      in_page_row_offset: filterSkip,
      in_page_rows: filterTake,
    };

    setFilterData({
      ...filterData,
      isFilter: isFilter,
      isSort: isSort,
      data: filterString,
      in_sort_number: sortNumber,
      sort_order: sortDir,
    });

    setAPICallingState(true);

    let filterStringData = `AND ( a.proc_ctrl_mstr_key = '${restProps.dataItemList.job_his_ref_key}' )  AND ( a.is_processed = 1 )  `;

    Action.getListDataAsync(dataVal, filterStringData);
    // (dataVal, filterStringData);
  };

  const onClearFilter = async () => {
    setAPICallingState(true);
    setFilterField({ logic: "and", filters: [] });
    //set in_params
    const dataVal = {
      ...apiCallData,
      in_filter: null,
      in_load_type: "grid-reload",
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
      in_page_row_offset: 0,
      in_page_rows: getPageTake(),
    };
    setFilterData({
      ...filterData,
      data: "",
      isFilter: false,
    });
    let filterStringData = `AND ( a.proc_ctrl_mstr_key = '${restProps.dataItemList.job_his_ref_key}' )  AND ( a.is_processed = 1 )  `;

    Action.getListDataAsync(dataVal, filterStringData);
  };
  return (
    <>
      <Window
        title={<ModalTitle />}
        onClose={onClosePopup}
        width={1085}
        height={688}
        resizable={false}
      >
        {isRefreshLoading && loadingPanel}
        {columns.length > 0 ? (
          <DataTable
            isAPICalling={isAPICalling}
            initialLoading={initialLoading}
            data={data}
            pageData={pageData}
            dataCount={dataCount}
            customColumn={CustomColumn}
            isColumnMenuSearch={isColumnMenuSearch}
            columns={columns}
            gridHeight={"600px"}
            dataItemKey={"user_key"}
            width={"auto"}
            defaultPageSize={defaultPageSize}
            pageSize={pageSize}
            noDataFound={noDataFound}
            fileName={"Export_job_history.xlsx"}
            module={"job-history"}
            setPageSize={setPageSize}
            handlePageChange={handlePageChange}
            refreshClick={onRefreshClick}
            handleColumnMenu={handleColumnMenu}
            onClearFilter={onClearFilter}
            initialFilter={filterField}
            initialSort={sortField}
          />
        ) : (
          <>{loadingPanel}</>
        )}
      </Window>
    </>
  );
};
export default React.memo(AssignPosition);
