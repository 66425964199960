import { useDispatch } from "react-redux";
import {
    getUserCredential,
    removeLocalStorage,
    removeOtpData,
    removeUserCredential,
} from "../lib/cookies";
import { POSTAPI } from "../api-setup/api-helper";
import { GET_PARENT_CHILD_ROLE_OBJ_ASSIGNMENT } from "../api-setup/api-endpoints";
import {
    setDbObjData,
    setReadWriteAccess,
} from "../redux/actions/main-actions";
import { useHistory } from "react-router-dom";

export const DbObjectService = () => {
    const dispatch = useDispatch();
    const cookiesData = getUserCredential();
    const history = useHistory();

    if (!cookiesData) return null;

    const header = {
        "x-access-token": cookiesData.secretToken,
    };

    const getSidebarDataAsync = () => {
        POSTAPI(
            false,
            GET_PARENT_CHILD_ROLE_OBJ_ASSIGNMENT,
            null,
            header,
            {
                in_db_role_key: cookiesData.out_db_role_key,
                in_sort_number: 2,
                in_sort_order: "ASC",
                in_parent_filter: null,
            },
            dispatch
        ).then((res) => {
            let parentData = [];
            let readAccessDeniend = [];
            let writeAccessDeniend = [];
            if (res === undefined || res?.statusCode === 401) {
                removeLocalStorage("resultData");
                removeUserCredential();
                removeOtpData();
                history.push("/login");
            } else {
                if (res && res.data.row_result) {
                    res.data.row_result
                        .filter((x) => x.is_menu === 1)
                        .forEach((resData, resId) => {
                            resData["is_active"] = 0;
                            let childrenData = [];
                            if (
                                resData?.children?.find(
                                    (o) => o?.read_access === 1
                                )
                            ) {
                                resData.read_access = 1;
                            }
                            resData?.children
                                ?.filter((x) => x.is_menu === 1)
                                .forEach((subResData) => {
                                    if (subResData.read_access === 0) {
                                        readAccessDeniend.push(
                                            subResData.db_obj_code
                                        );
                                        return;
                                    }

                                    if (subResData.write_access === 0) {
                                        writeAccessDeniend.push(
                                            subResData.db_obj_code
                                        );
                                    }

                                    subResData["is_active"] = 0;

                                    if (
                                        window.location.pathname ===
                                        "/" + subResData.db_obj_code
                                    ) {
                                        subResData["is_active"] = 1;
                                        resData["is_active"] = 1;
                                    }

                                    subResData.subChildren.map((sItem) => {
                                        if (sItem.read_access === 0) {
                                            readAccessDeniend.push(
                                                sItem.db_obj_code
                                            );
                                        }
                                        if (sItem.write_access === 0) {
                                            writeAccessDeniend.push(
                                                sItem.db_obj_code
                                            );
                                        }

                                        sItem["is_active"] = 0;

                                        if (
                                            window.location.pathname ===
                                            "/" + sItem.db_obj_code
                                        ) {
                                            sItem["is_active"] = 1;
                                            subResData["is_active"] = 1;
                                        }
                                        return 0;
                                    });

                                    childrenData.push(subResData);
                                    return 0;
                                });

                            if (
                                !childrenData.length ||
                                resData.read_access === 0
                            ) {
                                return;
                            }

                            resData["children"] = childrenData;

                            parentData.push(resData);
                            return 0;
                        });
                }
            }

            let accessDeniend = {
                read_access_deniend: readAccessDeniend,
                write_access_deniend: writeAccessDeniend,
            };
            dispatch(setDbObjData(parentData));
            dispatch(
                setReadWriteAccess(
                    accessDeniend.read_access_deniend,
                    accessDeniend.write_access_deniend
                )
            );
        });
    };

    return getSidebarDataAsync;
};
