import React from "react";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { EFFCTIVE_TYPE } from "../../constants/constants";
import { Button } from "@progress/kendo-react-buttons";
import { Switch } from "@progress/kendo-react-inputs";
import { filterBy } from "@progress/kendo-data-query";
import { Dialog } from "@progress/kendo-react-dialogs";



const AddPlanEffectiveVersion = (restProps) => {
  const [isDisable, setIsDisable] = React.useState(false);
  const [isdropdwonDisable, setdropdwonDisable] = React.useState(true);
  const [value, setValue] = React.useState([]);
  const [copyValue, setCopyValue] = React.useState();
  const [filterdata, setFilterData] = React.useState(EFFCTIVE_TYPE.slice());

  const addNewConfig = () => {
    restProps.setEffectivePlanPopup(!restProps.isAddEffPlanPopup);
    setCopyValue([]);
    setValue([]);
    setdropdwonDisable(true);
    setIsDisable(false);
  };

  const onValueChange = (e) => {
    if (e.value === true) {
      setIsDisable(true);
      setdropdwonDisable(false);
    } else {
      setValue([]);
      setCopyValue([]);
      setdropdwonDisable(true);
      setIsDisable(false);
    }
  };

  const handleFieldChange = (e) => {
    let data = e.value.map(getFullName);
    setIsDisable(false);
    setValue([...e.value]);

    setCopyValue(data);
    if (data.length === 0) {
      setIsDisable(true);
    }
  };

  const getFullName = (item) => {
    return [item.value].join(" ");
  };


  const saveAssignment = () => {
    restProps.setEffVersionState({
        ...restProps.effVersionState,
        insertPlanVersion: {
            rowId: restProps.rowId,
            periodKey: restProps.periodKey,
            copyAssignment:copyValue !== undefined ? copyValue.toString() : null,
        },
    });
    setCopyValue([]);
    setValue([]);
    setdropdwonDisable(true);
    setIsDisable(false);
  };

  const filterData = (filter) => {
    const data = EFFCTIVE_TYPE.slice();
    return filterBy(data, filter);
  };

  const filterChange = (event) => {
    setFilterData(filterData(event.filter));
  };

  const listNoDataRender = (element) => {
    const noData = (
      <h4
        style={{
          fontSize: "1em",
        }}
      >
        <br />
        No data found
      </h4>
    );
    return React.cloneElement(element, { ...element.props }, noData);
  };

  return (
    <div className="Page-title">
        {restProps.isAddEffPlanPopup && (
        <>
          <Dialog
            title={"Add New Effective Version"}
            onRemove={addNewConfig}
            onClose={addNewConfig}
             
          >
            <div className="text-center">
              Do you want to copy assignments from selected effective version{" "}
                <b className="bluebold">{restProps.effVersionDataItem.periodName}</b>?{" "}
              <Switch
                onLabel={"Yes"}
                offLabel={"No"}
                onChange={onValueChange}
              />
            </div>
            <br></br>
            <div className="floatleft width100">
              <MultiSelect
                onChange={(e) => {
                  handleFieldChange(e);
                }}
                filterable={true}
                placeholder={"Select Assignment to Copy"}
                value={value}
                data={filterdata}
                textField="text"
                name="in_object_type"
                autoClose={false}
                disabled={isdropdwonDisable}
                onFilterChange={filterChange}
                listNoDataRender={
                  filterdata && filterdata.length === 0
                    ? listNoDataRender
                    : listNoDataRender
                }
              />
            </div>
            <div className="dropdownFooter">
              <div className="k-form-buttons">
                <Button
                  type={"submit"}
                  className="primarybtn"
                  onClick={() => {
                    saveAssignment();
                  }}
                  disabled={isDisable}
                >
                  Create
                </Button>
                <Button
                  type={"submit"}
                  className="cancelbtn"
                  onClick={addNewConfig}
                  disabled={false}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Dialog>
        </>)}
    </div>
  );
};

export default AddPlanEffectiveVersion;
